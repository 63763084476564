// @ts-nocheck
import Event from './Event';

export default class ConnectionEvent extends Event {
  constructor(type, { headers, message, status, statusText }) {
    super(type);

    this.headers = headers;
    this.message = message;
    this.status = status;
    this.statusText = statusText;
  }
}
