export const DEFAULT_SEARCH_FIELDS = [
  'department',
  'displayName',
  'groupName',
  'name',
  'tagNames',
  'title',
  'patientContextMrn',
  'patientContextFirstName',
  'patientContextLastName',
  'patientContextDisplayName',
].join(',');

export const DEFAULT_CONTACTS_SEARCH_FIELDS = ['department', 'displayName', 'title'].join(',');

export const DEFAULT_PATIENT_SEARCH_FIELDS = ['displayName', 'name', 'patient_mrn'].join(',');

export const prepareAllEntries = (entries, { organizationId }) =>
  entries.map((entity) => {
    return {
      value: entity?.id,
      label: entity?.displayName,
      entity,
      organizationId,
    };
  });

export const filterSearchResults = (
  excludeIds = [],
  {
    excludeBroadcasts = false,
    excludeIntraTeams = false,
    excludeRoles = false,
    excludeTeams = false,
  } = {}
) => {
  return (entity) => {
    if (!entity) return false;
    const { $entityType: entityType, groupType } = entity;

    if (entityType === 'distributionList' && excludeBroadcasts) return false;
    if (entityType === 'group' && groupType === 'INTRA_TEAM' && excludeIntraTeams) return false;
    if (entityType === 'group' && groupType === 'ROLE_P2P') return false;
    if (entityType === 'role' && excludeRoles) return false;
    if (entityType === 'team' && excludeTeams) return false;
    if (excludeIds.includes(entity.id)) return false;

    return true;
  };
};

const rankOfEntity = (entity, { organizationId, searchTerms }, isPatientSearch = false) => {
  const {
    $entityType: entityType,
    displayName = '',
    firstName = '',
    lastName = '',
    profileByOrganizationId = {},
    roleTag,
    patient = {},
  } = entity;
  let toSearch;
  if (isPatientSearch) {
    const { mrn = '' } = patient;
    toSearch = [
      displayName.toLowerCase(),
      firstName.toLowerCase(),
      lastName.toLowerCase(),
      mrn.toLowerCase(),
    ];
  } else {
    const tagName = (roleTag && roleTag.displayName) || '';
    toSearch = [
      displayName.toLowerCase(),
      firstName.toLowerCase(),
      lastName.toLowerCase(),
      tagName.toLowerCase(),
    ];

    if (entityType === 'user') {
      const profile = profileByOrganizationId[organizationId];
      if (profile) {
        const { department = '', title = '' } = profile;
        toSearch.push(department.toLowerCase());
        toSearch.push(title.toLowerCase());
      }
    }
  }

  let lastIndex = -1;

  for (const searchTerm of searchTerms) {
    const splitOnCommas = searchTerm.split(',');

    for (let word of splitOnCommas) {
      word = word.trim();

      for (const name of toSearch) {
        const index = name.indexOf(word);

        if (index !== -1 && (lastIndex === -1 || index < lastIndex)) {
          lastIndex = index;
        }
      }
    }
  }

  return lastIndex;
};

const processAllEntries = (
  searchResults,
  {
    includePatientContacts = true,
    input,
    isPatientSearch = false,
    organizationId,
    relationOptions,
    sortResults = false,
    isNewQuery,
  }
) => {
  const searchTerms = input.split(',').map((word) => word.trim().toLowerCase());
  const occurrences = [];

  searchResults.forEach((searchResult) => {
    const { entity, label } = searchResult;
    const rank = rankOfEntity(entity, { organizationId, searchTerms }, isPatientSearch);

    if (entity.rank !== -1) {
      occurrences.push({
        label,
        rank,
        searchResult,
        entityType: entity.$entityType,
      });
    }
  });

  let sortedOccurrencesWithRank = occurrences;

  if (sortResults) {
    sortedOccurrencesWithRank = occurrences.sort((a, b) => {
      if (a.rank < b.rank) return -1;
      if (a.rank > b.rank) return 1;
      if (a.entityType === 'user' && b.entityType !== 'user') return -1;
      if (a.entityType !== 'user' && b.entityType === 'user') return 1;
      if (a.entityType === 'team' && b.entityType !== 'team') return -1;
      if (a.entityType !== 'team' && b.entityType === 'team') return 1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  }

  const sortedOccurrences = sortedOccurrencesWithRank.map(({ searchResult }) => searchResult);

  if (!isPatientSearch || !includePatientContacts) {
    return { results: sortedOccurrences, isNewQuery };
  }

  let patientResults = [];

  if (relationOptions && relationOptions.length > 0) {
    patientResults = relationOptions.concat(patientResults);
  }

  sortedOccurrences.forEach((occurrence) => {
    if (occurrence.entity.patient && occurrence.entity.patient.smsOptedOut) {
      occurrence.disabled = true;
    }

    patientResults.push(occurrence);

    if (
      occurrence.entity.patient &&
      occurrence.entity.patient.contacts &&
      occurrence.entity.patient.contacts.length > 0
    ) {
      occurrence.entity.patient.contacts.forEach((patientContact) => {
        const { user: entity } = patientContact;
        const { displayName, id } = entity;

        patientResults.push({
          disabled: entity.patientContact.smsOptedOut,
          entity,
          label: displayName,
          organizationId: occurrence.organizationId,
          value: id,
        });
      });
    }
  });

  return { results: patientResults, isNewQuery };
};

export const processSearchResults = (
  searchResults = [],
  {
    input,
    organizationId,
    isPatientSearch = false,
    relationOptions,
    sortResults = false,
    isNewQuery,
  }
) => {
  const processed = prepareAllEntries(searchResults, { organizationId });

  return processAllEntries(processed, {
    input,
    isPatientSearch,
    organizationId,
    relationOptions,
    sortResults,
    isNewQuery,
  });
};
