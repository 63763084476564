import EventEmitter from 'events';
import { action, runInAction } from 'mobx';
import queue from 'emitter-queue';

export default class GroupStore {
  events = queue(new EventEmitter());

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('GroupStore.createGroup') createGroup = async (options) => {
    const { conversationStore } = this.stores;
    const { isPublic } = options;
    let sdkGroup;

    if (isPublic) {
      sdkGroup = await this.client.forums.create(options);
    } else {
      sdkGroup = await this.client.groups.create(options);
    }

    if (sdkGroup.conversation) {
      this.entityStore.syncOne(sdkGroup.conversation);
    }
    const group = this.entityStore.syncOne(sdkGroup);

    this.events.emit('create', { group });
    conversationStore.selectConversationWithEntity({ group });

    return group;
  };

  @action('GroupStore.findAll') findAll = async (options) => {
    const groups = await this.client.groups.findAll(options);
    return this.entityStore.sync(groups);
  };

  @action('GroupStore.findGroup') findGroup = async (groupId) => {
    const group = await this.client.groups.find(groupId);
    return this.entityStore.syncOne(group);
  };

  // TODO: Can remove once OASIS-747 is completed
  @action('GroupStore.ensureMembersWithIds') ensureMembersWithIds = async (groupId, memberIds) => {
    await this.client.groups.ensureMembersWithIds(groupId, memberIds);
  };

  @action('GroupStore.ensureForumMembers') ensureForumMembers = async (group) => {
    await this.client.groups.ensureForumMembers(group);
  };

  @action('GroupStore.findGroupsWithSpecificMembers')
  findGroupsWithSpecificMembers = async (members, organizationId) => {
    const groups = await this.client.groups.findAllWithSpecificMembers(members, organizationId);
    return this.entityStore.sync(groups);
  };

  @action('GroupStore.addMember') addMember = async (groupId, memberId) => {
    await this.client.groups.addMember(groupId, memberId);
    this.events.emit('addMember', { groupId, memberId });
  };

  @action('GroupStore.addMembers') addMembers = async (groupId, memberIds) => {
    const { modalStore } = this.stores;

    try {
      await this.client.groups.addMembers(groupId, memberIds);
      this.events.emit('addMembers', { groupId, memberIds });
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
      this.events.emit('saveMembersFailed');
    }
  };

  @action('GroupStore.removeMember') removeMember = async (groupId, memberId) => {
    await this.client.groups.removeMember(groupId, memberId);
  };

  @action('GroupStore.removeMembers') removeMembers = async (groupId, memberIds) => {
    const { modalStore } = this.stores;

    try {
      await this.client.groups.removeMembers(groupId, memberIds);
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
      this.events.emit('saveMembersFailed');
    }
  };

  @action('GroupStore.refreshForums') refreshForums = async () => {
    try {
      const forums = await this.client.forums.findAll({ bypassCache: true });

      for (const forum of forums) {
        const { conversationId, id, organizationId } = forum;
        this.stores.conversationStore.findOrCreateConversationWithListEntity(
          'group',
          id,
          organizationId,
          {
            conversationId,
            shouldDisplay: true,
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action('GroupStore.joinForum') joinForum = async (groupId) => {
    await this.client.forums.join(groupId);
  };

  @action('GroupStore.promptToJoinForum')
  promptToJoinForum = async ({ id: forumId, conversationId }) => {
    const { conversationStore, messengerStore, modalStore } = this.stores;
    const { currentOrganizationId } = messengerStore;

    try {
      const conversation = await conversationStore.findOrCreateConversationWithListEntity(
        'group',
        forumId,
        currentOrganizationId,
        {
          conversationId,
          shouldDisplay: false,
        }
      );
      runInAction(() => {
        modalStore.openModal('joinForum', {
          conversation,
          forum: conversation.counterParty,
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  @action('GroupStore.leaveGroup') leaveGroup = async (group) => {
    await this.client.groups.leave(group.id);
    this.events.emit('leave');
    this.stores.composeMessageStore.stopComposing();
    this.stores.conversationStore.setCurrentConversationId(null);
  };

  @action('GroupStore.updateGroup') updateGroup = async (
    groupId,
    { avatarFile, memberIds, name, preserve, replayHistory }
  ) => {
    const preserveValue = this.stores.messengerStore.isConversationDoNotExpireEnabled && preserve;

    await this.client.groups.update(groupId, {
      avatarFile,
      memberIds,
      name,
      preserve: preserveValue,
      replayHistory,
    });
  };
}
