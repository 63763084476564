import * as errors from '../errors';
import BaseService from './BaseService';

export default class PatientContextService extends BaseService {
  async searchAllPatients({
    orgId,
    query = '',
    from,
    pageSize,
  }: {
    orgId: string;
    query?: string;
    from: number;
    pageSize: number;
  }) {
    if (!orgId) throw new errors.ValidationError('orgId', 'required');

    const res = await this.host.api.patientContext.searchAllPatients({
      orgId,
      query,
      from,
      pageSize,
    });
    return res;
  }

  async searchMyPatients({
    orgId,
    pageSize,
    continuationValue,
  }: {
    orgId: string;
    pageSize: number;
    continuationValue?: string;
  }) {
    if (!orgId) throw new errors.ValidationError('orgId', 'required');

    const res = await this.host.api.patientContext.searchMyPatients({
      orgId,
      pageSize,
      continuationValue,
    });

    return res;
  }

  async getPatient({ orgId, patientContextId }: { orgId: string; patientContextId: string }) {
    if (!orgId) throw new errors.ValidationError('orgId', 'required');
    if (!patientContextId) throw new errors.ValidationError('patientContextId', 'required');

    const res = await this.host.api.patientContext.getPatient({
      orgId,
      patientContextId,
    });

    return res;
  }

  async getPatientCareTeamMembers({
    continuationValue,
    orgId,
    pageSize,
    patientContextId,
    tcAccountOnly,
  }: {
    continuationValue?: string;
    orgId: string;
    pageSize?: number;
    patientContextId: string;
    tcAccountOnly: number;
  }) {
    if (!orgId) throw new errors.ValidationError('orgId', 'required');
    if (!patientContextId) throw new errors.ValidationError('patientContextId', 'required');

    const res = await this.host.api.patientContext.getPatientCareTeamMembers({
      continuationValue,
      orgId,
      pageSize,
      patientContextId,
      tcAccountOnly,
    });

    return res;
  }
}
