import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { AdminStateSlice, Organization, User, UserData } from '../../types';
import * as adminThunks from './thunk';
import { OrgSettingsTab } from './../../pages/OrgSettings/Tabs';

const initialState: AdminStateSlice = {
  isInitiated: false,
  organizations: {},
  permissions: [],
  selectedPage: 'Users',
  selectedOrganizationId: '',
  selectedUsersPageView: 'users',
  selectedForumsPageView: 'forums',
  selectedUser: {} as User,
  selectedOrganization: {} as Organization,
  toastSettings: {
    duration: 3000,
    onClose: () => {},
    open: false,
    message: '',
    type: 'SUCCESS',
  },
  customerSupportSearchOrganizationData: [],
  customerSupportSearchAccountData: null,
  customerSupportSearchParseAccountData: {} as UserData,
  customerSupportSearchSearchInput: '',
  viewToShow: '',
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setPermissions: (
      state,
      action: PayloadAction<{
        permissions: AdminStateSlice['permissions'];
      }>
    ) => {
      state.permissions = [...action.payload.permissions];
    },
    setAdminIsInitiated: (state) => {
      state.isInitiated = true;
    },
    setSelectedPage: (
      state,
      action: PayloadAction<{
        selectedPage: AdminStateSlice['selectedPage'];
      }>
    ) => {
      state.selectedPage = action.payload.selectedPage;
    },
    setViewToShow: (
      state,
      action: PayloadAction<{
        viewToShow: OrgSettingsTab | '';
      }>
    ) => {
      state.viewToShow = action.payload.viewToShow;
    },
    setOrganizations: (
      state,
      action: PayloadAction<{
        organizations: Organization[];
      }>
    ) => {
      action.payload.organizations.forEach((org) => (state.organizations[org.token] = org));
    },
    setSelectedOrganizationId: (
      state,
      action: PayloadAction<{
        selectedOrganizationId: string;
      }>
    ) => {
      const org = state.organizations[action.payload.selectedOrganizationId];
      if (!org) throw new Error('Org data not loaded.');

      state.selectedOrganization = org;
      state.selectedOrganizationId = org.token;
    },
    setOrgSettingsTabSaveFunc: (state, action: PayloadAction<() => Promise<void> | void>) => {
      state.orgSettingsTabSaveFunc = action.payload;
    },
    appendOrganization: (
      state,
      action: PayloadAction<{
        organization: Organization;
      }>
    ) => {
      state.organizations[action.payload.organization.token] = action.payload.organization;
    },
    removeOrganization: (
      state,
      action: PayloadAction<{
        organizationId: string;
      }>
    ) => {
      const orgId = action.payload.organizationId;

      delete state.organizations[orgId];

      if (orgId === state.selectedOrganizationId) {
        state.selectedOrganizationId = Object.keys(state.organizations)[0];
        state.selectedPage = 'Users';
      }
    },
    setToastSettings: (state, action: PayloadAction<Partial<AdminStateSlice['toastSettings']>>) => {
      Object.assign(state.toastSettings, action.payload);
    },
    // Users
    setUsersPageView: (
      state,
      action: PayloadAction<{
        selectedUsersPageView: AdminStateSlice['selectedUsersPageView'];
      }>
    ) => {
      state.selectedUsersPageView = action.payload.selectedUsersPageView;
    },
    viewUserPage: (state, action: PayloadAction<AdminStateSlice['selectedUser']>) => {
      state.selectedUser = cloneDeep(action.payload);
      state.selectedUsersPageView = 'user';
    },
    customerSupportSearchOrganizationData: (
      state,
      action: PayloadAction<AdminStateSlice['customerSupportSearchOrganizationData']>
    ) => {
      state.customerSupportSearchOrganizationData = action.payload;
    },
    customerSupportSearchAccountData: (
      state,
      action: PayloadAction<AdminStateSlice['customerSupportSearchAccountData']>
    ) => {
      state.customerSupportSearchAccountData = action.payload;
    },
    customerSupportSearchSearchInput: (
      state,
      action: PayloadAction<AdminStateSlice['customerSupportSearchSearchInput']>
    ) => {
      state.customerSupportSearchSearchInput = action.payload;
    },
    customerSupportSearchParseAccountData: (
      state,
      action: PayloadAction<AdminStateSlice['customerSupportSearchParseAccountData']>
    ) => {
      state.customerSupportSearchParseAccountData = action.payload;
    },
    setForumsPageView: (
      state,
      action: PayloadAction<{
        selectedForumsPageView: AdminStateSlice['selectedForumsPageView'];
      }>
    ) => {
      state.selectedForumsPageView = action.payload.selectedForumsPageView;
    },
  },
});

export { adminSlice, adminThunks };
