import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import AttachmentIcon from '../../AttachmentIcon';
import DotsIndicator from '../../DotsIndicator';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';

const classes = BEM.with('TemplateForm');

function DownloadAttachment({ attachment, downloadFile }) {
  return (
    <div className={classes('attachment-container')} onClick={downloadFile}>
      {attachment && (
        <AttachmentIcon fileType={attachment.type} className={classes('attachment-symbol')} />
      )}
      <span className={classes('attachment-file-name')}>{attachment && attachment.name}</span>
    </div>
  );
}

const MessageTemplateView = ({ downloadTemplateFile, id, loadTemplate, searchTemplates }) => {
  const [attachment, setAttachment] = useState(null);
  const [body, setBody] = useState('');
  const [fetchingTemplate, setFetchingTemplate] = useState(false);
  const [title, setTitle] = useState('');

  const fetchTemplate = useCallback(async () => {
    if (!id) return;

    setFetchingTemplate(true);
    const template = await loadTemplate(id);

    if (template.hasAttachment) {
      setAttachment({
        name: template.attachmentName,
        type: template.attachmentType,
      });
    } else {
      setAttachment(null);
    }

    setBody(template.body);
    setTitle(template.title);
    setFetchingTemplate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function downloadFile() {
    downloadTemplateFile({ templateId: id, fileName: attachment.name });
  }

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  return (
    <div className={classes()}>
      <div className={classes('form')}>
        <div className={classes('breadcrumbs')}>
          <span className={classes('parent')} onClick={searchTemplates}>
            Message Templates
          </span>
          &gt; <span className={classes('child')}>{title}</span>
        </div>
        <div className={classes('wrapper')}>
          {fetchingTemplate && <DotsIndicator color={'#969696'} size={13} />}
          {!fetchingTemplate && (
            <div className={classes('viewTemplate')}>
              <div className={classes('title')}>{title}</div>
              <pre className={classes('body')}>{body}</pre>
              <div
                className={classes('formGroup', {
                  attachmentPreview: true,
                  shouldHide: !attachment,
                })}
              >
                <ToolTip
                  text="Download File"
                  className={classes('attachment-tooltip')}
                  isPatientFacing
                >
                  <DownloadAttachment downloadFile={downloadFile} attachment={attachment} />
                </ToolTip>
              </div>
            </div>
          )}
        </div>
        <div className={classes('footer')}>
          <button
            className={classes('submit')}
            data-test-id={'template-ok'}
            onClick={searchTemplates}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

MessageTemplateView.propTypes = {
  downloadTemplateFile: PropTypes.func.isRequired,
  id: PropTypes.string,
  loadTemplate: PropTypes.func.isRequired,
  repository: PropTypes.string.isRequired,
  searchTemplates: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  patientAdminStore: ['createTemplate', 'deleteTemplate', 'loadTemplate', 'updateTemplate'],
  scheduleMessageStore: ['downloadTemplateFile'],
})(MessageTemplateView);
