// @ts-nocheck
export default function (store, options) {
  const Tag = store.defineModel('tag', {
    replaceKeys: {
      tagColor: 'color',
      tagName: 'name',
    },

    relations: {
      belongsTo: {
        organization: { type: 'organization', foreignKey: 'organizationId' },
      },
      hasMany: {
        roles: { type: 'role', foreignKey: 'tagId' },
        teams: { type: 'team', foreignKey: 'tagId' },
      },
    },

    parseAttrs(attrs) {
      if ('colorValue' in attrs) {
        const colorId = attrs.color || attrs.colorId;
        const color = attrs.colorValue;
        attrs.color = color;
        attrs.colorId = colorId;
        delete attrs.colorValue;
      }

      attrs.roleCount = attrs.roleCount || 0;
      attrs.teamCount = attrs.teamCount || 0;

      if ('color' in attrs) attrs.color = attrs.color.replace('0x', '#');

      return attrs;
    },

    afterAssignment(entity) {
      const { name } = entity;

      entity.displayName = name;
      entity.isDefault = name && name.startsWith('Default');
    },
  });

  return Tag;
}
