export default function overloadEvent(component, eventName, fn) {
  if (component.props[eventName]) {
    const oldFn = component.props[eventName];
    return (e) => {
      oldFn(e);
      // if (!e.isPropagationStopped())
      fn(e);
    };
  } else {
    return fn;
  }
}
