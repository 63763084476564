import { ServerValueEnum } from '../../utils/enum';

const SearchType = new ServerValueEnum({
  CARE_TEAM: { serverValue: 'care_team' },
  DISTRIBUTION_LIST: { serverValue: 'distribution_list' },
  DISTRIBUTION_LIST_MEMBER: { serverValue: 'dist_list_member' },
  DISTRIBUTION_LIST_SHARED: { serverValue: 'dist_list_shared' },
  FORUM: { serverValue: 'public_group' },
  GROUP: { serverValue: 'group' },
  MESSAGE: { serverValue: 'message' },
  MESSAGE_TEMPLATE: { serverValue: 'message_template' },
  ORGANIZATION: { serverValue: 'organization' },
  PATIENT_DISTRIBUTION_LIST: { serverValue: 'patient_distribution_list' },
  PATIENT: { serverValue: 'patient_account' },
  SCHEDULED_MESSAGE: { serverValue: 'schedule_message' },
  TAG: { serverValue: 'tag' },
  TEAM: { serverValue: 'team' },
  USER: { serverValue: 'account' },
  INDIVIDUAL: { serverValue: 'individual' },
  ROLE: { serverValue: 'role' },
} as const);

export const SearchTypeFromServer = {
  team: 'team',
  role: 'role',
  individual: 'user',
  group: 'group',
  public_group: 'forum',
  care_team: 'careTeam',
  distribution_list: 'distributionList',
  patient_account: 'user',
  patient_distribution_list: 'distributionList',
};

export default SearchType;
