// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import _ from 'lodash';
import { Camelizer, formatSavedEvent } from '../utils';
import * as errors from '../errors';
import GroupType from '../models/enums/GroupType';
import { RoleProps } from '../types/Roles';
import { readFile } from '../network/HttpClient';
import BaseService from './BaseService';

const RESOLVABLE_MODELS = ['role', 'user'];

type ReadFile = { fieldValue: string; mimetype: string; filename: string };

enum RoleActionType {
  role_opt_in,
  role_opt_out,
}

const ROLE_ACTION_TYPES = {
  out: 'role_opt_out',
  in: 'role_opt_in',
};

export default class RolesService extends BaseService {
  mounted() {
    this._previouslyInRoles = {};
    this._roleConversations = {};
    this._roleMessagesToReload = {};
    this._roleP2PConversations = {};
    this.host.models.Conversation.on('afterInject', this._onChangeConversation);
    this.host.models.Conversation.on('afterEject', this._onRemoveConversation);
  }

  dispose() {
    this._previouslyInRoles = {};
    this._roleConversations = {};
    this._roleMessagesToReload = {};
    this._roleP2PConversations = {};
    this.host.models.Conversation.removeListener('afterInject', this._onChangeConversation);
    this.host.models.Conversation.removeListener('afterEject', this._onRemoveConversation);
  }

  /**
   * Create a group
   * @param  {Object} options.metadata - Optional metadata
   * @param  {string} options.name - Group name
   * @param  {string} options.organizationId - Organization ID
   * @param  {string} options.recipientId - The other user or role who is part of this group
   * @param  {Boolean} options.replayHistory - Replay History
   * @param  {string} options.senderId - ID of role or user who is creating the group
   * @return {Promise.<Group,Error>} - A promise with a group
   */
  async createP2PGroup({
    metadata: metadataToSet,
    name,
    organizationId,
    recipientId,
    replayHistory = true,
    senderId = this.host.currentUserId,
  }) {
    this.host.requireUser();
    if (!recipientId) throw new errors.ValidationError('recipientId', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    const { currentUserId } = this.host;

    const initialUserIds = [senderId, recipientId];
    const memberIds = [];
    for (let userId of initialUserIds) {
      userId = this.host.roles.__resolveRoleId(userId);
      userId = this._resolveModelIdWithTypes(userId, 'user');
      if (!memberIds.includes(userId)) {
        memberIds.push(userId);
      }
    }

    if (memberIds.length !== 2) {
      throw new errors.ValidationError('recipientId', 'same user as senderId');
    }

    const users = await Promise.all(
      memberIds.map((userId) => this.host.users.find(userId, { organizationId }))
    );

    if (!name) {
      name =
        users
          .map((u) => (u.isRoleBot ? u.displayName : u.firstName))
          .filter(Boolean)
          .join(', ') || 'Group';
    }

    const response = await this.host.api.roles.createP2PGroup({
      createdByUserId: senderId,
      memberIds,
      name,
      organizationId,
      replayHistory,
    });

    let conversationId, entityAttrs;

    if (this.config.condensedReplays) {
      entityAttrs = response.entity || response;
      conversationId = response.conversation_id;
    } else {
      entityAttrs = response;
    }

    for (const { botRole, isRoleBot } of users) {
      if (!isRoleBot || !botRole) continue;

      const { memberIds: botMemberIds } = botRole;
      if (!botMemberIds) continue;

      for (const memberId of botMemberIds) {
        if (!memberIds.includes(memberId)) {
          memberIds.push(memberId);
        }
      }
    }

    const groupId = entityAttrs.id || entityAttrs.token;
    let group = this.host.models.Group.get(groupId);

    let checkMemberIds;
    if (group && group.memberIds) {
      checkMemberIds = group.memberIds;
    } else {
      entityAttrs.members = memberIds;
      checkMemberIds = memberIds;
    }

    group = this.host.conversations.__injectCounterParty({
      conversationId,
      entityAttrs,
      entityType: 'group',
      groupType: GroupType.ROLE_P2P,
      hasCurrentUser: checkMemberIds.includes(currentUserId),
      isPlaceholder: true,
      organizationId,
    });

    if (metadataToSet) {
      await this.host.metadata.update(group.id, metadataToSet, organizationId);
    }

    return group;
  }

  @reusePromise()
  async findAll() {
    let organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) {
      organizations = await this.host.organizations.findAll();
    }
    organizations = organizations.filter((org) => org.rolesEnabled);
    if (organizations.length === 0) return [];

    const { results } = await this.host.search.query({
      version: 'SEARCH_PARITY',
      types: ['individual'],
      organizationIds: organizations.map((org) => org.id),
      query: {
        displayName: '',
        featureService: 'role',
      },
      followContinuations: true,
    });

    const roles = results.map(({ entity }) => entity);
    organizations.map(({ id }) => this.host.models.Organization.touch(id));

    return roles;
  }

  @reusePromise()
  async findByOrganizationId(organizationId: string | Object) {
    organizationId = this._resolveModelId(organizationId);
    const organization = await this.host.organizations.find(organizationId);
    if (!organization.rolesEnabled) return [];

    const { results } = await this.host.search.query({
      version: 'LEGACY',
      types: ['user'],
      organizationIds: organization.id,
      query: {
        displayName: '',
        featureService: 'role',
      },
      followContinuations: true,
    });

    const roles = results.map(({ entity }) => entity);
    this.host.models.Organization.touch(organizationId);

    return roles;
  }

  @reusePromise()
  async find(id: string, organizationId: string | Object, options: Object = {}) {
    this.host.requireUser();
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: false });
    id = this.__resolveRoleId(id);
    organizationId = this._resolveModelId(organizationId);

    if (!options.bypassCache) {
      const role = this.getById(id);
      if (role && !role.botUser?.$placeholder) return role;
    }

    const metadata = await this.host.api.roles.find(id, organizationId);
    if (!metadata) {
      if (options.ignoreNotFound) return;
      throw new errors.NotFoundError(this.host.models.Role.name, id);
    }

    const role = this.__injectSearchResult({
      entity: { id, displayName: metadata.display_name },
      metadata,
      organizationId,
    });
    this.host.models.Organization.touch(organizationId);

    return role;
  }

  async findIntegrationIds(organizationId: string) {
    organizationId = this._resolveModelId(organizationId);
    const integrations = await this.host.api.scheduler.findIntegrationIds(organizationId);
    return integrations;
  }

  async findRolesSchedulerCsv(organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    const integrations = await this.findIntegrationIds(organizationId);
    const integrationId = integrations.length && integrations[0].id;
    if (!integrationId) throw new Error('No Integration ID found');
    const response = await this.host.api.scheduler.findCsv(organizationId, integrationId);
    return response;
  }

  async clearRolesScheduler(organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    const integrations = await this.findIntegrationIds(organizationId);
    const integrationId = integrations.length && integrations[0].id;
    if (!integrationId) throw new Error('No Integration ID found');
    const response = await this.host.api.scheduler.clearSchedule(organizationId, integrationId);
    return response;
  }

  async uploadRolesSchedule({
    file,
    integrationId,
    organizationId,
    timezone,
  }: {
    file: string;
    integrationId: string;
    organizationId: string;
    timezone: string;
  }) {
    const fileData: ReadFile = await readFile(file);
    const output = await this.host.api.scheduler.sendCsvSchedule(
      integrationId,
      fileData.fieldValue,
      organizationId,
      timezone
    );
    return output;
  }

  @reusePromise()
  async saveRole(roleId: string, organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);
    roleId = this.__resolveRoleId(roleId);

    await this.host.api.roles.saveRole(roleId, this.host.currentUserId, organizationId);
  }

  @reusePromise()
  async removeSavedRole(roleId: string, organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);
    roleId = this.__resolveRoleId(roleId);

    await this.host.api.roles.removeSavedRole(roleId, this.host.currentUserId, organizationId);
  }

  @reusePromise()
  async findSavedRoles(organizationId: string, options: Object = {}) {
    options = _.defaults(options, { ignoreNotFound: false });
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    const results = await this.host.api.roles.findSavedRoles(
      this.host.currentUserId,
      organizationId
    );
    if (!results) {
      if (options.ignoreNotFound) return;
      throw new errors.NotFoundError('saved roles', organizationId);
    }

    const roles = results
      .map((metadata) =>
        this.__injectSearchResult({
          entity: { id: metadata.token, displayName: metadata.display_name },
          metadata,
          organizationId,
        })
      )
      .filter(Boolean);
    this.host.models.Organization.touch(organizationId);

    return roles;
  }

  async create(newRole: RoleProps) {
    const { organizationId } = newRole;
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const res = await this.host.api.roles.createRole({
      ...newRole,
      tagToken: newRole.tagId,
    });

    const role = this.__injectRoleFromMetadata({
      metadata: {
        ...Camelizer.underscoreObject(newRole),
        display_name: newRole.name,
        token: res.token,
      },
      organizationId,
    });

    const { data } = this.host.metadata.get(res.token, organizationId);
    return { ...role, metadata: data };
  }

  async update(
    id: string,
    {
      description,
      doesRoleTransitionExcludePrivateGroups,
      escalationPolicy,
      isRoleTransitionEnabled,
      name,
      noOwnersMessage,
      openAssignment,
      organizationId,
      ownerRequired,
      owners,
      replayHistory,
      tagColor,
      tagName,
      tagId,
    }: {
      description?: string | null | undefined;
      doesRoleTransitionExcludePrivateGroups?: boolean | null | undefined;
      escalationPolicy?: Object | null | undefined;
      isRoleTransitionEnabled?: boolean | null | undefined;
      name?: string | null | undefined;
      noOwnersMessage?: string;
      openAssignment?: boolean | null | undefined;
      organizationId: string;
      ownerRequired?: boolean | null | undefined;
      owners?: Array<string> | null | undefined;
      replayHistory?: string | null | undefined;
      tagColor?: string | null | undefined;
      tagName?: string | null | undefined;
      tagId?: string | null | undefined;
    }
  ) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!id) throw new errors.ValidationError('id', 'required');
    const res = await this.host.api.roles.updateRole(id, {
      description,
      doesRoleTransitionExcludePrivateGroups,
      escalationPolicy,
      isRoleTransitionEnabled,
      name,
      noOwnersMessage,
      openAssignment,
      organizationId,
      ownerRequired,
      owners,
      replayHistory,
      tagColor,
      tagName,
      tagToken: tagId,
    });

    return res.token;
  }

  async delete(id: string, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!id) throw new errors.ValidationError('id', 'required');
    const res = await this.host.api.roles.deleteRole(organizationId, id);
    return res;
  }

  async optIn(roleId: string, organizationId: string, userId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roleId) throw new errors.ValidationError('roleId', 'required');
    if (!userId) throw new errors.ValidationError('userId', 'required');

    const res = await this.host.api.roles.optIn(roleId, userId, organizationId);
    this.host.models.Role.inject({ id: `role:${roleId}`, memberIds: [userId] });
    return res;
  }

  async optOut(roleId: string, organizationId: string, userId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roleId) throw new errors.ValidationError('roleId', 'required');
    if (!userId) throw new errors.ValidationError('userId', 'required');

    const res = await this.host.api.roles.optOut(roleId, userId, organizationId);
    this.host.models.Role.inject({ id: `role:${roleId}`, memberIds: [] });
    return res;
  }

  async saveAwayResponse(roleId: string, organizationId: string, status: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roleId) throw new errors.ValidationError('roleId', 'required');
    if (!status) throw new errors.ValidationError('status', 'required');

    const res = await this.host.api.roles.saveAwayResponse(roleId, organizationId, status);
    return res;
  }

  async removeAwayResponse(roleId: string, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roleId) throw new errors.ValidationError('roleId', 'required');

    const res = await this.host.api.roles.removeAwayResponse(roleId, organizationId);
    return res;
  }

  reactToSavedEvent(data) {
    this.emit('saved', formatSavedEvent(data));
  }

  reactToUpdateEventCR({ entity }) {
    let { display_name, metadata, organizationId, type } = entity;

    let roleId;

    if (type === 'group') {
      roleId = metadata.id;
    } else {
      roleId = entity.id;
    }

    metadata = { ...metadata, display_name, token: this.__resolveRoleId(roleId) };

    return this.__injectRoleFromMetadata({ metadata, organizationId });
  }

  reactToUpdateEvent({ data }) {
    let { display_name, metadata, organization_key: organizationId, type } = data;

    let roleId;

    if (type === 'tigertext:entity:group') {
      roleId = metadata.id;
    } else {
      roleId = data.token;
    }

    metadata = { ...metadata, display_name, token: this.__resolveRoleId(roleId) };

    return this.__injectRoleFromMetadata({ metadata, organizationId });
  }

  __injectSearchResult = ({ entity, metadata = {}, organizationId }) => {
    metadata = {
      ...metadata,
      display_name: entity.displayName,
      org_pagers: entity.orgPagers,
      accountStatus: entity.accountStatus,
      token: this.__resolveRoleId(entity.id),
    };
    return this.__injectRoleFromMetadata({ metadata, organizationId });
  };

  __injectRoleAssignmentEntity = (display_name, metadata, organizationId) => {
    const role = this.__injectRoleFromMetadata({
      metadata: {
        display_name,
        meta_type: metadata['meta_type'],
        owners: metadata['owner_id'],
        tag_color: metadata['tag_color'],
        tag_id: metadata['tag_id'],
        tag_name: metadata['tag_name'],
        token: metadata['id'],
      },
      organizationId,
    });

    return role;
  };

  __injectRoleFromMetadata({ metadata = {}, organizationId, replaceExisting = true }) {
    const { escalation_policy: rawPolicy, token: id } = metadata;
    if (!id) return null;

    const roleId = `role:${id}`;
    if (!replaceExisting) {
      const role = this.host.models.Role.get(roleId);
      if (role) return role;
    }

    const organization = this.host.models.Organization.get(organizationId);

    this.host.metadata.__injectMetadata(id, organizationId, {
      feature_service: 'role',
      ...metadata,
    });
    this.host.models.User.injectPlaceholder({
      id,
      organizationId,
      displayName: metadata.display_name,
    });

    const injectArgs = {
      botUserId: id,
      displayName: metadata.display_name,
      orgPagers: metadata.org_pagers,
      accountStatus: metadata.accountStatus,
      id: roleId,
      organizationId,
      isRoleTransitionEnabled:
        organization?.roleTransition === 'all' || metadata.role_transition === '1',
    };

    if (metadata.description) {
      injectArgs.description = metadata.description;
    }

    if (rawPolicy && rawPolicy['escalation_path']) {
      const attrs = JSON.parse(JSON.stringify(rawPolicy));
      attrs.id = `escalationPolicy:${id}`;
      attrs.targetId = id;
      const escalationPolicy = this.host.models.EscalationPolicy.inject(attrs);
      injectArgs.escalationPolicyId = escalationPolicy.id;
    } else {
      injectArgs.escalationPolicyId = null;
    }

    if (metadata['room_assignment_id']) {
      injectArgs.assignmentGroupId = metadata['room_assignment_id'];
    }

    if (metadata.owners) {
      if (typeof metadata.owners === 'string') {
        injectArgs.memberIds = metadata.owners.split(',');
        this.host.models.User.injectPlaceholder({ id: metadata.owners });
      } else {
        injectArgs.memberIds = metadata.owners.map((user) => user.token);
        for (const user of metadata.owners) {
          this.host.models.User.injectPlaceholder(user);
        }
      }
    }

    if (metadata['tag_id']) {
      injectArgs.tagId = `${organizationId}:${metadata['tag_id']}`;
      this.host.models.Tag.inject({
        ..._.pick(metadata, 'tag_id', 'tag_color', 'tag_name'),
        id: injectArgs.tagId,
        organizationId,
      });
    } else if (organization) {
      injectArgs.tagId = null;
    }
    const role = this.host.models.Role.inject(injectArgs);

    return role;
  }

  __parseRoleFromMetadata({ metadata = {}, organizationId }) {
    const { token: id } = metadata;
    if (!id) return null;

    const role = {
      $entityType: 'role',
      botUserId: id,
      displayName: metadata.display_name,
      id: `role:${id}`,
      organizationId,
    };

    let tag = null;

    if (metadata['tag_id']) {
      const tagId = `${organizationId}:${metadata['tag_id']}`;
      tag = {
        id: tagId,
        organizationId,
        color: metadata['tag_color'],
        tagId: metadata['tag_id'],
        name: metadata['tag_name'],
        displayName: metadata['tag_name'],
      };
      if (tag.color) {
        tag.color = tag.color.replace('0x', '#');
      }
    }

    role.tag = tag;

    const existingRole = this.host.models.Role.get(`role:${id}`);
    this.__injectRoleFromMetadata({
      metadata,
      organizationId,
      replaceExisting: !existingRole?.tagId && role.tag,
    });

    return role;
  }

  getAll() {
    return this.host.models.Role.getAll();
  }

  getById(id: string) {
    id = this.__resolveRoleId(id);
    return this.host.models.Role.get(`role:${id}`);
  }

  __resolveRoleId = (roleId: string | Object) => {
    if (typeof roleId !== 'string') {
      if (!RESOLVABLE_MODELS.includes(roleId.$entityType)) return roleId;
      roleId = this._resolveModelId(roleId);
    }
    return roleId.replace('role:', '');
  };

  @reusePromise()
  async refresh(organizationId: string) {
    const roleIds = [];
    for (const [roleId, conversations] of Object.entries(this._roleConversations)) {
      if (conversations[0].organizationId === organizationId) {
        roleIds.push(roleId);
      }
    }

    if (roleIds.length === 0) return;

    const entities = await this.host.entities.findMulti(roleIds, organizationId);

    for (const result of entities) {
      const { entity, metadata } = result;
      if (entity && metadata) {
        if (!metadata.owners) metadata.owners = [];
        this.__injectSearchResult({
          entity: { id: entity.token, displayName: entity.display_name },
          metadata,
          organizationId,
        });
      }
    }
  }

  _onChangeConversation = (resource, conversation) => {
    const { counterParty, counterPartyType } = conversation;
    const hasMembers = counterParty && counterParty.members && counterParty.members.length > 0;
    if (counterPartyType !== 'group' || !hasMembers) return;

    for (const user of counterParty.members) {
      if (user.isRoleBot) {
        const conversations = this._roleConversations[user.id] || [];
        if (!conversations.includes(conversation)) {
          conversations.push(conversation);
        }
        this._roleConversations[user.id] = conversations;

        if (counterParty.groupType === 'ROLE_P2P') {
          const roleP2PConversations = this._roleP2PConversations[user.id] || [];
          if (!roleP2PConversations.includes(conversation)) {
            roleP2PConversations.push(conversation);
          }
          this._roleP2PConversations[user.id] = roleP2PConversations;
        }
      }
    }
  };

  _onRemoveConversation = (resource, conversation) => {
    const { counterParty, counterPartyType } = conversation;
    const hasMembers = counterParty && counterParty.members && counterParty.members.length > 0;
    if (counterPartyType !== 'group' || !hasMembers) return;

    const { memberIds } = counterParty;
    for (const userId of memberIds) {
      const conversations = this._roleConversations[userId];
      const idx = conversations ? conversations.indexOf(conversation) : -1;
      if (idx > -1) {
        conversations.splice(idx, 1);
      }
      if (conversations && conversations.length === 0) {
        delete this._roleConversations[userId];
      }

      if (counterParty.groupType === 'ROLE_P2P') {
        const conversationsP2P = this._roleP2PConversations[userId];
        const idxP2P = conversationsP2P ? conversationsP2P.indexOf(conversation) : -1;
        if (idxP2P > -1) {
          conversationsP2P.splice(idxP2P, 1);
        }
        if (conversationsP2P && conversationsP2P.length === 0) {
          delete this._roleP2PConversations[userId];
        }
      }
      if (
        !this.host.currentUser.roles.find(
          ({ id }) => counterParty?.createdByRole?.botUserId === id
        ) &&
        this._roleP2PConversations[counterParty?.createdByRole?.botUserId]
      ) {
        delete this._roleP2PConversations[counterParty?.createdByRole?.botUserId];
      }
    }
  };

  async reactToFriendsEventCR(event) {
    const { action, attrs } = event;
    const { id, metadata = {}, organizationId } = attrs;
    attrs.action = action;
    attrs.token = id;

    await this.__reactToRoleChange(metadata['id'], organizationId, metadata['triggered_id'], attrs);
  }

  async reactToFriendsEvent(event) {
    const { metadata = {}, organization_id: organizationId, ...attrs } = event.data['friend'];

    await this.__reactToRoleChange(metadata['id'], organizationId, metadata['triggered_id'], attrs);
  }

  _addRoleMessageToReload(message, roleId) {
    roleId = this.__resolveRoleId(roleId);

    if (!this._roleMessagesToReload[roleId]) {
      this._roleMessagesToReload[roleId] = new Set();
    }

    this._roleMessagesToReload[roleId].add(message.id);
  }

  _reloadRoleMessages(roleId) {
    roleId = this.__resolveRoleId(roleId);

    if (this._roleMessagesToReload[roleId]) {
      for (const messageId of this._roleMessagesToReload[roleId].values()) {
        if (this.host.models.Message.get(messageId)) {
          this.host.models.Message.touch(messageId);
        }
      }
    }

    this._roleMessagesToReload[roleId] = null;
  }

  reactToRoleShiftSSE(event: {
    data: {
      event_name: string;
      action_type: RoleActionType;
      organization_id: string;
      role_id: string;
      timestamp: string;
      should_display_notice: boolean;
    };
  }) {
    if (!event?.should_display_notice) return;
    switch (event?.data?.event_name) {
      case 'start': {
        if (event?.data?.action_type === ROLE_ACTION_TYPES.in) {
          this.emit('optin:start', event.data);
        } else if (event?.data?.action_type === ROLE_ACTION_TYPES.out) {
          this.emit('optout:start', event.data);
        }
        return;
      }
      case 'stop': {
        if (event?.data?.action_type === ROLE_ACTION_TYPES.in) {
          this.emit('optin:stop', event.data);
        } else if (event?.data?.action_type === ROLE_ACTION_TYPES.out) {
          this.emit('optout:stop', event.data);
        }
        return;
      }
    }
  }

  @reusePromise()
  async __reactToRoleChange(roleId: string, organizationId: string, triggeredId: string, attrs) {
    organizationId = this._resolveModelId(organizationId);
    roleId = this.__resolveRoleId(roleId);
    triggeredId = this._resolveModelId(triggeredId);

    const { currentUser } = this.host;
    const { action, token } = attrs;
    const inRole = () => currentUser.roles.some((role) => role.botUserId === roleId);
    const previouslyIn = this._previouslyInRoles[roleId] || inRole();

    const { event_status: eventStatus } = attrs;
    const knownRole = this.getById(roleId);
    if (action === 'del') {
      if (eventStatus && eventStatus !== 'end') {
        this._previouslyInRoles[roleId] = previouslyIn;
        return;
      } else {
        delete this._previouslyInRoles[roleId];

        this._reloadRoleMessages(roleId);
        if (knownRole?.organization) {
          this.host.organizations.__reloadConversations(knownRole.organization);
        }
      }
    }

    let [role, triggeredBy] = await Promise.all([
      this.find(roleId, organizationId, { bypassCache: true, ignoreNotFound: true }),
      this.host.users.find(triggeredId, { organizationId, ignoreNotFound: true }),
    ]);
    if (!knownRole && !role) return;

    const isRoleDeleted = action === 'del' && knownRole && !role;

    if (isRoleDeleted) {
      role = knownRole;
      const id = `role:${roleId}`;
      this.host.models.Role.inject({ id, memberIds: [] });
      this.host.models.Role.eject(role);
    }

    const nowIn = inRole();
    if (nowIn && !previouslyIn) {
      this.emit('change', { type: 'OPT_IN', role, target: currentUser, triggeredBy });
    } else if (!nowIn && previouslyIn) {
      this.host.models.Group.eject(token);
      this.host.models.User.touch(this.host.currentUserId);
      this.emit('change', {
        type: 'OPT_OUT',
        isRoleDeleted,
        role,
        target: currentUser,
        triggeredBy,
      });
    }
  }
}
