import querystring from 'querystring';

const adjustQueryParams = (str, { add = [], remove = [] } = {}) => {
  const qs = querystring.parse(str.replace(/\?/g, ''));
  const params = [];

  for (const [key, value] of Object.entries(qs)) {
    if (!remove.includes(key)) {
      params.push(`${key}=${value}`);
    }
  }

  return [...add, ...params].join('&');
};

export default adjustQueryParams;
