import { setPrefix } from './common/bem';

if (!global.__tcWebReactUIToolkitInitialized) {
  // ensure this init code is called only once
  global.__tcWebReactUIToolkitInitialized = true;

  try {
    require(`./initializers/${process.env.NODE_ENV}`);
  } catch (e) {
    // Ignore error
  }

  setPrefix('tc');
}
