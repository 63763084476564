// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class ScheduledMessagesAPI extends BaseAPI {
  async batch({
    ids,
    operation,
    organizationId,
  }: {
    ids: string[];
    operation: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.patch('/v5/schedule_message', {
      data: {
        ids,
        operation,
      },
      headers,
    });
  }

  async create({
    attachment,
    body,
    delivery_method,
    end_date,
    network,
    no_reply,
    organizationId,
    recipients,
    repeating,
    send_now,
    start_date,
    start_time,
    template_id,
    timezone_name,
  }: {
    attachment?: (Object | null | undefined) | (string | null | undefined);
    body: string;
    delivery_method: string;
    end_date?: string | null | undefined;
    network: string;
    no_reply?: boolean | null | undefined;
    organizationId: string;
    recipients: string[];
    repeating?: string | null | undefined;
    send_now?: boolean | null | undefined;
    start_date?: string | null | undefined;
    start_time?: string | null | undefined;
    template_id?: string | null | undefined;
    timezone_name: string;
  }) {
    const files = {};
    const headers = { 'TT-X-Organization-Key': organizationId };

    if (attachment) files['attachment'] = attachment;

    const { data } = await this.httpClient.post('/v5/schedule_message', {
      files,
      data: {
        ...(files['attachment'] ? null : { attachment }),
        body,
        delivery_method,
        ...(repeating ? { end_date } : null),
        network,
        no_reply,
        recipients,
        ...(repeating ? { repeating } : null),
        send_now,
        start_date,
        start_time,
        ...(template_id ? { template_id } : null),
        timezone_name,
      },
      headers,
      timeout: !(attachment || template_id),
    });

    return data;
  }

  @recoverFromNotFound()
  async delete(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.del('/v5/schedule_message/:id', {
      headers,
      urlParams: {
        id,
      },
    });
  }

  @recoverFromNotFound()
  async downloadScheduledMessageFile({ scheduleId, fileName, organizationId }) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const path = `/v5/schedule_message/${scheduleId}/attachment/${fileName}`;
    return this.httpClient.downloadFileBlob(path, { headers });
  }

  @recoverFromNotFound()
  async find({ id, organizationId, isHistoryCategory }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.get(
      `/v5/schedule_message/:id?history=${isHistoryCategory}`,
      {
        headers,
        urlParams: {
          id,
        },
      }
    );

    return data;
  }

  async findCoarseTimezone(timezone, organizationId) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.get('/v5/timezone/:timezone', {
      headers,
      urlParams: {
        timezone,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async update({
    attachment,
    body,
    end_date,
    id,
    organizationId,
    repeating,
    start_date,
    start_time,
    template_id,
    timezone_name,
  }: {
    attachment?:
      | (null | null | undefined)
      | (Object | null | undefined)
      | (string | null | undefined);
    body: string;
    end_date?: string | null | undefined;
    id: string;
    organizationId: string;
    repeating?: string | null | undefined;
    start_date?: string | null | undefined;
    start_time?: string | null | undefined;
    template_id?: string | null | undefined;
    timezone_name: string;
  }) {
    const files = {};
    const headers = { 'TT-X-Organization-Key': organizationId };

    if (attachment) files['attachment'] = attachment;

    const { data } = await this.httpClient.put('/v5/schedule_message/:id', {
      files,
      data: {
        ...(files['attachment'] ? null : { attachment }),
        body,
        ...(repeating ? { end_date } : null),
        ...(repeating ? { repeating } : null),
        start_date,
        start_time,
        ...(template_id ? { template_id } : null),
        timezone_name,
      },
      headers,
      timeout: !(attachment || template_id),
      urlParams: {
        id,
      },
    });

    return data;
  }
}
