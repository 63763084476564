import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import BEM from '../../bem';
import { generateValidTimes, scheduledMessageFormats } from '../../utils';
import { ContextMenu, MenuItemList, MenuItem } from '../ContextMenu';

import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { KEYMAP } from 'common/constants';

const { SERVER_DATE_FORMAT, SERVER_TIME_FORMAT, TIME_FORMAT } = scheduledMessageFormats;
const TIME_INTERVAL = 15;

const classes = BEM.with('TimeInputSelect');
const classesDropdown = BEM.with('TimeInputSelectDropdown');

const TimeInputSelect = ({
  isAppointmentInPast,
  selectedStartDate,
  selectedTime,
  setNextButtonDisabled,
  setSelectedTime,
  timeBuffer = 15,
  timezone,
  accessibilityMode,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [options, setOptions] = useState([]);
  const [timeInput, setTimeInput] = useState('');
  const inputContainerRef = useRef(null);
  const timeInputRef = useRef(null);

  const checkValidTime = useCallback(
    (newTimeString) => {
      const newTime = moment(newTimeString, ['h:m A', 'h:mA'], true);
      if (newTime.isValid()) {
        const serverDate = (selectedStartDate || moment()).format(SERVER_DATE_FORMAT);
        const serverTime = newTime.format(SERVER_TIME_FORMAT);
        const newSelectedTime = moment.tz(`${serverDate}T${serverTime}`, timezone);
        if (moment().isBefore(newSelectedTime)) {
          return newSelectedTime;
        }
      }
      return null;
    },
    [selectedStartDate, timezone]
  );

  const setTime = useCallback(
    (time, updateValue) => {
      setIsInvalid(false);
      setSelectedTime(time);
      setTimeInput(time.format(TIME_FORMAT));
      if (updateValue) timeInputRef.current.value = time.format(TIME_FORMAT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedTime, timeInputRef.current]
  );

  const handleTimeInputBlur = ({ target: { value } }) => {
    setIsFocused(false);
    const newSelectedTime = checkValidTime(value.toUpperCase());
    if (newSelectedTime) {
      setTime(newSelectedTime, true);
    }
  };

  const handleTimeInputChange = ({ target: { value } }) => {
    const newTimeString = value.toUpperCase();
    setTimeInput(newTimeString);
  };

  useEffect(() => {
    setOptions(generateValidTimes(selectedStartDate, TIME_INTERVAL, timezone, timeBuffer));
  }, [selectedStartDate, timeBuffer, timezone]);

  useEffect(() => {
    if (!timeInput) return setIsInvalid(false);

    const newTime = checkValidTime(timeInput);
    if (newTime) {
      setTime(newTime);
    } else {
      setSelectedTime(null);
      setIsInvalid(true);
      setNextButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, timeInput]);

  useEffect(() => {
    if (!isFocused) {
      if (!selectedTime && timeInput) {
        timeInputRef.current.value = '';
        setTimeInput('');
      } else if (selectedTime) {
        const newTime = checkValidTime(selectedTime);
        if (!newTime) {
          setIsInvalid(true);
          setNextButtonDisabled(true);
        } else {
          timeInputRef.current.value = newTime.format(TIME_FORMAT);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  return (
    <div className={classes()}>
      {isInvalid && !isAppointmentInPast && (
        <div className={classes('time-error')}>Invalid time</div>
      )}
      <ContextMenu
        event="click"
        offsetY={6}
        position="bottominnerleft"
        relativeTo={inputContainerRef.current}
        theme="vertical"
        accessibilityMode={timeInput !== '' && isInvalid ? false : accessibilityMode}
        menu={
          <MenuItemList
            className={classesDropdown()}
            accessibilityMode={accessibilityMode}
            selectedOptionIndex={options.findIndex((time) => {
              const formattedTime = time.format(TIME_FORMAT);
              return !!(
                selectedTime &&
                formattedTime === selectedTime.format(TIME_FORMAT) &&
                formattedTime === timeInput
              );
            })}
          >
            {options.map((time) => {
              const formattedTime = time.format(TIME_FORMAT);
              const isSelected = !!(
                selectedTime &&
                formattedTime === selectedTime.format(TIME_FORMAT) &&
                formattedTime === timeInput
              );

              return (
                <MenuItem
                  className={classesDropdown('menu-item', {
                    selected: isSelected,
                  })}
                  onClick={() => {
                    setTime(time, true);
                    if (accessibilityMode) {
                      timeInputRef.current.focus();
                    }
                  }}
                  key={formattedTime}
                  onKeyDown={(e) => {
                    if (e.key === KEYMAP.ESCAPE || e.key === KEYMAP.TAB) {
                      if (accessibilityMode) timeInputRef?.current?.focus();
                    }
                  }}
                >
                  <p>{formattedTime}</p>
                  {isSelected && <CheckSvg className={classesDropdown('check')} />}
                </MenuItem>
              );
            })}
          </MenuItemList>
        }
      >
        <div
          className={classes('time-menu-btn', { isInvalidTime: isInvalid && !isAppointmentInPast })}
          ref={inputContainerRef}
        >
          <input
            defaultValue={selectedTime && selectedTime.tz(timezone)?.format(TIME_FORMAT)}
            onBlur={handleTimeInputBlur}
            onFocus={() => setIsFocused(true)}
            onChange={handleTimeInputChange}
            placeholder="Select Time"
            ref={timeInputRef}
            type="text"
          />
          <DropdownChevronSvg />
        </div>
      </ContextMenu>
    </div>
  );
};

TimeInputSelect.defaultProps = {
  isAppointmentInPast: false,
};

export default TimeInputSelect;
