// @ts-nocheck
export default function taggedStringBase(transformer) {
  return function urlEncodedTaggedString(strings, ...substitutions) {
    let result = strings[0];
    for (let i = 0; i < substitutions.length; ++i) {
      result += transformer(substitutions[i]);
      result += strings[i + 1];
    }
    return result;
  };
}
