import { useState } from 'react';
import BEM from '../bem';
import TCClient from '../../client';
import { PBXLinesList, User } from '../../types';
import Modal from './Modal';

const classes = BEM.with('RemoveRoleOrUserLineModal');

type RemoveRoleOrUserLineModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  options: {
    currentOrganizationId: string;
    lineType: string;
    findUser: (targetId: string, organizationId: string) => User;
    getAllPbxLinesInOrg: ({
      category,
      existingLines,
      pageNumber,
    }: {
      category: string;
      existingLines: PBXLinesList[];
      pageNumber: number;
    }) => void;
    pbxLinesList: PBXLinesList[];
    selectedLines: string[];
    setPbxLinesList: (pbxLinesList: PBXLinesList[]) => void;
    setSelected: (selectedLines: string[]) => void;
  };
};

const RemoveRoleOrUserLineModal = ({
  closeModal,
  isOpen,
  options,
}: RemoveRoleOrUserLineModalProps) => {
  const {
    lineType,
    selectedLines,
    currentOrganizationId,
    pbxLinesList,
    setSelected,
    findUser,
    getAllPbxLinesInOrg,
  } = options;
  const selectedLinesCount = selectedLines.length;
  const [pbxExtensionNumber, setPbxExtensionNumber] = useState<string>();
  const [targetUsers, setTargetUsers] = useState<string[]>();
  const [nurseCallUser, setNurseCallUser] = useState<string>();
  const currentLine = lineType === 'User Lines' || 'Nurse Call Users' ? 'User' : 'Role';
  const isNurseCallLine = lineType === 'Nurse Call Users';

  const findTargetUser = async (id: string) => {
    const userSelected = await findUser(id, currentOrganizationId);
    setNurseCallUser(userSelected.displayName);
  };

  const getSelectedLineDetails = async () => {
    const targetUsersArray: string[] = [];
    if (lineType === 'Nurse Call Users' && selectedLines.length === 1) {
      pbxLinesList.map((line: PBXLinesList) => {
        if (line.id === selectedLines[0] && line.targetId) {
          return findTargetUser(line.targetId);
        } else {
          return null;
        }
      });
    }
    if (selectedLinesCount < 2) {
      pbxLinesList.map((line: PBXLinesList) => {
        if (
          line.id === selectedLines[0] &&
          pbxExtensionNumber !== line.customSettings.extensionNumber &&
          line.targetId
        ) {
          setPbxExtensionNumber(line.customSettings.extensionNumber);
          targetUsersArray.push(line.targetId);
          return setTargetUsers(targetUsersArray);
        } else {
          return null;
        }
      });
    }
  };

  getSelectedLineDetails();

  const unassignSelected = async () => {
    const targetUsersArray: string[] = [];
    const { setSelected, pbxLinesList, setPbxLinesList, selectedLines, lineType } = options;
    if (lineType === 'Nurse Call Users') {
      pbxLinesList.map((line: PBXLinesList) => {
        if (selectedLines.includes(line.id) && line.targetId) {
          return targetUsersArray.push(line.targetId);
        } else {
          return null;
        }
      });
    }
    if (selectedLinesCount > 1) {
      await TCClient.pbx.batchUnAssignPbxLines({
        lineType,
        orgId: currentOrganizationId,
        pbxLineIds: selectedLines,
        targetIds: targetUsersArray,
      });
    } else {
      await TCClient.pbx.unAssignPbxLine({
        lineType,
        orgId: currentOrganizationId,
        pbxLineId: selectedLines[0],
        targetIds: targetUsers,
      });
    }
    const newPbxListContent: PBXLinesList[] = pbxLinesList.map((line) => {
      if (selectedLines.includes(line.id)) return { ...line, targetId: null };
      return line;
    });
    setSelected([]);
    if (!isNurseCallLine) {
      setPbxLinesList([...newPbxListContent]);
    }
    if (isNurseCallLine) {
      getAllPbxLinesInOrg({ category: lineType, existingLines: [], pageNumber: 1 });
    }
    closeModal();
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      header={`${isNurseCallLine ? 'Remove' : 'Unassign'} ${currentLine} ${
        !isNurseCallLine ? (selectedLinesCount > 1 ? 'lines' : 'line') : ''
      }`}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'large'}
      footerPrimaryActions={
        <button className={classes('unassign-btn')} onClick={unassignSelected} type="button">
          {isNurseCallLine ? 'REMOVE' : 'UNASSIGN'}
        </button>
      }
      footerSecondaryActions={
        <button
          type="button"
          onClick={() => {
            closeModal();
            setSelected([]);
          }}
          className={classes('cancel-btn')}
        >
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={closeModal}
    >
      <div>
        {selectedLinesCount > 1
          ? `Are you sure you want to ${
              isNurseCallLine ? 'remove' : 'unassign'
            } the ${selectedLinesCount} ${lineType}?`
          : `Are you sure you want to ${isNurseCallLine ? 'remove' : 'unassign'} ${
              isNurseCallLine ? '' : currentLine
            } ${!isNurseCallLine ? (selectedLinesCount > 1 ? 'lines' : 'line') : ''} ${
              isNurseCallLine ? nurseCallUser : pbxExtensionNumber
            }?`}
      </div>
    </Modal>
  );
};

export default RemoveRoleOrUserLineModal;
