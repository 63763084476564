const getRecipientPickerSearchPlaceholder = ({
  allowPatientBroadcastLists = false,
  isProviderNetwork = true,
} = {}) => {
  let placeholder;
  if (isProviderNetwork) {
    placeholder = 'Type the name of person or group';
  } else {
    placeholder = 'Enter Patient’s name';

    if (allowPatientBroadcastLists) {
      placeholder += ' or Broadcast List';
    }
  }

  return placeholder;
};

const fn = { getRecipientPickerSearchPlaceholder };
export default fn;
