// @ts-nocheck
function hasRole(roles, role) {
  return !!roles && roles.includes(role);
}

export default function (store, options) {
  const ProductRoles = store.defineModel('productRoles', {
    defaultValues: {
      roles: [],
    },

    afterAssignment(entity) {
      const roles = entity.roles;

      entity.isAdmin = hasRole(roles, 'tt_admin');
      entity.isAnalyticsAdmin = hasRole(roles, 'tt_reporting');
      entity.isLdapAdmin = hasRole(roles, 'tt_ldap');
      entity.isSuperAdmin = hasRole(roles, 'tt_super_admin');
    },

    methods: {
      hasRole(role) {
        return !!this.roles && this.roles.includes(role);
      },
    },

    relations: {
      belongsTo: {
        product: { type: 'product', foreignKey: 'productId' },
        user: { type: 'user', foreignKey: 'userId' },
      },
    },
  });

  return ProductRoles;
}
