// @ts-nocheck
import Camelizer from './Camelizer';
import * as attachments from './attachments';
import * as date from './date';
import * as email from './email';
import * as file from './file';
import formatSavedEvent from './formatSavedEvent';
import generateComparator from './generateComparator';
import isConfigurableProperty from './isConfigurableProperty';
import jsonCloneDeep from './jsonCloneDeep';
import * as phone from './phone';
import replaceKey from './replaceKey';
import replaceKeys from './replaceKeys';
import roundWithPrecision from './roundWithPrecision';
import SortedArray from './SortedArray';
import urlEncodedTaggedString from './urlEncodedTaggedString';
import Validator from './Validator';

export {
  attachments,
  Camelizer,
  date,
  email,
  file,
  formatSavedEvent,
  generateComparator,
  isConfigurableProperty,
  jsonCloneDeep,
  phone,
  replaceKey,
  replaceKeys,
  roundWithPrecision,
  SortedArray,
  urlEncodedTaggedString,
  Validator,
};

export function arrayWrapBound() {
  return arrayWrap(this);
}

export function arrayWrap(thing) {
  return Array.isArray(thing) ? thing : thing !== undefined && thing !== null ? [thing] : [];
}
