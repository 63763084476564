import React, { useEffect, useState } from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import VideoTrack from '../VideoTrack/VideoTrack';
import { IS_SAFARI } from '../../../common/utils/browsers';
import ConnectingPreview from './ConnectingPreview';

export default function Publication({ publication, isLocal, disableAudio, videoPriority }) {
  const track = useTrack(publication);
  const remoteVideoTrack = track && track.kind === 'video' && !isLocal;
  const [dimensions, setTrackDimensions] = useState(null);

  useEffect(() => {
    setTrackDimensions(remoteVideoTrack ? { ...track.dimensions } : null);

    if (remoteVideoTrack) {
      const setDimensions = () => setTrackDimensions({ ...track.dimensions });

      track.on('dimensionsChanged', setDimensions);
      return () => {
        track.off('dimensionsChanged', setDimensions);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);

  if (!track) return null;

  const shouldRenderPreview = IS_SAFARI
    ? ((!!dimensions && !dimensions.width) || (!!track.dimensions && !track.dimensions.width)) &&
      !isLocal
    : !!dimensions && !dimensions.width && !isLocal;

  switch (track.kind) {
    case 'video':
      if (shouldRenderPreview) {
        return <ConnectingPreview />;
      } else {
        return (
          <VideoTrack
            track={track}
            priority={videoPriority}
            isLocal={track.name === 'camera' && isLocal}
          />
        );
      }
    case 'audio':
      return disableAudio ? null : <AudioTrack track={track} />;
    default:
      return null;
  }
}
