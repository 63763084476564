const allowedSendersReasons = {
  CONTACTS_MESSAGING_BLOCKED_BY_ADMIN: {
    header: 'You are unable to message this user',
    text: `
      Please note that you cannot message contact users.
      An admin has disabled messaging to contacts.
    `,
  },
  CONVERSATION_WITH_ROLE_YOU_ARE_CURRENTLY_IN: {
    header: 'You are on duty for this role',
    text: `
      Please note that you cannot message a role that
      you are currently on duty for. When you are no longer
      on duty, you will be able to message this role again.
    `,
  },
  COUNTER_PARTY_IS_TIGERPAGE: {
    header: 'You are unable to message this user',
    text: `
      Please note that you cannot message TigerPage.
      When you select a different conversation, you
      will be able to send a message.
    `,
  },
};

export default allowedSendersReasons;
