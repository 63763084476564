// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import uuid from 'uuid';
import * as errors from '../errors';
import jsonCloneDeep from '../utils/jsonCloneDeep';
import BaseService from './BaseService';
import EventsService from './EventsService';

class NotificationsService extends BaseService {
  constructor(host, options) {
    super(host, options);
    this.events = new EventsService(host, { ...options, endpointType: 'simpleNotifications' });
  }

  mounted() {
    this.events.mounted();
    this.events.on('connected', this._emitConnected);
    this.events.on('disconnected', this._emitDisconnected);
    this.events.on('event', this._emitEvent);
  }

  dispose() {
    this.events.removeListener('connected', this._emitConnected);
    this.events.removeListener('disconnected', this._emitDisconnected);
    this.events.removeListener('event', this._emitEvent);
    this.events.dispose();
  }

  async signIn() {
    const userAuth = this.host.getAuth();
    if (!userAuth || !userAuth.key || !userAuth.secret) {
      throw new errors.RequiresBasicAuthError();
    }

    this.emit('signingIn');

    if (!this.events.isSignedIn) {
      const auth = await this.host.api.notifications.signIn(userAuth.key, userAuth.secret);
      this.events.setAuth(auth);
    }

    const auth = this.events.getAuth();
    this.emit('signedIn', auth);

    return auth;
  }

  @reusePromise({ serializeArguments: (args) => '' })
  async signOut({ fromServer = false } = {}) {
    const auth = this.events.getAuth();
    if (!auth) return false;

    this.emit('signingOut', { fromServer });
    let signedOut;

    try {
      await this.host.api.notifications.signOut(auth.key, auth.secret);
      signedOut = true;
    } catch (err) {
      if (this.httpClient.isUnauthorizedError(err)) {
        signedOut = true;
      } else {
        return Promise.reject(err);
      }
    }

    if (signedOut) {
      this.events.removeAuth();
    }

    this.emit('signedOut', { fromServer, signedOut });

    return signedOut;
  }

  get isSignedIn() {
    return this.events.isSignedIn;
  }

  async authenticate(key, secret) {
    this.events.setAuth({ key, secret });
  }

  connect() {
    return this.events.connect();
  }

  disconnect({ source } = {}) {
    return this.events.disconnect({ source });
  }

  reconnect() {
    return this.events.reconnect();
  }

  get hasConnection() {
    return this.events.hasConnection;
  }

  get isConnected() {
    return this.events.isConnected;
  }

  get isOpen() {
    return this.events.isOpen;
  }

  reactToMessageEvent({ data }) {
    data = jsonCloneDeep(data);

    const message = this.host.models.Message.createInstance({
      ...data,
      id: uuid.v4(),
    });

    this.emit('unread:change', message.unreadMessageCount);
    this.emit('message', message);
  }

  reactToMessageStatusEvent(msg) {
    this.emit('unread:change', msg.unread_message_count);
  }

  _emitConnected = () => {
    this.emit('connected');
  };

  _emitDisconnected = (options) => {
    this.emit('disconnected', options);
  };

  _emitEvent = (event) => {
    this.emit('event', event);
  };
}

export default NotificationsService;
