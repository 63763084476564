import { Camelizer } from '../utils';
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

const PBX_BASE_URL = '/pbx/v5/pbxLines/';

export default class PbxAPI extends BaseAPI {
  buildURL = (...paths: string[]) => {
    const baseURL = this.host.tcUrl + PBX_BASE_URL;
    const url = baseURL + [...paths].join('/');
    return url;
  };

  @recoverFromNotFound()
  async assignPbxLine({
    pbxLineId,
    orgId,
    targetId,
    targetType,
  }: {
    pbxLineId: string;
    orgId: string;
    targetId: string;
    targetType: string;
  }) {
    const url = this.buildURL(pbxLineId, 'assign');
    const res = await this.httpClient.post(url, {
      headers: { 'TT-X-Organization-Key': orgId },
      data: { targetId, targetType },
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async batchAssignUsersToNCLines({ targetIds, orgId }: { orgId: string; targetIds: string[] }) {
    const url = this.buildURL('nurseCall/assign');
    const res = await this.httpClient.post(url, {
      headers: { 'TT-X-Organization-Key': orgId },
      data: { targetIds },
    });

    return Camelizer.camelizeObject(res.data);
  }

  async retrieveNurseCallStatus({ orgId }: { orgId: string }) {
    const url = this.buildURL('nurseCall/status');
    const res = await this.httpClient.get(url, {
      headers: { 'TT-X-Organization-Key': orgId },
    });
    return res.data;
  }

  async batchUnAssignPbxLines({
    lineType,
    orgId,
    pbxLineIds,
    targetIds,
  }: {
    lineType: string;
    orgId: string;
    pbxLineIds: string[];
    targetIds?: string[];
  }) {
    const url =
      lineType === 'Nurse Call Users'
        ? this.buildURL('nurseCall/unassign')
        : this.buildURL('batchUnassign');
    const res = await this.httpClient.post(url, {
      headers: { 'TT-X-Organization-Key': orgId },
      data: lineType === 'Nurse Call Users' ? { targetIds } : { pbxLineIds },
    });

    return Camelizer.camelizeObject(res.data);
  }

  async unAssignPbxLine({
    lineType,
    orgId,
    pbxLineId,
    targetIds,
  }: {
    lineType: string;
    orgId: string;
    pbxLineId: string;
    targetIds?: string[];
  }) {
    const url =
      lineType === 'Nurse Call Users'
        ? this.buildURL('nurseCall/unassign')
        : this.buildURL(pbxLineId, 'unassign');
    const res = await this.httpClient.post(url, {
      headers: { 'TT-X-Organization-Key': orgId },
      data: lineType === 'Nurse Call Users' ? { targetIds } : {},
    });

    return Camelizer.camelizeObject(res.data);
  }

  async getPbxLineDetails({ orgId, pbxLineId }: { orgId: string; pbxLineId: string }) {
    const url = this.buildURL(pbxLineId);
    const headers = { 'TT-X-Organization-Key': orgId };
    const { data } = await this.httpClient.get(url, {
      headers,
    });
    return data;
  }

  async getPbxLines({
    orgId,
    page,
    size,
    targetType,
  }: {
    orgId: string;
    page: number;
    size: number;
    targetType: 'User' | 'Role' | 'NurseCall';
  }) {
    let params;
    if (targetType === 'NurseCall') {
      params = new URLSearchParams({
        orgToken: orgId,
        page: `${page}`,
        size: `${size}`,
        targetType,
        isAssigned: 'true',
      });
    } else {
      params = new URLSearchParams({
        orgToken: orgId,
        page: `${page}`,
        size: `${size}`,
        targetType,
      });
    }
    const url = this.buildURL('pagination') + '?' + params;
    const headers = { 'TT-X-Organization-Key': orgId };
    const { data } = await this.httpClient.get(url, {
      headers,
    });
    return data;
  }

  async uploadPbxLinesViaCsv({
    email,
    file,
    headers,
    systemConfigurationId,
  }: {
    email: string;
    file: File;
    headers: {
      Authorization: string;
      'TT-X-Organization-Key': string;
    };
    systemConfigurationId: string;
  }) {
    const url = `${this.host.tcUrl}/pbx/v5/pbxLines/uploadAssign`;
    const formData = new FormData();
    formData.append('system_configuration_id', systemConfigurationId);
    formData.append('email', email);
    formData.append('file', file);

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: formData,
      credentials: 'include' as RequestCredentials,
    };

    fetch(url, requestOptions).catch((error) => {
      console.error('Error:', error);
    });
  }

  async getPbxSystems({
    currentOrganizationId,
    containNurseCall,
  }: {
    currentOrganizationId: string;
    containNurseCall: boolean;
  }) {
    const res = await this.httpClient.get(
      `${this.host.tcUrl}/pbx/v5/pbxSystems?containNurseCall=${containNurseCall}`,
      {
        headers: { 'TT-X-Organization-Key': currentOrganizationId },
      }
    );
    return res.data.data;
  }

  async getPatientLink(roomName: string, memberId: string, organizationId: string) {
    const res = await this.httpClient.get(`/v2/patient_link/${roomName}/${memberId}`, {
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return res.data;
  }
}
