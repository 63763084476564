import { useEffect, useState } from 'react';
import { mobxInjectSelect } from '../../../../common/utils';
import { Role, Team, User } from '../../../../types';

type MobxProps = {
  findTeam: (id: string) => Promise<Team>;
  getAutoForwardEntityDisplayName: (autoForwardEntities: (User | Team | Role)[]) => string;
  getEntityData: (key: string, id: string) => User | Team | Role;
};

type DndRosterTextProps = {
  autoForwardEntitiesIds: Record<string, string>;
  autoForwardReceiver: {
    displayName: string;
  };
  isExtendedAutoForwardOptionsEnabled: boolean;
};

function DndRosterAutoForwardRecipientText({
  autoForwardEntitiesIds,
  autoForwardReceiver,
  findTeam,
  getAutoForwardEntityDisplayName,
  getEntityData,
  isExtendedAutoForwardOptionsEnabled,
}: MobxProps & DndRosterTextProps) {
  const [autoForwardEntitiesDisplayName, setAutoForwardEntitiesDisplayName] = useState('');

  useEffect(() => {
    const getCurrentAutoForwardDisplayName = async () => {
      if (isExtendedAutoForwardOptionsEnabled) {
        const autoForwardEntitiesIdsArr = [];
        const autoForwardEntitiesIdsKeys = Object.keys(autoForwardEntitiesIds);
        for (const key of autoForwardEntitiesIdsKeys) {
          for (const id of autoForwardEntitiesIds[key]) {
            if (key === 'team') {
              const team = await findTeam(id);
              autoForwardEntitiesIdsArr.push(team);
            } else {
              autoForwardEntitiesIdsArr.push(getEntityData(key, id));
            }
          }
        }

        const currText = getAutoForwardEntityDisplayName(autoForwardEntitiesIdsArr);

        setAutoForwardEntitiesDisplayName(currText);
      } else {
        setAutoForwardEntitiesDisplayName(autoForwardReceiver?.displayName);
      }
    };

    getCurrentAutoForwardDisplayName();
  }, [
    autoForwardEntitiesIds,
    autoForwardReceiver,
    findTeam,
    getAutoForwardEntityDisplayName,
    getEntityData,
    isExtendedAutoForwardOptionsEnabled,
  ]);

  return <div>{autoForwardEntitiesDisplayName}</div>;
}

export default mobxInjectSelect<DndRosterTextProps, MobxProps>({
  teamStore: ['findTeam'],
  userStore: ['getAutoForwardEntityDisplayName'],
  entityStore: ['getEntityData'],
})(DndRosterAutoForwardRecipientText);
