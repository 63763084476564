import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import BEM from '../bem';
import propTypes from '../propTypes';
import { messageRecipientStatusToReadableLabel } from '../utils/readableLabels';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { EntityAvatar, UserName } from './';

const classes = BEM.with('GroupMessageRecipient');

const DATE_FORMAT = 'MMM d, h:mm a';

class GroupMessageRecipient extends Component {
  static propTypes = {
    isFirst: PropTypes.bool.isRequired,
    isRolesTransitionFeatureFlagEnabled: PropTypes.bool.isRequired,
    showReaction: PropTypes.bool,
    status: PropTypes.shape({
      createdAt: PropTypes.instanceOf(Date).isRequired,
      reaction: PropTypes.string.isRequired,
      reactionTimestamp: PropTypes.instanceOf(Date),
      status: PropTypes.string.isRequired,
      user: propTypes.user.isRequired,
    }),
    user: propTypes.user,
  };

  render() {
    const { isFirst, isRolesTransitionFeatureFlagEnabled, status } = this.props;
    const user = isRolesTransitionFeatureFlagEnabled ? status.user : this.props.user;

    return (
      <div className={classes({ isFirst })}>
        <div className={classes('user-info')}>
          <span className={classes('avatar')}>
            <EntityAvatar entity={user.isRoleBot && user.botRole ? user.botRole : user} size={29} />
          </span>
          <span className={classes('name')}>
            <UserName className={classes('user-name')} includeRoleOwner user={user} />
          </span>
        </div>
        {this._generateDetails()}
      </div>
    );
  }

  _generateDetails() {
    const { showReaction = false, status } = this.props;
    const isRead = !!status && status.status === 'READ';

    if (showReaction && status?.reaction) {
      return (
        <div className={classes('details')}>
          <div className={classes('status', { isReaction: true })}>Chose "{status.reaction}"</div>
          <div className={classes('time')}>
            {status?.reactionTimestamp
              ? format(status.reactionTimestamp, DATE_FORMAT)
              : format(new Date(), DATE_FORMAT)}
          </div>
        </div>
      );
    }

    return (
      <div className={classes('details')}>
        <div className={classes('status', { read: isRead })}>
          {messageRecipientStatusToReadableLabel(status ? status.status : 'NEW')}
        </div>
        <div className={classes('time')}>
          {status?.createdAt
            ? format(status.createdAt, DATE_FORMAT)
            : format(new Date(), DATE_FORMAT)}
        </div>
      </div>
    );
  }
}

export default mobxInjectSelect({
  messengerStore: ['isRolesTransitionFeatureFlagEnabled'],
})(GroupMessageRecipient);
