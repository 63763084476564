// @ts-ignore
// @ts-nocheck

type Order = 'asc' | 'desc';
type SortFn<T> = (a: T, b: T) => 0 | 1 | -1;

export default function generateComparator<T = unknown>(rules: [keyof T, Order][]): SortFn<T> {
  let idx = 0;
  const lines = [];

  for (const [field, order] of rules) {
    lines.push(`const a${idx} = a['${field}']`);
    lines.push(`const b${idx} = b['${field}']`);

    if (order === 'asc') {
      lines.push(`if (a${idx} === undefined && b${idx} !== undefined) return -1`);
      lines.push(`if (a${idx} !== undefined && b${idx} === undefined) return 1`);
      lines.push(`if (a${idx} === null && b${idx} !== null) return -1`);
      lines.push(`if (a${idx} !== null && b${idx} === null) return 1`);
      lines.push(`if (a${idx} < b${idx}) return -1`);
      lines.push(`if (a${idx} > b${idx}) return 1`);
    } else if (order === 'desc') {
      lines.push(`if (a${idx} === undefined && b${idx} !== undefined) return 1`);
      lines.push(`if (a${idx} !== undefined && b${idx} === undefined) return -1`);
      lines.push(`if (a${idx} === null && b${idx} !== null) return 1`);
      lines.push(`if (a${idx} !== null && b${idx} === null) return -1`);
      lines.push(`if (a${idx} < b${idx}) return 1`);
      lines.push(`if (a${idx} > b${idx}) return -1`);
    } else {
      throw new Error(`unsupported sort ordering for field ${field}: ${order}`);
    }

    idx++;
  }

  lines.push('return 0');

  // eslint-disable-next-line no-new-func
  return new Function('a', 'b', lines.join('\n')) as SortFn<T>;
}
