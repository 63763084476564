export default function downloadFile(filename: string, type: string, body: string) {
  const file = new Blob([body], { type });

  if (window.navigator && typeof window.navigator.msSaveBlob === 'function') {
    window.navigator.msSaveBlob(file, filename);
  } else {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = filename;
    a.click();
  }
}
