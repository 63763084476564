// @ts-nocheck
import HeadersPolyfill from './HeadersPolyfill';
import XHRWrapper from './XHRWrapper';

export class XHRRequest {
  constructor({ headers, onProgress, onStart, url, withCredentials }) {
    this.headers = headers;
    this.isAborted = false;
    this.offset = 0;
    this.onProgress = onProgress;
    this.onStart = onStart;
    this.url = url;
    this.withCredentials = withCredentials;
    this.xhr = new XHRWrapper(new XMLHttpRequest());
  }

  async fetch() {
    if (this.isAborted) return;

    let resolve;
    const promise = new Promise((inResolve) => {
      resolve = inResolve;
    });

    this.xhr.open('GET', this.url);

    this.xhr.onprogress = () => {
      const responseText = this.xhr.responseText;
      const chunk = responseText.slice(this.offset);
      this.offset += chunk.length;
      this.onProgress(chunk);
    };

    this.xhr.onreadystatechange = () => {
      if (this.xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED) {
        const contentType = this.xhr.getResponseHeader('Content-Type');
        const headers = this.xhr.getAllResponseHeaders();
        this.onStart({
          contentType,
          headers: new HeadersPolyfill(headers),
          status: this.xhr.status,
          statusText: this.xhr.statusText,
        });
      } else if (this.xhr.readyState === XMLHttpRequest.DONE) {
        resolve();
      }
    };

    this.xhr.withCredentials = this.withCredentials;
    this.xhr.responseType = 'text';

    for (const [name, value] of Object.entries(this.headers)) {
      this.xhr.setRequestHeader(name, value);
    }

    this.xhr.send();

    await promise;
  }

  async abort() {
    if (this.isAborted) return;

    this.isAborted = true;
    this.xhr.abort();
  }
}
