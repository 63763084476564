// @ts-nocheck
import uuid from 'uuid';
import BaseAPI from './BaseAPI';

export default class TypingStatusAPI extends BaseAPI {
  async startTyping(
    recipientId: string,
    senderId: string | null | undefined,
    organizationId: string
  ) {
    const data = {
      recipient: recipientId,
    };

    if (senderId && senderId !== this.host.currentUserId) data['proxy_token'] = senderId;

    if (this.host.events.eventSourceIsWebSocket) {
      this.host.events.send('v1_typing', {
        commandId: uuid(),
        status: 'typing',
        org_token: organizationId,
        ...data,
      });
      return;
    }

    await this.httpClient.post('/v2/message/typing', {
      data,
      headers: { 'TT-X-Organization-Key': organizationId },
    });
  }

  async stopTyping(
    recipientId: string,
    senderId: string | null | undefined,
    organizationId: string
  ) {
    if (this.host.events.eventSourceIsWebSocket) {
      this.host.events.send('v1_typing', {
        commandId: uuid(),
        status: 'clear',
        recipient: recipientId,
        org_token: organizationId,
        proxy_token: senderId,
      });
      return;
    }

    if (senderId) {
      await this.httpClient.del('/v2/message/typing/:recipientId/:senderId', {
        urlParams: {
          recipientId,
          senderId,
        },
      });
    } else {
      await this.httpClient.del('/v2/message/typing/:recipientId', {
        urlParams: { recipientId },
      });
    }
  }
}
