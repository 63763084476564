import createMobxModel from './createMobxModel';

const Metadata = createMobxModel({
  name: 'metadata',

  fields: ['data'],

  relations: {
    one: {
      // TODO doesn't have entityType for polymorphic. need to loop through type
      entity: { type: ['group', 'user'] },
      organization: { type: 'organization' },
    },
  },
});

export default Metadata;
