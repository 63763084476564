import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { DotsIndicator, ScrollingList } from '../../../../common/components';
import propTypes from '../../../../common/propTypes';
import { FROZEN_EMPTY_ARRAY, mobxInjectSelect } from '../../../../common/utils';
import {
  PatientConversationItem,
  PatientConversationItemDetails,
  DummyPatientConversationItem,
  ComposePatientConversationItem,
} from './';
import ReduxEscapeHatch from 'common/components/ReduxEscapeHatch';

const classes = BEM.with('PatientConversationList');
const noTypers = () => FROZEN_EMPTY_ARRAY;
const SECTION_SEPARATOR_PX = 9;

// needed to offset custom Scrollbars which have a padding-bottom of 100px to hide native scrollbars;
// subtract one to keep the border-bottom of the last tile offscreen and hidden
const MARGIN_BOTTOM = 99;

class PatientConversationList extends Component {
  static propTypes = {
    allowInjectOCMessage: PropTypes.bool.isRequired,
    canAutoSelectConversation: PropTypes.bool.isRequired,
    composeEntity: propTypes.counterParty,
    conversations: propTypes.conversationArray,
    conversationsLoaded: PropTypes.bool.isRequired,
    currentConversation: propTypes.conversation,
    currentOrganizationId: PropTypes.string,
    currentRoles: propTypes.roleArray.isRequired,
    hasConversations: PropTypes.bool.isRequired,
    isComposing: PropTypes.bool.isRequired,
    patientGroupToken: PropTypes.string.isRequired,
    selectConversation: PropTypes.func.isRequired,
    stopComposing: PropTypes.func.isRequired,
  };

  _lastOrganizationId = null;
  _rows = FROZEN_EMPTY_ARRAY;

  componentDidMount() {
    this._didUpdate();
  }

  componentDidUpdate() {
    this._didUpdate();
  }

  _didUpdate() {
    const { conversationsLoaded } = this.props;

    if (!conversationsLoaded) return;

    this._autoSelectConversation();

    if (this.scrollbars) this.scrollbars.recomputeRowHeights();
  }

  state = {
    accessibilityMode: false,
  };

  _autoSelectConversation = () => {
    const {
      canAutoSelectConversation,
      currentConversation,
      selectConversation,
      patientGroupToken,
      allowInjectOCMessage,
    } = this.props;

    if (!canAutoSelectConversation) return;

    const currentIsShown =
      currentConversation && this._rows.find((row) => row.conversation === currentConversation);
    if (currentIsShown) return;

    if (!!patientGroupToken && allowInjectOCMessage) {
      const row = this._rows.find((row) => row.conversation?.counterPartyId === patientGroupToken);
      selectConversation(row?.conversation);
      return;
    }

    const allowAutoSelect = !allowInjectOCMessage;

    if (allowAutoSelect) {
      let next = this._rows.find((row) => row.autoSelectable && !!row.conversation) || null;
      if (next) next = next.conversation;
      if (currentConversation !== next) selectConversation(next);
    }
  };

  render() {
    const { conversationsLoaded, hasConversations } = this.props;
    let conversationsFragment;

    if (!conversationsLoaded) {
      conversationsFragment = (
        <div className={classes('placeholder-container')}>
          <div className={classes('dots-container')}>
            <DotsIndicator size={13} speed={0.7} />
          </div>
        </div>
      );
    } else {
      this._updateRows();

      if (hasConversations) {
        conversationsFragment = (
          <div className={classes('conversations')}>
            <ScrollingList
              ref={this._setScrollbars}
              focusableClasses={['.tc-PatientConversationItem']}
              rowCount={this._rows.length}
              rowHeight={this._getRowHeight}
              rowRenderer={this._renderRow}
              thumbMarginTop={0}
              tabIndex={-1}
              accessibilityMode={this.state.accessibilityMode}
            />
          </div>
        );
      }
    }

    return (
      <>
        <div
          className={classes()}
          id="patient-conversation-list"
          role="tabpanel"
          aria-labelledby="patient-sidebar-tab-picker-1"
        >
          {conversationsFragment}
        </div>
        <ReduxEscapeHatch
          ref={(ref) => {
            this.reduxEscapeHatch = ref;
            if (
              this.reduxEscapeHatch &&
              this.reduxEscapeHatch?.accessibilityMode !== this.state.accessibilityMode
            ) {
              this.setState({ accessibilityMode: this.reduxEscapeHatch?.accessibilityMode });
            }
          }}
        />
      </>
    );
  }

  _updateRows = () => {
    const {
      composeEntity,
      conversations,
      currentOrganizationId,
      isComposing,
      composingNewPatientConversationFromSearch,
    } = this.props;

    this._lastOrganizationId = currentOrganizationId;

    const normal = [];

    for (const conversation of conversations) {
      const { counterParty, shouldDisplay } = conversation;

      if (!shouldDisplay || !counterParty) continue;
      normal.push({ type: 'normal', conversation, autoSelectable: true });
    }

    this._rows = [
      { type: 'top-spacer', conversation: null },
      ...(isComposing
        ? !composingNewPatientConversationFromSearch
          ? [{ type: 'new-message', entity: composeEntity }]
          : []
        : []),
      ...(composingNewPatientConversationFromSearch
        ? [{ type: 'search-compose', entity: composeEntity }]
        : []),
      ...normal,
      { type: 'bottom-spacer', conversation: null },
    ];
  };

  _setScrollbars = (ref) => {
    this.scrollbars = ref;
  };

  _getKey = ({ index }) => {
    const row = this._rows[index];
    const { conversation, type } = row;

    return conversation ? conversation.id : `${type}-${index}`;
  };

  _getRowHeight = ({ index }) => {
    const { currentRoles } = this.props;
    const { conversation, sectionSeparator, type } = this._rows[index];
    const isPinned = type === 'pinned';

    if (type === 'top-spacer') {
      return 0;
    } else if (type === 'bottom-spacer') {
      return MARGIN_BOTTOM;
    } else if (type === 'new-message') {
      return 52;
    } else if (type === 'search-compose') {
      return 148;
    }

    let height = PatientConversationItemDetails.getHeight({
      conversation,
      currentRoles,
      getTypersForCounterParty: noTypers,
      isPinned,
      isSelected: false,
    });

    if (sectionSeparator) height += SECTION_SEPARATOR_PX;

    return height;
  };

  _renderRow = (props) => {
    if (!props) return null;

    const { index, isScrolling, key, style } = props;
    const { currentConversation, stopComposing } = this.props;
    const row = this._rows[index];
    const { conversation, entity, sectionSeparator, type } = row;
    const isPinned = type === 'pinned';
    const isSelected = currentConversation === conversation;
    let className;
    let contentsFragment;

    if (['top-spacer', 'bottom-spacer'].includes(type)) {
      // render empty div
    } else if (type === 'search-compose') {
      className = classes('list-item', {
        isScrolling,
        sectionSeparator,
      });

      contentsFragment = (
        <ComposePatientConversationItem entity={entity} stopComposing={stopComposing} />
      );
    } else if (type === 'new-message') {
      className = classes('list-item', { selected: true });
      contentsFragment = (
        <DummyPatientConversationItem entity={entity} stopComposing={stopComposing} />
      );
    } else {
      className = classes('list-item', {
        [conversation.counterParty.$entityType]: true,
        isScrolling,
        sectionSeparator,
      });

      contentsFragment = (
        <PatientConversationItem
          conversation={conversation}
          isPinned={isPinned}
          isSelected={isSelected}
          isInsideCard={true}
        />
      );
    }

    return (
      <div key={key} className={className} style={style}>
        {contentsFragment}
      </div>
    );
  };
}

export default mobxInjectSelect({
  composeMessageStore: [
    'composeEntity',
    'isComposing',
    'stopComposing',
    'composingNewPatientConversationFromSearch',
  ],
  conversationStore: [
    'canAutoSelectConversation',
    'conversations',
    'currentConversation',
    'hasConversations',
    'selectConversation',
  ],
  messengerStore: ['conversationsLoaded', 'currentOrganizationId'],
  operatorConsoleStore: ['allowInjectOCMessage', 'patientGroupToken'],
  roleStore: ['currentRoles'],
})(PatientConversationList);
