// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import _ from 'lodash';
import { arrayWrap } from '../utils';
import * as errors from '../errors';
import BaseService from './BaseService';

export default class EntitiesService extends BaseService {
  @reusePromise()
  async findMulti(ids: Array<string>, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    ids = _.uniq(arrayWrap(ids).map(this._resolveModelId));
    // server allows combined users (10) + metadata (10) = 20 maximum items per request
    const idGroupChunks = _.chunk(ids, 10);

    const promises = idGroupChunks.map((users) =>
      this.host.api.entities.findMulti({ users, metadata: users, organizationId })
    );
    const allResults = await Promise.all(promises);
    const entities = {};

    for (const result of allResults) {
      const { users, metadata: allMetadata } = result;

      if (users) {
        for (const user of users) {
          for (const [token, entity] of Object.entries(user)) {
            entities[token] = { entity };
          }
        }
      }

      if (allMetadata) {
        for (const data of allMetadata) {
          for (const [token, metadata] of Object.entries(data)) {
            const existing = entities[token];
            if (existing) existing.metadata = metadata;
          }
        }
      }
    }

    return Object.values(entities);
  }
}
