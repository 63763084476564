// @ts-nocheck
import { formatDob } from '../utils/date';
import { formatPhoneWithDash } from '../utils/phone';
import { GroupType } from './enums';

export default function (store, { host }) {
  const PatientContact = store.defineModel('patientContact', {
    replaceKeys: {
      patientDob: 'dob',
      patientGender: 'gender',
      patientMrn: 'mrn',
      relationName: 'relation',
    },

    parseAttrs(attrs) {
      if ('entity' in attrs) delete attrs['entity'];

      if ('dob' in attrs) {
        attrs.dob = formatDob(attrs.dob);
      }

      if ('phoneNumber' in attrs) {
        attrs.phoneNumber = formatPhoneWithDash(attrs.phoneNumber);
      }

      return attrs;
    },

    relations: {
      belongsTo: {
        patient: { type: 'patient', foreignKey: 'patientId' },
        user: { type: 'user', foreignKey: 'userId' },
      },
      hasMany: {
        sharedConversations: {
          type: 'conversation',
          foreignKey: 'patientContactId',
          filter: (conversation, patientContact) => {
            const { counterParty } = conversation;
            if (!counterParty) return false;

            const { groupType, hasCurrentUserOrRole, memberIds } = counterParty;
            if (
              groupType === GroupType.PATIENT_MESSAGING &&
              hasCurrentUserOrRole &&
              memberIds.includes(patientContact.userId)
            ) {
              return true;
            }

            return false;
          },
          sortBy: [
            ['highestSortNumber', 'desc'],
            ['_origIndex', 'asc'],
            ['id', 'asc'],
          ],
        },
      },
    },
  });

  return PatientContact;
}
