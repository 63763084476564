import { EventEmitter } from 'events';
// @ts-ignore
import { arrayWrap, Validator } from '../utils';
import { UsersAPI } from '../apis/Admin/UsersAPI';
import OrganizationsAPI from '../apis/Admin/OrganizationsAPI';
import MessagesAPI from '../apis/MessagesAPI';
import MultiOrgAPI from '../apis/MultiOrgAPI';
import PbxAPI from '../apis/PbxAPI';
import SearchAPI from '../apis/SearchAPI';
import type { ClientConfig } from '../configuration';

export default class BaseService extends EventEmitter {
  host: Record<string, any> & {
    api: Record<string, any> & {
      adminUsers: UsersAPI;
      adminOrganizations: OrganizationsAPI;
      pbx: PbxAPI;
      messages: MessagesAPI;
      multiOrg: MultiOrgAPI;
      search: SearchAPI;
    };
  };
  config: ClientConfig;
  httpClient: any;
  constructor(host: any, options: any) {
    super();
    Object.assign(this, options);

    this.host = host;
    this.config = host.config;
    this.httpClient = host.httpClient;
  }

  // a helper method that takes any entity OR an id and returns its ID, preferably its serverId
  _resolveModelId = (thing?: string | { serverId?: string; id?: string }) => {
    if (typeof thing === 'string') return thing;
    return thing ? thing.serverId || thing.id || null : null;
  };

  // a helper method that takes any entity OR an id and a list of types
  // if it's an entity, it ensure the type is one of the allowed types
  // if it's a string, it looks up the entity locally, and if found, ensure the type is correct
  // if not found locally, assumes it's ok (no way to tell what ID it is of)
  _resolveModelIdWithTypes(
    thing?: string | { $entityType: string; serverId: string; id: string },
    allowedEntityTypes?: string | string[]
  ) {
    if (!thing) return null;

    if (typeof thing === 'string') {
      const entity: {
        $entityType: string;
        serverId: string;
        id: string;
      } | null = this._resolveEntity(thing); // try finding in all types
      if (entity) thing = entity;
      else return thing; // not stored locally, assume it's ok
    }

    // @ts-ignore
    Validator.oneOf('thing', thing.$entityType, arrayWrap(allowedEntityTypes));

    return thing.serverId || thing.id || null;
  }

  // a helper method that gets a unique ID of a model and looks it up locally by .id or .serverId
  // all entities should have a unique ID so it's safe
  // list of types to look in defaults to all entity types
  _resolveEntity(
    id?: string | { $entityType: string; id: string },
    types?: string | string[]
  ): any {
    if (types) {
      types = arrayWrap(types);
    } else {
      types = this.host.allModelEntityTypes;
    }

    if (typeof id === 'object') {
      if (id.$entityType) {
        // @ts-ignore
        Validator.oneOf('thing', id.$entityType, types);
        types = [id.$entityType];
      }
      id = id.id;
    }

    // @ts-ignore
    for (const type of types) {
      const entity = this.host.modelsByEntityType[type].get(id);
      if (entity) return entity;
    }

    return null;
  }

  mounted() {}

  dispose() {}
}
