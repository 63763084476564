import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Participant from '../Participant/Participant';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import LocalVideoPreview from '../LocalVideoPreview/LocalVideoPreview';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: `calc(100% - ${theme.sidebarWidth}px)`,
      left: 0,
      width: '100%',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    emptyParticipants: {
      backgroundColor: '#000',
    },
    gridContainer: {
      position: 'relative',
      height: '100%',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: '50% 50%',
      gridColumnGap: '1px',
      gridRowGap: '1px',
      height: '100%',
      backgroundColor: '#fff',
    },
    hiddenTracks: {
      display: 'none',
    },
    wideTrack: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      backgroundColor: '#000',
    },
  })
);

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const classes = useStyles();
  const { indexOfParticipants, setIndexOfParticipants } = useAppState();
  const remoteParticipantsCount = participants.length;

  const emptyParticipantsComponent = [];
  let participantsViewable, participantsHidden, participantComponent, remoteParticipantsShownCount;
  if (indexOfParticipants === 0) {
    participantsViewable = participants.slice(indexOfParticipants, 3);
    participantsHidden = participants.slice();
    participantsHidden.splice(indexOfParticipants, 3);
    remoteParticipantsShownCount = participantsViewable.length;
    participantComponent =
      remoteParticipantsCount === 0 ? (
        <LocalVideoPreview />
      ) : (
        <Participant
          key={localParticipant.sid}
          participant={localParticipant}
          index={remoteParticipantsShownCount}
        />
      );
  } else {
    const startIndex = indexOfParticipants * 4 - 1;
    participantsViewable = participants.slice(startIndex, startIndex + 4);
    participantsHidden = participants.slice();
    participantsHidden.splice(startIndex, 4);
    remoteParticipantsShownCount = participantsViewable.length;
    if (remoteParticipantsShownCount < 3) {
      for (let i = 0; i < 4 - remoteParticipantsShownCount; i++) {
        emptyParticipantsComponent.push(<div className={classes.emptyParticipants} />);
      }
    }
  }

  if (remoteParticipantsShownCount === 0 && indexOfParticipants > 0) {
    setIndexOfParticipants(indexOfParticipants - 1);
  }

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.gridContainer, {
          [classes.grid]: remoteParticipantsCount > 1,
        })}
      >
        {participantsViewable
          .sort((participant1, participant2) => (participant1.sid < participant2.sid ? -1 : 1))
          .map((participant, index) => {
            const isWideView =
              (remoteParticipantsShownCount === 2 && index === 0 && indexOfParticipants === 0) ||
              (indexOfParticipants > 0 && remoteParticipantsShownCount === 3 && index === 0);

            return isWideView ? (
              <div key={participant.sid} className={classes.wideTrack}>
                <Participant participant={participant} index={index} />
              </div>
            ) : (
              <Participant key={participant.sid} participant={participant} index={index} />
            );
          })}
        {participantComponent}
        {emptyParticipantsComponent}
      </div>
      {participantsHidden.length > 0 && (
        <div className={classes.hiddenTracks}>
          {participantsHidden.map((participant, index) => {
            return <ParticipantTracks participant={participant} hiddenAudio={true} key={index} />;
          })}
        </div>
      )}
    </div>
  );
}
