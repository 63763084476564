import React, { useEffect } from 'react';
import { AlertFilterItems } from '../../../../../models/enums/';
import { mobxInjectSelect } from '../../../../../common/utils';
import BEM from '../../../../../common/bem';
import UnreadBadgeCount from '../../../../../common/components/UnreadBadgeCount';

const classes = BEM.with('AlertsFilterList');

type AlertsFilterListProps = {};

type MobxProps = {
  currentUserDnd: boolean;
  hideDnd: boolean;
  resetUnreadCounts: () => void;
  selectedAlertsFilter: string;
  setAlertsFilter: (filterItem: string) => void;
  unreadAlertsCount: number;
};

const AlertsFilterList = ({
  currentUserDnd,
  hideDnd,
  resetUnreadCounts,
  selectedAlertsFilter,
  setAlertsFilter,
  unreadAlertsCount,
}: AlertsFilterListProps & MobxProps) => {
  const AlertFilterItemsArray = Object.values(AlertFilterItems);
  useEffect(() => {
    resetUnreadCounts();
  }, [resetUnreadCounts]);

  const renderAlertItems = AlertFilterItemsArray.map((filterItem) => (
    <div
      className={classes('alert-conversation-items', {
        activeItem: filterItem === selectedAlertsFilter,
      })}
      data-test-id={`alert-items-${filterItem}`}
      onClick={() => {
        setAlertsFilter(filterItem as string);
      }}
      key={filterItem as string}
    >
      <span>{filterItem as string}</span>
      {filterItem === 'Alerts' && unreadAlertsCount > 0 && (
        <div className={classes('unread-count-wrapper')}>
          <UnreadBadgeCount
            className={classes('unread-count')}
            count={unreadAlertsCount}
            usePriorityButton={true}
          />
        </div>
      )}
    </div>
  ));

  return (
    <div
      className={classes('', { showDnd: currentUserDnd && !hideDnd })}
      data-test-id="alertsFilterList"
    >
      {renderAlertItems}
    </div>
  );
};

export default mobxInjectSelect<AlertsFilterListProps, MobxProps>({
  alertStore: ['unreadAlertsCount'],
  rosterStore: ['resetUnreadCounts', 'setAlertsFilter', 'selectedAlertsFilter'],
  sessionStore: ['currentUserDnd'],
  userStore: ['hideDnd'],
})(AlertsFilterList);
