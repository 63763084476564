import keycode from 'keycode';
import isInput from './isInput';

const ESC_KEY_CODE = keycode('esc');

export default function detectOnEsc(fn, { disableInInput = true } = { disableInInput: true }) {
  return function (e) {
    if (!fn) return;

    if (isInput(e.target) && disableInInput) return;

    if (e.keyCode === ESC_KEY_CODE) {
      e.stopPropagation();
      e.preventDefault();
      fn(e);
    }
  };
}
