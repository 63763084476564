import React, { useEffect, useState } from 'react';
import TCClient from '../../../client';
import parseUSPhoneNumber from '../../utils/parseUSPhoneNumber';
import {
  CLICK_2_CALL_MASK_TYPE_OPTIONS,
  CLICK_2_CALL_OPTIONS,
} from '../../utils/commonControlOptions';
import { OrganizationWideSettingOption } from '../../types';

import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import { Input } from '../../shared-components/Input/Input';
import { StandardOrgSettingInput } from '../../shared-components/StandardSettingInput';
import { Toast } from '../../shared-components/Toast/Toast';
import { Dropdown } from '../../shared-components/Dropdown/Dropdown';

import styles from './C2COrgSettingControls.module.css';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';

export const C2COrgSettingsControls = () => {
  const dispatch = useAppDispatch();
  const {
    click2CallNumberMask = '',
    click2CallNumberMaskProxyNumber = '',
    click2CallNumberMaskType = '',
    selectedOrgId,
  } = useAppSelector((state) => ({
    click2CallNumberMask: state.orgSettings.settings.click2CallNumberMask,
    click2CallNumberMaskProxyNumber: state.orgSettings.settings.click2CallNumberMaskProxyNumber,
    click2CallNumberMaskType: state.orgSettings.settings.click2CallNumberMaskType,
    selectedOrgId: state.admin.selectedOrganization.token,
  }));
  const [initialNumberMask, setInitialNumberMask] = useState(click2CallNumberMask);
  const [initialNumberMaskType] = useState(click2CallNumberMaskType);
  const [editMode, setEditMode] = useState(false);
  const [areaCodeInputBuffer, setAreaCodeInputBuffer] = useState('');
  const [countryCodeInputBuffer, setCountryCodeInputBuffer] = useState('');
  const [isSaveButtonDisplay, setIsSaveButtonDisplay] = useState(false);
  const [customNumberInputBuffer, setCustomNumberInputBuffer] = useState('');
  const [customNumberVerificationCode, setCustomNumberVerificationCode] = useState('');
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('SUCCESS');
  const isSendCodeButtonDisplay =
    click2CallNumberMaskType === 'custom' &&
    customNumberVerificationCode === '' &&
    customNumberInputBuffer.length === 14;

  const onSave = () =>
    thunk.saveSettings({ dispatch }).then(() => {
      setEditMode(false);
      setInitialNumberMask(click2CallNumberMask);
    });

  useEffect(() => {
    if (click2CallNumberMask !== initialNumberMask) {
      setCustomNumberInputBuffer('');
      setAreaCodeInputBuffer('');
    } else {
      setAreaCodeInputBuffer(click2CallNumberMaskProxyNumber.replace(/\D/g, '').substring(1, 4));
    }

    if (click2CallNumberMaskType !== initialNumberMaskType) {
      if (click2CallNumberMaskType === 'custom') setAreaCodeInputBuffer('');
      if (click2CallNumberMaskType === 'twilio') setCustomNumberInputBuffer('');
    }

    if (click2CallNumberMaskType === 'twilio') {
      setIsSaveButtonDisplay(true);
    } else {
      setIsSaveButtonDisplay(false);
    }
  }, [
    click2CallNumberMaskProxyNumber,
    click2CallNumberMaskType,
    click2CallNumberMask,
    initialNumberMask,
    initialNumberMaskType,
  ]);

  const originalState =
    click2CallNumberMaskProxyNumber && click2CallNumberMask === initialNumberMask;

  return (
    <div className={styles.c2cControls}>
      <StandardOrgSettingInput
        options={CLICK_2_CALL_OPTIONS}
        settingName="click2CallNumberMask"
        type="dropdown"
      />
      {click2CallNumberMask !== 'off' && (
        <>
          {originalState && !editMode ? (
            <>
              <div className={styles.c2cProxyNumber}>
                {parseUSPhoneNumber(click2CallNumberMaskProxyNumber)}
              </div>
              <CtaButton primary label="Change" onClick={() => setEditMode(true)} />
            </>
          ) : (
            <>
              <div className={styles.c2cMaskType}>
                <StandardOrgSettingInput
                  options={CLICK_2_CALL_MASK_TYPE_OPTIONS}
                  settingName="click2CallNumberMaskType"
                  type="dropdown"
                />
                {click2CallNumberMaskType === 'twilio' && (
                  <>
                    <div className={styles.countryCodeContainer}>
                      <label>Calling Number Country Code</label>
                      <Dropdown
                        onChange={(v) => setCountryCodeInputBuffer(v)}
                        defaultValue="US"
                        options={[
                          { label: '+1 (US)', value: 'US' },
                          { label: '+1 (CA)', value: 'CA' },
                        ]}
                      />
                    </div>

                    <div className={styles.c2cCallingNumberAreaCodeContainer}>
                      <label>Calling Number Area Code</label>
                      <Input
                        customCSS={
                          areaCodeInputBuffer.length !== 3 ? styles.c2cCallingNumberAreaCodeErr : ''
                        }
                        placeholder={'000'}
                        onInputChange={(e) => {
                          setAreaCodeInputBuffer(e.target.value.replace(/\D/g, '').substring(0));
                        }}
                        value={areaCodeInputBuffer}
                        lengthLimit={3}
                      />
                    </div>
                  </>
                )}
                {click2CallNumberMaskType === 'custom' && customNumberVerificationCode === '' && (
                  <>
                    <label>Calling Number</label>
                    <div className={styles.customNumberInputContainer}>
                      <Input
                        placeholder={'(000) 000-0000'}
                        customCSS={
                          customNumberInputBuffer.length !== 14
                            ? styles.c2cCallingNumberErr
                            : styles.c2cCallingNumber
                        }
                        onInputChange={(e) => {
                          const value = handleClickToCallCustomNumber(e.target.value);
                          setCustomNumberInputBuffer(value);
                        }}
                        value={customNumberInputBuffer}
                      />
                      <span className={styles.plusOne}>+1</span>
                    </div>
                    <span className={styles.sublabel}>We need to verify your number</span>
                  </>
                )}
                {customNumberVerificationCode !== '' && (
                  <div className={styles.verifyCustomContainer}>
                    <label>Verification Code</label>
                    <p>
                      In a few moments, our system will call you at the number previously entered.
                      When the call is answered you will need to input the verification displayed
                      below to complete the verification process.
                    </p>
                    <Input
                      value={customNumberVerificationCode}
                      disabled
                      customCSS={styles.verificationCodeInput}
                    />
                  </div>
                )}
              </div>
              <CtaButton label={'Cancel'} onClick={() => resetSettings()} />
              {isSendCodeButtonDisplay && (
                <CtaButton
                  label={'Send Code'}
                  primary
                  onClick={() => {
                    handleSendCode();
                  }}
                />
              )}
              {(areaCodeInputBuffer.length === 3 || customNumberInputBuffer.length === 14) &&
                !isSendCodeButtonDisplay &&
                isSaveButtonDisplay && <CtaButton label={'Save'} onClick={handleSave} primary />}
            </>
          )}
        </>
      )}
      <Toast
        message={toastMessage}
        open={isToastOpen}
        onClose={() => {
          setIsToastOpen(false);
        }}
        type={toastType as 'SUCCESS' | 'FAILURE'}
      />
    </div>
  );

  function resetSettings() {
    setEditMode(false);
    setCustomNumberVerificationCode('');
    setCustomNumberInputBuffer('');
    setAreaCodeInputBuffer('');
    setInitialNumberMask(initialNumberMask);
    dispatch(
      actions.updateSettings({
        click2CallNumberMask: initialNumberMask as OrganizationWideSettingOption,
        click2CallNumberMaskType: initialNumberMaskType as 'custom' | 'twilio',
      })
    );
  }

  async function handleTwilioSave() {
    const res = await TCClient.adminOrganizations.getAvailableTwilioNumbers({
      contains: areaCodeInputBuffer,
      countryCode: countryCodeInputBuffer,
    });

    if (!res.length) {
      setToastType('FAILURE');
      setToastMessage('No available numbers were returned for this area code.');
      setIsToastOpen(true);
      return;
    }

    dispatch(actions.updateSettings({ click2CallNumberMaskAreaCode: areaCodeInputBuffer }));
    onSave();
    // setInitial to new
  }

  async function handleCustomNumberSave() {
    try {
      await TCClient.adminOrganizations.verifyCustomNumber({
        organizationId: selectedOrgId,
      });
      setToastType('SUCCESS');
      setToastMessage('Number verified successfully.');
      setCustomNumberVerificationCode('');
    } catch (error) {
      if (error.status === 0) {
        setToastMessage('Timed out. Please cancel and try again.');
      } else if (error.status === 400) {
        setToastMessage('Please cancel and try again.');
      } else {
        setToastMessage(error.text);
      }
      setToastType('FAILURE');
    }
    setIsToastOpen(true);
    // setInitial to new
  }

  function handleSave() {
    if (click2CallNumberMaskType === 'twilio') {
      handleTwilioSave();
    } else if (click2CallNumberMaskType === 'custom') {
      handleCustomNumberSave();
    }
  }

  async function handleSendCode() {
    try {
      setIsSaveButtonDisplay(true);
      const res = await TCClient.adminOrganizations.getCustomNumberVerificationCode({
        status: click2CallNumberMask,
        customNumber: customNumberInputBuffer,
        organizationId: selectedOrgId,
      });
      if (res) {
        setCustomNumberVerificationCode(res.validationCode);
      }
    } catch (error) {
      setToastType('FAILURE');
      setToastMessage(error.text);
      setIsToastOpen(true);
    }
  }

  function handleClickToCallCustomNumber(value: string) {
    if (value.length < customNumberInputBuffer.length) {
      return value;
    }

    let strippedValue = value.replace(/\D/g, '').substring(0);
    const length = strippedValue.length;
    if (length > 10) return customNumberInputBuffer;

    if (length >= 1) strippedValue = '(' + strippedValue.substring(0);
    if (length >= 3)
      strippedValue = strippedValue.substring(0, 4) + ') ' + strippedValue.substring(4);
    if (length >= 6)
      strippedValue = strippedValue.substring(0, 9) + '-' + strippedValue.substring(9);

    return strippedValue;
  }
};
