import { makeStyles, styled } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as PhoneIcon } from '../../widgets/messenger/images/provider-call-icon.svg';
import { UserAvatar } from '../../common/components';
import Fab from '../components/Fab/Fab';

import { ReactComponent as CallDeclineIcon } from '../images/call-decline.svg';
import { ReactComponent as VideoOffIcon } from '../images/video-off.svg';
import { ReactComponent as VideoOnIcon } from '../images/video-on.svg';
import mobxInjectSelect from '../../common/utils/mobxInjectSelect';
import buildMembersList from '../utils/buildMembersList';
import animationStyles from './IncomingCall.module.css';

const IncomingContainer = styled('div')({
  position: 'absolute',
  bottom: '3px',
  width: '342px',
  marginLeft: '74px',
  height: '290px',
  // backgroundColor: '#ffffff',
  borderRadius: '6px',
  border: '3px solid #fcb040',
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.36)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
});

const CallerHeader = styled('div')({
  // color: '#4a657b',
  fontSize: '18px',
  fontWeight: 600,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const HeaderText = styled('div')({
  paddingLeft: '10px',
});

const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const ProviderHeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '80px',
  height: '60px',
});

const UserHeader = styled('div')({
  fontSize: '16px',
  marginTop: '10px',
  fontWeight: '500',
});

const GroupHeader = styled('div')({
  fontWeight: '900',
  fontSize: '12px',
});

const MembersContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
});

const NetworkHeader = styled('div')({
  fontWeight: 'bold',
  fontSize: '12px',
});

const MembersList = styled('div')({
  width: '88%',
  textAlign: 'center',
  overflowWrap: 'break-word',
  overflowY: 'hidden',
});

const Controls = styled('div')({
  display: 'flex',
  marginBottom: '12px',
});

const useStyles = makeStyles({
  phoneIconStyle: {
    '& path': {
      fill: 'white',
    },
  },

  videoIconStyle: {
    '& path': {
      fill: 'white',
    },
  },

  incomingContainer: {
    backgroundColor: (props) => (props.isDarkModeOn ? '#2b2b2b' : '#fff'),
    color: (props) => (props.isDarkModeOn ? '#e6e6e6' : '#4a657b'),
  },

  userAvatarStyle: {
    top: '10px',
  },
});

const ActionType = {
  DECLINE_CALL: 'DECLINE_CALL',
  JOIN_CALL_WITH_VIDEO: 'JOIN_CALL_WITH_VIDEO',
  JOIN_CALL_WITHOUT_VIDEO: 'JOIN_CALL_WITHOUT_VIDEO',
};

function IncomingCall({
  currentCall,
  currentUser,
  declineVoipCall,
  findUser,
  incomingCallActionsEnabled,
  isDarkModeOn,
  setIncomingCallActionsEnabled,
  tryToJoinVoipCall,
}) {
  const classes = useStyles({ isDarkModeOn });
  const [user, setUser] = useState(null);
  const [recipients, setRecipients] = useState(null);
  const {
    payload: {
      callerId,
      connectedParticipants,
      disabledParticipants,
      isVideo,
      networkType,
      participantsTokens = [],
      orgId,
    },
  } = currentCall;

  useEffect(() => {
    async function fetchData() {
      const user = await findUser(callerId, orgId);
      const results = await Promise.all(
        (connectedParticipants || participantsTokens)
          .filter(
            (token) =>
              !disabledParticipants[token] && callerId !== token && currentUser.id !== token
          )
          .map(async (token) => await findUser(token, orgId))
      );
      setUser(user);
      setRecipients(results);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCall = async (actionType) => {
    if (incomingCallActionsEnabled) setIncomingCallActionsEnabled(false);
    switch (actionType) {
      case ActionType.DECLINE_CALL:
        declineVoipCall();
        break;
      case ActionType.JOIN_CALL_WITH_VIDEO:
        await tryToJoinVoipCall(true);
        break;
      case ActionType.JOIN_CALL_WITHOUT_VIDEO:
        await tryToJoinVoipCall(false);
        break;
      default:
    }
  };

  if (!user || !recipients) {
    return null;
  }

  let headerFragment;
  if (networkType === 'patient') {
    headerFragment = (
      <HeaderContainer>
        <UserAvatar user={user} size={38} shouldUseMinWidth={true} />
        <HeaderText>
          <CallerHeader>Call from {user.displayName}</CallerHeader>
          {!recipients.length && <NetworkHeader>Patient Call</NetworkHeader>}
        </HeaderText>
      </HeaderContainer>
    );
  } else {
    const callerHeader = !recipients.length ? 'Incoming Call' : `Call from ${user.displayName}`;
    const headerTypeText = !recipients.length ? (
      <UserHeader>{user.displayName}</UserHeader>
    ) : isVideo ? (
      <GroupHeader>{'Group Video Call'}</GroupHeader>
    ) : (
      <GroupHeader>{'Group Audio Call'}</GroupHeader>
    );
    headerFragment = (
      <ProviderHeaderContainer>
        <HeaderContainer>
          <IconContainer>
            <div
              className={clsx(animationStyles.ringHolder, {
                [animationStyles.isGroup]: recipients.length,
              })}
            >
              <div className={animationStyles.ring}></div>
            </div>
            <UserAvatar className={classes.userAvatarStyle} user={user} size={35} />
          </IconContainer>
          <CallerHeader>{callerHeader}</CallerHeader>
        </HeaderContainer>
        {headerTypeText}
      </ProviderHeaderContainer>
    );
  }

  return (
    <IncomingContainer className={classes.incomingContainer}>
      {headerFragment}
      {networkType === 'patient' && !!recipients.length && (
        <MembersContainer>
          <NetworkHeader>Patient Call</NetworkHeader>
          <MembersList>{buildMembersList(recipients, currentUser)}</MembersList>
        </MembersContainer>
      )}
      <Controls>
        <Fab
          onClick={() => handleCall(ActionType.DECLINE_CALL)}
          isEnabled={incomingCallActionsEnabled}
          isDisabled={!incomingCallActionsEnabled}
          isInactive={!incomingCallActionsEnabled}
          title={'Decline'}
          backgroundColorOn={'#D92A25'}
          hoverColor={'#BC2521'}
          marginButton={'0 20px'}
          size={'large'}
          placement={'bottom'}
        >
          <CallDeclineIcon />
        </Fab>
        <Fab
          onClick={() => handleCall(ActionType.JOIN_CALL_WITHOUT_VIDEO)}
          isEnabled={incomingCallActionsEnabled}
          isDisabled={!incomingCallActionsEnabled}
          isInactive={!incomingCallActionsEnabled}
          title={'Answer with video off'}
          backgroundColorOn={'#4adc68'}
          hoverColor={'#339948'}
          marginButton={'0 20px'}
          size={'large'}
          placement={'bottom'}
        >
          {isVideo ? (
            <VideoOffIcon className={classes.videoIconStyle} />
          ) : (
            <PhoneIcon className={classes.phoneIconStyle} />
          )}
        </Fab>
        {isVideo && (
          <Fab
            onClick={() => handleCall(ActionType.JOIN_CALL_WITH_VIDEO)}
            isEnabled={incomingCallActionsEnabled}
            isDisabled={!incomingCallActionsEnabled}
            isInactive={!incomingCallActionsEnabled}
            title={'Answer with video on'}
            backgroundColorOn={'#4adc68'}
            hoverColor={'#339948'}
            size={'large'}
            marginButton={'0 20px'}
            placement={'bottom'}
          >
            <VideoOnIcon />
          </Fab>
        )}
      </Controls>
      {currentUser.incomingCallSound && <audio src={'media/call.wav'} autoPlay loop />}
    </IncomingContainer>
  );
}

IncomingCall.propTypes = {
  declineVoipCall: PropTypes.func.isRequired,
  findUser: PropTypes.func.isRequired,
  currentCall: PropTypes.shape({
    payload: PropTypes.shape({
      callerId: PropTypes.string,
      name: PropTypes.string,
      participantsTokens: PropTypes.arrayOf(PropTypes.string),
      disabledParticipants: PropTypes.object,
    }),
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  tryToJoinVoipCall: PropTypes.func.isRequired,
  setIncomingCallActionsEnabled: PropTypes.func.isRequired,
  incomingCallActionsEnabled: PropTypes.bool.isRequired,
};

class IncomingCallContainer extends React.Component {
  componentDidMount() {
    const { setIncomingCallActionsEnabled } = this.props;
    setIncomingCallActionsEnabled(true);
  }

  render() {
    return <IncomingCall {...this.props} />;
  }
}

export default mobxInjectSelect({
  callStore: [
    'currentCall',
    'declineVoipCall',
    'incomingCallActionsEnabled',
    'setIncomingCallActionsEnabled',
    'tryToJoinVoipCall',
  ],
  messengerStore: ['isDarkModeOn'],
  modalStore: ['openModal'],
  sessionStore: ['currentUser'],
  userStore: ['findUser'],
})(IncomingCallContainer);
