import { chunk, throttle, DebouncedFunc } from 'lodash';
import { PresenceStatus } from '../models/enums';
import NetworkStatus from '../network/NetworkStatus';
import BaseService from './BaseService';

export const MAX_USERS_PER_REQUEST = 50;
export const THROTTLE_TIME = 500;
export const POLL_INTERVAL = 60000;

type PresenceStatus = typeof PresenceStatus.keys[number];

export default class PresenceService extends BaseService {
  _data: Record<
    string,
    { dnd?: boolean; dnd_text?: string; presenceStatus: PresenceStatus }
  > | null = null;
  _userIds: Set<string> | null = null;
  throttledFetch: DebouncedFunc<() => Promise<void>> | null = null;
  timeoutId: number | null = null;

  mounted() {
    this._data = {};
    this._userIds = new Set();
    this.throttledFetch = throttle(this.fetchPresenceData, THROTTLE_TIME, { leading: false });
    this.timeoutId = window.setTimeout(this.throttledFetch, POLL_INTERVAL);

    this.host.on('events:connected', this.onEventsConnected);
  }

  dispose() {
    this._data = null;
    this._userIds = null;
    if (this.timeoutId) clearTimeout(this.timeoutId);

    this.host.removeListener('events:connected', this.onEventsConnected);
  }

  get useWebSockets() {
    return this.host.events.eventSourceIsWebSocket && this.config.allowAvailability;
  }

  fetchPresenceData = async () => {
    if (this.useWebSockets) {
      return;
    }
    if (this.timeoutId) clearTimeout(this.timeoutId);
    if (!this._userIds?.size) return;
    const idGroups = chunk(Array.from(this._userIds.values()), MAX_USERS_PER_REQUEST);
    try {
      for (const idGroup of idGroups) {
        const response: {
          result: {
            userId: string;
            presenceStatus: PresenceStatus;
          }[];
        } = await this.host.api.presence.findMulti({ userIds: idGroup });
        response.result.forEach(({ userId, presenceStatus }) => {
          // https://tigertext.atlassian.net/browse/MSE-2823
          this.set(userId, PresenceStatus.resolve(presenceStatus));
        });
      }
    } finally {
      if (this.throttledFetch) {
        this.timeoutId = window.setTimeout(this.throttledFetch, POLL_INTERVAL);
      }
    }
  };

  addUser(userId: string) {
    if (this.useWebSockets) {
      this.host.api.presence.subscribeUser(userId);
    } else {
      this._userIds?.add(userId);
      this.throttledFetch?.();
    }
  }

  removeUser(userId: string) {
    if (this.useWebSockets) {
      this.host.api.presence.unsubscribeUser(userId);
    } else {
      this._userIds?.delete(userId);
    }
    delete this._data?.[userId];
  }

  set(id: string, presenceStatus: PresenceStatus, dnd?: boolean, dnd_text?: string) {
    if (typeof id !== 'string' || typeof presenceStatus !== 'string') {
      return;
    }
    const hasChanged =
      this._data?.[id]?.presenceStatus !== presenceStatus ||
      this._data?.[id]?.dnd !== dnd ||
      this._data?.[id]?.dnd_text !== dnd_text;

    if (!hasChanged) return;

    if (this._data) this._data[id] = { presenceStatus, dnd, dnd_text };
    const user = this.host.models.User.inject({ id, dnd, dndText: dnd_text, presenceStatus });

    this.emit('change', {
      presenceStatus,
      user,
      userId: id,
    });
  }

  get(id: string) {
    if (id === this.host.currentUserId) return PresenceStatus.AVAILABLE;

    return this._data?.[id] || PresenceStatus.UNKNOWN;
  }

  reactToUserStatusChange(event: {
    data: { dnd: boolean; dnd_text: string; subscribed_user_token: string; status: string };
  }) {
    const { data } = event;
    this.set(
      data.subscribed_user_token,
      PresenceStatus.resolve(data.status),
      data.dnd,
      data.dnd_text
    );
  }

  onEventsConnected = () => {
    if (!this.useWebSockets) {
      return;
    }

    if (this._data) {
      Object.keys(this._data).map((userId) => {
        this.addUser(userId);
      });
    }
  };
}
