import _ from 'lodash';

type Key<K = string> = { key: K };
type ServerKey<K = string | number> = { serverValue: K };
type Entry = Record<string, Key | ServerKey>;
type WrapKeys<T> = {
  [K in keyof T]: Key<T[K]>;
};
type WrapEntries<K extends string> = {
  [k in K]: Key<k>;
};

class __Enum__ {
  keys: string[];
  entries: (Key | (Key & ServerKey))[];
  entriesByKey: Entry;
  defaultValue: null | string;
  [k: string]: unknown;

  constructor(
    entries: string[] | Entry,
    options: { defaultValue?: string; valueFn?: (k: string) => string } = {}
  ) {
    if (Array.isArray(entries)) {
      entries = entries.reduce((a, k) => {
        a[k] = {} as Key;
        return a;
      }, {} as Entry);
    }

    this.keys = [];
    this.entries = [];
    this.entriesByKey = {};
    this.defaultValue = options.defaultValue || null;

    for (const key in entries) {
      this[key] = options.valueFn ? options.valueFn(key) : key;
      const entry = { key, ...entries[key] };
      this.entriesByKey[key] = entry;
      this.entries.push(entry);
      this.keys.push(key);
    }

    Object.freeze(this.keys);
    Object.freeze(this.entries);
    Object.freeze(this.entriesByKey);
  }

  resolve(key?: string) {
    return key || this.defaultValue;
  }

  isValid(key: string) {
    return !!this.entriesByKey[key];
  }
}

type EnumClassProperties<
  Keys extends readonly string[],
  Default extends string | null = null,
  K extends Keys[number] = Keys[number]
> = {
  resolve: (key?: K) => K | Default;
  isValid: (key: string) => boolean;
  entries: WrapKeys<Keys>;
  entriesByKey: WrapEntries<K>;
  keys: Keys;
};

const Enum = __Enum__ as unknown as {
  new <Keys extends readonly string[], Default extends string | null = null>(
    values: Keys,
    options?: { defaultValue?: Default; valueFn?: (k: string) => string }
  ): { [key in Keys[number]]: key } & EnumClassProperties<Keys, Default>;
};

export default Enum;

class __ServerValueEnum__ extends __Enum__ {
  _mapEnumKeyToServerValue!: { [k: string]: string | number };
  _mapServerValueToEnumKey!: { [k: string]: string };

  mapEnumKeyToServerValue() {
    return (
      this._mapEnumKeyToServerValue ||
      (this._mapEnumKeyToServerValue = _.transform(
        this.entries,
        (all, entry) => (all[entry.key] = (entry as ServerKey).serverValue),
        {} as { [k: string]: string | number }
      ))
    );
  }

  mapServerValueToEnumKey() {
    return (
      this._mapServerValueToEnumKey ||
      (this._mapServerValueToEnumKey = _.invert(this.mapEnumKeyToServerValue()))
    );
  }

  fromServer(serverValue: string) {
    return this.mapServerValueToEnumKey()[serverValue];
  }

  toServer(key: string) {
    return this.mapEnumKeyToServerValue()[this.resolve(key) as string];
  }

  resolve(key: string) {
    return (this.isValid(key) ? key : this.fromServer(key)) || this.defaultValue;
  }
}

type ServerEnumClassProperties<
  Keys extends Record<string, ServerKey | Key>,
  Default extends string
> = {
  resolve: (key: string) => keyof Keys | Default;
  isValid: (key: string) => boolean;
  entries: (Key<keyof Keys> & Keys[keyof Keys])[];
  entriesByKey: { [K in keyof Keys]: Keys[K] & Key<K> };
  keys: (keyof Keys)[];

  _mapEnumKeyToServerValue: Record<string, string>;
  mapEnumKeyToServerValue(): string;
  _mapServerValueToEnumKey(): Record<string, string>;
  mapServerValueToEnumKey(): string;
  fromServer(serverValue: string): string;
  toServer(key: string): string;
};

export const ServerValueEnum = __ServerValueEnum__ as unknown as {
  new <Keys extends Record<string, ServerKey | Key>, Default extends string = never>(
    values: Keys,
    options?: { defaultValue?: Default; valueFn?: (k: string) => string }
  ): { [key in keyof Keys]: key } & ServerEnumClassProperties<Keys, Default>;
};
