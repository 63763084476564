import React from 'react';
import Highlighter from 'react-highlight-words';

declare function Classes(elem: string, mods?: { [k: string]: unknown }): string;
// TODO: https://tigertext.atlassian.net/browse/RW-4027
// eslint-disable-next-line no-redeclare
declare function Classes(mods: { [k: string]: unknown }): string;

export default function highlightText(
  str: string,
  highlight: string[],
  classes: typeof Classes,
  className = ''
) {
  if (!highlight) return str;
  if (!str) return null;

  const props = {
    autoEscape: true,
    ...(className ? { className: classes(className) } : {}),
    highlightClassName: classes('highlighted'),
    searchWords: highlight,
    textToHighlight: str,
  };

  return <Highlighter {...props} />;
}
