// @ts-nocheck
import BaseAPI from './BaseAPI';

export default class TwilioAPI extends BaseAPI {
  async getTwilioNumbers(organizationId: string, areaCode: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.post('/v2/twilio/numbers', {
      headers,
      data: {
        area_code: areaCode,
      },
    });
    return res.data;
  }

  async assignPagerNumber(organizationId: string, number: string, roleToken: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.post(
      '/v2/user/:roleToken/organization/:organizationId/pagers?process_number=true',
      {
        headers,
        urlParams: { organizationId, roleToken },
        data: {
          pager_numbers: number,
        },
      }
    );
  }

  async removePagerNumber(organizationId: string, number: string, roleToken: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del(
      '/v2/user/:roleToken/organization/:organizationId/pagers/:number?process_number=true',
      {
        headers,
        urlParams: { organizationId, roleToken, number },
      }
    );
  }
}
