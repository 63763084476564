export default class WrongCredentialsError extends Error {
  static CODE = 'wrong-credentials';

  code: string;
  loginAttemptsLeft: unknown;

  constructor(headers: Record<string, unknown> = {}, ...args: (string | undefined)[]) {
    super(...args);

    const loginAttemptsLeft = headers['tt_login_attempts_left'];

    this.code = WrongCredentialsError.CODE;
    this.loginAttemptsLeft = loginAttemptsLeft === undefined ? null : loginAttemptsLeft;
  }
}
