import React from 'react';
import { isEmpty, uniq } from 'lodash';
import { Button } from '@tigerconnect/web-component-library';
import { ReactComponent as PhoneIcon } from '../../../widgets/messenger/images/provider-call-icon.svg';
import PfCallOrigins from '../../../models/enums/PfCallOrigins';
import { Call } from '../../../types';
import BEM from '../../bem';
import mobxInjectSelect from '../../utils/mobxInjectSelect';

const classes = BEM.with('CallButton');
const PROVIDER_NETWORK_MEMBER_LIMIT = 10;
type Entity = {
  members: { id: string }[];
  memberIds?: string[];
  recipientId: string;
  recipientName: string;
  id?: string;
  metadata?: {
    meta_type?: string;
  };
};
type CallButtonProps = {
  disabled?: boolean;
  entity: Entity;
  origin: string;
};

type MobxProps = {
  currentCall: Call;
  currentUserId: string;
  videoCallSetUp: (...args: unknown[]) => Promise<unknown>;
  findGroup: (groupId: string | undefined) => Promise<Entity>;
  ensureMembersWithIds: (
    groupId: string | undefined,
    memberIds: string[] | undefined
  ) => Promise<Entity>;
  isGroupAlertsAllowed: boolean;
};

function CallButton({
  currentCall,
  currentUserId,
  disabled = false,
  entity,
  findGroup,
  isGroupAlertsAllowed,
  origin,
  videoCallSetUp,
  ensureMembersWithIds,
}: CallButtonProps & MobxProps) {
  const { members = [] } = entity;
  const callMembers = uniq(members.map((m) => m.id).concat([currentUserId]));
  const tooManyMembers = callMembers.length > PROVIDER_NETWORK_MEMBER_LIMIT;
  const isDisabled = disabled || !isEmpty(currentCall) || tooManyMembers;
  const detailsPane = origin === PfCallOrigins.CONVERSATION_DETAILS;

  const onClick = async () => {
    if (isDisabled) return;

    let updatedGroup = entity;

    // TODO: Can remove once OASIS-747 is completed
    if (isGroupAlertsAllowed && entity?.metadata?.meta_type === 'alert_conversation') {
      await ensureMembersWithIds(entity.id, entity.memberIds);
      updatedGroup = await findGroup(entity.id);
    }

    await videoCallSetUp(updatedGroup, origin);
  };

  const id =
    origin === PfCallOrigins.CONVERSATION_DETAILS
      ? 'conv-details-call'
      : origin === PfCallOrigins.CONVERSATION
      ? 'conv-call'
      : '';

  return (
    <div className={classes()}>
      {!detailsPane ? (
        <div id={id} data-test-id={'Phone call button'} onClick={onClick}>
          <Button shape="circle" icon="phone" color="neutral" disabled={isDisabled} />
        </div>
      ) : (
        <button
          className={classes('button', { detailsPane })}
          id={id}
          data-test-id={'Phone call button'}
          onClick={onClick}
        >
          <PhoneIcon className={classes('phone-icon')} />
        </button>
      )}
    </div>
  );
}

export default mobxInjectSelect<CallButtonProps, MobxProps>({
  callStore: ['currentCall', 'videoCallSetUp'],
  sessionStore: ['currentUserId'],
  groupStore: ['findGroup', 'ensureMembersWithIds'],
  messengerStore: ['isGroupAlertsAllowed'],
})(CallButton);
