import * as errors from '../errors';
import BaseService from './BaseService';

export default class WorkflowsService extends BaseService {
  async create({
    active = false,
    label,
    organizationId,
  }: {
    active: boolean;
    label: string;
    organizationId: string;
  }) {
    this._validate({ label, organizationId, skipIdCheck: true });

    const response = await this.host.api.workflows.create({
      active,
      label,
      organizationId,
    });

    const workflow = this.host.models.Workflow.inject({ ...response, organizationId });
    return workflow;
  }

  async createEvent(event: {
    deliveryMethod: string;
    offset: number;
    offsetUnit: string;
    organizationId: string;
    sender: string;
    templateId: string;
    workflowId: string;
  }) {
    this._validateEvent({ ...event, skipIdCheck: true });
    const response = await this.host.api.workflows.createEvent(event);

    const workflowEvent = this.host.models.WorkflowEvent.inject(response);
    return workflowEvent;
  }

  async delete({ id, organizationId }: { id: string; organizationId: string }) {
    this._validate({ id, organizationId, skipDataCheck: true });

    await this.host.api.workflows.delete(id, organizationId);

    const workflow = this.host.models.Workflow.eject({ id });
    return workflow;
  }

  async deleteEvent({
    id,
    workflowId,
    organizationId,
  }: {
    id: string;
    workflowId: string;
    organizationId: string;
  }) {
    this._validate({ id, organizationId, skipDataCheck: true });

    await this.host.api.workflows.deleteEvent(id, workflowId, organizationId);

    const workflowEvent = this.host.models.WorkflowEvent.inject({
      $deleted: true,
      id,
    });
    return workflowEvent;
  }

  async findAll(organizationId: string) {
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    const response = await this.host.api.workflows.findAll(organizationId);

    const workflows = response.map((item: object) =>
      this.host.models.Workflow.inject({ ...item, organizationId })
    );
    return workflows;
  }

  async findAllEvents(workflowId: string, organizationId: string) {
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }
    if (!workflowId) {
      throw new errors.ValidationError('workflowId', 'required');
    }

    const response = await this.host.api.workflows.findAllEvents(workflowId, organizationId);

    const workflowEvents = response.map((item: unknown) =>
      this.host.models.WorkflowEvent.inject(item)
    );
    return workflowEvents;
  }

  getById(workflowId: string) {
    return this.host.models.Workflow.get(workflowId);
  }

  getWorkflowEventById(workflowEventId: string) {
    return this.host.models.WorkflowEvent.get(workflowEventId);
  }

  async update({
    active,
    id,
    label,
    organizationId,
  }: {
    active?: boolean;
    id: string;
    label?: string;
    organizationId: string;
  }) {
    this._validate({ id, organizationId, skipDataCheck: true });

    const response = await this.host.api.workflows.update({
      active,
      id,
      label,
      organizationId,
    });

    const workflow = this.host.models.Workflow.inject({ ...response, organizationId });
    return workflow;
  }

  async updateEvent(event: {
    deliveryMethod: string;
    id: string;
    offset: number;
    offsetUnit: string;
    organizationId: string;
    sender: string;
    templateId: string;
    workflowId: string;
  }) {
    this._validateEvent(event);

    const response = await this.host.api.workflows.updateEvent(event);

    const workflowEvent = this.host.models.WorkflowEvent.inject(response);
    return workflowEvent;
  }

  _validate({
    id,
    label,
    organizationId,
    skipDataCheck = false,
    skipIdCheck = false,
  }: {
    id?: string;
    label?: string;
    organizationId: string;
    skipDataCheck?: boolean;
    skipIdCheck?: boolean;
  }) {
    if (!skipIdCheck && !id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }
    if (!skipDataCheck) {
      if (!label) {
        throw new errors.ValidationError('label', 'required');
      }
    }
  }

  _validateEvent({
    deliveryMethod,
    id,
    offset,
    offsetUnit,
    organizationId,
    sender,
    templateId,
    workflowId,
    skipIdCheck = false,
  }: {
    deliveryMethod: string;
    id?: string;
    offset: number;
    offsetUnit: string;
    organizationId: string;
    sender?: string;
    templateId: string;
    workflowId: string;
    skipIdCheck?: boolean;
  }) {
    if (!skipIdCheck && !id) throw new errors.ValidationError('id', 'required');
    if (!id && !templateId) throw new errors.ValidationError('templateId', 'required');
    if (!workflowId) throw new errors.ValidationError('workflowId', 'required');
    if (!deliveryMethod) throw new errors.ValidationError('deliveryMethod', 'required');
    if (!offset) throw new errors.ValidationError('offset', 'required');
    if (offset === 0) throw new errors.ValidationError('offset', 'invalid', 'offset must not be 0');
    if (!offsetUnit) throw new errors.ValidationError('offsetUnit', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (deliveryMethod !== 'sms' && !sender) throw new errors.ValidationError('sender', 'required');
  }
}
