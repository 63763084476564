// @ts-nocheck
import _keyBy from 'lodash-bound/keyBy';
import _each from 'lodash-bound/each';
import Camelizer from '../utils/Camelizer';
import { FeatureService, GroupType, MessageSubType } from './enums';
import addEnsureEntity from './extensions/ensureEntity';

export const CONTACTS_ORG_ID = 'UpM28mnfW65vJkbqEATtKvxm';

function getSettingValue(settings, key) {
  return key in settings ? settings[key].value : null;
}

export default function (store, { host }) {
  const Organization = store.defineModel('organization', {
    instanceEvents: true,

    replaceKeys: {
      alerts: '__showAlerts',
      allowMsgForward: '__isForwardAllowed',
      allowPublicGroups: 'forumsEnabled',
      allowRoleScheduleUpload: '__isRolesScheduleUploadEnabled',
      desktopPriorityMessaging: '__desktopPriorityMessagingSounds',
      eula: 'eulaEnabled',
      eulaContent: 'eulaUrl',
      featureAlerts: '__canShowAlerts',
      featurePatientCare: '__canShowPatientCare',
      featurePatientNetwork: '__canShowPatientNetwork',
      featurePbxIntegration: '__featurePbxIntegration',
      featurePfEnableSmsScheduleMessage: '__canShowPfDeliveryMethod',
      featureRoleScheduleUpload: '__canUploadRolesSchedule',
      featureRoleService: 'rolesEnabled',
      featureRolesEscalation: '__canEscalateMessages',
      featureTeams: '__canShowTeams',
      featureTransactions: 'canShowTransactions',
      hideNewPblUi: '__hideNewPblUi',
      latestIncomingMessageSortNumber: 'lastIncomingMessageSortNumber',
      members: 'memberCount',
      organizationKey: 'organizationId',
      otpAllowConfigure: '__otpAllowConfigure',
      patientCare: '__showPatientCare',
      patientNetwork: '__showPatientNetwork',
      pfEnableSmsScheduleMessage: '__showPfDeliveryMethod',
      pfNoAttachment: '__isPFAttachmentAllowed',
      pfNoPhoto: '__isPFPhotoAllowed',
      roleService: 'canAdminRoles',
      rolesEscalation: '__isRolesEscalationEnabled',
      ssoWebSessionDuration: 'desktopExtendedSession',
      teams: '__showTeams',
    },

    // Additional unused settings:
    //
    // pin_lock (optional): Default PIN lock setting for organization users
    // settings_restricted (optional): Can organization users change their settings?
    // lifecycle_callback (optional): Rest call to be invoked when adding new user to the organization
    // group_lifespan (optional): The number of minutes a group should live after the last message has been sent, -1 turns off group_lifespan. Affects all existing groups in the org.
    // welcome_email_settings (optional): A JSON object that contains the welcome email settings (with the fields "from", "subject", "optional_header", "optional_message", "optional_name")
    // welcome_email_logo (optional): A base 64 encoding of an image. Can be set only with the application/json format
    // auto_send_welcome_email (optional): A boolean value indicating whether welcome emails get sent automatically on accounts joining orgs or not

    defaultValues: {
      __canEscalateMessages: false,
      __canShowAlerts: false,
      __canShowPatientCare: false,
      __canShowTeams: false,
      __canUploadRolesSchedule: false,
      __hideNewPblUi: false,
      __featurePbxIntegration: false,
      __otpAllowConfigure: false,
      __isForwardAllowed: false,
      __isPFAttachmentAllowed: false,
      __isPFPhotoAllowed: false,
      __isRolesEscalationEnabled: false,
      __isRolesScheduleUploadEnabled: false,
      __showAlerts: false,
      __showPatientCare: false,
      __showTeams: false,
      canAdminRoles: false,
      canShowTransactions: false,
      desktopExtendedSession: -1,
      forumsEnabled: false,
      highestSortNumber: 0,
      isPatientNetworkEnabled: false,
      lastIncomingMessageSortNumber: 0,
      networks: null,
      paid: false,
      rolesEnabled: false,
      settings: {},
      unreadAlertsCount: 0,
      unreadCount: 0,
      unreadPriorityCount: 0,
      virtualWaitingRoomUnreadCount: 0,
    },

    transientAttrs: [
      'unreadAlertsCount',
      'unreadCount',
      'unreadPriorityCount',
      'virtualWaitingRoomUnreadCount',
    ],

    parseAttrs(attrs) {
      if (attrs.settings) {
        attrs.settings = _each.call(
          _keyBy.call(attrs.settings, (e) => Camelizer.camelizeKey(e.name)),
          (e) => {
            if (isFinite(e.value)) e.value = +e.value;
            if (e.value === 'true') e.value = true;
            if (e.value === 'false') e.value = false;
          }
        );
      }

      return attrs;
    },

    afterAssignment(entity) {
      let {
        __canEscalateMessages,
        __canShowAlerts,
        __canShowPatientCare,
        __canShowPatientNetwork,
        __canShowPfDeliveryMethod,
        __canShowTeams,
        __canUploadRolesSchedule,
        __desktopPriorityMessagingSounds,
        __hideNewPblUi,
        __featurePbxIntegration,
        __isForwardAllowed,
        __isPFAttachmentAllowed,
        __isPFPhotoAllowed,
        __isRolesEscalationEnabled,
        __isRolesScheduleUploadEnabled,
        __otpAllowConfigure,
        __showAlerts,
        __showPatientCare,
        __showPatientNetwork,
        __showPfDeliveryMethod,
        __showTeams,
        autoForwardEntitiesIds,
        autoForwardReceiverId,
        id,
        name,
        paid,
        settings,
      } = entity;

      __showTeams = __showTeams && !!getSettingValue(settings, 'teams');
      __showPatientNetwork =
        __showPatientNetwork === 'individual'
          ? !!getSettingValue(settings, 'patientNetwork')
          : !!__showPatientNetwork;
      autoForwardEntitiesIds = getSettingValue(settings, 'dndAutoForwardEntities');
      autoForwardReceiverId = getSettingValue(settings, 'dndAutoForwardReceiver');
      entity.allowPriorityMessage = !!getSettingValue(settings, 'priorityMessaging');
      entity.autoLogoutMinutes = getSettingValue(settings, 'autologoutTime') || 1440; // 24 hours
      entity.canUploadRolesSchedule = __canUploadRolesSchedule && __isRolesScheduleUploadEnabled;
      entity.deleteOnRead = !!getSettingValue(settings, 'dor');
      entity.desktopAutoUpdate = !!getSettingValue(settings, 'autoUpdate');
      entity.hideNewPblUi = __hideNewPblUi;
      entity.featurePbxIntegration = __featurePbxIntegration;
      entity.otpAllowConfigure = __otpAllowConfigure;
      entity.isEulaAccepted = !getSettingValue(settings, 'eula'); // True for displaying the prompt and false to never display it again
      entity.isForwardAllowed = __isForwardAllowed && getSettingValue(settings, 'forward') === 1;
      entity.messageTimeToLive = getSettingValue(settings, 'ttl') || -1;
      entity.isAutomatedAppointmentRemindersEnabled = !!getSettingValue(
        settings,
        'pfAutoAppointmentReminder'
      );
      entity.isPatientBroadcastEnabled = !!getSettingValue(settings, 'pfBroadcast');
      entity.isPatientScheduledMessagesEnabled = !!getSettingValue(settings, 'pfScheduleMessages');
      entity.isPatientListAccessEnabled = !!getSettingValue(settings, 'pfPatientListAccess');
      entity.isPatientVirtualWaitingRoomEnabled = !!getSettingValue(
        settings,
        'pfVirtualWaitingRoom'
      );
      entity.isPFVideoCallEnabled = !!getSettingValue(settings, 'pfVideoCall');
      entity.isPFVoiceCallEnabled = !!getSettingValue(settings, 'pfVoiceCall');
      entity.isPFGroupVideoCallEnabled = !!getSettingValue(settings, 'pfGroupVideoCall');
      entity.isPFGroupVoiceCallEnabled = !!getSettingValue(settings, 'pfGroupVoiceCall');
      entity.isPFAttachmentAllowed = !__isPFAttachmentAllowed;
      entity.isPFPhotoAllowed = !__isPFPhotoAllowed;
      entity.isVoiceCallEnabled = !!getSettingValue(settings, 'voip');
      entity.isVideoCallEnabled = !!getSettingValue(settings, 'videoCall');
      entity.isGroupVoiceCallEnabled = !!getSettingValue(settings, 'groupAudioCall');
      entity.isGroupVideoCallEnabled = !!getSettingValue(settings, 'groupVideoCall');
      entity.isPatientContextEnabled = !!getSettingValue(settings, 'patientContext');

      const isContacts = entity.id === CONTACTS_ORG_ID;
      entity.allowedSenders = host.organizations._getAllowedSenders(entity);
      entity.autoForwardEntitiesIds = autoForwardEntitiesIds;
      entity.autoForwardReceiverId = autoForwardReceiverId;
      entity.autoForwardReceiver = autoForwardReceiverId
        ? host.models.User.get(autoForwardReceiverId)
        : null;
      entity.canEscalateMessages = __canEscalateMessages && __isRolesEscalationEnabled;
      entity.desktopPriorityMessagingSounds = paid && __desktopPriorityMessagingSounds;
      entity.displayName = isContacts ? 'Contacts' : name;
      entity.isContacts = isContacts;
      entity.isPatientDeliveryMethodEnabled = __canShowPfDeliveryMethod && __showPfDeliveryMethod;
      entity.isPatientNetworkEnabled = __canShowPatientNetwork && __showPatientNetwork;
      const userIsPatientOrContact =
        host.currentUser && (host.currentUser.isPatient || host.currentUser.isPatientContact);
      if (userIsPatientOrContact && __canShowPatientNetwork) {
        entity.isPatientNetworkEnabled = __showPatientNetwork !== 'off';
      }
      entity.showAlerts = __canShowAlerts && __showAlerts;
      entity.showPatientCare = __canShowPatientCare && __showPatientCare;
      entity.showTeams = __canShowTeams && __showTeams;
      entity.userRoles = host.userRoles.getByOrganizationId(id);

      if (!entity.networks) entity.networks = {};

      if (!entity.networks.provider) {
        entity.networks.provider = {
          unreadCount: 0,
          unreadPriorityCount: 0,
        };
      }

      if (!entity.networks.patient) {
        entity.networks.patient = {
          dnd: false,
          dndText: '',
          unreadCount: 0,
          unreadPriorityCount: 0,
        };
      }

      if (entity.network) {
        const { dnd, dndText } = entity.network.patient;
        entity.networks.patient.dnd = dnd;
        entity.networks.patient.dndText = dndText !== null ? dndText : '';
        delete entity.network;
      }
    },

    methods: {
      getSettingValue(key) {
        return getSettingValue(this.settings, key);
      },
      getSettingEntry(key) {
        return this.settings && key in this.settings ? this.settings[key] : null;
      },
    },

    relations: {
      hasMany: {
        __expirableMessages: {
          type: 'message',
          foreignKey: '__organizationId',
          filter: (message) => !!message.expiresAt,
          sortBy: [
            ['expiresAt', 'asc'],
            ['id', 'asc'],
          ],
        },
        __expirableMutes: {
          type: 'mute',
          foreignKey: 'organizationId',
          filter: (mute) => !!mute.expiresAt,
          sortBy: [
            ['expiresAt', 'asc'],
            ['id', 'asc'],
          ],
        },
        __markingAsReadConversations: {
          type: 'conversation',
          foreignKey: 'organizationId',
          filter: (conversation) => !!conversation._markingAsRead,
          sortBy: [
            ['_markingAsReadExpiration', 'asc'],
            ['id', 'asc'],
          ],
        },
        alertMessages: {
          type: 'message',
          foreignKey: '__organizationId',
          filter: (message) => {
            // TODO: Remove CStore workaround when linked server blockers in RW-5506 are done
            if (host.config.condensedReplays) {
              return !!message.alertAttrs;
            } else {
              return [MessageSubType.ALERTS, MessageSubType.ROLE_ALERTS].includes(message.subType);
            }
          },
          sortBy: [
            ['sortNumber', 'desc'],
            ['id', 'asc'],
          ],
        },
        alertConversations: {
          type: 'conversation',
          foreignKey: 'organizationId',
          filter: (message) => {
            return [
              FeatureService.ALERTS,
              FeatureService.GROUP_ALERTS,
              FeatureService.ROLE_ALERTS,
            ].includes(message.featureService);
          },
          sortBy: [
            ['highestSortNumber', 'desc'],
            ['_origIndex', 'asc'],
            ['id', 'asc'],
          ],
        },
        conversations: {
          type: 'conversation',
          foreignKey: 'organizationId',
          filter: (conversation) => {
            const { counterParty } = conversation;
            if (!counterParty) return false;

            const { groupType } = counterParty;
            if (groupType === GroupType.ROLE_ASSIGNMENT) return false;

            return true;
          },
          sortBy: [
            ['highestSortNumber', 'desc'],
            ['_origIndex', 'asc'],
            ['id', 'asc'],
          ],
        },
        messageTemplates: {
          type: 'messageTemplate',
          foreignKey: 'organizationId',
          sortBy: [['title', 'asc']],
        },
        roles: {
          type: 'role',
          foreignKey: 'organizationId',
        },
        tags: {
          type: 'tag',
          foreignKey: 'organizationId',
        },
      },
    },
  });

  addEnsureEntity(Organization, {
    finder: (...args) => host.organizations.find(...args),
    placeholderEntityDelayBeforeRefreshing() {
      return host.config.placeholderEntityDelayBeforeRefreshing;
    },
    placeholderEntityAllowLoading() {
      return host.config.placeholderEntityAllowLoading;
    },

    defaultPlaceholderAttrs: { name: 'Loading Organization...' },
    defaultNotFoundAttrs: { displayName: '<Organization Not Found>' },
  });

  return Organization;
}
