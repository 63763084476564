// @ts-ignore

if (!('find' in Array.prototype)) throw new Error('Missing polyfill for Array.find');
if (!('from' in Array)) throw new Error('Missing polyfill for Array.from');
if (!('includes' in Array.prototype)) throw new Error('Missing polyfill for Array.includes');
if (!('assign' in Object)) throw new Error('Missing polyfill for Object.assign');
if (!('entries' in Object)) throw new Error('Missing polyfill for Object.entries');
if (!('values' in Object)) throw new Error('Missing polyfill for Object.values');
if (!('endsWith' in String.prototype)) throw new Error('Missing polyfill for String.endsWith');
if (!('includes' in String.prototype)) throw new Error('Missing polyfill for String.includes');
if (typeof Symbol === 'undefined') throw new Error('Missing polyfill for Symbol');
if (typeof Promise === 'undefined') throw new Error('Missing polyfill for Promise');
if (!('finally' in Promise.prototype)) throw new Error('Missing polyfill for Promise.finally');

if (typeof AbortController === 'undefined') throw new Error('Missing polyfill for AbortController');
if (typeof fetch === 'undefined') throw new Error('Missing polyfill for fetch');
if (typeof Headers === 'undefined') {
  throw new Error('Missing polyfill for Headers (part of fetch polyfill)');
}
if (typeof Request === 'undefined') {
  throw new Error('Missing polyfill for Request (part of fetch polyfill)');
}
if (typeof Response === 'undefined') {
  throw new Error('Missing polyfill for Response (part of fetch polyfill)');
}
