import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../bem';
import propTypes from '../propTypes';
import { MessageRecipientStatus, MessageSenderStatus } from './';

const classes = BEM.with('MessageStatus');

class MessageStatus extends Component {
  static propTypes = {
    isClickable: PropTypes.bool,
    message: propTypes.message.isRequired,
  };

  render() {
    const { isClickable, message } = this.props;
    const { counterPartyType, counterParty, senderStatus } = message;
    const groupType = counterParty && counterParty.groupType;
    const useRecipientStatus =
      senderStatus === 'SENT' &&
      (counterPartyType === 'user' ||
        (counterPartyType === 'group' &&
          (groupType === 'GROUP' ||
            groupType === 'ROLE_P2P' ||
            groupType === 'ESCALATION' ||
            groupType === 'PATIENT_CARE' ||
            groupType === 'PATIENT_MESSAGING' ||
            groupType === 'INTRA_TEAM' ||
            groupType === 'ACTIVATED_TEAM')));
    const Status = useRecipientStatus ? MessageRecipientStatus : MessageSenderStatus;

    return <Status className={classes()} isClickable={isClickable} message={message} />;
  }
}

export default observer(MessageStatus);
