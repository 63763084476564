//Generated from https://github.com/tigertext/emoji
type EmojiMapItem = {
  hexCodePoints: string;
  qualifiedStatus: 'fully-qualified' | 'minimally-qualified' | 'unqualified';
  emoji: string;
  version: string;
  baseName: string;
  fullName: string;
  isBase: boolean;
  baseHex: string;
  modifiers: null | {
    '1f3fb': string;
    '1f3fc': string;
    '1f3fd': string;
    '1f3fe': string;
    '1f3ff': string;
  };
};

export const emojiMap: Record<string, EmojiMapItem> = {
  '1f600': {
    hexCodePoints: '1f600',
    qualifiedStatus: 'fully-qualified',
    emoji: '😀',
    version: 'E1.0',
    baseName: 'grinning face',
    fullName: 'grinning face',
    isBase: true,
    baseHex: '1f600',
    modifiers: null,
  },
  '1f603': {
    hexCodePoints: '1f603',
    qualifiedStatus: 'fully-qualified',
    emoji: '😃',
    version: 'E0.6',
    baseName: 'grinning face with big eyes',
    fullName: 'grinning face with big eyes',
    isBase: true,
    baseHex: '1f603',
    modifiers: null,
  },
  '1f604': {
    hexCodePoints: '1f604',
    qualifiedStatus: 'fully-qualified',
    emoji: '😄',
    version: 'E0.6',
    baseName: 'grinning face with smiling eyes',
    fullName: 'grinning face with smiling eyes',
    isBase: true,
    baseHex: '1f604',
    modifiers: null,
  },
  '1f601': {
    hexCodePoints: '1f601',
    qualifiedStatus: 'fully-qualified',
    emoji: '😁',
    version: 'E0.6',
    baseName: 'beaming face with smiling eyes',
    fullName: 'beaming face with smiling eyes',
    isBase: true,
    baseHex: '1f601',
    modifiers: null,
  },
  '1f606': {
    hexCodePoints: '1f606',
    qualifiedStatus: 'fully-qualified',
    emoji: '😆',
    version: 'E0.6',
    baseName: 'grinning squinting face',
    fullName: 'grinning squinting face',
    isBase: true,
    baseHex: '1f606',
    modifiers: null,
  },
  '1f605': {
    hexCodePoints: '1f605',
    qualifiedStatus: 'fully-qualified',
    emoji: '😅',
    version: 'E0.6',
    baseName: 'grinning face with sweat',
    fullName: 'grinning face with sweat',
    isBase: true,
    baseHex: '1f605',
    modifiers: null,
  },
  '1f923': {
    hexCodePoints: '1f923',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤣',
    version: 'E3.0',
    baseName: 'rolling on the floor laughing',
    fullName: 'rolling on the floor laughing',
    isBase: true,
    baseHex: '1f923',
    modifiers: null,
  },
  '1f602': {
    hexCodePoints: '1f602',
    qualifiedStatus: 'fully-qualified',
    emoji: '😂',
    version: 'E0.6',
    baseName: 'face with tears of joy',
    fullName: 'face with tears of joy',
    isBase: true,
    baseHex: '1f602',
    modifiers: null,
  },
  '1f642': {
    hexCodePoints: '1f642',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙂',
    version: 'E1.0',
    baseName: 'slightly smiling face',
    fullName: 'slightly smiling face',
    isBase: true,
    baseHex: '1f642',
    modifiers: null,
  },
  '1f643': {
    hexCodePoints: '1f643',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙃',
    version: 'E1.0',
    baseName: 'upside-down face',
    fullName: 'upside-down face',
    isBase: true,
    baseHex: '1f643',
    modifiers: null,
  },
  '1fae0': {
    hexCodePoints: '1fae0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫠',
    version: 'E14.0',
    baseName: 'melting face',
    fullName: 'melting face',
    isBase: true,
    baseHex: '1fae0',
    modifiers: null,
  },
  '1f609': {
    hexCodePoints: '1f609',
    qualifiedStatus: 'fully-qualified',
    emoji: '😉',
    version: 'E0.6',
    baseName: 'winking face',
    fullName: 'winking face',
    isBase: true,
    baseHex: '1f609',
    modifiers: null,
  },
  '1f60a': {
    hexCodePoints: '1f60a',
    qualifiedStatus: 'fully-qualified',
    emoji: '😊',
    version: 'E0.6',
    baseName: 'smiling face with smiling eyes',
    fullName: 'smiling face with smiling eyes',
    isBase: true,
    baseHex: '1f60a',
    modifiers: null,
  },
  '1f607': {
    hexCodePoints: '1f607',
    qualifiedStatus: 'fully-qualified',
    emoji: '😇',
    version: 'E1.0',
    baseName: 'smiling face with halo',
    fullName: 'smiling face with halo',
    isBase: true,
    baseHex: '1f607',
    modifiers: null,
  },
  '1f970': {
    hexCodePoints: '1f970',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥰',
    version: 'E11.0',
    baseName: 'smiling face with hearts',
    fullName: 'smiling face with hearts',
    isBase: true,
    baseHex: '1f970',
    modifiers: null,
  },
  '1f60d': {
    hexCodePoints: '1f60d',
    qualifiedStatus: 'fully-qualified',
    emoji: '😍',
    version: 'E0.6',
    baseName: 'smiling face with heart-eyes',
    fullName: 'smiling face with heart-eyes',
    isBase: true,
    baseHex: '1f60d',
    modifiers: null,
  },
  '1f929': {
    hexCodePoints: '1f929',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤩',
    version: 'E5.0',
    baseName: 'star-struck',
    fullName: 'star-struck',
    isBase: true,
    baseHex: '1f929',
    modifiers: null,
  },
  '1f618': {
    hexCodePoints: '1f618',
    qualifiedStatus: 'fully-qualified',
    emoji: '😘',
    version: 'E0.6',
    baseName: 'face blowing a kiss',
    fullName: 'face blowing a kiss',
    isBase: true,
    baseHex: '1f618',
    modifiers: null,
  },
  '1f617': {
    hexCodePoints: '1f617',
    qualifiedStatus: 'fully-qualified',
    emoji: '😗',
    version: 'E1.0',
    baseName: 'kissing face',
    fullName: 'kissing face',
    isBase: true,
    baseHex: '1f617',
    modifiers: null,
  },
  '263a-fe0f': {
    hexCodePoints: '263a-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☺️',
    version: 'E0.6',
    baseName: 'smiling face',
    fullName: 'smiling face',
    isBase: true,
    baseHex: '263a-fe0f',
    modifiers: null,
  },
  '263a': {
    hexCodePoints: '263a',
    qualifiedStatus: 'unqualified',
    emoji: '☺',
    version: 'E0.6',
    baseName: 'smiling face',
    fullName: 'smiling face',
    isBase: false,
    baseHex: '263a-fe0f',
    modifiers: null,
  },
  '1f61a': {
    hexCodePoints: '1f61a',
    qualifiedStatus: 'fully-qualified',
    emoji: '😚',
    version: 'E0.6',
    baseName: 'kissing face with closed eyes',
    fullName: 'kissing face with closed eyes',
    isBase: true,
    baseHex: '1f61a',
    modifiers: null,
  },
  '1f619': {
    hexCodePoints: '1f619',
    qualifiedStatus: 'fully-qualified',
    emoji: '😙',
    version: 'E1.0',
    baseName: 'kissing face with smiling eyes',
    fullName: 'kissing face with smiling eyes',
    isBase: true,
    baseHex: '1f619',
    modifiers: null,
  },
  '1f972': {
    hexCodePoints: '1f972',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥲',
    version: 'E13.0',
    baseName: 'smiling face with tear',
    fullName: 'smiling face with tear',
    isBase: true,
    baseHex: '1f972',
    modifiers: null,
  },
  '1f60b': {
    hexCodePoints: '1f60b',
    qualifiedStatus: 'fully-qualified',
    emoji: '😋',
    version: 'E0.6',
    baseName: 'face savoring food',
    fullName: 'face savoring food',
    isBase: true,
    baseHex: '1f60b',
    modifiers: null,
  },
  '1f61b': {
    hexCodePoints: '1f61b',
    qualifiedStatus: 'fully-qualified',
    emoji: '😛',
    version: 'E1.0',
    baseName: 'face with tongue',
    fullName: 'face with tongue',
    isBase: true,
    baseHex: '1f61b',
    modifiers: null,
  },
  '1f61c': {
    hexCodePoints: '1f61c',
    qualifiedStatus: 'fully-qualified',
    emoji: '😜',
    version: 'E0.6',
    baseName: 'winking face with tongue',
    fullName: 'winking face with tongue',
    isBase: true,
    baseHex: '1f61c',
    modifiers: null,
  },
  '1f92a': {
    hexCodePoints: '1f92a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤪',
    version: 'E5.0',
    baseName: 'zany face',
    fullName: 'zany face',
    isBase: true,
    baseHex: '1f92a',
    modifiers: null,
  },
  '1f61d': {
    hexCodePoints: '1f61d',
    qualifiedStatus: 'fully-qualified',
    emoji: '😝',
    version: 'E0.6',
    baseName: 'squinting face with tongue',
    fullName: 'squinting face with tongue',
    isBase: true,
    baseHex: '1f61d',
    modifiers: null,
  },
  '1f911': {
    hexCodePoints: '1f911',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤑',
    version: 'E1.0',
    baseName: 'money-mouth face',
    fullName: 'money-mouth face',
    isBase: true,
    baseHex: '1f911',
    modifiers: null,
  },
  '1f917': {
    hexCodePoints: '1f917',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤗',
    version: 'E1.0',
    baseName: 'smiling face with open hands',
    fullName: 'smiling face with open hands',
    isBase: true,
    baseHex: '1f917',
    modifiers: null,
  },
  '1f92d': {
    hexCodePoints: '1f92d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤭',
    version: 'E5.0',
    baseName: 'face with hand over mouth',
    fullName: 'face with hand over mouth',
    isBase: true,
    baseHex: '1f92d',
    modifiers: null,
  },
  '1fae2': {
    hexCodePoints: '1fae2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫢',
    version: 'E14.0',
    baseName: 'face with open eyes and hand over mouth',
    fullName: 'face with open eyes and hand over mouth',
    isBase: true,
    baseHex: '1fae2',
    modifiers: null,
  },
  '1fae3': {
    hexCodePoints: '1fae3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫣',
    version: 'E14.0',
    baseName: 'face with peeking eye',
    fullName: 'face with peeking eye',
    isBase: true,
    baseHex: '1fae3',
    modifiers: null,
  },
  '1f92b': {
    hexCodePoints: '1f92b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤫',
    version: 'E5.0',
    baseName: 'shushing face',
    fullName: 'shushing face',
    isBase: true,
    baseHex: '1f92b',
    modifiers: null,
  },
  '1f914': {
    hexCodePoints: '1f914',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤔',
    version: 'E1.0',
    baseName: 'thinking face',
    fullName: 'thinking face',
    isBase: true,
    baseHex: '1f914',
    modifiers: null,
  },
  '1fae1': {
    hexCodePoints: '1fae1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫡',
    version: 'E14.0',
    baseName: 'saluting face',
    fullName: 'saluting face',
    isBase: true,
    baseHex: '1fae1',
    modifiers: null,
  },
  '1f910': {
    hexCodePoints: '1f910',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤐',
    version: 'E1.0',
    baseName: 'zipper-mouth face',
    fullName: 'zipper-mouth face',
    isBase: true,
    baseHex: '1f910',
    modifiers: null,
  },
  '1f928': {
    hexCodePoints: '1f928',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤨',
    version: 'E5.0',
    baseName: 'face with raised eyebrow',
    fullName: 'face with raised eyebrow',
    isBase: true,
    baseHex: '1f928',
    modifiers: null,
  },
  '1f610': {
    hexCodePoints: '1f610',
    qualifiedStatus: 'fully-qualified',
    emoji: '😐',
    version: 'E0.7',
    baseName: 'neutral face',
    fullName: 'neutral face',
    isBase: true,
    baseHex: '1f610',
    modifiers: null,
  },
  '1f611': {
    hexCodePoints: '1f611',
    qualifiedStatus: 'fully-qualified',
    emoji: '😑',
    version: 'E1.0',
    baseName: 'expressionless face',
    fullName: 'expressionless face',
    isBase: true,
    baseHex: '1f611',
    modifiers: null,
  },
  '1f636': {
    hexCodePoints: '1f636',
    qualifiedStatus: 'fully-qualified',
    emoji: '😶',
    version: 'E1.0',
    baseName: 'face without mouth',
    fullName: 'face without mouth',
    isBase: true,
    baseHex: '1f636',
    modifiers: null,
  },
  '1fae5': {
    hexCodePoints: '1fae5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫥',
    version: 'E14.0',
    baseName: 'dotted line face',
    fullName: 'dotted line face',
    isBase: true,
    baseHex: '1fae5',
    modifiers: null,
  },
  '1f636-200d-1f32b-fe0f': {
    hexCodePoints: '1f636-200d-1f32b-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '😶‍🌫️',
    version: 'E13.1',
    baseName: 'face in clouds',
    fullName: 'face in clouds',
    isBase: true,
    baseHex: '1f636-200d-1f32b-fe0f',
    modifiers: null,
  },
  '1f636-200d-1f32b': {
    hexCodePoints: '1f636-200d-1f32b',
    qualifiedStatus: 'minimally-qualified',
    emoji: '😶‍🌫',
    version: 'E13.1',
    baseName: 'face in clouds',
    fullName: 'face in clouds',
    isBase: false,
    baseHex: '1f636-200d-1f32b-fe0f',
    modifiers: null,
  },
  '1f60f': {
    hexCodePoints: '1f60f',
    qualifiedStatus: 'fully-qualified',
    emoji: '😏',
    version: 'E0.6',
    baseName: 'smirking face',
    fullName: 'smirking face',
    isBase: true,
    baseHex: '1f60f',
    modifiers: null,
  },
  '1f612': {
    hexCodePoints: '1f612',
    qualifiedStatus: 'fully-qualified',
    emoji: '😒',
    version: 'E0.6',
    baseName: 'unamused face',
    fullName: 'unamused face',
    isBase: true,
    baseHex: '1f612',
    modifiers: null,
  },
  '1f644': {
    hexCodePoints: '1f644',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙄',
    version: 'E1.0',
    baseName: 'face with rolling eyes',
    fullName: 'face with rolling eyes',
    isBase: true,
    baseHex: '1f644',
    modifiers: null,
  },
  '1f62c': {
    hexCodePoints: '1f62c',
    qualifiedStatus: 'fully-qualified',
    emoji: '😬',
    version: 'E1.0',
    baseName: 'grimacing face',
    fullName: 'grimacing face',
    isBase: true,
    baseHex: '1f62c',
    modifiers: null,
  },
  '1f62e-200d-1f4a8': {
    hexCodePoints: '1f62e-200d-1f4a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '😮‍💨',
    version: 'E13.1',
    baseName: 'face exhaling',
    fullName: 'face exhaling',
    isBase: true,
    baseHex: '1f62e-200d-1f4a8',
    modifiers: null,
  },
  '1f925': {
    hexCodePoints: '1f925',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤥',
    version: 'E3.0',
    baseName: 'lying face',
    fullName: 'lying face',
    isBase: true,
    baseHex: '1f925',
    modifiers: null,
  },
  '1fae8': {
    hexCodePoints: '1fae8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫨',
    version: 'E15.0',
    baseName: 'shaking face',
    fullName: 'shaking face',
    isBase: true,
    baseHex: '1fae8',
    modifiers: null,
  },
  '1f642-200d-2194-fe0f': {
    hexCodePoints: '1f642-200d-2194-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙂‍↔️',
    version: 'E15.1',
    baseName: 'head shaking horizontally',
    fullName: 'head shaking horizontally',
    isBase: true,
    baseHex: '1f642-200d-2194-fe0f',
    modifiers: null,
  },
  '1f642-200d-2194': {
    hexCodePoints: '1f642-200d-2194',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙂‍↔',
    version: 'E15.1',
    baseName: 'head shaking horizontally',
    fullName: 'head shaking horizontally',
    isBase: false,
    baseHex: '1f642-200d-2194-fe0f',
    modifiers: null,
  },
  '1f642-200d-2195-fe0f': {
    hexCodePoints: '1f642-200d-2195-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙂‍↕️',
    version: 'E15.1',
    baseName: 'head shaking vertically',
    fullName: 'head shaking vertically',
    isBase: true,
    baseHex: '1f642-200d-2195-fe0f',
    modifiers: null,
  },
  '1f642-200d-2195': {
    hexCodePoints: '1f642-200d-2195',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙂‍↕',
    version: 'E15.1',
    baseName: 'head shaking vertically',
    fullName: 'head shaking vertically',
    isBase: false,
    baseHex: '1f642-200d-2195-fe0f',
    modifiers: null,
  },
  '1f60c': {
    hexCodePoints: '1f60c',
    qualifiedStatus: 'fully-qualified',
    emoji: '😌',
    version: 'E0.6',
    baseName: 'relieved face',
    fullName: 'relieved face',
    isBase: true,
    baseHex: '1f60c',
    modifiers: null,
  },
  '1f614': {
    hexCodePoints: '1f614',
    qualifiedStatus: 'fully-qualified',
    emoji: '😔',
    version: 'E0.6',
    baseName: 'pensive face',
    fullName: 'pensive face',
    isBase: true,
    baseHex: '1f614',
    modifiers: null,
  },
  '1f62a': {
    hexCodePoints: '1f62a',
    qualifiedStatus: 'fully-qualified',
    emoji: '😪',
    version: 'E0.6',
    baseName: 'sleepy face',
    fullName: 'sleepy face',
    isBase: true,
    baseHex: '1f62a',
    modifiers: null,
  },
  '1f924': {
    hexCodePoints: '1f924',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤤',
    version: 'E3.0',
    baseName: 'drooling face',
    fullName: 'drooling face',
    isBase: true,
    baseHex: '1f924',
    modifiers: null,
  },
  '1f634': {
    hexCodePoints: '1f634',
    qualifiedStatus: 'fully-qualified',
    emoji: '😴',
    version: 'E1.0',
    baseName: 'sleeping face',
    fullName: 'sleeping face',
    isBase: true,
    baseHex: '1f634',
    modifiers: null,
  },
  '1f637': {
    hexCodePoints: '1f637',
    qualifiedStatus: 'fully-qualified',
    emoji: '😷',
    version: 'E0.6',
    baseName: 'face with medical mask',
    fullName: 'face with medical mask',
    isBase: true,
    baseHex: '1f637',
    modifiers: null,
  },
  '1f912': {
    hexCodePoints: '1f912',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤒',
    version: 'E1.0',
    baseName: 'face with thermometer',
    fullName: 'face with thermometer',
    isBase: true,
    baseHex: '1f912',
    modifiers: null,
  },
  '1f915': {
    hexCodePoints: '1f915',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤕',
    version: 'E1.0',
    baseName: 'face with head-bandage',
    fullName: 'face with head-bandage',
    isBase: true,
    baseHex: '1f915',
    modifiers: null,
  },
  '1f922': {
    hexCodePoints: '1f922',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤢',
    version: 'E3.0',
    baseName: 'nauseated face',
    fullName: 'nauseated face',
    isBase: true,
    baseHex: '1f922',
    modifiers: null,
  },
  '1f92e': {
    hexCodePoints: '1f92e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤮',
    version: 'E5.0',
    baseName: 'face vomiting',
    fullName: 'face vomiting',
    isBase: true,
    baseHex: '1f92e',
    modifiers: null,
  },
  '1f927': {
    hexCodePoints: '1f927',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤧',
    version: 'E3.0',
    baseName: 'sneezing face',
    fullName: 'sneezing face',
    isBase: true,
    baseHex: '1f927',
    modifiers: null,
  },
  '1f975': {
    hexCodePoints: '1f975',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥵',
    version: 'E11.0',
    baseName: 'hot face',
    fullName: 'hot face',
    isBase: true,
    baseHex: '1f975',
    modifiers: null,
  },
  '1f976': {
    hexCodePoints: '1f976',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥶',
    version: 'E11.0',
    baseName: 'cold face',
    fullName: 'cold face',
    isBase: true,
    baseHex: '1f976',
    modifiers: null,
  },
  '1f974': {
    hexCodePoints: '1f974',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥴',
    version: 'E11.0',
    baseName: 'woozy face',
    fullName: 'woozy face',
    isBase: true,
    baseHex: '1f974',
    modifiers: null,
  },
  '1f635': {
    hexCodePoints: '1f635',
    qualifiedStatus: 'fully-qualified',
    emoji: '😵',
    version: 'E0.6',
    baseName: 'face with crossed-out eyes',
    fullName: 'face with crossed-out eyes',
    isBase: true,
    baseHex: '1f635',
    modifiers: null,
  },
  '1f635-200d-1f4ab': {
    hexCodePoints: '1f635-200d-1f4ab',
    qualifiedStatus: 'fully-qualified',
    emoji: '😵‍💫',
    version: 'E13.1',
    baseName: 'face with spiral eyes',
    fullName: 'face with spiral eyes',
    isBase: true,
    baseHex: '1f635-200d-1f4ab',
    modifiers: null,
  },
  '1f92f': {
    hexCodePoints: '1f92f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤯',
    version: 'E5.0',
    baseName: 'exploding head',
    fullName: 'exploding head',
    isBase: true,
    baseHex: '1f92f',
    modifiers: null,
  },
  '1f920': {
    hexCodePoints: '1f920',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤠',
    version: 'E3.0',
    baseName: 'cowboy hat face',
    fullName: 'cowboy hat face',
    isBase: true,
    baseHex: '1f920',
    modifiers: null,
  },
  '1f973': {
    hexCodePoints: '1f973',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥳',
    version: 'E11.0',
    baseName: 'partying face',
    fullName: 'partying face',
    isBase: true,
    baseHex: '1f973',
    modifiers: null,
  },
  '1f978': {
    hexCodePoints: '1f978',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥸',
    version: 'E13.0',
    baseName: 'disguised face',
    fullName: 'disguised face',
    isBase: true,
    baseHex: '1f978',
    modifiers: null,
  },
  '1f60e': {
    hexCodePoints: '1f60e',
    qualifiedStatus: 'fully-qualified',
    emoji: '😎',
    version: 'E1.0',
    baseName: 'smiling face with sunglasses',
    fullName: 'smiling face with sunglasses',
    isBase: true,
    baseHex: '1f60e',
    modifiers: null,
  },
  '1f913': {
    hexCodePoints: '1f913',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤓',
    version: 'E1.0',
    baseName: 'nerd face',
    fullName: 'nerd face',
    isBase: true,
    baseHex: '1f913',
    modifiers: null,
  },
  '1f9d0': {
    hexCodePoints: '1f9d0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧐',
    version: 'E5.0',
    baseName: 'face with monocle',
    fullName: 'face with monocle',
    isBase: true,
    baseHex: '1f9d0',
    modifiers: null,
  },
  '1f615': {
    hexCodePoints: '1f615',
    qualifiedStatus: 'fully-qualified',
    emoji: '😕',
    version: 'E1.0',
    baseName: 'confused face',
    fullName: 'confused face',
    isBase: true,
    baseHex: '1f615',
    modifiers: null,
  },
  '1fae4': {
    hexCodePoints: '1fae4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫤',
    version: 'E14.0',
    baseName: 'face with diagonal mouth',
    fullName: 'face with diagonal mouth',
    isBase: true,
    baseHex: '1fae4',
    modifiers: null,
  },
  '1f61f': {
    hexCodePoints: '1f61f',
    qualifiedStatus: 'fully-qualified',
    emoji: '😟',
    version: 'E1.0',
    baseName: 'worried face',
    fullName: 'worried face',
    isBase: true,
    baseHex: '1f61f',
    modifiers: null,
  },
  '1f641': {
    hexCodePoints: '1f641',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙁',
    version: 'E1.0',
    baseName: 'slightly frowning face',
    fullName: 'slightly frowning face',
    isBase: true,
    baseHex: '1f641',
    modifiers: null,
  },
  '2639-fe0f': {
    hexCodePoints: '2639-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☹️',
    version: 'E0.7',
    baseName: 'frowning face',
    fullName: 'frowning face',
    isBase: true,
    baseHex: '2639-fe0f',
    modifiers: null,
  },
  '2639': {
    hexCodePoints: '2639',
    qualifiedStatus: 'unqualified',
    emoji: '☹',
    version: 'E0.7',
    baseName: 'frowning face',
    fullName: 'frowning face',
    isBase: false,
    baseHex: '2639-fe0f',
    modifiers: null,
  },
  '1f62e': {
    hexCodePoints: '1f62e',
    qualifiedStatus: 'fully-qualified',
    emoji: '😮',
    version: 'E1.0',
    baseName: 'face with open mouth',
    fullName: 'face with open mouth',
    isBase: true,
    baseHex: '1f62e',
    modifiers: null,
  },
  '1f62f': {
    hexCodePoints: '1f62f',
    qualifiedStatus: 'fully-qualified',
    emoji: '😯',
    version: 'E1.0',
    baseName: 'hushed face',
    fullName: 'hushed face',
    isBase: true,
    baseHex: '1f62f',
    modifiers: null,
  },
  '1f632': {
    hexCodePoints: '1f632',
    qualifiedStatus: 'fully-qualified',
    emoji: '😲',
    version: 'E0.6',
    baseName: 'astonished face',
    fullName: 'astonished face',
    isBase: true,
    baseHex: '1f632',
    modifiers: null,
  },
  '1f633': {
    hexCodePoints: '1f633',
    qualifiedStatus: 'fully-qualified',
    emoji: '😳',
    version: 'E0.6',
    baseName: 'flushed face',
    fullName: 'flushed face',
    isBase: true,
    baseHex: '1f633',
    modifiers: null,
  },
  '1f97a': {
    hexCodePoints: '1f97a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥺',
    version: 'E11.0',
    baseName: 'pleading face',
    fullName: 'pleading face',
    isBase: true,
    baseHex: '1f97a',
    modifiers: null,
  },
  '1f979': {
    hexCodePoints: '1f979',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥹',
    version: 'E14.0',
    baseName: 'face holding back tears',
    fullName: 'face holding back tears',
    isBase: true,
    baseHex: '1f979',
    modifiers: null,
  },
  '1f626': {
    hexCodePoints: '1f626',
    qualifiedStatus: 'fully-qualified',
    emoji: '😦',
    version: 'E1.0',
    baseName: 'frowning face with open mouth',
    fullName: 'frowning face with open mouth',
    isBase: true,
    baseHex: '1f626',
    modifiers: null,
  },
  '1f627': {
    hexCodePoints: '1f627',
    qualifiedStatus: 'fully-qualified',
    emoji: '😧',
    version: 'E1.0',
    baseName: 'anguished face',
    fullName: 'anguished face',
    isBase: true,
    baseHex: '1f627',
    modifiers: null,
  },
  '1f628': {
    hexCodePoints: '1f628',
    qualifiedStatus: 'fully-qualified',
    emoji: '😨',
    version: 'E0.6',
    baseName: 'fearful face',
    fullName: 'fearful face',
    isBase: true,
    baseHex: '1f628',
    modifiers: null,
  },
  '1f630': {
    hexCodePoints: '1f630',
    qualifiedStatus: 'fully-qualified',
    emoji: '😰',
    version: 'E0.6',
    baseName: 'anxious face with sweat',
    fullName: 'anxious face with sweat',
    isBase: true,
    baseHex: '1f630',
    modifiers: null,
  },
  '1f625': {
    hexCodePoints: '1f625',
    qualifiedStatus: 'fully-qualified',
    emoji: '😥',
    version: 'E0.6',
    baseName: 'sad but relieved face',
    fullName: 'sad but relieved face',
    isBase: true,
    baseHex: '1f625',
    modifiers: null,
  },
  '1f622': {
    hexCodePoints: '1f622',
    qualifiedStatus: 'fully-qualified',
    emoji: '😢',
    version: 'E0.6',
    baseName: 'crying face',
    fullName: 'crying face',
    isBase: true,
    baseHex: '1f622',
    modifiers: null,
  },
  '1f62d': {
    hexCodePoints: '1f62d',
    qualifiedStatus: 'fully-qualified',
    emoji: '😭',
    version: 'E0.6',
    baseName: 'loudly crying face',
    fullName: 'loudly crying face',
    isBase: true,
    baseHex: '1f62d',
    modifiers: null,
  },
  '1f631': {
    hexCodePoints: '1f631',
    qualifiedStatus: 'fully-qualified',
    emoji: '😱',
    version: 'E0.6',
    baseName: 'face screaming in fear',
    fullName: 'face screaming in fear',
    isBase: true,
    baseHex: '1f631',
    modifiers: null,
  },
  '1f616': {
    hexCodePoints: '1f616',
    qualifiedStatus: 'fully-qualified',
    emoji: '😖',
    version: 'E0.6',
    baseName: 'confounded face',
    fullName: 'confounded face',
    isBase: true,
    baseHex: '1f616',
    modifiers: null,
  },
  '1f623': {
    hexCodePoints: '1f623',
    qualifiedStatus: 'fully-qualified',
    emoji: '😣',
    version: 'E0.6',
    baseName: 'persevering face',
    fullName: 'persevering face',
    isBase: true,
    baseHex: '1f623',
    modifiers: null,
  },
  '1f61e': {
    hexCodePoints: '1f61e',
    qualifiedStatus: 'fully-qualified',
    emoji: '😞',
    version: 'E0.6',
    baseName: 'disappointed face',
    fullName: 'disappointed face',
    isBase: true,
    baseHex: '1f61e',
    modifiers: null,
  },
  '1f613': {
    hexCodePoints: '1f613',
    qualifiedStatus: 'fully-qualified',
    emoji: '😓',
    version: 'E0.6',
    baseName: 'downcast face with sweat',
    fullName: 'downcast face with sweat',
    isBase: true,
    baseHex: '1f613',
    modifiers: null,
  },
  '1f629': {
    hexCodePoints: '1f629',
    qualifiedStatus: 'fully-qualified',
    emoji: '😩',
    version: 'E0.6',
    baseName: 'weary face',
    fullName: 'weary face',
    isBase: true,
    baseHex: '1f629',
    modifiers: null,
  },
  '1f62b': {
    hexCodePoints: '1f62b',
    qualifiedStatus: 'fully-qualified',
    emoji: '😫',
    version: 'E0.6',
    baseName: 'tired face',
    fullName: 'tired face',
    isBase: true,
    baseHex: '1f62b',
    modifiers: null,
  },
  '1f971': {
    hexCodePoints: '1f971',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥱',
    version: 'E12.0',
    baseName: 'yawning face',
    fullName: 'yawning face',
    isBase: true,
    baseHex: '1f971',
    modifiers: null,
  },
  '1f624': {
    hexCodePoints: '1f624',
    qualifiedStatus: 'fully-qualified',
    emoji: '😤',
    version: 'E0.6',
    baseName: 'face with steam from nose',
    fullName: 'face with steam from nose',
    isBase: true,
    baseHex: '1f624',
    modifiers: null,
  },
  '1f621': {
    hexCodePoints: '1f621',
    qualifiedStatus: 'fully-qualified',
    emoji: '😡',
    version: 'E0.6',
    baseName: 'enraged face',
    fullName: 'enraged face',
    isBase: true,
    baseHex: '1f621',
    modifiers: null,
  },
  '1f620': {
    hexCodePoints: '1f620',
    qualifiedStatus: 'fully-qualified',
    emoji: '😠',
    version: 'E0.6',
    baseName: 'angry face',
    fullName: 'angry face',
    isBase: true,
    baseHex: '1f620',
    modifiers: null,
  },
  '1f92c': {
    hexCodePoints: '1f92c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤬',
    version: 'E5.0',
    baseName: 'face with symbols on mouth',
    fullName: 'face with symbols on mouth',
    isBase: true,
    baseHex: '1f92c',
    modifiers: null,
  },
  '1f608': {
    hexCodePoints: '1f608',
    qualifiedStatus: 'fully-qualified',
    emoji: '😈',
    version: 'E1.0',
    baseName: 'smiling face with horns',
    fullName: 'smiling face with horns',
    isBase: true,
    baseHex: '1f608',
    modifiers: null,
  },
  '1f47f': {
    hexCodePoints: '1f47f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👿',
    version: 'E0.6',
    baseName: 'angry face with horns',
    fullName: 'angry face with horns',
    isBase: true,
    baseHex: '1f47f',
    modifiers: null,
  },
  '1f480': {
    hexCodePoints: '1f480',
    qualifiedStatus: 'fully-qualified',
    emoji: '💀',
    version: 'E0.6',
    baseName: 'skull',
    fullName: 'skull',
    isBase: true,
    baseHex: '1f480',
    modifiers: null,
  },
  '2620-fe0f': {
    hexCodePoints: '2620-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☠️',
    version: 'E1.0',
    baseName: 'skull and crossbones',
    fullName: 'skull and crossbones',
    isBase: true,
    baseHex: '2620-fe0f',
    modifiers: null,
  },
  '2620': {
    hexCodePoints: '2620',
    qualifiedStatus: 'unqualified',
    emoji: '☠',
    version: 'E1.0',
    baseName: 'skull and crossbones',
    fullName: 'skull and crossbones',
    isBase: false,
    baseHex: '2620-fe0f',
    modifiers: null,
  },
  '1f4a9': {
    hexCodePoints: '1f4a9',
    qualifiedStatus: 'fully-qualified',
    emoji: '💩',
    version: 'E0.6',
    baseName: 'pile of poo',
    fullName: 'pile of poo',
    isBase: true,
    baseHex: '1f4a9',
    modifiers: null,
  },
  '1f921': {
    hexCodePoints: '1f921',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤡',
    version: 'E3.0',
    baseName: 'clown face',
    fullName: 'clown face',
    isBase: true,
    baseHex: '1f921',
    modifiers: null,
  },
  '1f479': {
    hexCodePoints: '1f479',
    qualifiedStatus: 'fully-qualified',
    emoji: '👹',
    version: 'E0.6',
    baseName: 'ogre',
    fullName: 'ogre',
    isBase: true,
    baseHex: '1f479',
    modifiers: null,
  },
  '1f47a': {
    hexCodePoints: '1f47a',
    qualifiedStatus: 'fully-qualified',
    emoji: '👺',
    version: 'E0.6',
    baseName: 'goblin',
    fullName: 'goblin',
    isBase: true,
    baseHex: '1f47a',
    modifiers: null,
  },
  '1f47b': {
    hexCodePoints: '1f47b',
    qualifiedStatus: 'fully-qualified',
    emoji: '👻',
    version: 'E0.6',
    baseName: 'ghost',
    fullName: 'ghost',
    isBase: true,
    baseHex: '1f47b',
    modifiers: null,
  },
  '1f47d': {
    hexCodePoints: '1f47d',
    qualifiedStatus: 'fully-qualified',
    emoji: '👽',
    version: 'E0.6',
    baseName: 'alien',
    fullName: 'alien',
    isBase: true,
    baseHex: '1f47d',
    modifiers: null,
  },
  '1f47e': {
    hexCodePoints: '1f47e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👾',
    version: 'E0.6',
    baseName: 'alien monster',
    fullName: 'alien monster',
    isBase: true,
    baseHex: '1f47e',
    modifiers: null,
  },
  '1f916': {
    hexCodePoints: '1f916',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤖',
    version: 'E1.0',
    baseName: 'robot',
    fullName: 'robot',
    isBase: true,
    baseHex: '1f916',
    modifiers: null,
  },
  '1f63a': {
    hexCodePoints: '1f63a',
    qualifiedStatus: 'fully-qualified',
    emoji: '😺',
    version: 'E0.6',
    baseName: 'grinning cat',
    fullName: 'grinning cat',
    isBase: true,
    baseHex: '1f63a',
    modifiers: null,
  },
  '1f638': {
    hexCodePoints: '1f638',
    qualifiedStatus: 'fully-qualified',
    emoji: '😸',
    version: 'E0.6',
    baseName: 'grinning cat with smiling eyes',
    fullName: 'grinning cat with smiling eyes',
    isBase: true,
    baseHex: '1f638',
    modifiers: null,
  },
  '1f639': {
    hexCodePoints: '1f639',
    qualifiedStatus: 'fully-qualified',
    emoji: '😹',
    version: 'E0.6',
    baseName: 'cat with tears of joy',
    fullName: 'cat with tears of joy',
    isBase: true,
    baseHex: '1f639',
    modifiers: null,
  },
  '1f63b': {
    hexCodePoints: '1f63b',
    qualifiedStatus: 'fully-qualified',
    emoji: '😻',
    version: 'E0.6',
    baseName: 'smiling cat with heart-eyes',
    fullName: 'smiling cat with heart-eyes',
    isBase: true,
    baseHex: '1f63b',
    modifiers: null,
  },
  '1f63c': {
    hexCodePoints: '1f63c',
    qualifiedStatus: 'fully-qualified',
    emoji: '😼',
    version: 'E0.6',
    baseName: 'cat with wry smile',
    fullName: 'cat with wry smile',
    isBase: true,
    baseHex: '1f63c',
    modifiers: null,
  },
  '1f63d': {
    hexCodePoints: '1f63d',
    qualifiedStatus: 'fully-qualified',
    emoji: '😽',
    version: 'E0.6',
    baseName: 'kissing cat',
    fullName: 'kissing cat',
    isBase: true,
    baseHex: '1f63d',
    modifiers: null,
  },
  '1f640': {
    hexCodePoints: '1f640',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙀',
    version: 'E0.6',
    baseName: 'weary cat',
    fullName: 'weary cat',
    isBase: true,
    baseHex: '1f640',
    modifiers: null,
  },
  '1f63f': {
    hexCodePoints: '1f63f',
    qualifiedStatus: 'fully-qualified',
    emoji: '😿',
    version: 'E0.6',
    baseName: 'crying cat',
    fullName: 'crying cat',
    isBase: true,
    baseHex: '1f63f',
    modifiers: null,
  },
  '1f63e': {
    hexCodePoints: '1f63e',
    qualifiedStatus: 'fully-qualified',
    emoji: '😾',
    version: 'E0.6',
    baseName: 'pouting cat',
    fullName: 'pouting cat',
    isBase: true,
    baseHex: '1f63e',
    modifiers: null,
  },
  '1f648': {
    hexCodePoints: '1f648',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙈',
    version: 'E0.6',
    baseName: 'see-no-evil monkey',
    fullName: 'see-no-evil monkey',
    isBase: true,
    baseHex: '1f648',
    modifiers: null,
  },
  '1f649': {
    hexCodePoints: '1f649',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙉',
    version: 'E0.6',
    baseName: 'hear-no-evil monkey',
    fullName: 'hear-no-evil monkey',
    isBase: true,
    baseHex: '1f649',
    modifiers: null,
  },
  '1f64a': {
    hexCodePoints: '1f64a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙊',
    version: 'E0.6',
    baseName: 'speak-no-evil monkey',
    fullName: 'speak-no-evil monkey',
    isBase: true,
    baseHex: '1f64a',
    modifiers: null,
  },
  '1f48c': {
    hexCodePoints: '1f48c',
    qualifiedStatus: 'fully-qualified',
    emoji: '💌',
    version: 'E0.6',
    baseName: 'love letter',
    fullName: 'love letter',
    isBase: true,
    baseHex: '1f48c',
    modifiers: null,
  },
  '1f498': {
    hexCodePoints: '1f498',
    qualifiedStatus: 'fully-qualified',
    emoji: '💘',
    version: 'E0.6',
    baseName: 'heart with arrow',
    fullName: 'heart with arrow',
    isBase: true,
    baseHex: '1f498',
    modifiers: null,
  },
  '1f49d': {
    hexCodePoints: '1f49d',
    qualifiedStatus: 'fully-qualified',
    emoji: '💝',
    version: 'E0.6',
    baseName: 'heart with ribbon',
    fullName: 'heart with ribbon',
    isBase: true,
    baseHex: '1f49d',
    modifiers: null,
  },
  '1f496': {
    hexCodePoints: '1f496',
    qualifiedStatus: 'fully-qualified',
    emoji: '💖',
    version: 'E0.6',
    baseName: 'sparkling heart',
    fullName: 'sparkling heart',
    isBase: true,
    baseHex: '1f496',
    modifiers: null,
  },
  '1f497': {
    hexCodePoints: '1f497',
    qualifiedStatus: 'fully-qualified',
    emoji: '💗',
    version: 'E0.6',
    baseName: 'growing heart',
    fullName: 'growing heart',
    isBase: true,
    baseHex: '1f497',
    modifiers: null,
  },
  '1f493': {
    hexCodePoints: '1f493',
    qualifiedStatus: 'fully-qualified',
    emoji: '💓',
    version: 'E0.6',
    baseName: 'beating heart',
    fullName: 'beating heart',
    isBase: true,
    baseHex: '1f493',
    modifiers: null,
  },
  '1f49e': {
    hexCodePoints: '1f49e',
    qualifiedStatus: 'fully-qualified',
    emoji: '💞',
    version: 'E0.6',
    baseName: 'revolving hearts',
    fullName: 'revolving hearts',
    isBase: true,
    baseHex: '1f49e',
    modifiers: null,
  },
  '1f495': {
    hexCodePoints: '1f495',
    qualifiedStatus: 'fully-qualified',
    emoji: '💕',
    version: 'E0.6',
    baseName: 'two hearts',
    fullName: 'two hearts',
    isBase: true,
    baseHex: '1f495',
    modifiers: null,
  },
  '1f49f': {
    hexCodePoints: '1f49f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💟',
    version: 'E0.6',
    baseName: 'heart decoration',
    fullName: 'heart decoration',
    isBase: true,
    baseHex: '1f49f',
    modifiers: null,
  },
  '2763-fe0f': {
    hexCodePoints: '2763-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '❣️',
    version: 'E1.0',
    baseName: 'heart exclamation',
    fullName: 'heart exclamation',
    isBase: true,
    baseHex: '2763-fe0f',
    modifiers: null,
  },
  '2763': {
    hexCodePoints: '2763',
    qualifiedStatus: 'unqualified',
    emoji: '❣',
    version: 'E1.0',
    baseName: 'heart exclamation',
    fullName: 'heart exclamation',
    isBase: false,
    baseHex: '2763-fe0f',
    modifiers: null,
  },
  '1f494': {
    hexCodePoints: '1f494',
    qualifiedStatus: 'fully-qualified',
    emoji: '💔',
    version: 'E0.6',
    baseName: 'broken heart',
    fullName: 'broken heart',
    isBase: true,
    baseHex: '1f494',
    modifiers: null,
  },
  '2764-fe0f-200d-1f525': {
    hexCodePoints: '2764-fe0f-200d-1f525',
    qualifiedStatus: 'fully-qualified',
    emoji: '❤️‍🔥',
    version: 'E13.1',
    baseName: 'heart on fire',
    fullName: 'heart on fire',
    isBase: true,
    baseHex: '2764-fe0f-200d-1f525',
    modifiers: null,
  },
  '2764-200d-1f525': {
    hexCodePoints: '2764-200d-1f525',
    qualifiedStatus: 'unqualified',
    emoji: '❤‍🔥',
    version: 'E13.1',
    baseName: 'heart on fire',
    fullName: 'heart on fire',
    isBase: false,
    baseHex: '2764-fe0f-200d-1f525',
    modifiers: null,
  },
  '2764-fe0f-200d-1fa79': {
    hexCodePoints: '2764-fe0f-200d-1fa79',
    qualifiedStatus: 'fully-qualified',
    emoji: '❤️‍🩹',
    version: 'E13.1',
    baseName: 'mending heart',
    fullName: 'mending heart',
    isBase: true,
    baseHex: '2764-fe0f-200d-1fa79',
    modifiers: null,
  },
  '2764-200d-1fa79': {
    hexCodePoints: '2764-200d-1fa79',
    qualifiedStatus: 'unqualified',
    emoji: '❤‍🩹',
    version: 'E13.1',
    baseName: 'mending heart',
    fullName: 'mending heart',
    isBase: false,
    baseHex: '2764-fe0f-200d-1fa79',
    modifiers: null,
  },
  '2764-fe0f': {
    hexCodePoints: '2764-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '❤️',
    version: 'E0.6',
    baseName: 'red heart',
    fullName: 'red heart',
    isBase: true,
    baseHex: '2764-fe0f',
    modifiers: null,
  },
  '2764': {
    hexCodePoints: '2764',
    qualifiedStatus: 'unqualified',
    emoji: '❤',
    version: 'E0.6',
    baseName: 'red heart',
    fullName: 'red heart',
    isBase: false,
    baseHex: '2764-fe0f',
    modifiers: null,
  },
  '1fa77': {
    hexCodePoints: '1fa77',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩷',
    version: 'E15.0',
    baseName: 'pink heart',
    fullName: 'pink heart',
    isBase: true,
    baseHex: '1fa77',
    modifiers: null,
  },
  '1f9e1': {
    hexCodePoints: '1f9e1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧡',
    version: 'E5.0',
    baseName: 'orange heart',
    fullName: 'orange heart',
    isBase: true,
    baseHex: '1f9e1',
    modifiers: null,
  },
  '1f49b': {
    hexCodePoints: '1f49b',
    qualifiedStatus: 'fully-qualified',
    emoji: '💛',
    version: 'E0.6',
    baseName: 'yellow heart',
    fullName: 'yellow heart',
    isBase: true,
    baseHex: '1f49b',
    modifiers: null,
  },
  '1f49a': {
    hexCodePoints: '1f49a',
    qualifiedStatus: 'fully-qualified',
    emoji: '💚',
    version: 'E0.6',
    baseName: 'green heart',
    fullName: 'green heart',
    isBase: true,
    baseHex: '1f49a',
    modifiers: null,
  },
  '1f499': {
    hexCodePoints: '1f499',
    qualifiedStatus: 'fully-qualified',
    emoji: '💙',
    version: 'E0.6',
    baseName: 'blue heart',
    fullName: 'blue heart',
    isBase: true,
    baseHex: '1f499',
    modifiers: null,
  },
  '1fa75': {
    hexCodePoints: '1fa75',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩵',
    version: 'E15.0',
    baseName: 'light blue heart',
    fullName: 'light blue heart',
    isBase: true,
    baseHex: '1fa75',
    modifiers: null,
  },
  '1f49c': {
    hexCodePoints: '1f49c',
    qualifiedStatus: 'fully-qualified',
    emoji: '💜',
    version: 'E0.6',
    baseName: 'purple heart',
    fullName: 'purple heart',
    isBase: true,
    baseHex: '1f49c',
    modifiers: null,
  },
  '1f90e': {
    hexCodePoints: '1f90e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤎',
    version: 'E12.0',
    baseName: 'brown heart',
    fullName: 'brown heart',
    isBase: true,
    baseHex: '1f90e',
    modifiers: null,
  },
  '1f5a4': {
    hexCodePoints: '1f5a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖤',
    version: 'E3.0',
    baseName: 'black heart',
    fullName: 'black heart',
    isBase: true,
    baseHex: '1f5a4',
    modifiers: null,
  },
  '1fa76': {
    hexCodePoints: '1fa76',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩶',
    version: 'E15.0',
    baseName: 'grey heart',
    fullName: 'grey heart',
    isBase: true,
    baseHex: '1fa76',
    modifiers: null,
  },
  '1f90d': {
    hexCodePoints: '1f90d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤍',
    version: 'E12.0',
    baseName: 'white heart',
    fullName: 'white heart',
    isBase: true,
    baseHex: '1f90d',
    modifiers: null,
  },
  '1f48b': {
    hexCodePoints: '1f48b',
    qualifiedStatus: 'fully-qualified',
    emoji: '💋',
    version: 'E0.6',
    baseName: 'kiss mark',
    fullName: 'kiss mark',
    isBase: true,
    baseHex: '1f48b',
    modifiers: null,
  },
  '1f4af': {
    hexCodePoints: '1f4af',
    qualifiedStatus: 'fully-qualified',
    emoji: '💯',
    version: 'E0.6',
    baseName: 'hundred points',
    fullName: 'hundred points',
    isBase: true,
    baseHex: '1f4af',
    modifiers: null,
  },
  '1f4a2': {
    hexCodePoints: '1f4a2',
    qualifiedStatus: 'fully-qualified',
    emoji: '💢',
    version: 'E0.6',
    baseName: 'anger symbol',
    fullName: 'anger symbol',
    isBase: true,
    baseHex: '1f4a2',
    modifiers: null,
  },
  '1f4a5': {
    hexCodePoints: '1f4a5',
    qualifiedStatus: 'fully-qualified',
    emoji: '💥',
    version: 'E0.6',
    baseName: 'collision',
    fullName: 'collision',
    isBase: true,
    baseHex: '1f4a5',
    modifiers: null,
  },
  '1f4ab': {
    hexCodePoints: '1f4ab',
    qualifiedStatus: 'fully-qualified',
    emoji: '💫',
    version: 'E0.6',
    baseName: 'dizzy',
    fullName: 'dizzy',
    isBase: true,
    baseHex: '1f4ab',
    modifiers: null,
  },
  '1f4a6': {
    hexCodePoints: '1f4a6',
    qualifiedStatus: 'fully-qualified',
    emoji: '💦',
    version: 'E0.6',
    baseName: 'sweat droplets',
    fullName: 'sweat droplets',
    isBase: true,
    baseHex: '1f4a6',
    modifiers: null,
  },
  '1f4a8': {
    hexCodePoints: '1f4a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '💨',
    version: 'E0.6',
    baseName: 'dashing away',
    fullName: 'dashing away',
    isBase: true,
    baseHex: '1f4a8',
    modifiers: null,
  },
  '1f573-fe0f': {
    hexCodePoints: '1f573-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕳️',
    version: 'E0.7',
    baseName: 'hole',
    fullName: 'hole',
    isBase: true,
    baseHex: '1f573-fe0f',
    modifiers: null,
  },
  '1f573': {
    hexCodePoints: '1f573',
    qualifiedStatus: 'unqualified',
    emoji: '🕳',
    version: 'E0.7',
    baseName: 'hole',
    fullName: 'hole',
    isBase: false,
    baseHex: '1f573-fe0f',
    modifiers: null,
  },
  '1f4ac': {
    hexCodePoints: '1f4ac',
    qualifiedStatus: 'fully-qualified',
    emoji: '💬',
    version: 'E0.6',
    baseName: 'speech balloon',
    fullName: 'speech balloon',
    isBase: true,
    baseHex: '1f4ac',
    modifiers: null,
  },
  '1f441-fe0f-200d-1f5e8-fe0f': {
    hexCodePoints: '1f441-fe0f-200d-1f5e8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👁️‍🗨️',
    version: 'E2.0',
    baseName: 'eye in speech bubble',
    fullName: 'eye in speech bubble',
    isBase: true,
    baseHex: '1f441-fe0f-200d-1f5e8-fe0f',
    modifiers: null,
  },
  '1f441-200d-1f5e8-fe0f': {
    hexCodePoints: '1f441-200d-1f5e8-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '👁‍🗨️',
    version: 'E2.0',
    baseName: 'eye in speech bubble',
    fullName: 'eye in speech bubble',
    isBase: false,
    baseHex: '1f441-fe0f-200d-1f5e8-fe0f',
    modifiers: null,
  },
  '1f441-fe0f-200d-1f5e8': {
    hexCodePoints: '1f441-fe0f-200d-1f5e8',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👁️‍🗨',
    version: 'E2.0',
    baseName: 'eye in speech bubble',
    fullName: 'eye in speech bubble',
    isBase: false,
    baseHex: '1f441-fe0f-200d-1f5e8-fe0f',
    modifiers: null,
  },
  '1f441-200d-1f5e8': {
    hexCodePoints: '1f441-200d-1f5e8',
    qualifiedStatus: 'unqualified',
    emoji: '👁‍🗨',
    version: 'E2.0',
    baseName: 'eye in speech bubble',
    fullName: 'eye in speech bubble',
    isBase: false,
    baseHex: '1f441-fe0f-200d-1f5e8-fe0f',
    modifiers: null,
  },
  '1f5e8-fe0f': {
    hexCodePoints: '1f5e8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗨️',
    version: 'E2.0',
    baseName: 'left speech bubble',
    fullName: 'left speech bubble',
    isBase: true,
    baseHex: '1f5e8-fe0f',
    modifiers: null,
  },
  '1f5e8': {
    hexCodePoints: '1f5e8',
    qualifiedStatus: 'unqualified',
    emoji: '🗨',
    version: 'E2.0',
    baseName: 'left speech bubble',
    fullName: 'left speech bubble',
    isBase: false,
    baseHex: '1f5e8-fe0f',
    modifiers: null,
  },
  '1f5ef-fe0f': {
    hexCodePoints: '1f5ef-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗯️',
    version: 'E0.7',
    baseName: 'right anger bubble',
    fullName: 'right anger bubble',
    isBase: true,
    baseHex: '1f5ef-fe0f',
    modifiers: null,
  },
  '1f5ef': {
    hexCodePoints: '1f5ef',
    qualifiedStatus: 'unqualified',
    emoji: '🗯',
    version: 'E0.7',
    baseName: 'right anger bubble',
    fullName: 'right anger bubble',
    isBase: false,
    baseHex: '1f5ef-fe0f',
    modifiers: null,
  },
  '1f4ad': {
    hexCodePoints: '1f4ad',
    qualifiedStatus: 'fully-qualified',
    emoji: '💭',
    version: 'E1.0',
    baseName: 'thought balloon',
    fullName: 'thought balloon',
    isBase: true,
    baseHex: '1f4ad',
    modifiers: null,
  },
  '1f4a4': {
    hexCodePoints: '1f4a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '💤',
    version: 'E0.6',
    baseName: 'ZZZ',
    fullName: 'ZZZ',
    isBase: true,
    baseHex: '1f4a4',
    modifiers: null,
  },
  '1f44b': {
    hexCodePoints: '1f44b',
    qualifiedStatus: 'fully-qualified',
    emoji: '👋',
    version: 'E0.6',
    baseName: 'waving hand',
    fullName: 'waving hand',
    isBase: true,
    baseHex: '1f44b',
    modifiers: {
      '1f3fb': '1f44b-1f3fb',
      '1f3fc': '1f44b-1f3fc',
      '1f3fd': '1f44b-1f3fd',
      '1f3fe': '1f44b-1f3fe',
      '1f3ff': '1f44b-1f3ff',
    },
  },
  '1f44b-1f3fb': {
    hexCodePoints: '1f44b-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👋🏻',
    version: 'E1.0',
    baseName: 'waving hand',
    fullName: 'waving hand: light skin tone',
    isBase: false,
    baseHex: '1f44b',
    modifiers: null,
  },
  '1f44b-1f3fc': {
    hexCodePoints: '1f44b-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👋🏼',
    version: 'E1.0',
    baseName: 'waving hand',
    fullName: 'waving hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f44b',
    modifiers: null,
  },
  '1f44b-1f3fd': {
    hexCodePoints: '1f44b-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👋🏽',
    version: 'E1.0',
    baseName: 'waving hand',
    fullName: 'waving hand: medium skin tone',
    isBase: false,
    baseHex: '1f44b',
    modifiers: null,
  },
  '1f44b-1f3fe': {
    hexCodePoints: '1f44b-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👋🏾',
    version: 'E1.0',
    baseName: 'waving hand',
    fullName: 'waving hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f44b',
    modifiers: null,
  },
  '1f44b-1f3ff': {
    hexCodePoints: '1f44b-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👋🏿',
    version: 'E1.0',
    baseName: 'waving hand',
    fullName: 'waving hand: dark skin tone',
    isBase: false,
    baseHex: '1f44b',
    modifiers: null,
  },
  '1f91a': {
    hexCodePoints: '1f91a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤚',
    version: 'E3.0',
    baseName: 'raised back of hand',
    fullName: 'raised back of hand',
    isBase: true,
    baseHex: '1f91a',
    modifiers: {
      '1f3fb': '1f91a-1f3fb',
      '1f3fc': '1f91a-1f3fc',
      '1f3fd': '1f91a-1f3fd',
      '1f3fe': '1f91a-1f3fe',
      '1f3ff': '1f91a-1f3ff',
    },
  },
  '1f91a-1f3fb': {
    hexCodePoints: '1f91a-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤚🏻',
    version: 'E3.0',
    baseName: 'raised back of hand',
    fullName: 'raised back of hand: light skin tone',
    isBase: false,
    baseHex: '1f91a',
    modifiers: null,
  },
  '1f91a-1f3fc': {
    hexCodePoints: '1f91a-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤚🏼',
    version: 'E3.0',
    baseName: 'raised back of hand',
    fullName: 'raised back of hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f91a',
    modifiers: null,
  },
  '1f91a-1f3fd': {
    hexCodePoints: '1f91a-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤚🏽',
    version: 'E3.0',
    baseName: 'raised back of hand',
    fullName: 'raised back of hand: medium skin tone',
    isBase: false,
    baseHex: '1f91a',
    modifiers: null,
  },
  '1f91a-1f3fe': {
    hexCodePoints: '1f91a-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤚🏾',
    version: 'E3.0',
    baseName: 'raised back of hand',
    fullName: 'raised back of hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f91a',
    modifiers: null,
  },
  '1f91a-1f3ff': {
    hexCodePoints: '1f91a-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤚🏿',
    version: 'E3.0',
    baseName: 'raised back of hand',
    fullName: 'raised back of hand: dark skin tone',
    isBase: false,
    baseHex: '1f91a',
    modifiers: null,
  },
  '1f590-fe0f': {
    hexCodePoints: '1f590-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖐️',
    version: 'E0.7',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed',
    isBase: true,
    baseHex: '1f590-fe0f',
    modifiers: {
      '1f3fb': '1f590-1f3fb',
      '1f3fc': '1f590-1f3fc',
      '1f3fd': '1f590-1f3fd',
      '1f3fe': '1f590-1f3fe',
      '1f3ff': '1f590-1f3ff',
    },
  },
  '1f590': {
    hexCodePoints: '1f590',
    qualifiedStatus: 'unqualified',
    emoji: '🖐',
    version: 'E0.7',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed',
    isBase: false,
    baseHex: '1f590-fe0f',
    modifiers: null,
  },
  '1f590-1f3fb': {
    hexCodePoints: '1f590-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖐🏻',
    version: 'E1.0',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed: light skin tone',
    isBase: false,
    baseHex: '1f590-fe0f',
    modifiers: null,
  },
  '1f590-1f3fc': {
    hexCodePoints: '1f590-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖐🏼',
    version: 'E1.0',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed: medium-light skin tone',
    isBase: false,
    baseHex: '1f590-fe0f',
    modifiers: null,
  },
  '1f590-1f3fd': {
    hexCodePoints: '1f590-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖐🏽',
    version: 'E1.0',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed: medium skin tone',
    isBase: false,
    baseHex: '1f590-fe0f',
    modifiers: null,
  },
  '1f590-1f3fe': {
    hexCodePoints: '1f590-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖐🏾',
    version: 'E1.0',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed: medium-dark skin tone',
    isBase: false,
    baseHex: '1f590-fe0f',
    modifiers: null,
  },
  '1f590-1f3ff': {
    hexCodePoints: '1f590-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖐🏿',
    version: 'E1.0',
    baseName: 'hand with fingers splayed',
    fullName: 'hand with fingers splayed: dark skin tone',
    isBase: false,
    baseHex: '1f590-fe0f',
    modifiers: null,
  },
  '270b': {
    hexCodePoints: '270b',
    qualifiedStatus: 'fully-qualified',
    emoji: '✋',
    version: 'E0.6',
    baseName: 'raised hand',
    fullName: 'raised hand',
    isBase: true,
    baseHex: '270b',
    modifiers: {
      '1f3fb': '270b-1f3fb',
      '1f3fc': '270b-1f3fc',
      '1f3fd': '270b-1f3fd',
      '1f3fe': '270b-1f3fe',
      '1f3ff': '270b-1f3ff',
    },
  },
  '270b-1f3fb': {
    hexCodePoints: '270b-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '✋🏻',
    version: 'E1.0',
    baseName: 'raised hand',
    fullName: 'raised hand: light skin tone',
    isBase: false,
    baseHex: '270b',
    modifiers: null,
  },
  '270b-1f3fc': {
    hexCodePoints: '270b-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '✋🏼',
    version: 'E1.0',
    baseName: 'raised hand',
    fullName: 'raised hand: medium-light skin tone',
    isBase: false,
    baseHex: '270b',
    modifiers: null,
  },
  '270b-1f3fd': {
    hexCodePoints: '270b-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '✋🏽',
    version: 'E1.0',
    baseName: 'raised hand',
    fullName: 'raised hand: medium skin tone',
    isBase: false,
    baseHex: '270b',
    modifiers: null,
  },
  '270b-1f3fe': {
    hexCodePoints: '270b-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '✋🏾',
    version: 'E1.0',
    baseName: 'raised hand',
    fullName: 'raised hand: medium-dark skin tone',
    isBase: false,
    baseHex: '270b',
    modifiers: null,
  },
  '270b-1f3ff': {
    hexCodePoints: '270b-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '✋🏿',
    version: 'E1.0',
    baseName: 'raised hand',
    fullName: 'raised hand: dark skin tone',
    isBase: false,
    baseHex: '270b',
    modifiers: null,
  },
  '1f596': {
    hexCodePoints: '1f596',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖖',
    version: 'E1.0',
    baseName: 'vulcan salute',
    fullName: 'vulcan salute',
    isBase: true,
    baseHex: '1f596',
    modifiers: {
      '1f3fb': '1f596-1f3fb',
      '1f3fc': '1f596-1f3fc',
      '1f3fd': '1f596-1f3fd',
      '1f3fe': '1f596-1f3fe',
      '1f3ff': '1f596-1f3ff',
    },
  },
  '1f596-1f3fb': {
    hexCodePoints: '1f596-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖖🏻',
    version: 'E1.0',
    baseName: 'vulcan salute',
    fullName: 'vulcan salute: light skin tone',
    isBase: false,
    baseHex: '1f596',
    modifiers: null,
  },
  '1f596-1f3fc': {
    hexCodePoints: '1f596-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖖🏼',
    version: 'E1.0',
    baseName: 'vulcan salute',
    fullName: 'vulcan salute: medium-light skin tone',
    isBase: false,
    baseHex: '1f596',
    modifiers: null,
  },
  '1f596-1f3fd': {
    hexCodePoints: '1f596-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖖🏽',
    version: 'E1.0',
    baseName: 'vulcan salute',
    fullName: 'vulcan salute: medium skin tone',
    isBase: false,
    baseHex: '1f596',
    modifiers: null,
  },
  '1f596-1f3fe': {
    hexCodePoints: '1f596-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖖🏾',
    version: 'E1.0',
    baseName: 'vulcan salute',
    fullName: 'vulcan salute: medium-dark skin tone',
    isBase: false,
    baseHex: '1f596',
    modifiers: null,
  },
  '1f596-1f3ff': {
    hexCodePoints: '1f596-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖖🏿',
    version: 'E1.0',
    baseName: 'vulcan salute',
    fullName: 'vulcan salute: dark skin tone',
    isBase: false,
    baseHex: '1f596',
    modifiers: null,
  },
  '1faf1': {
    hexCodePoints: '1faf1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱',
    version: 'E14.0',
    baseName: 'rightwards hand',
    fullName: 'rightwards hand',
    isBase: true,
    baseHex: '1faf1',
    modifiers: {
      '1f3fb': '1faf1-1f3fb',
      '1f3fc': '1faf1-1f3fc',
      '1f3fd': '1faf1-1f3fd',
      '1f3fe': '1faf1-1f3fe',
      '1f3ff': '1faf1-1f3ff',
    },
  },
  '1faf1-1f3fb': {
    hexCodePoints: '1faf1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏻',
    version: 'E14.0',
    baseName: 'rightwards hand',
    fullName: 'rightwards hand: light skin tone',
    isBase: false,
    baseHex: '1faf1',
    modifiers: null,
  },
  '1faf1-1f3fc': {
    hexCodePoints: '1faf1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏼',
    version: 'E14.0',
    baseName: 'rightwards hand',
    fullName: 'rightwards hand: medium-light skin tone',
    isBase: false,
    baseHex: '1faf1',
    modifiers: null,
  },
  '1faf1-1f3fd': {
    hexCodePoints: '1faf1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏽',
    version: 'E14.0',
    baseName: 'rightwards hand',
    fullName: 'rightwards hand: medium skin tone',
    isBase: false,
    baseHex: '1faf1',
    modifiers: null,
  },
  '1faf1-1f3fe': {
    hexCodePoints: '1faf1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏾',
    version: 'E14.0',
    baseName: 'rightwards hand',
    fullName: 'rightwards hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf1',
    modifiers: null,
  },
  '1faf1-1f3ff': {
    hexCodePoints: '1faf1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏿',
    version: 'E14.0',
    baseName: 'rightwards hand',
    fullName: 'rightwards hand: dark skin tone',
    isBase: false,
    baseHex: '1faf1',
    modifiers: null,
  },
  '1faf2': {
    hexCodePoints: '1faf2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫲',
    version: 'E14.0',
    baseName: 'leftwards hand',
    fullName: 'leftwards hand',
    isBase: true,
    baseHex: '1faf2',
    modifiers: {
      '1f3fb': '1faf2-1f3fb',
      '1f3fc': '1faf2-1f3fc',
      '1f3fd': '1faf2-1f3fd',
      '1f3fe': '1faf2-1f3fe',
      '1f3ff': '1faf2-1f3ff',
    },
  },
  '1faf2-1f3fb': {
    hexCodePoints: '1faf2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫲🏻',
    version: 'E14.0',
    baseName: 'leftwards hand',
    fullName: 'leftwards hand: light skin tone',
    isBase: false,
    baseHex: '1faf2',
    modifiers: null,
  },
  '1faf2-1f3fc': {
    hexCodePoints: '1faf2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫲🏼',
    version: 'E14.0',
    baseName: 'leftwards hand',
    fullName: 'leftwards hand: medium-light skin tone',
    isBase: false,
    baseHex: '1faf2',
    modifiers: null,
  },
  '1faf2-1f3fd': {
    hexCodePoints: '1faf2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫲🏽',
    version: 'E14.0',
    baseName: 'leftwards hand',
    fullName: 'leftwards hand: medium skin tone',
    isBase: false,
    baseHex: '1faf2',
    modifiers: null,
  },
  '1faf2-1f3fe': {
    hexCodePoints: '1faf2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫲🏾',
    version: 'E14.0',
    baseName: 'leftwards hand',
    fullName: 'leftwards hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf2',
    modifiers: null,
  },
  '1faf2-1f3ff': {
    hexCodePoints: '1faf2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫲🏿',
    version: 'E14.0',
    baseName: 'leftwards hand',
    fullName: 'leftwards hand: dark skin tone',
    isBase: false,
    baseHex: '1faf2',
    modifiers: null,
  },
  '1faf3': {
    hexCodePoints: '1faf3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫳',
    version: 'E14.0',
    baseName: 'palm down hand',
    fullName: 'palm down hand',
    isBase: true,
    baseHex: '1faf3',
    modifiers: {
      '1f3fb': '1faf3-1f3fb',
      '1f3fc': '1faf3-1f3fc',
      '1f3fd': '1faf3-1f3fd',
      '1f3fe': '1faf3-1f3fe',
      '1f3ff': '1faf3-1f3ff',
    },
  },
  '1faf3-1f3fb': {
    hexCodePoints: '1faf3-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫳🏻',
    version: 'E14.0',
    baseName: 'palm down hand',
    fullName: 'palm down hand: light skin tone',
    isBase: false,
    baseHex: '1faf3',
    modifiers: null,
  },
  '1faf3-1f3fc': {
    hexCodePoints: '1faf3-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫳🏼',
    version: 'E14.0',
    baseName: 'palm down hand',
    fullName: 'palm down hand: medium-light skin tone',
    isBase: false,
    baseHex: '1faf3',
    modifiers: null,
  },
  '1faf3-1f3fd': {
    hexCodePoints: '1faf3-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫳🏽',
    version: 'E14.0',
    baseName: 'palm down hand',
    fullName: 'palm down hand: medium skin tone',
    isBase: false,
    baseHex: '1faf3',
    modifiers: null,
  },
  '1faf3-1f3fe': {
    hexCodePoints: '1faf3-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫳🏾',
    version: 'E14.0',
    baseName: 'palm down hand',
    fullName: 'palm down hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf3',
    modifiers: null,
  },
  '1faf3-1f3ff': {
    hexCodePoints: '1faf3-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫳🏿',
    version: 'E14.0',
    baseName: 'palm down hand',
    fullName: 'palm down hand: dark skin tone',
    isBase: false,
    baseHex: '1faf3',
    modifiers: null,
  },
  '1faf4': {
    hexCodePoints: '1faf4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫴',
    version: 'E14.0',
    baseName: 'palm up hand',
    fullName: 'palm up hand',
    isBase: true,
    baseHex: '1faf4',
    modifiers: {
      '1f3fb': '1faf4-1f3fb',
      '1f3fc': '1faf4-1f3fc',
      '1f3fd': '1faf4-1f3fd',
      '1f3fe': '1faf4-1f3fe',
      '1f3ff': '1faf4-1f3ff',
    },
  },
  '1faf4-1f3fb': {
    hexCodePoints: '1faf4-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫴🏻',
    version: 'E14.0',
    baseName: 'palm up hand',
    fullName: 'palm up hand: light skin tone',
    isBase: false,
    baseHex: '1faf4',
    modifiers: null,
  },
  '1faf4-1f3fc': {
    hexCodePoints: '1faf4-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫴🏼',
    version: 'E14.0',
    baseName: 'palm up hand',
    fullName: 'palm up hand: medium-light skin tone',
    isBase: false,
    baseHex: '1faf4',
    modifiers: null,
  },
  '1faf4-1f3fd': {
    hexCodePoints: '1faf4-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫴🏽',
    version: 'E14.0',
    baseName: 'palm up hand',
    fullName: 'palm up hand: medium skin tone',
    isBase: false,
    baseHex: '1faf4',
    modifiers: null,
  },
  '1faf4-1f3fe': {
    hexCodePoints: '1faf4-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫴🏾',
    version: 'E14.0',
    baseName: 'palm up hand',
    fullName: 'palm up hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf4',
    modifiers: null,
  },
  '1faf4-1f3ff': {
    hexCodePoints: '1faf4-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫴🏿',
    version: 'E14.0',
    baseName: 'palm up hand',
    fullName: 'palm up hand: dark skin tone',
    isBase: false,
    baseHex: '1faf4',
    modifiers: null,
  },
  '1faf7': {
    hexCodePoints: '1faf7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫷',
    version: 'E15.0',
    baseName: 'leftwards pushing hand',
    fullName: 'leftwards pushing hand',
    isBase: true,
    baseHex: '1faf7',
    modifiers: {
      '1f3fb': '1faf7-1f3fb',
      '1f3fc': '1faf7-1f3fc',
      '1f3fd': '1faf7-1f3fd',
      '1f3fe': '1faf7-1f3fe',
      '1f3ff': '1faf7-1f3ff',
    },
  },
  '1faf7-1f3fb': {
    hexCodePoints: '1faf7-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫷🏻',
    version: 'E15.0',
    baseName: 'leftwards pushing hand',
    fullName: 'leftwards pushing hand: light skin tone',
    isBase: false,
    baseHex: '1faf7',
    modifiers: null,
  },
  '1faf7-1f3fc': {
    hexCodePoints: '1faf7-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫷🏼',
    version: 'E15.0',
    baseName: 'leftwards pushing hand',
    fullName: 'leftwards pushing hand: medium-light skin tone',
    isBase: false,
    baseHex: '1faf7',
    modifiers: null,
  },
  '1faf7-1f3fd': {
    hexCodePoints: '1faf7-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫷🏽',
    version: 'E15.0',
    baseName: 'leftwards pushing hand',
    fullName: 'leftwards pushing hand: medium skin tone',
    isBase: false,
    baseHex: '1faf7',
    modifiers: null,
  },
  '1faf7-1f3fe': {
    hexCodePoints: '1faf7-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫷🏾',
    version: 'E15.0',
    baseName: 'leftwards pushing hand',
    fullName: 'leftwards pushing hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf7',
    modifiers: null,
  },
  '1faf7-1f3ff': {
    hexCodePoints: '1faf7-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫷🏿',
    version: 'E15.0',
    baseName: 'leftwards pushing hand',
    fullName: 'leftwards pushing hand: dark skin tone',
    isBase: false,
    baseHex: '1faf7',
    modifiers: null,
  },
  '1faf8': {
    hexCodePoints: '1faf8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫸',
    version: 'E15.0',
    baseName: 'rightwards pushing hand',
    fullName: 'rightwards pushing hand',
    isBase: true,
    baseHex: '1faf8',
    modifiers: {
      '1f3fb': '1faf8-1f3fb',
      '1f3fc': '1faf8-1f3fc',
      '1f3fd': '1faf8-1f3fd',
      '1f3fe': '1faf8-1f3fe',
      '1f3ff': '1faf8-1f3ff',
    },
  },
  '1faf8-1f3fb': {
    hexCodePoints: '1faf8-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫸🏻',
    version: 'E15.0',
    baseName: 'rightwards pushing hand',
    fullName: 'rightwards pushing hand: light skin tone',
    isBase: false,
    baseHex: '1faf8',
    modifiers: null,
  },
  '1faf8-1f3fc': {
    hexCodePoints: '1faf8-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫸🏼',
    version: 'E15.0',
    baseName: 'rightwards pushing hand',
    fullName: 'rightwards pushing hand: medium-light skin tone',
    isBase: false,
    baseHex: '1faf8',
    modifiers: null,
  },
  '1faf8-1f3fd': {
    hexCodePoints: '1faf8-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫸🏽',
    version: 'E15.0',
    baseName: 'rightwards pushing hand',
    fullName: 'rightwards pushing hand: medium skin tone',
    isBase: false,
    baseHex: '1faf8',
    modifiers: null,
  },
  '1faf8-1f3fe': {
    hexCodePoints: '1faf8-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫸🏾',
    version: 'E15.0',
    baseName: 'rightwards pushing hand',
    fullName: 'rightwards pushing hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf8',
    modifiers: null,
  },
  '1faf8-1f3ff': {
    hexCodePoints: '1faf8-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫸🏿',
    version: 'E15.0',
    baseName: 'rightwards pushing hand',
    fullName: 'rightwards pushing hand: dark skin tone',
    isBase: false,
    baseHex: '1faf8',
    modifiers: null,
  },
  '1f44c': {
    hexCodePoints: '1f44c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👌',
    version: 'E0.6',
    baseName: 'OK hand',
    fullName: 'OK hand',
    isBase: true,
    baseHex: '1f44c',
    modifiers: {
      '1f3fb': '1f44c-1f3fb',
      '1f3fc': '1f44c-1f3fc',
      '1f3fd': '1f44c-1f3fd',
      '1f3fe': '1f44c-1f3fe',
      '1f3ff': '1f44c-1f3ff',
    },
  },
  '1f44c-1f3fb': {
    hexCodePoints: '1f44c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👌🏻',
    version: 'E1.0',
    baseName: 'OK hand',
    fullName: 'OK hand: light skin tone',
    isBase: false,
    baseHex: '1f44c',
    modifiers: null,
  },
  '1f44c-1f3fc': {
    hexCodePoints: '1f44c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👌🏼',
    version: 'E1.0',
    baseName: 'OK hand',
    fullName: 'OK hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f44c',
    modifiers: null,
  },
  '1f44c-1f3fd': {
    hexCodePoints: '1f44c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👌🏽',
    version: 'E1.0',
    baseName: 'OK hand',
    fullName: 'OK hand: medium skin tone',
    isBase: false,
    baseHex: '1f44c',
    modifiers: null,
  },
  '1f44c-1f3fe': {
    hexCodePoints: '1f44c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👌🏾',
    version: 'E1.0',
    baseName: 'OK hand',
    fullName: 'OK hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f44c',
    modifiers: null,
  },
  '1f44c-1f3ff': {
    hexCodePoints: '1f44c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👌🏿',
    version: 'E1.0',
    baseName: 'OK hand',
    fullName: 'OK hand: dark skin tone',
    isBase: false,
    baseHex: '1f44c',
    modifiers: null,
  },
  '1f90c': {
    hexCodePoints: '1f90c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤌',
    version: 'E13.0',
    baseName: 'pinched fingers',
    fullName: 'pinched fingers',
    isBase: true,
    baseHex: '1f90c',
    modifiers: {
      '1f3fb': '1f90c-1f3fb',
      '1f3fc': '1f90c-1f3fc',
      '1f3fd': '1f90c-1f3fd',
      '1f3fe': '1f90c-1f3fe',
      '1f3ff': '1f90c-1f3ff',
    },
  },
  '1f90c-1f3fb': {
    hexCodePoints: '1f90c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤌🏻',
    version: 'E13.0',
    baseName: 'pinched fingers',
    fullName: 'pinched fingers: light skin tone',
    isBase: false,
    baseHex: '1f90c',
    modifiers: null,
  },
  '1f90c-1f3fc': {
    hexCodePoints: '1f90c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤌🏼',
    version: 'E13.0',
    baseName: 'pinched fingers',
    fullName: 'pinched fingers: medium-light skin tone',
    isBase: false,
    baseHex: '1f90c',
    modifiers: null,
  },
  '1f90c-1f3fd': {
    hexCodePoints: '1f90c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤌🏽',
    version: 'E13.0',
    baseName: 'pinched fingers',
    fullName: 'pinched fingers: medium skin tone',
    isBase: false,
    baseHex: '1f90c',
    modifiers: null,
  },
  '1f90c-1f3fe': {
    hexCodePoints: '1f90c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤌🏾',
    version: 'E13.0',
    baseName: 'pinched fingers',
    fullName: 'pinched fingers: medium-dark skin tone',
    isBase: false,
    baseHex: '1f90c',
    modifiers: null,
  },
  '1f90c-1f3ff': {
    hexCodePoints: '1f90c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤌🏿',
    version: 'E13.0',
    baseName: 'pinched fingers',
    fullName: 'pinched fingers: dark skin tone',
    isBase: false,
    baseHex: '1f90c',
    modifiers: null,
  },
  '1f90f': {
    hexCodePoints: '1f90f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤏',
    version: 'E12.0',
    baseName: 'pinching hand',
    fullName: 'pinching hand',
    isBase: true,
    baseHex: '1f90f',
    modifiers: {
      '1f3fb': '1f90f-1f3fb',
      '1f3fc': '1f90f-1f3fc',
      '1f3fd': '1f90f-1f3fd',
      '1f3fe': '1f90f-1f3fe',
      '1f3ff': '1f90f-1f3ff',
    },
  },
  '1f90f-1f3fb': {
    hexCodePoints: '1f90f-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤏🏻',
    version: 'E12.0',
    baseName: 'pinching hand',
    fullName: 'pinching hand: light skin tone',
    isBase: false,
    baseHex: '1f90f',
    modifiers: null,
  },
  '1f90f-1f3fc': {
    hexCodePoints: '1f90f-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤏🏼',
    version: 'E12.0',
    baseName: 'pinching hand',
    fullName: 'pinching hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f90f',
    modifiers: null,
  },
  '1f90f-1f3fd': {
    hexCodePoints: '1f90f-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤏🏽',
    version: 'E12.0',
    baseName: 'pinching hand',
    fullName: 'pinching hand: medium skin tone',
    isBase: false,
    baseHex: '1f90f',
    modifiers: null,
  },
  '1f90f-1f3fe': {
    hexCodePoints: '1f90f-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤏🏾',
    version: 'E12.0',
    baseName: 'pinching hand',
    fullName: 'pinching hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f90f',
    modifiers: null,
  },
  '1f90f-1f3ff': {
    hexCodePoints: '1f90f-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤏🏿',
    version: 'E12.0',
    baseName: 'pinching hand',
    fullName: 'pinching hand: dark skin tone',
    isBase: false,
    baseHex: '1f90f',
    modifiers: null,
  },
  '270c-fe0f': {
    hexCodePoints: '270c-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✌️',
    version: 'E0.6',
    baseName: 'victory hand',
    fullName: 'victory hand',
    isBase: true,
    baseHex: '270c-fe0f',
    modifiers: {
      '1f3fb': '270c-1f3fb',
      '1f3fc': '270c-1f3fc',
      '1f3fd': '270c-1f3fd',
      '1f3fe': '270c-1f3fe',
      '1f3ff': '270c-1f3ff',
    },
  },
  '270c': {
    hexCodePoints: '270c',
    qualifiedStatus: 'unqualified',
    emoji: '✌',
    version: 'E0.6',
    baseName: 'victory hand',
    fullName: 'victory hand',
    isBase: false,
    baseHex: '270c-fe0f',
    modifiers: null,
  },
  '270c-1f3fb': {
    hexCodePoints: '270c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '✌🏻',
    version: 'E1.0',
    baseName: 'victory hand',
    fullName: 'victory hand: light skin tone',
    isBase: false,
    baseHex: '270c-fe0f',
    modifiers: null,
  },
  '270c-1f3fc': {
    hexCodePoints: '270c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '✌🏼',
    version: 'E1.0',
    baseName: 'victory hand',
    fullName: 'victory hand: medium-light skin tone',
    isBase: false,
    baseHex: '270c-fe0f',
    modifiers: null,
  },
  '270c-1f3fd': {
    hexCodePoints: '270c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '✌🏽',
    version: 'E1.0',
    baseName: 'victory hand',
    fullName: 'victory hand: medium skin tone',
    isBase: false,
    baseHex: '270c-fe0f',
    modifiers: null,
  },
  '270c-1f3fe': {
    hexCodePoints: '270c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '✌🏾',
    version: 'E1.0',
    baseName: 'victory hand',
    fullName: 'victory hand: medium-dark skin tone',
    isBase: false,
    baseHex: '270c-fe0f',
    modifiers: null,
  },
  '270c-1f3ff': {
    hexCodePoints: '270c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '✌🏿',
    version: 'E1.0',
    baseName: 'victory hand',
    fullName: 'victory hand: dark skin tone',
    isBase: false,
    baseHex: '270c-fe0f',
    modifiers: null,
  },
  '1f91e': {
    hexCodePoints: '1f91e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤞',
    version: 'E3.0',
    baseName: 'crossed fingers',
    fullName: 'crossed fingers',
    isBase: true,
    baseHex: '1f91e',
    modifiers: {
      '1f3fb': '1f91e-1f3fb',
      '1f3fc': '1f91e-1f3fc',
      '1f3fd': '1f91e-1f3fd',
      '1f3fe': '1f91e-1f3fe',
      '1f3ff': '1f91e-1f3ff',
    },
  },
  '1f91e-1f3fb': {
    hexCodePoints: '1f91e-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤞🏻',
    version: 'E3.0',
    baseName: 'crossed fingers',
    fullName: 'crossed fingers: light skin tone',
    isBase: false,
    baseHex: '1f91e',
    modifiers: null,
  },
  '1f91e-1f3fc': {
    hexCodePoints: '1f91e-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤞🏼',
    version: 'E3.0',
    baseName: 'crossed fingers',
    fullName: 'crossed fingers: medium-light skin tone',
    isBase: false,
    baseHex: '1f91e',
    modifiers: null,
  },
  '1f91e-1f3fd': {
    hexCodePoints: '1f91e-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤞🏽',
    version: 'E3.0',
    baseName: 'crossed fingers',
    fullName: 'crossed fingers: medium skin tone',
    isBase: false,
    baseHex: '1f91e',
    modifiers: null,
  },
  '1f91e-1f3fe': {
    hexCodePoints: '1f91e-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤞🏾',
    version: 'E3.0',
    baseName: 'crossed fingers',
    fullName: 'crossed fingers: medium-dark skin tone',
    isBase: false,
    baseHex: '1f91e',
    modifiers: null,
  },
  '1f91e-1f3ff': {
    hexCodePoints: '1f91e-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤞🏿',
    version: 'E3.0',
    baseName: 'crossed fingers',
    fullName: 'crossed fingers: dark skin tone',
    isBase: false,
    baseHex: '1f91e',
    modifiers: null,
  },
  '1faf0': {
    hexCodePoints: '1faf0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫰',
    version: 'E14.0',
    baseName: 'hand with index finger and thumb crossed',
    fullName: 'hand with index finger and thumb crossed',
    isBase: true,
    baseHex: '1faf0',
    modifiers: {
      '1f3fb': '1faf0-1f3fb',
      '1f3fc': '1faf0-1f3fc',
      '1f3fd': '1faf0-1f3fd',
      '1f3fe': '1faf0-1f3fe',
      '1f3ff': '1faf0-1f3ff',
    },
  },
  '1faf0-1f3fb': {
    hexCodePoints: '1faf0-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫰🏻',
    version: 'E14.0',
    baseName: 'hand with index finger and thumb crossed',
    fullName: 'hand with index finger and thumb crossed: light skin tone',
    isBase: false,
    baseHex: '1faf0',
    modifiers: null,
  },
  '1faf0-1f3fc': {
    hexCodePoints: '1faf0-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫰🏼',
    version: 'E14.0',
    baseName: 'hand with index finger and thumb crossed',
    fullName: 'hand with index finger and thumb crossed: medium-light skin tone',
    isBase: false,
    baseHex: '1faf0',
    modifiers: null,
  },
  '1faf0-1f3fd': {
    hexCodePoints: '1faf0-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫰🏽',
    version: 'E14.0',
    baseName: 'hand with index finger and thumb crossed',
    fullName: 'hand with index finger and thumb crossed: medium skin tone',
    isBase: false,
    baseHex: '1faf0',
    modifiers: null,
  },
  '1faf0-1f3fe': {
    hexCodePoints: '1faf0-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫰🏾',
    version: 'E14.0',
    baseName: 'hand with index finger and thumb crossed',
    fullName: 'hand with index finger and thumb crossed: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf0',
    modifiers: null,
  },
  '1faf0-1f3ff': {
    hexCodePoints: '1faf0-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫰🏿',
    version: 'E14.0',
    baseName: 'hand with index finger and thumb crossed',
    fullName: 'hand with index finger and thumb crossed: dark skin tone',
    isBase: false,
    baseHex: '1faf0',
    modifiers: null,
  },
  '1f91f': {
    hexCodePoints: '1f91f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤟',
    version: 'E5.0',
    baseName: 'love-you gesture',
    fullName: 'love-you gesture',
    isBase: true,
    baseHex: '1f91f',
    modifiers: {
      '1f3fb': '1f91f-1f3fb',
      '1f3fc': '1f91f-1f3fc',
      '1f3fd': '1f91f-1f3fd',
      '1f3fe': '1f91f-1f3fe',
      '1f3ff': '1f91f-1f3ff',
    },
  },
  '1f91f-1f3fb': {
    hexCodePoints: '1f91f-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤟🏻',
    version: 'E5.0',
    baseName: 'love-you gesture',
    fullName: 'love-you gesture: light skin tone',
    isBase: false,
    baseHex: '1f91f',
    modifiers: null,
  },
  '1f91f-1f3fc': {
    hexCodePoints: '1f91f-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤟🏼',
    version: 'E5.0',
    baseName: 'love-you gesture',
    fullName: 'love-you gesture: medium-light skin tone',
    isBase: false,
    baseHex: '1f91f',
    modifiers: null,
  },
  '1f91f-1f3fd': {
    hexCodePoints: '1f91f-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤟🏽',
    version: 'E5.0',
    baseName: 'love-you gesture',
    fullName: 'love-you gesture: medium skin tone',
    isBase: false,
    baseHex: '1f91f',
    modifiers: null,
  },
  '1f91f-1f3fe': {
    hexCodePoints: '1f91f-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤟🏾',
    version: 'E5.0',
    baseName: 'love-you gesture',
    fullName: 'love-you gesture: medium-dark skin tone',
    isBase: false,
    baseHex: '1f91f',
    modifiers: null,
  },
  '1f91f-1f3ff': {
    hexCodePoints: '1f91f-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤟🏿',
    version: 'E5.0',
    baseName: 'love-you gesture',
    fullName: 'love-you gesture: dark skin tone',
    isBase: false,
    baseHex: '1f91f',
    modifiers: null,
  },
  '1f918': {
    hexCodePoints: '1f918',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤘',
    version: 'E1.0',
    baseName: 'sign of the horns',
    fullName: 'sign of the horns',
    isBase: true,
    baseHex: '1f918',
    modifiers: {
      '1f3fb': '1f918-1f3fb',
      '1f3fc': '1f918-1f3fc',
      '1f3fd': '1f918-1f3fd',
      '1f3fe': '1f918-1f3fe',
      '1f3ff': '1f918-1f3ff',
    },
  },
  '1f918-1f3fb': {
    hexCodePoints: '1f918-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤘🏻',
    version: 'E1.0',
    baseName: 'sign of the horns',
    fullName: 'sign of the horns: light skin tone',
    isBase: false,
    baseHex: '1f918',
    modifiers: null,
  },
  '1f918-1f3fc': {
    hexCodePoints: '1f918-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤘🏼',
    version: 'E1.0',
    baseName: 'sign of the horns',
    fullName: 'sign of the horns: medium-light skin tone',
    isBase: false,
    baseHex: '1f918',
    modifiers: null,
  },
  '1f918-1f3fd': {
    hexCodePoints: '1f918-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤘🏽',
    version: 'E1.0',
    baseName: 'sign of the horns',
    fullName: 'sign of the horns: medium skin tone',
    isBase: false,
    baseHex: '1f918',
    modifiers: null,
  },
  '1f918-1f3fe': {
    hexCodePoints: '1f918-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤘🏾',
    version: 'E1.0',
    baseName: 'sign of the horns',
    fullName: 'sign of the horns: medium-dark skin tone',
    isBase: false,
    baseHex: '1f918',
    modifiers: null,
  },
  '1f918-1f3ff': {
    hexCodePoints: '1f918-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤘🏿',
    version: 'E1.0',
    baseName: 'sign of the horns',
    fullName: 'sign of the horns: dark skin tone',
    isBase: false,
    baseHex: '1f918',
    modifiers: null,
  },
  '1f919': {
    hexCodePoints: '1f919',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤙',
    version: 'E3.0',
    baseName: 'call me hand',
    fullName: 'call me hand',
    isBase: true,
    baseHex: '1f919',
    modifiers: {
      '1f3fb': '1f919-1f3fb',
      '1f3fc': '1f919-1f3fc',
      '1f3fd': '1f919-1f3fd',
      '1f3fe': '1f919-1f3fe',
      '1f3ff': '1f919-1f3ff',
    },
  },
  '1f919-1f3fb': {
    hexCodePoints: '1f919-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤙🏻',
    version: 'E3.0',
    baseName: 'call me hand',
    fullName: 'call me hand: light skin tone',
    isBase: false,
    baseHex: '1f919',
    modifiers: null,
  },
  '1f919-1f3fc': {
    hexCodePoints: '1f919-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤙🏼',
    version: 'E3.0',
    baseName: 'call me hand',
    fullName: 'call me hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f919',
    modifiers: null,
  },
  '1f919-1f3fd': {
    hexCodePoints: '1f919-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤙🏽',
    version: 'E3.0',
    baseName: 'call me hand',
    fullName: 'call me hand: medium skin tone',
    isBase: false,
    baseHex: '1f919',
    modifiers: null,
  },
  '1f919-1f3fe': {
    hexCodePoints: '1f919-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤙🏾',
    version: 'E3.0',
    baseName: 'call me hand',
    fullName: 'call me hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f919',
    modifiers: null,
  },
  '1f919-1f3ff': {
    hexCodePoints: '1f919-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤙🏿',
    version: 'E3.0',
    baseName: 'call me hand',
    fullName: 'call me hand: dark skin tone',
    isBase: false,
    baseHex: '1f919',
    modifiers: null,
  },
  '1f448': {
    hexCodePoints: '1f448',
    qualifiedStatus: 'fully-qualified',
    emoji: '👈',
    version: 'E0.6',
    baseName: 'backhand index pointing left',
    fullName: 'backhand index pointing left',
    isBase: true,
    baseHex: '1f448',
    modifiers: {
      '1f3fb': '1f448-1f3fb',
      '1f3fc': '1f448-1f3fc',
      '1f3fd': '1f448-1f3fd',
      '1f3fe': '1f448-1f3fe',
      '1f3ff': '1f448-1f3ff',
    },
  },
  '1f448-1f3fb': {
    hexCodePoints: '1f448-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👈🏻',
    version: 'E1.0',
    baseName: 'backhand index pointing left',
    fullName: 'backhand index pointing left: light skin tone',
    isBase: false,
    baseHex: '1f448',
    modifiers: null,
  },
  '1f448-1f3fc': {
    hexCodePoints: '1f448-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👈🏼',
    version: 'E1.0',
    baseName: 'backhand index pointing left',
    fullName: 'backhand index pointing left: medium-light skin tone',
    isBase: false,
    baseHex: '1f448',
    modifiers: null,
  },
  '1f448-1f3fd': {
    hexCodePoints: '1f448-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👈🏽',
    version: 'E1.0',
    baseName: 'backhand index pointing left',
    fullName: 'backhand index pointing left: medium skin tone',
    isBase: false,
    baseHex: '1f448',
    modifiers: null,
  },
  '1f448-1f3fe': {
    hexCodePoints: '1f448-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👈🏾',
    version: 'E1.0',
    baseName: 'backhand index pointing left',
    fullName: 'backhand index pointing left: medium-dark skin tone',
    isBase: false,
    baseHex: '1f448',
    modifiers: null,
  },
  '1f448-1f3ff': {
    hexCodePoints: '1f448-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👈🏿',
    version: 'E1.0',
    baseName: 'backhand index pointing left',
    fullName: 'backhand index pointing left: dark skin tone',
    isBase: false,
    baseHex: '1f448',
    modifiers: null,
  },
  '1f449': {
    hexCodePoints: '1f449',
    qualifiedStatus: 'fully-qualified',
    emoji: '👉',
    version: 'E0.6',
    baseName: 'backhand index pointing right',
    fullName: 'backhand index pointing right',
    isBase: true,
    baseHex: '1f449',
    modifiers: {
      '1f3fb': '1f449-1f3fb',
      '1f3fc': '1f449-1f3fc',
      '1f3fd': '1f449-1f3fd',
      '1f3fe': '1f449-1f3fe',
      '1f3ff': '1f449-1f3ff',
    },
  },
  '1f449-1f3fb': {
    hexCodePoints: '1f449-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👉🏻',
    version: 'E1.0',
    baseName: 'backhand index pointing right',
    fullName: 'backhand index pointing right: light skin tone',
    isBase: false,
    baseHex: '1f449',
    modifiers: null,
  },
  '1f449-1f3fc': {
    hexCodePoints: '1f449-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👉🏼',
    version: 'E1.0',
    baseName: 'backhand index pointing right',
    fullName: 'backhand index pointing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f449',
    modifiers: null,
  },
  '1f449-1f3fd': {
    hexCodePoints: '1f449-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👉🏽',
    version: 'E1.0',
    baseName: 'backhand index pointing right',
    fullName: 'backhand index pointing right: medium skin tone',
    isBase: false,
    baseHex: '1f449',
    modifiers: null,
  },
  '1f449-1f3fe': {
    hexCodePoints: '1f449-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👉🏾',
    version: 'E1.0',
    baseName: 'backhand index pointing right',
    fullName: 'backhand index pointing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f449',
    modifiers: null,
  },
  '1f449-1f3ff': {
    hexCodePoints: '1f449-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👉🏿',
    version: 'E1.0',
    baseName: 'backhand index pointing right',
    fullName: 'backhand index pointing right: dark skin tone',
    isBase: false,
    baseHex: '1f449',
    modifiers: null,
  },
  '1f446': {
    hexCodePoints: '1f446',
    qualifiedStatus: 'fully-qualified',
    emoji: '👆',
    version: 'E0.6',
    baseName: 'backhand index pointing up',
    fullName: 'backhand index pointing up',
    isBase: true,
    baseHex: '1f446',
    modifiers: {
      '1f3fb': '1f446-1f3fb',
      '1f3fc': '1f446-1f3fc',
      '1f3fd': '1f446-1f3fd',
      '1f3fe': '1f446-1f3fe',
      '1f3ff': '1f446-1f3ff',
    },
  },
  '1f446-1f3fb': {
    hexCodePoints: '1f446-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👆🏻',
    version: 'E1.0',
    baseName: 'backhand index pointing up',
    fullName: 'backhand index pointing up: light skin tone',
    isBase: false,
    baseHex: '1f446',
    modifiers: null,
  },
  '1f446-1f3fc': {
    hexCodePoints: '1f446-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👆🏼',
    version: 'E1.0',
    baseName: 'backhand index pointing up',
    fullName: 'backhand index pointing up: medium-light skin tone',
    isBase: false,
    baseHex: '1f446',
    modifiers: null,
  },
  '1f446-1f3fd': {
    hexCodePoints: '1f446-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👆🏽',
    version: 'E1.0',
    baseName: 'backhand index pointing up',
    fullName: 'backhand index pointing up: medium skin tone',
    isBase: false,
    baseHex: '1f446',
    modifiers: null,
  },
  '1f446-1f3fe': {
    hexCodePoints: '1f446-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👆🏾',
    version: 'E1.0',
    baseName: 'backhand index pointing up',
    fullName: 'backhand index pointing up: medium-dark skin tone',
    isBase: false,
    baseHex: '1f446',
    modifiers: null,
  },
  '1f446-1f3ff': {
    hexCodePoints: '1f446-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👆🏿',
    version: 'E1.0',
    baseName: 'backhand index pointing up',
    fullName: 'backhand index pointing up: dark skin tone',
    isBase: false,
    baseHex: '1f446',
    modifiers: null,
  },
  '1f595': {
    hexCodePoints: '1f595',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖕',
    version: 'E1.0',
    baseName: 'middle finger',
    fullName: 'middle finger',
    isBase: true,
    baseHex: '1f595',
    modifiers: {
      '1f3fb': '1f595-1f3fb',
      '1f3fc': '1f595-1f3fc',
      '1f3fd': '1f595-1f3fd',
      '1f3fe': '1f595-1f3fe',
      '1f3ff': '1f595-1f3ff',
    },
  },
  '1f595-1f3fb': {
    hexCodePoints: '1f595-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖕🏻',
    version: 'E1.0',
    baseName: 'middle finger',
    fullName: 'middle finger: light skin tone',
    isBase: false,
    baseHex: '1f595',
    modifiers: null,
  },
  '1f595-1f3fc': {
    hexCodePoints: '1f595-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖕🏼',
    version: 'E1.0',
    baseName: 'middle finger',
    fullName: 'middle finger: medium-light skin tone',
    isBase: false,
    baseHex: '1f595',
    modifiers: null,
  },
  '1f595-1f3fd': {
    hexCodePoints: '1f595-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖕🏽',
    version: 'E1.0',
    baseName: 'middle finger',
    fullName: 'middle finger: medium skin tone',
    isBase: false,
    baseHex: '1f595',
    modifiers: null,
  },
  '1f595-1f3fe': {
    hexCodePoints: '1f595-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖕🏾',
    version: 'E1.0',
    baseName: 'middle finger',
    fullName: 'middle finger: medium-dark skin tone',
    isBase: false,
    baseHex: '1f595',
    modifiers: null,
  },
  '1f595-1f3ff': {
    hexCodePoints: '1f595-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖕🏿',
    version: 'E1.0',
    baseName: 'middle finger',
    fullName: 'middle finger: dark skin tone',
    isBase: false,
    baseHex: '1f595',
    modifiers: null,
  },
  '1f447': {
    hexCodePoints: '1f447',
    qualifiedStatus: 'fully-qualified',
    emoji: '👇',
    version: 'E0.6',
    baseName: 'backhand index pointing down',
    fullName: 'backhand index pointing down',
    isBase: true,
    baseHex: '1f447',
    modifiers: {
      '1f3fb': '1f447-1f3fb',
      '1f3fc': '1f447-1f3fc',
      '1f3fd': '1f447-1f3fd',
      '1f3fe': '1f447-1f3fe',
      '1f3ff': '1f447-1f3ff',
    },
  },
  '1f447-1f3fb': {
    hexCodePoints: '1f447-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👇🏻',
    version: 'E1.0',
    baseName: 'backhand index pointing down',
    fullName: 'backhand index pointing down: light skin tone',
    isBase: false,
    baseHex: '1f447',
    modifiers: null,
  },
  '1f447-1f3fc': {
    hexCodePoints: '1f447-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👇🏼',
    version: 'E1.0',
    baseName: 'backhand index pointing down',
    fullName: 'backhand index pointing down: medium-light skin tone',
    isBase: false,
    baseHex: '1f447',
    modifiers: null,
  },
  '1f447-1f3fd': {
    hexCodePoints: '1f447-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👇🏽',
    version: 'E1.0',
    baseName: 'backhand index pointing down',
    fullName: 'backhand index pointing down: medium skin tone',
    isBase: false,
    baseHex: '1f447',
    modifiers: null,
  },
  '1f447-1f3fe': {
    hexCodePoints: '1f447-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👇🏾',
    version: 'E1.0',
    baseName: 'backhand index pointing down',
    fullName: 'backhand index pointing down: medium-dark skin tone',
    isBase: false,
    baseHex: '1f447',
    modifiers: null,
  },
  '1f447-1f3ff': {
    hexCodePoints: '1f447-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👇🏿',
    version: 'E1.0',
    baseName: 'backhand index pointing down',
    fullName: 'backhand index pointing down: dark skin tone',
    isBase: false,
    baseHex: '1f447',
    modifiers: null,
  },
  '261d-fe0f': {
    hexCodePoints: '261d-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☝️',
    version: 'E0.6',
    baseName: 'index pointing up',
    fullName: 'index pointing up',
    isBase: true,
    baseHex: '261d-fe0f',
    modifiers: {
      '1f3fb': '261d-1f3fb',
      '1f3fc': '261d-1f3fc',
      '1f3fd': '261d-1f3fd',
      '1f3fe': '261d-1f3fe',
      '1f3ff': '261d-1f3ff',
    },
  },
  '261d': {
    hexCodePoints: '261d',
    qualifiedStatus: 'unqualified',
    emoji: '☝',
    version: 'E0.6',
    baseName: 'index pointing up',
    fullName: 'index pointing up',
    isBase: false,
    baseHex: '261d-fe0f',
    modifiers: null,
  },
  '261d-1f3fb': {
    hexCodePoints: '261d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '☝🏻',
    version: 'E1.0',
    baseName: 'index pointing up',
    fullName: 'index pointing up: light skin tone',
    isBase: false,
    baseHex: '261d-fe0f',
    modifiers: null,
  },
  '261d-1f3fc': {
    hexCodePoints: '261d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '☝🏼',
    version: 'E1.0',
    baseName: 'index pointing up',
    fullName: 'index pointing up: medium-light skin tone',
    isBase: false,
    baseHex: '261d-fe0f',
    modifiers: null,
  },
  '261d-1f3fd': {
    hexCodePoints: '261d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '☝🏽',
    version: 'E1.0',
    baseName: 'index pointing up',
    fullName: 'index pointing up: medium skin tone',
    isBase: false,
    baseHex: '261d-fe0f',
    modifiers: null,
  },
  '261d-1f3fe': {
    hexCodePoints: '261d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '☝🏾',
    version: 'E1.0',
    baseName: 'index pointing up',
    fullName: 'index pointing up: medium-dark skin tone',
    isBase: false,
    baseHex: '261d-fe0f',
    modifiers: null,
  },
  '261d-1f3ff': {
    hexCodePoints: '261d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '☝🏿',
    version: 'E1.0',
    baseName: 'index pointing up',
    fullName: 'index pointing up: dark skin tone',
    isBase: false,
    baseHex: '261d-fe0f',
    modifiers: null,
  },
  '1faf5': {
    hexCodePoints: '1faf5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫵',
    version: 'E14.0',
    baseName: 'index pointing at the viewer',
    fullName: 'index pointing at the viewer',
    isBase: true,
    baseHex: '1faf5',
    modifiers: {
      '1f3fb': '1faf5-1f3fb',
      '1f3fc': '1faf5-1f3fc',
      '1f3fd': '1faf5-1f3fd',
      '1f3fe': '1faf5-1f3fe',
      '1f3ff': '1faf5-1f3ff',
    },
  },
  '1faf5-1f3fb': {
    hexCodePoints: '1faf5-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫵🏻',
    version: 'E14.0',
    baseName: 'index pointing at the viewer',
    fullName: 'index pointing at the viewer: light skin tone',
    isBase: false,
    baseHex: '1faf5',
    modifiers: null,
  },
  '1faf5-1f3fc': {
    hexCodePoints: '1faf5-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫵🏼',
    version: 'E14.0',
    baseName: 'index pointing at the viewer',
    fullName: 'index pointing at the viewer: medium-light skin tone',
    isBase: false,
    baseHex: '1faf5',
    modifiers: null,
  },
  '1faf5-1f3fd': {
    hexCodePoints: '1faf5-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫵🏽',
    version: 'E14.0',
    baseName: 'index pointing at the viewer',
    fullName: 'index pointing at the viewer: medium skin tone',
    isBase: false,
    baseHex: '1faf5',
    modifiers: null,
  },
  '1faf5-1f3fe': {
    hexCodePoints: '1faf5-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫵🏾',
    version: 'E14.0',
    baseName: 'index pointing at the viewer',
    fullName: 'index pointing at the viewer: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf5',
    modifiers: null,
  },
  '1faf5-1f3ff': {
    hexCodePoints: '1faf5-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫵🏿',
    version: 'E14.0',
    baseName: 'index pointing at the viewer',
    fullName: 'index pointing at the viewer: dark skin tone',
    isBase: false,
    baseHex: '1faf5',
    modifiers: null,
  },
  '1f44d': {
    hexCodePoints: '1f44d',
    qualifiedStatus: 'fully-qualified',
    emoji: '👍',
    version: 'E0.6',
    baseName: 'thumbs up',
    fullName: 'thumbs up',
    isBase: true,
    baseHex: '1f44d',
    modifiers: {
      '1f3fb': '1f44d-1f3fb',
      '1f3fc': '1f44d-1f3fc',
      '1f3fd': '1f44d-1f3fd',
      '1f3fe': '1f44d-1f3fe',
      '1f3ff': '1f44d-1f3ff',
    },
  },
  '1f44d-1f3fb': {
    hexCodePoints: '1f44d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👍🏻',
    version: 'E1.0',
    baseName: 'thumbs up',
    fullName: 'thumbs up: light skin tone',
    isBase: false,
    baseHex: '1f44d',
    modifiers: null,
  },
  '1f44d-1f3fc': {
    hexCodePoints: '1f44d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👍🏼',
    version: 'E1.0',
    baseName: 'thumbs up',
    fullName: 'thumbs up: medium-light skin tone',
    isBase: false,
    baseHex: '1f44d',
    modifiers: null,
  },
  '1f44d-1f3fd': {
    hexCodePoints: '1f44d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👍🏽',
    version: 'E1.0',
    baseName: 'thumbs up',
    fullName: 'thumbs up: medium skin tone',
    isBase: false,
    baseHex: '1f44d',
    modifiers: null,
  },
  '1f44d-1f3fe': {
    hexCodePoints: '1f44d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👍🏾',
    version: 'E1.0',
    baseName: 'thumbs up',
    fullName: 'thumbs up: medium-dark skin tone',
    isBase: false,
    baseHex: '1f44d',
    modifiers: null,
  },
  '1f44d-1f3ff': {
    hexCodePoints: '1f44d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👍🏿',
    version: 'E1.0',
    baseName: 'thumbs up',
    fullName: 'thumbs up: dark skin tone',
    isBase: false,
    baseHex: '1f44d',
    modifiers: null,
  },
  '1f44e': {
    hexCodePoints: '1f44e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👎',
    version: 'E0.6',
    baseName: 'thumbs down',
    fullName: 'thumbs down',
    isBase: true,
    baseHex: '1f44e',
    modifiers: {
      '1f3fb': '1f44e-1f3fb',
      '1f3fc': '1f44e-1f3fc',
      '1f3fd': '1f44e-1f3fd',
      '1f3fe': '1f44e-1f3fe',
      '1f3ff': '1f44e-1f3ff',
    },
  },
  '1f44e-1f3fb': {
    hexCodePoints: '1f44e-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👎🏻',
    version: 'E1.0',
    baseName: 'thumbs down',
    fullName: 'thumbs down: light skin tone',
    isBase: false,
    baseHex: '1f44e',
    modifiers: null,
  },
  '1f44e-1f3fc': {
    hexCodePoints: '1f44e-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👎🏼',
    version: 'E1.0',
    baseName: 'thumbs down',
    fullName: 'thumbs down: medium-light skin tone',
    isBase: false,
    baseHex: '1f44e',
    modifiers: null,
  },
  '1f44e-1f3fd': {
    hexCodePoints: '1f44e-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👎🏽',
    version: 'E1.0',
    baseName: 'thumbs down',
    fullName: 'thumbs down: medium skin tone',
    isBase: false,
    baseHex: '1f44e',
    modifiers: null,
  },
  '1f44e-1f3fe': {
    hexCodePoints: '1f44e-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👎🏾',
    version: 'E1.0',
    baseName: 'thumbs down',
    fullName: 'thumbs down: medium-dark skin tone',
    isBase: false,
    baseHex: '1f44e',
    modifiers: null,
  },
  '1f44e-1f3ff': {
    hexCodePoints: '1f44e-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👎🏿',
    version: 'E1.0',
    baseName: 'thumbs down',
    fullName: 'thumbs down: dark skin tone',
    isBase: false,
    baseHex: '1f44e',
    modifiers: null,
  },
  '270a': {
    hexCodePoints: '270a',
    qualifiedStatus: 'fully-qualified',
    emoji: '✊',
    version: 'E0.6',
    baseName: 'raised fist',
    fullName: 'raised fist',
    isBase: true,
    baseHex: '270a',
    modifiers: {
      '1f3fb': '270a-1f3fb',
      '1f3fc': '270a-1f3fc',
      '1f3fd': '270a-1f3fd',
      '1f3fe': '270a-1f3fe',
      '1f3ff': '270a-1f3ff',
    },
  },
  '270a-1f3fb': {
    hexCodePoints: '270a-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '✊🏻',
    version: 'E1.0',
    baseName: 'raised fist',
    fullName: 'raised fist: light skin tone',
    isBase: false,
    baseHex: '270a',
    modifiers: null,
  },
  '270a-1f3fc': {
    hexCodePoints: '270a-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '✊🏼',
    version: 'E1.0',
    baseName: 'raised fist',
    fullName: 'raised fist: medium-light skin tone',
    isBase: false,
    baseHex: '270a',
    modifiers: null,
  },
  '270a-1f3fd': {
    hexCodePoints: '270a-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '✊🏽',
    version: 'E1.0',
    baseName: 'raised fist',
    fullName: 'raised fist: medium skin tone',
    isBase: false,
    baseHex: '270a',
    modifiers: null,
  },
  '270a-1f3fe': {
    hexCodePoints: '270a-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '✊🏾',
    version: 'E1.0',
    baseName: 'raised fist',
    fullName: 'raised fist: medium-dark skin tone',
    isBase: false,
    baseHex: '270a',
    modifiers: null,
  },
  '270a-1f3ff': {
    hexCodePoints: '270a-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '✊🏿',
    version: 'E1.0',
    baseName: 'raised fist',
    fullName: 'raised fist: dark skin tone',
    isBase: false,
    baseHex: '270a',
    modifiers: null,
  },
  '1f44a': {
    hexCodePoints: '1f44a',
    qualifiedStatus: 'fully-qualified',
    emoji: '👊',
    version: 'E0.6',
    baseName: 'oncoming fist',
    fullName: 'oncoming fist',
    isBase: true,
    baseHex: '1f44a',
    modifiers: {
      '1f3fb': '1f44a-1f3fb',
      '1f3fc': '1f44a-1f3fc',
      '1f3fd': '1f44a-1f3fd',
      '1f3fe': '1f44a-1f3fe',
      '1f3ff': '1f44a-1f3ff',
    },
  },
  '1f44a-1f3fb': {
    hexCodePoints: '1f44a-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👊🏻',
    version: 'E1.0',
    baseName: 'oncoming fist',
    fullName: 'oncoming fist: light skin tone',
    isBase: false,
    baseHex: '1f44a',
    modifiers: null,
  },
  '1f44a-1f3fc': {
    hexCodePoints: '1f44a-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👊🏼',
    version: 'E1.0',
    baseName: 'oncoming fist',
    fullName: 'oncoming fist: medium-light skin tone',
    isBase: false,
    baseHex: '1f44a',
    modifiers: null,
  },
  '1f44a-1f3fd': {
    hexCodePoints: '1f44a-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👊🏽',
    version: 'E1.0',
    baseName: 'oncoming fist',
    fullName: 'oncoming fist: medium skin tone',
    isBase: false,
    baseHex: '1f44a',
    modifiers: null,
  },
  '1f44a-1f3fe': {
    hexCodePoints: '1f44a-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👊🏾',
    version: 'E1.0',
    baseName: 'oncoming fist',
    fullName: 'oncoming fist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f44a',
    modifiers: null,
  },
  '1f44a-1f3ff': {
    hexCodePoints: '1f44a-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👊🏿',
    version: 'E1.0',
    baseName: 'oncoming fist',
    fullName: 'oncoming fist: dark skin tone',
    isBase: false,
    baseHex: '1f44a',
    modifiers: null,
  },
  '1f91b': {
    hexCodePoints: '1f91b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤛',
    version: 'E3.0',
    baseName: 'left-facing fist',
    fullName: 'left-facing fist',
    isBase: true,
    baseHex: '1f91b',
    modifiers: {
      '1f3fb': '1f91b-1f3fb',
      '1f3fc': '1f91b-1f3fc',
      '1f3fd': '1f91b-1f3fd',
      '1f3fe': '1f91b-1f3fe',
      '1f3ff': '1f91b-1f3ff',
    },
  },
  '1f91b-1f3fb': {
    hexCodePoints: '1f91b-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤛🏻',
    version: 'E3.0',
    baseName: 'left-facing fist',
    fullName: 'left-facing fist: light skin tone',
    isBase: false,
    baseHex: '1f91b',
    modifiers: null,
  },
  '1f91b-1f3fc': {
    hexCodePoints: '1f91b-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤛🏼',
    version: 'E3.0',
    baseName: 'left-facing fist',
    fullName: 'left-facing fist: medium-light skin tone',
    isBase: false,
    baseHex: '1f91b',
    modifiers: null,
  },
  '1f91b-1f3fd': {
    hexCodePoints: '1f91b-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤛🏽',
    version: 'E3.0',
    baseName: 'left-facing fist',
    fullName: 'left-facing fist: medium skin tone',
    isBase: false,
    baseHex: '1f91b',
    modifiers: null,
  },
  '1f91b-1f3fe': {
    hexCodePoints: '1f91b-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤛🏾',
    version: 'E3.0',
    baseName: 'left-facing fist',
    fullName: 'left-facing fist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f91b',
    modifiers: null,
  },
  '1f91b-1f3ff': {
    hexCodePoints: '1f91b-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤛🏿',
    version: 'E3.0',
    baseName: 'left-facing fist',
    fullName: 'left-facing fist: dark skin tone',
    isBase: false,
    baseHex: '1f91b',
    modifiers: null,
  },
  '1f91c': {
    hexCodePoints: '1f91c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤜',
    version: 'E3.0',
    baseName: 'right-facing fist',
    fullName: 'right-facing fist',
    isBase: true,
    baseHex: '1f91c',
    modifiers: {
      '1f3fb': '1f91c-1f3fb',
      '1f3fc': '1f91c-1f3fc',
      '1f3fd': '1f91c-1f3fd',
      '1f3fe': '1f91c-1f3fe',
      '1f3ff': '1f91c-1f3ff',
    },
  },
  '1f91c-1f3fb': {
    hexCodePoints: '1f91c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤜🏻',
    version: 'E3.0',
    baseName: 'right-facing fist',
    fullName: 'right-facing fist: light skin tone',
    isBase: false,
    baseHex: '1f91c',
    modifiers: null,
  },
  '1f91c-1f3fc': {
    hexCodePoints: '1f91c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤜🏼',
    version: 'E3.0',
    baseName: 'right-facing fist',
    fullName: 'right-facing fist: medium-light skin tone',
    isBase: false,
    baseHex: '1f91c',
    modifiers: null,
  },
  '1f91c-1f3fd': {
    hexCodePoints: '1f91c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤜🏽',
    version: 'E3.0',
    baseName: 'right-facing fist',
    fullName: 'right-facing fist: medium skin tone',
    isBase: false,
    baseHex: '1f91c',
    modifiers: null,
  },
  '1f91c-1f3fe': {
    hexCodePoints: '1f91c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤜🏾',
    version: 'E3.0',
    baseName: 'right-facing fist',
    fullName: 'right-facing fist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f91c',
    modifiers: null,
  },
  '1f91c-1f3ff': {
    hexCodePoints: '1f91c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤜🏿',
    version: 'E3.0',
    baseName: 'right-facing fist',
    fullName: 'right-facing fist: dark skin tone',
    isBase: false,
    baseHex: '1f91c',
    modifiers: null,
  },
  '1f44f': {
    hexCodePoints: '1f44f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👏',
    version: 'E0.6',
    baseName: 'clapping hands',
    fullName: 'clapping hands',
    isBase: true,
    baseHex: '1f44f',
    modifiers: {
      '1f3fb': '1f44f-1f3fb',
      '1f3fc': '1f44f-1f3fc',
      '1f3fd': '1f44f-1f3fd',
      '1f3fe': '1f44f-1f3fe',
      '1f3ff': '1f44f-1f3ff',
    },
  },
  '1f44f-1f3fb': {
    hexCodePoints: '1f44f-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👏🏻',
    version: 'E1.0',
    baseName: 'clapping hands',
    fullName: 'clapping hands: light skin tone',
    isBase: false,
    baseHex: '1f44f',
    modifiers: null,
  },
  '1f44f-1f3fc': {
    hexCodePoints: '1f44f-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👏🏼',
    version: 'E1.0',
    baseName: 'clapping hands',
    fullName: 'clapping hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f44f',
    modifiers: null,
  },
  '1f44f-1f3fd': {
    hexCodePoints: '1f44f-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👏🏽',
    version: 'E1.0',
    baseName: 'clapping hands',
    fullName: 'clapping hands: medium skin tone',
    isBase: false,
    baseHex: '1f44f',
    modifiers: null,
  },
  '1f44f-1f3fe': {
    hexCodePoints: '1f44f-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👏🏾',
    version: 'E1.0',
    baseName: 'clapping hands',
    fullName: 'clapping hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f44f',
    modifiers: null,
  },
  '1f44f-1f3ff': {
    hexCodePoints: '1f44f-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👏🏿',
    version: 'E1.0',
    baseName: 'clapping hands',
    fullName: 'clapping hands: dark skin tone',
    isBase: false,
    baseHex: '1f44f',
    modifiers: null,
  },
  '1f64c': {
    hexCodePoints: '1f64c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙌',
    version: 'E0.6',
    baseName: 'raising hands',
    fullName: 'raising hands',
    isBase: true,
    baseHex: '1f64c',
    modifiers: {
      '1f3fb': '1f64c-1f3fb',
      '1f3fc': '1f64c-1f3fc',
      '1f3fd': '1f64c-1f3fd',
      '1f3fe': '1f64c-1f3fe',
      '1f3ff': '1f64c-1f3ff',
    },
  },
  '1f64c-1f3fb': {
    hexCodePoints: '1f64c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙌🏻',
    version: 'E1.0',
    baseName: 'raising hands',
    fullName: 'raising hands: light skin tone',
    isBase: false,
    baseHex: '1f64c',
    modifiers: null,
  },
  '1f64c-1f3fc': {
    hexCodePoints: '1f64c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙌🏼',
    version: 'E1.0',
    baseName: 'raising hands',
    fullName: 'raising hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f64c',
    modifiers: null,
  },
  '1f64c-1f3fd': {
    hexCodePoints: '1f64c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙌🏽',
    version: 'E1.0',
    baseName: 'raising hands',
    fullName: 'raising hands: medium skin tone',
    isBase: false,
    baseHex: '1f64c',
    modifiers: null,
  },
  '1f64c-1f3fe': {
    hexCodePoints: '1f64c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙌🏾',
    version: 'E1.0',
    baseName: 'raising hands',
    fullName: 'raising hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64c',
    modifiers: null,
  },
  '1f64c-1f3ff': {
    hexCodePoints: '1f64c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙌🏿',
    version: 'E1.0',
    baseName: 'raising hands',
    fullName: 'raising hands: dark skin tone',
    isBase: false,
    baseHex: '1f64c',
    modifiers: null,
  },
  '1faf6': {
    hexCodePoints: '1faf6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫶',
    version: 'E14.0',
    baseName: 'heart hands',
    fullName: 'heart hands',
    isBase: true,
    baseHex: '1faf6',
    modifiers: {
      '1f3fb': '1faf6-1f3fb',
      '1f3fc': '1faf6-1f3fc',
      '1f3fd': '1faf6-1f3fd',
      '1f3fe': '1faf6-1f3fe',
      '1f3ff': '1faf6-1f3ff',
    },
  },
  '1faf6-1f3fb': {
    hexCodePoints: '1faf6-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫶🏻',
    version: 'E14.0',
    baseName: 'heart hands',
    fullName: 'heart hands: light skin tone',
    isBase: false,
    baseHex: '1faf6',
    modifiers: null,
  },
  '1faf6-1f3fc': {
    hexCodePoints: '1faf6-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫶🏼',
    version: 'E14.0',
    baseName: 'heart hands',
    fullName: 'heart hands: medium-light skin tone',
    isBase: false,
    baseHex: '1faf6',
    modifiers: null,
  },
  '1faf6-1f3fd': {
    hexCodePoints: '1faf6-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫶🏽',
    version: 'E14.0',
    baseName: 'heart hands',
    fullName: 'heart hands: medium skin tone',
    isBase: false,
    baseHex: '1faf6',
    modifiers: null,
  },
  '1faf6-1f3fe': {
    hexCodePoints: '1faf6-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫶🏾',
    version: 'E14.0',
    baseName: 'heart hands',
    fullName: 'heart hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1faf6',
    modifiers: null,
  },
  '1faf6-1f3ff': {
    hexCodePoints: '1faf6-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫶🏿',
    version: 'E14.0',
    baseName: 'heart hands',
    fullName: 'heart hands: dark skin tone',
    isBase: false,
    baseHex: '1faf6',
    modifiers: null,
  },
  '1f450': {
    hexCodePoints: '1f450',
    qualifiedStatus: 'fully-qualified',
    emoji: '👐',
    version: 'E0.6',
    baseName: 'open hands',
    fullName: 'open hands',
    isBase: true,
    baseHex: '1f450',
    modifiers: {
      '1f3fb': '1f450-1f3fb',
      '1f3fc': '1f450-1f3fc',
      '1f3fd': '1f450-1f3fd',
      '1f3fe': '1f450-1f3fe',
      '1f3ff': '1f450-1f3ff',
    },
  },
  '1f450-1f3fb': {
    hexCodePoints: '1f450-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👐🏻',
    version: 'E1.0',
    baseName: 'open hands',
    fullName: 'open hands: light skin tone',
    isBase: false,
    baseHex: '1f450',
    modifiers: null,
  },
  '1f450-1f3fc': {
    hexCodePoints: '1f450-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👐🏼',
    version: 'E1.0',
    baseName: 'open hands',
    fullName: 'open hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f450',
    modifiers: null,
  },
  '1f450-1f3fd': {
    hexCodePoints: '1f450-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👐🏽',
    version: 'E1.0',
    baseName: 'open hands',
    fullName: 'open hands: medium skin tone',
    isBase: false,
    baseHex: '1f450',
    modifiers: null,
  },
  '1f450-1f3fe': {
    hexCodePoints: '1f450-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👐🏾',
    version: 'E1.0',
    baseName: 'open hands',
    fullName: 'open hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f450',
    modifiers: null,
  },
  '1f450-1f3ff': {
    hexCodePoints: '1f450-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👐🏿',
    version: 'E1.0',
    baseName: 'open hands',
    fullName: 'open hands: dark skin tone',
    isBase: false,
    baseHex: '1f450',
    modifiers: null,
  },
  '1f932': {
    hexCodePoints: '1f932',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤲',
    version: 'E5.0',
    baseName: 'palms up together',
    fullName: 'palms up together',
    isBase: true,
    baseHex: '1f932',
    modifiers: {
      '1f3fb': '1f932-1f3fb',
      '1f3fc': '1f932-1f3fc',
      '1f3fd': '1f932-1f3fd',
      '1f3fe': '1f932-1f3fe',
      '1f3ff': '1f932-1f3ff',
    },
  },
  '1f932-1f3fb': {
    hexCodePoints: '1f932-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤲🏻',
    version: 'E5.0',
    baseName: 'palms up together',
    fullName: 'palms up together: light skin tone',
    isBase: false,
    baseHex: '1f932',
    modifiers: null,
  },
  '1f932-1f3fc': {
    hexCodePoints: '1f932-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤲🏼',
    version: 'E5.0',
    baseName: 'palms up together',
    fullName: 'palms up together: medium-light skin tone',
    isBase: false,
    baseHex: '1f932',
    modifiers: null,
  },
  '1f932-1f3fd': {
    hexCodePoints: '1f932-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤲🏽',
    version: 'E5.0',
    baseName: 'palms up together',
    fullName: 'palms up together: medium skin tone',
    isBase: false,
    baseHex: '1f932',
    modifiers: null,
  },
  '1f932-1f3fe': {
    hexCodePoints: '1f932-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤲🏾',
    version: 'E5.0',
    baseName: 'palms up together',
    fullName: 'palms up together: medium-dark skin tone',
    isBase: false,
    baseHex: '1f932',
    modifiers: null,
  },
  '1f932-1f3ff': {
    hexCodePoints: '1f932-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤲🏿',
    version: 'E5.0',
    baseName: 'palms up together',
    fullName: 'palms up together: dark skin tone',
    isBase: false,
    baseHex: '1f932',
    modifiers: null,
  },
  '1f91d': {
    hexCodePoints: '1f91d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤝',
    version: 'E3.0',
    baseName: 'handshake',
    fullName: 'handshake',
    isBase: true,
    baseHex: '1f91d',
    modifiers: {
      '1f3fb': '1f91d-1f3fb',
      '1f3fc': '1f91d-1f3fc',
      '1f3fd': '1f91d-1f3fd',
      '1f3fe': '1f91d-1f3fe',
      '1f3ff': '1f91d-1f3ff',
    },
  },
  '1f91d-1f3fb': {
    hexCodePoints: '1f91d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤝🏻',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1f91d-1f3fc': {
    hexCodePoints: '1f91d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤝🏼',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1f91d-1f3fd': {
    hexCodePoints: '1f91d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤝🏽',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1f91d-1f3fe': {
    hexCodePoints: '1f91d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤝🏾',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1f91d-1f3ff': {
    hexCodePoints: '1f91d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤝🏿',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fb-200d-1faf2-1f3fc': {
    hexCodePoints: '1faf1-1f3fb-200d-1faf2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏻‍🫲🏼',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fb-200d-1faf2-1f3fd': {
    hexCodePoints: '1faf1-1f3fb-200d-1faf2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏻‍🫲🏽',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fb-200d-1faf2-1f3fe': {
    hexCodePoints: '1faf1-1f3fb-200d-1faf2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏻‍🫲🏾',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fb-200d-1faf2-1f3ff': {
    hexCodePoints: '1faf1-1f3fb-200d-1faf2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏻‍🫲🏿',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fc-200d-1faf2-1f3fb': {
    hexCodePoints: '1faf1-1f3fc-200d-1faf2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏼‍🫲🏻',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fc-200d-1faf2-1f3fd': {
    hexCodePoints: '1faf1-1f3fc-200d-1faf2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏼‍🫲🏽',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fc-200d-1faf2-1f3fe': {
    hexCodePoints: '1faf1-1f3fc-200d-1faf2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏼‍🫲🏾',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fc-200d-1faf2-1f3ff': {
    hexCodePoints: '1faf1-1f3fc-200d-1faf2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏼‍🫲🏿',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fd-200d-1faf2-1f3fb': {
    hexCodePoints: '1faf1-1f3fd-200d-1faf2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏽‍🫲🏻',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fd-200d-1faf2-1f3fc': {
    hexCodePoints: '1faf1-1f3fd-200d-1faf2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏽‍🫲🏼',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fd-200d-1faf2-1f3fe': {
    hexCodePoints: '1faf1-1f3fd-200d-1faf2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏽‍🫲🏾',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fd-200d-1faf2-1f3ff': {
    hexCodePoints: '1faf1-1f3fd-200d-1faf2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏽‍🫲🏿',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fe-200d-1faf2-1f3fb': {
    hexCodePoints: '1faf1-1f3fe-200d-1faf2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏾‍🫲🏻',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fe-200d-1faf2-1f3fc': {
    hexCodePoints: '1faf1-1f3fe-200d-1faf2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏾‍🫲🏼',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fe-200d-1faf2-1f3fd': {
    hexCodePoints: '1faf1-1f3fe-200d-1faf2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏾‍🫲🏽',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3fe-200d-1faf2-1f3ff': {
    hexCodePoints: '1faf1-1f3fe-200d-1faf2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏾‍🫲🏿',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3ff-200d-1faf2-1f3fb': {
    hexCodePoints: '1faf1-1f3ff-200d-1faf2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏿‍🫲🏻',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3ff-200d-1faf2-1f3fc': {
    hexCodePoints: '1faf1-1f3ff-200d-1faf2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏿‍🫲🏼',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3ff-200d-1faf2-1f3fd': {
    hexCodePoints: '1faf1-1f3ff-200d-1faf2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏿‍🫲🏽',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1faf1-1f3ff-200d-1faf2-1f3fe': {
    hexCodePoints: '1faf1-1f3ff-200d-1faf2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫱🏿‍🫲🏾',
    version: 'E14.0',
    baseName: 'handshake',
    fullName: 'handshake: dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f91d',
    modifiers: null,
  },
  '1f64f': {
    hexCodePoints: '1f64f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙏',
    version: 'E0.6',
    baseName: 'folded hands',
    fullName: 'folded hands',
    isBase: true,
    baseHex: '1f64f',
    modifiers: {
      '1f3fb': '1f64f-1f3fb',
      '1f3fc': '1f64f-1f3fc',
      '1f3fd': '1f64f-1f3fd',
      '1f3fe': '1f64f-1f3fe',
      '1f3ff': '1f64f-1f3ff',
    },
  },
  '1f64f-1f3fb': {
    hexCodePoints: '1f64f-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙏🏻',
    version: 'E1.0',
    baseName: 'folded hands',
    fullName: 'folded hands: light skin tone',
    isBase: false,
    baseHex: '1f64f',
    modifiers: null,
  },
  '1f64f-1f3fc': {
    hexCodePoints: '1f64f-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙏🏼',
    version: 'E1.0',
    baseName: 'folded hands',
    fullName: 'folded hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f64f',
    modifiers: null,
  },
  '1f64f-1f3fd': {
    hexCodePoints: '1f64f-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙏🏽',
    version: 'E1.0',
    baseName: 'folded hands',
    fullName: 'folded hands: medium skin tone',
    isBase: false,
    baseHex: '1f64f',
    modifiers: null,
  },
  '1f64f-1f3fe': {
    hexCodePoints: '1f64f-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙏🏾',
    version: 'E1.0',
    baseName: 'folded hands',
    fullName: 'folded hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64f',
    modifiers: null,
  },
  '1f64f-1f3ff': {
    hexCodePoints: '1f64f-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙏🏿',
    version: 'E1.0',
    baseName: 'folded hands',
    fullName: 'folded hands: dark skin tone',
    isBase: false,
    baseHex: '1f64f',
    modifiers: null,
  },
  '270d-fe0f': {
    hexCodePoints: '270d-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✍️',
    version: 'E0.7',
    baseName: 'writing hand',
    fullName: 'writing hand',
    isBase: true,
    baseHex: '270d-fe0f',
    modifiers: {
      '1f3fb': '270d-1f3fb',
      '1f3fc': '270d-1f3fc',
      '1f3fd': '270d-1f3fd',
      '1f3fe': '270d-1f3fe',
      '1f3ff': '270d-1f3ff',
    },
  },
  '270d': {
    hexCodePoints: '270d',
    qualifiedStatus: 'unqualified',
    emoji: '✍',
    version: 'E0.7',
    baseName: 'writing hand',
    fullName: 'writing hand',
    isBase: false,
    baseHex: '270d-fe0f',
    modifiers: null,
  },
  '270d-1f3fb': {
    hexCodePoints: '270d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '✍🏻',
    version: 'E1.0',
    baseName: 'writing hand',
    fullName: 'writing hand: light skin tone',
    isBase: false,
    baseHex: '270d-fe0f',
    modifiers: null,
  },
  '270d-1f3fc': {
    hexCodePoints: '270d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '✍🏼',
    version: 'E1.0',
    baseName: 'writing hand',
    fullName: 'writing hand: medium-light skin tone',
    isBase: false,
    baseHex: '270d-fe0f',
    modifiers: null,
  },
  '270d-1f3fd': {
    hexCodePoints: '270d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '✍🏽',
    version: 'E1.0',
    baseName: 'writing hand',
    fullName: 'writing hand: medium skin tone',
    isBase: false,
    baseHex: '270d-fe0f',
    modifiers: null,
  },
  '270d-1f3fe': {
    hexCodePoints: '270d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '✍🏾',
    version: 'E1.0',
    baseName: 'writing hand',
    fullName: 'writing hand: medium-dark skin tone',
    isBase: false,
    baseHex: '270d-fe0f',
    modifiers: null,
  },
  '270d-1f3ff': {
    hexCodePoints: '270d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '✍🏿',
    version: 'E1.0',
    baseName: 'writing hand',
    fullName: 'writing hand: dark skin tone',
    isBase: false,
    baseHex: '270d-fe0f',
    modifiers: null,
  },
  '1f485': {
    hexCodePoints: '1f485',
    qualifiedStatus: 'fully-qualified',
    emoji: '💅',
    version: 'E0.6',
    baseName: 'nail polish',
    fullName: 'nail polish',
    isBase: true,
    baseHex: '1f485',
    modifiers: {
      '1f3fb': '1f485-1f3fb',
      '1f3fc': '1f485-1f3fc',
      '1f3fd': '1f485-1f3fd',
      '1f3fe': '1f485-1f3fe',
      '1f3ff': '1f485-1f3ff',
    },
  },
  '1f485-1f3fb': {
    hexCodePoints: '1f485-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💅🏻',
    version: 'E1.0',
    baseName: 'nail polish',
    fullName: 'nail polish: light skin tone',
    isBase: false,
    baseHex: '1f485',
    modifiers: null,
  },
  '1f485-1f3fc': {
    hexCodePoints: '1f485-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💅🏼',
    version: 'E1.0',
    baseName: 'nail polish',
    fullName: 'nail polish: medium-light skin tone',
    isBase: false,
    baseHex: '1f485',
    modifiers: null,
  },
  '1f485-1f3fd': {
    hexCodePoints: '1f485-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💅🏽',
    version: 'E1.0',
    baseName: 'nail polish',
    fullName: 'nail polish: medium skin tone',
    isBase: false,
    baseHex: '1f485',
    modifiers: null,
  },
  '1f485-1f3fe': {
    hexCodePoints: '1f485-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💅🏾',
    version: 'E1.0',
    baseName: 'nail polish',
    fullName: 'nail polish: medium-dark skin tone',
    isBase: false,
    baseHex: '1f485',
    modifiers: null,
  },
  '1f485-1f3ff': {
    hexCodePoints: '1f485-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💅🏿',
    version: 'E1.0',
    baseName: 'nail polish',
    fullName: 'nail polish: dark skin tone',
    isBase: false,
    baseHex: '1f485',
    modifiers: null,
  },
  '1f933': {
    hexCodePoints: '1f933',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤳',
    version: 'E3.0',
    baseName: 'selfie',
    fullName: 'selfie',
    isBase: true,
    baseHex: '1f933',
    modifiers: {
      '1f3fb': '1f933-1f3fb',
      '1f3fc': '1f933-1f3fc',
      '1f3fd': '1f933-1f3fd',
      '1f3fe': '1f933-1f3fe',
      '1f3ff': '1f933-1f3ff',
    },
  },
  '1f933-1f3fb': {
    hexCodePoints: '1f933-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤳🏻',
    version: 'E3.0',
    baseName: 'selfie',
    fullName: 'selfie: light skin tone',
    isBase: false,
    baseHex: '1f933',
    modifiers: null,
  },
  '1f933-1f3fc': {
    hexCodePoints: '1f933-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤳🏼',
    version: 'E3.0',
    baseName: 'selfie',
    fullName: 'selfie: medium-light skin tone',
    isBase: false,
    baseHex: '1f933',
    modifiers: null,
  },
  '1f933-1f3fd': {
    hexCodePoints: '1f933-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤳🏽',
    version: 'E3.0',
    baseName: 'selfie',
    fullName: 'selfie: medium skin tone',
    isBase: false,
    baseHex: '1f933',
    modifiers: null,
  },
  '1f933-1f3fe': {
    hexCodePoints: '1f933-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤳🏾',
    version: 'E3.0',
    baseName: 'selfie',
    fullName: 'selfie: medium-dark skin tone',
    isBase: false,
    baseHex: '1f933',
    modifiers: null,
  },
  '1f933-1f3ff': {
    hexCodePoints: '1f933-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤳🏿',
    version: 'E3.0',
    baseName: 'selfie',
    fullName: 'selfie: dark skin tone',
    isBase: false,
    baseHex: '1f933',
    modifiers: null,
  },
  '1f4aa': {
    hexCodePoints: '1f4aa',
    qualifiedStatus: 'fully-qualified',
    emoji: '💪',
    version: 'E0.6',
    baseName: 'flexed biceps',
    fullName: 'flexed biceps',
    isBase: true,
    baseHex: '1f4aa',
    modifiers: {
      '1f3fb': '1f4aa-1f3fb',
      '1f3fc': '1f4aa-1f3fc',
      '1f3fd': '1f4aa-1f3fd',
      '1f3fe': '1f4aa-1f3fe',
      '1f3ff': '1f4aa-1f3ff',
    },
  },
  '1f4aa-1f3fb': {
    hexCodePoints: '1f4aa-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💪🏻',
    version: 'E1.0',
    baseName: 'flexed biceps',
    fullName: 'flexed biceps: light skin tone',
    isBase: false,
    baseHex: '1f4aa',
    modifiers: null,
  },
  '1f4aa-1f3fc': {
    hexCodePoints: '1f4aa-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💪🏼',
    version: 'E1.0',
    baseName: 'flexed biceps',
    fullName: 'flexed biceps: medium-light skin tone',
    isBase: false,
    baseHex: '1f4aa',
    modifiers: null,
  },
  '1f4aa-1f3fd': {
    hexCodePoints: '1f4aa-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💪🏽',
    version: 'E1.0',
    baseName: 'flexed biceps',
    fullName: 'flexed biceps: medium skin tone',
    isBase: false,
    baseHex: '1f4aa',
    modifiers: null,
  },
  '1f4aa-1f3fe': {
    hexCodePoints: '1f4aa-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💪🏾',
    version: 'E1.0',
    baseName: 'flexed biceps',
    fullName: 'flexed biceps: medium-dark skin tone',
    isBase: false,
    baseHex: '1f4aa',
    modifiers: null,
  },
  '1f4aa-1f3ff': {
    hexCodePoints: '1f4aa-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💪🏿',
    version: 'E1.0',
    baseName: 'flexed biceps',
    fullName: 'flexed biceps: dark skin tone',
    isBase: false,
    baseHex: '1f4aa',
    modifiers: null,
  },
  '1f9be': {
    hexCodePoints: '1f9be',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦾',
    version: 'E12.0',
    baseName: 'mechanical arm',
    fullName: 'mechanical arm',
    isBase: true,
    baseHex: '1f9be',
    modifiers: null,
  },
  '1f9bf': {
    hexCodePoints: '1f9bf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦿',
    version: 'E12.0',
    baseName: 'mechanical leg',
    fullName: 'mechanical leg',
    isBase: true,
    baseHex: '1f9bf',
    modifiers: null,
  },
  '1f9b5': {
    hexCodePoints: '1f9b5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦵',
    version: 'E11.0',
    baseName: 'leg',
    fullName: 'leg',
    isBase: true,
    baseHex: '1f9b5',
    modifiers: {
      '1f3fb': '1f9b5-1f3fb',
      '1f3fc': '1f9b5-1f3fc',
      '1f3fd': '1f9b5-1f3fd',
      '1f3fe': '1f9b5-1f3fe',
      '1f3ff': '1f9b5-1f3ff',
    },
  },
  '1f9b5-1f3fb': {
    hexCodePoints: '1f9b5-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦵🏻',
    version: 'E11.0',
    baseName: 'leg',
    fullName: 'leg: light skin tone',
    isBase: false,
    baseHex: '1f9b5',
    modifiers: null,
  },
  '1f9b5-1f3fc': {
    hexCodePoints: '1f9b5-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦵🏼',
    version: 'E11.0',
    baseName: 'leg',
    fullName: 'leg: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b5',
    modifiers: null,
  },
  '1f9b5-1f3fd': {
    hexCodePoints: '1f9b5-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦵🏽',
    version: 'E11.0',
    baseName: 'leg',
    fullName: 'leg: medium skin tone',
    isBase: false,
    baseHex: '1f9b5',
    modifiers: null,
  },
  '1f9b5-1f3fe': {
    hexCodePoints: '1f9b5-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦵🏾',
    version: 'E11.0',
    baseName: 'leg',
    fullName: 'leg: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b5',
    modifiers: null,
  },
  '1f9b5-1f3ff': {
    hexCodePoints: '1f9b5-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦵🏿',
    version: 'E11.0',
    baseName: 'leg',
    fullName: 'leg: dark skin tone',
    isBase: false,
    baseHex: '1f9b5',
    modifiers: null,
  },
  '1f9b6': {
    hexCodePoints: '1f9b6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦶',
    version: 'E11.0',
    baseName: 'foot',
    fullName: 'foot',
    isBase: true,
    baseHex: '1f9b6',
    modifiers: {
      '1f3fb': '1f9b6-1f3fb',
      '1f3fc': '1f9b6-1f3fc',
      '1f3fd': '1f9b6-1f3fd',
      '1f3fe': '1f9b6-1f3fe',
      '1f3ff': '1f9b6-1f3ff',
    },
  },
  '1f9b6-1f3fb': {
    hexCodePoints: '1f9b6-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦶🏻',
    version: 'E11.0',
    baseName: 'foot',
    fullName: 'foot: light skin tone',
    isBase: false,
    baseHex: '1f9b6',
    modifiers: null,
  },
  '1f9b6-1f3fc': {
    hexCodePoints: '1f9b6-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦶🏼',
    version: 'E11.0',
    baseName: 'foot',
    fullName: 'foot: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b6',
    modifiers: null,
  },
  '1f9b6-1f3fd': {
    hexCodePoints: '1f9b6-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦶🏽',
    version: 'E11.0',
    baseName: 'foot',
    fullName: 'foot: medium skin tone',
    isBase: false,
    baseHex: '1f9b6',
    modifiers: null,
  },
  '1f9b6-1f3fe': {
    hexCodePoints: '1f9b6-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦶🏾',
    version: 'E11.0',
    baseName: 'foot',
    fullName: 'foot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b6',
    modifiers: null,
  },
  '1f9b6-1f3ff': {
    hexCodePoints: '1f9b6-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦶🏿',
    version: 'E11.0',
    baseName: 'foot',
    fullName: 'foot: dark skin tone',
    isBase: false,
    baseHex: '1f9b6',
    modifiers: null,
  },
  '1f442': {
    hexCodePoints: '1f442',
    qualifiedStatus: 'fully-qualified',
    emoji: '👂',
    version: 'E0.6',
    baseName: 'ear',
    fullName: 'ear',
    isBase: true,
    baseHex: '1f442',
    modifiers: {
      '1f3fb': '1f442-1f3fb',
      '1f3fc': '1f442-1f3fc',
      '1f3fd': '1f442-1f3fd',
      '1f3fe': '1f442-1f3fe',
      '1f3ff': '1f442-1f3ff',
    },
  },
  '1f442-1f3fb': {
    hexCodePoints: '1f442-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👂🏻',
    version: 'E1.0',
    baseName: 'ear',
    fullName: 'ear: light skin tone',
    isBase: false,
    baseHex: '1f442',
    modifiers: null,
  },
  '1f442-1f3fc': {
    hexCodePoints: '1f442-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👂🏼',
    version: 'E1.0',
    baseName: 'ear',
    fullName: 'ear: medium-light skin tone',
    isBase: false,
    baseHex: '1f442',
    modifiers: null,
  },
  '1f442-1f3fd': {
    hexCodePoints: '1f442-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👂🏽',
    version: 'E1.0',
    baseName: 'ear',
    fullName: 'ear: medium skin tone',
    isBase: false,
    baseHex: '1f442',
    modifiers: null,
  },
  '1f442-1f3fe': {
    hexCodePoints: '1f442-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👂🏾',
    version: 'E1.0',
    baseName: 'ear',
    fullName: 'ear: medium-dark skin tone',
    isBase: false,
    baseHex: '1f442',
    modifiers: null,
  },
  '1f442-1f3ff': {
    hexCodePoints: '1f442-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👂🏿',
    version: 'E1.0',
    baseName: 'ear',
    fullName: 'ear: dark skin tone',
    isBase: false,
    baseHex: '1f442',
    modifiers: null,
  },
  '1f9bb': {
    hexCodePoints: '1f9bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦻',
    version: 'E12.0',
    baseName: 'ear with hearing aid',
    fullName: 'ear with hearing aid',
    isBase: true,
    baseHex: '1f9bb',
    modifiers: {
      '1f3fb': '1f9bb-1f3fb',
      '1f3fc': '1f9bb-1f3fc',
      '1f3fd': '1f9bb-1f3fd',
      '1f3fe': '1f9bb-1f3fe',
      '1f3ff': '1f9bb-1f3ff',
    },
  },
  '1f9bb-1f3fb': {
    hexCodePoints: '1f9bb-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦻🏻',
    version: 'E12.0',
    baseName: 'ear with hearing aid',
    fullName: 'ear with hearing aid: light skin tone',
    isBase: false,
    baseHex: '1f9bb',
    modifiers: null,
  },
  '1f9bb-1f3fc': {
    hexCodePoints: '1f9bb-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦻🏼',
    version: 'E12.0',
    baseName: 'ear with hearing aid',
    fullName: 'ear with hearing aid: medium-light skin tone',
    isBase: false,
    baseHex: '1f9bb',
    modifiers: null,
  },
  '1f9bb-1f3fd': {
    hexCodePoints: '1f9bb-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦻🏽',
    version: 'E12.0',
    baseName: 'ear with hearing aid',
    fullName: 'ear with hearing aid: medium skin tone',
    isBase: false,
    baseHex: '1f9bb',
    modifiers: null,
  },
  '1f9bb-1f3fe': {
    hexCodePoints: '1f9bb-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦻🏾',
    version: 'E12.0',
    baseName: 'ear with hearing aid',
    fullName: 'ear with hearing aid: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9bb',
    modifiers: null,
  },
  '1f9bb-1f3ff': {
    hexCodePoints: '1f9bb-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦻🏿',
    version: 'E12.0',
    baseName: 'ear with hearing aid',
    fullName: 'ear with hearing aid: dark skin tone',
    isBase: false,
    baseHex: '1f9bb',
    modifiers: null,
  },
  '1f443': {
    hexCodePoints: '1f443',
    qualifiedStatus: 'fully-qualified',
    emoji: '👃',
    version: 'E0.6',
    baseName: 'nose',
    fullName: 'nose',
    isBase: true,
    baseHex: '1f443',
    modifiers: {
      '1f3fb': '1f443-1f3fb',
      '1f3fc': '1f443-1f3fc',
      '1f3fd': '1f443-1f3fd',
      '1f3fe': '1f443-1f3fe',
      '1f3ff': '1f443-1f3ff',
    },
  },
  '1f443-1f3fb': {
    hexCodePoints: '1f443-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👃🏻',
    version: 'E1.0',
    baseName: 'nose',
    fullName: 'nose: light skin tone',
    isBase: false,
    baseHex: '1f443',
    modifiers: null,
  },
  '1f443-1f3fc': {
    hexCodePoints: '1f443-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👃🏼',
    version: 'E1.0',
    baseName: 'nose',
    fullName: 'nose: medium-light skin tone',
    isBase: false,
    baseHex: '1f443',
    modifiers: null,
  },
  '1f443-1f3fd': {
    hexCodePoints: '1f443-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👃🏽',
    version: 'E1.0',
    baseName: 'nose',
    fullName: 'nose: medium skin tone',
    isBase: false,
    baseHex: '1f443',
    modifiers: null,
  },
  '1f443-1f3fe': {
    hexCodePoints: '1f443-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👃🏾',
    version: 'E1.0',
    baseName: 'nose',
    fullName: 'nose: medium-dark skin tone',
    isBase: false,
    baseHex: '1f443',
    modifiers: null,
  },
  '1f443-1f3ff': {
    hexCodePoints: '1f443-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👃🏿',
    version: 'E1.0',
    baseName: 'nose',
    fullName: 'nose: dark skin tone',
    isBase: false,
    baseHex: '1f443',
    modifiers: null,
  },
  '1f9e0': {
    hexCodePoints: '1f9e0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧠',
    version: 'E5.0',
    baseName: 'brain',
    fullName: 'brain',
    isBase: true,
    baseHex: '1f9e0',
    modifiers: null,
  },
  '1fac0': {
    hexCodePoints: '1fac0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫀',
    version: 'E13.0',
    baseName: 'anatomical heart',
    fullName: 'anatomical heart',
    isBase: true,
    baseHex: '1fac0',
    modifiers: null,
  },
  '1fac1': {
    hexCodePoints: '1fac1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫁',
    version: 'E13.0',
    baseName: 'lungs',
    fullName: 'lungs',
    isBase: true,
    baseHex: '1fac1',
    modifiers: null,
  },
  '1f9b7': {
    hexCodePoints: '1f9b7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦷',
    version: 'E11.0',
    baseName: 'tooth',
    fullName: 'tooth',
    isBase: true,
    baseHex: '1f9b7',
    modifiers: null,
  },
  '1f9b4': {
    hexCodePoints: '1f9b4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦴',
    version: 'E11.0',
    baseName: 'bone',
    fullName: 'bone',
    isBase: true,
    baseHex: '1f9b4',
    modifiers: null,
  },
  '1f440': {
    hexCodePoints: '1f440',
    qualifiedStatus: 'fully-qualified',
    emoji: '👀',
    version: 'E0.6',
    baseName: 'eyes',
    fullName: 'eyes',
    isBase: true,
    baseHex: '1f440',
    modifiers: null,
  },
  '1f441-fe0f': {
    hexCodePoints: '1f441-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👁️',
    version: 'E0.7',
    baseName: 'eye',
    fullName: 'eye',
    isBase: true,
    baseHex: '1f441-fe0f',
    modifiers: null,
  },
  '1f441': {
    hexCodePoints: '1f441',
    qualifiedStatus: 'unqualified',
    emoji: '👁',
    version: 'E0.7',
    baseName: 'eye',
    fullName: 'eye',
    isBase: false,
    baseHex: '1f441-fe0f',
    modifiers: null,
  },
  '1f445': {
    hexCodePoints: '1f445',
    qualifiedStatus: 'fully-qualified',
    emoji: '👅',
    version: 'E0.6',
    baseName: 'tongue',
    fullName: 'tongue',
    isBase: true,
    baseHex: '1f445',
    modifiers: null,
  },
  '1f444': {
    hexCodePoints: '1f444',
    qualifiedStatus: 'fully-qualified',
    emoji: '👄',
    version: 'E0.6',
    baseName: 'mouth',
    fullName: 'mouth',
    isBase: true,
    baseHex: '1f444',
    modifiers: null,
  },
  '1fae6': {
    hexCodePoints: '1fae6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫦',
    version: 'E14.0',
    baseName: 'biting lip',
    fullName: 'biting lip',
    isBase: true,
    baseHex: '1fae6',
    modifiers: null,
  },
  '1f476': {
    hexCodePoints: '1f476',
    qualifiedStatus: 'fully-qualified',
    emoji: '👶',
    version: 'E0.6',
    baseName: 'baby',
    fullName: 'baby',
    isBase: true,
    baseHex: '1f476',
    modifiers: {
      '1f3fb': '1f476-1f3fb',
      '1f3fc': '1f476-1f3fc',
      '1f3fd': '1f476-1f3fd',
      '1f3fe': '1f476-1f3fe',
      '1f3ff': '1f476-1f3ff',
    },
  },
  '1f476-1f3fb': {
    hexCodePoints: '1f476-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👶🏻',
    version: 'E1.0',
    baseName: 'baby',
    fullName: 'baby: light skin tone',
    isBase: false,
    baseHex: '1f476',
    modifiers: null,
  },
  '1f476-1f3fc': {
    hexCodePoints: '1f476-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👶🏼',
    version: 'E1.0',
    baseName: 'baby',
    fullName: 'baby: medium-light skin tone',
    isBase: false,
    baseHex: '1f476',
    modifiers: null,
  },
  '1f476-1f3fd': {
    hexCodePoints: '1f476-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👶🏽',
    version: 'E1.0',
    baseName: 'baby',
    fullName: 'baby: medium skin tone',
    isBase: false,
    baseHex: '1f476',
    modifiers: null,
  },
  '1f476-1f3fe': {
    hexCodePoints: '1f476-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👶🏾',
    version: 'E1.0',
    baseName: 'baby',
    fullName: 'baby: medium-dark skin tone',
    isBase: false,
    baseHex: '1f476',
    modifiers: null,
  },
  '1f476-1f3ff': {
    hexCodePoints: '1f476-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👶🏿',
    version: 'E1.0',
    baseName: 'baby',
    fullName: 'baby: dark skin tone',
    isBase: false,
    baseHex: '1f476',
    modifiers: null,
  },
  '1f9d2': {
    hexCodePoints: '1f9d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧒',
    version: 'E5.0',
    baseName: 'child',
    fullName: 'child',
    isBase: true,
    baseHex: '1f9d2',
    modifiers: {
      '1f3fb': '1f9d2-1f3fb',
      '1f3fc': '1f9d2-1f3fc',
      '1f3fd': '1f9d2-1f3fd',
      '1f3fe': '1f9d2-1f3fe',
      '1f3ff': '1f9d2-1f3ff',
    },
  },
  '1f9d2-1f3fb': {
    hexCodePoints: '1f9d2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧒🏻',
    version: 'E5.0',
    baseName: 'child',
    fullName: 'child: light skin tone',
    isBase: false,
    baseHex: '1f9d2',
    modifiers: null,
  },
  '1f9d2-1f3fc': {
    hexCodePoints: '1f9d2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧒🏼',
    version: 'E5.0',
    baseName: 'child',
    fullName: 'child: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d2',
    modifiers: null,
  },
  '1f9d2-1f3fd': {
    hexCodePoints: '1f9d2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧒🏽',
    version: 'E5.0',
    baseName: 'child',
    fullName: 'child: medium skin tone',
    isBase: false,
    baseHex: '1f9d2',
    modifiers: null,
  },
  '1f9d2-1f3fe': {
    hexCodePoints: '1f9d2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧒🏾',
    version: 'E5.0',
    baseName: 'child',
    fullName: 'child: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d2',
    modifiers: null,
  },
  '1f9d2-1f3ff': {
    hexCodePoints: '1f9d2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧒🏿',
    version: 'E5.0',
    baseName: 'child',
    fullName: 'child: dark skin tone',
    isBase: false,
    baseHex: '1f9d2',
    modifiers: null,
  },
  '1f466': {
    hexCodePoints: '1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👦',
    version: 'E0.6',
    baseName: 'boy',
    fullName: 'boy',
    isBase: true,
    baseHex: '1f466',
    modifiers: {
      '1f3fb': '1f466-1f3fb',
      '1f3fc': '1f466-1f3fc',
      '1f3fd': '1f466-1f3fd',
      '1f3fe': '1f466-1f3fe',
      '1f3ff': '1f466-1f3ff',
    },
  },
  '1f466-1f3fb': {
    hexCodePoints: '1f466-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👦🏻',
    version: 'E1.0',
    baseName: 'boy',
    fullName: 'boy: light skin tone',
    isBase: false,
    baseHex: '1f466',
    modifiers: null,
  },
  '1f466-1f3fc': {
    hexCodePoints: '1f466-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👦🏼',
    version: 'E1.0',
    baseName: 'boy',
    fullName: 'boy: medium-light skin tone',
    isBase: false,
    baseHex: '1f466',
    modifiers: null,
  },
  '1f466-1f3fd': {
    hexCodePoints: '1f466-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👦🏽',
    version: 'E1.0',
    baseName: 'boy',
    fullName: 'boy: medium skin tone',
    isBase: false,
    baseHex: '1f466',
    modifiers: null,
  },
  '1f466-1f3fe': {
    hexCodePoints: '1f466-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👦🏾',
    version: 'E1.0',
    baseName: 'boy',
    fullName: 'boy: medium-dark skin tone',
    isBase: false,
    baseHex: '1f466',
    modifiers: null,
  },
  '1f466-1f3ff': {
    hexCodePoints: '1f466-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👦🏿',
    version: 'E1.0',
    baseName: 'boy',
    fullName: 'boy: dark skin tone',
    isBase: false,
    baseHex: '1f466',
    modifiers: null,
  },
  '1f467': {
    hexCodePoints: '1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👧',
    version: 'E0.6',
    baseName: 'girl',
    fullName: 'girl',
    isBase: true,
    baseHex: '1f467',
    modifiers: {
      '1f3fb': '1f467-1f3fb',
      '1f3fc': '1f467-1f3fc',
      '1f3fd': '1f467-1f3fd',
      '1f3fe': '1f467-1f3fe',
      '1f3ff': '1f467-1f3ff',
    },
  },
  '1f467-1f3fb': {
    hexCodePoints: '1f467-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👧🏻',
    version: 'E1.0',
    baseName: 'girl',
    fullName: 'girl: light skin tone',
    isBase: false,
    baseHex: '1f467',
    modifiers: null,
  },
  '1f467-1f3fc': {
    hexCodePoints: '1f467-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👧🏼',
    version: 'E1.0',
    baseName: 'girl',
    fullName: 'girl: medium-light skin tone',
    isBase: false,
    baseHex: '1f467',
    modifiers: null,
  },
  '1f467-1f3fd': {
    hexCodePoints: '1f467-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👧🏽',
    version: 'E1.0',
    baseName: 'girl',
    fullName: 'girl: medium skin tone',
    isBase: false,
    baseHex: '1f467',
    modifiers: null,
  },
  '1f467-1f3fe': {
    hexCodePoints: '1f467-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👧🏾',
    version: 'E1.0',
    baseName: 'girl',
    fullName: 'girl: medium-dark skin tone',
    isBase: false,
    baseHex: '1f467',
    modifiers: null,
  },
  '1f467-1f3ff': {
    hexCodePoints: '1f467-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👧🏿',
    version: 'E1.0',
    baseName: 'girl',
    fullName: 'girl: dark skin tone',
    isBase: false,
    baseHex: '1f467',
    modifiers: null,
  },
  '1f9d1': {
    hexCodePoints: '1f9d1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑',
    version: 'E5.0',
    baseName: 'person',
    fullName: 'person',
    isBase: true,
    baseHex: '1f9d1',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb',
      '1f3fc': '1f9d1-1f3fc',
      '1f3fd': '1f9d1-1f3fd',
      '1f3fe': '1f9d1-1f3fe',
      '1f3ff': '1f9d1-1f3ff',
    },
  },
  '1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻',
    version: 'E5.0',
    baseName: 'person',
    fullName: 'person: light skin tone',
    isBase: false,
    baseHex: '1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼',
    version: 'E5.0',
    baseName: 'person',
    fullName: 'person: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽',
    version: 'E5.0',
    baseName: 'person',
    fullName: 'person: medium skin tone',
    isBase: false,
    baseHex: '1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾',
    version: 'E5.0',
    baseName: 'person',
    fullName: 'person: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿',
    version: 'E5.0',
    baseName: 'person',
    fullName: 'person: dark skin tone',
    isBase: false,
    baseHex: '1f9d1',
    modifiers: null,
  },
  '1f471': {
    hexCodePoints: '1f471',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱',
    version: 'E0.6',
    baseName: 'person: blond hair',
    fullName: 'person: blond hair',
    isBase: true,
    baseHex: '1f471',
    modifiers: {
      '1f3fb': '1f471-1f3fb',
      '1f3fc': '1f471-1f3fc',
      '1f3fd': '1f471-1f3fd',
      '1f3fe': '1f471-1f3fe',
      '1f3ff': '1f471-1f3ff',
    },
  },
  '1f471-1f3fb': {
    hexCodePoints: '1f471-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏻',
    version: 'E1.0',
    baseName: 'person: blond hair',
    fullName: 'person: light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471',
    modifiers: null,
  },
  '1f471-1f3fc': {
    hexCodePoints: '1f471-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏼',
    version: 'E1.0',
    baseName: 'person: blond hair',
    fullName: 'person: medium-light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471',
    modifiers: null,
  },
  '1f471-1f3fd': {
    hexCodePoints: '1f471-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏽',
    version: 'E1.0',
    baseName: 'person: blond hair',
    fullName: 'person: medium skin tone, blond hair',
    isBase: false,
    baseHex: '1f471',
    modifiers: null,
  },
  '1f471-1f3fe': {
    hexCodePoints: '1f471-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏾',
    version: 'E1.0',
    baseName: 'person: blond hair',
    fullName: 'person: medium-dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471',
    modifiers: null,
  },
  '1f471-1f3ff': {
    hexCodePoints: '1f471-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏿',
    version: 'E1.0',
    baseName: 'person: blond hair',
    fullName: 'person: dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471',
    modifiers: null,
  },
  '1f468': {
    hexCodePoints: '1f468',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨',
    version: 'E0.6',
    baseName: 'man',
    fullName: 'man',
    isBase: true,
    baseHex: '1f468',
    modifiers: {
      '1f3fb': '1f468-1f3fb',
      '1f3fc': '1f468-1f3fc',
      '1f3fd': '1f468-1f3fd',
      '1f3fe': '1f468-1f3fe',
      '1f3ff': '1f468-1f3ff',
    },
  },
  '1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻',
    version: 'E1.0',
    baseName: 'man',
    fullName: 'man: light skin tone',
    isBase: false,
    baseHex: '1f468',
    modifiers: null,
  },
  '1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼',
    version: 'E1.0',
    baseName: 'man',
    fullName: 'man: medium-light skin tone',
    isBase: false,
    baseHex: '1f468',
    modifiers: null,
  },
  '1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽',
    version: 'E1.0',
    baseName: 'man',
    fullName: 'man: medium skin tone',
    isBase: false,
    baseHex: '1f468',
    modifiers: null,
  },
  '1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾',
    version: 'E1.0',
    baseName: 'man',
    fullName: 'man: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468',
    modifiers: null,
  },
  '1f468-1f3ff': {
    hexCodePoints: '1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿',
    version: 'E1.0',
    baseName: 'man',
    fullName: 'man: dark skin tone',
    isBase: false,
    baseHex: '1f468',
    modifiers: null,
  },
  '1f9d4': {
    hexCodePoints: '1f9d4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔',
    version: 'E5.0',
    baseName: 'person: beard',
    fullName: 'person: beard',
    isBase: true,
    baseHex: '1f9d4',
    modifiers: {
      '1f3fb': '1f9d4-1f3fb',
      '1f3fc': '1f9d4-1f3fc',
      '1f3fd': '1f9d4-1f3fd',
      '1f3fe': '1f9d4-1f3fe',
      '1f3ff': '1f9d4-1f3ff',
    },
  },
  '1f9d4-1f3fb': {
    hexCodePoints: '1f9d4-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏻',
    version: 'E5.0',
    baseName: 'person: beard',
    fullName: 'person: light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4',
    modifiers: null,
  },
  '1f9d4-1f3fc': {
    hexCodePoints: '1f9d4-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏼',
    version: 'E5.0',
    baseName: 'person: beard',
    fullName: 'person: medium-light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4',
    modifiers: null,
  },
  '1f9d4-1f3fd': {
    hexCodePoints: '1f9d4-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏽',
    version: 'E5.0',
    baseName: 'person: beard',
    fullName: 'person: medium skin tone, beard',
    isBase: false,
    baseHex: '1f9d4',
    modifiers: null,
  },
  '1f9d4-1f3fe': {
    hexCodePoints: '1f9d4-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏾',
    version: 'E5.0',
    baseName: 'person: beard',
    fullName: 'person: medium-dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4',
    modifiers: null,
  },
  '1f9d4-1f3ff': {
    hexCodePoints: '1f9d4-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏿',
    version: 'E5.0',
    baseName: 'person: beard',
    fullName: 'person: dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4',
    modifiers: null,
  },
  '1f9d4-200d-2642-fe0f': {
    hexCodePoints: '1f9d4-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔‍♂️',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: beard',
    isBase: true,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9d4-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9d4-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9d4-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9d4-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9d4-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9d4-200d-2642': {
    hexCodePoints: '1f9d4-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔‍♂',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9d4-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏻‍♂️',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fb-200d-2642': {
    hexCodePoints: '1f9d4-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏻‍♂',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9d4-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏼‍♂️',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: medium-light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fc-200d-2642': {
    hexCodePoints: '1f9d4-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏼‍♂',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: medium-light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9d4-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏽‍♂️',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: medium skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fd-200d-2642': {
    hexCodePoints: '1f9d4-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏽‍♂',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: medium skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9d4-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏾‍♂️',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: medium-dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fe-200d-2642': {
    hexCodePoints: '1f9d4-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏾‍♂',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: medium-dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9d4-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏿‍♂️',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3ff-200d-2642': {
    hexCodePoints: '1f9d4-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏿‍♂',
    version: 'E13.1',
    baseName: 'man: beard',
    fullName: 'man: dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d4-200d-2640-fe0f': {
    hexCodePoints: '1f9d4-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔‍♀️',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: beard',
    isBase: true,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9d4-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9d4-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9d4-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9d4-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9d4-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9d4-200d-2640': {
    hexCodePoints: '1f9d4-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔‍♀',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9d4-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏻‍♀️',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fb-200d-2640': {
    hexCodePoints: '1f9d4-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏻‍♀',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9d4-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏼‍♀️',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: medium-light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fc-200d-2640': {
    hexCodePoints: '1f9d4-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏼‍♀',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: medium-light skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9d4-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏽‍♀️',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: medium skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fd-200d-2640': {
    hexCodePoints: '1f9d4-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏽‍♀',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: medium skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9d4-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏾‍♀️',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: medium-dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3fe-200d-2640': {
    hexCodePoints: '1f9d4-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏾‍♀',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: medium-dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9d4-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧔🏿‍♀️',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d4-1f3ff-200d-2640': {
    hexCodePoints: '1f9d4-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧔🏿‍♀',
    version: 'E13.1',
    baseName: 'woman: beard',
    fullName: 'woman: dark skin tone, beard',
    isBase: false,
    baseHex: '1f9d4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f468-200d-1f9b0': {
    hexCodePoints: '1f468-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦰',
    version: 'E11.0',
    baseName: 'man: red hair',
    fullName: 'man: red hair',
    isBase: true,
    baseHex: '1f468-200d-1f9b0',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9b0',
      '1f3fc': '1f468-1f3fc-200d-1f9b0',
      '1f3fd': '1f468-1f3fd-200d-1f9b0',
      '1f3fe': '1f468-1f3fe-200d-1f9b0',
      '1f3ff': '1f468-1f3ff-200d-1f9b0',
    },
  },
  '1f468-1f3fb-200d-1f9b0': {
    hexCodePoints: '1f468-1f3fb-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦰',
    version: 'E11.0',
    baseName: 'man: red hair',
    fullName: 'man: light skin tone, red hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b0',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9b0': {
    hexCodePoints: '1f468-1f3fc-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦰',
    version: 'E11.0',
    baseName: 'man: red hair',
    fullName: 'man: medium-light skin tone, red hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b0',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9b0': {
    hexCodePoints: '1f468-1f3fd-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦰',
    version: 'E11.0',
    baseName: 'man: red hair',
    fullName: 'man: medium skin tone, red hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b0',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9b0': {
    hexCodePoints: '1f468-1f3fe-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦰',
    version: 'E11.0',
    baseName: 'man: red hair',
    fullName: 'man: medium-dark skin tone, red hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b0',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9b0': {
    hexCodePoints: '1f468-1f3ff-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦰',
    version: 'E11.0',
    baseName: 'man: red hair',
    fullName: 'man: dark skin tone, red hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b0',
    modifiers: null,
  },
  '1f468-200d-1f9b1': {
    hexCodePoints: '1f468-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦱',
    version: 'E11.0',
    baseName: 'man: curly hair',
    fullName: 'man: curly hair',
    isBase: true,
    baseHex: '1f468-200d-1f9b1',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9b1',
      '1f3fc': '1f468-1f3fc-200d-1f9b1',
      '1f3fd': '1f468-1f3fd-200d-1f9b1',
      '1f3fe': '1f468-1f3fe-200d-1f9b1',
      '1f3ff': '1f468-1f3ff-200d-1f9b1',
    },
  },
  '1f468-1f3fb-200d-1f9b1': {
    hexCodePoints: '1f468-1f3fb-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦱',
    version: 'E11.0',
    baseName: 'man: curly hair',
    fullName: 'man: light skin tone, curly hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b1',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9b1': {
    hexCodePoints: '1f468-1f3fc-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦱',
    version: 'E11.0',
    baseName: 'man: curly hair',
    fullName: 'man: medium-light skin tone, curly hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b1',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9b1': {
    hexCodePoints: '1f468-1f3fd-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦱',
    version: 'E11.0',
    baseName: 'man: curly hair',
    fullName: 'man: medium skin tone, curly hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b1',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9b1': {
    hexCodePoints: '1f468-1f3fe-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦱',
    version: 'E11.0',
    baseName: 'man: curly hair',
    fullName: 'man: medium-dark skin tone, curly hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b1',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9b1': {
    hexCodePoints: '1f468-1f3ff-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦱',
    version: 'E11.0',
    baseName: 'man: curly hair',
    fullName: 'man: dark skin tone, curly hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b1',
    modifiers: null,
  },
  '1f468-200d-1f9b3': {
    hexCodePoints: '1f468-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦳',
    version: 'E11.0',
    baseName: 'man: white hair',
    fullName: 'man: white hair',
    isBase: true,
    baseHex: '1f468-200d-1f9b3',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9b3',
      '1f3fc': '1f468-1f3fc-200d-1f9b3',
      '1f3fd': '1f468-1f3fd-200d-1f9b3',
      '1f3fe': '1f468-1f3fe-200d-1f9b3',
      '1f3ff': '1f468-1f3ff-200d-1f9b3',
    },
  },
  '1f468-1f3fb-200d-1f9b3': {
    hexCodePoints: '1f468-1f3fb-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦳',
    version: 'E11.0',
    baseName: 'man: white hair',
    fullName: 'man: light skin tone, white hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b3',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9b3': {
    hexCodePoints: '1f468-1f3fc-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦳',
    version: 'E11.0',
    baseName: 'man: white hair',
    fullName: 'man: medium-light skin tone, white hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b3',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9b3': {
    hexCodePoints: '1f468-1f3fd-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦳',
    version: 'E11.0',
    baseName: 'man: white hair',
    fullName: 'man: medium skin tone, white hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b3',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9b3': {
    hexCodePoints: '1f468-1f3fe-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦳',
    version: 'E11.0',
    baseName: 'man: white hair',
    fullName: 'man: medium-dark skin tone, white hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b3',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9b3': {
    hexCodePoints: '1f468-1f3ff-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦳',
    version: 'E11.0',
    baseName: 'man: white hair',
    fullName: 'man: dark skin tone, white hair',
    isBase: false,
    baseHex: '1f468-200d-1f9b3',
    modifiers: null,
  },
  '1f468-200d-1f9b2': {
    hexCodePoints: '1f468-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦲',
    version: 'E11.0',
    baseName: 'man: bald',
    fullName: 'man: bald',
    isBase: true,
    baseHex: '1f468-200d-1f9b2',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9b2',
      '1f3fc': '1f468-1f3fc-200d-1f9b2',
      '1f3fd': '1f468-1f3fd-200d-1f9b2',
      '1f3fe': '1f468-1f3fe-200d-1f9b2',
      '1f3ff': '1f468-1f3ff-200d-1f9b2',
    },
  },
  '1f468-1f3fb-200d-1f9b2': {
    hexCodePoints: '1f468-1f3fb-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦲',
    version: 'E11.0',
    baseName: 'man: bald',
    fullName: 'man: light skin tone, bald',
    isBase: false,
    baseHex: '1f468-200d-1f9b2',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9b2': {
    hexCodePoints: '1f468-1f3fc-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦲',
    version: 'E11.0',
    baseName: 'man: bald',
    fullName: 'man: medium-light skin tone, bald',
    isBase: false,
    baseHex: '1f468-200d-1f9b2',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9b2': {
    hexCodePoints: '1f468-1f3fd-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦲',
    version: 'E11.0',
    baseName: 'man: bald',
    fullName: 'man: medium skin tone, bald',
    isBase: false,
    baseHex: '1f468-200d-1f9b2',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9b2': {
    hexCodePoints: '1f468-1f3fe-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦲',
    version: 'E11.0',
    baseName: 'man: bald',
    fullName: 'man: medium-dark skin tone, bald',
    isBase: false,
    baseHex: '1f468-200d-1f9b2',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9b2': {
    hexCodePoints: '1f468-1f3ff-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦲',
    version: 'E11.0',
    baseName: 'man: bald',
    fullName: 'man: dark skin tone, bald',
    isBase: false,
    baseHex: '1f468-200d-1f9b2',
    modifiers: null,
  },
  '1f469': {
    hexCodePoints: '1f469',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩',
    version: 'E0.6',
    baseName: 'woman',
    fullName: 'woman',
    isBase: true,
    baseHex: '1f469',
    modifiers: {
      '1f3fb': '1f469-1f3fb',
      '1f3fc': '1f469-1f3fc',
      '1f3fd': '1f469-1f3fd',
      '1f3fe': '1f469-1f3fe',
      '1f3ff': '1f469-1f3ff',
    },
  },
  '1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻',
    version: 'E1.0',
    baseName: 'woman',
    fullName: 'woman: light skin tone',
    isBase: false,
    baseHex: '1f469',
    modifiers: null,
  },
  '1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼',
    version: 'E1.0',
    baseName: 'woman',
    fullName: 'woman: medium-light skin tone',
    isBase: false,
    baseHex: '1f469',
    modifiers: null,
  },
  '1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽',
    version: 'E1.0',
    baseName: 'woman',
    fullName: 'woman: medium skin tone',
    isBase: false,
    baseHex: '1f469',
    modifiers: null,
  },
  '1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾',
    version: 'E1.0',
    baseName: 'woman',
    fullName: 'woman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469',
    modifiers: null,
  },
  '1f469-1f3ff': {
    hexCodePoints: '1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿',
    version: 'E1.0',
    baseName: 'woman',
    fullName: 'woman: dark skin tone',
    isBase: false,
    baseHex: '1f469',
    modifiers: null,
  },
  '1f469-200d-1f9b0': {
    hexCodePoints: '1f469-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦰',
    version: 'E11.0',
    baseName: 'woman: red hair',
    fullName: 'woman: red hair',
    isBase: true,
    baseHex: '1f469-200d-1f9b0',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9b0',
      '1f3fc': '1f469-1f3fc-200d-1f9b0',
      '1f3fd': '1f469-1f3fd-200d-1f9b0',
      '1f3fe': '1f469-1f3fe-200d-1f9b0',
      '1f3ff': '1f469-1f3ff-200d-1f9b0',
    },
  },
  '1f469-1f3fb-200d-1f9b0': {
    hexCodePoints: '1f469-1f3fb-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦰',
    version: 'E11.0',
    baseName: 'woman: red hair',
    fullName: 'woman: light skin tone, red hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b0',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9b0': {
    hexCodePoints: '1f469-1f3fc-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦰',
    version: 'E11.0',
    baseName: 'woman: red hair',
    fullName: 'woman: medium-light skin tone, red hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b0',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9b0': {
    hexCodePoints: '1f469-1f3fd-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦰',
    version: 'E11.0',
    baseName: 'woman: red hair',
    fullName: 'woman: medium skin tone, red hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b0',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9b0': {
    hexCodePoints: '1f469-1f3fe-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦰',
    version: 'E11.0',
    baseName: 'woman: red hair',
    fullName: 'woman: medium-dark skin tone, red hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b0',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9b0': {
    hexCodePoints: '1f469-1f3ff-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦰',
    version: 'E11.0',
    baseName: 'woman: red hair',
    fullName: 'woman: dark skin tone, red hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b0',
    modifiers: null,
  },
  '1f9d1-200d-1f9b0': {
    hexCodePoints: '1f9d1-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦰',
    version: 'E12.1',
    baseName: 'person: red hair',
    fullName: 'person: red hair',
    isBase: true,
    baseHex: '1f9d1-200d-1f9b0',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9b0',
      '1f3fc': '1f9d1-1f3fc-200d-1f9b0',
      '1f3fd': '1f9d1-1f3fd-200d-1f9b0',
      '1f3fe': '1f9d1-1f3fe-200d-1f9b0',
      '1f3ff': '1f9d1-1f3ff-200d-1f9b0',
    },
  },
  '1f9d1-1f3fb-200d-1f9b0': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦰',
    version: 'E12.1',
    baseName: 'person: red hair',
    fullName: 'person: light skin tone, red hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b0',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9b0': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦰',
    version: 'E12.1',
    baseName: 'person: red hair',
    fullName: 'person: medium-light skin tone, red hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b0',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9b0': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦰',
    version: 'E12.1',
    baseName: 'person: red hair',
    fullName: 'person: medium skin tone, red hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b0',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9b0': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦰',
    version: 'E12.1',
    baseName: 'person: red hair',
    fullName: 'person: medium-dark skin tone, red hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b0',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9b0': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦰',
    version: 'E12.1',
    baseName: 'person: red hair',
    fullName: 'person: dark skin tone, red hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b0',
    modifiers: null,
  },
  '1f469-200d-1f9b1': {
    hexCodePoints: '1f469-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦱',
    version: 'E11.0',
    baseName: 'woman: curly hair',
    fullName: 'woman: curly hair',
    isBase: true,
    baseHex: '1f469-200d-1f9b1',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9b1',
      '1f3fc': '1f469-1f3fc-200d-1f9b1',
      '1f3fd': '1f469-1f3fd-200d-1f9b1',
      '1f3fe': '1f469-1f3fe-200d-1f9b1',
      '1f3ff': '1f469-1f3ff-200d-1f9b1',
    },
  },
  '1f469-1f3fb-200d-1f9b1': {
    hexCodePoints: '1f469-1f3fb-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦱',
    version: 'E11.0',
    baseName: 'woman: curly hair',
    fullName: 'woman: light skin tone, curly hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b1',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9b1': {
    hexCodePoints: '1f469-1f3fc-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦱',
    version: 'E11.0',
    baseName: 'woman: curly hair',
    fullName: 'woman: medium-light skin tone, curly hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b1',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9b1': {
    hexCodePoints: '1f469-1f3fd-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦱',
    version: 'E11.0',
    baseName: 'woman: curly hair',
    fullName: 'woman: medium skin tone, curly hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b1',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9b1': {
    hexCodePoints: '1f469-1f3fe-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦱',
    version: 'E11.0',
    baseName: 'woman: curly hair',
    fullName: 'woman: medium-dark skin tone, curly hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b1',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9b1': {
    hexCodePoints: '1f469-1f3ff-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦱',
    version: 'E11.0',
    baseName: 'woman: curly hair',
    fullName: 'woman: dark skin tone, curly hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b1',
    modifiers: null,
  },
  '1f9d1-200d-1f9b1': {
    hexCodePoints: '1f9d1-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦱',
    version: 'E12.1',
    baseName: 'person: curly hair',
    fullName: 'person: curly hair',
    isBase: true,
    baseHex: '1f9d1-200d-1f9b1',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9b1',
      '1f3fc': '1f9d1-1f3fc-200d-1f9b1',
      '1f3fd': '1f9d1-1f3fd-200d-1f9b1',
      '1f3fe': '1f9d1-1f3fe-200d-1f9b1',
      '1f3ff': '1f9d1-1f3ff-200d-1f9b1',
    },
  },
  '1f9d1-1f3fb-200d-1f9b1': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦱',
    version: 'E12.1',
    baseName: 'person: curly hair',
    fullName: 'person: light skin tone, curly hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b1',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9b1': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦱',
    version: 'E12.1',
    baseName: 'person: curly hair',
    fullName: 'person: medium-light skin tone, curly hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b1',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9b1': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦱',
    version: 'E12.1',
    baseName: 'person: curly hair',
    fullName: 'person: medium skin tone, curly hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b1',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9b1': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦱',
    version: 'E12.1',
    baseName: 'person: curly hair',
    fullName: 'person: medium-dark skin tone, curly hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b1',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9b1': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦱',
    version: 'E12.1',
    baseName: 'person: curly hair',
    fullName: 'person: dark skin tone, curly hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b1',
    modifiers: null,
  },
  '1f469-200d-1f9b3': {
    hexCodePoints: '1f469-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦳',
    version: 'E11.0',
    baseName: 'woman: white hair',
    fullName: 'woman: white hair',
    isBase: true,
    baseHex: '1f469-200d-1f9b3',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9b3',
      '1f3fc': '1f469-1f3fc-200d-1f9b3',
      '1f3fd': '1f469-1f3fd-200d-1f9b3',
      '1f3fe': '1f469-1f3fe-200d-1f9b3',
      '1f3ff': '1f469-1f3ff-200d-1f9b3',
    },
  },
  '1f469-1f3fb-200d-1f9b3': {
    hexCodePoints: '1f469-1f3fb-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦳',
    version: 'E11.0',
    baseName: 'woman: white hair',
    fullName: 'woman: light skin tone, white hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b3',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9b3': {
    hexCodePoints: '1f469-1f3fc-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦳',
    version: 'E11.0',
    baseName: 'woman: white hair',
    fullName: 'woman: medium-light skin tone, white hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b3',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9b3': {
    hexCodePoints: '1f469-1f3fd-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦳',
    version: 'E11.0',
    baseName: 'woman: white hair',
    fullName: 'woman: medium skin tone, white hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b3',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9b3': {
    hexCodePoints: '1f469-1f3fe-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦳',
    version: 'E11.0',
    baseName: 'woman: white hair',
    fullName: 'woman: medium-dark skin tone, white hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b3',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9b3': {
    hexCodePoints: '1f469-1f3ff-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦳',
    version: 'E11.0',
    baseName: 'woman: white hair',
    fullName: 'woman: dark skin tone, white hair',
    isBase: false,
    baseHex: '1f469-200d-1f9b3',
    modifiers: null,
  },
  '1f9d1-200d-1f9b3': {
    hexCodePoints: '1f9d1-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦳',
    version: 'E12.1',
    baseName: 'person: white hair',
    fullName: 'person: white hair',
    isBase: true,
    baseHex: '1f9d1-200d-1f9b3',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9b3',
      '1f3fc': '1f9d1-1f3fc-200d-1f9b3',
      '1f3fd': '1f9d1-1f3fd-200d-1f9b3',
      '1f3fe': '1f9d1-1f3fe-200d-1f9b3',
      '1f3ff': '1f9d1-1f3ff-200d-1f9b3',
    },
  },
  '1f9d1-1f3fb-200d-1f9b3': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦳',
    version: 'E12.1',
    baseName: 'person: white hair',
    fullName: 'person: light skin tone, white hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b3',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9b3': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦳',
    version: 'E12.1',
    baseName: 'person: white hair',
    fullName: 'person: medium-light skin tone, white hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b3',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9b3': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦳',
    version: 'E12.1',
    baseName: 'person: white hair',
    fullName: 'person: medium skin tone, white hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b3',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9b3': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦳',
    version: 'E12.1',
    baseName: 'person: white hair',
    fullName: 'person: medium-dark skin tone, white hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b3',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9b3': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦳',
    version: 'E12.1',
    baseName: 'person: white hair',
    fullName: 'person: dark skin tone, white hair',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b3',
    modifiers: null,
  },
  '1f469-200d-1f9b2': {
    hexCodePoints: '1f469-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦲',
    version: 'E11.0',
    baseName: 'woman: bald',
    fullName: 'woman: bald',
    isBase: true,
    baseHex: '1f469-200d-1f9b2',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9b2',
      '1f3fc': '1f469-1f3fc-200d-1f9b2',
      '1f3fd': '1f469-1f3fd-200d-1f9b2',
      '1f3fe': '1f469-1f3fe-200d-1f9b2',
      '1f3ff': '1f469-1f3ff-200d-1f9b2',
    },
  },
  '1f469-1f3fb-200d-1f9b2': {
    hexCodePoints: '1f469-1f3fb-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦲',
    version: 'E11.0',
    baseName: 'woman: bald',
    fullName: 'woman: light skin tone, bald',
    isBase: false,
    baseHex: '1f469-200d-1f9b2',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9b2': {
    hexCodePoints: '1f469-1f3fc-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦲',
    version: 'E11.0',
    baseName: 'woman: bald',
    fullName: 'woman: medium-light skin tone, bald',
    isBase: false,
    baseHex: '1f469-200d-1f9b2',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9b2': {
    hexCodePoints: '1f469-1f3fd-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦲',
    version: 'E11.0',
    baseName: 'woman: bald',
    fullName: 'woman: medium skin tone, bald',
    isBase: false,
    baseHex: '1f469-200d-1f9b2',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9b2': {
    hexCodePoints: '1f469-1f3fe-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦲',
    version: 'E11.0',
    baseName: 'woman: bald',
    fullName: 'woman: medium-dark skin tone, bald',
    isBase: false,
    baseHex: '1f469-200d-1f9b2',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9b2': {
    hexCodePoints: '1f469-1f3ff-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦲',
    version: 'E11.0',
    baseName: 'woman: bald',
    fullName: 'woman: dark skin tone, bald',
    isBase: false,
    baseHex: '1f469-200d-1f9b2',
    modifiers: null,
  },
  '1f9d1-200d-1f9b2': {
    hexCodePoints: '1f9d1-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦲',
    version: 'E12.1',
    baseName: 'person: bald',
    fullName: 'person: bald',
    isBase: true,
    baseHex: '1f9d1-200d-1f9b2',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9b2',
      '1f3fc': '1f9d1-1f3fc-200d-1f9b2',
      '1f3fd': '1f9d1-1f3fd-200d-1f9b2',
      '1f3fe': '1f9d1-1f3fe-200d-1f9b2',
      '1f3ff': '1f9d1-1f3ff-200d-1f9b2',
    },
  },
  '1f9d1-1f3fb-200d-1f9b2': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦲',
    version: 'E12.1',
    baseName: 'person: bald',
    fullName: 'person: light skin tone, bald',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b2',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9b2': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦲',
    version: 'E12.1',
    baseName: 'person: bald',
    fullName: 'person: medium-light skin tone, bald',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b2',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9b2': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦲',
    version: 'E12.1',
    baseName: 'person: bald',
    fullName: 'person: medium skin tone, bald',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b2',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9b2': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦲',
    version: 'E12.1',
    baseName: 'person: bald',
    fullName: 'person: medium-dark skin tone, bald',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b2',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9b2': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦲',
    version: 'E12.1',
    baseName: 'person: bald',
    fullName: 'person: dark skin tone, bald',
    isBase: false,
    baseHex: '1f9d1-200d-1f9b2',
    modifiers: null,
  },
  '1f471-200d-2640-fe0f': {
    hexCodePoints: '1f471-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱‍♀️',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: blond hair',
    isBase: true,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f471-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f471-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f471-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f471-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f471-1f3ff-200d-2640-fe0f',
    },
  },
  '1f471-200d-2640': {
    hexCodePoints: '1f471-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱‍♀',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f471-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fb-200d-2640': {
    hexCodePoints: '1f471-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏻‍♀',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f471-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: medium-light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fc-200d-2640': {
    hexCodePoints: '1f471-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏼‍♀',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: medium-light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f471-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: medium skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fd-200d-2640': {
    hexCodePoints: '1f471-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏽‍♀',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: medium skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f471-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: medium-dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3fe-200d-2640': {
    hexCodePoints: '1f471-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏾‍♀',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: medium-dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f471-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-1f3ff-200d-2640': {
    hexCodePoints: '1f471-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏿‍♀',
    version: 'E4.0',
    baseName: 'woman: blond hair',
    fullName: 'woman: dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2640-fe0f',
    modifiers: null,
  },
  '1f471-200d-2642-fe0f': {
    hexCodePoints: '1f471-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱‍♂️',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: blond hair',
    isBase: true,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f471-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f471-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f471-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f471-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f471-1f3ff-200d-2642-fe0f',
    },
  },
  '1f471-200d-2642': {
    hexCodePoints: '1f471-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱‍♂',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f471-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏻‍♂️',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fb-200d-2642': {
    hexCodePoints: '1f471-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏻‍♂',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f471-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏼‍♂️',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: medium-light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fc-200d-2642': {
    hexCodePoints: '1f471-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏼‍♂',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: medium-light skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f471-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏽‍♂️',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: medium skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fd-200d-2642': {
    hexCodePoints: '1f471-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏽‍♂',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: medium skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f471-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏾‍♂️',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: medium-dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3fe-200d-2642': {
    hexCodePoints: '1f471-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏾‍♂',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: medium-dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f471-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👱🏿‍♂️',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f471-1f3ff-200d-2642': {
    hexCodePoints: '1f471-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👱🏿‍♂',
    version: 'E4.0',
    baseName: 'man: blond hair',
    fullName: 'man: dark skin tone, blond hair',
    isBase: false,
    baseHex: '1f471-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d3': {
    hexCodePoints: '1f9d3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧓',
    version: 'E5.0',
    baseName: 'older person',
    fullName: 'older person',
    isBase: true,
    baseHex: '1f9d3',
    modifiers: {
      '1f3fb': '1f9d3-1f3fb',
      '1f3fc': '1f9d3-1f3fc',
      '1f3fd': '1f9d3-1f3fd',
      '1f3fe': '1f9d3-1f3fe',
      '1f3ff': '1f9d3-1f3ff',
    },
  },
  '1f9d3-1f3fb': {
    hexCodePoints: '1f9d3-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧓🏻',
    version: 'E5.0',
    baseName: 'older person',
    fullName: 'older person: light skin tone',
    isBase: false,
    baseHex: '1f9d3',
    modifiers: null,
  },
  '1f9d3-1f3fc': {
    hexCodePoints: '1f9d3-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧓🏼',
    version: 'E5.0',
    baseName: 'older person',
    fullName: 'older person: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d3',
    modifiers: null,
  },
  '1f9d3-1f3fd': {
    hexCodePoints: '1f9d3-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧓🏽',
    version: 'E5.0',
    baseName: 'older person',
    fullName: 'older person: medium skin tone',
    isBase: false,
    baseHex: '1f9d3',
    modifiers: null,
  },
  '1f9d3-1f3fe': {
    hexCodePoints: '1f9d3-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧓🏾',
    version: 'E5.0',
    baseName: 'older person',
    fullName: 'older person: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d3',
    modifiers: null,
  },
  '1f9d3-1f3ff': {
    hexCodePoints: '1f9d3-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧓🏿',
    version: 'E5.0',
    baseName: 'older person',
    fullName: 'older person: dark skin tone',
    isBase: false,
    baseHex: '1f9d3',
    modifiers: null,
  },
  '1f474': {
    hexCodePoints: '1f474',
    qualifiedStatus: 'fully-qualified',
    emoji: '👴',
    version: 'E0.6',
    baseName: 'old man',
    fullName: 'old man',
    isBase: true,
    baseHex: '1f474',
    modifiers: {
      '1f3fb': '1f474-1f3fb',
      '1f3fc': '1f474-1f3fc',
      '1f3fd': '1f474-1f3fd',
      '1f3fe': '1f474-1f3fe',
      '1f3ff': '1f474-1f3ff',
    },
  },
  '1f474-1f3fb': {
    hexCodePoints: '1f474-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👴🏻',
    version: 'E1.0',
    baseName: 'old man',
    fullName: 'old man: light skin tone',
    isBase: false,
    baseHex: '1f474',
    modifiers: null,
  },
  '1f474-1f3fc': {
    hexCodePoints: '1f474-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👴🏼',
    version: 'E1.0',
    baseName: 'old man',
    fullName: 'old man: medium-light skin tone',
    isBase: false,
    baseHex: '1f474',
    modifiers: null,
  },
  '1f474-1f3fd': {
    hexCodePoints: '1f474-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👴🏽',
    version: 'E1.0',
    baseName: 'old man',
    fullName: 'old man: medium skin tone',
    isBase: false,
    baseHex: '1f474',
    modifiers: null,
  },
  '1f474-1f3fe': {
    hexCodePoints: '1f474-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👴🏾',
    version: 'E1.0',
    baseName: 'old man',
    fullName: 'old man: medium-dark skin tone',
    isBase: false,
    baseHex: '1f474',
    modifiers: null,
  },
  '1f474-1f3ff': {
    hexCodePoints: '1f474-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👴🏿',
    version: 'E1.0',
    baseName: 'old man',
    fullName: 'old man: dark skin tone',
    isBase: false,
    baseHex: '1f474',
    modifiers: null,
  },
  '1f475': {
    hexCodePoints: '1f475',
    qualifiedStatus: 'fully-qualified',
    emoji: '👵',
    version: 'E0.6',
    baseName: 'old woman',
    fullName: 'old woman',
    isBase: true,
    baseHex: '1f475',
    modifiers: {
      '1f3fb': '1f475-1f3fb',
      '1f3fc': '1f475-1f3fc',
      '1f3fd': '1f475-1f3fd',
      '1f3fe': '1f475-1f3fe',
      '1f3ff': '1f475-1f3ff',
    },
  },
  '1f475-1f3fb': {
    hexCodePoints: '1f475-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👵🏻',
    version: 'E1.0',
    baseName: 'old woman',
    fullName: 'old woman: light skin tone',
    isBase: false,
    baseHex: '1f475',
    modifiers: null,
  },
  '1f475-1f3fc': {
    hexCodePoints: '1f475-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👵🏼',
    version: 'E1.0',
    baseName: 'old woman',
    fullName: 'old woman: medium-light skin tone',
    isBase: false,
    baseHex: '1f475',
    modifiers: null,
  },
  '1f475-1f3fd': {
    hexCodePoints: '1f475-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👵🏽',
    version: 'E1.0',
    baseName: 'old woman',
    fullName: 'old woman: medium skin tone',
    isBase: false,
    baseHex: '1f475',
    modifiers: null,
  },
  '1f475-1f3fe': {
    hexCodePoints: '1f475-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👵🏾',
    version: 'E1.0',
    baseName: 'old woman',
    fullName: 'old woman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f475',
    modifiers: null,
  },
  '1f475-1f3ff': {
    hexCodePoints: '1f475-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👵🏿',
    version: 'E1.0',
    baseName: 'old woman',
    fullName: 'old woman: dark skin tone',
    isBase: false,
    baseHex: '1f475',
    modifiers: null,
  },
  '1f64d': {
    hexCodePoints: '1f64d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍',
    version: 'E0.6',
    baseName: 'person frowning',
    fullName: 'person frowning',
    isBase: true,
    baseHex: '1f64d',
    modifiers: {
      '1f3fb': '1f64d-1f3fb',
      '1f3fc': '1f64d-1f3fc',
      '1f3fd': '1f64d-1f3fd',
      '1f3fe': '1f64d-1f3fe',
      '1f3ff': '1f64d-1f3ff',
    },
  },
  '1f64d-1f3fb': {
    hexCodePoints: '1f64d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏻',
    version: 'E1.0',
    baseName: 'person frowning',
    fullName: 'person frowning: light skin tone',
    isBase: false,
    baseHex: '1f64d',
    modifiers: null,
  },
  '1f64d-1f3fc': {
    hexCodePoints: '1f64d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏼',
    version: 'E1.0',
    baseName: 'person frowning',
    fullName: 'person frowning: medium-light skin tone',
    isBase: false,
    baseHex: '1f64d',
    modifiers: null,
  },
  '1f64d-1f3fd': {
    hexCodePoints: '1f64d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏽',
    version: 'E1.0',
    baseName: 'person frowning',
    fullName: 'person frowning: medium skin tone',
    isBase: false,
    baseHex: '1f64d',
    modifiers: null,
  },
  '1f64d-1f3fe': {
    hexCodePoints: '1f64d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏾',
    version: 'E1.0',
    baseName: 'person frowning',
    fullName: 'person frowning: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64d',
    modifiers: null,
  },
  '1f64d-1f3ff': {
    hexCodePoints: '1f64d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏿',
    version: 'E1.0',
    baseName: 'person frowning',
    fullName: 'person frowning: dark skin tone',
    isBase: false,
    baseHex: '1f64d',
    modifiers: null,
  },
  '1f64d-200d-2642-fe0f': {
    hexCodePoints: '1f64d-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍‍♂️',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning',
    isBase: true,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f64d-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f64d-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f64d-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f64d-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f64d-1f3ff-200d-2642-fe0f',
    },
  },
  '1f64d-200d-2642': {
    hexCodePoints: '1f64d-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍‍♂',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f64d-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏻‍♂️',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fb-200d-2642': {
    hexCodePoints: '1f64d-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏻‍♂',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f64d-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏼‍♂️',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: medium-light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fc-200d-2642': {
    hexCodePoints: '1f64d-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏼‍♂',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: medium-light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f64d-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏽‍♂️',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: medium skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fd-200d-2642': {
    hexCodePoints: '1f64d-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏽‍♂',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: medium skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f64d-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏾‍♂️',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fe-200d-2642': {
    hexCodePoints: '1f64d-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏾‍♂',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f64d-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏿‍♂️',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-1f3ff-200d-2642': {
    hexCodePoints: '1f64d-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏿‍♂',
    version: 'E4.0',
    baseName: 'man frowning',
    fullName: 'man frowning: dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64d-200d-2640-fe0f': {
    hexCodePoints: '1f64d-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍‍♀️',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning',
    isBase: true,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f64d-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f64d-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f64d-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f64d-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f64d-1f3ff-200d-2640-fe0f',
    },
  },
  '1f64d-200d-2640': {
    hexCodePoints: '1f64d-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍‍♀',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f64d-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fb-200d-2640': {
    hexCodePoints: '1f64d-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏻‍♀',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f64d-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: medium-light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fc-200d-2640': {
    hexCodePoints: '1f64d-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏼‍♀',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: medium-light skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f64d-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: medium skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fd-200d-2640': {
    hexCodePoints: '1f64d-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏽‍♀',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: medium skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f64d-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3fe-200d-2640': {
    hexCodePoints: '1f64d-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏾‍♀',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f64d-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙍🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64d-1f3ff-200d-2640': {
    hexCodePoints: '1f64d-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙍🏿‍♀',
    version: 'E4.0',
    baseName: 'woman frowning',
    fullName: 'woman frowning: dark skin tone',
    isBase: false,
    baseHex: '1f64d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e': {
    hexCodePoints: '1f64e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎',
    version: 'E0.6',
    baseName: 'person pouting',
    fullName: 'person pouting',
    isBase: true,
    baseHex: '1f64e',
    modifiers: {
      '1f3fb': '1f64e-1f3fb',
      '1f3fc': '1f64e-1f3fc',
      '1f3fd': '1f64e-1f3fd',
      '1f3fe': '1f64e-1f3fe',
      '1f3ff': '1f64e-1f3ff',
    },
  },
  '1f64e-1f3fb': {
    hexCodePoints: '1f64e-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏻',
    version: 'E1.0',
    baseName: 'person pouting',
    fullName: 'person pouting: light skin tone',
    isBase: false,
    baseHex: '1f64e',
    modifiers: null,
  },
  '1f64e-1f3fc': {
    hexCodePoints: '1f64e-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏼',
    version: 'E1.0',
    baseName: 'person pouting',
    fullName: 'person pouting: medium-light skin tone',
    isBase: false,
    baseHex: '1f64e',
    modifiers: null,
  },
  '1f64e-1f3fd': {
    hexCodePoints: '1f64e-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏽',
    version: 'E1.0',
    baseName: 'person pouting',
    fullName: 'person pouting: medium skin tone',
    isBase: false,
    baseHex: '1f64e',
    modifiers: null,
  },
  '1f64e-1f3fe': {
    hexCodePoints: '1f64e-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏾',
    version: 'E1.0',
    baseName: 'person pouting',
    fullName: 'person pouting: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64e',
    modifiers: null,
  },
  '1f64e-1f3ff': {
    hexCodePoints: '1f64e-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏿',
    version: 'E1.0',
    baseName: 'person pouting',
    fullName: 'person pouting: dark skin tone',
    isBase: false,
    baseHex: '1f64e',
    modifiers: null,
  },
  '1f64e-200d-2642-fe0f': {
    hexCodePoints: '1f64e-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎‍♂️',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting',
    isBase: true,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f64e-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f64e-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f64e-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f64e-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f64e-1f3ff-200d-2642-fe0f',
    },
  },
  '1f64e-200d-2642': {
    hexCodePoints: '1f64e-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎‍♂',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f64e-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏻‍♂️',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fb-200d-2642': {
    hexCodePoints: '1f64e-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏻‍♂',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f64e-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏼‍♂️',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: medium-light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fc-200d-2642': {
    hexCodePoints: '1f64e-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏼‍♂',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: medium-light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f64e-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏽‍♂️',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: medium skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fd-200d-2642': {
    hexCodePoints: '1f64e-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏽‍♂',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: medium skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f64e-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏾‍♂️',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fe-200d-2642': {
    hexCodePoints: '1f64e-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏾‍♂',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f64e-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏿‍♂️',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-1f3ff-200d-2642': {
    hexCodePoints: '1f64e-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏿‍♂',
    version: 'E4.0',
    baseName: 'man pouting',
    fullName: 'man pouting: dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64e-200d-2640-fe0f': {
    hexCodePoints: '1f64e-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎‍♀️',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting',
    isBase: true,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f64e-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f64e-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f64e-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f64e-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f64e-1f3ff-200d-2640-fe0f',
    },
  },
  '1f64e-200d-2640': {
    hexCodePoints: '1f64e-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎‍♀',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f64e-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fb-200d-2640': {
    hexCodePoints: '1f64e-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏻‍♀',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f64e-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: medium-light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fc-200d-2640': {
    hexCodePoints: '1f64e-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏼‍♀',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: medium-light skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f64e-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: medium skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fd-200d-2640': {
    hexCodePoints: '1f64e-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏽‍♀',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: medium skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f64e-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3fe-200d-2640': {
    hexCodePoints: '1f64e-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏾‍♀',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f64e-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙎🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64e-1f3ff-200d-2640': {
    hexCodePoints: '1f64e-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙎🏿‍♀',
    version: 'E4.0',
    baseName: 'woman pouting',
    fullName: 'woman pouting: dark skin tone',
    isBase: false,
    baseHex: '1f64e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645': {
    hexCodePoints: '1f645',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅',
    version: 'E0.6',
    baseName: 'person gesturing NO',
    fullName: 'person gesturing NO',
    isBase: true,
    baseHex: '1f645',
    modifiers: {
      '1f3fb': '1f645-1f3fb',
      '1f3fc': '1f645-1f3fc',
      '1f3fd': '1f645-1f3fd',
      '1f3fe': '1f645-1f3fe',
      '1f3ff': '1f645-1f3ff',
    },
  },
  '1f645-1f3fb': {
    hexCodePoints: '1f645-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏻',
    version: 'E1.0',
    baseName: 'person gesturing NO',
    fullName: 'person gesturing NO: light skin tone',
    isBase: false,
    baseHex: '1f645',
    modifiers: null,
  },
  '1f645-1f3fc': {
    hexCodePoints: '1f645-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏼',
    version: 'E1.0',
    baseName: 'person gesturing NO',
    fullName: 'person gesturing NO: medium-light skin tone',
    isBase: false,
    baseHex: '1f645',
    modifiers: null,
  },
  '1f645-1f3fd': {
    hexCodePoints: '1f645-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏽',
    version: 'E1.0',
    baseName: 'person gesturing NO',
    fullName: 'person gesturing NO: medium skin tone',
    isBase: false,
    baseHex: '1f645',
    modifiers: null,
  },
  '1f645-1f3fe': {
    hexCodePoints: '1f645-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏾',
    version: 'E1.0',
    baseName: 'person gesturing NO',
    fullName: 'person gesturing NO: medium-dark skin tone',
    isBase: false,
    baseHex: '1f645',
    modifiers: null,
  },
  '1f645-1f3ff': {
    hexCodePoints: '1f645-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏿',
    version: 'E1.0',
    baseName: 'person gesturing NO',
    fullName: 'person gesturing NO: dark skin tone',
    isBase: false,
    baseHex: '1f645',
    modifiers: null,
  },
  '1f645-200d-2642-fe0f': {
    hexCodePoints: '1f645-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO',
    isBase: true,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f645-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f645-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f645-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f645-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f645-1f3ff-200d-2642-fe0f',
    },
  },
  '1f645-200d-2642': {
    hexCodePoints: '1f645-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅‍♂',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f645-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏻‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fb-200d-2642': {
    hexCodePoints: '1f645-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏻‍♂',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f645-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏼‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: medium-light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fc-200d-2642': {
    hexCodePoints: '1f645-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏼‍♂',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: medium-light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f645-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏽‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: medium skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fd-200d-2642': {
    hexCodePoints: '1f645-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏽‍♂',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: medium skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f645-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏾‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: medium-dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3fe-200d-2642': {
    hexCodePoints: '1f645-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏾‍♂',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: medium-dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f645-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏿‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-1f3ff-200d-2642': {
    hexCodePoints: '1f645-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏿‍♂',
    version: 'E4.0',
    baseName: 'man gesturing NO',
    fullName: 'man gesturing NO: dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2642-fe0f',
    modifiers: null,
  },
  '1f645-200d-2640-fe0f': {
    hexCodePoints: '1f645-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO',
    isBase: true,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f645-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f645-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f645-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f645-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f645-1f3ff-200d-2640-fe0f',
    },
  },
  '1f645-200d-2640': {
    hexCodePoints: '1f645-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f645-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fb-200d-2640': {
    hexCodePoints: '1f645-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏻‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f645-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: medium-light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fc-200d-2640': {
    hexCodePoints: '1f645-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏼‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: medium-light skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f645-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: medium skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fd-200d-2640': {
    hexCodePoints: '1f645-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏽‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: medium skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f645-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: medium-dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3fe-200d-2640': {
    hexCodePoints: '1f645-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏾‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: medium-dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f645-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙅🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f645-1f3ff-200d-2640': {
    hexCodePoints: '1f645-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙅🏿‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing NO',
    fullName: 'woman gesturing NO: dark skin tone',
    isBase: false,
    baseHex: '1f645-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646': {
    hexCodePoints: '1f646',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆',
    version: 'E0.6',
    baseName: 'person gesturing OK',
    fullName: 'person gesturing OK',
    isBase: true,
    baseHex: '1f646',
    modifiers: {
      '1f3fb': '1f646-1f3fb',
      '1f3fc': '1f646-1f3fc',
      '1f3fd': '1f646-1f3fd',
      '1f3fe': '1f646-1f3fe',
      '1f3ff': '1f646-1f3ff',
    },
  },
  '1f646-1f3fb': {
    hexCodePoints: '1f646-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏻',
    version: 'E1.0',
    baseName: 'person gesturing OK',
    fullName: 'person gesturing OK: light skin tone',
    isBase: false,
    baseHex: '1f646',
    modifiers: null,
  },
  '1f646-1f3fc': {
    hexCodePoints: '1f646-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏼',
    version: 'E1.0',
    baseName: 'person gesturing OK',
    fullName: 'person gesturing OK: medium-light skin tone',
    isBase: false,
    baseHex: '1f646',
    modifiers: null,
  },
  '1f646-1f3fd': {
    hexCodePoints: '1f646-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏽',
    version: 'E1.0',
    baseName: 'person gesturing OK',
    fullName: 'person gesturing OK: medium skin tone',
    isBase: false,
    baseHex: '1f646',
    modifiers: null,
  },
  '1f646-1f3fe': {
    hexCodePoints: '1f646-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏾',
    version: 'E1.0',
    baseName: 'person gesturing OK',
    fullName: 'person gesturing OK: medium-dark skin tone',
    isBase: false,
    baseHex: '1f646',
    modifiers: null,
  },
  '1f646-1f3ff': {
    hexCodePoints: '1f646-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏿',
    version: 'E1.0',
    baseName: 'person gesturing OK',
    fullName: 'person gesturing OK: dark skin tone',
    isBase: false,
    baseHex: '1f646',
    modifiers: null,
  },
  '1f646-200d-2642-fe0f': {
    hexCodePoints: '1f646-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK',
    isBase: true,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f646-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f646-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f646-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f646-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f646-1f3ff-200d-2642-fe0f',
    },
  },
  '1f646-200d-2642': {
    hexCodePoints: '1f646-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆‍♂',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f646-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏻‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fb-200d-2642': {
    hexCodePoints: '1f646-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏻‍♂',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f646-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏼‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: medium-light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fc-200d-2642': {
    hexCodePoints: '1f646-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏼‍♂',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: medium-light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f646-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏽‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: medium skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fd-200d-2642': {
    hexCodePoints: '1f646-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏽‍♂',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: medium skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f646-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏾‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: medium-dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3fe-200d-2642': {
    hexCodePoints: '1f646-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏾‍♂',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: medium-dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f646-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏿‍♂️',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-1f3ff-200d-2642': {
    hexCodePoints: '1f646-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏿‍♂',
    version: 'E4.0',
    baseName: 'man gesturing OK',
    fullName: 'man gesturing OK: dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2642-fe0f',
    modifiers: null,
  },
  '1f646-200d-2640-fe0f': {
    hexCodePoints: '1f646-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK',
    isBase: true,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f646-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f646-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f646-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f646-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f646-1f3ff-200d-2640-fe0f',
    },
  },
  '1f646-200d-2640': {
    hexCodePoints: '1f646-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f646-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fb-200d-2640': {
    hexCodePoints: '1f646-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏻‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f646-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: medium-light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fc-200d-2640': {
    hexCodePoints: '1f646-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏼‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: medium-light skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f646-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: medium skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fd-200d-2640': {
    hexCodePoints: '1f646-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏽‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: medium skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f646-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: medium-dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3fe-200d-2640': {
    hexCodePoints: '1f646-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏾‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: medium-dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f646-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙆🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f646-1f3ff-200d-2640': {
    hexCodePoints: '1f646-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙆🏿‍♀',
    version: 'E4.0',
    baseName: 'woman gesturing OK',
    fullName: 'woman gesturing OK: dark skin tone',
    isBase: false,
    baseHex: '1f646-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481': {
    hexCodePoints: '1f481',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁',
    version: 'E0.6',
    baseName: 'person tipping hand',
    fullName: 'person tipping hand',
    isBase: true,
    baseHex: '1f481',
    modifiers: {
      '1f3fb': '1f481-1f3fb',
      '1f3fc': '1f481-1f3fc',
      '1f3fd': '1f481-1f3fd',
      '1f3fe': '1f481-1f3fe',
      '1f3ff': '1f481-1f3ff',
    },
  },
  '1f481-1f3fb': {
    hexCodePoints: '1f481-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏻',
    version: 'E1.0',
    baseName: 'person tipping hand',
    fullName: 'person tipping hand: light skin tone',
    isBase: false,
    baseHex: '1f481',
    modifiers: null,
  },
  '1f481-1f3fc': {
    hexCodePoints: '1f481-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏼',
    version: 'E1.0',
    baseName: 'person tipping hand',
    fullName: 'person tipping hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f481',
    modifiers: null,
  },
  '1f481-1f3fd': {
    hexCodePoints: '1f481-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏽',
    version: 'E1.0',
    baseName: 'person tipping hand',
    fullName: 'person tipping hand: medium skin tone',
    isBase: false,
    baseHex: '1f481',
    modifiers: null,
  },
  '1f481-1f3fe': {
    hexCodePoints: '1f481-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏾',
    version: 'E1.0',
    baseName: 'person tipping hand',
    fullName: 'person tipping hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f481',
    modifiers: null,
  },
  '1f481-1f3ff': {
    hexCodePoints: '1f481-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏿',
    version: 'E1.0',
    baseName: 'person tipping hand',
    fullName: 'person tipping hand: dark skin tone',
    isBase: false,
    baseHex: '1f481',
    modifiers: null,
  },
  '1f481-200d-2642-fe0f': {
    hexCodePoints: '1f481-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁‍♂️',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand',
    isBase: true,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f481-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f481-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f481-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f481-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f481-1f3ff-200d-2642-fe0f',
    },
  },
  '1f481-200d-2642': {
    hexCodePoints: '1f481-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁‍♂',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f481-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏻‍♂️',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fb-200d-2642': {
    hexCodePoints: '1f481-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏻‍♂',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f481-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏼‍♂️',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fc-200d-2642': {
    hexCodePoints: '1f481-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏼‍♂',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f481-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏽‍♂️',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: medium skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fd-200d-2642': {
    hexCodePoints: '1f481-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏽‍♂',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: medium skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f481-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏾‍♂️',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3fe-200d-2642': {
    hexCodePoints: '1f481-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏾‍♂',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f481-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏿‍♂️',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-1f3ff-200d-2642': {
    hexCodePoints: '1f481-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏿‍♂',
    version: 'E4.0',
    baseName: 'man tipping hand',
    fullName: 'man tipping hand: dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2642-fe0f',
    modifiers: null,
  },
  '1f481-200d-2640-fe0f': {
    hexCodePoints: '1f481-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁‍♀️',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand',
    isBase: true,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f481-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f481-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f481-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f481-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f481-1f3ff-200d-2640-fe0f',
    },
  },
  '1f481-200d-2640': {
    hexCodePoints: '1f481-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁‍♀',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f481-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fb-200d-2640': {
    hexCodePoints: '1f481-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏻‍♀',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f481-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fc-200d-2640': {
    hexCodePoints: '1f481-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏼‍♀',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f481-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: medium skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fd-200d-2640': {
    hexCodePoints: '1f481-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏽‍♀',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: medium skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f481-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3fe-200d-2640': {
    hexCodePoints: '1f481-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏾‍♀',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f481-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💁🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f481-1f3ff-200d-2640': {
    hexCodePoints: '1f481-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💁🏿‍♀',
    version: 'E4.0',
    baseName: 'woman tipping hand',
    fullName: 'woman tipping hand: dark skin tone',
    isBase: false,
    baseHex: '1f481-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b': {
    hexCodePoints: '1f64b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋',
    version: 'E0.6',
    baseName: 'person raising hand',
    fullName: 'person raising hand',
    isBase: true,
    baseHex: '1f64b',
    modifiers: {
      '1f3fb': '1f64b-1f3fb',
      '1f3fc': '1f64b-1f3fc',
      '1f3fd': '1f64b-1f3fd',
      '1f3fe': '1f64b-1f3fe',
      '1f3ff': '1f64b-1f3ff',
    },
  },
  '1f64b-1f3fb': {
    hexCodePoints: '1f64b-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏻',
    version: 'E1.0',
    baseName: 'person raising hand',
    fullName: 'person raising hand: light skin tone',
    isBase: false,
    baseHex: '1f64b',
    modifiers: null,
  },
  '1f64b-1f3fc': {
    hexCodePoints: '1f64b-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏼',
    version: 'E1.0',
    baseName: 'person raising hand',
    fullName: 'person raising hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f64b',
    modifiers: null,
  },
  '1f64b-1f3fd': {
    hexCodePoints: '1f64b-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏽',
    version: 'E1.0',
    baseName: 'person raising hand',
    fullName: 'person raising hand: medium skin tone',
    isBase: false,
    baseHex: '1f64b',
    modifiers: null,
  },
  '1f64b-1f3fe': {
    hexCodePoints: '1f64b-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏾',
    version: 'E1.0',
    baseName: 'person raising hand',
    fullName: 'person raising hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64b',
    modifiers: null,
  },
  '1f64b-1f3ff': {
    hexCodePoints: '1f64b-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏿',
    version: 'E1.0',
    baseName: 'person raising hand',
    fullName: 'person raising hand: dark skin tone',
    isBase: false,
    baseHex: '1f64b',
    modifiers: null,
  },
  '1f64b-200d-2642-fe0f': {
    hexCodePoints: '1f64b-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋‍♂️',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand',
    isBase: true,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f64b-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f64b-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f64b-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f64b-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f64b-1f3ff-200d-2642-fe0f',
    },
  },
  '1f64b-200d-2642': {
    hexCodePoints: '1f64b-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋‍♂',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f64b-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏻‍♂️',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fb-200d-2642': {
    hexCodePoints: '1f64b-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏻‍♂',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f64b-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏼‍♂️',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fc-200d-2642': {
    hexCodePoints: '1f64b-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏼‍♂',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f64b-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏽‍♂️',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: medium skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fd-200d-2642': {
    hexCodePoints: '1f64b-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏽‍♂',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: medium skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f64b-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏾‍♂️',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fe-200d-2642': {
    hexCodePoints: '1f64b-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏾‍♂',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f64b-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏿‍♂️',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-1f3ff-200d-2642': {
    hexCodePoints: '1f64b-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏿‍♂',
    version: 'E4.0',
    baseName: 'man raising hand',
    fullName: 'man raising hand: dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2642-fe0f',
    modifiers: null,
  },
  '1f64b-200d-2640-fe0f': {
    hexCodePoints: '1f64b-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋‍♀️',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand',
    isBase: true,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f64b-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f64b-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f64b-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f64b-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f64b-1f3ff-200d-2640-fe0f',
    },
  },
  '1f64b-200d-2640': {
    hexCodePoints: '1f64b-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋‍♀',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f64b-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fb-200d-2640': {
    hexCodePoints: '1f64b-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏻‍♀',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f64b-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fc-200d-2640': {
    hexCodePoints: '1f64b-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏼‍♀',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: medium-light skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f64b-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: medium skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fd-200d-2640': {
    hexCodePoints: '1f64b-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏽‍♀',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: medium skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f64b-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3fe-200d-2640': {
    hexCodePoints: '1f64b-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏾‍♀',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: medium-dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f64b-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙋🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f64b-1f3ff-200d-2640': {
    hexCodePoints: '1f64b-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙋🏿‍♀',
    version: 'E4.0',
    baseName: 'woman raising hand',
    fullName: 'woman raising hand: dark skin tone',
    isBase: false,
    baseHex: '1f64b-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf': {
    hexCodePoints: '1f9cf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏',
    version: 'E12.0',
    baseName: 'deaf person',
    fullName: 'deaf person',
    isBase: true,
    baseHex: '1f9cf',
    modifiers: {
      '1f3fb': '1f9cf-1f3fb',
      '1f3fc': '1f9cf-1f3fc',
      '1f3fd': '1f9cf-1f3fd',
      '1f3fe': '1f9cf-1f3fe',
      '1f3ff': '1f9cf-1f3ff',
    },
  },
  '1f9cf-1f3fb': {
    hexCodePoints: '1f9cf-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏻',
    version: 'E12.0',
    baseName: 'deaf person',
    fullName: 'deaf person: light skin tone',
    isBase: false,
    baseHex: '1f9cf',
    modifiers: null,
  },
  '1f9cf-1f3fc': {
    hexCodePoints: '1f9cf-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏼',
    version: 'E12.0',
    baseName: 'deaf person',
    fullName: 'deaf person: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cf',
    modifiers: null,
  },
  '1f9cf-1f3fd': {
    hexCodePoints: '1f9cf-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏽',
    version: 'E12.0',
    baseName: 'deaf person',
    fullName: 'deaf person: medium skin tone',
    isBase: false,
    baseHex: '1f9cf',
    modifiers: null,
  },
  '1f9cf-1f3fe': {
    hexCodePoints: '1f9cf-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏾',
    version: 'E12.0',
    baseName: 'deaf person',
    fullName: 'deaf person: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cf',
    modifiers: null,
  },
  '1f9cf-1f3ff': {
    hexCodePoints: '1f9cf-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏿',
    version: 'E12.0',
    baseName: 'deaf person',
    fullName: 'deaf person: dark skin tone',
    isBase: false,
    baseHex: '1f9cf',
    modifiers: null,
  },
  '1f9cf-200d-2642-fe0f': {
    hexCodePoints: '1f9cf-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏‍♂️',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man',
    isBase: true,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9cf-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9cf-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9cf-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9cf-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9cf-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9cf-200d-2642': {
    hexCodePoints: '1f9cf-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏‍♂',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9cf-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏻‍♂️',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fb-200d-2642': {
    hexCodePoints: '1f9cf-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏻‍♂',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9cf-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏼‍♂️',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fc-200d-2642': {
    hexCodePoints: '1f9cf-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏼‍♂',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9cf-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏽‍♂️',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: medium skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fd-200d-2642': {
    hexCodePoints: '1f9cf-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏽‍♂',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: medium skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9cf-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏾‍♂️',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fe-200d-2642': {
    hexCodePoints: '1f9cf-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏾‍♂',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9cf-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏿‍♂️',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3ff-200d-2642': {
    hexCodePoints: '1f9cf-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏿‍♂',
    version: 'E12.0',
    baseName: 'deaf man',
    fullName: 'deaf man: dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cf-200d-2640-fe0f': {
    hexCodePoints: '1f9cf-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏‍♀️',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman',
    isBase: true,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9cf-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9cf-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9cf-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9cf-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9cf-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9cf-200d-2640': {
    hexCodePoints: '1f9cf-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏‍♀',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9cf-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏻‍♀️',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fb-200d-2640': {
    hexCodePoints: '1f9cf-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏻‍♀',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9cf-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏼‍♀️',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fc-200d-2640': {
    hexCodePoints: '1f9cf-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏼‍♀',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9cf-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏽‍♀️',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: medium skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fd-200d-2640': {
    hexCodePoints: '1f9cf-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏽‍♀',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: medium skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9cf-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏾‍♀️',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3fe-200d-2640': {
    hexCodePoints: '1f9cf-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏾‍♀',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9cf-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧏🏿‍♀️',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cf-1f3ff-200d-2640': {
    hexCodePoints: '1f9cf-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧏🏿‍♀',
    version: 'E12.0',
    baseName: 'deaf woman',
    fullName: 'deaf woman: dark skin tone',
    isBase: false,
    baseHex: '1f9cf-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647': {
    hexCodePoints: '1f647',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇',
    version: 'E0.6',
    baseName: 'person bowing',
    fullName: 'person bowing',
    isBase: true,
    baseHex: '1f647',
    modifiers: {
      '1f3fb': '1f647-1f3fb',
      '1f3fc': '1f647-1f3fc',
      '1f3fd': '1f647-1f3fd',
      '1f3fe': '1f647-1f3fe',
      '1f3ff': '1f647-1f3ff',
    },
  },
  '1f647-1f3fb': {
    hexCodePoints: '1f647-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏻',
    version: 'E1.0',
    baseName: 'person bowing',
    fullName: 'person bowing: light skin tone',
    isBase: false,
    baseHex: '1f647',
    modifiers: null,
  },
  '1f647-1f3fc': {
    hexCodePoints: '1f647-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏼',
    version: 'E1.0',
    baseName: 'person bowing',
    fullName: 'person bowing: medium-light skin tone',
    isBase: false,
    baseHex: '1f647',
    modifiers: null,
  },
  '1f647-1f3fd': {
    hexCodePoints: '1f647-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏽',
    version: 'E1.0',
    baseName: 'person bowing',
    fullName: 'person bowing: medium skin tone',
    isBase: false,
    baseHex: '1f647',
    modifiers: null,
  },
  '1f647-1f3fe': {
    hexCodePoints: '1f647-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏾',
    version: 'E1.0',
    baseName: 'person bowing',
    fullName: 'person bowing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f647',
    modifiers: null,
  },
  '1f647-1f3ff': {
    hexCodePoints: '1f647-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏿',
    version: 'E1.0',
    baseName: 'person bowing',
    fullName: 'person bowing: dark skin tone',
    isBase: false,
    baseHex: '1f647',
    modifiers: null,
  },
  '1f647-200d-2642-fe0f': {
    hexCodePoints: '1f647-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇‍♂️',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing',
    isBase: true,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f647-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f647-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f647-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f647-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f647-1f3ff-200d-2642-fe0f',
    },
  },
  '1f647-200d-2642': {
    hexCodePoints: '1f647-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇‍♂',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f647-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏻‍♂️',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fb-200d-2642': {
    hexCodePoints: '1f647-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏻‍♂',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f647-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏼‍♂️',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: medium-light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fc-200d-2642': {
    hexCodePoints: '1f647-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏼‍♂',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: medium-light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f647-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏽‍♂️',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: medium skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fd-200d-2642': {
    hexCodePoints: '1f647-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏽‍♂',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: medium skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f647-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏾‍♂️',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3fe-200d-2642': {
    hexCodePoints: '1f647-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏾‍♂',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f647-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏿‍♂️',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-1f3ff-200d-2642': {
    hexCodePoints: '1f647-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏿‍♂',
    version: 'E4.0',
    baseName: 'man bowing',
    fullName: 'man bowing: dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2642-fe0f',
    modifiers: null,
  },
  '1f647-200d-2640-fe0f': {
    hexCodePoints: '1f647-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇‍♀️',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing',
    isBase: true,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f647-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f647-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f647-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f647-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f647-1f3ff-200d-2640-fe0f',
    },
  },
  '1f647-200d-2640': {
    hexCodePoints: '1f647-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇‍♀',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f647-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fb-200d-2640': {
    hexCodePoints: '1f647-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏻‍♀',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f647-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: medium-light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fc-200d-2640': {
    hexCodePoints: '1f647-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏼‍♀',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: medium-light skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f647-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: medium skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fd-200d-2640': {
    hexCodePoints: '1f647-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏽‍♀',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: medium skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f647-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3fe-200d-2640': {
    hexCodePoints: '1f647-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏾‍♀',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f647-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🙇🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f647-1f3ff-200d-2640': {
    hexCodePoints: '1f647-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🙇🏿‍♀',
    version: 'E4.0',
    baseName: 'woman bowing',
    fullName: 'woman bowing: dark skin tone',
    isBase: false,
    baseHex: '1f647-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926': {
    hexCodePoints: '1f926',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦',
    version: 'E3.0',
    baseName: 'person facepalming',
    fullName: 'person facepalming',
    isBase: true,
    baseHex: '1f926',
    modifiers: {
      '1f3fb': '1f926-1f3fb',
      '1f3fc': '1f926-1f3fc',
      '1f3fd': '1f926-1f3fd',
      '1f3fe': '1f926-1f3fe',
      '1f3ff': '1f926-1f3ff',
    },
  },
  '1f926-1f3fb': {
    hexCodePoints: '1f926-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏻',
    version: 'E3.0',
    baseName: 'person facepalming',
    fullName: 'person facepalming: light skin tone',
    isBase: false,
    baseHex: '1f926',
    modifiers: null,
  },
  '1f926-1f3fc': {
    hexCodePoints: '1f926-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏼',
    version: 'E3.0',
    baseName: 'person facepalming',
    fullName: 'person facepalming: medium-light skin tone',
    isBase: false,
    baseHex: '1f926',
    modifiers: null,
  },
  '1f926-1f3fd': {
    hexCodePoints: '1f926-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏽',
    version: 'E3.0',
    baseName: 'person facepalming',
    fullName: 'person facepalming: medium skin tone',
    isBase: false,
    baseHex: '1f926',
    modifiers: null,
  },
  '1f926-1f3fe': {
    hexCodePoints: '1f926-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏾',
    version: 'E3.0',
    baseName: 'person facepalming',
    fullName: 'person facepalming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f926',
    modifiers: null,
  },
  '1f926-1f3ff': {
    hexCodePoints: '1f926-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏿',
    version: 'E3.0',
    baseName: 'person facepalming',
    fullName: 'person facepalming: dark skin tone',
    isBase: false,
    baseHex: '1f926',
    modifiers: null,
  },
  '1f926-200d-2642-fe0f': {
    hexCodePoints: '1f926-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦‍♂️',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming',
    isBase: true,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f926-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f926-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f926-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f926-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f926-1f3ff-200d-2642-fe0f',
    },
  },
  '1f926-200d-2642': {
    hexCodePoints: '1f926-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦‍♂',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f926-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏻‍♂️',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fb-200d-2642': {
    hexCodePoints: '1f926-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏻‍♂',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f926-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏼‍♂️',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: medium-light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fc-200d-2642': {
    hexCodePoints: '1f926-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏼‍♂',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: medium-light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f926-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏽‍♂️',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: medium skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fd-200d-2642': {
    hexCodePoints: '1f926-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏽‍♂',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: medium skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f926-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏾‍♂️',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3fe-200d-2642': {
    hexCodePoints: '1f926-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏾‍♂',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f926-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏿‍♂️',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-1f3ff-200d-2642': {
    hexCodePoints: '1f926-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏿‍♂',
    version: 'E4.0',
    baseName: 'man facepalming',
    fullName: 'man facepalming: dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2642-fe0f',
    modifiers: null,
  },
  '1f926-200d-2640-fe0f': {
    hexCodePoints: '1f926-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦‍♀️',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming',
    isBase: true,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f926-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f926-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f926-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f926-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f926-1f3ff-200d-2640-fe0f',
    },
  },
  '1f926-200d-2640': {
    hexCodePoints: '1f926-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦‍♀',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f926-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fb-200d-2640': {
    hexCodePoints: '1f926-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏻‍♀',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f926-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: medium-light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fc-200d-2640': {
    hexCodePoints: '1f926-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏼‍♀',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: medium-light skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f926-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: medium skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fd-200d-2640': {
    hexCodePoints: '1f926-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏽‍♀',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: medium skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f926-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3fe-200d-2640': {
    hexCodePoints: '1f926-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏾‍♀',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f926-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤦🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f926-1f3ff-200d-2640': {
    hexCodePoints: '1f926-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤦🏿‍♀',
    version: 'E4.0',
    baseName: 'woman facepalming',
    fullName: 'woman facepalming: dark skin tone',
    isBase: false,
    baseHex: '1f926-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937': {
    hexCodePoints: '1f937',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷',
    version: 'E3.0',
    baseName: 'person shrugging',
    fullName: 'person shrugging',
    isBase: true,
    baseHex: '1f937',
    modifiers: {
      '1f3fb': '1f937-1f3fb',
      '1f3fc': '1f937-1f3fc',
      '1f3fd': '1f937-1f3fd',
      '1f3fe': '1f937-1f3fe',
      '1f3ff': '1f937-1f3ff',
    },
  },
  '1f937-1f3fb': {
    hexCodePoints: '1f937-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏻',
    version: 'E3.0',
    baseName: 'person shrugging',
    fullName: 'person shrugging: light skin tone',
    isBase: false,
    baseHex: '1f937',
    modifiers: null,
  },
  '1f937-1f3fc': {
    hexCodePoints: '1f937-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏼',
    version: 'E3.0',
    baseName: 'person shrugging',
    fullName: 'person shrugging: medium-light skin tone',
    isBase: false,
    baseHex: '1f937',
    modifiers: null,
  },
  '1f937-1f3fd': {
    hexCodePoints: '1f937-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏽',
    version: 'E3.0',
    baseName: 'person shrugging',
    fullName: 'person shrugging: medium skin tone',
    isBase: false,
    baseHex: '1f937',
    modifiers: null,
  },
  '1f937-1f3fe': {
    hexCodePoints: '1f937-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏾',
    version: 'E3.0',
    baseName: 'person shrugging',
    fullName: 'person shrugging: medium-dark skin tone',
    isBase: false,
    baseHex: '1f937',
    modifiers: null,
  },
  '1f937-1f3ff': {
    hexCodePoints: '1f937-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏿',
    version: 'E3.0',
    baseName: 'person shrugging',
    fullName: 'person shrugging: dark skin tone',
    isBase: false,
    baseHex: '1f937',
    modifiers: null,
  },
  '1f937-200d-2642-fe0f': {
    hexCodePoints: '1f937-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷‍♂️',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging',
    isBase: true,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f937-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f937-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f937-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f937-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f937-1f3ff-200d-2642-fe0f',
    },
  },
  '1f937-200d-2642': {
    hexCodePoints: '1f937-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷‍♂',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f937-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏻‍♂️',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fb-200d-2642': {
    hexCodePoints: '1f937-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏻‍♂',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f937-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏼‍♂️',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: medium-light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fc-200d-2642': {
    hexCodePoints: '1f937-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏼‍♂',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: medium-light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f937-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏽‍♂️',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: medium skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fd-200d-2642': {
    hexCodePoints: '1f937-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏽‍♂',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: medium skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f937-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏾‍♂️',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: medium-dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3fe-200d-2642': {
    hexCodePoints: '1f937-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏾‍♂',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: medium-dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f937-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏿‍♂️',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-1f3ff-200d-2642': {
    hexCodePoints: '1f937-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏿‍♂',
    version: 'E4.0',
    baseName: 'man shrugging',
    fullName: 'man shrugging: dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2642-fe0f',
    modifiers: null,
  },
  '1f937-200d-2640-fe0f': {
    hexCodePoints: '1f937-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷‍♀️',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging',
    isBase: true,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f937-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f937-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f937-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f937-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f937-1f3ff-200d-2640-fe0f',
    },
  },
  '1f937-200d-2640': {
    hexCodePoints: '1f937-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷‍♀',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f937-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fb-200d-2640': {
    hexCodePoints: '1f937-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏻‍♀',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f937-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: medium-light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fc-200d-2640': {
    hexCodePoints: '1f937-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏼‍♀',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: medium-light skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f937-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: medium skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fd-200d-2640': {
    hexCodePoints: '1f937-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏽‍♀',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: medium skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f937-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: medium-dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3fe-200d-2640': {
    hexCodePoints: '1f937-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏾‍♀',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: medium-dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f937-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤷🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f937-1f3ff-200d-2640': {
    hexCodePoints: '1f937-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤷🏿‍♀',
    version: 'E4.0',
    baseName: 'woman shrugging',
    fullName: 'woman shrugging: dark skin tone',
    isBase: false,
    baseHex: '1f937-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-2695-fe0f': {
    hexCodePoints: '1f9d1-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍⚕️',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker',
    isBase: true,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-2695-fe0f',
      '1f3fc': '1f9d1-1f3fc-200d-2695-fe0f',
      '1f3fd': '1f9d1-1f3fd-200d-2695-fe0f',
      '1f3fe': '1f9d1-1f3fe-200d-2695-fe0f',
      '1f3ff': '1f9d1-1f3ff-200d-2695-fe0f',
    },
  },
  '1f9d1-200d-2695': {
    hexCodePoints: '1f9d1-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑‍⚕',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2695-fe0f': {
    hexCodePoints: '1f9d1-1f3fb-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍⚕️',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2695': {
    hexCodePoints: '1f9d1-1f3fb-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍⚕',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2695-fe0f': {
    hexCodePoints: '1f9d1-1f3fc-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍⚕️',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2695': {
    hexCodePoints: '1f9d1-1f3fc-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍⚕',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2695-fe0f': {
    hexCodePoints: '1f9d1-1f3fd-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍⚕️',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2695': {
    hexCodePoints: '1f9d1-1f3fd-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍⚕',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2695-fe0f': {
    hexCodePoints: '1f9d1-1f3fe-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍⚕️',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2695': {
    hexCodePoints: '1f9d1-1f3fe-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍⚕',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2695-fe0f': {
    hexCodePoints: '1f9d1-1f3ff-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍⚕️',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2695': {
    hexCodePoints: '1f9d1-1f3ff-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍⚕',
    version: 'E12.1',
    baseName: 'health worker',
    fullName: 'health worker: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-200d-2695-fe0f': {
    hexCodePoints: '1f468-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍⚕️',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker',
    isBase: true,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-2695-fe0f',
      '1f3fc': '1f468-1f3fc-200d-2695-fe0f',
      '1f3fd': '1f468-1f3fd-200d-2695-fe0f',
      '1f3fe': '1f468-1f3fe-200d-2695-fe0f',
      '1f3ff': '1f468-1f3ff-200d-2695-fe0f',
    },
  },
  '1f468-200d-2695': {
    hexCodePoints: '1f468-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍⚕',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2695-fe0f': {
    hexCodePoints: '1f468-1f3fb-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍⚕️',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2695': {
    hexCodePoints: '1f468-1f3fb-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍⚕',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2695-fe0f': {
    hexCodePoints: '1f468-1f3fc-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍⚕️',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2695': {
    hexCodePoints: '1f468-1f3fc-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍⚕',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2695-fe0f': {
    hexCodePoints: '1f468-1f3fd-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍⚕️',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2695': {
    hexCodePoints: '1f468-1f3fd-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍⚕',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2695-fe0f': {
    hexCodePoints: '1f468-1f3fe-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍⚕️',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2695': {
    hexCodePoints: '1f468-1f3fe-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍⚕',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2695-fe0f': {
    hexCodePoints: '1f468-1f3ff-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍⚕️',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2695': {
    hexCodePoints: '1f468-1f3ff-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍⚕',
    version: 'E4.0',
    baseName: 'man health worker',
    fullName: 'man health worker: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-200d-2695-fe0f': {
    hexCodePoints: '1f469-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍⚕️',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker',
    isBase: true,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-2695-fe0f',
      '1f3fc': '1f469-1f3fc-200d-2695-fe0f',
      '1f3fd': '1f469-1f3fd-200d-2695-fe0f',
      '1f3fe': '1f469-1f3fe-200d-2695-fe0f',
      '1f3ff': '1f469-1f3ff-200d-2695-fe0f',
    },
  },
  '1f469-200d-2695': {
    hexCodePoints: '1f469-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍⚕',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2695-fe0f': {
    hexCodePoints: '1f469-1f3fb-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍⚕️',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2695': {
    hexCodePoints: '1f469-1f3fb-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍⚕',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2695-fe0f': {
    hexCodePoints: '1f469-1f3fc-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍⚕️',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2695': {
    hexCodePoints: '1f469-1f3fc-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍⚕',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2695-fe0f': {
    hexCodePoints: '1f469-1f3fd-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍⚕️',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2695': {
    hexCodePoints: '1f469-1f3fd-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍⚕',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2695-fe0f': {
    hexCodePoints: '1f469-1f3fe-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍⚕️',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2695': {
    hexCodePoints: '1f469-1f3fe-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍⚕',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2695-fe0f': {
    hexCodePoints: '1f469-1f3ff-200d-2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍⚕️',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2695': {
    hexCodePoints: '1f469-1f3ff-200d-2695',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍⚕',
    version: 'E4.0',
    baseName: 'woman health worker',
    fullName: 'woman health worker: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2695-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-1f393': {
    hexCodePoints: '1f9d1-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🎓',
    version: 'E12.1',
    baseName: 'student',
    fullName: 'student',
    isBase: true,
    baseHex: '1f9d1-200d-1f393',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f393',
      '1f3fc': '1f9d1-1f3fc-200d-1f393',
      '1f3fd': '1f9d1-1f3fd-200d-1f393',
      '1f3fe': '1f9d1-1f3fe-200d-1f393',
      '1f3ff': '1f9d1-1f3ff-200d-1f393',
    },
  },
  '1f9d1-1f3fb-200d-1f393': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🎓',
    version: 'E12.1',
    baseName: 'student',
    fullName: 'student: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f393',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f393': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🎓',
    version: 'E12.1',
    baseName: 'student',
    fullName: 'student: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f393',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f393': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🎓',
    version: 'E12.1',
    baseName: 'student',
    fullName: 'student: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f393',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f393': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🎓',
    version: 'E12.1',
    baseName: 'student',
    fullName: 'student: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f393',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f393': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🎓',
    version: 'E12.1',
    baseName: 'student',
    fullName: 'student: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f393',
    modifiers: null,
  },
  '1f468-200d-1f393': {
    hexCodePoints: '1f468-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🎓',
    version: 'E4.0',
    baseName: 'man student',
    fullName: 'man student',
    isBase: true,
    baseHex: '1f468-200d-1f393',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f393',
      '1f3fc': '1f468-1f3fc-200d-1f393',
      '1f3fd': '1f468-1f3fd-200d-1f393',
      '1f3fe': '1f468-1f3fe-200d-1f393',
      '1f3ff': '1f468-1f3ff-200d-1f393',
    },
  },
  '1f468-1f3fb-200d-1f393': {
    hexCodePoints: '1f468-1f3fb-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🎓',
    version: 'E4.0',
    baseName: 'man student',
    fullName: 'man student: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f393',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f393': {
    hexCodePoints: '1f468-1f3fc-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🎓',
    version: 'E4.0',
    baseName: 'man student',
    fullName: 'man student: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f393',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f393': {
    hexCodePoints: '1f468-1f3fd-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🎓',
    version: 'E4.0',
    baseName: 'man student',
    fullName: 'man student: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f393',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f393': {
    hexCodePoints: '1f468-1f3fe-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🎓',
    version: 'E4.0',
    baseName: 'man student',
    fullName: 'man student: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f393',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f393': {
    hexCodePoints: '1f468-1f3ff-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🎓',
    version: 'E4.0',
    baseName: 'man student',
    fullName: 'man student: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f393',
    modifiers: null,
  },
  '1f469-200d-1f393': {
    hexCodePoints: '1f469-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🎓',
    version: 'E4.0',
    baseName: 'woman student',
    fullName: 'woman student',
    isBase: true,
    baseHex: '1f469-200d-1f393',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f393',
      '1f3fc': '1f469-1f3fc-200d-1f393',
      '1f3fd': '1f469-1f3fd-200d-1f393',
      '1f3fe': '1f469-1f3fe-200d-1f393',
      '1f3ff': '1f469-1f3ff-200d-1f393',
    },
  },
  '1f469-1f3fb-200d-1f393': {
    hexCodePoints: '1f469-1f3fb-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🎓',
    version: 'E4.0',
    baseName: 'woman student',
    fullName: 'woman student: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f393',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f393': {
    hexCodePoints: '1f469-1f3fc-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🎓',
    version: 'E4.0',
    baseName: 'woman student',
    fullName: 'woman student: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f393',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f393': {
    hexCodePoints: '1f469-1f3fd-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🎓',
    version: 'E4.0',
    baseName: 'woman student',
    fullName: 'woman student: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f393',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f393': {
    hexCodePoints: '1f469-1f3fe-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🎓',
    version: 'E4.0',
    baseName: 'woman student',
    fullName: 'woman student: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f393',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f393': {
    hexCodePoints: '1f469-1f3ff-200d-1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🎓',
    version: 'E4.0',
    baseName: 'woman student',
    fullName: 'woman student: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f393',
    modifiers: null,
  },
  '1f9d1-200d-1f3eb': {
    hexCodePoints: '1f9d1-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🏫',
    version: 'E12.1',
    baseName: 'teacher',
    fullName: 'teacher',
    isBase: true,
    baseHex: '1f9d1-200d-1f3eb',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f3eb',
      '1f3fc': '1f9d1-1f3fc-200d-1f3eb',
      '1f3fd': '1f9d1-1f3fd-200d-1f3eb',
      '1f3fe': '1f9d1-1f3fe-200d-1f3eb',
      '1f3ff': '1f9d1-1f3ff-200d-1f3eb',
    },
  },
  '1f9d1-1f3fb-200d-1f3eb': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🏫',
    version: 'E12.1',
    baseName: 'teacher',
    fullName: 'teacher: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3eb',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f3eb': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🏫',
    version: 'E12.1',
    baseName: 'teacher',
    fullName: 'teacher: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3eb',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f3eb': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🏫',
    version: 'E12.1',
    baseName: 'teacher',
    fullName: 'teacher: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3eb',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f3eb': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🏫',
    version: 'E12.1',
    baseName: 'teacher',
    fullName: 'teacher: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3eb',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f3eb': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🏫',
    version: 'E12.1',
    baseName: 'teacher',
    fullName: 'teacher: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3eb',
    modifiers: null,
  },
  '1f468-200d-1f3eb': {
    hexCodePoints: '1f468-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🏫',
    version: 'E4.0',
    baseName: 'man teacher',
    fullName: 'man teacher',
    isBase: true,
    baseHex: '1f468-200d-1f3eb',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f3eb',
      '1f3fc': '1f468-1f3fc-200d-1f3eb',
      '1f3fd': '1f468-1f3fd-200d-1f3eb',
      '1f3fe': '1f468-1f3fe-200d-1f3eb',
      '1f3ff': '1f468-1f3ff-200d-1f3eb',
    },
  },
  '1f468-1f3fb-200d-1f3eb': {
    hexCodePoints: '1f468-1f3fb-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🏫',
    version: 'E4.0',
    baseName: 'man teacher',
    fullName: 'man teacher: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3eb',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f3eb': {
    hexCodePoints: '1f468-1f3fc-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🏫',
    version: 'E4.0',
    baseName: 'man teacher',
    fullName: 'man teacher: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3eb',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f3eb': {
    hexCodePoints: '1f468-1f3fd-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🏫',
    version: 'E4.0',
    baseName: 'man teacher',
    fullName: 'man teacher: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3eb',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f3eb': {
    hexCodePoints: '1f468-1f3fe-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🏫',
    version: 'E4.0',
    baseName: 'man teacher',
    fullName: 'man teacher: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3eb',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f3eb': {
    hexCodePoints: '1f468-1f3ff-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🏫',
    version: 'E4.0',
    baseName: 'man teacher',
    fullName: 'man teacher: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3eb',
    modifiers: null,
  },
  '1f469-200d-1f3eb': {
    hexCodePoints: '1f469-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🏫',
    version: 'E4.0',
    baseName: 'woman teacher',
    fullName: 'woman teacher',
    isBase: true,
    baseHex: '1f469-200d-1f3eb',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f3eb',
      '1f3fc': '1f469-1f3fc-200d-1f3eb',
      '1f3fd': '1f469-1f3fd-200d-1f3eb',
      '1f3fe': '1f469-1f3fe-200d-1f3eb',
      '1f3ff': '1f469-1f3ff-200d-1f3eb',
    },
  },
  '1f469-1f3fb-200d-1f3eb': {
    hexCodePoints: '1f469-1f3fb-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🏫',
    version: 'E4.0',
    baseName: 'woman teacher',
    fullName: 'woman teacher: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3eb',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f3eb': {
    hexCodePoints: '1f469-1f3fc-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🏫',
    version: 'E4.0',
    baseName: 'woman teacher',
    fullName: 'woman teacher: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3eb',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f3eb': {
    hexCodePoints: '1f469-1f3fd-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🏫',
    version: 'E4.0',
    baseName: 'woman teacher',
    fullName: 'woman teacher: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3eb',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f3eb': {
    hexCodePoints: '1f469-1f3fe-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🏫',
    version: 'E4.0',
    baseName: 'woman teacher',
    fullName: 'woman teacher: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3eb',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f3eb': {
    hexCodePoints: '1f469-1f3ff-200d-1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🏫',
    version: 'E4.0',
    baseName: 'woman teacher',
    fullName: 'woman teacher: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3eb',
    modifiers: null,
  },
  '1f9d1-200d-2696-fe0f': {
    hexCodePoints: '1f9d1-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍⚖️',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge',
    isBase: true,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-2696-fe0f',
      '1f3fc': '1f9d1-1f3fc-200d-2696-fe0f',
      '1f3fd': '1f9d1-1f3fd-200d-2696-fe0f',
      '1f3fe': '1f9d1-1f3fe-200d-2696-fe0f',
      '1f3ff': '1f9d1-1f3ff-200d-2696-fe0f',
    },
  },
  '1f9d1-200d-2696': {
    hexCodePoints: '1f9d1-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑‍⚖',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2696-fe0f': {
    hexCodePoints: '1f9d1-1f3fb-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍⚖️',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2696': {
    hexCodePoints: '1f9d1-1f3fb-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍⚖',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2696-fe0f': {
    hexCodePoints: '1f9d1-1f3fc-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍⚖️',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2696': {
    hexCodePoints: '1f9d1-1f3fc-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍⚖',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2696-fe0f': {
    hexCodePoints: '1f9d1-1f3fd-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍⚖️',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2696': {
    hexCodePoints: '1f9d1-1f3fd-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍⚖',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2696-fe0f': {
    hexCodePoints: '1f9d1-1f3fe-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍⚖️',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2696': {
    hexCodePoints: '1f9d1-1f3fe-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍⚖',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2696-fe0f': {
    hexCodePoints: '1f9d1-1f3ff-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍⚖️',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2696': {
    hexCodePoints: '1f9d1-1f3ff-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍⚖',
    version: 'E12.1',
    baseName: 'judge',
    fullName: 'judge: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-200d-2696-fe0f': {
    hexCodePoints: '1f468-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍⚖️',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge',
    isBase: true,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-2696-fe0f',
      '1f3fc': '1f468-1f3fc-200d-2696-fe0f',
      '1f3fd': '1f468-1f3fd-200d-2696-fe0f',
      '1f3fe': '1f468-1f3fe-200d-2696-fe0f',
      '1f3ff': '1f468-1f3ff-200d-2696-fe0f',
    },
  },
  '1f468-200d-2696': {
    hexCodePoints: '1f468-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍⚖',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2696-fe0f': {
    hexCodePoints: '1f468-1f3fb-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍⚖️',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2696': {
    hexCodePoints: '1f468-1f3fb-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍⚖',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2696-fe0f': {
    hexCodePoints: '1f468-1f3fc-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍⚖️',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2696': {
    hexCodePoints: '1f468-1f3fc-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍⚖',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2696-fe0f': {
    hexCodePoints: '1f468-1f3fd-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍⚖️',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2696': {
    hexCodePoints: '1f468-1f3fd-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍⚖',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2696-fe0f': {
    hexCodePoints: '1f468-1f3fe-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍⚖️',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2696': {
    hexCodePoints: '1f468-1f3fe-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍⚖',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2696-fe0f': {
    hexCodePoints: '1f468-1f3ff-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍⚖️',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2696': {
    hexCodePoints: '1f468-1f3ff-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍⚖',
    version: 'E4.0',
    baseName: 'man judge',
    fullName: 'man judge: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-200d-2696-fe0f': {
    hexCodePoints: '1f469-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍⚖️',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge',
    isBase: true,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-2696-fe0f',
      '1f3fc': '1f469-1f3fc-200d-2696-fe0f',
      '1f3fd': '1f469-1f3fd-200d-2696-fe0f',
      '1f3fe': '1f469-1f3fe-200d-2696-fe0f',
      '1f3ff': '1f469-1f3ff-200d-2696-fe0f',
    },
  },
  '1f469-200d-2696': {
    hexCodePoints: '1f469-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍⚖',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2696-fe0f': {
    hexCodePoints: '1f469-1f3fb-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍⚖️',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2696': {
    hexCodePoints: '1f469-1f3fb-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍⚖',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2696-fe0f': {
    hexCodePoints: '1f469-1f3fc-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍⚖️',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2696': {
    hexCodePoints: '1f469-1f3fc-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍⚖',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2696-fe0f': {
    hexCodePoints: '1f469-1f3fd-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍⚖️',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2696': {
    hexCodePoints: '1f469-1f3fd-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍⚖',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2696-fe0f': {
    hexCodePoints: '1f469-1f3fe-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍⚖️',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2696': {
    hexCodePoints: '1f469-1f3fe-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍⚖',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2696-fe0f': {
    hexCodePoints: '1f469-1f3ff-200d-2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍⚖️',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2696': {
    hexCodePoints: '1f469-1f3ff-200d-2696',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍⚖',
    version: 'E4.0',
    baseName: 'woman judge',
    fullName: 'woman judge: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2696-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-1f33e': {
    hexCodePoints: '1f9d1-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🌾',
    version: 'E12.1',
    baseName: 'farmer',
    fullName: 'farmer',
    isBase: true,
    baseHex: '1f9d1-200d-1f33e',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f33e',
      '1f3fc': '1f9d1-1f3fc-200d-1f33e',
      '1f3fd': '1f9d1-1f3fd-200d-1f33e',
      '1f3fe': '1f9d1-1f3fe-200d-1f33e',
      '1f3ff': '1f9d1-1f3ff-200d-1f33e',
    },
  },
  '1f9d1-1f3fb-200d-1f33e': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🌾',
    version: 'E12.1',
    baseName: 'farmer',
    fullName: 'farmer: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f33e',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f33e': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🌾',
    version: 'E12.1',
    baseName: 'farmer',
    fullName: 'farmer: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f33e',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f33e': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🌾',
    version: 'E12.1',
    baseName: 'farmer',
    fullName: 'farmer: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f33e',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f33e': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🌾',
    version: 'E12.1',
    baseName: 'farmer',
    fullName: 'farmer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f33e',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f33e': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🌾',
    version: 'E12.1',
    baseName: 'farmer',
    fullName: 'farmer: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f33e',
    modifiers: null,
  },
  '1f468-200d-1f33e': {
    hexCodePoints: '1f468-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🌾',
    version: 'E4.0',
    baseName: 'man farmer',
    fullName: 'man farmer',
    isBase: true,
    baseHex: '1f468-200d-1f33e',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f33e',
      '1f3fc': '1f468-1f3fc-200d-1f33e',
      '1f3fd': '1f468-1f3fd-200d-1f33e',
      '1f3fe': '1f468-1f3fe-200d-1f33e',
      '1f3ff': '1f468-1f3ff-200d-1f33e',
    },
  },
  '1f468-1f3fb-200d-1f33e': {
    hexCodePoints: '1f468-1f3fb-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🌾',
    version: 'E4.0',
    baseName: 'man farmer',
    fullName: 'man farmer: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f33e',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f33e': {
    hexCodePoints: '1f468-1f3fc-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🌾',
    version: 'E4.0',
    baseName: 'man farmer',
    fullName: 'man farmer: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f33e',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f33e': {
    hexCodePoints: '1f468-1f3fd-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🌾',
    version: 'E4.0',
    baseName: 'man farmer',
    fullName: 'man farmer: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f33e',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f33e': {
    hexCodePoints: '1f468-1f3fe-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🌾',
    version: 'E4.0',
    baseName: 'man farmer',
    fullName: 'man farmer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f33e',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f33e': {
    hexCodePoints: '1f468-1f3ff-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🌾',
    version: 'E4.0',
    baseName: 'man farmer',
    fullName: 'man farmer: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f33e',
    modifiers: null,
  },
  '1f469-200d-1f33e': {
    hexCodePoints: '1f469-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🌾',
    version: 'E4.0',
    baseName: 'woman farmer',
    fullName: 'woman farmer',
    isBase: true,
    baseHex: '1f469-200d-1f33e',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f33e',
      '1f3fc': '1f469-1f3fc-200d-1f33e',
      '1f3fd': '1f469-1f3fd-200d-1f33e',
      '1f3fe': '1f469-1f3fe-200d-1f33e',
      '1f3ff': '1f469-1f3ff-200d-1f33e',
    },
  },
  '1f469-1f3fb-200d-1f33e': {
    hexCodePoints: '1f469-1f3fb-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🌾',
    version: 'E4.0',
    baseName: 'woman farmer',
    fullName: 'woman farmer: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f33e',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f33e': {
    hexCodePoints: '1f469-1f3fc-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🌾',
    version: 'E4.0',
    baseName: 'woman farmer',
    fullName: 'woman farmer: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f33e',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f33e': {
    hexCodePoints: '1f469-1f3fd-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🌾',
    version: 'E4.0',
    baseName: 'woman farmer',
    fullName: 'woman farmer: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f33e',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f33e': {
    hexCodePoints: '1f469-1f3fe-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🌾',
    version: 'E4.0',
    baseName: 'woman farmer',
    fullName: 'woman farmer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f33e',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f33e': {
    hexCodePoints: '1f469-1f3ff-200d-1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🌾',
    version: 'E4.0',
    baseName: 'woman farmer',
    fullName: 'woman farmer: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f33e',
    modifiers: null,
  },
  '1f9d1-200d-1f373': {
    hexCodePoints: '1f9d1-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🍳',
    version: 'E12.1',
    baseName: 'cook',
    fullName: 'cook',
    isBase: true,
    baseHex: '1f9d1-200d-1f373',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f373',
      '1f3fc': '1f9d1-1f3fc-200d-1f373',
      '1f3fd': '1f9d1-1f3fd-200d-1f373',
      '1f3fe': '1f9d1-1f3fe-200d-1f373',
      '1f3ff': '1f9d1-1f3ff-200d-1f373',
    },
  },
  '1f9d1-1f3fb-200d-1f373': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🍳',
    version: 'E12.1',
    baseName: 'cook',
    fullName: 'cook: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f373',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f373': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🍳',
    version: 'E12.1',
    baseName: 'cook',
    fullName: 'cook: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f373',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f373': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🍳',
    version: 'E12.1',
    baseName: 'cook',
    fullName: 'cook: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f373',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f373': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🍳',
    version: 'E12.1',
    baseName: 'cook',
    fullName: 'cook: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f373',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f373': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🍳',
    version: 'E12.1',
    baseName: 'cook',
    fullName: 'cook: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f373',
    modifiers: null,
  },
  '1f468-200d-1f373': {
    hexCodePoints: '1f468-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🍳',
    version: 'E4.0',
    baseName: 'man cook',
    fullName: 'man cook',
    isBase: true,
    baseHex: '1f468-200d-1f373',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f373',
      '1f3fc': '1f468-1f3fc-200d-1f373',
      '1f3fd': '1f468-1f3fd-200d-1f373',
      '1f3fe': '1f468-1f3fe-200d-1f373',
      '1f3ff': '1f468-1f3ff-200d-1f373',
    },
  },
  '1f468-1f3fb-200d-1f373': {
    hexCodePoints: '1f468-1f3fb-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🍳',
    version: 'E4.0',
    baseName: 'man cook',
    fullName: 'man cook: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f373',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f373': {
    hexCodePoints: '1f468-1f3fc-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🍳',
    version: 'E4.0',
    baseName: 'man cook',
    fullName: 'man cook: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f373',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f373': {
    hexCodePoints: '1f468-1f3fd-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🍳',
    version: 'E4.0',
    baseName: 'man cook',
    fullName: 'man cook: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f373',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f373': {
    hexCodePoints: '1f468-1f3fe-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🍳',
    version: 'E4.0',
    baseName: 'man cook',
    fullName: 'man cook: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f373',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f373': {
    hexCodePoints: '1f468-1f3ff-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🍳',
    version: 'E4.0',
    baseName: 'man cook',
    fullName: 'man cook: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f373',
    modifiers: null,
  },
  '1f469-200d-1f373': {
    hexCodePoints: '1f469-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🍳',
    version: 'E4.0',
    baseName: 'woman cook',
    fullName: 'woman cook',
    isBase: true,
    baseHex: '1f469-200d-1f373',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f373',
      '1f3fc': '1f469-1f3fc-200d-1f373',
      '1f3fd': '1f469-1f3fd-200d-1f373',
      '1f3fe': '1f469-1f3fe-200d-1f373',
      '1f3ff': '1f469-1f3ff-200d-1f373',
    },
  },
  '1f469-1f3fb-200d-1f373': {
    hexCodePoints: '1f469-1f3fb-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🍳',
    version: 'E4.0',
    baseName: 'woman cook',
    fullName: 'woman cook: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f373',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f373': {
    hexCodePoints: '1f469-1f3fc-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🍳',
    version: 'E4.0',
    baseName: 'woman cook',
    fullName: 'woman cook: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f373',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f373': {
    hexCodePoints: '1f469-1f3fd-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🍳',
    version: 'E4.0',
    baseName: 'woman cook',
    fullName: 'woman cook: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f373',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f373': {
    hexCodePoints: '1f469-1f3fe-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🍳',
    version: 'E4.0',
    baseName: 'woman cook',
    fullName: 'woman cook: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f373',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f373': {
    hexCodePoints: '1f469-1f3ff-200d-1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🍳',
    version: 'E4.0',
    baseName: 'woman cook',
    fullName: 'woman cook: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f373',
    modifiers: null,
  },
  '1f9d1-200d-1f527': {
    hexCodePoints: '1f9d1-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🔧',
    version: 'E12.1',
    baseName: 'mechanic',
    fullName: 'mechanic',
    isBase: true,
    baseHex: '1f9d1-200d-1f527',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f527',
      '1f3fc': '1f9d1-1f3fc-200d-1f527',
      '1f3fd': '1f9d1-1f3fd-200d-1f527',
      '1f3fe': '1f9d1-1f3fe-200d-1f527',
      '1f3ff': '1f9d1-1f3ff-200d-1f527',
    },
  },
  '1f9d1-1f3fb-200d-1f527': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🔧',
    version: 'E12.1',
    baseName: 'mechanic',
    fullName: 'mechanic: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f527',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f527': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🔧',
    version: 'E12.1',
    baseName: 'mechanic',
    fullName: 'mechanic: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f527',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f527': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🔧',
    version: 'E12.1',
    baseName: 'mechanic',
    fullName: 'mechanic: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f527',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f527': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🔧',
    version: 'E12.1',
    baseName: 'mechanic',
    fullName: 'mechanic: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f527',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f527': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🔧',
    version: 'E12.1',
    baseName: 'mechanic',
    fullName: 'mechanic: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f527',
    modifiers: null,
  },
  '1f468-200d-1f527': {
    hexCodePoints: '1f468-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🔧',
    version: 'E4.0',
    baseName: 'man mechanic',
    fullName: 'man mechanic',
    isBase: true,
    baseHex: '1f468-200d-1f527',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f527',
      '1f3fc': '1f468-1f3fc-200d-1f527',
      '1f3fd': '1f468-1f3fd-200d-1f527',
      '1f3fe': '1f468-1f3fe-200d-1f527',
      '1f3ff': '1f468-1f3ff-200d-1f527',
    },
  },
  '1f468-1f3fb-200d-1f527': {
    hexCodePoints: '1f468-1f3fb-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🔧',
    version: 'E4.0',
    baseName: 'man mechanic',
    fullName: 'man mechanic: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f527',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f527': {
    hexCodePoints: '1f468-1f3fc-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🔧',
    version: 'E4.0',
    baseName: 'man mechanic',
    fullName: 'man mechanic: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f527',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f527': {
    hexCodePoints: '1f468-1f3fd-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🔧',
    version: 'E4.0',
    baseName: 'man mechanic',
    fullName: 'man mechanic: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f527',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f527': {
    hexCodePoints: '1f468-1f3fe-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🔧',
    version: 'E4.0',
    baseName: 'man mechanic',
    fullName: 'man mechanic: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f527',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f527': {
    hexCodePoints: '1f468-1f3ff-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🔧',
    version: 'E4.0',
    baseName: 'man mechanic',
    fullName: 'man mechanic: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f527',
    modifiers: null,
  },
  '1f469-200d-1f527': {
    hexCodePoints: '1f469-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🔧',
    version: 'E4.0',
    baseName: 'woman mechanic',
    fullName: 'woman mechanic',
    isBase: true,
    baseHex: '1f469-200d-1f527',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f527',
      '1f3fc': '1f469-1f3fc-200d-1f527',
      '1f3fd': '1f469-1f3fd-200d-1f527',
      '1f3fe': '1f469-1f3fe-200d-1f527',
      '1f3ff': '1f469-1f3ff-200d-1f527',
    },
  },
  '1f469-1f3fb-200d-1f527': {
    hexCodePoints: '1f469-1f3fb-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🔧',
    version: 'E4.0',
    baseName: 'woman mechanic',
    fullName: 'woman mechanic: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f527',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f527': {
    hexCodePoints: '1f469-1f3fc-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🔧',
    version: 'E4.0',
    baseName: 'woman mechanic',
    fullName: 'woman mechanic: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f527',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f527': {
    hexCodePoints: '1f469-1f3fd-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🔧',
    version: 'E4.0',
    baseName: 'woman mechanic',
    fullName: 'woman mechanic: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f527',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f527': {
    hexCodePoints: '1f469-1f3fe-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🔧',
    version: 'E4.0',
    baseName: 'woman mechanic',
    fullName: 'woman mechanic: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f527',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f527': {
    hexCodePoints: '1f469-1f3ff-200d-1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🔧',
    version: 'E4.0',
    baseName: 'woman mechanic',
    fullName: 'woman mechanic: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f527',
    modifiers: null,
  },
  '1f9d1-200d-1f3ed': {
    hexCodePoints: '1f9d1-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🏭',
    version: 'E12.1',
    baseName: 'factory worker',
    fullName: 'factory worker',
    isBase: true,
    baseHex: '1f9d1-200d-1f3ed',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f3ed',
      '1f3fc': '1f9d1-1f3fc-200d-1f3ed',
      '1f3fd': '1f9d1-1f3fd-200d-1f3ed',
      '1f3fe': '1f9d1-1f3fe-200d-1f3ed',
      '1f3ff': '1f9d1-1f3ff-200d-1f3ed',
    },
  },
  '1f9d1-1f3fb-200d-1f3ed': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🏭',
    version: 'E12.1',
    baseName: 'factory worker',
    fullName: 'factory worker: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3ed',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f3ed': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🏭',
    version: 'E12.1',
    baseName: 'factory worker',
    fullName: 'factory worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3ed',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f3ed': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🏭',
    version: 'E12.1',
    baseName: 'factory worker',
    fullName: 'factory worker: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3ed',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f3ed': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🏭',
    version: 'E12.1',
    baseName: 'factory worker',
    fullName: 'factory worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3ed',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f3ed': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🏭',
    version: 'E12.1',
    baseName: 'factory worker',
    fullName: 'factory worker: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3ed',
    modifiers: null,
  },
  '1f468-200d-1f3ed': {
    hexCodePoints: '1f468-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🏭',
    version: 'E4.0',
    baseName: 'man factory worker',
    fullName: 'man factory worker',
    isBase: true,
    baseHex: '1f468-200d-1f3ed',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f3ed',
      '1f3fc': '1f468-1f3fc-200d-1f3ed',
      '1f3fd': '1f468-1f3fd-200d-1f3ed',
      '1f3fe': '1f468-1f3fe-200d-1f3ed',
      '1f3ff': '1f468-1f3ff-200d-1f3ed',
    },
  },
  '1f468-1f3fb-200d-1f3ed': {
    hexCodePoints: '1f468-1f3fb-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🏭',
    version: 'E4.0',
    baseName: 'man factory worker',
    fullName: 'man factory worker: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3ed',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f3ed': {
    hexCodePoints: '1f468-1f3fc-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🏭',
    version: 'E4.0',
    baseName: 'man factory worker',
    fullName: 'man factory worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3ed',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f3ed': {
    hexCodePoints: '1f468-1f3fd-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🏭',
    version: 'E4.0',
    baseName: 'man factory worker',
    fullName: 'man factory worker: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3ed',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f3ed': {
    hexCodePoints: '1f468-1f3fe-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🏭',
    version: 'E4.0',
    baseName: 'man factory worker',
    fullName: 'man factory worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3ed',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f3ed': {
    hexCodePoints: '1f468-1f3ff-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🏭',
    version: 'E4.0',
    baseName: 'man factory worker',
    fullName: 'man factory worker: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3ed',
    modifiers: null,
  },
  '1f469-200d-1f3ed': {
    hexCodePoints: '1f469-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🏭',
    version: 'E4.0',
    baseName: 'woman factory worker',
    fullName: 'woman factory worker',
    isBase: true,
    baseHex: '1f469-200d-1f3ed',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f3ed',
      '1f3fc': '1f469-1f3fc-200d-1f3ed',
      '1f3fd': '1f469-1f3fd-200d-1f3ed',
      '1f3fe': '1f469-1f3fe-200d-1f3ed',
      '1f3ff': '1f469-1f3ff-200d-1f3ed',
    },
  },
  '1f469-1f3fb-200d-1f3ed': {
    hexCodePoints: '1f469-1f3fb-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🏭',
    version: 'E4.0',
    baseName: 'woman factory worker',
    fullName: 'woman factory worker: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3ed',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f3ed': {
    hexCodePoints: '1f469-1f3fc-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🏭',
    version: 'E4.0',
    baseName: 'woman factory worker',
    fullName: 'woman factory worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3ed',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f3ed': {
    hexCodePoints: '1f469-1f3fd-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🏭',
    version: 'E4.0',
    baseName: 'woman factory worker',
    fullName: 'woman factory worker: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3ed',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f3ed': {
    hexCodePoints: '1f469-1f3fe-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🏭',
    version: 'E4.0',
    baseName: 'woman factory worker',
    fullName: 'woman factory worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3ed',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f3ed': {
    hexCodePoints: '1f469-1f3ff-200d-1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🏭',
    version: 'E4.0',
    baseName: 'woman factory worker',
    fullName: 'woman factory worker: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3ed',
    modifiers: null,
  },
  '1f9d1-200d-1f4bc': {
    hexCodePoints: '1f9d1-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍💼',
    version: 'E12.1',
    baseName: 'office worker',
    fullName: 'office worker',
    isBase: true,
    baseHex: '1f9d1-200d-1f4bc',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f4bc',
      '1f3fc': '1f9d1-1f3fc-200d-1f4bc',
      '1f3fd': '1f9d1-1f3fd-200d-1f4bc',
      '1f3fe': '1f9d1-1f3fe-200d-1f4bc',
      '1f3ff': '1f9d1-1f3ff-200d-1f4bc',
    },
  },
  '1f9d1-1f3fb-200d-1f4bc': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍💼',
    version: 'E12.1',
    baseName: 'office worker',
    fullName: 'office worker: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bc',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f4bc': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍💼',
    version: 'E12.1',
    baseName: 'office worker',
    fullName: 'office worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bc',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f4bc': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍💼',
    version: 'E12.1',
    baseName: 'office worker',
    fullName: 'office worker: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bc',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f4bc': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍💼',
    version: 'E12.1',
    baseName: 'office worker',
    fullName: 'office worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bc',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f4bc': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍💼',
    version: 'E12.1',
    baseName: 'office worker',
    fullName: 'office worker: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bc',
    modifiers: null,
  },
  '1f468-200d-1f4bc': {
    hexCodePoints: '1f468-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍💼',
    version: 'E4.0',
    baseName: 'man office worker',
    fullName: 'man office worker',
    isBase: true,
    baseHex: '1f468-200d-1f4bc',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f4bc',
      '1f3fc': '1f468-1f3fc-200d-1f4bc',
      '1f3fd': '1f468-1f3fd-200d-1f4bc',
      '1f3fe': '1f468-1f3fe-200d-1f4bc',
      '1f3ff': '1f468-1f3ff-200d-1f4bc',
    },
  },
  '1f468-1f3fb-200d-1f4bc': {
    hexCodePoints: '1f468-1f3fb-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍💼',
    version: 'E4.0',
    baseName: 'man office worker',
    fullName: 'man office worker: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bc',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f4bc': {
    hexCodePoints: '1f468-1f3fc-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍💼',
    version: 'E4.0',
    baseName: 'man office worker',
    fullName: 'man office worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bc',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f4bc': {
    hexCodePoints: '1f468-1f3fd-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍💼',
    version: 'E4.0',
    baseName: 'man office worker',
    fullName: 'man office worker: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bc',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f4bc': {
    hexCodePoints: '1f468-1f3fe-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍💼',
    version: 'E4.0',
    baseName: 'man office worker',
    fullName: 'man office worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bc',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f4bc': {
    hexCodePoints: '1f468-1f3ff-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍💼',
    version: 'E4.0',
    baseName: 'man office worker',
    fullName: 'man office worker: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bc',
    modifiers: null,
  },
  '1f469-200d-1f4bc': {
    hexCodePoints: '1f469-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍💼',
    version: 'E4.0',
    baseName: 'woman office worker',
    fullName: 'woman office worker',
    isBase: true,
    baseHex: '1f469-200d-1f4bc',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f4bc',
      '1f3fc': '1f469-1f3fc-200d-1f4bc',
      '1f3fd': '1f469-1f3fd-200d-1f4bc',
      '1f3fe': '1f469-1f3fe-200d-1f4bc',
      '1f3ff': '1f469-1f3ff-200d-1f4bc',
    },
  },
  '1f469-1f3fb-200d-1f4bc': {
    hexCodePoints: '1f469-1f3fb-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍💼',
    version: 'E4.0',
    baseName: 'woman office worker',
    fullName: 'woman office worker: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bc',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f4bc': {
    hexCodePoints: '1f469-1f3fc-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍💼',
    version: 'E4.0',
    baseName: 'woman office worker',
    fullName: 'woman office worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bc',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f4bc': {
    hexCodePoints: '1f469-1f3fd-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍💼',
    version: 'E4.0',
    baseName: 'woman office worker',
    fullName: 'woman office worker: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bc',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f4bc': {
    hexCodePoints: '1f469-1f3fe-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍💼',
    version: 'E4.0',
    baseName: 'woman office worker',
    fullName: 'woman office worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bc',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f4bc': {
    hexCodePoints: '1f469-1f3ff-200d-1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍💼',
    version: 'E4.0',
    baseName: 'woman office worker',
    fullName: 'woman office worker: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bc',
    modifiers: null,
  },
  '1f9d1-200d-1f52c': {
    hexCodePoints: '1f9d1-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🔬',
    version: 'E12.1',
    baseName: 'scientist',
    fullName: 'scientist',
    isBase: true,
    baseHex: '1f9d1-200d-1f52c',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f52c',
      '1f3fc': '1f9d1-1f3fc-200d-1f52c',
      '1f3fd': '1f9d1-1f3fd-200d-1f52c',
      '1f3fe': '1f9d1-1f3fe-200d-1f52c',
      '1f3ff': '1f9d1-1f3ff-200d-1f52c',
    },
  },
  '1f9d1-1f3fb-200d-1f52c': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🔬',
    version: 'E12.1',
    baseName: 'scientist',
    fullName: 'scientist: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f52c',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f52c': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🔬',
    version: 'E12.1',
    baseName: 'scientist',
    fullName: 'scientist: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f52c',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f52c': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🔬',
    version: 'E12.1',
    baseName: 'scientist',
    fullName: 'scientist: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f52c',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f52c': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🔬',
    version: 'E12.1',
    baseName: 'scientist',
    fullName: 'scientist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f52c',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f52c': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🔬',
    version: 'E12.1',
    baseName: 'scientist',
    fullName: 'scientist: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f52c',
    modifiers: null,
  },
  '1f468-200d-1f52c': {
    hexCodePoints: '1f468-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🔬',
    version: 'E4.0',
    baseName: 'man scientist',
    fullName: 'man scientist',
    isBase: true,
    baseHex: '1f468-200d-1f52c',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f52c',
      '1f3fc': '1f468-1f3fc-200d-1f52c',
      '1f3fd': '1f468-1f3fd-200d-1f52c',
      '1f3fe': '1f468-1f3fe-200d-1f52c',
      '1f3ff': '1f468-1f3ff-200d-1f52c',
    },
  },
  '1f468-1f3fb-200d-1f52c': {
    hexCodePoints: '1f468-1f3fb-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🔬',
    version: 'E4.0',
    baseName: 'man scientist',
    fullName: 'man scientist: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f52c',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f52c': {
    hexCodePoints: '1f468-1f3fc-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🔬',
    version: 'E4.0',
    baseName: 'man scientist',
    fullName: 'man scientist: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f52c',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f52c': {
    hexCodePoints: '1f468-1f3fd-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🔬',
    version: 'E4.0',
    baseName: 'man scientist',
    fullName: 'man scientist: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f52c',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f52c': {
    hexCodePoints: '1f468-1f3fe-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🔬',
    version: 'E4.0',
    baseName: 'man scientist',
    fullName: 'man scientist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f52c',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f52c': {
    hexCodePoints: '1f468-1f3ff-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🔬',
    version: 'E4.0',
    baseName: 'man scientist',
    fullName: 'man scientist: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f52c',
    modifiers: null,
  },
  '1f469-200d-1f52c': {
    hexCodePoints: '1f469-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🔬',
    version: 'E4.0',
    baseName: 'woman scientist',
    fullName: 'woman scientist',
    isBase: true,
    baseHex: '1f469-200d-1f52c',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f52c',
      '1f3fc': '1f469-1f3fc-200d-1f52c',
      '1f3fd': '1f469-1f3fd-200d-1f52c',
      '1f3fe': '1f469-1f3fe-200d-1f52c',
      '1f3ff': '1f469-1f3ff-200d-1f52c',
    },
  },
  '1f469-1f3fb-200d-1f52c': {
    hexCodePoints: '1f469-1f3fb-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🔬',
    version: 'E4.0',
    baseName: 'woman scientist',
    fullName: 'woman scientist: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f52c',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f52c': {
    hexCodePoints: '1f469-1f3fc-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🔬',
    version: 'E4.0',
    baseName: 'woman scientist',
    fullName: 'woman scientist: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f52c',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f52c': {
    hexCodePoints: '1f469-1f3fd-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🔬',
    version: 'E4.0',
    baseName: 'woman scientist',
    fullName: 'woman scientist: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f52c',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f52c': {
    hexCodePoints: '1f469-1f3fe-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🔬',
    version: 'E4.0',
    baseName: 'woman scientist',
    fullName: 'woman scientist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f52c',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f52c': {
    hexCodePoints: '1f469-1f3ff-200d-1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🔬',
    version: 'E4.0',
    baseName: 'woman scientist',
    fullName: 'woman scientist: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f52c',
    modifiers: null,
  },
  '1f9d1-200d-1f4bb': {
    hexCodePoints: '1f9d1-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍💻',
    version: 'E12.1',
    baseName: 'technologist',
    fullName: 'technologist',
    isBase: true,
    baseHex: '1f9d1-200d-1f4bb',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f4bb',
      '1f3fc': '1f9d1-1f3fc-200d-1f4bb',
      '1f3fd': '1f9d1-1f3fd-200d-1f4bb',
      '1f3fe': '1f9d1-1f3fe-200d-1f4bb',
      '1f3ff': '1f9d1-1f3ff-200d-1f4bb',
    },
  },
  '1f9d1-1f3fb-200d-1f4bb': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍💻',
    version: 'E12.1',
    baseName: 'technologist',
    fullName: 'technologist: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bb',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f4bb': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍💻',
    version: 'E12.1',
    baseName: 'technologist',
    fullName: 'technologist: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bb',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f4bb': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍💻',
    version: 'E12.1',
    baseName: 'technologist',
    fullName: 'technologist: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bb',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f4bb': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍💻',
    version: 'E12.1',
    baseName: 'technologist',
    fullName: 'technologist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bb',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f4bb': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍💻',
    version: 'E12.1',
    baseName: 'technologist',
    fullName: 'technologist: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f4bb',
    modifiers: null,
  },
  '1f468-200d-1f4bb': {
    hexCodePoints: '1f468-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍💻',
    version: 'E4.0',
    baseName: 'man technologist',
    fullName: 'man technologist',
    isBase: true,
    baseHex: '1f468-200d-1f4bb',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f4bb',
      '1f3fc': '1f468-1f3fc-200d-1f4bb',
      '1f3fd': '1f468-1f3fd-200d-1f4bb',
      '1f3fe': '1f468-1f3fe-200d-1f4bb',
      '1f3ff': '1f468-1f3ff-200d-1f4bb',
    },
  },
  '1f468-1f3fb-200d-1f4bb': {
    hexCodePoints: '1f468-1f3fb-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍💻',
    version: 'E4.0',
    baseName: 'man technologist',
    fullName: 'man technologist: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bb',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f4bb': {
    hexCodePoints: '1f468-1f3fc-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍💻',
    version: 'E4.0',
    baseName: 'man technologist',
    fullName: 'man technologist: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bb',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f4bb': {
    hexCodePoints: '1f468-1f3fd-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍💻',
    version: 'E4.0',
    baseName: 'man technologist',
    fullName: 'man technologist: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bb',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f4bb': {
    hexCodePoints: '1f468-1f3fe-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍💻',
    version: 'E4.0',
    baseName: 'man technologist',
    fullName: 'man technologist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bb',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f4bb': {
    hexCodePoints: '1f468-1f3ff-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍💻',
    version: 'E4.0',
    baseName: 'man technologist',
    fullName: 'man technologist: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f4bb',
    modifiers: null,
  },
  '1f469-200d-1f4bb': {
    hexCodePoints: '1f469-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍💻',
    version: 'E4.0',
    baseName: 'woman technologist',
    fullName: 'woman technologist',
    isBase: true,
    baseHex: '1f469-200d-1f4bb',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f4bb',
      '1f3fc': '1f469-1f3fc-200d-1f4bb',
      '1f3fd': '1f469-1f3fd-200d-1f4bb',
      '1f3fe': '1f469-1f3fe-200d-1f4bb',
      '1f3ff': '1f469-1f3ff-200d-1f4bb',
    },
  },
  '1f469-1f3fb-200d-1f4bb': {
    hexCodePoints: '1f469-1f3fb-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍💻',
    version: 'E4.0',
    baseName: 'woman technologist',
    fullName: 'woman technologist: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bb',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f4bb': {
    hexCodePoints: '1f469-1f3fc-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍💻',
    version: 'E4.0',
    baseName: 'woman technologist',
    fullName: 'woman technologist: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bb',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f4bb': {
    hexCodePoints: '1f469-1f3fd-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍💻',
    version: 'E4.0',
    baseName: 'woman technologist',
    fullName: 'woman technologist: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bb',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f4bb': {
    hexCodePoints: '1f469-1f3fe-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍💻',
    version: 'E4.0',
    baseName: 'woman technologist',
    fullName: 'woman technologist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bb',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f4bb': {
    hexCodePoints: '1f469-1f3ff-200d-1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍💻',
    version: 'E4.0',
    baseName: 'woman technologist',
    fullName: 'woman technologist: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f4bb',
    modifiers: null,
  },
  '1f9d1-200d-1f3a4': {
    hexCodePoints: '1f9d1-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🎤',
    version: 'E12.1',
    baseName: 'singer',
    fullName: 'singer',
    isBase: true,
    baseHex: '1f9d1-200d-1f3a4',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f3a4',
      '1f3fc': '1f9d1-1f3fc-200d-1f3a4',
      '1f3fd': '1f9d1-1f3fd-200d-1f3a4',
      '1f3fe': '1f9d1-1f3fe-200d-1f3a4',
      '1f3ff': '1f9d1-1f3ff-200d-1f3a4',
    },
  },
  '1f9d1-1f3fb-200d-1f3a4': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🎤',
    version: 'E12.1',
    baseName: 'singer',
    fullName: 'singer: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a4',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f3a4': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🎤',
    version: 'E12.1',
    baseName: 'singer',
    fullName: 'singer: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a4',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f3a4': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🎤',
    version: 'E12.1',
    baseName: 'singer',
    fullName: 'singer: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a4',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f3a4': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🎤',
    version: 'E12.1',
    baseName: 'singer',
    fullName: 'singer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a4',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f3a4': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🎤',
    version: 'E12.1',
    baseName: 'singer',
    fullName: 'singer: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a4',
    modifiers: null,
  },
  '1f468-200d-1f3a4': {
    hexCodePoints: '1f468-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🎤',
    version: 'E4.0',
    baseName: 'man singer',
    fullName: 'man singer',
    isBase: true,
    baseHex: '1f468-200d-1f3a4',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f3a4',
      '1f3fc': '1f468-1f3fc-200d-1f3a4',
      '1f3fd': '1f468-1f3fd-200d-1f3a4',
      '1f3fe': '1f468-1f3fe-200d-1f3a4',
      '1f3ff': '1f468-1f3ff-200d-1f3a4',
    },
  },
  '1f468-1f3fb-200d-1f3a4': {
    hexCodePoints: '1f468-1f3fb-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🎤',
    version: 'E4.0',
    baseName: 'man singer',
    fullName: 'man singer: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a4',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f3a4': {
    hexCodePoints: '1f468-1f3fc-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🎤',
    version: 'E4.0',
    baseName: 'man singer',
    fullName: 'man singer: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a4',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f3a4': {
    hexCodePoints: '1f468-1f3fd-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🎤',
    version: 'E4.0',
    baseName: 'man singer',
    fullName: 'man singer: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a4',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f3a4': {
    hexCodePoints: '1f468-1f3fe-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🎤',
    version: 'E4.0',
    baseName: 'man singer',
    fullName: 'man singer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a4',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f3a4': {
    hexCodePoints: '1f468-1f3ff-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🎤',
    version: 'E4.0',
    baseName: 'man singer',
    fullName: 'man singer: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a4',
    modifiers: null,
  },
  '1f469-200d-1f3a4': {
    hexCodePoints: '1f469-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🎤',
    version: 'E4.0',
    baseName: 'woman singer',
    fullName: 'woman singer',
    isBase: true,
    baseHex: '1f469-200d-1f3a4',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f3a4',
      '1f3fc': '1f469-1f3fc-200d-1f3a4',
      '1f3fd': '1f469-1f3fd-200d-1f3a4',
      '1f3fe': '1f469-1f3fe-200d-1f3a4',
      '1f3ff': '1f469-1f3ff-200d-1f3a4',
    },
  },
  '1f469-1f3fb-200d-1f3a4': {
    hexCodePoints: '1f469-1f3fb-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🎤',
    version: 'E4.0',
    baseName: 'woman singer',
    fullName: 'woman singer: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a4',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f3a4': {
    hexCodePoints: '1f469-1f3fc-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🎤',
    version: 'E4.0',
    baseName: 'woman singer',
    fullName: 'woman singer: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a4',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f3a4': {
    hexCodePoints: '1f469-1f3fd-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🎤',
    version: 'E4.0',
    baseName: 'woman singer',
    fullName: 'woman singer: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a4',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f3a4': {
    hexCodePoints: '1f469-1f3fe-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🎤',
    version: 'E4.0',
    baseName: 'woman singer',
    fullName: 'woman singer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a4',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f3a4': {
    hexCodePoints: '1f469-1f3ff-200d-1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🎤',
    version: 'E4.0',
    baseName: 'woman singer',
    fullName: 'woman singer: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a4',
    modifiers: null,
  },
  '1f9d1-200d-1f3a8': {
    hexCodePoints: '1f9d1-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🎨',
    version: 'E12.1',
    baseName: 'artist',
    fullName: 'artist',
    isBase: true,
    baseHex: '1f9d1-200d-1f3a8',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f3a8',
      '1f3fc': '1f9d1-1f3fc-200d-1f3a8',
      '1f3fd': '1f9d1-1f3fd-200d-1f3a8',
      '1f3fe': '1f9d1-1f3fe-200d-1f3a8',
      '1f3ff': '1f9d1-1f3ff-200d-1f3a8',
    },
  },
  '1f9d1-1f3fb-200d-1f3a8': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🎨',
    version: 'E12.1',
    baseName: 'artist',
    fullName: 'artist: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a8',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f3a8': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🎨',
    version: 'E12.1',
    baseName: 'artist',
    fullName: 'artist: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a8',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f3a8': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🎨',
    version: 'E12.1',
    baseName: 'artist',
    fullName: 'artist: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a8',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f3a8': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🎨',
    version: 'E12.1',
    baseName: 'artist',
    fullName: 'artist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a8',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f3a8': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🎨',
    version: 'E12.1',
    baseName: 'artist',
    fullName: 'artist: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f3a8',
    modifiers: null,
  },
  '1f468-200d-1f3a8': {
    hexCodePoints: '1f468-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🎨',
    version: 'E4.0',
    baseName: 'man artist',
    fullName: 'man artist',
    isBase: true,
    baseHex: '1f468-200d-1f3a8',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f3a8',
      '1f3fc': '1f468-1f3fc-200d-1f3a8',
      '1f3fd': '1f468-1f3fd-200d-1f3a8',
      '1f3fe': '1f468-1f3fe-200d-1f3a8',
      '1f3ff': '1f468-1f3ff-200d-1f3a8',
    },
  },
  '1f468-1f3fb-200d-1f3a8': {
    hexCodePoints: '1f468-1f3fb-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🎨',
    version: 'E4.0',
    baseName: 'man artist',
    fullName: 'man artist: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a8',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f3a8': {
    hexCodePoints: '1f468-1f3fc-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🎨',
    version: 'E4.0',
    baseName: 'man artist',
    fullName: 'man artist: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a8',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f3a8': {
    hexCodePoints: '1f468-1f3fd-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🎨',
    version: 'E4.0',
    baseName: 'man artist',
    fullName: 'man artist: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a8',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f3a8': {
    hexCodePoints: '1f468-1f3fe-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🎨',
    version: 'E4.0',
    baseName: 'man artist',
    fullName: 'man artist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a8',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f3a8': {
    hexCodePoints: '1f468-1f3ff-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🎨',
    version: 'E4.0',
    baseName: 'man artist',
    fullName: 'man artist: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f3a8',
    modifiers: null,
  },
  '1f469-200d-1f3a8': {
    hexCodePoints: '1f469-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🎨',
    version: 'E4.0',
    baseName: 'woman artist',
    fullName: 'woman artist',
    isBase: true,
    baseHex: '1f469-200d-1f3a8',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f3a8',
      '1f3fc': '1f469-1f3fc-200d-1f3a8',
      '1f3fd': '1f469-1f3fd-200d-1f3a8',
      '1f3fe': '1f469-1f3fe-200d-1f3a8',
      '1f3ff': '1f469-1f3ff-200d-1f3a8',
    },
  },
  '1f469-1f3fb-200d-1f3a8': {
    hexCodePoints: '1f469-1f3fb-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🎨',
    version: 'E4.0',
    baseName: 'woman artist',
    fullName: 'woman artist: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a8',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f3a8': {
    hexCodePoints: '1f469-1f3fc-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🎨',
    version: 'E4.0',
    baseName: 'woman artist',
    fullName: 'woman artist: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a8',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f3a8': {
    hexCodePoints: '1f469-1f3fd-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🎨',
    version: 'E4.0',
    baseName: 'woman artist',
    fullName: 'woman artist: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a8',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f3a8': {
    hexCodePoints: '1f469-1f3fe-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🎨',
    version: 'E4.0',
    baseName: 'woman artist',
    fullName: 'woman artist: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a8',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f3a8': {
    hexCodePoints: '1f469-1f3ff-200d-1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🎨',
    version: 'E4.0',
    baseName: 'woman artist',
    fullName: 'woman artist: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f3a8',
    modifiers: null,
  },
  '1f9d1-200d-2708-fe0f': {
    hexCodePoints: '1f9d1-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍✈️',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot',
    isBase: true,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-2708-fe0f',
      '1f3fc': '1f9d1-1f3fc-200d-2708-fe0f',
      '1f3fd': '1f9d1-1f3fd-200d-2708-fe0f',
      '1f3fe': '1f9d1-1f3fe-200d-2708-fe0f',
      '1f3ff': '1f9d1-1f3ff-200d-2708-fe0f',
    },
  },
  '1f9d1-200d-2708': {
    hexCodePoints: '1f9d1-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑‍✈',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2708-fe0f': {
    hexCodePoints: '1f9d1-1f3fb-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍✈️',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2708': {
    hexCodePoints: '1f9d1-1f3fb-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍✈',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2708-fe0f': {
    hexCodePoints: '1f9d1-1f3fc-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍✈️',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2708': {
    hexCodePoints: '1f9d1-1f3fc-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍✈',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2708-fe0f': {
    hexCodePoints: '1f9d1-1f3fd-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍✈️',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2708': {
    hexCodePoints: '1f9d1-1f3fd-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍✈',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2708-fe0f': {
    hexCodePoints: '1f9d1-1f3fe-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍✈️',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2708': {
    hexCodePoints: '1f9d1-1f3fe-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍✈',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2708-fe0f': {
    hexCodePoints: '1f9d1-1f3ff-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍✈️',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2708': {
    hexCodePoints: '1f9d1-1f3ff-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍✈',
    version: 'E12.1',
    baseName: 'pilot',
    fullName: 'pilot: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-200d-2708-fe0f': {
    hexCodePoints: '1f468-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍✈️',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot',
    isBase: true,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-2708-fe0f',
      '1f3fc': '1f468-1f3fc-200d-2708-fe0f',
      '1f3fd': '1f468-1f3fd-200d-2708-fe0f',
      '1f3fe': '1f468-1f3fe-200d-2708-fe0f',
      '1f3ff': '1f468-1f3ff-200d-2708-fe0f',
    },
  },
  '1f468-200d-2708': {
    hexCodePoints: '1f468-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍✈',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2708-fe0f': {
    hexCodePoints: '1f468-1f3fb-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍✈️',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2708': {
    hexCodePoints: '1f468-1f3fb-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍✈',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2708-fe0f': {
    hexCodePoints: '1f468-1f3fc-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍✈️',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2708': {
    hexCodePoints: '1f468-1f3fc-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍✈',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2708-fe0f': {
    hexCodePoints: '1f468-1f3fd-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍✈️',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2708': {
    hexCodePoints: '1f468-1f3fd-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍✈',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2708-fe0f': {
    hexCodePoints: '1f468-1f3fe-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍✈️',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2708': {
    hexCodePoints: '1f468-1f3fe-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍✈',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2708-fe0f': {
    hexCodePoints: '1f468-1f3ff-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍✈️',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2708': {
    hexCodePoints: '1f468-1f3ff-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍✈',
    version: 'E4.0',
    baseName: 'man pilot',
    fullName: 'man pilot: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-200d-2708-fe0f': {
    hexCodePoints: '1f469-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍✈️',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot',
    isBase: true,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-2708-fe0f',
      '1f3fc': '1f469-1f3fc-200d-2708-fe0f',
      '1f3fd': '1f469-1f3fd-200d-2708-fe0f',
      '1f3fe': '1f469-1f3fe-200d-2708-fe0f',
      '1f3ff': '1f469-1f3ff-200d-2708-fe0f',
    },
  },
  '1f469-200d-2708': {
    hexCodePoints: '1f469-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍✈',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2708-fe0f': {
    hexCodePoints: '1f469-1f3fb-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍✈️',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2708': {
    hexCodePoints: '1f469-1f3fb-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍✈',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2708-fe0f': {
    hexCodePoints: '1f469-1f3fc-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍✈️',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2708': {
    hexCodePoints: '1f469-1f3fc-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍✈',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2708-fe0f': {
    hexCodePoints: '1f469-1f3fd-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍✈️',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2708': {
    hexCodePoints: '1f469-1f3fd-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍✈',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2708-fe0f': {
    hexCodePoints: '1f469-1f3fe-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍✈️',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2708': {
    hexCodePoints: '1f469-1f3fe-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍✈',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2708-fe0f': {
    hexCodePoints: '1f469-1f3ff-200d-2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍✈️',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2708': {
    hexCodePoints: '1f469-1f3ff-200d-2708',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍✈',
    version: 'E4.0',
    baseName: 'woman pilot',
    fullName: 'woman pilot: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2708-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-1f680': {
    hexCodePoints: '1f9d1-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🚀',
    version: 'E12.1',
    baseName: 'astronaut',
    fullName: 'astronaut',
    isBase: true,
    baseHex: '1f9d1-200d-1f680',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f680',
      '1f3fc': '1f9d1-1f3fc-200d-1f680',
      '1f3fd': '1f9d1-1f3fd-200d-1f680',
      '1f3fe': '1f9d1-1f3fe-200d-1f680',
      '1f3ff': '1f9d1-1f3ff-200d-1f680',
    },
  },
  '1f9d1-1f3fb-200d-1f680': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🚀',
    version: 'E12.1',
    baseName: 'astronaut',
    fullName: 'astronaut: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f680',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f680': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🚀',
    version: 'E12.1',
    baseName: 'astronaut',
    fullName: 'astronaut: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f680',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f680': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🚀',
    version: 'E12.1',
    baseName: 'astronaut',
    fullName: 'astronaut: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f680',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f680': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🚀',
    version: 'E12.1',
    baseName: 'astronaut',
    fullName: 'astronaut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f680',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f680': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🚀',
    version: 'E12.1',
    baseName: 'astronaut',
    fullName: 'astronaut: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f680',
    modifiers: null,
  },
  '1f468-200d-1f680': {
    hexCodePoints: '1f468-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🚀',
    version: 'E4.0',
    baseName: 'man astronaut',
    fullName: 'man astronaut',
    isBase: true,
    baseHex: '1f468-200d-1f680',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f680',
      '1f3fc': '1f468-1f3fc-200d-1f680',
      '1f3fd': '1f468-1f3fd-200d-1f680',
      '1f3fe': '1f468-1f3fe-200d-1f680',
      '1f3ff': '1f468-1f3ff-200d-1f680',
    },
  },
  '1f468-1f3fb-200d-1f680': {
    hexCodePoints: '1f468-1f3fb-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🚀',
    version: 'E4.0',
    baseName: 'man astronaut',
    fullName: 'man astronaut: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f680',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f680': {
    hexCodePoints: '1f468-1f3fc-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🚀',
    version: 'E4.0',
    baseName: 'man astronaut',
    fullName: 'man astronaut: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f680',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f680': {
    hexCodePoints: '1f468-1f3fd-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🚀',
    version: 'E4.0',
    baseName: 'man astronaut',
    fullName: 'man astronaut: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f680',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f680': {
    hexCodePoints: '1f468-1f3fe-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🚀',
    version: 'E4.0',
    baseName: 'man astronaut',
    fullName: 'man astronaut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f680',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f680': {
    hexCodePoints: '1f468-1f3ff-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🚀',
    version: 'E4.0',
    baseName: 'man astronaut',
    fullName: 'man astronaut: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f680',
    modifiers: null,
  },
  '1f469-200d-1f680': {
    hexCodePoints: '1f469-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🚀',
    version: 'E4.0',
    baseName: 'woman astronaut',
    fullName: 'woman astronaut',
    isBase: true,
    baseHex: '1f469-200d-1f680',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f680',
      '1f3fc': '1f469-1f3fc-200d-1f680',
      '1f3fd': '1f469-1f3fd-200d-1f680',
      '1f3fe': '1f469-1f3fe-200d-1f680',
      '1f3ff': '1f469-1f3ff-200d-1f680',
    },
  },
  '1f469-1f3fb-200d-1f680': {
    hexCodePoints: '1f469-1f3fb-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🚀',
    version: 'E4.0',
    baseName: 'woman astronaut',
    fullName: 'woman astronaut: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f680',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f680': {
    hexCodePoints: '1f469-1f3fc-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🚀',
    version: 'E4.0',
    baseName: 'woman astronaut',
    fullName: 'woman astronaut: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f680',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f680': {
    hexCodePoints: '1f469-1f3fd-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🚀',
    version: 'E4.0',
    baseName: 'woman astronaut',
    fullName: 'woman astronaut: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f680',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f680': {
    hexCodePoints: '1f469-1f3fe-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🚀',
    version: 'E4.0',
    baseName: 'woman astronaut',
    fullName: 'woman astronaut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f680',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f680': {
    hexCodePoints: '1f469-1f3ff-200d-1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🚀',
    version: 'E4.0',
    baseName: 'woman astronaut',
    fullName: 'woman astronaut: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f680',
    modifiers: null,
  },
  '1f9d1-200d-1f692': {
    hexCodePoints: '1f9d1-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🚒',
    version: 'E12.1',
    baseName: 'firefighter',
    fullName: 'firefighter',
    isBase: true,
    baseHex: '1f9d1-200d-1f692',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f692',
      '1f3fc': '1f9d1-1f3fc-200d-1f692',
      '1f3fd': '1f9d1-1f3fd-200d-1f692',
      '1f3fe': '1f9d1-1f3fe-200d-1f692',
      '1f3ff': '1f9d1-1f3ff-200d-1f692',
    },
  },
  '1f9d1-1f3fb-200d-1f692': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🚒',
    version: 'E12.1',
    baseName: 'firefighter',
    fullName: 'firefighter: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f692',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f692': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🚒',
    version: 'E12.1',
    baseName: 'firefighter',
    fullName: 'firefighter: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f692',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f692': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🚒',
    version: 'E12.1',
    baseName: 'firefighter',
    fullName: 'firefighter: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f692',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f692': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🚒',
    version: 'E12.1',
    baseName: 'firefighter',
    fullName: 'firefighter: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f692',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f692': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🚒',
    version: 'E12.1',
    baseName: 'firefighter',
    fullName: 'firefighter: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f692',
    modifiers: null,
  },
  '1f468-200d-1f692': {
    hexCodePoints: '1f468-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🚒',
    version: 'E4.0',
    baseName: 'man firefighter',
    fullName: 'man firefighter',
    isBase: true,
    baseHex: '1f468-200d-1f692',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f692',
      '1f3fc': '1f468-1f3fc-200d-1f692',
      '1f3fd': '1f468-1f3fd-200d-1f692',
      '1f3fe': '1f468-1f3fe-200d-1f692',
      '1f3ff': '1f468-1f3ff-200d-1f692',
    },
  },
  '1f468-1f3fb-200d-1f692': {
    hexCodePoints: '1f468-1f3fb-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🚒',
    version: 'E4.0',
    baseName: 'man firefighter',
    fullName: 'man firefighter: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f692',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f692': {
    hexCodePoints: '1f468-1f3fc-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🚒',
    version: 'E4.0',
    baseName: 'man firefighter',
    fullName: 'man firefighter: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f692',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f692': {
    hexCodePoints: '1f468-1f3fd-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🚒',
    version: 'E4.0',
    baseName: 'man firefighter',
    fullName: 'man firefighter: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f692',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f692': {
    hexCodePoints: '1f468-1f3fe-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🚒',
    version: 'E4.0',
    baseName: 'man firefighter',
    fullName: 'man firefighter: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f692',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f692': {
    hexCodePoints: '1f468-1f3ff-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🚒',
    version: 'E4.0',
    baseName: 'man firefighter',
    fullName: 'man firefighter: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f692',
    modifiers: null,
  },
  '1f469-200d-1f692': {
    hexCodePoints: '1f469-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🚒',
    version: 'E4.0',
    baseName: 'woman firefighter',
    fullName: 'woman firefighter',
    isBase: true,
    baseHex: '1f469-200d-1f692',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f692',
      '1f3fc': '1f469-1f3fc-200d-1f692',
      '1f3fd': '1f469-1f3fd-200d-1f692',
      '1f3fe': '1f469-1f3fe-200d-1f692',
      '1f3ff': '1f469-1f3ff-200d-1f692',
    },
  },
  '1f469-1f3fb-200d-1f692': {
    hexCodePoints: '1f469-1f3fb-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🚒',
    version: 'E4.0',
    baseName: 'woman firefighter',
    fullName: 'woman firefighter: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f692',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f692': {
    hexCodePoints: '1f469-1f3fc-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🚒',
    version: 'E4.0',
    baseName: 'woman firefighter',
    fullName: 'woman firefighter: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f692',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f692': {
    hexCodePoints: '1f469-1f3fd-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🚒',
    version: 'E4.0',
    baseName: 'woman firefighter',
    fullName: 'woman firefighter: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f692',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f692': {
    hexCodePoints: '1f469-1f3fe-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🚒',
    version: 'E4.0',
    baseName: 'woman firefighter',
    fullName: 'woman firefighter: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f692',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f692': {
    hexCodePoints: '1f469-1f3ff-200d-1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🚒',
    version: 'E4.0',
    baseName: 'woman firefighter',
    fullName: 'woman firefighter: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f692',
    modifiers: null,
  },
  '1f46e': {
    hexCodePoints: '1f46e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮',
    version: 'E0.6',
    baseName: 'police officer',
    fullName: 'police officer',
    isBase: true,
    baseHex: '1f46e',
    modifiers: {
      '1f3fb': '1f46e-1f3fb',
      '1f3fc': '1f46e-1f3fc',
      '1f3fd': '1f46e-1f3fd',
      '1f3fe': '1f46e-1f3fe',
      '1f3ff': '1f46e-1f3ff',
    },
  },
  '1f46e-1f3fb': {
    hexCodePoints: '1f46e-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏻',
    version: 'E1.0',
    baseName: 'police officer',
    fullName: 'police officer: light skin tone',
    isBase: false,
    baseHex: '1f46e',
    modifiers: null,
  },
  '1f46e-1f3fc': {
    hexCodePoints: '1f46e-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏼',
    version: 'E1.0',
    baseName: 'police officer',
    fullName: 'police officer: medium-light skin tone',
    isBase: false,
    baseHex: '1f46e',
    modifiers: null,
  },
  '1f46e-1f3fd': {
    hexCodePoints: '1f46e-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏽',
    version: 'E1.0',
    baseName: 'police officer',
    fullName: 'police officer: medium skin tone',
    isBase: false,
    baseHex: '1f46e',
    modifiers: null,
  },
  '1f46e-1f3fe': {
    hexCodePoints: '1f46e-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏾',
    version: 'E1.0',
    baseName: 'police officer',
    fullName: 'police officer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46e',
    modifiers: null,
  },
  '1f46e-1f3ff': {
    hexCodePoints: '1f46e-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏿',
    version: 'E1.0',
    baseName: 'police officer',
    fullName: 'police officer: dark skin tone',
    isBase: false,
    baseHex: '1f46e',
    modifiers: null,
  },
  '1f46e-200d-2642-fe0f': {
    hexCodePoints: '1f46e-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮‍♂️',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer',
    isBase: true,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f46e-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f46e-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f46e-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f46e-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f46e-1f3ff-200d-2642-fe0f',
    },
  },
  '1f46e-200d-2642': {
    hexCodePoints: '1f46e-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮‍♂',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f46e-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏻‍♂️',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fb-200d-2642': {
    hexCodePoints: '1f46e-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏻‍♂',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f46e-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏼‍♂️',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: medium-light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fc-200d-2642': {
    hexCodePoints: '1f46e-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏼‍♂',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: medium-light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f46e-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏽‍♂️',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: medium skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fd-200d-2642': {
    hexCodePoints: '1f46e-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏽‍♂',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: medium skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f46e-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏾‍♂️',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fe-200d-2642': {
    hexCodePoints: '1f46e-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏾‍♂',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f46e-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏿‍♂️',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-1f3ff-200d-2642': {
    hexCodePoints: '1f46e-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏿‍♂',
    version: 'E4.0',
    baseName: 'man police officer',
    fullName: 'man police officer: dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46e-200d-2640-fe0f': {
    hexCodePoints: '1f46e-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮‍♀️',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer',
    isBase: true,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f46e-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f46e-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f46e-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f46e-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f46e-1f3ff-200d-2640-fe0f',
    },
  },
  '1f46e-200d-2640': {
    hexCodePoints: '1f46e-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮‍♀',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f46e-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fb-200d-2640': {
    hexCodePoints: '1f46e-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏻‍♀',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f46e-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: medium-light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fc-200d-2640': {
    hexCodePoints: '1f46e-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏼‍♀',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: medium-light skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f46e-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: medium skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fd-200d-2640': {
    hexCodePoints: '1f46e-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏽‍♀',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: medium skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f46e-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3fe-200d-2640': {
    hexCodePoints: '1f46e-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏾‍♀',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f46e-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👮🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46e-1f3ff-200d-2640': {
    hexCodePoints: '1f46e-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👮🏿‍♀',
    version: 'E4.0',
    baseName: 'woman police officer',
    fullName: 'woman police officer: dark skin tone',
    isBase: false,
    baseHex: '1f46e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-fe0f': {
    hexCodePoints: '1f575-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵️',
    version: 'E0.7',
    baseName: 'detective',
    fullName: 'detective',
    isBase: true,
    baseHex: '1f575-fe0f',
    modifiers: {
      '1f3fb': '1f575-1f3fb',
      '1f3fc': '1f575-1f3fc',
      '1f3fd': '1f575-1f3fd',
      '1f3fe': '1f575-1f3fe',
      '1f3ff': '1f575-1f3ff',
    },
  },
  '1f575': {
    hexCodePoints: '1f575',
    qualifiedStatus: 'unqualified',
    emoji: '🕵',
    version: 'E0.7',
    baseName: 'detective',
    fullName: 'detective',
    isBase: false,
    baseHex: '1f575-fe0f',
    modifiers: null,
  },
  '1f575-1f3fb': {
    hexCodePoints: '1f575-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏻',
    version: 'E2.0',
    baseName: 'detective',
    fullName: 'detective: light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f',
    modifiers: null,
  },
  '1f575-1f3fc': {
    hexCodePoints: '1f575-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏼',
    version: 'E2.0',
    baseName: 'detective',
    fullName: 'detective: medium-light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f',
    modifiers: null,
  },
  '1f575-1f3fd': {
    hexCodePoints: '1f575-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏽',
    version: 'E2.0',
    baseName: 'detective',
    fullName: 'detective: medium skin tone',
    isBase: false,
    baseHex: '1f575-fe0f',
    modifiers: null,
  },
  '1f575-1f3fe': {
    hexCodePoints: '1f575-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏾',
    version: 'E2.0',
    baseName: 'detective',
    fullName: 'detective: medium-dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f',
    modifiers: null,
  },
  '1f575-1f3ff': {
    hexCodePoints: '1f575-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏿',
    version: 'E2.0',
    baseName: 'detective',
    fullName: 'detective: dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f',
    modifiers: null,
  },
  '1f575-fe0f-200d-2642-fe0f': {
    hexCodePoints: '1f575-fe0f-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵️‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective',
    isBase: true,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f575-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f575-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f575-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f575-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f575-1f3ff-200d-2642-fe0f',
    },
  },
  '1f575-200d-2642-fe0f': {
    hexCodePoints: '1f575-200d-2642-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🕵‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-fe0f-200d-2642': {
    hexCodePoints: '1f575-fe0f-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵️‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-200d-2642': {
    hexCodePoints: '1f575-200d-2642',
    qualifiedStatus: 'unqualified',
    emoji: '🕵‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f575-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏻‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fb-200d-2642': {
    hexCodePoints: '1f575-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏻‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f575-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏼‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: medium-light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fc-200d-2642': {
    hexCodePoints: '1f575-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏼‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: medium-light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f575-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏽‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: medium skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fd-200d-2642': {
    hexCodePoints: '1f575-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏽‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: medium skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f575-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏾‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: medium-dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3fe-200d-2642': {
    hexCodePoints: '1f575-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏾‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: medium-dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f575-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏿‍♂️',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-1f3ff-200d-2642': {
    hexCodePoints: '1f575-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏿‍♂',
    version: 'E4.0',
    baseName: 'man detective',
    fullName: 'man detective: dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f575-fe0f-200d-2640-fe0f': {
    hexCodePoints: '1f575-fe0f-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵️‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective',
    isBase: true,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f575-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f575-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f575-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f575-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f575-1f3ff-200d-2640-fe0f',
    },
  },
  '1f575-200d-2640-fe0f': {
    hexCodePoints: '1f575-200d-2640-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🕵‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-fe0f-200d-2640': {
    hexCodePoints: '1f575-fe0f-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵️‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-200d-2640': {
    hexCodePoints: '1f575-200d-2640',
    qualifiedStatus: 'unqualified',
    emoji: '🕵‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f575-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fb-200d-2640': {
    hexCodePoints: '1f575-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏻‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f575-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: medium-light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fc-200d-2640': {
    hexCodePoints: '1f575-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏼‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: medium-light skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f575-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: medium skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fd-200d-2640': {
    hexCodePoints: '1f575-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏽‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: medium skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f575-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: medium-dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3fe-200d-2640': {
    hexCodePoints: '1f575-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏾‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: medium-dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f575-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕵🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f575-1f3ff-200d-2640': {
    hexCodePoints: '1f575-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🕵🏿‍♀',
    version: 'E4.0',
    baseName: 'woman detective',
    fullName: 'woman detective: dark skin tone',
    isBase: false,
    baseHex: '1f575-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482': {
    hexCodePoints: '1f482',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂',
    version: 'E0.6',
    baseName: 'guard',
    fullName: 'guard',
    isBase: true,
    baseHex: '1f482',
    modifiers: {
      '1f3fb': '1f482-1f3fb',
      '1f3fc': '1f482-1f3fc',
      '1f3fd': '1f482-1f3fd',
      '1f3fe': '1f482-1f3fe',
      '1f3ff': '1f482-1f3ff',
    },
  },
  '1f482-1f3fb': {
    hexCodePoints: '1f482-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏻',
    version: 'E1.0',
    baseName: 'guard',
    fullName: 'guard: light skin tone',
    isBase: false,
    baseHex: '1f482',
    modifiers: null,
  },
  '1f482-1f3fc': {
    hexCodePoints: '1f482-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏼',
    version: 'E1.0',
    baseName: 'guard',
    fullName: 'guard: medium-light skin tone',
    isBase: false,
    baseHex: '1f482',
    modifiers: null,
  },
  '1f482-1f3fd': {
    hexCodePoints: '1f482-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏽',
    version: 'E1.0',
    baseName: 'guard',
    fullName: 'guard: medium skin tone',
    isBase: false,
    baseHex: '1f482',
    modifiers: null,
  },
  '1f482-1f3fe': {
    hexCodePoints: '1f482-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏾',
    version: 'E1.0',
    baseName: 'guard',
    fullName: 'guard: medium-dark skin tone',
    isBase: false,
    baseHex: '1f482',
    modifiers: null,
  },
  '1f482-1f3ff': {
    hexCodePoints: '1f482-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏿',
    version: 'E1.0',
    baseName: 'guard',
    fullName: 'guard: dark skin tone',
    isBase: false,
    baseHex: '1f482',
    modifiers: null,
  },
  '1f482-200d-2642-fe0f': {
    hexCodePoints: '1f482-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂‍♂️',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard',
    isBase: true,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f482-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f482-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f482-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f482-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f482-1f3ff-200d-2642-fe0f',
    },
  },
  '1f482-200d-2642': {
    hexCodePoints: '1f482-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂‍♂',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f482-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏻‍♂️',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fb-200d-2642': {
    hexCodePoints: '1f482-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏻‍♂',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f482-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏼‍♂️',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: medium-light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fc-200d-2642': {
    hexCodePoints: '1f482-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏼‍♂',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: medium-light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f482-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏽‍♂️',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: medium skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fd-200d-2642': {
    hexCodePoints: '1f482-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏽‍♂',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: medium skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f482-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏾‍♂️',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: medium-dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3fe-200d-2642': {
    hexCodePoints: '1f482-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏾‍♂',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: medium-dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f482-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏿‍♂️',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-1f3ff-200d-2642': {
    hexCodePoints: '1f482-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏿‍♂',
    version: 'E4.0',
    baseName: 'man guard',
    fullName: 'man guard: dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2642-fe0f',
    modifiers: null,
  },
  '1f482-200d-2640-fe0f': {
    hexCodePoints: '1f482-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂‍♀️',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard',
    isBase: true,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f482-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f482-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f482-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f482-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f482-1f3ff-200d-2640-fe0f',
    },
  },
  '1f482-200d-2640': {
    hexCodePoints: '1f482-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂‍♀',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f482-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fb-200d-2640': {
    hexCodePoints: '1f482-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏻‍♀',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f482-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: medium-light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fc-200d-2640': {
    hexCodePoints: '1f482-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏼‍♀',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: medium-light skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f482-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: medium skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fd-200d-2640': {
    hexCodePoints: '1f482-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏽‍♀',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: medium skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f482-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: medium-dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3fe-200d-2640': {
    hexCodePoints: '1f482-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏾‍♀',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: medium-dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f482-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💂🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f482-1f3ff-200d-2640': {
    hexCodePoints: '1f482-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💂🏿‍♀',
    version: 'E4.0',
    baseName: 'woman guard',
    fullName: 'woman guard: dark skin tone',
    isBase: false,
    baseHex: '1f482-200d-2640-fe0f',
    modifiers: null,
  },
  '1f977': {
    hexCodePoints: '1f977',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥷',
    version: 'E13.0',
    baseName: 'ninja',
    fullName: 'ninja',
    isBase: true,
    baseHex: '1f977',
    modifiers: {
      '1f3fb': '1f977-1f3fb',
      '1f3fc': '1f977-1f3fc',
      '1f3fd': '1f977-1f3fd',
      '1f3fe': '1f977-1f3fe',
      '1f3ff': '1f977-1f3ff',
    },
  },
  '1f977-1f3fb': {
    hexCodePoints: '1f977-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥷🏻',
    version: 'E13.0',
    baseName: 'ninja',
    fullName: 'ninja: light skin tone',
    isBase: false,
    baseHex: '1f977',
    modifiers: null,
  },
  '1f977-1f3fc': {
    hexCodePoints: '1f977-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥷🏼',
    version: 'E13.0',
    baseName: 'ninja',
    fullName: 'ninja: medium-light skin tone',
    isBase: false,
    baseHex: '1f977',
    modifiers: null,
  },
  '1f977-1f3fd': {
    hexCodePoints: '1f977-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥷🏽',
    version: 'E13.0',
    baseName: 'ninja',
    fullName: 'ninja: medium skin tone',
    isBase: false,
    baseHex: '1f977',
    modifiers: null,
  },
  '1f977-1f3fe': {
    hexCodePoints: '1f977-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥷🏾',
    version: 'E13.0',
    baseName: 'ninja',
    fullName: 'ninja: medium-dark skin tone',
    isBase: false,
    baseHex: '1f977',
    modifiers: null,
  },
  '1f977-1f3ff': {
    hexCodePoints: '1f977-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥷🏿',
    version: 'E13.0',
    baseName: 'ninja',
    fullName: 'ninja: dark skin tone',
    isBase: false,
    baseHex: '1f977',
    modifiers: null,
  },
  '1f477': {
    hexCodePoints: '1f477',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷',
    version: 'E0.6',
    baseName: 'construction worker',
    fullName: 'construction worker',
    isBase: true,
    baseHex: '1f477',
    modifiers: {
      '1f3fb': '1f477-1f3fb',
      '1f3fc': '1f477-1f3fc',
      '1f3fd': '1f477-1f3fd',
      '1f3fe': '1f477-1f3fe',
      '1f3ff': '1f477-1f3ff',
    },
  },
  '1f477-1f3fb': {
    hexCodePoints: '1f477-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏻',
    version: 'E1.0',
    baseName: 'construction worker',
    fullName: 'construction worker: light skin tone',
    isBase: false,
    baseHex: '1f477',
    modifiers: null,
  },
  '1f477-1f3fc': {
    hexCodePoints: '1f477-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏼',
    version: 'E1.0',
    baseName: 'construction worker',
    fullName: 'construction worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f477',
    modifiers: null,
  },
  '1f477-1f3fd': {
    hexCodePoints: '1f477-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏽',
    version: 'E1.0',
    baseName: 'construction worker',
    fullName: 'construction worker: medium skin tone',
    isBase: false,
    baseHex: '1f477',
    modifiers: null,
  },
  '1f477-1f3fe': {
    hexCodePoints: '1f477-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏾',
    version: 'E1.0',
    baseName: 'construction worker',
    fullName: 'construction worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f477',
    modifiers: null,
  },
  '1f477-1f3ff': {
    hexCodePoints: '1f477-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏿',
    version: 'E1.0',
    baseName: 'construction worker',
    fullName: 'construction worker: dark skin tone',
    isBase: false,
    baseHex: '1f477',
    modifiers: null,
  },
  '1f477-200d-2642-fe0f': {
    hexCodePoints: '1f477-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷‍♂️',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker',
    isBase: true,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f477-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f477-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f477-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f477-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f477-1f3ff-200d-2642-fe0f',
    },
  },
  '1f477-200d-2642': {
    hexCodePoints: '1f477-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷‍♂',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f477-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏻‍♂️',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fb-200d-2642': {
    hexCodePoints: '1f477-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏻‍♂',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f477-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏼‍♂️',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fc-200d-2642': {
    hexCodePoints: '1f477-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏼‍♂',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f477-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏽‍♂️',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: medium skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fd-200d-2642': {
    hexCodePoints: '1f477-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏽‍♂',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: medium skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f477-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏾‍♂️',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3fe-200d-2642': {
    hexCodePoints: '1f477-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏾‍♂',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f477-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏿‍♂️',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-1f3ff-200d-2642': {
    hexCodePoints: '1f477-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏿‍♂',
    version: 'E4.0',
    baseName: 'man construction worker',
    fullName: 'man construction worker: dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2642-fe0f',
    modifiers: null,
  },
  '1f477-200d-2640-fe0f': {
    hexCodePoints: '1f477-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷‍♀️',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker',
    isBase: true,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f477-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f477-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f477-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f477-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f477-1f3ff-200d-2640-fe0f',
    },
  },
  '1f477-200d-2640': {
    hexCodePoints: '1f477-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷‍♀',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f477-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fb-200d-2640': {
    hexCodePoints: '1f477-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏻‍♀',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f477-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fc-200d-2640': {
    hexCodePoints: '1f477-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏼‍♀',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: medium-light skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f477-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: medium skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fd-200d-2640': {
    hexCodePoints: '1f477-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏽‍♀',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: medium skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f477-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3fe-200d-2640': {
    hexCodePoints: '1f477-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏾‍♀',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: medium-dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f477-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👷🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1f477-1f3ff-200d-2640': {
    hexCodePoints: '1f477-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👷🏿‍♀',
    version: 'E4.0',
    baseName: 'woman construction worker',
    fullName: 'woman construction worker: dark skin tone',
    isBase: false,
    baseHex: '1f477-200d-2640-fe0f',
    modifiers: null,
  },
  '1fac5': {
    hexCodePoints: '1fac5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫅',
    version: 'E14.0',
    baseName: 'person with crown',
    fullName: 'person with crown',
    isBase: true,
    baseHex: '1fac5',
    modifiers: {
      '1f3fb': '1fac5-1f3fb',
      '1f3fc': '1fac5-1f3fc',
      '1f3fd': '1fac5-1f3fd',
      '1f3fe': '1fac5-1f3fe',
      '1f3ff': '1fac5-1f3ff',
    },
  },
  '1fac5-1f3fb': {
    hexCodePoints: '1fac5-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫅🏻',
    version: 'E14.0',
    baseName: 'person with crown',
    fullName: 'person with crown: light skin tone',
    isBase: false,
    baseHex: '1fac5',
    modifiers: null,
  },
  '1fac5-1f3fc': {
    hexCodePoints: '1fac5-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫅🏼',
    version: 'E14.0',
    baseName: 'person with crown',
    fullName: 'person with crown: medium-light skin tone',
    isBase: false,
    baseHex: '1fac5',
    modifiers: null,
  },
  '1fac5-1f3fd': {
    hexCodePoints: '1fac5-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫅🏽',
    version: 'E14.0',
    baseName: 'person with crown',
    fullName: 'person with crown: medium skin tone',
    isBase: false,
    baseHex: '1fac5',
    modifiers: null,
  },
  '1fac5-1f3fe': {
    hexCodePoints: '1fac5-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫅🏾',
    version: 'E14.0',
    baseName: 'person with crown',
    fullName: 'person with crown: medium-dark skin tone',
    isBase: false,
    baseHex: '1fac5',
    modifiers: null,
  },
  '1fac5-1f3ff': {
    hexCodePoints: '1fac5-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫅🏿',
    version: 'E14.0',
    baseName: 'person with crown',
    fullName: 'person with crown: dark skin tone',
    isBase: false,
    baseHex: '1fac5',
    modifiers: null,
  },
  '1f934': {
    hexCodePoints: '1f934',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤴',
    version: 'E3.0',
    baseName: 'prince',
    fullName: 'prince',
    isBase: true,
    baseHex: '1f934',
    modifiers: {
      '1f3fb': '1f934-1f3fb',
      '1f3fc': '1f934-1f3fc',
      '1f3fd': '1f934-1f3fd',
      '1f3fe': '1f934-1f3fe',
      '1f3ff': '1f934-1f3ff',
    },
  },
  '1f934-1f3fb': {
    hexCodePoints: '1f934-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤴🏻',
    version: 'E3.0',
    baseName: 'prince',
    fullName: 'prince: light skin tone',
    isBase: false,
    baseHex: '1f934',
    modifiers: null,
  },
  '1f934-1f3fc': {
    hexCodePoints: '1f934-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤴🏼',
    version: 'E3.0',
    baseName: 'prince',
    fullName: 'prince: medium-light skin tone',
    isBase: false,
    baseHex: '1f934',
    modifiers: null,
  },
  '1f934-1f3fd': {
    hexCodePoints: '1f934-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤴🏽',
    version: 'E3.0',
    baseName: 'prince',
    fullName: 'prince: medium skin tone',
    isBase: false,
    baseHex: '1f934',
    modifiers: null,
  },
  '1f934-1f3fe': {
    hexCodePoints: '1f934-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤴🏾',
    version: 'E3.0',
    baseName: 'prince',
    fullName: 'prince: medium-dark skin tone',
    isBase: false,
    baseHex: '1f934',
    modifiers: null,
  },
  '1f934-1f3ff': {
    hexCodePoints: '1f934-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤴🏿',
    version: 'E3.0',
    baseName: 'prince',
    fullName: 'prince: dark skin tone',
    isBase: false,
    baseHex: '1f934',
    modifiers: null,
  },
  '1f478': {
    hexCodePoints: '1f478',
    qualifiedStatus: 'fully-qualified',
    emoji: '👸',
    version: 'E0.6',
    baseName: 'princess',
    fullName: 'princess',
    isBase: true,
    baseHex: '1f478',
    modifiers: {
      '1f3fb': '1f478-1f3fb',
      '1f3fc': '1f478-1f3fc',
      '1f3fd': '1f478-1f3fd',
      '1f3fe': '1f478-1f3fe',
      '1f3ff': '1f478-1f3ff',
    },
  },
  '1f478-1f3fb': {
    hexCodePoints: '1f478-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👸🏻',
    version: 'E1.0',
    baseName: 'princess',
    fullName: 'princess: light skin tone',
    isBase: false,
    baseHex: '1f478',
    modifiers: null,
  },
  '1f478-1f3fc': {
    hexCodePoints: '1f478-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👸🏼',
    version: 'E1.0',
    baseName: 'princess',
    fullName: 'princess: medium-light skin tone',
    isBase: false,
    baseHex: '1f478',
    modifiers: null,
  },
  '1f478-1f3fd': {
    hexCodePoints: '1f478-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👸🏽',
    version: 'E1.0',
    baseName: 'princess',
    fullName: 'princess: medium skin tone',
    isBase: false,
    baseHex: '1f478',
    modifiers: null,
  },
  '1f478-1f3fe': {
    hexCodePoints: '1f478-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👸🏾',
    version: 'E1.0',
    baseName: 'princess',
    fullName: 'princess: medium-dark skin tone',
    isBase: false,
    baseHex: '1f478',
    modifiers: null,
  },
  '1f478-1f3ff': {
    hexCodePoints: '1f478-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👸🏿',
    version: 'E1.0',
    baseName: 'princess',
    fullName: 'princess: dark skin tone',
    isBase: false,
    baseHex: '1f478',
    modifiers: null,
  },
  '1f473': {
    hexCodePoints: '1f473',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳',
    version: 'E0.6',
    baseName: 'person wearing turban',
    fullName: 'person wearing turban',
    isBase: true,
    baseHex: '1f473',
    modifiers: {
      '1f3fb': '1f473-1f3fb',
      '1f3fc': '1f473-1f3fc',
      '1f3fd': '1f473-1f3fd',
      '1f3fe': '1f473-1f3fe',
      '1f3ff': '1f473-1f3ff',
    },
  },
  '1f473-1f3fb': {
    hexCodePoints: '1f473-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏻',
    version: 'E1.0',
    baseName: 'person wearing turban',
    fullName: 'person wearing turban: light skin tone',
    isBase: false,
    baseHex: '1f473',
    modifiers: null,
  },
  '1f473-1f3fc': {
    hexCodePoints: '1f473-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏼',
    version: 'E1.0',
    baseName: 'person wearing turban',
    fullName: 'person wearing turban: medium-light skin tone',
    isBase: false,
    baseHex: '1f473',
    modifiers: null,
  },
  '1f473-1f3fd': {
    hexCodePoints: '1f473-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏽',
    version: 'E1.0',
    baseName: 'person wearing turban',
    fullName: 'person wearing turban: medium skin tone',
    isBase: false,
    baseHex: '1f473',
    modifiers: null,
  },
  '1f473-1f3fe': {
    hexCodePoints: '1f473-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏾',
    version: 'E1.0',
    baseName: 'person wearing turban',
    fullName: 'person wearing turban: medium-dark skin tone',
    isBase: false,
    baseHex: '1f473',
    modifiers: null,
  },
  '1f473-1f3ff': {
    hexCodePoints: '1f473-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏿',
    version: 'E1.0',
    baseName: 'person wearing turban',
    fullName: 'person wearing turban: dark skin tone',
    isBase: false,
    baseHex: '1f473',
    modifiers: null,
  },
  '1f473-200d-2642-fe0f': {
    hexCodePoints: '1f473-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳‍♂️',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban',
    isBase: true,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f473-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f473-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f473-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f473-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f473-1f3ff-200d-2642-fe0f',
    },
  },
  '1f473-200d-2642': {
    hexCodePoints: '1f473-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳‍♂',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f473-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏻‍♂️',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fb-200d-2642': {
    hexCodePoints: '1f473-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏻‍♂',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f473-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏼‍♂️',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: medium-light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fc-200d-2642': {
    hexCodePoints: '1f473-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏼‍♂',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: medium-light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f473-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏽‍♂️',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: medium skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fd-200d-2642': {
    hexCodePoints: '1f473-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏽‍♂',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: medium skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f473-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏾‍♂️',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: medium-dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3fe-200d-2642': {
    hexCodePoints: '1f473-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏾‍♂',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: medium-dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f473-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏿‍♂️',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-1f3ff-200d-2642': {
    hexCodePoints: '1f473-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏿‍♂',
    version: 'E4.0',
    baseName: 'man wearing turban',
    fullName: 'man wearing turban: dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2642-fe0f',
    modifiers: null,
  },
  '1f473-200d-2640-fe0f': {
    hexCodePoints: '1f473-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳‍♀️',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban',
    isBase: true,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f473-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f473-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f473-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f473-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f473-1f3ff-200d-2640-fe0f',
    },
  },
  '1f473-200d-2640': {
    hexCodePoints: '1f473-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳‍♀',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f473-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fb-200d-2640': {
    hexCodePoints: '1f473-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏻‍♀',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f473-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: medium-light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fc-200d-2640': {
    hexCodePoints: '1f473-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏼‍♀',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: medium-light skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f473-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: medium skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fd-200d-2640': {
    hexCodePoints: '1f473-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏽‍♀',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: medium skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f473-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: medium-dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3fe-200d-2640': {
    hexCodePoints: '1f473-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏾‍♀',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: medium-dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f473-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👳🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f473-1f3ff-200d-2640': {
    hexCodePoints: '1f473-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👳🏿‍♀',
    version: 'E4.0',
    baseName: 'woman wearing turban',
    fullName: 'woman wearing turban: dark skin tone',
    isBase: false,
    baseHex: '1f473-200d-2640-fe0f',
    modifiers: null,
  },
  '1f472': {
    hexCodePoints: '1f472',
    qualifiedStatus: 'fully-qualified',
    emoji: '👲',
    version: 'E0.6',
    baseName: 'person with skullcap',
    fullName: 'person with skullcap',
    isBase: true,
    baseHex: '1f472',
    modifiers: {
      '1f3fb': '1f472-1f3fb',
      '1f3fc': '1f472-1f3fc',
      '1f3fd': '1f472-1f3fd',
      '1f3fe': '1f472-1f3fe',
      '1f3ff': '1f472-1f3ff',
    },
  },
  '1f472-1f3fb': {
    hexCodePoints: '1f472-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👲🏻',
    version: 'E1.0',
    baseName: 'person with skullcap',
    fullName: 'person with skullcap: light skin tone',
    isBase: false,
    baseHex: '1f472',
    modifiers: null,
  },
  '1f472-1f3fc': {
    hexCodePoints: '1f472-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👲🏼',
    version: 'E1.0',
    baseName: 'person with skullcap',
    fullName: 'person with skullcap: medium-light skin tone',
    isBase: false,
    baseHex: '1f472',
    modifiers: null,
  },
  '1f472-1f3fd': {
    hexCodePoints: '1f472-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👲🏽',
    version: 'E1.0',
    baseName: 'person with skullcap',
    fullName: 'person with skullcap: medium skin tone',
    isBase: false,
    baseHex: '1f472',
    modifiers: null,
  },
  '1f472-1f3fe': {
    hexCodePoints: '1f472-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👲🏾',
    version: 'E1.0',
    baseName: 'person with skullcap',
    fullName: 'person with skullcap: medium-dark skin tone',
    isBase: false,
    baseHex: '1f472',
    modifiers: null,
  },
  '1f472-1f3ff': {
    hexCodePoints: '1f472-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👲🏿',
    version: 'E1.0',
    baseName: 'person with skullcap',
    fullName: 'person with skullcap: dark skin tone',
    isBase: false,
    baseHex: '1f472',
    modifiers: null,
  },
  '1f9d5': {
    hexCodePoints: '1f9d5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧕',
    version: 'E5.0',
    baseName: 'woman with headscarf',
    fullName: 'woman with headscarf',
    isBase: true,
    baseHex: '1f9d5',
    modifiers: {
      '1f3fb': '1f9d5-1f3fb',
      '1f3fc': '1f9d5-1f3fc',
      '1f3fd': '1f9d5-1f3fd',
      '1f3fe': '1f9d5-1f3fe',
      '1f3ff': '1f9d5-1f3ff',
    },
  },
  '1f9d5-1f3fb': {
    hexCodePoints: '1f9d5-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧕🏻',
    version: 'E5.0',
    baseName: 'woman with headscarf',
    fullName: 'woman with headscarf: light skin tone',
    isBase: false,
    baseHex: '1f9d5',
    modifiers: null,
  },
  '1f9d5-1f3fc': {
    hexCodePoints: '1f9d5-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧕🏼',
    version: 'E5.0',
    baseName: 'woman with headscarf',
    fullName: 'woman with headscarf: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d5',
    modifiers: null,
  },
  '1f9d5-1f3fd': {
    hexCodePoints: '1f9d5-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧕🏽',
    version: 'E5.0',
    baseName: 'woman with headscarf',
    fullName: 'woman with headscarf: medium skin tone',
    isBase: false,
    baseHex: '1f9d5',
    modifiers: null,
  },
  '1f9d5-1f3fe': {
    hexCodePoints: '1f9d5-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧕🏾',
    version: 'E5.0',
    baseName: 'woman with headscarf',
    fullName: 'woman with headscarf: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d5',
    modifiers: null,
  },
  '1f9d5-1f3ff': {
    hexCodePoints: '1f9d5-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧕🏿',
    version: 'E5.0',
    baseName: 'woman with headscarf',
    fullName: 'woman with headscarf: dark skin tone',
    isBase: false,
    baseHex: '1f9d5',
    modifiers: null,
  },
  '1f935': {
    hexCodePoints: '1f935',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵',
    version: 'E3.0',
    baseName: 'person in tuxedo',
    fullName: 'person in tuxedo',
    isBase: true,
    baseHex: '1f935',
    modifiers: {
      '1f3fb': '1f935-1f3fb',
      '1f3fc': '1f935-1f3fc',
      '1f3fd': '1f935-1f3fd',
      '1f3fe': '1f935-1f3fe',
      '1f3ff': '1f935-1f3ff',
    },
  },
  '1f935-1f3fb': {
    hexCodePoints: '1f935-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏻',
    version: 'E3.0',
    baseName: 'person in tuxedo',
    fullName: 'person in tuxedo: light skin tone',
    isBase: false,
    baseHex: '1f935',
    modifiers: null,
  },
  '1f935-1f3fc': {
    hexCodePoints: '1f935-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏼',
    version: 'E3.0',
    baseName: 'person in tuxedo',
    fullName: 'person in tuxedo: medium-light skin tone',
    isBase: false,
    baseHex: '1f935',
    modifiers: null,
  },
  '1f935-1f3fd': {
    hexCodePoints: '1f935-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏽',
    version: 'E3.0',
    baseName: 'person in tuxedo',
    fullName: 'person in tuxedo: medium skin tone',
    isBase: false,
    baseHex: '1f935',
    modifiers: null,
  },
  '1f935-1f3fe': {
    hexCodePoints: '1f935-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏾',
    version: 'E3.0',
    baseName: 'person in tuxedo',
    fullName: 'person in tuxedo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f935',
    modifiers: null,
  },
  '1f935-1f3ff': {
    hexCodePoints: '1f935-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏿',
    version: 'E3.0',
    baseName: 'person in tuxedo',
    fullName: 'person in tuxedo: dark skin tone',
    isBase: false,
    baseHex: '1f935',
    modifiers: null,
  },
  '1f935-200d-2642-fe0f': {
    hexCodePoints: '1f935-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵‍♂️',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo',
    isBase: true,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f935-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f935-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f935-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f935-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f935-1f3ff-200d-2642-fe0f',
    },
  },
  '1f935-200d-2642': {
    hexCodePoints: '1f935-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵‍♂',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f935-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏻‍♂️',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fb-200d-2642': {
    hexCodePoints: '1f935-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏻‍♂',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f935-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏼‍♂️',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: medium-light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fc-200d-2642': {
    hexCodePoints: '1f935-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏼‍♂',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: medium-light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f935-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏽‍♂️',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: medium skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fd-200d-2642': {
    hexCodePoints: '1f935-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏽‍♂',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: medium skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f935-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏾‍♂️',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3fe-200d-2642': {
    hexCodePoints: '1f935-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏾‍♂',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f935-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏿‍♂️',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-1f3ff-200d-2642': {
    hexCodePoints: '1f935-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏿‍♂',
    version: 'E13.0',
    baseName: 'man in tuxedo',
    fullName: 'man in tuxedo: dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2642-fe0f',
    modifiers: null,
  },
  '1f935-200d-2640-fe0f': {
    hexCodePoints: '1f935-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵‍♀️',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo',
    isBase: true,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f935-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f935-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f935-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f935-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f935-1f3ff-200d-2640-fe0f',
    },
  },
  '1f935-200d-2640': {
    hexCodePoints: '1f935-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵‍♀',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f935-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏻‍♀️',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fb-200d-2640': {
    hexCodePoints: '1f935-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏻‍♀',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f935-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏼‍♀️',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: medium-light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fc-200d-2640': {
    hexCodePoints: '1f935-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏼‍♀',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: medium-light skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f935-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏽‍♀️',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: medium skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fd-200d-2640': {
    hexCodePoints: '1f935-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏽‍♀',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: medium skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f935-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏾‍♀️',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3fe-200d-2640': {
    hexCodePoints: '1f935-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏾‍♀',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f935-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤵🏿‍♀️',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f935-1f3ff-200d-2640': {
    hexCodePoints: '1f935-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤵🏿‍♀',
    version: 'E13.0',
    baseName: 'woman in tuxedo',
    fullName: 'woman in tuxedo: dark skin tone',
    isBase: false,
    baseHex: '1f935-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470': {
    hexCodePoints: '1f470',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰',
    version: 'E0.6',
    baseName: 'person with veil',
    fullName: 'person with veil',
    isBase: true,
    baseHex: '1f470',
    modifiers: {
      '1f3fb': '1f470-1f3fb',
      '1f3fc': '1f470-1f3fc',
      '1f3fd': '1f470-1f3fd',
      '1f3fe': '1f470-1f3fe',
      '1f3ff': '1f470-1f3ff',
    },
  },
  '1f470-1f3fb': {
    hexCodePoints: '1f470-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏻',
    version: 'E1.0',
    baseName: 'person with veil',
    fullName: 'person with veil: light skin tone',
    isBase: false,
    baseHex: '1f470',
    modifiers: null,
  },
  '1f470-1f3fc': {
    hexCodePoints: '1f470-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏼',
    version: 'E1.0',
    baseName: 'person with veil',
    fullName: 'person with veil: medium-light skin tone',
    isBase: false,
    baseHex: '1f470',
    modifiers: null,
  },
  '1f470-1f3fd': {
    hexCodePoints: '1f470-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏽',
    version: 'E1.0',
    baseName: 'person with veil',
    fullName: 'person with veil: medium skin tone',
    isBase: false,
    baseHex: '1f470',
    modifiers: null,
  },
  '1f470-1f3fe': {
    hexCodePoints: '1f470-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏾',
    version: 'E1.0',
    baseName: 'person with veil',
    fullName: 'person with veil: medium-dark skin tone',
    isBase: false,
    baseHex: '1f470',
    modifiers: null,
  },
  '1f470-1f3ff': {
    hexCodePoints: '1f470-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏿',
    version: 'E1.0',
    baseName: 'person with veil',
    fullName: 'person with veil: dark skin tone',
    isBase: false,
    baseHex: '1f470',
    modifiers: null,
  },
  '1f470-200d-2642-fe0f': {
    hexCodePoints: '1f470-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰‍♂️',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil',
    isBase: true,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f470-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f470-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f470-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f470-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f470-1f3ff-200d-2642-fe0f',
    },
  },
  '1f470-200d-2642': {
    hexCodePoints: '1f470-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰‍♂',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f470-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏻‍♂️',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fb-200d-2642': {
    hexCodePoints: '1f470-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏻‍♂',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f470-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏼‍♂️',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: medium-light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fc-200d-2642': {
    hexCodePoints: '1f470-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏼‍♂',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: medium-light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f470-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏽‍♂️',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: medium skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fd-200d-2642': {
    hexCodePoints: '1f470-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏽‍♂',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: medium skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f470-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏾‍♂️',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: medium-dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3fe-200d-2642': {
    hexCodePoints: '1f470-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏾‍♂',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: medium-dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f470-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏿‍♂️',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-1f3ff-200d-2642': {
    hexCodePoints: '1f470-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏿‍♂',
    version: 'E13.0',
    baseName: 'man with veil',
    fullName: 'man with veil: dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2642-fe0f',
    modifiers: null,
  },
  '1f470-200d-2640-fe0f': {
    hexCodePoints: '1f470-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰‍♀️',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil',
    isBase: true,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f470-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f470-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f470-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f470-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f470-1f3ff-200d-2640-fe0f',
    },
  },
  '1f470-200d-2640': {
    hexCodePoints: '1f470-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰‍♀',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f470-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏻‍♀️',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fb-200d-2640': {
    hexCodePoints: '1f470-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏻‍♀',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f470-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏼‍♀️',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: medium-light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fc-200d-2640': {
    hexCodePoints: '1f470-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏼‍♀',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: medium-light skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f470-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏽‍♀️',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: medium skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fd-200d-2640': {
    hexCodePoints: '1f470-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏽‍♀',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: medium skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f470-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏾‍♀️',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: medium-dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3fe-200d-2640': {
    hexCodePoints: '1f470-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏾‍♀',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: medium-dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f470-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👰🏿‍♀️',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f470-1f3ff-200d-2640': {
    hexCodePoints: '1f470-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👰🏿‍♀',
    version: 'E13.0',
    baseName: 'woman with veil',
    fullName: 'woman with veil: dark skin tone',
    isBase: false,
    baseHex: '1f470-200d-2640-fe0f',
    modifiers: null,
  },
  '1f930': {
    hexCodePoints: '1f930',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤰',
    version: 'E3.0',
    baseName: 'pregnant woman',
    fullName: 'pregnant woman',
    isBase: true,
    baseHex: '1f930',
    modifiers: {
      '1f3fb': '1f930-1f3fb',
      '1f3fc': '1f930-1f3fc',
      '1f3fd': '1f930-1f3fd',
      '1f3fe': '1f930-1f3fe',
      '1f3ff': '1f930-1f3ff',
    },
  },
  '1f930-1f3fb': {
    hexCodePoints: '1f930-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤰🏻',
    version: 'E3.0',
    baseName: 'pregnant woman',
    fullName: 'pregnant woman: light skin tone',
    isBase: false,
    baseHex: '1f930',
    modifiers: null,
  },
  '1f930-1f3fc': {
    hexCodePoints: '1f930-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤰🏼',
    version: 'E3.0',
    baseName: 'pregnant woman',
    fullName: 'pregnant woman: medium-light skin tone',
    isBase: false,
    baseHex: '1f930',
    modifiers: null,
  },
  '1f930-1f3fd': {
    hexCodePoints: '1f930-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤰🏽',
    version: 'E3.0',
    baseName: 'pregnant woman',
    fullName: 'pregnant woman: medium skin tone',
    isBase: false,
    baseHex: '1f930',
    modifiers: null,
  },
  '1f930-1f3fe': {
    hexCodePoints: '1f930-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤰🏾',
    version: 'E3.0',
    baseName: 'pregnant woman',
    fullName: 'pregnant woman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f930',
    modifiers: null,
  },
  '1f930-1f3ff': {
    hexCodePoints: '1f930-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤰🏿',
    version: 'E3.0',
    baseName: 'pregnant woman',
    fullName: 'pregnant woman: dark skin tone',
    isBase: false,
    baseHex: '1f930',
    modifiers: null,
  },
  '1fac3': {
    hexCodePoints: '1fac3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫃',
    version: 'E14.0',
    baseName: 'pregnant man',
    fullName: 'pregnant man',
    isBase: true,
    baseHex: '1fac3',
    modifiers: {
      '1f3fb': '1fac3-1f3fb',
      '1f3fc': '1fac3-1f3fc',
      '1f3fd': '1fac3-1f3fd',
      '1f3fe': '1fac3-1f3fe',
      '1f3ff': '1fac3-1f3ff',
    },
  },
  '1fac3-1f3fb': {
    hexCodePoints: '1fac3-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫃🏻',
    version: 'E14.0',
    baseName: 'pregnant man',
    fullName: 'pregnant man: light skin tone',
    isBase: false,
    baseHex: '1fac3',
    modifiers: null,
  },
  '1fac3-1f3fc': {
    hexCodePoints: '1fac3-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫃🏼',
    version: 'E14.0',
    baseName: 'pregnant man',
    fullName: 'pregnant man: medium-light skin tone',
    isBase: false,
    baseHex: '1fac3',
    modifiers: null,
  },
  '1fac3-1f3fd': {
    hexCodePoints: '1fac3-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫃🏽',
    version: 'E14.0',
    baseName: 'pregnant man',
    fullName: 'pregnant man: medium skin tone',
    isBase: false,
    baseHex: '1fac3',
    modifiers: null,
  },
  '1fac3-1f3fe': {
    hexCodePoints: '1fac3-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫃🏾',
    version: 'E14.0',
    baseName: 'pregnant man',
    fullName: 'pregnant man: medium-dark skin tone',
    isBase: false,
    baseHex: '1fac3',
    modifiers: null,
  },
  '1fac3-1f3ff': {
    hexCodePoints: '1fac3-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫃🏿',
    version: 'E14.0',
    baseName: 'pregnant man',
    fullName: 'pregnant man: dark skin tone',
    isBase: false,
    baseHex: '1fac3',
    modifiers: null,
  },
  '1fac4': {
    hexCodePoints: '1fac4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫄',
    version: 'E14.0',
    baseName: 'pregnant person',
    fullName: 'pregnant person',
    isBase: true,
    baseHex: '1fac4',
    modifiers: {
      '1f3fb': '1fac4-1f3fb',
      '1f3fc': '1fac4-1f3fc',
      '1f3fd': '1fac4-1f3fd',
      '1f3fe': '1fac4-1f3fe',
      '1f3ff': '1fac4-1f3ff',
    },
  },
  '1fac4-1f3fb': {
    hexCodePoints: '1fac4-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫄🏻',
    version: 'E14.0',
    baseName: 'pregnant person',
    fullName: 'pregnant person: light skin tone',
    isBase: false,
    baseHex: '1fac4',
    modifiers: null,
  },
  '1fac4-1f3fc': {
    hexCodePoints: '1fac4-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫄🏼',
    version: 'E14.0',
    baseName: 'pregnant person',
    fullName: 'pregnant person: medium-light skin tone',
    isBase: false,
    baseHex: '1fac4',
    modifiers: null,
  },
  '1fac4-1f3fd': {
    hexCodePoints: '1fac4-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫄🏽',
    version: 'E14.0',
    baseName: 'pregnant person',
    fullName: 'pregnant person: medium skin tone',
    isBase: false,
    baseHex: '1fac4',
    modifiers: null,
  },
  '1fac4-1f3fe': {
    hexCodePoints: '1fac4-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫄🏾',
    version: 'E14.0',
    baseName: 'pregnant person',
    fullName: 'pregnant person: medium-dark skin tone',
    isBase: false,
    baseHex: '1fac4',
    modifiers: null,
  },
  '1fac4-1f3ff': {
    hexCodePoints: '1fac4-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫄🏿',
    version: 'E14.0',
    baseName: 'pregnant person',
    fullName: 'pregnant person: dark skin tone',
    isBase: false,
    baseHex: '1fac4',
    modifiers: null,
  },
  '1f931': {
    hexCodePoints: '1f931',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤱',
    version: 'E5.0',
    baseName: 'breast-feeding',
    fullName: 'breast-feeding',
    isBase: true,
    baseHex: '1f931',
    modifiers: {
      '1f3fb': '1f931-1f3fb',
      '1f3fc': '1f931-1f3fc',
      '1f3fd': '1f931-1f3fd',
      '1f3fe': '1f931-1f3fe',
      '1f3ff': '1f931-1f3ff',
    },
  },
  '1f931-1f3fb': {
    hexCodePoints: '1f931-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤱🏻',
    version: 'E5.0',
    baseName: 'breast-feeding',
    fullName: 'breast-feeding: light skin tone',
    isBase: false,
    baseHex: '1f931',
    modifiers: null,
  },
  '1f931-1f3fc': {
    hexCodePoints: '1f931-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤱🏼',
    version: 'E5.0',
    baseName: 'breast-feeding',
    fullName: 'breast-feeding: medium-light skin tone',
    isBase: false,
    baseHex: '1f931',
    modifiers: null,
  },
  '1f931-1f3fd': {
    hexCodePoints: '1f931-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤱🏽',
    version: 'E5.0',
    baseName: 'breast-feeding',
    fullName: 'breast-feeding: medium skin tone',
    isBase: false,
    baseHex: '1f931',
    modifiers: null,
  },
  '1f931-1f3fe': {
    hexCodePoints: '1f931-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤱🏾',
    version: 'E5.0',
    baseName: 'breast-feeding',
    fullName: 'breast-feeding: medium-dark skin tone',
    isBase: false,
    baseHex: '1f931',
    modifiers: null,
  },
  '1f931-1f3ff': {
    hexCodePoints: '1f931-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤱🏿',
    version: 'E5.0',
    baseName: 'breast-feeding',
    fullName: 'breast-feeding: dark skin tone',
    isBase: false,
    baseHex: '1f931',
    modifiers: null,
  },
  '1f469-200d-1f37c': {
    hexCodePoints: '1f469-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🍼',
    version: 'E13.0',
    baseName: 'woman feeding baby',
    fullName: 'woman feeding baby',
    isBase: true,
    baseHex: '1f469-200d-1f37c',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f37c',
      '1f3fc': '1f469-1f3fc-200d-1f37c',
      '1f3fd': '1f469-1f3fd-200d-1f37c',
      '1f3fe': '1f469-1f3fe-200d-1f37c',
      '1f3ff': '1f469-1f3ff-200d-1f37c',
    },
  },
  '1f469-1f3fb-200d-1f37c': {
    hexCodePoints: '1f469-1f3fb-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🍼',
    version: 'E13.0',
    baseName: 'woman feeding baby',
    fullName: 'woman feeding baby: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f37c',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f37c': {
    hexCodePoints: '1f469-1f3fc-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🍼',
    version: 'E13.0',
    baseName: 'woman feeding baby',
    fullName: 'woman feeding baby: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f37c',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f37c': {
    hexCodePoints: '1f469-1f3fd-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🍼',
    version: 'E13.0',
    baseName: 'woman feeding baby',
    fullName: 'woman feeding baby: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f37c',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f37c': {
    hexCodePoints: '1f469-1f3fe-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🍼',
    version: 'E13.0',
    baseName: 'woman feeding baby',
    fullName: 'woman feeding baby: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f37c',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f37c': {
    hexCodePoints: '1f469-1f3ff-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🍼',
    version: 'E13.0',
    baseName: 'woman feeding baby',
    fullName: 'woman feeding baby: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f37c',
    modifiers: null,
  },
  '1f468-200d-1f37c': {
    hexCodePoints: '1f468-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🍼',
    version: 'E13.0',
    baseName: 'man feeding baby',
    fullName: 'man feeding baby',
    isBase: true,
    baseHex: '1f468-200d-1f37c',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f37c',
      '1f3fc': '1f468-1f3fc-200d-1f37c',
      '1f3fd': '1f468-1f3fd-200d-1f37c',
      '1f3fe': '1f468-1f3fe-200d-1f37c',
      '1f3ff': '1f468-1f3ff-200d-1f37c',
    },
  },
  '1f468-1f3fb-200d-1f37c': {
    hexCodePoints: '1f468-1f3fb-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🍼',
    version: 'E13.0',
    baseName: 'man feeding baby',
    fullName: 'man feeding baby: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f37c',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f37c': {
    hexCodePoints: '1f468-1f3fc-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🍼',
    version: 'E13.0',
    baseName: 'man feeding baby',
    fullName: 'man feeding baby: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f37c',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f37c': {
    hexCodePoints: '1f468-1f3fd-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🍼',
    version: 'E13.0',
    baseName: 'man feeding baby',
    fullName: 'man feeding baby: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f37c',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f37c': {
    hexCodePoints: '1f468-1f3fe-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🍼',
    version: 'E13.0',
    baseName: 'man feeding baby',
    fullName: 'man feeding baby: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f37c',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f37c': {
    hexCodePoints: '1f468-1f3ff-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🍼',
    version: 'E13.0',
    baseName: 'man feeding baby',
    fullName: 'man feeding baby: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f37c',
    modifiers: null,
  },
  '1f9d1-200d-1f37c': {
    hexCodePoints: '1f9d1-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🍼',
    version: 'E13.0',
    baseName: 'person feeding baby',
    fullName: 'person feeding baby',
    isBase: true,
    baseHex: '1f9d1-200d-1f37c',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f37c',
      '1f3fc': '1f9d1-1f3fc-200d-1f37c',
      '1f3fd': '1f9d1-1f3fd-200d-1f37c',
      '1f3fe': '1f9d1-1f3fe-200d-1f37c',
      '1f3ff': '1f9d1-1f3ff-200d-1f37c',
    },
  },
  '1f9d1-1f3fb-200d-1f37c': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🍼',
    version: 'E13.0',
    baseName: 'person feeding baby',
    fullName: 'person feeding baby: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f37c',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f37c': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🍼',
    version: 'E13.0',
    baseName: 'person feeding baby',
    fullName: 'person feeding baby: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f37c',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f37c': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🍼',
    version: 'E13.0',
    baseName: 'person feeding baby',
    fullName: 'person feeding baby: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f37c',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f37c': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🍼',
    version: 'E13.0',
    baseName: 'person feeding baby',
    fullName: 'person feeding baby: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f37c',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f37c': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🍼',
    version: 'E13.0',
    baseName: 'person feeding baby',
    fullName: 'person feeding baby: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f37c',
    modifiers: null,
  },
  '1f47c': {
    hexCodePoints: '1f47c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👼',
    version: 'E0.6',
    baseName: 'baby angel',
    fullName: 'baby angel',
    isBase: true,
    baseHex: '1f47c',
    modifiers: {
      '1f3fb': '1f47c-1f3fb',
      '1f3fc': '1f47c-1f3fc',
      '1f3fd': '1f47c-1f3fd',
      '1f3fe': '1f47c-1f3fe',
      '1f3ff': '1f47c-1f3ff',
    },
  },
  '1f47c-1f3fb': {
    hexCodePoints: '1f47c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👼🏻',
    version: 'E1.0',
    baseName: 'baby angel',
    fullName: 'baby angel: light skin tone',
    isBase: false,
    baseHex: '1f47c',
    modifiers: null,
  },
  '1f47c-1f3fc': {
    hexCodePoints: '1f47c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👼🏼',
    version: 'E1.0',
    baseName: 'baby angel',
    fullName: 'baby angel: medium-light skin tone',
    isBase: false,
    baseHex: '1f47c',
    modifiers: null,
  },
  '1f47c-1f3fd': {
    hexCodePoints: '1f47c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👼🏽',
    version: 'E1.0',
    baseName: 'baby angel',
    fullName: 'baby angel: medium skin tone',
    isBase: false,
    baseHex: '1f47c',
    modifiers: null,
  },
  '1f47c-1f3fe': {
    hexCodePoints: '1f47c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👼🏾',
    version: 'E1.0',
    baseName: 'baby angel',
    fullName: 'baby angel: medium-dark skin tone',
    isBase: false,
    baseHex: '1f47c',
    modifiers: null,
  },
  '1f47c-1f3ff': {
    hexCodePoints: '1f47c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👼🏿',
    version: 'E1.0',
    baseName: 'baby angel',
    fullName: 'baby angel: dark skin tone',
    isBase: false,
    baseHex: '1f47c',
    modifiers: null,
  },
  '1f385': {
    hexCodePoints: '1f385',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎅',
    version: 'E0.6',
    baseName: 'Santa Claus',
    fullName: 'Santa Claus',
    isBase: true,
    baseHex: '1f385',
    modifiers: {
      '1f3fb': '1f385-1f3fb',
      '1f3fc': '1f385-1f3fc',
      '1f3fd': '1f385-1f3fd',
      '1f3fe': '1f385-1f3fe',
      '1f3ff': '1f385-1f3ff',
    },
  },
  '1f385-1f3fb': {
    hexCodePoints: '1f385-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎅🏻',
    version: 'E1.0',
    baseName: 'Santa Claus',
    fullName: 'Santa Claus: light skin tone',
    isBase: false,
    baseHex: '1f385',
    modifiers: null,
  },
  '1f385-1f3fc': {
    hexCodePoints: '1f385-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎅🏼',
    version: 'E1.0',
    baseName: 'Santa Claus',
    fullName: 'Santa Claus: medium-light skin tone',
    isBase: false,
    baseHex: '1f385',
    modifiers: null,
  },
  '1f385-1f3fd': {
    hexCodePoints: '1f385-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎅🏽',
    version: 'E1.0',
    baseName: 'Santa Claus',
    fullName: 'Santa Claus: medium skin tone',
    isBase: false,
    baseHex: '1f385',
    modifiers: null,
  },
  '1f385-1f3fe': {
    hexCodePoints: '1f385-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎅🏾',
    version: 'E1.0',
    baseName: 'Santa Claus',
    fullName: 'Santa Claus: medium-dark skin tone',
    isBase: false,
    baseHex: '1f385',
    modifiers: null,
  },
  '1f385-1f3ff': {
    hexCodePoints: '1f385-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎅🏿',
    version: 'E1.0',
    baseName: 'Santa Claus',
    fullName: 'Santa Claus: dark skin tone',
    isBase: false,
    baseHex: '1f385',
    modifiers: null,
  },
  '1f936': {
    hexCodePoints: '1f936',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤶',
    version: 'E3.0',
    baseName: 'Mrs. Claus',
    fullName: 'Mrs. Claus',
    isBase: true,
    baseHex: '1f936',
    modifiers: {
      '1f3fb': '1f936-1f3fb',
      '1f3fc': '1f936-1f3fc',
      '1f3fd': '1f936-1f3fd',
      '1f3fe': '1f936-1f3fe',
      '1f3ff': '1f936-1f3ff',
    },
  },
  '1f936-1f3fb': {
    hexCodePoints: '1f936-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤶🏻',
    version: 'E3.0',
    baseName: 'Mrs. Claus',
    fullName: 'Mrs. Claus: light skin tone',
    isBase: false,
    baseHex: '1f936',
    modifiers: null,
  },
  '1f936-1f3fc': {
    hexCodePoints: '1f936-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤶🏼',
    version: 'E3.0',
    baseName: 'Mrs. Claus',
    fullName: 'Mrs. Claus: medium-light skin tone',
    isBase: false,
    baseHex: '1f936',
    modifiers: null,
  },
  '1f936-1f3fd': {
    hexCodePoints: '1f936-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤶🏽',
    version: 'E3.0',
    baseName: 'Mrs. Claus',
    fullName: 'Mrs. Claus: medium skin tone',
    isBase: false,
    baseHex: '1f936',
    modifiers: null,
  },
  '1f936-1f3fe': {
    hexCodePoints: '1f936-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤶🏾',
    version: 'E3.0',
    baseName: 'Mrs. Claus',
    fullName: 'Mrs. Claus: medium-dark skin tone',
    isBase: false,
    baseHex: '1f936',
    modifiers: null,
  },
  '1f936-1f3ff': {
    hexCodePoints: '1f936-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤶🏿',
    version: 'E3.0',
    baseName: 'Mrs. Claus',
    fullName: 'Mrs. Claus: dark skin tone',
    isBase: false,
    baseHex: '1f936',
    modifiers: null,
  },
  '1f9d1-200d-1f384': {
    hexCodePoints: '1f9d1-200d-1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🎄',
    version: 'E13.0',
    baseName: 'mx claus',
    fullName: 'mx claus',
    isBase: true,
    baseHex: '1f9d1-200d-1f384',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f384',
      '1f3fc': '1f9d1-1f3fc-200d-1f384',
      '1f3fd': '1f9d1-1f3fd-200d-1f384',
      '1f3fe': '1f9d1-1f3fe-200d-1f384',
      '1f3ff': '1f9d1-1f3ff-200d-1f384',
    },
  },
  '1f9d1-1f3fb-200d-1f384': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🎄',
    version: 'E13.0',
    baseName: 'mx claus',
    fullName: 'mx claus: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f384',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f384': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🎄',
    version: 'E13.0',
    baseName: 'mx claus',
    fullName: 'mx claus: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f384',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f384': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🎄',
    version: 'E13.0',
    baseName: 'mx claus',
    fullName: 'mx claus: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f384',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f384': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🎄',
    version: 'E13.0',
    baseName: 'mx claus',
    fullName: 'mx claus: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f384',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f384': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🎄',
    version: 'E13.0',
    baseName: 'mx claus',
    fullName: 'mx claus: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f384',
    modifiers: null,
  },
  '1f9b8': {
    hexCodePoints: '1f9b8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸',
    version: 'E11.0',
    baseName: 'superhero',
    fullName: 'superhero',
    isBase: true,
    baseHex: '1f9b8',
    modifiers: {
      '1f3fb': '1f9b8-1f3fb',
      '1f3fc': '1f9b8-1f3fc',
      '1f3fd': '1f9b8-1f3fd',
      '1f3fe': '1f9b8-1f3fe',
      '1f3ff': '1f9b8-1f3ff',
    },
  },
  '1f9b8-1f3fb': {
    hexCodePoints: '1f9b8-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏻',
    version: 'E11.0',
    baseName: 'superhero',
    fullName: 'superhero: light skin tone',
    isBase: false,
    baseHex: '1f9b8',
    modifiers: null,
  },
  '1f9b8-1f3fc': {
    hexCodePoints: '1f9b8-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏼',
    version: 'E11.0',
    baseName: 'superhero',
    fullName: 'superhero: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b8',
    modifiers: null,
  },
  '1f9b8-1f3fd': {
    hexCodePoints: '1f9b8-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏽',
    version: 'E11.0',
    baseName: 'superhero',
    fullName: 'superhero: medium skin tone',
    isBase: false,
    baseHex: '1f9b8',
    modifiers: null,
  },
  '1f9b8-1f3fe': {
    hexCodePoints: '1f9b8-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏾',
    version: 'E11.0',
    baseName: 'superhero',
    fullName: 'superhero: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b8',
    modifiers: null,
  },
  '1f9b8-1f3ff': {
    hexCodePoints: '1f9b8-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏿',
    version: 'E11.0',
    baseName: 'superhero',
    fullName: 'superhero: dark skin tone',
    isBase: false,
    baseHex: '1f9b8',
    modifiers: null,
  },
  '1f9b8-200d-2642-fe0f': {
    hexCodePoints: '1f9b8-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸‍♂️',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero',
    isBase: true,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9b8-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9b8-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9b8-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9b8-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9b8-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9b8-200d-2642': {
    hexCodePoints: '1f9b8-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸‍♂',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9b8-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏻‍♂️',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fb-200d-2642': {
    hexCodePoints: '1f9b8-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏻‍♂',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9b8-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏼‍♂️',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fc-200d-2642': {
    hexCodePoints: '1f9b8-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏼‍♂',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9b8-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏽‍♂️',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: medium skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fd-200d-2642': {
    hexCodePoints: '1f9b8-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏽‍♂',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: medium skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9b8-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏾‍♂️',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fe-200d-2642': {
    hexCodePoints: '1f9b8-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏾‍♂',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9b8-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏿‍♂️',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3ff-200d-2642': {
    hexCodePoints: '1f9b8-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏿‍♂',
    version: 'E11.0',
    baseName: 'man superhero',
    fullName: 'man superhero: dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b8-200d-2640-fe0f': {
    hexCodePoints: '1f9b8-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸‍♀️',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero',
    isBase: true,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9b8-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9b8-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9b8-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9b8-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9b8-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9b8-200d-2640': {
    hexCodePoints: '1f9b8-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸‍♀',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9b8-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏻‍♀️',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fb-200d-2640': {
    hexCodePoints: '1f9b8-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏻‍♀',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9b8-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏼‍♀️',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fc-200d-2640': {
    hexCodePoints: '1f9b8-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏼‍♀',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9b8-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏽‍♀️',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: medium skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fd-200d-2640': {
    hexCodePoints: '1f9b8-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏽‍♀',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: medium skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9b8-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏾‍♀️',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3fe-200d-2640': {
    hexCodePoints: '1f9b8-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏾‍♀',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9b8-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦸🏿‍♀️',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b8-1f3ff-200d-2640': {
    hexCodePoints: '1f9b8-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦸🏿‍♀',
    version: 'E11.0',
    baseName: 'woman superhero',
    fullName: 'woman superhero: dark skin tone',
    isBase: false,
    baseHex: '1f9b8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9': {
    hexCodePoints: '1f9b9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹',
    version: 'E11.0',
    baseName: 'supervillain',
    fullName: 'supervillain',
    isBase: true,
    baseHex: '1f9b9',
    modifiers: {
      '1f3fb': '1f9b9-1f3fb',
      '1f3fc': '1f9b9-1f3fc',
      '1f3fd': '1f9b9-1f3fd',
      '1f3fe': '1f9b9-1f3fe',
      '1f3ff': '1f9b9-1f3ff',
    },
  },
  '1f9b9-1f3fb': {
    hexCodePoints: '1f9b9-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏻',
    version: 'E11.0',
    baseName: 'supervillain',
    fullName: 'supervillain: light skin tone',
    isBase: false,
    baseHex: '1f9b9',
    modifiers: null,
  },
  '1f9b9-1f3fc': {
    hexCodePoints: '1f9b9-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏼',
    version: 'E11.0',
    baseName: 'supervillain',
    fullName: 'supervillain: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b9',
    modifiers: null,
  },
  '1f9b9-1f3fd': {
    hexCodePoints: '1f9b9-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏽',
    version: 'E11.0',
    baseName: 'supervillain',
    fullName: 'supervillain: medium skin tone',
    isBase: false,
    baseHex: '1f9b9',
    modifiers: null,
  },
  '1f9b9-1f3fe': {
    hexCodePoints: '1f9b9-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏾',
    version: 'E11.0',
    baseName: 'supervillain',
    fullName: 'supervillain: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b9',
    modifiers: null,
  },
  '1f9b9-1f3ff': {
    hexCodePoints: '1f9b9-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏿',
    version: 'E11.0',
    baseName: 'supervillain',
    fullName: 'supervillain: dark skin tone',
    isBase: false,
    baseHex: '1f9b9',
    modifiers: null,
  },
  '1f9b9-200d-2642-fe0f': {
    hexCodePoints: '1f9b9-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹‍♂️',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain',
    isBase: true,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9b9-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9b9-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9b9-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9b9-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9b9-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9b9-200d-2642': {
    hexCodePoints: '1f9b9-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹‍♂',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9b9-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏻‍♂️',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fb-200d-2642': {
    hexCodePoints: '1f9b9-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏻‍♂',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9b9-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏼‍♂️',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fc-200d-2642': {
    hexCodePoints: '1f9b9-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏼‍♂',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9b9-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏽‍♂️',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: medium skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fd-200d-2642': {
    hexCodePoints: '1f9b9-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏽‍♂',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: medium skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9b9-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏾‍♂️',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fe-200d-2642': {
    hexCodePoints: '1f9b9-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏾‍♂',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9b9-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏿‍♂️',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3ff-200d-2642': {
    hexCodePoints: '1f9b9-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏿‍♂',
    version: 'E11.0',
    baseName: 'man supervillain',
    fullName: 'man supervillain: dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9b9-200d-2640-fe0f': {
    hexCodePoints: '1f9b9-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹‍♀️',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain',
    isBase: true,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9b9-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9b9-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9b9-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9b9-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9b9-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9b9-200d-2640': {
    hexCodePoints: '1f9b9-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹‍♀',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9b9-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏻‍♀️',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fb-200d-2640': {
    hexCodePoints: '1f9b9-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏻‍♀',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9b9-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏼‍♀️',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fc-200d-2640': {
    hexCodePoints: '1f9b9-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏼‍♀',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: medium-light skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9b9-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏽‍♀️',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: medium skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fd-200d-2640': {
    hexCodePoints: '1f9b9-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏽‍♀',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: medium skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9b9-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏾‍♀️',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3fe-200d-2640': {
    hexCodePoints: '1f9b9-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏾‍♀',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9b9-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦹🏿‍♀️',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9b9-1f3ff-200d-2640': {
    hexCodePoints: '1f9b9-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🦹🏿‍♀',
    version: 'E11.0',
    baseName: 'woman supervillain',
    fullName: 'woman supervillain: dark skin tone',
    isBase: false,
    baseHex: '1f9b9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9': {
    hexCodePoints: '1f9d9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙',
    version: 'E5.0',
    baseName: 'mage',
    fullName: 'mage',
    isBase: true,
    baseHex: '1f9d9',
    modifiers: {
      '1f3fb': '1f9d9-1f3fb',
      '1f3fc': '1f9d9-1f3fc',
      '1f3fd': '1f9d9-1f3fd',
      '1f3fe': '1f9d9-1f3fe',
      '1f3ff': '1f9d9-1f3ff',
    },
  },
  '1f9d9-1f3fb': {
    hexCodePoints: '1f9d9-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏻',
    version: 'E5.0',
    baseName: 'mage',
    fullName: 'mage: light skin tone',
    isBase: false,
    baseHex: '1f9d9',
    modifiers: null,
  },
  '1f9d9-1f3fc': {
    hexCodePoints: '1f9d9-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏼',
    version: 'E5.0',
    baseName: 'mage',
    fullName: 'mage: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d9',
    modifiers: null,
  },
  '1f9d9-1f3fd': {
    hexCodePoints: '1f9d9-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏽',
    version: 'E5.0',
    baseName: 'mage',
    fullName: 'mage: medium skin tone',
    isBase: false,
    baseHex: '1f9d9',
    modifiers: null,
  },
  '1f9d9-1f3fe': {
    hexCodePoints: '1f9d9-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏾',
    version: 'E5.0',
    baseName: 'mage',
    fullName: 'mage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d9',
    modifiers: null,
  },
  '1f9d9-1f3ff': {
    hexCodePoints: '1f9d9-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏿',
    version: 'E5.0',
    baseName: 'mage',
    fullName: 'mage: dark skin tone',
    isBase: false,
    baseHex: '1f9d9',
    modifiers: null,
  },
  '1f9d9-200d-2642-fe0f': {
    hexCodePoints: '1f9d9-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙‍♂️',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage',
    isBase: true,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9d9-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9d9-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9d9-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9d9-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9d9-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9d9-200d-2642': {
    hexCodePoints: '1f9d9-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙‍♂',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9d9-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏻‍♂️',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fb-200d-2642': {
    hexCodePoints: '1f9d9-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏻‍♂',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9d9-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏼‍♂️',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fc-200d-2642': {
    hexCodePoints: '1f9d9-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏼‍♂',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9d9-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏽‍♂️',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: medium skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fd-200d-2642': {
    hexCodePoints: '1f9d9-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏽‍♂',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: medium skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9d9-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏾‍♂️',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fe-200d-2642': {
    hexCodePoints: '1f9d9-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏾‍♂',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9d9-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏿‍♂️',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3ff-200d-2642': {
    hexCodePoints: '1f9d9-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏿‍♂',
    version: 'E5.0',
    baseName: 'man mage',
    fullName: 'man mage: dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d9-200d-2640-fe0f': {
    hexCodePoints: '1f9d9-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙‍♀️',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage',
    isBase: true,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9d9-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9d9-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9d9-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9d9-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9d9-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9d9-200d-2640': {
    hexCodePoints: '1f9d9-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙‍♀',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9d9-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fb-200d-2640': {
    hexCodePoints: '1f9d9-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏻‍♀',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9d9-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fc-200d-2640': {
    hexCodePoints: '1f9d9-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏼‍♀',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9d9-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: medium skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fd-200d-2640': {
    hexCodePoints: '1f9d9-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏽‍♀',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: medium skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9d9-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3fe-200d-2640': {
    hexCodePoints: '1f9d9-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏾‍♀',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9d9-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧙🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d9-1f3ff-200d-2640': {
    hexCodePoints: '1f9d9-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧙🏿‍♀',
    version: 'E5.0',
    baseName: 'woman mage',
    fullName: 'woman mage: dark skin tone',
    isBase: false,
    baseHex: '1f9d9-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da': {
    hexCodePoints: '1f9da',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚',
    version: 'E5.0',
    baseName: 'fairy',
    fullName: 'fairy',
    isBase: true,
    baseHex: '1f9da',
    modifiers: {
      '1f3fb': '1f9da-1f3fb',
      '1f3fc': '1f9da-1f3fc',
      '1f3fd': '1f9da-1f3fd',
      '1f3fe': '1f9da-1f3fe',
      '1f3ff': '1f9da-1f3ff',
    },
  },
  '1f9da-1f3fb': {
    hexCodePoints: '1f9da-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏻',
    version: 'E5.0',
    baseName: 'fairy',
    fullName: 'fairy: light skin tone',
    isBase: false,
    baseHex: '1f9da',
    modifiers: null,
  },
  '1f9da-1f3fc': {
    hexCodePoints: '1f9da-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏼',
    version: 'E5.0',
    baseName: 'fairy',
    fullName: 'fairy: medium-light skin tone',
    isBase: false,
    baseHex: '1f9da',
    modifiers: null,
  },
  '1f9da-1f3fd': {
    hexCodePoints: '1f9da-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏽',
    version: 'E5.0',
    baseName: 'fairy',
    fullName: 'fairy: medium skin tone',
    isBase: false,
    baseHex: '1f9da',
    modifiers: null,
  },
  '1f9da-1f3fe': {
    hexCodePoints: '1f9da-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏾',
    version: 'E5.0',
    baseName: 'fairy',
    fullName: 'fairy: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9da',
    modifiers: null,
  },
  '1f9da-1f3ff': {
    hexCodePoints: '1f9da-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏿',
    version: 'E5.0',
    baseName: 'fairy',
    fullName: 'fairy: dark skin tone',
    isBase: false,
    baseHex: '1f9da',
    modifiers: null,
  },
  '1f9da-200d-2642-fe0f': {
    hexCodePoints: '1f9da-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚‍♂️',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy',
    isBase: true,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9da-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9da-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9da-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9da-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9da-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9da-200d-2642': {
    hexCodePoints: '1f9da-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚‍♂',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9da-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏻‍♂️',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fb-200d-2642': {
    hexCodePoints: '1f9da-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏻‍♂',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9da-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏼‍♂️',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: medium-light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fc-200d-2642': {
    hexCodePoints: '1f9da-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏼‍♂',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: medium-light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9da-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏽‍♂️',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: medium skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fd-200d-2642': {
    hexCodePoints: '1f9da-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏽‍♂',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: medium skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9da-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏾‍♂️',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fe-200d-2642': {
    hexCodePoints: '1f9da-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏾‍♂',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9da-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏿‍♂️',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-1f3ff-200d-2642': {
    hexCodePoints: '1f9da-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏿‍♂',
    version: 'E5.0',
    baseName: 'man fairy',
    fullName: 'man fairy: dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9da-200d-2640-fe0f': {
    hexCodePoints: '1f9da-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚‍♀️',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy',
    isBase: true,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9da-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9da-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9da-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9da-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9da-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9da-200d-2640': {
    hexCodePoints: '1f9da-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚‍♀',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9da-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fb-200d-2640': {
    hexCodePoints: '1f9da-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏻‍♀',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9da-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: medium-light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fc-200d-2640': {
    hexCodePoints: '1f9da-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏼‍♀',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: medium-light skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9da-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: medium skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fd-200d-2640': {
    hexCodePoints: '1f9da-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏽‍♀',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: medium skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9da-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3fe-200d-2640': {
    hexCodePoints: '1f9da-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏾‍♀',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9da-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧚🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9da-1f3ff-200d-2640': {
    hexCodePoints: '1f9da-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧚🏿‍♀',
    version: 'E5.0',
    baseName: 'woman fairy',
    fullName: 'woman fairy: dark skin tone',
    isBase: false,
    baseHex: '1f9da-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db': {
    hexCodePoints: '1f9db',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛',
    version: 'E5.0',
    baseName: 'vampire',
    fullName: 'vampire',
    isBase: true,
    baseHex: '1f9db',
    modifiers: {
      '1f3fb': '1f9db-1f3fb',
      '1f3fc': '1f9db-1f3fc',
      '1f3fd': '1f9db-1f3fd',
      '1f3fe': '1f9db-1f3fe',
      '1f3ff': '1f9db-1f3ff',
    },
  },
  '1f9db-1f3fb': {
    hexCodePoints: '1f9db-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏻',
    version: 'E5.0',
    baseName: 'vampire',
    fullName: 'vampire: light skin tone',
    isBase: false,
    baseHex: '1f9db',
    modifiers: null,
  },
  '1f9db-1f3fc': {
    hexCodePoints: '1f9db-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏼',
    version: 'E5.0',
    baseName: 'vampire',
    fullName: 'vampire: medium-light skin tone',
    isBase: false,
    baseHex: '1f9db',
    modifiers: null,
  },
  '1f9db-1f3fd': {
    hexCodePoints: '1f9db-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏽',
    version: 'E5.0',
    baseName: 'vampire',
    fullName: 'vampire: medium skin tone',
    isBase: false,
    baseHex: '1f9db',
    modifiers: null,
  },
  '1f9db-1f3fe': {
    hexCodePoints: '1f9db-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏾',
    version: 'E5.0',
    baseName: 'vampire',
    fullName: 'vampire: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9db',
    modifiers: null,
  },
  '1f9db-1f3ff': {
    hexCodePoints: '1f9db-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏿',
    version: 'E5.0',
    baseName: 'vampire',
    fullName: 'vampire: dark skin tone',
    isBase: false,
    baseHex: '1f9db',
    modifiers: null,
  },
  '1f9db-200d-2642-fe0f': {
    hexCodePoints: '1f9db-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛‍♂️',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire',
    isBase: true,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9db-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9db-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9db-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9db-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9db-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9db-200d-2642': {
    hexCodePoints: '1f9db-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛‍♂',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9db-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏻‍♂️',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fb-200d-2642': {
    hexCodePoints: '1f9db-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏻‍♂',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9db-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏼‍♂️',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: medium-light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fc-200d-2642': {
    hexCodePoints: '1f9db-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏼‍♂',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: medium-light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9db-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏽‍♂️',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: medium skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fd-200d-2642': {
    hexCodePoints: '1f9db-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏽‍♂',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: medium skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9db-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏾‍♂️',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fe-200d-2642': {
    hexCodePoints: '1f9db-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏾‍♂',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9db-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏿‍♂️',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-1f3ff-200d-2642': {
    hexCodePoints: '1f9db-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏿‍♂',
    version: 'E5.0',
    baseName: 'man vampire',
    fullName: 'man vampire: dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9db-200d-2640-fe0f': {
    hexCodePoints: '1f9db-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛‍♀️',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire',
    isBase: true,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9db-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9db-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9db-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9db-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9db-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9db-200d-2640': {
    hexCodePoints: '1f9db-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛‍♀',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9db-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fb-200d-2640': {
    hexCodePoints: '1f9db-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏻‍♀',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9db-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: medium-light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fc-200d-2640': {
    hexCodePoints: '1f9db-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏼‍♀',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: medium-light skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9db-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: medium skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fd-200d-2640': {
    hexCodePoints: '1f9db-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏽‍♀',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: medium skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9db-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3fe-200d-2640': {
    hexCodePoints: '1f9db-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏾‍♀',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9db-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧛🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9db-1f3ff-200d-2640': {
    hexCodePoints: '1f9db-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧛🏿‍♀',
    version: 'E5.0',
    baseName: 'woman vampire',
    fullName: 'woman vampire: dark skin tone',
    isBase: false,
    baseHex: '1f9db-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc': {
    hexCodePoints: '1f9dc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜',
    version: 'E5.0',
    baseName: 'merperson',
    fullName: 'merperson',
    isBase: true,
    baseHex: '1f9dc',
    modifiers: {
      '1f3fb': '1f9dc-1f3fb',
      '1f3fc': '1f9dc-1f3fc',
      '1f3fd': '1f9dc-1f3fd',
      '1f3fe': '1f9dc-1f3fe',
      '1f3ff': '1f9dc-1f3ff',
    },
  },
  '1f9dc-1f3fb': {
    hexCodePoints: '1f9dc-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏻',
    version: 'E5.0',
    baseName: 'merperson',
    fullName: 'merperson: light skin tone',
    isBase: false,
    baseHex: '1f9dc',
    modifiers: null,
  },
  '1f9dc-1f3fc': {
    hexCodePoints: '1f9dc-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏼',
    version: 'E5.0',
    baseName: 'merperson',
    fullName: 'merperson: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dc',
    modifiers: null,
  },
  '1f9dc-1f3fd': {
    hexCodePoints: '1f9dc-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏽',
    version: 'E5.0',
    baseName: 'merperson',
    fullName: 'merperson: medium skin tone',
    isBase: false,
    baseHex: '1f9dc',
    modifiers: null,
  },
  '1f9dc-1f3fe': {
    hexCodePoints: '1f9dc-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏾',
    version: 'E5.0',
    baseName: 'merperson',
    fullName: 'merperson: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dc',
    modifiers: null,
  },
  '1f9dc-1f3ff': {
    hexCodePoints: '1f9dc-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏿',
    version: 'E5.0',
    baseName: 'merperson',
    fullName: 'merperson: dark skin tone',
    isBase: false,
    baseHex: '1f9dc',
    modifiers: null,
  },
  '1f9dc-200d-2642-fe0f': {
    hexCodePoints: '1f9dc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜‍♂️',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman',
    isBase: true,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9dc-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9dc-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9dc-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9dc-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9dc-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9dc-200d-2642': {
    hexCodePoints: '1f9dc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜‍♂',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9dc-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏻‍♂️',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fb-200d-2642': {
    hexCodePoints: '1f9dc-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏻‍♂',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9dc-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏼‍♂️',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fc-200d-2642': {
    hexCodePoints: '1f9dc-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏼‍♂',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9dc-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏽‍♂️',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: medium skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fd-200d-2642': {
    hexCodePoints: '1f9dc-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏽‍♂',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: medium skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9dc-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏾‍♂️',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fe-200d-2642': {
    hexCodePoints: '1f9dc-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏾‍♂',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9dc-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏿‍♂️',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3ff-200d-2642': {
    hexCodePoints: '1f9dc-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏿‍♂',
    version: 'E5.0',
    baseName: 'merman',
    fullName: 'merman: dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dc-200d-2640-fe0f': {
    hexCodePoints: '1f9dc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜‍♀️',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid',
    isBase: true,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9dc-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9dc-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9dc-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9dc-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9dc-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9dc-200d-2640': {
    hexCodePoints: '1f9dc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜‍♀',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9dc-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏻‍♀️',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fb-200d-2640': {
    hexCodePoints: '1f9dc-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏻‍♀',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9dc-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏼‍♀️',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fc-200d-2640': {
    hexCodePoints: '1f9dc-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏼‍♀',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9dc-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏽‍♀️',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: medium skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fd-200d-2640': {
    hexCodePoints: '1f9dc-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏽‍♀',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: medium skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9dc-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏾‍♀️',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3fe-200d-2640': {
    hexCodePoints: '1f9dc-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏾‍♀',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9dc-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧜🏿‍♀️',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dc-1f3ff-200d-2640': {
    hexCodePoints: '1f9dc-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧜🏿‍♀',
    version: 'E5.0',
    baseName: 'mermaid',
    fullName: 'mermaid: dark skin tone',
    isBase: false,
    baseHex: '1f9dc-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd': {
    hexCodePoints: '1f9dd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝',
    version: 'E5.0',
    baseName: 'elf',
    fullName: 'elf',
    isBase: true,
    baseHex: '1f9dd',
    modifiers: {
      '1f3fb': '1f9dd-1f3fb',
      '1f3fc': '1f9dd-1f3fc',
      '1f3fd': '1f9dd-1f3fd',
      '1f3fe': '1f9dd-1f3fe',
      '1f3ff': '1f9dd-1f3ff',
    },
  },
  '1f9dd-1f3fb': {
    hexCodePoints: '1f9dd-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏻',
    version: 'E5.0',
    baseName: 'elf',
    fullName: 'elf: light skin tone',
    isBase: false,
    baseHex: '1f9dd',
    modifiers: null,
  },
  '1f9dd-1f3fc': {
    hexCodePoints: '1f9dd-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏼',
    version: 'E5.0',
    baseName: 'elf',
    fullName: 'elf: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dd',
    modifiers: null,
  },
  '1f9dd-1f3fd': {
    hexCodePoints: '1f9dd-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏽',
    version: 'E5.0',
    baseName: 'elf',
    fullName: 'elf: medium skin tone',
    isBase: false,
    baseHex: '1f9dd',
    modifiers: null,
  },
  '1f9dd-1f3fe': {
    hexCodePoints: '1f9dd-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏾',
    version: 'E5.0',
    baseName: 'elf',
    fullName: 'elf: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dd',
    modifiers: null,
  },
  '1f9dd-1f3ff': {
    hexCodePoints: '1f9dd-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏿',
    version: 'E5.0',
    baseName: 'elf',
    fullName: 'elf: dark skin tone',
    isBase: false,
    baseHex: '1f9dd',
    modifiers: null,
  },
  '1f9dd-200d-2642-fe0f': {
    hexCodePoints: '1f9dd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝‍♂️',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf',
    isBase: true,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9dd-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9dd-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9dd-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9dd-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9dd-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9dd-200d-2642': {
    hexCodePoints: '1f9dd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝‍♂',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9dd-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏻‍♂️',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fb-200d-2642': {
    hexCodePoints: '1f9dd-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏻‍♂',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9dd-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏼‍♂️',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fc-200d-2642': {
    hexCodePoints: '1f9dd-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏼‍♂',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9dd-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏽‍♂️',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: medium skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fd-200d-2642': {
    hexCodePoints: '1f9dd-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏽‍♂',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: medium skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9dd-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏾‍♂️',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fe-200d-2642': {
    hexCodePoints: '1f9dd-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏾‍♂',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9dd-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏿‍♂️',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3ff-200d-2642': {
    hexCodePoints: '1f9dd-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏿‍♂',
    version: 'E5.0',
    baseName: 'man elf',
    fullName: 'man elf: dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9dd-200d-2640-fe0f': {
    hexCodePoints: '1f9dd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝‍♀️',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf',
    isBase: true,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9dd-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9dd-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9dd-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9dd-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9dd-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9dd-200d-2640': {
    hexCodePoints: '1f9dd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝‍♀',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9dd-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fb-200d-2640': {
    hexCodePoints: '1f9dd-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏻‍♀',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9dd-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fc-200d-2640': {
    hexCodePoints: '1f9dd-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏼‍♀',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: medium-light skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9dd-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: medium skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fd-200d-2640': {
    hexCodePoints: '1f9dd-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏽‍♀',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: medium skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9dd-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3fe-200d-2640': {
    hexCodePoints: '1f9dd-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏾‍♀',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9dd-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧝🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9dd-1f3ff-200d-2640': {
    hexCodePoints: '1f9dd-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧝🏿‍♀',
    version: 'E5.0',
    baseName: 'woman elf',
    fullName: 'woman elf: dark skin tone',
    isBase: false,
    baseHex: '1f9dd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9de': {
    hexCodePoints: '1f9de',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧞',
    version: 'E5.0',
    baseName: 'genie',
    fullName: 'genie',
    isBase: true,
    baseHex: '1f9de',
    modifiers: null,
  },
  '1f9de-200d-2642-fe0f': {
    hexCodePoints: '1f9de-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧞‍♂️',
    version: 'E5.0',
    baseName: 'man genie',
    fullName: 'man genie',
    isBase: true,
    baseHex: '1f9de-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9de-200d-2642': {
    hexCodePoints: '1f9de-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧞‍♂',
    version: 'E5.0',
    baseName: 'man genie',
    fullName: 'man genie',
    isBase: false,
    baseHex: '1f9de-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9de-200d-2640-fe0f': {
    hexCodePoints: '1f9de-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧞‍♀️',
    version: 'E5.0',
    baseName: 'woman genie',
    fullName: 'woman genie',
    isBase: true,
    baseHex: '1f9de-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9de-200d-2640': {
    hexCodePoints: '1f9de-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧞‍♀',
    version: 'E5.0',
    baseName: 'woman genie',
    fullName: 'woman genie',
    isBase: false,
    baseHex: '1f9de-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9df': {
    hexCodePoints: '1f9df',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧟',
    version: 'E5.0',
    baseName: 'zombie',
    fullName: 'zombie',
    isBase: true,
    baseHex: '1f9df',
    modifiers: null,
  },
  '1f9df-200d-2642-fe0f': {
    hexCodePoints: '1f9df-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧟‍♂️',
    version: 'E5.0',
    baseName: 'man zombie',
    fullName: 'man zombie',
    isBase: true,
    baseHex: '1f9df-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9df-200d-2642': {
    hexCodePoints: '1f9df-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧟‍♂',
    version: 'E5.0',
    baseName: 'man zombie',
    fullName: 'man zombie',
    isBase: false,
    baseHex: '1f9df-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9df-200d-2640-fe0f': {
    hexCodePoints: '1f9df-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧟‍♀️',
    version: 'E5.0',
    baseName: 'woman zombie',
    fullName: 'woman zombie',
    isBase: true,
    baseHex: '1f9df-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9df-200d-2640': {
    hexCodePoints: '1f9df-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧟‍♀',
    version: 'E5.0',
    baseName: 'woman zombie',
    fullName: 'woman zombie',
    isBase: false,
    baseHex: '1f9df-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cc': {
    hexCodePoints: '1f9cc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧌',
    version: 'E14.0',
    baseName: 'troll',
    fullName: 'troll',
    isBase: true,
    baseHex: '1f9cc',
    modifiers: null,
  },
  '1f486': {
    hexCodePoints: '1f486',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆',
    version: 'E0.6',
    baseName: 'person getting massage',
    fullName: 'person getting massage',
    isBase: true,
    baseHex: '1f486',
    modifiers: {
      '1f3fb': '1f486-1f3fb',
      '1f3fc': '1f486-1f3fc',
      '1f3fd': '1f486-1f3fd',
      '1f3fe': '1f486-1f3fe',
      '1f3ff': '1f486-1f3ff',
    },
  },
  '1f486-1f3fb': {
    hexCodePoints: '1f486-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏻',
    version: 'E1.0',
    baseName: 'person getting massage',
    fullName: 'person getting massage: light skin tone',
    isBase: false,
    baseHex: '1f486',
    modifiers: null,
  },
  '1f486-1f3fc': {
    hexCodePoints: '1f486-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏼',
    version: 'E1.0',
    baseName: 'person getting massage',
    fullName: 'person getting massage: medium-light skin tone',
    isBase: false,
    baseHex: '1f486',
    modifiers: null,
  },
  '1f486-1f3fd': {
    hexCodePoints: '1f486-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏽',
    version: 'E1.0',
    baseName: 'person getting massage',
    fullName: 'person getting massage: medium skin tone',
    isBase: false,
    baseHex: '1f486',
    modifiers: null,
  },
  '1f486-1f3fe': {
    hexCodePoints: '1f486-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏾',
    version: 'E1.0',
    baseName: 'person getting massage',
    fullName: 'person getting massage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f486',
    modifiers: null,
  },
  '1f486-1f3ff': {
    hexCodePoints: '1f486-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏿',
    version: 'E1.0',
    baseName: 'person getting massage',
    fullName: 'person getting massage: dark skin tone',
    isBase: false,
    baseHex: '1f486',
    modifiers: null,
  },
  '1f486-200d-2642-fe0f': {
    hexCodePoints: '1f486-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆‍♂️',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage',
    isBase: true,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f486-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f486-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f486-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f486-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f486-1f3ff-200d-2642-fe0f',
    },
  },
  '1f486-200d-2642': {
    hexCodePoints: '1f486-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆‍♂',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f486-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏻‍♂️',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fb-200d-2642': {
    hexCodePoints: '1f486-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏻‍♂',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f486-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏼‍♂️',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: medium-light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fc-200d-2642': {
    hexCodePoints: '1f486-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏼‍♂',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: medium-light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f486-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏽‍♂️',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: medium skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fd-200d-2642': {
    hexCodePoints: '1f486-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏽‍♂',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: medium skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f486-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏾‍♂️',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3fe-200d-2642': {
    hexCodePoints: '1f486-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏾‍♂',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f486-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏿‍♂️',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-1f3ff-200d-2642': {
    hexCodePoints: '1f486-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏿‍♂',
    version: 'E4.0',
    baseName: 'man getting massage',
    fullName: 'man getting massage: dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2642-fe0f',
    modifiers: null,
  },
  '1f486-200d-2640-fe0f': {
    hexCodePoints: '1f486-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆‍♀️',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage',
    isBase: true,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f486-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f486-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f486-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f486-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f486-1f3ff-200d-2640-fe0f',
    },
  },
  '1f486-200d-2640': {
    hexCodePoints: '1f486-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆‍♀',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f486-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fb-200d-2640': {
    hexCodePoints: '1f486-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏻‍♀',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f486-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: medium-light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fc-200d-2640': {
    hexCodePoints: '1f486-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏼‍♀',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: medium-light skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f486-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: medium skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fd-200d-2640': {
    hexCodePoints: '1f486-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏽‍♀',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: medium skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f486-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3fe-200d-2640': {
    hexCodePoints: '1f486-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏾‍♀',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: medium-dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f486-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💆🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f486-1f3ff-200d-2640': {
    hexCodePoints: '1f486-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💆🏿‍♀',
    version: 'E4.0',
    baseName: 'woman getting massage',
    fullName: 'woman getting massage: dark skin tone',
    isBase: false,
    baseHex: '1f486-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487': {
    hexCodePoints: '1f487',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇',
    version: 'E0.6',
    baseName: 'person getting haircut',
    fullName: 'person getting haircut',
    isBase: true,
    baseHex: '1f487',
    modifiers: {
      '1f3fb': '1f487-1f3fb',
      '1f3fc': '1f487-1f3fc',
      '1f3fd': '1f487-1f3fd',
      '1f3fe': '1f487-1f3fe',
      '1f3ff': '1f487-1f3ff',
    },
  },
  '1f487-1f3fb': {
    hexCodePoints: '1f487-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏻',
    version: 'E1.0',
    baseName: 'person getting haircut',
    fullName: 'person getting haircut: light skin tone',
    isBase: false,
    baseHex: '1f487',
    modifiers: null,
  },
  '1f487-1f3fc': {
    hexCodePoints: '1f487-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏼',
    version: 'E1.0',
    baseName: 'person getting haircut',
    fullName: 'person getting haircut: medium-light skin tone',
    isBase: false,
    baseHex: '1f487',
    modifiers: null,
  },
  '1f487-1f3fd': {
    hexCodePoints: '1f487-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏽',
    version: 'E1.0',
    baseName: 'person getting haircut',
    fullName: 'person getting haircut: medium skin tone',
    isBase: false,
    baseHex: '1f487',
    modifiers: null,
  },
  '1f487-1f3fe': {
    hexCodePoints: '1f487-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏾',
    version: 'E1.0',
    baseName: 'person getting haircut',
    fullName: 'person getting haircut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f487',
    modifiers: null,
  },
  '1f487-1f3ff': {
    hexCodePoints: '1f487-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏿',
    version: 'E1.0',
    baseName: 'person getting haircut',
    fullName: 'person getting haircut: dark skin tone',
    isBase: false,
    baseHex: '1f487',
    modifiers: null,
  },
  '1f487-200d-2642-fe0f': {
    hexCodePoints: '1f487-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇‍♂️',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut',
    isBase: true,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f487-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f487-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f487-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f487-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f487-1f3ff-200d-2642-fe0f',
    },
  },
  '1f487-200d-2642': {
    hexCodePoints: '1f487-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇‍♂',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f487-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏻‍♂️',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fb-200d-2642': {
    hexCodePoints: '1f487-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏻‍♂',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f487-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏼‍♂️',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: medium-light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fc-200d-2642': {
    hexCodePoints: '1f487-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏼‍♂',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: medium-light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f487-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏽‍♂️',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: medium skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fd-200d-2642': {
    hexCodePoints: '1f487-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏽‍♂',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: medium skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f487-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏾‍♂️',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3fe-200d-2642': {
    hexCodePoints: '1f487-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏾‍♂',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f487-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏿‍♂️',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-1f3ff-200d-2642': {
    hexCodePoints: '1f487-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏿‍♂',
    version: 'E4.0',
    baseName: 'man getting haircut',
    fullName: 'man getting haircut: dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2642-fe0f',
    modifiers: null,
  },
  '1f487-200d-2640-fe0f': {
    hexCodePoints: '1f487-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇‍♀️',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut',
    isBase: true,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f487-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f487-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f487-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f487-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f487-1f3ff-200d-2640-fe0f',
    },
  },
  '1f487-200d-2640': {
    hexCodePoints: '1f487-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇‍♀',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f487-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fb-200d-2640': {
    hexCodePoints: '1f487-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏻‍♀',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f487-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: medium-light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fc-200d-2640': {
    hexCodePoints: '1f487-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏼‍♀',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: medium-light skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f487-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: medium skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fd-200d-2640': {
    hexCodePoints: '1f487-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏽‍♀',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: medium skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f487-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3fe-200d-2640': {
    hexCodePoints: '1f487-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏾‍♀',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: medium-dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f487-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💇🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f487-1f3ff-200d-2640': {
    hexCodePoints: '1f487-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '💇🏿‍♀',
    version: 'E4.0',
    baseName: 'woman getting haircut',
    fullName: 'woman getting haircut: dark skin tone',
    isBase: false,
    baseHex: '1f487-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6': {
    hexCodePoints: '1f6b6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶',
    version: 'E0.6',
    baseName: 'person walking',
    fullName: 'person walking',
    isBase: true,
    baseHex: '1f6b6',
    modifiers: {
      '1f3fb': '1f6b6-1f3fb',
      '1f3fc': '1f6b6-1f3fc',
      '1f3fd': '1f6b6-1f3fd',
      '1f3fe': '1f6b6-1f3fe',
      '1f3ff': '1f6b6-1f3ff',
    },
  },
  '1f6b6-1f3fb': {
    hexCodePoints: '1f6b6-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏻',
    version: 'E1.0',
    baseName: 'person walking',
    fullName: 'person walking: light skin tone',
    isBase: false,
    baseHex: '1f6b6',
    modifiers: null,
  },
  '1f6b6-1f3fc': {
    hexCodePoints: '1f6b6-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏼',
    version: 'E1.0',
    baseName: 'person walking',
    fullName: 'person walking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6',
    modifiers: null,
  },
  '1f6b6-1f3fd': {
    hexCodePoints: '1f6b6-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏽',
    version: 'E1.0',
    baseName: 'person walking',
    fullName: 'person walking: medium skin tone',
    isBase: false,
    baseHex: '1f6b6',
    modifiers: null,
  },
  '1f6b6-1f3fe': {
    hexCodePoints: '1f6b6-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏾',
    version: 'E1.0',
    baseName: 'person walking',
    fullName: 'person walking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6',
    modifiers: null,
  },
  '1f6b6-1f3ff': {
    hexCodePoints: '1f6b6-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏿',
    version: 'E1.0',
    baseName: 'person walking',
    fullName: 'person walking: dark skin tone',
    isBase: false,
    baseHex: '1f6b6',
    modifiers: null,
  },
  '1f6b6-200d-2642-fe0f': {
    hexCodePoints: '1f6b6-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶‍♂️',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking',
    isBase: true,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f6b6-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f6b6-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f6b6-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f6b6-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f6b6-1f3ff-200d-2642-fe0f',
    },
  },
  '1f6b6-200d-2642': {
    hexCodePoints: '1f6b6-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♂',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏻‍♂️',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2642': {
    hexCodePoints: '1f6b6-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♂',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏼‍♂️',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2642': {
    hexCodePoints: '1f6b6-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♂',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏽‍♂️',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2642': {
    hexCodePoints: '1f6b6-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♂',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏾‍♂️',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2642': {
    hexCodePoints: '1f6b6-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♂',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏿‍♂️',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2642': {
    hexCodePoints: '1f6b6-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♂',
    version: 'E4.0',
    baseName: 'man walking',
    fullName: 'man walking: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2640-fe0f': {
    hexCodePoints: '1f6b6-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶‍♀️',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking',
    isBase: true,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f6b6-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f6b6-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f6b6-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f6b6-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f6b6-1f3ff-200d-2640-fe0f',
    },
  },
  '1f6b6-200d-2640': {
    hexCodePoints: '1f6b6-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♀',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2640': {
    hexCodePoints: '1f6b6-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♀',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2640': {
    hexCodePoints: '1f6b6-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♀',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2640': {
    hexCodePoints: '1f6b6-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♀',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2640': {
    hexCodePoints: '1f6b6-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♀',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2640': {
    hexCodePoints: '1f6b6-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♀',
    version: 'E4.0',
    baseName: 'woman walking',
    fullName: 'woman walking: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶‍➡️',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right',
    isBase: true,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f6b6-1f3fb-200d-27a1-fe0f',
      '1f3fc': '1f6b6-1f3fc-200d-27a1-fe0f',
      '1f3fd': '1f6b6-1f3fd-200d-27a1-fe0f',
      '1f3fe': '1f6b6-1f3fe-200d-27a1-fe0f',
      '1f3ff': '1f6b6-1f3ff-200d-27a1-fe0f',
    },
  },
  '1f6b6-200d-27a1': {
    hexCodePoints: '1f6b6-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍➡',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏻‍➡️',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fb-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍➡',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏼‍➡️',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍➡',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏽‍➡️',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍➡',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏾‍➡️',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fe-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍➡',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏿‍➡️',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-27a1': {
    hexCodePoints: '1f6b6-1f3ff-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍➡',
    version: 'E15.1',
    baseName: 'person walking facing right',
    fullName: 'person walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right',
    isBase: true,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f6b6-1f3fb-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fc': '1f6b6-1f3fc-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fd': '1f6b6-1f3fd-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fe': '1f6b6-1f3fe-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3ff': '1f6b6-1f3ff-200d-2640-fe0f-200d-27a1-fe0f',
    },
  },
  '1f6b6-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2640-200d-27a1': {
    hexCodePoints: '1f6b6-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏻‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fb-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2640-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fb-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏼‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fc-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2640-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fc-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏽‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fd-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2640-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fd-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏾‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fe-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2640-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fe-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏿‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3ff-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2640-200d-27a1': {
    hexCodePoints: '1f6b6-1f3ff-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman walking facing right',
    fullName: 'woman walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right',
    isBase: true,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f6b6-1f3fb-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fc': '1f6b6-1f3fc-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fd': '1f6b6-1f3fd-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fe': '1f6b6-1f3fe-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3ff': '1f6b6-1f3ff-200d-2642-fe0f-200d-27a1-fe0f',
    },
  },
  '1f6b6-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-200d-2642-200d-27a1': {
    hexCodePoints: '1f6b6-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶‍♂‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏻‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fb-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fb-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fb-200d-2642-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fb-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏻‍♂‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏼‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fc-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fc-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fc-200d-2642-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fc-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏼‍♂‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏽‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fd-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fd-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fd-200d-2642-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fd-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏽‍♂‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏾‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3fe-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fe-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3fe-200d-2642-200d-27a1': {
    hexCodePoints: '1f6b6-1f3fe-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏾‍♂‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚶🏿‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f6b6-1f3ff-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f6b6-1f3ff-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f6b6-1f3ff-200d-2642-200d-27a1': {
    hexCodePoints: '1f6b6-1f3ff-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚶🏿‍♂‍➡',
    version: 'E15.1',
    baseName: 'man walking facing right',
    fullName: 'man walking facing right: dark skin tone',
    isBase: false,
    baseHex: '1f6b6-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9cd': {
    hexCodePoints: '1f9cd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍',
    version: 'E12.0',
    baseName: 'person standing',
    fullName: 'person standing',
    isBase: true,
    baseHex: '1f9cd',
    modifiers: {
      '1f3fb': '1f9cd-1f3fb',
      '1f3fc': '1f9cd-1f3fc',
      '1f3fd': '1f9cd-1f3fd',
      '1f3fe': '1f9cd-1f3fe',
      '1f3ff': '1f9cd-1f3ff',
    },
  },
  '1f9cd-1f3fb': {
    hexCodePoints: '1f9cd-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏻',
    version: 'E12.0',
    baseName: 'person standing',
    fullName: 'person standing: light skin tone',
    isBase: false,
    baseHex: '1f9cd',
    modifiers: null,
  },
  '1f9cd-1f3fc': {
    hexCodePoints: '1f9cd-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏼',
    version: 'E12.0',
    baseName: 'person standing',
    fullName: 'person standing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cd',
    modifiers: null,
  },
  '1f9cd-1f3fd': {
    hexCodePoints: '1f9cd-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏽',
    version: 'E12.0',
    baseName: 'person standing',
    fullName: 'person standing: medium skin tone',
    isBase: false,
    baseHex: '1f9cd',
    modifiers: null,
  },
  '1f9cd-1f3fe': {
    hexCodePoints: '1f9cd-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏾',
    version: 'E12.0',
    baseName: 'person standing',
    fullName: 'person standing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cd',
    modifiers: null,
  },
  '1f9cd-1f3ff': {
    hexCodePoints: '1f9cd-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏿',
    version: 'E12.0',
    baseName: 'person standing',
    fullName: 'person standing: dark skin tone',
    isBase: false,
    baseHex: '1f9cd',
    modifiers: null,
  },
  '1f9cd-200d-2642-fe0f': {
    hexCodePoints: '1f9cd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍‍♂️',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing',
    isBase: true,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9cd-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9cd-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9cd-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9cd-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9cd-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9cd-200d-2642': {
    hexCodePoints: '1f9cd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍‍♂',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9cd-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏻‍♂️',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fb-200d-2642': {
    hexCodePoints: '1f9cd-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏻‍♂',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9cd-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏼‍♂️',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fc-200d-2642': {
    hexCodePoints: '1f9cd-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏼‍♂',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9cd-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏽‍♂️',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: medium skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fd-200d-2642': {
    hexCodePoints: '1f9cd-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏽‍♂',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: medium skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9cd-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏾‍♂️',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fe-200d-2642': {
    hexCodePoints: '1f9cd-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏾‍♂',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9cd-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏿‍♂️',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3ff-200d-2642': {
    hexCodePoints: '1f9cd-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏿‍♂',
    version: 'E12.0',
    baseName: 'man standing',
    fullName: 'man standing: dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9cd-200d-2640-fe0f': {
    hexCodePoints: '1f9cd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍‍♀️',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing',
    isBase: true,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9cd-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9cd-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9cd-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9cd-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9cd-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9cd-200d-2640': {
    hexCodePoints: '1f9cd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍‍♀',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9cd-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏻‍♀️',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fb-200d-2640': {
    hexCodePoints: '1f9cd-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏻‍♀',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9cd-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏼‍♀️',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fc-200d-2640': {
    hexCodePoints: '1f9cd-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏼‍♀',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9cd-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏽‍♀️',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: medium skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fd-200d-2640': {
    hexCodePoints: '1f9cd-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏽‍♀',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: medium skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9cd-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏾‍♀️',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3fe-200d-2640': {
    hexCodePoints: '1f9cd-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏾‍♀',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9cd-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧍🏿‍♀️',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9cd-1f3ff-200d-2640': {
    hexCodePoints: '1f9cd-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧍🏿‍♀',
    version: 'E12.0',
    baseName: 'woman standing',
    fullName: 'woman standing: dark skin tone',
    isBase: false,
    baseHex: '1f9cd-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce': {
    hexCodePoints: '1f9ce',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎',
    version: 'E12.0',
    baseName: 'person kneeling',
    fullName: 'person kneeling',
    isBase: true,
    baseHex: '1f9ce',
    modifiers: {
      '1f3fb': '1f9ce-1f3fb',
      '1f3fc': '1f9ce-1f3fc',
      '1f3fd': '1f9ce-1f3fd',
      '1f3fe': '1f9ce-1f3fe',
      '1f3ff': '1f9ce-1f3ff',
    },
  },
  '1f9ce-1f3fb': {
    hexCodePoints: '1f9ce-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏻',
    version: 'E12.0',
    baseName: 'person kneeling',
    fullName: 'person kneeling: light skin tone',
    isBase: false,
    baseHex: '1f9ce',
    modifiers: null,
  },
  '1f9ce-1f3fc': {
    hexCodePoints: '1f9ce-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏼',
    version: 'E12.0',
    baseName: 'person kneeling',
    fullName: 'person kneeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce',
    modifiers: null,
  },
  '1f9ce-1f3fd': {
    hexCodePoints: '1f9ce-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏽',
    version: 'E12.0',
    baseName: 'person kneeling',
    fullName: 'person kneeling: medium skin tone',
    isBase: false,
    baseHex: '1f9ce',
    modifiers: null,
  },
  '1f9ce-1f3fe': {
    hexCodePoints: '1f9ce-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏾',
    version: 'E12.0',
    baseName: 'person kneeling',
    fullName: 'person kneeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce',
    modifiers: null,
  },
  '1f9ce-1f3ff': {
    hexCodePoints: '1f9ce-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏿',
    version: 'E12.0',
    baseName: 'person kneeling',
    fullName: 'person kneeling: dark skin tone',
    isBase: false,
    baseHex: '1f9ce',
    modifiers: null,
  },
  '1f9ce-200d-2642-fe0f': {
    hexCodePoints: '1f9ce-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎‍♂️',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling',
    isBase: true,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9ce-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9ce-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9ce-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9ce-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9ce-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9ce-200d-2642': {
    hexCodePoints: '1f9ce-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♂',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏻‍♂️',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2642': {
    hexCodePoints: '1f9ce-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♂',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏼‍♂️',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2642': {
    hexCodePoints: '1f9ce-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♂',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏽‍♂️',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2642': {
    hexCodePoints: '1f9ce-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♂',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏾‍♂️',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2642': {
    hexCodePoints: '1f9ce-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♂',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏿‍♂️',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2642': {
    hexCodePoints: '1f9ce-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♂',
    version: 'E12.0',
    baseName: 'man kneeling',
    fullName: 'man kneeling: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2640-fe0f': {
    hexCodePoints: '1f9ce-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎‍♀️',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling',
    isBase: true,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9ce-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9ce-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9ce-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9ce-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9ce-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9ce-200d-2640': {
    hexCodePoints: '1f9ce-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♀',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏻‍♀️',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2640': {
    hexCodePoints: '1f9ce-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♀',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏼‍♀️',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2640': {
    hexCodePoints: '1f9ce-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♀',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏽‍♀️',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2640': {
    hexCodePoints: '1f9ce-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♀',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏾‍♀️',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2640': {
    hexCodePoints: '1f9ce-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♀',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏿‍♀️',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2640': {
    hexCodePoints: '1f9ce-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♀',
    version: 'E12.0',
    baseName: 'woman kneeling',
    fullName: 'woman kneeling: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎‍➡️',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right',
    isBase: true,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f9ce-1f3fb-200d-27a1-fe0f',
      '1f3fc': '1f9ce-1f3fc-200d-27a1-fe0f',
      '1f3fd': '1f9ce-1f3fd-200d-27a1-fe0f',
      '1f3fe': '1f9ce-1f3fe-200d-27a1-fe0f',
      '1f3ff': '1f9ce-1f3ff-200d-27a1-fe0f',
    },
  },
  '1f9ce-200d-27a1': {
    hexCodePoints: '1f9ce-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍➡',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏻‍➡️',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fb-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍➡',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏼‍➡️',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍➡',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏽‍➡️',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍➡',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏾‍➡️',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fe-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍➡',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏿‍➡️',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-27a1': {
    hexCodePoints: '1f9ce-1f3ff-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍➡',
    version: 'E15.1',
    baseName: 'person kneeling facing right',
    fullName: 'person kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right',
    isBase: true,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f9ce-1f3fb-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fc': '1f9ce-1f3fc-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fd': '1f9ce-1f3fd-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fe': '1f9ce-1f3fe-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3ff': '1f9ce-1f3ff-200d-2640-fe0f-200d-27a1-fe0f',
    },
  },
  '1f9ce-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2640-200d-27a1': {
    hexCodePoints: '1f9ce-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏻‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fb-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2640-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fb-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏼‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fc-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2640-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fc-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏽‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fd-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2640-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fd-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏾‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fe-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2640-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fe-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏿‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3ff-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2640-200d-27a1': {
    hexCodePoints: '1f9ce-1f3ff-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman kneeling facing right',
    fullName: 'woman kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right',
    isBase: true,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f9ce-1f3fb-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fc': '1f9ce-1f3fc-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fd': '1f9ce-1f3fd-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fe': '1f9ce-1f3fe-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3ff': '1f9ce-1f3ff-200d-2642-fe0f-200d-27a1-fe0f',
    },
  },
  '1f9ce-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-200d-2642-200d-27a1': {
    hexCodePoints: '1f9ce-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎‍♂‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏻‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fb-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fb-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fb-200d-2642-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fb-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏻‍♂‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏼‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fc-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fc-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fc-200d-2642-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fc-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏼‍♂‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏽‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fd-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fd-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fd-200d-2642-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fd-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏽‍♂‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏾‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3fe-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fe-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3fe-200d-2642-200d-27a1': {
    hexCodePoints: '1f9ce-1f3fe-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏾‍♂‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧎🏿‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f9ce-1f3ff-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f9ce-1f3ff-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9ce-1f3ff-200d-2642-200d-27a1': {
    hexCodePoints: '1f9ce-1f3ff-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧎🏿‍♂‍➡',
    version: 'E15.1',
    baseName: 'man kneeling facing right',
    fullName: 'man kneeling facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9ce-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-1f9af': {
    hexCodePoints: '1f9d1-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦯',
    version: 'E12.1',
    baseName: 'person with white cane',
    fullName: 'person with white cane',
    isBase: true,
    baseHex: '1f9d1-200d-1f9af',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9af',
      '1f3fc': '1f9d1-1f3fc-200d-1f9af',
      '1f3fd': '1f9d1-1f3fd-200d-1f9af',
      '1f3fe': '1f9d1-1f3fe-200d-1f9af',
      '1f3ff': '1f9d1-1f3ff-200d-1f9af',
    },
  },
  '1f9d1-1f3fb-200d-1f9af': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦯',
    version: 'E12.1',
    baseName: 'person with white cane',
    fullName: 'person with white cane: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9af': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦯',
    version: 'E12.1',
    baseName: 'person with white cane',
    fullName: 'person with white cane: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9af': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦯',
    version: 'E12.1',
    baseName: 'person with white cane',
    fullName: 'person with white cane: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9af': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦯',
    version: 'E12.1',
    baseName: 'person with white cane',
    fullName: 'person with white cane: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9af': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦯',
    version: 'E12.1',
    baseName: 'person with white cane',
    fullName: 'person with white cane: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af',
    modifiers: null,
  },
  '1f9d1-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right',
    isBase: true,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9af-200d-27a1-fe0f',
      '1f3fc': '1f9d1-1f3fc-200d-1f9af-200d-27a1-fe0f',
      '1f3fd': '1f9d1-1f3fd-200d-1f9af-200d-27a1-fe0f',
      '1f3fe': '1f9d1-1f3fe-200d-1f9af-200d-27a1-fe0f',
      '1f3ff': '1f9d1-1f3ff-200d-1f9af-200d-27a1-fe0f',
    },
  },
  '1f9d1-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f9d1-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑‍🦯‍➡',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍🦯‍➡',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍🦯‍➡',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍🦯‍➡',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍🦯‍➡',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍🦯‍➡',
    version: 'E15.1',
    baseName: 'person with white cane facing right',
    fullName: 'person with white cane facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-200d-1f9af': {
    hexCodePoints: '1f468-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦯',
    version: 'E12.0',
    baseName: 'man with white cane',
    fullName: 'man with white cane',
    isBase: true,
    baseHex: '1f468-200d-1f9af',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9af',
      '1f3fc': '1f468-1f3fc-200d-1f9af',
      '1f3fd': '1f468-1f3fd-200d-1f9af',
      '1f3fe': '1f468-1f3fe-200d-1f9af',
      '1f3ff': '1f468-1f3ff-200d-1f9af',
    },
  },
  '1f468-1f3fb-200d-1f9af': {
    hexCodePoints: '1f468-1f3fb-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦯',
    version: 'E12.0',
    baseName: 'man with white cane',
    fullName: 'man with white cane: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9af': {
    hexCodePoints: '1f468-1f3fc-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦯',
    version: 'E12.0',
    baseName: 'man with white cane',
    fullName: 'man with white cane: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9af': {
    hexCodePoints: '1f468-1f3fd-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦯',
    version: 'E12.0',
    baseName: 'man with white cane',
    fullName: 'man with white cane: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9af': {
    hexCodePoints: '1f468-1f3fe-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦯',
    version: 'E12.0',
    baseName: 'man with white cane',
    fullName: 'man with white cane: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9af': {
    hexCodePoints: '1f468-1f3ff-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦯',
    version: 'E12.0',
    baseName: 'man with white cane',
    fullName: 'man with white cane: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af',
    modifiers: null,
  },
  '1f468-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f468-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right',
    isBase: true,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9af-200d-27a1-fe0f',
      '1f3fc': '1f468-1f3fc-200d-1f9af-200d-27a1-fe0f',
      '1f3fd': '1f468-1f3fd-200d-1f9af-200d-27a1-fe0f',
      '1f3fe': '1f468-1f3fe-200d-1f9af-200d-27a1-fe0f',
      '1f3ff': '1f468-1f3ff-200d-1f9af-200d-27a1-fe0f',
    },
  },
  '1f468-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f468-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍🦯‍➡',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fb-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f468-1f3fb-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍🦯‍➡',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fc-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f468-1f3fc-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍🦯‍➡',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fd-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f468-1f3fd-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍🦯‍➡',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fe-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f468-1f3fe-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍🦯‍➡',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3ff-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f468-1f3ff-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍🦯‍➡',
    version: 'E15.1',
    baseName: 'man with white cane facing right',
    fullName: 'man with white cane facing right: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-200d-1f9af': {
    hexCodePoints: '1f469-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦯',
    version: 'E12.0',
    baseName: 'woman with white cane',
    fullName: 'woman with white cane',
    isBase: true,
    baseHex: '1f469-200d-1f9af',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9af',
      '1f3fc': '1f469-1f3fc-200d-1f9af',
      '1f3fd': '1f469-1f3fd-200d-1f9af',
      '1f3fe': '1f469-1f3fe-200d-1f9af',
      '1f3ff': '1f469-1f3ff-200d-1f9af',
    },
  },
  '1f469-1f3fb-200d-1f9af': {
    hexCodePoints: '1f469-1f3fb-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦯',
    version: 'E12.0',
    baseName: 'woman with white cane',
    fullName: 'woman with white cane: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9af': {
    hexCodePoints: '1f469-1f3fc-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦯',
    version: 'E12.0',
    baseName: 'woman with white cane',
    fullName: 'woman with white cane: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9af': {
    hexCodePoints: '1f469-1f3fd-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦯',
    version: 'E12.0',
    baseName: 'woman with white cane',
    fullName: 'woman with white cane: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9af': {
    hexCodePoints: '1f469-1f3fe-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦯',
    version: 'E12.0',
    baseName: 'woman with white cane',
    fullName: 'woman with white cane: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9af': {
    hexCodePoints: '1f469-1f3ff-200d-1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦯',
    version: 'E12.0',
    baseName: 'woman with white cane',
    fullName: 'woman with white cane: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af',
    modifiers: null,
  },
  '1f469-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f469-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right',
    isBase: true,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9af-200d-27a1-fe0f',
      '1f3fc': '1f469-1f3fc-200d-1f9af-200d-27a1-fe0f',
      '1f3fd': '1f469-1f3fd-200d-1f9af-200d-27a1-fe0f',
      '1f3fe': '1f469-1f3fe-200d-1f9af-200d-27a1-fe0f',
      '1f3ff': '1f469-1f3ff-200d-1f9af-200d-27a1-fe0f',
    },
  },
  '1f469-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f469-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍🦯‍➡',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fb-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f469-1f3fb-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍🦯‍➡',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fc-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f469-1f3fc-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍🦯‍➡',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fd-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f469-1f3fd-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍🦯‍➡',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fe-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f469-1f3fe-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍🦯‍➡',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9af-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3ff-200d-1f9af-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦯‍➡️',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9af-200d-27a1': {
    hexCodePoints: '1f469-1f3ff-200d-1f9af-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍🦯‍➡',
    version: 'E15.1',
    baseName: 'woman with white cane facing right',
    fullName: 'woman with white cane facing right: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9af-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-1f9bc': {
    hexCodePoints: '1f9d1-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦼',
    version: 'E12.1',
    baseName: 'person in motorized wheelchair',
    fullName: 'person in motorized wheelchair',
    isBase: true,
    baseHex: '1f9d1-200d-1f9bc',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9bc',
      '1f3fc': '1f9d1-1f3fc-200d-1f9bc',
      '1f3fd': '1f9d1-1f3fd-200d-1f9bc',
      '1f3fe': '1f9d1-1f3fe-200d-1f9bc',
      '1f3ff': '1f9d1-1f3ff-200d-1f9bc',
    },
  },
  '1f9d1-1f3fb-200d-1f9bc': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦼',
    version: 'E12.1',
    baseName: 'person in motorized wheelchair',
    fullName: 'person in motorized wheelchair: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9bc': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦼',
    version: 'E12.1',
    baseName: 'person in motorized wheelchair',
    fullName: 'person in motorized wheelchair: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9bc': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦼',
    version: 'E12.1',
    baseName: 'person in motorized wheelchair',
    fullName: 'person in motorized wheelchair: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9bc': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦼',
    version: 'E12.1',
    baseName: 'person in motorized wheelchair',
    fullName: 'person in motorized wheelchair: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9bc': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦼',
    version: 'E12.1',
    baseName: 'person in motorized wheelchair',
    fullName: 'person in motorized wheelchair: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc',
    modifiers: null,
  },
  '1f9d1-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right',
    isBase: true,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9bc-200d-27a1-fe0f',
      '1f3fc': '1f9d1-1f3fc-200d-1f9bc-200d-27a1-fe0f',
      '1f3fd': '1f9d1-1f3fd-200d-1f9bc-200d-27a1-fe0f',
      '1f3fe': '1f9d1-1f3fe-200d-1f9bc-200d-27a1-fe0f',
      '1f3ff': '1f9d1-1f3ff-200d-1f9bc-200d-27a1-fe0f',
    },
  },
  '1f9d1-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f9d1-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑‍🦼‍➡',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍🦼‍➡',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍🦼‍➡',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍🦼‍➡',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍🦼‍➡',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍🦼‍➡',
    version: 'E15.1',
    baseName: 'person in motorized wheelchair facing right',
    fullName: 'person in motorized wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-200d-1f9bc': {
    hexCodePoints: '1f468-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦼',
    version: 'E12.0',
    baseName: 'man in motorized wheelchair',
    fullName: 'man in motorized wheelchair',
    isBase: true,
    baseHex: '1f468-200d-1f9bc',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9bc',
      '1f3fc': '1f468-1f3fc-200d-1f9bc',
      '1f3fd': '1f468-1f3fd-200d-1f9bc',
      '1f3fe': '1f468-1f3fe-200d-1f9bc',
      '1f3ff': '1f468-1f3ff-200d-1f9bc',
    },
  },
  '1f468-1f3fb-200d-1f9bc': {
    hexCodePoints: '1f468-1f3fb-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦼',
    version: 'E12.0',
    baseName: 'man in motorized wheelchair',
    fullName: 'man in motorized wheelchair: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9bc': {
    hexCodePoints: '1f468-1f3fc-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦼',
    version: 'E12.0',
    baseName: 'man in motorized wheelchair',
    fullName: 'man in motorized wheelchair: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9bc': {
    hexCodePoints: '1f468-1f3fd-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦼',
    version: 'E12.0',
    baseName: 'man in motorized wheelchair',
    fullName: 'man in motorized wheelchair: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9bc': {
    hexCodePoints: '1f468-1f3fe-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦼',
    version: 'E12.0',
    baseName: 'man in motorized wheelchair',
    fullName: 'man in motorized wheelchair: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9bc': {
    hexCodePoints: '1f468-1f3ff-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦼',
    version: 'E12.0',
    baseName: 'man in motorized wheelchair',
    fullName: 'man in motorized wheelchair: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc',
    modifiers: null,
  },
  '1f468-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f468-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right',
    isBase: true,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9bc-200d-27a1-fe0f',
      '1f3fc': '1f468-1f3fc-200d-1f9bc-200d-27a1-fe0f',
      '1f3fd': '1f468-1f3fd-200d-1f9bc-200d-27a1-fe0f',
      '1f3fe': '1f468-1f3fe-200d-1f9bc-200d-27a1-fe0f',
      '1f3ff': '1f468-1f3ff-200d-1f9bc-200d-27a1-fe0f',
    },
  },
  '1f468-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f468-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍🦼‍➡',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fb-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f468-1f3fb-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍🦼‍➡',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fc-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f468-1f3fc-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍🦼‍➡',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fd-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f468-1f3fd-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍🦼‍➡',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fe-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f468-1f3fe-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍🦼‍➡',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3ff-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f468-1f3ff-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍🦼‍➡',
    version: 'E15.1',
    baseName: 'man in motorized wheelchair facing right',
    fullName: 'man in motorized wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-200d-1f9bc': {
    hexCodePoints: '1f469-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦼',
    version: 'E12.0',
    baseName: 'woman in motorized wheelchair',
    fullName: 'woman in motorized wheelchair',
    isBase: true,
    baseHex: '1f469-200d-1f9bc',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9bc',
      '1f3fc': '1f469-1f3fc-200d-1f9bc',
      '1f3fd': '1f469-1f3fd-200d-1f9bc',
      '1f3fe': '1f469-1f3fe-200d-1f9bc',
      '1f3ff': '1f469-1f3ff-200d-1f9bc',
    },
  },
  '1f469-1f3fb-200d-1f9bc': {
    hexCodePoints: '1f469-1f3fb-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦼',
    version: 'E12.0',
    baseName: 'woman in motorized wheelchair',
    fullName: 'woman in motorized wheelchair: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9bc': {
    hexCodePoints: '1f469-1f3fc-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦼',
    version: 'E12.0',
    baseName: 'woman in motorized wheelchair',
    fullName: 'woman in motorized wheelchair: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9bc': {
    hexCodePoints: '1f469-1f3fd-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦼',
    version: 'E12.0',
    baseName: 'woman in motorized wheelchair',
    fullName: 'woman in motorized wheelchair: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9bc': {
    hexCodePoints: '1f469-1f3fe-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦼',
    version: 'E12.0',
    baseName: 'woman in motorized wheelchair',
    fullName: 'woman in motorized wheelchair: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9bc': {
    hexCodePoints: '1f469-1f3ff-200d-1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦼',
    version: 'E12.0',
    baseName: 'woman in motorized wheelchair',
    fullName: 'woman in motorized wheelchair: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc',
    modifiers: null,
  },
  '1f469-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f469-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right',
    isBase: true,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9bc-200d-27a1-fe0f',
      '1f3fc': '1f469-1f3fc-200d-1f9bc-200d-27a1-fe0f',
      '1f3fd': '1f469-1f3fd-200d-1f9bc-200d-27a1-fe0f',
      '1f3fe': '1f469-1f3fe-200d-1f9bc-200d-27a1-fe0f',
      '1f3ff': '1f469-1f3ff-200d-1f9bc-200d-27a1-fe0f',
    },
  },
  '1f469-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f469-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍🦼‍➡',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fb-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f469-1f3fb-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍🦼‍➡',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fc-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f469-1f3fc-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍🦼‍➡',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fd-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f469-1f3fd-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍🦼‍➡',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fe-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f469-1f3fe-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍🦼‍➡',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9bc-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3ff-200d-1f9bc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦼‍➡️',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9bc-200d-27a1': {
    hexCodePoints: '1f469-1f3ff-200d-1f9bc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍🦼‍➡',
    version: 'E15.1',
    baseName: 'woman in motorized wheelchair facing right',
    fullName: 'woman in motorized wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bc-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-200d-1f9bd': {
    hexCodePoints: '1f9d1-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦽',
    version: 'E12.1',
    baseName: 'person in manual wheelchair',
    fullName: 'person in manual wheelchair',
    isBase: true,
    baseHex: '1f9d1-200d-1f9bd',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9bd',
      '1f3fc': '1f9d1-1f3fc-200d-1f9bd',
      '1f3fd': '1f9d1-1f3fd-200d-1f9bd',
      '1f3fe': '1f9d1-1f3fe-200d-1f9bd',
      '1f3ff': '1f9d1-1f3ff-200d-1f9bd',
    },
  },
  '1f9d1-1f3fb-200d-1f9bd': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦽',
    version: 'E12.1',
    baseName: 'person in manual wheelchair',
    fullName: 'person in manual wheelchair: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9bd': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦽',
    version: 'E12.1',
    baseName: 'person in manual wheelchair',
    fullName: 'person in manual wheelchair: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9bd': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦽',
    version: 'E12.1',
    baseName: 'person in manual wheelchair',
    fullName: 'person in manual wheelchair: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9bd': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦽',
    version: 'E12.1',
    baseName: 'person in manual wheelchair',
    fullName: 'person in manual wheelchair: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9bd': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦽',
    version: 'E12.1',
    baseName: 'person in manual wheelchair',
    fullName: 'person in manual wheelchair: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd',
    modifiers: null,
  },
  '1f9d1-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right',
    isBase: true,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f9d1-1f3fb-200d-1f9bd-200d-27a1-fe0f',
      '1f3fc': '1f9d1-1f3fc-200d-1f9bd-200d-27a1-fe0f',
      '1f3fd': '1f9d1-1f3fd-200d-1f9bd-200d-27a1-fe0f',
      '1f3fe': '1f9d1-1f3fe-200d-1f9bd-200d-27a1-fe0f',
      '1f3ff': '1f9d1-1f3ff-200d-1f9bd-200d-27a1-fe0f',
    },
  },
  '1f9d1-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f9d1-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑‍🦽‍➡',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍🦽‍➡',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍🦽‍➡',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍🦽‍➡',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍🦽‍➡',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍🦽‍➡',
    version: 'E15.1',
    baseName: 'person in manual wheelchair facing right',
    fullName: 'person in manual wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-200d-1f9bd': {
    hexCodePoints: '1f468-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦽',
    version: 'E12.0',
    baseName: 'man in manual wheelchair',
    fullName: 'man in manual wheelchair',
    isBase: true,
    baseHex: '1f468-200d-1f9bd',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9bd',
      '1f3fc': '1f468-1f3fc-200d-1f9bd',
      '1f3fd': '1f468-1f3fd-200d-1f9bd',
      '1f3fe': '1f468-1f3fe-200d-1f9bd',
      '1f3ff': '1f468-1f3ff-200d-1f9bd',
    },
  },
  '1f468-1f3fb-200d-1f9bd': {
    hexCodePoints: '1f468-1f3fb-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦽',
    version: 'E12.0',
    baseName: 'man in manual wheelchair',
    fullName: 'man in manual wheelchair: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9bd': {
    hexCodePoints: '1f468-1f3fc-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦽',
    version: 'E12.0',
    baseName: 'man in manual wheelchair',
    fullName: 'man in manual wheelchair: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9bd': {
    hexCodePoints: '1f468-1f3fd-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦽',
    version: 'E12.0',
    baseName: 'man in manual wheelchair',
    fullName: 'man in manual wheelchair: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9bd': {
    hexCodePoints: '1f468-1f3fe-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦽',
    version: 'E12.0',
    baseName: 'man in manual wheelchair',
    fullName: 'man in manual wheelchair: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9bd': {
    hexCodePoints: '1f468-1f3ff-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦽',
    version: 'E12.0',
    baseName: 'man in manual wheelchair',
    fullName: 'man in manual wheelchair: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd',
    modifiers: null,
  },
  '1f468-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f468-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right',
    isBase: true,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f468-1f3fb-200d-1f9bd-200d-27a1-fe0f',
      '1f3fc': '1f468-1f3fc-200d-1f9bd-200d-27a1-fe0f',
      '1f3fd': '1f468-1f3fd-200d-1f9bd-200d-27a1-fe0f',
      '1f3fe': '1f468-1f3fe-200d-1f9bd-200d-27a1-fe0f',
      '1f3ff': '1f468-1f3ff-200d-1f9bd-200d-27a1-fe0f',
    },
  },
  '1f468-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f468-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍🦽‍➡',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fb-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f468-1f3fb-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍🦽‍➡',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fc-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f468-1f3fc-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍🦽‍➡',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fd-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f468-1f3fd-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍🦽‍➡',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3fe-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f468-1f3fe-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍🦽‍➡',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f468-1f3ff-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f468-1f3ff-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍🦽‍➡',
    version: 'E15.1',
    baseName: 'man in manual wheelchair facing right',
    fullName: 'man in manual wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-200d-1f9bd': {
    hexCodePoints: '1f469-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦽',
    version: 'E12.0',
    baseName: 'woman in manual wheelchair',
    fullName: 'woman in manual wheelchair',
    isBase: true,
    baseHex: '1f469-200d-1f9bd',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9bd',
      '1f3fc': '1f469-1f3fc-200d-1f9bd',
      '1f3fd': '1f469-1f3fd-200d-1f9bd',
      '1f3fe': '1f469-1f3fe-200d-1f9bd',
      '1f3ff': '1f469-1f3ff-200d-1f9bd',
    },
  },
  '1f469-1f3fb-200d-1f9bd': {
    hexCodePoints: '1f469-1f3fb-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦽',
    version: 'E12.0',
    baseName: 'woman in manual wheelchair',
    fullName: 'woman in manual wheelchair: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9bd': {
    hexCodePoints: '1f469-1f3fc-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦽',
    version: 'E12.0',
    baseName: 'woman in manual wheelchair',
    fullName: 'woman in manual wheelchair: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9bd': {
    hexCodePoints: '1f469-1f3fd-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦽',
    version: 'E12.0',
    baseName: 'woman in manual wheelchair',
    fullName: 'woman in manual wheelchair: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9bd': {
    hexCodePoints: '1f469-1f3fe-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦽',
    version: 'E12.0',
    baseName: 'woman in manual wheelchair',
    fullName: 'woman in manual wheelchair: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9bd': {
    hexCodePoints: '1f469-1f3ff-200d-1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦽',
    version: 'E12.0',
    baseName: 'woman in manual wheelchair',
    fullName: 'woman in manual wheelchair: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd',
    modifiers: null,
  },
  '1f469-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f469-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right',
    isBase: true,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f469-1f3fb-200d-1f9bd-200d-27a1-fe0f',
      '1f3fc': '1f469-1f3fc-200d-1f9bd-200d-27a1-fe0f',
      '1f3fd': '1f469-1f3fd-200d-1f9bd-200d-27a1-fe0f',
      '1f3fe': '1f469-1f3fe-200d-1f9bd-200d-27a1-fe0f',
      '1f3ff': '1f469-1f3ff-200d-1f9bd-200d-27a1-fe0f',
    },
  },
  '1f469-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f469-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍🦽‍➡',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fb-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f469-1f3fb-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍🦽‍➡',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fc-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f469-1f3fc-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍🦽‍➡',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fd-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f469-1f3fd-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍🦽‍➡',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3fe-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f469-1f3fe-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍🦽‍➡',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9bd-200d-27a1-fe0f': {
    hexCodePoints: '1f469-1f3ff-200d-1f9bd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🦽‍➡️',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f9bd-200d-27a1': {
    hexCodePoints: '1f469-1f3ff-200d-1f9bd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍🦽‍➡',
    version: 'E15.1',
    baseName: 'woman in manual wheelchair facing right',
    fullName: 'woman in manual wheelchair facing right: dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-1f9bd-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3': {
    hexCodePoints: '1f3c3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃',
    version: 'E0.6',
    baseName: 'person running',
    fullName: 'person running',
    isBase: true,
    baseHex: '1f3c3',
    modifiers: {
      '1f3fb': '1f3c3-1f3fb',
      '1f3fc': '1f3c3-1f3fc',
      '1f3fd': '1f3c3-1f3fd',
      '1f3fe': '1f3c3-1f3fe',
      '1f3ff': '1f3c3-1f3ff',
    },
  },
  '1f3c3-1f3fb': {
    hexCodePoints: '1f3c3-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏻',
    version: 'E1.0',
    baseName: 'person running',
    fullName: 'person running: light skin tone',
    isBase: false,
    baseHex: '1f3c3',
    modifiers: null,
  },
  '1f3c3-1f3fc': {
    hexCodePoints: '1f3c3-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏼',
    version: 'E1.0',
    baseName: 'person running',
    fullName: 'person running: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3',
    modifiers: null,
  },
  '1f3c3-1f3fd': {
    hexCodePoints: '1f3c3-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏽',
    version: 'E1.0',
    baseName: 'person running',
    fullName: 'person running: medium skin tone',
    isBase: false,
    baseHex: '1f3c3',
    modifiers: null,
  },
  '1f3c3-1f3fe': {
    hexCodePoints: '1f3c3-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏾',
    version: 'E1.0',
    baseName: 'person running',
    fullName: 'person running: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3',
    modifiers: null,
  },
  '1f3c3-1f3ff': {
    hexCodePoints: '1f3c3-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏿',
    version: 'E1.0',
    baseName: 'person running',
    fullName: 'person running: dark skin tone',
    isBase: false,
    baseHex: '1f3c3',
    modifiers: null,
  },
  '1f3c3-200d-2642-fe0f': {
    hexCodePoints: '1f3c3-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃‍♂️',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running',
    isBase: true,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f3c3-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f3c3-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f3c3-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f3c3-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f3c3-1f3ff-200d-2642-fe0f',
    },
  },
  '1f3c3-200d-2642': {
    hexCodePoints: '1f3c3-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♂',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏻‍♂️',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2642': {
    hexCodePoints: '1f3c3-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♂',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏼‍♂️',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2642': {
    hexCodePoints: '1f3c3-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♂',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏽‍♂️',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2642': {
    hexCodePoints: '1f3c3-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♂',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏾‍♂️',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2642': {
    hexCodePoints: '1f3c3-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♂',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏿‍♂️',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2642': {
    hexCodePoints: '1f3c3-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♂',
    version: 'E4.0',
    baseName: 'man running',
    fullName: 'man running: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2640-fe0f': {
    hexCodePoints: '1f3c3-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃‍♀️',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running',
    isBase: true,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f3c3-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f3c3-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f3c3-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f3c3-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f3c3-1f3ff-200d-2640-fe0f',
    },
  },
  '1f3c3-200d-2640': {
    hexCodePoints: '1f3c3-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♀',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2640': {
    hexCodePoints: '1f3c3-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♀',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2640': {
    hexCodePoints: '1f3c3-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♀',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2640': {
    hexCodePoints: '1f3c3-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♀',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2640': {
    hexCodePoints: '1f3c3-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♀',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2640': {
    hexCodePoints: '1f3c3-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♀',
    version: 'E4.0',
    baseName: 'woman running',
    fullName: 'woman running: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃‍➡️',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right',
    isBase: true,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f3c3-1f3fb-200d-27a1-fe0f',
      '1f3fc': '1f3c3-1f3fc-200d-27a1-fe0f',
      '1f3fd': '1f3c3-1f3fd-200d-27a1-fe0f',
      '1f3fe': '1f3c3-1f3fe-200d-27a1-fe0f',
      '1f3ff': '1f3c3-1f3ff-200d-27a1-fe0f',
    },
  },
  '1f3c3-200d-27a1': {
    hexCodePoints: '1f3c3-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍➡',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏻‍➡️',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fb-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍➡',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏼‍➡️',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fc-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍➡',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏽‍➡️',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fd-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍➡',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏾‍➡️',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fe-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍➡',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏿‍➡️',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-27a1': {
    hexCodePoints: '1f3c3-1f3ff-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍➡',
    version: 'E15.1',
    baseName: 'person running facing right',
    fullName: 'person running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right',
    isBase: true,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f3c3-1f3fb-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fc': '1f3c3-1f3fc-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fd': '1f3c3-1f3fd-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3fe': '1f3c3-1f3fe-200d-2640-fe0f-200d-27a1-fe0f',
      '1f3ff': '1f3c3-1f3ff-200d-2640-fe0f-200d-27a1-fe0f',
    },
  },
  '1f3c3-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2640-200d-27a1': {
    hexCodePoints: '1f3c3-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏻‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fb-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2640-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fb-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏼‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fc-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2640-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fc-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏽‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fd-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2640-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fd-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏾‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fe-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2640-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fe-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2640-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-2640-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏿‍♀️‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2640-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-2640-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♀‍➡️',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2640-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3ff-200d-2640-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♀️‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2640-200d-27a1': {
    hexCodePoints: '1f3c3-1f3ff-200d-2640-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♀‍➡',
    version: 'E15.1',
    baseName: 'woman running facing right',
    fullName: 'woman running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2640-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right',
    isBase: true,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: {
      '1f3fb': '1f3c3-1f3fb-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fc': '1f3c3-1f3fc-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fd': '1f3c3-1f3fd-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3fe': '1f3c3-1f3fe-200d-2642-fe0f-200d-27a1-fe0f',
      '1f3ff': '1f3c3-1f3ff-200d-2642-fe0f-200d-27a1-fe0f',
    },
  },
  '1f3c3-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-200d-2642-200d-27a1': {
    hexCodePoints: '1f3c3-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃‍♂‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏻‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fb-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fb-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fb-200d-2642-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fb-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏻‍♂‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏼‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fc-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fc-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fc-200d-2642-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fc-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏼‍♂‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏽‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fd-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fd-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fd-200d-2642-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fd-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏽‍♂‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏾‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3fe-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fe-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3fe-200d-2642-200d-27a1': {
    hexCodePoints: '1f3c3-1f3fe-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏾‍♂‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2642-fe0f-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-2642-fe0f-200d-27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏃🏿‍♂️‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2642-200d-27a1-fe0f': {
    hexCodePoints: '1f3c3-1f3ff-200d-2642-200d-27a1-fe0f',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♂‍➡️',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2642-fe0f-200d-27a1': {
    hexCodePoints: '1f3c3-1f3ff-200d-2642-fe0f-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♂️‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f3c3-1f3ff-200d-2642-200d-27a1': {
    hexCodePoints: '1f3c3-1f3ff-200d-2642-200d-27a1',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏃🏿‍♂‍➡',
    version: 'E15.1',
    baseName: 'man running facing right',
    fullName: 'man running facing right: dark skin tone',
    isBase: false,
    baseHex: '1f3c3-200d-2642-fe0f-200d-27a1-fe0f',
    modifiers: null,
  },
  '1f483': {
    hexCodePoints: '1f483',
    qualifiedStatus: 'fully-qualified',
    emoji: '💃',
    version: 'E0.6',
    baseName: 'woman dancing',
    fullName: 'woman dancing',
    isBase: true,
    baseHex: '1f483',
    modifiers: {
      '1f3fb': '1f483-1f3fb',
      '1f3fc': '1f483-1f3fc',
      '1f3fd': '1f483-1f3fd',
      '1f3fe': '1f483-1f3fe',
      '1f3ff': '1f483-1f3ff',
    },
  },
  '1f483-1f3fb': {
    hexCodePoints: '1f483-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💃🏻',
    version: 'E1.0',
    baseName: 'woman dancing',
    fullName: 'woman dancing: light skin tone',
    isBase: false,
    baseHex: '1f483',
    modifiers: null,
  },
  '1f483-1f3fc': {
    hexCodePoints: '1f483-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💃🏼',
    version: 'E1.0',
    baseName: 'woman dancing',
    fullName: 'woman dancing: medium-light skin tone',
    isBase: false,
    baseHex: '1f483',
    modifiers: null,
  },
  '1f483-1f3fd': {
    hexCodePoints: '1f483-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💃🏽',
    version: 'E1.0',
    baseName: 'woman dancing',
    fullName: 'woman dancing: medium skin tone',
    isBase: false,
    baseHex: '1f483',
    modifiers: null,
  },
  '1f483-1f3fe': {
    hexCodePoints: '1f483-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💃🏾',
    version: 'E1.0',
    baseName: 'woman dancing',
    fullName: 'woman dancing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f483',
    modifiers: null,
  },
  '1f483-1f3ff': {
    hexCodePoints: '1f483-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💃🏿',
    version: 'E1.0',
    baseName: 'woman dancing',
    fullName: 'woman dancing: dark skin tone',
    isBase: false,
    baseHex: '1f483',
    modifiers: null,
  },
  '1f57a': {
    hexCodePoints: '1f57a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕺',
    version: 'E3.0',
    baseName: 'man dancing',
    fullName: 'man dancing',
    isBase: true,
    baseHex: '1f57a',
    modifiers: {
      '1f3fb': '1f57a-1f3fb',
      '1f3fc': '1f57a-1f3fc',
      '1f3fd': '1f57a-1f3fd',
      '1f3fe': '1f57a-1f3fe',
      '1f3ff': '1f57a-1f3ff',
    },
  },
  '1f57a-1f3fb': {
    hexCodePoints: '1f57a-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕺🏻',
    version: 'E3.0',
    baseName: 'man dancing',
    fullName: 'man dancing: light skin tone',
    isBase: false,
    baseHex: '1f57a',
    modifiers: null,
  },
  '1f57a-1f3fc': {
    hexCodePoints: '1f57a-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕺🏼',
    version: 'E3.0',
    baseName: 'man dancing',
    fullName: 'man dancing: medium-light skin tone',
    isBase: false,
    baseHex: '1f57a',
    modifiers: null,
  },
  '1f57a-1f3fd': {
    hexCodePoints: '1f57a-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕺🏽',
    version: 'E3.0',
    baseName: 'man dancing',
    fullName: 'man dancing: medium skin tone',
    isBase: false,
    baseHex: '1f57a',
    modifiers: null,
  },
  '1f57a-1f3fe': {
    hexCodePoints: '1f57a-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕺🏾',
    version: 'E3.0',
    baseName: 'man dancing',
    fullName: 'man dancing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f57a',
    modifiers: null,
  },
  '1f57a-1f3ff': {
    hexCodePoints: '1f57a-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕺🏿',
    version: 'E3.0',
    baseName: 'man dancing',
    fullName: 'man dancing: dark skin tone',
    isBase: false,
    baseHex: '1f57a',
    modifiers: null,
  },
  '1f574-fe0f': {
    hexCodePoints: '1f574-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕴️',
    version: 'E0.7',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating',
    isBase: true,
    baseHex: '1f574-fe0f',
    modifiers: {
      '1f3fb': '1f574-1f3fb',
      '1f3fc': '1f574-1f3fc',
      '1f3fd': '1f574-1f3fd',
      '1f3fe': '1f574-1f3fe',
      '1f3ff': '1f574-1f3ff',
    },
  },
  '1f574': {
    hexCodePoints: '1f574',
    qualifiedStatus: 'unqualified',
    emoji: '🕴',
    version: 'E0.7',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating',
    isBase: false,
    baseHex: '1f574-fe0f',
    modifiers: null,
  },
  '1f574-1f3fb': {
    hexCodePoints: '1f574-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕴🏻',
    version: 'E4.0',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating: light skin tone',
    isBase: false,
    baseHex: '1f574-fe0f',
    modifiers: null,
  },
  '1f574-1f3fc': {
    hexCodePoints: '1f574-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕴🏼',
    version: 'E4.0',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating: medium-light skin tone',
    isBase: false,
    baseHex: '1f574-fe0f',
    modifiers: null,
  },
  '1f574-1f3fd': {
    hexCodePoints: '1f574-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕴🏽',
    version: 'E4.0',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating: medium skin tone',
    isBase: false,
    baseHex: '1f574-fe0f',
    modifiers: null,
  },
  '1f574-1f3fe': {
    hexCodePoints: '1f574-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕴🏾',
    version: 'E4.0',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating: medium-dark skin tone',
    isBase: false,
    baseHex: '1f574-fe0f',
    modifiers: null,
  },
  '1f574-1f3ff': {
    hexCodePoints: '1f574-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕴🏿',
    version: 'E4.0',
    baseName: 'person in suit levitating',
    fullName: 'person in suit levitating: dark skin tone',
    isBase: false,
    baseHex: '1f574-fe0f',
    modifiers: null,
  },
  '1f46f': {
    hexCodePoints: '1f46f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👯',
    version: 'E0.6',
    baseName: 'people with bunny ears',
    fullName: 'people with bunny ears',
    isBase: true,
    baseHex: '1f46f',
    modifiers: null,
  },
  '1f46f-200d-2642-fe0f': {
    hexCodePoints: '1f46f-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👯‍♂️',
    version: 'E4.0',
    baseName: 'men with bunny ears',
    fullName: 'men with bunny ears',
    isBase: true,
    baseHex: '1f46f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46f-200d-2642': {
    hexCodePoints: '1f46f-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👯‍♂',
    version: 'E4.0',
    baseName: 'men with bunny ears',
    fullName: 'men with bunny ears',
    isBase: false,
    baseHex: '1f46f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f46f-200d-2640-fe0f': {
    hexCodePoints: '1f46f-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👯‍♀️',
    version: 'E4.0',
    baseName: 'women with bunny ears',
    fullName: 'women with bunny ears',
    isBase: true,
    baseHex: '1f46f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f46f-200d-2640': {
    hexCodePoints: '1f46f-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👯‍♀',
    version: 'E4.0',
    baseName: 'women with bunny ears',
    fullName: 'women with bunny ears',
    isBase: false,
    baseHex: '1f46f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6': {
    hexCodePoints: '1f9d6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖',
    version: 'E5.0',
    baseName: 'person in steamy room',
    fullName: 'person in steamy room',
    isBase: true,
    baseHex: '1f9d6',
    modifiers: {
      '1f3fb': '1f9d6-1f3fb',
      '1f3fc': '1f9d6-1f3fc',
      '1f3fd': '1f9d6-1f3fd',
      '1f3fe': '1f9d6-1f3fe',
      '1f3ff': '1f9d6-1f3ff',
    },
  },
  '1f9d6-1f3fb': {
    hexCodePoints: '1f9d6-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏻',
    version: 'E5.0',
    baseName: 'person in steamy room',
    fullName: 'person in steamy room: light skin tone',
    isBase: false,
    baseHex: '1f9d6',
    modifiers: null,
  },
  '1f9d6-1f3fc': {
    hexCodePoints: '1f9d6-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏼',
    version: 'E5.0',
    baseName: 'person in steamy room',
    fullName: 'person in steamy room: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d6',
    modifiers: null,
  },
  '1f9d6-1f3fd': {
    hexCodePoints: '1f9d6-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏽',
    version: 'E5.0',
    baseName: 'person in steamy room',
    fullName: 'person in steamy room: medium skin tone',
    isBase: false,
    baseHex: '1f9d6',
    modifiers: null,
  },
  '1f9d6-1f3fe': {
    hexCodePoints: '1f9d6-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏾',
    version: 'E5.0',
    baseName: 'person in steamy room',
    fullName: 'person in steamy room: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d6',
    modifiers: null,
  },
  '1f9d6-1f3ff': {
    hexCodePoints: '1f9d6-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏿',
    version: 'E5.0',
    baseName: 'person in steamy room',
    fullName: 'person in steamy room: dark skin tone',
    isBase: false,
    baseHex: '1f9d6',
    modifiers: null,
  },
  '1f9d6-200d-2642-fe0f': {
    hexCodePoints: '1f9d6-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖‍♂️',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room',
    isBase: true,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9d6-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9d6-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9d6-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9d6-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9d6-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9d6-200d-2642': {
    hexCodePoints: '1f9d6-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖‍♂',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9d6-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏻‍♂️',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fb-200d-2642': {
    hexCodePoints: '1f9d6-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏻‍♂',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9d6-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏼‍♂️',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fc-200d-2642': {
    hexCodePoints: '1f9d6-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏼‍♂',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9d6-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏽‍♂️',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: medium skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fd-200d-2642': {
    hexCodePoints: '1f9d6-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏽‍♂',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: medium skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9d6-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏾‍♂️',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fe-200d-2642': {
    hexCodePoints: '1f9d6-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏾‍♂',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9d6-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏿‍♂️',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3ff-200d-2642': {
    hexCodePoints: '1f9d6-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏿‍♂',
    version: 'E5.0',
    baseName: 'man in steamy room',
    fullName: 'man in steamy room: dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d6-200d-2640-fe0f': {
    hexCodePoints: '1f9d6-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖‍♀️',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room',
    isBase: true,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9d6-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9d6-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9d6-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9d6-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9d6-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9d6-200d-2640': {
    hexCodePoints: '1f9d6-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖‍♀',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9d6-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fb-200d-2640': {
    hexCodePoints: '1f9d6-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏻‍♀',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9d6-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fc-200d-2640': {
    hexCodePoints: '1f9d6-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏼‍♀',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9d6-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: medium skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fd-200d-2640': {
    hexCodePoints: '1f9d6-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏽‍♀',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: medium skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9d6-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3fe-200d-2640': {
    hexCodePoints: '1f9d6-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏾‍♀',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9d6-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧖🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d6-1f3ff-200d-2640': {
    hexCodePoints: '1f9d6-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧖🏿‍♀',
    version: 'E5.0',
    baseName: 'woman in steamy room',
    fullName: 'woman in steamy room: dark skin tone',
    isBase: false,
    baseHex: '1f9d6-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7': {
    hexCodePoints: '1f9d7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗',
    version: 'E5.0',
    baseName: 'person climbing',
    fullName: 'person climbing',
    isBase: true,
    baseHex: '1f9d7',
    modifiers: {
      '1f3fb': '1f9d7-1f3fb',
      '1f3fc': '1f9d7-1f3fc',
      '1f3fd': '1f9d7-1f3fd',
      '1f3fe': '1f9d7-1f3fe',
      '1f3ff': '1f9d7-1f3ff',
    },
  },
  '1f9d7-1f3fb': {
    hexCodePoints: '1f9d7-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏻',
    version: 'E5.0',
    baseName: 'person climbing',
    fullName: 'person climbing: light skin tone',
    isBase: false,
    baseHex: '1f9d7',
    modifiers: null,
  },
  '1f9d7-1f3fc': {
    hexCodePoints: '1f9d7-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏼',
    version: 'E5.0',
    baseName: 'person climbing',
    fullName: 'person climbing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d7',
    modifiers: null,
  },
  '1f9d7-1f3fd': {
    hexCodePoints: '1f9d7-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏽',
    version: 'E5.0',
    baseName: 'person climbing',
    fullName: 'person climbing: medium skin tone',
    isBase: false,
    baseHex: '1f9d7',
    modifiers: null,
  },
  '1f9d7-1f3fe': {
    hexCodePoints: '1f9d7-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏾',
    version: 'E5.0',
    baseName: 'person climbing',
    fullName: 'person climbing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d7',
    modifiers: null,
  },
  '1f9d7-1f3ff': {
    hexCodePoints: '1f9d7-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏿',
    version: 'E5.0',
    baseName: 'person climbing',
    fullName: 'person climbing: dark skin tone',
    isBase: false,
    baseHex: '1f9d7',
    modifiers: null,
  },
  '1f9d7-200d-2642-fe0f': {
    hexCodePoints: '1f9d7-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗‍♂️',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing',
    isBase: true,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9d7-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9d7-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9d7-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9d7-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9d7-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9d7-200d-2642': {
    hexCodePoints: '1f9d7-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗‍♂',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9d7-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏻‍♂️',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fb-200d-2642': {
    hexCodePoints: '1f9d7-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏻‍♂',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9d7-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏼‍♂️',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fc-200d-2642': {
    hexCodePoints: '1f9d7-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏼‍♂',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9d7-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏽‍♂️',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: medium skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fd-200d-2642': {
    hexCodePoints: '1f9d7-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏽‍♂',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: medium skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9d7-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏾‍♂️',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fe-200d-2642': {
    hexCodePoints: '1f9d7-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏾‍♂',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9d7-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏿‍♂️',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3ff-200d-2642': {
    hexCodePoints: '1f9d7-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏿‍♂',
    version: 'E5.0',
    baseName: 'man climbing',
    fullName: 'man climbing: dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d7-200d-2640-fe0f': {
    hexCodePoints: '1f9d7-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗‍♀️',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing',
    isBase: true,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9d7-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9d7-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9d7-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9d7-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9d7-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9d7-200d-2640': {
    hexCodePoints: '1f9d7-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗‍♀',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9d7-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fb-200d-2640': {
    hexCodePoints: '1f9d7-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏻‍♀',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9d7-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fc-200d-2640': {
    hexCodePoints: '1f9d7-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏼‍♀',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9d7-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: medium skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fd-200d-2640': {
    hexCodePoints: '1f9d7-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏽‍♀',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: medium skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9d7-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3fe-200d-2640': {
    hexCodePoints: '1f9d7-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏾‍♀',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9d7-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧗🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d7-1f3ff-200d-2640': {
    hexCodePoints: '1f9d7-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧗🏿‍♀',
    version: 'E5.0',
    baseName: 'woman climbing',
    fullName: 'woman climbing: dark skin tone',
    isBase: false,
    baseHex: '1f9d7-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93a': {
    hexCodePoints: '1f93a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤺',
    version: 'E3.0',
    baseName: 'person fencing',
    fullName: 'person fencing',
    isBase: true,
    baseHex: '1f93a',
    modifiers: null,
  },
  '1f3c7': {
    hexCodePoints: '1f3c7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏇',
    version: 'E1.0',
    baseName: 'horse racing',
    fullName: 'horse racing',
    isBase: true,
    baseHex: '1f3c7',
    modifiers: {
      '1f3fb': '1f3c7-1f3fb',
      '1f3fc': '1f3c7-1f3fc',
      '1f3fd': '1f3c7-1f3fd',
      '1f3fe': '1f3c7-1f3fe',
      '1f3ff': '1f3c7-1f3ff',
    },
  },
  '1f3c7-1f3fb': {
    hexCodePoints: '1f3c7-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏇🏻',
    version: 'E1.0',
    baseName: 'horse racing',
    fullName: 'horse racing: light skin tone',
    isBase: false,
    baseHex: '1f3c7',
    modifiers: null,
  },
  '1f3c7-1f3fc': {
    hexCodePoints: '1f3c7-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏇🏼',
    version: 'E1.0',
    baseName: 'horse racing',
    fullName: 'horse racing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c7',
    modifiers: null,
  },
  '1f3c7-1f3fd': {
    hexCodePoints: '1f3c7-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏇🏽',
    version: 'E1.0',
    baseName: 'horse racing',
    fullName: 'horse racing: medium skin tone',
    isBase: false,
    baseHex: '1f3c7',
    modifiers: null,
  },
  '1f3c7-1f3fe': {
    hexCodePoints: '1f3c7-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏇🏾',
    version: 'E1.0',
    baseName: 'horse racing',
    fullName: 'horse racing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c7',
    modifiers: null,
  },
  '1f3c7-1f3ff': {
    hexCodePoints: '1f3c7-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏇🏿',
    version: 'E1.0',
    baseName: 'horse racing',
    fullName: 'horse racing: dark skin tone',
    isBase: false,
    baseHex: '1f3c7',
    modifiers: null,
  },
  '26f7-fe0f': {
    hexCodePoints: '26f7-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛷️',
    version: 'E0.7',
    baseName: 'skier',
    fullName: 'skier',
    isBase: true,
    baseHex: '26f7-fe0f',
    modifiers: null,
  },
  '26f7': {
    hexCodePoints: '26f7',
    qualifiedStatus: 'unqualified',
    emoji: '⛷',
    version: 'E0.7',
    baseName: 'skier',
    fullName: 'skier',
    isBase: false,
    baseHex: '26f7-fe0f',
    modifiers: null,
  },
  '1f3c2': {
    hexCodePoints: '1f3c2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏂',
    version: 'E0.6',
    baseName: 'snowboarder',
    fullName: 'snowboarder',
    isBase: true,
    baseHex: '1f3c2',
    modifiers: {
      '1f3fb': '1f3c2-1f3fb',
      '1f3fc': '1f3c2-1f3fc',
      '1f3fd': '1f3c2-1f3fd',
      '1f3fe': '1f3c2-1f3fe',
      '1f3ff': '1f3c2-1f3ff',
    },
  },
  '1f3c2-1f3fb': {
    hexCodePoints: '1f3c2-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏂🏻',
    version: 'E1.0',
    baseName: 'snowboarder',
    fullName: 'snowboarder: light skin tone',
    isBase: false,
    baseHex: '1f3c2',
    modifiers: null,
  },
  '1f3c2-1f3fc': {
    hexCodePoints: '1f3c2-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏂🏼',
    version: 'E1.0',
    baseName: 'snowboarder',
    fullName: 'snowboarder: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c2',
    modifiers: null,
  },
  '1f3c2-1f3fd': {
    hexCodePoints: '1f3c2-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏂🏽',
    version: 'E1.0',
    baseName: 'snowboarder',
    fullName: 'snowboarder: medium skin tone',
    isBase: false,
    baseHex: '1f3c2',
    modifiers: null,
  },
  '1f3c2-1f3fe': {
    hexCodePoints: '1f3c2-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏂🏾',
    version: 'E1.0',
    baseName: 'snowboarder',
    fullName: 'snowboarder: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c2',
    modifiers: null,
  },
  '1f3c2-1f3ff': {
    hexCodePoints: '1f3c2-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏂🏿',
    version: 'E1.0',
    baseName: 'snowboarder',
    fullName: 'snowboarder: dark skin tone',
    isBase: false,
    baseHex: '1f3c2',
    modifiers: null,
  },
  '1f3cc-fe0f': {
    hexCodePoints: '1f3cc-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌️',
    version: 'E0.7',
    baseName: 'person golfing',
    fullName: 'person golfing',
    isBase: true,
    baseHex: '1f3cc-fe0f',
    modifiers: {
      '1f3fb': '1f3cc-1f3fb',
      '1f3fc': '1f3cc-1f3fc',
      '1f3fd': '1f3cc-1f3fd',
      '1f3fe': '1f3cc-1f3fe',
      '1f3ff': '1f3cc-1f3ff',
    },
  },
  '1f3cc': {
    hexCodePoints: '1f3cc',
    qualifiedStatus: 'unqualified',
    emoji: '🏌',
    version: 'E0.7',
    baseName: 'person golfing',
    fullName: 'person golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fb': {
    hexCodePoints: '1f3cc-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏻',
    version: 'E4.0',
    baseName: 'person golfing',
    fullName: 'person golfing: light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fc': {
    hexCodePoints: '1f3cc-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏼',
    version: 'E4.0',
    baseName: 'person golfing',
    fullName: 'person golfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fd': {
    hexCodePoints: '1f3cc-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏽',
    version: 'E4.0',
    baseName: 'person golfing',
    fullName: 'person golfing: medium skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fe': {
    hexCodePoints: '1f3cc-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏾',
    version: 'E4.0',
    baseName: 'person golfing',
    fullName: 'person golfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3ff': {
    hexCodePoints: '1f3cc-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏿',
    version: 'E4.0',
    baseName: 'person golfing',
    fullName: 'person golfing: dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f',
    modifiers: null,
  },
  '1f3cc-fe0f-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-fe0f-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌️‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing',
    isBase: true,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f3cc-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f3cc-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f3cc-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f3cc-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f3cc-1f3ff-200d-2642-fe0f',
    },
  },
  '1f3cc-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-200d-2642-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🏌‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-fe0f-200d-2642': {
    hexCodePoints: '1f3cc-fe0f-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌️‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-200d-2642': {
    hexCodePoints: '1f3cc-200d-2642',
    qualifiedStatus: 'unqualified',
    emoji: '🏌‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏻‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fb-200d-2642': {
    hexCodePoints: '1f3cc-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏻‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏼‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fc-200d-2642': {
    hexCodePoints: '1f3cc-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏼‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏽‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: medium skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fd-200d-2642': {
    hexCodePoints: '1f3cc-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏽‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: medium skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏾‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fe-200d-2642': {
    hexCodePoints: '1f3cc-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏾‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f3cc-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏿‍♂️',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3ff-200d-2642': {
    hexCodePoints: '1f3cc-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏿‍♂',
    version: 'E4.0',
    baseName: 'man golfing',
    fullName: 'man golfing: dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cc-fe0f-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-fe0f-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌️‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing',
    isBase: true,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f3cc-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f3cc-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f3cc-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f3cc-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f3cc-1f3ff-200d-2640-fe0f',
    },
  },
  '1f3cc-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-200d-2640-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🏌‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-fe0f-200d-2640': {
    hexCodePoints: '1f3cc-fe0f-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌️‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-200d-2640': {
    hexCodePoints: '1f3cc-200d-2640',
    qualifiedStatus: 'unqualified',
    emoji: '🏌‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fb-200d-2640': {
    hexCodePoints: '1f3cc-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏻‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fc-200d-2640': {
    hexCodePoints: '1f3cc-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏼‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: medium skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fd-200d-2640': {
    hexCodePoints: '1f3cc-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏽‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: medium skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3fe-200d-2640': {
    hexCodePoints: '1f3cc-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏾‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f3cc-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏌🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cc-1f3ff-200d-2640': {
    hexCodePoints: '1f3cc-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏌🏿‍♀',
    version: 'E4.0',
    baseName: 'woman golfing',
    fullName: 'woman golfing: dark skin tone',
    isBase: false,
    baseHex: '1f3cc-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4': {
    hexCodePoints: '1f3c4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄',
    version: 'E0.6',
    baseName: 'person surfing',
    fullName: 'person surfing',
    isBase: true,
    baseHex: '1f3c4',
    modifiers: {
      '1f3fb': '1f3c4-1f3fb',
      '1f3fc': '1f3c4-1f3fc',
      '1f3fd': '1f3c4-1f3fd',
      '1f3fe': '1f3c4-1f3fe',
      '1f3ff': '1f3c4-1f3ff',
    },
  },
  '1f3c4-1f3fb': {
    hexCodePoints: '1f3c4-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏻',
    version: 'E1.0',
    baseName: 'person surfing',
    fullName: 'person surfing: light skin tone',
    isBase: false,
    baseHex: '1f3c4',
    modifiers: null,
  },
  '1f3c4-1f3fc': {
    hexCodePoints: '1f3c4-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏼',
    version: 'E1.0',
    baseName: 'person surfing',
    fullName: 'person surfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c4',
    modifiers: null,
  },
  '1f3c4-1f3fd': {
    hexCodePoints: '1f3c4-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏽',
    version: 'E1.0',
    baseName: 'person surfing',
    fullName: 'person surfing: medium skin tone',
    isBase: false,
    baseHex: '1f3c4',
    modifiers: null,
  },
  '1f3c4-1f3fe': {
    hexCodePoints: '1f3c4-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏾',
    version: 'E1.0',
    baseName: 'person surfing',
    fullName: 'person surfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c4',
    modifiers: null,
  },
  '1f3c4-1f3ff': {
    hexCodePoints: '1f3c4-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏿',
    version: 'E1.0',
    baseName: 'person surfing',
    fullName: 'person surfing: dark skin tone',
    isBase: false,
    baseHex: '1f3c4',
    modifiers: null,
  },
  '1f3c4-200d-2642-fe0f': {
    hexCodePoints: '1f3c4-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄‍♂️',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing',
    isBase: true,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f3c4-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f3c4-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f3c4-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f3c4-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f3c4-1f3ff-200d-2642-fe0f',
    },
  },
  '1f3c4-200d-2642': {
    hexCodePoints: '1f3c4-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄‍♂',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f3c4-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏻‍♂️',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fb-200d-2642': {
    hexCodePoints: '1f3c4-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏻‍♂',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f3c4-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏼‍♂️',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fc-200d-2642': {
    hexCodePoints: '1f3c4-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏼‍♂',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f3c4-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏽‍♂️',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: medium skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fd-200d-2642': {
    hexCodePoints: '1f3c4-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏽‍♂',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: medium skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f3c4-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏾‍♂️',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fe-200d-2642': {
    hexCodePoints: '1f3c4-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏾‍♂',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f3c4-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏿‍♂️',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3ff-200d-2642': {
    hexCodePoints: '1f3c4-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏿‍♂',
    version: 'E4.0',
    baseName: 'man surfing',
    fullName: 'man surfing: dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3c4-200d-2640-fe0f': {
    hexCodePoints: '1f3c4-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄‍♀️',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing',
    isBase: true,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f3c4-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f3c4-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f3c4-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f3c4-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f3c4-1f3ff-200d-2640-fe0f',
    },
  },
  '1f3c4-200d-2640': {
    hexCodePoints: '1f3c4-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄‍♀',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f3c4-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fb-200d-2640': {
    hexCodePoints: '1f3c4-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏻‍♀',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f3c4-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fc-200d-2640': {
    hexCodePoints: '1f3c4-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏼‍♀',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: medium-light skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f3c4-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: medium skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fd-200d-2640': {
    hexCodePoints: '1f3c4-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏽‍♀',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: medium skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f3c4-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3fe-200d-2640': {
    hexCodePoints: '1f3c4-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏾‍♀',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f3c4-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏄🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3c4-1f3ff-200d-2640': {
    hexCodePoints: '1f3c4-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏄🏿‍♀',
    version: 'E4.0',
    baseName: 'woman surfing',
    fullName: 'woman surfing: dark skin tone',
    isBase: false,
    baseHex: '1f3c4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3': {
    hexCodePoints: '1f6a3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣',
    version: 'E1.0',
    baseName: 'person rowing boat',
    fullName: 'person rowing boat',
    isBase: true,
    baseHex: '1f6a3',
    modifiers: {
      '1f3fb': '1f6a3-1f3fb',
      '1f3fc': '1f6a3-1f3fc',
      '1f3fd': '1f6a3-1f3fd',
      '1f3fe': '1f6a3-1f3fe',
      '1f3ff': '1f6a3-1f3ff',
    },
  },
  '1f6a3-1f3fb': {
    hexCodePoints: '1f6a3-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏻',
    version: 'E1.0',
    baseName: 'person rowing boat',
    fullName: 'person rowing boat: light skin tone',
    isBase: false,
    baseHex: '1f6a3',
    modifiers: null,
  },
  '1f6a3-1f3fc': {
    hexCodePoints: '1f6a3-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏼',
    version: 'E1.0',
    baseName: 'person rowing boat',
    fullName: 'person rowing boat: medium-light skin tone',
    isBase: false,
    baseHex: '1f6a3',
    modifiers: null,
  },
  '1f6a3-1f3fd': {
    hexCodePoints: '1f6a3-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏽',
    version: 'E1.0',
    baseName: 'person rowing boat',
    fullName: 'person rowing boat: medium skin tone',
    isBase: false,
    baseHex: '1f6a3',
    modifiers: null,
  },
  '1f6a3-1f3fe': {
    hexCodePoints: '1f6a3-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏾',
    version: 'E1.0',
    baseName: 'person rowing boat',
    fullName: 'person rowing boat: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6a3',
    modifiers: null,
  },
  '1f6a3-1f3ff': {
    hexCodePoints: '1f6a3-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏿',
    version: 'E1.0',
    baseName: 'person rowing boat',
    fullName: 'person rowing boat: dark skin tone',
    isBase: false,
    baseHex: '1f6a3',
    modifiers: null,
  },
  '1f6a3-200d-2642-fe0f': {
    hexCodePoints: '1f6a3-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣‍♂️',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat',
    isBase: true,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f6a3-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f6a3-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f6a3-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f6a3-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f6a3-1f3ff-200d-2642-fe0f',
    },
  },
  '1f6a3-200d-2642': {
    hexCodePoints: '1f6a3-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣‍♂',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f6a3-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏻‍♂️',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fb-200d-2642': {
    hexCodePoints: '1f6a3-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏻‍♂',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f6a3-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏼‍♂️',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: medium-light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fc-200d-2642': {
    hexCodePoints: '1f6a3-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏼‍♂',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: medium-light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f6a3-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏽‍♂️',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: medium skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fd-200d-2642': {
    hexCodePoints: '1f6a3-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏽‍♂',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: medium skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f6a3-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏾‍♂️',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fe-200d-2642': {
    hexCodePoints: '1f6a3-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏾‍♂',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f6a3-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏿‍♂️',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3ff-200d-2642': {
    hexCodePoints: '1f6a3-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏿‍♂',
    version: 'E4.0',
    baseName: 'man rowing boat',
    fullName: 'man rowing boat: dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6a3-200d-2640-fe0f': {
    hexCodePoints: '1f6a3-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣‍♀️',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat',
    isBase: true,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f6a3-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f6a3-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f6a3-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f6a3-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f6a3-1f3ff-200d-2640-fe0f',
    },
  },
  '1f6a3-200d-2640': {
    hexCodePoints: '1f6a3-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣‍♀',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f6a3-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fb-200d-2640': {
    hexCodePoints: '1f6a3-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏻‍♀',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f6a3-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: medium-light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fc-200d-2640': {
    hexCodePoints: '1f6a3-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏼‍♀',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: medium-light skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f6a3-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: medium skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fd-200d-2640': {
    hexCodePoints: '1f6a3-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏽‍♀',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: medium skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f6a3-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3fe-200d-2640': {
    hexCodePoints: '1f6a3-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏾‍♀',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f6a3-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚣🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6a3-1f3ff-200d-2640': {
    hexCodePoints: '1f6a3-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚣🏿‍♀',
    version: 'E4.0',
    baseName: 'woman rowing boat',
    fullName: 'woman rowing boat: dark skin tone',
    isBase: false,
    baseHex: '1f6a3-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca': {
    hexCodePoints: '1f3ca',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊',
    version: 'E0.6',
    baseName: 'person swimming',
    fullName: 'person swimming',
    isBase: true,
    baseHex: '1f3ca',
    modifiers: {
      '1f3fb': '1f3ca-1f3fb',
      '1f3fc': '1f3ca-1f3fc',
      '1f3fd': '1f3ca-1f3fd',
      '1f3fe': '1f3ca-1f3fe',
      '1f3ff': '1f3ca-1f3ff',
    },
  },
  '1f3ca-1f3fb': {
    hexCodePoints: '1f3ca-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏻',
    version: 'E1.0',
    baseName: 'person swimming',
    fullName: 'person swimming: light skin tone',
    isBase: false,
    baseHex: '1f3ca',
    modifiers: null,
  },
  '1f3ca-1f3fc': {
    hexCodePoints: '1f3ca-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏼',
    version: 'E1.0',
    baseName: 'person swimming',
    fullName: 'person swimming: medium-light skin tone',
    isBase: false,
    baseHex: '1f3ca',
    modifiers: null,
  },
  '1f3ca-1f3fd': {
    hexCodePoints: '1f3ca-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏽',
    version: 'E1.0',
    baseName: 'person swimming',
    fullName: 'person swimming: medium skin tone',
    isBase: false,
    baseHex: '1f3ca',
    modifiers: null,
  },
  '1f3ca-1f3fe': {
    hexCodePoints: '1f3ca-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏾',
    version: 'E1.0',
    baseName: 'person swimming',
    fullName: 'person swimming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3ca',
    modifiers: null,
  },
  '1f3ca-1f3ff': {
    hexCodePoints: '1f3ca-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏿',
    version: 'E1.0',
    baseName: 'person swimming',
    fullName: 'person swimming: dark skin tone',
    isBase: false,
    baseHex: '1f3ca',
    modifiers: null,
  },
  '1f3ca-200d-2642-fe0f': {
    hexCodePoints: '1f3ca-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊‍♂️',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming',
    isBase: true,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f3ca-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f3ca-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f3ca-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f3ca-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f3ca-1f3ff-200d-2642-fe0f',
    },
  },
  '1f3ca-200d-2642': {
    hexCodePoints: '1f3ca-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊‍♂',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f3ca-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏻‍♂️',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fb-200d-2642': {
    hexCodePoints: '1f3ca-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏻‍♂',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f3ca-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏼‍♂️',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: medium-light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fc-200d-2642': {
    hexCodePoints: '1f3ca-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏼‍♂',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: medium-light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f3ca-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏽‍♂️',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: medium skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fd-200d-2642': {
    hexCodePoints: '1f3ca-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏽‍♂',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: medium skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f3ca-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏾‍♂️',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fe-200d-2642': {
    hexCodePoints: '1f3ca-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏾‍♂',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f3ca-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏿‍♂️',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3ff-200d-2642': {
    hexCodePoints: '1f3ca-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏿‍♂',
    version: 'E4.0',
    baseName: 'man swimming',
    fullName: 'man swimming: dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3ca-200d-2640-fe0f': {
    hexCodePoints: '1f3ca-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊‍♀️',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming',
    isBase: true,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f3ca-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f3ca-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f3ca-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f3ca-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f3ca-1f3ff-200d-2640-fe0f',
    },
  },
  '1f3ca-200d-2640': {
    hexCodePoints: '1f3ca-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊‍♀',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f3ca-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fb-200d-2640': {
    hexCodePoints: '1f3ca-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏻‍♀',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f3ca-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: medium-light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fc-200d-2640': {
    hexCodePoints: '1f3ca-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏼‍♀',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: medium-light skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f3ca-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: medium skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fd-200d-2640': {
    hexCodePoints: '1f3ca-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏽‍♀',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: medium skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f3ca-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3fe-200d-2640': {
    hexCodePoints: '1f3ca-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏾‍♀',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f3ca-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏊🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3ca-1f3ff-200d-2640': {
    hexCodePoints: '1f3ca-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏊🏿‍♀',
    version: 'E4.0',
    baseName: 'woman swimming',
    fullName: 'woman swimming: dark skin tone',
    isBase: false,
    baseHex: '1f3ca-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-fe0f': {
    hexCodePoints: '26f9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹️',
    version: 'E0.7',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball',
    isBase: true,
    baseHex: '26f9-fe0f',
    modifiers: {
      '1f3fb': '26f9-1f3fb',
      '1f3fc': '26f9-1f3fc',
      '1f3fd': '26f9-1f3fd',
      '1f3fe': '26f9-1f3fe',
      '1f3ff': '26f9-1f3ff',
    },
  },
  '26f9': {
    hexCodePoints: '26f9',
    qualifiedStatus: 'unqualified',
    emoji: '⛹',
    version: 'E0.7',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f',
    modifiers: null,
  },
  '26f9-1f3fb': {
    hexCodePoints: '26f9-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏻',
    version: 'E2.0',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball: light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f',
    modifiers: null,
  },
  '26f9-1f3fc': {
    hexCodePoints: '26f9-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏼',
    version: 'E2.0',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball: medium-light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f',
    modifiers: null,
  },
  '26f9-1f3fd': {
    hexCodePoints: '26f9-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏽',
    version: 'E2.0',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball: medium skin tone',
    isBase: false,
    baseHex: '26f9-fe0f',
    modifiers: null,
  },
  '26f9-1f3fe': {
    hexCodePoints: '26f9-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏾',
    version: 'E2.0',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball: medium-dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f',
    modifiers: null,
  },
  '26f9-1f3ff': {
    hexCodePoints: '26f9-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏿',
    version: 'E2.0',
    baseName: 'person bouncing ball',
    fullName: 'person bouncing ball: dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f',
    modifiers: null,
  },
  '26f9-fe0f-200d-2642-fe0f': {
    hexCodePoints: '26f9-fe0f-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹️‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball',
    isBase: true,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '26f9-1f3fb-200d-2642-fe0f',
      '1f3fc': '26f9-1f3fc-200d-2642-fe0f',
      '1f3fd': '26f9-1f3fd-200d-2642-fe0f',
      '1f3fe': '26f9-1f3fe-200d-2642-fe0f',
      '1f3ff': '26f9-1f3ff-200d-2642-fe0f',
    },
  },
  '26f9-200d-2642-fe0f': {
    hexCodePoints: '26f9-200d-2642-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '⛹‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-fe0f-200d-2642': {
    hexCodePoints: '26f9-fe0f-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹️‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-200d-2642': {
    hexCodePoints: '26f9-200d-2642',
    qualifiedStatus: 'unqualified',
    emoji: '⛹‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '26f9-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏻‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fb-200d-2642': {
    hexCodePoints: '26f9-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏻‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '26f9-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏼‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: medium-light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fc-200d-2642': {
    hexCodePoints: '26f9-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏼‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: medium-light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '26f9-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏽‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: medium skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fd-200d-2642': {
    hexCodePoints: '26f9-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏽‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: medium skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '26f9-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏾‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: medium-dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3fe-200d-2642': {
    hexCodePoints: '26f9-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏾‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: medium-dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '26f9-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏿‍♂️',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-1f3ff-200d-2642': {
    hexCodePoints: '26f9-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏿‍♂',
    version: 'E4.0',
    baseName: 'man bouncing ball',
    fullName: 'man bouncing ball: dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '26f9-fe0f-200d-2640-fe0f': {
    hexCodePoints: '26f9-fe0f-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹️‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball',
    isBase: true,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '26f9-1f3fb-200d-2640-fe0f',
      '1f3fc': '26f9-1f3fc-200d-2640-fe0f',
      '1f3fd': '26f9-1f3fd-200d-2640-fe0f',
      '1f3fe': '26f9-1f3fe-200d-2640-fe0f',
      '1f3ff': '26f9-1f3ff-200d-2640-fe0f',
    },
  },
  '26f9-200d-2640-fe0f': {
    hexCodePoints: '26f9-200d-2640-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '⛹‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-fe0f-200d-2640': {
    hexCodePoints: '26f9-fe0f-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹️‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-200d-2640': {
    hexCodePoints: '26f9-200d-2640',
    qualifiedStatus: 'unqualified',
    emoji: '⛹‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '26f9-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fb-200d-2640': {
    hexCodePoints: '26f9-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏻‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '26f9-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: medium-light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fc-200d-2640': {
    hexCodePoints: '26f9-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏼‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: medium-light skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '26f9-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: medium skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fd-200d-2640': {
    hexCodePoints: '26f9-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏽‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: medium skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '26f9-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: medium-dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3fe-200d-2640': {
    hexCodePoints: '26f9-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏾‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: medium-dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '26f9-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛹🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '26f9-1f3ff-200d-2640': {
    hexCodePoints: '26f9-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '⛹🏿‍♀',
    version: 'E4.0',
    baseName: 'woman bouncing ball',
    fullName: 'woman bouncing ball: dark skin tone',
    isBase: false,
    baseHex: '26f9-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-fe0f': {
    hexCodePoints: '1f3cb-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋️',
    version: 'E0.7',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights',
    isBase: true,
    baseHex: '1f3cb-fe0f',
    modifiers: {
      '1f3fb': '1f3cb-1f3fb',
      '1f3fc': '1f3cb-1f3fc',
      '1f3fd': '1f3cb-1f3fd',
      '1f3fe': '1f3cb-1f3fe',
      '1f3ff': '1f3cb-1f3ff',
    },
  },
  '1f3cb': {
    hexCodePoints: '1f3cb',
    qualifiedStatus: 'unqualified',
    emoji: '🏋',
    version: 'E0.7',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fb': {
    hexCodePoints: '1f3cb-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏻',
    version: 'E2.0',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights: light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fc': {
    hexCodePoints: '1f3cb-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏼',
    version: 'E2.0',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fd': {
    hexCodePoints: '1f3cb-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏽',
    version: 'E2.0',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights: medium skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fe': {
    hexCodePoints: '1f3cb-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏾',
    version: 'E2.0',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3ff': {
    hexCodePoints: '1f3cb-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏿',
    version: 'E2.0',
    baseName: 'person lifting weights',
    fullName: 'person lifting weights: dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f',
    modifiers: null,
  },
  '1f3cb-fe0f-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-fe0f-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋️‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights',
    isBase: true,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f3cb-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f3cb-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f3cb-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f3cb-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f3cb-1f3ff-200d-2642-fe0f',
    },
  },
  '1f3cb-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-200d-2642-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🏋‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-fe0f-200d-2642': {
    hexCodePoints: '1f3cb-fe0f-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋️‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-200d-2642': {
    hexCodePoints: '1f3cb-200d-2642',
    qualifiedStatus: 'unqualified',
    emoji: '🏋‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏻‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fb-200d-2642': {
    hexCodePoints: '1f3cb-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏻‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏼‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fc-200d-2642': {
    hexCodePoints: '1f3cb-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏼‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏽‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: medium skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fd-200d-2642': {
    hexCodePoints: '1f3cb-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏽‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: medium skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏾‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fe-200d-2642': {
    hexCodePoints: '1f3cb-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏾‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f3cb-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏿‍♂️',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3ff-200d-2642': {
    hexCodePoints: '1f3cb-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏿‍♂',
    version: 'E4.0',
    baseName: 'man lifting weights',
    fullName: 'man lifting weights: dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2642-fe0f',
    modifiers: null,
  },
  '1f3cb-fe0f-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-fe0f-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋️‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights',
    isBase: true,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f3cb-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f3cb-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f3cb-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f3cb-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f3cb-1f3ff-200d-2640-fe0f',
    },
  },
  '1f3cb-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-200d-2640-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🏋‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-fe0f-200d-2640': {
    hexCodePoints: '1f3cb-fe0f-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋️‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-200d-2640': {
    hexCodePoints: '1f3cb-200d-2640',
    qualifiedStatus: 'unqualified',
    emoji: '🏋‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fb-200d-2640': {
    hexCodePoints: '1f3cb-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏻‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fc-200d-2640': {
    hexCodePoints: '1f3cb-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏼‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: medium-light skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: medium skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fd-200d-2640': {
    hexCodePoints: '1f3cb-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏽‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: medium skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3fe-200d-2640': {
    hexCodePoints: '1f3cb-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏾‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: medium-dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f3cb-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏋🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f3cb-1f3ff-200d-2640': {
    hexCodePoints: '1f3cb-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏋🏿‍♀',
    version: 'E4.0',
    baseName: 'woman lifting weights',
    fullName: 'woman lifting weights: dark skin tone',
    isBase: false,
    baseHex: '1f3cb-fe0f-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4': {
    hexCodePoints: '1f6b4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴',
    version: 'E1.0',
    baseName: 'person biking',
    fullName: 'person biking',
    isBase: true,
    baseHex: '1f6b4',
    modifiers: {
      '1f3fb': '1f6b4-1f3fb',
      '1f3fc': '1f6b4-1f3fc',
      '1f3fd': '1f6b4-1f3fd',
      '1f3fe': '1f6b4-1f3fe',
      '1f3ff': '1f6b4-1f3ff',
    },
  },
  '1f6b4-1f3fb': {
    hexCodePoints: '1f6b4-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏻',
    version: 'E1.0',
    baseName: 'person biking',
    fullName: 'person biking: light skin tone',
    isBase: false,
    baseHex: '1f6b4',
    modifiers: null,
  },
  '1f6b4-1f3fc': {
    hexCodePoints: '1f6b4-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏼',
    version: 'E1.0',
    baseName: 'person biking',
    fullName: 'person biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b4',
    modifiers: null,
  },
  '1f6b4-1f3fd': {
    hexCodePoints: '1f6b4-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏽',
    version: 'E1.0',
    baseName: 'person biking',
    fullName: 'person biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b4',
    modifiers: null,
  },
  '1f6b4-1f3fe': {
    hexCodePoints: '1f6b4-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏾',
    version: 'E1.0',
    baseName: 'person biking',
    fullName: 'person biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b4',
    modifiers: null,
  },
  '1f6b4-1f3ff': {
    hexCodePoints: '1f6b4-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏿',
    version: 'E1.0',
    baseName: 'person biking',
    fullName: 'person biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b4',
    modifiers: null,
  },
  '1f6b4-200d-2642-fe0f': {
    hexCodePoints: '1f6b4-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴‍♂️',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking',
    isBase: true,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f6b4-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f6b4-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f6b4-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f6b4-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f6b4-1f3ff-200d-2642-fe0f',
    },
  },
  '1f6b4-200d-2642': {
    hexCodePoints: '1f6b4-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴‍♂',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f6b4-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏻‍♂️',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fb-200d-2642': {
    hexCodePoints: '1f6b4-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏻‍♂',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f6b4-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏼‍♂️',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fc-200d-2642': {
    hexCodePoints: '1f6b4-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏼‍♂',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f6b4-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏽‍♂️',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fd-200d-2642': {
    hexCodePoints: '1f6b4-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏽‍♂',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f6b4-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏾‍♂️',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fe-200d-2642': {
    hexCodePoints: '1f6b4-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏾‍♂',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f6b4-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏿‍♂️',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3ff-200d-2642': {
    hexCodePoints: '1f6b4-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏿‍♂',
    version: 'E4.0',
    baseName: 'man biking',
    fullName: 'man biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b4-200d-2640-fe0f': {
    hexCodePoints: '1f6b4-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴‍♀️',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking',
    isBase: true,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f6b4-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f6b4-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f6b4-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f6b4-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f6b4-1f3ff-200d-2640-fe0f',
    },
  },
  '1f6b4-200d-2640': {
    hexCodePoints: '1f6b4-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴‍♀',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f6b4-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fb-200d-2640': {
    hexCodePoints: '1f6b4-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏻‍♀',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f6b4-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fc-200d-2640': {
    hexCodePoints: '1f6b4-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏼‍♀',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f6b4-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fd-200d-2640': {
    hexCodePoints: '1f6b4-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏽‍♀',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f6b4-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3fe-200d-2640': {
    hexCodePoints: '1f6b4-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏾‍♀',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f6b4-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚴🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b4-1f3ff-200d-2640': {
    hexCodePoints: '1f6b4-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚴🏿‍♀',
    version: 'E4.0',
    baseName: 'woman biking',
    fullName: 'woman biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b4-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5': {
    hexCodePoints: '1f6b5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵',
    version: 'E1.0',
    baseName: 'person mountain biking',
    fullName: 'person mountain biking',
    isBase: true,
    baseHex: '1f6b5',
    modifiers: {
      '1f3fb': '1f6b5-1f3fb',
      '1f3fc': '1f6b5-1f3fc',
      '1f3fd': '1f6b5-1f3fd',
      '1f3fe': '1f6b5-1f3fe',
      '1f3ff': '1f6b5-1f3ff',
    },
  },
  '1f6b5-1f3fb': {
    hexCodePoints: '1f6b5-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏻',
    version: 'E1.0',
    baseName: 'person mountain biking',
    fullName: 'person mountain biking: light skin tone',
    isBase: false,
    baseHex: '1f6b5',
    modifiers: null,
  },
  '1f6b5-1f3fc': {
    hexCodePoints: '1f6b5-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏼',
    version: 'E1.0',
    baseName: 'person mountain biking',
    fullName: 'person mountain biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b5',
    modifiers: null,
  },
  '1f6b5-1f3fd': {
    hexCodePoints: '1f6b5-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏽',
    version: 'E1.0',
    baseName: 'person mountain biking',
    fullName: 'person mountain biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b5',
    modifiers: null,
  },
  '1f6b5-1f3fe': {
    hexCodePoints: '1f6b5-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏾',
    version: 'E1.0',
    baseName: 'person mountain biking',
    fullName: 'person mountain biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b5',
    modifiers: null,
  },
  '1f6b5-1f3ff': {
    hexCodePoints: '1f6b5-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏿',
    version: 'E1.0',
    baseName: 'person mountain biking',
    fullName: 'person mountain biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b5',
    modifiers: null,
  },
  '1f6b5-200d-2642-fe0f': {
    hexCodePoints: '1f6b5-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵‍♂️',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking',
    isBase: true,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f6b5-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f6b5-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f6b5-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f6b5-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f6b5-1f3ff-200d-2642-fe0f',
    },
  },
  '1f6b5-200d-2642': {
    hexCodePoints: '1f6b5-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵‍♂',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f6b5-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏻‍♂️',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fb-200d-2642': {
    hexCodePoints: '1f6b5-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏻‍♂',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f6b5-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏼‍♂️',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fc-200d-2642': {
    hexCodePoints: '1f6b5-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏼‍♂',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f6b5-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏽‍♂️',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fd-200d-2642': {
    hexCodePoints: '1f6b5-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏽‍♂',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f6b5-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏾‍♂️',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fe-200d-2642': {
    hexCodePoints: '1f6b5-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏾‍♂',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f6b5-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏿‍♂️',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3ff-200d-2642': {
    hexCodePoints: '1f6b5-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏿‍♂',
    version: 'E4.0',
    baseName: 'man mountain biking',
    fullName: 'man mountain biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2642-fe0f',
    modifiers: null,
  },
  '1f6b5-200d-2640-fe0f': {
    hexCodePoints: '1f6b5-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵‍♀️',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking',
    isBase: true,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f6b5-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f6b5-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f6b5-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f6b5-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f6b5-1f3ff-200d-2640-fe0f',
    },
  },
  '1f6b5-200d-2640': {
    hexCodePoints: '1f6b5-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵‍♀',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f6b5-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fb-200d-2640': {
    hexCodePoints: '1f6b5-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏻‍♀',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f6b5-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fc-200d-2640': {
    hexCodePoints: '1f6b5-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏼‍♀',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: medium-light skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f6b5-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fd-200d-2640': {
    hexCodePoints: '1f6b5-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏽‍♀',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: medium skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f6b5-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3fe-200d-2640': {
    hexCodePoints: '1f6b5-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏾‍♀',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f6b5-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚵🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6b5-1f3ff-200d-2640': {
    hexCodePoints: '1f6b5-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🚵🏿‍♀',
    version: 'E4.0',
    baseName: 'woman mountain biking',
    fullName: 'woman mountain biking: dark skin tone',
    isBase: false,
    baseHex: '1f6b5-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938': {
    hexCodePoints: '1f938',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸',
    version: 'E3.0',
    baseName: 'person cartwheeling',
    fullName: 'person cartwheeling',
    isBase: true,
    baseHex: '1f938',
    modifiers: {
      '1f3fb': '1f938-1f3fb',
      '1f3fc': '1f938-1f3fc',
      '1f3fd': '1f938-1f3fd',
      '1f3fe': '1f938-1f3fe',
      '1f3ff': '1f938-1f3ff',
    },
  },
  '1f938-1f3fb': {
    hexCodePoints: '1f938-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏻',
    version: 'E3.0',
    baseName: 'person cartwheeling',
    fullName: 'person cartwheeling: light skin tone',
    isBase: false,
    baseHex: '1f938',
    modifiers: null,
  },
  '1f938-1f3fc': {
    hexCodePoints: '1f938-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏼',
    version: 'E3.0',
    baseName: 'person cartwheeling',
    fullName: 'person cartwheeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f938',
    modifiers: null,
  },
  '1f938-1f3fd': {
    hexCodePoints: '1f938-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏽',
    version: 'E3.0',
    baseName: 'person cartwheeling',
    fullName: 'person cartwheeling: medium skin tone',
    isBase: false,
    baseHex: '1f938',
    modifiers: null,
  },
  '1f938-1f3fe': {
    hexCodePoints: '1f938-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏾',
    version: 'E3.0',
    baseName: 'person cartwheeling',
    fullName: 'person cartwheeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f938',
    modifiers: null,
  },
  '1f938-1f3ff': {
    hexCodePoints: '1f938-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏿',
    version: 'E3.0',
    baseName: 'person cartwheeling',
    fullName: 'person cartwheeling: dark skin tone',
    isBase: false,
    baseHex: '1f938',
    modifiers: null,
  },
  '1f938-200d-2642-fe0f': {
    hexCodePoints: '1f938-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸‍♂️',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling',
    isBase: true,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f938-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f938-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f938-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f938-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f938-1f3ff-200d-2642-fe0f',
    },
  },
  '1f938-200d-2642': {
    hexCodePoints: '1f938-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸‍♂',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f938-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏻‍♂️',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fb-200d-2642': {
    hexCodePoints: '1f938-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏻‍♂',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f938-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏼‍♂️',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fc-200d-2642': {
    hexCodePoints: '1f938-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏼‍♂',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f938-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏽‍♂️',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: medium skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fd-200d-2642': {
    hexCodePoints: '1f938-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏽‍♂',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: medium skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f938-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏾‍♂️',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3fe-200d-2642': {
    hexCodePoints: '1f938-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏾‍♂',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f938-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏿‍♂️',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-1f3ff-200d-2642': {
    hexCodePoints: '1f938-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏿‍♂',
    version: 'E4.0',
    baseName: 'man cartwheeling',
    fullName: 'man cartwheeling: dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2642-fe0f',
    modifiers: null,
  },
  '1f938-200d-2640-fe0f': {
    hexCodePoints: '1f938-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸‍♀️',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling',
    isBase: true,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f938-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f938-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f938-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f938-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f938-1f3ff-200d-2640-fe0f',
    },
  },
  '1f938-200d-2640': {
    hexCodePoints: '1f938-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸‍♀',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f938-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fb-200d-2640': {
    hexCodePoints: '1f938-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏻‍♀',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f938-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fc-200d-2640': {
    hexCodePoints: '1f938-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏼‍♀',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: medium-light skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f938-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: medium skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fd-200d-2640': {
    hexCodePoints: '1f938-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏽‍♀',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: medium skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f938-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3fe-200d-2640': {
    hexCodePoints: '1f938-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏾‍♀',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f938-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤸🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f938-1f3ff-200d-2640': {
    hexCodePoints: '1f938-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤸🏿‍♀',
    version: 'E4.0',
    baseName: 'woman cartwheeling',
    fullName: 'woman cartwheeling: dark skin tone',
    isBase: false,
    baseHex: '1f938-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93c': {
    hexCodePoints: '1f93c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤼',
    version: 'E3.0',
    baseName: 'people wrestling',
    fullName: 'people wrestling',
    isBase: true,
    baseHex: '1f93c',
    modifiers: null,
  },
  '1f93c-200d-2642-fe0f': {
    hexCodePoints: '1f93c-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤼‍♂️',
    version: 'E4.0',
    baseName: 'men wrestling',
    fullName: 'men wrestling',
    isBase: true,
    baseHex: '1f93c-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93c-200d-2642': {
    hexCodePoints: '1f93c-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤼‍♂',
    version: 'E4.0',
    baseName: 'men wrestling',
    fullName: 'men wrestling',
    isBase: false,
    baseHex: '1f93c-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93c-200d-2640-fe0f': {
    hexCodePoints: '1f93c-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤼‍♀️',
    version: 'E4.0',
    baseName: 'women wrestling',
    fullName: 'women wrestling',
    isBase: true,
    baseHex: '1f93c-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93c-200d-2640': {
    hexCodePoints: '1f93c-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤼‍♀',
    version: 'E4.0',
    baseName: 'women wrestling',
    fullName: 'women wrestling',
    isBase: false,
    baseHex: '1f93c-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d': {
    hexCodePoints: '1f93d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽',
    version: 'E3.0',
    baseName: 'person playing water polo',
    fullName: 'person playing water polo',
    isBase: true,
    baseHex: '1f93d',
    modifiers: {
      '1f3fb': '1f93d-1f3fb',
      '1f3fc': '1f93d-1f3fc',
      '1f3fd': '1f93d-1f3fd',
      '1f3fe': '1f93d-1f3fe',
      '1f3ff': '1f93d-1f3ff',
    },
  },
  '1f93d-1f3fb': {
    hexCodePoints: '1f93d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏻',
    version: 'E3.0',
    baseName: 'person playing water polo',
    fullName: 'person playing water polo: light skin tone',
    isBase: false,
    baseHex: '1f93d',
    modifiers: null,
  },
  '1f93d-1f3fc': {
    hexCodePoints: '1f93d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏼',
    version: 'E3.0',
    baseName: 'person playing water polo',
    fullName: 'person playing water polo: medium-light skin tone',
    isBase: false,
    baseHex: '1f93d',
    modifiers: null,
  },
  '1f93d-1f3fd': {
    hexCodePoints: '1f93d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏽',
    version: 'E3.0',
    baseName: 'person playing water polo',
    fullName: 'person playing water polo: medium skin tone',
    isBase: false,
    baseHex: '1f93d',
    modifiers: null,
  },
  '1f93d-1f3fe': {
    hexCodePoints: '1f93d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏾',
    version: 'E3.0',
    baseName: 'person playing water polo',
    fullName: 'person playing water polo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93d',
    modifiers: null,
  },
  '1f93d-1f3ff': {
    hexCodePoints: '1f93d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏿',
    version: 'E3.0',
    baseName: 'person playing water polo',
    fullName: 'person playing water polo: dark skin tone',
    isBase: false,
    baseHex: '1f93d',
    modifiers: null,
  },
  '1f93d-200d-2642-fe0f': {
    hexCodePoints: '1f93d-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽‍♂️',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo',
    isBase: true,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f93d-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f93d-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f93d-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f93d-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f93d-1f3ff-200d-2642-fe0f',
    },
  },
  '1f93d-200d-2642': {
    hexCodePoints: '1f93d-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽‍♂',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f93d-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏻‍♂️',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fb-200d-2642': {
    hexCodePoints: '1f93d-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏻‍♂',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f93d-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏼‍♂️',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: medium-light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fc-200d-2642': {
    hexCodePoints: '1f93d-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏼‍♂',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: medium-light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f93d-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏽‍♂️',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: medium skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fd-200d-2642': {
    hexCodePoints: '1f93d-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏽‍♂',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: medium skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f93d-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏾‍♂️',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fe-200d-2642': {
    hexCodePoints: '1f93d-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏾‍♂',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f93d-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏿‍♂️',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-1f3ff-200d-2642': {
    hexCodePoints: '1f93d-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏿‍♂',
    version: 'E4.0',
    baseName: 'man playing water polo',
    fullName: 'man playing water polo: dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93d-200d-2640-fe0f': {
    hexCodePoints: '1f93d-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽‍♀️',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo',
    isBase: true,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f93d-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f93d-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f93d-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f93d-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f93d-1f3ff-200d-2640-fe0f',
    },
  },
  '1f93d-200d-2640': {
    hexCodePoints: '1f93d-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽‍♀',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f93d-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fb-200d-2640': {
    hexCodePoints: '1f93d-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏻‍♀',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f93d-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: medium-light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fc-200d-2640': {
    hexCodePoints: '1f93d-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏼‍♀',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: medium-light skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f93d-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: medium skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fd-200d-2640': {
    hexCodePoints: '1f93d-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏽‍♀',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: medium skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f93d-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3fe-200d-2640': {
    hexCodePoints: '1f93d-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏾‍♀',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f93d-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤽🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93d-1f3ff-200d-2640': {
    hexCodePoints: '1f93d-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤽🏿‍♀',
    version: 'E4.0',
    baseName: 'woman playing water polo',
    fullName: 'woman playing water polo: dark skin tone',
    isBase: false,
    baseHex: '1f93d-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e': {
    hexCodePoints: '1f93e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾',
    version: 'E3.0',
    baseName: 'person playing handball',
    fullName: 'person playing handball',
    isBase: true,
    baseHex: '1f93e',
    modifiers: {
      '1f3fb': '1f93e-1f3fb',
      '1f3fc': '1f93e-1f3fc',
      '1f3fd': '1f93e-1f3fd',
      '1f3fe': '1f93e-1f3fe',
      '1f3ff': '1f93e-1f3ff',
    },
  },
  '1f93e-1f3fb': {
    hexCodePoints: '1f93e-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏻',
    version: 'E3.0',
    baseName: 'person playing handball',
    fullName: 'person playing handball: light skin tone',
    isBase: false,
    baseHex: '1f93e',
    modifiers: null,
  },
  '1f93e-1f3fc': {
    hexCodePoints: '1f93e-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏼',
    version: 'E3.0',
    baseName: 'person playing handball',
    fullName: 'person playing handball: medium-light skin tone',
    isBase: false,
    baseHex: '1f93e',
    modifiers: null,
  },
  '1f93e-1f3fd': {
    hexCodePoints: '1f93e-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏽',
    version: 'E3.0',
    baseName: 'person playing handball',
    fullName: 'person playing handball: medium skin tone',
    isBase: false,
    baseHex: '1f93e',
    modifiers: null,
  },
  '1f93e-1f3fe': {
    hexCodePoints: '1f93e-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏾',
    version: 'E3.0',
    baseName: 'person playing handball',
    fullName: 'person playing handball: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93e',
    modifiers: null,
  },
  '1f93e-1f3ff': {
    hexCodePoints: '1f93e-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏿',
    version: 'E3.0',
    baseName: 'person playing handball',
    fullName: 'person playing handball: dark skin tone',
    isBase: false,
    baseHex: '1f93e',
    modifiers: null,
  },
  '1f93e-200d-2642-fe0f': {
    hexCodePoints: '1f93e-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾‍♂️',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball',
    isBase: true,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f93e-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f93e-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f93e-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f93e-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f93e-1f3ff-200d-2642-fe0f',
    },
  },
  '1f93e-200d-2642': {
    hexCodePoints: '1f93e-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾‍♂',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f93e-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏻‍♂️',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fb-200d-2642': {
    hexCodePoints: '1f93e-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏻‍♂',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f93e-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏼‍♂️',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: medium-light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fc-200d-2642': {
    hexCodePoints: '1f93e-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏼‍♂',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: medium-light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f93e-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏽‍♂️',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: medium skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fd-200d-2642': {
    hexCodePoints: '1f93e-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏽‍♂',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: medium skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f93e-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏾‍♂️',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fe-200d-2642': {
    hexCodePoints: '1f93e-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏾‍♂',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f93e-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏿‍♂️',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-1f3ff-200d-2642': {
    hexCodePoints: '1f93e-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏿‍♂',
    version: 'E4.0',
    baseName: 'man playing handball',
    fullName: 'man playing handball: dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2642-fe0f',
    modifiers: null,
  },
  '1f93e-200d-2640-fe0f': {
    hexCodePoints: '1f93e-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾‍♀️',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball',
    isBase: true,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f93e-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f93e-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f93e-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f93e-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f93e-1f3ff-200d-2640-fe0f',
    },
  },
  '1f93e-200d-2640': {
    hexCodePoints: '1f93e-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾‍♀',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f93e-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fb-200d-2640': {
    hexCodePoints: '1f93e-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏻‍♀',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f93e-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: medium-light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fc-200d-2640': {
    hexCodePoints: '1f93e-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏼‍♀',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: medium-light skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f93e-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: medium skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fd-200d-2640': {
    hexCodePoints: '1f93e-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏽‍♀',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: medium skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f93e-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3fe-200d-2640': {
    hexCodePoints: '1f93e-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏾‍♀',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: medium-dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f93e-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤾🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f93e-1f3ff-200d-2640': {
    hexCodePoints: '1f93e-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤾🏿‍♀',
    version: 'E4.0',
    baseName: 'woman playing handball',
    fullName: 'woman playing handball: dark skin tone',
    isBase: false,
    baseHex: '1f93e-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939': {
    hexCodePoints: '1f939',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹',
    version: 'E3.0',
    baseName: 'person juggling',
    fullName: 'person juggling',
    isBase: true,
    baseHex: '1f939',
    modifiers: {
      '1f3fb': '1f939-1f3fb',
      '1f3fc': '1f939-1f3fc',
      '1f3fd': '1f939-1f3fd',
      '1f3fe': '1f939-1f3fe',
      '1f3ff': '1f939-1f3ff',
    },
  },
  '1f939-1f3fb': {
    hexCodePoints: '1f939-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏻',
    version: 'E3.0',
    baseName: 'person juggling',
    fullName: 'person juggling: light skin tone',
    isBase: false,
    baseHex: '1f939',
    modifiers: null,
  },
  '1f939-1f3fc': {
    hexCodePoints: '1f939-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏼',
    version: 'E3.0',
    baseName: 'person juggling',
    fullName: 'person juggling: medium-light skin tone',
    isBase: false,
    baseHex: '1f939',
    modifiers: null,
  },
  '1f939-1f3fd': {
    hexCodePoints: '1f939-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏽',
    version: 'E3.0',
    baseName: 'person juggling',
    fullName: 'person juggling: medium skin tone',
    isBase: false,
    baseHex: '1f939',
    modifiers: null,
  },
  '1f939-1f3fe': {
    hexCodePoints: '1f939-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏾',
    version: 'E3.0',
    baseName: 'person juggling',
    fullName: 'person juggling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f939',
    modifiers: null,
  },
  '1f939-1f3ff': {
    hexCodePoints: '1f939-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏿',
    version: 'E3.0',
    baseName: 'person juggling',
    fullName: 'person juggling: dark skin tone',
    isBase: false,
    baseHex: '1f939',
    modifiers: null,
  },
  '1f939-200d-2642-fe0f': {
    hexCodePoints: '1f939-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹‍♂️',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling',
    isBase: true,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f939-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f939-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f939-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f939-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f939-1f3ff-200d-2642-fe0f',
    },
  },
  '1f939-200d-2642': {
    hexCodePoints: '1f939-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹‍♂',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f939-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏻‍♂️',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fb-200d-2642': {
    hexCodePoints: '1f939-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏻‍♂',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f939-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏼‍♂️',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: medium-light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fc-200d-2642': {
    hexCodePoints: '1f939-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏼‍♂',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: medium-light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f939-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏽‍♂️',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: medium skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fd-200d-2642': {
    hexCodePoints: '1f939-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏽‍♂',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: medium skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f939-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏾‍♂️',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3fe-200d-2642': {
    hexCodePoints: '1f939-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏾‍♂',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f939-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏿‍♂️',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-1f3ff-200d-2642': {
    hexCodePoints: '1f939-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏿‍♂',
    version: 'E4.0',
    baseName: 'man juggling',
    fullName: 'man juggling: dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2642-fe0f',
    modifiers: null,
  },
  '1f939-200d-2640-fe0f': {
    hexCodePoints: '1f939-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹‍♀️',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling',
    isBase: true,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f939-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f939-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f939-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f939-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f939-1f3ff-200d-2640-fe0f',
    },
  },
  '1f939-200d-2640': {
    hexCodePoints: '1f939-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹‍♀',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f939-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏻‍♀️',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fb-200d-2640': {
    hexCodePoints: '1f939-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏻‍♀',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f939-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏼‍♀️',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: medium-light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fc-200d-2640': {
    hexCodePoints: '1f939-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏼‍♀',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: medium-light skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f939-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏽‍♀️',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: medium skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fd-200d-2640': {
    hexCodePoints: '1f939-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏽‍♀',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: medium skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f939-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏾‍♀️',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3fe-200d-2640': {
    hexCodePoints: '1f939-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏾‍♀',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: medium-dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f939-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤹🏿‍♀️',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f939-1f3ff-200d-2640': {
    hexCodePoints: '1f939-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🤹🏿‍♀',
    version: 'E4.0',
    baseName: 'woman juggling',
    fullName: 'woman juggling: dark skin tone',
    isBase: false,
    baseHex: '1f939-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8': {
    hexCodePoints: '1f9d8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘',
    version: 'E5.0',
    baseName: 'person in lotus position',
    fullName: 'person in lotus position',
    isBase: true,
    baseHex: '1f9d8',
    modifiers: {
      '1f3fb': '1f9d8-1f3fb',
      '1f3fc': '1f9d8-1f3fc',
      '1f3fd': '1f9d8-1f3fd',
      '1f3fe': '1f9d8-1f3fe',
      '1f3ff': '1f9d8-1f3ff',
    },
  },
  '1f9d8-1f3fb': {
    hexCodePoints: '1f9d8-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏻',
    version: 'E5.0',
    baseName: 'person in lotus position',
    fullName: 'person in lotus position: light skin tone',
    isBase: false,
    baseHex: '1f9d8',
    modifiers: null,
  },
  '1f9d8-1f3fc': {
    hexCodePoints: '1f9d8-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏼',
    version: 'E5.0',
    baseName: 'person in lotus position',
    fullName: 'person in lotus position: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d8',
    modifiers: null,
  },
  '1f9d8-1f3fd': {
    hexCodePoints: '1f9d8-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏽',
    version: 'E5.0',
    baseName: 'person in lotus position',
    fullName: 'person in lotus position: medium skin tone',
    isBase: false,
    baseHex: '1f9d8',
    modifiers: null,
  },
  '1f9d8-1f3fe': {
    hexCodePoints: '1f9d8-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏾',
    version: 'E5.0',
    baseName: 'person in lotus position',
    fullName: 'person in lotus position: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d8',
    modifiers: null,
  },
  '1f9d8-1f3ff': {
    hexCodePoints: '1f9d8-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏿',
    version: 'E5.0',
    baseName: 'person in lotus position',
    fullName: 'person in lotus position: dark skin tone',
    isBase: false,
    baseHex: '1f9d8',
    modifiers: null,
  },
  '1f9d8-200d-2642-fe0f': {
    hexCodePoints: '1f9d8-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘‍♂️',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position',
    isBase: true,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: {
      '1f3fb': '1f9d8-1f3fb-200d-2642-fe0f',
      '1f3fc': '1f9d8-1f3fc-200d-2642-fe0f',
      '1f3fd': '1f9d8-1f3fd-200d-2642-fe0f',
      '1f3fe': '1f9d8-1f3fe-200d-2642-fe0f',
      '1f3ff': '1f9d8-1f3ff-200d-2642-fe0f',
    },
  },
  '1f9d8-200d-2642': {
    hexCodePoints: '1f9d8-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘‍♂',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fb-200d-2642-fe0f': {
    hexCodePoints: '1f9d8-1f3fb-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏻‍♂️',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fb-200d-2642': {
    hexCodePoints: '1f9d8-1f3fb-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏻‍♂',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fc-200d-2642-fe0f': {
    hexCodePoints: '1f9d8-1f3fc-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏼‍♂️',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fc-200d-2642': {
    hexCodePoints: '1f9d8-1f3fc-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏼‍♂',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fd-200d-2642-fe0f': {
    hexCodePoints: '1f9d8-1f3fd-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏽‍♂️',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: medium skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fd-200d-2642': {
    hexCodePoints: '1f9d8-1f3fd-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏽‍♂',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: medium skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fe-200d-2642-fe0f': {
    hexCodePoints: '1f9d8-1f3fe-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏾‍♂️',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fe-200d-2642': {
    hexCodePoints: '1f9d8-1f3fe-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏾‍♂',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3ff-200d-2642-fe0f': {
    hexCodePoints: '1f9d8-1f3ff-200d-2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏿‍♂️',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3ff-200d-2642': {
    hexCodePoints: '1f9d8-1f3ff-200d-2642',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏿‍♂',
    version: 'E5.0',
    baseName: 'man in lotus position',
    fullName: 'man in lotus position: dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2642-fe0f',
    modifiers: null,
  },
  '1f9d8-200d-2640-fe0f': {
    hexCodePoints: '1f9d8-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘‍♀️',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position',
    isBase: true,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: {
      '1f3fb': '1f9d8-1f3fb-200d-2640-fe0f',
      '1f3fc': '1f9d8-1f3fc-200d-2640-fe0f',
      '1f3fd': '1f9d8-1f3fd-200d-2640-fe0f',
      '1f3fe': '1f9d8-1f3fe-200d-2640-fe0f',
      '1f3ff': '1f9d8-1f3ff-200d-2640-fe0f',
    },
  },
  '1f9d8-200d-2640': {
    hexCodePoints: '1f9d8-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘‍♀',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fb-200d-2640-fe0f': {
    hexCodePoints: '1f9d8-1f3fb-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏻‍♀️',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fb-200d-2640': {
    hexCodePoints: '1f9d8-1f3fb-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏻‍♀',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fc-200d-2640-fe0f': {
    hexCodePoints: '1f9d8-1f3fc-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏼‍♀️',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fc-200d-2640': {
    hexCodePoints: '1f9d8-1f3fc-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏼‍♀',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fd-200d-2640-fe0f': {
    hexCodePoints: '1f9d8-1f3fd-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏽‍♀️',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: medium skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fd-200d-2640': {
    hexCodePoints: '1f9d8-1f3fd-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏽‍♀',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: medium skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fe-200d-2640-fe0f': {
    hexCodePoints: '1f9d8-1f3fe-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏾‍♀️',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3fe-200d-2640': {
    hexCodePoints: '1f9d8-1f3fe-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏾‍♀',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3ff-200d-2640-fe0f': {
    hexCodePoints: '1f9d8-1f3ff-200d-2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧘🏿‍♀️',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f9d8-1f3ff-200d-2640': {
    hexCodePoints: '1f9d8-1f3ff-200d-2640',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧘🏿‍♀',
    version: 'E5.0',
    baseName: 'woman in lotus position',
    fullName: 'woman in lotus position: dark skin tone',
    isBase: false,
    baseHex: '1f9d8-200d-2640-fe0f',
    modifiers: null,
  },
  '1f6c0': {
    hexCodePoints: '1f6c0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛀',
    version: 'E0.6',
    baseName: 'person taking bath',
    fullName: 'person taking bath',
    isBase: true,
    baseHex: '1f6c0',
    modifiers: {
      '1f3fb': '1f6c0-1f3fb',
      '1f3fc': '1f6c0-1f3fc',
      '1f3fd': '1f6c0-1f3fd',
      '1f3fe': '1f6c0-1f3fe',
      '1f3ff': '1f6c0-1f3ff',
    },
  },
  '1f6c0-1f3fb': {
    hexCodePoints: '1f6c0-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛀🏻',
    version: 'E1.0',
    baseName: 'person taking bath',
    fullName: 'person taking bath: light skin tone',
    isBase: false,
    baseHex: '1f6c0',
    modifiers: null,
  },
  '1f6c0-1f3fc': {
    hexCodePoints: '1f6c0-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛀🏼',
    version: 'E1.0',
    baseName: 'person taking bath',
    fullName: 'person taking bath: medium-light skin tone',
    isBase: false,
    baseHex: '1f6c0',
    modifiers: null,
  },
  '1f6c0-1f3fd': {
    hexCodePoints: '1f6c0-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛀🏽',
    version: 'E1.0',
    baseName: 'person taking bath',
    fullName: 'person taking bath: medium skin tone',
    isBase: false,
    baseHex: '1f6c0',
    modifiers: null,
  },
  '1f6c0-1f3fe': {
    hexCodePoints: '1f6c0-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛀🏾',
    version: 'E1.0',
    baseName: 'person taking bath',
    fullName: 'person taking bath: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6c0',
    modifiers: null,
  },
  '1f6c0-1f3ff': {
    hexCodePoints: '1f6c0-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛀🏿',
    version: 'E1.0',
    baseName: 'person taking bath',
    fullName: 'person taking bath: dark skin tone',
    isBase: false,
    baseHex: '1f6c0',
    modifiers: null,
  },
  '1f6cc': {
    hexCodePoints: '1f6cc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛌',
    version: 'E1.0',
    baseName: 'person in bed',
    fullName: 'person in bed',
    isBase: true,
    baseHex: '1f6cc',
    modifiers: {
      '1f3fb': '1f6cc-1f3fb',
      '1f3fc': '1f6cc-1f3fc',
      '1f3fd': '1f6cc-1f3fd',
      '1f3fe': '1f6cc-1f3fe',
      '1f3ff': '1f6cc-1f3ff',
    },
  },
  '1f6cc-1f3fb': {
    hexCodePoints: '1f6cc-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛌🏻',
    version: 'E4.0',
    baseName: 'person in bed',
    fullName: 'person in bed: light skin tone',
    isBase: false,
    baseHex: '1f6cc',
    modifiers: null,
  },
  '1f6cc-1f3fc': {
    hexCodePoints: '1f6cc-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛌🏼',
    version: 'E4.0',
    baseName: 'person in bed',
    fullName: 'person in bed: medium-light skin tone',
    isBase: false,
    baseHex: '1f6cc',
    modifiers: null,
  },
  '1f6cc-1f3fd': {
    hexCodePoints: '1f6cc-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛌🏽',
    version: 'E4.0',
    baseName: 'person in bed',
    fullName: 'person in bed: medium skin tone',
    isBase: false,
    baseHex: '1f6cc',
    modifiers: null,
  },
  '1f6cc-1f3fe': {
    hexCodePoints: '1f6cc-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛌🏾',
    version: 'E4.0',
    baseName: 'person in bed',
    fullName: 'person in bed: medium-dark skin tone',
    isBase: false,
    baseHex: '1f6cc',
    modifiers: null,
  },
  '1f6cc-1f3ff': {
    hexCodePoints: '1f6cc-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛌🏿',
    version: 'E4.0',
    baseName: 'person in bed',
    fullName: 'person in bed: dark skin tone',
    isBase: false,
    baseHex: '1f6cc',
    modifiers: null,
  },
  '1f9d1-200d-1f91d-200d-1f9d1': {
    hexCodePoints: '1f9d1-200d-1f91d-200d-1f9d1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🤝‍🧑',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands',
    isBase: true,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🤝‍🧑🏻',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🤝‍🧑🏼',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🤝‍🧑🏽',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🤝‍🧑🏾',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fb-200d-1f91d-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍🤝‍🧑🏿',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🤝‍🧑🏻',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🤝‍🧑🏼',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🤝‍🧑🏽',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🤝‍🧑🏾',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fc-200d-1f91d-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍🤝‍🧑🏿',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🤝‍🧑🏻',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🤝‍🧑🏼',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🤝‍🧑🏽',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🤝‍🧑🏾',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fd-200d-1f91d-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍🤝‍🧑🏿',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🤝‍🧑🏻',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🤝‍🧑🏼',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🤝‍🧑🏽',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🤝‍🧑🏾',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fe-200d-1f91d-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍🤝‍🧑🏿',
    version: 'E12.1',
    baseName: 'people holding hands',
    fullName: 'people holding hands: medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🤝‍🧑🏻',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🤝‍🧑🏼',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🤝‍🧑🏽',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🤝‍🧑🏾',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3ff-200d-1f91d-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍🤝‍🧑🏿',
    version: 'E12.0',
    baseName: 'people holding hands',
    fullName: 'people holding hands: dark skin tone',
    isBase: false,
    baseHex: '1f9d1-200d-1f91d-200d-1f9d1',
    modifiers: null,
  },
  '1f46d': {
    hexCodePoints: '1f46d',
    qualifiedStatus: 'fully-qualified',
    emoji: '👭',
    version: 'E1.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands',
    isBase: true,
    baseHex: '1f46d',
    modifiers: {
      '1f3fb': '1f46d-1f3fb',
      '1f3fc': '1f46d-1f3fc',
      '1f3fd': '1f46d-1f3fd',
      '1f3fe': '1f46d-1f3fe',
      '1f3ff': '1f46d-1f3ff',
    },
  },
  '1f46d-1f3fb': {
    hexCodePoints: '1f46d-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👭🏻',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👩🏼',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👩🏽',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👩🏾',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👩🏿',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👩🏻',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f46d-1f3fc': {
    hexCodePoints: '1f46d-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👭🏼',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👩🏽',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👩🏾',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👩🏿',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👩🏻',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👩🏼',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f46d-1f3fd': {
    hexCodePoints: '1f46d-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👭🏽',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👩🏾',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👩🏿',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👩🏻',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👩🏼',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👩🏽',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f46d-1f3fe': {
    hexCodePoints: '1f46d-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👭🏾',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👩🏿',
    version: 'E12.1',
    baseName: 'women holding hands',
    fullName: 'women holding hands: medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👩🏻',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👩🏼',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👩🏽',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👩🏾',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f46d-1f3ff': {
    hexCodePoints: '1f46d-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👭🏿',
    version: 'E12.0',
    baseName: 'women holding hands',
    fullName: 'women holding hands: dark skin tone',
    isBase: false,
    baseHex: '1f46d',
    modifiers: null,
  },
  '1f46b': {
    hexCodePoints: '1f46b',
    qualifiedStatus: 'fully-qualified',
    emoji: '👫',
    version: 'E0.6',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands',
    isBase: true,
    baseHex: '1f46b',
    modifiers: {
      '1f3fb': '1f46b-1f3fb',
      '1f3fc': '1f46b-1f3fc',
      '1f3fd': '1f46b-1f3fd',
      '1f3fe': '1f46b-1f3fe',
      '1f3ff': '1f46b-1f3ff',
    },
  },
  '1f46b-1f3fb': {
    hexCodePoints: '1f46b-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👫🏻',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👨🏽',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👨🏾',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍🤝‍👨🏿',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f46b-1f3fc': {
    hexCodePoints: '1f46b-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👫🏼',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👨🏽',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👨🏾',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍🤝‍👨🏿',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f46b-1f3fd': {
    hexCodePoints: '1f46b-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👫🏽',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👨🏾',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍🤝‍👨🏿',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👨🏽',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f46b-1f3fe': {
    hexCodePoints: '1f46b-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👫🏾',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍🤝‍👨🏿',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👨🏽',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍🤝‍👨🏾',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f46b-1f3ff': {
    hexCodePoints: '1f46b-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👫🏿',
    version: 'E12.0',
    baseName: 'woman and man holding hands',
    fullName: 'woman and man holding hands: dark skin tone',
    isBase: false,
    baseHex: '1f46b',
    modifiers: null,
  },
  '1f46c': {
    hexCodePoints: '1f46c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👬',
    version: 'E1.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands',
    isBase: true,
    baseHex: '1f46c',
    modifiers: {
      '1f3fb': '1f46c-1f3fb',
      '1f3fc': '1f46c-1f3fc',
      '1f3fd': '1f46c-1f3fd',
      '1f3fe': '1f46c-1f3fe',
      '1f3ff': '1f46c-1f3ff',
    },
  },
  '1f46c-1f3fb': {
    hexCodePoints: '1f46c-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👬🏻',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fb-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🤝‍👨🏼',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fb-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🤝‍👨🏽',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fb-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🤝‍👨🏾',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fb-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍🤝‍👨🏿',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fc-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f46c-1f3fc': {
    hexCodePoints: '1f46c-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👬🏼',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fc-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🤝‍👨🏽',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fc-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🤝‍👨🏾',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fc-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍🤝‍👨🏿',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fd-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fd-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f46c-1f3fd': {
    hexCodePoints: '1f46c-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👬🏽',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fd-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🤝‍👨🏾',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fd-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍🤝‍👨🏿',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fe-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fe-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fe-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🤝‍👨🏽',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f46c-1f3fe': {
    hexCodePoints: '1f46c-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👬🏾',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fe-200d-1f91d-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍🤝‍👨🏿',
    version: 'E12.1',
    baseName: 'men holding hands',
    fullName: 'men holding hands: medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🤝‍👨🏻',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🤝‍👨🏼',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🤝‍👨🏽',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3ff-200d-1f91d-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍🤝‍👨🏾',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f46c-1f3ff': {
    hexCodePoints: '1f46c-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👬🏿',
    version: 'E12.0',
    baseName: 'men holding hands',
    fullName: 'men holding hands: dark skin tone',
    isBase: false,
    baseHex: '1f46c',
    modifiers: null,
  },
  '1f48f': {
    hexCodePoints: '1f48f',
    qualifiedStatus: 'fully-qualified',
    emoji: '💏',
    version: 'E0.6',
    baseName: 'kiss',
    fullName: 'kiss',
    isBase: true,
    baseHex: '1f48f',
    modifiers: {
      '1f3fb': '1f48f-1f3fb',
      '1f3fc': '1f48f-1f3fc',
      '1f3fd': '1f48f-1f3fd',
      '1f3fe': '1f48f-1f3fe',
      '1f3ff': '1f48f-1f3ff',
    },
  },
  '1f48f-1f3fb': {
    hexCodePoints: '1f48f-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💏🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f48f-1f3fc': {
    hexCodePoints: '1f48f-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💏🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f48f-1f3fd': {
    hexCodePoints: '1f48f-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💏🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f48f-1f3fe': {
    hexCodePoints: '1f48f-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💏🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f48f-1f3ff': {
    hexCodePoints: '1f48f-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💏🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f48b-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍💋‍🧑🏿',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍💋‍🧑🏻',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍💋‍🧑🏼',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍💋‍🧑🏽',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f48b-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍💋‍🧑🏾',
    version: 'E13.1',
    baseName: 'kiss',
    fullName: 'kiss: person, person, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f48f',
    modifiers: null,
  },
  '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468': {
    hexCodePoints: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍❤️‍💋‍👨',
    version: 'E2.0',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man',
    isBase: true,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-200d-2764-200d-1f48b-200d-1f468': {
    hexCodePoints: '1f469-200d-2764-200d-1f48b-200d-1f468',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍❤‍💋‍👨',
    version: 'E2.0',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, man',
    fullName: 'kiss: woman, man, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468': {
    hexCodePoints: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍❤️‍💋‍👨',
    version: 'E2.0',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man',
    isBase: true,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-200d-2764-200d-1f48b-200d-1f468': {
    hexCodePoints: '1f468-200d-2764-200d-1f48b-200d-1f468',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍❤‍💋‍👨',
    version: 'E2.0',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍💋‍👨🏻',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍💋‍👨🏼',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍💋‍👨🏽',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍💋‍👨🏾',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f48b-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍💋‍👨🏿',
    version: 'E13.1',
    baseName: 'kiss: man, man',
    fullName: 'kiss: man, man, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f48b-200d-1f468',
    modifiers: null,
  },
  '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469': {
    hexCodePoints: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍❤️‍💋‍👩',
    version: 'E2.0',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman',
    isBase: true,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-200d-2764-200d-1f48b-200d-1f469': {
    hexCodePoints: '1f469-200d-2764-200d-1f48b-200d-1f469',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍❤‍💋‍👩',
    version: 'E2.0',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👩🏻',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👩🏼',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👩🏽',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👩🏾',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f48b-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍💋‍👩🏿',
    version: 'E13.1',
    baseName: 'kiss: woman, woman',
    fullName: 'kiss: woman, woman, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f48b-200d-1f469',
    modifiers: null,
  },
  '1f491': {
    hexCodePoints: '1f491',
    qualifiedStatus: 'fully-qualified',
    emoji: '💑',
    version: 'E0.6',
    baseName: 'couple with heart',
    fullName: 'couple with heart',
    isBase: true,
    baseHex: '1f491',
    modifiers: {
      '1f3fb': '1f491-1f3fb',
      '1f3fc': '1f491-1f3fc',
      '1f3fd': '1f491-1f3fd',
      '1f3fe': '1f491-1f3fe',
      '1f3ff': '1f491-1f3ff',
    },
  },
  '1f491-1f3fb': {
    hexCodePoints: '1f491-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f491-1f3fc': {
    hexCodePoints: '1f491-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f491-1f3fd': {
    hexCodePoints: '1f491-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f491-1f3fe': {
    hexCodePoints: '1f491-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '💑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f491-1f3ff': {
    hexCodePoints: '1f491-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '💑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-fe0f-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏻‍❤️‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fb-200d-2764-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏻‍❤‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-fe0f-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏼‍❤️‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fc-200d-2764-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏼‍❤‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-fe0f-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏽‍❤️‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fd-200d-2764-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏽‍❤‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-fe0f-200d-1f9d1-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏾‍❤️‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3ff': {
    hexCodePoints: '1f9d1-1f3fe-200d-2764-200d-1f9d1-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏾‍❤‍🧑🏿',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fb': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍🧑🏻',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fc': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍🧑🏼',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fd': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍🧑🏽',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-fe0f-200d-1f9d1-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑🏿‍❤️‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fe': {
    hexCodePoints: '1f9d1-1f3ff-200d-2764-200d-1f9d1-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🧑🏿‍❤‍🧑🏾',
    version: 'E13.1',
    baseName: 'couple with heart',
    fullName: 'couple with heart: person, person, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f491',
    modifiers: null,
  },
  '1f469-200d-2764-fe0f-200d-1f468': {
    hexCodePoints: '1f469-200d-2764-fe0f-200d-1f468',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍❤️‍👨',
    version: 'E2.0',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man',
    isBase: true,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-200d-2764-200d-1f468': {
    hexCodePoints: '1f469-200d-2764-200d-1f468',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍❤‍👨',
    version: 'E2.0',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, man',
    fullName: 'couple with heart: woman, man, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-200d-2764-fe0f-200d-1f468': {
    hexCodePoints: '1f468-200d-2764-fe0f-200d-1f468',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍❤️‍👨',
    version: 'E2.0',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man',
    isBase: true,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-200d-2764-200d-1f468': {
    hexCodePoints: '1f468-200d-2764-200d-1f468',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨‍❤‍👨',
    version: 'E2.0',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fb-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏻‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fb-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fb-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏻‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fc-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏼‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fc-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fc-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏼‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fd-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏽‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fd-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fd-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏽‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fe-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏾‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3fe-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3fe-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏾‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f468-1f3fb': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f468-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍👨🏻',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f468-1f3fc': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f468-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍👨🏼',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f468-1f3fd': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f468-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍👨🏽',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f468-1f3fe': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f468-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍👨🏾',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3ff-200d-2764-fe0f-200d-1f468-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨🏿‍❤️‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f468-1f3ff-200d-2764-200d-1f468-1f3ff': {
    hexCodePoints: '1f468-1f3ff-200d-2764-200d-1f468-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👨🏿‍❤‍👨🏿',
    version: 'E13.1',
    baseName: 'couple with heart: man, man',
    fullName: 'couple with heart: man, man, dark skin tone',
    isBase: false,
    baseHex: '1f468-200d-2764-fe0f-200d-1f468',
    modifiers: null,
  },
  '1f469-200d-2764-fe0f-200d-1f469': {
    hexCodePoints: '1f469-200d-2764-fe0f-200d-1f469',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍❤️‍👩',
    version: 'E2.0',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman',
    isBase: true,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-200d-2764-200d-1f469': {
    hexCodePoints: '1f469-200d-2764-200d-1f469',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩‍❤‍👩',
    version: 'E2.0',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-fe0f-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏻‍❤️‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fb-200d-2764-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fb-200d-2764-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏻‍❤‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-fe0f-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏼‍❤️‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fc-200d-2764-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fc-200d-2764-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏼‍❤‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-light skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-fe0f-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏽‍❤️‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fd-200d-2764-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fd-200d-2764-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏽‍❤‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-fe0f-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏾‍❤️‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3fe-200d-2764-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3fe-200d-2764-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏾‍❤‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, medium-dark skin tone, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f469-1f3fb': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f469-1f3fb',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👩🏻',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f469-1f3fc': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f469-1f3fc',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👩🏼',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, medium-light skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f469-1f3fd': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f469-1f3fd',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👩🏽',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, medium skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f469-1f3fe': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f469-1f3fe',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👩🏾',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone, medium-dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-fe0f-200d-1f469-1f3ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩🏿‍❤️‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f469-1f3ff-200d-2764-200d-1f469-1f3ff': {
    hexCodePoints: '1f469-1f3ff-200d-2764-200d-1f469-1f3ff',
    qualifiedStatus: 'minimally-qualified',
    emoji: '👩🏿‍❤‍👩🏿',
    version: 'E13.1',
    baseName: 'couple with heart: woman, woman',
    fullName: 'couple with heart: woman, woman, dark skin tone',
    isBase: false,
    baseHex: '1f469-200d-2764-fe0f-200d-1f469',
    modifiers: null,
  },
  '1f468-200d-1f469-200d-1f466': {
    hexCodePoints: '1f468-200d-1f469-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👩‍👦',
    version: 'E2.0',
    baseName: 'family: man, woman, boy',
    fullName: 'family: man, woman, boy',
    isBase: true,
    baseHex: '1f468-200d-1f469-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f469-200d-1f467': {
    hexCodePoints: '1f468-200d-1f469-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👩‍👧',
    version: 'E2.0',
    baseName: 'family: man, woman, girl',
    fullName: 'family: man, woman, girl',
    isBase: true,
    baseHex: '1f468-200d-1f469-200d-1f467',
    modifiers: null,
  },
  '1f468-200d-1f469-200d-1f467-200d-1f466': {
    hexCodePoints: '1f468-200d-1f469-200d-1f467-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👩‍👧‍👦',
    version: 'E2.0',
    baseName: 'family: man, woman, girl, boy',
    fullName: 'family: man, woman, girl, boy',
    isBase: true,
    baseHex: '1f468-200d-1f469-200d-1f467-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f469-200d-1f466-200d-1f466': {
    hexCodePoints: '1f468-200d-1f469-200d-1f466-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👩‍👦‍👦',
    version: 'E2.0',
    baseName: 'family: man, woman, boy, boy',
    fullName: 'family: man, woman, boy, boy',
    isBase: true,
    baseHex: '1f468-200d-1f469-200d-1f466-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f469-200d-1f467-200d-1f467': {
    hexCodePoints: '1f468-200d-1f469-200d-1f467-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👩‍👧‍👧',
    version: 'E2.0',
    baseName: 'family: man, woman, girl, girl',
    fullName: 'family: man, woman, girl, girl',
    isBase: true,
    baseHex: '1f468-200d-1f469-200d-1f467-200d-1f467',
    modifiers: null,
  },
  '1f468-200d-1f468-200d-1f466': {
    hexCodePoints: '1f468-200d-1f468-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👨‍👦',
    version: 'E2.0',
    baseName: 'family: man, man, boy',
    fullName: 'family: man, man, boy',
    isBase: true,
    baseHex: '1f468-200d-1f468-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f468-200d-1f467': {
    hexCodePoints: '1f468-200d-1f468-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👨‍👧',
    version: 'E2.0',
    baseName: 'family: man, man, girl',
    fullName: 'family: man, man, girl',
    isBase: true,
    baseHex: '1f468-200d-1f468-200d-1f467',
    modifiers: null,
  },
  '1f468-200d-1f468-200d-1f467-200d-1f466': {
    hexCodePoints: '1f468-200d-1f468-200d-1f467-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👨‍👧‍👦',
    version: 'E2.0',
    baseName: 'family: man, man, girl, boy',
    fullName: 'family: man, man, girl, boy',
    isBase: true,
    baseHex: '1f468-200d-1f468-200d-1f467-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f468-200d-1f466-200d-1f466': {
    hexCodePoints: '1f468-200d-1f468-200d-1f466-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👨‍👦‍👦',
    version: 'E2.0',
    baseName: 'family: man, man, boy, boy',
    fullName: 'family: man, man, boy, boy',
    isBase: true,
    baseHex: '1f468-200d-1f468-200d-1f466-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f468-200d-1f467-200d-1f467': {
    hexCodePoints: '1f468-200d-1f468-200d-1f467-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👨‍👧‍👧',
    version: 'E2.0',
    baseName: 'family: man, man, girl, girl',
    fullName: 'family: man, man, girl, girl',
    isBase: true,
    baseHex: '1f468-200d-1f468-200d-1f467-200d-1f467',
    modifiers: null,
  },
  '1f469-200d-1f469-200d-1f466': {
    hexCodePoints: '1f469-200d-1f469-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👩‍👦',
    version: 'E2.0',
    baseName: 'family: woman, woman, boy',
    fullName: 'family: woman, woman, boy',
    isBase: true,
    baseHex: '1f469-200d-1f469-200d-1f466',
    modifiers: null,
  },
  '1f469-200d-1f469-200d-1f467': {
    hexCodePoints: '1f469-200d-1f469-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👩‍👧',
    version: 'E2.0',
    baseName: 'family: woman, woman, girl',
    fullName: 'family: woman, woman, girl',
    isBase: true,
    baseHex: '1f469-200d-1f469-200d-1f467',
    modifiers: null,
  },
  '1f469-200d-1f469-200d-1f467-200d-1f466': {
    hexCodePoints: '1f469-200d-1f469-200d-1f467-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👩‍👧‍👦',
    version: 'E2.0',
    baseName: 'family: woman, woman, girl, boy',
    fullName: 'family: woman, woman, girl, boy',
    isBase: true,
    baseHex: '1f469-200d-1f469-200d-1f467-200d-1f466',
    modifiers: null,
  },
  '1f469-200d-1f469-200d-1f466-200d-1f466': {
    hexCodePoints: '1f469-200d-1f469-200d-1f466-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👩‍👦‍👦',
    version: 'E2.0',
    baseName: 'family: woman, woman, boy, boy',
    fullName: 'family: woman, woman, boy, boy',
    isBase: true,
    baseHex: '1f469-200d-1f469-200d-1f466-200d-1f466',
    modifiers: null,
  },
  '1f469-200d-1f469-200d-1f467-200d-1f467': {
    hexCodePoints: '1f469-200d-1f469-200d-1f467-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👩‍👧‍👧',
    version: 'E2.0',
    baseName: 'family: woman, woman, girl, girl',
    fullName: 'family: woman, woman, girl, girl',
    isBase: true,
    baseHex: '1f469-200d-1f469-200d-1f467-200d-1f467',
    modifiers: null,
  },
  '1f468-200d-1f466': {
    hexCodePoints: '1f468-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👦',
    version: 'E4.0',
    baseName: 'family: man, boy',
    fullName: 'family: man, boy',
    isBase: true,
    baseHex: '1f468-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f466-200d-1f466': {
    hexCodePoints: '1f468-200d-1f466-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👦‍👦',
    version: 'E4.0',
    baseName: 'family: man, boy, boy',
    fullName: 'family: man, boy, boy',
    isBase: true,
    baseHex: '1f468-200d-1f466-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f467': {
    hexCodePoints: '1f468-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👧',
    version: 'E4.0',
    baseName: 'family: man, girl',
    fullName: 'family: man, girl',
    isBase: true,
    baseHex: '1f468-200d-1f467',
    modifiers: null,
  },
  '1f468-200d-1f467-200d-1f466': {
    hexCodePoints: '1f468-200d-1f467-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👧‍👦',
    version: 'E4.0',
    baseName: 'family: man, girl, boy',
    fullName: 'family: man, girl, boy',
    isBase: true,
    baseHex: '1f468-200d-1f467-200d-1f466',
    modifiers: null,
  },
  '1f468-200d-1f467-200d-1f467': {
    hexCodePoints: '1f468-200d-1f467-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👨‍👧‍👧',
    version: 'E4.0',
    baseName: 'family: man, girl, girl',
    fullName: 'family: man, girl, girl',
    isBase: true,
    baseHex: '1f468-200d-1f467-200d-1f467',
    modifiers: null,
  },
  '1f469-200d-1f466': {
    hexCodePoints: '1f469-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👦',
    version: 'E4.0',
    baseName: 'family: woman, boy',
    fullName: 'family: woman, boy',
    isBase: true,
    baseHex: '1f469-200d-1f466',
    modifiers: null,
  },
  '1f469-200d-1f466-200d-1f466': {
    hexCodePoints: '1f469-200d-1f466-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👦‍👦',
    version: 'E4.0',
    baseName: 'family: woman, boy, boy',
    fullName: 'family: woman, boy, boy',
    isBase: true,
    baseHex: '1f469-200d-1f466-200d-1f466',
    modifiers: null,
  },
  '1f469-200d-1f467': {
    hexCodePoints: '1f469-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👧',
    version: 'E4.0',
    baseName: 'family: woman, girl',
    fullName: 'family: woman, girl',
    isBase: true,
    baseHex: '1f469-200d-1f467',
    modifiers: null,
  },
  '1f469-200d-1f467-200d-1f466': {
    hexCodePoints: '1f469-200d-1f467-200d-1f466',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👧‍👦',
    version: 'E4.0',
    baseName: 'family: woman, girl, boy',
    fullName: 'family: woman, girl, boy',
    isBase: true,
    baseHex: '1f469-200d-1f467-200d-1f466',
    modifiers: null,
  },
  '1f469-200d-1f467-200d-1f467': {
    hexCodePoints: '1f469-200d-1f467-200d-1f467',
    qualifiedStatus: 'fully-qualified',
    emoji: '👩‍👧‍👧',
    version: 'E4.0',
    baseName: 'family: woman, girl, girl',
    fullName: 'family: woman, girl, girl',
    isBase: true,
    baseHex: '1f469-200d-1f467-200d-1f467',
    modifiers: null,
  },
  '1f5e3-fe0f': {
    hexCodePoints: '1f5e3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗣️',
    version: 'E0.7',
    baseName: 'speaking head',
    fullName: 'speaking head',
    isBase: true,
    baseHex: '1f5e3-fe0f',
    modifiers: null,
  },
  '1f5e3': {
    hexCodePoints: '1f5e3',
    qualifiedStatus: 'unqualified',
    emoji: '🗣',
    version: 'E0.7',
    baseName: 'speaking head',
    fullName: 'speaking head',
    isBase: false,
    baseHex: '1f5e3-fe0f',
    modifiers: null,
  },
  '1f464': {
    hexCodePoints: '1f464',
    qualifiedStatus: 'fully-qualified',
    emoji: '👤',
    version: 'E0.6',
    baseName: 'bust in silhouette',
    fullName: 'bust in silhouette',
    isBase: true,
    baseHex: '1f464',
    modifiers: null,
  },
  '1f465': {
    hexCodePoints: '1f465',
    qualifiedStatus: 'fully-qualified',
    emoji: '👥',
    version: 'E1.0',
    baseName: 'busts in silhouette',
    fullName: 'busts in silhouette',
    isBase: true,
    baseHex: '1f465',
    modifiers: null,
  },
  '1fac2': {
    hexCodePoints: '1fac2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫂',
    version: 'E13.0',
    baseName: 'people hugging',
    fullName: 'people hugging',
    isBase: true,
    baseHex: '1fac2',
    modifiers: null,
  },
  '1f46a': {
    hexCodePoints: '1f46a',
    qualifiedStatus: 'fully-qualified',
    emoji: '👪',
    version: 'E0.6',
    baseName: 'family',
    fullName: 'family',
    isBase: true,
    baseHex: '1f46a',
    modifiers: null,
  },
  '1f9d1-200d-1f9d1-200d-1f9d2': {
    hexCodePoints: '1f9d1-200d-1f9d1-200d-1f9d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🧑‍🧒',
    version: 'E15.1',
    baseName: 'family: adult, adult, child',
    fullName: 'family: adult, adult, child',
    isBase: true,
    baseHex: '1f9d1-200d-1f9d1-200d-1f9d2',
    modifiers: null,
  },
  '1f9d1-200d-1f9d1-200d-1f9d2-200d-1f9d2': {
    hexCodePoints: '1f9d1-200d-1f9d1-200d-1f9d2-200d-1f9d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🧑‍🧒‍🧒',
    version: 'E15.1',
    baseName: 'family: adult, adult, child, child',
    fullName: 'family: adult, adult, child, child',
    isBase: true,
    baseHex: '1f9d1-200d-1f9d1-200d-1f9d2-200d-1f9d2',
    modifiers: null,
  },
  '1f9d1-200d-1f9d2': {
    hexCodePoints: '1f9d1-200d-1f9d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🧒',
    version: 'E15.1',
    baseName: 'family: adult, child',
    fullName: 'family: adult, child',
    isBase: true,
    baseHex: '1f9d1-200d-1f9d2',
    modifiers: null,
  },
  '1f9d1-200d-1f9d2-200d-1f9d2': {
    hexCodePoints: '1f9d1-200d-1f9d2-200d-1f9d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧑‍🧒‍🧒',
    version: 'E15.1',
    baseName: 'family: adult, child, child',
    fullName: 'family: adult, child, child',
    isBase: true,
    baseHex: '1f9d1-200d-1f9d2-200d-1f9d2',
    modifiers: null,
  },
  '1f463': {
    hexCodePoints: '1f463',
    qualifiedStatus: 'fully-qualified',
    emoji: '👣',
    version: 'E0.6',
    baseName: 'footprints',
    fullName: 'footprints',
    isBase: true,
    baseHex: '1f463',
    modifiers: null,
  },
  '1f435': {
    hexCodePoints: '1f435',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐵',
    version: 'E0.6',
    baseName: 'monkey face',
    fullName: 'monkey face',
    isBase: true,
    baseHex: '1f435',
    modifiers: null,
  },
  '1f412': {
    hexCodePoints: '1f412',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐒',
    version: 'E0.6',
    baseName: 'monkey',
    fullName: 'monkey',
    isBase: true,
    baseHex: '1f412',
    modifiers: null,
  },
  '1f98d': {
    hexCodePoints: '1f98d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦍',
    version: 'E3.0',
    baseName: 'gorilla',
    fullName: 'gorilla',
    isBase: true,
    baseHex: '1f98d',
    modifiers: null,
  },
  '1f9a7': {
    hexCodePoints: '1f9a7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦧',
    version: 'E12.0',
    baseName: 'orangutan',
    fullName: 'orangutan',
    isBase: true,
    baseHex: '1f9a7',
    modifiers: null,
  },
  '1f436': {
    hexCodePoints: '1f436',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐶',
    version: 'E0.6',
    baseName: 'dog face',
    fullName: 'dog face',
    isBase: true,
    baseHex: '1f436',
    modifiers: null,
  },
  '1f415': {
    hexCodePoints: '1f415',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐕',
    version: 'E0.7',
    baseName: 'dog',
    fullName: 'dog',
    isBase: true,
    baseHex: '1f415',
    modifiers: null,
  },
  '1f9ae': {
    hexCodePoints: '1f9ae',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦮',
    version: 'E12.0',
    baseName: 'guide dog',
    fullName: 'guide dog',
    isBase: true,
    baseHex: '1f9ae',
    modifiers: null,
  },
  '1f415-200d-1f9ba': {
    hexCodePoints: '1f415-200d-1f9ba',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐕‍🦺',
    version: 'E12.0',
    baseName: 'service dog',
    fullName: 'service dog',
    isBase: true,
    baseHex: '1f415-200d-1f9ba',
    modifiers: null,
  },
  '1f429': {
    hexCodePoints: '1f429',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐩',
    version: 'E0.6',
    baseName: 'poodle',
    fullName: 'poodle',
    isBase: true,
    baseHex: '1f429',
    modifiers: null,
  },
  '1f43a': {
    hexCodePoints: '1f43a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐺',
    version: 'E0.6',
    baseName: 'wolf',
    fullName: 'wolf',
    isBase: true,
    baseHex: '1f43a',
    modifiers: null,
  },
  '1f98a': {
    hexCodePoints: '1f98a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦊',
    version: 'E3.0',
    baseName: 'fox',
    fullName: 'fox',
    isBase: true,
    baseHex: '1f98a',
    modifiers: null,
  },
  '1f99d': {
    hexCodePoints: '1f99d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦝',
    version: 'E11.0',
    baseName: 'raccoon',
    fullName: 'raccoon',
    isBase: true,
    baseHex: '1f99d',
    modifiers: null,
  },
  '1f431': {
    hexCodePoints: '1f431',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐱',
    version: 'E0.6',
    baseName: 'cat face',
    fullName: 'cat face',
    isBase: true,
    baseHex: '1f431',
    modifiers: null,
  },
  '1f408': {
    hexCodePoints: '1f408',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐈',
    version: 'E0.7',
    baseName: 'cat',
    fullName: 'cat',
    isBase: true,
    baseHex: '1f408',
    modifiers: null,
  },
  '1f408-200d-2b1b': {
    hexCodePoints: '1f408-200d-2b1b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐈‍⬛',
    version: 'E13.0',
    baseName: 'black cat',
    fullName: 'black cat',
    isBase: true,
    baseHex: '1f408-200d-2b1b',
    modifiers: null,
  },
  '1f981': {
    hexCodePoints: '1f981',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦁',
    version: 'E1.0',
    baseName: 'lion',
    fullName: 'lion',
    isBase: true,
    baseHex: '1f981',
    modifiers: null,
  },
  '1f42f': {
    hexCodePoints: '1f42f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐯',
    version: 'E0.6',
    baseName: 'tiger face',
    fullName: 'tiger face',
    isBase: true,
    baseHex: '1f42f',
    modifiers: null,
  },
  '1f405': {
    hexCodePoints: '1f405',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐅',
    version: 'E1.0',
    baseName: 'tiger',
    fullName: 'tiger',
    isBase: true,
    baseHex: '1f405',
    modifiers: null,
  },
  '1f406': {
    hexCodePoints: '1f406',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐆',
    version: 'E1.0',
    baseName: 'leopard',
    fullName: 'leopard',
    isBase: true,
    baseHex: '1f406',
    modifiers: null,
  },
  '1f434': {
    hexCodePoints: '1f434',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐴',
    version: 'E0.6',
    baseName: 'horse face',
    fullName: 'horse face',
    isBase: true,
    baseHex: '1f434',
    modifiers: null,
  },
  '1face': {
    hexCodePoints: '1face',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫎',
    version: 'E15.0',
    baseName: 'moose',
    fullName: 'moose',
    isBase: true,
    baseHex: '1face',
    modifiers: null,
  },
  '1facf': {
    hexCodePoints: '1facf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫏',
    version: 'E15.0',
    baseName: 'donkey',
    fullName: 'donkey',
    isBase: true,
    baseHex: '1facf',
    modifiers: null,
  },
  '1f40e': {
    hexCodePoints: '1f40e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐎',
    version: 'E0.6',
    baseName: 'horse',
    fullName: 'horse',
    isBase: true,
    baseHex: '1f40e',
    modifiers: null,
  },
  '1f984': {
    hexCodePoints: '1f984',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦄',
    version: 'E1.0',
    baseName: 'unicorn',
    fullName: 'unicorn',
    isBase: true,
    baseHex: '1f984',
    modifiers: null,
  },
  '1f993': {
    hexCodePoints: '1f993',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦓',
    version: 'E5.0',
    baseName: 'zebra',
    fullName: 'zebra',
    isBase: true,
    baseHex: '1f993',
    modifiers: null,
  },
  '1f98c': {
    hexCodePoints: '1f98c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦌',
    version: 'E3.0',
    baseName: 'deer',
    fullName: 'deer',
    isBase: true,
    baseHex: '1f98c',
    modifiers: null,
  },
  '1f9ac': {
    hexCodePoints: '1f9ac',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦬',
    version: 'E13.0',
    baseName: 'bison',
    fullName: 'bison',
    isBase: true,
    baseHex: '1f9ac',
    modifiers: null,
  },
  '1f42e': {
    hexCodePoints: '1f42e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐮',
    version: 'E0.6',
    baseName: 'cow face',
    fullName: 'cow face',
    isBase: true,
    baseHex: '1f42e',
    modifiers: null,
  },
  '1f402': {
    hexCodePoints: '1f402',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐂',
    version: 'E1.0',
    baseName: 'ox',
    fullName: 'ox',
    isBase: true,
    baseHex: '1f402',
    modifiers: null,
  },
  '1f403': {
    hexCodePoints: '1f403',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐃',
    version: 'E1.0',
    baseName: 'water buffalo',
    fullName: 'water buffalo',
    isBase: true,
    baseHex: '1f403',
    modifiers: null,
  },
  '1f404': {
    hexCodePoints: '1f404',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐄',
    version: 'E1.0',
    baseName: 'cow',
    fullName: 'cow',
    isBase: true,
    baseHex: '1f404',
    modifiers: null,
  },
  '1f437': {
    hexCodePoints: '1f437',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐷',
    version: 'E0.6',
    baseName: 'pig face',
    fullName: 'pig face',
    isBase: true,
    baseHex: '1f437',
    modifiers: null,
  },
  '1f416': {
    hexCodePoints: '1f416',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐖',
    version: 'E1.0',
    baseName: 'pig',
    fullName: 'pig',
    isBase: true,
    baseHex: '1f416',
    modifiers: null,
  },
  '1f417': {
    hexCodePoints: '1f417',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐗',
    version: 'E0.6',
    baseName: 'boar',
    fullName: 'boar',
    isBase: true,
    baseHex: '1f417',
    modifiers: null,
  },
  '1f43d': {
    hexCodePoints: '1f43d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐽',
    version: 'E0.6',
    baseName: 'pig nose',
    fullName: 'pig nose',
    isBase: true,
    baseHex: '1f43d',
    modifiers: null,
  },
  '1f40f': {
    hexCodePoints: '1f40f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐏',
    version: 'E1.0',
    baseName: 'ram',
    fullName: 'ram',
    isBase: true,
    baseHex: '1f40f',
    modifiers: null,
  },
  '1f411': {
    hexCodePoints: '1f411',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐑',
    version: 'E0.6',
    baseName: 'ewe',
    fullName: 'ewe',
    isBase: true,
    baseHex: '1f411',
    modifiers: null,
  },
  '1f410': {
    hexCodePoints: '1f410',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐐',
    version: 'E1.0',
    baseName: 'goat',
    fullName: 'goat',
    isBase: true,
    baseHex: '1f410',
    modifiers: null,
  },
  '1f42a': {
    hexCodePoints: '1f42a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐪',
    version: 'E1.0',
    baseName: 'camel',
    fullName: 'camel',
    isBase: true,
    baseHex: '1f42a',
    modifiers: null,
  },
  '1f42b': {
    hexCodePoints: '1f42b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐫',
    version: 'E0.6',
    baseName: 'two-hump camel',
    fullName: 'two-hump camel',
    isBase: true,
    baseHex: '1f42b',
    modifiers: null,
  },
  '1f999': {
    hexCodePoints: '1f999',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦙',
    version: 'E11.0',
    baseName: 'llama',
    fullName: 'llama',
    isBase: true,
    baseHex: '1f999',
    modifiers: null,
  },
  '1f992': {
    hexCodePoints: '1f992',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦒',
    version: 'E5.0',
    baseName: 'giraffe',
    fullName: 'giraffe',
    isBase: true,
    baseHex: '1f992',
    modifiers: null,
  },
  '1f418': {
    hexCodePoints: '1f418',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐘',
    version: 'E0.6',
    baseName: 'elephant',
    fullName: 'elephant',
    isBase: true,
    baseHex: '1f418',
    modifiers: null,
  },
  '1f9a3': {
    hexCodePoints: '1f9a3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦣',
    version: 'E13.0',
    baseName: 'mammoth',
    fullName: 'mammoth',
    isBase: true,
    baseHex: '1f9a3',
    modifiers: null,
  },
  '1f98f': {
    hexCodePoints: '1f98f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦏',
    version: 'E3.0',
    baseName: 'rhinoceros',
    fullName: 'rhinoceros',
    isBase: true,
    baseHex: '1f98f',
    modifiers: null,
  },
  '1f99b': {
    hexCodePoints: '1f99b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦛',
    version: 'E11.0',
    baseName: 'hippopotamus',
    fullName: 'hippopotamus',
    isBase: true,
    baseHex: '1f99b',
    modifiers: null,
  },
  '1f42d': {
    hexCodePoints: '1f42d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐭',
    version: 'E0.6',
    baseName: 'mouse face',
    fullName: 'mouse face',
    isBase: true,
    baseHex: '1f42d',
    modifiers: null,
  },
  '1f401': {
    hexCodePoints: '1f401',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐁',
    version: 'E1.0',
    baseName: 'mouse',
    fullName: 'mouse',
    isBase: true,
    baseHex: '1f401',
    modifiers: null,
  },
  '1f400': {
    hexCodePoints: '1f400',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐀',
    version: 'E1.0',
    baseName: 'rat',
    fullName: 'rat',
    isBase: true,
    baseHex: '1f400',
    modifiers: null,
  },
  '1f439': {
    hexCodePoints: '1f439',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐹',
    version: 'E0.6',
    baseName: 'hamster',
    fullName: 'hamster',
    isBase: true,
    baseHex: '1f439',
    modifiers: null,
  },
  '1f430': {
    hexCodePoints: '1f430',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐰',
    version: 'E0.6',
    baseName: 'rabbit face',
    fullName: 'rabbit face',
    isBase: true,
    baseHex: '1f430',
    modifiers: null,
  },
  '1f407': {
    hexCodePoints: '1f407',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐇',
    version: 'E1.0',
    baseName: 'rabbit',
    fullName: 'rabbit',
    isBase: true,
    baseHex: '1f407',
    modifiers: null,
  },
  '1f43f-fe0f': {
    hexCodePoints: '1f43f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐿️',
    version: 'E0.7',
    baseName: 'chipmunk',
    fullName: 'chipmunk',
    isBase: true,
    baseHex: '1f43f-fe0f',
    modifiers: null,
  },
  '1f43f': {
    hexCodePoints: '1f43f',
    qualifiedStatus: 'unqualified',
    emoji: '🐿',
    version: 'E0.7',
    baseName: 'chipmunk',
    fullName: 'chipmunk',
    isBase: false,
    baseHex: '1f43f-fe0f',
    modifiers: null,
  },
  '1f9ab': {
    hexCodePoints: '1f9ab',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦫',
    version: 'E13.0',
    baseName: 'beaver',
    fullName: 'beaver',
    isBase: true,
    baseHex: '1f9ab',
    modifiers: null,
  },
  '1f994': {
    hexCodePoints: '1f994',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦔',
    version: 'E5.0',
    baseName: 'hedgehog',
    fullName: 'hedgehog',
    isBase: true,
    baseHex: '1f994',
    modifiers: null,
  },
  '1f987': {
    hexCodePoints: '1f987',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦇',
    version: 'E3.0',
    baseName: 'bat',
    fullName: 'bat',
    isBase: true,
    baseHex: '1f987',
    modifiers: null,
  },
  '1f43b': {
    hexCodePoints: '1f43b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐻',
    version: 'E0.6',
    baseName: 'bear',
    fullName: 'bear',
    isBase: true,
    baseHex: '1f43b',
    modifiers: null,
  },
  '1f43b-200d-2744-fe0f': {
    hexCodePoints: '1f43b-200d-2744-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐻‍❄️',
    version: 'E13.0',
    baseName: 'polar bear',
    fullName: 'polar bear',
    isBase: true,
    baseHex: '1f43b-200d-2744-fe0f',
    modifiers: null,
  },
  '1f43b-200d-2744': {
    hexCodePoints: '1f43b-200d-2744',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🐻‍❄',
    version: 'E13.0',
    baseName: 'polar bear',
    fullName: 'polar bear',
    isBase: false,
    baseHex: '1f43b-200d-2744-fe0f',
    modifiers: null,
  },
  '1f428': {
    hexCodePoints: '1f428',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐨',
    version: 'E0.6',
    baseName: 'koala',
    fullName: 'koala',
    isBase: true,
    baseHex: '1f428',
    modifiers: null,
  },
  '1f43c': {
    hexCodePoints: '1f43c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐼',
    version: 'E0.6',
    baseName: 'panda',
    fullName: 'panda',
    isBase: true,
    baseHex: '1f43c',
    modifiers: null,
  },
  '1f9a5': {
    hexCodePoints: '1f9a5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦥',
    version: 'E12.0',
    baseName: 'sloth',
    fullName: 'sloth',
    isBase: true,
    baseHex: '1f9a5',
    modifiers: null,
  },
  '1f9a6': {
    hexCodePoints: '1f9a6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦦',
    version: 'E12.0',
    baseName: 'otter',
    fullName: 'otter',
    isBase: true,
    baseHex: '1f9a6',
    modifiers: null,
  },
  '1f9a8': {
    hexCodePoints: '1f9a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦨',
    version: 'E12.0',
    baseName: 'skunk',
    fullName: 'skunk',
    isBase: true,
    baseHex: '1f9a8',
    modifiers: null,
  },
  '1f998': {
    hexCodePoints: '1f998',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦘',
    version: 'E11.0',
    baseName: 'kangaroo',
    fullName: 'kangaroo',
    isBase: true,
    baseHex: '1f998',
    modifiers: null,
  },
  '1f9a1': {
    hexCodePoints: '1f9a1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦡',
    version: 'E11.0',
    baseName: 'badger',
    fullName: 'badger',
    isBase: true,
    baseHex: '1f9a1',
    modifiers: null,
  },
  '1f43e': {
    hexCodePoints: '1f43e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐾',
    version: 'E0.6',
    baseName: 'paw prints',
    fullName: 'paw prints',
    isBase: true,
    baseHex: '1f43e',
    modifiers: null,
  },
  '1f983': {
    hexCodePoints: '1f983',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦃',
    version: 'E1.0',
    baseName: 'turkey',
    fullName: 'turkey',
    isBase: true,
    baseHex: '1f983',
    modifiers: null,
  },
  '1f414': {
    hexCodePoints: '1f414',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐔',
    version: 'E0.6',
    baseName: 'chicken',
    fullName: 'chicken',
    isBase: true,
    baseHex: '1f414',
    modifiers: null,
  },
  '1f413': {
    hexCodePoints: '1f413',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐓',
    version: 'E1.0',
    baseName: 'rooster',
    fullName: 'rooster',
    isBase: true,
    baseHex: '1f413',
    modifiers: null,
  },
  '1f423': {
    hexCodePoints: '1f423',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐣',
    version: 'E0.6',
    baseName: 'hatching chick',
    fullName: 'hatching chick',
    isBase: true,
    baseHex: '1f423',
    modifiers: null,
  },
  '1f424': {
    hexCodePoints: '1f424',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐤',
    version: 'E0.6',
    baseName: 'baby chick',
    fullName: 'baby chick',
    isBase: true,
    baseHex: '1f424',
    modifiers: null,
  },
  '1f425': {
    hexCodePoints: '1f425',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐥',
    version: 'E0.6',
    baseName: 'front-facing baby chick',
    fullName: 'front-facing baby chick',
    isBase: true,
    baseHex: '1f425',
    modifiers: null,
  },
  '1f426': {
    hexCodePoints: '1f426',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐦',
    version: 'E0.6',
    baseName: 'bird',
    fullName: 'bird',
    isBase: true,
    baseHex: '1f426',
    modifiers: null,
  },
  '1f427': {
    hexCodePoints: '1f427',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐧',
    version: 'E0.6',
    baseName: 'penguin',
    fullName: 'penguin',
    isBase: true,
    baseHex: '1f427',
    modifiers: null,
  },
  '1f54a-fe0f': {
    hexCodePoints: '1f54a-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕊️',
    version: 'E0.7',
    baseName: 'dove',
    fullName: 'dove',
    isBase: true,
    baseHex: '1f54a-fe0f',
    modifiers: null,
  },
  '1f54a': {
    hexCodePoints: '1f54a',
    qualifiedStatus: 'unqualified',
    emoji: '🕊',
    version: 'E0.7',
    baseName: 'dove',
    fullName: 'dove',
    isBase: false,
    baseHex: '1f54a-fe0f',
    modifiers: null,
  },
  '1f985': {
    hexCodePoints: '1f985',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦅',
    version: 'E3.0',
    baseName: 'eagle',
    fullName: 'eagle',
    isBase: true,
    baseHex: '1f985',
    modifiers: null,
  },
  '1f986': {
    hexCodePoints: '1f986',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦆',
    version: 'E3.0',
    baseName: 'duck',
    fullName: 'duck',
    isBase: true,
    baseHex: '1f986',
    modifiers: null,
  },
  '1f9a2': {
    hexCodePoints: '1f9a2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦢',
    version: 'E11.0',
    baseName: 'swan',
    fullName: 'swan',
    isBase: true,
    baseHex: '1f9a2',
    modifiers: null,
  },
  '1f989': {
    hexCodePoints: '1f989',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦉',
    version: 'E3.0',
    baseName: 'owl',
    fullName: 'owl',
    isBase: true,
    baseHex: '1f989',
    modifiers: null,
  },
  '1f9a4': {
    hexCodePoints: '1f9a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦤',
    version: 'E13.0',
    baseName: 'dodo',
    fullName: 'dodo',
    isBase: true,
    baseHex: '1f9a4',
    modifiers: null,
  },
  '1fab6': {
    hexCodePoints: '1fab6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪶',
    version: 'E13.0',
    baseName: 'feather',
    fullName: 'feather',
    isBase: true,
    baseHex: '1fab6',
    modifiers: null,
  },
  '1f9a9': {
    hexCodePoints: '1f9a9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦩',
    version: 'E12.0',
    baseName: 'flamingo',
    fullName: 'flamingo',
    isBase: true,
    baseHex: '1f9a9',
    modifiers: null,
  },
  '1f99a': {
    hexCodePoints: '1f99a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦚',
    version: 'E11.0',
    baseName: 'peacock',
    fullName: 'peacock',
    isBase: true,
    baseHex: '1f99a',
    modifiers: null,
  },
  '1f99c': {
    hexCodePoints: '1f99c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦜',
    version: 'E11.0',
    baseName: 'parrot',
    fullName: 'parrot',
    isBase: true,
    baseHex: '1f99c',
    modifiers: null,
  },
  '1fabd': {
    hexCodePoints: '1fabd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪽',
    version: 'E15.0',
    baseName: 'wing',
    fullName: 'wing',
    isBase: true,
    baseHex: '1fabd',
    modifiers: null,
  },
  '1f426-200d-2b1b': {
    hexCodePoints: '1f426-200d-2b1b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐦‍⬛',
    version: 'E15.0',
    baseName: 'black bird',
    fullName: 'black bird',
    isBase: true,
    baseHex: '1f426-200d-2b1b',
    modifiers: null,
  },
  '1fabf': {
    hexCodePoints: '1fabf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪿',
    version: 'E15.0',
    baseName: 'goose',
    fullName: 'goose',
    isBase: true,
    baseHex: '1fabf',
    modifiers: null,
  },
  '1f426-200d-1f525': {
    hexCodePoints: '1f426-200d-1f525',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐦‍🔥',
    version: 'E15.1',
    baseName: 'phoenix',
    fullName: 'phoenix',
    isBase: true,
    baseHex: '1f426-200d-1f525',
    modifiers: null,
  },
  '1f438': {
    hexCodePoints: '1f438',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐸',
    version: 'E0.6',
    baseName: 'frog',
    fullName: 'frog',
    isBase: true,
    baseHex: '1f438',
    modifiers: null,
  },
  '1f40a': {
    hexCodePoints: '1f40a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐊',
    version: 'E1.0',
    baseName: 'crocodile',
    fullName: 'crocodile',
    isBase: true,
    baseHex: '1f40a',
    modifiers: null,
  },
  '1f422': {
    hexCodePoints: '1f422',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐢',
    version: 'E0.6',
    baseName: 'turtle',
    fullName: 'turtle',
    isBase: true,
    baseHex: '1f422',
    modifiers: null,
  },
  '1f98e': {
    hexCodePoints: '1f98e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦎',
    version: 'E3.0',
    baseName: 'lizard',
    fullName: 'lizard',
    isBase: true,
    baseHex: '1f98e',
    modifiers: null,
  },
  '1f40d': {
    hexCodePoints: '1f40d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐍',
    version: 'E0.6',
    baseName: 'snake',
    fullName: 'snake',
    isBase: true,
    baseHex: '1f40d',
    modifiers: null,
  },
  '1f432': {
    hexCodePoints: '1f432',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐲',
    version: 'E0.6',
    baseName: 'dragon face',
    fullName: 'dragon face',
    isBase: true,
    baseHex: '1f432',
    modifiers: null,
  },
  '1f409': {
    hexCodePoints: '1f409',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐉',
    version: 'E1.0',
    baseName: 'dragon',
    fullName: 'dragon',
    isBase: true,
    baseHex: '1f409',
    modifiers: null,
  },
  '1f995': {
    hexCodePoints: '1f995',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦕',
    version: 'E5.0',
    baseName: 'sauropod',
    fullName: 'sauropod',
    isBase: true,
    baseHex: '1f995',
    modifiers: null,
  },
  '1f996': {
    hexCodePoints: '1f996',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦖',
    version: 'E5.0',
    baseName: 'T-Rex',
    fullName: 'T-Rex',
    isBase: true,
    baseHex: '1f996',
    modifiers: null,
  },
  '1f433': {
    hexCodePoints: '1f433',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐳',
    version: 'E0.6',
    baseName: 'spouting whale',
    fullName: 'spouting whale',
    isBase: true,
    baseHex: '1f433',
    modifiers: null,
  },
  '1f40b': {
    hexCodePoints: '1f40b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐋',
    version: 'E1.0',
    baseName: 'whale',
    fullName: 'whale',
    isBase: true,
    baseHex: '1f40b',
    modifiers: null,
  },
  '1f42c': {
    hexCodePoints: '1f42c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐬',
    version: 'E0.6',
    baseName: 'dolphin',
    fullName: 'dolphin',
    isBase: true,
    baseHex: '1f42c',
    modifiers: null,
  },
  '1f9ad': {
    hexCodePoints: '1f9ad',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦭',
    version: 'E13.0',
    baseName: 'seal',
    fullName: 'seal',
    isBase: true,
    baseHex: '1f9ad',
    modifiers: null,
  },
  '1f41f': {
    hexCodePoints: '1f41f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐟',
    version: 'E0.6',
    baseName: 'fish',
    fullName: 'fish',
    isBase: true,
    baseHex: '1f41f',
    modifiers: null,
  },
  '1f420': {
    hexCodePoints: '1f420',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐠',
    version: 'E0.6',
    baseName: 'tropical fish',
    fullName: 'tropical fish',
    isBase: true,
    baseHex: '1f420',
    modifiers: null,
  },
  '1f421': {
    hexCodePoints: '1f421',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐡',
    version: 'E0.6',
    baseName: 'blowfish',
    fullName: 'blowfish',
    isBase: true,
    baseHex: '1f421',
    modifiers: null,
  },
  '1f988': {
    hexCodePoints: '1f988',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦈',
    version: 'E3.0',
    baseName: 'shark',
    fullName: 'shark',
    isBase: true,
    baseHex: '1f988',
    modifiers: null,
  },
  '1f419': {
    hexCodePoints: '1f419',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐙',
    version: 'E0.6',
    baseName: 'octopus',
    fullName: 'octopus',
    isBase: true,
    baseHex: '1f419',
    modifiers: null,
  },
  '1f41a': {
    hexCodePoints: '1f41a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐚',
    version: 'E0.6',
    baseName: 'spiral shell',
    fullName: 'spiral shell',
    isBase: true,
    baseHex: '1f41a',
    modifiers: null,
  },
  '1fab8': {
    hexCodePoints: '1fab8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪸',
    version: 'E14.0',
    baseName: 'coral',
    fullName: 'coral',
    isBase: true,
    baseHex: '1fab8',
    modifiers: null,
  },
  '1fabc': {
    hexCodePoints: '1fabc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪼',
    version: 'E15.0',
    baseName: 'jellyfish',
    fullName: 'jellyfish',
    isBase: true,
    baseHex: '1fabc',
    modifiers: null,
  },
  '1f40c': {
    hexCodePoints: '1f40c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐌',
    version: 'E0.6',
    baseName: 'snail',
    fullName: 'snail',
    isBase: true,
    baseHex: '1f40c',
    modifiers: null,
  },
  '1f98b': {
    hexCodePoints: '1f98b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦋',
    version: 'E3.0',
    baseName: 'butterfly',
    fullName: 'butterfly',
    isBase: true,
    baseHex: '1f98b',
    modifiers: null,
  },
  '1f41b': {
    hexCodePoints: '1f41b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐛',
    version: 'E0.6',
    baseName: 'bug',
    fullName: 'bug',
    isBase: true,
    baseHex: '1f41b',
    modifiers: null,
  },
  '1f41c': {
    hexCodePoints: '1f41c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐜',
    version: 'E0.6',
    baseName: 'ant',
    fullName: 'ant',
    isBase: true,
    baseHex: '1f41c',
    modifiers: null,
  },
  '1f41d': {
    hexCodePoints: '1f41d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐝',
    version: 'E0.6',
    baseName: 'honeybee',
    fullName: 'honeybee',
    isBase: true,
    baseHex: '1f41d',
    modifiers: null,
  },
  '1fab2': {
    hexCodePoints: '1fab2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪲',
    version: 'E13.0',
    baseName: 'beetle',
    fullName: 'beetle',
    isBase: true,
    baseHex: '1fab2',
    modifiers: null,
  },
  '1f41e': {
    hexCodePoints: '1f41e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🐞',
    version: 'E0.6',
    baseName: 'lady beetle',
    fullName: 'lady beetle',
    isBase: true,
    baseHex: '1f41e',
    modifiers: null,
  },
  '1f997': {
    hexCodePoints: '1f997',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦗',
    version: 'E5.0',
    baseName: 'cricket',
    fullName: 'cricket',
    isBase: true,
    baseHex: '1f997',
    modifiers: null,
  },
  '1fab3': {
    hexCodePoints: '1fab3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪳',
    version: 'E13.0',
    baseName: 'cockroach',
    fullName: 'cockroach',
    isBase: true,
    baseHex: '1fab3',
    modifiers: null,
  },
  '1f577-fe0f': {
    hexCodePoints: '1f577-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕷️',
    version: 'E0.7',
    baseName: 'spider',
    fullName: 'spider',
    isBase: true,
    baseHex: '1f577-fe0f',
    modifiers: null,
  },
  '1f577': {
    hexCodePoints: '1f577',
    qualifiedStatus: 'unqualified',
    emoji: '🕷',
    version: 'E0.7',
    baseName: 'spider',
    fullName: 'spider',
    isBase: false,
    baseHex: '1f577-fe0f',
    modifiers: null,
  },
  '1f578-fe0f': {
    hexCodePoints: '1f578-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕸️',
    version: 'E0.7',
    baseName: 'spider web',
    fullName: 'spider web',
    isBase: true,
    baseHex: '1f578-fe0f',
    modifiers: null,
  },
  '1f578': {
    hexCodePoints: '1f578',
    qualifiedStatus: 'unqualified',
    emoji: '🕸',
    version: 'E0.7',
    baseName: 'spider web',
    fullName: 'spider web',
    isBase: false,
    baseHex: '1f578-fe0f',
    modifiers: null,
  },
  '1f982': {
    hexCodePoints: '1f982',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦂',
    version: 'E1.0',
    baseName: 'scorpion',
    fullName: 'scorpion',
    isBase: true,
    baseHex: '1f982',
    modifiers: null,
  },
  '1f99f': {
    hexCodePoints: '1f99f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦟',
    version: 'E11.0',
    baseName: 'mosquito',
    fullName: 'mosquito',
    isBase: true,
    baseHex: '1f99f',
    modifiers: null,
  },
  '1fab0': {
    hexCodePoints: '1fab0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪰',
    version: 'E13.0',
    baseName: 'fly',
    fullName: 'fly',
    isBase: true,
    baseHex: '1fab0',
    modifiers: null,
  },
  '1fab1': {
    hexCodePoints: '1fab1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪱',
    version: 'E13.0',
    baseName: 'worm',
    fullName: 'worm',
    isBase: true,
    baseHex: '1fab1',
    modifiers: null,
  },
  '1f9a0': {
    hexCodePoints: '1f9a0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦠',
    version: 'E11.0',
    baseName: 'microbe',
    fullName: 'microbe',
    isBase: true,
    baseHex: '1f9a0',
    modifiers: null,
  },
  '1f490': {
    hexCodePoints: '1f490',
    qualifiedStatus: 'fully-qualified',
    emoji: '💐',
    version: 'E0.6',
    baseName: 'bouquet',
    fullName: 'bouquet',
    isBase: true,
    baseHex: '1f490',
    modifiers: null,
  },
  '1f338': {
    hexCodePoints: '1f338',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌸',
    version: 'E0.6',
    baseName: 'cherry blossom',
    fullName: 'cherry blossom',
    isBase: true,
    baseHex: '1f338',
    modifiers: null,
  },
  '1f4ae': {
    hexCodePoints: '1f4ae',
    qualifiedStatus: 'fully-qualified',
    emoji: '💮',
    version: 'E0.6',
    baseName: 'white flower',
    fullName: 'white flower',
    isBase: true,
    baseHex: '1f4ae',
    modifiers: null,
  },
  '1fab7': {
    hexCodePoints: '1fab7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪷',
    version: 'E14.0',
    baseName: 'lotus',
    fullName: 'lotus',
    isBase: true,
    baseHex: '1fab7',
    modifiers: null,
  },
  '1f3f5-fe0f': {
    hexCodePoints: '1f3f5-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏵️',
    version: 'E0.7',
    baseName: 'rosette',
    fullName: 'rosette',
    isBase: true,
    baseHex: '1f3f5-fe0f',
    modifiers: null,
  },
  '1f3f5': {
    hexCodePoints: '1f3f5',
    qualifiedStatus: 'unqualified',
    emoji: '🏵',
    version: 'E0.7',
    baseName: 'rosette',
    fullName: 'rosette',
    isBase: false,
    baseHex: '1f3f5-fe0f',
    modifiers: null,
  },
  '1f339': {
    hexCodePoints: '1f339',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌹',
    version: 'E0.6',
    baseName: 'rose',
    fullName: 'rose',
    isBase: true,
    baseHex: '1f339',
    modifiers: null,
  },
  '1f940': {
    hexCodePoints: '1f940',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥀',
    version: 'E3.0',
    baseName: 'wilted flower',
    fullName: 'wilted flower',
    isBase: true,
    baseHex: '1f940',
    modifiers: null,
  },
  '1f33a': {
    hexCodePoints: '1f33a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌺',
    version: 'E0.6',
    baseName: 'hibiscus',
    fullName: 'hibiscus',
    isBase: true,
    baseHex: '1f33a',
    modifiers: null,
  },
  '1f33b': {
    hexCodePoints: '1f33b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌻',
    version: 'E0.6',
    baseName: 'sunflower',
    fullName: 'sunflower',
    isBase: true,
    baseHex: '1f33b',
    modifiers: null,
  },
  '1f33c': {
    hexCodePoints: '1f33c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌼',
    version: 'E0.6',
    baseName: 'blossom',
    fullName: 'blossom',
    isBase: true,
    baseHex: '1f33c',
    modifiers: null,
  },
  '1f337': {
    hexCodePoints: '1f337',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌷',
    version: 'E0.6',
    baseName: 'tulip',
    fullName: 'tulip',
    isBase: true,
    baseHex: '1f337',
    modifiers: null,
  },
  '1fabb': {
    hexCodePoints: '1fabb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪻',
    version: 'E15.0',
    baseName: 'hyacinth',
    fullName: 'hyacinth',
    isBase: true,
    baseHex: '1fabb',
    modifiers: null,
  },
  '1f331': {
    hexCodePoints: '1f331',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌱',
    version: 'E0.6',
    baseName: 'seedling',
    fullName: 'seedling',
    isBase: true,
    baseHex: '1f331',
    modifiers: null,
  },
  '1fab4': {
    hexCodePoints: '1fab4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪴',
    version: 'E13.0',
    baseName: 'potted plant',
    fullName: 'potted plant',
    isBase: true,
    baseHex: '1fab4',
    modifiers: null,
  },
  '1f332': {
    hexCodePoints: '1f332',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌲',
    version: 'E1.0',
    baseName: 'evergreen tree',
    fullName: 'evergreen tree',
    isBase: true,
    baseHex: '1f332',
    modifiers: null,
  },
  '1f333': {
    hexCodePoints: '1f333',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌳',
    version: 'E1.0',
    baseName: 'deciduous tree',
    fullName: 'deciduous tree',
    isBase: true,
    baseHex: '1f333',
    modifiers: null,
  },
  '1f334': {
    hexCodePoints: '1f334',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌴',
    version: 'E0.6',
    baseName: 'palm tree',
    fullName: 'palm tree',
    isBase: true,
    baseHex: '1f334',
    modifiers: null,
  },
  '1f335': {
    hexCodePoints: '1f335',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌵',
    version: 'E0.6',
    baseName: 'cactus',
    fullName: 'cactus',
    isBase: true,
    baseHex: '1f335',
    modifiers: null,
  },
  '1f33e': {
    hexCodePoints: '1f33e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌾',
    version: 'E0.6',
    baseName: 'sheaf of rice',
    fullName: 'sheaf of rice',
    isBase: true,
    baseHex: '1f33e',
    modifiers: null,
  },
  '1f33f': {
    hexCodePoints: '1f33f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌿',
    version: 'E0.6',
    baseName: 'herb',
    fullName: 'herb',
    isBase: true,
    baseHex: '1f33f',
    modifiers: null,
  },
  '2618-fe0f': {
    hexCodePoints: '2618-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☘️',
    version: 'E1.0',
    baseName: 'shamrock',
    fullName: 'shamrock',
    isBase: true,
    baseHex: '2618-fe0f',
    modifiers: null,
  },
  '2618': {
    hexCodePoints: '2618',
    qualifiedStatus: 'unqualified',
    emoji: '☘',
    version: 'E1.0',
    baseName: 'shamrock',
    fullName: 'shamrock',
    isBase: false,
    baseHex: '2618-fe0f',
    modifiers: null,
  },
  '1f340': {
    hexCodePoints: '1f340',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍀',
    version: 'E0.6',
    baseName: 'four leaf clover',
    fullName: 'four leaf clover',
    isBase: true,
    baseHex: '1f340',
    modifiers: null,
  },
  '1f341': {
    hexCodePoints: '1f341',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍁',
    version: 'E0.6',
    baseName: 'maple leaf',
    fullName: 'maple leaf',
    isBase: true,
    baseHex: '1f341',
    modifiers: null,
  },
  '1f342': {
    hexCodePoints: '1f342',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍂',
    version: 'E0.6',
    baseName: 'fallen leaf',
    fullName: 'fallen leaf',
    isBase: true,
    baseHex: '1f342',
    modifiers: null,
  },
  '1f343': {
    hexCodePoints: '1f343',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍃',
    version: 'E0.6',
    baseName: 'leaf fluttering in wind',
    fullName: 'leaf fluttering in wind',
    isBase: true,
    baseHex: '1f343',
    modifiers: null,
  },
  '1fab9': {
    hexCodePoints: '1fab9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪹',
    version: 'E14.0',
    baseName: 'empty nest',
    fullName: 'empty nest',
    isBase: true,
    baseHex: '1fab9',
    modifiers: null,
  },
  '1faba': {
    hexCodePoints: '1faba',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪺',
    version: 'E14.0',
    baseName: 'nest with eggs',
    fullName: 'nest with eggs',
    isBase: true,
    baseHex: '1faba',
    modifiers: null,
  },
  '1f344': {
    hexCodePoints: '1f344',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍄',
    version: 'E0.6',
    baseName: 'mushroom',
    fullName: 'mushroom',
    isBase: true,
    baseHex: '1f344',
    modifiers: null,
  },
  '1f347': {
    hexCodePoints: '1f347',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍇',
    version: 'E0.6',
    baseName: 'grapes',
    fullName: 'grapes',
    isBase: true,
    baseHex: '1f347',
    modifiers: null,
  },
  '1f348': {
    hexCodePoints: '1f348',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍈',
    version: 'E0.6',
    baseName: 'melon',
    fullName: 'melon',
    isBase: true,
    baseHex: '1f348',
    modifiers: null,
  },
  '1f349': {
    hexCodePoints: '1f349',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍉',
    version: 'E0.6',
    baseName: 'watermelon',
    fullName: 'watermelon',
    isBase: true,
    baseHex: '1f349',
    modifiers: null,
  },
  '1f34a': {
    hexCodePoints: '1f34a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍊',
    version: 'E0.6',
    baseName: 'tangerine',
    fullName: 'tangerine',
    isBase: true,
    baseHex: '1f34a',
    modifiers: null,
  },
  '1f34b': {
    hexCodePoints: '1f34b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍋',
    version: 'E1.0',
    baseName: 'lemon',
    fullName: 'lemon',
    isBase: true,
    baseHex: '1f34b',
    modifiers: null,
  },
  '1f34b-200d-1f7e9': {
    hexCodePoints: '1f34b-200d-1f7e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍋‍🟩',
    version: 'E15.1',
    baseName: 'lime',
    fullName: 'lime',
    isBase: true,
    baseHex: '1f34b-200d-1f7e9',
    modifiers: null,
  },
  '1f34c': {
    hexCodePoints: '1f34c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍌',
    version: 'E0.6',
    baseName: 'banana',
    fullName: 'banana',
    isBase: true,
    baseHex: '1f34c',
    modifiers: null,
  },
  '1f34d': {
    hexCodePoints: '1f34d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍍',
    version: 'E0.6',
    baseName: 'pineapple',
    fullName: 'pineapple',
    isBase: true,
    baseHex: '1f34d',
    modifiers: null,
  },
  '1f96d': {
    hexCodePoints: '1f96d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥭',
    version: 'E11.0',
    baseName: 'mango',
    fullName: 'mango',
    isBase: true,
    baseHex: '1f96d',
    modifiers: null,
  },
  '1f34e': {
    hexCodePoints: '1f34e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍎',
    version: 'E0.6',
    baseName: 'red apple',
    fullName: 'red apple',
    isBase: true,
    baseHex: '1f34e',
    modifiers: null,
  },
  '1f34f': {
    hexCodePoints: '1f34f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍏',
    version: 'E0.6',
    baseName: 'green apple',
    fullName: 'green apple',
    isBase: true,
    baseHex: '1f34f',
    modifiers: null,
  },
  '1f350': {
    hexCodePoints: '1f350',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍐',
    version: 'E1.0',
    baseName: 'pear',
    fullName: 'pear',
    isBase: true,
    baseHex: '1f350',
    modifiers: null,
  },
  '1f351': {
    hexCodePoints: '1f351',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍑',
    version: 'E0.6',
    baseName: 'peach',
    fullName: 'peach',
    isBase: true,
    baseHex: '1f351',
    modifiers: null,
  },
  '1f352': {
    hexCodePoints: '1f352',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍒',
    version: 'E0.6',
    baseName: 'cherries',
    fullName: 'cherries',
    isBase: true,
    baseHex: '1f352',
    modifiers: null,
  },
  '1f353': {
    hexCodePoints: '1f353',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍓',
    version: 'E0.6',
    baseName: 'strawberry',
    fullName: 'strawberry',
    isBase: true,
    baseHex: '1f353',
    modifiers: null,
  },
  '1fad0': {
    hexCodePoints: '1fad0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫐',
    version: 'E13.0',
    baseName: 'blueberries',
    fullName: 'blueberries',
    isBase: true,
    baseHex: '1fad0',
    modifiers: null,
  },
  '1f95d': {
    hexCodePoints: '1f95d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥝',
    version: 'E3.0',
    baseName: 'kiwi fruit',
    fullName: 'kiwi fruit',
    isBase: true,
    baseHex: '1f95d',
    modifiers: null,
  },
  '1f345': {
    hexCodePoints: '1f345',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍅',
    version: 'E0.6',
    baseName: 'tomato',
    fullName: 'tomato',
    isBase: true,
    baseHex: '1f345',
    modifiers: null,
  },
  '1fad2': {
    hexCodePoints: '1fad2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫒',
    version: 'E13.0',
    baseName: 'olive',
    fullName: 'olive',
    isBase: true,
    baseHex: '1fad2',
    modifiers: null,
  },
  '1f965': {
    hexCodePoints: '1f965',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥥',
    version: 'E5.0',
    baseName: 'coconut',
    fullName: 'coconut',
    isBase: true,
    baseHex: '1f965',
    modifiers: null,
  },
  '1f951': {
    hexCodePoints: '1f951',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥑',
    version: 'E3.0',
    baseName: 'avocado',
    fullName: 'avocado',
    isBase: true,
    baseHex: '1f951',
    modifiers: null,
  },
  '1f346': {
    hexCodePoints: '1f346',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍆',
    version: 'E0.6',
    baseName: 'eggplant',
    fullName: 'eggplant',
    isBase: true,
    baseHex: '1f346',
    modifiers: null,
  },
  '1f954': {
    hexCodePoints: '1f954',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥔',
    version: 'E3.0',
    baseName: 'potato',
    fullName: 'potato',
    isBase: true,
    baseHex: '1f954',
    modifiers: null,
  },
  '1f955': {
    hexCodePoints: '1f955',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥕',
    version: 'E3.0',
    baseName: 'carrot',
    fullName: 'carrot',
    isBase: true,
    baseHex: '1f955',
    modifiers: null,
  },
  '1f33d': {
    hexCodePoints: '1f33d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌽',
    version: 'E0.6',
    baseName: 'ear of corn',
    fullName: 'ear of corn',
    isBase: true,
    baseHex: '1f33d',
    modifiers: null,
  },
  '1f336-fe0f': {
    hexCodePoints: '1f336-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌶️',
    version: 'E0.7',
    baseName: 'hot pepper',
    fullName: 'hot pepper',
    isBase: true,
    baseHex: '1f336-fe0f',
    modifiers: null,
  },
  '1f336': {
    hexCodePoints: '1f336',
    qualifiedStatus: 'unqualified',
    emoji: '🌶',
    version: 'E0.7',
    baseName: 'hot pepper',
    fullName: 'hot pepper',
    isBase: false,
    baseHex: '1f336-fe0f',
    modifiers: null,
  },
  '1fad1': {
    hexCodePoints: '1fad1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫑',
    version: 'E13.0',
    baseName: 'bell pepper',
    fullName: 'bell pepper',
    isBase: true,
    baseHex: '1fad1',
    modifiers: null,
  },
  '1f952': {
    hexCodePoints: '1f952',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥒',
    version: 'E3.0',
    baseName: 'cucumber',
    fullName: 'cucumber',
    isBase: true,
    baseHex: '1f952',
    modifiers: null,
  },
  '1f96c': {
    hexCodePoints: '1f96c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥬',
    version: 'E11.0',
    baseName: 'leafy green',
    fullName: 'leafy green',
    isBase: true,
    baseHex: '1f96c',
    modifiers: null,
  },
  '1f966': {
    hexCodePoints: '1f966',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥦',
    version: 'E5.0',
    baseName: 'broccoli',
    fullName: 'broccoli',
    isBase: true,
    baseHex: '1f966',
    modifiers: null,
  },
  '1f9c4': {
    hexCodePoints: '1f9c4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧄',
    version: 'E12.0',
    baseName: 'garlic',
    fullName: 'garlic',
    isBase: true,
    baseHex: '1f9c4',
    modifiers: null,
  },
  '1f9c5': {
    hexCodePoints: '1f9c5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧅',
    version: 'E12.0',
    baseName: 'onion',
    fullName: 'onion',
    isBase: true,
    baseHex: '1f9c5',
    modifiers: null,
  },
  '1f95c': {
    hexCodePoints: '1f95c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥜',
    version: 'E3.0',
    baseName: 'peanuts',
    fullName: 'peanuts',
    isBase: true,
    baseHex: '1f95c',
    modifiers: null,
  },
  '1fad8': {
    hexCodePoints: '1fad8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫘',
    version: 'E14.0',
    baseName: 'beans',
    fullName: 'beans',
    isBase: true,
    baseHex: '1fad8',
    modifiers: null,
  },
  '1f330': {
    hexCodePoints: '1f330',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌰',
    version: 'E0.6',
    baseName: 'chestnut',
    fullName: 'chestnut',
    isBase: true,
    baseHex: '1f330',
    modifiers: null,
  },
  '1fada': {
    hexCodePoints: '1fada',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫚',
    version: 'E15.0',
    baseName: 'ginger root',
    fullName: 'ginger root',
    isBase: true,
    baseHex: '1fada',
    modifiers: null,
  },
  '1fadb': {
    hexCodePoints: '1fadb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫛',
    version: 'E15.0',
    baseName: 'pea pod',
    fullName: 'pea pod',
    isBase: true,
    baseHex: '1fadb',
    modifiers: null,
  },
  '1f344-200d-1f7eb': {
    hexCodePoints: '1f344-200d-1f7eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍄‍🟫',
    version: 'E15.1',
    baseName: 'brown mushroom',
    fullName: 'brown mushroom',
    isBase: true,
    baseHex: '1f344-200d-1f7eb',
    modifiers: null,
  },
  '1f35e': {
    hexCodePoints: '1f35e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍞',
    version: 'E0.6',
    baseName: 'bread',
    fullName: 'bread',
    isBase: true,
    baseHex: '1f35e',
    modifiers: null,
  },
  '1f950': {
    hexCodePoints: '1f950',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥐',
    version: 'E3.0',
    baseName: 'croissant',
    fullName: 'croissant',
    isBase: true,
    baseHex: '1f950',
    modifiers: null,
  },
  '1f956': {
    hexCodePoints: '1f956',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥖',
    version: 'E3.0',
    baseName: 'baguette bread',
    fullName: 'baguette bread',
    isBase: true,
    baseHex: '1f956',
    modifiers: null,
  },
  '1fad3': {
    hexCodePoints: '1fad3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫓',
    version: 'E13.0',
    baseName: 'flatbread',
    fullName: 'flatbread',
    isBase: true,
    baseHex: '1fad3',
    modifiers: null,
  },
  '1f968': {
    hexCodePoints: '1f968',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥨',
    version: 'E5.0',
    baseName: 'pretzel',
    fullName: 'pretzel',
    isBase: true,
    baseHex: '1f968',
    modifiers: null,
  },
  '1f96f': {
    hexCodePoints: '1f96f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥯',
    version: 'E11.0',
    baseName: 'bagel',
    fullName: 'bagel',
    isBase: true,
    baseHex: '1f96f',
    modifiers: null,
  },
  '1f95e': {
    hexCodePoints: '1f95e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥞',
    version: 'E3.0',
    baseName: 'pancakes',
    fullName: 'pancakes',
    isBase: true,
    baseHex: '1f95e',
    modifiers: null,
  },
  '1f9c7': {
    hexCodePoints: '1f9c7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧇',
    version: 'E12.0',
    baseName: 'waffle',
    fullName: 'waffle',
    isBase: true,
    baseHex: '1f9c7',
    modifiers: null,
  },
  '1f9c0': {
    hexCodePoints: '1f9c0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧀',
    version: 'E1.0',
    baseName: 'cheese wedge',
    fullName: 'cheese wedge',
    isBase: true,
    baseHex: '1f9c0',
    modifiers: null,
  },
  '1f356': {
    hexCodePoints: '1f356',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍖',
    version: 'E0.6',
    baseName: 'meat on bone',
    fullName: 'meat on bone',
    isBase: true,
    baseHex: '1f356',
    modifiers: null,
  },
  '1f357': {
    hexCodePoints: '1f357',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍗',
    version: 'E0.6',
    baseName: 'poultry leg',
    fullName: 'poultry leg',
    isBase: true,
    baseHex: '1f357',
    modifiers: null,
  },
  '1f969': {
    hexCodePoints: '1f969',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥩',
    version: 'E5.0',
    baseName: 'cut of meat',
    fullName: 'cut of meat',
    isBase: true,
    baseHex: '1f969',
    modifiers: null,
  },
  '1f953': {
    hexCodePoints: '1f953',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥓',
    version: 'E3.0',
    baseName: 'bacon',
    fullName: 'bacon',
    isBase: true,
    baseHex: '1f953',
    modifiers: null,
  },
  '1f354': {
    hexCodePoints: '1f354',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍔',
    version: 'E0.6',
    baseName: 'hamburger',
    fullName: 'hamburger',
    isBase: true,
    baseHex: '1f354',
    modifiers: null,
  },
  '1f35f': {
    hexCodePoints: '1f35f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍟',
    version: 'E0.6',
    baseName: 'french fries',
    fullName: 'french fries',
    isBase: true,
    baseHex: '1f35f',
    modifiers: null,
  },
  '1f355': {
    hexCodePoints: '1f355',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍕',
    version: 'E0.6',
    baseName: 'pizza',
    fullName: 'pizza',
    isBase: true,
    baseHex: '1f355',
    modifiers: null,
  },
  '1f32d': {
    hexCodePoints: '1f32d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌭',
    version: 'E1.0',
    baseName: 'hot dog',
    fullName: 'hot dog',
    isBase: true,
    baseHex: '1f32d',
    modifiers: null,
  },
  '1f96a': {
    hexCodePoints: '1f96a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥪',
    version: 'E5.0',
    baseName: 'sandwich',
    fullName: 'sandwich',
    isBase: true,
    baseHex: '1f96a',
    modifiers: null,
  },
  '1f32e': {
    hexCodePoints: '1f32e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌮',
    version: 'E1.0',
    baseName: 'taco',
    fullName: 'taco',
    isBase: true,
    baseHex: '1f32e',
    modifiers: null,
  },
  '1f32f': {
    hexCodePoints: '1f32f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌯',
    version: 'E1.0',
    baseName: 'burrito',
    fullName: 'burrito',
    isBase: true,
    baseHex: '1f32f',
    modifiers: null,
  },
  '1fad4': {
    hexCodePoints: '1fad4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫔',
    version: 'E13.0',
    baseName: 'tamale',
    fullName: 'tamale',
    isBase: true,
    baseHex: '1fad4',
    modifiers: null,
  },
  '1f959': {
    hexCodePoints: '1f959',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥙',
    version: 'E3.0',
    baseName: 'stuffed flatbread',
    fullName: 'stuffed flatbread',
    isBase: true,
    baseHex: '1f959',
    modifiers: null,
  },
  '1f9c6': {
    hexCodePoints: '1f9c6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧆',
    version: 'E12.0',
    baseName: 'falafel',
    fullName: 'falafel',
    isBase: true,
    baseHex: '1f9c6',
    modifiers: null,
  },
  '1f95a': {
    hexCodePoints: '1f95a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥚',
    version: 'E3.0',
    baseName: 'egg',
    fullName: 'egg',
    isBase: true,
    baseHex: '1f95a',
    modifiers: null,
  },
  '1f373': {
    hexCodePoints: '1f373',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍳',
    version: 'E0.6',
    baseName: 'cooking',
    fullName: 'cooking',
    isBase: true,
    baseHex: '1f373',
    modifiers: null,
  },
  '1f958': {
    hexCodePoints: '1f958',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥘',
    version: 'E3.0',
    baseName: 'shallow pan of food',
    fullName: 'shallow pan of food',
    isBase: true,
    baseHex: '1f958',
    modifiers: null,
  },
  '1f372': {
    hexCodePoints: '1f372',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍲',
    version: 'E0.6',
    baseName: 'pot of food',
    fullName: 'pot of food',
    isBase: true,
    baseHex: '1f372',
    modifiers: null,
  },
  '1fad5': {
    hexCodePoints: '1fad5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫕',
    version: 'E13.0',
    baseName: 'fondue',
    fullName: 'fondue',
    isBase: true,
    baseHex: '1fad5',
    modifiers: null,
  },
  '1f963': {
    hexCodePoints: '1f963',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥣',
    version: 'E5.0',
    baseName: 'bowl with spoon',
    fullName: 'bowl with spoon',
    isBase: true,
    baseHex: '1f963',
    modifiers: null,
  },
  '1f957': {
    hexCodePoints: '1f957',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥗',
    version: 'E3.0',
    baseName: 'green salad',
    fullName: 'green salad',
    isBase: true,
    baseHex: '1f957',
    modifiers: null,
  },
  '1f37f': {
    hexCodePoints: '1f37f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍿',
    version: 'E1.0',
    baseName: 'popcorn',
    fullName: 'popcorn',
    isBase: true,
    baseHex: '1f37f',
    modifiers: null,
  },
  '1f9c8': {
    hexCodePoints: '1f9c8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧈',
    version: 'E12.0',
    baseName: 'butter',
    fullName: 'butter',
    isBase: true,
    baseHex: '1f9c8',
    modifiers: null,
  },
  '1f9c2': {
    hexCodePoints: '1f9c2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧂',
    version: 'E11.0',
    baseName: 'salt',
    fullName: 'salt',
    isBase: true,
    baseHex: '1f9c2',
    modifiers: null,
  },
  '1f96b': {
    hexCodePoints: '1f96b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥫',
    version: 'E5.0',
    baseName: 'canned food',
    fullName: 'canned food',
    isBase: true,
    baseHex: '1f96b',
    modifiers: null,
  },
  '1f371': {
    hexCodePoints: '1f371',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍱',
    version: 'E0.6',
    baseName: 'bento box',
    fullName: 'bento box',
    isBase: true,
    baseHex: '1f371',
    modifiers: null,
  },
  '1f358': {
    hexCodePoints: '1f358',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍘',
    version: 'E0.6',
    baseName: 'rice cracker',
    fullName: 'rice cracker',
    isBase: true,
    baseHex: '1f358',
    modifiers: null,
  },
  '1f359': {
    hexCodePoints: '1f359',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍙',
    version: 'E0.6',
    baseName: 'rice ball',
    fullName: 'rice ball',
    isBase: true,
    baseHex: '1f359',
    modifiers: null,
  },
  '1f35a': {
    hexCodePoints: '1f35a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍚',
    version: 'E0.6',
    baseName: 'cooked rice',
    fullName: 'cooked rice',
    isBase: true,
    baseHex: '1f35a',
    modifiers: null,
  },
  '1f35b': {
    hexCodePoints: '1f35b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍛',
    version: 'E0.6',
    baseName: 'curry rice',
    fullName: 'curry rice',
    isBase: true,
    baseHex: '1f35b',
    modifiers: null,
  },
  '1f35c': {
    hexCodePoints: '1f35c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍜',
    version: 'E0.6',
    baseName: 'steaming bowl',
    fullName: 'steaming bowl',
    isBase: true,
    baseHex: '1f35c',
    modifiers: null,
  },
  '1f35d': {
    hexCodePoints: '1f35d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍝',
    version: 'E0.6',
    baseName: 'spaghetti',
    fullName: 'spaghetti',
    isBase: true,
    baseHex: '1f35d',
    modifiers: null,
  },
  '1f360': {
    hexCodePoints: '1f360',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍠',
    version: 'E0.6',
    baseName: 'roasted sweet potato',
    fullName: 'roasted sweet potato',
    isBase: true,
    baseHex: '1f360',
    modifiers: null,
  },
  '1f362': {
    hexCodePoints: '1f362',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍢',
    version: 'E0.6',
    baseName: 'oden',
    fullName: 'oden',
    isBase: true,
    baseHex: '1f362',
    modifiers: null,
  },
  '1f363': {
    hexCodePoints: '1f363',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍣',
    version: 'E0.6',
    baseName: 'sushi',
    fullName: 'sushi',
    isBase: true,
    baseHex: '1f363',
    modifiers: null,
  },
  '1f364': {
    hexCodePoints: '1f364',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍤',
    version: 'E0.6',
    baseName: 'fried shrimp',
    fullName: 'fried shrimp',
    isBase: true,
    baseHex: '1f364',
    modifiers: null,
  },
  '1f365': {
    hexCodePoints: '1f365',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍥',
    version: 'E0.6',
    baseName: 'fish cake with swirl',
    fullName: 'fish cake with swirl',
    isBase: true,
    baseHex: '1f365',
    modifiers: null,
  },
  '1f96e': {
    hexCodePoints: '1f96e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥮',
    version: 'E11.0',
    baseName: 'moon cake',
    fullName: 'moon cake',
    isBase: true,
    baseHex: '1f96e',
    modifiers: null,
  },
  '1f361': {
    hexCodePoints: '1f361',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍡',
    version: 'E0.6',
    baseName: 'dango',
    fullName: 'dango',
    isBase: true,
    baseHex: '1f361',
    modifiers: null,
  },
  '1f95f': {
    hexCodePoints: '1f95f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥟',
    version: 'E5.0',
    baseName: 'dumpling',
    fullName: 'dumpling',
    isBase: true,
    baseHex: '1f95f',
    modifiers: null,
  },
  '1f960': {
    hexCodePoints: '1f960',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥠',
    version: 'E5.0',
    baseName: 'fortune cookie',
    fullName: 'fortune cookie',
    isBase: true,
    baseHex: '1f960',
    modifiers: null,
  },
  '1f961': {
    hexCodePoints: '1f961',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥡',
    version: 'E5.0',
    baseName: 'takeout box',
    fullName: 'takeout box',
    isBase: true,
    baseHex: '1f961',
    modifiers: null,
  },
  '1f980': {
    hexCodePoints: '1f980',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦀',
    version: 'E1.0',
    baseName: 'crab',
    fullName: 'crab',
    isBase: true,
    baseHex: '1f980',
    modifiers: null,
  },
  '1f99e': {
    hexCodePoints: '1f99e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦞',
    version: 'E11.0',
    baseName: 'lobster',
    fullName: 'lobster',
    isBase: true,
    baseHex: '1f99e',
    modifiers: null,
  },
  '1f990': {
    hexCodePoints: '1f990',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦐',
    version: 'E3.0',
    baseName: 'shrimp',
    fullName: 'shrimp',
    isBase: true,
    baseHex: '1f990',
    modifiers: null,
  },
  '1f991': {
    hexCodePoints: '1f991',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦑',
    version: 'E3.0',
    baseName: 'squid',
    fullName: 'squid',
    isBase: true,
    baseHex: '1f991',
    modifiers: null,
  },
  '1f9aa': {
    hexCodePoints: '1f9aa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦪',
    version: 'E12.0',
    baseName: 'oyster',
    fullName: 'oyster',
    isBase: true,
    baseHex: '1f9aa',
    modifiers: null,
  },
  '1f366': {
    hexCodePoints: '1f366',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍦',
    version: 'E0.6',
    baseName: 'soft ice cream',
    fullName: 'soft ice cream',
    isBase: true,
    baseHex: '1f366',
    modifiers: null,
  },
  '1f367': {
    hexCodePoints: '1f367',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍧',
    version: 'E0.6',
    baseName: 'shaved ice',
    fullName: 'shaved ice',
    isBase: true,
    baseHex: '1f367',
    modifiers: null,
  },
  '1f368': {
    hexCodePoints: '1f368',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍨',
    version: 'E0.6',
    baseName: 'ice cream',
    fullName: 'ice cream',
    isBase: true,
    baseHex: '1f368',
    modifiers: null,
  },
  '1f369': {
    hexCodePoints: '1f369',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍩',
    version: 'E0.6',
    baseName: 'doughnut',
    fullName: 'doughnut',
    isBase: true,
    baseHex: '1f369',
    modifiers: null,
  },
  '1f36a': {
    hexCodePoints: '1f36a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍪',
    version: 'E0.6',
    baseName: 'cookie',
    fullName: 'cookie',
    isBase: true,
    baseHex: '1f36a',
    modifiers: null,
  },
  '1f382': {
    hexCodePoints: '1f382',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎂',
    version: 'E0.6',
    baseName: 'birthday cake',
    fullName: 'birthday cake',
    isBase: true,
    baseHex: '1f382',
    modifiers: null,
  },
  '1f370': {
    hexCodePoints: '1f370',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍰',
    version: 'E0.6',
    baseName: 'shortcake',
    fullName: 'shortcake',
    isBase: true,
    baseHex: '1f370',
    modifiers: null,
  },
  '1f9c1': {
    hexCodePoints: '1f9c1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧁',
    version: 'E11.0',
    baseName: 'cupcake',
    fullName: 'cupcake',
    isBase: true,
    baseHex: '1f9c1',
    modifiers: null,
  },
  '1f967': {
    hexCodePoints: '1f967',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥧',
    version: 'E5.0',
    baseName: 'pie',
    fullName: 'pie',
    isBase: true,
    baseHex: '1f967',
    modifiers: null,
  },
  '1f36b': {
    hexCodePoints: '1f36b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍫',
    version: 'E0.6',
    baseName: 'chocolate bar',
    fullName: 'chocolate bar',
    isBase: true,
    baseHex: '1f36b',
    modifiers: null,
  },
  '1f36c': {
    hexCodePoints: '1f36c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍬',
    version: 'E0.6',
    baseName: 'candy',
    fullName: 'candy',
    isBase: true,
    baseHex: '1f36c',
    modifiers: null,
  },
  '1f36d': {
    hexCodePoints: '1f36d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍭',
    version: 'E0.6',
    baseName: 'lollipop',
    fullName: 'lollipop',
    isBase: true,
    baseHex: '1f36d',
    modifiers: null,
  },
  '1f36e': {
    hexCodePoints: '1f36e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍮',
    version: 'E0.6',
    baseName: 'custard',
    fullName: 'custard',
    isBase: true,
    baseHex: '1f36e',
    modifiers: null,
  },
  '1f36f': {
    hexCodePoints: '1f36f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍯',
    version: 'E0.6',
    baseName: 'honey pot',
    fullName: 'honey pot',
    isBase: true,
    baseHex: '1f36f',
    modifiers: null,
  },
  '1f37c': {
    hexCodePoints: '1f37c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍼',
    version: 'E1.0',
    baseName: 'baby bottle',
    fullName: 'baby bottle',
    isBase: true,
    baseHex: '1f37c',
    modifiers: null,
  },
  '1f95b': {
    hexCodePoints: '1f95b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥛',
    version: 'E3.0',
    baseName: 'glass of milk',
    fullName: 'glass of milk',
    isBase: true,
    baseHex: '1f95b',
    modifiers: null,
  },
  '2615': {
    hexCodePoints: '2615',
    qualifiedStatus: 'fully-qualified',
    emoji: '☕',
    version: 'E0.6',
    baseName: 'hot beverage',
    fullName: 'hot beverage',
    isBase: true,
    baseHex: '2615',
    modifiers: null,
  },
  '1fad6': {
    hexCodePoints: '1fad6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫖',
    version: 'E13.0',
    baseName: 'teapot',
    fullName: 'teapot',
    isBase: true,
    baseHex: '1fad6',
    modifiers: null,
  },
  '1f375': {
    hexCodePoints: '1f375',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍵',
    version: 'E0.6',
    baseName: 'teacup without handle',
    fullName: 'teacup without handle',
    isBase: true,
    baseHex: '1f375',
    modifiers: null,
  },
  '1f376': {
    hexCodePoints: '1f376',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍶',
    version: 'E0.6',
    baseName: 'sake',
    fullName: 'sake',
    isBase: true,
    baseHex: '1f376',
    modifiers: null,
  },
  '1f37e': {
    hexCodePoints: '1f37e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍾',
    version: 'E1.0',
    baseName: 'bottle with popping cork',
    fullName: 'bottle with popping cork',
    isBase: true,
    baseHex: '1f37e',
    modifiers: null,
  },
  '1f377': {
    hexCodePoints: '1f377',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍷',
    version: 'E0.6',
    baseName: 'wine glass',
    fullName: 'wine glass',
    isBase: true,
    baseHex: '1f377',
    modifiers: null,
  },
  '1f378': {
    hexCodePoints: '1f378',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍸',
    version: 'E0.6',
    baseName: 'cocktail glass',
    fullName: 'cocktail glass',
    isBase: true,
    baseHex: '1f378',
    modifiers: null,
  },
  '1f379': {
    hexCodePoints: '1f379',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍹',
    version: 'E0.6',
    baseName: 'tropical drink',
    fullName: 'tropical drink',
    isBase: true,
    baseHex: '1f379',
    modifiers: null,
  },
  '1f37a': {
    hexCodePoints: '1f37a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍺',
    version: 'E0.6',
    baseName: 'beer mug',
    fullName: 'beer mug',
    isBase: true,
    baseHex: '1f37a',
    modifiers: null,
  },
  '1f37b': {
    hexCodePoints: '1f37b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍻',
    version: 'E0.6',
    baseName: 'clinking beer mugs',
    fullName: 'clinking beer mugs',
    isBase: true,
    baseHex: '1f37b',
    modifiers: null,
  },
  '1f942': {
    hexCodePoints: '1f942',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥂',
    version: 'E3.0',
    baseName: 'clinking glasses',
    fullName: 'clinking glasses',
    isBase: true,
    baseHex: '1f942',
    modifiers: null,
  },
  '1f943': {
    hexCodePoints: '1f943',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥃',
    version: 'E3.0',
    baseName: 'tumbler glass',
    fullName: 'tumbler glass',
    isBase: true,
    baseHex: '1f943',
    modifiers: null,
  },
  '1fad7': {
    hexCodePoints: '1fad7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫗',
    version: 'E14.0',
    baseName: 'pouring liquid',
    fullName: 'pouring liquid',
    isBase: true,
    baseHex: '1fad7',
    modifiers: null,
  },
  '1f964': {
    hexCodePoints: '1f964',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥤',
    version: 'E5.0',
    baseName: 'cup with straw',
    fullName: 'cup with straw',
    isBase: true,
    baseHex: '1f964',
    modifiers: null,
  },
  '1f9cb': {
    hexCodePoints: '1f9cb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧋',
    version: 'E13.0',
    baseName: 'bubble tea',
    fullName: 'bubble tea',
    isBase: true,
    baseHex: '1f9cb',
    modifiers: null,
  },
  '1f9c3': {
    hexCodePoints: '1f9c3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧃',
    version: 'E12.0',
    baseName: 'beverage box',
    fullName: 'beverage box',
    isBase: true,
    baseHex: '1f9c3',
    modifiers: null,
  },
  '1f9c9': {
    hexCodePoints: '1f9c9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧉',
    version: 'E12.0',
    baseName: 'mate',
    fullName: 'mate',
    isBase: true,
    baseHex: '1f9c9',
    modifiers: null,
  },
  '1f9ca': {
    hexCodePoints: '1f9ca',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧊',
    version: 'E12.0',
    baseName: 'ice',
    fullName: 'ice',
    isBase: true,
    baseHex: '1f9ca',
    modifiers: null,
  },
  '1f962': {
    hexCodePoints: '1f962',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥢',
    version: 'E5.0',
    baseName: 'chopsticks',
    fullName: 'chopsticks',
    isBase: true,
    baseHex: '1f962',
    modifiers: null,
  },
  '1f37d-fe0f': {
    hexCodePoints: '1f37d-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍽️',
    version: 'E0.7',
    baseName: 'fork and knife with plate',
    fullName: 'fork and knife with plate',
    isBase: true,
    baseHex: '1f37d-fe0f',
    modifiers: null,
  },
  '1f37d': {
    hexCodePoints: '1f37d',
    qualifiedStatus: 'unqualified',
    emoji: '🍽',
    version: 'E0.7',
    baseName: 'fork and knife with plate',
    fullName: 'fork and knife with plate',
    isBase: false,
    baseHex: '1f37d-fe0f',
    modifiers: null,
  },
  '1f374': {
    hexCodePoints: '1f374',
    qualifiedStatus: 'fully-qualified',
    emoji: '🍴',
    version: 'E0.6',
    baseName: 'fork and knife',
    fullName: 'fork and knife',
    isBase: true,
    baseHex: '1f374',
    modifiers: null,
  },
  '1f944': {
    hexCodePoints: '1f944',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥄',
    version: 'E3.0',
    baseName: 'spoon',
    fullName: 'spoon',
    isBase: true,
    baseHex: '1f944',
    modifiers: null,
  },
  '1f52a': {
    hexCodePoints: '1f52a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔪',
    version: 'E0.6',
    baseName: 'kitchen knife',
    fullName: 'kitchen knife',
    isBase: true,
    baseHex: '1f52a',
    modifiers: null,
  },
  '1fad9': {
    hexCodePoints: '1fad9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫙',
    version: 'E14.0',
    baseName: 'jar',
    fullName: 'jar',
    isBase: true,
    baseHex: '1fad9',
    modifiers: null,
  },
  '1f3fa': {
    hexCodePoints: '1f3fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏺',
    version: 'E1.0',
    baseName: 'amphora',
    fullName: 'amphora',
    isBase: true,
    baseHex: '1f3fa',
    modifiers: null,
  },
  '1f30d': {
    hexCodePoints: '1f30d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌍',
    version: 'E0.7',
    baseName: 'globe showing Europe-Africa',
    fullName: 'globe showing Europe-Africa',
    isBase: true,
    baseHex: '1f30d',
    modifiers: null,
  },
  '1f30e': {
    hexCodePoints: '1f30e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌎',
    version: 'E0.7',
    baseName: 'globe showing Americas',
    fullName: 'globe showing Americas',
    isBase: true,
    baseHex: '1f30e',
    modifiers: null,
  },
  '1f30f': {
    hexCodePoints: '1f30f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌏',
    version: 'E0.6',
    baseName: 'globe showing Asia-Australia',
    fullName: 'globe showing Asia-Australia',
    isBase: true,
    baseHex: '1f30f',
    modifiers: null,
  },
  '1f310': {
    hexCodePoints: '1f310',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌐',
    version: 'E1.0',
    baseName: 'globe with meridians',
    fullName: 'globe with meridians',
    isBase: true,
    baseHex: '1f310',
    modifiers: null,
  },
  '1f5fa-fe0f': {
    hexCodePoints: '1f5fa-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗺️',
    version: 'E0.7',
    baseName: 'world map',
    fullName: 'world map',
    isBase: true,
    baseHex: '1f5fa-fe0f',
    modifiers: null,
  },
  '1f5fa': {
    hexCodePoints: '1f5fa',
    qualifiedStatus: 'unqualified',
    emoji: '🗺',
    version: 'E0.7',
    baseName: 'world map',
    fullName: 'world map',
    isBase: false,
    baseHex: '1f5fa-fe0f',
    modifiers: null,
  },
  '1f5fe': {
    hexCodePoints: '1f5fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗾',
    version: 'E0.6',
    baseName: 'map of Japan',
    fullName: 'map of Japan',
    isBase: true,
    baseHex: '1f5fe',
    modifiers: null,
  },
  '1f9ed': {
    hexCodePoints: '1f9ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧭',
    version: 'E11.0',
    baseName: 'compass',
    fullName: 'compass',
    isBase: true,
    baseHex: '1f9ed',
    modifiers: null,
  },
  '1f3d4-fe0f': {
    hexCodePoints: '1f3d4-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏔️',
    version: 'E0.7',
    baseName: 'snow-capped mountain',
    fullName: 'snow-capped mountain',
    isBase: true,
    baseHex: '1f3d4-fe0f',
    modifiers: null,
  },
  '1f3d4': {
    hexCodePoints: '1f3d4',
    qualifiedStatus: 'unqualified',
    emoji: '🏔',
    version: 'E0.7',
    baseName: 'snow-capped mountain',
    fullName: 'snow-capped mountain',
    isBase: false,
    baseHex: '1f3d4-fe0f',
    modifiers: null,
  },
  '26f0-fe0f': {
    hexCodePoints: '26f0-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛰️',
    version: 'E0.7',
    baseName: 'mountain',
    fullName: 'mountain',
    isBase: true,
    baseHex: '26f0-fe0f',
    modifiers: null,
  },
  '26f0': {
    hexCodePoints: '26f0',
    qualifiedStatus: 'unqualified',
    emoji: '⛰',
    version: 'E0.7',
    baseName: 'mountain',
    fullName: 'mountain',
    isBase: false,
    baseHex: '26f0-fe0f',
    modifiers: null,
  },
  '1f30b': {
    hexCodePoints: '1f30b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌋',
    version: 'E0.6',
    baseName: 'volcano',
    fullName: 'volcano',
    isBase: true,
    baseHex: '1f30b',
    modifiers: null,
  },
  '1f5fb': {
    hexCodePoints: '1f5fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗻',
    version: 'E0.6',
    baseName: 'mount fuji',
    fullName: 'mount fuji',
    isBase: true,
    baseHex: '1f5fb',
    modifiers: null,
  },
  '1f3d5-fe0f': {
    hexCodePoints: '1f3d5-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏕️',
    version: 'E0.7',
    baseName: 'camping',
    fullName: 'camping',
    isBase: true,
    baseHex: '1f3d5-fe0f',
    modifiers: null,
  },
  '1f3d5': {
    hexCodePoints: '1f3d5',
    qualifiedStatus: 'unqualified',
    emoji: '🏕',
    version: 'E0.7',
    baseName: 'camping',
    fullName: 'camping',
    isBase: false,
    baseHex: '1f3d5-fe0f',
    modifiers: null,
  },
  '1f3d6-fe0f': {
    hexCodePoints: '1f3d6-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏖️',
    version: 'E0.7',
    baseName: 'beach with umbrella',
    fullName: 'beach with umbrella',
    isBase: true,
    baseHex: '1f3d6-fe0f',
    modifiers: null,
  },
  '1f3d6': {
    hexCodePoints: '1f3d6',
    qualifiedStatus: 'unqualified',
    emoji: '🏖',
    version: 'E0.7',
    baseName: 'beach with umbrella',
    fullName: 'beach with umbrella',
    isBase: false,
    baseHex: '1f3d6-fe0f',
    modifiers: null,
  },
  '1f3dc-fe0f': {
    hexCodePoints: '1f3dc-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏜️',
    version: 'E0.7',
    baseName: 'desert',
    fullName: 'desert',
    isBase: true,
    baseHex: '1f3dc-fe0f',
    modifiers: null,
  },
  '1f3dc': {
    hexCodePoints: '1f3dc',
    qualifiedStatus: 'unqualified',
    emoji: '🏜',
    version: 'E0.7',
    baseName: 'desert',
    fullName: 'desert',
    isBase: false,
    baseHex: '1f3dc-fe0f',
    modifiers: null,
  },
  '1f3dd-fe0f': {
    hexCodePoints: '1f3dd-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏝️',
    version: 'E0.7',
    baseName: 'desert island',
    fullName: 'desert island',
    isBase: true,
    baseHex: '1f3dd-fe0f',
    modifiers: null,
  },
  '1f3dd': {
    hexCodePoints: '1f3dd',
    qualifiedStatus: 'unqualified',
    emoji: '🏝',
    version: 'E0.7',
    baseName: 'desert island',
    fullName: 'desert island',
    isBase: false,
    baseHex: '1f3dd-fe0f',
    modifiers: null,
  },
  '1f3de-fe0f': {
    hexCodePoints: '1f3de-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏞️',
    version: 'E0.7',
    baseName: 'national park',
    fullName: 'national park',
    isBase: true,
    baseHex: '1f3de-fe0f',
    modifiers: null,
  },
  '1f3de': {
    hexCodePoints: '1f3de',
    qualifiedStatus: 'unqualified',
    emoji: '🏞',
    version: 'E0.7',
    baseName: 'national park',
    fullName: 'national park',
    isBase: false,
    baseHex: '1f3de-fe0f',
    modifiers: null,
  },
  '1f3df-fe0f': {
    hexCodePoints: '1f3df-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏟️',
    version: 'E0.7',
    baseName: 'stadium',
    fullName: 'stadium',
    isBase: true,
    baseHex: '1f3df-fe0f',
    modifiers: null,
  },
  '1f3df': {
    hexCodePoints: '1f3df',
    qualifiedStatus: 'unqualified',
    emoji: '🏟',
    version: 'E0.7',
    baseName: 'stadium',
    fullName: 'stadium',
    isBase: false,
    baseHex: '1f3df-fe0f',
    modifiers: null,
  },
  '1f3db-fe0f': {
    hexCodePoints: '1f3db-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏛️',
    version: 'E0.7',
    baseName: 'classical building',
    fullName: 'classical building',
    isBase: true,
    baseHex: '1f3db-fe0f',
    modifiers: null,
  },
  '1f3db': {
    hexCodePoints: '1f3db',
    qualifiedStatus: 'unqualified',
    emoji: '🏛',
    version: 'E0.7',
    baseName: 'classical building',
    fullName: 'classical building',
    isBase: false,
    baseHex: '1f3db-fe0f',
    modifiers: null,
  },
  '1f3d7-fe0f': {
    hexCodePoints: '1f3d7-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏗️',
    version: 'E0.7',
    baseName: 'building construction',
    fullName: 'building construction',
    isBase: true,
    baseHex: '1f3d7-fe0f',
    modifiers: null,
  },
  '1f3d7': {
    hexCodePoints: '1f3d7',
    qualifiedStatus: 'unqualified',
    emoji: '🏗',
    version: 'E0.7',
    baseName: 'building construction',
    fullName: 'building construction',
    isBase: false,
    baseHex: '1f3d7-fe0f',
    modifiers: null,
  },
  '1f9f1': {
    hexCodePoints: '1f9f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧱',
    version: 'E11.0',
    baseName: 'brick',
    fullName: 'brick',
    isBase: true,
    baseHex: '1f9f1',
    modifiers: null,
  },
  '1faa8': {
    hexCodePoints: '1faa8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪨',
    version: 'E13.0',
    baseName: 'rock',
    fullName: 'rock',
    isBase: true,
    baseHex: '1faa8',
    modifiers: null,
  },
  '1fab5': {
    hexCodePoints: '1fab5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪵',
    version: 'E13.0',
    baseName: 'wood',
    fullName: 'wood',
    isBase: true,
    baseHex: '1fab5',
    modifiers: null,
  },
  '1f6d6': {
    hexCodePoints: '1f6d6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛖',
    version: 'E13.0',
    baseName: 'hut',
    fullName: 'hut',
    isBase: true,
    baseHex: '1f6d6',
    modifiers: null,
  },
  '1f3d8-fe0f': {
    hexCodePoints: '1f3d8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏘️',
    version: 'E0.7',
    baseName: 'houses',
    fullName: 'houses',
    isBase: true,
    baseHex: '1f3d8-fe0f',
    modifiers: null,
  },
  '1f3d8': {
    hexCodePoints: '1f3d8',
    qualifiedStatus: 'unqualified',
    emoji: '🏘',
    version: 'E0.7',
    baseName: 'houses',
    fullName: 'houses',
    isBase: false,
    baseHex: '1f3d8-fe0f',
    modifiers: null,
  },
  '1f3da-fe0f': {
    hexCodePoints: '1f3da-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏚️',
    version: 'E0.7',
    baseName: 'derelict house',
    fullName: 'derelict house',
    isBase: true,
    baseHex: '1f3da-fe0f',
    modifiers: null,
  },
  '1f3da': {
    hexCodePoints: '1f3da',
    qualifiedStatus: 'unqualified',
    emoji: '🏚',
    version: 'E0.7',
    baseName: 'derelict house',
    fullName: 'derelict house',
    isBase: false,
    baseHex: '1f3da-fe0f',
    modifiers: null,
  },
  '1f3e0': {
    hexCodePoints: '1f3e0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏠',
    version: 'E0.6',
    baseName: 'house',
    fullName: 'house',
    isBase: true,
    baseHex: '1f3e0',
    modifiers: null,
  },
  '1f3e1': {
    hexCodePoints: '1f3e1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏡',
    version: 'E0.6',
    baseName: 'house with garden',
    fullName: 'house with garden',
    isBase: true,
    baseHex: '1f3e1',
    modifiers: null,
  },
  '1f3e2': {
    hexCodePoints: '1f3e2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏢',
    version: 'E0.6',
    baseName: 'office building',
    fullName: 'office building',
    isBase: true,
    baseHex: '1f3e2',
    modifiers: null,
  },
  '1f3e3': {
    hexCodePoints: '1f3e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏣',
    version: 'E0.6',
    baseName: 'Japanese post office',
    fullName: 'Japanese post office',
    isBase: true,
    baseHex: '1f3e3',
    modifiers: null,
  },
  '1f3e4': {
    hexCodePoints: '1f3e4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏤',
    version: 'E1.0',
    baseName: 'post office',
    fullName: 'post office',
    isBase: true,
    baseHex: '1f3e4',
    modifiers: null,
  },
  '1f3e5': {
    hexCodePoints: '1f3e5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏥',
    version: 'E0.6',
    baseName: 'hospital',
    fullName: 'hospital',
    isBase: true,
    baseHex: '1f3e5',
    modifiers: null,
  },
  '1f3e6': {
    hexCodePoints: '1f3e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏦',
    version: 'E0.6',
    baseName: 'bank',
    fullName: 'bank',
    isBase: true,
    baseHex: '1f3e6',
    modifiers: null,
  },
  '1f3e8': {
    hexCodePoints: '1f3e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏨',
    version: 'E0.6',
    baseName: 'hotel',
    fullName: 'hotel',
    isBase: true,
    baseHex: '1f3e8',
    modifiers: null,
  },
  '1f3e9': {
    hexCodePoints: '1f3e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏩',
    version: 'E0.6',
    baseName: 'love hotel',
    fullName: 'love hotel',
    isBase: true,
    baseHex: '1f3e9',
    modifiers: null,
  },
  '1f3ea': {
    hexCodePoints: '1f3ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏪',
    version: 'E0.6',
    baseName: 'convenience store',
    fullName: 'convenience store',
    isBase: true,
    baseHex: '1f3ea',
    modifiers: null,
  },
  '1f3eb': {
    hexCodePoints: '1f3eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏫',
    version: 'E0.6',
    baseName: 'school',
    fullName: 'school',
    isBase: true,
    baseHex: '1f3eb',
    modifiers: null,
  },
  '1f3ec': {
    hexCodePoints: '1f3ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏬',
    version: 'E0.6',
    baseName: 'department store',
    fullName: 'department store',
    isBase: true,
    baseHex: '1f3ec',
    modifiers: null,
  },
  '1f3ed': {
    hexCodePoints: '1f3ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏭',
    version: 'E0.6',
    baseName: 'factory',
    fullName: 'factory',
    isBase: true,
    baseHex: '1f3ed',
    modifiers: null,
  },
  '1f3ef': {
    hexCodePoints: '1f3ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏯',
    version: 'E0.6',
    baseName: 'Japanese castle',
    fullName: 'Japanese castle',
    isBase: true,
    baseHex: '1f3ef',
    modifiers: null,
  },
  '1f3f0': {
    hexCodePoints: '1f3f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏰',
    version: 'E0.6',
    baseName: 'castle',
    fullName: 'castle',
    isBase: true,
    baseHex: '1f3f0',
    modifiers: null,
  },
  '1f492': {
    hexCodePoints: '1f492',
    qualifiedStatus: 'fully-qualified',
    emoji: '💒',
    version: 'E0.6',
    baseName: 'wedding',
    fullName: 'wedding',
    isBase: true,
    baseHex: '1f492',
    modifiers: null,
  },
  '1f5fc': {
    hexCodePoints: '1f5fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗼',
    version: 'E0.6',
    baseName: 'Tokyo tower',
    fullName: 'Tokyo tower',
    isBase: true,
    baseHex: '1f5fc',
    modifiers: null,
  },
  '1f5fd': {
    hexCodePoints: '1f5fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗽',
    version: 'E0.6',
    baseName: 'Statue of Liberty',
    fullName: 'Statue of Liberty',
    isBase: true,
    baseHex: '1f5fd',
    modifiers: null,
  },
  '26ea': {
    hexCodePoints: '26ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛪',
    version: 'E0.6',
    baseName: 'church',
    fullName: 'church',
    isBase: true,
    baseHex: '26ea',
    modifiers: null,
  },
  '1f54c': {
    hexCodePoints: '1f54c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕌',
    version: 'E1.0',
    baseName: 'mosque',
    fullName: 'mosque',
    isBase: true,
    baseHex: '1f54c',
    modifiers: null,
  },
  '1f6d5': {
    hexCodePoints: '1f6d5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛕',
    version: 'E12.0',
    baseName: 'hindu temple',
    fullName: 'hindu temple',
    isBase: true,
    baseHex: '1f6d5',
    modifiers: null,
  },
  '1f54d': {
    hexCodePoints: '1f54d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕍',
    version: 'E1.0',
    baseName: 'synagogue',
    fullName: 'synagogue',
    isBase: true,
    baseHex: '1f54d',
    modifiers: null,
  },
  '26e9-fe0f': {
    hexCodePoints: '26e9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛩️',
    version: 'E0.7',
    baseName: 'shinto shrine',
    fullName: 'shinto shrine',
    isBase: true,
    baseHex: '26e9-fe0f',
    modifiers: null,
  },
  '26e9': {
    hexCodePoints: '26e9',
    qualifiedStatus: 'unqualified',
    emoji: '⛩',
    version: 'E0.7',
    baseName: 'shinto shrine',
    fullName: 'shinto shrine',
    isBase: false,
    baseHex: '26e9-fe0f',
    modifiers: null,
  },
  '1f54b': {
    hexCodePoints: '1f54b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕋',
    version: 'E1.0',
    baseName: 'kaaba',
    fullName: 'kaaba',
    isBase: true,
    baseHex: '1f54b',
    modifiers: null,
  },
  '26f2': {
    hexCodePoints: '26f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛲',
    version: 'E0.6',
    baseName: 'fountain',
    fullName: 'fountain',
    isBase: true,
    baseHex: '26f2',
    modifiers: null,
  },
  '26fa': {
    hexCodePoints: '26fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛺',
    version: 'E0.6',
    baseName: 'tent',
    fullName: 'tent',
    isBase: true,
    baseHex: '26fa',
    modifiers: null,
  },
  '1f301': {
    hexCodePoints: '1f301',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌁',
    version: 'E0.6',
    baseName: 'foggy',
    fullName: 'foggy',
    isBase: true,
    baseHex: '1f301',
    modifiers: null,
  },
  '1f303': {
    hexCodePoints: '1f303',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌃',
    version: 'E0.6',
    baseName: 'night with stars',
    fullName: 'night with stars',
    isBase: true,
    baseHex: '1f303',
    modifiers: null,
  },
  '1f3d9-fe0f': {
    hexCodePoints: '1f3d9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏙️',
    version: 'E0.7',
    baseName: 'cityscape',
    fullName: 'cityscape',
    isBase: true,
    baseHex: '1f3d9-fe0f',
    modifiers: null,
  },
  '1f3d9': {
    hexCodePoints: '1f3d9',
    qualifiedStatus: 'unqualified',
    emoji: '🏙',
    version: 'E0.7',
    baseName: 'cityscape',
    fullName: 'cityscape',
    isBase: false,
    baseHex: '1f3d9-fe0f',
    modifiers: null,
  },
  '1f304': {
    hexCodePoints: '1f304',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌄',
    version: 'E0.6',
    baseName: 'sunrise over mountains',
    fullName: 'sunrise over mountains',
    isBase: true,
    baseHex: '1f304',
    modifiers: null,
  },
  '1f305': {
    hexCodePoints: '1f305',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌅',
    version: 'E0.6',
    baseName: 'sunrise',
    fullName: 'sunrise',
    isBase: true,
    baseHex: '1f305',
    modifiers: null,
  },
  '1f306': {
    hexCodePoints: '1f306',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌆',
    version: 'E0.6',
    baseName: 'cityscape at dusk',
    fullName: 'cityscape at dusk',
    isBase: true,
    baseHex: '1f306',
    modifiers: null,
  },
  '1f307': {
    hexCodePoints: '1f307',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌇',
    version: 'E0.6',
    baseName: 'sunset',
    fullName: 'sunset',
    isBase: true,
    baseHex: '1f307',
    modifiers: null,
  },
  '1f309': {
    hexCodePoints: '1f309',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌉',
    version: 'E0.6',
    baseName: 'bridge at night',
    fullName: 'bridge at night',
    isBase: true,
    baseHex: '1f309',
    modifiers: null,
  },
  '2668-fe0f': {
    hexCodePoints: '2668-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♨️',
    version: 'E0.6',
    baseName: 'hot springs',
    fullName: 'hot springs',
    isBase: true,
    baseHex: '2668-fe0f',
    modifiers: null,
  },
  '2668': {
    hexCodePoints: '2668',
    qualifiedStatus: 'unqualified',
    emoji: '♨',
    version: 'E0.6',
    baseName: 'hot springs',
    fullName: 'hot springs',
    isBase: false,
    baseHex: '2668-fe0f',
    modifiers: null,
  },
  '1f3a0': {
    hexCodePoints: '1f3a0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎠',
    version: 'E0.6',
    baseName: 'carousel horse',
    fullName: 'carousel horse',
    isBase: true,
    baseHex: '1f3a0',
    modifiers: null,
  },
  '1f6dd': {
    hexCodePoints: '1f6dd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛝',
    version: 'E14.0',
    baseName: 'playground slide',
    fullName: 'playground slide',
    isBase: true,
    baseHex: '1f6dd',
    modifiers: null,
  },
  '1f3a1': {
    hexCodePoints: '1f3a1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎡',
    version: 'E0.6',
    baseName: 'ferris wheel',
    fullName: 'ferris wheel',
    isBase: true,
    baseHex: '1f3a1',
    modifiers: null,
  },
  '1f3a2': {
    hexCodePoints: '1f3a2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎢',
    version: 'E0.6',
    baseName: 'roller coaster',
    fullName: 'roller coaster',
    isBase: true,
    baseHex: '1f3a2',
    modifiers: null,
  },
  '1f488': {
    hexCodePoints: '1f488',
    qualifiedStatus: 'fully-qualified',
    emoji: '💈',
    version: 'E0.6',
    baseName: 'barber pole',
    fullName: 'barber pole',
    isBase: true,
    baseHex: '1f488',
    modifiers: null,
  },
  '1f3aa': {
    hexCodePoints: '1f3aa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎪',
    version: 'E0.6',
    baseName: 'circus tent',
    fullName: 'circus tent',
    isBase: true,
    baseHex: '1f3aa',
    modifiers: null,
  },
  '1f682': {
    hexCodePoints: '1f682',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚂',
    version: 'E1.0',
    baseName: 'locomotive',
    fullName: 'locomotive',
    isBase: true,
    baseHex: '1f682',
    modifiers: null,
  },
  '1f683': {
    hexCodePoints: '1f683',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚃',
    version: 'E0.6',
    baseName: 'railway car',
    fullName: 'railway car',
    isBase: true,
    baseHex: '1f683',
    modifiers: null,
  },
  '1f684': {
    hexCodePoints: '1f684',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚄',
    version: 'E0.6',
    baseName: 'high-speed train',
    fullName: 'high-speed train',
    isBase: true,
    baseHex: '1f684',
    modifiers: null,
  },
  '1f685': {
    hexCodePoints: '1f685',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚅',
    version: 'E0.6',
    baseName: 'bullet train',
    fullName: 'bullet train',
    isBase: true,
    baseHex: '1f685',
    modifiers: null,
  },
  '1f686': {
    hexCodePoints: '1f686',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚆',
    version: 'E1.0',
    baseName: 'train',
    fullName: 'train',
    isBase: true,
    baseHex: '1f686',
    modifiers: null,
  },
  '1f687': {
    hexCodePoints: '1f687',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚇',
    version: 'E0.6',
    baseName: 'metro',
    fullName: 'metro',
    isBase: true,
    baseHex: '1f687',
    modifiers: null,
  },
  '1f688': {
    hexCodePoints: '1f688',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚈',
    version: 'E1.0',
    baseName: 'light rail',
    fullName: 'light rail',
    isBase: true,
    baseHex: '1f688',
    modifiers: null,
  },
  '1f689': {
    hexCodePoints: '1f689',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚉',
    version: 'E0.6',
    baseName: 'station',
    fullName: 'station',
    isBase: true,
    baseHex: '1f689',
    modifiers: null,
  },
  '1f68a': {
    hexCodePoints: '1f68a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚊',
    version: 'E1.0',
    baseName: 'tram',
    fullName: 'tram',
    isBase: true,
    baseHex: '1f68a',
    modifiers: null,
  },
  '1f69d': {
    hexCodePoints: '1f69d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚝',
    version: 'E1.0',
    baseName: 'monorail',
    fullName: 'monorail',
    isBase: true,
    baseHex: '1f69d',
    modifiers: null,
  },
  '1f69e': {
    hexCodePoints: '1f69e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚞',
    version: 'E1.0',
    baseName: 'mountain railway',
    fullName: 'mountain railway',
    isBase: true,
    baseHex: '1f69e',
    modifiers: null,
  },
  '1f68b': {
    hexCodePoints: '1f68b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚋',
    version: 'E1.0',
    baseName: 'tram car',
    fullName: 'tram car',
    isBase: true,
    baseHex: '1f68b',
    modifiers: null,
  },
  '1f68c': {
    hexCodePoints: '1f68c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚌',
    version: 'E0.6',
    baseName: 'bus',
    fullName: 'bus',
    isBase: true,
    baseHex: '1f68c',
    modifiers: null,
  },
  '1f68d': {
    hexCodePoints: '1f68d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚍',
    version: 'E0.7',
    baseName: 'oncoming bus',
    fullName: 'oncoming bus',
    isBase: true,
    baseHex: '1f68d',
    modifiers: null,
  },
  '1f68e': {
    hexCodePoints: '1f68e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚎',
    version: 'E1.0',
    baseName: 'trolleybus',
    fullName: 'trolleybus',
    isBase: true,
    baseHex: '1f68e',
    modifiers: null,
  },
  '1f690': {
    hexCodePoints: '1f690',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚐',
    version: 'E1.0',
    baseName: 'minibus',
    fullName: 'minibus',
    isBase: true,
    baseHex: '1f690',
    modifiers: null,
  },
  '1f691': {
    hexCodePoints: '1f691',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚑',
    version: 'E0.6',
    baseName: 'ambulance',
    fullName: 'ambulance',
    isBase: true,
    baseHex: '1f691',
    modifiers: null,
  },
  '1f692': {
    hexCodePoints: '1f692',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚒',
    version: 'E0.6',
    baseName: 'fire engine',
    fullName: 'fire engine',
    isBase: true,
    baseHex: '1f692',
    modifiers: null,
  },
  '1f693': {
    hexCodePoints: '1f693',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚓',
    version: 'E0.6',
    baseName: 'police car',
    fullName: 'police car',
    isBase: true,
    baseHex: '1f693',
    modifiers: null,
  },
  '1f694': {
    hexCodePoints: '1f694',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚔',
    version: 'E0.7',
    baseName: 'oncoming police car',
    fullName: 'oncoming police car',
    isBase: true,
    baseHex: '1f694',
    modifiers: null,
  },
  '1f695': {
    hexCodePoints: '1f695',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚕',
    version: 'E0.6',
    baseName: 'taxi',
    fullName: 'taxi',
    isBase: true,
    baseHex: '1f695',
    modifiers: null,
  },
  '1f696': {
    hexCodePoints: '1f696',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚖',
    version: 'E1.0',
    baseName: 'oncoming taxi',
    fullName: 'oncoming taxi',
    isBase: true,
    baseHex: '1f696',
    modifiers: null,
  },
  '1f697': {
    hexCodePoints: '1f697',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚗',
    version: 'E0.6',
    baseName: 'automobile',
    fullName: 'automobile',
    isBase: true,
    baseHex: '1f697',
    modifiers: null,
  },
  '1f698': {
    hexCodePoints: '1f698',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚘',
    version: 'E0.7',
    baseName: 'oncoming automobile',
    fullName: 'oncoming automobile',
    isBase: true,
    baseHex: '1f698',
    modifiers: null,
  },
  '1f699': {
    hexCodePoints: '1f699',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚙',
    version: 'E0.6',
    baseName: 'sport utility vehicle',
    fullName: 'sport utility vehicle',
    isBase: true,
    baseHex: '1f699',
    modifiers: null,
  },
  '1f6fb': {
    hexCodePoints: '1f6fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛻',
    version: 'E13.0',
    baseName: 'pickup truck',
    fullName: 'pickup truck',
    isBase: true,
    baseHex: '1f6fb',
    modifiers: null,
  },
  '1f69a': {
    hexCodePoints: '1f69a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚚',
    version: 'E0.6',
    baseName: 'delivery truck',
    fullName: 'delivery truck',
    isBase: true,
    baseHex: '1f69a',
    modifiers: null,
  },
  '1f69b': {
    hexCodePoints: '1f69b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚛',
    version: 'E1.0',
    baseName: 'articulated lorry',
    fullName: 'articulated lorry',
    isBase: true,
    baseHex: '1f69b',
    modifiers: null,
  },
  '1f69c': {
    hexCodePoints: '1f69c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚜',
    version: 'E1.0',
    baseName: 'tractor',
    fullName: 'tractor',
    isBase: true,
    baseHex: '1f69c',
    modifiers: null,
  },
  '1f3ce-fe0f': {
    hexCodePoints: '1f3ce-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏎️',
    version: 'E0.7',
    baseName: 'racing car',
    fullName: 'racing car',
    isBase: true,
    baseHex: '1f3ce-fe0f',
    modifiers: null,
  },
  '1f3ce': {
    hexCodePoints: '1f3ce',
    qualifiedStatus: 'unqualified',
    emoji: '🏎',
    version: 'E0.7',
    baseName: 'racing car',
    fullName: 'racing car',
    isBase: false,
    baseHex: '1f3ce-fe0f',
    modifiers: null,
  },
  '1f3cd-fe0f': {
    hexCodePoints: '1f3cd-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏍️',
    version: 'E0.7',
    baseName: 'motorcycle',
    fullName: 'motorcycle',
    isBase: true,
    baseHex: '1f3cd-fe0f',
    modifiers: null,
  },
  '1f3cd': {
    hexCodePoints: '1f3cd',
    qualifiedStatus: 'unqualified',
    emoji: '🏍',
    version: 'E0.7',
    baseName: 'motorcycle',
    fullName: 'motorcycle',
    isBase: false,
    baseHex: '1f3cd-fe0f',
    modifiers: null,
  },
  '1f6f5': {
    hexCodePoints: '1f6f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛵',
    version: 'E3.0',
    baseName: 'motor scooter',
    fullName: 'motor scooter',
    isBase: true,
    baseHex: '1f6f5',
    modifiers: null,
  },
  '1f9bd': {
    hexCodePoints: '1f9bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦽',
    version: 'E12.0',
    baseName: 'manual wheelchair',
    fullName: 'manual wheelchair',
    isBase: true,
    baseHex: '1f9bd',
    modifiers: null,
  },
  '1f9bc': {
    hexCodePoints: '1f9bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦼',
    version: 'E12.0',
    baseName: 'motorized wheelchair',
    fullName: 'motorized wheelchair',
    isBase: true,
    baseHex: '1f9bc',
    modifiers: null,
  },
  '1f6fa': {
    hexCodePoints: '1f6fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛺',
    version: 'E12.0',
    baseName: 'auto rickshaw',
    fullName: 'auto rickshaw',
    isBase: true,
    baseHex: '1f6fa',
    modifiers: null,
  },
  '1f6b2': {
    hexCodePoints: '1f6b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚲',
    version: 'E0.6',
    baseName: 'bicycle',
    fullName: 'bicycle',
    isBase: true,
    baseHex: '1f6b2',
    modifiers: null,
  },
  '1f6f4': {
    hexCodePoints: '1f6f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛴',
    version: 'E3.0',
    baseName: 'kick scooter',
    fullName: 'kick scooter',
    isBase: true,
    baseHex: '1f6f4',
    modifiers: null,
  },
  '1f6f9': {
    hexCodePoints: '1f6f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛹',
    version: 'E11.0',
    baseName: 'skateboard',
    fullName: 'skateboard',
    isBase: true,
    baseHex: '1f6f9',
    modifiers: null,
  },
  '1f6fc': {
    hexCodePoints: '1f6fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛼',
    version: 'E13.0',
    baseName: 'roller skate',
    fullName: 'roller skate',
    isBase: true,
    baseHex: '1f6fc',
    modifiers: null,
  },
  '1f68f': {
    hexCodePoints: '1f68f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚏',
    version: 'E0.6',
    baseName: 'bus stop',
    fullName: 'bus stop',
    isBase: true,
    baseHex: '1f68f',
    modifiers: null,
  },
  '1f6e3-fe0f': {
    hexCodePoints: '1f6e3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛣️',
    version: 'E0.7',
    baseName: 'motorway',
    fullName: 'motorway',
    isBase: true,
    baseHex: '1f6e3-fe0f',
    modifiers: null,
  },
  '1f6e3': {
    hexCodePoints: '1f6e3',
    qualifiedStatus: 'unqualified',
    emoji: '🛣',
    version: 'E0.7',
    baseName: 'motorway',
    fullName: 'motorway',
    isBase: false,
    baseHex: '1f6e3-fe0f',
    modifiers: null,
  },
  '1f6e4-fe0f': {
    hexCodePoints: '1f6e4-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛤️',
    version: 'E0.7',
    baseName: 'railway track',
    fullName: 'railway track',
    isBase: true,
    baseHex: '1f6e4-fe0f',
    modifiers: null,
  },
  '1f6e4': {
    hexCodePoints: '1f6e4',
    qualifiedStatus: 'unqualified',
    emoji: '🛤',
    version: 'E0.7',
    baseName: 'railway track',
    fullName: 'railway track',
    isBase: false,
    baseHex: '1f6e4-fe0f',
    modifiers: null,
  },
  '1f6e2-fe0f': {
    hexCodePoints: '1f6e2-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛢️',
    version: 'E0.7',
    baseName: 'oil drum',
    fullName: 'oil drum',
    isBase: true,
    baseHex: '1f6e2-fe0f',
    modifiers: null,
  },
  '1f6e2': {
    hexCodePoints: '1f6e2',
    qualifiedStatus: 'unqualified',
    emoji: '🛢',
    version: 'E0.7',
    baseName: 'oil drum',
    fullName: 'oil drum',
    isBase: false,
    baseHex: '1f6e2-fe0f',
    modifiers: null,
  },
  '26fd': {
    hexCodePoints: '26fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛽',
    version: 'E0.6',
    baseName: 'fuel pump',
    fullName: 'fuel pump',
    isBase: true,
    baseHex: '26fd',
    modifiers: null,
  },
  '1f6de': {
    hexCodePoints: '1f6de',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛞',
    version: 'E14.0',
    baseName: 'wheel',
    fullName: 'wheel',
    isBase: true,
    baseHex: '1f6de',
    modifiers: null,
  },
  '1f6a8': {
    hexCodePoints: '1f6a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚨',
    version: 'E0.6',
    baseName: 'police car light',
    fullName: 'police car light',
    isBase: true,
    baseHex: '1f6a8',
    modifiers: null,
  },
  '1f6a5': {
    hexCodePoints: '1f6a5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚥',
    version: 'E0.6',
    baseName: 'horizontal traffic light',
    fullName: 'horizontal traffic light',
    isBase: true,
    baseHex: '1f6a5',
    modifiers: null,
  },
  '1f6a6': {
    hexCodePoints: '1f6a6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚦',
    version: 'E1.0',
    baseName: 'vertical traffic light',
    fullName: 'vertical traffic light',
    isBase: true,
    baseHex: '1f6a6',
    modifiers: null,
  },
  '1f6d1': {
    hexCodePoints: '1f6d1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛑',
    version: 'E3.0',
    baseName: 'stop sign',
    fullName: 'stop sign',
    isBase: true,
    baseHex: '1f6d1',
    modifiers: null,
  },
  '1f6a7': {
    hexCodePoints: '1f6a7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚧',
    version: 'E0.6',
    baseName: 'construction',
    fullName: 'construction',
    isBase: true,
    baseHex: '1f6a7',
    modifiers: null,
  },
  '2693': {
    hexCodePoints: '2693',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚓',
    version: 'E0.6',
    baseName: 'anchor',
    fullName: 'anchor',
    isBase: true,
    baseHex: '2693',
    modifiers: null,
  },
  '1f6df': {
    hexCodePoints: '1f6df',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛟',
    version: 'E14.0',
    baseName: 'ring buoy',
    fullName: 'ring buoy',
    isBase: true,
    baseHex: '1f6df',
    modifiers: null,
  },
  '26f5': {
    hexCodePoints: '26f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛵',
    version: 'E0.6',
    baseName: 'sailboat',
    fullName: 'sailboat',
    isBase: true,
    baseHex: '26f5',
    modifiers: null,
  },
  '1f6f6': {
    hexCodePoints: '1f6f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛶',
    version: 'E3.0',
    baseName: 'canoe',
    fullName: 'canoe',
    isBase: true,
    baseHex: '1f6f6',
    modifiers: null,
  },
  '1f6a4': {
    hexCodePoints: '1f6a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚤',
    version: 'E0.6',
    baseName: 'speedboat',
    fullName: 'speedboat',
    isBase: true,
    baseHex: '1f6a4',
    modifiers: null,
  },
  '1f6f3-fe0f': {
    hexCodePoints: '1f6f3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛳️',
    version: 'E0.7',
    baseName: 'passenger ship',
    fullName: 'passenger ship',
    isBase: true,
    baseHex: '1f6f3-fe0f',
    modifiers: null,
  },
  '1f6f3': {
    hexCodePoints: '1f6f3',
    qualifiedStatus: 'unqualified',
    emoji: '🛳',
    version: 'E0.7',
    baseName: 'passenger ship',
    fullName: 'passenger ship',
    isBase: false,
    baseHex: '1f6f3-fe0f',
    modifiers: null,
  },
  '26f4-fe0f': {
    hexCodePoints: '26f4-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛴️',
    version: 'E0.7',
    baseName: 'ferry',
    fullName: 'ferry',
    isBase: true,
    baseHex: '26f4-fe0f',
    modifiers: null,
  },
  '26f4': {
    hexCodePoints: '26f4',
    qualifiedStatus: 'unqualified',
    emoji: '⛴',
    version: 'E0.7',
    baseName: 'ferry',
    fullName: 'ferry',
    isBase: false,
    baseHex: '26f4-fe0f',
    modifiers: null,
  },
  '1f6e5-fe0f': {
    hexCodePoints: '1f6e5-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛥️',
    version: 'E0.7',
    baseName: 'motor boat',
    fullName: 'motor boat',
    isBase: true,
    baseHex: '1f6e5-fe0f',
    modifiers: null,
  },
  '1f6e5': {
    hexCodePoints: '1f6e5',
    qualifiedStatus: 'unqualified',
    emoji: '🛥',
    version: 'E0.7',
    baseName: 'motor boat',
    fullName: 'motor boat',
    isBase: false,
    baseHex: '1f6e5-fe0f',
    modifiers: null,
  },
  '1f6a2': {
    hexCodePoints: '1f6a2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚢',
    version: 'E0.6',
    baseName: 'ship',
    fullName: 'ship',
    isBase: true,
    baseHex: '1f6a2',
    modifiers: null,
  },
  '2708-fe0f': {
    hexCodePoints: '2708-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✈️',
    version: 'E0.6',
    baseName: 'airplane',
    fullName: 'airplane',
    isBase: true,
    baseHex: '2708-fe0f',
    modifiers: null,
  },
  '2708': {
    hexCodePoints: '2708',
    qualifiedStatus: 'unqualified',
    emoji: '✈',
    version: 'E0.6',
    baseName: 'airplane',
    fullName: 'airplane',
    isBase: false,
    baseHex: '2708-fe0f',
    modifiers: null,
  },
  '1f6e9-fe0f': {
    hexCodePoints: '1f6e9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛩️',
    version: 'E0.7',
    baseName: 'small airplane',
    fullName: 'small airplane',
    isBase: true,
    baseHex: '1f6e9-fe0f',
    modifiers: null,
  },
  '1f6e9': {
    hexCodePoints: '1f6e9',
    qualifiedStatus: 'unqualified',
    emoji: '🛩',
    version: 'E0.7',
    baseName: 'small airplane',
    fullName: 'small airplane',
    isBase: false,
    baseHex: '1f6e9-fe0f',
    modifiers: null,
  },
  '1f6eb': {
    hexCodePoints: '1f6eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛫',
    version: 'E1.0',
    baseName: 'airplane departure',
    fullName: 'airplane departure',
    isBase: true,
    baseHex: '1f6eb',
    modifiers: null,
  },
  '1f6ec': {
    hexCodePoints: '1f6ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛬',
    version: 'E1.0',
    baseName: 'airplane arrival',
    fullName: 'airplane arrival',
    isBase: true,
    baseHex: '1f6ec',
    modifiers: null,
  },
  '1fa82': {
    hexCodePoints: '1fa82',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪂',
    version: 'E12.0',
    baseName: 'parachute',
    fullName: 'parachute',
    isBase: true,
    baseHex: '1fa82',
    modifiers: null,
  },
  '1f4ba': {
    hexCodePoints: '1f4ba',
    qualifiedStatus: 'fully-qualified',
    emoji: '💺',
    version: 'E0.6',
    baseName: 'seat',
    fullName: 'seat',
    isBase: true,
    baseHex: '1f4ba',
    modifiers: null,
  },
  '1f681': {
    hexCodePoints: '1f681',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚁',
    version: 'E1.0',
    baseName: 'helicopter',
    fullName: 'helicopter',
    isBase: true,
    baseHex: '1f681',
    modifiers: null,
  },
  '1f69f': {
    hexCodePoints: '1f69f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚟',
    version: 'E1.0',
    baseName: 'suspension railway',
    fullName: 'suspension railway',
    isBase: true,
    baseHex: '1f69f',
    modifiers: null,
  },
  '1f6a0': {
    hexCodePoints: '1f6a0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚠',
    version: 'E1.0',
    baseName: 'mountain cableway',
    fullName: 'mountain cableway',
    isBase: true,
    baseHex: '1f6a0',
    modifiers: null,
  },
  '1f6a1': {
    hexCodePoints: '1f6a1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚡',
    version: 'E1.0',
    baseName: 'aerial tramway',
    fullName: 'aerial tramway',
    isBase: true,
    baseHex: '1f6a1',
    modifiers: null,
  },
  '1f6f0-fe0f': {
    hexCodePoints: '1f6f0-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛰️',
    version: 'E0.7',
    baseName: 'satellite',
    fullName: 'satellite',
    isBase: true,
    baseHex: '1f6f0-fe0f',
    modifiers: null,
  },
  '1f6f0': {
    hexCodePoints: '1f6f0',
    qualifiedStatus: 'unqualified',
    emoji: '🛰',
    version: 'E0.7',
    baseName: 'satellite',
    fullName: 'satellite',
    isBase: false,
    baseHex: '1f6f0-fe0f',
    modifiers: null,
  },
  '1f680': {
    hexCodePoints: '1f680',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚀',
    version: 'E0.6',
    baseName: 'rocket',
    fullName: 'rocket',
    isBase: true,
    baseHex: '1f680',
    modifiers: null,
  },
  '1f6f8': {
    hexCodePoints: '1f6f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛸',
    version: 'E5.0',
    baseName: 'flying saucer',
    fullName: 'flying saucer',
    isBase: true,
    baseHex: '1f6f8',
    modifiers: null,
  },
  '1f6ce-fe0f': {
    hexCodePoints: '1f6ce-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛎️',
    version: 'E0.7',
    baseName: 'bellhop bell',
    fullName: 'bellhop bell',
    isBase: true,
    baseHex: '1f6ce-fe0f',
    modifiers: null,
  },
  '1f6ce': {
    hexCodePoints: '1f6ce',
    qualifiedStatus: 'unqualified',
    emoji: '🛎',
    version: 'E0.7',
    baseName: 'bellhop bell',
    fullName: 'bellhop bell',
    isBase: false,
    baseHex: '1f6ce-fe0f',
    modifiers: null,
  },
  '1f9f3': {
    hexCodePoints: '1f9f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧳',
    version: 'E11.0',
    baseName: 'luggage',
    fullName: 'luggage',
    isBase: true,
    baseHex: '1f9f3',
    modifiers: null,
  },
  '231b': {
    hexCodePoints: '231b',
    qualifiedStatus: 'fully-qualified',
    emoji: '⌛',
    version: 'E0.6',
    baseName: 'hourglass done',
    fullName: 'hourglass done',
    isBase: true,
    baseHex: '231b',
    modifiers: null,
  },
  '23f3': {
    hexCodePoints: '23f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏳',
    version: 'E0.6',
    baseName: 'hourglass not done',
    fullName: 'hourglass not done',
    isBase: true,
    baseHex: '23f3',
    modifiers: null,
  },
  '231a': {
    hexCodePoints: '231a',
    qualifiedStatus: 'fully-qualified',
    emoji: '⌚',
    version: 'E0.6',
    baseName: 'watch',
    fullName: 'watch',
    isBase: true,
    baseHex: '231a',
    modifiers: null,
  },
  '23f0': {
    hexCodePoints: '23f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏰',
    version: 'E0.6',
    baseName: 'alarm clock',
    fullName: 'alarm clock',
    isBase: true,
    baseHex: '23f0',
    modifiers: null,
  },
  '23f1-fe0f': {
    hexCodePoints: '23f1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏱️',
    version: 'E1.0',
    baseName: 'stopwatch',
    fullName: 'stopwatch',
    isBase: true,
    baseHex: '23f1-fe0f',
    modifiers: null,
  },
  '23f1': {
    hexCodePoints: '23f1',
    qualifiedStatus: 'unqualified',
    emoji: '⏱',
    version: 'E1.0',
    baseName: 'stopwatch',
    fullName: 'stopwatch',
    isBase: false,
    baseHex: '23f1-fe0f',
    modifiers: null,
  },
  '23f2-fe0f': {
    hexCodePoints: '23f2-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏲️',
    version: 'E1.0',
    baseName: 'timer clock',
    fullName: 'timer clock',
    isBase: true,
    baseHex: '23f2-fe0f',
    modifiers: null,
  },
  '23f2': {
    hexCodePoints: '23f2',
    qualifiedStatus: 'unqualified',
    emoji: '⏲',
    version: 'E1.0',
    baseName: 'timer clock',
    fullName: 'timer clock',
    isBase: false,
    baseHex: '23f2-fe0f',
    modifiers: null,
  },
  '1f570-fe0f': {
    hexCodePoints: '1f570-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕰️',
    version: 'E0.7',
    baseName: 'mantelpiece clock',
    fullName: 'mantelpiece clock',
    isBase: true,
    baseHex: '1f570-fe0f',
    modifiers: null,
  },
  '1f570': {
    hexCodePoints: '1f570',
    qualifiedStatus: 'unqualified',
    emoji: '🕰',
    version: 'E0.7',
    baseName: 'mantelpiece clock',
    fullName: 'mantelpiece clock',
    isBase: false,
    baseHex: '1f570-fe0f',
    modifiers: null,
  },
  '1f55b': {
    hexCodePoints: '1f55b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕛',
    version: 'E0.6',
    baseName: 'twelve o’clock',
    fullName: 'twelve o’clock',
    isBase: true,
    baseHex: '1f55b',
    modifiers: null,
  },
  '1f567': {
    hexCodePoints: '1f567',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕧',
    version: 'E0.7',
    baseName: 'twelve-thirty',
    fullName: 'twelve-thirty',
    isBase: true,
    baseHex: '1f567',
    modifiers: null,
  },
  '1f550': {
    hexCodePoints: '1f550',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕐',
    version: 'E0.6',
    baseName: 'one o’clock',
    fullName: 'one o’clock',
    isBase: true,
    baseHex: '1f550',
    modifiers: null,
  },
  '1f55c': {
    hexCodePoints: '1f55c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕜',
    version: 'E0.7',
    baseName: 'one-thirty',
    fullName: 'one-thirty',
    isBase: true,
    baseHex: '1f55c',
    modifiers: null,
  },
  '1f551': {
    hexCodePoints: '1f551',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕑',
    version: 'E0.6',
    baseName: 'two o’clock',
    fullName: 'two o’clock',
    isBase: true,
    baseHex: '1f551',
    modifiers: null,
  },
  '1f55d': {
    hexCodePoints: '1f55d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕝',
    version: 'E0.7',
    baseName: 'two-thirty',
    fullName: 'two-thirty',
    isBase: true,
    baseHex: '1f55d',
    modifiers: null,
  },
  '1f552': {
    hexCodePoints: '1f552',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕒',
    version: 'E0.6',
    baseName: 'three o’clock',
    fullName: 'three o’clock',
    isBase: true,
    baseHex: '1f552',
    modifiers: null,
  },
  '1f55e': {
    hexCodePoints: '1f55e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕞',
    version: 'E0.7',
    baseName: 'three-thirty',
    fullName: 'three-thirty',
    isBase: true,
    baseHex: '1f55e',
    modifiers: null,
  },
  '1f553': {
    hexCodePoints: '1f553',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕓',
    version: 'E0.6',
    baseName: 'four o’clock',
    fullName: 'four o’clock',
    isBase: true,
    baseHex: '1f553',
    modifiers: null,
  },
  '1f55f': {
    hexCodePoints: '1f55f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕟',
    version: 'E0.7',
    baseName: 'four-thirty',
    fullName: 'four-thirty',
    isBase: true,
    baseHex: '1f55f',
    modifiers: null,
  },
  '1f554': {
    hexCodePoints: '1f554',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕔',
    version: 'E0.6',
    baseName: 'five o’clock',
    fullName: 'five o’clock',
    isBase: true,
    baseHex: '1f554',
    modifiers: null,
  },
  '1f560': {
    hexCodePoints: '1f560',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕠',
    version: 'E0.7',
    baseName: 'five-thirty',
    fullName: 'five-thirty',
    isBase: true,
    baseHex: '1f560',
    modifiers: null,
  },
  '1f555': {
    hexCodePoints: '1f555',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕕',
    version: 'E0.6',
    baseName: 'six o’clock',
    fullName: 'six o’clock',
    isBase: true,
    baseHex: '1f555',
    modifiers: null,
  },
  '1f561': {
    hexCodePoints: '1f561',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕡',
    version: 'E0.7',
    baseName: 'six-thirty',
    fullName: 'six-thirty',
    isBase: true,
    baseHex: '1f561',
    modifiers: null,
  },
  '1f556': {
    hexCodePoints: '1f556',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕖',
    version: 'E0.6',
    baseName: 'seven o’clock',
    fullName: 'seven o’clock',
    isBase: true,
    baseHex: '1f556',
    modifiers: null,
  },
  '1f562': {
    hexCodePoints: '1f562',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕢',
    version: 'E0.7',
    baseName: 'seven-thirty',
    fullName: 'seven-thirty',
    isBase: true,
    baseHex: '1f562',
    modifiers: null,
  },
  '1f557': {
    hexCodePoints: '1f557',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕗',
    version: 'E0.6',
    baseName: 'eight o’clock',
    fullName: 'eight o’clock',
    isBase: true,
    baseHex: '1f557',
    modifiers: null,
  },
  '1f563': {
    hexCodePoints: '1f563',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕣',
    version: 'E0.7',
    baseName: 'eight-thirty',
    fullName: 'eight-thirty',
    isBase: true,
    baseHex: '1f563',
    modifiers: null,
  },
  '1f558': {
    hexCodePoints: '1f558',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕘',
    version: 'E0.6',
    baseName: 'nine o’clock',
    fullName: 'nine o’clock',
    isBase: true,
    baseHex: '1f558',
    modifiers: null,
  },
  '1f564': {
    hexCodePoints: '1f564',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕤',
    version: 'E0.7',
    baseName: 'nine-thirty',
    fullName: 'nine-thirty',
    isBase: true,
    baseHex: '1f564',
    modifiers: null,
  },
  '1f559': {
    hexCodePoints: '1f559',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕙',
    version: 'E0.6',
    baseName: 'ten o’clock',
    fullName: 'ten o’clock',
    isBase: true,
    baseHex: '1f559',
    modifiers: null,
  },
  '1f565': {
    hexCodePoints: '1f565',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕥',
    version: 'E0.7',
    baseName: 'ten-thirty',
    fullName: 'ten-thirty',
    isBase: true,
    baseHex: '1f565',
    modifiers: null,
  },
  '1f55a': {
    hexCodePoints: '1f55a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕚',
    version: 'E0.6',
    baseName: 'eleven o’clock',
    fullName: 'eleven o’clock',
    isBase: true,
    baseHex: '1f55a',
    modifiers: null,
  },
  '1f566': {
    hexCodePoints: '1f566',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕦',
    version: 'E0.7',
    baseName: 'eleven-thirty',
    fullName: 'eleven-thirty',
    isBase: true,
    baseHex: '1f566',
    modifiers: null,
  },
  '1f311': {
    hexCodePoints: '1f311',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌑',
    version: 'E0.6',
    baseName: 'new moon',
    fullName: 'new moon',
    isBase: true,
    baseHex: '1f311',
    modifiers: null,
  },
  '1f312': {
    hexCodePoints: '1f312',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌒',
    version: 'E1.0',
    baseName: 'waxing crescent moon',
    fullName: 'waxing crescent moon',
    isBase: true,
    baseHex: '1f312',
    modifiers: null,
  },
  '1f313': {
    hexCodePoints: '1f313',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌓',
    version: 'E0.6',
    baseName: 'first quarter moon',
    fullName: 'first quarter moon',
    isBase: true,
    baseHex: '1f313',
    modifiers: null,
  },
  '1f314': {
    hexCodePoints: '1f314',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌔',
    version: 'E0.6',
    baseName: 'waxing gibbous moon',
    fullName: 'waxing gibbous moon',
    isBase: true,
    baseHex: '1f314',
    modifiers: null,
  },
  '1f315': {
    hexCodePoints: '1f315',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌕',
    version: 'E0.6',
    baseName: 'full moon',
    fullName: 'full moon',
    isBase: true,
    baseHex: '1f315',
    modifiers: null,
  },
  '1f316': {
    hexCodePoints: '1f316',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌖',
    version: 'E1.0',
    baseName: 'waning gibbous moon',
    fullName: 'waning gibbous moon',
    isBase: true,
    baseHex: '1f316',
    modifiers: null,
  },
  '1f317': {
    hexCodePoints: '1f317',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌗',
    version: 'E1.0',
    baseName: 'last quarter moon',
    fullName: 'last quarter moon',
    isBase: true,
    baseHex: '1f317',
    modifiers: null,
  },
  '1f318': {
    hexCodePoints: '1f318',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌘',
    version: 'E1.0',
    baseName: 'waning crescent moon',
    fullName: 'waning crescent moon',
    isBase: true,
    baseHex: '1f318',
    modifiers: null,
  },
  '1f319': {
    hexCodePoints: '1f319',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌙',
    version: 'E0.6',
    baseName: 'crescent moon',
    fullName: 'crescent moon',
    isBase: true,
    baseHex: '1f319',
    modifiers: null,
  },
  '1f31a': {
    hexCodePoints: '1f31a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌚',
    version: 'E1.0',
    baseName: 'new moon face',
    fullName: 'new moon face',
    isBase: true,
    baseHex: '1f31a',
    modifiers: null,
  },
  '1f31b': {
    hexCodePoints: '1f31b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌛',
    version: 'E0.6',
    baseName: 'first quarter moon face',
    fullName: 'first quarter moon face',
    isBase: true,
    baseHex: '1f31b',
    modifiers: null,
  },
  '1f31c': {
    hexCodePoints: '1f31c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌜',
    version: 'E0.7',
    baseName: 'last quarter moon face',
    fullName: 'last quarter moon face',
    isBase: true,
    baseHex: '1f31c',
    modifiers: null,
  },
  '1f321-fe0f': {
    hexCodePoints: '1f321-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌡️',
    version: 'E0.7',
    baseName: 'thermometer',
    fullName: 'thermometer',
    isBase: true,
    baseHex: '1f321-fe0f',
    modifiers: null,
  },
  '1f321': {
    hexCodePoints: '1f321',
    qualifiedStatus: 'unqualified',
    emoji: '🌡',
    version: 'E0.7',
    baseName: 'thermometer',
    fullName: 'thermometer',
    isBase: false,
    baseHex: '1f321-fe0f',
    modifiers: null,
  },
  '2600-fe0f': {
    hexCodePoints: '2600-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☀️',
    version: 'E0.6',
    baseName: 'sun',
    fullName: 'sun',
    isBase: true,
    baseHex: '2600-fe0f',
    modifiers: null,
  },
  '2600': {
    hexCodePoints: '2600',
    qualifiedStatus: 'unqualified',
    emoji: '☀',
    version: 'E0.6',
    baseName: 'sun',
    fullName: 'sun',
    isBase: false,
    baseHex: '2600-fe0f',
    modifiers: null,
  },
  '1f31d': {
    hexCodePoints: '1f31d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌝',
    version: 'E1.0',
    baseName: 'full moon face',
    fullName: 'full moon face',
    isBase: true,
    baseHex: '1f31d',
    modifiers: null,
  },
  '1f31e': {
    hexCodePoints: '1f31e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌞',
    version: 'E1.0',
    baseName: 'sun with face',
    fullName: 'sun with face',
    isBase: true,
    baseHex: '1f31e',
    modifiers: null,
  },
  '1fa90': {
    hexCodePoints: '1fa90',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪐',
    version: 'E12.0',
    baseName: 'ringed planet',
    fullName: 'ringed planet',
    isBase: true,
    baseHex: '1fa90',
    modifiers: null,
  },
  '2b50': {
    hexCodePoints: '2b50',
    qualifiedStatus: 'fully-qualified',
    emoji: '⭐',
    version: 'E0.6',
    baseName: 'star',
    fullName: 'star',
    isBase: true,
    baseHex: '2b50',
    modifiers: null,
  },
  '1f31f': {
    hexCodePoints: '1f31f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌟',
    version: 'E0.6',
    baseName: 'glowing star',
    fullName: 'glowing star',
    isBase: true,
    baseHex: '1f31f',
    modifiers: null,
  },
  '1f320': {
    hexCodePoints: '1f320',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌠',
    version: 'E0.6',
    baseName: 'shooting star',
    fullName: 'shooting star',
    isBase: true,
    baseHex: '1f320',
    modifiers: null,
  },
  '1f30c': {
    hexCodePoints: '1f30c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌌',
    version: 'E0.6',
    baseName: 'milky way',
    fullName: 'milky way',
    isBase: true,
    baseHex: '1f30c',
    modifiers: null,
  },
  '2601-fe0f': {
    hexCodePoints: '2601-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☁️',
    version: 'E0.6',
    baseName: 'cloud',
    fullName: 'cloud',
    isBase: true,
    baseHex: '2601-fe0f',
    modifiers: null,
  },
  '2601': {
    hexCodePoints: '2601',
    qualifiedStatus: 'unqualified',
    emoji: '☁',
    version: 'E0.6',
    baseName: 'cloud',
    fullName: 'cloud',
    isBase: false,
    baseHex: '2601-fe0f',
    modifiers: null,
  },
  '26c5': {
    hexCodePoints: '26c5',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛅',
    version: 'E0.6',
    baseName: 'sun behind cloud',
    fullName: 'sun behind cloud',
    isBase: true,
    baseHex: '26c5',
    modifiers: null,
  },
  '26c8-fe0f': {
    hexCodePoints: '26c8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛈️',
    version: 'E0.7',
    baseName: 'cloud with lightning and rain',
    fullName: 'cloud with lightning and rain',
    isBase: true,
    baseHex: '26c8-fe0f',
    modifiers: null,
  },
  '26c8': {
    hexCodePoints: '26c8',
    qualifiedStatus: 'unqualified',
    emoji: '⛈',
    version: 'E0.7',
    baseName: 'cloud with lightning and rain',
    fullName: 'cloud with lightning and rain',
    isBase: false,
    baseHex: '26c8-fe0f',
    modifiers: null,
  },
  '1f324-fe0f': {
    hexCodePoints: '1f324-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌤️',
    version: 'E0.7',
    baseName: 'sun behind small cloud',
    fullName: 'sun behind small cloud',
    isBase: true,
    baseHex: '1f324-fe0f',
    modifiers: null,
  },
  '1f324': {
    hexCodePoints: '1f324',
    qualifiedStatus: 'unqualified',
    emoji: '🌤',
    version: 'E0.7',
    baseName: 'sun behind small cloud',
    fullName: 'sun behind small cloud',
    isBase: false,
    baseHex: '1f324-fe0f',
    modifiers: null,
  },
  '1f325-fe0f': {
    hexCodePoints: '1f325-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌥️',
    version: 'E0.7',
    baseName: 'sun behind large cloud',
    fullName: 'sun behind large cloud',
    isBase: true,
    baseHex: '1f325-fe0f',
    modifiers: null,
  },
  '1f325': {
    hexCodePoints: '1f325',
    qualifiedStatus: 'unqualified',
    emoji: '🌥',
    version: 'E0.7',
    baseName: 'sun behind large cloud',
    fullName: 'sun behind large cloud',
    isBase: false,
    baseHex: '1f325-fe0f',
    modifiers: null,
  },
  '1f326-fe0f': {
    hexCodePoints: '1f326-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌦️',
    version: 'E0.7',
    baseName: 'sun behind rain cloud',
    fullName: 'sun behind rain cloud',
    isBase: true,
    baseHex: '1f326-fe0f',
    modifiers: null,
  },
  '1f326': {
    hexCodePoints: '1f326',
    qualifiedStatus: 'unqualified',
    emoji: '🌦',
    version: 'E0.7',
    baseName: 'sun behind rain cloud',
    fullName: 'sun behind rain cloud',
    isBase: false,
    baseHex: '1f326-fe0f',
    modifiers: null,
  },
  '1f327-fe0f': {
    hexCodePoints: '1f327-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌧️',
    version: 'E0.7',
    baseName: 'cloud with rain',
    fullName: 'cloud with rain',
    isBase: true,
    baseHex: '1f327-fe0f',
    modifiers: null,
  },
  '1f327': {
    hexCodePoints: '1f327',
    qualifiedStatus: 'unqualified',
    emoji: '🌧',
    version: 'E0.7',
    baseName: 'cloud with rain',
    fullName: 'cloud with rain',
    isBase: false,
    baseHex: '1f327-fe0f',
    modifiers: null,
  },
  '1f328-fe0f': {
    hexCodePoints: '1f328-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌨️',
    version: 'E0.7',
    baseName: 'cloud with snow',
    fullName: 'cloud with snow',
    isBase: true,
    baseHex: '1f328-fe0f',
    modifiers: null,
  },
  '1f328': {
    hexCodePoints: '1f328',
    qualifiedStatus: 'unqualified',
    emoji: '🌨',
    version: 'E0.7',
    baseName: 'cloud with snow',
    fullName: 'cloud with snow',
    isBase: false,
    baseHex: '1f328-fe0f',
    modifiers: null,
  },
  '1f329-fe0f': {
    hexCodePoints: '1f329-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌩️',
    version: 'E0.7',
    baseName: 'cloud with lightning',
    fullName: 'cloud with lightning',
    isBase: true,
    baseHex: '1f329-fe0f',
    modifiers: null,
  },
  '1f329': {
    hexCodePoints: '1f329',
    qualifiedStatus: 'unqualified',
    emoji: '🌩',
    version: 'E0.7',
    baseName: 'cloud with lightning',
    fullName: 'cloud with lightning',
    isBase: false,
    baseHex: '1f329-fe0f',
    modifiers: null,
  },
  '1f32a-fe0f': {
    hexCodePoints: '1f32a-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌪️',
    version: 'E0.7',
    baseName: 'tornado',
    fullName: 'tornado',
    isBase: true,
    baseHex: '1f32a-fe0f',
    modifiers: null,
  },
  '1f32a': {
    hexCodePoints: '1f32a',
    qualifiedStatus: 'unqualified',
    emoji: '🌪',
    version: 'E0.7',
    baseName: 'tornado',
    fullName: 'tornado',
    isBase: false,
    baseHex: '1f32a-fe0f',
    modifiers: null,
  },
  '1f32b-fe0f': {
    hexCodePoints: '1f32b-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌫️',
    version: 'E0.7',
    baseName: 'fog',
    fullName: 'fog',
    isBase: true,
    baseHex: '1f32b-fe0f',
    modifiers: null,
  },
  '1f32b': {
    hexCodePoints: '1f32b',
    qualifiedStatus: 'unqualified',
    emoji: '🌫',
    version: 'E0.7',
    baseName: 'fog',
    fullName: 'fog',
    isBase: false,
    baseHex: '1f32b-fe0f',
    modifiers: null,
  },
  '1f32c-fe0f': {
    hexCodePoints: '1f32c-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌬️',
    version: 'E0.7',
    baseName: 'wind face',
    fullName: 'wind face',
    isBase: true,
    baseHex: '1f32c-fe0f',
    modifiers: null,
  },
  '1f32c': {
    hexCodePoints: '1f32c',
    qualifiedStatus: 'unqualified',
    emoji: '🌬',
    version: 'E0.7',
    baseName: 'wind face',
    fullName: 'wind face',
    isBase: false,
    baseHex: '1f32c-fe0f',
    modifiers: null,
  },
  '1f300': {
    hexCodePoints: '1f300',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌀',
    version: 'E0.6',
    baseName: 'cyclone',
    fullName: 'cyclone',
    isBase: true,
    baseHex: '1f300',
    modifiers: null,
  },
  '1f308': {
    hexCodePoints: '1f308',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌈',
    version: 'E0.6',
    baseName: 'rainbow',
    fullName: 'rainbow',
    isBase: true,
    baseHex: '1f308',
    modifiers: null,
  },
  '1f302': {
    hexCodePoints: '1f302',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌂',
    version: 'E0.6',
    baseName: 'closed umbrella',
    fullName: 'closed umbrella',
    isBase: true,
    baseHex: '1f302',
    modifiers: null,
  },
  '2602-fe0f': {
    hexCodePoints: '2602-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☂️',
    version: 'E0.7',
    baseName: 'umbrella',
    fullName: 'umbrella',
    isBase: true,
    baseHex: '2602-fe0f',
    modifiers: null,
  },
  '2602': {
    hexCodePoints: '2602',
    qualifiedStatus: 'unqualified',
    emoji: '☂',
    version: 'E0.7',
    baseName: 'umbrella',
    fullName: 'umbrella',
    isBase: false,
    baseHex: '2602-fe0f',
    modifiers: null,
  },
  '2614': {
    hexCodePoints: '2614',
    qualifiedStatus: 'fully-qualified',
    emoji: '☔',
    version: 'E0.6',
    baseName: 'umbrella with rain drops',
    fullName: 'umbrella with rain drops',
    isBase: true,
    baseHex: '2614',
    modifiers: null,
  },
  '26f1-fe0f': {
    hexCodePoints: '26f1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛱️',
    version: 'E0.7',
    baseName: 'umbrella on ground',
    fullName: 'umbrella on ground',
    isBase: true,
    baseHex: '26f1-fe0f',
    modifiers: null,
  },
  '26f1': {
    hexCodePoints: '26f1',
    qualifiedStatus: 'unqualified',
    emoji: '⛱',
    version: 'E0.7',
    baseName: 'umbrella on ground',
    fullName: 'umbrella on ground',
    isBase: false,
    baseHex: '26f1-fe0f',
    modifiers: null,
  },
  '26a1': {
    hexCodePoints: '26a1',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚡',
    version: 'E0.6',
    baseName: 'high voltage',
    fullName: 'high voltage',
    isBase: true,
    baseHex: '26a1',
    modifiers: null,
  },
  '2744-fe0f': {
    hexCodePoints: '2744-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '❄️',
    version: 'E0.6',
    baseName: 'snowflake',
    fullName: 'snowflake',
    isBase: true,
    baseHex: '2744-fe0f',
    modifiers: null,
  },
  '2744': {
    hexCodePoints: '2744',
    qualifiedStatus: 'unqualified',
    emoji: '❄',
    version: 'E0.6',
    baseName: 'snowflake',
    fullName: 'snowflake',
    isBase: false,
    baseHex: '2744-fe0f',
    modifiers: null,
  },
  '2603-fe0f': {
    hexCodePoints: '2603-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☃️',
    version: 'E0.7',
    baseName: 'snowman',
    fullName: 'snowman',
    isBase: true,
    baseHex: '2603-fe0f',
    modifiers: null,
  },
  '2603': {
    hexCodePoints: '2603',
    qualifiedStatus: 'unqualified',
    emoji: '☃',
    version: 'E0.7',
    baseName: 'snowman',
    fullName: 'snowman',
    isBase: false,
    baseHex: '2603-fe0f',
    modifiers: null,
  },
  '26c4': {
    hexCodePoints: '26c4',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛄',
    version: 'E0.6',
    baseName: 'snowman without snow',
    fullName: 'snowman without snow',
    isBase: true,
    baseHex: '26c4',
    modifiers: null,
  },
  '2604-fe0f': {
    hexCodePoints: '2604-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☄️',
    version: 'E1.0',
    baseName: 'comet',
    fullName: 'comet',
    isBase: true,
    baseHex: '2604-fe0f',
    modifiers: null,
  },
  '2604': {
    hexCodePoints: '2604',
    qualifiedStatus: 'unqualified',
    emoji: '☄',
    version: 'E1.0',
    baseName: 'comet',
    fullName: 'comet',
    isBase: false,
    baseHex: '2604-fe0f',
    modifiers: null,
  },
  '1f525': {
    hexCodePoints: '1f525',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔥',
    version: 'E0.6',
    baseName: 'fire',
    fullName: 'fire',
    isBase: true,
    baseHex: '1f525',
    modifiers: null,
  },
  '1f4a7': {
    hexCodePoints: '1f4a7',
    qualifiedStatus: 'fully-qualified',
    emoji: '💧',
    version: 'E0.6',
    baseName: 'droplet',
    fullName: 'droplet',
    isBase: true,
    baseHex: '1f4a7',
    modifiers: null,
  },
  '1f30a': {
    hexCodePoints: '1f30a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🌊',
    version: 'E0.6',
    baseName: 'water wave',
    fullName: 'water wave',
    isBase: true,
    baseHex: '1f30a',
    modifiers: null,
  },
  '1f383': {
    hexCodePoints: '1f383',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎃',
    version: 'E0.6',
    baseName: 'jack-o-lantern',
    fullName: 'jack-o-lantern',
    isBase: true,
    baseHex: '1f383',
    modifiers: null,
  },
  '1f384': {
    hexCodePoints: '1f384',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎄',
    version: 'E0.6',
    baseName: 'Christmas tree',
    fullName: 'Christmas tree',
    isBase: true,
    baseHex: '1f384',
    modifiers: null,
  },
  '1f386': {
    hexCodePoints: '1f386',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎆',
    version: 'E0.6',
    baseName: 'fireworks',
    fullName: 'fireworks',
    isBase: true,
    baseHex: '1f386',
    modifiers: null,
  },
  '1f387': {
    hexCodePoints: '1f387',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎇',
    version: 'E0.6',
    baseName: 'sparkler',
    fullName: 'sparkler',
    isBase: true,
    baseHex: '1f387',
    modifiers: null,
  },
  '1f9e8': {
    hexCodePoints: '1f9e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧨',
    version: 'E11.0',
    baseName: 'firecracker',
    fullName: 'firecracker',
    isBase: true,
    baseHex: '1f9e8',
    modifiers: null,
  },
  '2728': {
    hexCodePoints: '2728',
    qualifiedStatus: 'fully-qualified',
    emoji: '✨',
    version: 'E0.6',
    baseName: 'sparkles',
    fullName: 'sparkles',
    isBase: true,
    baseHex: '2728',
    modifiers: null,
  },
  '1f388': {
    hexCodePoints: '1f388',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎈',
    version: 'E0.6',
    baseName: 'balloon',
    fullName: 'balloon',
    isBase: true,
    baseHex: '1f388',
    modifiers: null,
  },
  '1f389': {
    hexCodePoints: '1f389',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎉',
    version: 'E0.6',
    baseName: 'party popper',
    fullName: 'party popper',
    isBase: true,
    baseHex: '1f389',
    modifiers: null,
  },
  '1f38a': {
    hexCodePoints: '1f38a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎊',
    version: 'E0.6',
    baseName: 'confetti ball',
    fullName: 'confetti ball',
    isBase: true,
    baseHex: '1f38a',
    modifiers: null,
  },
  '1f38b': {
    hexCodePoints: '1f38b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎋',
    version: 'E0.6',
    baseName: 'tanabata tree',
    fullName: 'tanabata tree',
    isBase: true,
    baseHex: '1f38b',
    modifiers: null,
  },
  '1f38d': {
    hexCodePoints: '1f38d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎍',
    version: 'E0.6',
    baseName: 'pine decoration',
    fullName: 'pine decoration',
    isBase: true,
    baseHex: '1f38d',
    modifiers: null,
  },
  '1f38e': {
    hexCodePoints: '1f38e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎎',
    version: 'E0.6',
    baseName: 'Japanese dolls',
    fullName: 'Japanese dolls',
    isBase: true,
    baseHex: '1f38e',
    modifiers: null,
  },
  '1f38f': {
    hexCodePoints: '1f38f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎏',
    version: 'E0.6',
    baseName: 'carp streamer',
    fullName: 'carp streamer',
    isBase: true,
    baseHex: '1f38f',
    modifiers: null,
  },
  '1f390': {
    hexCodePoints: '1f390',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎐',
    version: 'E0.6',
    baseName: 'wind chime',
    fullName: 'wind chime',
    isBase: true,
    baseHex: '1f390',
    modifiers: null,
  },
  '1f391': {
    hexCodePoints: '1f391',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎑',
    version: 'E0.6',
    baseName: 'moon viewing ceremony',
    fullName: 'moon viewing ceremony',
    isBase: true,
    baseHex: '1f391',
    modifiers: null,
  },
  '1f9e7': {
    hexCodePoints: '1f9e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧧',
    version: 'E11.0',
    baseName: 'red envelope',
    fullName: 'red envelope',
    isBase: true,
    baseHex: '1f9e7',
    modifiers: null,
  },
  '1f380': {
    hexCodePoints: '1f380',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎀',
    version: 'E0.6',
    baseName: 'ribbon',
    fullName: 'ribbon',
    isBase: true,
    baseHex: '1f380',
    modifiers: null,
  },
  '1f381': {
    hexCodePoints: '1f381',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎁',
    version: 'E0.6',
    baseName: 'wrapped gift',
    fullName: 'wrapped gift',
    isBase: true,
    baseHex: '1f381',
    modifiers: null,
  },
  '1f397-fe0f': {
    hexCodePoints: '1f397-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎗️',
    version: 'E0.7',
    baseName: 'reminder ribbon',
    fullName: 'reminder ribbon',
    isBase: true,
    baseHex: '1f397-fe0f',
    modifiers: null,
  },
  '1f397': {
    hexCodePoints: '1f397',
    qualifiedStatus: 'unqualified',
    emoji: '🎗',
    version: 'E0.7',
    baseName: 'reminder ribbon',
    fullName: 'reminder ribbon',
    isBase: false,
    baseHex: '1f397-fe0f',
    modifiers: null,
  },
  '1f39f-fe0f': {
    hexCodePoints: '1f39f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎟️',
    version: 'E0.7',
    baseName: 'admission tickets',
    fullName: 'admission tickets',
    isBase: true,
    baseHex: '1f39f-fe0f',
    modifiers: null,
  },
  '1f39f': {
    hexCodePoints: '1f39f',
    qualifiedStatus: 'unqualified',
    emoji: '🎟',
    version: 'E0.7',
    baseName: 'admission tickets',
    fullName: 'admission tickets',
    isBase: false,
    baseHex: '1f39f-fe0f',
    modifiers: null,
  },
  '1f3ab': {
    hexCodePoints: '1f3ab',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎫',
    version: 'E0.6',
    baseName: 'ticket',
    fullName: 'ticket',
    isBase: true,
    baseHex: '1f3ab',
    modifiers: null,
  },
  '1f396-fe0f': {
    hexCodePoints: '1f396-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎖️',
    version: 'E0.7',
    baseName: 'military medal',
    fullName: 'military medal',
    isBase: true,
    baseHex: '1f396-fe0f',
    modifiers: null,
  },
  '1f396': {
    hexCodePoints: '1f396',
    qualifiedStatus: 'unqualified',
    emoji: '🎖',
    version: 'E0.7',
    baseName: 'military medal',
    fullName: 'military medal',
    isBase: false,
    baseHex: '1f396-fe0f',
    modifiers: null,
  },
  '1f3c6': {
    hexCodePoints: '1f3c6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏆',
    version: 'E0.6',
    baseName: 'trophy',
    fullName: 'trophy',
    isBase: true,
    baseHex: '1f3c6',
    modifiers: null,
  },
  '1f3c5': {
    hexCodePoints: '1f3c5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏅',
    version: 'E1.0',
    baseName: 'sports medal',
    fullName: 'sports medal',
    isBase: true,
    baseHex: '1f3c5',
    modifiers: null,
  },
  '1f947': {
    hexCodePoints: '1f947',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥇',
    version: 'E3.0',
    baseName: '1st place medal',
    fullName: '1st place medal',
    isBase: true,
    baseHex: '1f947',
    modifiers: null,
  },
  '1f948': {
    hexCodePoints: '1f948',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥈',
    version: 'E3.0',
    baseName: '2nd place medal',
    fullName: '2nd place medal',
    isBase: true,
    baseHex: '1f948',
    modifiers: null,
  },
  '1f949': {
    hexCodePoints: '1f949',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥉',
    version: 'E3.0',
    baseName: '3rd place medal',
    fullName: '3rd place medal',
    isBase: true,
    baseHex: '1f949',
    modifiers: null,
  },
  '26bd': {
    hexCodePoints: '26bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚽',
    version: 'E0.6',
    baseName: 'soccer ball',
    fullName: 'soccer ball',
    isBase: true,
    baseHex: '26bd',
    modifiers: null,
  },
  '26be': {
    hexCodePoints: '26be',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚾',
    version: 'E0.6',
    baseName: 'baseball',
    fullName: 'baseball',
    isBase: true,
    baseHex: '26be',
    modifiers: null,
  },
  '1f94e': {
    hexCodePoints: '1f94e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥎',
    version: 'E11.0',
    baseName: 'softball',
    fullName: 'softball',
    isBase: true,
    baseHex: '1f94e',
    modifiers: null,
  },
  '1f3c0': {
    hexCodePoints: '1f3c0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏀',
    version: 'E0.6',
    baseName: 'basketball',
    fullName: 'basketball',
    isBase: true,
    baseHex: '1f3c0',
    modifiers: null,
  },
  '1f3d0': {
    hexCodePoints: '1f3d0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏐',
    version: 'E1.0',
    baseName: 'volleyball',
    fullName: 'volleyball',
    isBase: true,
    baseHex: '1f3d0',
    modifiers: null,
  },
  '1f3c8': {
    hexCodePoints: '1f3c8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏈',
    version: 'E0.6',
    baseName: 'american football',
    fullName: 'american football',
    isBase: true,
    baseHex: '1f3c8',
    modifiers: null,
  },
  '1f3c9': {
    hexCodePoints: '1f3c9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏉',
    version: 'E1.0',
    baseName: 'rugby football',
    fullName: 'rugby football',
    isBase: true,
    baseHex: '1f3c9',
    modifiers: null,
  },
  '1f3be': {
    hexCodePoints: '1f3be',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎾',
    version: 'E0.6',
    baseName: 'tennis',
    fullName: 'tennis',
    isBase: true,
    baseHex: '1f3be',
    modifiers: null,
  },
  '1f94f': {
    hexCodePoints: '1f94f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥏',
    version: 'E11.0',
    baseName: 'flying disc',
    fullName: 'flying disc',
    isBase: true,
    baseHex: '1f94f',
    modifiers: null,
  },
  '1f3b3': {
    hexCodePoints: '1f3b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎳',
    version: 'E0.6',
    baseName: 'bowling',
    fullName: 'bowling',
    isBase: true,
    baseHex: '1f3b3',
    modifiers: null,
  },
  '1f3cf': {
    hexCodePoints: '1f3cf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏏',
    version: 'E1.0',
    baseName: 'cricket game',
    fullName: 'cricket game',
    isBase: true,
    baseHex: '1f3cf',
    modifiers: null,
  },
  '1f3d1': {
    hexCodePoints: '1f3d1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏑',
    version: 'E1.0',
    baseName: 'field hockey',
    fullName: 'field hockey',
    isBase: true,
    baseHex: '1f3d1',
    modifiers: null,
  },
  '1f3d2': {
    hexCodePoints: '1f3d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏒',
    version: 'E1.0',
    baseName: 'ice hockey',
    fullName: 'ice hockey',
    isBase: true,
    baseHex: '1f3d2',
    modifiers: null,
  },
  '1f94d': {
    hexCodePoints: '1f94d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥍',
    version: 'E11.0',
    baseName: 'lacrosse',
    fullName: 'lacrosse',
    isBase: true,
    baseHex: '1f94d',
    modifiers: null,
  },
  '1f3d3': {
    hexCodePoints: '1f3d3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏓',
    version: 'E1.0',
    baseName: 'ping pong',
    fullName: 'ping pong',
    isBase: true,
    baseHex: '1f3d3',
    modifiers: null,
  },
  '1f3f8': {
    hexCodePoints: '1f3f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏸',
    version: 'E1.0',
    baseName: 'badminton',
    fullName: 'badminton',
    isBase: true,
    baseHex: '1f3f8',
    modifiers: null,
  },
  '1f94a': {
    hexCodePoints: '1f94a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥊',
    version: 'E3.0',
    baseName: 'boxing glove',
    fullName: 'boxing glove',
    isBase: true,
    baseHex: '1f94a',
    modifiers: null,
  },
  '1f94b': {
    hexCodePoints: '1f94b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥋',
    version: 'E3.0',
    baseName: 'martial arts uniform',
    fullName: 'martial arts uniform',
    isBase: true,
    baseHex: '1f94b',
    modifiers: null,
  },
  '1f945': {
    hexCodePoints: '1f945',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥅',
    version: 'E3.0',
    baseName: 'goal net',
    fullName: 'goal net',
    isBase: true,
    baseHex: '1f945',
    modifiers: null,
  },
  '26f3': {
    hexCodePoints: '26f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛳',
    version: 'E0.6',
    baseName: 'flag in hole',
    fullName: 'flag in hole',
    isBase: true,
    baseHex: '26f3',
    modifiers: null,
  },
  '26f8-fe0f': {
    hexCodePoints: '26f8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛸️',
    version: 'E0.7',
    baseName: 'ice skate',
    fullName: 'ice skate',
    isBase: true,
    baseHex: '26f8-fe0f',
    modifiers: null,
  },
  '26f8': {
    hexCodePoints: '26f8',
    qualifiedStatus: 'unqualified',
    emoji: '⛸',
    version: 'E0.7',
    baseName: 'ice skate',
    fullName: 'ice skate',
    isBase: false,
    baseHex: '26f8-fe0f',
    modifiers: null,
  },
  '1f3a3': {
    hexCodePoints: '1f3a3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎣',
    version: 'E0.6',
    baseName: 'fishing pole',
    fullName: 'fishing pole',
    isBase: true,
    baseHex: '1f3a3',
    modifiers: null,
  },
  '1f93f': {
    hexCodePoints: '1f93f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🤿',
    version: 'E12.0',
    baseName: 'diving mask',
    fullName: 'diving mask',
    isBase: true,
    baseHex: '1f93f',
    modifiers: null,
  },
  '1f3bd': {
    hexCodePoints: '1f3bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎽',
    version: 'E0.6',
    baseName: 'running shirt',
    fullName: 'running shirt',
    isBase: true,
    baseHex: '1f3bd',
    modifiers: null,
  },
  '1f3bf': {
    hexCodePoints: '1f3bf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎿',
    version: 'E0.6',
    baseName: 'skis',
    fullName: 'skis',
    isBase: true,
    baseHex: '1f3bf',
    modifiers: null,
  },
  '1f6f7': {
    hexCodePoints: '1f6f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛷',
    version: 'E5.0',
    baseName: 'sled',
    fullName: 'sled',
    isBase: true,
    baseHex: '1f6f7',
    modifiers: null,
  },
  '1f94c': {
    hexCodePoints: '1f94c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥌',
    version: 'E5.0',
    baseName: 'curling stone',
    fullName: 'curling stone',
    isBase: true,
    baseHex: '1f94c',
    modifiers: null,
  },
  '1f3af': {
    hexCodePoints: '1f3af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎯',
    version: 'E0.6',
    baseName: 'bullseye',
    fullName: 'bullseye',
    isBase: true,
    baseHex: '1f3af',
    modifiers: null,
  },
  '1fa80': {
    hexCodePoints: '1fa80',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪀',
    version: 'E12.0',
    baseName: 'yo-yo',
    fullName: 'yo-yo',
    isBase: true,
    baseHex: '1fa80',
    modifiers: null,
  },
  '1fa81': {
    hexCodePoints: '1fa81',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪁',
    version: 'E12.0',
    baseName: 'kite',
    fullName: 'kite',
    isBase: true,
    baseHex: '1fa81',
    modifiers: null,
  },
  '1f52b': {
    hexCodePoints: '1f52b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔫',
    version: 'E0.6',
    baseName: 'water pistol',
    fullName: 'water pistol',
    isBase: true,
    baseHex: '1f52b',
    modifiers: null,
  },
  '1f3b1': {
    hexCodePoints: '1f3b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎱',
    version: 'E0.6',
    baseName: 'pool 8 ball',
    fullName: 'pool 8 ball',
    isBase: true,
    baseHex: '1f3b1',
    modifiers: null,
  },
  '1f52e': {
    hexCodePoints: '1f52e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔮',
    version: 'E0.6',
    baseName: 'crystal ball',
    fullName: 'crystal ball',
    isBase: true,
    baseHex: '1f52e',
    modifiers: null,
  },
  '1fa84': {
    hexCodePoints: '1fa84',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪄',
    version: 'E13.0',
    baseName: 'magic wand',
    fullName: 'magic wand',
    isBase: true,
    baseHex: '1fa84',
    modifiers: null,
  },
  '1f3ae': {
    hexCodePoints: '1f3ae',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎮',
    version: 'E0.6',
    baseName: 'video game',
    fullName: 'video game',
    isBase: true,
    baseHex: '1f3ae',
    modifiers: null,
  },
  '1f579-fe0f': {
    hexCodePoints: '1f579-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕹️',
    version: 'E0.7',
    baseName: 'joystick',
    fullName: 'joystick',
    isBase: true,
    baseHex: '1f579-fe0f',
    modifiers: null,
  },
  '1f579': {
    hexCodePoints: '1f579',
    qualifiedStatus: 'unqualified',
    emoji: '🕹',
    version: 'E0.7',
    baseName: 'joystick',
    fullName: 'joystick',
    isBase: false,
    baseHex: '1f579-fe0f',
    modifiers: null,
  },
  '1f3b0': {
    hexCodePoints: '1f3b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎰',
    version: 'E0.6',
    baseName: 'slot machine',
    fullName: 'slot machine',
    isBase: true,
    baseHex: '1f3b0',
    modifiers: null,
  },
  '1f3b2': {
    hexCodePoints: '1f3b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎲',
    version: 'E0.6',
    baseName: 'game die',
    fullName: 'game die',
    isBase: true,
    baseHex: '1f3b2',
    modifiers: null,
  },
  '1f9e9': {
    hexCodePoints: '1f9e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧩',
    version: 'E11.0',
    baseName: 'puzzle piece',
    fullName: 'puzzle piece',
    isBase: true,
    baseHex: '1f9e9',
    modifiers: null,
  },
  '1f9f8': {
    hexCodePoints: '1f9f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧸',
    version: 'E11.0',
    baseName: 'teddy bear',
    fullName: 'teddy bear',
    isBase: true,
    baseHex: '1f9f8',
    modifiers: null,
  },
  '1fa85': {
    hexCodePoints: '1fa85',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪅',
    version: 'E13.0',
    baseName: 'piñata',
    fullName: 'piñata',
    isBase: true,
    baseHex: '1fa85',
    modifiers: null,
  },
  '1faa9': {
    hexCodePoints: '1faa9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪩',
    version: 'E14.0',
    baseName: 'mirror ball',
    fullName: 'mirror ball',
    isBase: true,
    baseHex: '1faa9',
    modifiers: null,
  },
  '1fa86': {
    hexCodePoints: '1fa86',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪆',
    version: 'E13.0',
    baseName: 'nesting dolls',
    fullName: 'nesting dolls',
    isBase: true,
    baseHex: '1fa86',
    modifiers: null,
  },
  '2660-fe0f': {
    hexCodePoints: '2660-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♠️',
    version: 'E0.6',
    baseName: 'spade suit',
    fullName: 'spade suit',
    isBase: true,
    baseHex: '2660-fe0f',
    modifiers: null,
  },
  '2660': {
    hexCodePoints: '2660',
    qualifiedStatus: 'unqualified',
    emoji: '♠',
    version: 'E0.6',
    baseName: 'spade suit',
    fullName: 'spade suit',
    isBase: false,
    baseHex: '2660-fe0f',
    modifiers: null,
  },
  '2665-fe0f': {
    hexCodePoints: '2665-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♥️',
    version: 'E0.6',
    baseName: 'heart suit',
    fullName: 'heart suit',
    isBase: true,
    baseHex: '2665-fe0f',
    modifiers: null,
  },
  '2665': {
    hexCodePoints: '2665',
    qualifiedStatus: 'unqualified',
    emoji: '♥',
    version: 'E0.6',
    baseName: 'heart suit',
    fullName: 'heart suit',
    isBase: false,
    baseHex: '2665-fe0f',
    modifiers: null,
  },
  '2666-fe0f': {
    hexCodePoints: '2666-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♦️',
    version: 'E0.6',
    baseName: 'diamond suit',
    fullName: 'diamond suit',
    isBase: true,
    baseHex: '2666-fe0f',
    modifiers: null,
  },
  '2666': {
    hexCodePoints: '2666',
    qualifiedStatus: 'unqualified',
    emoji: '♦',
    version: 'E0.6',
    baseName: 'diamond suit',
    fullName: 'diamond suit',
    isBase: false,
    baseHex: '2666-fe0f',
    modifiers: null,
  },
  '2663-fe0f': {
    hexCodePoints: '2663-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♣️',
    version: 'E0.6',
    baseName: 'club suit',
    fullName: 'club suit',
    isBase: true,
    baseHex: '2663-fe0f',
    modifiers: null,
  },
  '2663': {
    hexCodePoints: '2663',
    qualifiedStatus: 'unqualified',
    emoji: '♣',
    version: 'E0.6',
    baseName: 'club suit',
    fullName: 'club suit',
    isBase: false,
    baseHex: '2663-fe0f',
    modifiers: null,
  },
  '265f-fe0f': {
    hexCodePoints: '265f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♟️',
    version: 'E11.0',
    baseName: 'chess pawn',
    fullName: 'chess pawn',
    isBase: true,
    baseHex: '265f-fe0f',
    modifiers: null,
  },
  '265f': {
    hexCodePoints: '265f',
    qualifiedStatus: 'unqualified',
    emoji: '♟',
    version: 'E11.0',
    baseName: 'chess pawn',
    fullName: 'chess pawn',
    isBase: false,
    baseHex: '265f-fe0f',
    modifiers: null,
  },
  '1f0cf': {
    hexCodePoints: '1f0cf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🃏',
    version: 'E0.6',
    baseName: 'joker',
    fullName: 'joker',
    isBase: true,
    baseHex: '1f0cf',
    modifiers: null,
  },
  '1f004': {
    hexCodePoints: '1f004',
    qualifiedStatus: 'fully-qualified',
    emoji: '🀄',
    version: 'E0.6',
    baseName: 'mahjong red dragon',
    fullName: 'mahjong red dragon',
    isBase: true,
    baseHex: '1f004',
    modifiers: null,
  },
  '1f3b4': {
    hexCodePoints: '1f3b4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎴',
    version: 'E0.6',
    baseName: 'flower playing cards',
    fullName: 'flower playing cards',
    isBase: true,
    baseHex: '1f3b4',
    modifiers: null,
  },
  '1f3ad': {
    hexCodePoints: '1f3ad',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎭',
    version: 'E0.6',
    baseName: 'performing arts',
    fullName: 'performing arts',
    isBase: true,
    baseHex: '1f3ad',
    modifiers: null,
  },
  '1f5bc-fe0f': {
    hexCodePoints: '1f5bc-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖼️',
    version: 'E0.7',
    baseName: 'framed picture',
    fullName: 'framed picture',
    isBase: true,
    baseHex: '1f5bc-fe0f',
    modifiers: null,
  },
  '1f5bc': {
    hexCodePoints: '1f5bc',
    qualifiedStatus: 'unqualified',
    emoji: '🖼',
    version: 'E0.7',
    baseName: 'framed picture',
    fullName: 'framed picture',
    isBase: false,
    baseHex: '1f5bc-fe0f',
    modifiers: null,
  },
  '1f3a8': {
    hexCodePoints: '1f3a8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎨',
    version: 'E0.6',
    baseName: 'artist palette',
    fullName: 'artist palette',
    isBase: true,
    baseHex: '1f3a8',
    modifiers: null,
  },
  '1f9f5': {
    hexCodePoints: '1f9f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧵',
    version: 'E11.0',
    baseName: 'thread',
    fullName: 'thread',
    isBase: true,
    baseHex: '1f9f5',
    modifiers: null,
  },
  '1faa1': {
    hexCodePoints: '1faa1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪡',
    version: 'E13.0',
    baseName: 'sewing needle',
    fullName: 'sewing needle',
    isBase: true,
    baseHex: '1faa1',
    modifiers: null,
  },
  '1f9f6': {
    hexCodePoints: '1f9f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧶',
    version: 'E11.0',
    baseName: 'yarn',
    fullName: 'yarn',
    isBase: true,
    baseHex: '1f9f6',
    modifiers: null,
  },
  '1faa2': {
    hexCodePoints: '1faa2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪢',
    version: 'E13.0',
    baseName: 'knot',
    fullName: 'knot',
    isBase: true,
    baseHex: '1faa2',
    modifiers: null,
  },
  '1f453': {
    hexCodePoints: '1f453',
    qualifiedStatus: 'fully-qualified',
    emoji: '👓',
    version: 'E0.6',
    baseName: 'glasses',
    fullName: 'glasses',
    isBase: true,
    baseHex: '1f453',
    modifiers: null,
  },
  '1f576-fe0f': {
    hexCodePoints: '1f576-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕶️',
    version: 'E0.7',
    baseName: 'sunglasses',
    fullName: 'sunglasses',
    isBase: true,
    baseHex: '1f576-fe0f',
    modifiers: null,
  },
  '1f576': {
    hexCodePoints: '1f576',
    qualifiedStatus: 'unqualified',
    emoji: '🕶',
    version: 'E0.7',
    baseName: 'sunglasses',
    fullName: 'sunglasses',
    isBase: false,
    baseHex: '1f576-fe0f',
    modifiers: null,
  },
  '1f97d': {
    hexCodePoints: '1f97d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥽',
    version: 'E11.0',
    baseName: 'goggles',
    fullName: 'goggles',
    isBase: true,
    baseHex: '1f97d',
    modifiers: null,
  },
  '1f97c': {
    hexCodePoints: '1f97c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥼',
    version: 'E11.0',
    baseName: 'lab coat',
    fullName: 'lab coat',
    isBase: true,
    baseHex: '1f97c',
    modifiers: null,
  },
  '1f9ba': {
    hexCodePoints: '1f9ba',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦺',
    version: 'E12.0',
    baseName: 'safety vest',
    fullName: 'safety vest',
    isBase: true,
    baseHex: '1f9ba',
    modifiers: null,
  },
  '1f454': {
    hexCodePoints: '1f454',
    qualifiedStatus: 'fully-qualified',
    emoji: '👔',
    version: 'E0.6',
    baseName: 'necktie',
    fullName: 'necktie',
    isBase: true,
    baseHex: '1f454',
    modifiers: null,
  },
  '1f455': {
    hexCodePoints: '1f455',
    qualifiedStatus: 'fully-qualified',
    emoji: '👕',
    version: 'E0.6',
    baseName: 't-shirt',
    fullName: 't-shirt',
    isBase: true,
    baseHex: '1f455',
    modifiers: null,
  },
  '1f456': {
    hexCodePoints: '1f456',
    qualifiedStatus: 'fully-qualified',
    emoji: '👖',
    version: 'E0.6',
    baseName: 'jeans',
    fullName: 'jeans',
    isBase: true,
    baseHex: '1f456',
    modifiers: null,
  },
  '1f9e3': {
    hexCodePoints: '1f9e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧣',
    version: 'E5.0',
    baseName: 'scarf',
    fullName: 'scarf',
    isBase: true,
    baseHex: '1f9e3',
    modifiers: null,
  },
  '1f9e4': {
    hexCodePoints: '1f9e4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧤',
    version: 'E5.0',
    baseName: 'gloves',
    fullName: 'gloves',
    isBase: true,
    baseHex: '1f9e4',
    modifiers: null,
  },
  '1f9e5': {
    hexCodePoints: '1f9e5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧥',
    version: 'E5.0',
    baseName: 'coat',
    fullName: 'coat',
    isBase: true,
    baseHex: '1f9e5',
    modifiers: null,
  },
  '1f9e6': {
    hexCodePoints: '1f9e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧦',
    version: 'E5.0',
    baseName: 'socks',
    fullName: 'socks',
    isBase: true,
    baseHex: '1f9e6',
    modifiers: null,
  },
  '1f457': {
    hexCodePoints: '1f457',
    qualifiedStatus: 'fully-qualified',
    emoji: '👗',
    version: 'E0.6',
    baseName: 'dress',
    fullName: 'dress',
    isBase: true,
    baseHex: '1f457',
    modifiers: null,
  },
  '1f458': {
    hexCodePoints: '1f458',
    qualifiedStatus: 'fully-qualified',
    emoji: '👘',
    version: 'E0.6',
    baseName: 'kimono',
    fullName: 'kimono',
    isBase: true,
    baseHex: '1f458',
    modifiers: null,
  },
  '1f97b': {
    hexCodePoints: '1f97b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥻',
    version: 'E12.0',
    baseName: 'sari',
    fullName: 'sari',
    isBase: true,
    baseHex: '1f97b',
    modifiers: null,
  },
  '1fa71': {
    hexCodePoints: '1fa71',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩱',
    version: 'E12.0',
    baseName: 'one-piece swimsuit',
    fullName: 'one-piece swimsuit',
    isBase: true,
    baseHex: '1fa71',
    modifiers: null,
  },
  '1fa72': {
    hexCodePoints: '1fa72',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩲',
    version: 'E12.0',
    baseName: 'briefs',
    fullName: 'briefs',
    isBase: true,
    baseHex: '1fa72',
    modifiers: null,
  },
  '1fa73': {
    hexCodePoints: '1fa73',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩳',
    version: 'E12.0',
    baseName: 'shorts',
    fullName: 'shorts',
    isBase: true,
    baseHex: '1fa73',
    modifiers: null,
  },
  '1f459': {
    hexCodePoints: '1f459',
    qualifiedStatus: 'fully-qualified',
    emoji: '👙',
    version: 'E0.6',
    baseName: 'bikini',
    fullName: 'bikini',
    isBase: true,
    baseHex: '1f459',
    modifiers: null,
  },
  '1f45a': {
    hexCodePoints: '1f45a',
    qualifiedStatus: 'fully-qualified',
    emoji: '👚',
    version: 'E0.6',
    baseName: 'woman’s clothes',
    fullName: 'woman’s clothes',
    isBase: true,
    baseHex: '1f45a',
    modifiers: null,
  },
  '1faad': {
    hexCodePoints: '1faad',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪭',
    version: 'E15.0',
    baseName: 'folding hand fan',
    fullName: 'folding hand fan',
    isBase: true,
    baseHex: '1faad',
    modifiers: null,
  },
  '1f45b': {
    hexCodePoints: '1f45b',
    qualifiedStatus: 'fully-qualified',
    emoji: '👛',
    version: 'E0.6',
    baseName: 'purse',
    fullName: 'purse',
    isBase: true,
    baseHex: '1f45b',
    modifiers: null,
  },
  '1f45c': {
    hexCodePoints: '1f45c',
    qualifiedStatus: 'fully-qualified',
    emoji: '👜',
    version: 'E0.6',
    baseName: 'handbag',
    fullName: 'handbag',
    isBase: true,
    baseHex: '1f45c',
    modifiers: null,
  },
  '1f45d': {
    hexCodePoints: '1f45d',
    qualifiedStatus: 'fully-qualified',
    emoji: '👝',
    version: 'E0.6',
    baseName: 'clutch bag',
    fullName: 'clutch bag',
    isBase: true,
    baseHex: '1f45d',
    modifiers: null,
  },
  '1f6cd-fe0f': {
    hexCodePoints: '1f6cd-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛍️',
    version: 'E0.7',
    baseName: 'shopping bags',
    fullName: 'shopping bags',
    isBase: true,
    baseHex: '1f6cd-fe0f',
    modifiers: null,
  },
  '1f6cd': {
    hexCodePoints: '1f6cd',
    qualifiedStatus: 'unqualified',
    emoji: '🛍',
    version: 'E0.7',
    baseName: 'shopping bags',
    fullName: 'shopping bags',
    isBase: false,
    baseHex: '1f6cd-fe0f',
    modifiers: null,
  },
  '1f392': {
    hexCodePoints: '1f392',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎒',
    version: 'E0.6',
    baseName: 'backpack',
    fullName: 'backpack',
    isBase: true,
    baseHex: '1f392',
    modifiers: null,
  },
  '1fa74': {
    hexCodePoints: '1fa74',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩴',
    version: 'E13.0',
    baseName: 'thong sandal',
    fullName: 'thong sandal',
    isBase: true,
    baseHex: '1fa74',
    modifiers: null,
  },
  '1f45e': {
    hexCodePoints: '1f45e',
    qualifiedStatus: 'fully-qualified',
    emoji: '👞',
    version: 'E0.6',
    baseName: 'man’s shoe',
    fullName: 'man’s shoe',
    isBase: true,
    baseHex: '1f45e',
    modifiers: null,
  },
  '1f45f': {
    hexCodePoints: '1f45f',
    qualifiedStatus: 'fully-qualified',
    emoji: '👟',
    version: 'E0.6',
    baseName: 'running shoe',
    fullName: 'running shoe',
    isBase: true,
    baseHex: '1f45f',
    modifiers: null,
  },
  '1f97e': {
    hexCodePoints: '1f97e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥾',
    version: 'E11.0',
    baseName: 'hiking boot',
    fullName: 'hiking boot',
    isBase: true,
    baseHex: '1f97e',
    modifiers: null,
  },
  '1f97f': {
    hexCodePoints: '1f97f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥿',
    version: 'E11.0',
    baseName: 'flat shoe',
    fullName: 'flat shoe',
    isBase: true,
    baseHex: '1f97f',
    modifiers: null,
  },
  '1f460': {
    hexCodePoints: '1f460',
    qualifiedStatus: 'fully-qualified',
    emoji: '👠',
    version: 'E0.6',
    baseName: 'high-heeled shoe',
    fullName: 'high-heeled shoe',
    isBase: true,
    baseHex: '1f460',
    modifiers: null,
  },
  '1f461': {
    hexCodePoints: '1f461',
    qualifiedStatus: 'fully-qualified',
    emoji: '👡',
    version: 'E0.6',
    baseName: 'woman’s sandal',
    fullName: 'woman’s sandal',
    isBase: true,
    baseHex: '1f461',
    modifiers: null,
  },
  '1fa70': {
    hexCodePoints: '1fa70',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩰',
    version: 'E12.0',
    baseName: 'ballet shoes',
    fullName: 'ballet shoes',
    isBase: true,
    baseHex: '1fa70',
    modifiers: null,
  },
  '1f462': {
    hexCodePoints: '1f462',
    qualifiedStatus: 'fully-qualified',
    emoji: '👢',
    version: 'E0.6',
    baseName: 'woman’s boot',
    fullName: 'woman’s boot',
    isBase: true,
    baseHex: '1f462',
    modifiers: null,
  },
  '1faae': {
    hexCodePoints: '1faae',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪮',
    version: 'E15.0',
    baseName: 'hair pick',
    fullName: 'hair pick',
    isBase: true,
    baseHex: '1faae',
    modifiers: null,
  },
  '1f451': {
    hexCodePoints: '1f451',
    qualifiedStatus: 'fully-qualified',
    emoji: '👑',
    version: 'E0.6',
    baseName: 'crown',
    fullName: 'crown',
    isBase: true,
    baseHex: '1f451',
    modifiers: null,
  },
  '1f452': {
    hexCodePoints: '1f452',
    qualifiedStatus: 'fully-qualified',
    emoji: '👒',
    version: 'E0.6',
    baseName: 'woman’s hat',
    fullName: 'woman’s hat',
    isBase: true,
    baseHex: '1f452',
    modifiers: null,
  },
  '1f3a9': {
    hexCodePoints: '1f3a9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎩',
    version: 'E0.6',
    baseName: 'top hat',
    fullName: 'top hat',
    isBase: true,
    baseHex: '1f3a9',
    modifiers: null,
  },
  '1f393': {
    hexCodePoints: '1f393',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎓',
    version: 'E0.6',
    baseName: 'graduation cap',
    fullName: 'graduation cap',
    isBase: true,
    baseHex: '1f393',
    modifiers: null,
  },
  '1f9e2': {
    hexCodePoints: '1f9e2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧢',
    version: 'E5.0',
    baseName: 'billed cap',
    fullName: 'billed cap',
    isBase: true,
    baseHex: '1f9e2',
    modifiers: null,
  },
  '1fa96': {
    hexCodePoints: '1fa96',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪖',
    version: 'E13.0',
    baseName: 'military helmet',
    fullName: 'military helmet',
    isBase: true,
    baseHex: '1fa96',
    modifiers: null,
  },
  '26d1-fe0f': {
    hexCodePoints: '26d1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛑️',
    version: 'E0.7',
    baseName: 'rescue worker’s helmet',
    fullName: 'rescue worker’s helmet',
    isBase: true,
    baseHex: '26d1-fe0f',
    modifiers: null,
  },
  '26d1': {
    hexCodePoints: '26d1',
    qualifiedStatus: 'unqualified',
    emoji: '⛑',
    version: 'E0.7',
    baseName: 'rescue worker’s helmet',
    fullName: 'rescue worker’s helmet',
    isBase: false,
    baseHex: '26d1-fe0f',
    modifiers: null,
  },
  '1f4ff': {
    hexCodePoints: '1f4ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '📿',
    version: 'E1.0',
    baseName: 'prayer beads',
    fullName: 'prayer beads',
    isBase: true,
    baseHex: '1f4ff',
    modifiers: null,
  },
  '1f484': {
    hexCodePoints: '1f484',
    qualifiedStatus: 'fully-qualified',
    emoji: '💄',
    version: 'E0.6',
    baseName: 'lipstick',
    fullName: 'lipstick',
    isBase: true,
    baseHex: '1f484',
    modifiers: null,
  },
  '1f48d': {
    hexCodePoints: '1f48d',
    qualifiedStatus: 'fully-qualified',
    emoji: '💍',
    version: 'E0.6',
    baseName: 'ring',
    fullName: 'ring',
    isBase: true,
    baseHex: '1f48d',
    modifiers: null,
  },
  '1f48e': {
    hexCodePoints: '1f48e',
    qualifiedStatus: 'fully-qualified',
    emoji: '💎',
    version: 'E0.6',
    baseName: 'gem stone',
    fullName: 'gem stone',
    isBase: true,
    baseHex: '1f48e',
    modifiers: null,
  },
  '1f507': {
    hexCodePoints: '1f507',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔇',
    version: 'E1.0',
    baseName: 'muted speaker',
    fullName: 'muted speaker',
    isBase: true,
    baseHex: '1f507',
    modifiers: null,
  },
  '1f508': {
    hexCodePoints: '1f508',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔈',
    version: 'E0.7',
    baseName: 'speaker low volume',
    fullName: 'speaker low volume',
    isBase: true,
    baseHex: '1f508',
    modifiers: null,
  },
  '1f509': {
    hexCodePoints: '1f509',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔉',
    version: 'E1.0',
    baseName: 'speaker medium volume',
    fullName: 'speaker medium volume',
    isBase: true,
    baseHex: '1f509',
    modifiers: null,
  },
  '1f50a': {
    hexCodePoints: '1f50a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔊',
    version: 'E0.6',
    baseName: 'speaker high volume',
    fullName: 'speaker high volume',
    isBase: true,
    baseHex: '1f50a',
    modifiers: null,
  },
  '1f4e2': {
    hexCodePoints: '1f4e2',
    qualifiedStatus: 'fully-qualified',
    emoji: '📢',
    version: 'E0.6',
    baseName: 'loudspeaker',
    fullName: 'loudspeaker',
    isBase: true,
    baseHex: '1f4e2',
    modifiers: null,
  },
  '1f4e3': {
    hexCodePoints: '1f4e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '📣',
    version: 'E0.6',
    baseName: 'megaphone',
    fullName: 'megaphone',
    isBase: true,
    baseHex: '1f4e3',
    modifiers: null,
  },
  '1f4ef': {
    hexCodePoints: '1f4ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '📯',
    version: 'E1.0',
    baseName: 'postal horn',
    fullName: 'postal horn',
    isBase: true,
    baseHex: '1f4ef',
    modifiers: null,
  },
  '1f514': {
    hexCodePoints: '1f514',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔔',
    version: 'E0.6',
    baseName: 'bell',
    fullName: 'bell',
    isBase: true,
    baseHex: '1f514',
    modifiers: null,
  },
  '1f515': {
    hexCodePoints: '1f515',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔕',
    version: 'E1.0',
    baseName: 'bell with slash',
    fullName: 'bell with slash',
    isBase: true,
    baseHex: '1f515',
    modifiers: null,
  },
  '1f3bc': {
    hexCodePoints: '1f3bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎼',
    version: 'E0.6',
    baseName: 'musical score',
    fullName: 'musical score',
    isBase: true,
    baseHex: '1f3bc',
    modifiers: null,
  },
  '1f3b5': {
    hexCodePoints: '1f3b5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎵',
    version: 'E0.6',
    baseName: 'musical note',
    fullName: 'musical note',
    isBase: true,
    baseHex: '1f3b5',
    modifiers: null,
  },
  '1f3b6': {
    hexCodePoints: '1f3b6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎶',
    version: 'E0.6',
    baseName: 'musical notes',
    fullName: 'musical notes',
    isBase: true,
    baseHex: '1f3b6',
    modifiers: null,
  },
  '1f399-fe0f': {
    hexCodePoints: '1f399-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎙️',
    version: 'E0.7',
    baseName: 'studio microphone',
    fullName: 'studio microphone',
    isBase: true,
    baseHex: '1f399-fe0f',
    modifiers: null,
  },
  '1f399': {
    hexCodePoints: '1f399',
    qualifiedStatus: 'unqualified',
    emoji: '🎙',
    version: 'E0.7',
    baseName: 'studio microphone',
    fullName: 'studio microphone',
    isBase: false,
    baseHex: '1f399-fe0f',
    modifiers: null,
  },
  '1f39a-fe0f': {
    hexCodePoints: '1f39a-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎚️',
    version: 'E0.7',
    baseName: 'level slider',
    fullName: 'level slider',
    isBase: true,
    baseHex: '1f39a-fe0f',
    modifiers: null,
  },
  '1f39a': {
    hexCodePoints: '1f39a',
    qualifiedStatus: 'unqualified',
    emoji: '🎚',
    version: 'E0.7',
    baseName: 'level slider',
    fullName: 'level slider',
    isBase: false,
    baseHex: '1f39a-fe0f',
    modifiers: null,
  },
  '1f39b-fe0f': {
    hexCodePoints: '1f39b-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎛️',
    version: 'E0.7',
    baseName: 'control knobs',
    fullName: 'control knobs',
    isBase: true,
    baseHex: '1f39b-fe0f',
    modifiers: null,
  },
  '1f39b': {
    hexCodePoints: '1f39b',
    qualifiedStatus: 'unqualified',
    emoji: '🎛',
    version: 'E0.7',
    baseName: 'control knobs',
    fullName: 'control knobs',
    isBase: false,
    baseHex: '1f39b-fe0f',
    modifiers: null,
  },
  '1f3a4': {
    hexCodePoints: '1f3a4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎤',
    version: 'E0.6',
    baseName: 'microphone',
    fullName: 'microphone',
    isBase: true,
    baseHex: '1f3a4',
    modifiers: null,
  },
  '1f3a7': {
    hexCodePoints: '1f3a7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎧',
    version: 'E0.6',
    baseName: 'headphone',
    fullName: 'headphone',
    isBase: true,
    baseHex: '1f3a7',
    modifiers: null,
  },
  '1f4fb': {
    hexCodePoints: '1f4fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '📻',
    version: 'E0.6',
    baseName: 'radio',
    fullName: 'radio',
    isBase: true,
    baseHex: '1f4fb',
    modifiers: null,
  },
  '1f3b7': {
    hexCodePoints: '1f3b7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎷',
    version: 'E0.6',
    baseName: 'saxophone',
    fullName: 'saxophone',
    isBase: true,
    baseHex: '1f3b7',
    modifiers: null,
  },
  '1fa97': {
    hexCodePoints: '1fa97',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪗',
    version: 'E13.0',
    baseName: 'accordion',
    fullName: 'accordion',
    isBase: true,
    baseHex: '1fa97',
    modifiers: null,
  },
  '1f3b8': {
    hexCodePoints: '1f3b8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎸',
    version: 'E0.6',
    baseName: 'guitar',
    fullName: 'guitar',
    isBase: true,
    baseHex: '1f3b8',
    modifiers: null,
  },
  '1f3b9': {
    hexCodePoints: '1f3b9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎹',
    version: 'E0.6',
    baseName: 'musical keyboard',
    fullName: 'musical keyboard',
    isBase: true,
    baseHex: '1f3b9',
    modifiers: null,
  },
  '1f3ba': {
    hexCodePoints: '1f3ba',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎺',
    version: 'E0.6',
    baseName: 'trumpet',
    fullName: 'trumpet',
    isBase: true,
    baseHex: '1f3ba',
    modifiers: null,
  },
  '1f3bb': {
    hexCodePoints: '1f3bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎻',
    version: 'E0.6',
    baseName: 'violin',
    fullName: 'violin',
    isBase: true,
    baseHex: '1f3bb',
    modifiers: null,
  },
  '1fa95': {
    hexCodePoints: '1fa95',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪕',
    version: 'E12.0',
    baseName: 'banjo',
    fullName: 'banjo',
    isBase: true,
    baseHex: '1fa95',
    modifiers: null,
  },
  '1f941': {
    hexCodePoints: '1f941',
    qualifiedStatus: 'fully-qualified',
    emoji: '🥁',
    version: 'E3.0',
    baseName: 'drum',
    fullName: 'drum',
    isBase: true,
    baseHex: '1f941',
    modifiers: null,
  },
  '1fa98': {
    hexCodePoints: '1fa98',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪘',
    version: 'E13.0',
    baseName: 'long drum',
    fullName: 'long drum',
    isBase: true,
    baseHex: '1fa98',
    modifiers: null,
  },
  '1fa87': {
    hexCodePoints: '1fa87',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪇',
    version: 'E15.0',
    baseName: 'maracas',
    fullName: 'maracas',
    isBase: true,
    baseHex: '1fa87',
    modifiers: null,
  },
  '1fa88': {
    hexCodePoints: '1fa88',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪈',
    version: 'E15.0',
    baseName: 'flute',
    fullName: 'flute',
    isBase: true,
    baseHex: '1fa88',
    modifiers: null,
  },
  '1f4f1': {
    hexCodePoints: '1f4f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '📱',
    version: 'E0.6',
    baseName: 'mobile phone',
    fullName: 'mobile phone',
    isBase: true,
    baseHex: '1f4f1',
    modifiers: null,
  },
  '1f4f2': {
    hexCodePoints: '1f4f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '📲',
    version: 'E0.6',
    baseName: 'mobile phone with arrow',
    fullName: 'mobile phone with arrow',
    isBase: true,
    baseHex: '1f4f2',
    modifiers: null,
  },
  '260e-fe0f': {
    hexCodePoints: '260e-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☎️',
    version: 'E0.6',
    baseName: 'telephone',
    fullName: 'telephone',
    isBase: true,
    baseHex: '260e-fe0f',
    modifiers: null,
  },
  '260e': {
    hexCodePoints: '260e',
    qualifiedStatus: 'unqualified',
    emoji: '☎',
    version: 'E0.6',
    baseName: 'telephone',
    fullName: 'telephone',
    isBase: false,
    baseHex: '260e-fe0f',
    modifiers: null,
  },
  '1f4de': {
    hexCodePoints: '1f4de',
    qualifiedStatus: 'fully-qualified',
    emoji: '📞',
    version: 'E0.6',
    baseName: 'telephone receiver',
    fullName: 'telephone receiver',
    isBase: true,
    baseHex: '1f4de',
    modifiers: null,
  },
  '1f4df': {
    hexCodePoints: '1f4df',
    qualifiedStatus: 'fully-qualified',
    emoji: '📟',
    version: 'E0.6',
    baseName: 'pager',
    fullName: 'pager',
    isBase: true,
    baseHex: '1f4df',
    modifiers: null,
  },
  '1f4e0': {
    hexCodePoints: '1f4e0',
    qualifiedStatus: 'fully-qualified',
    emoji: '📠',
    version: 'E0.6',
    baseName: 'fax machine',
    fullName: 'fax machine',
    isBase: true,
    baseHex: '1f4e0',
    modifiers: null,
  },
  '1f50b': {
    hexCodePoints: '1f50b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔋',
    version: 'E0.6',
    baseName: 'battery',
    fullName: 'battery',
    isBase: true,
    baseHex: '1f50b',
    modifiers: null,
  },
  '1faab': {
    hexCodePoints: '1faab',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪫',
    version: 'E14.0',
    baseName: 'low battery',
    fullName: 'low battery',
    isBase: true,
    baseHex: '1faab',
    modifiers: null,
  },
  '1f50c': {
    hexCodePoints: '1f50c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔌',
    version: 'E0.6',
    baseName: 'electric plug',
    fullName: 'electric plug',
    isBase: true,
    baseHex: '1f50c',
    modifiers: null,
  },
  '1f4bb': {
    hexCodePoints: '1f4bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '💻',
    version: 'E0.6',
    baseName: 'laptop',
    fullName: 'laptop',
    isBase: true,
    baseHex: '1f4bb',
    modifiers: null,
  },
  '1f5a5-fe0f': {
    hexCodePoints: '1f5a5-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖥️',
    version: 'E0.7',
    baseName: 'desktop computer',
    fullName: 'desktop computer',
    isBase: true,
    baseHex: '1f5a5-fe0f',
    modifiers: null,
  },
  '1f5a5': {
    hexCodePoints: '1f5a5',
    qualifiedStatus: 'unqualified',
    emoji: '🖥',
    version: 'E0.7',
    baseName: 'desktop computer',
    fullName: 'desktop computer',
    isBase: false,
    baseHex: '1f5a5-fe0f',
    modifiers: null,
  },
  '1f5a8-fe0f': {
    hexCodePoints: '1f5a8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖨️',
    version: 'E0.7',
    baseName: 'printer',
    fullName: 'printer',
    isBase: true,
    baseHex: '1f5a8-fe0f',
    modifiers: null,
  },
  '1f5a8': {
    hexCodePoints: '1f5a8',
    qualifiedStatus: 'unqualified',
    emoji: '🖨',
    version: 'E0.7',
    baseName: 'printer',
    fullName: 'printer',
    isBase: false,
    baseHex: '1f5a8-fe0f',
    modifiers: null,
  },
  '2328-fe0f': {
    hexCodePoints: '2328-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⌨️',
    version: 'E1.0',
    baseName: 'keyboard',
    fullName: 'keyboard',
    isBase: true,
    baseHex: '2328-fe0f',
    modifiers: null,
  },
  '2328': {
    hexCodePoints: '2328',
    qualifiedStatus: 'unqualified',
    emoji: '⌨',
    version: 'E1.0',
    baseName: 'keyboard',
    fullName: 'keyboard',
    isBase: false,
    baseHex: '2328-fe0f',
    modifiers: null,
  },
  '1f5b1-fe0f': {
    hexCodePoints: '1f5b1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖱️',
    version: 'E0.7',
    baseName: 'computer mouse',
    fullName: 'computer mouse',
    isBase: true,
    baseHex: '1f5b1-fe0f',
    modifiers: null,
  },
  '1f5b1': {
    hexCodePoints: '1f5b1',
    qualifiedStatus: 'unqualified',
    emoji: '🖱',
    version: 'E0.7',
    baseName: 'computer mouse',
    fullName: 'computer mouse',
    isBase: false,
    baseHex: '1f5b1-fe0f',
    modifiers: null,
  },
  '1f5b2-fe0f': {
    hexCodePoints: '1f5b2-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖲️',
    version: 'E0.7',
    baseName: 'trackball',
    fullName: 'trackball',
    isBase: true,
    baseHex: '1f5b2-fe0f',
    modifiers: null,
  },
  '1f5b2': {
    hexCodePoints: '1f5b2',
    qualifiedStatus: 'unqualified',
    emoji: '🖲',
    version: 'E0.7',
    baseName: 'trackball',
    fullName: 'trackball',
    isBase: false,
    baseHex: '1f5b2-fe0f',
    modifiers: null,
  },
  '1f4bd': {
    hexCodePoints: '1f4bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '💽',
    version: 'E0.6',
    baseName: 'computer disk',
    fullName: 'computer disk',
    isBase: true,
    baseHex: '1f4bd',
    modifiers: null,
  },
  '1f4be': {
    hexCodePoints: '1f4be',
    qualifiedStatus: 'fully-qualified',
    emoji: '💾',
    version: 'E0.6',
    baseName: 'floppy disk',
    fullName: 'floppy disk',
    isBase: true,
    baseHex: '1f4be',
    modifiers: null,
  },
  '1f4bf': {
    hexCodePoints: '1f4bf',
    qualifiedStatus: 'fully-qualified',
    emoji: '💿',
    version: 'E0.6',
    baseName: 'optical disk',
    fullName: 'optical disk',
    isBase: true,
    baseHex: '1f4bf',
    modifiers: null,
  },
  '1f4c0': {
    hexCodePoints: '1f4c0',
    qualifiedStatus: 'fully-qualified',
    emoji: '📀',
    version: 'E0.6',
    baseName: 'dvd',
    fullName: 'dvd',
    isBase: true,
    baseHex: '1f4c0',
    modifiers: null,
  },
  '1f9ee': {
    hexCodePoints: '1f9ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧮',
    version: 'E11.0',
    baseName: 'abacus',
    fullName: 'abacus',
    isBase: true,
    baseHex: '1f9ee',
    modifiers: null,
  },
  '1f3a5': {
    hexCodePoints: '1f3a5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎥',
    version: 'E0.6',
    baseName: 'movie camera',
    fullName: 'movie camera',
    isBase: true,
    baseHex: '1f3a5',
    modifiers: null,
  },
  '1f39e-fe0f': {
    hexCodePoints: '1f39e-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎞️',
    version: 'E0.7',
    baseName: 'film frames',
    fullName: 'film frames',
    isBase: true,
    baseHex: '1f39e-fe0f',
    modifiers: null,
  },
  '1f39e': {
    hexCodePoints: '1f39e',
    qualifiedStatus: 'unqualified',
    emoji: '🎞',
    version: 'E0.7',
    baseName: 'film frames',
    fullName: 'film frames',
    isBase: false,
    baseHex: '1f39e-fe0f',
    modifiers: null,
  },
  '1f4fd-fe0f': {
    hexCodePoints: '1f4fd-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '📽️',
    version: 'E0.7',
    baseName: 'film projector',
    fullName: 'film projector',
    isBase: true,
    baseHex: '1f4fd-fe0f',
    modifiers: null,
  },
  '1f4fd': {
    hexCodePoints: '1f4fd',
    qualifiedStatus: 'unqualified',
    emoji: '📽',
    version: 'E0.7',
    baseName: 'film projector',
    fullName: 'film projector',
    isBase: false,
    baseHex: '1f4fd-fe0f',
    modifiers: null,
  },
  '1f3ac': {
    hexCodePoints: '1f3ac',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎬',
    version: 'E0.6',
    baseName: 'clapper board',
    fullName: 'clapper board',
    isBase: true,
    baseHex: '1f3ac',
    modifiers: null,
  },
  '1f4fa': {
    hexCodePoints: '1f4fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '📺',
    version: 'E0.6',
    baseName: 'television',
    fullName: 'television',
    isBase: true,
    baseHex: '1f4fa',
    modifiers: null,
  },
  '1f4f7': {
    hexCodePoints: '1f4f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '📷',
    version: 'E0.6',
    baseName: 'camera',
    fullName: 'camera',
    isBase: true,
    baseHex: '1f4f7',
    modifiers: null,
  },
  '1f4f8': {
    hexCodePoints: '1f4f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '📸',
    version: 'E1.0',
    baseName: 'camera with flash',
    fullName: 'camera with flash',
    isBase: true,
    baseHex: '1f4f8',
    modifiers: null,
  },
  '1f4f9': {
    hexCodePoints: '1f4f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '📹',
    version: 'E0.6',
    baseName: 'video camera',
    fullName: 'video camera',
    isBase: true,
    baseHex: '1f4f9',
    modifiers: null,
  },
  '1f4fc': {
    hexCodePoints: '1f4fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '📼',
    version: 'E0.6',
    baseName: 'videocassette',
    fullName: 'videocassette',
    isBase: true,
    baseHex: '1f4fc',
    modifiers: null,
  },
  '1f50d': {
    hexCodePoints: '1f50d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔍',
    version: 'E0.6',
    baseName: 'magnifying glass tilted left',
    fullName: 'magnifying glass tilted left',
    isBase: true,
    baseHex: '1f50d',
    modifiers: null,
  },
  '1f50e': {
    hexCodePoints: '1f50e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔎',
    version: 'E0.6',
    baseName: 'magnifying glass tilted right',
    fullName: 'magnifying glass tilted right',
    isBase: true,
    baseHex: '1f50e',
    modifiers: null,
  },
  '1f56f-fe0f': {
    hexCodePoints: '1f56f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕯️',
    version: 'E0.7',
    baseName: 'candle',
    fullName: 'candle',
    isBase: true,
    baseHex: '1f56f-fe0f',
    modifiers: null,
  },
  '1f56f': {
    hexCodePoints: '1f56f',
    qualifiedStatus: 'unqualified',
    emoji: '🕯',
    version: 'E0.7',
    baseName: 'candle',
    fullName: 'candle',
    isBase: false,
    baseHex: '1f56f-fe0f',
    modifiers: null,
  },
  '1f4a1': {
    hexCodePoints: '1f4a1',
    qualifiedStatus: 'fully-qualified',
    emoji: '💡',
    version: 'E0.6',
    baseName: 'light bulb',
    fullName: 'light bulb',
    isBase: true,
    baseHex: '1f4a1',
    modifiers: null,
  },
  '1f526': {
    hexCodePoints: '1f526',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔦',
    version: 'E0.6',
    baseName: 'flashlight',
    fullName: 'flashlight',
    isBase: true,
    baseHex: '1f526',
    modifiers: null,
  },
  '1f3ee': {
    hexCodePoints: '1f3ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏮',
    version: 'E0.6',
    baseName: 'red paper lantern',
    fullName: 'red paper lantern',
    isBase: true,
    baseHex: '1f3ee',
    modifiers: null,
  },
  '1fa94': {
    hexCodePoints: '1fa94',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪔',
    version: 'E12.0',
    baseName: 'diya lamp',
    fullName: 'diya lamp',
    isBase: true,
    baseHex: '1fa94',
    modifiers: null,
  },
  '1f4d4': {
    hexCodePoints: '1f4d4',
    qualifiedStatus: 'fully-qualified',
    emoji: '📔',
    version: 'E0.6',
    baseName: 'notebook with decorative cover',
    fullName: 'notebook with decorative cover',
    isBase: true,
    baseHex: '1f4d4',
    modifiers: null,
  },
  '1f4d5': {
    hexCodePoints: '1f4d5',
    qualifiedStatus: 'fully-qualified',
    emoji: '📕',
    version: 'E0.6',
    baseName: 'closed book',
    fullName: 'closed book',
    isBase: true,
    baseHex: '1f4d5',
    modifiers: null,
  },
  '1f4d6': {
    hexCodePoints: '1f4d6',
    qualifiedStatus: 'fully-qualified',
    emoji: '📖',
    version: 'E0.6',
    baseName: 'open book',
    fullName: 'open book',
    isBase: true,
    baseHex: '1f4d6',
    modifiers: null,
  },
  '1f4d7': {
    hexCodePoints: '1f4d7',
    qualifiedStatus: 'fully-qualified',
    emoji: '📗',
    version: 'E0.6',
    baseName: 'green book',
    fullName: 'green book',
    isBase: true,
    baseHex: '1f4d7',
    modifiers: null,
  },
  '1f4d8': {
    hexCodePoints: '1f4d8',
    qualifiedStatus: 'fully-qualified',
    emoji: '📘',
    version: 'E0.6',
    baseName: 'blue book',
    fullName: 'blue book',
    isBase: true,
    baseHex: '1f4d8',
    modifiers: null,
  },
  '1f4d9': {
    hexCodePoints: '1f4d9',
    qualifiedStatus: 'fully-qualified',
    emoji: '📙',
    version: 'E0.6',
    baseName: 'orange book',
    fullName: 'orange book',
    isBase: true,
    baseHex: '1f4d9',
    modifiers: null,
  },
  '1f4da': {
    hexCodePoints: '1f4da',
    qualifiedStatus: 'fully-qualified',
    emoji: '📚',
    version: 'E0.6',
    baseName: 'books',
    fullName: 'books',
    isBase: true,
    baseHex: '1f4da',
    modifiers: null,
  },
  '1f4d3': {
    hexCodePoints: '1f4d3',
    qualifiedStatus: 'fully-qualified',
    emoji: '📓',
    version: 'E0.6',
    baseName: 'notebook',
    fullName: 'notebook',
    isBase: true,
    baseHex: '1f4d3',
    modifiers: null,
  },
  '1f4d2': {
    hexCodePoints: '1f4d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '📒',
    version: 'E0.6',
    baseName: 'ledger',
    fullName: 'ledger',
    isBase: true,
    baseHex: '1f4d2',
    modifiers: null,
  },
  '1f4c3': {
    hexCodePoints: '1f4c3',
    qualifiedStatus: 'fully-qualified',
    emoji: '📃',
    version: 'E0.6',
    baseName: 'page with curl',
    fullName: 'page with curl',
    isBase: true,
    baseHex: '1f4c3',
    modifiers: null,
  },
  '1f4dc': {
    hexCodePoints: '1f4dc',
    qualifiedStatus: 'fully-qualified',
    emoji: '📜',
    version: 'E0.6',
    baseName: 'scroll',
    fullName: 'scroll',
    isBase: true,
    baseHex: '1f4dc',
    modifiers: null,
  },
  '1f4c4': {
    hexCodePoints: '1f4c4',
    qualifiedStatus: 'fully-qualified',
    emoji: '📄',
    version: 'E0.6',
    baseName: 'page facing up',
    fullName: 'page facing up',
    isBase: true,
    baseHex: '1f4c4',
    modifiers: null,
  },
  '1f4f0': {
    hexCodePoints: '1f4f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '📰',
    version: 'E0.6',
    baseName: 'newspaper',
    fullName: 'newspaper',
    isBase: true,
    baseHex: '1f4f0',
    modifiers: null,
  },
  '1f5de-fe0f': {
    hexCodePoints: '1f5de-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗞️',
    version: 'E0.7',
    baseName: 'rolled-up newspaper',
    fullName: 'rolled-up newspaper',
    isBase: true,
    baseHex: '1f5de-fe0f',
    modifiers: null,
  },
  '1f5de': {
    hexCodePoints: '1f5de',
    qualifiedStatus: 'unqualified',
    emoji: '🗞',
    version: 'E0.7',
    baseName: 'rolled-up newspaper',
    fullName: 'rolled-up newspaper',
    isBase: false,
    baseHex: '1f5de-fe0f',
    modifiers: null,
  },
  '1f4d1': {
    hexCodePoints: '1f4d1',
    qualifiedStatus: 'fully-qualified',
    emoji: '📑',
    version: 'E0.6',
    baseName: 'bookmark tabs',
    fullName: 'bookmark tabs',
    isBase: true,
    baseHex: '1f4d1',
    modifiers: null,
  },
  '1f516': {
    hexCodePoints: '1f516',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔖',
    version: 'E0.6',
    baseName: 'bookmark',
    fullName: 'bookmark',
    isBase: true,
    baseHex: '1f516',
    modifiers: null,
  },
  '1f3f7-fe0f': {
    hexCodePoints: '1f3f7-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏷️',
    version: 'E0.7',
    baseName: 'label',
    fullName: 'label',
    isBase: true,
    baseHex: '1f3f7-fe0f',
    modifiers: null,
  },
  '1f3f7': {
    hexCodePoints: '1f3f7',
    qualifiedStatus: 'unqualified',
    emoji: '🏷',
    version: 'E0.7',
    baseName: 'label',
    fullName: 'label',
    isBase: false,
    baseHex: '1f3f7-fe0f',
    modifiers: null,
  },
  '1f4b0': {
    hexCodePoints: '1f4b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '💰',
    version: 'E0.6',
    baseName: 'money bag',
    fullName: 'money bag',
    isBase: true,
    baseHex: '1f4b0',
    modifiers: null,
  },
  '1fa99': {
    hexCodePoints: '1fa99',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪙',
    version: 'E13.0',
    baseName: 'coin',
    fullName: 'coin',
    isBase: true,
    baseHex: '1fa99',
    modifiers: null,
  },
  '1f4b4': {
    hexCodePoints: '1f4b4',
    qualifiedStatus: 'fully-qualified',
    emoji: '💴',
    version: 'E0.6',
    baseName: 'yen banknote',
    fullName: 'yen banknote',
    isBase: true,
    baseHex: '1f4b4',
    modifiers: null,
  },
  '1f4b5': {
    hexCodePoints: '1f4b5',
    qualifiedStatus: 'fully-qualified',
    emoji: '💵',
    version: 'E0.6',
    baseName: 'dollar banknote',
    fullName: 'dollar banknote',
    isBase: true,
    baseHex: '1f4b5',
    modifiers: null,
  },
  '1f4b6': {
    hexCodePoints: '1f4b6',
    qualifiedStatus: 'fully-qualified',
    emoji: '💶',
    version: 'E1.0',
    baseName: 'euro banknote',
    fullName: 'euro banknote',
    isBase: true,
    baseHex: '1f4b6',
    modifiers: null,
  },
  '1f4b7': {
    hexCodePoints: '1f4b7',
    qualifiedStatus: 'fully-qualified',
    emoji: '💷',
    version: 'E1.0',
    baseName: 'pound banknote',
    fullName: 'pound banknote',
    isBase: true,
    baseHex: '1f4b7',
    modifiers: null,
  },
  '1f4b8': {
    hexCodePoints: '1f4b8',
    qualifiedStatus: 'fully-qualified',
    emoji: '💸',
    version: 'E0.6',
    baseName: 'money with wings',
    fullName: 'money with wings',
    isBase: true,
    baseHex: '1f4b8',
    modifiers: null,
  },
  '1f4b3': {
    hexCodePoints: '1f4b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '💳',
    version: 'E0.6',
    baseName: 'credit card',
    fullName: 'credit card',
    isBase: true,
    baseHex: '1f4b3',
    modifiers: null,
  },
  '1f9fe': {
    hexCodePoints: '1f9fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧾',
    version: 'E11.0',
    baseName: 'receipt',
    fullName: 'receipt',
    isBase: true,
    baseHex: '1f9fe',
    modifiers: null,
  },
  '1f4b9': {
    hexCodePoints: '1f4b9',
    qualifiedStatus: 'fully-qualified',
    emoji: '💹',
    version: 'E0.6',
    baseName: 'chart increasing with yen',
    fullName: 'chart increasing with yen',
    isBase: true,
    baseHex: '1f4b9',
    modifiers: null,
  },
  '2709-fe0f': {
    hexCodePoints: '2709-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✉️',
    version: 'E0.6',
    baseName: 'envelope',
    fullName: 'envelope',
    isBase: true,
    baseHex: '2709-fe0f',
    modifiers: null,
  },
  '2709': {
    hexCodePoints: '2709',
    qualifiedStatus: 'unqualified',
    emoji: '✉',
    version: 'E0.6',
    baseName: 'envelope',
    fullName: 'envelope',
    isBase: false,
    baseHex: '2709-fe0f',
    modifiers: null,
  },
  '1f4e7': {
    hexCodePoints: '1f4e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '📧',
    version: 'E0.6',
    baseName: 'e-mail',
    fullName: 'e-mail',
    isBase: true,
    baseHex: '1f4e7',
    modifiers: null,
  },
  '1f4e8': {
    hexCodePoints: '1f4e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '📨',
    version: 'E0.6',
    baseName: 'incoming envelope',
    fullName: 'incoming envelope',
    isBase: true,
    baseHex: '1f4e8',
    modifiers: null,
  },
  '1f4e9': {
    hexCodePoints: '1f4e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '📩',
    version: 'E0.6',
    baseName: 'envelope with arrow',
    fullName: 'envelope with arrow',
    isBase: true,
    baseHex: '1f4e9',
    modifiers: null,
  },
  '1f4e4': {
    hexCodePoints: '1f4e4',
    qualifiedStatus: 'fully-qualified',
    emoji: '📤',
    version: 'E0.6',
    baseName: 'outbox tray',
    fullName: 'outbox tray',
    isBase: true,
    baseHex: '1f4e4',
    modifiers: null,
  },
  '1f4e5': {
    hexCodePoints: '1f4e5',
    qualifiedStatus: 'fully-qualified',
    emoji: '📥',
    version: 'E0.6',
    baseName: 'inbox tray',
    fullName: 'inbox tray',
    isBase: true,
    baseHex: '1f4e5',
    modifiers: null,
  },
  '1f4e6': {
    hexCodePoints: '1f4e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '📦',
    version: 'E0.6',
    baseName: 'package',
    fullName: 'package',
    isBase: true,
    baseHex: '1f4e6',
    modifiers: null,
  },
  '1f4eb': {
    hexCodePoints: '1f4eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '📫',
    version: 'E0.6',
    baseName: 'closed mailbox with raised flag',
    fullName: 'closed mailbox with raised flag',
    isBase: true,
    baseHex: '1f4eb',
    modifiers: null,
  },
  '1f4ea': {
    hexCodePoints: '1f4ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '📪',
    version: 'E0.6',
    baseName: 'closed mailbox with lowered flag',
    fullName: 'closed mailbox with lowered flag',
    isBase: true,
    baseHex: '1f4ea',
    modifiers: null,
  },
  '1f4ec': {
    hexCodePoints: '1f4ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '📬',
    version: 'E0.7',
    baseName: 'open mailbox with raised flag',
    fullName: 'open mailbox with raised flag',
    isBase: true,
    baseHex: '1f4ec',
    modifiers: null,
  },
  '1f4ed': {
    hexCodePoints: '1f4ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '📭',
    version: 'E0.7',
    baseName: 'open mailbox with lowered flag',
    fullName: 'open mailbox with lowered flag',
    isBase: true,
    baseHex: '1f4ed',
    modifiers: null,
  },
  '1f4ee': {
    hexCodePoints: '1f4ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '📮',
    version: 'E0.6',
    baseName: 'postbox',
    fullName: 'postbox',
    isBase: true,
    baseHex: '1f4ee',
    modifiers: null,
  },
  '1f5f3-fe0f': {
    hexCodePoints: '1f5f3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗳️',
    version: 'E0.7',
    baseName: 'ballot box with ballot',
    fullName: 'ballot box with ballot',
    isBase: true,
    baseHex: '1f5f3-fe0f',
    modifiers: null,
  },
  '1f5f3': {
    hexCodePoints: '1f5f3',
    qualifiedStatus: 'unqualified',
    emoji: '🗳',
    version: 'E0.7',
    baseName: 'ballot box with ballot',
    fullName: 'ballot box with ballot',
    isBase: false,
    baseHex: '1f5f3-fe0f',
    modifiers: null,
  },
  '270f-fe0f': {
    hexCodePoints: '270f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✏️',
    version: 'E0.6',
    baseName: 'pencil',
    fullName: 'pencil',
    isBase: true,
    baseHex: '270f-fe0f',
    modifiers: null,
  },
  '270f': {
    hexCodePoints: '270f',
    qualifiedStatus: 'unqualified',
    emoji: '✏',
    version: 'E0.6',
    baseName: 'pencil',
    fullName: 'pencil',
    isBase: false,
    baseHex: '270f-fe0f',
    modifiers: null,
  },
  '2712-fe0f': {
    hexCodePoints: '2712-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✒️',
    version: 'E0.6',
    baseName: 'black nib',
    fullName: 'black nib',
    isBase: true,
    baseHex: '2712-fe0f',
    modifiers: null,
  },
  '2712': {
    hexCodePoints: '2712',
    qualifiedStatus: 'unqualified',
    emoji: '✒',
    version: 'E0.6',
    baseName: 'black nib',
    fullName: 'black nib',
    isBase: false,
    baseHex: '2712-fe0f',
    modifiers: null,
  },
  '1f58b-fe0f': {
    hexCodePoints: '1f58b-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖋️',
    version: 'E0.7',
    baseName: 'fountain pen',
    fullName: 'fountain pen',
    isBase: true,
    baseHex: '1f58b-fe0f',
    modifiers: null,
  },
  '1f58b': {
    hexCodePoints: '1f58b',
    qualifiedStatus: 'unqualified',
    emoji: '🖋',
    version: 'E0.7',
    baseName: 'fountain pen',
    fullName: 'fountain pen',
    isBase: false,
    baseHex: '1f58b-fe0f',
    modifiers: null,
  },
  '1f58a-fe0f': {
    hexCodePoints: '1f58a-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖊️',
    version: 'E0.7',
    baseName: 'pen',
    fullName: 'pen',
    isBase: true,
    baseHex: '1f58a-fe0f',
    modifiers: null,
  },
  '1f58a': {
    hexCodePoints: '1f58a',
    qualifiedStatus: 'unqualified',
    emoji: '🖊',
    version: 'E0.7',
    baseName: 'pen',
    fullName: 'pen',
    isBase: false,
    baseHex: '1f58a-fe0f',
    modifiers: null,
  },
  '1f58c-fe0f': {
    hexCodePoints: '1f58c-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖌️',
    version: 'E0.7',
    baseName: 'paintbrush',
    fullName: 'paintbrush',
    isBase: true,
    baseHex: '1f58c-fe0f',
    modifiers: null,
  },
  '1f58c': {
    hexCodePoints: '1f58c',
    qualifiedStatus: 'unqualified',
    emoji: '🖌',
    version: 'E0.7',
    baseName: 'paintbrush',
    fullName: 'paintbrush',
    isBase: false,
    baseHex: '1f58c-fe0f',
    modifiers: null,
  },
  '1f58d-fe0f': {
    hexCodePoints: '1f58d-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖍️',
    version: 'E0.7',
    baseName: 'crayon',
    fullName: 'crayon',
    isBase: true,
    baseHex: '1f58d-fe0f',
    modifiers: null,
  },
  '1f58d': {
    hexCodePoints: '1f58d',
    qualifiedStatus: 'unqualified',
    emoji: '🖍',
    version: 'E0.7',
    baseName: 'crayon',
    fullName: 'crayon',
    isBase: false,
    baseHex: '1f58d-fe0f',
    modifiers: null,
  },
  '1f4dd': {
    hexCodePoints: '1f4dd',
    qualifiedStatus: 'fully-qualified',
    emoji: '📝',
    version: 'E0.6',
    baseName: 'memo',
    fullName: 'memo',
    isBase: true,
    baseHex: '1f4dd',
    modifiers: null,
  },
  '1f4bc': {
    hexCodePoints: '1f4bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '💼',
    version: 'E0.6',
    baseName: 'briefcase',
    fullName: 'briefcase',
    isBase: true,
    baseHex: '1f4bc',
    modifiers: null,
  },
  '1f4c1': {
    hexCodePoints: '1f4c1',
    qualifiedStatus: 'fully-qualified',
    emoji: '📁',
    version: 'E0.6',
    baseName: 'file folder',
    fullName: 'file folder',
    isBase: true,
    baseHex: '1f4c1',
    modifiers: null,
  },
  '1f4c2': {
    hexCodePoints: '1f4c2',
    qualifiedStatus: 'fully-qualified',
    emoji: '📂',
    version: 'E0.6',
    baseName: 'open file folder',
    fullName: 'open file folder',
    isBase: true,
    baseHex: '1f4c2',
    modifiers: null,
  },
  '1f5c2-fe0f': {
    hexCodePoints: '1f5c2-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗂️',
    version: 'E0.7',
    baseName: 'card index dividers',
    fullName: 'card index dividers',
    isBase: true,
    baseHex: '1f5c2-fe0f',
    modifiers: null,
  },
  '1f5c2': {
    hexCodePoints: '1f5c2',
    qualifiedStatus: 'unqualified',
    emoji: '🗂',
    version: 'E0.7',
    baseName: 'card index dividers',
    fullName: 'card index dividers',
    isBase: false,
    baseHex: '1f5c2-fe0f',
    modifiers: null,
  },
  '1f4c5': {
    hexCodePoints: '1f4c5',
    qualifiedStatus: 'fully-qualified',
    emoji: '📅',
    version: 'E0.6',
    baseName: 'calendar',
    fullName: 'calendar',
    isBase: true,
    baseHex: '1f4c5',
    modifiers: null,
  },
  '1f4c6': {
    hexCodePoints: '1f4c6',
    qualifiedStatus: 'fully-qualified',
    emoji: '📆',
    version: 'E0.6',
    baseName: 'tear-off calendar',
    fullName: 'tear-off calendar',
    isBase: true,
    baseHex: '1f4c6',
    modifiers: null,
  },
  '1f5d2-fe0f': {
    hexCodePoints: '1f5d2-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗒️',
    version: 'E0.7',
    baseName: 'spiral notepad',
    fullName: 'spiral notepad',
    isBase: true,
    baseHex: '1f5d2-fe0f',
    modifiers: null,
  },
  '1f5d2': {
    hexCodePoints: '1f5d2',
    qualifiedStatus: 'unqualified',
    emoji: '🗒',
    version: 'E0.7',
    baseName: 'spiral notepad',
    fullName: 'spiral notepad',
    isBase: false,
    baseHex: '1f5d2-fe0f',
    modifiers: null,
  },
  '1f5d3-fe0f': {
    hexCodePoints: '1f5d3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗓️',
    version: 'E0.7',
    baseName: 'spiral calendar',
    fullName: 'spiral calendar',
    isBase: true,
    baseHex: '1f5d3-fe0f',
    modifiers: null,
  },
  '1f5d3': {
    hexCodePoints: '1f5d3',
    qualifiedStatus: 'unqualified',
    emoji: '🗓',
    version: 'E0.7',
    baseName: 'spiral calendar',
    fullName: 'spiral calendar',
    isBase: false,
    baseHex: '1f5d3-fe0f',
    modifiers: null,
  },
  '1f4c7': {
    hexCodePoints: '1f4c7',
    qualifiedStatus: 'fully-qualified',
    emoji: '📇',
    version: 'E0.6',
    baseName: 'card index',
    fullName: 'card index',
    isBase: true,
    baseHex: '1f4c7',
    modifiers: null,
  },
  '1f4c8': {
    hexCodePoints: '1f4c8',
    qualifiedStatus: 'fully-qualified',
    emoji: '📈',
    version: 'E0.6',
    baseName: 'chart increasing',
    fullName: 'chart increasing',
    isBase: true,
    baseHex: '1f4c8',
    modifiers: null,
  },
  '1f4c9': {
    hexCodePoints: '1f4c9',
    qualifiedStatus: 'fully-qualified',
    emoji: '📉',
    version: 'E0.6',
    baseName: 'chart decreasing',
    fullName: 'chart decreasing',
    isBase: true,
    baseHex: '1f4c9',
    modifiers: null,
  },
  '1f4ca': {
    hexCodePoints: '1f4ca',
    qualifiedStatus: 'fully-qualified',
    emoji: '📊',
    version: 'E0.6',
    baseName: 'bar chart',
    fullName: 'bar chart',
    isBase: true,
    baseHex: '1f4ca',
    modifiers: null,
  },
  '1f4cb': {
    hexCodePoints: '1f4cb',
    qualifiedStatus: 'fully-qualified',
    emoji: '📋',
    version: 'E0.6',
    baseName: 'clipboard',
    fullName: 'clipboard',
    isBase: true,
    baseHex: '1f4cb',
    modifiers: null,
  },
  '1f4cc': {
    hexCodePoints: '1f4cc',
    qualifiedStatus: 'fully-qualified',
    emoji: '📌',
    version: 'E0.6',
    baseName: 'pushpin',
    fullName: 'pushpin',
    isBase: true,
    baseHex: '1f4cc',
    modifiers: null,
  },
  '1f4cd': {
    hexCodePoints: '1f4cd',
    qualifiedStatus: 'fully-qualified',
    emoji: '📍',
    version: 'E0.6',
    baseName: 'round pushpin',
    fullName: 'round pushpin',
    isBase: true,
    baseHex: '1f4cd',
    modifiers: null,
  },
  '1f4ce': {
    hexCodePoints: '1f4ce',
    qualifiedStatus: 'fully-qualified',
    emoji: '📎',
    version: 'E0.6',
    baseName: 'paperclip',
    fullName: 'paperclip',
    isBase: true,
    baseHex: '1f4ce',
    modifiers: null,
  },
  '1f587-fe0f': {
    hexCodePoints: '1f587-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🖇️',
    version: 'E0.7',
    baseName: 'linked paperclips',
    fullName: 'linked paperclips',
    isBase: true,
    baseHex: '1f587-fe0f',
    modifiers: null,
  },
  '1f587': {
    hexCodePoints: '1f587',
    qualifiedStatus: 'unqualified',
    emoji: '🖇',
    version: 'E0.7',
    baseName: 'linked paperclips',
    fullName: 'linked paperclips',
    isBase: false,
    baseHex: '1f587-fe0f',
    modifiers: null,
  },
  '1f4cf': {
    hexCodePoints: '1f4cf',
    qualifiedStatus: 'fully-qualified',
    emoji: '📏',
    version: 'E0.6',
    baseName: 'straight ruler',
    fullName: 'straight ruler',
    isBase: true,
    baseHex: '1f4cf',
    modifiers: null,
  },
  '1f4d0': {
    hexCodePoints: '1f4d0',
    qualifiedStatus: 'fully-qualified',
    emoji: '📐',
    version: 'E0.6',
    baseName: 'triangular ruler',
    fullName: 'triangular ruler',
    isBase: true,
    baseHex: '1f4d0',
    modifiers: null,
  },
  '2702-fe0f': {
    hexCodePoints: '2702-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✂️',
    version: 'E0.6',
    baseName: 'scissors',
    fullName: 'scissors',
    isBase: true,
    baseHex: '2702-fe0f',
    modifiers: null,
  },
  '2702': {
    hexCodePoints: '2702',
    qualifiedStatus: 'unqualified',
    emoji: '✂',
    version: 'E0.6',
    baseName: 'scissors',
    fullName: 'scissors',
    isBase: false,
    baseHex: '2702-fe0f',
    modifiers: null,
  },
  '1f5c3-fe0f': {
    hexCodePoints: '1f5c3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗃️',
    version: 'E0.7',
    baseName: 'card file box',
    fullName: 'card file box',
    isBase: true,
    baseHex: '1f5c3-fe0f',
    modifiers: null,
  },
  '1f5c3': {
    hexCodePoints: '1f5c3',
    qualifiedStatus: 'unqualified',
    emoji: '🗃',
    version: 'E0.7',
    baseName: 'card file box',
    fullName: 'card file box',
    isBase: false,
    baseHex: '1f5c3-fe0f',
    modifiers: null,
  },
  '1f5c4-fe0f': {
    hexCodePoints: '1f5c4-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗄️',
    version: 'E0.7',
    baseName: 'file cabinet',
    fullName: 'file cabinet',
    isBase: true,
    baseHex: '1f5c4-fe0f',
    modifiers: null,
  },
  '1f5c4': {
    hexCodePoints: '1f5c4',
    qualifiedStatus: 'unqualified',
    emoji: '🗄',
    version: 'E0.7',
    baseName: 'file cabinet',
    fullName: 'file cabinet',
    isBase: false,
    baseHex: '1f5c4-fe0f',
    modifiers: null,
  },
  '1f5d1-fe0f': {
    hexCodePoints: '1f5d1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗑️',
    version: 'E0.7',
    baseName: 'wastebasket',
    fullName: 'wastebasket',
    isBase: true,
    baseHex: '1f5d1-fe0f',
    modifiers: null,
  },
  '1f5d1': {
    hexCodePoints: '1f5d1',
    qualifiedStatus: 'unqualified',
    emoji: '🗑',
    version: 'E0.7',
    baseName: 'wastebasket',
    fullName: 'wastebasket',
    isBase: false,
    baseHex: '1f5d1-fe0f',
    modifiers: null,
  },
  '1f512': {
    hexCodePoints: '1f512',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔒',
    version: 'E0.6',
    baseName: 'locked',
    fullName: 'locked',
    isBase: true,
    baseHex: '1f512',
    modifiers: null,
  },
  '1f513': {
    hexCodePoints: '1f513',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔓',
    version: 'E0.6',
    baseName: 'unlocked',
    fullName: 'unlocked',
    isBase: true,
    baseHex: '1f513',
    modifiers: null,
  },
  '1f50f': {
    hexCodePoints: '1f50f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔏',
    version: 'E0.6',
    baseName: 'locked with pen',
    fullName: 'locked with pen',
    isBase: true,
    baseHex: '1f50f',
    modifiers: null,
  },
  '1f510': {
    hexCodePoints: '1f510',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔐',
    version: 'E0.6',
    baseName: 'locked with key',
    fullName: 'locked with key',
    isBase: true,
    baseHex: '1f510',
    modifiers: null,
  },
  '1f511': {
    hexCodePoints: '1f511',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔑',
    version: 'E0.6',
    baseName: 'key',
    fullName: 'key',
    isBase: true,
    baseHex: '1f511',
    modifiers: null,
  },
  '1f5dd-fe0f': {
    hexCodePoints: '1f5dd-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗝️',
    version: 'E0.7',
    baseName: 'old key',
    fullName: 'old key',
    isBase: true,
    baseHex: '1f5dd-fe0f',
    modifiers: null,
  },
  '1f5dd': {
    hexCodePoints: '1f5dd',
    qualifiedStatus: 'unqualified',
    emoji: '🗝',
    version: 'E0.7',
    baseName: 'old key',
    fullName: 'old key',
    isBase: false,
    baseHex: '1f5dd-fe0f',
    modifiers: null,
  },
  '1f528': {
    hexCodePoints: '1f528',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔨',
    version: 'E0.6',
    baseName: 'hammer',
    fullName: 'hammer',
    isBase: true,
    baseHex: '1f528',
    modifiers: null,
  },
  '1fa93': {
    hexCodePoints: '1fa93',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪓',
    version: 'E12.0',
    baseName: 'axe',
    fullName: 'axe',
    isBase: true,
    baseHex: '1fa93',
    modifiers: null,
  },
  '26cf-fe0f': {
    hexCodePoints: '26cf-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛏️',
    version: 'E0.7',
    baseName: 'pick',
    fullName: 'pick',
    isBase: true,
    baseHex: '26cf-fe0f',
    modifiers: null,
  },
  '26cf': {
    hexCodePoints: '26cf',
    qualifiedStatus: 'unqualified',
    emoji: '⛏',
    version: 'E0.7',
    baseName: 'pick',
    fullName: 'pick',
    isBase: false,
    baseHex: '26cf-fe0f',
    modifiers: null,
  },
  '2692-fe0f': {
    hexCodePoints: '2692-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚒️',
    version: 'E1.0',
    baseName: 'hammer and pick',
    fullName: 'hammer and pick',
    isBase: true,
    baseHex: '2692-fe0f',
    modifiers: null,
  },
  '2692': {
    hexCodePoints: '2692',
    qualifiedStatus: 'unqualified',
    emoji: '⚒',
    version: 'E1.0',
    baseName: 'hammer and pick',
    fullName: 'hammer and pick',
    isBase: false,
    baseHex: '2692-fe0f',
    modifiers: null,
  },
  '1f6e0-fe0f': {
    hexCodePoints: '1f6e0-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛠️',
    version: 'E0.7',
    baseName: 'hammer and wrench',
    fullName: 'hammer and wrench',
    isBase: true,
    baseHex: '1f6e0-fe0f',
    modifiers: null,
  },
  '1f6e0': {
    hexCodePoints: '1f6e0',
    qualifiedStatus: 'unqualified',
    emoji: '🛠',
    version: 'E0.7',
    baseName: 'hammer and wrench',
    fullName: 'hammer and wrench',
    isBase: false,
    baseHex: '1f6e0-fe0f',
    modifiers: null,
  },
  '1f5e1-fe0f': {
    hexCodePoints: '1f5e1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗡️',
    version: 'E0.7',
    baseName: 'dagger',
    fullName: 'dagger',
    isBase: true,
    baseHex: '1f5e1-fe0f',
    modifiers: null,
  },
  '1f5e1': {
    hexCodePoints: '1f5e1',
    qualifiedStatus: 'unqualified',
    emoji: '🗡',
    version: 'E0.7',
    baseName: 'dagger',
    fullName: 'dagger',
    isBase: false,
    baseHex: '1f5e1-fe0f',
    modifiers: null,
  },
  '2694-fe0f': {
    hexCodePoints: '2694-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚔️',
    version: 'E1.0',
    baseName: 'crossed swords',
    fullName: 'crossed swords',
    isBase: true,
    baseHex: '2694-fe0f',
    modifiers: null,
  },
  '2694': {
    hexCodePoints: '2694',
    qualifiedStatus: 'unqualified',
    emoji: '⚔',
    version: 'E1.0',
    baseName: 'crossed swords',
    fullName: 'crossed swords',
    isBase: false,
    baseHex: '2694-fe0f',
    modifiers: null,
  },
  '1f4a3': {
    hexCodePoints: '1f4a3',
    qualifiedStatus: 'fully-qualified',
    emoji: '💣',
    version: 'E0.6',
    baseName: 'bomb',
    fullName: 'bomb',
    isBase: true,
    baseHex: '1f4a3',
    modifiers: null,
  },
  '1fa83': {
    hexCodePoints: '1fa83',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪃',
    version: 'E13.0',
    baseName: 'boomerang',
    fullName: 'boomerang',
    isBase: true,
    baseHex: '1fa83',
    modifiers: null,
  },
  '1f3f9': {
    hexCodePoints: '1f3f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏹',
    version: 'E1.0',
    baseName: 'bow and arrow',
    fullName: 'bow and arrow',
    isBase: true,
    baseHex: '1f3f9',
    modifiers: null,
  },
  '1f6e1-fe0f': {
    hexCodePoints: '1f6e1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛡️',
    version: 'E0.7',
    baseName: 'shield',
    fullName: 'shield',
    isBase: true,
    baseHex: '1f6e1-fe0f',
    modifiers: null,
  },
  '1f6e1': {
    hexCodePoints: '1f6e1',
    qualifiedStatus: 'unqualified',
    emoji: '🛡',
    version: 'E0.7',
    baseName: 'shield',
    fullName: 'shield',
    isBase: false,
    baseHex: '1f6e1-fe0f',
    modifiers: null,
  },
  '1fa9a': {
    hexCodePoints: '1fa9a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪚',
    version: 'E13.0',
    baseName: 'carpentry saw',
    fullName: 'carpentry saw',
    isBase: true,
    baseHex: '1fa9a',
    modifiers: null,
  },
  '1f527': {
    hexCodePoints: '1f527',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔧',
    version: 'E0.6',
    baseName: 'wrench',
    fullName: 'wrench',
    isBase: true,
    baseHex: '1f527',
    modifiers: null,
  },
  '1fa9b': {
    hexCodePoints: '1fa9b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪛',
    version: 'E13.0',
    baseName: 'screwdriver',
    fullName: 'screwdriver',
    isBase: true,
    baseHex: '1fa9b',
    modifiers: null,
  },
  '1f529': {
    hexCodePoints: '1f529',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔩',
    version: 'E0.6',
    baseName: 'nut and bolt',
    fullName: 'nut and bolt',
    isBase: true,
    baseHex: '1f529',
    modifiers: null,
  },
  '2699-fe0f': {
    hexCodePoints: '2699-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚙️',
    version: 'E1.0',
    baseName: 'gear',
    fullName: 'gear',
    isBase: true,
    baseHex: '2699-fe0f',
    modifiers: null,
  },
  '2699': {
    hexCodePoints: '2699',
    qualifiedStatus: 'unqualified',
    emoji: '⚙',
    version: 'E1.0',
    baseName: 'gear',
    fullName: 'gear',
    isBase: false,
    baseHex: '2699-fe0f',
    modifiers: null,
  },
  '1f5dc-fe0f': {
    hexCodePoints: '1f5dc-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗜️',
    version: 'E0.7',
    baseName: 'clamp',
    fullName: 'clamp',
    isBase: true,
    baseHex: '1f5dc-fe0f',
    modifiers: null,
  },
  '1f5dc': {
    hexCodePoints: '1f5dc',
    qualifiedStatus: 'unqualified',
    emoji: '🗜',
    version: 'E0.7',
    baseName: 'clamp',
    fullName: 'clamp',
    isBase: false,
    baseHex: '1f5dc-fe0f',
    modifiers: null,
  },
  '2696-fe0f': {
    hexCodePoints: '2696-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚖️',
    version: 'E1.0',
    baseName: 'balance scale',
    fullName: 'balance scale',
    isBase: true,
    baseHex: '2696-fe0f',
    modifiers: null,
  },
  '2696': {
    hexCodePoints: '2696',
    qualifiedStatus: 'unqualified',
    emoji: '⚖',
    version: 'E1.0',
    baseName: 'balance scale',
    fullName: 'balance scale',
    isBase: false,
    baseHex: '2696-fe0f',
    modifiers: null,
  },
  '1f9af': {
    hexCodePoints: '1f9af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🦯',
    version: 'E12.0',
    baseName: 'white cane',
    fullName: 'white cane',
    isBase: true,
    baseHex: '1f9af',
    modifiers: null,
  },
  '1f517': {
    hexCodePoints: '1f517',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔗',
    version: 'E0.6',
    baseName: 'link',
    fullName: 'link',
    isBase: true,
    baseHex: '1f517',
    modifiers: null,
  },
  '26d3-fe0f-200d-1f4a5': {
    hexCodePoints: '26d3-fe0f-200d-1f4a5',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛓️‍💥',
    version: 'E15.1',
    baseName: 'broken chain',
    fullName: 'broken chain',
    isBase: true,
    baseHex: '26d3-fe0f-200d-1f4a5',
    modifiers: null,
  },
  '26d3-200d-1f4a5': {
    hexCodePoints: '26d3-200d-1f4a5',
    qualifiedStatus: 'unqualified',
    emoji: '⛓‍💥',
    version: 'E15.1',
    baseName: 'broken chain',
    fullName: 'broken chain',
    isBase: false,
    baseHex: '26d3-fe0f-200d-1f4a5',
    modifiers: null,
  },
  '26d3-fe0f': {
    hexCodePoints: '26d3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛓️',
    version: 'E0.7',
    baseName: 'chains',
    fullName: 'chains',
    isBase: true,
    baseHex: '26d3-fe0f',
    modifiers: null,
  },
  '26d3': {
    hexCodePoints: '26d3',
    qualifiedStatus: 'unqualified',
    emoji: '⛓',
    version: 'E0.7',
    baseName: 'chains',
    fullName: 'chains',
    isBase: false,
    baseHex: '26d3-fe0f',
    modifiers: null,
  },
  '1fa9d': {
    hexCodePoints: '1fa9d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪝',
    version: 'E13.0',
    baseName: 'hook',
    fullName: 'hook',
    isBase: true,
    baseHex: '1fa9d',
    modifiers: null,
  },
  '1f9f0': {
    hexCodePoints: '1f9f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧰',
    version: 'E11.0',
    baseName: 'toolbox',
    fullName: 'toolbox',
    isBase: true,
    baseHex: '1f9f0',
    modifiers: null,
  },
  '1f9f2': {
    hexCodePoints: '1f9f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧲',
    version: 'E11.0',
    baseName: 'magnet',
    fullName: 'magnet',
    isBase: true,
    baseHex: '1f9f2',
    modifiers: null,
  },
  '1fa9c': {
    hexCodePoints: '1fa9c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪜',
    version: 'E13.0',
    baseName: 'ladder',
    fullName: 'ladder',
    isBase: true,
    baseHex: '1fa9c',
    modifiers: null,
  },
  '2697-fe0f': {
    hexCodePoints: '2697-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚗️',
    version: 'E1.0',
    baseName: 'alembic',
    fullName: 'alembic',
    isBase: true,
    baseHex: '2697-fe0f',
    modifiers: null,
  },
  '2697': {
    hexCodePoints: '2697',
    qualifiedStatus: 'unqualified',
    emoji: '⚗',
    version: 'E1.0',
    baseName: 'alembic',
    fullName: 'alembic',
    isBase: false,
    baseHex: '2697-fe0f',
    modifiers: null,
  },
  '1f9ea': {
    hexCodePoints: '1f9ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧪',
    version: 'E11.0',
    baseName: 'test tube',
    fullName: 'test tube',
    isBase: true,
    baseHex: '1f9ea',
    modifiers: null,
  },
  '1f9eb': {
    hexCodePoints: '1f9eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧫',
    version: 'E11.0',
    baseName: 'petri dish',
    fullName: 'petri dish',
    isBase: true,
    baseHex: '1f9eb',
    modifiers: null,
  },
  '1f9ec': {
    hexCodePoints: '1f9ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧬',
    version: 'E11.0',
    baseName: 'dna',
    fullName: 'dna',
    isBase: true,
    baseHex: '1f9ec',
    modifiers: null,
  },
  '1f52c': {
    hexCodePoints: '1f52c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔬',
    version: 'E1.0',
    baseName: 'microscope',
    fullName: 'microscope',
    isBase: true,
    baseHex: '1f52c',
    modifiers: null,
  },
  '1f52d': {
    hexCodePoints: '1f52d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔭',
    version: 'E1.0',
    baseName: 'telescope',
    fullName: 'telescope',
    isBase: true,
    baseHex: '1f52d',
    modifiers: null,
  },
  '1f4e1': {
    hexCodePoints: '1f4e1',
    qualifiedStatus: 'fully-qualified',
    emoji: '📡',
    version: 'E0.6',
    baseName: 'satellite antenna',
    fullName: 'satellite antenna',
    isBase: true,
    baseHex: '1f4e1',
    modifiers: null,
  },
  '1f489': {
    hexCodePoints: '1f489',
    qualifiedStatus: 'fully-qualified',
    emoji: '💉',
    version: 'E0.6',
    baseName: 'syringe',
    fullName: 'syringe',
    isBase: true,
    baseHex: '1f489',
    modifiers: null,
  },
  '1fa78': {
    hexCodePoints: '1fa78',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩸',
    version: 'E12.0',
    baseName: 'drop of blood',
    fullName: 'drop of blood',
    isBase: true,
    baseHex: '1fa78',
    modifiers: null,
  },
  '1f48a': {
    hexCodePoints: '1f48a',
    qualifiedStatus: 'fully-qualified',
    emoji: '💊',
    version: 'E0.6',
    baseName: 'pill',
    fullName: 'pill',
    isBase: true,
    baseHex: '1f48a',
    modifiers: null,
  },
  '1fa79': {
    hexCodePoints: '1fa79',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩹',
    version: 'E12.0',
    baseName: 'adhesive bandage',
    fullName: 'adhesive bandage',
    isBase: true,
    baseHex: '1fa79',
    modifiers: null,
  },
  '1fa7c': {
    hexCodePoints: '1fa7c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩼',
    version: 'E14.0',
    baseName: 'crutch',
    fullName: 'crutch',
    isBase: true,
    baseHex: '1fa7c',
    modifiers: null,
  },
  '1fa7a': {
    hexCodePoints: '1fa7a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩺',
    version: 'E12.0',
    baseName: 'stethoscope',
    fullName: 'stethoscope',
    isBase: true,
    baseHex: '1fa7a',
    modifiers: null,
  },
  '1fa7b': {
    hexCodePoints: '1fa7b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🩻',
    version: 'E14.0',
    baseName: 'x-ray',
    fullName: 'x-ray',
    isBase: true,
    baseHex: '1fa7b',
    modifiers: null,
  },
  '1f6aa': {
    hexCodePoints: '1f6aa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚪',
    version: 'E0.6',
    baseName: 'door',
    fullName: 'door',
    isBase: true,
    baseHex: '1f6aa',
    modifiers: null,
  },
  '1f6d7': {
    hexCodePoints: '1f6d7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛗',
    version: 'E13.0',
    baseName: 'elevator',
    fullName: 'elevator',
    isBase: true,
    baseHex: '1f6d7',
    modifiers: null,
  },
  '1fa9e': {
    hexCodePoints: '1fa9e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪞',
    version: 'E13.0',
    baseName: 'mirror',
    fullName: 'mirror',
    isBase: true,
    baseHex: '1fa9e',
    modifiers: null,
  },
  '1fa9f': {
    hexCodePoints: '1fa9f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪟',
    version: 'E13.0',
    baseName: 'window',
    fullName: 'window',
    isBase: true,
    baseHex: '1fa9f',
    modifiers: null,
  },
  '1f6cf-fe0f': {
    hexCodePoints: '1f6cf-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛏️',
    version: 'E0.7',
    baseName: 'bed',
    fullName: 'bed',
    isBase: true,
    baseHex: '1f6cf-fe0f',
    modifiers: null,
  },
  '1f6cf': {
    hexCodePoints: '1f6cf',
    qualifiedStatus: 'unqualified',
    emoji: '🛏',
    version: 'E0.7',
    baseName: 'bed',
    fullName: 'bed',
    isBase: false,
    baseHex: '1f6cf-fe0f',
    modifiers: null,
  },
  '1f6cb-fe0f': {
    hexCodePoints: '1f6cb-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛋️',
    version: 'E0.7',
    baseName: 'couch and lamp',
    fullName: 'couch and lamp',
    isBase: true,
    baseHex: '1f6cb-fe0f',
    modifiers: null,
  },
  '1f6cb': {
    hexCodePoints: '1f6cb',
    qualifiedStatus: 'unqualified',
    emoji: '🛋',
    version: 'E0.7',
    baseName: 'couch and lamp',
    fullName: 'couch and lamp',
    isBase: false,
    baseHex: '1f6cb-fe0f',
    modifiers: null,
  },
  '1fa91': {
    hexCodePoints: '1fa91',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪑',
    version: 'E12.0',
    baseName: 'chair',
    fullName: 'chair',
    isBase: true,
    baseHex: '1fa91',
    modifiers: null,
  },
  '1f6bd': {
    hexCodePoints: '1f6bd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚽',
    version: 'E0.6',
    baseName: 'toilet',
    fullName: 'toilet',
    isBase: true,
    baseHex: '1f6bd',
    modifiers: null,
  },
  '1faa0': {
    hexCodePoints: '1faa0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪠',
    version: 'E13.0',
    baseName: 'plunger',
    fullName: 'plunger',
    isBase: true,
    baseHex: '1faa0',
    modifiers: null,
  },
  '1f6bf': {
    hexCodePoints: '1f6bf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚿',
    version: 'E1.0',
    baseName: 'shower',
    fullName: 'shower',
    isBase: true,
    baseHex: '1f6bf',
    modifiers: null,
  },
  '1f6c1': {
    hexCodePoints: '1f6c1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛁',
    version: 'E1.0',
    baseName: 'bathtub',
    fullName: 'bathtub',
    isBase: true,
    baseHex: '1f6c1',
    modifiers: null,
  },
  '1faa4': {
    hexCodePoints: '1faa4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪤',
    version: 'E13.0',
    baseName: 'mouse trap',
    fullName: 'mouse trap',
    isBase: true,
    baseHex: '1faa4',
    modifiers: null,
  },
  '1fa92': {
    hexCodePoints: '1fa92',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪒',
    version: 'E12.0',
    baseName: 'razor',
    fullName: 'razor',
    isBase: true,
    baseHex: '1fa92',
    modifiers: null,
  },
  '1f9f4': {
    hexCodePoints: '1f9f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧴',
    version: 'E11.0',
    baseName: 'lotion bottle',
    fullName: 'lotion bottle',
    isBase: true,
    baseHex: '1f9f4',
    modifiers: null,
  },
  '1f9f7': {
    hexCodePoints: '1f9f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧷',
    version: 'E11.0',
    baseName: 'safety pin',
    fullName: 'safety pin',
    isBase: true,
    baseHex: '1f9f7',
    modifiers: null,
  },
  '1f9f9': {
    hexCodePoints: '1f9f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧹',
    version: 'E11.0',
    baseName: 'broom',
    fullName: 'broom',
    isBase: true,
    baseHex: '1f9f9',
    modifiers: null,
  },
  '1f9fa': {
    hexCodePoints: '1f9fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧺',
    version: 'E11.0',
    baseName: 'basket',
    fullName: 'basket',
    isBase: true,
    baseHex: '1f9fa',
    modifiers: null,
  },
  '1f9fb': {
    hexCodePoints: '1f9fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧻',
    version: 'E11.0',
    baseName: 'roll of paper',
    fullName: 'roll of paper',
    isBase: true,
    baseHex: '1f9fb',
    modifiers: null,
  },
  '1faa3': {
    hexCodePoints: '1faa3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪣',
    version: 'E13.0',
    baseName: 'bucket',
    fullName: 'bucket',
    isBase: true,
    baseHex: '1faa3',
    modifiers: null,
  },
  '1f9fc': {
    hexCodePoints: '1f9fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧼',
    version: 'E11.0',
    baseName: 'soap',
    fullName: 'soap',
    isBase: true,
    baseHex: '1f9fc',
    modifiers: null,
  },
  '1fae7': {
    hexCodePoints: '1fae7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🫧',
    version: 'E14.0',
    baseName: 'bubbles',
    fullName: 'bubbles',
    isBase: true,
    baseHex: '1fae7',
    modifiers: null,
  },
  '1faa5': {
    hexCodePoints: '1faa5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪥',
    version: 'E13.0',
    baseName: 'toothbrush',
    fullName: 'toothbrush',
    isBase: true,
    baseHex: '1faa5',
    modifiers: null,
  },
  '1f9fd': {
    hexCodePoints: '1f9fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧽',
    version: 'E11.0',
    baseName: 'sponge',
    fullName: 'sponge',
    isBase: true,
    baseHex: '1f9fd',
    modifiers: null,
  },
  '1f9ef': {
    hexCodePoints: '1f9ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧯',
    version: 'E11.0',
    baseName: 'fire extinguisher',
    fullName: 'fire extinguisher',
    isBase: true,
    baseHex: '1f9ef',
    modifiers: null,
  },
  '1f6d2': {
    hexCodePoints: '1f6d2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛒',
    version: 'E3.0',
    baseName: 'shopping cart',
    fullName: 'shopping cart',
    isBase: true,
    baseHex: '1f6d2',
    modifiers: null,
  },
  '1f6ac': {
    hexCodePoints: '1f6ac',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚬',
    version: 'E0.6',
    baseName: 'cigarette',
    fullName: 'cigarette',
    isBase: true,
    baseHex: '1f6ac',
    modifiers: null,
  },
  '26b0-fe0f': {
    hexCodePoints: '26b0-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚰️',
    version: 'E1.0',
    baseName: 'coffin',
    fullName: 'coffin',
    isBase: true,
    baseHex: '26b0-fe0f',
    modifiers: null,
  },
  '26b0': {
    hexCodePoints: '26b0',
    qualifiedStatus: 'unqualified',
    emoji: '⚰',
    version: 'E1.0',
    baseName: 'coffin',
    fullName: 'coffin',
    isBase: false,
    baseHex: '26b0-fe0f',
    modifiers: null,
  },
  '1faa6': {
    hexCodePoints: '1faa6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪦',
    version: 'E13.0',
    baseName: 'headstone',
    fullName: 'headstone',
    isBase: true,
    baseHex: '1faa6',
    modifiers: null,
  },
  '26b1-fe0f': {
    hexCodePoints: '26b1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚱️',
    version: 'E1.0',
    baseName: 'funeral urn',
    fullName: 'funeral urn',
    isBase: true,
    baseHex: '26b1-fe0f',
    modifiers: null,
  },
  '26b1': {
    hexCodePoints: '26b1',
    qualifiedStatus: 'unqualified',
    emoji: '⚱',
    version: 'E1.0',
    baseName: 'funeral urn',
    fullName: 'funeral urn',
    isBase: false,
    baseHex: '26b1-fe0f',
    modifiers: null,
  },
  '1f9ff': {
    hexCodePoints: '1f9ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🧿',
    version: 'E11.0',
    baseName: 'nazar amulet',
    fullName: 'nazar amulet',
    isBase: true,
    baseHex: '1f9ff',
    modifiers: null,
  },
  '1faac': {
    hexCodePoints: '1faac',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪬',
    version: 'E14.0',
    baseName: 'hamsa',
    fullName: 'hamsa',
    isBase: true,
    baseHex: '1faac',
    modifiers: null,
  },
  '1f5ff': {
    hexCodePoints: '1f5ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🗿',
    version: 'E0.6',
    baseName: 'moai',
    fullName: 'moai',
    isBase: true,
    baseHex: '1f5ff',
    modifiers: null,
  },
  '1faa7': {
    hexCodePoints: '1faa7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪧',
    version: 'E13.0',
    baseName: 'placard',
    fullName: 'placard',
    isBase: true,
    baseHex: '1faa7',
    modifiers: null,
  },
  '1faaa': {
    hexCodePoints: '1faaa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪪',
    version: 'E14.0',
    baseName: 'identification card',
    fullName: 'identification card',
    isBase: true,
    baseHex: '1faaa',
    modifiers: null,
  },
  '1f3e7': {
    hexCodePoints: '1f3e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏧',
    version: 'E0.6',
    baseName: 'ATM sign',
    fullName: 'ATM sign',
    isBase: true,
    baseHex: '1f3e7',
    modifiers: null,
  },
  '1f6ae': {
    hexCodePoints: '1f6ae',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚮',
    version: 'E1.0',
    baseName: 'litter in bin sign',
    fullName: 'litter in bin sign',
    isBase: true,
    baseHex: '1f6ae',
    modifiers: null,
  },
  '1f6b0': {
    hexCodePoints: '1f6b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚰',
    version: 'E1.0',
    baseName: 'potable water',
    fullName: 'potable water',
    isBase: true,
    baseHex: '1f6b0',
    modifiers: null,
  },
  '267f': {
    hexCodePoints: '267f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♿',
    version: 'E0.6',
    baseName: 'wheelchair symbol',
    fullName: 'wheelchair symbol',
    isBase: true,
    baseHex: '267f',
    modifiers: null,
  },
  '1f6b9': {
    hexCodePoints: '1f6b9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚹',
    version: 'E0.6',
    baseName: 'men’s room',
    fullName: 'men’s room',
    isBase: true,
    baseHex: '1f6b9',
    modifiers: null,
  },
  '1f6ba': {
    hexCodePoints: '1f6ba',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚺',
    version: 'E0.6',
    baseName: 'women’s room',
    fullName: 'women’s room',
    isBase: true,
    baseHex: '1f6ba',
    modifiers: null,
  },
  '1f6bb': {
    hexCodePoints: '1f6bb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚻',
    version: 'E0.6',
    baseName: 'restroom',
    fullName: 'restroom',
    isBase: true,
    baseHex: '1f6bb',
    modifiers: null,
  },
  '1f6bc': {
    hexCodePoints: '1f6bc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚼',
    version: 'E0.6',
    baseName: 'baby symbol',
    fullName: 'baby symbol',
    isBase: true,
    baseHex: '1f6bc',
    modifiers: null,
  },
  '1f6be': {
    hexCodePoints: '1f6be',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚾',
    version: 'E0.6',
    baseName: 'water closet',
    fullName: 'water closet',
    isBase: true,
    baseHex: '1f6be',
    modifiers: null,
  },
  '1f6c2': {
    hexCodePoints: '1f6c2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛂',
    version: 'E1.0',
    baseName: 'passport control',
    fullName: 'passport control',
    isBase: true,
    baseHex: '1f6c2',
    modifiers: null,
  },
  '1f6c3': {
    hexCodePoints: '1f6c3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛃',
    version: 'E1.0',
    baseName: 'customs',
    fullName: 'customs',
    isBase: true,
    baseHex: '1f6c3',
    modifiers: null,
  },
  '1f6c4': {
    hexCodePoints: '1f6c4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛄',
    version: 'E1.0',
    baseName: 'baggage claim',
    fullName: 'baggage claim',
    isBase: true,
    baseHex: '1f6c4',
    modifiers: null,
  },
  '1f6c5': {
    hexCodePoints: '1f6c5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛅',
    version: 'E1.0',
    baseName: 'left luggage',
    fullName: 'left luggage',
    isBase: true,
    baseHex: '1f6c5',
    modifiers: null,
  },
  '26a0-fe0f': {
    hexCodePoints: '26a0-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚠️',
    version: 'E0.6',
    baseName: 'warning',
    fullName: 'warning',
    isBase: true,
    baseHex: '26a0-fe0f',
    modifiers: null,
  },
  '26a0': {
    hexCodePoints: '26a0',
    qualifiedStatus: 'unqualified',
    emoji: '⚠',
    version: 'E0.6',
    baseName: 'warning',
    fullName: 'warning',
    isBase: false,
    baseHex: '26a0-fe0f',
    modifiers: null,
  },
  '1f6b8': {
    hexCodePoints: '1f6b8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚸',
    version: 'E1.0',
    baseName: 'children crossing',
    fullName: 'children crossing',
    isBase: true,
    baseHex: '1f6b8',
    modifiers: null,
  },
  '26d4': {
    hexCodePoints: '26d4',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛔',
    version: 'E0.6',
    baseName: 'no entry',
    fullName: 'no entry',
    isBase: true,
    baseHex: '26d4',
    modifiers: null,
  },
  '1f6ab': {
    hexCodePoints: '1f6ab',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚫',
    version: 'E0.6',
    baseName: 'prohibited',
    fullName: 'prohibited',
    isBase: true,
    baseHex: '1f6ab',
    modifiers: null,
  },
  '1f6b3': {
    hexCodePoints: '1f6b3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚳',
    version: 'E1.0',
    baseName: 'no bicycles',
    fullName: 'no bicycles',
    isBase: true,
    baseHex: '1f6b3',
    modifiers: null,
  },
  '1f6ad': {
    hexCodePoints: '1f6ad',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚭',
    version: 'E0.6',
    baseName: 'no smoking',
    fullName: 'no smoking',
    isBase: true,
    baseHex: '1f6ad',
    modifiers: null,
  },
  '1f6af': {
    hexCodePoints: '1f6af',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚯',
    version: 'E1.0',
    baseName: 'no littering',
    fullName: 'no littering',
    isBase: true,
    baseHex: '1f6af',
    modifiers: null,
  },
  '1f6b1': {
    hexCodePoints: '1f6b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚱',
    version: 'E1.0',
    baseName: 'non-potable water',
    fullName: 'non-potable water',
    isBase: true,
    baseHex: '1f6b1',
    modifiers: null,
  },
  '1f6b7': {
    hexCodePoints: '1f6b7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚷',
    version: 'E1.0',
    baseName: 'no pedestrians',
    fullName: 'no pedestrians',
    isBase: true,
    baseHex: '1f6b7',
    modifiers: null,
  },
  '1f4f5': {
    hexCodePoints: '1f4f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '📵',
    version: 'E1.0',
    baseName: 'no mobile phones',
    fullName: 'no mobile phones',
    isBase: true,
    baseHex: '1f4f5',
    modifiers: null,
  },
  '1f51e': {
    hexCodePoints: '1f51e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔞',
    version: 'E0.6',
    baseName: 'no one under eighteen',
    fullName: 'no one under eighteen',
    isBase: true,
    baseHex: '1f51e',
    modifiers: null,
  },
  '2622-fe0f': {
    hexCodePoints: '2622-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☢️',
    version: 'E1.0',
    baseName: 'radioactive',
    fullName: 'radioactive',
    isBase: true,
    baseHex: '2622-fe0f',
    modifiers: null,
  },
  '2622': {
    hexCodePoints: '2622',
    qualifiedStatus: 'unqualified',
    emoji: '☢',
    version: 'E1.0',
    baseName: 'radioactive',
    fullName: 'radioactive',
    isBase: false,
    baseHex: '2622-fe0f',
    modifiers: null,
  },
  '2623-fe0f': {
    hexCodePoints: '2623-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☣️',
    version: 'E1.0',
    baseName: 'biohazard',
    fullName: 'biohazard',
    isBase: true,
    baseHex: '2623-fe0f',
    modifiers: null,
  },
  '2623': {
    hexCodePoints: '2623',
    qualifiedStatus: 'unqualified',
    emoji: '☣',
    version: 'E1.0',
    baseName: 'biohazard',
    fullName: 'biohazard',
    isBase: false,
    baseHex: '2623-fe0f',
    modifiers: null,
  },
  '2b06-fe0f': {
    hexCodePoints: '2b06-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⬆️',
    version: 'E0.6',
    baseName: 'up arrow',
    fullName: 'up arrow',
    isBase: true,
    baseHex: '2b06-fe0f',
    modifiers: null,
  },
  '2b06': {
    hexCodePoints: '2b06',
    qualifiedStatus: 'unqualified',
    emoji: '⬆',
    version: 'E0.6',
    baseName: 'up arrow',
    fullName: 'up arrow',
    isBase: false,
    baseHex: '2b06-fe0f',
    modifiers: null,
  },
  '2197-fe0f': {
    hexCodePoints: '2197-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↗️',
    version: 'E0.6',
    baseName: 'up-right arrow',
    fullName: 'up-right arrow',
    isBase: true,
    baseHex: '2197-fe0f',
    modifiers: null,
  },
  '2197': {
    hexCodePoints: '2197',
    qualifiedStatus: 'unqualified',
    emoji: '↗',
    version: 'E0.6',
    baseName: 'up-right arrow',
    fullName: 'up-right arrow',
    isBase: false,
    baseHex: '2197-fe0f',
    modifiers: null,
  },
  '27a1-fe0f': {
    hexCodePoints: '27a1-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '➡️',
    version: 'E0.6',
    baseName: 'right arrow',
    fullName: 'right arrow',
    isBase: true,
    baseHex: '27a1-fe0f',
    modifiers: null,
  },
  '27a1': {
    hexCodePoints: '27a1',
    qualifiedStatus: 'unqualified',
    emoji: '➡',
    version: 'E0.6',
    baseName: 'right arrow',
    fullName: 'right arrow',
    isBase: false,
    baseHex: '27a1-fe0f',
    modifiers: null,
  },
  '2198-fe0f': {
    hexCodePoints: '2198-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↘️',
    version: 'E0.6',
    baseName: 'down-right arrow',
    fullName: 'down-right arrow',
    isBase: true,
    baseHex: '2198-fe0f',
    modifiers: null,
  },
  '2198': {
    hexCodePoints: '2198',
    qualifiedStatus: 'unqualified',
    emoji: '↘',
    version: 'E0.6',
    baseName: 'down-right arrow',
    fullName: 'down-right arrow',
    isBase: false,
    baseHex: '2198-fe0f',
    modifiers: null,
  },
  '2b07-fe0f': {
    hexCodePoints: '2b07-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⬇️',
    version: 'E0.6',
    baseName: 'down arrow',
    fullName: 'down arrow',
    isBase: true,
    baseHex: '2b07-fe0f',
    modifiers: null,
  },
  '2b07': {
    hexCodePoints: '2b07',
    qualifiedStatus: 'unqualified',
    emoji: '⬇',
    version: 'E0.6',
    baseName: 'down arrow',
    fullName: 'down arrow',
    isBase: false,
    baseHex: '2b07-fe0f',
    modifiers: null,
  },
  '2199-fe0f': {
    hexCodePoints: '2199-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↙️',
    version: 'E0.6',
    baseName: 'down-left arrow',
    fullName: 'down-left arrow',
    isBase: true,
    baseHex: '2199-fe0f',
    modifiers: null,
  },
  '2199': {
    hexCodePoints: '2199',
    qualifiedStatus: 'unqualified',
    emoji: '↙',
    version: 'E0.6',
    baseName: 'down-left arrow',
    fullName: 'down-left arrow',
    isBase: false,
    baseHex: '2199-fe0f',
    modifiers: null,
  },
  '2b05-fe0f': {
    hexCodePoints: '2b05-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⬅️',
    version: 'E0.6',
    baseName: 'left arrow',
    fullName: 'left arrow',
    isBase: true,
    baseHex: '2b05-fe0f',
    modifiers: null,
  },
  '2b05': {
    hexCodePoints: '2b05',
    qualifiedStatus: 'unqualified',
    emoji: '⬅',
    version: 'E0.6',
    baseName: 'left arrow',
    fullName: 'left arrow',
    isBase: false,
    baseHex: '2b05-fe0f',
    modifiers: null,
  },
  '2196-fe0f': {
    hexCodePoints: '2196-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↖️',
    version: 'E0.6',
    baseName: 'up-left arrow',
    fullName: 'up-left arrow',
    isBase: true,
    baseHex: '2196-fe0f',
    modifiers: null,
  },
  '2196': {
    hexCodePoints: '2196',
    qualifiedStatus: 'unqualified',
    emoji: '↖',
    version: 'E0.6',
    baseName: 'up-left arrow',
    fullName: 'up-left arrow',
    isBase: false,
    baseHex: '2196-fe0f',
    modifiers: null,
  },
  '2195-fe0f': {
    hexCodePoints: '2195-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↕️',
    version: 'E0.6',
    baseName: 'up-down arrow',
    fullName: 'up-down arrow',
    isBase: true,
    baseHex: '2195-fe0f',
    modifiers: null,
  },
  '2195': {
    hexCodePoints: '2195',
    qualifiedStatus: 'unqualified',
    emoji: '↕',
    version: 'E0.6',
    baseName: 'up-down arrow',
    fullName: 'up-down arrow',
    isBase: false,
    baseHex: '2195-fe0f',
    modifiers: null,
  },
  '2194-fe0f': {
    hexCodePoints: '2194-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↔️',
    version: 'E0.6',
    baseName: 'left-right arrow',
    fullName: 'left-right arrow',
    isBase: true,
    baseHex: '2194-fe0f',
    modifiers: null,
  },
  '2194': {
    hexCodePoints: '2194',
    qualifiedStatus: 'unqualified',
    emoji: '↔',
    version: 'E0.6',
    baseName: 'left-right arrow',
    fullName: 'left-right arrow',
    isBase: false,
    baseHex: '2194-fe0f',
    modifiers: null,
  },
  '21a9-fe0f': {
    hexCodePoints: '21a9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↩️',
    version: 'E0.6',
    baseName: 'right arrow curving left',
    fullName: 'right arrow curving left',
    isBase: true,
    baseHex: '21a9-fe0f',
    modifiers: null,
  },
  '21a9': {
    hexCodePoints: '21a9',
    qualifiedStatus: 'unqualified',
    emoji: '↩',
    version: 'E0.6',
    baseName: 'right arrow curving left',
    fullName: 'right arrow curving left',
    isBase: false,
    baseHex: '21a9-fe0f',
    modifiers: null,
  },
  '21aa-fe0f': {
    hexCodePoints: '21aa-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '↪️',
    version: 'E0.6',
    baseName: 'left arrow curving right',
    fullName: 'left arrow curving right',
    isBase: true,
    baseHex: '21aa-fe0f',
    modifiers: null,
  },
  '21aa': {
    hexCodePoints: '21aa',
    qualifiedStatus: 'unqualified',
    emoji: '↪',
    version: 'E0.6',
    baseName: 'left arrow curving right',
    fullName: 'left arrow curving right',
    isBase: false,
    baseHex: '21aa-fe0f',
    modifiers: null,
  },
  '2934-fe0f': {
    hexCodePoints: '2934-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⤴️',
    version: 'E0.6',
    baseName: 'right arrow curving up',
    fullName: 'right arrow curving up',
    isBase: true,
    baseHex: '2934-fe0f',
    modifiers: null,
  },
  '2934': {
    hexCodePoints: '2934',
    qualifiedStatus: 'unqualified',
    emoji: '⤴',
    version: 'E0.6',
    baseName: 'right arrow curving up',
    fullName: 'right arrow curving up',
    isBase: false,
    baseHex: '2934-fe0f',
    modifiers: null,
  },
  '2935-fe0f': {
    hexCodePoints: '2935-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⤵️',
    version: 'E0.6',
    baseName: 'right arrow curving down',
    fullName: 'right arrow curving down',
    isBase: true,
    baseHex: '2935-fe0f',
    modifiers: null,
  },
  '2935': {
    hexCodePoints: '2935',
    qualifiedStatus: 'unqualified',
    emoji: '⤵',
    version: 'E0.6',
    baseName: 'right arrow curving down',
    fullName: 'right arrow curving down',
    isBase: false,
    baseHex: '2935-fe0f',
    modifiers: null,
  },
  '1f503': {
    hexCodePoints: '1f503',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔃',
    version: 'E0.6',
    baseName: 'clockwise vertical arrows',
    fullName: 'clockwise vertical arrows',
    isBase: true,
    baseHex: '1f503',
    modifiers: null,
  },
  '1f504': {
    hexCodePoints: '1f504',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔄',
    version: 'E1.0',
    baseName: 'counterclockwise arrows button',
    fullName: 'counterclockwise arrows button',
    isBase: true,
    baseHex: '1f504',
    modifiers: null,
  },
  '1f519': {
    hexCodePoints: '1f519',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔙',
    version: 'E0.6',
    baseName: 'BACK arrow',
    fullName: 'BACK arrow',
    isBase: true,
    baseHex: '1f519',
    modifiers: null,
  },
  '1f51a': {
    hexCodePoints: '1f51a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔚',
    version: 'E0.6',
    baseName: 'END arrow',
    fullName: 'END arrow',
    isBase: true,
    baseHex: '1f51a',
    modifiers: null,
  },
  '1f51b': {
    hexCodePoints: '1f51b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔛',
    version: 'E0.6',
    baseName: 'ON! arrow',
    fullName: 'ON! arrow',
    isBase: true,
    baseHex: '1f51b',
    modifiers: null,
  },
  '1f51c': {
    hexCodePoints: '1f51c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔜',
    version: 'E0.6',
    baseName: 'SOON arrow',
    fullName: 'SOON arrow',
    isBase: true,
    baseHex: '1f51c',
    modifiers: null,
  },
  '1f51d': {
    hexCodePoints: '1f51d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔝',
    version: 'E0.6',
    baseName: 'TOP arrow',
    fullName: 'TOP arrow',
    isBase: true,
    baseHex: '1f51d',
    modifiers: null,
  },
  '1f6d0': {
    hexCodePoints: '1f6d0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛐',
    version: 'E1.0',
    baseName: 'place of worship',
    fullName: 'place of worship',
    isBase: true,
    baseHex: '1f6d0',
    modifiers: null,
  },
  '269b-fe0f': {
    hexCodePoints: '269b-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚛️',
    version: 'E1.0',
    baseName: 'atom symbol',
    fullName: 'atom symbol',
    isBase: true,
    baseHex: '269b-fe0f',
    modifiers: null,
  },
  '269b': {
    hexCodePoints: '269b',
    qualifiedStatus: 'unqualified',
    emoji: '⚛',
    version: 'E1.0',
    baseName: 'atom symbol',
    fullName: 'atom symbol',
    isBase: false,
    baseHex: '269b-fe0f',
    modifiers: null,
  },
  '1f549-fe0f': {
    hexCodePoints: '1f549-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕉️',
    version: 'E0.7',
    baseName: 'om',
    fullName: 'om',
    isBase: true,
    baseHex: '1f549-fe0f',
    modifiers: null,
  },
  '1f549': {
    hexCodePoints: '1f549',
    qualifiedStatus: 'unqualified',
    emoji: '🕉',
    version: 'E0.7',
    baseName: 'om',
    fullName: 'om',
    isBase: false,
    baseHex: '1f549-fe0f',
    modifiers: null,
  },
  '2721-fe0f': {
    hexCodePoints: '2721-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✡️',
    version: 'E0.7',
    baseName: 'star of David',
    fullName: 'star of David',
    isBase: true,
    baseHex: '2721-fe0f',
    modifiers: null,
  },
  '2721': {
    hexCodePoints: '2721',
    qualifiedStatus: 'unqualified',
    emoji: '✡',
    version: 'E0.7',
    baseName: 'star of David',
    fullName: 'star of David',
    isBase: false,
    baseHex: '2721-fe0f',
    modifiers: null,
  },
  '2638-fe0f': {
    hexCodePoints: '2638-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☸️',
    version: 'E0.7',
    baseName: 'wheel of dharma',
    fullName: 'wheel of dharma',
    isBase: true,
    baseHex: '2638-fe0f',
    modifiers: null,
  },
  '2638': {
    hexCodePoints: '2638',
    qualifiedStatus: 'unqualified',
    emoji: '☸',
    version: 'E0.7',
    baseName: 'wheel of dharma',
    fullName: 'wheel of dharma',
    isBase: false,
    baseHex: '2638-fe0f',
    modifiers: null,
  },
  '262f-fe0f': {
    hexCodePoints: '262f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☯️',
    version: 'E0.7',
    baseName: 'yin yang',
    fullName: 'yin yang',
    isBase: true,
    baseHex: '262f-fe0f',
    modifiers: null,
  },
  '262f': {
    hexCodePoints: '262f',
    qualifiedStatus: 'unqualified',
    emoji: '☯',
    version: 'E0.7',
    baseName: 'yin yang',
    fullName: 'yin yang',
    isBase: false,
    baseHex: '262f-fe0f',
    modifiers: null,
  },
  '271d-fe0f': {
    hexCodePoints: '271d-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✝️',
    version: 'E0.7',
    baseName: 'latin cross',
    fullName: 'latin cross',
    isBase: true,
    baseHex: '271d-fe0f',
    modifiers: null,
  },
  '271d': {
    hexCodePoints: '271d',
    qualifiedStatus: 'unqualified',
    emoji: '✝',
    version: 'E0.7',
    baseName: 'latin cross',
    fullName: 'latin cross',
    isBase: false,
    baseHex: '271d-fe0f',
    modifiers: null,
  },
  '2626-fe0f': {
    hexCodePoints: '2626-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☦️',
    version: 'E1.0',
    baseName: 'orthodox cross',
    fullName: 'orthodox cross',
    isBase: true,
    baseHex: '2626-fe0f',
    modifiers: null,
  },
  '2626': {
    hexCodePoints: '2626',
    qualifiedStatus: 'unqualified',
    emoji: '☦',
    version: 'E1.0',
    baseName: 'orthodox cross',
    fullName: 'orthodox cross',
    isBase: false,
    baseHex: '2626-fe0f',
    modifiers: null,
  },
  '262a-fe0f': {
    hexCodePoints: '262a-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☪️',
    version: 'E0.7',
    baseName: 'star and crescent',
    fullName: 'star and crescent',
    isBase: true,
    baseHex: '262a-fe0f',
    modifiers: null,
  },
  '262a': {
    hexCodePoints: '262a',
    qualifiedStatus: 'unqualified',
    emoji: '☪',
    version: 'E0.7',
    baseName: 'star and crescent',
    fullName: 'star and crescent',
    isBase: false,
    baseHex: '262a-fe0f',
    modifiers: null,
  },
  '262e-fe0f': {
    hexCodePoints: '262e-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☮️',
    version: 'E1.0',
    baseName: 'peace symbol',
    fullName: 'peace symbol',
    isBase: true,
    baseHex: '262e-fe0f',
    modifiers: null,
  },
  '262e': {
    hexCodePoints: '262e',
    qualifiedStatus: 'unqualified',
    emoji: '☮',
    version: 'E1.0',
    baseName: 'peace symbol',
    fullName: 'peace symbol',
    isBase: false,
    baseHex: '262e-fe0f',
    modifiers: null,
  },
  '1f54e': {
    hexCodePoints: '1f54e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🕎',
    version: 'E1.0',
    baseName: 'menorah',
    fullName: 'menorah',
    isBase: true,
    baseHex: '1f54e',
    modifiers: null,
  },
  '1f52f': {
    hexCodePoints: '1f52f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔯',
    version: 'E0.6',
    baseName: 'dotted six-pointed star',
    fullName: 'dotted six-pointed star',
    isBase: true,
    baseHex: '1f52f',
    modifiers: null,
  },
  '1faaf': {
    hexCodePoints: '1faaf',
    qualifiedStatus: 'fully-qualified',
    emoji: '🪯',
    version: 'E15.0',
    baseName: 'khanda',
    fullName: 'khanda',
    isBase: true,
    baseHex: '1faaf',
    modifiers: null,
  },
  '2648': {
    hexCodePoints: '2648',
    qualifiedStatus: 'fully-qualified',
    emoji: '♈',
    version: 'E0.6',
    baseName: 'Aries',
    fullName: 'Aries',
    isBase: true,
    baseHex: '2648',
    modifiers: null,
  },
  '2649': {
    hexCodePoints: '2649',
    qualifiedStatus: 'fully-qualified',
    emoji: '♉',
    version: 'E0.6',
    baseName: 'Taurus',
    fullName: 'Taurus',
    isBase: true,
    baseHex: '2649',
    modifiers: null,
  },
  '264a': {
    hexCodePoints: '264a',
    qualifiedStatus: 'fully-qualified',
    emoji: '♊',
    version: 'E0.6',
    baseName: 'Gemini',
    fullName: 'Gemini',
    isBase: true,
    baseHex: '264a',
    modifiers: null,
  },
  '264b': {
    hexCodePoints: '264b',
    qualifiedStatus: 'fully-qualified',
    emoji: '♋',
    version: 'E0.6',
    baseName: 'Cancer',
    fullName: 'Cancer',
    isBase: true,
    baseHex: '264b',
    modifiers: null,
  },
  '264c': {
    hexCodePoints: '264c',
    qualifiedStatus: 'fully-qualified',
    emoji: '♌',
    version: 'E0.6',
    baseName: 'Leo',
    fullName: 'Leo',
    isBase: true,
    baseHex: '264c',
    modifiers: null,
  },
  '264d': {
    hexCodePoints: '264d',
    qualifiedStatus: 'fully-qualified',
    emoji: '♍',
    version: 'E0.6',
    baseName: 'Virgo',
    fullName: 'Virgo',
    isBase: true,
    baseHex: '264d',
    modifiers: null,
  },
  '264e': {
    hexCodePoints: '264e',
    qualifiedStatus: 'fully-qualified',
    emoji: '♎',
    version: 'E0.6',
    baseName: 'Libra',
    fullName: 'Libra',
    isBase: true,
    baseHex: '264e',
    modifiers: null,
  },
  '264f': {
    hexCodePoints: '264f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♏',
    version: 'E0.6',
    baseName: 'Scorpio',
    fullName: 'Scorpio',
    isBase: true,
    baseHex: '264f',
    modifiers: null,
  },
  '2650': {
    hexCodePoints: '2650',
    qualifiedStatus: 'fully-qualified',
    emoji: '♐',
    version: 'E0.6',
    baseName: 'Sagittarius',
    fullName: 'Sagittarius',
    isBase: true,
    baseHex: '2650',
    modifiers: null,
  },
  '2651': {
    hexCodePoints: '2651',
    qualifiedStatus: 'fully-qualified',
    emoji: '♑',
    version: 'E0.6',
    baseName: 'Capricorn',
    fullName: 'Capricorn',
    isBase: true,
    baseHex: '2651',
    modifiers: null,
  },
  '2652': {
    hexCodePoints: '2652',
    qualifiedStatus: 'fully-qualified',
    emoji: '♒',
    version: 'E0.6',
    baseName: 'Aquarius',
    fullName: 'Aquarius',
    isBase: true,
    baseHex: '2652',
    modifiers: null,
  },
  '2653': {
    hexCodePoints: '2653',
    qualifiedStatus: 'fully-qualified',
    emoji: '♓',
    version: 'E0.6',
    baseName: 'Pisces',
    fullName: 'Pisces',
    isBase: true,
    baseHex: '2653',
    modifiers: null,
  },
  '26ce': {
    hexCodePoints: '26ce',
    qualifiedStatus: 'fully-qualified',
    emoji: '⛎',
    version: 'E0.6',
    baseName: 'Ophiuchus',
    fullName: 'Ophiuchus',
    isBase: true,
    baseHex: '26ce',
    modifiers: null,
  },
  '1f500': {
    hexCodePoints: '1f500',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔀',
    version: 'E1.0',
    baseName: 'shuffle tracks button',
    fullName: 'shuffle tracks button',
    isBase: true,
    baseHex: '1f500',
    modifiers: null,
  },
  '1f501': {
    hexCodePoints: '1f501',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔁',
    version: 'E1.0',
    baseName: 'repeat button',
    fullName: 'repeat button',
    isBase: true,
    baseHex: '1f501',
    modifiers: null,
  },
  '1f502': {
    hexCodePoints: '1f502',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔂',
    version: 'E1.0',
    baseName: 'repeat single button',
    fullName: 'repeat single button',
    isBase: true,
    baseHex: '1f502',
    modifiers: null,
  },
  '25b6-fe0f': {
    hexCodePoints: '25b6-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '▶️',
    version: 'E0.6',
    baseName: 'play button',
    fullName: 'play button',
    isBase: true,
    baseHex: '25b6-fe0f',
    modifiers: null,
  },
  '25b6': {
    hexCodePoints: '25b6',
    qualifiedStatus: 'unqualified',
    emoji: '▶',
    version: 'E0.6',
    baseName: 'play button',
    fullName: 'play button',
    isBase: false,
    baseHex: '25b6-fe0f',
    modifiers: null,
  },
  '23e9': {
    hexCodePoints: '23e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏩',
    version: 'E0.6',
    baseName: 'fast-forward button',
    fullName: 'fast-forward button',
    isBase: true,
    baseHex: '23e9',
    modifiers: null,
  },
  '23ed-fe0f': {
    hexCodePoints: '23ed-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏭️',
    version: 'E0.7',
    baseName: 'next track button',
    fullName: 'next track button',
    isBase: true,
    baseHex: '23ed-fe0f',
    modifiers: null,
  },
  '23ed': {
    hexCodePoints: '23ed',
    qualifiedStatus: 'unqualified',
    emoji: '⏭',
    version: 'E0.7',
    baseName: 'next track button',
    fullName: 'next track button',
    isBase: false,
    baseHex: '23ed-fe0f',
    modifiers: null,
  },
  '23ef-fe0f': {
    hexCodePoints: '23ef-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏯️',
    version: 'E1.0',
    baseName: 'play or pause button',
    fullName: 'play or pause button',
    isBase: true,
    baseHex: '23ef-fe0f',
    modifiers: null,
  },
  '23ef': {
    hexCodePoints: '23ef',
    qualifiedStatus: 'unqualified',
    emoji: '⏯',
    version: 'E1.0',
    baseName: 'play or pause button',
    fullName: 'play or pause button',
    isBase: false,
    baseHex: '23ef-fe0f',
    modifiers: null,
  },
  '25c0-fe0f': {
    hexCodePoints: '25c0-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '◀️',
    version: 'E0.6',
    baseName: 'reverse button',
    fullName: 'reverse button',
    isBase: true,
    baseHex: '25c0-fe0f',
    modifiers: null,
  },
  '25c0': {
    hexCodePoints: '25c0',
    qualifiedStatus: 'unqualified',
    emoji: '◀',
    version: 'E0.6',
    baseName: 'reverse button',
    fullName: 'reverse button',
    isBase: false,
    baseHex: '25c0-fe0f',
    modifiers: null,
  },
  '23ea': {
    hexCodePoints: '23ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏪',
    version: 'E0.6',
    baseName: 'fast reverse button',
    fullName: 'fast reverse button',
    isBase: true,
    baseHex: '23ea',
    modifiers: null,
  },
  '23ee-fe0f': {
    hexCodePoints: '23ee-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏮️',
    version: 'E0.7',
    baseName: 'last track button',
    fullName: 'last track button',
    isBase: true,
    baseHex: '23ee-fe0f',
    modifiers: null,
  },
  '23ee': {
    hexCodePoints: '23ee',
    qualifiedStatus: 'unqualified',
    emoji: '⏮',
    version: 'E0.7',
    baseName: 'last track button',
    fullName: 'last track button',
    isBase: false,
    baseHex: '23ee-fe0f',
    modifiers: null,
  },
  '1f53c': {
    hexCodePoints: '1f53c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔼',
    version: 'E0.6',
    baseName: 'upwards button',
    fullName: 'upwards button',
    isBase: true,
    baseHex: '1f53c',
    modifiers: null,
  },
  '23eb': {
    hexCodePoints: '23eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏫',
    version: 'E0.6',
    baseName: 'fast up button',
    fullName: 'fast up button',
    isBase: true,
    baseHex: '23eb',
    modifiers: null,
  },
  '1f53d': {
    hexCodePoints: '1f53d',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔽',
    version: 'E0.6',
    baseName: 'downwards button',
    fullName: 'downwards button',
    isBase: true,
    baseHex: '1f53d',
    modifiers: null,
  },
  '23ec': {
    hexCodePoints: '23ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏬',
    version: 'E0.6',
    baseName: 'fast down button',
    fullName: 'fast down button',
    isBase: true,
    baseHex: '23ec',
    modifiers: null,
  },
  '23f8-fe0f': {
    hexCodePoints: '23f8-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏸️',
    version: 'E0.7',
    baseName: 'pause button',
    fullName: 'pause button',
    isBase: true,
    baseHex: '23f8-fe0f',
    modifiers: null,
  },
  '23f8': {
    hexCodePoints: '23f8',
    qualifiedStatus: 'unqualified',
    emoji: '⏸',
    version: 'E0.7',
    baseName: 'pause button',
    fullName: 'pause button',
    isBase: false,
    baseHex: '23f8-fe0f',
    modifiers: null,
  },
  '23f9-fe0f': {
    hexCodePoints: '23f9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏹️',
    version: 'E0.7',
    baseName: 'stop button',
    fullName: 'stop button',
    isBase: true,
    baseHex: '23f9-fe0f',
    modifiers: null,
  },
  '23f9': {
    hexCodePoints: '23f9',
    qualifiedStatus: 'unqualified',
    emoji: '⏹',
    version: 'E0.7',
    baseName: 'stop button',
    fullName: 'stop button',
    isBase: false,
    baseHex: '23f9-fe0f',
    modifiers: null,
  },
  '23fa-fe0f': {
    hexCodePoints: '23fa-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏺️',
    version: 'E0.7',
    baseName: 'record button',
    fullName: 'record button',
    isBase: true,
    baseHex: '23fa-fe0f',
    modifiers: null,
  },
  '23fa': {
    hexCodePoints: '23fa',
    qualifiedStatus: 'unqualified',
    emoji: '⏺',
    version: 'E0.7',
    baseName: 'record button',
    fullName: 'record button',
    isBase: false,
    baseHex: '23fa-fe0f',
    modifiers: null,
  },
  '23cf-fe0f': {
    hexCodePoints: '23cf-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⏏️',
    version: 'E1.0',
    baseName: 'eject button',
    fullName: 'eject button',
    isBase: true,
    baseHex: '23cf-fe0f',
    modifiers: null,
  },
  '23cf': {
    hexCodePoints: '23cf',
    qualifiedStatus: 'unqualified',
    emoji: '⏏',
    version: 'E1.0',
    baseName: 'eject button',
    fullName: 'eject button',
    isBase: false,
    baseHex: '23cf-fe0f',
    modifiers: null,
  },
  '1f3a6': {
    hexCodePoints: '1f3a6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎦',
    version: 'E0.6',
    baseName: 'cinema',
    fullName: 'cinema',
    isBase: true,
    baseHex: '1f3a6',
    modifiers: null,
  },
  '1f505': {
    hexCodePoints: '1f505',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔅',
    version: 'E1.0',
    baseName: 'dim button',
    fullName: 'dim button',
    isBase: true,
    baseHex: '1f505',
    modifiers: null,
  },
  '1f506': {
    hexCodePoints: '1f506',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔆',
    version: 'E1.0',
    baseName: 'bright button',
    fullName: 'bright button',
    isBase: true,
    baseHex: '1f506',
    modifiers: null,
  },
  '1f4f6': {
    hexCodePoints: '1f4f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '📶',
    version: 'E0.6',
    baseName: 'antenna bars',
    fullName: 'antenna bars',
    isBase: true,
    baseHex: '1f4f6',
    modifiers: null,
  },
  '1f6dc': {
    hexCodePoints: '1f6dc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🛜',
    version: 'E15.0',
    baseName: 'wireless',
    fullName: 'wireless',
    isBase: true,
    baseHex: '1f6dc',
    modifiers: null,
  },
  '1f4f3': {
    hexCodePoints: '1f4f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '📳',
    version: 'E0.6',
    baseName: 'vibration mode',
    fullName: 'vibration mode',
    isBase: true,
    baseHex: '1f4f3',
    modifiers: null,
  },
  '1f4f4': {
    hexCodePoints: '1f4f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '📴',
    version: 'E0.6',
    baseName: 'mobile phone off',
    fullName: 'mobile phone off',
    isBase: true,
    baseHex: '1f4f4',
    modifiers: null,
  },
  '2640-fe0f': {
    hexCodePoints: '2640-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♀️',
    version: 'E4.0',
    baseName: 'female sign',
    fullName: 'female sign',
    isBase: true,
    baseHex: '2640-fe0f',
    modifiers: null,
  },
  '2640': {
    hexCodePoints: '2640',
    qualifiedStatus: 'unqualified',
    emoji: '♀',
    version: 'E4.0',
    baseName: 'female sign',
    fullName: 'female sign',
    isBase: false,
    baseHex: '2640-fe0f',
    modifiers: null,
  },
  '2642-fe0f': {
    hexCodePoints: '2642-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♂️',
    version: 'E4.0',
    baseName: 'male sign',
    fullName: 'male sign',
    isBase: true,
    baseHex: '2642-fe0f',
    modifiers: null,
  },
  '2642': {
    hexCodePoints: '2642',
    qualifiedStatus: 'unqualified',
    emoji: '♂',
    version: 'E4.0',
    baseName: 'male sign',
    fullName: 'male sign',
    isBase: false,
    baseHex: '2642-fe0f',
    modifiers: null,
  },
  '26a7-fe0f': {
    hexCodePoints: '26a7-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚧️',
    version: 'E13.0',
    baseName: 'transgender symbol',
    fullName: 'transgender symbol',
    isBase: true,
    baseHex: '26a7-fe0f',
    modifiers: null,
  },
  '26a7': {
    hexCodePoints: '26a7',
    qualifiedStatus: 'unqualified',
    emoji: '⚧',
    version: 'E13.0',
    baseName: 'transgender symbol',
    fullName: 'transgender symbol',
    isBase: false,
    baseHex: '26a7-fe0f',
    modifiers: null,
  },
  '2716-fe0f': {
    hexCodePoints: '2716-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✖️',
    version: 'E0.6',
    baseName: 'multiply',
    fullName: 'multiply',
    isBase: true,
    baseHex: '2716-fe0f',
    modifiers: null,
  },
  '2716': {
    hexCodePoints: '2716',
    qualifiedStatus: 'unqualified',
    emoji: '✖',
    version: 'E0.6',
    baseName: 'multiply',
    fullName: 'multiply',
    isBase: false,
    baseHex: '2716-fe0f',
    modifiers: null,
  },
  '2795': {
    hexCodePoints: '2795',
    qualifiedStatus: 'fully-qualified',
    emoji: '➕',
    version: 'E0.6',
    baseName: 'plus',
    fullName: 'plus',
    isBase: true,
    baseHex: '2795',
    modifiers: null,
  },
  '2796': {
    hexCodePoints: '2796',
    qualifiedStatus: 'fully-qualified',
    emoji: '➖',
    version: 'E0.6',
    baseName: 'minus',
    fullName: 'minus',
    isBase: true,
    baseHex: '2796',
    modifiers: null,
  },
  '2797': {
    hexCodePoints: '2797',
    qualifiedStatus: 'fully-qualified',
    emoji: '➗',
    version: 'E0.6',
    baseName: 'divide',
    fullName: 'divide',
    isBase: true,
    baseHex: '2797',
    modifiers: null,
  },
  '1f7f0': {
    hexCodePoints: '1f7f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟰',
    version: 'E14.0',
    baseName: 'heavy equals sign',
    fullName: 'heavy equals sign',
    isBase: true,
    baseHex: '1f7f0',
    modifiers: null,
  },
  '267e-fe0f': {
    hexCodePoints: '267e-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♾️',
    version: 'E11.0',
    baseName: 'infinity',
    fullName: 'infinity',
    isBase: true,
    baseHex: '267e-fe0f',
    modifiers: null,
  },
  '267e': {
    hexCodePoints: '267e',
    qualifiedStatus: 'unqualified',
    emoji: '♾',
    version: 'E11.0',
    baseName: 'infinity',
    fullName: 'infinity',
    isBase: false,
    baseHex: '267e-fe0f',
    modifiers: null,
  },
  '203c-fe0f': {
    hexCodePoints: '203c-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '‼️',
    version: 'E0.6',
    baseName: 'double exclamation mark',
    fullName: 'double exclamation mark',
    isBase: true,
    baseHex: '203c-fe0f',
    modifiers: null,
  },
  '203c': {
    hexCodePoints: '203c',
    qualifiedStatus: 'unqualified',
    emoji: '‼',
    version: 'E0.6',
    baseName: 'double exclamation mark',
    fullName: 'double exclamation mark',
    isBase: false,
    baseHex: '203c-fe0f',
    modifiers: null,
  },
  '2049-fe0f': {
    hexCodePoints: '2049-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⁉️',
    version: 'E0.6',
    baseName: 'exclamation question mark',
    fullName: 'exclamation question mark',
    isBase: true,
    baseHex: '2049-fe0f',
    modifiers: null,
  },
  '2049': {
    hexCodePoints: '2049',
    qualifiedStatus: 'unqualified',
    emoji: '⁉',
    version: 'E0.6',
    baseName: 'exclamation question mark',
    fullName: 'exclamation question mark',
    isBase: false,
    baseHex: '2049-fe0f',
    modifiers: null,
  },
  '2753': {
    hexCodePoints: '2753',
    qualifiedStatus: 'fully-qualified',
    emoji: '❓',
    version: 'E0.6',
    baseName: 'red question mark',
    fullName: 'red question mark',
    isBase: true,
    baseHex: '2753',
    modifiers: null,
  },
  '2754': {
    hexCodePoints: '2754',
    qualifiedStatus: 'fully-qualified',
    emoji: '❔',
    version: 'E0.6',
    baseName: 'white question mark',
    fullName: 'white question mark',
    isBase: true,
    baseHex: '2754',
    modifiers: null,
  },
  '2755': {
    hexCodePoints: '2755',
    qualifiedStatus: 'fully-qualified',
    emoji: '❕',
    version: 'E0.6',
    baseName: 'white exclamation mark',
    fullName: 'white exclamation mark',
    isBase: true,
    baseHex: '2755',
    modifiers: null,
  },
  '2757': {
    hexCodePoints: '2757',
    qualifiedStatus: 'fully-qualified',
    emoji: '❗',
    version: 'E0.6',
    baseName: 'red exclamation mark',
    fullName: 'red exclamation mark',
    isBase: true,
    baseHex: '2757',
    modifiers: null,
  },
  '3030-fe0f': {
    hexCodePoints: '3030-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '〰️',
    version: 'E0.6',
    baseName: 'wavy dash',
    fullName: 'wavy dash',
    isBase: true,
    baseHex: '3030-fe0f',
    modifiers: null,
  },
  '3030': {
    hexCodePoints: '3030',
    qualifiedStatus: 'unqualified',
    emoji: '〰',
    version: 'E0.6',
    baseName: 'wavy dash',
    fullName: 'wavy dash',
    isBase: false,
    baseHex: '3030-fe0f',
    modifiers: null,
  },
  '1f4b1': {
    hexCodePoints: '1f4b1',
    qualifiedStatus: 'fully-qualified',
    emoji: '💱',
    version: 'E0.6',
    baseName: 'currency exchange',
    fullName: 'currency exchange',
    isBase: true,
    baseHex: '1f4b1',
    modifiers: null,
  },
  '1f4b2': {
    hexCodePoints: '1f4b2',
    qualifiedStatus: 'fully-qualified',
    emoji: '💲',
    version: 'E0.6',
    baseName: 'heavy dollar sign',
    fullName: 'heavy dollar sign',
    isBase: true,
    baseHex: '1f4b2',
    modifiers: null,
  },
  '2695-fe0f': {
    hexCodePoints: '2695-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚕️',
    version: 'E4.0',
    baseName: 'medical symbol',
    fullName: 'medical symbol',
    isBase: true,
    baseHex: '2695-fe0f',
    modifiers: null,
  },
  '2695': {
    hexCodePoints: '2695',
    qualifiedStatus: 'unqualified',
    emoji: '⚕',
    version: 'E4.0',
    baseName: 'medical symbol',
    fullName: 'medical symbol',
    isBase: false,
    baseHex: '2695-fe0f',
    modifiers: null,
  },
  '267b-fe0f': {
    hexCodePoints: '267b-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '♻️',
    version: 'E0.6',
    baseName: 'recycling symbol',
    fullName: 'recycling symbol',
    isBase: true,
    baseHex: '267b-fe0f',
    modifiers: null,
  },
  '267b': {
    hexCodePoints: '267b',
    qualifiedStatus: 'unqualified',
    emoji: '♻',
    version: 'E0.6',
    baseName: 'recycling symbol',
    fullName: 'recycling symbol',
    isBase: false,
    baseHex: '267b-fe0f',
    modifiers: null,
  },
  '269c-fe0f': {
    hexCodePoints: '269c-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚜️',
    version: 'E1.0',
    baseName: 'fleur-de-lis',
    fullName: 'fleur-de-lis',
    isBase: true,
    baseHex: '269c-fe0f',
    modifiers: null,
  },
  '269c': {
    hexCodePoints: '269c',
    qualifiedStatus: 'unqualified',
    emoji: '⚜',
    version: 'E1.0',
    baseName: 'fleur-de-lis',
    fullName: 'fleur-de-lis',
    isBase: false,
    baseHex: '269c-fe0f',
    modifiers: null,
  },
  '1f531': {
    hexCodePoints: '1f531',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔱',
    version: 'E0.6',
    baseName: 'trident emblem',
    fullName: 'trident emblem',
    isBase: true,
    baseHex: '1f531',
    modifiers: null,
  },
  '1f4db': {
    hexCodePoints: '1f4db',
    qualifiedStatus: 'fully-qualified',
    emoji: '📛',
    version: 'E0.6',
    baseName: 'name badge',
    fullName: 'name badge',
    isBase: true,
    baseHex: '1f4db',
    modifiers: null,
  },
  '1f530': {
    hexCodePoints: '1f530',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔰',
    version: 'E0.6',
    baseName: 'Japanese symbol for beginner',
    fullName: 'Japanese symbol for beginner',
    isBase: true,
    baseHex: '1f530',
    modifiers: null,
  },
  '2b55': {
    hexCodePoints: '2b55',
    qualifiedStatus: 'fully-qualified',
    emoji: '⭕',
    version: 'E0.6',
    baseName: 'hollow red circle',
    fullName: 'hollow red circle',
    isBase: true,
    baseHex: '2b55',
    modifiers: null,
  },
  '2705': {
    hexCodePoints: '2705',
    qualifiedStatus: 'fully-qualified',
    emoji: '✅',
    version: 'E0.6',
    baseName: 'check mark button',
    fullName: 'check mark button',
    isBase: true,
    baseHex: '2705',
    modifiers: null,
  },
  '2611-fe0f': {
    hexCodePoints: '2611-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '☑️',
    version: 'E0.6',
    baseName: 'check box with check',
    fullName: 'check box with check',
    isBase: true,
    baseHex: '2611-fe0f',
    modifiers: null,
  },
  '2611': {
    hexCodePoints: '2611',
    qualifiedStatus: 'unqualified',
    emoji: '☑',
    version: 'E0.6',
    baseName: 'check box with check',
    fullName: 'check box with check',
    isBase: false,
    baseHex: '2611-fe0f',
    modifiers: null,
  },
  '2714-fe0f': {
    hexCodePoints: '2714-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✔️',
    version: 'E0.6',
    baseName: 'check mark',
    fullName: 'check mark',
    isBase: true,
    baseHex: '2714-fe0f',
    modifiers: null,
  },
  '2714': {
    hexCodePoints: '2714',
    qualifiedStatus: 'unqualified',
    emoji: '✔',
    version: 'E0.6',
    baseName: 'check mark',
    fullName: 'check mark',
    isBase: false,
    baseHex: '2714-fe0f',
    modifiers: null,
  },
  '274c': {
    hexCodePoints: '274c',
    qualifiedStatus: 'fully-qualified',
    emoji: '❌',
    version: 'E0.6',
    baseName: 'cross mark',
    fullName: 'cross mark',
    isBase: true,
    baseHex: '274c',
    modifiers: null,
  },
  '274e': {
    hexCodePoints: '274e',
    qualifiedStatus: 'fully-qualified',
    emoji: '❎',
    version: 'E0.6',
    baseName: 'cross mark button',
    fullName: 'cross mark button',
    isBase: true,
    baseHex: '274e',
    modifiers: null,
  },
  '27b0': {
    hexCodePoints: '27b0',
    qualifiedStatus: 'fully-qualified',
    emoji: '➰',
    version: 'E0.6',
    baseName: 'curly loop',
    fullName: 'curly loop',
    isBase: true,
    baseHex: '27b0',
    modifiers: null,
  },
  '27bf': {
    hexCodePoints: '27bf',
    qualifiedStatus: 'fully-qualified',
    emoji: '➿',
    version: 'E1.0',
    baseName: 'double curly loop',
    fullName: 'double curly loop',
    isBase: true,
    baseHex: '27bf',
    modifiers: null,
  },
  '303d-fe0f': {
    hexCodePoints: '303d-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '〽️',
    version: 'E0.6',
    baseName: 'part alternation mark',
    fullName: 'part alternation mark',
    isBase: true,
    baseHex: '303d-fe0f',
    modifiers: null,
  },
  '303d': {
    hexCodePoints: '303d',
    qualifiedStatus: 'unqualified',
    emoji: '〽',
    version: 'E0.6',
    baseName: 'part alternation mark',
    fullName: 'part alternation mark',
    isBase: false,
    baseHex: '303d-fe0f',
    modifiers: null,
  },
  '2733-fe0f': {
    hexCodePoints: '2733-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✳️',
    version: 'E0.6',
    baseName: 'eight-spoked asterisk',
    fullName: 'eight-spoked asterisk',
    isBase: true,
    baseHex: '2733-fe0f',
    modifiers: null,
  },
  '2733': {
    hexCodePoints: '2733',
    qualifiedStatus: 'unqualified',
    emoji: '✳',
    version: 'E0.6',
    baseName: 'eight-spoked asterisk',
    fullName: 'eight-spoked asterisk',
    isBase: false,
    baseHex: '2733-fe0f',
    modifiers: null,
  },
  '2734-fe0f': {
    hexCodePoints: '2734-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '✴️',
    version: 'E0.6',
    baseName: 'eight-pointed star',
    fullName: 'eight-pointed star',
    isBase: true,
    baseHex: '2734-fe0f',
    modifiers: null,
  },
  '2734': {
    hexCodePoints: '2734',
    qualifiedStatus: 'unqualified',
    emoji: '✴',
    version: 'E0.6',
    baseName: 'eight-pointed star',
    fullName: 'eight-pointed star',
    isBase: false,
    baseHex: '2734-fe0f',
    modifiers: null,
  },
  '2747-fe0f': {
    hexCodePoints: '2747-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '❇️',
    version: 'E0.6',
    baseName: 'sparkle',
    fullName: 'sparkle',
    isBase: true,
    baseHex: '2747-fe0f',
    modifiers: null,
  },
  '2747': {
    hexCodePoints: '2747',
    qualifiedStatus: 'unqualified',
    emoji: '❇',
    version: 'E0.6',
    baseName: 'sparkle',
    fullName: 'sparkle',
    isBase: false,
    baseHex: '2747-fe0f',
    modifiers: null,
  },
  '00a9-fe0f': {
    hexCodePoints: '00a9-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '©️',
    version: 'E0.6',
    baseName: 'copyright',
    fullName: 'copyright',
    isBase: true,
    baseHex: '00a9-fe0f',
    modifiers: null,
  },
  '00a9': {
    hexCodePoints: '00a9',
    qualifiedStatus: 'unqualified',
    emoji: '©',
    version: 'E0.6',
    baseName: 'copyright',
    fullName: 'copyright',
    isBase: false,
    baseHex: '00a9-fe0f',
    modifiers: null,
  },
  '00ae-fe0f': {
    hexCodePoints: '00ae-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '®️',
    version: 'E0.6',
    baseName: 'registered',
    fullName: 'registered',
    isBase: true,
    baseHex: '00ae-fe0f',
    modifiers: null,
  },
  '00ae': {
    hexCodePoints: '00ae',
    qualifiedStatus: 'unqualified',
    emoji: '®',
    version: 'E0.6',
    baseName: 'registered',
    fullName: 'registered',
    isBase: false,
    baseHex: '00ae-fe0f',
    modifiers: null,
  },
  '2122-fe0f': {
    hexCodePoints: '2122-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '™️',
    version: 'E0.6',
    baseName: 'trade mark',
    fullName: 'trade mark',
    isBase: true,
    baseHex: '2122-fe0f',
    modifiers: null,
  },
  '2122': {
    hexCodePoints: '2122',
    qualifiedStatus: 'unqualified',
    emoji: '™',
    version: 'E0.6',
    baseName: 'trade mark',
    fullName: 'trade mark',
    isBase: false,
    baseHex: '2122-fe0f',
    modifiers: null,
  },
  '0023-fe0f-20e3': {
    hexCodePoints: '0023-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '#️⃣',
    version: 'E0.6',
    baseName: 'keycap: #',
    fullName: 'keycap: #',
    isBase: true,
    baseHex: '0023-fe0f-20e3',
    modifiers: null,
  },
  '0023-20e3': {
    hexCodePoints: '0023-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '#⃣',
    version: 'E0.6',
    baseName: 'keycap: #',
    fullName: 'keycap: #',
    isBase: false,
    baseHex: '0023-fe0f-20e3',
    modifiers: null,
  },
  '002a-fe0f-20e3': {
    hexCodePoints: '002a-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '*️⃣',
    version: 'E2.0',
    baseName: 'keycap: *',
    fullName: 'keycap: *',
    isBase: true,
    baseHex: '002a-fe0f-20e3',
    modifiers: null,
  },
  '002a-20e3': {
    hexCodePoints: '002a-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '*⃣',
    version: 'E2.0',
    baseName: 'keycap: *',
    fullName: 'keycap: *',
    isBase: false,
    baseHex: '002a-fe0f-20e3',
    modifiers: null,
  },
  '0030-fe0f-20e3': {
    hexCodePoints: '0030-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '0️⃣',
    version: 'E0.6',
    baseName: 'keycap: 0',
    fullName: 'keycap: 0',
    isBase: true,
    baseHex: '0030-fe0f-20e3',
    modifiers: null,
  },
  '0030-20e3': {
    hexCodePoints: '0030-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '0⃣',
    version: 'E0.6',
    baseName: 'keycap: 0',
    fullName: 'keycap: 0',
    isBase: false,
    baseHex: '0030-fe0f-20e3',
    modifiers: null,
  },
  '0031-fe0f-20e3': {
    hexCodePoints: '0031-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '1️⃣',
    version: 'E0.6',
    baseName: 'keycap: 1',
    fullName: 'keycap: 1',
    isBase: true,
    baseHex: '0031-fe0f-20e3',
    modifiers: null,
  },
  '0031-20e3': {
    hexCodePoints: '0031-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '1⃣',
    version: 'E0.6',
    baseName: 'keycap: 1',
    fullName: 'keycap: 1',
    isBase: false,
    baseHex: '0031-fe0f-20e3',
    modifiers: null,
  },
  '0032-fe0f-20e3': {
    hexCodePoints: '0032-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '2️⃣',
    version: 'E0.6',
    baseName: 'keycap: 2',
    fullName: 'keycap: 2',
    isBase: true,
    baseHex: '0032-fe0f-20e3',
    modifiers: null,
  },
  '0032-20e3': {
    hexCodePoints: '0032-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '2⃣',
    version: 'E0.6',
    baseName: 'keycap: 2',
    fullName: 'keycap: 2',
    isBase: false,
    baseHex: '0032-fe0f-20e3',
    modifiers: null,
  },
  '0033-fe0f-20e3': {
    hexCodePoints: '0033-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '3️⃣',
    version: 'E0.6',
    baseName: 'keycap: 3',
    fullName: 'keycap: 3',
    isBase: true,
    baseHex: '0033-fe0f-20e3',
    modifiers: null,
  },
  '0033-20e3': {
    hexCodePoints: '0033-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '3⃣',
    version: 'E0.6',
    baseName: 'keycap: 3',
    fullName: 'keycap: 3',
    isBase: false,
    baseHex: '0033-fe0f-20e3',
    modifiers: null,
  },
  '0034-fe0f-20e3': {
    hexCodePoints: '0034-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '4️⃣',
    version: 'E0.6',
    baseName: 'keycap: 4',
    fullName: 'keycap: 4',
    isBase: true,
    baseHex: '0034-fe0f-20e3',
    modifiers: null,
  },
  '0034-20e3': {
    hexCodePoints: '0034-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '4⃣',
    version: 'E0.6',
    baseName: 'keycap: 4',
    fullName: 'keycap: 4',
    isBase: false,
    baseHex: '0034-fe0f-20e3',
    modifiers: null,
  },
  '0035-fe0f-20e3': {
    hexCodePoints: '0035-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '5️⃣',
    version: 'E0.6',
    baseName: 'keycap: 5',
    fullName: 'keycap: 5',
    isBase: true,
    baseHex: '0035-fe0f-20e3',
    modifiers: null,
  },
  '0035-20e3': {
    hexCodePoints: '0035-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '5⃣',
    version: 'E0.6',
    baseName: 'keycap: 5',
    fullName: 'keycap: 5',
    isBase: false,
    baseHex: '0035-fe0f-20e3',
    modifiers: null,
  },
  '0036-fe0f-20e3': {
    hexCodePoints: '0036-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '6️⃣',
    version: 'E0.6',
    baseName: 'keycap: 6',
    fullName: 'keycap: 6',
    isBase: true,
    baseHex: '0036-fe0f-20e3',
    modifiers: null,
  },
  '0036-20e3': {
    hexCodePoints: '0036-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '6⃣',
    version: 'E0.6',
    baseName: 'keycap: 6',
    fullName: 'keycap: 6',
    isBase: false,
    baseHex: '0036-fe0f-20e3',
    modifiers: null,
  },
  '0037-fe0f-20e3': {
    hexCodePoints: '0037-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '7️⃣',
    version: 'E0.6',
    baseName: 'keycap: 7',
    fullName: 'keycap: 7',
    isBase: true,
    baseHex: '0037-fe0f-20e3',
    modifiers: null,
  },
  '0037-20e3': {
    hexCodePoints: '0037-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '7⃣',
    version: 'E0.6',
    baseName: 'keycap: 7',
    fullName: 'keycap: 7',
    isBase: false,
    baseHex: '0037-fe0f-20e3',
    modifiers: null,
  },
  '0038-fe0f-20e3': {
    hexCodePoints: '0038-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '8️⃣',
    version: 'E0.6',
    baseName: 'keycap: 8',
    fullName: 'keycap: 8',
    isBase: true,
    baseHex: '0038-fe0f-20e3',
    modifiers: null,
  },
  '0038-20e3': {
    hexCodePoints: '0038-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '8⃣',
    version: 'E0.6',
    baseName: 'keycap: 8',
    fullName: 'keycap: 8',
    isBase: false,
    baseHex: '0038-fe0f-20e3',
    modifiers: null,
  },
  '0039-fe0f-20e3': {
    hexCodePoints: '0039-fe0f-20e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '9️⃣',
    version: 'E0.6',
    baseName: 'keycap: 9',
    fullName: 'keycap: 9',
    isBase: true,
    baseHex: '0039-fe0f-20e3',
    modifiers: null,
  },
  '0039-20e3': {
    hexCodePoints: '0039-20e3',
    qualifiedStatus: 'unqualified',
    emoji: '9⃣',
    version: 'E0.6',
    baseName: 'keycap: 9',
    fullName: 'keycap: 9',
    isBase: false,
    baseHex: '0039-fe0f-20e3',
    modifiers: null,
  },
  '1f51f': {
    hexCodePoints: '1f51f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔟',
    version: 'E0.6',
    baseName: 'keycap: 10',
    fullName: 'keycap: 10',
    isBase: true,
    baseHex: '1f51f',
    modifiers: null,
  },
  '1f520': {
    hexCodePoints: '1f520',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔠',
    version: 'E0.6',
    baseName: 'input latin uppercase',
    fullName: 'input latin uppercase',
    isBase: true,
    baseHex: '1f520',
    modifiers: null,
  },
  '1f521': {
    hexCodePoints: '1f521',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔡',
    version: 'E0.6',
    baseName: 'input latin lowercase',
    fullName: 'input latin lowercase',
    isBase: true,
    baseHex: '1f521',
    modifiers: null,
  },
  '1f522': {
    hexCodePoints: '1f522',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔢',
    version: 'E0.6',
    baseName: 'input numbers',
    fullName: 'input numbers',
    isBase: true,
    baseHex: '1f522',
    modifiers: null,
  },
  '1f523': {
    hexCodePoints: '1f523',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔣',
    version: 'E0.6',
    baseName: 'input symbols',
    fullName: 'input symbols',
    isBase: true,
    baseHex: '1f523',
    modifiers: null,
  },
  '1f524': {
    hexCodePoints: '1f524',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔤',
    version: 'E0.6',
    baseName: 'input latin letters',
    fullName: 'input latin letters',
    isBase: true,
    baseHex: '1f524',
    modifiers: null,
  },
  '1f170-fe0f': {
    hexCodePoints: '1f170-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🅰️',
    version: 'E0.6',
    baseName: 'A button (blood type)',
    fullName: 'A button (blood type)',
    isBase: true,
    baseHex: '1f170-fe0f',
    modifiers: null,
  },
  '1f170': {
    hexCodePoints: '1f170',
    qualifiedStatus: 'unqualified',
    emoji: '🅰',
    version: 'E0.6',
    baseName: 'A button (blood type)',
    fullName: 'A button (blood type)',
    isBase: false,
    baseHex: '1f170-fe0f',
    modifiers: null,
  },
  '1f18e': {
    hexCodePoints: '1f18e',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆎',
    version: 'E0.6',
    baseName: 'AB button (blood type)',
    fullName: 'AB button (blood type)',
    isBase: true,
    baseHex: '1f18e',
    modifiers: null,
  },
  '1f171-fe0f': {
    hexCodePoints: '1f171-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🅱️',
    version: 'E0.6',
    baseName: 'B button (blood type)',
    fullName: 'B button (blood type)',
    isBase: true,
    baseHex: '1f171-fe0f',
    modifiers: null,
  },
  '1f171': {
    hexCodePoints: '1f171',
    qualifiedStatus: 'unqualified',
    emoji: '🅱',
    version: 'E0.6',
    baseName: 'B button (blood type)',
    fullName: 'B button (blood type)',
    isBase: false,
    baseHex: '1f171-fe0f',
    modifiers: null,
  },
  '1f191': {
    hexCodePoints: '1f191',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆑',
    version: 'E0.6',
    baseName: 'CL button',
    fullName: 'CL button',
    isBase: true,
    baseHex: '1f191',
    modifiers: null,
  },
  '1f192': {
    hexCodePoints: '1f192',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆒',
    version: 'E0.6',
    baseName: 'COOL button',
    fullName: 'COOL button',
    isBase: true,
    baseHex: '1f192',
    modifiers: null,
  },
  '1f193': {
    hexCodePoints: '1f193',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆓',
    version: 'E0.6',
    baseName: 'FREE button',
    fullName: 'FREE button',
    isBase: true,
    baseHex: '1f193',
    modifiers: null,
  },
  '2139-fe0f': {
    hexCodePoints: '2139-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: 'ℹ️',
    version: 'E0.6',
    baseName: 'information',
    fullName: 'information',
    isBase: true,
    baseHex: '2139-fe0f',
    modifiers: null,
  },
  '2139': {
    hexCodePoints: '2139',
    qualifiedStatus: 'unqualified',
    emoji: 'ℹ',
    version: 'E0.6',
    baseName: 'information',
    fullName: 'information',
    isBase: false,
    baseHex: '2139-fe0f',
    modifiers: null,
  },
  '1f194': {
    hexCodePoints: '1f194',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆔',
    version: 'E0.6',
    baseName: 'ID button',
    fullName: 'ID button',
    isBase: true,
    baseHex: '1f194',
    modifiers: null,
  },
  '24c2-fe0f': {
    hexCodePoints: '24c2-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: 'Ⓜ️',
    version: 'E0.6',
    baseName: 'circled M',
    fullName: 'circled M',
    isBase: true,
    baseHex: '24c2-fe0f',
    modifiers: null,
  },
  '24c2': {
    hexCodePoints: '24c2',
    qualifiedStatus: 'unqualified',
    emoji: 'Ⓜ',
    version: 'E0.6',
    baseName: 'circled M',
    fullName: 'circled M',
    isBase: false,
    baseHex: '24c2-fe0f',
    modifiers: null,
  },
  '1f195': {
    hexCodePoints: '1f195',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆕',
    version: 'E0.6',
    baseName: 'NEW button',
    fullName: 'NEW button',
    isBase: true,
    baseHex: '1f195',
    modifiers: null,
  },
  '1f196': {
    hexCodePoints: '1f196',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆖',
    version: 'E0.6',
    baseName: 'NG button',
    fullName: 'NG button',
    isBase: true,
    baseHex: '1f196',
    modifiers: null,
  },
  '1f17e-fe0f': {
    hexCodePoints: '1f17e-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🅾️',
    version: 'E0.6',
    baseName: 'O button (blood type)',
    fullName: 'O button (blood type)',
    isBase: true,
    baseHex: '1f17e-fe0f',
    modifiers: null,
  },
  '1f17e': {
    hexCodePoints: '1f17e',
    qualifiedStatus: 'unqualified',
    emoji: '🅾',
    version: 'E0.6',
    baseName: 'O button (blood type)',
    fullName: 'O button (blood type)',
    isBase: false,
    baseHex: '1f17e-fe0f',
    modifiers: null,
  },
  '1f197': {
    hexCodePoints: '1f197',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆗',
    version: 'E0.6',
    baseName: 'OK button',
    fullName: 'OK button',
    isBase: true,
    baseHex: '1f197',
    modifiers: null,
  },
  '1f17f-fe0f': {
    hexCodePoints: '1f17f-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🅿️',
    version: 'E0.6',
    baseName: 'P button',
    fullName: 'P button',
    isBase: true,
    baseHex: '1f17f-fe0f',
    modifiers: null,
  },
  '1f17f': {
    hexCodePoints: '1f17f',
    qualifiedStatus: 'unqualified',
    emoji: '🅿',
    version: 'E0.6',
    baseName: 'P button',
    fullName: 'P button',
    isBase: false,
    baseHex: '1f17f-fe0f',
    modifiers: null,
  },
  '1f198': {
    hexCodePoints: '1f198',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆘',
    version: 'E0.6',
    baseName: 'SOS button',
    fullName: 'SOS button',
    isBase: true,
    baseHex: '1f198',
    modifiers: null,
  },
  '1f199': {
    hexCodePoints: '1f199',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆙',
    version: 'E0.6',
    baseName: 'UP! button',
    fullName: 'UP! button',
    isBase: true,
    baseHex: '1f199',
    modifiers: null,
  },
  '1f19a': {
    hexCodePoints: '1f19a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🆚',
    version: 'E0.6',
    baseName: 'VS button',
    fullName: 'VS button',
    isBase: true,
    baseHex: '1f19a',
    modifiers: null,
  },
  '1f201': {
    hexCodePoints: '1f201',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈁',
    version: 'E0.6',
    baseName: 'Japanese “here” button',
    fullName: 'Japanese “here” button',
    isBase: true,
    baseHex: '1f201',
    modifiers: null,
  },
  '1f202-fe0f': {
    hexCodePoints: '1f202-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈂️',
    version: 'E0.6',
    baseName: 'Japanese “service charge” button',
    fullName: 'Japanese “service charge” button',
    isBase: true,
    baseHex: '1f202-fe0f',
    modifiers: null,
  },
  '1f202': {
    hexCodePoints: '1f202',
    qualifiedStatus: 'unqualified',
    emoji: '🈂',
    version: 'E0.6',
    baseName: 'Japanese “service charge” button',
    fullName: 'Japanese “service charge” button',
    isBase: false,
    baseHex: '1f202-fe0f',
    modifiers: null,
  },
  '1f237-fe0f': {
    hexCodePoints: '1f237-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈷️',
    version: 'E0.6',
    baseName: 'Japanese “monthly amount” button',
    fullName: 'Japanese “monthly amount” button',
    isBase: true,
    baseHex: '1f237-fe0f',
    modifiers: null,
  },
  '1f237': {
    hexCodePoints: '1f237',
    qualifiedStatus: 'unqualified',
    emoji: '🈷',
    version: 'E0.6',
    baseName: 'Japanese “monthly amount” button',
    fullName: 'Japanese “monthly amount” button',
    isBase: false,
    baseHex: '1f237-fe0f',
    modifiers: null,
  },
  '1f236': {
    hexCodePoints: '1f236',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈶',
    version: 'E0.6',
    baseName: 'Japanese “not free of charge” button',
    fullName: 'Japanese “not free of charge” button',
    isBase: true,
    baseHex: '1f236',
    modifiers: null,
  },
  '1f22f': {
    hexCodePoints: '1f22f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈯',
    version: 'E0.6',
    baseName: 'Japanese “reserved” button',
    fullName: 'Japanese “reserved” button',
    isBase: true,
    baseHex: '1f22f',
    modifiers: null,
  },
  '1f250': {
    hexCodePoints: '1f250',
    qualifiedStatus: 'fully-qualified',
    emoji: '🉐',
    version: 'E0.6',
    baseName: 'Japanese “bargain” button',
    fullName: 'Japanese “bargain” button',
    isBase: true,
    baseHex: '1f250',
    modifiers: null,
  },
  '1f239': {
    hexCodePoints: '1f239',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈹',
    version: 'E0.6',
    baseName: 'Japanese “discount” button',
    fullName: 'Japanese “discount” button',
    isBase: true,
    baseHex: '1f239',
    modifiers: null,
  },
  '1f21a': {
    hexCodePoints: '1f21a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈚',
    version: 'E0.6',
    baseName: 'Japanese “free of charge” button',
    fullName: 'Japanese “free of charge” button',
    isBase: true,
    baseHex: '1f21a',
    modifiers: null,
  },
  '1f232': {
    hexCodePoints: '1f232',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈲',
    version: 'E0.6',
    baseName: 'Japanese “prohibited” button',
    fullName: 'Japanese “prohibited” button',
    isBase: true,
    baseHex: '1f232',
    modifiers: null,
  },
  '1f251': {
    hexCodePoints: '1f251',
    qualifiedStatus: 'fully-qualified',
    emoji: '🉑',
    version: 'E0.6',
    baseName: 'Japanese “acceptable” button',
    fullName: 'Japanese “acceptable” button',
    isBase: true,
    baseHex: '1f251',
    modifiers: null,
  },
  '1f238': {
    hexCodePoints: '1f238',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈸',
    version: 'E0.6',
    baseName: 'Japanese “application” button',
    fullName: 'Japanese “application” button',
    isBase: true,
    baseHex: '1f238',
    modifiers: null,
  },
  '1f234': {
    hexCodePoints: '1f234',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈴',
    version: 'E0.6',
    baseName: 'Japanese “passing grade” button',
    fullName: 'Japanese “passing grade” button',
    isBase: true,
    baseHex: '1f234',
    modifiers: null,
  },
  '1f233': {
    hexCodePoints: '1f233',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈳',
    version: 'E0.6',
    baseName: 'Japanese “vacancy” button',
    fullName: 'Japanese “vacancy” button',
    isBase: true,
    baseHex: '1f233',
    modifiers: null,
  },
  '3297-fe0f': {
    hexCodePoints: '3297-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '㊗️',
    version: 'E0.6',
    baseName: 'Japanese “congratulations” button',
    fullName: 'Japanese “congratulations” button',
    isBase: true,
    baseHex: '3297-fe0f',
    modifiers: null,
  },
  '3297': {
    hexCodePoints: '3297',
    qualifiedStatus: 'unqualified',
    emoji: '㊗',
    version: 'E0.6',
    baseName: 'Japanese “congratulations” button',
    fullName: 'Japanese “congratulations” button',
    isBase: false,
    baseHex: '3297-fe0f',
    modifiers: null,
  },
  '3299-fe0f': {
    hexCodePoints: '3299-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '㊙️',
    version: 'E0.6',
    baseName: 'Japanese “secret” button',
    fullName: 'Japanese “secret” button',
    isBase: true,
    baseHex: '3299-fe0f',
    modifiers: null,
  },
  '3299': {
    hexCodePoints: '3299',
    qualifiedStatus: 'unqualified',
    emoji: '㊙',
    version: 'E0.6',
    baseName: 'Japanese “secret” button',
    fullName: 'Japanese “secret” button',
    isBase: false,
    baseHex: '3299-fe0f',
    modifiers: null,
  },
  '1f23a': {
    hexCodePoints: '1f23a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈺',
    version: 'E0.6',
    baseName: 'Japanese “open for business” button',
    fullName: 'Japanese “open for business” button',
    isBase: true,
    baseHex: '1f23a',
    modifiers: null,
  },
  '1f235': {
    hexCodePoints: '1f235',
    qualifiedStatus: 'fully-qualified',
    emoji: '🈵',
    version: 'E0.6',
    baseName: 'Japanese “no vacancy” button',
    fullName: 'Japanese “no vacancy” button',
    isBase: true,
    baseHex: '1f235',
    modifiers: null,
  },
  '1f534': {
    hexCodePoints: '1f534',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔴',
    version: 'E0.6',
    baseName: 'red circle',
    fullName: 'red circle',
    isBase: true,
    baseHex: '1f534',
    modifiers: null,
  },
  '1f7e0': {
    hexCodePoints: '1f7e0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟠',
    version: 'E12.0',
    baseName: 'orange circle',
    fullName: 'orange circle',
    isBase: true,
    baseHex: '1f7e0',
    modifiers: null,
  },
  '1f7e1': {
    hexCodePoints: '1f7e1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟡',
    version: 'E12.0',
    baseName: 'yellow circle',
    fullName: 'yellow circle',
    isBase: true,
    baseHex: '1f7e1',
    modifiers: null,
  },
  '1f7e2': {
    hexCodePoints: '1f7e2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟢',
    version: 'E12.0',
    baseName: 'green circle',
    fullName: 'green circle',
    isBase: true,
    baseHex: '1f7e2',
    modifiers: null,
  },
  '1f535': {
    hexCodePoints: '1f535',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔵',
    version: 'E0.6',
    baseName: 'blue circle',
    fullName: 'blue circle',
    isBase: true,
    baseHex: '1f535',
    modifiers: null,
  },
  '1f7e3': {
    hexCodePoints: '1f7e3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟣',
    version: 'E12.0',
    baseName: 'purple circle',
    fullName: 'purple circle',
    isBase: true,
    baseHex: '1f7e3',
    modifiers: null,
  },
  '1f7e4': {
    hexCodePoints: '1f7e4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟤',
    version: 'E12.0',
    baseName: 'brown circle',
    fullName: 'brown circle',
    isBase: true,
    baseHex: '1f7e4',
    modifiers: null,
  },
  '26ab': {
    hexCodePoints: '26ab',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚫',
    version: 'E0.6',
    baseName: 'black circle',
    fullName: 'black circle',
    isBase: true,
    baseHex: '26ab',
    modifiers: null,
  },
  '26aa': {
    hexCodePoints: '26aa',
    qualifiedStatus: 'fully-qualified',
    emoji: '⚪',
    version: 'E0.6',
    baseName: 'white circle',
    fullName: 'white circle',
    isBase: true,
    baseHex: '26aa',
    modifiers: null,
  },
  '1f7e5': {
    hexCodePoints: '1f7e5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟥',
    version: 'E12.0',
    baseName: 'red square',
    fullName: 'red square',
    isBase: true,
    baseHex: '1f7e5',
    modifiers: null,
  },
  '1f7e7': {
    hexCodePoints: '1f7e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟧',
    version: 'E12.0',
    baseName: 'orange square',
    fullName: 'orange square',
    isBase: true,
    baseHex: '1f7e7',
    modifiers: null,
  },
  '1f7e8': {
    hexCodePoints: '1f7e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟨',
    version: 'E12.0',
    baseName: 'yellow square',
    fullName: 'yellow square',
    isBase: true,
    baseHex: '1f7e8',
    modifiers: null,
  },
  '1f7e9': {
    hexCodePoints: '1f7e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟩',
    version: 'E12.0',
    baseName: 'green square',
    fullName: 'green square',
    isBase: true,
    baseHex: '1f7e9',
    modifiers: null,
  },
  '1f7e6': {
    hexCodePoints: '1f7e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟦',
    version: 'E12.0',
    baseName: 'blue square',
    fullName: 'blue square',
    isBase: true,
    baseHex: '1f7e6',
    modifiers: null,
  },
  '1f7ea': {
    hexCodePoints: '1f7ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟪',
    version: 'E12.0',
    baseName: 'purple square',
    fullName: 'purple square',
    isBase: true,
    baseHex: '1f7ea',
    modifiers: null,
  },
  '1f7eb': {
    hexCodePoints: '1f7eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🟫',
    version: 'E12.0',
    baseName: 'brown square',
    fullName: 'brown square',
    isBase: true,
    baseHex: '1f7eb',
    modifiers: null,
  },
  '2b1b': {
    hexCodePoints: '2b1b',
    qualifiedStatus: 'fully-qualified',
    emoji: '⬛',
    version: 'E0.6',
    baseName: 'black large square',
    fullName: 'black large square',
    isBase: true,
    baseHex: '2b1b',
    modifiers: null,
  },
  '2b1c': {
    hexCodePoints: '2b1c',
    qualifiedStatus: 'fully-qualified',
    emoji: '⬜',
    version: 'E0.6',
    baseName: 'white large square',
    fullName: 'white large square',
    isBase: true,
    baseHex: '2b1c',
    modifiers: null,
  },
  '25fc-fe0f': {
    hexCodePoints: '25fc-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '◼️',
    version: 'E0.6',
    baseName: 'black medium square',
    fullName: 'black medium square',
    isBase: true,
    baseHex: '25fc-fe0f',
    modifiers: null,
  },
  '25fc': {
    hexCodePoints: '25fc',
    qualifiedStatus: 'unqualified',
    emoji: '◼',
    version: 'E0.6',
    baseName: 'black medium square',
    fullName: 'black medium square',
    isBase: false,
    baseHex: '25fc-fe0f',
    modifiers: null,
  },
  '25fb-fe0f': {
    hexCodePoints: '25fb-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '◻️',
    version: 'E0.6',
    baseName: 'white medium square',
    fullName: 'white medium square',
    isBase: true,
    baseHex: '25fb-fe0f',
    modifiers: null,
  },
  '25fb': {
    hexCodePoints: '25fb',
    qualifiedStatus: 'unqualified',
    emoji: '◻',
    version: 'E0.6',
    baseName: 'white medium square',
    fullName: 'white medium square',
    isBase: false,
    baseHex: '25fb-fe0f',
    modifiers: null,
  },
  '25fe': {
    hexCodePoints: '25fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '◾',
    version: 'E0.6',
    baseName: 'black medium-small square',
    fullName: 'black medium-small square',
    isBase: true,
    baseHex: '25fe',
    modifiers: null,
  },
  '25fd': {
    hexCodePoints: '25fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '◽',
    version: 'E0.6',
    baseName: 'white medium-small square',
    fullName: 'white medium-small square',
    isBase: true,
    baseHex: '25fd',
    modifiers: null,
  },
  '25aa-fe0f': {
    hexCodePoints: '25aa-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '▪️',
    version: 'E0.6',
    baseName: 'black small square',
    fullName: 'black small square',
    isBase: true,
    baseHex: '25aa-fe0f',
    modifiers: null,
  },
  '25aa': {
    hexCodePoints: '25aa',
    qualifiedStatus: 'unqualified',
    emoji: '▪',
    version: 'E0.6',
    baseName: 'black small square',
    fullName: 'black small square',
    isBase: false,
    baseHex: '25aa-fe0f',
    modifiers: null,
  },
  '25ab-fe0f': {
    hexCodePoints: '25ab-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '▫️',
    version: 'E0.6',
    baseName: 'white small square',
    fullName: 'white small square',
    isBase: true,
    baseHex: '25ab-fe0f',
    modifiers: null,
  },
  '25ab': {
    hexCodePoints: '25ab',
    qualifiedStatus: 'unqualified',
    emoji: '▫',
    version: 'E0.6',
    baseName: 'white small square',
    fullName: 'white small square',
    isBase: false,
    baseHex: '25ab-fe0f',
    modifiers: null,
  },
  '1f536': {
    hexCodePoints: '1f536',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔶',
    version: 'E0.6',
    baseName: 'large orange diamond',
    fullName: 'large orange diamond',
    isBase: true,
    baseHex: '1f536',
    modifiers: null,
  },
  '1f537': {
    hexCodePoints: '1f537',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔷',
    version: 'E0.6',
    baseName: 'large blue diamond',
    fullName: 'large blue diamond',
    isBase: true,
    baseHex: '1f537',
    modifiers: null,
  },
  '1f538': {
    hexCodePoints: '1f538',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔸',
    version: 'E0.6',
    baseName: 'small orange diamond',
    fullName: 'small orange diamond',
    isBase: true,
    baseHex: '1f538',
    modifiers: null,
  },
  '1f539': {
    hexCodePoints: '1f539',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔹',
    version: 'E0.6',
    baseName: 'small blue diamond',
    fullName: 'small blue diamond',
    isBase: true,
    baseHex: '1f539',
    modifiers: null,
  },
  '1f53a': {
    hexCodePoints: '1f53a',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔺',
    version: 'E0.6',
    baseName: 'red triangle pointed up',
    fullName: 'red triangle pointed up',
    isBase: true,
    baseHex: '1f53a',
    modifiers: null,
  },
  '1f53b': {
    hexCodePoints: '1f53b',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔻',
    version: 'E0.6',
    baseName: 'red triangle pointed down',
    fullName: 'red triangle pointed down',
    isBase: true,
    baseHex: '1f53b',
    modifiers: null,
  },
  '1f4a0': {
    hexCodePoints: '1f4a0',
    qualifiedStatus: 'fully-qualified',
    emoji: '💠',
    version: 'E0.6',
    baseName: 'diamond with a dot',
    fullName: 'diamond with a dot',
    isBase: true,
    baseHex: '1f4a0',
    modifiers: null,
  },
  '1f518': {
    hexCodePoints: '1f518',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔘',
    version: 'E0.6',
    baseName: 'radio button',
    fullName: 'radio button',
    isBase: true,
    baseHex: '1f518',
    modifiers: null,
  },
  '1f533': {
    hexCodePoints: '1f533',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔳',
    version: 'E0.6',
    baseName: 'white square button',
    fullName: 'white square button',
    isBase: true,
    baseHex: '1f533',
    modifiers: null,
  },
  '1f532': {
    hexCodePoints: '1f532',
    qualifiedStatus: 'fully-qualified',
    emoji: '🔲',
    version: 'E0.6',
    baseName: 'black square button',
    fullName: 'black square button',
    isBase: true,
    baseHex: '1f532',
    modifiers: null,
  },
  '1f3c1': {
    hexCodePoints: '1f3c1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏁',
    version: 'E0.6',
    baseName: 'chequered flag',
    fullName: 'chequered flag',
    isBase: true,
    baseHex: '1f3c1',
    modifiers: null,
  },
  '1f6a9': {
    hexCodePoints: '1f6a9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🚩',
    version: 'E0.6',
    baseName: 'triangular flag',
    fullName: 'triangular flag',
    isBase: true,
    baseHex: '1f6a9',
    modifiers: null,
  },
  '1f38c': {
    hexCodePoints: '1f38c',
    qualifiedStatus: 'fully-qualified',
    emoji: '🎌',
    version: 'E0.6',
    baseName: 'crossed flags',
    fullName: 'crossed flags',
    isBase: true,
    baseHex: '1f38c',
    modifiers: null,
  },
  '1f3f4': {
    hexCodePoints: '1f3f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏴',
    version: 'E1.0',
    baseName: 'black flag',
    fullName: 'black flag',
    isBase: true,
    baseHex: '1f3f4',
    modifiers: null,
  },
  '1f3f3-fe0f': {
    hexCodePoints: '1f3f3-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏳️',
    version: 'E0.7',
    baseName: 'white flag',
    fullName: 'white flag',
    isBase: true,
    baseHex: '1f3f3-fe0f',
    modifiers: null,
  },
  '1f3f3': {
    hexCodePoints: '1f3f3',
    qualifiedStatus: 'unqualified',
    emoji: '🏳',
    version: 'E0.7',
    baseName: 'white flag',
    fullName: 'white flag',
    isBase: false,
    baseHex: '1f3f3-fe0f',
    modifiers: null,
  },
  '1f3f3-fe0f-200d-1f308': {
    hexCodePoints: '1f3f3-fe0f-200d-1f308',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏳️‍🌈',
    version: 'E4.0',
    baseName: 'rainbow flag',
    fullName: 'rainbow flag',
    isBase: true,
    baseHex: '1f3f3-fe0f-200d-1f308',
    modifiers: null,
  },
  '1f3f3-200d-1f308': {
    hexCodePoints: '1f3f3-200d-1f308',
    qualifiedStatus: 'unqualified',
    emoji: '🏳‍🌈',
    version: 'E4.0',
    baseName: 'rainbow flag',
    fullName: 'rainbow flag',
    isBase: false,
    baseHex: '1f3f3-fe0f-200d-1f308',
    modifiers: null,
  },
  '1f3f3-fe0f-200d-26a7-fe0f': {
    hexCodePoints: '1f3f3-fe0f-200d-26a7-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏳️‍⚧️',
    version: 'E13.0',
    baseName: 'transgender flag',
    fullName: 'transgender flag',
    isBase: true,
    baseHex: '1f3f3-fe0f-200d-26a7-fe0f',
    modifiers: null,
  },
  '1f3f3-200d-26a7-fe0f': {
    hexCodePoints: '1f3f3-200d-26a7-fe0f',
    qualifiedStatus: 'unqualified',
    emoji: '🏳‍⚧️',
    version: 'E13.0',
    baseName: 'transgender flag',
    fullName: 'transgender flag',
    isBase: false,
    baseHex: '1f3f3-fe0f-200d-26a7-fe0f',
    modifiers: null,
  },
  '1f3f3-fe0f-200d-26a7': {
    hexCodePoints: '1f3f3-fe0f-200d-26a7',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏳️‍⚧',
    version: 'E13.0',
    baseName: 'transgender flag',
    fullName: 'transgender flag',
    isBase: false,
    baseHex: '1f3f3-fe0f-200d-26a7-fe0f',
    modifiers: null,
  },
  '1f3f3-200d-26a7': {
    hexCodePoints: '1f3f3-200d-26a7',
    qualifiedStatus: 'unqualified',
    emoji: '🏳‍⚧',
    version: 'E13.0',
    baseName: 'transgender flag',
    fullName: 'transgender flag',
    isBase: false,
    baseHex: '1f3f3-fe0f-200d-26a7-fe0f',
    modifiers: null,
  },
  '1f3f4-200d-2620-fe0f': {
    hexCodePoints: '1f3f4-200d-2620-fe0f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏴‍☠️',
    version: 'E11.0',
    baseName: 'pirate flag',
    fullName: 'pirate flag',
    isBase: true,
    baseHex: '1f3f4-200d-2620-fe0f',
    modifiers: null,
  },
  '1f3f4-200d-2620': {
    hexCodePoints: '1f3f4-200d-2620',
    qualifiedStatus: 'minimally-qualified',
    emoji: '🏴‍☠',
    version: 'E11.0',
    baseName: 'pirate flag',
    fullName: 'pirate flag',
    isBase: false,
    baseHex: '1f3f4-200d-2620-fe0f',
    modifiers: null,
  },
  '1f1e6-1f1e8': {
    hexCodePoints: '1f1e6-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇨',
    version: 'E2.0',
    baseName: 'flag: Ascension Island',
    fullName: 'flag: Ascension Island',
    isBase: true,
    baseHex: '1f1e6-1f1e8',
    modifiers: null,
  },
  '1f1e6-1f1e9': {
    hexCodePoints: '1f1e6-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇩',
    version: 'E2.0',
    baseName: 'flag: Andorra',
    fullName: 'flag: Andorra',
    isBase: true,
    baseHex: '1f1e6-1f1e9',
    modifiers: null,
  },
  '1f1e6-1f1ea': {
    hexCodePoints: '1f1e6-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇪',
    version: 'E2.0',
    baseName: 'flag: United Arab Emirates',
    fullName: 'flag: United Arab Emirates',
    isBase: true,
    baseHex: '1f1e6-1f1ea',
    modifiers: null,
  },
  '1f1e6-1f1eb': {
    hexCodePoints: '1f1e6-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇫',
    version: 'E2.0',
    baseName: 'flag: Afghanistan',
    fullName: 'flag: Afghanistan',
    isBase: true,
    baseHex: '1f1e6-1f1eb',
    modifiers: null,
  },
  '1f1e6-1f1ec': {
    hexCodePoints: '1f1e6-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇬',
    version: 'E2.0',
    baseName: 'flag: Antigua & Barbuda',
    fullName: 'flag: Antigua & Barbuda',
    isBase: true,
    baseHex: '1f1e6-1f1ec',
    modifiers: null,
  },
  '1f1e6-1f1ee': {
    hexCodePoints: '1f1e6-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇮',
    version: 'E2.0',
    baseName: 'flag: Anguilla',
    fullName: 'flag: Anguilla',
    isBase: true,
    baseHex: '1f1e6-1f1ee',
    modifiers: null,
  },
  '1f1e6-1f1f1': {
    hexCodePoints: '1f1e6-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇱',
    version: 'E2.0',
    baseName: 'flag: Albania',
    fullName: 'flag: Albania',
    isBase: true,
    baseHex: '1f1e6-1f1f1',
    modifiers: null,
  },
  '1f1e6-1f1f2': {
    hexCodePoints: '1f1e6-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇲',
    version: 'E2.0',
    baseName: 'flag: Armenia',
    fullName: 'flag: Armenia',
    isBase: true,
    baseHex: '1f1e6-1f1f2',
    modifiers: null,
  },
  '1f1e6-1f1f4': {
    hexCodePoints: '1f1e6-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇴',
    version: 'E2.0',
    baseName: 'flag: Angola',
    fullName: 'flag: Angola',
    isBase: true,
    baseHex: '1f1e6-1f1f4',
    modifiers: null,
  },
  '1f1e6-1f1f6': {
    hexCodePoints: '1f1e6-1f1f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇶',
    version: 'E2.0',
    baseName: 'flag: Antarctica',
    fullName: 'flag: Antarctica',
    isBase: true,
    baseHex: '1f1e6-1f1f6',
    modifiers: null,
  },
  '1f1e6-1f1f7': {
    hexCodePoints: '1f1e6-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇷',
    version: 'E2.0',
    baseName: 'flag: Argentina',
    fullName: 'flag: Argentina',
    isBase: true,
    baseHex: '1f1e6-1f1f7',
    modifiers: null,
  },
  '1f1e6-1f1f8': {
    hexCodePoints: '1f1e6-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇸',
    version: 'E2.0',
    baseName: 'flag: American Samoa',
    fullName: 'flag: American Samoa',
    isBase: true,
    baseHex: '1f1e6-1f1f8',
    modifiers: null,
  },
  '1f1e6-1f1f9': {
    hexCodePoints: '1f1e6-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇹',
    version: 'E2.0',
    baseName: 'flag: Austria',
    fullName: 'flag: Austria',
    isBase: true,
    baseHex: '1f1e6-1f1f9',
    modifiers: null,
  },
  '1f1e6-1f1fa': {
    hexCodePoints: '1f1e6-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇺',
    version: 'E2.0',
    baseName: 'flag: Australia',
    fullName: 'flag: Australia',
    isBase: true,
    baseHex: '1f1e6-1f1fa',
    modifiers: null,
  },
  '1f1e6-1f1fc': {
    hexCodePoints: '1f1e6-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇼',
    version: 'E2.0',
    baseName: 'flag: Aruba',
    fullName: 'flag: Aruba',
    isBase: true,
    baseHex: '1f1e6-1f1fc',
    modifiers: null,
  },
  '1f1e6-1f1fd': {
    hexCodePoints: '1f1e6-1f1fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇽',
    version: 'E2.0',
    baseName: 'flag: Åland Islands',
    fullName: 'flag: Åland Islands',
    isBase: true,
    baseHex: '1f1e6-1f1fd',
    modifiers: null,
  },
  '1f1e6-1f1ff': {
    hexCodePoints: '1f1e6-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇦🇿',
    version: 'E2.0',
    baseName: 'flag: Azerbaijan',
    fullName: 'flag: Azerbaijan',
    isBase: true,
    baseHex: '1f1e6-1f1ff',
    modifiers: null,
  },
  '1f1e7-1f1e6': {
    hexCodePoints: '1f1e7-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇦',
    version: 'E2.0',
    baseName: 'flag: Bosnia & Herzegovina',
    fullName: 'flag: Bosnia & Herzegovina',
    isBase: true,
    baseHex: '1f1e7-1f1e6',
    modifiers: null,
  },
  '1f1e7-1f1e7': {
    hexCodePoints: '1f1e7-1f1e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇧',
    version: 'E2.0',
    baseName: 'flag: Barbados',
    fullName: 'flag: Barbados',
    isBase: true,
    baseHex: '1f1e7-1f1e7',
    modifiers: null,
  },
  '1f1e7-1f1e9': {
    hexCodePoints: '1f1e7-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇩',
    version: 'E2.0',
    baseName: 'flag: Bangladesh',
    fullName: 'flag: Bangladesh',
    isBase: true,
    baseHex: '1f1e7-1f1e9',
    modifiers: null,
  },
  '1f1e7-1f1ea': {
    hexCodePoints: '1f1e7-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇪',
    version: 'E2.0',
    baseName: 'flag: Belgium',
    fullName: 'flag: Belgium',
    isBase: true,
    baseHex: '1f1e7-1f1ea',
    modifiers: null,
  },
  '1f1e7-1f1eb': {
    hexCodePoints: '1f1e7-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇫',
    version: 'E2.0',
    baseName: 'flag: Burkina Faso',
    fullName: 'flag: Burkina Faso',
    isBase: true,
    baseHex: '1f1e7-1f1eb',
    modifiers: null,
  },
  '1f1e7-1f1ec': {
    hexCodePoints: '1f1e7-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇬',
    version: 'E2.0',
    baseName: 'flag: Bulgaria',
    fullName: 'flag: Bulgaria',
    isBase: true,
    baseHex: '1f1e7-1f1ec',
    modifiers: null,
  },
  '1f1e7-1f1ed': {
    hexCodePoints: '1f1e7-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇭',
    version: 'E2.0',
    baseName: 'flag: Bahrain',
    fullName: 'flag: Bahrain',
    isBase: true,
    baseHex: '1f1e7-1f1ed',
    modifiers: null,
  },
  '1f1e7-1f1ee': {
    hexCodePoints: '1f1e7-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇮',
    version: 'E2.0',
    baseName: 'flag: Burundi',
    fullName: 'flag: Burundi',
    isBase: true,
    baseHex: '1f1e7-1f1ee',
    modifiers: null,
  },
  '1f1e7-1f1ef': {
    hexCodePoints: '1f1e7-1f1ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇯',
    version: 'E2.0',
    baseName: 'flag: Benin',
    fullName: 'flag: Benin',
    isBase: true,
    baseHex: '1f1e7-1f1ef',
    modifiers: null,
  },
  '1f1e7-1f1f1': {
    hexCodePoints: '1f1e7-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇱',
    version: 'E2.0',
    baseName: 'flag: St. Barthélemy',
    fullName: 'flag: St. Barthélemy',
    isBase: true,
    baseHex: '1f1e7-1f1f1',
    modifiers: null,
  },
  '1f1e7-1f1f2': {
    hexCodePoints: '1f1e7-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇲',
    version: 'E2.0',
    baseName: 'flag: Bermuda',
    fullName: 'flag: Bermuda',
    isBase: true,
    baseHex: '1f1e7-1f1f2',
    modifiers: null,
  },
  '1f1e7-1f1f3': {
    hexCodePoints: '1f1e7-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇳',
    version: 'E2.0',
    baseName: 'flag: Brunei',
    fullName: 'flag: Brunei',
    isBase: true,
    baseHex: '1f1e7-1f1f3',
    modifiers: null,
  },
  '1f1e7-1f1f4': {
    hexCodePoints: '1f1e7-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇴',
    version: 'E2.0',
    baseName: 'flag: Bolivia',
    fullName: 'flag: Bolivia',
    isBase: true,
    baseHex: '1f1e7-1f1f4',
    modifiers: null,
  },
  '1f1e7-1f1f6': {
    hexCodePoints: '1f1e7-1f1f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇶',
    version: 'E2.0',
    baseName: 'flag: Caribbean Netherlands',
    fullName: 'flag: Caribbean Netherlands',
    isBase: true,
    baseHex: '1f1e7-1f1f6',
    modifiers: null,
  },
  '1f1e7-1f1f7': {
    hexCodePoints: '1f1e7-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇷',
    version: 'E2.0',
    baseName: 'flag: Brazil',
    fullName: 'flag: Brazil',
    isBase: true,
    baseHex: '1f1e7-1f1f7',
    modifiers: null,
  },
  '1f1e7-1f1f8': {
    hexCodePoints: '1f1e7-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇸',
    version: 'E2.0',
    baseName: 'flag: Bahamas',
    fullName: 'flag: Bahamas',
    isBase: true,
    baseHex: '1f1e7-1f1f8',
    modifiers: null,
  },
  '1f1e7-1f1f9': {
    hexCodePoints: '1f1e7-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇹',
    version: 'E2.0',
    baseName: 'flag: Bhutan',
    fullName: 'flag: Bhutan',
    isBase: true,
    baseHex: '1f1e7-1f1f9',
    modifiers: null,
  },
  '1f1e7-1f1fb': {
    hexCodePoints: '1f1e7-1f1fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇻',
    version: 'E2.0',
    baseName: 'flag: Bouvet Island',
    fullName: 'flag: Bouvet Island',
    isBase: true,
    baseHex: '1f1e7-1f1fb',
    modifiers: null,
  },
  '1f1e7-1f1fc': {
    hexCodePoints: '1f1e7-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇼',
    version: 'E2.0',
    baseName: 'flag: Botswana',
    fullName: 'flag: Botswana',
    isBase: true,
    baseHex: '1f1e7-1f1fc',
    modifiers: null,
  },
  '1f1e7-1f1fe': {
    hexCodePoints: '1f1e7-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇾',
    version: 'E2.0',
    baseName: 'flag: Belarus',
    fullName: 'flag: Belarus',
    isBase: true,
    baseHex: '1f1e7-1f1fe',
    modifiers: null,
  },
  '1f1e7-1f1ff': {
    hexCodePoints: '1f1e7-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇧🇿',
    version: 'E2.0',
    baseName: 'flag: Belize',
    fullName: 'flag: Belize',
    isBase: true,
    baseHex: '1f1e7-1f1ff',
    modifiers: null,
  },
  '1f1e8-1f1e6': {
    hexCodePoints: '1f1e8-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇦',
    version: 'E2.0',
    baseName: 'flag: Canada',
    fullName: 'flag: Canada',
    isBase: true,
    baseHex: '1f1e8-1f1e6',
    modifiers: null,
  },
  '1f1e8-1f1e8': {
    hexCodePoints: '1f1e8-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇨',
    version: 'E2.0',
    baseName: 'flag: Cocos (Keeling) Islands',
    fullName: 'flag: Cocos (Keeling) Islands',
    isBase: true,
    baseHex: '1f1e8-1f1e8',
    modifiers: null,
  },
  '1f1e8-1f1e9': {
    hexCodePoints: '1f1e8-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇩',
    version: 'E2.0',
    baseName: 'flag: Congo - Kinshasa',
    fullName: 'flag: Congo - Kinshasa',
    isBase: true,
    baseHex: '1f1e8-1f1e9',
    modifiers: null,
  },
  '1f1e8-1f1eb': {
    hexCodePoints: '1f1e8-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇫',
    version: 'E2.0',
    baseName: 'flag: Central African Republic',
    fullName: 'flag: Central African Republic',
    isBase: true,
    baseHex: '1f1e8-1f1eb',
    modifiers: null,
  },
  '1f1e8-1f1ec': {
    hexCodePoints: '1f1e8-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇬',
    version: 'E2.0',
    baseName: 'flag: Congo - Brazzaville',
    fullName: 'flag: Congo - Brazzaville',
    isBase: true,
    baseHex: '1f1e8-1f1ec',
    modifiers: null,
  },
  '1f1e8-1f1ed': {
    hexCodePoints: '1f1e8-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇭',
    version: 'E2.0',
    baseName: 'flag: Switzerland',
    fullName: 'flag: Switzerland',
    isBase: true,
    baseHex: '1f1e8-1f1ed',
    modifiers: null,
  },
  '1f1e8-1f1ee': {
    hexCodePoints: '1f1e8-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇮',
    version: 'E2.0',
    baseName: 'flag: Côte d’Ivoire',
    fullName: 'flag: Côte d’Ivoire',
    isBase: true,
    baseHex: '1f1e8-1f1ee',
    modifiers: null,
  },
  '1f1e8-1f1f0': {
    hexCodePoints: '1f1e8-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇰',
    version: 'E2.0',
    baseName: 'flag: Cook Islands',
    fullName: 'flag: Cook Islands',
    isBase: true,
    baseHex: '1f1e8-1f1f0',
    modifiers: null,
  },
  '1f1e8-1f1f1': {
    hexCodePoints: '1f1e8-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇱',
    version: 'E2.0',
    baseName: 'flag: Chile',
    fullName: 'flag: Chile',
    isBase: true,
    baseHex: '1f1e8-1f1f1',
    modifiers: null,
  },
  '1f1e8-1f1f2': {
    hexCodePoints: '1f1e8-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇲',
    version: 'E2.0',
    baseName: 'flag: Cameroon',
    fullName: 'flag: Cameroon',
    isBase: true,
    baseHex: '1f1e8-1f1f2',
    modifiers: null,
  },
  '1f1e8-1f1f3': {
    hexCodePoints: '1f1e8-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇳',
    version: 'E0.6',
    baseName: 'flag: China',
    fullName: 'flag: China',
    isBase: true,
    baseHex: '1f1e8-1f1f3',
    modifiers: null,
  },
  '1f1e8-1f1f4': {
    hexCodePoints: '1f1e8-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇴',
    version: 'E2.0',
    baseName: 'flag: Colombia',
    fullName: 'flag: Colombia',
    isBase: true,
    baseHex: '1f1e8-1f1f4',
    modifiers: null,
  },
  '1f1e8-1f1f5': {
    hexCodePoints: '1f1e8-1f1f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇵',
    version: 'E2.0',
    baseName: 'flag: Clipperton Island',
    fullName: 'flag: Clipperton Island',
    isBase: true,
    baseHex: '1f1e8-1f1f5',
    modifiers: null,
  },
  '1f1e8-1f1f7': {
    hexCodePoints: '1f1e8-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇷',
    version: 'E2.0',
    baseName: 'flag: Costa Rica',
    fullName: 'flag: Costa Rica',
    isBase: true,
    baseHex: '1f1e8-1f1f7',
    modifiers: null,
  },
  '1f1e8-1f1fa': {
    hexCodePoints: '1f1e8-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇺',
    version: 'E2.0',
    baseName: 'flag: Cuba',
    fullName: 'flag: Cuba',
    isBase: true,
    baseHex: '1f1e8-1f1fa',
    modifiers: null,
  },
  '1f1e8-1f1fb': {
    hexCodePoints: '1f1e8-1f1fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇻',
    version: 'E2.0',
    baseName: 'flag: Cape Verde',
    fullName: 'flag: Cape Verde',
    isBase: true,
    baseHex: '1f1e8-1f1fb',
    modifiers: null,
  },
  '1f1e8-1f1fc': {
    hexCodePoints: '1f1e8-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇼',
    version: 'E2.0',
    baseName: 'flag: Curaçao',
    fullName: 'flag: Curaçao',
    isBase: true,
    baseHex: '1f1e8-1f1fc',
    modifiers: null,
  },
  '1f1e8-1f1fd': {
    hexCodePoints: '1f1e8-1f1fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇽',
    version: 'E2.0',
    baseName: 'flag: Christmas Island',
    fullName: 'flag: Christmas Island',
    isBase: true,
    baseHex: '1f1e8-1f1fd',
    modifiers: null,
  },
  '1f1e8-1f1fe': {
    hexCodePoints: '1f1e8-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇾',
    version: 'E2.0',
    baseName: 'flag: Cyprus',
    fullName: 'flag: Cyprus',
    isBase: true,
    baseHex: '1f1e8-1f1fe',
    modifiers: null,
  },
  '1f1e8-1f1ff': {
    hexCodePoints: '1f1e8-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇨🇿',
    version: 'E2.0',
    baseName: 'flag: Czechia',
    fullName: 'flag: Czechia',
    isBase: true,
    baseHex: '1f1e8-1f1ff',
    modifiers: null,
  },
  '1f1e9-1f1ea': {
    hexCodePoints: '1f1e9-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇪',
    version: 'E0.6',
    baseName: 'flag: Germany',
    fullName: 'flag: Germany',
    isBase: true,
    baseHex: '1f1e9-1f1ea',
    modifiers: null,
  },
  '1f1e9-1f1ec': {
    hexCodePoints: '1f1e9-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇬',
    version: 'E2.0',
    baseName: 'flag: Diego Garcia',
    fullName: 'flag: Diego Garcia',
    isBase: true,
    baseHex: '1f1e9-1f1ec',
    modifiers: null,
  },
  '1f1e9-1f1ef': {
    hexCodePoints: '1f1e9-1f1ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇯',
    version: 'E2.0',
    baseName: 'flag: Djibouti',
    fullName: 'flag: Djibouti',
    isBase: true,
    baseHex: '1f1e9-1f1ef',
    modifiers: null,
  },
  '1f1e9-1f1f0': {
    hexCodePoints: '1f1e9-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇰',
    version: 'E2.0',
    baseName: 'flag: Denmark',
    fullName: 'flag: Denmark',
    isBase: true,
    baseHex: '1f1e9-1f1f0',
    modifiers: null,
  },
  '1f1e9-1f1f2': {
    hexCodePoints: '1f1e9-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇲',
    version: 'E2.0',
    baseName: 'flag: Dominica',
    fullName: 'flag: Dominica',
    isBase: true,
    baseHex: '1f1e9-1f1f2',
    modifiers: null,
  },
  '1f1e9-1f1f4': {
    hexCodePoints: '1f1e9-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇴',
    version: 'E2.0',
    baseName: 'flag: Dominican Republic',
    fullName: 'flag: Dominican Republic',
    isBase: true,
    baseHex: '1f1e9-1f1f4',
    modifiers: null,
  },
  '1f1e9-1f1ff': {
    hexCodePoints: '1f1e9-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇩🇿',
    version: 'E2.0',
    baseName: 'flag: Algeria',
    fullName: 'flag: Algeria',
    isBase: true,
    baseHex: '1f1e9-1f1ff',
    modifiers: null,
  },
  '1f1ea-1f1e6': {
    hexCodePoints: '1f1ea-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇦',
    version: 'E2.0',
    baseName: 'flag: Ceuta & Melilla',
    fullName: 'flag: Ceuta & Melilla',
    isBase: true,
    baseHex: '1f1ea-1f1e6',
    modifiers: null,
  },
  '1f1ea-1f1e8': {
    hexCodePoints: '1f1ea-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇨',
    version: 'E2.0',
    baseName: 'flag: Ecuador',
    fullName: 'flag: Ecuador',
    isBase: true,
    baseHex: '1f1ea-1f1e8',
    modifiers: null,
  },
  '1f1ea-1f1ea': {
    hexCodePoints: '1f1ea-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇪',
    version: 'E2.0',
    baseName: 'flag: Estonia',
    fullName: 'flag: Estonia',
    isBase: true,
    baseHex: '1f1ea-1f1ea',
    modifiers: null,
  },
  '1f1ea-1f1ec': {
    hexCodePoints: '1f1ea-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇬',
    version: 'E2.0',
    baseName: 'flag: Egypt',
    fullName: 'flag: Egypt',
    isBase: true,
    baseHex: '1f1ea-1f1ec',
    modifiers: null,
  },
  '1f1ea-1f1ed': {
    hexCodePoints: '1f1ea-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇭',
    version: 'E2.0',
    baseName: 'flag: Western Sahara',
    fullName: 'flag: Western Sahara',
    isBase: true,
    baseHex: '1f1ea-1f1ed',
    modifiers: null,
  },
  '1f1ea-1f1f7': {
    hexCodePoints: '1f1ea-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇷',
    version: 'E2.0',
    baseName: 'flag: Eritrea',
    fullName: 'flag: Eritrea',
    isBase: true,
    baseHex: '1f1ea-1f1f7',
    modifiers: null,
  },
  '1f1ea-1f1f8': {
    hexCodePoints: '1f1ea-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇸',
    version: 'E0.6',
    baseName: 'flag: Spain',
    fullName: 'flag: Spain',
    isBase: true,
    baseHex: '1f1ea-1f1f8',
    modifiers: null,
  },
  '1f1ea-1f1f9': {
    hexCodePoints: '1f1ea-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇹',
    version: 'E2.0',
    baseName: 'flag: Ethiopia',
    fullName: 'flag: Ethiopia',
    isBase: true,
    baseHex: '1f1ea-1f1f9',
    modifiers: null,
  },
  '1f1ea-1f1fa': {
    hexCodePoints: '1f1ea-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇪🇺',
    version: 'E2.0',
    baseName: 'flag: European Union',
    fullName: 'flag: European Union',
    isBase: true,
    baseHex: '1f1ea-1f1fa',
    modifiers: null,
  },
  '1f1eb-1f1ee': {
    hexCodePoints: '1f1eb-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇫🇮',
    version: 'E2.0',
    baseName: 'flag: Finland',
    fullName: 'flag: Finland',
    isBase: true,
    baseHex: '1f1eb-1f1ee',
    modifiers: null,
  },
  '1f1eb-1f1ef': {
    hexCodePoints: '1f1eb-1f1ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇫🇯',
    version: 'E2.0',
    baseName: 'flag: Fiji',
    fullName: 'flag: Fiji',
    isBase: true,
    baseHex: '1f1eb-1f1ef',
    modifiers: null,
  },
  '1f1eb-1f1f0': {
    hexCodePoints: '1f1eb-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇫🇰',
    version: 'E2.0',
    baseName: 'flag: Falkland Islands',
    fullName: 'flag: Falkland Islands',
    isBase: true,
    baseHex: '1f1eb-1f1f0',
    modifiers: null,
  },
  '1f1eb-1f1f2': {
    hexCodePoints: '1f1eb-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇫🇲',
    version: 'E2.0',
    baseName: 'flag: Micronesia',
    fullName: 'flag: Micronesia',
    isBase: true,
    baseHex: '1f1eb-1f1f2',
    modifiers: null,
  },
  '1f1eb-1f1f4': {
    hexCodePoints: '1f1eb-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇫🇴',
    version: 'E2.0',
    baseName: 'flag: Faroe Islands',
    fullName: 'flag: Faroe Islands',
    isBase: true,
    baseHex: '1f1eb-1f1f4',
    modifiers: null,
  },
  '1f1eb-1f1f7': {
    hexCodePoints: '1f1eb-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇫🇷',
    version: 'E0.6',
    baseName: 'flag: France',
    fullName: 'flag: France',
    isBase: true,
    baseHex: '1f1eb-1f1f7',
    modifiers: null,
  },
  '1f1ec-1f1e6': {
    hexCodePoints: '1f1ec-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇦',
    version: 'E2.0',
    baseName: 'flag: Gabon',
    fullName: 'flag: Gabon',
    isBase: true,
    baseHex: '1f1ec-1f1e6',
    modifiers: null,
  },
  '1f1ec-1f1e7': {
    hexCodePoints: '1f1ec-1f1e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇧',
    version: 'E0.6',
    baseName: 'flag: United Kingdom',
    fullName: 'flag: United Kingdom',
    isBase: true,
    baseHex: '1f1ec-1f1e7',
    modifiers: null,
  },
  '1f1ec-1f1e9': {
    hexCodePoints: '1f1ec-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇩',
    version: 'E2.0',
    baseName: 'flag: Grenada',
    fullName: 'flag: Grenada',
    isBase: true,
    baseHex: '1f1ec-1f1e9',
    modifiers: null,
  },
  '1f1ec-1f1ea': {
    hexCodePoints: '1f1ec-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇪',
    version: 'E2.0',
    baseName: 'flag: Georgia',
    fullName: 'flag: Georgia',
    isBase: true,
    baseHex: '1f1ec-1f1ea',
    modifiers: null,
  },
  '1f1ec-1f1eb': {
    hexCodePoints: '1f1ec-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇫',
    version: 'E2.0',
    baseName: 'flag: French Guiana',
    fullName: 'flag: French Guiana',
    isBase: true,
    baseHex: '1f1ec-1f1eb',
    modifiers: null,
  },
  '1f1ec-1f1ec': {
    hexCodePoints: '1f1ec-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇬',
    version: 'E2.0',
    baseName: 'flag: Guernsey',
    fullName: 'flag: Guernsey',
    isBase: true,
    baseHex: '1f1ec-1f1ec',
    modifiers: null,
  },
  '1f1ec-1f1ed': {
    hexCodePoints: '1f1ec-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇭',
    version: 'E2.0',
    baseName: 'flag: Ghana',
    fullName: 'flag: Ghana',
    isBase: true,
    baseHex: '1f1ec-1f1ed',
    modifiers: null,
  },
  '1f1ec-1f1ee': {
    hexCodePoints: '1f1ec-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇮',
    version: 'E2.0',
    baseName: 'flag: Gibraltar',
    fullName: 'flag: Gibraltar',
    isBase: true,
    baseHex: '1f1ec-1f1ee',
    modifiers: null,
  },
  '1f1ec-1f1f1': {
    hexCodePoints: '1f1ec-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇱',
    version: 'E2.0',
    baseName: 'flag: Greenland',
    fullName: 'flag: Greenland',
    isBase: true,
    baseHex: '1f1ec-1f1f1',
    modifiers: null,
  },
  '1f1ec-1f1f2': {
    hexCodePoints: '1f1ec-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇲',
    version: 'E2.0',
    baseName: 'flag: Gambia',
    fullName: 'flag: Gambia',
    isBase: true,
    baseHex: '1f1ec-1f1f2',
    modifiers: null,
  },
  '1f1ec-1f1f3': {
    hexCodePoints: '1f1ec-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇳',
    version: 'E2.0',
    baseName: 'flag: Guinea',
    fullName: 'flag: Guinea',
    isBase: true,
    baseHex: '1f1ec-1f1f3',
    modifiers: null,
  },
  '1f1ec-1f1f5': {
    hexCodePoints: '1f1ec-1f1f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇵',
    version: 'E2.0',
    baseName: 'flag: Guadeloupe',
    fullName: 'flag: Guadeloupe',
    isBase: true,
    baseHex: '1f1ec-1f1f5',
    modifiers: null,
  },
  '1f1ec-1f1f6': {
    hexCodePoints: '1f1ec-1f1f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇶',
    version: 'E2.0',
    baseName: 'flag: Equatorial Guinea',
    fullName: 'flag: Equatorial Guinea',
    isBase: true,
    baseHex: '1f1ec-1f1f6',
    modifiers: null,
  },
  '1f1ec-1f1f7': {
    hexCodePoints: '1f1ec-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇷',
    version: 'E2.0',
    baseName: 'flag: Greece',
    fullName: 'flag: Greece',
    isBase: true,
    baseHex: '1f1ec-1f1f7',
    modifiers: null,
  },
  '1f1ec-1f1f8': {
    hexCodePoints: '1f1ec-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇸',
    version: 'E2.0',
    baseName: 'flag: South Georgia & South Sandwich Islands',
    fullName: 'flag: South Georgia & South Sandwich Islands',
    isBase: true,
    baseHex: '1f1ec-1f1f8',
    modifiers: null,
  },
  '1f1ec-1f1f9': {
    hexCodePoints: '1f1ec-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇹',
    version: 'E2.0',
    baseName: 'flag: Guatemala',
    fullName: 'flag: Guatemala',
    isBase: true,
    baseHex: '1f1ec-1f1f9',
    modifiers: null,
  },
  '1f1ec-1f1fa': {
    hexCodePoints: '1f1ec-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇺',
    version: 'E2.0',
    baseName: 'flag: Guam',
    fullName: 'flag: Guam',
    isBase: true,
    baseHex: '1f1ec-1f1fa',
    modifiers: null,
  },
  '1f1ec-1f1fc': {
    hexCodePoints: '1f1ec-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇼',
    version: 'E2.0',
    baseName: 'flag: Guinea-Bissau',
    fullName: 'flag: Guinea-Bissau',
    isBase: true,
    baseHex: '1f1ec-1f1fc',
    modifiers: null,
  },
  '1f1ec-1f1fe': {
    hexCodePoints: '1f1ec-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇬🇾',
    version: 'E2.0',
    baseName: 'flag: Guyana',
    fullName: 'flag: Guyana',
    isBase: true,
    baseHex: '1f1ec-1f1fe',
    modifiers: null,
  },
  '1f1ed-1f1f0': {
    hexCodePoints: '1f1ed-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇭🇰',
    version: 'E2.0',
    baseName: 'flag: Hong Kong SAR China',
    fullName: 'flag: Hong Kong SAR China',
    isBase: true,
    baseHex: '1f1ed-1f1f0',
    modifiers: null,
  },
  '1f1ed-1f1f2': {
    hexCodePoints: '1f1ed-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇭🇲',
    version: 'E2.0',
    baseName: 'flag: Heard & McDonald Islands',
    fullName: 'flag: Heard & McDonald Islands',
    isBase: true,
    baseHex: '1f1ed-1f1f2',
    modifiers: null,
  },
  '1f1ed-1f1f3': {
    hexCodePoints: '1f1ed-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇭🇳',
    version: 'E2.0',
    baseName: 'flag: Honduras',
    fullName: 'flag: Honduras',
    isBase: true,
    baseHex: '1f1ed-1f1f3',
    modifiers: null,
  },
  '1f1ed-1f1f7': {
    hexCodePoints: '1f1ed-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇭🇷',
    version: 'E2.0',
    baseName: 'flag: Croatia',
    fullName: 'flag: Croatia',
    isBase: true,
    baseHex: '1f1ed-1f1f7',
    modifiers: null,
  },
  '1f1ed-1f1f9': {
    hexCodePoints: '1f1ed-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇭🇹',
    version: 'E2.0',
    baseName: 'flag: Haiti',
    fullName: 'flag: Haiti',
    isBase: true,
    baseHex: '1f1ed-1f1f9',
    modifiers: null,
  },
  '1f1ed-1f1fa': {
    hexCodePoints: '1f1ed-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇭🇺',
    version: 'E2.0',
    baseName: 'flag: Hungary',
    fullName: 'flag: Hungary',
    isBase: true,
    baseHex: '1f1ed-1f1fa',
    modifiers: null,
  },
  '1f1ee-1f1e8': {
    hexCodePoints: '1f1ee-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇨',
    version: 'E2.0',
    baseName: 'flag: Canary Islands',
    fullName: 'flag: Canary Islands',
    isBase: true,
    baseHex: '1f1ee-1f1e8',
    modifiers: null,
  },
  '1f1ee-1f1e9': {
    hexCodePoints: '1f1ee-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇩',
    version: 'E2.0',
    baseName: 'flag: Indonesia',
    fullName: 'flag: Indonesia',
    isBase: true,
    baseHex: '1f1ee-1f1e9',
    modifiers: null,
  },
  '1f1ee-1f1ea': {
    hexCodePoints: '1f1ee-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇪',
    version: 'E2.0',
    baseName: 'flag: Ireland',
    fullName: 'flag: Ireland',
    isBase: true,
    baseHex: '1f1ee-1f1ea',
    modifiers: null,
  },
  '1f1ee-1f1f1': {
    hexCodePoints: '1f1ee-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇱',
    version: 'E2.0',
    baseName: 'flag: Israel',
    fullName: 'flag: Israel',
    isBase: true,
    baseHex: '1f1ee-1f1f1',
    modifiers: null,
  },
  '1f1ee-1f1f2': {
    hexCodePoints: '1f1ee-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇲',
    version: 'E2.0',
    baseName: 'flag: Isle of Man',
    fullName: 'flag: Isle of Man',
    isBase: true,
    baseHex: '1f1ee-1f1f2',
    modifiers: null,
  },
  '1f1ee-1f1f3': {
    hexCodePoints: '1f1ee-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇳',
    version: 'E2.0',
    baseName: 'flag: India',
    fullName: 'flag: India',
    isBase: true,
    baseHex: '1f1ee-1f1f3',
    modifiers: null,
  },
  '1f1ee-1f1f4': {
    hexCodePoints: '1f1ee-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇴',
    version: 'E2.0',
    baseName: 'flag: British Indian Ocean Territory',
    fullName: 'flag: British Indian Ocean Territory',
    isBase: true,
    baseHex: '1f1ee-1f1f4',
    modifiers: null,
  },
  '1f1ee-1f1f6': {
    hexCodePoints: '1f1ee-1f1f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇶',
    version: 'E2.0',
    baseName: 'flag: Iraq',
    fullName: 'flag: Iraq',
    isBase: true,
    baseHex: '1f1ee-1f1f6',
    modifiers: null,
  },
  '1f1ee-1f1f7': {
    hexCodePoints: '1f1ee-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇷',
    version: 'E2.0',
    baseName: 'flag: Iran',
    fullName: 'flag: Iran',
    isBase: true,
    baseHex: '1f1ee-1f1f7',
    modifiers: null,
  },
  '1f1ee-1f1f8': {
    hexCodePoints: '1f1ee-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇸',
    version: 'E2.0',
    baseName: 'flag: Iceland',
    fullName: 'flag: Iceland',
    isBase: true,
    baseHex: '1f1ee-1f1f8',
    modifiers: null,
  },
  '1f1ee-1f1f9': {
    hexCodePoints: '1f1ee-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇮🇹',
    version: 'E0.6',
    baseName: 'flag: Italy',
    fullName: 'flag: Italy',
    isBase: true,
    baseHex: '1f1ee-1f1f9',
    modifiers: null,
  },
  '1f1ef-1f1ea': {
    hexCodePoints: '1f1ef-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇯🇪',
    version: 'E2.0',
    baseName: 'flag: Jersey',
    fullName: 'flag: Jersey',
    isBase: true,
    baseHex: '1f1ef-1f1ea',
    modifiers: null,
  },
  '1f1ef-1f1f2': {
    hexCodePoints: '1f1ef-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇯🇲',
    version: 'E2.0',
    baseName: 'flag: Jamaica',
    fullName: 'flag: Jamaica',
    isBase: true,
    baseHex: '1f1ef-1f1f2',
    modifiers: null,
  },
  '1f1ef-1f1f4': {
    hexCodePoints: '1f1ef-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇯🇴',
    version: 'E2.0',
    baseName: 'flag: Jordan',
    fullName: 'flag: Jordan',
    isBase: true,
    baseHex: '1f1ef-1f1f4',
    modifiers: null,
  },
  '1f1ef-1f1f5': {
    hexCodePoints: '1f1ef-1f1f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇯🇵',
    version: 'E0.6',
    baseName: 'flag: Japan',
    fullName: 'flag: Japan',
    isBase: true,
    baseHex: '1f1ef-1f1f5',
    modifiers: null,
  },
  '1f1f0-1f1ea': {
    hexCodePoints: '1f1f0-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇪',
    version: 'E2.0',
    baseName: 'flag: Kenya',
    fullName: 'flag: Kenya',
    isBase: true,
    baseHex: '1f1f0-1f1ea',
    modifiers: null,
  },
  '1f1f0-1f1ec': {
    hexCodePoints: '1f1f0-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇬',
    version: 'E2.0',
    baseName: 'flag: Kyrgyzstan',
    fullName: 'flag: Kyrgyzstan',
    isBase: true,
    baseHex: '1f1f0-1f1ec',
    modifiers: null,
  },
  '1f1f0-1f1ed': {
    hexCodePoints: '1f1f0-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇭',
    version: 'E2.0',
    baseName: 'flag: Cambodia',
    fullName: 'flag: Cambodia',
    isBase: true,
    baseHex: '1f1f0-1f1ed',
    modifiers: null,
  },
  '1f1f0-1f1ee': {
    hexCodePoints: '1f1f0-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇮',
    version: 'E2.0',
    baseName: 'flag: Kiribati',
    fullName: 'flag: Kiribati',
    isBase: true,
    baseHex: '1f1f0-1f1ee',
    modifiers: null,
  },
  '1f1f0-1f1f2': {
    hexCodePoints: '1f1f0-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇲',
    version: 'E2.0',
    baseName: 'flag: Comoros',
    fullName: 'flag: Comoros',
    isBase: true,
    baseHex: '1f1f0-1f1f2',
    modifiers: null,
  },
  '1f1f0-1f1f3': {
    hexCodePoints: '1f1f0-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇳',
    version: 'E2.0',
    baseName: 'flag: St. Kitts & Nevis',
    fullName: 'flag: St. Kitts & Nevis',
    isBase: true,
    baseHex: '1f1f0-1f1f3',
    modifiers: null,
  },
  '1f1f0-1f1f5': {
    hexCodePoints: '1f1f0-1f1f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇵',
    version: 'E2.0',
    baseName: 'flag: North Korea',
    fullName: 'flag: North Korea',
    isBase: true,
    baseHex: '1f1f0-1f1f5',
    modifiers: null,
  },
  '1f1f0-1f1f7': {
    hexCodePoints: '1f1f0-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇷',
    version: 'E0.6',
    baseName: 'flag: South Korea',
    fullName: 'flag: South Korea',
    isBase: true,
    baseHex: '1f1f0-1f1f7',
    modifiers: null,
  },
  '1f1f0-1f1fc': {
    hexCodePoints: '1f1f0-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇼',
    version: 'E2.0',
    baseName: 'flag: Kuwait',
    fullName: 'flag: Kuwait',
    isBase: true,
    baseHex: '1f1f0-1f1fc',
    modifiers: null,
  },
  '1f1f0-1f1fe': {
    hexCodePoints: '1f1f0-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇾',
    version: 'E2.0',
    baseName: 'flag: Cayman Islands',
    fullName: 'flag: Cayman Islands',
    isBase: true,
    baseHex: '1f1f0-1f1fe',
    modifiers: null,
  },
  '1f1f0-1f1ff': {
    hexCodePoints: '1f1f0-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇰🇿',
    version: 'E2.0',
    baseName: 'flag: Kazakhstan',
    fullName: 'flag: Kazakhstan',
    isBase: true,
    baseHex: '1f1f0-1f1ff',
    modifiers: null,
  },
  '1f1f1-1f1e6': {
    hexCodePoints: '1f1f1-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇦',
    version: 'E2.0',
    baseName: 'flag: Laos',
    fullName: 'flag: Laos',
    isBase: true,
    baseHex: '1f1f1-1f1e6',
    modifiers: null,
  },
  '1f1f1-1f1e7': {
    hexCodePoints: '1f1f1-1f1e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇧',
    version: 'E2.0',
    baseName: 'flag: Lebanon',
    fullName: 'flag: Lebanon',
    isBase: true,
    baseHex: '1f1f1-1f1e7',
    modifiers: null,
  },
  '1f1f1-1f1e8': {
    hexCodePoints: '1f1f1-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇨',
    version: 'E2.0',
    baseName: 'flag: St. Lucia',
    fullName: 'flag: St. Lucia',
    isBase: true,
    baseHex: '1f1f1-1f1e8',
    modifiers: null,
  },
  '1f1f1-1f1ee': {
    hexCodePoints: '1f1f1-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇮',
    version: 'E2.0',
    baseName: 'flag: Liechtenstein',
    fullName: 'flag: Liechtenstein',
    isBase: true,
    baseHex: '1f1f1-1f1ee',
    modifiers: null,
  },
  '1f1f1-1f1f0': {
    hexCodePoints: '1f1f1-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇰',
    version: 'E2.0',
    baseName: 'flag: Sri Lanka',
    fullName: 'flag: Sri Lanka',
    isBase: true,
    baseHex: '1f1f1-1f1f0',
    modifiers: null,
  },
  '1f1f1-1f1f7': {
    hexCodePoints: '1f1f1-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇷',
    version: 'E2.0',
    baseName: 'flag: Liberia',
    fullName: 'flag: Liberia',
    isBase: true,
    baseHex: '1f1f1-1f1f7',
    modifiers: null,
  },
  '1f1f1-1f1f8': {
    hexCodePoints: '1f1f1-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇸',
    version: 'E2.0',
    baseName: 'flag: Lesotho',
    fullName: 'flag: Lesotho',
    isBase: true,
    baseHex: '1f1f1-1f1f8',
    modifiers: null,
  },
  '1f1f1-1f1f9': {
    hexCodePoints: '1f1f1-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇹',
    version: 'E2.0',
    baseName: 'flag: Lithuania',
    fullName: 'flag: Lithuania',
    isBase: true,
    baseHex: '1f1f1-1f1f9',
    modifiers: null,
  },
  '1f1f1-1f1fa': {
    hexCodePoints: '1f1f1-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇺',
    version: 'E2.0',
    baseName: 'flag: Luxembourg',
    fullName: 'flag: Luxembourg',
    isBase: true,
    baseHex: '1f1f1-1f1fa',
    modifiers: null,
  },
  '1f1f1-1f1fb': {
    hexCodePoints: '1f1f1-1f1fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇻',
    version: 'E2.0',
    baseName: 'flag: Latvia',
    fullName: 'flag: Latvia',
    isBase: true,
    baseHex: '1f1f1-1f1fb',
    modifiers: null,
  },
  '1f1f1-1f1fe': {
    hexCodePoints: '1f1f1-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇱🇾',
    version: 'E2.0',
    baseName: 'flag: Libya',
    fullName: 'flag: Libya',
    isBase: true,
    baseHex: '1f1f1-1f1fe',
    modifiers: null,
  },
  '1f1f2-1f1e6': {
    hexCodePoints: '1f1f2-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇦',
    version: 'E2.0',
    baseName: 'flag: Morocco',
    fullName: 'flag: Morocco',
    isBase: true,
    baseHex: '1f1f2-1f1e6',
    modifiers: null,
  },
  '1f1f2-1f1e8': {
    hexCodePoints: '1f1f2-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇨',
    version: 'E2.0',
    baseName: 'flag: Monaco',
    fullName: 'flag: Monaco',
    isBase: true,
    baseHex: '1f1f2-1f1e8',
    modifiers: null,
  },
  '1f1f2-1f1e9': {
    hexCodePoints: '1f1f2-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇩',
    version: 'E2.0',
    baseName: 'flag: Moldova',
    fullName: 'flag: Moldova',
    isBase: true,
    baseHex: '1f1f2-1f1e9',
    modifiers: null,
  },
  '1f1f2-1f1ea': {
    hexCodePoints: '1f1f2-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇪',
    version: 'E2.0',
    baseName: 'flag: Montenegro',
    fullName: 'flag: Montenegro',
    isBase: true,
    baseHex: '1f1f2-1f1ea',
    modifiers: null,
  },
  '1f1f2-1f1eb': {
    hexCodePoints: '1f1f2-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇫',
    version: 'E2.0',
    baseName: 'flag: St. Martin',
    fullName: 'flag: St. Martin',
    isBase: true,
    baseHex: '1f1f2-1f1eb',
    modifiers: null,
  },
  '1f1f2-1f1ec': {
    hexCodePoints: '1f1f2-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇬',
    version: 'E2.0',
    baseName: 'flag: Madagascar',
    fullName: 'flag: Madagascar',
    isBase: true,
    baseHex: '1f1f2-1f1ec',
    modifiers: null,
  },
  '1f1f2-1f1ed': {
    hexCodePoints: '1f1f2-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇭',
    version: 'E2.0',
    baseName: 'flag: Marshall Islands',
    fullName: 'flag: Marshall Islands',
    isBase: true,
    baseHex: '1f1f2-1f1ed',
    modifiers: null,
  },
  '1f1f2-1f1f0': {
    hexCodePoints: '1f1f2-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇰',
    version: 'E2.0',
    baseName: 'flag: North Macedonia',
    fullName: 'flag: North Macedonia',
    isBase: true,
    baseHex: '1f1f2-1f1f0',
    modifiers: null,
  },
  '1f1f2-1f1f1': {
    hexCodePoints: '1f1f2-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇱',
    version: 'E2.0',
    baseName: 'flag: Mali',
    fullName: 'flag: Mali',
    isBase: true,
    baseHex: '1f1f2-1f1f1',
    modifiers: null,
  },
  '1f1f2-1f1f2': {
    hexCodePoints: '1f1f2-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇲',
    version: 'E2.0',
    baseName: 'flag: Myanmar (Burma)',
    fullName: 'flag: Myanmar (Burma)',
    isBase: true,
    baseHex: '1f1f2-1f1f2',
    modifiers: null,
  },
  '1f1f2-1f1f3': {
    hexCodePoints: '1f1f2-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇳',
    version: 'E2.0',
    baseName: 'flag: Mongolia',
    fullName: 'flag: Mongolia',
    isBase: true,
    baseHex: '1f1f2-1f1f3',
    modifiers: null,
  },
  '1f1f2-1f1f4': {
    hexCodePoints: '1f1f2-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇴',
    version: 'E2.0',
    baseName: 'flag: Macao SAR China',
    fullName: 'flag: Macao SAR China',
    isBase: true,
    baseHex: '1f1f2-1f1f4',
    modifiers: null,
  },
  '1f1f2-1f1f5': {
    hexCodePoints: '1f1f2-1f1f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇵',
    version: 'E2.0',
    baseName: 'flag: Northern Mariana Islands',
    fullName: 'flag: Northern Mariana Islands',
    isBase: true,
    baseHex: '1f1f2-1f1f5',
    modifiers: null,
  },
  '1f1f2-1f1f6': {
    hexCodePoints: '1f1f2-1f1f6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇶',
    version: 'E2.0',
    baseName: 'flag: Martinique',
    fullName: 'flag: Martinique',
    isBase: true,
    baseHex: '1f1f2-1f1f6',
    modifiers: null,
  },
  '1f1f2-1f1f7': {
    hexCodePoints: '1f1f2-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇷',
    version: 'E2.0',
    baseName: 'flag: Mauritania',
    fullName: 'flag: Mauritania',
    isBase: true,
    baseHex: '1f1f2-1f1f7',
    modifiers: null,
  },
  '1f1f2-1f1f8': {
    hexCodePoints: '1f1f2-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇸',
    version: 'E2.0',
    baseName: 'flag: Montserrat',
    fullName: 'flag: Montserrat',
    isBase: true,
    baseHex: '1f1f2-1f1f8',
    modifiers: null,
  },
  '1f1f2-1f1f9': {
    hexCodePoints: '1f1f2-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇹',
    version: 'E2.0',
    baseName: 'flag: Malta',
    fullName: 'flag: Malta',
    isBase: true,
    baseHex: '1f1f2-1f1f9',
    modifiers: null,
  },
  '1f1f2-1f1fa': {
    hexCodePoints: '1f1f2-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇺',
    version: 'E2.0',
    baseName: 'flag: Mauritius',
    fullName: 'flag: Mauritius',
    isBase: true,
    baseHex: '1f1f2-1f1fa',
    modifiers: null,
  },
  '1f1f2-1f1fb': {
    hexCodePoints: '1f1f2-1f1fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇻',
    version: 'E2.0',
    baseName: 'flag: Maldives',
    fullName: 'flag: Maldives',
    isBase: true,
    baseHex: '1f1f2-1f1fb',
    modifiers: null,
  },
  '1f1f2-1f1fc': {
    hexCodePoints: '1f1f2-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇼',
    version: 'E2.0',
    baseName: 'flag: Malawi',
    fullName: 'flag: Malawi',
    isBase: true,
    baseHex: '1f1f2-1f1fc',
    modifiers: null,
  },
  '1f1f2-1f1fd': {
    hexCodePoints: '1f1f2-1f1fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇽',
    version: 'E2.0',
    baseName: 'flag: Mexico',
    fullName: 'flag: Mexico',
    isBase: true,
    baseHex: '1f1f2-1f1fd',
    modifiers: null,
  },
  '1f1f2-1f1fe': {
    hexCodePoints: '1f1f2-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇾',
    version: 'E2.0',
    baseName: 'flag: Malaysia',
    fullName: 'flag: Malaysia',
    isBase: true,
    baseHex: '1f1f2-1f1fe',
    modifiers: null,
  },
  '1f1f2-1f1ff': {
    hexCodePoints: '1f1f2-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇲🇿',
    version: 'E2.0',
    baseName: 'flag: Mozambique',
    fullName: 'flag: Mozambique',
    isBase: true,
    baseHex: '1f1f2-1f1ff',
    modifiers: null,
  },
  '1f1f3-1f1e6': {
    hexCodePoints: '1f1f3-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇦',
    version: 'E2.0',
    baseName: 'flag: Namibia',
    fullName: 'flag: Namibia',
    isBase: true,
    baseHex: '1f1f3-1f1e6',
    modifiers: null,
  },
  '1f1f3-1f1e8': {
    hexCodePoints: '1f1f3-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇨',
    version: 'E2.0',
    baseName: 'flag: New Caledonia',
    fullName: 'flag: New Caledonia',
    isBase: true,
    baseHex: '1f1f3-1f1e8',
    modifiers: null,
  },
  '1f1f3-1f1ea': {
    hexCodePoints: '1f1f3-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇪',
    version: 'E2.0',
    baseName: 'flag: Niger',
    fullName: 'flag: Niger',
    isBase: true,
    baseHex: '1f1f3-1f1ea',
    modifiers: null,
  },
  '1f1f3-1f1eb': {
    hexCodePoints: '1f1f3-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇫',
    version: 'E2.0',
    baseName: 'flag: Norfolk Island',
    fullName: 'flag: Norfolk Island',
    isBase: true,
    baseHex: '1f1f3-1f1eb',
    modifiers: null,
  },
  '1f1f3-1f1ec': {
    hexCodePoints: '1f1f3-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇬',
    version: 'E2.0',
    baseName: 'flag: Nigeria',
    fullName: 'flag: Nigeria',
    isBase: true,
    baseHex: '1f1f3-1f1ec',
    modifiers: null,
  },
  '1f1f3-1f1ee': {
    hexCodePoints: '1f1f3-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇮',
    version: 'E2.0',
    baseName: 'flag: Nicaragua',
    fullName: 'flag: Nicaragua',
    isBase: true,
    baseHex: '1f1f3-1f1ee',
    modifiers: null,
  },
  '1f1f3-1f1f1': {
    hexCodePoints: '1f1f3-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇱',
    version: 'E2.0',
    baseName: 'flag: Netherlands',
    fullName: 'flag: Netherlands',
    isBase: true,
    baseHex: '1f1f3-1f1f1',
    modifiers: null,
  },
  '1f1f3-1f1f4': {
    hexCodePoints: '1f1f3-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇴',
    version: 'E2.0',
    baseName: 'flag: Norway',
    fullName: 'flag: Norway',
    isBase: true,
    baseHex: '1f1f3-1f1f4',
    modifiers: null,
  },
  '1f1f3-1f1f5': {
    hexCodePoints: '1f1f3-1f1f5',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇵',
    version: 'E2.0',
    baseName: 'flag: Nepal',
    fullName: 'flag: Nepal',
    isBase: true,
    baseHex: '1f1f3-1f1f5',
    modifiers: null,
  },
  '1f1f3-1f1f7': {
    hexCodePoints: '1f1f3-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇷',
    version: 'E2.0',
    baseName: 'flag: Nauru',
    fullName: 'flag: Nauru',
    isBase: true,
    baseHex: '1f1f3-1f1f7',
    modifiers: null,
  },
  '1f1f3-1f1fa': {
    hexCodePoints: '1f1f3-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇺',
    version: 'E2.0',
    baseName: 'flag: Niue',
    fullName: 'flag: Niue',
    isBase: true,
    baseHex: '1f1f3-1f1fa',
    modifiers: null,
  },
  '1f1f3-1f1ff': {
    hexCodePoints: '1f1f3-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇳🇿',
    version: 'E2.0',
    baseName: 'flag: New Zealand',
    fullName: 'flag: New Zealand',
    isBase: true,
    baseHex: '1f1f3-1f1ff',
    modifiers: null,
  },
  '1f1f4-1f1f2': {
    hexCodePoints: '1f1f4-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇴🇲',
    version: 'E2.0',
    baseName: 'flag: Oman',
    fullName: 'flag: Oman',
    isBase: true,
    baseHex: '1f1f4-1f1f2',
    modifiers: null,
  },
  '1f1f5-1f1e6': {
    hexCodePoints: '1f1f5-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇦',
    version: 'E2.0',
    baseName: 'flag: Panama',
    fullName: 'flag: Panama',
    isBase: true,
    baseHex: '1f1f5-1f1e6',
    modifiers: null,
  },
  '1f1f5-1f1ea': {
    hexCodePoints: '1f1f5-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇪',
    version: 'E2.0',
    baseName: 'flag: Peru',
    fullName: 'flag: Peru',
    isBase: true,
    baseHex: '1f1f5-1f1ea',
    modifiers: null,
  },
  '1f1f5-1f1eb': {
    hexCodePoints: '1f1f5-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇫',
    version: 'E2.0',
    baseName: 'flag: French Polynesia',
    fullName: 'flag: French Polynesia',
    isBase: true,
    baseHex: '1f1f5-1f1eb',
    modifiers: null,
  },
  '1f1f5-1f1ec': {
    hexCodePoints: '1f1f5-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇬',
    version: 'E2.0',
    baseName: 'flag: Papua New Guinea',
    fullName: 'flag: Papua New Guinea',
    isBase: true,
    baseHex: '1f1f5-1f1ec',
    modifiers: null,
  },
  '1f1f5-1f1ed': {
    hexCodePoints: '1f1f5-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇭',
    version: 'E2.0',
    baseName: 'flag: Philippines',
    fullName: 'flag: Philippines',
    isBase: true,
    baseHex: '1f1f5-1f1ed',
    modifiers: null,
  },
  '1f1f5-1f1f0': {
    hexCodePoints: '1f1f5-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇰',
    version: 'E2.0',
    baseName: 'flag: Pakistan',
    fullName: 'flag: Pakistan',
    isBase: true,
    baseHex: '1f1f5-1f1f0',
    modifiers: null,
  },
  '1f1f5-1f1f1': {
    hexCodePoints: '1f1f5-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇱',
    version: 'E2.0',
    baseName: 'flag: Poland',
    fullName: 'flag: Poland',
    isBase: true,
    baseHex: '1f1f5-1f1f1',
    modifiers: null,
  },
  '1f1f5-1f1f2': {
    hexCodePoints: '1f1f5-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇲',
    version: 'E2.0',
    baseName: 'flag: St. Pierre & Miquelon',
    fullName: 'flag: St. Pierre & Miquelon',
    isBase: true,
    baseHex: '1f1f5-1f1f2',
    modifiers: null,
  },
  '1f1f5-1f1f3': {
    hexCodePoints: '1f1f5-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇳',
    version: 'E2.0',
    baseName: 'flag: Pitcairn Islands',
    fullName: 'flag: Pitcairn Islands',
    isBase: true,
    baseHex: '1f1f5-1f1f3',
    modifiers: null,
  },
  '1f1f5-1f1f7': {
    hexCodePoints: '1f1f5-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇷',
    version: 'E2.0',
    baseName: 'flag: Puerto Rico',
    fullName: 'flag: Puerto Rico',
    isBase: true,
    baseHex: '1f1f5-1f1f7',
    modifiers: null,
  },
  '1f1f5-1f1f8': {
    hexCodePoints: '1f1f5-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇸',
    version: 'E2.0',
    baseName: 'flag: Palestinian Territories',
    fullName: 'flag: Palestinian Territories',
    isBase: true,
    baseHex: '1f1f5-1f1f8',
    modifiers: null,
  },
  '1f1f5-1f1f9': {
    hexCodePoints: '1f1f5-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇹',
    version: 'E2.0',
    baseName: 'flag: Portugal',
    fullName: 'flag: Portugal',
    isBase: true,
    baseHex: '1f1f5-1f1f9',
    modifiers: null,
  },
  '1f1f5-1f1fc': {
    hexCodePoints: '1f1f5-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇼',
    version: 'E2.0',
    baseName: 'flag: Palau',
    fullName: 'flag: Palau',
    isBase: true,
    baseHex: '1f1f5-1f1fc',
    modifiers: null,
  },
  '1f1f5-1f1fe': {
    hexCodePoints: '1f1f5-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇵🇾',
    version: 'E2.0',
    baseName: 'flag: Paraguay',
    fullName: 'flag: Paraguay',
    isBase: true,
    baseHex: '1f1f5-1f1fe',
    modifiers: null,
  },
  '1f1f6-1f1e6': {
    hexCodePoints: '1f1f6-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇶🇦',
    version: 'E2.0',
    baseName: 'flag: Qatar',
    fullName: 'flag: Qatar',
    isBase: true,
    baseHex: '1f1f6-1f1e6',
    modifiers: null,
  },
  '1f1f7-1f1ea': {
    hexCodePoints: '1f1f7-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇷🇪',
    version: 'E2.0',
    baseName: 'flag: Réunion',
    fullName: 'flag: Réunion',
    isBase: true,
    baseHex: '1f1f7-1f1ea',
    modifiers: null,
  },
  '1f1f7-1f1f4': {
    hexCodePoints: '1f1f7-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇷🇴',
    version: 'E2.0',
    baseName: 'flag: Romania',
    fullName: 'flag: Romania',
    isBase: true,
    baseHex: '1f1f7-1f1f4',
    modifiers: null,
  },
  '1f1f7-1f1f8': {
    hexCodePoints: '1f1f7-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇷🇸',
    version: 'E2.0',
    baseName: 'flag: Serbia',
    fullName: 'flag: Serbia',
    isBase: true,
    baseHex: '1f1f7-1f1f8',
    modifiers: null,
  },
  '1f1f7-1f1fa': {
    hexCodePoints: '1f1f7-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇷🇺',
    version: 'E0.6',
    baseName: 'flag: Russia',
    fullName: 'flag: Russia',
    isBase: true,
    baseHex: '1f1f7-1f1fa',
    modifiers: null,
  },
  '1f1f7-1f1fc': {
    hexCodePoints: '1f1f7-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇷🇼',
    version: 'E2.0',
    baseName: 'flag: Rwanda',
    fullName: 'flag: Rwanda',
    isBase: true,
    baseHex: '1f1f7-1f1fc',
    modifiers: null,
  },
  '1f1f8-1f1e6': {
    hexCodePoints: '1f1f8-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇦',
    version: 'E2.0',
    baseName: 'flag: Saudi Arabia',
    fullName: 'flag: Saudi Arabia',
    isBase: true,
    baseHex: '1f1f8-1f1e6',
    modifiers: null,
  },
  '1f1f8-1f1e7': {
    hexCodePoints: '1f1f8-1f1e7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇧',
    version: 'E2.0',
    baseName: 'flag: Solomon Islands',
    fullName: 'flag: Solomon Islands',
    isBase: true,
    baseHex: '1f1f8-1f1e7',
    modifiers: null,
  },
  '1f1f8-1f1e8': {
    hexCodePoints: '1f1f8-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇨',
    version: 'E2.0',
    baseName: 'flag: Seychelles',
    fullName: 'flag: Seychelles',
    isBase: true,
    baseHex: '1f1f8-1f1e8',
    modifiers: null,
  },
  '1f1f8-1f1e9': {
    hexCodePoints: '1f1f8-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇩',
    version: 'E2.0',
    baseName: 'flag: Sudan',
    fullName: 'flag: Sudan',
    isBase: true,
    baseHex: '1f1f8-1f1e9',
    modifiers: null,
  },
  '1f1f8-1f1ea': {
    hexCodePoints: '1f1f8-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇪',
    version: 'E2.0',
    baseName: 'flag: Sweden',
    fullName: 'flag: Sweden',
    isBase: true,
    baseHex: '1f1f8-1f1ea',
    modifiers: null,
  },
  '1f1f8-1f1ec': {
    hexCodePoints: '1f1f8-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇬',
    version: 'E2.0',
    baseName: 'flag: Singapore',
    fullName: 'flag: Singapore',
    isBase: true,
    baseHex: '1f1f8-1f1ec',
    modifiers: null,
  },
  '1f1f8-1f1ed': {
    hexCodePoints: '1f1f8-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇭',
    version: 'E2.0',
    baseName: 'flag: St. Helena',
    fullName: 'flag: St. Helena',
    isBase: true,
    baseHex: '1f1f8-1f1ed',
    modifiers: null,
  },
  '1f1f8-1f1ee': {
    hexCodePoints: '1f1f8-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇮',
    version: 'E2.0',
    baseName: 'flag: Slovenia',
    fullName: 'flag: Slovenia',
    isBase: true,
    baseHex: '1f1f8-1f1ee',
    modifiers: null,
  },
  '1f1f8-1f1ef': {
    hexCodePoints: '1f1f8-1f1ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇯',
    version: 'E2.0',
    baseName: 'flag: Svalbard & Jan Mayen',
    fullName: 'flag: Svalbard & Jan Mayen',
    isBase: true,
    baseHex: '1f1f8-1f1ef',
    modifiers: null,
  },
  '1f1f8-1f1f0': {
    hexCodePoints: '1f1f8-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇰',
    version: 'E2.0',
    baseName: 'flag: Slovakia',
    fullName: 'flag: Slovakia',
    isBase: true,
    baseHex: '1f1f8-1f1f0',
    modifiers: null,
  },
  '1f1f8-1f1f1': {
    hexCodePoints: '1f1f8-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇱',
    version: 'E2.0',
    baseName: 'flag: Sierra Leone',
    fullName: 'flag: Sierra Leone',
    isBase: true,
    baseHex: '1f1f8-1f1f1',
    modifiers: null,
  },
  '1f1f8-1f1f2': {
    hexCodePoints: '1f1f8-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇲',
    version: 'E2.0',
    baseName: 'flag: San Marino',
    fullName: 'flag: San Marino',
    isBase: true,
    baseHex: '1f1f8-1f1f2',
    modifiers: null,
  },
  '1f1f8-1f1f3': {
    hexCodePoints: '1f1f8-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇳',
    version: 'E2.0',
    baseName: 'flag: Senegal',
    fullName: 'flag: Senegal',
    isBase: true,
    baseHex: '1f1f8-1f1f3',
    modifiers: null,
  },
  '1f1f8-1f1f4': {
    hexCodePoints: '1f1f8-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇴',
    version: 'E2.0',
    baseName: 'flag: Somalia',
    fullName: 'flag: Somalia',
    isBase: true,
    baseHex: '1f1f8-1f1f4',
    modifiers: null,
  },
  '1f1f8-1f1f7': {
    hexCodePoints: '1f1f8-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇷',
    version: 'E2.0',
    baseName: 'flag: Suriname',
    fullName: 'flag: Suriname',
    isBase: true,
    baseHex: '1f1f8-1f1f7',
    modifiers: null,
  },
  '1f1f8-1f1f8': {
    hexCodePoints: '1f1f8-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇸',
    version: 'E2.0',
    baseName: 'flag: South Sudan',
    fullName: 'flag: South Sudan',
    isBase: true,
    baseHex: '1f1f8-1f1f8',
    modifiers: null,
  },
  '1f1f8-1f1f9': {
    hexCodePoints: '1f1f8-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇹',
    version: 'E2.0',
    baseName: 'flag: São Tomé & Príncipe',
    fullName: 'flag: São Tomé & Príncipe',
    isBase: true,
    baseHex: '1f1f8-1f1f9',
    modifiers: null,
  },
  '1f1f8-1f1fb': {
    hexCodePoints: '1f1f8-1f1fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇻',
    version: 'E2.0',
    baseName: 'flag: El Salvador',
    fullName: 'flag: El Salvador',
    isBase: true,
    baseHex: '1f1f8-1f1fb',
    modifiers: null,
  },
  '1f1f8-1f1fd': {
    hexCodePoints: '1f1f8-1f1fd',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇽',
    version: 'E2.0',
    baseName: 'flag: Sint Maarten',
    fullName: 'flag: Sint Maarten',
    isBase: true,
    baseHex: '1f1f8-1f1fd',
    modifiers: null,
  },
  '1f1f8-1f1fe': {
    hexCodePoints: '1f1f8-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇾',
    version: 'E2.0',
    baseName: 'flag: Syria',
    fullName: 'flag: Syria',
    isBase: true,
    baseHex: '1f1f8-1f1fe',
    modifiers: null,
  },
  '1f1f8-1f1ff': {
    hexCodePoints: '1f1f8-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇸🇿',
    version: 'E2.0',
    baseName: 'flag: Eswatini',
    fullName: 'flag: Eswatini',
    isBase: true,
    baseHex: '1f1f8-1f1ff',
    modifiers: null,
  },
  '1f1f9-1f1e6': {
    hexCodePoints: '1f1f9-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇦',
    version: 'E2.0',
    baseName: 'flag: Tristan da Cunha',
    fullName: 'flag: Tristan da Cunha',
    isBase: true,
    baseHex: '1f1f9-1f1e6',
    modifiers: null,
  },
  '1f1f9-1f1e8': {
    hexCodePoints: '1f1f9-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇨',
    version: 'E2.0',
    baseName: 'flag: Turks & Caicos Islands',
    fullName: 'flag: Turks & Caicos Islands',
    isBase: true,
    baseHex: '1f1f9-1f1e8',
    modifiers: null,
  },
  '1f1f9-1f1e9': {
    hexCodePoints: '1f1f9-1f1e9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇩',
    version: 'E2.0',
    baseName: 'flag: Chad',
    fullName: 'flag: Chad',
    isBase: true,
    baseHex: '1f1f9-1f1e9',
    modifiers: null,
  },
  '1f1f9-1f1eb': {
    hexCodePoints: '1f1f9-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇫',
    version: 'E2.0',
    baseName: 'flag: French Southern Territories',
    fullName: 'flag: French Southern Territories',
    isBase: true,
    baseHex: '1f1f9-1f1eb',
    modifiers: null,
  },
  '1f1f9-1f1ec': {
    hexCodePoints: '1f1f9-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇬',
    version: 'E2.0',
    baseName: 'flag: Togo',
    fullName: 'flag: Togo',
    isBase: true,
    baseHex: '1f1f9-1f1ec',
    modifiers: null,
  },
  '1f1f9-1f1ed': {
    hexCodePoints: '1f1f9-1f1ed',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇭',
    version: 'E2.0',
    baseName: 'flag: Thailand',
    fullName: 'flag: Thailand',
    isBase: true,
    baseHex: '1f1f9-1f1ed',
    modifiers: null,
  },
  '1f1f9-1f1ef': {
    hexCodePoints: '1f1f9-1f1ef',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇯',
    version: 'E2.0',
    baseName: 'flag: Tajikistan',
    fullName: 'flag: Tajikistan',
    isBase: true,
    baseHex: '1f1f9-1f1ef',
    modifiers: null,
  },
  '1f1f9-1f1f0': {
    hexCodePoints: '1f1f9-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇰',
    version: 'E2.0',
    baseName: 'flag: Tokelau',
    fullName: 'flag: Tokelau',
    isBase: true,
    baseHex: '1f1f9-1f1f0',
    modifiers: null,
  },
  '1f1f9-1f1f1': {
    hexCodePoints: '1f1f9-1f1f1',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇱',
    version: 'E2.0',
    baseName: 'flag: Timor-Leste',
    fullName: 'flag: Timor-Leste',
    isBase: true,
    baseHex: '1f1f9-1f1f1',
    modifiers: null,
  },
  '1f1f9-1f1f2': {
    hexCodePoints: '1f1f9-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇲',
    version: 'E2.0',
    baseName: 'flag: Turkmenistan',
    fullName: 'flag: Turkmenistan',
    isBase: true,
    baseHex: '1f1f9-1f1f2',
    modifiers: null,
  },
  '1f1f9-1f1f3': {
    hexCodePoints: '1f1f9-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇳',
    version: 'E2.0',
    baseName: 'flag: Tunisia',
    fullName: 'flag: Tunisia',
    isBase: true,
    baseHex: '1f1f9-1f1f3',
    modifiers: null,
  },
  '1f1f9-1f1f4': {
    hexCodePoints: '1f1f9-1f1f4',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇴',
    version: 'E2.0',
    baseName: 'flag: Tonga',
    fullName: 'flag: Tonga',
    isBase: true,
    baseHex: '1f1f9-1f1f4',
    modifiers: null,
  },
  '1f1f9-1f1f7': {
    hexCodePoints: '1f1f9-1f1f7',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇷',
    version: 'E2.0',
    baseName: 'flag: Türkiye',
    fullName: 'flag: Türkiye',
    isBase: true,
    baseHex: '1f1f9-1f1f7',
    modifiers: null,
  },
  '1f1f9-1f1f9': {
    hexCodePoints: '1f1f9-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇹',
    version: 'E2.0',
    baseName: 'flag: Trinidad & Tobago',
    fullName: 'flag: Trinidad & Tobago',
    isBase: true,
    baseHex: '1f1f9-1f1f9',
    modifiers: null,
  },
  '1f1f9-1f1fb': {
    hexCodePoints: '1f1f9-1f1fb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇻',
    version: 'E2.0',
    baseName: 'flag: Tuvalu',
    fullName: 'flag: Tuvalu',
    isBase: true,
    baseHex: '1f1f9-1f1fb',
    modifiers: null,
  },
  '1f1f9-1f1fc': {
    hexCodePoints: '1f1f9-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇼',
    version: 'E2.0',
    baseName: 'flag: Taiwan',
    fullName: 'flag: Taiwan',
    isBase: true,
    baseHex: '1f1f9-1f1fc',
    modifiers: null,
  },
  '1f1f9-1f1ff': {
    hexCodePoints: '1f1f9-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇹🇿',
    version: 'E2.0',
    baseName: 'flag: Tanzania',
    fullName: 'flag: Tanzania',
    isBase: true,
    baseHex: '1f1f9-1f1ff',
    modifiers: null,
  },
  '1f1fa-1f1e6': {
    hexCodePoints: '1f1fa-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇦',
    version: 'E2.0',
    baseName: 'flag: Ukraine',
    fullName: 'flag: Ukraine',
    isBase: true,
    baseHex: '1f1fa-1f1e6',
    modifiers: null,
  },
  '1f1fa-1f1ec': {
    hexCodePoints: '1f1fa-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇬',
    version: 'E2.0',
    baseName: 'flag: Uganda',
    fullName: 'flag: Uganda',
    isBase: true,
    baseHex: '1f1fa-1f1ec',
    modifiers: null,
  },
  '1f1fa-1f1f2': {
    hexCodePoints: '1f1fa-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇲',
    version: 'E2.0',
    baseName: 'flag: U.S. Outlying Islands',
    fullName: 'flag: U.S. Outlying Islands',
    isBase: true,
    baseHex: '1f1fa-1f1f2',
    modifiers: null,
  },
  '1f1fa-1f1f3': {
    hexCodePoints: '1f1fa-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇳',
    version: 'E4.0',
    baseName: 'flag: United Nations',
    fullName: 'flag: United Nations',
    isBase: true,
    baseHex: '1f1fa-1f1f3',
    modifiers: null,
  },
  '1f1fa-1f1f8': {
    hexCodePoints: '1f1fa-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇸',
    version: 'E0.6',
    baseName: 'flag: United States',
    fullName: 'flag: United States',
    isBase: true,
    baseHex: '1f1fa-1f1f8',
    modifiers: null,
  },
  '1f1fa-1f1fe': {
    hexCodePoints: '1f1fa-1f1fe',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇾',
    version: 'E2.0',
    baseName: 'flag: Uruguay',
    fullName: 'flag: Uruguay',
    isBase: true,
    baseHex: '1f1fa-1f1fe',
    modifiers: null,
  },
  '1f1fa-1f1ff': {
    hexCodePoints: '1f1fa-1f1ff',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇺🇿',
    version: 'E2.0',
    baseName: 'flag: Uzbekistan',
    fullName: 'flag: Uzbekistan',
    isBase: true,
    baseHex: '1f1fa-1f1ff',
    modifiers: null,
  },
  '1f1fb-1f1e6': {
    hexCodePoints: '1f1fb-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇦',
    version: 'E2.0',
    baseName: 'flag: Vatican City',
    fullName: 'flag: Vatican City',
    isBase: true,
    baseHex: '1f1fb-1f1e6',
    modifiers: null,
  },
  '1f1fb-1f1e8': {
    hexCodePoints: '1f1fb-1f1e8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇨',
    version: 'E2.0',
    baseName: 'flag: St. Vincent & Grenadines',
    fullName: 'flag: St. Vincent & Grenadines',
    isBase: true,
    baseHex: '1f1fb-1f1e8',
    modifiers: null,
  },
  '1f1fb-1f1ea': {
    hexCodePoints: '1f1fb-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇪',
    version: 'E2.0',
    baseName: 'flag: Venezuela',
    fullName: 'flag: Venezuela',
    isBase: true,
    baseHex: '1f1fb-1f1ea',
    modifiers: null,
  },
  '1f1fb-1f1ec': {
    hexCodePoints: '1f1fb-1f1ec',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇬',
    version: 'E2.0',
    baseName: 'flag: British Virgin Islands',
    fullName: 'flag: British Virgin Islands',
    isBase: true,
    baseHex: '1f1fb-1f1ec',
    modifiers: null,
  },
  '1f1fb-1f1ee': {
    hexCodePoints: '1f1fb-1f1ee',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇮',
    version: 'E2.0',
    baseName: 'flag: U.S. Virgin Islands',
    fullName: 'flag: U.S. Virgin Islands',
    isBase: true,
    baseHex: '1f1fb-1f1ee',
    modifiers: null,
  },
  '1f1fb-1f1f3': {
    hexCodePoints: '1f1fb-1f1f3',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇳',
    version: 'E2.0',
    baseName: 'flag: Vietnam',
    fullName: 'flag: Vietnam',
    isBase: true,
    baseHex: '1f1fb-1f1f3',
    modifiers: null,
  },
  '1f1fb-1f1fa': {
    hexCodePoints: '1f1fb-1f1fa',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇻🇺',
    version: 'E2.0',
    baseName: 'flag: Vanuatu',
    fullName: 'flag: Vanuatu',
    isBase: true,
    baseHex: '1f1fb-1f1fa',
    modifiers: null,
  },
  '1f1fc-1f1eb': {
    hexCodePoints: '1f1fc-1f1eb',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇼🇫',
    version: 'E2.0',
    baseName: 'flag: Wallis & Futuna',
    fullName: 'flag: Wallis & Futuna',
    isBase: true,
    baseHex: '1f1fc-1f1eb',
    modifiers: null,
  },
  '1f1fc-1f1f8': {
    hexCodePoints: '1f1fc-1f1f8',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇼🇸',
    version: 'E2.0',
    baseName: 'flag: Samoa',
    fullName: 'flag: Samoa',
    isBase: true,
    baseHex: '1f1fc-1f1f8',
    modifiers: null,
  },
  '1f1fd-1f1f0': {
    hexCodePoints: '1f1fd-1f1f0',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇽🇰',
    version: 'E2.0',
    baseName: 'flag: Kosovo',
    fullName: 'flag: Kosovo',
    isBase: true,
    baseHex: '1f1fd-1f1f0',
    modifiers: null,
  },
  '1f1fe-1f1ea': {
    hexCodePoints: '1f1fe-1f1ea',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇾🇪',
    version: 'E2.0',
    baseName: 'flag: Yemen',
    fullName: 'flag: Yemen',
    isBase: true,
    baseHex: '1f1fe-1f1ea',
    modifiers: null,
  },
  '1f1fe-1f1f9': {
    hexCodePoints: '1f1fe-1f1f9',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇾🇹',
    version: 'E2.0',
    baseName: 'flag: Mayotte',
    fullName: 'flag: Mayotte',
    isBase: true,
    baseHex: '1f1fe-1f1f9',
    modifiers: null,
  },
  '1f1ff-1f1e6': {
    hexCodePoints: '1f1ff-1f1e6',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇿🇦',
    version: 'E2.0',
    baseName: 'flag: South Africa',
    fullName: 'flag: South Africa',
    isBase: true,
    baseHex: '1f1ff-1f1e6',
    modifiers: null,
  },
  '1f1ff-1f1f2': {
    hexCodePoints: '1f1ff-1f1f2',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇿🇲',
    version: 'E2.0',
    baseName: 'flag: Zambia',
    fullName: 'flag: Zambia',
    isBase: true,
    baseHex: '1f1ff-1f1f2',
    modifiers: null,
  },
  '1f1ff-1f1fc': {
    hexCodePoints: '1f1ff-1f1fc',
    qualifiedStatus: 'fully-qualified',
    emoji: '🇿🇼',
    version: 'E2.0',
    baseName: 'flag: Zimbabwe',
    fullName: 'flag: Zimbabwe',
    isBase: true,
    baseHex: '1f1ff-1f1fc',
    modifiers: null,
  },
  '1f3f4-e0067-e0062-e0065-e006e-e0067-e007f': {
    hexCodePoints: '1f3f4-e0067-e0062-e0065-e006e-e0067-e007f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    version: 'E5.0',
    baseName: 'flag: England',
    fullName: 'flag: England',
    isBase: true,
    baseHex: '1f3f4-e0067-e0062-e0065-e006e-e0067-e007f',
    modifiers: null,
  },
  '1f3f4-e0067-e0062-e0073-e0063-e0074-e007f': {
    hexCodePoints: '1f3f4-e0067-e0062-e0073-e0063-e0074-e007f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    version: 'E5.0',
    baseName: 'flag: Scotland',
    fullName: 'flag: Scotland',
    isBase: true,
    baseHex: '1f3f4-e0067-e0062-e0073-e0063-e0074-e007f',
    modifiers: null,
  },
  '1f3f4-e0067-e0062-e0077-e006c-e0073-e007f': {
    hexCodePoints: '1f3f4-e0067-e0062-e0077-e006c-e0073-e007f',
    qualifiedStatus: 'fully-qualified',
    emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    version: 'E5.0',
    baseName: 'flag: Wales',
    fullName: 'flag: Wales',
    isBase: true,
    baseHex: '1f3f4-e0067-e0062-e0077-e006c-e0073-e007f',
    modifiers: null,
  },
};
