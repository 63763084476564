// @ts-nocheck
export default class Logger {
  name: string;
  level: string;

  constructor({ name, level }: { name: string; level: string }) {
    this.name = name;
    this.level = level;
  }

  setLevel(level: string) {
    this.level = level.toLowerCase();
  }
}

export const LOG_LEVELS = ['debug', 'log', 'info', 'warn', 'error'];
const LOG_LEVEL_N_BY_LEVEL = LOG_LEVELS.reduce((all, curr, i) => {
  all[curr] = i;
  return all;
}, {});

LOG_LEVELS.forEach((method) => {
  const methodLevel = LOG_LEVEL_N_BY_LEVEL[method];

  Logger.prototype[method] = function (...args) {
    if (methodLevel >= LOG_LEVEL_N_BY_LEVEL[this.level]) {
      args = [`[${this.name}]`, ...args];
      // supports IE
      Function.prototype.apply.call(console[method], console, args);
    }
  };
});
