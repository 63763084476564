import { Message } from '../../../types';

const getLimitedMessages = (
  originalMessages: Message[],
  {
    hasUnreadMessages = false,
    numMessagesToShowBottom = 0,
    numMessagesToShowTop = 0,
    unreadCount = 0,
  } = {}
) => {
  let limitedMessages;

  if (hasUnreadMessages) {
    const firstUnreadIdx = originalMessages.length - unreadCount;
    limitedMessages = originalMessages.slice(
      Math.max(0, firstUnreadIdx - numMessagesToShowTop),
      firstUnreadIdx + numMessagesToShowBottom
    );
  } else {
    limitedMessages = originalMessages.slice(
      Math.max(0, originalMessages.length - numMessagesToShowTop)
    );
  }

  return limitedMessages;
};

export default getLimitedMessages;
