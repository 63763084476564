const FeatureService = {
  DEFAULT: '',
  ALERTS: 'alerts',
  GROUP_ALERTS: 'group_alerts',
  PATIENT_MESSAGING: 'patient_messaging',
  ROLE_ALERTS: 'role_alerts',
  VIRTUAL_WAITING_ROOM: 'vwr',
  WORKFLOW_NOTIFICATION: 'cph',
} as const;

export default FeatureService;
