// @ts-nocheck
import compact from 'lodash/compact';
import Camelizer from '../utils/Camelizer';
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { TEAMS_CREATE, TEAMS_FIND, TEAMS_LEAVE, TEAMS_UPDATE } = ROUTES;

export default class TeamsAPI extends BaseAPI {
  async find(id: string, { organizationId }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_FIND);

    const res = await this.httpClient.get('/:version/team/:team_id', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        team_id: id,
        version,
      },
    });

    return res.data;
  }

  /**
   * POST /teams - create a team
   * @param  {File} options.avatar - Avatar
   * @param {boolean} options.canRequestToJoin - Check if a user can request to join a team
   * @param {boolean} options.canMembersLeave - Check if members can leave a team
   * @param  {string} options.description - Team description
   * @param  {string} options.display_name - Team name
   * @param  {Array<string>} options.members - Member IDs
   * @param  {string} options.tagId - Tag ID
   * @return {Promise.<Object,Error>} - A promise with an object representing a team
   */
  async create({
    avatar,
    canMembersLeave = true,
    canRequestToJoin = true,
    description,
    displayName,
    memberIds = [],
    organizationId,
    tagId,
  }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_CREATE);
    const headers = {};
    const data = {
      can_members_leave: canMembersLeave,
      can_request_to_join: canRequestToJoin,
      display_name: displayName,
      members: memberIds,
    };

    const files = {};

    if (avatar) files['avatar'] = avatar;
    if (description) data['description'] = description;
    if (tagId !== undefined) data['tag_ids'] = compact([tagId]);

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
    }

    const res = await this.httpClient.post('/:version/team', {
      urlParams: { version },
      headers,
      data,
      files,
    });

    return res.data;
  }

  async update({
    avatar,
    canMembersLeave = true,
    canRequestToJoin = true,
    description,
    displayName,
    id,
    memberIds = [],
    organizationId,
    tagId,
  }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_UPDATE);
    const headers = {};
    const data = {
      can_members_leave: canMembersLeave,
      can_request_to_join: canRequestToJoin,
      display_name: displayName,
      members: memberIds,
    };

    const files = {};

    if (avatar) files['avatar'] = avatar;
    if (description) data['description'] = description;
    if (tagId !== undefined) data['tag_ids'] = compact([tagId]);

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
    }

    const res = await this.httpClient.post('/:version/team/:id', {
      urlParams: { id, version },
      headers,
      data,
      files,
    });

    return res.data;
  }

  async delete(teamId, { organizationId }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_CREATE);

    const res = await this.httpClient.del('/:version/team/:id', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        version,
        id: teamId,
      },
    });

    return res.status;
  }

  async requestToJoin(teamId, { organizationId, requestedById }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_CREATE);

    const res = await this.httpClient.post('/:version/team/:id/request', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        id: teamId,
        version,
      },
      data: {
        requested_by: requestedById,
      },
    });

    return res.status;
  }

  async updateJoinRequest(requestId, { organizationId, teamId, handledAsId, status }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_CREATE);

    const res = await this.httpClient.post('/:version/team/:team_id/request/:request_id', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        version,
        team_id: teamId,
        request_id: requestId,
      },
      data: {
        handled_as: handledAsId,
        status: status,
      },
    });

    return res;
  }

  async leave(teamId, { organizationId }) {
    const { version = VERSION_TWO } = super.getVersion(TEAMS_LEAVE);

    const res = await this.httpClient.post('/:version/team/:team_id/leave', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        team_id: teamId,
        version,
      },
      data: {},
    });

    return res.status;
  }

  @recoverFromNotFound()
  async findSavedTeams(userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/user/:userId/saved_teams', {
      urlParams: { userId },
      headers,
    });

    return Camelizer.camelizeObject(res.data);
  }

  async saveTeam(teamId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.post('/v2/user/:userId/saved_teams', {
      urlParams: { userId },
      data: {
        team_id: teamId,
      },
      headers,
    });
  }

  async removeSavedTeam(teamId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del('/v2/user/:userId/saved_teams/:teamId', {
      urlParams: { userId, teamId },
      headers,
    });
  }
}
