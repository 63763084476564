import Camelizer from '../../utils/Camelizer';
import {
  ServerUserSettings,
  ServerUserData,
  ServerPendingUser,
  ServerAccountSearch,
  ServerUserOrganizationSettings,
  ProductRole,
} from '../../services/Admin/utils/types';
import BaseAdminAPI from './BaseAdminAPI';

const CONSUMER_KEY = 'bc1oOf3DZXbnaNZ2LCWZGvSg';

export class UsersAPI extends BaseAdminAPI {
  async getUserSettings({ userId, organizationId }: { userId: string; organizationId: string }) {
    const { data } = (await this.cnDevApi({
      method: 'get',
      endpoint: `v2/user/${userId}/organization/${organizationId}`,
      organizationId,
    })) as {
      data: {
        settings: { value: unknown; name: keyof ServerUserSettings }[];
      };
    };
    return data;
  }

  async setAccountSettings({
    userId,
    settings,
  }: {
    userId: string;
    settings: {
      set: Record<string, unknown>;
      del: Record<string, unknown>;
    };
  }) {
    const data = { token: userId, settings: { token: userId, settings }, ts: new Date().getTime() };
    await this.cnRoute({
      method: 'post',
      route: 'account_settings',
      data,
      requestFormat: 'form',
    });
  }

  async getAccountSettings({
    userId,
    organizationId,
  }: {
    userId?: string;
    organizationId?: string;
  } = {}): Promise<ServerUserData> {
    const { data } = await this.cnRoute<{ settings: ServerUserData }>({
      method: 'post',
      route: 'account_settings',
      data: {
        ...(userId &&
          organizationId && {
            tokens: [userId],
            organization_ids: [organizationId],
          }),
      },
    });

    if (!Array.isArray(data.settings.phone_numbers) && data.settings.phone_numbers) {
      data.settings.phone_numbers = [data.settings.phone_numbers];
      if (
        !Array.isArray(data.settings.phone_numbers[0].phone_number) &&
        data.settings.phone_numbers[0].phone_number
      ) {
        data.settings.phone_numbers[0].phone_number = [data.settings.phone_numbers[0].phone_number];
      }
    }

    return data.settings;
  }

  async accountSearch({
    searchInput,
    organizationId,
  }: {
    searchInput: string;
    organizationId: string;
  }) {
    const data = {
      search: [
        {
          key: 'email_address',
          value: searchInput,
        },
        {
          key: 'username',
          value: searchInput,
        },
        {
          key: 'phone_number',
          value: searchInput,
        },
        {
          key: 'organization_id',
          value: organizationId,
        },
      ],
    };
    const res = await this.cnRoute<ServerAccountSearch>({
      route: 'account_search',
      method: 'get',
      data,
    });

    return res.data;
  }

  async createUser({
    organizationId,
    formData,
  }: {
    organizationId: string;
    formData: {
      displayName: string;
      firstName: string;
      lastName: string;
      department: string;
      title: string;
      emailAddress: string;
      password: string;
      mobilePhone?: string;
    };
  }) {
    const data = {
      data: {
        organization_id: organizationId,
        display_name: formData.displayName,
        first_name: formData.firstName,
        last_name: formData.lastName,
        password: formData.password,
        settings: {
          mobile_phone: formData.mobilePhone || '',
          email_address: formData.emailAddress,
          title: formData.title,
          department: formData.department,
        },
      },
    };

    const res = await this.cnRoute({
      data,
      method: 'post',
      route: 'organization_account',
    });

    return res.data;
  }

  async getPendingUsers(orgId: string, query = ''): Promise<ServerPendingUser[]> {
    const req = {
      ac_version: '4',
      organization_id: orgId,
      organizations: orgId,
      query,
      type: 'account',
      ignore_sg: 'true',
      fields: {
        pending_admin_approval: 'true',
      },
    };

    const { data } = (await this.cnRoute({
      method: 'get',
      route: 'account_search',
      data: req,
    })) as {
      data: { entities: { entity: ServerPendingUser | ServerPendingUser[] } };
    };

    if (!data?.entities?.entity) return [];
    const pendingUsers = Array.isArray(data?.entities?.entity)
      ? data.entities.entity
      : [data.entities.entity];

    return pendingUsers.map((pendingUser) => {
      pendingUser.id = pendingUser.token;
      return pendingUser;
    });
  }

  async addPendingUser(data: {
    organization_id: string;
    username: string;
    settings: { email_address: string };
  }) {
    const req = {
      data,
    };

    await this.cnRoute({
      method: 'post',
      route: 'organization_account',
      data: req,
    });
  }

  async declinePendingUser(data: { organization_id: string; token: string }) {
    const req = {
      ...data,
      action: 'disable',
      delete_in: '0',
    };

    await this.cnRoute({
      method: 'get',
      route: 'account_admin',
      data: req,
    });
  }

  async fetchOrganizationSettings({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<ServerUserOrganizationSettings> {
    const route = `organization`;

    const res = await this.cnRoute<{ organization: ServerUserOrganizationSettings }>({
      data: {
        organization_id: organizationId,
      },
      method: 'get',
      route,
    });

    return res.data.organization;
  }

  async getUserSecurityGroups({ orgId, userId }: { userId: string; orgId: string }) {
    const data = {
      token: userId,
      type: 'get',
      organization_id: orgId,
    };
    const res = await this.cnRoute({
      route: 'security_groups_devapi',
      data,
      method: 'get',
    });
    return res.data;
  }

  async updateUserSecurityGroups({
    orgId,
    userId,
    groups,
  }: {
    userId: string;
    orgId: string;
    groups: string[];
  }) {
    const data = {
      token: userId,
      type: 'set',
      organization_id: orgId,
      groups,
    };
    const res = await this.cnRoute({
      route: 'security_groups_devapi',
      data,
      method: 'post',
    });
    return res.data;
  }

  async getDevices({ orgId, userId }: { orgId: string; userId: string }) {
    const endpoint = `v2/user/${userId}/resources`;
    const res = await this.cnDevApi({
      organizationId: orgId,
      endpoint,
      method: 'get',
    });
    return res.data;
  }

  async getUserPermissionRoles({ userId, orgId }: { userId: string; orgId: string }) {
    const res = await this.cnDevApi<{
      roles: { organization: { organization_id: string; roles: string[] } }[];
    }>({
      endpoint: `v2/user/${userId}/organization/${orgId}/roles`,
      method: 'get',
      organizationId: orgId,
    });
    return res.data;
  }

  async updateUserPermissionRole({
    userId,
    orgId,
    permission,
    action,
  }: {
    userId: string;
    orgId: string;
    permission: string;
    action: 'DEL' | 'ADD';
  }) {
    await this.cnDevApi({
      method: action === 'ADD' ? 'post' : 'del',
      organizationId: orgId,
      endpoint: `v2/user/${userId}/organization/${orgId}/roles/${permission}`,
    });
  }

  async deletePagerNumber(number: string) {
    const res = await this.cnRoute({
      route: 'twilio_delete_number',
      method: 'get',
      data: { number },
    });
    return res.data;
  }

  async getAvailablePagerNumbers(pagerNumber: string) {
    const res = await this.cnRoute<{ phone_number: string; friendly_name: string }[]>({
      route: 'twilio_available_numbers',
      data: { contains: pagerNumber },
    });
    return res.data;
  }

  async setIncomingNumber({ number, special }: { number: string; special: boolean }) {
    const res = await this.cnRoute({
      route: 'twilio_incoming_numbers',
      data: { number, special },
    });
    return res.data;
  }

  async getUserPagers({ userId, orgId }: { userId: string; orgId: string }) {
    const res = await this.cnDevApi<{ pager_numbers: { pager_number: string; label: string }[] }>({
      endpoint: `v2/user/${userId}/pagers`,
      organizationId: orgId,
      method: 'get',
    });
    return res.data;
  }

  async getPagerData({ phoneNumber }: { phoneNumber: string }) {
    const res = await this.cnRoute<string>({
      route: 'pager_lookup',
      data: { phone_number: phoneNumber },
    });
    return res.data;
  }

  async getApiKeys({ orgId, userId }: { orgId: string; userId: string }) {
    const { data } = await this.cnRoute<{
      entity?: { key: string; secret: string }[] | { key: string; secret: string };
    }>({
      data: {
        token: userId,
        organization_id: orgId,
      },
      route: 'api_key',
      method: 'get',
    });
    if (Array.isArray(data.entity)) {
      return data.entity;
    }
    if (data.entity) {
      return [data.entity];
    }
    return [];
  }

  async deleteApiKey({ apiKey, orgId, userId }: { apiKey: string; orgId: string; userId: string }) {
    const res = await this.cnRoute({
      route: 'api_key_delete',
      method: 'get',
      data: {
        apiKey,
        token: userId,
        organization_id: orgId,
      },
    });
    return res.data;
  }

  async createApiKey({ orgId, userId }: { orgId: string; userId: string }) {
    const { data } = await this.cnRoute<{ entity?: unknown }>({
      route: 'api_key_create',
      method: 'get',
      data: {
        token: userId,
        organization_id: orgId,
      },
    });
    return data.entity;
  }

  async savePassword({
    orgId,
    password,
    userId,
  }: {
    orgId: string;
    password: string;
    userId: string;
  }) {
    const res = await this.cnDevApi({
      endpoint: `v2/user/${userId}/temp_password`,
      method: 'post',
      data: { password },
      organizationId: orgId,
    });
    return res.data;
  }

  async resetPassword({ email }: { email: string }) {
    // https://tigertext.atlassian.net/browse/TMG-506
    const time = new Date().getTime().toString();
    const req = JSON.stringify({ email: encodeURIComponent(email), ts: time });
    const passwordForgetURL = `${this.host.cnUrl}/admin_cn${this.cnBox}/password_forget?req=${req}&_=${time}`;
    const res = await fetch(passwordForgetURL);
    return res.text();
  }

  async remoteWipe({
    orgId,
    resource,
    userId,
  }: {
    orgId: string;
    resource?: string;
    userId: string;
  }) {
    const res = await this.cnRoute({
      method: 'get',
      route: 'remote_wipe',
      data: {
        token: userId,
        resource,
        organization: orgId,
      },
    });
    return res.data;
  }

  async accountDelete({ userId }: { userId: string }) {
    const res = await this.cnRoute({
      method: 'get',
      route: 'account_delete',
      data: { token: userId },
    });
    return res.data;
  }

  async getUserProductRoles({
    userId,
    organizationId,
  }: {
    userId: string;
    organizationId: string;
  }) {
    const res = await this.cnDevApi<{
      roles: { product: { roles: ProductRole[] } }[];
    }>({
      method: 'get',
      endpoint: `v2/user/${userId}/product/${CONSUMER_KEY}/roles`,
      organizationId,
    });
    return res.data;
  }

  async updateUserProductRole({
    userId,
    orgId,
    product,
    action,
  }: {
    userId: string;
    orgId: string;
    product: 'tt_admin' | 'tt_ldap' | 'tt_super_admin' | 'tt_reporting';
    action: 'DEL' | 'ADD';
  }) {
    const method = action === 'ADD' ? 'post' : 'del';
    const res = await this.cnDevApi({
      method,
      endpoint: `v2/user/${userId}/product/${CONSUMER_KEY}/roles/${product}`,
      organizationId: orgId,
    });
    return res.data;
  }

  async csvUploadUsers({ file, orgId }: { file: unknown; orgId: string }) {
    const res = await this.cnRoute<string, 'text'>({
      method: 'post',
      route: 'organization_account_batch',
      data: { organization_id: orgId },
      files: { csvFile: file },
      responseFormat: 'text',
    });
    const jsonIndex = res.indexOf('{');
    const jsonString = res.slice(jsonIndex);
    try {
      const data = Camelizer.camelizeObject<{ error: unknown[]; successful: number }>(
        JSON.parse(jsonString)
      );
      return { data };
    } catch (e) {
      console.error(e);
      return { data: { error: [{}], successful: 0 } };
    }
  }

  async getOrganization({ orgId, userId }: { orgId: string; userId: string }) {
    const res = await this.cnDevApi({
      method: 'get',
      endpoint: `v2/user/${userId}/organization/${orgId}`,
      organizationId: orgId,
    });
    return res.data;
  }

  async postOrganization({
    orgId,
    userId,
    data,
  }: {
    orgId: string;
    userId: string;
    data: Record<string, unknown>;
  }) {
    const res = await this.cnDevApi({
      method: 'post',
      endpoint: `v2/user/${userId}/organization/${orgId}`,
      organizationId: orgId,
      data,
    });
    return res.data;
  }

  async organizationLeave({ orgId, userId }: { orgId: string; userId: string }) {
    const res = await this.cnRoute({
      method: 'get',
      route: `organization_leave`,
      data: {
        token: userId,
        organization_id: orgId,
      },
    });

    return res.data;
  }

  async unlockUser({ userToken }: { userToken: string }) {
    const res = await this.cnDevApi({
      method: 'post',
      endpoint: `v2/user/unlock`,
      data: { user_token: userToken },
      // TODO
      organizationId: '',
    });

    return res;
  }

  async unlinkPhoneNumber({
    id,
    organizationId,
    phoneNumber,
  }: {
    id: string;
    organizationId: string;
    phoneNumber: string;
  }) {
    const encodeURIComponentPhoneNumber = encodeURIComponent(phoneNumber);

    const res = await this.cnDevApi({
      method: 'del',
      endpoint: `v2/user/${id}/linked_phone/${encodeURIComponentPhoneNumber}`,
      data: {
        token: id,
      },
      organizationId,
    });

    return res;
  }
}
