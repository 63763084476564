import { useCallback } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalAudioToggle() {
  const {
    getLocalAudioTrack,
    room: { localParticipant },
    localTracks,
    localTrackToggling: { toggleLocalTrack },
  } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === 'audio');
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    toggleLocalTrack('audio');
    if (audioTrack) {
      if (audioTrack.isEnabled) {
        audioTrack.disable();
      } else {
        audioTrack.enable();
      }
    } else {
      getLocalAudioTrack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTrack, localParticipant]);

  return [isEnabled, toggleAudioEnabled];
}
