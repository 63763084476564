import { useEffect, useState, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import TCClient from '../../../../client';

const commandBotUrl = TCClient.webInfraUrl + '/giphy-bot/';

type Response = Record<string, unknown> | null;
export const useLambdaFetch = (
  options: { body?: Record<string, unknown> },
  dontRunOnMount = false
): {
  response: Response;
  error: Response;
  isLoading: boolean;
  fetchData: (body?: Record<string, unknown>) => void;
} => {
  const hasRanInitialFetch = useRef(false);
  const [response, setResponse] = useState<Response>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = useCallback(
    async (body?: Record<string, unknown>) => {
      setIsLoading(true);

      try {
        const res = await fetch(commandBotUrl, {
          method: 'POST',
          body: JSON.stringify(body ? body : options?.body),
        });

        if (res.status === 204) {
          setResponse({});
          setIsLoading(false);
        } else {
          const json = await res.json();

          setResponse(json);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError(error);
      }
    },
    [options]
  );

  useEffect(() => {
    const debounceFetchData = debounce(fetchData, 400);
    if (hasRanInitialFetch.current && dontRunOnMount) return;
    debounceFetchData();
    hasRanInitialFetch.current = true;
    return () => {
      debounceFetchData.cancel();
    };
  }, [dontRunOnMount, fetchData]);

  return { response, error, isLoading, fetchData };
};
