// @ts-nocheck
import EventEmitter from 'events';
import _ from 'lodash';
import _filter from 'lodash-bound/filter';
import _flatten from 'lodash-bound/flatten';
import _keys from 'lodash-bound/keys';
import _map from 'lodash-bound/map';
import _toPairs from 'lodash-bound/toPairs';
import _uniq from 'lodash-bound/uniq';
import _values from 'lodash-bound/values';
import _without from 'lodash-bound/without';
import { generateComparator, isConfigurableProperty, jsonCloneDeep, SortedArray } from '../utils';

const MODEL_CLASS_DEFAULT_OPTIONS = {
  computed: {},
  defaultValues: null,
  idAttribute: 'id',
  methods: {},
  parseAttrs: _.identity,
  relations: {
    belongsTo: {},
    hasMany: {},
  },
};
const NOT_PROVIDED = Symbol('NOT_PROVIDED');

export class ModelBase {
  static create(store, name, options) {
    return new this(store, name, options);
  }

  constructor(store, name, options = {}) {
    this.options = _.defaultsDeep({}, options, MODEL_CLASS_DEFAULT_OPTIONS);
    this.idAttribute = this.options.idAttribute;

    this.name = name;
    this.store = store;

    this.entitiesById = {};
    this.entitiesByServerId = {};
    this.entitiesList = [];
    this.hasManySortFields = null;
    this.previousHasManySortValues = {};
    this.previousServerIds = {};
    this.transientAttrsStore = {};

    if (this.options.relations) {
      this.__initRelations();
    }

    this.__initTransientAttrs();

    Object.assign(this.options.computed, {
      $entityType: {
        enumerable: true,
        value: name,
        writable: false,
      },
      $entityClass: {
        enumerable: false,
        value: this,
        writable: false,
      },
    });

    this.options.computed = _.transform(
      this.options.computed,
      (all, v, k) => {
        if (typeof v === 'function') {
          all[k] = { get: v, enumerable: false };
        } else {
          all[k] = v;
        }
      },
      {}
    );

    // contains all user-defined computed and transient keys
    this._computedKeys = _without.call(
      _keys.call(this.options.computed),
      '$entityType',
      '$entityClass',
      '$$transientAttrs'
    );
    this._writablePropertyKeys = _map.call(
      _filter.call(_toPairs.call(this.options.computed), (a) => a[1].set),
      _.first
    );
    this._relationKeys = _flatten.call(_map.call(_values.call(this.options.relations), _.keys));
  }

  __initRelations() {
    const self = this;

    const belongsToRelationProperties = _.transform(
      this.options.relations.belongsTo,
      (all, config, relationKey) => {
        all[relationKey] = {
          get() {
            return self.store.get(config.type, this[config.foreignKey]);
          },
          set(val) {
            this[config.foreignKey] = self.store.resolveId(config.type, val);
          },
        };
      },
      {}
    );

    Object.assign(this.options.computed, belongsToRelationProperties);

    const hasManyRelationProperties = _.transform(
      this.options.relations.hasMany,
      (all, config, relationKey) => {
        const { filter, foreignKey, sortBy, type } = config;
        const sorted = this.__getSorted(sortBy);

        all[relationKey] = {
          get() {
            if (!this.$$transientAttrs.relations) {
              this.$$transientAttrs.relations = {};
            }
            if (this.$$transientAttrs.relations[relationKey]) {
              return this.$$transientAttrs.relations[relationKey];
            }

            const arr = [];
            const entityModel = self.store.models[type];
            if (entityModel.hasManySortFields) {
              for (const entity of entityModel.getAll()) {
                if (
                  entity[foreignKey] === this[self.idAttribute] &&
                  (!filter || filter(entity, this))
                ) {
                  const previousValue = _.has(
                    this.previousHasManySortValues,
                    entity[this.idAttribute]
                  )
                    ? this.previousHasManySortValues[entity[this.idAttribute]]
                    : NOT_PROVIDED;
                  entityModel.previousHasManySortValues[entity[entityModel.idAttribute]] = _.pick(
                    entity,
                    entityModel.hasManySortFields
                  );

                  if (previousValue === NOT_PROVIDED) {
                    sorted.add(arr, entity);
                  } else {
                    sorted.update(arr, entity, { previousValue });
                  }
                }
              }
            }
            this.$$transientAttrs.relations[relationKey] = arr;
            return arr;
          },
          set(val) {
            // this[config.foreignKey] = self.store.resolveId(config.type, val)
          },
        };
      },
      {}
    );

    Object.assign(this.options.computed, hasManyRelationProperties);
  }

  __getSorted(rules = [[this.idAttribute, 'asc']]) {
    const cmp = generateComparator(rules);
    return new SortedArray(cmp);
  }

  __initTransientAttrs() {
    const self = this;

    const propertyDescriptors = {
      $$transientAttrs: {
        enumerable: false,
        get() {
          const id = this[self.idAttribute];
          return self.transientAttrsStore[id] || (self.transientAttrsStore[id] = {});
        },
      },
    };

    const transientAttrsDefaultValues = {};

    // transientAttrs is an array of keys (strings) that are not supposed to be stored on the entity,
    // only in memory. usually used for transient/cache attributes
    // each key converts into a property on an instance, and the actual value is stored on a different
    // in-memory map
    // examples: conversation.lastMessage, conversation.unreadCount, user.presence
    if (this.options.transientAttrs) {
      this.options.transientAttrs.forEach((attr) => {
        if (this.options.defaultValues) {
          if (attr in this.options.defaultValues) {
            transientAttrsDefaultValues[attr] = jsonCloneDeep(this.options.defaultValues[attr]);
            delete this.options.defaultValues[attr];
          }
        }

        propertyDescriptors[attr] = {
          // enumerable: true, // TODO rethink?
          get() {
            if (attr in this.$$transientAttrs) {
              return this.$$transientAttrs[attr];
            }
            if (attr in transientAttrsDefaultValues) {
              return (this.$$transientAttrs[attr] = transientAttrsDefaultValues[attr]);
            }
          },
          set(val) {
            this.$$transientAttrs[attr] = val;
          },
        };
      });
    }

    Object.assign(this.options.computed, propertyDescriptors);
  }

  createInstance(attrs, shouldParseAttrs = true) {
    if (shouldParseAttrs) attrs = this.parseAttrs(attrs);

    const id = attrs[this.idAttribute];
    if (this.entitiesById[id] || this.entitiesByServerId[id]) {
      throw new Error('called createInstance on an existing instance');
    }

    const instance = this.__wireInstance(attrs);

    return instance;
  }

  __wireInstance(attrs) {
    const self = this;

    if (this.options.defaultValues) {
      _.defaults(attrs, jsonCloneDeep(this.options.defaultValues));
    }

    const transientValues = {};
    this._writablePropertyKeys.forEach((k) => {
      if (isConfigurableProperty(attrs, k)) {
        transientValues[k] = attrs[k];
        delete attrs[k];
      }
    });

    Object.defineProperties(attrs, this.options.computed);
    Object.assign(attrs, transientValues);
    // TODO remove
    if (this.options.instanceEvents) Object.assign(attrs, EventEmitter.prototype);

    Object.assign(attrs, this.options.methods);

    Object.assign(attrs, {
      toPlainObject(options) {
        const computedValues = {};
        self._computedKeys.forEach((k) => (computedValues[k] = this[k]));

        const result = Object.assign({}, this, computedValues);

        // TODO nicer
        delete result.setMaxListeners;
        delete result.emit;
        delete result.addListener;
        delete result.on;
        delete result.once;
        delete result.removeListener;
        delete result.removeAllListeners;
        delete result.listeners;
        delete result.listenerCount;
        delete result.off;
        delete result.toPlainObject;
        delete result._events;
        delete result._maxListeners;

        return result;
      },
    });

    const id = attrs[this.idAttribute];
    if (id && !('serverId' in attrs)) {
      attrs.serverId = id;
    }

    this.options.beforeCreateInstance && this.options.beforeCreateInstance(attrs);

    return attrs;
  }

  inject(attrs) {
    let id;
    let instance;

    if (this.is(attrs)) {
      instance = attrs;
      id = this.resolveId(instance);
      // update already injected entity
      if (this.entitiesById[id]) {
        this.__beforeInject(instance);
        this.__register(id, instance);
        this.options.afterAssignment && this.options.afterAssignment(instance);
        this.__addToHasManyRelationships(instance);
        this.options.afterInject && this.options.afterInject(instance);
        this.emit('afterInject', this, instance);

        return instance;
      }
    }

    if (!instance) {
      attrs = this.parseAttrs(attrs);
      id = this.resolveId(attrs);
      if (!this.isValidId(id)) {
        throw new Error(`'${this.idAttribute}' is required`);
      }
    }

    let isNewEntity = false;
    const registeredInstance = this.entitiesById[id] || this.entitiesByServerId[id];
    if (instance) {
      if (!registeredInstance) isNewEntity = true;
      this.__beforeInject(instance);
    } else if (registeredInstance) {
      instance = registeredInstance;
      this.__beforeInject(attrs, instance);
      Object.assign(instance, attrs);
    } else {
      isNewEntity = true;
      instance = this.__wireInstance(attrs);
      this.__beforeInject(instance);
    }

    this.__register(id, instance);
    this.options.afterAssignment && this.options.afterAssignment(instance);
    this.__addToHasManyRelationships(instance);

    this.options.afterInject && this.options.afterInject(instance);
    this.emit('afterInject', this, instance);

    if (isNewEntity) {
      this.options.afterCreate && this.options.afterCreate(instance);
      this.emit('afterCreate', this, instance);
    }

    return instance;
  }

  __beforeInject(...args) {
    const [attrs, existingEntity] = args;

    // existing instance, preserve its own serverId, otherwise it's going to be overridden by .id
    if (existingEntity && !('serverId' in attrs)) {
      attrs.serverId = existingEntity.serverId;
    }

    this.options.beforeInject && this.options.beforeInject(...args);
    this.emit('beforeInject', this, attrs);
  }

  replaceExisting(attrs, { instance } = {}) {
    const { idAttribute } = this.options;
    if (this.is(attrs)) {
      throw new Error('cannot call replaceExisting() on an instance; should be plain object');
    }
    if (instance && !this.is(instance)) {
      throw new Error("provided 'instance' option is not an instance of this model");
    }

    attrs = this.parseAttrs(attrs);
    const serverId = this.resolveId(attrs);
    if (!this.isValidId(serverId)) {
      throw new Error(`'${idAttribute}' is required`);
    }

    let id = serverId;
    let isNewEntity;

    if (instance) {
      const oldId = this.resolveId(instance);

      if (id !== oldId) {
        const serverInstance = this.entitiesById[id];
        if (serverInstance) {
          this.eject(instance);
          instance = null;
        } else {
          id = oldId;
        }
      }
    }

    if (!instance) {
      instance = this.entitiesById[id] || this.entitiesByServerId[id];
      if (instance) {
        id = instance[idAttribute];
      }
    }

    if (instance) {
      // allow some original instance settings to be kept if removed from attrs by beforeInject()
      const incomingAttrs = {};
      for (const member in attrs) incomingAttrs[member] = true;
      this.__beforeInject(attrs, instance);

      for (const prop of Object.getOwnPropertyNames(instance)) {
        if (!incomingAttrs[prop] && isConfigurableProperty(instance, prop)) {
          delete instance[prop];
        }
      }

      Object.assign(instance, attrs);
      instance[idAttribute] = id;
      instance.serverId = serverId;
      this.__wireInstance(instance);
    } else {
      isNewEntity = true;
      instance = this.__wireInstance(attrs);
      this.__beforeInject(instance);
    }

    this.__register(id, instance);
    this.options.afterAssignment && this.options.afterAssignment(instance);
    this.__addToHasManyRelationships(instance);

    this.options.afterInject && this.options.afterInject(instance);
    this.emit('afterInject', this, instance);

    if (isNewEntity) {
      this.options.afterCreate && this.options.afterCreate(instance);
      this.emit('afterCreate', this, instance);
    }

    return instance;
  }

  eject(id) {
    id = this.resolveId(id);

    const instance = this.entitiesById[id] || this.entitiesByServerId[id];
    if (!instance) return;

    this.emit('beforeEject', this, instance);

    this.__removeFromHasManyRelationships(instance);
    this.__unregister(id, instance);

    this.emit('afterEject', this, instance);
  }

  __register(id, instance) {
    if (this.entitiesById[id] !== instance) {
      this.entitiesById[id] = instance;
      this.entitiesList.push(instance);
    }

    const previousServerId = this.previousServerIds[id];
    const serverId = instance.serverId;

    if (previousServerId && previousServerId !== serverId) {
      delete this.entitiesByServerId[previousServerId];

      if (serverId === id) {
        delete this.previousServerIds[id];
      } else {
        this.entitiesByServerId[serverId] = instance;
        this.previousServerIds[id] = serverId;
      }
    }

    if (!previousServerId && serverId !== id) {
      this.entitiesByServerId[serverId] = instance;
      this.previousServerIds[id] = serverId;
    }
  }

  __unregister(id, instance) {
    delete this.entitiesById[id];
    _.pull(this.entitiesList, instance);

    if (id in this.transientAttrsStore) {
      delete this.transientAttrsStore[id];
    }

    const previousServerId = this.previousServerIds[id];
    if (previousServerId) {
      delete this.entitiesByServerId[previousServerId];
      delete this.previousServerIds[id];
    }
  }

  injectPlaceholder(attrs) {
    const id = attrs.token || attrs[this.idAttribute];

    if (this.get(id)) {
      return this.inject(attrs);
    } else {
      const entity = this.createInstance(attrs);
      Object.defineProperty(entity, '$placeholder', { value: true, configurable: true });
      return this.inject(entity);
    }
  }

  removePlaceholder({ entity, attrs }) {
    if (!entity || !entity.$placeholder) {
      // do nothing
    } else if (!attrs) {
      Object.defineProperty(entity, '$notFound', { value: true, configurable: true });
      this.inject(entity);
    } else {
      delete entity.$placeholder;
      delete entity.$notFound;
      this.inject(entity);
    }
  }

  touch(id) {
    id = this.resolveId(id);

    const instance = this.entitiesById[id] || this.entitiesByServerId[id];
    if (!instance) return;

    this.inject({ [this.idAttribute]: id });
  }

  __addToHasManyRelationships(instance) {
    this.__updateRelationsIfNeeded(instance, 'add');
  }

  __removeFromHasManyRelationships(instance) {
    this.__updateRelationsIfNeeded(instance, 'remove');
  }

  __updateRelationsIfNeeded(instance, action) {
    const parentRelationshipsOfModel = this.store.getParentRelationshipsOfModel(this.name);
    if (!parentRelationshipsOfModel) return;

    if (!this.hasManySortFields) {
      this.hasManySortFields = _uniq.call(
        _flatten
          .call(
            parentRelationshipsOfModel
              .map(({ sortBy }) => sortBy && sortBy.map(([field]) => field))
              .filter(Boolean)
          )
          .concat([this.idAttribute])
      );
    }

    const previousValue = _.has(this.previousHasManySortValues, instance[this.idAttribute])
      ? this.previousHasManySortValues[instance[this.idAttribute]]
      : NOT_PROVIDED;
    this.previousHasManySortValues[instance[this.idAttribute]] = _.pick(
      instance,
      this.hasManySortFields
    );

    parentRelationshipsOfModel.forEach((relationConfig) => {
      const { filter, foreignKey, relationKey, sortBy, sorted } = relationConfig;
      const parent = this.store.get(relationConfig.parent, instance[foreignKey]);
      if (
        !parent ||
        !parent.$$transientAttrs.relations ||
        !parent.$$transientAttrs.relations[relationKey]
      )
        return;
      let changed = false;

      if (action === 'remove' || (filter && !filter(instance, parent))) {
        if (sortBy) {
          changed =
            previousValue === NOT_PROVIDED
              ? sorted.remove(parent[relationKey], instance)
              : sorted.remove(parent[relationKey], instance, { previousValue });
        } else {
          const idx = parent[relationKey].indexOf(instance);
          if (idx !== -1) {
            parent[relationKey].splice(idx, 1);
            changed = true;
          }
        }
      } else if (action === 'add') {
        if (sortBy) {
          changed =
            previousValue === NOT_PROVIDED
              ? sorted.update(parent[relationKey], instance)
              : sorted.update(parent[relationKey], instance, { previousValue });
        } else {
          if (!parent[relationKey].includes(instance)) {
            parent[relationKey].push(instance);
            changed = true;
          }
        }
      }

      if (!parent.$$transientAttrs.$$wasUpdatedByRelation) {
        parent.$$transientAttrs.$$wasUpdatedByRelation = changed;
      }
    });
  }

  ejectAll() {
    while (this.entitiesList.length > 0) {
      this.eject(this.entitiesList[0]);
    }
  }

  parseAttrs(attrs) {
    // when parsing attributes, take out all the entities and transient attributes from attrs,
    // to prevent jsonCloneDeep or camelizing them
    const attrsWithEntities = _.pickBy(attrs, (val) => {
      return this.store.isEntity(val) || this.store.isEntityArray(val);
    });
    const transientAttrs = _.pick(attrs, this.options.transientAttrs);
    const parsableAttrs = _.omit(
      attrs,
      Object.keys(attrsWithEntities),
      this.options.transientAttrs
    );
    const parsedAttrs = this.options.parseAttrs(jsonCloneDeep(parsableAttrs));
    const allParsedAttrs = { ...parsedAttrs, ...attrsWithEntities, ...transientAttrs };
    return allParsedAttrs;
  }

  get = (id) => {
    return this.entitiesById[id] || this.entitiesByServerId[id] || null;
  };

  getAll() {
    return this.entitiesList;
  }

  getMulti(ids) {
    return ids.map(this.get);
  }

  resolveId(attrs) {
    return this.isValidId(attrs) ? attrs : attrs[this.idAttribute];
  }

  isValidId(id) {
    return typeof id === 'string' || typeof id === 'number';
  }

  is(o) {
    return o && o.$entityClass === this;
  }
}

Object.assign(ModelBase.prototype, EventEmitter.prototype);

export default class Store {
  constructor(options = {}) {
    this.options = options;

    this.models = {};

    this.__relationsOfHasManyByContainedItem = {};

    const methods = ['filter', 'get', 'getAll', 'inject', 'injectPlaceholder', 'is', 'resolveId'];
    methods.forEach((method) => {
      this[method] = (type, ...args) => {
        return this.models[type][method](...args);
      };
    });
  }

  defineModel(name, options = {}) {
    if (this.models[name]) {
      throw new Error(`${name} already defined`);
    }

    options = {
      ...this.options,
      ...options,
    };

    this.models[name] = ModelBase.create(this, name, options);

    const hasMany = this.models[name].options.relations.hasMany;

    if (!_.isEmpty(hasMany)) {
      _.each(hasMany, (relationConfig, relationKey) => {
        const { sortBy, type } = relationConfig;
        const sorted = this.models[name].__getSorted(sortBy);
        if (!this.__relationsOfHasManyByContainedItem[type]) {
          this.__relationsOfHasManyByContainedItem[type] = [];
        }
        this.__relationsOfHasManyByContainedItem[type].push({
          ...relationConfig,
          relationKey,
          sorted,
          parent: name,
        });
      });
    }

    return this.models[name];
  }

  getParentRelationshipsOfModel(modelName) {
    return this.__relationsOfHasManyByContainedItem[modelName];
  }

  isEntity(o) {
    return o && o.$entityClass && o.$entityClass.constructor === ModelBase;
  }

  isEntityArray(a) {
    return a && Array.isArray(a) && a.some(this.isEntity);
  }

  clear() {
    _.values(this.models).forEach((m) => m.ejectAll());
  }
}
