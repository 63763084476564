import { action, autorun, observable, runInAction } from 'mobx';
import uuid from 'uuid';
import { AppTypes } from '../models/enums';
import { IFRAME_STYLE } from './AdminConsoleStore';

const ADMIN_SDK_CONTRACT = 1;

const actionTypes = {
  API_CALL: 'API_CALL',
  CREDENTIALS: 'CREDENTIALS',
  CURRENT_USER_FETCH_FAILED: 'CURRENT_USER_FETCH_FAILED',
  CURRENT_USER_FETCH_REQUESTED: 'CURRENT_USER_FETCH_REQUESTED',
  CURRENT_USER_FETCH_SUCCEEDED: 'CURRENT_USER_FETCH_SUCCEEDED',
  CURRENT_USER_SELECT_ORGANIZATION: 'CURRENT_USER_SELECT_ORGANIZATION',
  DND_STATE: 'DND_STATE',
  MESSAGE_ROLE: 'MESSAGE_ROLE',
  OPEN_ESCALATION_SETTINGS_MODAL: 'OPEN_ESCALATION_SETTINGS_MODAL',
  OPEN_MY_ROLES: 'OPEN_MY_ROLES',
  OPEN_SELECT_ORGANIZATION_MODAL: 'OPEN_SELECT_ORGANIZATION_MODAL',
  ROLE_OWNERSHIP_CHANGE: 'ROLE_OWNERSHIP_CHANGE',
  ROLE_OWNERSHIPS_IN_ORG: 'ROLE_OWNERSHIPS_IN_ORG',
  SCHEDULE_TOOL_CLEAR: 'SCHEDULE_TOOL_CLEAR',
  SCHEDULE_TOOL_EXPORT: 'SCHEDULE_TOOL_EXPORT',
  SCHEDULE_TOOL_LAST_UPLOAD: 'SCHEDULE_TOOL_LAST_UPLOAD',
  SCHEDULE_TOOL_OPEN: 'SCHEDULE_TOOL_OPEN',
  TRACKER_EVENT: 'TRACKER_EVENT',
  UPDATE_PERMISSIONS: 'UPDATE_PERMISSIONS',
};

export default class ReactAdminStore {
  @observable isIframeLoading = false;
  @observable isLoadingData = false;
  iframeParent = null;
  selectedCategory = null;

  constructor({ client, stores }) {
    this.client = client;
    this.stores = stores;
  }

  _sendDndState = () => {
    const { dnd } = this.stores.sessionStore.currentUser || {};

    this.emit({
      dnd,
      type: actionTypes.DND_STATE,
    });
  };

  _updatePermissions = () => {
    const { messengerStore } = this.stores;
    const { currentOrganization, currentOrganizationId: organizationId } = messengerStore;
    const isRolesAdmin = messengerStore.isRolesAdminInOrganization();
    const isAnalyticsAdmin = messengerStore.isProductAnalyticsAdmin();
    const isAnalyticsOrgAdmin = messengerStore.isAnalyticsAdminInOrganization();
    const isTTPlusReportingAdmin = messengerStore.isTTPlusReportingAdminInOrganization();
    const isPatientDataReportAnalyticsAdmin =
      messengerStore.isPatientDataReportAnalyticsAdminInOrganization();
    const patientContextReporting = messengerStore.patientContextReporting();
    const patientDataAuditAdmin = messengerStore.patientDataAuditAdmin();
    const {
      allowLooker: hasInsights = false,
      canEscalateMessages: canEscalate = false,
      canShowTransactions: hasTransactions = false,
      canAdminRoles: hasRoles = false,
      isPatientNetworkEnabled: hasPatientFacing = false,
      roleTransition = 'off',
      name: organizationName = '',
      isVideoCallEnabled = false,
      isVoiceCallEnabled = false,
    } = currentOrganization || {};

    this.emit({
      canEscalate,
      canShowVoIPCalling: isVideoCallEnabled || isVoiceCallEnabled,
      hasInsights,
      hasPatientFacing,
      hasRoles,
      hasTransactions,
      isAnalyticsAdmin,
      isAnalyticsOrgAdmin,
      isPatientDataReportAnalyticsAdmin,
      isRolesAdmin,
      isTTPlusReportingAdmin,
      organizationId,
      organizationName,
      patientContextReporting,
      patientDataAuditAdmin,
      roleTransition,
      type: actionTypes.UPDATE_PERMISSIONS,
    });
  };

  mounted() {
    autorun(this._sendDndState);
    autorun(this._updatePermissions);
  }

  _handleMessageFromIframe = async ({ data: event }) => {
    const { args = [], callId, method, model, organizationId, type, roleToken } = event;
    const { composeMessageStore, roleStore, trackerStore } = this.stores;
    const apiClass = this.client.api[model];

    if (type === actionTypes.API_CALL && apiClass && typeof apiClass[method] === 'function') {
      let data = null;
      let error = null;

      try {
        data = await apiClass[method](...args);
        if (data && data.getHeader) {
          delete data.getHeader;
        }
      } catch (inError) {
        console.error(model, method, inError.response || inError);
        error = inError.status || inError.constructor.name;
        data = inError.response && inError.response.text;
      }

      this.emit({
        adminSdkContract: ADMIN_SDK_CONTRACT,
        callId,
        data,
        error,
      });
    } else if (type === actionTypes.MESSAGE_ROLE) {
      const role = await roleStore.findRole(roleToken, organizationId);
      composeMessageStore.composeToEntity(role);
    } else if (type === actionTypes.TRACKER_EVENT) {
      const [event, data] = args;
      if (typeof trackerStore[event] === 'function') {
        trackerStore[event](data);
      }
    }
  };

  _handleRoleChange = ({ role, target, triggeredBy, type }) => {
    const { currentOrganizationId } = this.stores.messengerStore;

    if (role && target && triggeredBy && role.organizationId === currentOrganizationId) {
      this.emit({
        event: {
          role: role.botUserId,
          target: target.id,
          triggeredBy: triggeredBy.id,
          type: type,
        },
        type: actionTypes.ROLE_OWNERSHIP_CHANGE,
      });
    }
  };

  _onLoad = () => {
    const { messengerStore, sessionStore } = this.stores;
    const {
      currentAppSelected,
      currentOrganization,
      currentOrganizationId: organizationId,
    } = messengerStore;
    const isRolesAdmin = messengerStore.isRolesAdminInOrganization();
    const isAnalyticsAdmin = messengerStore.isProductAnalyticsAdmin();
    const isAnalyticsOrgAdmin = messengerStore.isAnalyticsAdminInOrganization();
    const isTTPlusReportingAdmin = messengerStore.isTTPlusReportingAdminInOrganization();
    const isPatientDataReportAnalyticsAdmin =
      messengerStore.isPatientDataReportAnalyticsAdminInOrganization();
    const patientContextReporting = messengerStore.patientContextReporting();
    const patientDataAuditAdmin = messengerStore.patientDataAuditAdmin();
    const {
      allowLooker: hasInsights = false,
      canAdminRoles: hasRoles = false,
      canEscalateMessages: canEscalate = false,
      canShowTransactions: hasTransactions = false,
      isPatientNetworkEnabled: hasPatientFacing = false,
      name: organizationName = '',
      roleTransition = 'off',
      isVideoCallEnabled = false,
      isVoiceCallEnabled = false,
    } = currentOrganization || {};

    this.emit({
      canEscalate,
      canShowVoIPCalling: isVideoCallEnabled || isVoiceCallEnabled,
      currentAppSelected,
      env: this.apiUrl,
      hasInsights,
      hasPatientFacing,
      hasRoles,
      hasTransactions,
      isAnalyticsAdmin,
      isAnalyticsOrgAdmin,
      isPatientDataReportAnalyticsAdmin,
      isRolesAdmin,
      isTTPlusReportingAdmin,
      key: this.apiKey,
      organizationId,
      organizationName,
      patientContextReporting,
      patientDataAuditAdmin,
      roleTransition,
      secret: this.apiSecret,
      selectedCategory: this.selectedCategory,
      type: actionTypes.CREDENTIALS,
    });
    this.iframe.setAttribute('aria-label', 'iframe loaded');

    this.sendRoleOwnershipData();
    sessionStore.resetAutoLogout();
    this.selectedCategory = null;

    this.startListeners();
  };

  _startLoading = () => {
    this.isLoadingData = true;
  };

  _stopLoading = ({ data: event }) => {
    const { type } = event;

    if (
      type === actionTypes.CURRENT_USER_FETCH_SUCCEEDED ||
      type === actionTypes.CURRENT_USER_FETCH_FAILED
    ) {
      this.isLoadingData = false;
    }
  };

  appendIframe = (element) => {
    this.element = element;
    this.element.appendChild(this.iframe);
  };

  createIframe = (view) => {
    let rolesUrl = this.client.rolesUrl;
    if (view === 'Analytics') {
      rolesUrl += '#!/analytics';
    } else if (view === 'Roles') {
      rolesUrl += '#!/roles';
    }
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('style', IFRAME_STYLE);
    this.iframe.setAttribute('src', rolesUrl);
    this.iframe.setAttribute('id', uuid());
    this.iframe.setAttribute('aria-label', 'iframe loading');

    this.iframe.onload = this._onLoad;
  };

  emit = (action) => {
    if (this.iframe) {
      this.iframe.contentWindow.postMessage(action, '*');
    }
  };

  sendRoleOwnershipData = () => {
    const { messengerStore, roleStore } = this.stores;
    const { currentAppSelected } = messengerStore;

    if (currentAppSelected !== AppTypes.ANALYTICS) {
      this.emit({
        event: {
          roles: roleStore.currentRoleBotUserIds,
          type: actionTypes.ROLE_OWNERSHIPS_IN_ORG,
        },
        type: actionTypes.ROLE_OWNERSHIP_CHANGE,
      });
    }
  };

  startListeners = () => {
    window.addEventListener('message', this._handleMessageFromIframe, false);
    window.addEventListener('message', this._stopLoading, false);
    this.client.on('role:change', this._handleRoleChange);
  };

  stopListeners = () => {
    window.removeEventListener('message', this._handleMessageFromIframe, false);
    window.addEventListener('message', this._stopLoading, false);
    this.client.removeListener('role:change', this._handleRoleChange);
  };

  @action('ReactAdminStore.changeReactIframeOrg') changeReactIframeOrg = (organization) => {
    const { sessionStore } = this.stores;
    this.emit({
      organizationId: organization.id,
      organizationName: organization.name,
      type: actionTypes.CURRENT_USER_SELECT_ORGANIZATION,
    });
    this.sendRoleOwnershipData();
    sessionStore.resetAutoLogout();
  };

  @action('ReactAdminStore.destroyIframe') destroyIframe = () => {
    const parentNode = this.iframe && this.iframe.parentNode;
    if (parentNode) {
      parentNode.removeChild(this.iframe);
    }
    this.iframe = null;
    this.stopListeners();
  };

  @action('ReactAdminStore.getIframe') getIframe = async (element, organizationId, view) => {
    if (!element) {
      throw new Error('Please provide a node to append the iframe to.');
    }

    this.iframeParent = element;
    this.isIframeLoading = true;
    this.stores.sessionStore.resetAutoLogout();

    try {
      const { key, secret } = this.client.getAuth();
      this.apiKey = key;
      this.apiSecret = secret;
      this.apiUrl = this.client.config.baseUrl;

      this.createIframe(view);
      this.appendIframe(element);

      runInAction(() => {
        this.isIframeLoading = false;
      });

      return this;
    } catch (err) {
      console.error(err);

      runInAction(() => {
        this.isIframeLoading = false;
      });

      return Promise.reject(err);
    }
  };

  @action('ReactAdminStore.refreshAdminIframe') refreshAdminIframe = () => {
    const { currentOrganizationId } = this.stores.messengerStore;
    if (this.iframeParent && !this.isLoadingData) {
      this._startLoading();
      this.destroyIframe();
      this.getIframe(this.iframeParent, currentOrganizationId, 'Roles');
    }
  };

  @action('ReactAdminStore.clearExistingSchedule')
  clearExistingSchedule = () => {
    this.emit({ type: actionTypes.SCHEDULE_TOOL_CLEAR });
  };

  @action('ReactAdminStore.exportRoleDetailsToScheduleTool')
  exportRoleDetailsToScheduleTool = () => {
    this.emit({ type: actionTypes.SCHEDULE_TOOL_EXPORT });
  };

  @action('ReactAdminStore.openScheduleTool') openScheduleTool = () => {
    this.emit({ type: actionTypes.SCHEDULE_TOOL_OPEN });
  };

  @action('ReactAdminStore.openOrgSelectModal') openOrgSelectModal = () => {
    this.emit({ type: actionTypes.OPEN_SELECT_ORGANIZATION_MODAL });
  };

  @action('ReactAdminStore.retrieveLastUploadToScheduleTool')
  retrieveLastUploadToScheduleTool = () => {
    this.emit({ type: actionTypes.SCHEDULE_TOOL_LAST_UPLOAD });
  };

  @action('ReactAdminStore.openMyRoles') openMyRoles = () => {
    this.emit({ type: actionTypes.OPEN_MY_ROLES });
  };

  @action('ReactAdminStore.changeSelectedCategory') changeSelectedCategory = (category) => {
    this.selectedCategory = category;
  };
}
