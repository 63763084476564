export const CONNECTED = 'Connected' as const;
export const NOTCONNECTED = 'Not Connected' as const;
export const LEFTCALL = 'Left call' as const;
export const MISSED = 'Missed call' as const;
export const DECLINED = 'Declined call' as const;
export const RINGING = 'Ringing...' as const;
export const UNAVAILABLE = 'Unavailable' as const;

export type VideoCallMemberStatusType =
  | typeof CONNECTED
  | typeof NOTCONNECTED
  | typeof LEFTCALL
  | typeof MISSED
  | typeof DECLINED
  | typeof RINGING
  | typeof UNAVAILABLE;

const VideoCallMemberStatus: { [k: string]: VideoCallMemberStatusType } = {
  CONNECTED,
  NOTCONNECTED,
  LEFTCALL,
  MISSED,
  DECLINED,
  RINGING,
  UNAVAILABLE,
};

export default VideoCallMemberStatus;
