import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import { actions, ReduxState, useAppDispatch, useAppSelector } from '../../../redux-stores';
import { getIfUserIsNotInConversation } from '../../utils/getIfUserIsNotInConversation';
import EntitiesSelector from '../Collaboration/Entities/EntitiesSelector';
import { Conversation, Role, User } from '../../../types';
import style from './MentionMemberFloatingModal.module.css';

const { setModal } = actions;

export type HandleKeyDown = {
  handleKeyDown: (key: string) => void;
  isOpen: boolean;
};

type MentionMemberSelectorProps = {
  currentUserId: string;
  currentConversation: Conversation;
  selectEntity: (entity: Role | User) => void;
  setMentionMemberFloatingModal: (isOpen: boolean) => void;
  filter?: string;
};

const MentionMemberFloatingModal = forwardRef(
  (
    {
      currentConversation,
      currentUserId,
      selectEntity,
      setMentionMemberFloatingModal,
      filter,
    }: MentionMemberSelectorProps,
    ref: ForwardedRef<HandleKeyDown>
  ) => {
    const currentOrgId = useAppSelector((state: ReduxState) => state.session.currentOrganizationId);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [pressedKey, setPressedKey] = useState<string>('');
    const dispatch = useAppDispatch();

    const isCurrentConversationGroup =
      currentConversation?.counterPartyType?.toLowerCase() === 'group';

    const closeModal = () => {
      setMentionMemberFloatingModal(false);
    };

    const selectUser = (entity: Role | User) => {
      setMentionMemberFloatingModal(false);
      selectEntity(entity);

      const isUserNotAConversationMember = getIfUserIsNotInConversation(
        currentConversation,
        currentUserId,
        entity?.botUser?.id || entity.id
      );

      const membersToAdd = [
        { displayName: entity.displayName, id: entity?.botUser?.id || entity.id },
      ];

      if (
        isUserNotAConversationMember &&
        isCurrentConversationGroup &&
        currentConversation?.counterParty.groupType !== 'ROLE_P2P'
      ) {
        dispatch(
          setModal({
            name: 'addMemberToConversation',
            data: {
              // Needed for serialize elements
              membersToAdd: JSON.parse(JSON.stringify(membersToAdd)),
            },
          })
        );
      } else {
        closeModal();
      }
    };

    const clearEntity = (e: React.MouseEvent) => {
      e.stopPropagation();
    };

    useImperativeHandle(ref, () => ({
      handleKeyDown(key: string) {
        setPressedKey(key);
      },
      isOpen,
    }));

    return (
      <div
        className={classNames(style.mentionMemberFloatingModal, {
          [style.hideMentionMemberFloatingModal]: !isOpen,
        })}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Escape') {
            closeModal();
            setIsOpen(false);
          }
        }}
        id={'MentionMemberFloatingModal'}
      >
        <EntitiesSelector
          allowRolesRecipients={true}
          clearEntity={clearEntity}
          currentUserId={currentUserId}
          isEntityInConversationCheck={true}
          isMentionMemberFloatingModalOpen={true}
          organizationId={currentOrgId}
          selectEntity={selectUser}
          disableDndEntities={false}
          shouldDisplayOnDutyText={false}
          toggleHandler={() => setMentionMemberFloatingModal(false)}
          filter={filter}
          pressedKey={pressedKey}
          setIsOpen={setIsOpen}
        />
      </div>
    );
  }
);

export default MentionMemberFloatingModal;
