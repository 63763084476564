// @ts-nocheck
import _ from 'lodash';
import _values from 'lodash-bound/values';
import _flatten from 'lodash-bound/flatten';
import _map from 'lodash-bound/map';
import moment from 'moment';
import { decorator as reusePromise } from 'reuse-promise';
import TypingContext from '../models/enums/TypingContext';
import BaseService from './BaseService';

const GLOBAL_CONTEXT = TypingContext.GLOBAL;

/**
 * @class TypingStatusService
 * Keeps track of typers in 1:1 conversation or group conversation
 */
export default class TypingStatusService extends BaseService {
  mounted() {
    // this._typers is a map between a context and another map of typers and some metadata (currently a timer)
    // there are 2 types of contexts:
    // - GLOBAL_CONTEXT - all incoming 1:1 typing events from other users go here
    // - a group ID - also has a list of currently typing users in the group
    //
    // this._currentUserTyping contains all outgoing typing events from current user to other counter parties
    //
    // this structure is flexible and allows more contexts to be formed, such as 1:1 conversations within a specific
    // organization (currently 1:1 notifications are global)
    //
    // the timers kept in the maps are 'auto clearing' timers. if we have a new typing notification and it's been a
    // certain number of seconds (configurable on client) without any other event (more typing, [TODO] an incoming
    // message or a stop event), then the service will reset the state and fire a change event. same for outgoing but
    // without a change event.
    this._lastStartTypingAPICalls = {};
    this._lastStopTypingAPICalls = {};
    this._typers = {};
    this._currentUserTyping = {};
    this.host.on('message:sending', this._onMessageSending);
    // TODO - set incoming message typer state to false
    // this.host.on('message', this._onIncomingMessage)
  }

  dispose() {
    _map
      .call(_flatten.call(_map.call(_values.call(this._typers), _.values)), 'timer')
      .forEach((timer) => timer !== null && clearTimeout(timer));
    _map
      .call(_values.call(this._currentUserTyping), 'timer')
      .forEach((timer) => timer !== null && clearTimeout(timer));

    this._lastStartTypingAPICalls = null;
    this._lastStopTypingAPICalls = null;
    this._typers = null;
    this.host.removeListener('message:sending', this._onMessageSending);
  }

  reactToIsTypingEvent({ data }): void {
    this.host.requireUser();
    let userId;

    if (data['proxy_token']) {
      userId = data['proxy_token'];
    } else {
      userId = data['sender_token'];
    }

    if (userId === this.host.currentUserId) return;

    const groupId = data['group_token'];
    const orgId = data['org_token'];

    const contextId = groupId || orgId || null;
    const contextType = groupId
      ? TypingContext.GROUP
      : orgId
      ? TypingContext.ORGANIZATION
      : GLOBAL_CONTEXT;
    const isTyping = data['status'] === 'typing';

    // TODO TEST
    this.host.models.User.ensureEntity(userId, { onlyPlaceholder: false });

    if (contextType === GLOBAL_CONTEXT) {
      _.get(this.host, 'currentUser.organizationIds', []).forEach((orgId) => {
        this._setTypingStatusOfUser(orgId, TypingContext.ORGANIZATION, userId, isTyping);
      });
    } else {
      this._setTypingStatusOfUser(contextId, contextType, userId, isTyping);
    }
  }

  async startTyping(
    counterPartyId: string | Object | void,
    { autoStopAfterTimeout, senderId, organizationId } = {}
  ) {
    if (typeof counterPartyId === 'undefined') return;

    counterPartyId = this._resolveModelId(counterPartyId);
    senderId = this._resolveModelId(senderId);
    this.host.requireUser();

    await this._sendStartTyping(counterPartyId, senderId, organizationId);

    let typeSettings = this._currentUserTyping[counterPartyId];
    if (typeSettings) {
      clearTimeout(this._currentUserTyping[counterPartyId].timer);
      typeSettings.timer = null;
    } else {
      typeSettings = {};
      this._currentUserTyping[counterPartyId] = typeSettings;
    }

    const timeout =
      typeof autoStopAfterTimeout === 'number'
        ? autoStopAfterTimeout
        : this.host.config.clearTypingStatusTimeout;

    typeSettings.timer = setTimeout(() => {
      this.logger.info('auto stop typing for current user', counterPartyId);
      this.stopTyping(counterPartyId, { senderId });
    }, timeout);
  }

  @reusePromise()
  async _sendStartTyping(counterPartyId, senderId, organizationId) {
    if (this._lastStartTypingAPICalls[counterPartyId]) {
      const { expiresAt, retVal, lastSenderId } = this._lastStartTypingAPICalls[counterPartyId];
      if (senderId !== lastSenderId) {
        await this.stopTyping(counterPartyId, { senderId: lastSenderId });
      }
      if (moment().isBefore(expiresAt)) return retVal;
    }

    const retVal = await this.host.api.typingStatus.startTyping(
      counterPartyId,
      senderId,
      organizationId
    );

    const expiresAt = moment().add(this.config.clearTypingStatusThrottleDelay, 'ms');
    this._lastStartTypingAPICalls[counterPartyId] = { expiresAt, retVal, lastSenderId: senderId };

    return retVal;
  }

  async stopTyping(counterPartyId: string | Object | void, { senderId } = {}) {
    if (typeof counterPartyId === 'undefined') return;
    counterPartyId = this._resolveModelId(counterPartyId);
    senderId = this._resolveModelId(senderId);
    this.host.requireUser();

    const typeSettings = this._currentUserTyping[counterPartyId];
    if (typeSettings && typeSettings.timer !== null) {
      clearTimeout(typeSettings.timer);
      typeSettings.timer = null;
    }

    await this._sendStopTyping(counterPartyId, senderId);
  }

  @reusePromise()
  async _sendStopTyping(counterPartyId, senderId) {
    const typeSettings = this._lastStopTypingAPICalls[counterPartyId];
    if (typeSettings) {
      const { expiresAt, retVal } = typeSettings;
      if (moment().isBefore(expiresAt)) return retVal;
    }

    const retVal = await this.host.api.typingStatus.stopTyping(counterPartyId, senderId);
    const expiresAt = moment().add(this.config.clearTypingStatusThrottleDelay, 'ms');
    this._lastStopTypingAPICalls[counterPartyId] = { expiresAt, retVal, senderId };

    return retVal;
  }

  _setTypingStatusOfUser(
    contextId: string | null,
    contextType: string,
    userId: string,
    isTyping: boolean,
    timedOut: boolean | null | undefined = false
  ) {
    contextId = contextId || TypingContext.GLOBAL;
    let contextSettings = this._typers[contextId];

    if (isTyping) {
      if (!contextSettings) {
        contextSettings = {};
        this._typers[contextId] = contextSettings;
      }

      let typeSettings = contextSettings[userId];
      const isAlreadyTyping = typeSettings && typeSettings.timer !== null;

      if (typeSettings) {
        clearTimeout(typeSettings.timer);
      } else {
        typeSettings = {};
        contextSettings[userId] = typeSettings;
      }

      typeSettings.timer = setTimeout(() => {
        this.logger.info('auto stop typing for other typer', contextId, userId);
        this._setTypingStatusOfUser(contextId, contextType, userId, false, true);
      }, this.host.config.clearTypingStatusTimeout);

      if (isAlreadyTyping) return;
    } else {
      const typeSettings = contextSettings && contextSettings[userId];
      const isAlreadyTyping = typeSettings && typeSettings.timer !== null;
      if (!isAlreadyTyping) return;

      clearTimeout(typeSettings.timer);
      typeSettings.timer = null;
    }

    this.emit('change', {
      userId,
      contextId,
      contextType,
      isTyping,
      timedOut,
    });
  }

  isUserTyping(userId: Object | string, { organizationId } = {}) {
    userId = this._resolveModelId(userId);
    if (organizationId) return this._isUserTypingInContext(organizationId, userId);
    return _.get(this.host, 'currentUser.organizationIds', []).some((orgId) =>
      this._isUserTypingInContext(orgId, userId)
    );
  }

  isCurrentUserTyping(counterPartyId: Object | string) {
    counterPartyId = this._resolveModelId(counterPartyId);
    const typeSettings = this._currentUserTyping[counterPartyId];
    return !!typeSettings && typeSettings.timer !== null;
  }

  isUserTypingInGroup(groupId: Object | string, userId: Object | string) {
    userId = this._resolveModelId(userId);
    groupId = this._resolveModelId(groupId);
    return this._isUserTypingInContext(groupId, userId);
  }

  _isUserTypingInContext(contextId: string, userId: string) {
    const contextSettings = this._typers[contextId];
    const typeSettings = contextSettings && contextSettings[userId];
    return !!typeSettings && typeSettings.timer !== null;
  }

  _onMessageSending = (message) => {
    this.stopTyping(message.counterPartyId, { senderId: message.senderId });
  };
}
