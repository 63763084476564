import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import { useInfiniteSearchResults } from '../../../hooks';
import { mobxInjectSelect } from '../../../utils';
import { DotsIndicator, CollapsingSearchBar } from '../../';
import RefreshButton from '../../RefreshButton';

import { ReactComponent as CloseIconSvg } from '../../../images/close-icon.svg';
import { ReactComponent as DeleteButtonSvg } from '../../../images/delete_button.svg';
import { ReactComponent as ExpandDatePickerSvg } from '../../../images/expand-datepicker.svg';
import ScheduledMessagesDateRangePicker from './ScheduledMessagesDateRangePicker';
import { ScheduledMessagesTable } from './';
import { useAppSelector } from 'redux-stores';
import { AccessibleList } from 'common/components';

const US_DATE_FORMAT = 'MM/DD/YYYY';
const classes = BEM.with('ScheduledMessagesView');
const DEBOUNCE_TIMEOUT = 500;

const ScheduledMessagesView = ({
  feed,
  fetchScheduledMessageHits,
  loadScheduledMessages,
  logPendoAnalytics,
  openModal,
  openScheduleMessageModal,
  setCurrentAdminPageRefresher,
}) => {
  const { isLoading, results, resetSearch, scrollContainerRef, updateOptions } =
    useInfiniteSearchResults(loadScheduledMessages, { feed });
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  const [query, setQuery] = useState('');
  const [category, setCategory] = useState('queue');
  const [hits, setHits] = useState({});
  const [selected, setSelected] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [sortBy, setSortBy] = useState('scheduled_time');
  const [sortOrder, setSortOrder] = useState('asc');
  const [range, setRange] = useState(null);
  const isQueue = category === 'queue';
  const isHistory = category === 'history';
  const setQueryDebounced = useRef(debounce(setQuery, DEBOUNCE_TIMEOUT));

  const loadHits = async (shouldForce) => {
    const newHits = await fetchScheduledMessageHits(feed, shouldForce);
    setHits(newHits);
  };

  const handleQueryChange = (value) => {
    if (value !== query) {
      resetSearch();
      setQueryDebounced.current(value);
    }
  };

  const reloadSearch = (options) => {
    setSelected([]);
    setShowDatePicker(false);
    resetSearch();
    loadHits(true);
    updateOptions(options);
  };

  const handleBulkDelete = () => {
    openModal('deleteScheduledMessage', {
      selected,
      onClose: reloadSearch,
    });
  };

  setCurrentAdminPageRefresher(reloadSearch);

  const toggleSelected = (token) => {
    if (selected.includes(token)) {
      setSelected(selected.filter((id) => id !== token));
    } else {
      setSelected([...selected, token]);
    }
  };

  const toggleSort = (newSortBy) => {
    setSortOrder(sortBy !== newSortBy ? 'asc' : sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(newSortBy);
  };

  useEffect(() => {
    setQuery('');
    setCategory('queue');
    setRange(null);
    setSortBy('scheduled_time');
    setSortOrder('asc');
  }, [feed]);

  useEffect(() => {
    setQuery('');
    setRange(null);
    setSortBy('scheduled_time');
    setSortOrder(category === 'history' ? 'desc' : 'asc');
  }, [category]);

  useEffect(() => {
    reloadSearch({
      query,
      category,
      feed,
      range,
      sortBy,
      sortOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, category, feed, range, sortBy, sortOrder]);

  useMemo(async () => {
    const newHits = await fetchScheduledMessageHits(feed);
    setHits(newHits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <h3 className={classes('title')} id="patient-settings-heading">
          Scheduled Messages: {feed === 'all' ? 'admin' : feed}
          <RefreshButton refreshList={reloadSearch} />
        </h3>
        <div>
          <button
            className={classes('show-picker')}
            data-test-id={'date-picker'}
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            <div className={classes('expand-icon-svg')}>
              <ExpandDatePickerSvg />
            </div>
          </button>
          {showDatePicker && (
            <div className={classes('outer-container')}>
              <div className={classes('inner-container')}>
                <ScheduledMessagesDateRangePicker
                  category={category}
                  range={range}
                  setRange={setRange}
                  setShowDatePicker={setShowDatePicker}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes('title-sect')}>
        <AccessibleList
          focusableClasses=".tc-ScheduledMessagesView__title-buttons"
          loop={true}
          accessibilityMode={accessibilityMode}
          direction="horizontal"
          setStartElementOnChange={true}
        >
          <button
            onClick={() => setCategory('queue')}
            className={classes('title-buttons', { selected: isQueue })}
            data-test-id={'queue-button'}
            role="tab"
            aria-selected={isQueue}
            aria-controls="scheduled-messages-table__queue-list"
            id="scheduled-messages__queue-button"
          >
            Queue ({hits.queue})
          </button>
          <button
            onClick={() => setCategory('history')}
            className={classes('title-buttons', { selected: isHistory })}
            data-test-id={'history-button'}
            role="tab"
            aria-selected={isHistory}
            aria-controls="scheduled-messages-table__history-list"
            id="scheduled-messages__history-button"
          >
            History ({hits.history})
          </button>
        </AccessibleList>
        <div className={classes('filler')} />
      </div>
      <div className={classes('actions')}>
        <div className={classes('actions-left')}>
          <button
            className={classes('create')}
            onClick={() => {
              openScheduleMessageModal(reloadSearch, feed);
              logPendoAnalytics({
                parentPathName: 'Patient Settings',
                pathName: `Create ${feed} Schedule Message Template`,
              });
            }}
            data-test-id={'create-schedule-message-button'}
          >
            Create Schedule Message
          </button>
          <div className={classes('filters')}>
            <CollapsingSearchBar handleQueryChange={handleQueryChange} query={query} />
          </div>
        </div>
        {selected.length > 0 && (
          <div className={classes('actions-right')}>
            <button
              onClick={handleBulkDelete}
              className={classes('actions-right-button')}
              data-test-id={'delete-button'}
            >
              <DeleteButtonSvg className={classes('delete-icon')} />
              <span className={classes('delete-icon-text')}>Delete </span>
            </button>
          </div>
        )}
      </div>
      {range && (
        <div className={classes('filtered-by')}>
          <div>Filtered by</div>
          <div className={classes('filtered-range')}>
            {moment(range['scheduled_time'].from).format(US_DATE_FORMAT)} -{' '}
            {moment(range['scheduled_time'].to).format(US_DATE_FORMAT)}
            <button onClick={() => setRange(null)}>
              <CloseIconSvg />
            </button>
          </div>
        </div>
      )}
      <ScheduledMessagesTable
        category={category}
        feed={feed}
        isLoading={isLoading}
        reloadSearch={reloadSearch}
        results={results}
        scrollContainerRef={scrollContainerRef}
        selected={selected}
        sortBy={sortBy}
        sortOrder={sortOrder}
        toggleSelected={toggleSelected}
        toggleSort={toggleSort}
        queryValue={query}
      />
      <div className={classes('loading-more', { isLoadingMore: isLoading })}>
        <DotsIndicator color={'#969696'} size={18} />
      </div>
    </div>
  );
};

ScheduledMessagesView.propTypes = {
  feed: PropTypes.string.isRequired,
  fetchScheduledMessageHits: PropTypes.func.isRequired,
  loadScheduledMessages: PropTypes.func.isRequired,
  logPendoAnalytics: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  openScheduleMessageModal: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  modalStore: ['openModal'],
  patientAdminStore: ['fetchScheduledMessageHits', 'loadScheduledMessages'],
  scheduleMessageStore: ['openScheduleMessageModal'],
  trackerStore: ['logPendoAnalytics'],
})(ScheduledMessagesView);
