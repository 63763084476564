// @ts-nocheck
const REPLACER = 0xfffd;

export const NativeTextDecoder = global.TextDecoder;

export function supportsStreamOption() {
  if (!NativeTextDecoder || typeof TextEncoder === 'undefined') return false;

  try {
    return (
      new NativeTextDecoder().decode(new TextEncoder().encode('test'), { stream: true }) === 'test'
    );
  } catch (error) {
    // do nothing
  }

  return false;
}

function valid(codePoint, shift, octetsCount) {
  if (octetsCount === 1) {
    return codePoint >= 0x0080 >> shift && codePoint << shift <= 0x07ff;
  } else if (octetsCount === 2) {
    return (
      (codePoint >= 0x0800 >> shift && codePoint << shift <= 0xd7ff) ||
      (codePoint >= 0xe000 >> shift && codePoint << shift <= 0xffff)
    );
  } else if (octetsCount === 3) {
    return codePoint >= 0x010000 >> shift && codePoint << shift <= 0x10ffff;
  }

  throw new Error('Invalid octetsCount');
}

function octetsCount(bitsNeeded, codePoint) {
  if (bitsNeeded === 6 * 1) {
    return codePoint >> 6 > 15 ? 3 : codePoint > 31 ? 2 : 1;
  }
  if (bitsNeeded === 6 * 2) {
    return codePoint > 15 ? 3 : 2;
  }
  if (bitsNeeded === 6 * 3) {
    return 3;
  }
  throw new Error();
}

export class TextDecoderPolyfill {
  constructor() {
    this.bitsNeeded = 0;
    this.codePoint = 0;
  }

  decode(octets) {
    let string = '';
    let bitsNeeded = this.bitsNeeded;
    let codePoint = this.codePoint;

    for (let i = 0; i < octets.length; i += 1) {
      const octet = octets[i];
      if (bitsNeeded !== 0) {
        if (
          octet < 128 ||
          octet > 191 ||
          !valid(
            (codePoint << 6) | (octet & 63),
            bitsNeeded - 6,
            octetsCount(bitsNeeded, codePoint)
          )
        ) {
          bitsNeeded = 0;
          codePoint = REPLACER;
          string += String.fromCharCode(codePoint);
        }
      }

      if (bitsNeeded === 0) {
        if (octet >= 0 && octet <= 127) {
          bitsNeeded = 0;
          codePoint = octet;
        } else if (octet >= 192 && octet <= 223) {
          bitsNeeded = 6 * 1;
          codePoint = octet & 31;
        } else if (octet >= 224 && octet <= 239) {
          bitsNeeded = 6 * 2;
          codePoint = octet & 15;
        } else if (octet >= 240 && octet <= 247) {
          bitsNeeded = 6 * 3;
          codePoint = octet & 7;
        } else {
          bitsNeeded = 0;
          codePoint = REPLACER;
        }
        if (bitsNeeded !== 0 && !valid(codePoint, bitsNeeded, octetsCount(bitsNeeded, codePoint))) {
          bitsNeeded = 0;
          codePoint = REPLACER;
        }
      } else {
        bitsNeeded -= 6;
        codePoint = (codePoint << 6) | (octet & 63);
      }

      if (bitsNeeded === 0) {
        if (codePoint <= 0xffff) {
          string += String.fromCharCode(codePoint);
        } else {
          string += String.fromCharCode(0xd800 + ((codePoint - 0xffff - 1) >> 10));
          string += String.fromCharCode(0xdc00 + ((codePoint - 0xffff - 1) & 0x3ff));
        }
      }
    }

    this.bitsNeeded = bitsNeeded;
    this.codePoint = codePoint;

    return string;
  }
}
