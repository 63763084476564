// @ts-nocheck
import BaseAPI from './BaseAPI';

export default class SchedulerAPI extends BaseAPI {
  async findIntegrationIds(organizationId: string) {
    const headers = {
      Accept: '*/*',
      'TT-X-Organization-Key': organizationId,
    };
    const res = await this.httpClient.post('/v1/integrations/scheduler/configs/info/org', {
      headers,
      data: {
        org: organizationId,
      },
    });
    return res.data;
  }

  async sendCsvSchedule(
    integrationId: string,
    file: string,
    organizationId: string,
    timezone: string
  ) {
    const headers = {
      'Content-Type': 'text/csv',
      'TT-X-Organization-Key': organizationId,
      'TT-X-Integration-Id': integrationId,
      'TT-X-Timezone': timezone,
    };
    let json;
    let status;

    try {
      const res = await this.httpClient.post('/v1/integrations/scheduler/webhook/csv', {
        data: file,
        headers,
      });
      json = res.data;
      status = res.status;
    } catch (error) {
      // ignore 400 error, throw all others
      if (error.status === 400) {
        json = error.response && (error.response.body || error.response.text);
        status = error.status;
      } else {
        return Promise.reject(error);
      }
    }

    return {
      json,
      status,
    };
  }

  async findCsv(organizationId: string, id: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.post('/v1/integrations/scheduler/webhook/csv/download', {
      headers,
      data: {
        org: organizationId,
        id,
      },
      resFormat: 'text',
    });

    // leave as res, there is no res.data for resFormat===text
    return res;
  }

  async clearSchedule(organizationId: string, id: string) {
    const headers = {
      Accept: '*/*',
      'TT-X-Organization-Key': organizationId,
    };
    const res = await this.httpClient.post('/v1/integrations/scheduler/configs/truncate', {
      headers,
      data: {
        id,
      },
    });
    return res.status;
  }
}
