// @ts-nocheck
export default function replaceKeys(object, replacements) {
  for (const [oldKey, newKey] of Object.entries(replacements)) {
    if (!(newKey in object) && oldKey in object) {
      object[newKey] = object[oldKey];
      delete object[oldKey];
    }
  }

  return object;
}
