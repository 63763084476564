// @ts-nocheck
export default function (store, { host }) {
  const TeamRequest = store.defineModel('teamRequest', {
    replaceKeys: {},

    transientAttrs: [],

    defaultValues: {},

    parseAttrs(attrs) {
      if (attrs.createdBy) {
        attrs.createdById = attrs.createdBy.token;
        delete attrs.createdBy;
      }

      if (attrs.team) {
        attrs.teamId = attrs.team.id;
        delete attrs.team;
      }

      return attrs;
    },

    afterAssignment(entity) {},

    relations: {
      belongsTo: {
        createdBy: { type: 'user', foreignKey: 'createdById' },
        team: { type: 'team', foreignKey: 'teamId' },
      },
    },
  });

  return TeamRequest;
}
