// @ts-nocheck
import { base64ToArrayBuffer } from '../network/HttpClient/encoding';
import * as errors from '../errors';

export function createBlob(ext, oldFile) {
  if (process.env.NODE_TARGET === 'web') {
    const mimeTypes = {
      xls: 'application/vnd.ms-excel',
      csv: 'text/csv',
    };

    if (!mimeTypes[ext]) return null;

    const newFile = new Blob([oldFile], {
      type: mimeTypes[ext],
    });
    newFile.name = oldFile.name;
    newFile.lastModifiedDate = oldFile.lastModifiedDate;
    return newFile;
  } else {
    errors.TargetNotSupportedError.raise();
  }
}

export async function downloadFile({ attachmentContentType, blob, fileName }) {
  if (process.env.NODE_TARGET === 'web') {
    const isIOSDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (isIOSDevice && navigator.userAgent.match('chrome')) {
      const base64 = await loadLocalFilePathWithFileReader(blob);
      const byte = base64ToArrayBuffer(base64);
      saveByteArray(fileName, byte, attachmentContentType);
    } else if (isIOSDevice) {
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    } else {
      const { saveAs } = require('file-saver');
      saveAs(blob, fileName);
    }
  } else {
    errors.TargetNotSupportedError.raise();
  }
}

export function loadLocalFilePathWithFileReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.addEventListener(
      'error',
      (e) => {
        reject(e);
      },
      false
    );
    reader.readAsDataURL(file);
  });
}

export function saveByteArray(fileName, byte, type) {
  const reader = new FileReader();
  const out = new Blob([byte], { type });
  reader.fileName = fileName;
  reader.onload = function (e) {
    window.open(reader.result, '_blank');
  };
  reader.readAsDataURL(out);
}
