export type MessageSubType =
  | 'AUTOMATED_MESSAGE'
  | 'CHATBOT_MESSAGE'
  | 'EMPTY_ROLE_NOTIFICATION'
  | 'ROLE_AWAY_NOTIFICATION'
  | 'WORKFLOW_AUTOMATION_NOTIFICATION'
  | 'SCHEDULE_MESSAGE'
  | 'SMS_OPT_IN_NOTIFICATION'
  | 'SMS_OPT_OUT_NOTIFICATION'
  | 'SYSTEM'
  | 'USER_AWAY_NOTIFICATION'
  | 'VIRTUAL_WAITING_ROOM_CALL_INVITE';

/**
 * @deprecated Prefer the MessageSubType union, as this object will be deprecated in the future.
 */
export const MessageSubTypes: { [T in MessageSubType]: T } = {
  AUTOMATED_MESSAGE: 'AUTOMATED_MESSAGE',
  CHATBOT_MESSAGE: 'CHATBOT_MESSAGE',
  EMPTY_ROLE_NOTIFICATION: 'EMPTY_ROLE_NOTIFICATION',
  ROLE_AWAY_NOTIFICATION: 'ROLE_AWAY_NOTIFICATION',
  WORKFLOW_AUTOMATION_NOTIFICATION: 'WORKFLOW_AUTOMATION_NOTIFICATION',
  SCHEDULE_MESSAGE: 'SCHEDULE_MESSAGE',
  SMS_OPT_IN_NOTIFICATION: 'SMS_OPT_IN_NOTIFICATION',
  SMS_OPT_OUT_NOTIFICATION: 'SMS_OPT_OUT_NOTIFICATION',
  SYSTEM: 'SYSTEM',
  USER_AWAY_NOTIFICATION: 'USER_AWAY_NOTIFICATION',
  VIRTUAL_WAITING_ROOM_CALL_INVITE: 'VIRTUAL_WAITING_ROOM_CALL_INVITE',
} as const;
