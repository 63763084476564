// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (!(global as any)._babelPolyfill && (global as any)._tcShouldPolyfill !== false) {
  require('./utils/polyfills');
} else {
  require('./utils/check-polyfills');
}

const NODE_TARGET = process.env.NODE_TARGET || 'node';
require(`./platform-specific/${NODE_TARGET}`);

const { TigerConnectClient } = require('./Client');

export const Client = TigerConnectClient;
