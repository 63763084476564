// @ts-nocheck
export default function (store, { host }) {
  const EscalationPolicy = store.defineModel('escalationPolicy', {
    replaceKeys: {
      destructionDuration: 'minutesToExpire',
      escalationPath: 'path',
      target: 'targetId',
      targetDuration: 'targetMinutesToAcknowledge',
    },

    defaultValues: {
      alwaysEscalate: false,
    },

    parseAttrs(attrs) {
      if ('path' in attrs) {
        const path = [];
        let invalidTarget = false;

        for (const step of attrs.path) {
          const { targets: targetIds, duration: minutesToAcknowledge } = step;
          const targets = [];
          for (const target of targetIds) {
            if (typeof target === 'string') {
              targets.push(host.injectPlaceholderModel({ id: target }, 'user'));
            } else if (target && target.token) {
              const { displayName, token: id } = target;
              targets.push(host.injectPlaceholderModel({ id, displayName }, 'user'));
            } else {
              invalidTarget = true;
            }
          }
          path.push({ minutesToAcknowledge, targets });
        }

        if (invalidTarget) {
          delete attrs.path;
        } else {
          attrs.path = path;
        }
      }

      return attrs;
    },

    afterAssignment(entity) {},

    relations: {
      belongsTo: {
        target: { type: 'user', foreignKey: 'targetId' },
      },
    },
  });

  return EscalationPolicy;
}
