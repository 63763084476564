import React, { useEffect, useRef } from 'react';
import throttle from 'lodash.throttle';
import { mobxInjectSelect } from 'common/utils';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setAccessibilityMode, setMessageBodyInputFocus } = actions;

// 5 minutes
const MOUSE_ACTIVITY_THROTTLE = 60000 * 5;

function GlobalKeyMap(props) {
  const {
    children,
    className,
    isAccessibilityEnabled,
    isHidden,
    isModalOpen,
    openModal,
    stopCurrentAction,
    resetAutoLogout,
    isMouseActivityLogoutEnabled,
  } = props;
  const dispatch = useAppDispatch();
  const {
    isModalOpen: isReduxModalOpen,
    messageBodyInputFocus,
    isMentionsModalOpen,
  } = useAppSelector(({ session, ui }) => ({
    isModalOpen: session.isModalOpen,
    messageBodyInputFocus: ui.messageBodyInputFocus,
    isMentionsModalOpen: ui.isMentionsModalOpen,
  }));

  const isAnyModalOpen = isReduxModalOpen || isModalOpen;

  const readMouseActivity = useRef(
    throttle(
      () => {
        resetAutoLogout();
      },
      MOUSE_ACTIVITY_THROTTLE,
      { leading: true }
    )
  );

  useEffect(() => {
    const clickListener = (e) => {
      if (e.pointerType === 'mouse') dispatch(setAccessibilityMode(false));
    };
    const tabListener = (e) => {
      if (e.code === 'Tab' && isAccessibilityEnabled) dispatch(setAccessibilityMode(true));
    };

    document.addEventListener('click', clickListener, true);
    document.addEventListener('keydown', tabListener, true);
    return () => {
      document.removeEventListener('click', clickListener, true);
      document.removeEventListener('keydown', tabListener, true);
    };
  }, [dispatch, isAccessibilityEnabled]);

  useEffect(
    function handleWindowFocus() {
      function windowFocusHandler() {
        if (!isHidden && !messageBodyInputFocus && !isAnyModalOpen) {
          dispatch(setMessageBodyInputFocus(true));
        }
      }

      window.addEventListener('focus', windowFocusHandler);
      return () => {
        window.removeEventListener('focus', windowFocusHandler);
      };
    },
    [dispatch, messageBodyInputFocus, isHidden, isAnyModalOpen]
  );

  function handleKeyDown(event) {
    if (isHidden) return;
    const { key, altKey, ctrlKey, metaKey, location } = event;
    // const anyModifier = altKey || ctrlKey || metaKey || shiftKey;

    const isPaste = metaKey && key === 'v';
    const shouldFocusMessageBodyInput =
      isPaste || (!metaKey && !ctrlKey && location === 0 && !isAnyModalOpen);

    if (key === 'Escape') {
      stopCurrentAction();
    } else if ((altKey || ctrlKey) && key === 'g') {
      openModal('go');
    }

    if (
      !messageBodyInputFocus &&
      shouldFocusMessageBodyInput &&
      document.activeElement.id !== 'search-organization-input' &&
      !isMentionsModalOpen
    ) {
      dispatch(setMessageBodyInputFocus(true));
    }
  }

  function handleMouseMove() {
    if (!isMouseActivityLogoutEnabled) return;
    readMouseActivity.current();
  }

  function handleCopy(event) {
    const textOnly = document.getSelection().toString();
    const clipData = event.clipboardData || window.clipboardData;
    clipData.setData('text/plain', textOnly);
    event.preventDefault();
  }

  return (
    <div
      onCopy={handleCopy}
      className={className}
      onMouseMove={handleMouseMove}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
    >
      {children}
    </div>
  );
}

export default mobxInjectSelect({
  messengerStore: ['isAccessibilityEnabled', 'stopCurrentAction', 'isMouseActivityLogoutEnabled'],
  modalStore: ['openModal', 'isModalOpen'],
  sessionStore: ['resetAutoLogout'],
})(GlobalKeyMap);
