// @ts-nocheck
import _ from 'lodash';
import _uniq from 'lodash-bound/uniq';
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import BaseService from './BaseService';

const forumReturnField = [
  'token',
  'status',
  'display_name',
  'first_name',
  'last_name',
  'username',
  'phones',
  'emails',
  'api_keys',
  'pagers',
  'department',
  'title',
  'organization_key',
  'organization_name',
  'avatar',
  'account_type',
  'activation_time',
  'welcome_email_sent',
  'name',
  'replay_history',
  'is_public',
  'members',
  'num_members',
];

export default class ForumsService extends BaseService {
  mounted() {
    this._fetched = false;
  }

  dispose() {
    this._fetched = false;
  }

  async create({
    avatarFile,
    description,
    memberIds = [],
    metadata,
    name,
    organizationId,
    replayHistory = true,
    senderId = this.host.currentUserId,
  }) {
    this.host.requireUser();
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const users = await Promise.all(
      [senderId, ...memberIds]
        .map(this.host.roles.__resolveRoleId)
        .filter(Boolean)
        .map((userId) => this._resolveModelIdWithTypes(userId, 'user'))
        .map((userId) => this.host.users.findOrCreate(userId, { organizationId }))
    );
    const uniqueUsers = _uniq.call(users);
    memberIds = uniqueUsers.map((userId) => this._resolveModelIdWithTypes(userId, 'user'));

    const response = await this.host.api.groups.create({
      avatarFile,
      description,
      excludeCreator: false,
      isPublic: true,
      memberIds,
      metadata,
      name,
      organizationId,
      replayHistory,
    });

    let forum;
    if (this.config.condensedReplays && response.entity) {
      forum = this.host.conversations.__injectCounterParty({
        conversationId: response.conversation_id,
        entityAttrs: response.entity,
        entityType: 'group',
        hasCurrentUser: true,
        organizationId: response.organization_id,
      });
    } else {
      forum = this.host.models.Group.inject(response);
    }

    if (metadata) {
      this.host.metadata.__injectMetadata(forum.id, organizationId, metadata);
    }

    return forum;
  }

  @reusePromise()
  async find(id: string, options = {}) {
    options = _.defaults(options, {
      bypassCache: false,
      ignoreNotFound: false,
    });
    this.host.requireUser();

    let forum = null;

    if (!options.bypassCache) {
      forum = this.host.models.Group.get(id);

      if (forum && !forum.$placeholder) {
        await this.host.groups.__ensureEntitiesLoaded(forum.id, options);

        return forum;
      }
    }

    try {
      forum = await this.host.groups.__find(id);
    } catch (err) {
      if (options.ignoreNotFound) {
        return null;
      } else {
        return Promise.reject(err);
      }
    }

    await this.host.groups.__ensureEntitiesLoaded(forum.id, options);

    return forum;
  }

  @reusePromise()
  async findAll({ bypassCache = false } = {}) {
    if (!bypassCache && this._fetched) return this.getAll();

    let organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) {
      organizations = await this.host.organizations.findAll();
    }

    const { results } = await this.host.search.query({
      version: 'LEGACY',
      types: ['forum'],
      organizationIds: organizations.map((o) => o.id),
      query: { displayName: '' },
      followContinuations: true,
    });

    this._fetched = true;

    return results.map((searchResult) => searchResult.entity);
  }

  @reusePromise()
  async findAllByOrg(organizationId, { query = '', continuation }) {
    const SEARCH_TERMS = ['name'].join(',');
    const { results, metadata } = await this.host.search.query({
      version: 'SEARCH_PARITY',
      types: ['forum'],
      organizationIds: organizationId,
      query: { [SEARCH_TERMS]: query },
      returnFields: forumReturnField,
      continuation,
    });

    return {
      results: results.map((searchResult) => searchResult.entity),
      continuation: metadata.continuation,
    };
  }

  getAll() {
    return this.host.models.Group.getAll().filter((group) => group.isPublic);
  }

  getById(id: string) {
    return this.host.models.Group.get(id);
  }

  async join(id: string | Object) {
    return this.host.groups.addMembers(id, [this.host.currentUserId]);
  }
}
