import moment from 'moment';
import { escalationStatusToReadableLabel } from './readableLabels';

export default function escalationStatusLine(escalationExecution) {
  const { actionUserName, status } = escalationExecution;
  let { actionTime, expiresAt } = escalationExecution;
  actionTime = moment(actionTime).format('M/D h:mm A');
  expiresAt = moment(expiresAt).format('M/D h:mm A');

  let statusLine;
  if (status === 'ACKNOWLEDGED') {
    statusLine = `Acknowledged by ${actionUserName} ${actionTime}. This conversation expires ${expiresAt}.`;
  } else if (status === 'CANCELLED') {
    statusLine = `Cancelled by ${actionUserName} ${actionTime}. This conversation expires ${expiresAt}.`;
  } else if (status === 'IN_PROGRESS') {
    statusLine = `${escalationStatusToReadableLabel(status)}`;
  } else if (status === 'NO_RESPONSE') {
    statusLine = `No Response ${actionTime}. This conversation expires ${expiresAt}.`;
  }
  return statusLine;
}
