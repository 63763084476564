import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useTrack from '../../hooks/useTrack/useTrack';
import useDominantSpeaker from '../../hooks/useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { ReactComponent as VideoOffIcon } from '../../images/provider-video-off.svg';
import { ReactComponent as AudioOnlyIcon } from '../../../widgets/messenger/images/provider-call-icon.svg';
import AvatarImage from '../../../common/components/AvatarImage';
import { ReactComponent as AudioOffIcon } from '../../images/mic-off.svg';
import { useAppState } from './../../state';

const useStyles = makeStyles(() =>
  createStyles({
    audioOff: {
      '& path[fill-rule="nonzero"]': {
        fill: '#ff4d5b',
      },
    },
    audioOnly: {
      '& path[fill-rule="evenodd"]': {
        fill: '#fff',
      },
    },
    nameAudioHolder: {
      backgroundColor: 'rgba(53, 51, 60, .75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      height: '20px',
      bottom: '7px',
      left: '7px',
      borderRadius: '4px',
      color: '#fff',
      fontSize: '12px',
      padding: '5px',
      maxWidth: '100%',
    },
    gridNameAudioHolder: {
      left: 'auto',
    },
    isRemoteAudioOff: {
      borderRadius: '8px',
      width: '30px',
      height: '30px',
      bottom: '12px',
      left: '13px',
    },
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      '& video': {
        filter: 'none',
      },
    },
    isLocal: {
      position: 'fixed',
      width: '90px',
      height: '83px',
      zIndex: 2,
      border: '3px solid white',
      borderRadius: '12px',
      marginTop: '5px',
      marginLeft: '5px',
    },
    isLocalMax: {
      position: 'fixed',
      width: '120px',
      height: '120px',
      zIndex: 2,
      border: '3px solid white',
      borderRadius: '12px',
      marginTop: '25px',
      marginLeft: '25px',
    },
    isRemote: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
    },
    isLocalVideoSwitchedOff: {
      border: '3px solid white',
      borderRadius: '12px',
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '0.4em',
      width: '100%',
      background: 'transparent',
      alignItems: 'center',
    },
    localHideVideo: {
      background: '#c2cbd2',
      justifyContent: 'center',
      alignItems: 'center',
    },
    remoteHideVideo: {
      background: '#d9e5ee',
      justifyContent: 'center',
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '0.1em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    p2p: {
      top: 0,
      position: 'absolute',
    },
    wideTrack: {
      width: '50%',
      margin: '0 auto',
    },
    border: {
      border: '2px solid #fff',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    dominantSpeaker: {
      border: '2px solid #fcb040',
    },
  })
);

export default function ParticipantInfo({ participant, children, index }) {
  const publications = usePublications(participant);
  const { indexOfParticipants, isEnlargedWindow, getUserInfo } = useAppState();

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => p.kind === 'video');
  const audioTrack = useTrack(audioPublication);
  const videoTrack = useTrack(videoPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  const isVideoEnabled = useIsTrackEnabled(videoTrack);
  const isAudioSwitchedOff = useIsTrackSwitchedOff(audioTrack);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
  const isAudioOn = isAudioEnabled && !isAudioSwitchedOff;
  const isVideoOn = isVideoEnabled && !isVideoSwitchedOff;

  const { canUseVideo, room } = useVideoContext();
  const isLocal = participant === room.localParticipant;
  let remoteParticipantsCount;
  if (!room.participants) {
    remoteParticipantsCount = 0;
  } else {
    if (indexOfParticipants === 0) {
      if (room.participants.size < 3) {
        remoteParticipantsCount = room.participants.size;
      } else {
        remoteParticipantsCount = 3;
      }
    } else {
      remoteParticipantsCount = room.participants.size + 1 - indexOfParticipants * 4;
    }
  }
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isProvider, setIsProvider] = useState(null);
  const [displayName, setDisplayName] = useState(null);

  const dominantSpeaker = useDominantSpeaker();

  useEffect(() => {
    async function userInfo(userId) {
      const { avatarUrl, displayName, isPatient, isPatientContact, patientContact } =
        await getUserInfo(userId);
      const patientOrContact = isPatient || isPatientContact;
      setAvatarUrl(avatarUrl);
      setIsProvider(!patientOrContact);
      setDisplayName(
        `${displayName}${
          patientOrContact ? ` (${isPatient ? 'Patient' : patientContact.relation})` : ''
        }`
      );
    }
    userInfo(participant.identity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  const isGrid =
    remoteParticipantsCount > 1 || (indexOfParticipants > 0 && remoteParticipantsCount >= 1);
  const isWideView =
    (remoteParticipantsCount === 2 && index === 0 && indexOfParticipants === 0) ||
    (indexOfParticipants > 0 && remoteParticipantsCount === 3 && index === 0);
  const isDominantSpeaker = dominantSpeaker && participant.identity === dominantSpeaker.identity;
  let videoPanel;
  if (isLocal) {
    videoPanel = isVideoOn ? 'provider-video-panel' : 'provider-video-disabled-panel';
  } else {
    videoPanel = isVideoOn ? 'participant-video-panel' : 'participant-video-disabled-panel';
  }

  return (
    <div
      className={clsx(classes.container, {
        [classes.isVideoSwitchedOff]: !isVideoOn,
        [classes.isLocalVideoSwitchedOff]: !isVideoOn && isLocal,
        [classes.isLocal]: isLocal && !isEnlargedWindow && !isGrid,
        [classes.isLocalMax]: isLocal && isEnlargedWindow && !isGrid,
        [classes.isRemote]: !isLocal,
        [classes.p2p]: !isGrid,
        [classes.wideTrack]: isWideView,
        [classes.border]: isGrid && !isWideView,
        [classes.dominantSpeaker]: isDominantSpeaker && isGrid,
      })}
      data-cy-participant={participant.identity}
      data-test-id={videoPanel}
    >
      <div
        className={clsx(classes.infoContainer, {
          [classes.remoteHideVideo]: !isVideoOn && !isLocal,
          [classes.localHideVideo]: !isVideoOn && isLocal,
        })}
      >
        {!isVideoOn &&
          (isLocal ? (
            !canUseVideo ? (
              <AudioOnlyIcon width={24} className={clsx(classes.audioOnly)} />
            ) : (
              <VideoOffIcon width={24} />
            )
          ) : (
            <AvatarImage
              size={40}
              squareAvatar={false}
              entityType={isProvider ? 'user' : 'singleProvider'}
              avatarUrl={avatarUrl}
            />
          ))}
        {(isGrid || !isAudioOn) && (
          <div
            className={clsx(classes.nameAudioHolder, {
              [classes.gridNameAudioHolder]: isGrid,
            })}
          >
            {!isAudioOn && (
              <AudioOffIcon
                className={clsx(classes.audioOff)}
                data-test-id={isLocal ? 'provider-muted-indicator' : 'patient-muted-indicator'}
                width={15}
              />
            )}
            {isGrid && (
              <div className={clsx(classes.ellipsis)}> {isLocal ? 'You' : displayName} </div>
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
