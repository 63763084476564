import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
// import { intersection, keyBy, pick, sortBy } from 'lodash';

import { OrganizationSetting, SettingsSlice } from '../../../types';
import * as thunks from './thunk';

export const settingsSlice = createSlice({
  name: 'orgSettings',
  initialState: {
    settings: {},
  } as SettingsSlice,
  reducers: {
    updateSettings: (state, action: PayloadAction<OrganizationSetting>) => {
      const { authType } = action.payload;
      if (authType === 'saml' || authType === 'native') {
        action.payload.orgLoginWorkflow = 'Username & Password';
      }
      if (authType !== 'saml' && authType !== 'native') {
        state.settings.orgLoginWorkflow = state.settings.serverOrgLoginWorkflow;
      }
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
    },
  },
});

export const settingsThunks = thunks;
