import React, { Component } from 'react';
import Modal2 from 'react-modal';
import classNames from 'classnames';
import BEM from '../bem';
import { ReactComponent as CloseButtonSvg } from '../images/close-button.svg';

const classes = BEM.with('Modal');
const overlayClasses = BEM.with('ModalOverlay');

type ModalProps = {
  ariaLabelCloseButton?: string;
  ariaLabelHeader?: string;
  bodyClass?: string;
  className?: string;
  closeClass?: string;
  closeTimeoutMS?: number;
  footer?: React.ReactNode | string;
  footerClass?: string;
  footerAgreementClass?: string;
  footerAgreementCheck?: React.ReactNode;
  footerPrimaryActions?: React.ReactNode;
  footerSecondaryActions?: React.ReactNode;
  hasCloseButton?: boolean;
  header?: React.ReactNode | string;
  headerClass?: string;
  headerTextStyle?: string;
  imgHeader?: {
    image: React.FC<React.SVGProps<SVGSVGElement>>;
    fill?: string;
    imgClassHeader: string;
  };
  isOpen: boolean;
  onRequestClose?: () => void;
  overlayClassName?: string;
  size?: 'large' | 'medium-large' | 'medium' | 'medium-small' | 'small' | 'image' | 'variable';
  shouldSplitSecondaryFooterActions?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
} & typeof Modal.defaultProps;

class Modal extends Component<ModalProps, {}> {
  static defaultProps = {
    closeTimeoutMS: 200,
    hasCloseButton: true,
    onRequestClose: function () {},
    shouldCloseOnOverlayClick: true,
    size: 'medium',
  };

  _lastIsOpen = false;

  componentDidMount() {
    this._lastIsOpen = this.props.isOpen;
  }

  componentDidUpdate(nextProps: ModalProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this._lastIsOpen = nextProps.isOpen;
    }
  }

  render() {
    const {
      ariaLabelCloseButton,
      ariaLabelHeader,
      bodyClass,
      children,
      className,
      closeClass,
      closeTimeoutMS,
      footer,
      footerAgreementCheck,
      footerAgreementClass,
      footerClass,
      footerPrimaryActions,
      footerSecondaryActions,
      hasCloseButton,
      header,
      headerClass,
      headerTextStyle,
      imgHeader,
      onRequestClose,
      overlayClassName,
      shouldSplitSecondaryFooterActions,
      size,
      ...restProps
    } = this.props;
    let footerActionsFragment;

    if (footerAgreementCheck) {
      footerActionsFragment = (
        <div className={footerClass || classes('footer')}>
          {footerAgreementCheck && (
            <div className={footerAgreementClass}> {footerAgreementCheck} </div>
          )}
          <div className={classes('footer-container')}>
            {footerSecondaryActions ? (
              <div
                className={classes(
                  `footer-secondary-actions${shouldSplitSecondaryFooterActions ? '-split' : ''}`
                )}
              >
                {footerSecondaryActions}
              </div>
            ) : (
              <div />
            )}
            {footer}
            {footerPrimaryActions && (
              <div className={classes('footer-primary-actions')}>{footerPrimaryActions}</div>
            )}
          </div>
        </div>
      );
    } else {
      footerActionsFragment = (
        <div className={footerClass || classes('footer')}>
          {footerSecondaryActions ? (
            <div
              className={classes(
                `footer-secondary-actions${shouldSplitSecondaryFooterActions ? '-split' : ''}`
              )}
            >
              {footerSecondaryActions}
            </div>
          ) : (
            <div />
          )}
          {footer}
          {footerPrimaryActions && (
            <div className={classes('footer-primary-actions')}>{footerPrimaryActions}</div>
          )}
        </div>
      );
    }
    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
      const enterOrSpace = e.key === 'Enter' || e.key === ' ';
      if (enterOrSpace) {
        e.preventDefault();
        this._close();
      }
    };

    const closeButton = hasCloseButton && (
      <div
        aria-label={ariaLabelCloseButton}
        className={closeClass || classes('close')}
        data-test-id={ariaLabelCloseButton}
        onClick={this._close}
        onKeyDown={onKeyPress}
        role="button"
        tabIndex={0}
      >
        <CloseButtonSvg aria-hidden />
      </div>
    );

    const shouldRenderFooter = footer || footerPrimaryActions || footerSecondaryActions;
    return (
      <Modal2
        className={classNames(className, classes({ size }))}
        closeTimeoutMS={closeTimeoutMS}
        // @ts-expect-error https://tigertext.atlassian.net/browse/RW-4017
        isOpen={this._lastIsOpen}
        onRequestClose={this._close}
        overlayClassName={classNames(overlayClassName, overlayClasses())}
        appElement={document.getElementById('app') as HTMLElement}
        {...restProps}
      >
        <div className={headerClass || classes('header')}>
          {(header || imgHeader) && (
            <div className={classes('header-contents')}>
              <div className={classes('header-img')}>
                {imgHeader && (
                  <imgHeader.image className={imgHeader.imgClassHeader} fill={imgHeader.fill} />
                )}
              </div>
              <div
                aria-label={ariaLabelHeader}
                data-test-id={ariaLabelHeader}
                className={headerTextStyle || classes('header-text')}
              >
                {header}
              </div>
            </div>
          )}
          {closeButton}
        </div>

        <div className={bodyClass || classes('body')}>{children}</div>

        {shouldRenderFooter && footerActionsFragment}
      </Modal2>
    );
  }

  _close = () => {
    this._lastIsOpen = false;
    this.props.onRequestClose();
  };
}

export default Modal;
