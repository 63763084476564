/* eslint react/no-find-dom-node: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import domAlign from 'dom-align';
import _ from 'lodash';
import BEM from '../../bem';

const positionedContextMenuClasses = BEM.with('PositionedContextMenu');

const DOM_ALIGN_PROPS_BY_POSITION = {
  top: {
    points: ['bc', 'tc'],
  },
  left: {
    points: ['cr', 'cl'],
  },
  bottom: {
    points: ['tc', 'bc'],
  },
  right: {
    points: ['cl', 'cr'],
  },
  topinnerleft: {
    points: ['tl', 'tl'],
  },
  bottominnerleft: {
    points: ['tl', 'bl'],
  },
  bottomright: {
    points: ['tl', 'cr'],
  },
  bottomleft: {
    points: ['tr', 'cl'],
  },
};

export default class PositionedContextMenu extends Component {
  static propTypes = {
    defaultShow: PropTypes.bool,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    position: PropTypes.oneOf([
      'bottom',
      'left',
      'right',
      'top',
      'topinnerleft',
      'bottominnerleft',
      'bottomright',
      'bottomleft',
    ]),
  };

  static defaultProps = {
    defaultShow: false,
    offsetX: 0,
    offsetY: 0,
    position: 'top',
  };

  state = {
    show: this.props.defaultShow,
    flippedY: false,
    flippedX: false,
  };

  constructor(...args) {
    super(...args);
    this._windowResized = _.throttle(this._windowResized, 50);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  componentWillUnmount() {
    this._windowResized.cancel();
    if (this.state.show) window.removeEventListener('resize', this._windowResized);
  }

  render() {
    if (!this.state.show) return <div />;

    let { position } = this.props;
    const { flippedY, flippedX } = this.state;

    if (position === 'top' && flippedY) position = 'bottom';
    else if (position === 'bottom' && flippedY) position = 'top';
    else if (position === 'left' && flippedX) position = 'right';
    else if (position === 'right' && flippedX) position = 'left';

    const menu = React.Children.only(this.props.children);

    return React.cloneElement(menu, {
      ..._.omit(this.props, 'position', 'defaultShow', 'children'),
      position,
      className: classNames(menu.props.className, positionedContextMenuClasses()),
    });
  }

  show(relativeTo) {
    if (this.state.show) return;
    this._relativeTo = relativeTo;
    window.addEventListener('resize', this._windowResized);

    this.setState({ show: true }, () => {
      this.reposition(relativeTo);
    });
  }

  hide() {
    if (this.state.show) window.removeEventListener('resize', this._windowResized);
    this._relativeTo = null;
    this.setState({ show: false });
  }

  reposition(relativeTo = this._relativeTo) {
    const { offsetX, offsetY, position } = this.props;

    const align = domAlign(ReactDOM.findDOMNode(this), relativeTo, {
      offset: [offsetX, offsetY],
      overflow: {
        adjustX: true,
        adjustY: true,
      },
      ...DOM_ALIGN_PROPS_BY_POSITION[position],
    });
    this.setState({
      flippedY: align.overflow.flippedY,
      flippedX: align.overflow.flippedX,
    });

    this._relativeTo = relativeTo;
  }

  _windowResized = () => {
    this.reposition(this._relativeTo);
  };
}
