import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { useInfiniteSearchResults, useScrollListener } from '../../hooks';
import { PatientReferenceCard } from '../PatientContext';
import generateExpirationTime from '../../utils/generateExpirationTime';
import { GroupForm, GroupMembers, GroupOptions } from './';

const classes = BEM.with('GroupEditor');
const RECIPIENT_PICKER_HEIGHT = 34;
const MEMBER_HEIGHT = 33;
const BUTTONS_HEIGHT = 50;

function GroupEditor({
  ensureMembersWithIds,
  findGroup,
  group,
  isGroupAlertsAllowed,
  resetGroupEditor,
  syncGroupMembers,
}) {
  const { canLoadMore, scrollContainerRef, updateOptions } =
    useInfiniteSearchResults(syncGroupMembers);

  const [fixedButtons, setFixedButtons] = useState(false);
  const [scrollBarOffset, setScrollBarOffset] = useState(0);
  const groupMembersRef = useRef();
  const initialFocusedElement = useRef();
  const metaType = group?.metadata?.meta_type;
  let expirationFragment;

  useEffect(() => {
    const distanceFromBottom = groupMembersRef.current.getBoundingClientRect().bottom;
    const distanceFromBottomAfterAdd =
      distanceFromBottom + RECIPIENT_PICKER_HEIGHT + MEMBER_HEIGHT + BUTTONS_HEIGHT;
    const fixedButtons = distanceFromBottomAfterAdd >= window.innerHeight;
    if (fixedButtons) {
      setFixedButtons(fixedButtons);
    }

    initialFocusedElement.current = document.activeElement;
  }, [groupMembersRef, initialFocusedElement]);

  const handleScroll = useCallback(() => {
    setScrollBarOffset(
      scrollContainerRef.current.offsetWidth - scrollContainerRef.current.clientWidth
    );
    const groupMembersScrollBottom =
      groupMembersRef.current.getBoundingClientRect().bottom -
      (fixedButtons ? 0 : 70) -
      window.innerHeight;
    if (groupMembersScrollBottom > 0) {
      setFixedButtons(true);
    } else {
      setFixedButtons(false);
    }
  }, [groupMembersRef, fixedButtons, scrollContainerRef]);

  useScrollListener(scrollContainerRef.current, handleScroll);

  useEffect(() => {
    updateOptions();
    resetGroupEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetGroupEditor]);

  useEffect(() => {
    async function updateGroup() {
      // TODO: Can remove once OASIS-747 is completed
      if (isGroupAlertsAllowed && metaType === 'alert_conversation') {
        await ensureMembersWithIds(group.id, group.memberIds);
        await findGroup(group.id);
      }
    }
    updateGroup();
  }, [isGroupAlertsAllowed, metaType, ensureMembersWithIds, group.id, group.memberIds, findGroup]);

  const returnFocus = () => {
    const focusElement = initialFocusedElement.current;
    const canFocus = focusElement && focusElement.focus;
    canFocus && focusElement.focus();
  };

  if (group && group.expireAt !== undefined) {
    const expireInDays = generateExpirationTime(group.expireAt);

    expirationFragment = (
      <div className={classes('expiration-detail')}>
        This conversation will expire in {expireInDays}
      </div>
    );
  }

  return (
    <div className={classes()} ref={scrollContainerRef}>
      <div>
        <GroupForm group={group} />
        {group?.patientContextId && (
          <PatientReferenceCard type={'editor'} patientContextId={group.patientContextId} />
        )}
        <GroupOptions group={group} />
        <GroupMembers
          fixedButtons={fixedButtons}
          returnFocus={returnFocus}
          scrollBarOffset={scrollBarOffset}
          setGroupMembers={groupMembersRef}
          isLoading={canLoadMore}
        />
        {expirationFragment}
        <div className={classes('bottom-div')} />
      </div>
    </div>
  );
}

GroupEditor.propType = {
  resetGroupEditor: PropTypes.func.isRequired,
  group: propTypes.group.isRequired,
  syncGroupMembers: PropTypes.func.isRequired,
  isGroupAlertsAllowed: PropTypes.bool,
};

export default mobxInjectSelect({
  groupEditorStore: ['resetGroupEditor', 'syncGroupMembers'],
  messengerStore: ['isGroupAlertsAllowed'],
  groupStore: ['findGroup', 'ensureMembersWithIds'],
})(GroupEditor);
