import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import BEM from '../bem';
import { UserAvatar } from './';

const classes = BEM.with('PatientSearchResultHeader');

class PatientSearchResultHeader extends Component {
  static propTypes = {
    avatarSize: PropTypes.number,
    entity: PropTypes.object.isRequired,
    isSingleRecipient: PropTypes.bool,
  };

  static defaultProps = {
    avatarSize: 25,
    isSingleRecipient: false,
  };

  render() {
    const { avatarSize, entity, isSingleRecipient } = this.props;
    const { displayName, isPatientContact, patient } = entity;
    const { dob, mrn, gender, user: patientBot } = patient;
    let titleFragment;

    if (isPatientContact) {
      titleFragment = (
        <span className={classes('relation')}>{'(' + entity.patientContact.relation + ')'}</span>
      );
    } else {
      titleFragment = <span className={classes('relation')}>(Patient)</span>;
    }

    return (
      <div className={classes()}>
        {!isSingleRecipient && (
          <UserAvatar className={classes('patient-avatar')} size={avatarSize} user={entity} />
        )}
        <div className={classes('name-container', { isCentered: isSingleRecipient === false })}>
          <div className={classes('name', { isCentered: isSingleRecipient === false })}>
            {displayName}
            {titleFragment}
          </div>
          {isSingleRecipient && (
            <div className={classes('patient-detail-container')}>
              <div className={classes('patient-name')}>{patientBot.displayName}</div>
              <div className={classes('patient-detail-container')}>
                {`MRN ${mrn} | DOB ${dob} | ${gender}`}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(PatientSearchResultHeader);
