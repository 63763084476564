const DATE_FORMAT = 'MMM D, YYYY';
const DATE_FORMAT_WITH_DAY = 'ddd, MMM D, YYYY';
const DATE_FORMAT_WITH_DAY_AND_TIME = 'ddd, MMM D, YYYY [at] h:mm A';
const MAX_DAYS = 180;
const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
const SERVER_TIME_FORMAT = 'HH:mm';
const TIME_FORMAT = 'h:mm A';

const REPEAT_SCHEDULE_TO_SERVER_VALUE = {
  Daily: 'daily',
  Weekly: 'weekly',
  'Bi-weekly': 'biweekly',
  Monthly: 'monthly',
};

const SERVER_VALUE_TO_REPEAT_SCHEDULE = {
  biweekly: 'Bi-weekly',
  weekly: 'Weekly',
  daily: 'Daily',
  monthly: 'Monthly',
};

const NONE_SELECTED_TEMPLATE = {
  id: 'noneSelected',
  title: 'None Selected (Custom Message)',
};

const formats = {
  DATE_FORMAT_WITH_DAY_AND_TIME,
  DATE_FORMAT_WITH_DAY,
  DATE_FORMAT,
  MAX_DAYS,
  NONE_SELECTED_TEMPLATE,
  REPEAT_SCHEDULE_TO_SERVER_VALUE,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT,
  SERVER_VALUE_TO_REPEAT_SCHEDULE,
  TIME_FORMAT,
};

export default formats;
