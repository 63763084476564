import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import styled from 'styled-components';
import { Scrollbars as UpstreamScrollbars } from 'tt-react-custom-scrollbars';
import BEM from '../bem';

const classes = BEM.with('Scrollbars');

class ScrollbarWrapper extends Component {
  _style = null;

  render() {
    const { children, height, scrollbarsRef, width, upstreamStyle = {}, ...restProps } = this.props;
    if (!this._style || this._style.height !== height || this._style.width !== width) {
      this._style = {
        position: 'absolute',
        height,
        width,
      };
    }

    return (
      <div className={classes()} style={this._style}>
        <UpstreamScrollbars style={upstreamStyle} ref={scrollbarsRef} {...restProps}>
          {children}
        </UpstreamScrollbars>
      </div>
    );
  }
}

const StyledScrollbars = styled(ScrollbarWrapper)`
  > div:nth-child(3) {
    ${(props) =>
      props.thumbMarginTop &&
      `
      margin-top: ${props.thumbMarginTop}px;
    `}
  }
`;

class Scrollbars extends Component {
  static propTypes = {
    autoHide: PropTypes.bool,
    children: PropTypes.node,
    onUpdate: PropTypes.func,
    scrollOverflowAmount: PropTypes.number,
    shouldScrollOverflow: PropTypes.bool,
    thumbMarginTop: PropTypes.number,
  };

  static defaultProps = {
    autoHide: true,
    height: '100%',
    shouldScrollOverflow: false,
    scrollOverflowAmount: 100,
    width: '100%',
  };

  state = {
    isScrollable: false,
  };

  _style = null;

  render() {
    const { autoHide, children, scrollOverflowAmount, customStyle, ...restProps } = this.props;
    const { isScrollable } = this.state;
    const newStyle = isScrollable ? { marginBottom: scrollOverflowAmount } : null;
    if (!shallowequal(this._style, newStyle)) {
      this._style = newStyle;
    }
    if (customStyle) {
      this._style = customStyle;
    }

    return (
      <StyledScrollbars
        autoHide={autoHide}
        autoHideTimeout={750}
        scrollbarsRef={this._setScrollbars}
        thumbMinSize={100}
        onUpdate={this._onUpdate}
        {...restProps}
      >
        <div className={classes('inner')} style={this._style}>
          {children}
        </div>
      </StyledScrollbars>
    );
  }

  _setScrollbars = (ref) => {
    this.scrollbars = ref;
  };

  _onUpdate = (scrollValues) => {
    if (!scrollValues) return;
    const { scrollOverflowAmount, onUpdate, shouldScrollOverflow } = this.props;
    let { scrollHeight } = scrollValues;
    const { clientHeight } = scrollValues;
    const { isScrollable } = this.state;

    if (shouldScrollOverflow) {
      if (isScrollable) scrollHeight -= scrollOverflowAmount;
      const newIsScrollable = clientHeight < scrollHeight;
      if (isScrollable !== newIsScrollable) {
        this.setState({ isScrollable: newIsScrollable });
      }
    }
    if (onUpdate) onUpdate(scrollValues);
  };

  getClientHeight() {
    return this.scrollbars.getClientHeight();
  }

  getScrollHeight() {
    return this.scrollbars.getScrollHeight();
  }

  getScrollTop() {
    return this.scrollbars.getScrollTop();
  }

  getValues() {
    return this.scrollbars.getValues();
  }

  scrollTop(top) {
    return this.scrollbars.scrollTop(top);
  }
}

export default Scrollbars;
