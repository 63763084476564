import React, { useEffect, useState } from 'react';
import { Tabs } from '@tigerconnect/web-component-library';
import {
  addDays,
  addHours,
  addMinutes,
  format,
  isBefore,
  parse,
  parseISO,
  startOfMinute,
} from 'date-fns';
import BasicModal from 'common/components/BasicModal';
import { actions, useAppDispatch } from 'redux-stores';
import { mobxInjectSelect } from 'common/utils';
import { Dropdown } from 'common/components/WebComponents';
import { ReactComponent as DropdownChevron } from 'common/images/dropdown-chevron.svg';
import BEM from 'common/bem';

const classes = BEM.with('CustomDndTimeModal');

const DND_TIME_LABELS = ['Pick a Time', 'Set a Timer'];
const MAX_DATE_LIMIT = 30;
const DATE_INPUT_FORMAT = 'E, PP';
const TIMER_MESSAGE = 'Timer minimum is 5 minutes.';

function buildOptions({
  type,
  maxValue,
  minValue,
}: {
  type: 'number' | 'date' | 'meridiem';
  maxValue?: number;
  minValue?: number;
}) {
  let options: string[] = [];
  if (type === 'number' && typeof maxValue === 'number' && typeof minValue === 'number') {
    let currValue = minValue;
    const range = maxValue - minValue + 1;
    for (let idx = 0; idx < range; idx++) {
      options[idx] = currValue.toString().padStart(2, '0');
      currValue++;
    }
  } else if (type === 'date') {
    const today = new Date();
    for (let idx = 0; idx < MAX_DATE_LIMIT + 1; idx++) {
      options[idx] = format(addDays(today, idx), DATE_INPUT_FORMAT);
    }
  } else if (type === 'meridiem') {
    options = ['AM', 'PM'];
  }
  return options;
}

function TimeInput({
  label,
  maxValue,
  minValue,
  type = 'number',
  formData,
  field,
  setFormValue,
}: {
  label?: string;
  maxValue?: number;
  minValue?: number;
  type?: 'number' | 'date' | 'meridiem';
  formData: CustomDndTimeFormData;
  field: string;
  setFormValue: (field: string, value: string) => void;
}) {
  const optionsArr = buildOptions({ type, maxValue, minValue });
  const [isOpen, setIsOpen] = useState(false);

  function handleSelect(value: string) {
    return () => {
      setFormValue(field, value);
      setIsOpen(false);
    };
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function determineWidth() {
    switch (type) {
      case 'date':
        return '190px';
      case 'meridiem':
        return '60px';
      case 'number':
        return '55px';
      default:
        return '0px';
    }
  }

  return (
    <div className={classes('timeInput-container')}>
      <div className={classes('timeInput-label')}>{label}</div>
      <div className={classes('timeInput-input-container')}>
        <div
          className={classes('timeInput-input')}
          style={{ width: determineWidth() }}
          onClick={handleOpen}
          data-test-id={`${field}-input`}
        >
          <div style={{ flex: '1 1' }}>{formData[field as keyof CustomDndTimeFormData]}</div>
          <div>
            <DropdownChevron />
          </div>
        </div>
        {isOpen && (
          <div className={classes('timeInput-dropdown-container')}>
            <Dropdown triggerHandler={() => setIsOpen(false)} maxHeight="200px">
              <div className={classes('timeInput-dropdown')}>
                {optionsArr.map((num) => {
                  return (
                    <div
                      className={classes('timeInput-dropdown-item')}
                      key={num}
                      onClick={handleSelect(num)}
                      data-test-id={`${num}-option`}
                    >
                      {num}
                    </div>
                  );
                })}
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
}

type CustomDndTimeModalProps = {
  isOpen: boolean;
};

type MobxProps = {
  update: ({ dnd, dndExpireAt }: { dnd: boolean; dndExpireAt?: string }) => Promise<void>;
  logPendoAnalytics: (data: { tracker: { name: string; props?: Record<string, unknown> } }) => void;
};

type CustomDndTimeFormData = {
  hour: string;
  minutes: string;
  meridiem: string;
  date: string;
  timerHours: string;
  timerMinutes: string;
};

const ERROR_STATE = {
  none: '',
  expired: 'The selected time has passed. Please select a new time.',
} as Record<string, string>;

function CustomDndTimeModal({
  isOpen,
  update,
  logPendoAnalytics,
}: CustomDndTimeModalProps & MobxProps) {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(DND_TIME_LABELS[0]);
  const [errorState, setErrorState] = useState('none');

  const [formData, setFormData] = useState<CustomDndTimeFormData>({
    hour: '01',
    minutes: '00',
    meridiem: 'AM',
    date: format(new Date(), DATE_INPUT_FORMAT),
    timerHours: '00',
    timerMinutes: '00',
  });

  const isTimerNotValid = formData.timerHours === '00' && formData.timerMinutes < '05';

  useEffect(() => {
    if (isOpen) {
      const now = new Date();
      setFormData({
        hour: format(now, 'hh'),
        minutes: format(now, 'mm'),
        meridiem: format(now, 'a'),
        date: format(now, DATE_INPUT_FORMAT),
        timerHours: '00',
        timerMinutes: '00',
      });
    }
    setErrorState('none');
  }, [isOpen]);

  function closeModal() {
    dispatch(actions.setModal(undefined));
  }

  function calcExpireTime() {
    let now = startOfMinute(new Date());

    if (selectedTab === DND_TIME_LABELS[0]) {
      const { hour, minutes, meridiem, date } = formData;
      now = parse(`${hour} ${minutes} ${meridiem} ${date}`, 'hh mm a E, PP', now);
    } else if (selectedTab === DND_TIME_LABELS[1]) {
      const { timerHours, timerMinutes } = formData;
      now = addMinutes(now, Number(timerMinutes));
      now = addHours(now, Number(timerHours));
    }

    return now.toISOString();
  }

  function validateTime() {
    const dndExpireAt = calcExpireTime();
    const compareTime = startOfMinute(addMinutes(new Date(), 5));
    return isBefore(parseISO(dndExpireAt), compareTime) ? '' : dndExpireAt;
  }

  async function handleSubmit() {
    const dndExpireAt = validateTime();
    if (!dndExpireAt && selectedTab === DND_TIME_LABELS[0]) {
      setErrorState('expired');
      return;
    }
    await update({
      dnd: true,
      dndExpireAt,
    });
    logPendoAnalytics({
      tracker: {
        name: 'Admin + Settings | Settings - Unavailable Custom Confirm',
        props: {
          UnavailableCustomTimeType: selectedTab === DND_TIME_LABELS[0] ? 'Date Time' : 'Timer',
        },
      },
    });
    closeModal();
  }

  function handleTab(label: string) {
    setSelectedTab(label);
  }

  function setFormValue(field: string, value: string) {
    setFormData({ ...formData, [field]: value });
  }

  return (
    <BasicModal
      ariaLabelCancelButton="CustomDndTime Cancel"
      ariaLabelCloseButton="CustomDndTime Close"
      ariaLabelHeader="CustomDndTime Header"
      ariaLabelSubmitButton="CustomDndTime Submit"
      headerText="Make Me Unavailable Until"
      isOpen={isOpen}
      onClose={closeModal}
      onSubmit={handleSubmit}
      submitText="APPLY"
      hasCloseButton
      submitDisabled={!validateTime()}
      type={'empty'}
      size={'medium'}
      useWCL
    >
      <div className={classes()}>
        <Tabs
          clickHandler={handleTab}
          labels={DND_TIME_LABELS}
          defaultTab={selectedTab}
          theme="secondary"
          align="center"
        />
        <div className={classes('input-container')}>
          {selectedTab === DND_TIME_LABELS[0] && (
            <>
              <TimeInput
                setFormValue={setFormValue}
                formData={formData}
                field="hour"
                label="Time"
                minValue={1}
                maxValue={12}
              />
              :
              <TimeInput
                field="minutes"
                setFormValue={setFormValue}
                formData={formData}
                minValue={0}
                maxValue={59}
              />
              <TimeInput
                field="meridiem"
                setFormValue={setFormValue}
                formData={formData}
                type="meridiem"
              />
              <span style={{ marginLeft: '20px' }}>
                <TimeInput
                  field="date"
                  setFormValue={setFormValue}
                  formData={formData}
                  type="date"
                  label="Date"
                />
              </span>
            </>
          )}
          {selectedTab === DND_TIME_LABELS[1] && (
            <>
              <TimeInput
                setFormValue={setFormValue}
                formData={formData}
                field="timerHours"
                label="Hours"
                minValue={0}
                maxValue={24}
              />
              :
              <TimeInput
                field="timerMinutes"
                setFormValue={setFormValue}
                formData={formData}
                label="Mins"
                minValue={0}
                maxValue={59}
              />
              {isTimerNotValid && <p className={classes('timer-message')}>{TIMER_MESSAGE}</p>}
            </>
          )}
        </div>
        {selectedTab === DND_TIME_LABELS[0] && (
          <div className={classes('error-text')}>{ERROR_STATE[errorState]}</div>
        )}
      </div>
    </BasicModal>
  );
}

export default mobxInjectSelect<CustomDndTimeModalProps, MobxProps>({
  userStore: ['update'],
  trackerStore: ['logPendoAnalytics'],
})(CustomDndTimeModal);
