import { useEffect, useState } from 'react';
import { TrackMessage } from '../../../models/enums';
import useVideoContext from '../useVideoContext/useVideoContext';
import { useAppState } from '../../state';

const processMessage = async (data, { getUserInfo, localParticipant, sendDataMessage } = {}) => {
  let message;
  try {
    message = JSON.parse(data);
  } catch (err) {
    console.error(err);
  }

  if (!message) return;
  if (!localParticipant.dataTracks.size) return;

  if (message.type === TrackMessage.REQUEST_TYPE) {
    const { payload } = message;

    switch (message.requestType) {
      case TrackMessage.GET_USER_INFO:
        if (payload.userIds) {
          const participantsInfo = await Promise.all(
            payload.userIds.map(async (userId) => {
              const user = await getUserInfo(userId);
              const { avatarUrl, displayName, id, isPatient, isPatientContact } = user;

              return {
                avatarUrl,
                displayName,
                id,
                isPatient,
                isPatientContact,
              };
            })
          );

          sendDataMessage({
            type: TrackMessage.RESPONSE_TYPE,
            requestType: message.requestType,
            payload: {
              usersInfo: participantsInfo,
            },
          });
        }

        break;
      default:
    }
  }
};

export default function usePublications(participant) {
  const [publications, setPublications] = useState([]);
  const {
    sendDataMessage,
    room: { localParticipant },
  } = useVideoContext();
  const { getUserInfo } = useAppState();

  useEffect(() => {
    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.tracks.values()));

    /*
      Filtering is a temporary solution, we need to figure out what is the real issue with
      rendering 2 audio and 2 video tags for local participant
    */
    const publicationAdded = (publication) => {
      setPublications((prevPublications) => {
        const prevPublicationsSet = new Set(prevPublications);
        return prevPublicationsSet.has(publication)
          ? prevPublications
          : [...prevPublications, publication];
      });
    };

    const publicationRemoved = (publication) =>
      setPublications((prevPublications) => prevPublications.filter((p) => p !== publication));

    const trackSubscribed = (track) => {
      if (track.kind === 'data') {
        track.on('message', (data) =>
          processMessage(data, { getUserInfo, localParticipant, sendDataMessage })
        );
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.off('trackPublished', publicationAdded);
      participant.off('trackSubscribed', trackSubscribed);
      participant.off('trackUnpublished', publicationRemoved);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participant]);

  return publications;
}
