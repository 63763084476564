import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import PatientCareAction from './PatientCareAction';
import PatientCareHeader from './PatientCareHeader';
import PatientCareItem from './PatientCareItem';

const classes = BEM.with('PatientCareCard');

const PATIENT_CARE_COMPONENT = {
  ACTION: PatientCareAction,
  HEADER: PatientCareHeader,
  ITEM: PatientCareItem,
  TEXT: null, // NOTE: Server does not support this type yet
};

class PatientCareCard extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
  };

  render() {
    const { createdAt, patientCareCard } = this.props.message;
    const careCardComponents = [];
    const outerComponents = [];

    for (const component of patientCareCard) {
      const { action_type: actionType, type } = component;

      const isOuterComponent = type === 'ACTION' && actionType === 'ADD_MEMBERS';

      if (isOuterComponent) {
        outerComponents.push(this._renderComponent(component));
      } else {
        careCardComponents.push(this._renderComponent({ ...component, createdAt }));
      }
    }

    return (
      <div className={classes()}>
        <div className={classes('card')}>{careCardComponents}</div>
        {outerComponents}
      </div>
    );
  }

  _renderComponent = (component) => {
    const PatientCareComponent = PATIENT_CARE_COMPONENT[component.type];

    if (PatientCareComponent) {
      return <PatientCareComponent key={component.value} component={component} />;
    }
  };
}

export default observer(PatientCareCard);
