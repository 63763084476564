import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RosterFilterBarButton, RosterFilterMenu } from './';
import { ReactComponent as DownArrowSvg } from 'common/images/down-arrow.svg';
import BEM from 'common/bem';
import propTypes from 'common/propTypes';
import { UnreadBadgeCount } from 'common/components';
import { mobxInjectSelect } from 'common/utils';
import { ContextMenu } from 'common/components/ContextMenu';

const classes = BEM.with('RosterFilterBar');

const MENU_OFFSET_X = -6;
const MENU_OFFSET_Y = 3;

class RosterFilterBar extends Component {
  static propTypes = {
    currentOrganization: propTypes.organization,
    currentUser: propTypes.user.isRequired,
    filterUnreadCount: PropTypes.number.isRequired,
    filterUnreadPriorityCount: PropTypes.number.isRequired,
    getAdminRolesInOrganization: PropTypes.func.isRequired,
    getUnselectedUnreadCounts: PropTypes.func.isRequired,
    hideDnd: PropTypes.bool.isRequired,
    inactiveRosterUnreadCount: PropTypes.number.isRequired,
    inactiveRosterUnreadPriorityCount: PropTypes.number.isRequired,
    selectedFilter: PropTypes.string.isRequired,
    selectedFilterBar: PropTypes.string.isRequired,
    setFilterBar: PropTypes.func.isRequired,
    unreadAlertsCount: PropTypes.number.isRequired,
  };

  state = {
    menuTarget: null,
  };

  render() {
    const {
      currentOrganization,
      currentUser,
      filterUnreadCount,
      filterUnreadPriorityCount,
      getAdminRolesInOrganization,
      getUnselectedUnreadCounts,
      hideDnd,
      selectedFilter,
      selectedFilterBar,
      unreadAlertsCount,
    } = this.props;
    const { menuTarget } = this.state;
    const showAlerts = !!currentOrganization && currentOrganization.showAlerts;
    const showPatientCare = !!currentOrganization && currentOrganization.showPatientCare;
    const showDnd = currentUser.dnd && !hideDnd;
    const { isTeamAdmin = false } = getAdminRolesInOrganization();
    const numButtons = [
      true, // Inbox / filters
      showAlerts && selectedFilter === 'Inbox', // Alerts
      showPatientCare && selectedFilter === 'Inbox', // Care Team
      isTeamAdmin && selectedFilter === 'Teams', // New Team +
    ].filter(Boolean).length;
    const rosterButtons = [];

    let { inactiveRosterUnreadCount, inactiveRosterUnreadPriorityCount } = this.props;
    let contextMenu,
      showPriorityButton,
      showUnreadCount,
      unreadCount,
      unreadOrArrowFragment,
      unselectedPatientCareUnreadCount,
      unselectedPatientCareUnreadPriorityCount,
      unselectedRosterUnreadCount,
      unselectedRosterUnreadPriorityCount;
    if (showAlerts && selectedFilterBar === 'Alerts') {
      const unselectedUnreadCounts = getUnselectedUnreadCounts();
      unselectedPatientCareUnreadCount = unselectedUnreadCounts.patientCareUnreadCount;
      unselectedPatientCareUnreadPriorityCount =
        unselectedUnreadCounts.patientCareUnreadPriorityCount;
      unselectedRosterUnreadCount = unselectedUnreadCounts.rosterUnreadCount;
      unselectedRosterUnreadPriorityCount = unselectedUnreadCounts.rosterUnreadPriorityCount;

      showUnreadCount = unselectedRosterUnreadCount > 0 || unselectedPatientCareUnreadCount > 0;
      showPriorityButton = unselectedRosterUnreadPriorityCount > 0;
      unreadCount = 0;
      inactiveRosterUnreadCount = 0;
      inactiveRosterUnreadPriorityCount = 0;
    } else if (showPatientCare && selectedFilterBar === 'Right') {
      showUnreadCount = inactiveRosterUnreadCount > 0;
      unreadCount = inactiveRosterUnreadCount;
      showPriorityButton = inactiveRosterUnreadPriorityCount > 0;
    } else if ((showAlerts || showPatientCare) && selectedFilter !== 'Inbox') {
      showUnreadCount = filterUnreadCount > 0 || inactiveRosterUnreadCount > 0;
      unreadCount = filterUnreadCount + inactiveRosterUnreadCount;
      showPriorityButton = filterUnreadPriorityCount > 0 || inactiveRosterUnreadPriorityCount > 0;

      if (showAlerts) {
        unreadCount += unreadAlertsCount || 0;
      }
    } else {
      showUnreadCount = filterUnreadCount > 0;
      unreadCount = filterUnreadCount;
      showPriorityButton = filterUnreadPriorityCount > 0;
    }

    if (
      (showUnreadCount && (unselectedRosterUnreadCount > 0 || unreadCount > 0)) ||
      showPriorityButton
    ) {
      unreadOrArrowFragment = (
        <UnreadBadgeCount
          className={classes('unread-count')}
          count={unselectedRosterUnreadCount || unreadCount}
          priority={showPriorityButton}
          usePriorityButton={true}
        />
      );
    } else {
      unreadOrArrowFragment = (
        <span className={classes('image-container')}>
          <DownArrowSvg aria-label="Roster Filter Bar" className={classes('image')} />
        </span>
      );
    }

    if (selectedFilterBar === 'Left') {
      contextMenu = (
        <ContextMenu
          event="click"
          offsetX={MENU_OFFSET_X}
          offsetY={MENU_OFFSET_Y}
          position="bottominnerleft"
          relativeTo={menuTarget}
          softClick={false}
          menu={<RosterFilterMenu />}
        >
          <div ref={this._setMenuTarget} className={classes('filter-container')}>
            <span>{selectedFilter}</span>
            {unreadOrArrowFragment}
          </div>
        </ContextMenu>
      );
    } else {
      contextMenu = (
        <div ref={this._setMenuTarget} className={classes('filter-container')}>
          <span>{selectedFilter}</span>
          {unreadOrArrowFragment}
        </div>
      );
    }

    rosterButtons.push(
      <RosterFilterBarButton
        key="roster"
        dataTestId="rosterTab"
        isSelected={selectedFilterBar === 'Left'}
        onClick={this._switchFilterBar('Left')}
        showUnreadCount={showUnreadCount}
        useSelectedLine={selectedFilterBar === 'Left' && numButtons === 3}
      >
        {contextMenu}
      </RosterFilterBarButton>
    );

    if (showAlerts && selectedFilter === 'Inbox') {
      const isSelected = selectedFilterBar === 'Alerts';

      rosterButtons.push(
        <RosterFilterBarButton
          key="alerts"
          dataTestId="alertsTab"
          isSelected={isSelected}
          onClick={this._switchFilterBar('Alerts')}
          showUnreadCount={showUnreadCount}
          useSelectedLine={selectedFilterBar === 'Alerts' && numButtons === 3}
        >
          <span>Alerts</span>
          {!isSelected && unreadAlertsCount > 0 && (
            <UnreadBadgeCount
              className={classes('unread-count')}
              count={unreadAlertsCount}
              usePriorityButton={true}
            />
          )}
        </RosterFilterBarButton>
      );
    }

    if (showPatientCare && selectedFilter === 'Inbox') {
      const showPriorityButton =
        unselectedPatientCareUnreadPriorityCount > 0 || inactiveRosterUnreadPriorityCount > 0;
      let unreadFragment;

      if (
        (unselectedPatientCareUnreadCount > 0 || inactiveRosterUnreadCount > 0) &&
        selectedFilterBar !== 'Right'
      ) {
        unreadFragment = (
          <UnreadBadgeCount
            className={classes('unread-count')}
            count={unselectedPatientCareUnreadCount || inactiveRosterUnreadCount}
            priority={showPriorityButton}
            usePriorityButton={true}
          />
        );
      }
      rosterButtons.push(
        <RosterFilterBarButton
          key="care-team"
          dataTestId="careTeamTab"
          isSelected={selectedFilterBar === 'Right'}
          onClick={this._switchFilterBar('Right')}
          showUnreadCount={showUnreadCount}
          useSelectedLine={selectedFilterBar === 'Right' && numButtons === 3}
        >
          <span>Care Team</span>
          {unreadFragment}
        </RosterFilterBarButton>
      );
    }

    return (
      <div className={classes()}>
        <div
          className={classes('filter', {
            spacingBelowFilterBar: (showDnd && numButtons === 3) || selectedFilterBar === 'Alerts',
          })}
        >
          {rosterButtons}
        </div>
      </div>
    );
  }

  _setMenuTarget = (menuTarget) => {
    this.setState({ menuTarget });
  };

  _switchFilterBar = (filter) => {
    const { setFilterBar } = this.props;
    return () => setFilterBar(filter);
  };
}

export default mobxInjectSelect({
  alertStore: ['unreadAlertsCount'],
  messengerStore: ['getAdminRolesInOrganization'],
  rosterStore: [
    'filterUnreadCount',
    'filterUnreadPriorityCount',
    'getUnselectedUnreadCounts',
    'inactiveRosterUnreadCount',
    'inactiveRosterUnreadPriorityCount',
    'selectedFilter',
    'selectedFilterBar',
    'setFilterBar',
  ],
  sessionStore: ['currentUser'],
  userStore: ['hideDnd'],
})(RosterFilterBar);
