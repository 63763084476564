// @ts-nocheck
import { isEqual } from 'lodash';
import * as errors from '../errors';
import { isPhone, normalizePhone } from '../utils/phone';
import BaseService from './BaseService';

const RESOLVABLE_MODELS = ['patient', 'user'];

enum CSVUploadTypes {
  AAR = 'aar',
  BROADCAST = 'broadcastList',
}

type CSVUploadType = keyof typeof CSVUploadTypes;

export default class PatientsService extends BaseService {
  async create({ contacts, dob, firstName, lastName, mrn, organizationId, phoneNumber, gender }) {
    if (!mrn) throw new errors.ValidationError('mrn', 'required');
    if (!firstName) throw new errors.ValidationError('firstName', 'required');
    if (!lastName) throw new errors.ValidationError('lastName', 'required');
    if (!phoneNumber) throw new errors.ValidationError('phoneNumber', 'required');
    if (!isPhone(phoneNumber))
      throw new errors.ValidationError('phoneNumber', 'not a valid format');
    if (!dob) throw new errors.ValidationError('dob', 'required');
    if (!/^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/.test(dob))
      throw new errors.ValidationError('dob', 'not a valid format of YYYY-MM-DD');
    if (!gender) throw new errors.ValidationError('gender', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    if (contacts && contacts.length) {
      checkContactsAreValid(contacts);
    }

    const res = await this.host.api.patients.create({
      contacts: validateContactPhoneNumbers(contacts),
      dob,
      firstName,
      gender,
      lastName,
      mrn,
      organizationId,
      phoneNumber: normalizePhone(phoneNumber),
    });

    return this.host.models.User.inject(res.entity);
  }

  async update({
    currentEditVersion,
    dob,
    firstName,
    patientId,
    lastName,
    organizationId,
    phoneNumber,
    gender,
  }) {
    if (!currentEditVersion) throw new errors.ValidationError('currentEditVersion', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!patientId) throw new errors.ValidationError('patientId', 'required');

    const res = await this.host.api.patients.update({
      dob,
      editVersion: updateEditVersion(currentEditVersion),
      firstName,
      gender,
      patientId,
      lastName,
      organizationId,
      phoneNumber: normalizePhone(phoneNumber),
    });

    return this.host.models.User.inject(res.entity);
  }

  async delete(id: string, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    const patient = this.host.models.Patient.get(id);

    if (patient) {
      this.host.models.Patient.eject(patient);
    }
    await this.host.api.patients.delete(id, organizationId);
  }

  async createContact({ firstName, lastName, organizationId, patientId, phoneNumber, relation }) {
    if (!patientId) throw new errors.ValidationError('patientId', 'required');
    if (!firstName) throw new errors.ValidationError('firstName', 'required');
    if (!lastName) throw new errors.ValidationError('lastName', 'required');
    if (!phoneNumber) throw new errors.ValidationError('phoneNumber', 'required');
    if (!isPhone(phoneNumber))
      throw new errors.ValidationError('phoneNumber', 'not a valid format');
    if (!relation) throw new errors.ValidationError('relation', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const patient = await this.host.api.patients.createContact({
      firstName,
      lastName,
      organizationId,
      patientId,
      phoneNumber: normalizePhone(phoneNumber),
      relation,
    });
    return this.host.models.User.inject(patient.entity);
  }

  async updateContact({
    contactId,
    currentEditVersion,
    firstName,
    lastName,
    organizationId,
    patientId,
    phoneNumber,
    relation,
  }) {
    if (!currentEditVersion) throw new errors.ValidationError('currentEditVersion', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!patientId) throw new errors.ValidationError('patientId', 'required');
    if (!contactId) throw new errors.ValidationError('contactId', 'required');
    if (!firstName) throw new errors.ValidationError('firstName', 'required');
    if (!lastName) throw new errors.ValidationError('lastName', 'required');
    if (!phoneNumber) throw new errors.ValidationError('phoneNumber', 'required');
    if (!isPhone(phoneNumber))
      throw new errors.ValidationError('phoneNumber', 'not a valid format');
    if (!relation) throw new errors.ValidationError('relation', 'required');

    const patient = await this.host.api.patients.updateContact({
      contactId,
      editVersion: updateEditVersion(currentEditVersion),
      firstName,
      lastName,
      organizationId,
      patientId,
      phoneNumber: normalizePhone(phoneNumber),
      relation,
    });

    return this.host.models.User.inject(patient.entity);
  }

  async deleteContact({ contactId, organizationId, patientId }) {
    if (!contactId) throw new errors.ValidationError('contactId', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!patientId) throw new errors.ValidationError('patientId', 'required');

    await this.host.api.patients.deleteContact({ contactId, patientId, organizationId });

    const contact = this.host.models.PatientContact.get(`patientContact:${contactId}`);
    if (contact) {
      this.host.models.PatientContact.eject(contact);
    }
  }

  async login({ accessToken, dateOfBirth, linkToken, sessions }) {
    this.host.emit('signingIn');

    const patientLoginResponse = await this.host.api.patients.login(
      linkToken,
      accessToken,
      dateOfBirth,
      sessions
    );
    const {
      api_key: apiKey,
      api_secret: apiSecret,
      group_id: groupId,
      organization_id: organizationId,
    } = patientLoginResponse;

    this.host.configure({ events: { ...this.host.config.events, singleConversation: groupId } });

    this.host._setAuth({
      key: apiKey,
      secret: apiSecret,
    });
    this.host.setDefaultOrganization(organizationId);

    const currentUser = await this.host.users.findMe({ ignoreNotFound: true });
    this.host._setCurrentUser(currentUser);

    this.host.emit('signedIn', currentUser, this.host.getAuth());

    this.host.messages._fetchAttachmentTokens();

    return patientLoginResponse;
  }

  async loginCheck({ linkToken, sessions }) {
    return this.host.api.patients.loginCheck(linkToken, sessions);
  }

  async requestAccessToken(linkToken) {
    return this.host.api.patients.requestAccessToken(linkToken);
  }

  async csvUpload(organizationId, file) {
    return this.host.api.patients.csvUpload(organizationId, file);
  }

  async asyncCsvUpload(organizationId, file, filename) {
    return this.host.api.patients.asyncCsvUpload(organizationId, file, filename);
  }

  async getPendingCsvUploads(organizationId) {
    return this.host.api.patients.getPendingCsvUploads(organizationId);
  }

  async integrationsCsvUpload(
    organizationId: string,
    file: string,
    email: string,
    fileName: string,
    csvType: CSVUploadType,
    { listId, Timezone }: { listId?: string; Timezone?: string }
  ) {
    const { key, secret } = this.host.getAuth();
    const verificationToken = btoa(
      JSON.stringify({ OrgToken: organizationId, ApiKey: key, ApiSecret: secret })
    );

    try {
      const { url } = await this.host.api.patients.integrationsCsvUpload({
        additionalParams: { listId, Timezone },
        email,
        fileName,
        objectKey: `${organizationId}/${csvType}`,
        verificationToken,
      });

      await fetch(url, {
        body: file,
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'x-amz-meta-RecipientEmail': email,
          ...(listId ? { 'x-amz-meta-ListId': listId } : null),
          ...(Timezone ? { 'x-amz-meta-Timezone': Timezone } : null),
        },
      });
    } catch (error) {
      console.error('Error uploading csv', error);
    }
  }

  async broadcastListCsvUpload(organizationId, file, listId, email) {
    const { name: listName } = this.host.distributionLists.getById(listId);
    await this.integrationsCsvUpload(
      organizationId,
      file,
      email,
      `${listName}.csv`,
      CSVUploadTypes.BROADCAST,
      { listId }
    );
  }

  async appointmentReminderCsvUpload(organizationId, file, timezone, email) {
    await this.integrationsCsvUpload(
      organizationId,
      file,
      email,
      'appointments.csv',
      CSVUploadTypes.AAR,
      {
        Timezone: timezone,
      }
    );
  }

  async getWhiteLabel(organizationId) {
    return this.host.api.patients.getWhiteLabel(organizationId);
  }

  async setWhiteLabel(organizationId, payload) {
    return this.host.api.patients.setWhiteLabel(organizationId, payload);
  }

  _createPatientGroupMetadata(entity) {
    const { displayName, patient, id } = entity;
    const { dob, gender, mrn, phoneNumber, smsOptedOut } = patient;

    return {
      feature_service: 'patient_messaging',
      is_patient_contact: 'false',
      patient_dob: dob,
      patient_gender: gender,
      patient_id: id,
      patient_mrn: mrn,
      patient_name: displayName,
      phone_number: phoneNumber,
      sms_opted_out: smsOptedOut,
    };
  }

  _createPatientContactGroupMetadata(entity) {
    const { patient, patientContact, id } = entity;
    const { dob, gender, mrn, user } = patient;
    const { phoneNumber, relation, smsOptedOut } = patientContact;

    return {
      feature_service: 'patient_messaging',
      is_patient_contact: 'true',
      patient_contact_id: id,
      patient_dob: dob,
      patient_gender: gender,
      patient_id: user.id,
      patient_mrn: mrn,
      patient_name: user.displayName,
      phone_number: phoneNumber,
      relation_name: relation,
      sms_opted_out: smsOptedOut,
    };
  }

  getAll() {
    return this.host.models.Patient.getAll();
  }
  getById(id: string) {
    id = this.__resolvePatientId(id);
    return this.host.models.Patient.get(`patient:${id}`);
  }

  __resolvePatientId = (patientId: string | Object) => {
    if (typeof patientId !== 'string') {
      if (!RESOLVABLE_MODELS.includes(patientId.$entityType)) return patientId;
      patientId = this._resolveModelId(patientId);
    }
    return patientId.replace('patient:', '');
  };
}

export function checkContactsAreValid(contacts) {
  if (contacts.length > 2) throw new errors.ValidationError('contacts count', 'larger than 2');

  for (let i = 0; i < contacts.length; i++) {
    const { firstName, lastName, phoneNumber, relation } = contacts[i];
    if (!firstName) throw new errors.ValidationError('contact firstName', 'required');
    if (!lastName) throw new errors.ValidationError('contact lastName', 'required');
    if (!phoneNumber) throw new errors.ValidationError('contact phoneNumber', 'required');
    if (!relation) throw new errors.ValidationError('contact relation', 'required');
  }

  if (contacts.length === 2 && isEqual(contacts[0], contacts[1])) {
    throw new errors.ValidationError('contacts being unique', 'required');
  }
}

export function updateEditVersion(version) {
  if (version === undefined || version === null) {
    throw new errors.ValidationError('version', 'of type string or number');
  }
  if (typeof version === 'string') {
    return parseInt(version) + 1;
  } else return version + 1;
}

export function validateContactPhoneNumbers(contacts = []) {
  return contacts.map(({ phoneNumber, ...rest }) => ({
    ...rest,
    phoneNumber: normalizePhone(phoneNumber),
  }));
}
