// @ts-nocheck
export default function (store, { host }) {
  const Product = store.defineModel('product', {
    defaultValues: {
      activeProcessingEvents: null,
      conversationCount: 0,
      organizations: null,
      rolesOptingIn: null,
      unreadAlertsCount: 0,
      unreadCount: 0,
      unreadPriorityCount: 0,
      unreadVWRCount: 0,
    },

    transientAttrs: [
      'conversationCount',
      'organizations',
      'unreadAlertsCount',
      'unreadCount',
      'unreadPriorityCount',
      'unreadVWRCount',
    ],

    afterAssignment(entity) {
      const { id } = entity;
      entity.productRoles = host.userRoles.getByProductId(id);
      if (entity.activeProcessingEvents === null) entity.activeProcessingEvents = [];
      if (entity.organizations === null) entity.organizations = [];
      if (entity.rolesOptingIn === null) entity.rolesOptingIn = [];
    },
  });

  return Product;
}
