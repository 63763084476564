export const PendoPathNameLabels = {
  'Analytics Patient Data': 'PatientData',
  'Analytics Patient Video': 'PatientVideo',
  automatedMessages: 'AutomatedMessages',
  'Broadcast lists': 'CreateProviderBroadcastList',
  'Create aar_variables Message Template': 'MessageTemplates/Dynamic/CreateMessageTemplate',
  'Create all Schedule Message Template': 'ScheduledMessages/Admin',
  'Create automated Schedule Message Template': 'ScheduledMessages/Automated',
  'Create personal Schedule Message Template': 'ScheduledMessages/Personal',
  'Create general Message Template': 'MessageTemplates/General/CreateMessageTemplate',
  'Create New Waiting Room': 'VirtualWaitingRoom/CreateNewWaitingRoom',
  'Create personal Message Template': 'MessageTemplates/Personal/CreateMessageTemplate',
  'Create Provider Broadcast List': 'CreateProviderBroadcastList',
  'Create Patient Broadcast List': 'PatientBroadcastLists/CreatePatientBroadcastList',
  'Customer Support': 'CustomerSupport',
  customLabeling: 'CustomLabeling',
  Forums: 'Forums',
  Insights: 'Insights',
  'Login Password': 'LoginPassword',
  'Login Username': 'LoginUsername',
  Messages: 'Messenger',
  messageTemplates: 'MessageTemplates/Personal',
  'messageTemplates/Dynamic': 'MessageTemplates/Dynamic',
  'messageTemplates/General': 'MessageTemplates/General',
  'messageTemplates/Personal': 'MessageTemplates/Personal',
  'Org Settings': 'OrgSettings',
  Organizations: 'Organizations',
  patientBroadcastLists: 'PatientBroadcastLists',
  patientsList: 'PatientsList',
  'Patient Data': 'PatientsList/PatientData',
  'Patient Network User Settings': 'PatientNetworkUserSettings',
  'Profile Settings': 'ProfileSettings',
  'Patient Video': 'PatientVideo',
  Roles: 'Roles',
  scheduledMessages: 'ScheduledMessages/Personal',
  'scheduledMessages/Admin': 'ScheduledMessages/Admin',
  'scheduledMessages/Automated': 'ScheduledMessages/Automated',
  'scheduledMessages/Personal': 'ScheduledMessages/Personal',
  Transactions: 'Transactions',
  Trends: 'Trends',
  Users: 'Users',
  virtualWaitingRoom: 'VirtualWaitingRoom',
  'VoIP Calling': 'VoIPCalling',
};

export const PendoParentSettingsPathNameLabels = {
  Analytics: 'Analytics',
  Messages: 'Messenger',
  Roles: 'Roles',
  'Roles & Teams': 'Roles&Teams',
  Settings: 'Settings',
  'Patient Network Messenger': 'PatientNetworkMessenger',
  'Patient Settings': 'PtSettings',
  'Virtual Waiting': 'VirtualWaiting',
  'Broadcast Lists': 'BroadcastLists',
  'Patient Directory': 'PatientDirectory',
};

export default PendoPathNameLabels;
