import { ProviderBLAction } from './actions';
import { ProviderBLState } from './context';

export const providerBLReducer = (
  state: ProviderBLState,
  action: ProviderBLAction
): ProviderBLState => {
  switch (action.type) {
    case 'SET_PERMISSIONS': {
      const { isBLAdmin } = action.payload;
      return {
        ...state,
        isBLAdmin,
      };
    }
    // default:
    //   return ((_: never): never => {
    //     throw new Error('Reducer is not exhaustive');
    //   })(action);
  }
};
