import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { mobxInjectSelect } from '../../utils';
import { DotsIndicator, GroupAvatar, GroupName, UserAvatar, UserName } from '../';
import { ReactComponent as CloseButtonSvg } from '../../images/close-button.svg';
import PrintMessageList from './PrintMessageList';

const classes = BEM.with('PrintMode');

class PrintMode extends Component {
  static propTypes = {
    closePrintMode: PropTypes.func.isRequired,
    currentConversation: propTypes.conversation.isRequired,
    isLoadingPrintConversation: PropTypes.bool.isRequired,
    isProviderNetwork: PropTypes.bool.isRequired,
    sendPrintLogs: PropTypes.func.isRequired,
  };

  render() {
    const {
      closePrintMode,
      currentConversation,
      isLoadingPrintConversation: isLoading,
      isProviderNetwork,
    } = this.props;

    const { counterParty, timeline } = currentConversation;
    const messages = timeline.filter(({ messageType }) => messageType === 'USER_SENT');

    return (
      <div className={classes()}>
        <div className={classes('header')}>
          {counterParty.$entityType === 'user' ? (
            <div className={classes('party-container')}>
              <UserAvatar user={counterParty} size={38} />
              <UserName className={classes('display-name')} user={counterParty} />
            </div>
          ) : (
            <div className={classes('party-container')}>
              <GroupAvatar group={counterParty} size={38} />
              <GroupName className={classes('display-name')} group={counterParty} />
            </div>
          )}
          <div className={classes('buttons-container')}>
            <button
              className={classes('print-button', { disabled: isLoading })}
              disabled={isLoading}
              onClick={this._print}
              type="button"
            >
              <span>{isProviderNetwork ? 'Print' : 'Export'}</span>
            </button>
            <button
              className={classes('close-button')}
              onClick={closePrintMode}
              aria-label="close-print-mode"
            >
              <CloseButtonSvg aria-hidden />
            </button>
          </div>
        </div>

        <div className={classes('pages')}>
          {isLoading ? (
            <div className={classes('dots-container')}>
              <DotsIndicator size={13} />
              <div className={classes('dots-text')}>
                Please wait while we load this conversation
              </div>
            </div>
          ) : (
            <PrintMessageList messages={messages} />
          )}
        </div>
      </div>
    );
  }

  _print = () => {
    const { currentConversation, sendPrintLogs } = this.props;

    sendPrintLogs(currentConversation);
    // On Safari, the print dialog does not show up immediately after window.print() is invoked
    // It may be the case that pending requests block the print dialog from showing
    // window.stop() seems to pause those requests for a bit and allow the print dialog to be shown
    if (window.stop) {
      window.stop();
    }
    window.print();
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  messengerStore: ['closePrintMode', 'isLoadingPrintConversation'],
  networkStore: ['isProviderNetwork'],
  trackerStore: ['sendPrintLogs'],
})(PrintMode);
