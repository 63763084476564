import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import BEM from '../bem';

const classes = BEM.with('SimpleMenu');

function SimpleMenu({ button, handleOpen, menu }) {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu && handleOpen) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  return (
    <div className={classes()}>
      <div onClick={() => setShowMenu(!showMenu)}>{button}</div>
      {showMenu && (
        <OutsideClickHandler onOutsideClick={() => setShowMenu(false)}>
          <div className={classes('menu')}>{menu}</div>
        </OutsideClickHandler>
      )}
    </div>
  );
}

SimpleMenu.propTypes = {
  button: PropTypes.element.isRequired,
  handleOpen: PropTypes.func,
  menu: PropTypes.element.isRequired,
};

export default SimpleMenu;
