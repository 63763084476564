import { get } from 'lodash';

const userAgent = typeof window === 'undefined' ? 'serverSide' : window.navigator.userAgent;

export const IS_BRAVE = userAgent !== 'serverSide' && !!get(window, 'clientInformation.brave');
export const IS_OPERA = userAgent.includes('OPR/');
export const IS_EDGE = userAgent.includes('Edge/');
export const IS_CHROME =
  (userAgent.includes('Chrome/') || userAgent.includes('Chromium/')) &&
  !IS_BRAVE &&
  !IS_OPERA &&
  !IS_EDGE;
export const IS_SAFARI =
  userAgent.includes('Safari/') && !IS_CHROME && !IS_BRAVE && !IS_OPERA && !IS_EDGE;
export const IS_IE11 = userAgent.includes('Trident/');
export const IS_CHROMIUM_EDGE = !IS_EDGE && userAgent.includes('Edg/');
export const IS_ELECTRON = userAgent.includes('Electron/');
export const IS_FIREFOX = userAgent.includes('Firefox/');
