import { get } from 'lodash';
// @ts-ignore
import Camelizer from '../utils/Camelizer';
import {
  AnswerType,
  DisabledParticipantsType,
  EndType,
  JoinResponse,
  InviteType,
  StartResponseType,
  StartType,
  StateType,
  LogType,
} from '../types/Calls';
import BaseAPI from './BaseAPI';

const LOG_PAGE_SIZE = 10;

export default class CallsAPI extends BaseAPI {
  async start(payload: StartType) {
    const res = await this.httpClient.post<StartResponseType['data']>('/v2/voip_call', {
      data: Camelizer.underscoreObject(payload),
      headers: {
        'TT-X-Organization-Key': payload.organizationId,
      },
    });
    const disabledParticipants: DisabledParticipantsType = get(
      res,
      'data.disabled_participants',
      {}
    );
    const camelizedData = Camelizer.camelizeObject(res.data);
    camelizedData.disabledParticipants = disabledParticipants;

    return camelizedData;
  }

  async end(
    roomName: string,
    organizationId: string,
    { callId, payload, reason, isVwrFeatureEnabled = false }: EndType
  ) {
    const disabledParticipants: DisabledParticipantsType = get(payload, 'disabledParticipants', {});
    const underscoredData = Camelizer.underscoreObject({ payload, reason, callId });
    underscoredData.payload.disabled_participants = disabledParticipants;

    await this.httpClient.post(`/v2/voip_call/${roomName}/end`, {
      data: underscoredData,
      headers: {
        'TT-X-Organization-Key': organizationId,
        ...(isVwrFeatureEnabled ? { 'tt-x-features': 'vwr' } : {}),
      },
    });

    return;
  }

  async join(
    roomName: string,
    organizationId: string,
    { isVwrFeatureEnabled } = { isVwrFeatureEnabled: false }
  ) {
    const res = await this.httpClient.get<JoinResponse['data']>(`/v2/voip_call/${roomName}`, {
      headers: {
        'TT-X-Organization-Key': organizationId,
        ...(isVwrFeatureEnabled ? { 'tt-x-features': 'vwr' } : {}),
      },
    });

    return res.data;
  }

  async answer(
    roomName: string,
    organizationId: string,
    { callId, payload, isVwrFeatureEnabled = false }: AnswerType
  ) {
    const disabledParticipants: DisabledParticipantsType = get(payload, 'disabledParticipants', {});
    const reason = 'answered';
    const underscoredData = Camelizer.underscoreObject({
      callId,
      payload: { ...payload, reason, callId },
      reason,
      type: 'call_answered',
      roomName,
    });
    underscoredData.payload.disabled_participants = disabledParticipants;
    const res = await this.httpClient.post(`/v2/voip_call/${roomName}/answer`, {
      data: underscoredData,
      headers: {
        'TT-X-Organization-Key': organizationId,
        ...(isVwrFeatureEnabled ? { 'tt-x-features': 'vwr' } : {}),
      },
    });

    return res.data;
  }

  async invite(
    roomName: string,
    organizationId: string,
    { callId, payload, recipients }: InviteType
  ) {
    const disabledParticipants: DisabledParticipantsType = get(payload, 'disabledParticipants', {});
    const underscoredData = Camelizer.underscoreObject({ callId, payload, recipients });
    underscoredData.payload.disabled_participants = disabledParticipants;

    await this.httpClient.post(`/v2/voip_call/${roomName}/add_participants`, {
      data: underscoredData,
      headers: { 'TT-X-Organization-Key': organizationId },
    });
  }

  async state(roomName: string, organizationId: string, { callId, payload }: StateType) {
    const disabledParticipants: DisabledParticipantsType = get(payload, 'disabledParticipants', {});
    const underscoredData = Camelizer.underscoreObject({ callId, payload: { ...payload, callId } });
    underscoredData.payload.disabled_participants = disabledParticipants;

    await this.httpClient.post(`/v2/voip_call/${roomName}/state`, {
      data: underscoredData,
      headers: { 'TT-X-Organization-Key': organizationId },
    });
  }

  async log({ network, organizationId, page }: LogType) {
    const query: { network: string; size: number; page?: number } = {
      network,
      size: LOG_PAGE_SIZE,
    };
    if (page) query.page = page;
    const res = await this.httpClient.get('/v2/call_log', {
      // @ts-nocheck
      headers: { 'TT-X-Organization-Key': organizationId },
      query,
    });

    return res.data;
  }

  async getPatientLink(roomName: string, memberId: string, organizationId: string) {
    const res = await this.httpClient.get(`/v2/patient_link/${roomName}/${memberId}`, {
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return res.data;
  }

  async getConnectedParticipants(accessToken: string, organizationId: string) {
    const res = await this.httpClient.get('/v2/patient/call/:id', {
      query: { render_connected_participants: 'true' },
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: { id: accessToken },
    });
    return res.data;
  }
}
