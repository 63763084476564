// @ts-nocheck
export default function (store, { host }) {
  const EscalationExecutionChange = store.defineModel('escalationExecutionChange', {
    parseAttrs(attrs) {
      if ('actionTime' in attrs && typeof attrs.actionTime === 'string') {
        attrs.actionTime = new Date(attrs.actionTime);
      }

      return attrs;
    },
  });

  return EscalationExecutionChange;
}
