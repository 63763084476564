// @ts-nocheck
export default function (store, { host }) {
  const VirtualWaitingRoom = store.defineModel('virtualWaitingRoom', {
    parseAttrs(attrs) {
      return attrs;
    },

    relations: {
      belongsTo: {
        organization: { type: 'organization', foreignKey: 'organizationId' },
      },
    },
  });

  return VirtualWaitingRoom;
}
