import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import BEM from '../bem';
import { ReactComponent as SideIconVirtualWaitingSvg } from '../../widgets/messenger/images/side-icon-VirtualWaiting.svg';
import { CallStatus, CallStatuses } from '../../models/enums/CallStatus';
import { mobxInjectSelect } from '../utils';
import TCClient from '../../client';

import { ReactComponent as VideoSvg } from '../images/icon-video.svg';
import { Organization } from '../../types';

const classes = BEM.with('VirtualWaitingRoomCallCard');

type vwrCallInvite = {
  room_name?: string;
  twilio_room_name?: string;
  visit_id?: string;
  vwr_call_id?: string;
  vwr_room_id?: string;
  status?: string;
};

type Message = {
  body: string;
  createdAt: string;
  senderId: string;
  vwrCallInvite?: vwrCallInvite;
  conversation: { featureService?: string };
};

type Payload = {
  roomName: string;
};

type VirtualWaitingRoomCallCardProps = {
  message: Message;
  status: CallStatus;
};

type MobxProps = {
  currentCall: Payload;
  currentOrganization: Organization;
  currentOrganizationId: string;
  currentUserId: string;
  tryToJoinVoipCall: (shouldAnswerWithCamOn: boolean, option: { roomName: string }) => void;
};

const VirtualWaitingRoomCallCard: React.FC<VirtualWaitingRoomCallCardProps & MobxProps> = ({
  currentCall,
  currentOrganization,
  currentOrganizationId,
  currentUserId,
  message,
  status,
  tryToJoinVoipCall,
}) => {
  const {
    twilio_room_name: roomName,
    visit_id: visitId,
    vwr_call_id: callId,
    vwr_room_id: roomId,
  } = message?.vwrCallInvite || {};

  const { createdAt, senderId } = message;

  const disableButtons =
    senderId === currentUserId ||
    !roomName ||
    (currentCall && currentCall?.roomName === roomName) ||
    status === CallStatuses.COMPLETE ||
    status === CallStatuses.EXPIRE;

  const EndButtonText =
    status === CallStatuses.COMPLETE
      ? 'Call Completed'
      : status === CallStatuses.EXPIRE
      ? 'Call Expired'
      : 'Mark Call As Ended';

  const onEndAction = async () => {
    if (status === CallStatuses.INVITE) {
      try {
        await TCClient.virtualWaitingRoom.markCallAsCompleted(currentOrganizationId, {
          roomId,
          visitId,
          callId,
          target: 'provider',
          reason: 'COMPLETED',
        });
        // TODO if any vwr call on going clear it
      } catch {
        console.error('Failed to complete the call');
      }
    }
  };

  const onJoinCall = () => {
    if (roomName) tryToJoinVoipCall(true, { roomName });
  };

  return (
    <div className={classes()}>
      <div className={classes('header-container')}>
        <div className={classes('header-icon')}>
          <SideIconVirtualWaitingSvg />
        </div>
      </div>
      <div className={classes('header-text')} title={message.body}>
        {message.body}
      </div>
      <div className={classes('call-buttons')}>
        <button
          className={classNames(
            classes('call-button'),
            classes({ status, disabled: disableButtons })
          )}
          onClick={() => onJoinCall()}
          disabled={disableButtons || currentCall !== null}
        >
          <div className={classes('video-icon')}>
            <VideoSvg />
          </div>
          <span>Join Video Call</span>
        </button>
      </div>

      <button
        className={classNames(classes('end-button'), classes({ status, disable: disableButtons }))}
        disabled={disableButtons}
        onClick={onEndAction}
      >
        {EndButtonText}
      </button>
      <div className={classes('time')}>{moment(createdAt).format('h:mm A')}</div>
    </div>
  );
};

export default mobxInjectSelect<VirtualWaitingRoomCallCardProps, MobxProps>({
  callStore: ['currentCall', 'tryToJoinVoipCall'],
  messengerStore: ['currentOrganization', 'currentOrganizationId'],
  sessionStore: ['currentUserId'],
})(VirtualWaitingRoomCallCard);
