export default function passwordValidation(value, list) {
  const validatePassword = {};

  validatePassword['uppercase'] = /[A-Z]/.test(value);
  validatePassword['digits'] = /\d/.test(value);
  validatePassword['symbols'] = /\W/.test(value);
  validatePassword['min'] = value.length >= 8;

  if (list) {
    return validatePassword;
  } else {
    return (
      validatePassword['uppercase'] &&
      validatePassword['digits'] &&
      validatePassword['symbols'] &&
      validatePassword['min']
    );
  }
}
