// @ts-nocheck
import addEnsureEntity from './extensions/ensureEntity';

export default function (store, options) {
  const DistributionList = store.defineModel('distributionList', {
    replaceKeys: {
      avatar: 'avatarUrl',
      createdBy: 'createdById',
      displayName: 'name',
      organizationKey: 'organizationId',
      adSecurityGroup: 'securityGroupName',
      totalMembers: 'memberCount',
      updatedBy: 'updatedById',
    },

    skipCamelizationForKeys: ['metadata'],

    transientAttrs: ['$deleted'],

    defaultValues: {
      memberCount: 0,
    },

    afterAssignment(entity) {
      entity.displayName = entity.name;
    },

    relations: {
      belongsTo: {
        createdBy: { type: 'user', foreignKey: 'createdById' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        updatedBy: { type: 'user', foreignKey: 'updatedById' },
      },
    },
  });

  addEnsureEntity(DistributionList, {
    finder: (...args) => options.host.distributionLists.find(...args),
    placeholderEntityDelayBeforeRefreshing() {
      return store.host.config.placeholderEntityDelayBeforeRefreshing;
    },
    placeholderEntityAllowLoading() {
      return store.host.config.placeholderEntityAllowLoading;
    },

    defaultPlaceholderAttrs: { name: 'Loading Distribution List...' },
    defaultNotFoundAttrs: { name: '<Distribution List Not Found>' },
  });

  return DistributionList;
}
