const TIGERPAGE_TOKENS = [
  'f3e06a6f-e509-4f65-9d8f-bd6fbafcba02',
  '999760f7-110b-4c9a-8d26-7520918896a5',
  'ccd0b6da-e5c8-40fe-9708-828fa8fef035',
  'e5ef47bd-6ab9-4ece-9b3e-aa477056d086',
];

export default function isTokenTigerPage(token: string) {
  if (TIGERPAGE_TOKENS.indexOf(token) > -1) {
    return true;
  }
}
