import React, { useState } from 'react';

import { CtaButton } from '../../shared-components/CtaButton/CtaButton';
import { Link } from '../../shared-components/Link/Link';
import { PageHeader } from '../../shared-components/PageHeader/PageHeader';
import { Table } from '../../shared-components/Table/Table';

import TCClient from '../../../client';

import styles from './ImportUsers.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

const { setUsersPageView } = actions;

export default function ImportUsers() {
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<unknown[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successfulEntries, setSuccessfulEntries] = useState(0);
  const [isErrorsTableShown, toggleIsErrorsTableShown] = useState(false);
  const [hasCompletedUpload, setHasCompletedUpload] = useState(false);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;
    const newFile = files?.length ? files[0] : null;
    if (newFile) {
      setFile(newFile);
    }
  };

  const handleFileSubmit = () => {
    async function submit() {
      if (isSubmitting && !file) return;
      try {
        setIsSubmitting(true);
        const { data } = await TCClient.adminUsers.csvUploadUsers({
          file,
          orgId: selectedOrganization.token,
        });
        const { error, successful } = data;
        if (error) {
          setErrors(error);
        } else {
          setErrors([]);
        }
        setSuccessfulEntries(successful);
      } catch (e) {
        console.error(e);
      } finally {
        setIsSubmitting(false);
        setHasCompletedUpload(true);
      }
    }

    submit();
  };
  return (
    <div className={styles.root}>
      <PageHeader label={'Users'} subLabel={'Add Users'} />
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div>
            <div className={styles.bold}>Upload CSV file</div>
            <input type={'file'} accept={'.csv'} className={styles.file} onChange={onFileChange} />
          </div>
          <div className={styles.buttonContainer}>
            <CtaButton label={'Submit'} primary onClick={handleFileSubmit} />
            <div className={styles.cancelContainer}>
              <Link
                onClick={() => {
                  dispatch(setUsersPageView({ selectedUsersPageView: 'users' }));
                }}
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.rightContainer}>
          {hasCompletedUpload ? (
            <>
              <div>{`${successfulEntries} Successful entries.`}</div>
              <div>{errors.length} Errors.</div>
              {errors.length > 0 && (
                <Link
                  onClick={() => {
                    toggleIsErrorsTableShown(!isErrorsTableShown);
                  }}
                >
                  <>{isErrorsTableShown ? 'Hide' : 'View'} Errors</>
                </Link>
              )}
            </>
          ) : (
            <>
              <div className={styles.bold}>Your CSV file should have the following formatting</div>
              <div>
                first_name, last_name, email_address, display_name (optional), password (optional),
                title (optional), department (optional)
              </div>
              <div className={styles.dashBox}>
                first_name, last_name, email_address, display_name, password, title, department
                <br />
                John, Stevens, john.stevens@mycompany.com,,, Director,
                <br />
                Anne, LEE, anne.lee@mycompany.com, alee, tempPass,, ISD
              </div>
              <Link
                download={'example.csv'}
                target="_blank"
                href={
                  window.location.origin +
                  window.location.pathname.replace('index.html', '') +
                  'media/import_users_template.csv'
                }
              >
                Download sample file
              </Link>
            </>
          )}
        </div>
      </div>
      {isErrorsTableShown && (
        <div className={styles.table}>
          <Table
            columns={[
              {
                field: 'firstName',
                headerName: 'first_name',
              },
              {
                field: 'lastName',
                headerName: 'last_name',
              },
              {
                field: 'emailAddress',
                headerName: 'email_address',
              },
              {
                field: 'displayName',
                headerName: 'display_name',
              },
              {
                field: 'password',
                headerName: 'password',
              },
              {
                field: 'title',
                headerName: 'title',
              },
              {
                field: 'department',
                headerName: 'department',
              },
              {
                field: 'reason',
                headerName: 'reason',
              },
            ]}
            // @ts-expect-error TODO
            rows={errors}
          />
        </div>
      )}
    </div>
  );
}
