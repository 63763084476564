import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';
import styled from 'styled-components';
import { Scrollbars } from 'tt-react-custom-scrollbars';
import AccessibleList from './AccessibleList';

class ScrollbarWrapper extends Component {
  _style = null;

  render() {
    const { children, height, width, ...restProps } = this.props;
    if (!this._lastStyle || this._lastStyle.height !== height || this._lastStyle.width !== width) {
      this._style = {
        position: 'absolute',
        height,
        width,
      };
    }

    return (
      <div style={this._style}>
        <Scrollbars {...restProps}>{children}</Scrollbars>
      </div>
    );
  }
}

const StyledScrollbars = styled(ScrollbarWrapper)`
  > div:nth-child(3) {
    ${(props) =>
      props.thumbMarginTop &&
      `
      margin-top: ${props.thumbMarginTop}px;
    `}
  }
`;

const LIST_STYLE = {
  overflowX: false,
  overflowY: false,
  outline: 'none',
};

class ScrollingList extends Component {
  static propTypes = {
    autoHide: PropTypes.bool,
    thumbMarginTop: PropTypes.number,
    focusableClasses: PropTypes.array,
    focusableChildrenClasses: PropTypes.array,
    accessibilityMode: PropTypes.bool,
    focusCentered: PropTypes.bool,
  };

  static defaultProps = {
    autoHide: true,
  };

  list = null;

  render() {
    const {
      autoHide,
      thumbMarginTop,
      focusableClasses,
      focusableChildrenClasses,
      accessibilityMode,
      focusCentered,
      ...listProps
    } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <StyledScrollbars
            autoHide={autoHide}
            autoHideTimeout={750}
            height={height}
            onScroll={this._handleScroll}
            thumbMarginTop={thumbMarginTop}
            thumbMinSize={100}
            width={width}
          >
            <AccessibleList
              focusableClasses={focusableClasses}
              focusableChildrenClasses={focusableChildrenClasses}
              focusStart={'first'}
              loop={false}
              accessibilityMode={accessibilityMode}
              focusCenteredOnScroll={focusCentered}
            >
              <List
                {...listProps}
                height={height}
                ref={this._setList}
                style={LIST_STYLE}
                width={width}
              />
            </AccessibleList>
          </StyledScrollbars>
        )}
      </AutoSizer>
    );
  }

  _handleScroll = ({ target }) => {
    const { scrollTop, scrollLeft } = target;
    const { Grid: grid } = this.list;
    grid.handleScrollEvent({ scrollTop, scrollLeft });
  };

  _setList = (ref) => {
    this.list = ref;
  };

  recomputeRowHeights = (...args) => this.list.recomputeRowHeights(...args);
}

export default ScrollingList;
