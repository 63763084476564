import roundWithPrecision from './roundWithPrecision';

export const DEFAULT_PRECISION = 3;

type TimeValues = number | null | undefined;

export default function getTimeDiffInSecondsRounded(
  startTimeMs: TimeValues,
  endTimeMs: TimeValues,
  { precision = DEFAULT_PRECISION }: { precision?: number } = {}
): number {
  if (startTimeMs === null && endTimeMs === null) {
    return 0;
  }
  if (
    startTimeMs === null ||
    startTimeMs === undefined ||
    endTimeMs === null ||
    endTimeMs === undefined
  ) {
    return -1;
  }
  if (startTimeMs > endTimeMs) {
    return -1;
  }

  const timeDiffSeconds = (endTimeMs - startTimeMs) / 1000;
  return roundWithPrecision(timeDiffSeconds, precision);
}
