// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import BaseService from './BaseService';

export default class UserRolesService extends BaseService {
  dispose() {
    reusePromise.clear(this._findAll);
  }

  @reusePromise()
  async findAll({ userId = this.host.currentUserId, organizationId } = {}) {
    this.host.requireUser();
    return this._findAll(userId, organizationId);
  }

  @reusePromise()
  async refreshAll({ userId = this.host.currentUserId, organizationId } = {}) {
    this.host.requireUser();
    reusePromise.clear(this._findAll);
    return this._findAll(userId, organizationId);
  }

  @reusePromise({
    memoize: true,
    serializeArguments: (args) => args[0],
  })
  async _findAll(userId: string, organizationId = this._pickOrganizationId()) {
    const results = await this.host.api.userRoles.findAll(userId, organizationId);
    const dictionary = { userRoles: [], productRoles: [] };

    results.forEach(({ organization, product }) => {
      if (organization) {
        const organizationId = organization.organization_id;
        const existingOrganization = this.host.organizations.getById(organizationId);
        const userRoles = this.host.models.UserRoles.inject({
          id: this._getOrganizationKey({ organizationId, userId }),
          userId,
          ...organization,
        });
        if (existingOrganization) {
          this.host.models.Organization.touch(existingOrganization);
        }
        dictionary.userRoles.push(userRoles);
      } else if (product) {
        const productId = product.product_id;
        const productRoles = this.host.models.ProductRoles.inject({
          id: this._getProductKey({ productId, userId }),
          userId,
          ...product,
        });
        this.host.models.Product.inject({ id: productId });
        dictionary.productRoles.push(productRoles);
      }
    });

    return dictionary;
  }

  _pickOrganizationId() {
    const organizations = this.host.organizations.getAll();
    // any organizationId works here, even nonexistent ones, but we still try to pick a meaningful one
    // if we can find it
    if (organizations.length === 0) return 'placeholder';
    return organizations[0].id;
  }

  getAllUserRoles() {
    return this.host.models.UserRoles.getAll();
  }

  getByOrganizationId(organizationId: string, { userId = this.host.currentUserId } = {}) {
    const id = this._getOrganizationKey({ organizationId, userId });
    return this.host.models.UserRoles.get(id);
  }

  _getOrganizationKey({
    organizationId,
    userId = this.host.currentUserId,
  }: {
    organizationId: string;
    userId?: string | null | undefined;
  }) {
    return `userRoles:${userId}:${organizationId}`;
  }

  getAllProductRoles() {
    return this.host.models.ProductRoles.getAll();
  }

  getByProductId(productId: string, { userId = this.host.currentUserId } = {}) {
    const id = this._getProductKey({ productId, userId });
    return this.host.models.ProductRoles.get(id);
  }

  _getProductKey({
    productId,
    userId = this.host.currentUserId,
  }: {
    productId: string;
    userId?: string | null | undefined;
  }) {
    return `productRoles:${userId}:${productId}`;
  }
}
