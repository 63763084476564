import { useState, useMemo, useEffect } from 'react';

import BEM from '../../../../common/bem';

import DotsIndicator from '../../DotsIndicator';

import { useLambdaFetch } from './useLambdaFetch';
import PoweredBy from './PoweredBy.png';
import { mobxInjectSelect } from 'common/utils';

const classes = BEM.with('GiphySelector');

type MobxProps = {
  isDarkModeOn: boolean;
};

type GiphySelectorProps = {
  send: (gifUrl: string) => void;
  close: () => void;
  query: string;
};

function GiphySelector({ send, close, query, isDarkModeOn }: GiphySelectorProps & MobxProps) {
  const [selectedGifIndex, setSelectedGifIndex] = useState(0);
  const [isLoadingNextGif, setIsLoadingNextGif] = useState(false);

  const options = useMemo(() => ({ body: { query, eventType: 'giphy' } }), [query]);
  const { isLoading, error, response } = useLambdaFetch(options);

  const hasNoResults = !isLoading && Array.isArray(response) && !response.length;
  const next = () => {
    if (isLoadingNextGif || !response || !Array.isArray(response)) return;
    const nextGif = selectedGifIndex === response?.length - 1 ? 0 : selectedGifIndex + 1;
    setIsLoadingNextGif(true);
    setSelectedGifIndex(nextGif);
    setTimeout(() => {
      setIsLoadingNextGif(false);
    }, 1000);
  };

  const sendGif = () => {
    if (isLoadingNextGif || !response || !Array.isArray(response)) return;
    const gifUrl = response[selectedGifIndex].images.original.url.split('?')[0];
    send && send(gifUrl);
    close();
  };

  useEffect(() => {
    if (isLoading) {
      setSelectedGifIndex(0);
    }
  }, [isLoading, setSelectedGifIndex]);

  return (
    <div className={classes()}>
      <div className={classes('title')}>Only visible to you</div>
      {!isLoading && !isLoadingNextGif && response && response?.length ? (
        <video
          autoPlay
          loop
          playsInline
          src={Array.isArray(response) ? response[selectedGifIndex]?.images.original.mp4 : ''}
          className={classes('gif')}
        />
      ) : hasNoResults ? (
        <div className={classes('no-results')}>No Results. Please close and try again.</div>
      ) : (
        <div className={classes('loading')}>
          <DotsIndicator color="#3080df" size={20} />
        </div>
      )}
      <div className={classes('action-bar')}>
        {!hasNoResults ? (
          <>
            <button
              className={classes('action-button', { send: true, isDisabled: isLoadingNextGif })}
              onClick={sendGif}
            >
              Send
            </button>
            <button
              className={classes('action-button', { next: true, isDisabled: isLoadingNextGif })}
              onClick={next}
              disabled={isLoadingNextGif}
            >
              Next
            </button>
          </>
        ) : null}
        <button className={classes('action-button', { close: true })} onClick={close}>
          Close
        </button>
        {error && <div>Please close and try again.</div>}
        <div className={classes('powered-by', { isDarkMode: isDarkModeOn })}>
          <img src={PoweredBy} alt="Powered By Giphy" />
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<GiphySelectorProps, MobxProps>({
  messengerStore: ['isDarkModeOn'],
})(GiphySelector);
