import EventEmitter from 'events';
import { action, observable, runInAction } from 'mobx';
import queue from 'emitter-queue';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import * as Sentry from '@sentry/react';

import {
  DEFAULT_CONTACTS_SEARCH_FIELDS,
  DEFAULT_SEARCH_FIELDS,
  DEFAULT_PATIENT_SEARCH_FIELDS,
  filterSearchResults,
  processSearchResults,
} from '../common/utils/searchResults';
import { updateEntitySortMap } from '../common/utils/searchParity';
import SearchTypes from '../models/enums/SearchTypes';

export default class SearchSidebarStore {
  @observable input = '';
  @observable isSearching = false;
  @observable.shallow searchResults = [];
  @observable noResults = false;
  @observable searchErrorOccurred = false;

  _scrollContinuation = null;
  _scrollCachedProps = null;

  entitySortOrg = null;
  entitySortMap = new Map();

  _searcher = null;
  events = queue(new EventEmitter());

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  _initializeSearcher = () => {
    this._searcher = Observable.fromEvent(this.events, 'search')
      .debounceTime(200)
      .switchMap(this._search)
      .subscribe(
        action(({ results = null, isNewQuery = true }) => {
          if (!results) {
            this.searchResults = [];
            return;
          }
          this.searchResults = isNewQuery
            ? results
            : results.length > 0 && this.searchResults.concat(results);
        })
      );
  };

  _disposeSearcher = () => {
    if (this._searcher) {
      this._searcher.unsubscribe();
      this._searcher = null;
      this.isSearching = false;
      this.searchResults = [];

      this._scrollContinuation = null;
      this._scrollCachedProps = null;
      this.noResults = false;
    }
  };

  @action('SearchSidebarStore.clearSearchResults') clearSearchResults = () => {
    const { conversationStore, networkStore } = this.stores;

    if (!networkStore.isProviderNetwork) {
      conversationStore.patientFilteredConversations = [];
      conversationStore.patientFilteredEntity = null;
      conversationStore.setPatientFilteredConversations = false;
    }

    this._disposeSearcher();
    this.input = '';
  };

  @action('SearchSidebarStore.search') search = (props) => {
    let { searchTypes } = props;
    const { organization, text = this.input } = props;
    const { forumsEnabled } = organization || {};
    const { conversationStore, networkStore, sessionStore } = this.stores;

    if (!networkStore.isProviderNetwork) {
      conversationStore.patientFilteredConversations = [];
      conversationStore.patientFilteredEntity = null;
      conversationStore.setPatientFilteredConversations = false;
    }

    if (!forumsEnabled && searchTypes.includes(SearchTypes.FORUM)) {
      searchTypes = searchTypes.filter((type) => type !== SearchTypes.FORUM);
    }

    sessionStore.resetAutoLogout();

    if (this.input !== text) this.input = text;
    if (!organization || !searchTypes || searchTypes.length === 0 || !text || text.trim() === '') {
      this._disposeSearcher();
    } else {
      runInAction(() => {
        this.searchErrorOccurred = false;
        this.isSearching = true;
      });
      if (!this._searcher) this._initializeSearcher();
      this.events.queue('search', { ...props, text: text.trim() });
    }
  };

  @action('SearchSidebarStore.scrollSearch') scrollSearch = () => {
    if (!this._scrollContinuation) return;
    runInAction(() => {
      this.searchErrorOccurred = false;
      this.isSearching = true;
    });
    return this.search({ ...this._scrollCachedProps, isNewQuery: false });
  };

  // Warning: Since this function is on a delay, caution is needed when relying on variables outside of this scope. The values may not be expected.
  @action('SearchSidebarStore._search')
  _search = async (props) => {
    const { excludeIds, organization, searchTypes, text, isNewQuery = true } = props;
    const { id: organizationId, isContacts, showTeams } = organization;
    const { messengerStore, networkStore, roleStore } = this.stores;

    const { isProviderNetwork } = networkStore;
    const { isPresenceFeatureFlagEnabled } = messengerStore;

    const searchFields = !isProviderNetwork
      ? DEFAULT_PATIENT_SEARCH_FIELDS
      : isContacts
      ? DEFAULT_CONTACTS_SEARCH_FIELDS
      : DEFAULT_SEARCH_FIELDS;

    runInAction(() => {
      if (isNewQuery) {
        this._scrollContinuation = null;
        this._scrollCachedProps = null;
        this.noResults = false;
      }
    });

    try {
      const { results, metadata } = await this.client.search.query({
        excludeIds,
        excludeReturnFields: true,
        excludeSelf: true,
        organizationId,
        query: { [searchFields]: text },
        sort: isPresenceFeatureFlagEnabled ? ['presenceStatus', 'displayName'] : ['displayName'],
        types: searchTypes,
        version: 'SEARCH_PARITY',
        ...(this._scrollContinuation && { continuation: this._scrollContinuation }),
      });

      if (!isContacts) {
        if (!metadata.entityOrder) {
          const e = new Error('No entityOrder in metadata');
          Sentry.captureException(e);
        }
        const currentOrganizationId = this.stores.messengerStore.currentOrganizationId;
        const { entitySortOrg, entitySortMap } = updateEntitySortMap({
          currentOrganizationId,
          entitySortMap: this.entitySortMap,
          entitySortOrg: this.entitySortOrg,
          isNewQuery,
          metadata,
        });
        this.entitySortOrg = entitySortOrg;
        this.entitySortMap = entitySortMap;
      }

      const { myRoleIds } = roleStore;
      const queryResults = this.entityStore.getOrSync(
        results
          .map(({ entity }) => entity)
          .filter(filterSearchResults(myRoleIds, { excludeTeams: !showTeams }))
      );

      const isPatientSearch = searchTypes.includes('patient_account');

      runInAction(() => {
        this._scrollContinuation = metadata.continuation;
        this._scrollCachedProps = props;
        if (metadata.totalHits === 0) {
          this.noResults = true;
        }
        this.isSearching = false;
      });

      return processSearchResults(queryResults, {
        input: text,
        isPatientSearch,
        organizationId,
        isNewQuery,
      });
    } catch (err) {
      runInAction(() => {
        this.searchErrorOccurred = true;
        this.isSearching = false;
      });
      console.log(err);
      return {};
    }
  };
}
