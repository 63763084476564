import React from 'react';

import AddUser from './AddUser';
import Users from './Users';
import User from './User';
import ImportUsers from './ImportUsers';
import { useAppSelector } from 'redux-stores';

export default function UsersRouter({
  onScrollCallBackRef,
}: {
  onScrollCallBackRef: React.RefObject<(e: React.UIEvent<HTMLElement>) => void>;
}) {
  const selectedUsersPageView = useAppSelector((state) => state.admin.selectedUsersPageView);
  const selectedOrganization = useAppSelector((state) => state.admin.selectedOrganization);

  switch (selectedUsersPageView) {
    case 'add':
      return <AddUser />;
    case 'import':
      return <ImportUsers />;
    case 'user':
      return <User />;
    case 'users':
    default:
      return (
        <Users
          onScrollCallBackRef={onScrollCallBackRef}
          key={selectedOrganization.token}
          selectedOrganization={selectedOrganization}
        />
      );
  }
}
