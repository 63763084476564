// @ts-nocheck
import { omit } from 'lodash';
import Camelizer from '../utils/Camelizer';
import BaseAPI from './BaseAPI';

interface SessionObject {
  account_token: string;
  session_token: string;
}

interface AdditionalBroadcastParams {
  listId: string;
  Timezone: string;
}

export default class PatientsAPI extends BaseAPI {
  async create(payload) {
    const res = await this.httpClient.post('/v5/patient', {
      data: Camelizer.underscoreObject(omit(payload, 'organizationId')),
      headers: { 'TT-X-Organization-Key': payload.organizationId },
    });

    return res.data;
  }

  async delete(id: string, organizationId: string) {
    const res = await this.httpClient.del('/v5/patient/:id', {
      urlParams: { id },
      headers: { 'TT-X-Organization-Key': organizationId },
    });
    return res.data;
  }

  async update(payload) {
    const res = await this.httpClient.post('/v5/patient/:id', {
      data: Camelizer.underscoreObject(omit(payload, ['organizationId', 'patientId'])),
      headers: { 'TT-X-Organization-Key': payload.organizationId },
      urlParams: { id: payload.patientId },
    });

    return res.data;
  }

  async createContact(payload) {
    const res = await this.httpClient.post('/v5/patient/:patient_id/contact', {
      data: Camelizer.underscoreObject(omit(payload, ['organizationId', 'patientId'])),
      headers: { 'TT-X-Organization-Key': payload.organizationId },
      urlParams: { patient_id: payload.patientId },
    });

    return res.data;
  }

  async deleteContact(payload) {
    const res = await this.httpClient.del('/v5/patient/:patient_id/contact/:contact_id', {
      urlParams: { contact_id: payload.contactId, patient_id: payload.patientId },
      headers: { 'TT-X-Organization-Key': payload.organizationId },
    });
    return res.data;
  }

  async updateContact(payload) {
    const res = await this.httpClient.post('/v5/patient/:patient_id/contact/:contact_id', {
      data: Camelizer.underscoreObject(omit(payload, ['organizationId', 'patientId', 'contactId'])),
      headers: { 'TT-X-Organization-Key': payload.organizationId },
      urlParams: { patient_id: payload.patientId, contact_id: payload.contactId },
    });

    return res.data;
  }

  async login(
    linkToken: string,
    accessToken?: string,
    dateOfBirth?: string,
    sessions?: SessionObject[]
  ) {
    const data = {
      want_condensed_replays: this.config.wantCondensedReplays,
      want_processing_events: this.config.wantCondensedReplays,
    };
    if (accessToken) {
      data.auth = {
        access_token: accessToken,
      };
      if (dateOfBirth) {
        data.auth.dob = dateOfBirth;
      }
    } else if (dateOfBirth) {
      data.auth = {
        dob: dateOfBirth,
      };
    }

    if (Array.isArray(sessions)) {
      data['session_tokens'] = Camelizer.underscoreObject(sessions);
    }

    const res = await this.httpClient.post('/v5/patient/login/:linkToken', {
      urlParams: { linkToken },
      data,
    });

    return res.data;
  }

  async loginCheck(linkToken: string, sessions?: SessionObject[]) {
    const data = {};

    if (Array.isArray(sessions)) {
      data['session_tokens'] = Camelizer.underscoreObject(sessions);
    }

    const res = await this.httpClient.post('/v5/patient/login_check/:linkToken', {
      urlParams: { linkToken },
      data,
    });

    return res.data;
  }

  async requestAccessToken(linkToken: string) {
    const res = await this.httpClient.post('/v5/patient/request_access_token/:linkToken', {
      urlParams: { linkToken },
      data: {},
    });

    return res.data;
  }

  async csvUpload(organizationId: string, file: string) {
    const res = await this.httpClient.post('/v5/patient/csv/upload', {
      data: file,
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return res.data;
  }

  async asyncCsvUpload(organizationId: string, file: string, filename: string) {
    const res = await this.httpClient.post('/v5/patient/ingestion/csv', {
      data: {
        file_name: filename,
        ignore_errors: true,
        update_existing: true,
        add_new: true,
      },
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    fetch(res.data.upload_destination, {
      body: file,
      method: 'PUT',
    });
  }

  async getPendingCsvUploads(organizationId) {
    const res = await this.httpClient.get('/v5/patient/ingestion/csv?status=pending', {
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return res.data;
  }

  async integrationsCsvUpload({
    additionalParams,
    email,
    fileName,
    objectKey,
    verificationToken,
  }: {
    additionalParams?: AdditionalBroadcastParams;
    email: string;
    fileName: string;
    verificationToken: string;
  }) {
    const res = await this.httpClient.post(`${this.host.integrationUrl}/csvUpload`, {
      data: { ...additionalParams, fileName, objectKey, RecipientEmail: email },
      headers: { 'verification-token': verificationToken },
      withCredentials: false,
    });

    return res.data;
  }

  async getWhiteLabel(organizationId) {
    const res = await this.httpClient.get('/v5/patient/white_label/:organizationId', {
      urlParams: { organizationId },
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return Camelizer.camelizeObject(res.data);
  }

  async setWhiteLabel(organizationId, payload) {
    const res = await this.httpClient.post('/v5/patient/white_label/:organizationId', {
      urlParams: { organizationId },
      headers: { 'TT-X-Organization-Key': organizationId },
      data: Camelizer.underscoreObject(payload),
    });

    return res;
  }
}
