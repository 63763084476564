import React, { useEffect, useState } from 'react';
import { chain, sortBy, partition } from 'lodash';
import moment from 'moment';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import { Appointment } from '../../../../types';

import { ReactComponent as PatientSvg } from '../../../images/default-avatar--patientCare.svg';
import { ReactComponent as DeleteButtonSvg } from '../../../images/delete_button.svg';
import { AppointmentList } from './';
import { UISliceState } from 'redux-stores/ui';
import { useAppSelector } from 'redux-stores';
import AccessibleList from 'common/components/AccessibleList';

const classes = BEM.with('PatientsList');

type PatientDataProps = {
  selectedPatientId: string;
  closePatientData?: () => void;
};

type MobxProps = {
  appointments: Appointment[];
  getUserById: (patientId: string) => {
    patient: { mrn: string; gender: string; dob: string };
    displayName: string;
  };
  openAppointmentModal: (appointment: Partial<Appointment>) => void;
  openModal: (name: string, options: unknown) => void;
  showAllAppointments: (selectedPatientId: string) => Promise<unknown[]>;
};

function PatientData({
  appointments,
  closePatientData,
  getUserById,
  openAppointmentModal,
  openModal,
  selectedPatientId,
  showAllAppointments,
}: PatientDataProps & MobxProps) {
  const [patientMrn, setPatientMrn] = useState('');
  const [patientDob, setPatientDob] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientDisplayName, setPatientDisplayName] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
  const [groupedList, setGroupedList] = useState({});
  const [isPastAppointments, setIsPastAppointments] = useState(false);
  const [apptsByType, setApptsByType] = useState<Appointment[][]>([]);

  const { accessibilityMode } = useAppSelector(({ ui }: { ui: UISliceState }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  const isPastAppointment = (appointment: Appointment) => {
    const { appointmentDate, appointmentTime, appointmentTimezone } = appointment;
    return (
      moment.tz(`${appointmentDate}T${appointmentTime}`, appointmentTimezone).valueOf() <
      moment.now()
    );
  };

  useEffect(() => {
    if (selectedPatientId.length !== 0) {
      const patientInformation = getUserById(selectedPatientId);
      const { patient } = patientInformation;
      setPatientMrn(patient.mrn);
      setPatientDob(patient.dob);
      setPatientGender(patient.gender);
      setPatientDisplayName(patientInformation.displayName);
    }
  }, [getUserById, selectedPatientId]);

  useEffect(() => {
    async function searchForAppointments() {
      await showAllAppointments(selectedPatientId);
      setHasLoaded(true);
    }
    searchForAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatientId]);

  const getMonth = (appointment: Appointment) => {
    return appointment.appointmentDate?.substr(0, 7);
  };

  useEffect(() => {
    if (appointments?.length > 0) {
      const groupedList = chain(appointments)
        .filter((a) => (isPastAppointments ? isPastAppointment(a) : !isPastAppointment(a)))
        .groupBy(getMonth)
        .value();
      setGroupedList(groupedList);
      setApptsByType(partition(appointments, isPastAppointment));
    } else {
      setGroupedList({});
    }
  }, [appointments, isPastAppointments]);

  const deleteAppointment = () => {
    if (itemsToDelete.length > 0)
      openModal('deleteAppointment', {
        itemsToDelete,
        patientId: selectedPatientId,
        setItemsToDelete,
      });
  };

  return (
    <div className={classes()}>
      <div className={classes('patient-admin-navigator')}>
        <div className={classes('patient-admin-navigator-data')}>
          <button
            className={classes('patient-list-title')}
            onClick={closePatientData}
            aria-label="Patient List"
          >
            PATIENT LIST
          </button>
          <span className={classes('patient-admin-navigator-breadcrumb')}> &gt; </span>
          <div className={classes('patient-name-info')}>{patientDisplayName}'S DATA</div>
        </div>
      </div>
      <div className={classes('patient-details-wrapper')}>
        <div className={classes('patient-details-holder')}>
          <div className={classes('patient-details-header')}>
            <div className={classes('patient-admin-data')}>Patient Data</div>
          </div>
          <div className={classes('patient-details')}>
            <PatientSvg className={classes('patient-avatar')} aria-hidden />
            <div className={classes('patient-name')}>
              <div className={classes('patient-display-name-container')}>
                <span className={classes('patient-display-name')} title={patientDisplayName}>
                  {patientDisplayName}
                </span>
                <span>(Patient)</span>
              </div>
              <div className={classes('patient-information')}>
                <div
                  title={patientMrn}
                  className={classes('patient-mrn')}
                >{`MRN ${patientMrn}`}</div>
                <div className={classes('patient-detailed-information', { isDob: true })}>
                  {`|  DOB ${patientDob} |`}
                </div>
                <div className={classes('patient-detailed-information', { isGender: true })}>
                  {`${patientGender}`}{' '}
                </div>
              </div>
            </div>
          </div>
          <div className={classes('appointments-info')}>Appointments</div>
          <div className={classes('appointments-available-container')}>
            {itemsToDelete.length > 0 && (
              <button
                className={classes('delete-appointment-button')}
                onClick={() => deleteAppointment()}
                aria-label="Delete appointment(s)"
              >
                <DeleteButtonSvg className={classes('delete-icon')} aria-hidden />
                Delete
              </button>
            )}
            <button
              className={classes('create-appointment-button')}
              onClick={() =>
                openAppointmentModal({
                  patientId: selectedPatientId,
                  patientName: patientDisplayName,
                })
              }
              aria-label="Create appointment"
            >
              Create Appointment
            </button>
          </div>
          {appointments.length > 0 && (
            <div className={classes('appointments-table')}>
              <div className={classes('upcoming-past-appointments')}>
                <AccessibleList
                  focusableClasses={['.tc-PatientsList__appt-section']}
                  direction="horizontal"
                  accessibilityMode={accessibilityMode}
                  role="tablist"
                  aria-label="Appointments Categories"
                >
                  <button
                    onClick={() => setIsPastAppointments(false)}
                    className={classes('appt-section', {
                      active: !isPastAppointments,
                    })}
                    aria-label="Upcoming Appointments List"
                    role="tab"
                    aria-selected={!isPastAppointments}
                    aria-controls="upcoming-appointments"
                    id="upcoming-tab"
                  >
                    Upcoming ({apptsByType[1]?.length})
                  </button>
                  <button
                    onClick={() => setIsPastAppointments(true)}
                    className={classes('appt-section', { active: isPastAppointments })}
                    aria-label="Past Appointments List"
                    role="tab"
                    aria-selected={isPastAppointments}
                    aria-controls="past-appointments"
                    id="past-tab"
                  >
                    Past ({apptsByType[0]?.length})
                  </button>
                </AccessibleList>
              </div>
              <AccessibleList
                className={classes('appointment-list')}
                direction={'vertical'}
                focusableClasses={['.tc-AppointmentList__appointment-container']}
                focusableChildrenClasses={[
                  '.tc-AppointmentList__check-mark',
                  '.tc-AppointmentList__appointment-details',
                ]}
                setStartElementOnChange={true}
                focusStart={'first'}
                loop={false}
                accessibilityMode={accessibilityMode}
                role="tabpanel"
                aria-labelledby={isPastAppointments ? 'past-tab' : 'upcoming-tab'}
              >
                {groupedList &&
                  sortBy(Object.entries(groupedList), ([month]) => month).map(([month, appts]) => (
                    <div key={month}>
                      <div className={classes('appointment-month-container')}>
                        {moment(month).format('MMMM YYYY')}
                      </div>
                      {sortBy(
                        appts as Appointment[],
                        (a) => a.appointmentDate + a.appointmentTime
                      ).map((appointment) => (
                        <AppointmentList
                          appointment={appointment}
                          key={appointment.id}
                          itemsToDelete={itemsToDelete}
                          setItemsToDelete={setItemsToDelete}
                          patientId={selectedPatientId}
                          patientName={patientDisplayName}
                          isPastAppointments={isPastAppointments}
                        />
                      ))}
                    </div>
                  ))}
              </AccessibleList>
            </div>
          )}
          {hasLoaded && !Object.entries(groupedList).length && (
            <div className={classes('empty-message')}>No Appointments</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<PatientDataProps, MobxProps>({
  userStore: ['getUserById'],
  modalStore: ['openModal'],
  appointmentsStore: ['showAllAppointments', 'openAppointmentModal', 'appointments'],
})(PatientData);
