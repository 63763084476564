// @ts-nocheck
import BaseService from './BaseService';

export default class EventRouter extends BaseService {
  constructor(host, options) {
    super(host, options);

    for (const [eventName, fn] of Object.entries(this.EVENTS)) {
      if (eventName.includes('|')) {
        for (const alias of eventName.split('|')) {
          this.EVENTS[alias] = fn;
        }

        delete this.EVENTS[eventName];
      }
    }
  }

  emit(event) {
    if (this.EVENTS[event.type]) {
      this.EVENTS[event.type].call(this, event);
    } else {
      this.logger.info(`unhandled event: ${event.type}`, event.data);
    }
  }

  EVENTS = {
    bang(event) {
      this.host.groups.reactToBangEvent(event);
    },

    client_advisory(event) {
      switch (event.data['advisory']['name']) {
        case 'replay_start':
          this.host.reactToAdvisoryEvent('replayStart', {
            replayMessageCount: event.data['advisory']['replay_message_count'],
          });
          break;

        case 'replay_stop':
          this.host.reactToAdvisoryEvent('replayStop');
          break;
        default:
      }
    },

    escalations(event) {
      this.host.escalations.reactToEscalationsEvent(event);
    },

    friends(event) {
      this.host.conversations.reactToFriendsEvent(event);
    },

    'message|group_message'(event) {
      this.host.conversations.reactToMessageEvent(event);
    },

    'message:status|group:message:status'(event) {
      this.host.messages.reactToMessageStatusEvent(event);
    },

    saved({ data }) {
      const {
        entity: { type },
      } = data;
      switch (type) {
        case 'tag':
          this.host.tags.reactToSavedEvent(data);
          break;
        case 'role':
          this.host.roles.reactToSavedEvent(data);
          break;
        case 'team':
          this.host.teams.reactToSavedEvent(data);
          break;
        default:
      }
    },

    user_preferences(event) {
      this.host.users.reactToUserPreferencesEvent(event);
    },

    org_preferences(event) {
      this.host.organizations.reactToOrganizationPreferencesEvent(event);
    },

    update(event) {
      this.host.conversations.reactToUpdateEvent(event);
    },

    'update:tag'(event) {
      this.host.tags.reactToUpdateEvent(event);
    },

    emoji_reaction(event) {
      this.host.messages.reactToReactionUpdate(event);
    },

    'update:team_request'(event) {
      this.host.conversations.reactToUpdateTeamRequestEvent(event);
    },

    // TODO test
    'account:settings'(event) {
      event.data['services'].forEach((service) => {
        switch (service['xmlns']) {
          case 'tigertext:iq:update:account':
            const userId = service['account'] || event.data['token']; // issue a refresh for user - will inject into store

            this.host.users.refresh(userId);
            break;
          case 'tigertext:iq:update:org_settings':
            // need to reload all orgs
            this.host.organizations.refreshAll();
            break;

          default:
            this.logger.warn(`unhandled account:settings service type ${service['xmlns']}`);
        }
      });
    },

    user_status_change: (event) => {
      this.host.presence.reactToUserStatusChange(event);
    },

    'message:is_typing'(event) {
      this.host.typingStatus.reactToIsTypingEvent(event);
    },

    'processing:start'(event) {
      this.host.conversations.reactToProcessingStart(event);
    },

    'processing:stop'(event) {
      this.host.conversations.reactToProcessingStop(event);
    },

    async remote_wipe(event) {
      this.host.notifications.disconnect({ source: 'remote_wipe event' });

      await this.host.notifications.signOut({ fromServer: true });
      this.host.setSessionWipeStatus('remote_wipe');
      this.host.signOut({ fromServer: true });
    },

    validated(event) {
      this.logger.warn('validated not implemented');
    },

    'msg:metadata_update'(event) {
      this.host.messages.reactToMessageMetadataEvent(event);
    },

    conversation_mute(event) {
      this.host.mute.reactToMuteEvent(event);
    },

    reconnect(event) {
      this.host.events.reconnect();
    },

    'roles:processing'(event) {
      this.host.roles.reactToRoleShiftSSE(event);
    },

    'vwr:joinablecall'(event) {
      this.host.virtualWaitingRooms.reactToJoinableCallSSE(event);
    },

    'update:vwr'(event) {
      this.host.virtualWaitingRoom.reactToUpdateVisitSSE(event);
    },

    'voip:incoming|voip:ended|voip:answered|voip:state'(event) {
      this.host.calls.reactToVoipSSE(event);
    },

    'push:credentials': () => null,
    'sse:heartbeat': () => null,
    'sse:keepalive': () => null,
  };
}
