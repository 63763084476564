// @ts-nocheck
import BaseAPI, { ROUTES, VERSIONS } from './BaseAPI';
const { VERSION_TWO } = VERSIONS;

const { EVENTS_ACK, EVENTS_CLOSE_ALL_CONNECTIONS } = ROUTES;

export default class EventsAPI extends BaseAPI {
  async ack(eventIds) {
    const { version = VERSION_TWO } = super.getVersion(EVENTS_ACK);

    await this.httpClient.post('/:version/events/ack', {
      urlParams: { version },
      data: {
        events: eventIds,
      },
    });
  }

  async closeAllConnections() {
    const { version = VERSION_TWO } = super.getVersion(EVENTS_CLOSE_ALL_CONNECTIONS);

    await this.httpClient.del('/:version/events', {
      urlParams: { version },
    });
  }
}
