import React, { useCallback, useEffect, useRef, useState } from 'react';
import BEM from '../../bem';
import {
  useVWRContext,
  changeSelectedVisitor,
  getVisitors,
} from '../../../contexts/VirtualWaitingRoom';
import { Conversation, Visitor } from '../../../types';
import { mobxInjectSelect } from '../../utils';
import AccessibleList from '../AccessibleList';
import VisitorCard from './VisitorCard';
import EmptyRoomCard from './EmptyRoomCard';

const classes = BEM.with('VisitorCardList');

type VisitorCardListProps = {
  roomState: string;
  visitsFilterStatus?: string;
};

type MobxProps = {
  markConversationAsRead: (c: Conversation) => void;
  accessibilityMode: boolean;
};

function VisitorCardList({
  markConversationAsRead,
  roomState = 'closed',
  visitsFilterStatus,
  accessibilityMode = false,
}: VisitorCardListProps & MobxProps) {
  const {
    dispatch,
    state: {
      selectedRoom,
      selectedStatus,
      visitors: { hasAllVisitors, selectedVisitor, visitors, page: visitorsPage },
      userHasRoomsAvailable,
      mobxProps,
    },
  } = useVWRContext();
  const scrollRef = useRef<HTMLDivElement>(null);

  const emptyRoomText = !userHasRoomsAvailable ? (
    <></>
  ) : roomState.toLowerCase() === 'closed' ? (
    <div>
      <p>Virtual Waiting Room is currently closed.</p>
      <p>Please go to the tab above to open.</p>
    </div>
  ) : (
    <p>There are no patients in this virtual waiting room</p>
  );

  const makeClickHandler = (visitor: Visitor, conversation: Conversation) => {
    return () => {
      if (conversation) {
        markConversationAsRead(conversation);
      }
      changeSelectedVisitor(dispatch, visitor, mobxProps);
    };
  };

  const initialStatus = hasAllVisitors ? 'done' : 'idle';
  const [status, setStatus] = useState<'idle' | 'pending' | 'done'>(initialStatus);

  // https://tigertext.atlassian.net/browse/RW-4130
  const paginate = useCallback(async () => {
    if (selectedRoom) {
      setStatus('pending');

      const visitorsResponse = await getVisitors(
        selectedRoom.id,
        mobxProps,
        selectedStatus,
        visitorsPage + 1,
        false
      );
      dispatch({ type: 'APPEND_VISITORS', payload: visitorsResponse });

      if (visitorsResponse.hasAllVisitors) {
        setStatus('done');
      } else {
        setStatus('idle');
      }
    }
  }, [dispatch, mobxProps, selectedRoom, selectedStatus, visitorsPage]);

  const DEFAULT_SCROLL_RATIO = 0.8;
  const handleScroll = useCallback(
    async ({ target }) => {
      const { clientHeight, scrollHeight, scrollTop } = target;
      const nearBottom = clientHeight / (scrollHeight - scrollTop) > DEFAULT_SCROLL_RATIO;
      if (nearBottom && status === 'idle' && !hasAllVisitors) {
        await paginate();
      }
    },
    [paginate, status, hasAllVisitors]
  );

  // https://tigertext.atlassian.net/browse/RW-4129
  useEffect(() => {
    const refCleanup = scrollRef;
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);

      return () => {
        if (refCleanup.current) {
          refCleanup.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [handleScroll, scrollRef]);

  return (
    <div className={classes()} ref={scrollRef}>
      <AccessibleList
        focusableClasses={['.tc-VisitorCard']}
        direction="vertical"
        accessibilityMode={accessibilityMode}
        loop={false}
        className={classes('AccessibleList')}
      >
        {visitors.length <= 0 ? (
          <EmptyRoomCard>{emptyRoomText}</EmptyRoomCard>
        ) : (
          visitors.map((visitor) => (
            <VisitorCard
              visitor={visitor}
              key={visitor.id}
              isSelected={visitor.id === selectedVisitor?.id}
              handleClick={makeClickHandler(visitor, visitor.conversation)}
              visitsFilterStatus={visitsFilterStatus}
              accessibilityMode={accessibilityMode}
            />
          ))
        )}
      </AccessibleList>
    </div>
  );
}

export default mobxInjectSelect<VisitorCardListProps, MobxProps>({
  conversationStore: ['markConversationAsRead'],
  sessionStore: ['accessibilityMode'],
})(VisitorCardList);
