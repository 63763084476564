import { makeStyles } from '@material-ui/core/styles';
// TooltipProps definitely exists, something up with our setup
// eslint-disable-next-line
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const useStyles = makeStyles((_theme) => ({
  tooltip: {
    fontFamily: 'inherit',
    fontSize: '11px',
    fontWeight: 400,
    backgroundColor: '#444',
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.14)',
  },
  arrow: {
    color: '#444',
  },
}));

export function MaterialTooltip(props: TooltipProps) {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}
