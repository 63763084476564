// @ts-nocheck
import _ from 'lodash';

export const cloudFrontUrlTransform = (url = '', config = {}) => {
  if (!url || _.isEmpty(config.cloudfront)) return url;

  const cloudfront = config.cloudfront;

  const cloudFrontSubDomain = _.findKey(cloudfront, (item, key) => url.indexOf(key) !== -1);

  const originalPath = cloudfront[cloudFrontSubDomain]
    ? `${cloudFrontSubDomain}.${config.cloudfrontDomain}`
    : '';
  const newPath = cloudfront[cloudFrontSubDomain]
    ? `${config.assetDomain}/${cloudfront[cloudFrontSubDomain]}`
    : '';

  let newUrl = originalPath && newPath ? url.replace(originalPath, newPath) : url;

  newUrl = newUrl.replace('http://', 'https://');

  return newUrl;
};
