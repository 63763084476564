export function escalationStatusToReadableLabel(escalationStatus) {
  switch (escalationStatus) {
    case 'ACKNOWLEDGED':
      return 'Acknowledged';
    case 'CANCELLED':
      return 'Cancelled';
    case 'IN_PROGRESS':
      return 'In Progress';
    case 'NO_RESPONSE':
      return 'No Response';
    default:
  }
}

export function messageRecipientStatusToReadableLabel(recipientStatus) {
  switch (recipientStatus) {
    case 'NEW':
      return 'Sent';
    case 'DELIVERED':
      return 'Delivered';
    case 'READ':
      return 'Read';
    case 'ACKNOWLEDGED':
      return 'Acknowledged';
    default:
  }
}

export function messageSenderStatusToReadableLabel(senderStatus) {
  switch (senderStatus) {
    case 'SENDING':
      return 'Sending...';
    case 'SENT':
      return 'Sent';
    case 'FAILED':
      return 'Send Failed';
    case 'RETRYING':
      return 'Retrying';
    default:
  }
}

export function userPresenceReadableLabel(presence) {
  switch (presence) {
    case 'AVAILABLE':
      return 'Available';
    case 'UNAVAILABLE':
      return 'Unavailable';
    case 'DND':
      return 'Do Not Disturb';
    default:
  }

  return 'Unavailable';
}

export function groupTypeReadableLabel(groupType) {
  switch (groupType) {
    case 'FORUM':
      return 'Forum';
    case 'ROLE_P2P':
      return 'Conversation';
    default:
  }

  return 'Group';
}
