// @ts-nocheck
import { getMimeEntryByContentType, getMimeEntryByExtension } from '../../utils/attachments';

export const DEFAULT_MIME_CONTENT_TYPE = 'application/octet-stream';
export const DEFAULT_MIME_EXTENSION = '.bin';

export async function getMimeType({ provideDefault = false, ...restOptions }) {
  let match;

  if (process.env.NODE_TARGET === 'node') {
    match = await getMimeTypeNode(restOptions);
  } else if (process.env.NODE_TARGET === 'web') {
    match = getMimeTypeWeb(restOptions);
  }
  if (match) {
    return match;
  } else if (provideDefault) {
    return {
      ext: DEFAULT_MIME_EXTENSION,
      mime: DEFAULT_MIME_CONTENT_TYPE,
    };
  }
}

async function getMimeTypeNode({ fileBuffer, filename }) {
  if (process.env.NODE_TARGET === 'node') {
    const fileTypeMatch = await require('file-type').fromBuffer(fileBuffer);
    const ext = filename && require('path').extname(filename);
    const supportedTypeMatches = ext && getMimeEntryByExtension(ext);

    if (fileTypeMatch) {
      let { ext, mime } = fileTypeMatch;
      ext = `.${ext}`;

      return { ext, mime };
    } else if (supportedTypeMatches) {
      return supportedTypeMatches[0];
    } else {
      return {
        ext: ext || DEFAULT_MIME_EXTENSION,
        mime: DEFAULT_MIME_CONTENT_TYPE,
      };
    }
  }
}

function getMimeTypeWeb({ mime }) {
  if (process.env.NODE_TARGET === 'web') {
    const matches = getMimeEntryByContentType(mime);
    if (matches) {
      let ext = matches[0];
      ext = `.${ext}`;

      return { ext, mime };
    }
  }
}

export default getMimeType;
