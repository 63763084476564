import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UpstreamFavicon from 'react-favicon';
import mobxInjectSelect from '../../../common/utils/mobxInjectSelect';

class Favicon extends Component {
  static propTypes = {
    faviconSelector: PropTypes.func.isRequired,
    totalUnreadAlertsCount: PropTypes.number.isRequired,
    totalUnreadCount: PropTypes.number.isRequired,
    totalUnreadVWRCount: PropTypes.number.isRequired,
  };

  render() {
    const { faviconSelector, totalUnreadAlertsCount, totalUnreadCount, totalUnreadVWRCount } =
      this.props;

    const unreadCount = totalUnreadAlertsCount + totalUnreadCount + totalUnreadVWRCount;

    const url = faviconSelector(unreadCount);

    return <UpstreamFavicon url={url} keep={this._keepFavicons} />;
  }

  _keepFavicons = (link) => {
    const rel = link.getAttribute('rel');
    return rel !== 'icon';
  };
}

export default mobxInjectSelect({
  messengerStore: ['totalUnreadAlertsCount', 'totalUnreadCount', 'totalUnreadVWRCount'],
})(Favicon);
