// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import { jsonCloneDeep, Validator } from '../utils';
import BaseService from './BaseService';

const MAP_MUTED_ENTITY_SERVER_TYPE_TO_TYPE = {
  account: 'user',
  group: 'group',
};

const HANDLED_MUTED_SERVER_ENTITY_TYPES = new Set(
  Object.keys(MAP_MUTED_ENTITY_SERVER_TYPE_TO_TYPE)
);

const MAX_MUTE_DURATION = 525600;

export default class MuteService extends BaseService {
  mounted() {
    this._fetched = false;
    this._muteEntriesByConversationHandle = {};
  }

  dispose() {
    this._fetched = false;
    this._muteEntriesByConversationHandle = null;
  }

  @reusePromise()

  /**
   * Finds all current active mute states.
   * @return {Promise.<MuteEntry[],Error>} a promise with MuteEntry array
   *
   *   [{
   *     conversationId: 'convId',
   *     durationInMinutes: 15,
   *     startedAt: 1459975766000,
   *     expiresAt: 1459975776000,
   *     organizationId: 'org1',
   *     entityId: 'userId|groupId',
   *     entityType: 'group|user'
   *   }, ...]
   */
  async findAll() {
    // after fetch, service should always have the latest snapshot of mute settings
    // because it gets events from server, and manages its own '_muteEntriesByConversationHandle' object
    // on mute/unmutes

    if (!this._fetched) {
      const muteEntries = await this.host.api.mute.findAll();
      for (const muteEntry of muteEntries) {
        if (!HANDLED_MUTED_SERVER_ENTITY_TYPES.has(muteEntry['sender_type'])) continue;

        const { conversationId, entry } = this._parseMuteEntry(muteEntry);
        this._addEntry(conversationId, entry);
      }

      this._fetched = true;
    }

    return Object.values(this._muteEntriesByConversationHandle);
  }

  _parseMuteEntry = (entry) => {
    const durationInMinutes = +entry['mute_duration'];
    let startedAt = entry['mute_start'];
    const organizationId = entry['caller_organization'] || entry['organization_id'];

    if (startedAt) {
      startedAt = +startedAt * 1000;
    }

    let conversationId;
    if (this.config.condensedReplays) {
      conversationId = entry['conversation_id'];
    } else {
      const entityType = MAP_MUTED_ENTITY_SERVER_TYPE_TO_TYPE[entry['sender_type']];
      // caller_organization in list, organization_id key in events
      const entityId = entry['sender_token'];

      conversationId = this.host.conversations.getConversationKey(
        entityType,
        entityId,
        organizationId
      );
    }

    return { conversationId, entry: { durationInMinutes, organizationId, startedAt } };
  };

  _addEntry = (conversationId, { durationInMinutes, organizationId, startedAt = Date.now() }) => {
    const expiresAt = +startedAt + durationInMinutes * 60 * 1000;
    if (expiresAt < Date.now()) return;

    const mute = this.host.models.Mute.inject({
      id: `mute:${conversationId}`,
      conversationId,
      durationInMinutes,
      expiresAt,
      organizationId,
      startedAt,
    });

    if (this.config.condensedReplays) {
      const conversation = this.host.conversations.getById(conversationId);
      if (!conversation) return mute;
      conversation.muted = mute;
      this.host.models.Conversation.inject(conversation);
    } else {
      this._muteEntriesByConversationHandle[conversationId] = mute;
      this.host.models.Conversation.touch(conversationId);
    }

    return mute;
  };

  _removeEntry = (conversationId) => {
    if (!this.config.condensedReplays) {
      delete this._muteEntriesByConversationHandle[conversationId];
    }

    const conversation = this.host.conversations.getById(conversationId);
    if (!conversation || !conversation.muted) return;
    this.host.models.Mute.eject(conversation.muted);
    conversation.muted = null;
    this.host.models.Conversation.inject(conversation);
  };

  _removeAllEntries() {
    if (this.config.condensedReplays) {
      for (const conversation of this.host.conversations.getAll()) {
        if (conversation.muted) {
          this._removeEntry(conversation.id);
        }
      }
    } else {
      for (const conversationId of Object.keys(this._muteEntriesByConversationHandle)) {
        this._removeEntry(conversationId);
      }
    }
  }

  // gets a non-expired entry for conversation if exists
  // if expired, deletes it from local storage
  _getByConversationHandle(conversationHandle: string) {
    const entry =
      this._muteEntriesByConversationHandle &&
      this._muteEntriesByConversationHandle[conversationHandle];
    if (!entry) return null;

    if (entry.expiresAt < Date.now()) {
      delete this._muteEntriesByConversationHandle[conversationHandle];
      return null;
    }

    return entry;
  }

  async unmuteAll() {
    await this.host.api.mute.unmuteAll();
    this._removeAllEntries();
  }

  async muteConversation(conversationId: string | Object, durationInMinutes: number) {
    this.__validateDurationInMinutes(durationInMinutes);

    conversationId = this._resolveModelId(conversationId);
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation) throw new Error('conversation ' + conversationId + ' not found');
    conversationId = conversation.id;

    const { counterPartyId, counterPartyType, organizationId } = conversation;

    if (counterPartyType === 'user') {
      await this.host.api.mute.muteUser(counterPartyId, durationInMinutes, organizationId, {
        recipientOrganizationId: organizationId,
      });
      this._addEntry(conversationId, { durationInMinutes, organizationId });
    } else if (counterPartyType === 'group') {
      await this.host.api.mute.muteGroup(counterPartyId, durationInMinutes, organizationId);
      this._addEntry(conversationId, { durationInMinutes, organizationId });
    }
  }

  async unmuteConversation(conversationId: string | Object) {
    const conversation = this._resolveEntity(conversationId, 'conversation');
    if (!conversation) throw new Error('conversation ' + conversationId + ' not found');
    conversationId = conversation.id;

    const { counterPartyId, counterPartyType, organizationId } = conversation;

    if (counterPartyType === 'user') {
      await this.host.api.mute.unmuteUser(counterPartyId, organizationId, {
        recipientOrganizationId: organizationId,
      });
      this._removeEntry(conversationId);
    } else if (counterPartyType === 'group') {
      await this.host.api.mute.unmuteGroup(counterPartyId, organizationId);
      this._removeEntry(conversationId);
    }
  }

  reactToMuteEvent({ data }) {
    data = jsonCloneDeep(data);
    if (data['token'] !== this.host.currentUser.id) return;

    const action = data['action'];

    if (action === 'clear') {
      this._removeAllEntries();
      return;
    }

    const { conversationId, entry } = this._parseMuteEntry(data);

    if (action === 'add') {
      this._addEntry(conversationId, entry);
    } else {
      this._removeEntry(conversationId);
    }
  }

  getById(muteId) {
    if (!muteId.startsWith('mute:')) {
      muteId = `mute:${muteId}`;
    }

    return this.host.models.Mute.get(muteId);
  }

  __validateDurationInMinutes(durationInMinutes: number) {
    if (
      durationInMinutes < 0 ||
      durationInMinutes > MAX_MUTE_DURATION ||
      durationInMinutes % 1 !== 0
    ) {
      Validator.raise(
        'durationInMinutes',
        'invalid',
        `should be a positive integer from 1 to ${MAX_MUTE_DURATION} (1 year)`
      );
    }
  }
}
