// @ts-nocheck
import CharCodes from './CharCodes';
import ConnectionEvent from './ConnectionEvent';
import ConnectionStatus from './ConnectionStatus';
import Event from './Event';
import MessageEvent from './MessageEvent';
import ParserStatus from './ParserStatus';

const CONTENT_TYPE_REGEX = /^text\/event-stream?(\s*charset=utf-8)?$/i;

export default class EventSourceRequest {
  constructor({
    RequestClass,
    dispatchEvent,
    headers,
    lastEventId = '',
    onError,
    onMessage,
    onOpen,
    onReadyStateChange,
    url,
    withCredentials,
  }) {
    this.RequestClass = RequestClass;
    this.dispatchEvent = dispatchEvent;
    this.headers = headers;
    this.lastEventId = lastEventId;
    this.onError = onError;
    this.onMessage = onMessage;
    this.onOpen = onOpen;
    this.onReadyStateChange = onReadyStateChange;
    this.url = url;
    this.withCredentials = withCredentials;

    this.currentState = ConnectionStatus.WAITING;
    this.parserState = {
      dataBuffer: '',
      eventTypeBuffer: '',
      fieldStart: 0,
      lastEventIdBuffer: lastEventId,
      state: ParserStatus.FIELD_START,
      textBuffer: '',
      valueStart: 0,
    };
    this.request = undefined;
  }

  async start() {
    this.onReadyStateChange(ConnectionStatus.CONNECTING);
    this.currentState = ConnectionStatus.CONNECTING;

    let { url, withCredentials } = this;

    // https://bugzilla.mozilla.org/show_bug.cgi?id=428916
    // Request header field Last-Event-ID is not allowed by Access-Control-Allow-Headers.
    if (url.slice(0, 5) !== 'data:' && url.slice(0, 5) !== 'blob:') {
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += 'lastEventId=' + encodeURIComponent(this.lastEventId);
    }

    const requestHeaders = {};
    requestHeaders['Accept'] = 'text/event-stream';

    if (this.headers) {
      for (const [name, value] of Object.entries(this.headers)) {
        requestHeaders[name] = value;
      }
    }

    try {
      this.request = new this.RequestClass({
        headers: requestHeaders,
        onProgress: (textChunk) => this.onProgress(textChunk),
        onStart: (options) => this.onStart(options),
        url,
        withCredentials,
      });

      await this.request.fetch();

      throw new Error('Connection closed by server');
    } catch (err) {
      if (this.request.isAborted) return;

      this.close();
      this.onFinish(err);
    }
  }

  onStart({ contentType, headers, status, statusText }) {
    if (this.currentState === ConnectionStatus.CONNECTING) {
      if (status === 200 && contentType && CONTENT_TYPE_REGEX.test(contentType)) {
        this.currentState = ConnectionStatus.OPEN;
        this.onReadyStateChange(ConnectionStatus.OPEN);

        const event = new ConnectionEvent('open', { headers, status, statusText });
        this.dispatchEvent(event);
        this.onOpen(event);
      } else {
        let message = '';
        if (status !== 200) {
          if (statusText) {
            statusText = statusText.replace(/\s+/g, ' ');
          }
          message =
            "EventSource's response has a status " +
            status +
            ' ' +
            statusText +
            ' that is not 200. Aborting the connection.';
        } else {
          message =
            "EventSource's response has a Content-Type specifying an unsupported type: " +
            (contentType ? '-' : contentType.replace(/\s+/g, ' ')) +
            '. Aborting the connection.';
        }

        const event = new ConnectionEvent('error', { headers, message, status, statusText });

        this.close();
        this.dispatchEvent(event);
        this.onError(event);
      }
    }
  }

  onProgress(textChunk) {
    if (this.currentState !== ConnectionStatus.OPEN) return;

    let {
      dataBuffer,
      eventTypeBuffer,
      fieldStart,
      lastEventIdBuffer,
      state,
      textBuffer,
      valueStart,
    } = this.parserState;

    let n = -1;
    for (let i = 0; i < textChunk.length; i += 1) {
      const c = textChunk.charCodeAt(i);
      if (c === CharCodes['\n'] || c === CharCodes['\r']) {
        n = i;
      }
    }

    const chunk = (n !== -1 ? textBuffer : '') + textChunk.slice(0, n + 1);
    textBuffer = (n === -1 ? textBuffer : '') + textChunk.slice(n + 1);

    for (let position = 0; position < chunk.length; position += 1) {
      const c = chunk.charCodeAt(position);

      if (state === ParserStatus.AFTER_CR && c === CharCodes['\n']) {
        state = ParserStatus.FIELD_START;
        continue;
      }

      if (state === ParserStatus.AFTER_CR) {
        state = ParserStatus.FIELD_START;
      }

      if (c === CharCodes['\n'] || c === CharCodes['\r']) {
        if (state !== ParserStatus.FIELD_START) {
          if (state === ParserStatus.FIELD) {
            valueStart = position + 1;
          }

          const field = chunk.slice(fieldStart, valueStart - 1);
          const valueLen =
            valueStart < position && chunk.charCodeAt(valueStart) === CharCodes[' '] ? 1 : 0;
          const value = chunk.slice(valueStart + valueLen, position);

          if (field === 'data') {
            dataBuffer += '\n';
            dataBuffer += value;
          } else if (field === 'id') {
            lastEventIdBuffer = value;
          } else if (field === 'event') {
            eventTypeBuffer = value;
          }
        }

        if (state === ParserStatus.FIELD_START) {
          if (dataBuffer !== '') {
            this.lastEventId = lastEventIdBuffer;
            if (eventTypeBuffer === '') {
              eventTypeBuffer = 'message';
            }

            const event = new MessageEvent(eventTypeBuffer, {
              data: dataBuffer.slice(1),
              lastEventId: lastEventIdBuffer,
            });
            this.dispatchEvent(event);

            if (eventTypeBuffer === 'message') {
              this.onMessage(event);
            }
            if (this.currentState === ConnectionStatus.CLOSED) {
              return;
            }
          }
          dataBuffer = '';
          eventTypeBuffer = '';
        }

        state = c === CharCodes['\r'] ? ParserStatus.AFTER_CR : ParserStatus.FIELD_START;
      } else {
        if (state === ParserStatus.FIELD_START) {
          fieldStart = position;
          state = ParserStatus.FIELD;
        }

        if (state === ParserStatus.FIELD) {
          if (c === CharCodes[':']) {
            valueStart = position + 1;
            state = ParserStatus.VALUE_START;
          }
        } else if (state === ParserStatus.VALUE_START) {
          state = ParserStatus.VALUE;
        }
      }
    }

    this.parserState = {
      dataBuffer,
      eventTypeBuffer,
      fieldStart,
      lastEventIdBuffer,
      state,
      textBuffer,
      valueStart,
    };
  }

  onFinish(err) {
    const event = new Event('error');
    if (err) {
      event.message = err.message;
      event.stack = err.stack;
    }

    this.dispatchEvent(event);
    this.onError(event);
  }

  async close() {
    this.currentState = ConnectionStatus.CLOSED;
    this.onReadyStateChange(ConnectionStatus.CLOSED);

    if (this.request && !this.request.isAborted) {
      await this.request.abort();
    }
  }
}
