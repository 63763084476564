import { action, computed, observable, runInAction } from 'mobx';
import { Message, Organization, TCClient } from '../types';

type Stores = {
  conversationStore: {
    fetchAllAlertMessagesForOrganization: (currentOrganizationId: string) => void;
  };
  messengerStore: {
    condensedReplays: boolean;
    currentOrganization: Organization;
    currentOrganizationId: string;
    isGroupAlertsAllowed: boolean;
  };
  rosterStore: {
    selectedAlertsFilter: 'Alerts' | 'History';
  };
};

export default class AlertStore {
  client: TCClient;
  stores: Stores;
  @observable.shallow alertElements: Record<string, HTMLElement> = {};
  @observable.shallow fetchedAlertMessagesForOrg: Record<string, boolean> = {};
  @observable isFetchingAlertMessages = false;

  constructor({ client, stores }: { client: TCClient; stores: Stores }) {
    this.client = client;
    this.stores = stores;
  }

  _canShowAlert = (message: Message) => {
    return !message.$placeholder && !!message?.alertDetails?.includesCurrentUserOrRole;
  };

  @computed get alertMessages() {
    const { messengerStore, rosterStore } = this.stores;
    const { currentOrganization, isGroupAlertsAllowed } = messengerStore;
    const { selectedAlertsFilter } = rosterStore;

    if (!currentOrganization) return [];
    const { alertMessages } = currentOrganization;

    if (isGroupAlertsAllowed) {
      if (selectedAlertsFilter === 'Alerts') {
        return alertMessages.filter(
          (message) => !message.isHistoricalAlert && this._canShowAlert(message)
        );
      } else if (selectedAlertsFilter === 'History') {
        return alertMessages.filter(
          (message) => message.isHistoricalAlert && this._canShowAlert(message)
        );
      }

      return [];
    }

    if (selectedAlertsFilter === 'Alerts') {
      return alertMessages.filter((message) => message.isUnread);
    } else if (selectedAlertsFilter === 'History') {
      return alertMessages.filter((message) => !message.isUnread);
    }

    return [];
  }

  @computed get unreadAlertsCount() {
    const { messengerStore } = this.stores;
    const { currentOrganization } = messengerStore;
    if (!currentOrganization) return 0;

    return currentOrganization.unreadAlertsCount;
  }

  @action('processAlertCallbackResponse') processAlertCallbackResponse = (
    data: {
      internal_message_id: string;
      payload: string;
    },
    throwOnError: boolean
  ) => {
    try {
      this.client.messages.processAlertMetadata(data.internal_message_id, JSON.parse(data.payload));
    } catch (err: unknown) {
      if (throwOnError) throw new Error('Error processing alert callback response');
      console.error(err);
    }
  };

  @action('fetchAlertMessagesForCurrentOrg') fetchAlertMessagesForCurrentOrg = async () => {
    const { conversationStore, messengerStore } = this.stores;
    const { currentOrganizationId } = messengerStore;
    this.isFetchingAlertMessages = true;
    if (
      messengerStore.condensedReplays &&
      !this.fetchedAlertMessagesForOrg[messengerStore.currentOrganizationId]
    ) {
      await conversationStore.fetchAllAlertMessagesForOrganization(currentOrganizationId);
      this.fetchedAlertMessagesForOrg[messengerStore.currentOrganizationId] = true;
    }
    runInAction(() => {
      this.isFetchingAlertMessages = false;
    });
  };

  @action('resetAlertElements') resetAlertElements = () => {
    this.alertElements = {};
  };

  @action('setAlertElement') setAlertElement = (
    messageId: string,
    alertElement: HTMLDivElement | null
  ) => {
    if (!alertElement) return;

    this.alertElements[messageId] = alertElement;
  };

  @action('scrollToAlertMessage') scrollToAlertMessage = (messageId: string) => {
    const alertElement = this.alertElements[messageId];
    if (alertElement) {
      alertElement.scrollIntoView();
    }
  };
}
