import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import BEM from '../../bem';

const classes = BEM.with('Form');

export default class Form extends Component {
  static propTypes = {
    columns: PropTypes.number,
    isWorking: PropTypes.bool,
    onInvalid: PropTypes.func,
    onSubmit: PropTypes.func,
    onValid: PropTypes.func,
    theme: PropTypes.oneOf(['rows', 'columns']),
  };

  render() {
    const {
      children,
      className,
      columns,
      isWorking,
      onInvalid,
      onSubmit,
      onValid,
      theme = 'columns',
    } = this.props;

    return (
      <Formsy
        className={classNames(className, classes({ columns, theme, working: isWorking }))}
        ref={this._setForm}
        noValidate
        onInvalid={onInvalid}
        onSubmit={onSubmit}
        onValid={onValid}
      >
        {children}
      </Formsy>
    );
  }

  _setForm = (ref) => {
    this.form = ref;
  };

  reset(...args) {
    return this.form.reset(...args);
  }
}
