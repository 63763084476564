import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Fab from '../../Fab/Fab';
import { ReactComponent as VideoOffIcon } from '../../../images/video-off.svg';
import { ReactComponent as VideoOnIcon } from '../../../images/video-on.svg';
import { mobxInjectSelect } from '../../../../common/utils';

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useIsTrackEnabled from '../../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles(() =>
  createStyles({
    videoIcon: {
      '&.isVideoInactive': {
        '& path': {
          fill: 'white',
        },
      },
    },
  })
);

function ToggleVideoButton({ isVideoInactive, disabled }) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();
  const [videoAvailable, toggleVideoEnabled] = useLocalVideoToggle();
  const videoTrack = localTracks.find((track) => track.name === 'camera');
  const isVideoEnabled = useIsTrackEnabled(videoTrack);
  const isEnabled = isVideoEnabled && videoAvailable;
  return (
    <Fab
      dataTestId={
        isVideoInactive
          ? 'inactive-video-button'
          : isEnabled
          ? 'disable-video-button'
          : 'enable-video-button'
      }
      onClick={toggleVideoEnabled}
      isDisabled={disabled || isVideoInactive}
      isEnabled={isEnabled}
      isInactive={isVideoInactive}
      backgroundColorOff="rgba(0, 0, 0, 0.5)"
      backgroundColorOn="rgba(0, 0, 0, 0.7)"
      hoverColor="rgba(0, 0, 0, 0.5)"
      title={isVideoInactive ? 'Inactive' : isEnabled ? 'Disable your video' : 'Enable your video'}
    >
      {isEnabled ? (
        <VideoOnIcon aria-hidden />
      ) : (
        <VideoOffIcon className={classNames(classes.videoIcon, { isVideoInactive })} aria-hidden />
      )}
    </Fab>
  );
}

class ToggleVideoButtonContainer extends React.Component {
  render() {
    return <ToggleVideoButton {...this.props} />;
  }
}

export default mobxInjectSelect({
  callStore: ['isVideoInactive'],
})(ToggleVideoButtonContainer);
