// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import BaseService from './BaseService';

export default class EscalationsService extends BaseService {
  @reusePromise()
  async escalateMessage(messageId: string | Object) {
    const message = this._resolveEntity(messageId, 'message');

    if (!message) {
      throw new errors.NotFoundError(this.host.models.Message.name, messageId);
    }

    const attrs = await this.host.api.escalations.escalateMessage(
      message.serverId,
      message.recipientOrganizationId
    );
    const { escalation } = attrs || {};

    return this._updateEscalation(escalation);
  }

  @reusePromise()
  async acknowledge(escalationExecutionId: string | Object) {
    const escalationExecution = this._resolveEntity(escalationExecutionId, 'escalationExecution');

    if (!escalationExecution) {
      throw new errors.NotFoundError(
        this.host.models.EscalationExecution.name,
        escalationExecutionId
      );
    }

    escalationExecutionId = escalationExecution.id;
    const { originalMessageId, organizationId } = escalationExecution;
    const { currentUser, currentUserId } = this.host;

    const userTokens = currentUser.roles.map(({ botUserId }) => botUserId);
    userTokens.push(currentUserId);

    const attrs = await this.host.api.escalations.acknowledge(escalationExecutionId, {
      messageId: originalMessageId,
      organizationId,
      userTokens,
    });
    const newExecution = this.host.models.EscalationExecution.inject(attrs);

    return newExecution;
  }

  @reusePromise()
  async cancel(escalationExecutionId: string | Object) {
    const escalationExecution = this._resolveEntity(escalationExecutionId, 'escalationExecution');

    if (!escalationExecution) {
      throw new errors.NotFoundError(
        this.host.models.EscalationExecution.name,
        escalationExecutionId
      );
    }

    const { originalMessageId, organizationId } = escalationExecution;

    await this.host.api.escalations.cancel(originalMessageId, organizationId);
  }

  async ensureEscalationExecution(escalationExecutionId: string | Object) {
    const escalationExecution = this._resolveEntity(escalationExecutionId, 'escalationExecution');

    if (!escalationExecution) {
      throw new errors.NotFoundError(
        this.host.models.EscalationExecution.name,
        escalationExecutionId
      );
    }

    const { carbonCopyMessageId, originalMessageId } = escalationExecution;
    const toEnsure = [];
    if (carbonCopyMessageId) toEnsure.push(carbonCopyMessageId);
    if (originalMessageId) toEnsure.push(originalMessageId);

    await this.host.messages.ensureRecipientStatusMulti(toEnsure);
  }

  @reusePromise()
  async skip(escalationExecutionId: string | Object) {
    const escalationExecution = this._resolveEntity(escalationExecutionId, 'escalationExecution');

    if (!escalationExecution) {
      throw new errors.NotFoundError(
        this.host.models.EscalationExecution.name,
        escalationExecutionId
      );
    }

    escalationExecutionId = escalationExecution.id;
    const { organizationId, originalMessageId } = escalationExecution;
    const { currentUser, currentUserId } = this.host;

    const userTokens = currentUser.roles
      .filter((role) => role.organizationId === organizationId)
      .map(({ botUserId }) => botUserId);

    userTokens.push(currentUserId);

    const attrs = await this.host.api.escalations.skip(escalationExecutionId, {
      messageId: originalMessageId,
      organizationId,
      userTokens,
    });
    const newExecution = this.host.models.EscalationExecution.inject(attrs);

    return newExecution;
  }

  @reusePromise()
  async removeEscalationGroup(escalationExecutionId: string | Object) {
    const escalationExecution = this._resolveEntity(escalationExecutionId, 'escalationExecution');

    if (!escalationExecution) {
      throw new errors.NotFoundError(
        this.host.models.EscalationExecution.name,
        escalationExecutionId
      );
    }

    const { groupId } = escalationExecution;

    const operationResult = await this.host.api.groups.destroy(groupId);

    if (!operationResult) {
      throw new errors.NotFoundError(this.host.models.Group.name, groupId);
    }
  }

  reactToEscalationsEvent({ data }) {
    this._updateEscalation(data.escalation);
  }

  _updateEscalation(attrs, { inGroupInject = false } = {}) {
    let escalationExecution = {};
    if (attrs && attrs['display_name']) {
      escalationExecution = this.host.models.EscalationExecution.inject(attrs);
    }

    const {
      id: escalationExecutionId,
      carbonCopyMessage,
      groupId,
      organizationId,
      originalMessage,
    } = escalationExecution;

    if (originalMessage) {
      this.host.models.Message.inject({
        escalationExecutionId,
        id: originalMessage.id,
      });
    }

    if (carbonCopyMessage) {
      this.host.models.Message.inject({
        escalationExecutionId,
        id: carbonCopyMessage.id,
      });
    }

    if (groupId && !inGroupInject) {
      this.host.models.Group.inject({ id: groupId, escalationExecutionId, organizationId });
    }

    return escalationExecution;
  }

  getAll() {
    return this.host.models.EscalationExecution.getAll();
  }
  getById(id: string) {
    return this.host.models.EscalationExecution.get(id);
  }
}
