// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import { downloadFile } from '../utils/file';
import BaseService from './BaseService';

const SEARCH_TERMS = ['template_label'].join(',');

export default class MessageTemplatesService extends BaseService {
  async batchDelete({ ids, organizationId }) {
    if (!Array.isArray(ids)) {
      throw new errors.ValidationError('ids', 'required', 'ids must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.messageTemplates.batch({
      ids,
      operation: 'destroy',
      organizationId,
    });

    const messageTemplates = ids.map((id) =>
      this.host.models.MessageTemplate.inject({
        $deleted: true,
        id,
      })
    );

    return messageTemplates;
  }

  async create({
    attachment,
    body,
    isSmsCompatible = false,
    network = 'patient',
    organizationId,
    repository = 'personal',
    title,
  }) {
    const trimmedTitle = title ? title.trim() : '';
    const trimmedBody = body ? body.trim() : '';
    this._validate({
      body: trimmedBody,
      organizationId,
      repository,
      shouldCheckId: false,
      title: trimmedTitle,
    });
    if (['patient'].includes(network) === false) {
      throw new errors.ValidationError('network', 'invalid', 'network must be "patient"');
    }

    const response = await this.host.api.messageTemplates.create({
      attachment,
      body: trimmedBody,
      isSmsCompatible,
      network,
      organizationId,
      repository,
      title: trimmedTitle,
    });

    const messageTemplate = this.host.models.MessageTemplate.inject(response);
    return messageTemplate;
  }

  async delete(id, organizationId) {
    this._validate({ id, organizationId, shouldCheckInvalid: false });

    await this.host.api.messageTemplates.delete(id, organizationId);

    const messageTemplate = this.host.models.MessageTemplate.inject({
      $deleted: true,
      id,
    });

    return messageTemplate;
  }

  @reusePromise()
  async downloadTemplateFile({ templateId, fileName, organizationId }) {
    const blob = await this.host.api.messageTemplates.downloadTemplateFile({
      templateId,
      fileName,
      organizationId,
    });
    await downloadFile({ attachmentContentType: blob.type, blob, fileName });
  }

  @reusePromise()
  async find(id, organizationId) {
    this._validate({ id, organizationId, shouldCheckInvalid: false });

    const response = await this.host.api.messageTemplates.find(id, organizationId);

    const messageTemplate = this.host.models.MessageTemplate.inject(response);
    return messageTemplate;
  }

  getAll() {
    return this.host.models.MessageTemplate.getAll();
  }

  getById(id: string) {
    return this.host.models.MessageTemplate.get(id);
  }

  @reusePromise()
  async search({
    continuation,
    isSmsCompatible,
    network = 'patient',
    organizationId,
    query = '',
    repository = 'personal',
    sortBy = 'title',
    sortOrder = 'asc',
  }) {
    const response = await this.host.search.query({
      version: 'SEARCH_PARITY',
      continuation,
      network,
      organizationId,
      query: {
        [SEARCH_TERMS]: query,
        repository,
        isSmsCompatible,
      },
      returnFields: [],
      sort: {
        [sortBy]: sortOrder,
      },
      types: ['messageTemplate'],
    });

    const results = this._processSearchResults(response.results);

    return {
      metadata: response.metadata,
      results,
    };
  }

  async update({
    attachment,
    body,
    id,
    isSmsCompatible = false,
    organizationId,
    repository,
    title,
  }) {
    const trimmedTitle = title ? title.trim() : '';
    const trimmedBody = body ? body.trim() : '';
    this._validate({ body: trimmedBody, id, organizationId, repository, title: trimmedTitle });

    const response = await this.host.api.messageTemplates.update({
      ...(isSmsCompatible ? null : { attachment }),
      body: trimmedBody,
      id,
      isSmsCompatible,
      organizationId,
      repository,
      title: trimmedTitle,
    });

    const messageTemplate = this.host.models.MessageTemplate.inject(response);

    return messageTemplate;
  }

  _processSearchResults(results) {
    return results.map(({ entity }) => entity).filter(({ $deleted }) => !$deleted);
  }

  _validate({
    body,
    id,
    network,
    organizationId,
    repository,
    shouldCheckId = true,
    shouldCheckInvalid = true,
    title,
  }) {
    if (shouldCheckId && !id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }
    if (shouldCheckInvalid) {
      if (!body || body.trim().length === 0) {
        throw new errors.ValidationError('body', 'invalid', 'body must be at least 1 character');
      }
      if (!repository || !['general', 'personal', 'aar_variables'].includes(repository)) {
        throw new errors.ValidationError(
          'repository',
          'invalid',
          'repository must be either "general" or "personal"'
        );
      }
      if (!title || title.trim().length === 0 || title.trim().length > 60) {
        throw new errors.ValidationError(
          'title',
          'invalid',
          'title must be between 1 and 60 characters'
        );
      }
    }
  }
}
