import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState, actions } from '../../../../redux-stores';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import BEM from '../../../bem';
import CollaborationSearch from '../CollaborationSearch';

import { ReactComponent as ColorsEmptyIcon } from '../../../images/colors-empty.svg';
import { ReactComponent as SearchIcon } from '../../../images/tag-search.svg';
import { ReactComponent as PlusIcon } from '../../../images/tag-plus.svg';
import { ReactComponent as DeleteIcon } from '../../../images/trash-icon.svg';
import { reduxInjectSelect, MapStateToProps } from '../../../utils/reduxInjectSelect';
import { notTaggedTag, selectCheckedTagsIds } from '../../../../redux-stores/Collaboration/tags';
import TagFilterColorPills from './TagFilterColorPills';
import TagColorFilter from './TagColorFilter';

const { setCheckedTags, setModal, setTagBeingEdited, setIsTagSearchOpen, setTagSearchQuery } =
  actions;
const classes = BEM.with('CollaborationTags');

const reduxSelectors = {
  collab: ['isRolesAdmin', 'isTeamAdmin'],
  tags: ['filteredColors', 'hasTags', 'isTagSearchOpen', 'tagSearchQuery'],
} as const;

type ReduxProps = MapStateToProps<ReduxState, typeof reduxSelectors>;

type TagsHeaderProps = {};

function TagsHeader({
  filteredColors,
  hasTags,
  isRolesAdmin,
  isTagSearchOpen,
  isTeamAdmin,
  tagSearchQuery,
}: TagsHeaderProps & ReduxProps) {
  const dispatch = useDispatch();
  const checkedTagsIds = useSelector(selectCheckedTagsIds);
  const [colorFilterOpen, setColorFilterOpen] = useState(false);
  const filterColorButtonRef = useRef(null);
  const isDeselect = checkedTagsIds.length > 0;

  const noResultDueToAction = hasTags || isTagSearchOpen || filteredColors.length > 0;

  const setColorFilter = useCallback(() => {
    setColorFilterOpen(!colorFilterOpen);
  }, [colorFilterOpen]);
  const shouldShowDeleteIcon =
    !checkedTagsIds.includes(notTaggedTag.id) && checkedTagsIds.length >= 1;

  return (
    <div>
      <div
        className={classes('header', {
          isTagSearchOpen,
        })}
        data-test-id="tags header"
      >
        <div className={classes('heading')}>Tags</div>
        <div className={classes('actions')}>
          <div className={classes('action')}>
            {noResultDueToAction && (
              <ToolTip text="Filter by color" textAlign="center">
                <button
                  className={classes('icon', {
                    open: colorFilterOpen,
                  })}
                  onClick={setColorFilter}
                  data-test-id="filter by color"
                  ref={filterColorButtonRef}
                >
                  <ColorsEmptyIcon />
                </button>
              </ToolTip>
            )}
            {colorFilterOpen && (
              <TagColorFilter buttonRef={filterColorButtonRef} toggleHandler={setColorFilter} />
            )}
          </div>
          {noResultDueToAction && (
            <div className={classes('action')}>
              <ToolTip className={classes('tooltip')} text="Search Tags" textAlign="center">
                <button
                  className={classes('icon', {
                    open: isTagSearchOpen,
                  })}
                  onClick={() => {
                    isTagSearchOpen && dispatch(setTagSearchQuery(''));
                    dispatch(setIsTagSearchOpen(!isTagSearchOpen));
                  }}
                  data-test-id="search for tag"
                >
                  <SearchIcon />
                </button>
              </ToolTip>
            </div>
          )}
          {(isRolesAdmin || isTeamAdmin) && (
            <div className={classes('action')}>
              {shouldShowDeleteIcon ? (
                <ToolTip text="Delete Tags" textAlign="center">
                  <button
                    className={classes('icon')}
                    data-test-id="delete selected tags"
                    onClick={() => {
                      dispatch(setTagBeingEdited({ tag: undefined }));
                      dispatch(setModal({ name: 'deleteTag' }));
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </ToolTip>
              ) : (
                <ToolTip text="New Tag" textAlign="center">
                  <button
                    className={classes('icon')}
                    data-test-id="create new tag"
                    onClick={() => dispatch(setModal({ name: 'tag' }))}
                  >
                    <PlusIcon />
                  </button>
                </ToolTip>
              )}
            </div>
          )}
        </div>
      </div>
      {isTagSearchOpen && (
        <CollaborationSearch
          actionFn={setTagSearchQuery}
          placeholder={'Search Tags'}
          query={tagSearchQuery || ''}
        />
      )}
      <div className={classes('batch-actions-container', { isTagSearchOpen })}>
        {isDeselect && (
          <div className={classes('batch-actions')}>
            <div className={classes('deselect')} onClick={() => dispatch(setCheckedTags({}))}>
              <input name="deselect" type="checkbox" checked readOnly />
              <label htmlFor="deselect">Deselect</label>
            </div>
          </div>
        )}
      </div>
      {filteredColors.length > 0 && <TagFilterColorPills />}
    </div>
  );
}
export default reduxInjectSelect<TagsHeaderProps, ReduxProps, ReduxState>(reduxSelectors)(
  TagsHeader
);
