import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PatientAvatar } from '../../../common/images/default-avatar--singleProvider.svg';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#c2cbd2',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarContainer: {
    backgroundColor: '#4a657b',
    borderRadius: '50%',
    marginBottom: '70px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.23)',
  },
  avatar: {
    width: '28px',
    height: '28px',
    paddingBottom: '3px',
  },
});

export default function AudioPreview() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.avatarContainer}>
        <PatientAvatar className={classes.avatar} />
      </div>
    </div>
  );
}
