import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';
import { Scrollbars } from 'tt-react-custom-scrollbars';
import TCClient from '../../../../client';
import { actions, ReduxState } from '../../../../redux-stores';
import { Organization, SearchResult } from '../../../../types';
import BEM from '../../../bem';
import { mobxInjectSelect } from '../../../utils';
import { Modal } from '../..';

import CollaborationSearch from '../CollaborationSearch';

const { setSelectedOrganization } = actions;
const classes = BEM.with('CollaborationModal');

const THROTTLE_TIMEOUT = 50;

type MobxProps = {
  closeAdminOrgSelectModal: () => void;
  setCurrentOrganizationId: (orgId: string) => void;
  find: (id: string) => Organization;
};

type OrgSelectorModalProps = {};

function OrgSelectorModal({
  closeAdminOrgSelectModal,
  find,
  setCurrentOrganizationId,
}: OrgSelectorModalProps & MobxProps) {
  const dispatch = useDispatch();
  const [continuation, setContinuation] = useState('');
  const [results, setResults] = useState<Organization[]>([]);
  const [query, setQuery] = useState('');
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const isAdminOrgSelectorOpen = useSelector(
    (state: ReduxState) => state.collab.isAdminOrgSelectorOpen
  );

  const throttledLoadMore = useRef(
    throttle(() => setShouldLoadMore(true), THROTTLE_TIMEOUT, {
      leading: false,
    })
  );

  const fetchOrgs = useCallback(() => {
    setShouldLoadMore(false);
    const getOrgs = async () => {
      const { results: serverResults, metadata } = await TCClient.search.queryOrgs({
        version: 'LEGACY',
        query,
        continuation,
      });

      const organizations: Organization[] = serverResults.map((r: SearchResult) => {
        return r.entity as Organization;
      });
      setResults(continuation ? [...results, ...organizations] : organizations);
      setContinuation(metadata.continuation);
    };
    getOrgs();
  }, [continuation, query, results]);

  useEffect(() => {
    if (isAdminOrgSelectorOpen) {
      fetchOrgs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminOrgSelectorOpen]);

  const clearSearch = () => {
    setQuery('');
    setContinuation('');
    throttledLoadMore.current();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = event.target.value;
    setQuery(newVal);
    setContinuation('');
    throttledLoadMore.current();
  };

  const switchOrg = async (token: string, name: string) => {
    await find(token);
    const organizationIds = TCClient.currentUser.organizationIds;
    const organizationHasCurrentUser = organizationIds && organizationIds.includes(token);
    organizationHasCurrentUser
      ? setCurrentOrganizationId(token)
      : dispatch(setSelectedOrganization({ id: token, name }));
    closeModal();
  };

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = e.currentTarget;
    if (continuation && clientHeight / (scrollHeight - scrollTop) > 0.8) {
      throttledLoadMore.current();
    }
  };

  const closeModal = () => {
    closeAdminOrgSelectModal();
    setQuery('');
    setContinuation('');
    setResults([]);
  };

  useEffect(() => {
    if (shouldLoadMore) {
      fetchOrgs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  return (
    <Modal
      ariaLabelCloseButton={'Org Selector Close'}
      ariaLabelHeader={'Org Selector Header'}
      closeClass={classes(`close-button-normal`)}
      header={'Org Lookup Tool'}
      headerClass={classes(`header-normal`)}
      hasCloseButton={true}
      isOpen={isAdminOrgSelectorOpen}
      size={'large'}
      overlayClassName={classes('')}
      className={classes('org-modal')}
      onRequestClose={closeModal}
    >
      <div data-test-id={'Org Selector Body'} className={classes('org-modal-body')}>
        <label htmlFor="orgMenu" className={classes('label')}>
          Search Orgs:
        </label>
        <div>
          <CollaborationSearch
            placeholder="Search"
            query={query}
            onChangeHandler={handleSearch}
            clearInputHandler={clearSearch}
          />
          <div className={classes('org-table')}>
            <div className={classes('org-table-header')}>
              <div className={classes('org-name')}>Org Name</div>
              <div className={classes('org-token')}>Org Token</div>
              <div className={classes('org-user-count')}># of users</div>
              <div className={classes('org-status')}>Status</div>
              <div className={classes('org-domain')}>Domain</div>
            </div>
            <div className={classes('org-table-list')}>
              <Scrollbars autoHide onScroll={onScroll} autoHideTimeout={750}>
                {results.map((org) => (
                  <div
                    className={classes('org-list-item')}
                    onClick={() => switchOrg(org.token, org.name)}
                    key={org.token}
                  >
                    <div className={classes('org-name')} title={org.name}>
                      {org.name}
                    </div>
                    <div className={classes('org-token')} title={org.token}>
                      {org.token}
                    </div>
                    <div className={classes('org-user-count')}>{org.totalMembers}</div>
                    <div className={classes('org-status')}>
                      {org.billing === 'active' ? 'Paid' : 'Free'}
                    </div>
                    <div className={classes('org-domain')} title={org.emailDomains}>
                      {org.emailDomains}
                    </div>
                  </div>
                ))}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<OrgSelectorModalProps, MobxProps>({
  messengerStore: ['closeAdminOrgSelectModal', 'setCurrentOrganizationId'],
  organizationStore: ['find'],
})(OrgSelectorModal);
