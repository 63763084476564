// @ts-nocheck
import { DEFAULT_MIME_CONTENT_TYPE, getMimeType } from './mimeTypes';
import { arrayBufferToBinaryString } from './encoding';

const CHUNK_DELAY_MS = 100;
const CHUNK_SIZE = 1024 * 1024;

async function readFile(file, options) {
  let content = '';

  if (process.env.NODE_TARGET === 'node') {
    content = await readFileNode(file, options);
  } else if (process.env.NODE_TARGET === 'web') {
    content = await readFileWeb(file, options);
  }

  return content;
}

function readFileNode(file, { idx } = {}) {
  if (process.env.NODE_TARGET === 'node') {
    const fs = require('fs');
    const path = require('path');
    let fileBuffer;
    let filename;
    let mimetype;

    if (idx !== undefined) idx = `-${idx}`;

    return Promise.resolve().then(async () => {
      if (typeof file === 'string') {
        fileBuffer = await new Promise((resolve, reject) => {
          fs.readFile(file, (err, buffer) => {
            err ? reject(err) : resolve(buffer);
          });
        });
        filename = path.basename(file);
        const { mime } = await getMimeType({ fileBuffer, filename, provideDefault: true });
        mimetype = mime;
      } else if (file instanceof Buffer) {
        fileBuffer = file;
        const { ext, mime } = await getMimeType({ fileBuffer, provideDefault: true });
        filename = `attachment${idx}${ext}`;
        mimetype = mime;
      } else {
        throw new Error('Unsupported file attachment type');
      }

      return {
        fieldValue: fileBuffer.toString('binary'),
        mimetype,
        filename,
      };
    });
  }

  return Promise.reject(new Error('not supported in this environment'));
}

function readFileWeb(file) {
  if (process.env.NODE_TARGET === 'web') {
    return Promise.resolve().then(async () => {
      const chunks = [];
      let byteLength = 0;
      let offset = 0;
      let readerComplete;
      let readerError;

      const reader = new FileReader();
      reader.addEventListener('load', () => readerComplete && readerComplete(reader.result), false);
      reader.addEventListener('error', (e) => readerError && readerError(e), false);

      while (offset < file.size) {
        if (offset > 0) await new Promise((resolve) => setTimeout(resolve, CHUNK_DELAY_MS));

        const end = offset + CHUNK_SIZE;
        const slice = file.slice(offset, end);
        offset = end;

        // eslint-disable-next-line no-loop-func
        const fileContents = await new Promise((resolve, reject) => {
          readerComplete = resolve;
          readerError = reject;
          reader.readAsArrayBuffer(slice);
        });

        chunks.push(fileContents);
        byteLength += fileContents.byteLength;
      }

      const concatenatedChunks = new Uint8Array(byteLength);
      offset = 0;
      for (const chunk of chunks) {
        concatenatedChunks.set(new Uint8Array(chunk), offset);
        offset += chunk.byteLength;
      }

      return {
        fieldValue: arrayBufferToBinaryString(concatenatedChunks.buffer),
        mimetype: file.type || DEFAULT_MIME_CONTENT_TYPE,
        filename: file.name,
      };
    });
  }

  return Promise.reject(new Error('not supported in this environment'));
}

export default readFile;
