import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Appointment, Workflow } from '../../../types';
import BEM from '../../bem';
import { ReactComponent as CheckSvg } from '../../images/selected-check.svg';
import { ReactComponent as DropdownChevronSvg } from '../../images/dropdown-chevron.svg';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { ContextMenu, MenuItemList, MenuItem } from '../ContextMenu';
import Modal from '../Modal';
import InputCounter from '../InputCounter';
import AppointmentDateTimeForm from './AppointmentDateTimeForm';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('CreateAppointmentModal');

const MAX_LOCATION_CHARACTERS = 75;
const MAX_PROVIDER_CHARACTERS = 50;

type CreateAppointmentModalProps = {
  isOpen: boolean;
  options: {
    isAppointmentInPast: boolean;
  };
};

type MobxProps = {
  appointment: Appointment;
  closeModal: () => void;
  currentOrganizationId: string;
  deleteAppointments: (ids: string[]) => void;
  saveAppointment: () => void;
  updateAppointment: (field: string, values: string) => void;
  workflows: Workflow[];
};

function CreateAppointmentModal({
  appointment,
  closeModal,
  currentOrganizationId,
  deleteAppointments,
  options,
  isOpen,
  saveAppointment,
  updateAppointment,
  workflows,
}: MobxProps & CreateAppointmentModalProps) {
  const { id, location, patientName, provider, visitNumber, workflowId } = appointment;
  const { isAppointmentInPast } = options;
  const [workflow, setWorkflow] = useState<Workflow | null>(null);
  const [appointmentError, setAppointmentError] = useState<string | null>(null);
  const workflowButtonRef = useRef(null);
  const canSave = !visitNumber && location && provider && workflowId;
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const setDefaultWorkflow = useCallback(() => {
    if (!workflowId && workflows.length === 1) {
      updateAppointment('workflowId', workflows[0].id);
    } else if (!workflowId) {
      setWorkflow(null);
    }
  }, [updateAppointment, workflowId, workflows]);

  useEffect(() => {
    if (workflowId) {
      const foundWorkflow = workflows.find((x) => x.id === workflowId);
      foundWorkflow && setWorkflow(foundWorkflow);
      setSelectedOptionIndex(workflows.findIndex((w) => w.id === workflowId));
    }
    setDefaultWorkflow();
  }, [setDefaultWorkflow, workflowId, workflows]);

  const saveAppointmentForPatient = async () => {
    try {
      await saveAppointment();
      closeAppointmentModal();
    } catch (err) {
      if (err.message === 'Cannot have duplicate appointment_date_time') {
        setAppointmentError(
          'An appointment at that time already exists. Please update and try again.'
        );
      } else {
        setAppointmentError('Appointment must be created at least 30 minutes before it occurs');
      }
    }
  };

  const closeAppointmentModal = () => {
    closeModal();
    setAppointmentError('');
  };

  const deleteAppt = () => {
    if (!id) return;
    deleteAppointments([id]);
    closeModal();
  };

  return (
    <Modal
      ariaLabelCloseButton="Patient Appointment Close"
      ariaLabelHeader="Patient Appointment"
      bodyClass={classes('body', { isDisabled: isAppointmentInPast })}
      closeClass={classes('close-button')}
      header={`${patientName}'s Appointment`}
      headerClass={classes('header')}
      size={'medium-large'}
      isOpen={isOpen}
      footerPrimaryActions={
        <button
          aria-label={
            visitNumber || isAppointmentInPast ? 'ok appointment button' : 'Save appointment button'
          }
          className={classes('save-btn', {
            disabled: visitNumber ? false : !canSave,
          })}
          type="button"
          onClick={() =>
            visitNumber || isAppointmentInPast ? closeModal() : saveAppointmentForPatient()
          }
        >
          {visitNumber || isAppointmentInPast ? 'OK' : 'SAVE'}
        </button>
      }
      footerSecondaryActions={
        <>
          <div className={classes('delete-cancel')}>
            {id && !visitNumber && (
              <button
                onClick={() => deleteAppt()}
                className={classes('delete-btn', { isEditing: !!id })}
                type="button"
                aria-label="Delete Appointment"
              >
                DELETE
              </button>
            )}
          </div>
          <div
            className={classes('cancel-appointment', {
              visitNumberAbsent: id && !visitNumber,
              visitNumberPresent: id && visitNumber,
            })}
          >
            {!isAppointmentInPast && (
              <button
                aria-label="Create Appointment Cancel"
                className={classes('cancel-btn')}
                onClick={closeAppointmentModal}
                type="button"
              >
                CANCEL
              </button>
            )}
          </div>
        </>
      }
      className={classes()}
      onRequestClose={closeAppointmentModal}
    >
      <div aria-label="Create Appointment Info" className={classes('info')}>
        {visitNumber && (
          <div className={classes('visit-number')}> Visit Number: {visitNumber} </div>
        )}
        <div className={classes('section')}>
          <AppointmentDateTimeForm isAppointmentInPast={isAppointmentInPast} />
        </div>
        <div className={classes('row')}>
          <div className={classes('section', { isLocationInput: true })}>
            <div className={classes('label')}>Location</div>
            <InputCounter
              handleChange={(e) => updateAppointment('location', e.target.value)}
              inputValue={location}
              maxCharacterInputValue={MAX_LOCATION_CHARACTERS}
              placeholderValue="Type Location"
              type="location"
            />
          </div>
          <div className={classes('section', { isProviderInput: true })}>
            <div className={classes('label')}>Provider</div>
            <InputCounter
              handleChange={(e) => updateAppointment('provider', e.target.value)}
              inputValue={provider}
              maxCharacterInputValue={MAX_PROVIDER_CHARACTERS}
              placeholderValue="Type Provider"
              type="provider"
            />
          </div>
        </div>
        <div className={classes('section')}>
          <div className={classes('label')}>Workflow</div>
          <ContextMenu
            event="click"
            offsetY={6}
            position="bottominnerleft"
            relativeTo={workflowButtonRef.current}
            theme="vertical"
            accessibilityMode={accessibilityMode}
            menu={
              <MenuItemList
                className={classes('', { menu: true })}
                accessibilityMode={accessibilityMode}
                selectedOptionIndex={selectedOptionIndex}
              >
                {workflows
                  .filter((w) => w.organizationId === currentOrganizationId)
                  .map((workflowItem, idx) => {
                    const isSelected = workflowId === workflowItem.id;
                    return (
                      <MenuItem
                        className={classes('menu-item', {
                          selected: isSelected,
                        })}
                        onClick={() => {
                          setWorkflow(workflowItem);
                          setSelectedOptionIndex(idx);
                          updateAppointment('workflowId', workflowItem.id);
                          if (accessibilityMode && workflowButtonRef?.current) {
                            (workflowButtonRef?.current as HTMLElement).focus();
                          }
                        }}
                        key={workflowItem.id}
                      >
                        <div className={classes('workflow-label')}> {workflowItem.label} </div>
                        {isSelected && <CheckSvg className={classes('check')} />}
                      </MenuItem>
                    );
                  })}
              </MenuItemList>
            }
          >
            <button
              className={classes('workflow-btn', {
                placeholder: !workflowId,
              })}
              data-test-id={'workflow-menu-button'}
              ref={workflowButtonRef}
            >
              <div className={classes('workflow-label')}>
                {' '}
                {workflow ? workflow.label : 'Select a Workflow'}{' '}
              </div>
              <DropdownChevronSvg aria-hidden />
            </button>
          </ContextMenu>
        </div>
        {appointmentError && (
          <div className={classes('appointment-duplicate')}>{appointmentError}</div>
        )}
      </div>
    </Modal>
  );
}

export default mobxInjectSelect<CreateAppointmentModalProps, MobxProps>({
  messengerStore: ['currentOrganizationId'],
  appointmentsStore: [
    'appointment',
    'deleteAppointments',
    'saveAppointment',
    'updateAppointment',
    'workflows',
  ],
})(CreateAppointmentModal);
