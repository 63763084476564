// @ts-nocheck
export default function (store, { host }) {
  const Role = store.defineModel('role', {
    defaultValues: {
      description: '',
      memberIds: null,
      tagId: null,
    },

    afterAssignment(entity) {
      host.users._setMembersOnEntity({ entity });
    },

    relations: {
      belongsTo: {
        assignmentGroup: { type: 'group', foreignKey: 'assignmentGroupId' },
        botUser: { type: 'user', foreignKey: 'botUserId' },
        escalationPolicy: { type: 'escalationPolicy', foreignKey: 'escalationPolicyId' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        tag: { type: 'tag', foreignKey: 'tagId' },
      },
    },
  });

  return Role;
}
