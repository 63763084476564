import { useEffect, useState, useRef } from 'react';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';
import useLocalVideoToggle from '../useLocalVideoToggle/useLocalVideoToggle';
import { useAppState } from '../../state';

export default function useParticipants() {
  const { currentCall, endVoipCall } = useAppState();
  const {
    room,
    localTrackToggling: { isLocalVideoToggledOff },
  } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(Array.from(room.participants.values()));
  const [, toggleVideoEnabled] = useLocalVideoToggle();
  const oldParticipants = useRef(participants);

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants((prevParticipants) => [
        dominantSpeaker,
        ...prevParticipants.filter((participant) => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    if (
      currentCall?.type !== 'vwr' &&
      oldParticipants.current.length > participants.length &&
      participants.length === 0
    ) {
      room.disconnect();
      endVoipCall && endVoipCall();
    }
    oldParticipants.current = participants;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  useEffect(() => {
    const participantConnected = (participant) => {
      if (!room.localParticipant.videoTracks.size && !isLocalVideoToggledOff) {
        toggleVideoEnabled();
      }
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      if (room.state === 'disconnected') return;
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
    };
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  return participants;
}
