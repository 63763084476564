import React, { Component } from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';
import propTypes from '../../../../common/propTypes';
import { getRecipientEntity, mobxInjectSelect } from '../../../../common/utils';
import {
  DistributionListConversationHeader,
  GroupConversationHeader,
  PatientConversationHeader,
  RoleConversationHeader,
  UserConversationHeader,
} from './';

class ConversationHeader extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation,
    isInfoPaneOpen: PropTypes.bool,
  };

  COMPONENT_BY_ENTITY_TYPE = {
    distributionList: DistributionListConversationHeader,
    group: GroupConversationHeader,
    role: RoleConversationHeader,
    user: UserConversationHeader,
  };

  render() {
    const {
      currentConversation,
      isGroupVideoCallEnabled,
      isGroupVoiceCallEnabled,
      isInfoPaneOpen,
      isVideoCallEnabled,
      isVoiceCallEnabled,
      videoCallSetUp,
    } = this.props;
    if (!currentConversation) return null;

    const { counterParty, counterPartyType } = currentConversation;
    if (!counterParty) return null;

    const { entity, entityType } = getRecipientEntity({
      entity: counterParty,
      entityType: counterPartyType,
    });

    if (!entity || !entityType) return null;

    const componentOptions = {
      conversation: currentConversation,
      videoCallSetUp,
      organizationId: currentConversation.organizationId,
      isGroupVideoCallEnabled,
      isGroupVoiceCallEnabled,
      isInfoPaneOpen,
      isVideoCallEnabled,
      isVoiceCallEnabled,
    };

    let ConversationHeaderComponent;
    if (entityType === 'group' && entity.groupType === 'PATIENT_MESSAGING') {
      ConversationHeaderComponent = PatientConversationHeader;
      componentOptions.members = counterParty.members;
    } else if (entityType === 'team') {
      return null;
    } else {
      ConversationHeaderComponent = this.COMPONENT_BY_ENTITY_TYPE[entityType];
    }

    invariant(ConversationHeaderComponent, `unknown counterPartyType: '${entityType}'`);

    componentOptions[entityType] = entity;
    if (entityType === 'role') {
      componentOptions.user =
        entity.members && entity.members.length === 1 ? entity.members[0] : entity.botUser;
    }

    return <ConversationHeaderComponent {...componentOptions} />;
  }
}

export default mobxInjectSelect({
  callStore: [
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isVideoCallEnabled',
    'isVoiceCallEnabled',
    'videoCallSetUp',
  ],
  conversationStore: ['currentConversation'],
  messengerStore: ['isInfoPaneOpen'],
})(ConversationHeader);
