import Enum from '../utils/enum';

const statuses = ['ONLINE', 'OFFLINE', 'CONNECTING', 'UNREACHABLE'] as const;

type __Status = {
  [k in typeof statuses[number]]: k;
};

const NetworkStatus = new Enum(statuses) as unknown as __Status;

export default NetworkStatus;
