import Camelizer from '../utils/Camelizer';
import {
  ClearCompletedVisits,
  CreateRoom,
  CreatedVisitResponse,
  FindAllVisitsRequest,
  FindAllOptions,
  GetVisitRequest,
  InformProvider,
  MarkCallAsCompleted,
  RejoinVisit,
  RejoinVisitWithAuthForm,
  UpdateRoom,
  UpdateVisit,
} from '../types/VirtualWaitingRoom';
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

const GET_ROOMS_LIMIT = 1000;

const DEFAULT_VISIT_SORT = 'visitorJoinedAt,desc';

export default class VirtualWaitingRoomAPI extends BaseAPI {
  @recoverFromNotFound()
  async findAll(
    organizationId: string,
    { showAll, renderUnreadMessageCount }: FindAllOptions = {
      showAll: false,
      renderUnreadMessageCount: false,
    }
  ) {
    let url = `/vwr/v1/rooms?size=${GET_ROOMS_LIMIT}`;
    if (showAll) {
      url += '&show_all=true';
    }

    if (renderUnreadMessageCount) {
      url += '&render_unread_message_count=true';
    }
    const res = await this.httpClient.get(url, {
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async find(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get(`/vwr/v1/room/:id`, {
      headers,
      urlParams: {
        id,
      },
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async create(organizationId: string, { staff, name }: CreateRoom) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const data = Camelizer.underscoreObject({ staff, name });
    const res = await this.httpClient.post(`/vwr/v1/room`, {
      headers,
      data,
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async update(id: string, organizationId: string, options: UpdateRoom) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const data = Camelizer.underscoreObject(options);
    const { sourceId } = options;
    const res = await this.httpClient.put(`/vwr/v1/room/:id?source_id=:sourceId`, {
      headers,
      urlParams: {
        id,
        sourceId,
      },
      data,
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async delete(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.del(`/vwr/v1/room/:id`, {
      headers,
      urlParams: {
        id,
      },
    });

    return Camelizer.camelizeObject(res.data);
  }

  async getVisitInfo(joinId: string) {
    const res = await this.httpClient.get('/vwr/v1/visit/join?join_id=:joinId', {
      urlParams: {
        joinId,
      },
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async createVisit({
    firstName,
    joinId,
    lastName,
    mobilePhone,
    organizationId,
    visitReasonKey,
  }: {
    firstName: string;
    joinId: string;
    lastName: string;
    mobilePhone: string;
    organizationId: string;
    visitReasonKey?: string;
  }): Promise<CreatedVisitResponse> {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.post('/vwr/v1/visit/join?join_id=:joinId', {
      headers,
      data: Camelizer.underscoreObject({
        firstName,
        lastName,
        mobilePhone,
        visitReasonKey,
        wantProcessingEvents: true,
        wantCondensedReplays: true,
      }),
      urlParams: {
        joinId,
      },
    });

    return Camelizer.camelizeObject(res.data);
  }

  async rejoinVisit({ rejoinToken, sessionTokens }: RejoinVisit) {
    const data = Camelizer.underscoreObject({
      rejoinToken,
      sessionTokens,
      wantProcessingEvents: true,
      wantCondensedReplays: true,
    });

    const res = await this.httpClient.post('/vwr/v1/visit/rejoin', {
      data,
    });
    return Camelizer.camelizeObject(res.data);
  }

  async rejoinWithAuthForm({ rejoinToken, challengeAnswers }: RejoinVisitWithAuthForm) {
    const data = Camelizer.underscoreObject({
      rejoinToken,
      challengeAnswers,
      wantProcessingEvents: true,
      wantCondensedReplays: true,
    });
    const res = await this.httpClient.put('/vwr/v1/visit/rejoin', {
      data,
    });

    return Camelizer.camelizeObject(res.data);
  }

  async cleanupVisits({ type, roomId, organizationId }: ClearCompletedVisits) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    await this.httpClient.put('/vwr/v1/room/:roomId/cleanup', {
      headers,
      data: { type },
      urlParams: {
        roomId,
      },
    });
  }

  async getVisit(organizationId: string, { visitId }: GetVisitRequest) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/vwr/v1/visit/:visitId', {
      headers,
      urlParams: {
        visitId,
      },
    });

    return Camelizer.camelizeObject(res.data);
  }

  async findAllVisits({
    roomId,
    organizationId,
    sort = DEFAULT_VISIT_SORT,
    page = 0,
    status = 'INCOMPLETE',
  }: FindAllVisitsRequest) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/vwr/v1/room/:roomId/visits', {
      headers,
      urlParams: {
        roomId,
      },
      query: {
        sort,
        status,
        page,
        size: 50,
      },
    });

    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async informProvider(
    organizationId: string,
    { roomId, visitId, accountToken, vwrConversationId }: InformProvider
  ) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const data = Camelizer.underscoreObject({
      conversationId: vwrConversationId,
      provider: accountToken,
    });
    const res = await this.httpClient.post(`/vwr/v1/room/:roomId/visit/:visitId/call_invite`, {
      headers,
      urlParams: {
        roomId,
        visitId,
      },
      data,
    });
    return res.data;
  }

  @recoverFromNotFound()
  async updateVisit(organizationId: string, { roomId, visitId, options }: UpdateVisit) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.put(`/vwr/v1/room/:roomId/visit/:visitId`, {
      headers,
      urlParams: {
        roomId,
        visitId,
      },
      data: Camelizer.underscoreObject(options),
    });
    return Camelizer.camelizeObject(res.data);
  }

  @recoverFromNotFound()
  async markCallAsCompleted(
    organizationId: string,
    { callId, reason, roomId, target, visitId }: MarkCallAsCompleted
  ) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    await this.httpClient.post('/vwr/v1/room/:roomId/visit/:visitId/call_completed', {
      headers,
      urlParams: {
        roomId,
        visitId,
      },
      data: Camelizer.underscoreObject({
        callId,
        target,
        reason,
      }),
    });
  }

  async getStats(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/vwr/v1/visit/stats/total_new', {
      headers,
    });

    return res.data;
  }
}
