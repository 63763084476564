import React, { useEffect, useState } from 'react';

import { ScheduledMessagesView } from './';

const ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  SEARCH: 'search',
  VIEW: 'view',
};

type ScheduledMessagesProps = {
  feed: string;
  isAdmin: boolean;
  setCurrentAdminPageRefresher: (fn: () => void) => void;
};

const ScheduledMessages: React.FC<ScheduledMessagesProps> = ({
  feed,
  isAdmin,
  setCurrentAdminPageRefresher,
}) => {
  const [action, setAction] = useState(ACTIONS.SEARCH);
  const [id, setId] = useState<string | null>(null);

  useEffect(() => {
    setAction(ACTIONS.SEARCH);
    setId(null);
  }, [feed]);

  // const searchScheduledMessages = () => {
  //   setId(null)
  //   setAction(ACTIONS.SEARCH)
  // }

  const viewScheduledMessage = (id: string) => {
    setId(id);
    setAction(ACTIONS.VIEW);
  };

  switch (action) {
    case ACTIONS.CREATE:
    case ACTIONS.EDIT:
    case ACTIONS.SEARCH:
      return (
        <ScheduledMessagesView
          feed={feed}
          isAdmin={isAdmin}
          viewScheduledMessage={viewScheduledMessage}
          setCurrentAdminPageRefresher={setCurrentAdminPageRefresher}
        />
      );
    case ACTIONS.VIEW:
      return (
        <div>
          Not yet implemented {action} {id}
        </div>
      );
    default:
      return <></>;
  }
};

export default ScheduledMessages;
