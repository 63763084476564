import { isEqual, isUndefined } from 'lodash';
import { OrganizationWideSettingOption, ServerBool } from './types';

export function fixArray<T>(val?: null | T[] | T) {
  if (!val) {
    return [];
  } else {
    if (Array.isArray(val)) {
      return val;
    } else {
      return [val];
    }
  }
}

/**
 * Parses an unknown value to a standard TigerConnect organization access-level restriction value
 * @param setting The raw setting value obtained from a network request
 */
export function parseOrganizationWideSettingOption(
  setting: unknown
): OrganizationWideSettingOption {
  if (setting === 'all' || setting === 'individual') {
    return setting;
  } else {
    return 'off';
  }
}

/**
 * Parses an unknown value to a boolean accounting for string numbers or boolean values
 * @param setting The raw setting value obtained from a network request
 */
export function parseBooleanSettingOption(setting: unknown) {
  return setting === '1' || setting === true || setting === 'true';
}

/**
 * Parses an unknown value to a boolean accounting for string numbers or boolean values
 * @param setting The raw setting value obtained from a network request
 */
export function formatBooleanSettingOption(setting: boolean): ServerBool {
  if (typeof setting !== 'boolean') return undefined;
  return setting ? '1' : '0';
}

/**
 * Parses an unknown value to a numeric type falling back to a default value
 * @param setting The raw setting value obtained from a network request
 * @param defaultValue A default integer value to use in lieu of a parseable value
 */
export function parseNumberSettingOption(setting: unknown, defaultValue = 0) {
  if (setting) {
    return Number(setting);
  } else {
    return defaultValue;
  }
}

/**
 * Parses an unknown value to an appropriate paid org setting value, defaulting to 'free'
 * @param setting The raw setting value obtained from a network request
 */
export function parsePaidStatus(setting: unknown) {
  return setting === 'active' ? 'paid' : 'free';
}

export function parseLoginType(setting: unknown) {
  return setting === 'otp' ? 'Username & OTP' : 'Username & Password';
}

export function parseIfDefined<ValueType, ParsedType>(
  valueToParse: ValueType | undefined,
  parseFn: (valueToParse: ValueType) => ParsedType
) {
  if (isUndefined(valueToParse)) return undefined;
  return parseFn(valueToParse);
}

/**
 * Formats a paid status UI value to an appropriate server org setting value, defaulting to 'inactive'
 * @param setting The raw setting value obtained from a network request
 */
export function formatPaidStatus(setting?: 'paid' | 'free') {
  return setting === 'paid' ? 'active' : setting === 'free' ? 'inactive' : undefined;
}

export function formatLoginType(setting?: 'Username & Password' | 'Username & OTP' | unknown) {
  return setting === 'Username & Password'
    ? 'password'
    : setting === 'Username & OTP'
    ? 'otp'
    : undefined;
}

/**
 * Parses an unknown value to an appropriate pin lock setting value, defaulting to 0
 * @param setting The raw setting value obtained from a network request
 */
export function parsePinLock(setting: unknown) {
  return Array.isArray(setting) ? -1 : Number(setting);
}

/**
 * Parses an unknown value to an appropriate fast-deploy setting value, defaulting to 'email'
 * @param setting The raw setting value obtained from a network request
 */
export function parseFastDeployValue(setting: unknown) {
  return setting === 'username' ? 'username' : 'email';
}

/**
 * Parses an unknown value to an appropriate tigerconnect patient engagement setting value, defaulting to 'all'
 * @param setting The raw setting value obtained from a network request
 */
export function parsePatientEngagementValue(setting: unknown) {
  return setting === 'individual' ? 'individual' : 'all';
}

export function parseC2CNumberMaskType(setting: unknown) {
  return setting === 'custom' ? 'custom' : 'twilio';
}

export function parseAuthType(setting?: string) {
  switch (setting) {
    case 'basic':
    case 'ldap':
    case 'ntlm':
    case 'saml':
      return setting;
    default:
      return 'native';
  }
}

export function parseArchiveNetwork({
  allowPatient,
  allowProvider,
}: {
  allowPatient?: boolean;
  allowProvider?: boolean;
}) {
  if (allowPatient && allowProvider) {
    return 'all';
  } else if (allowPatient && !allowProvider) {
    return 'patient';
  } else {
    return 'provider';
  }
}

const StringType = (value: unknown) => Object.prototype.toString.call(value).slice(8, -1);

type Primitive = string | number | null | boolean | undefined;

const isPrimitive = (value: unknown): value is Primitive =>
  ['String', 'Number', 'Null', 'Boolean', 'Undefined'].includes(StringType(value));

type ArrayLike = (Primitive | ObjectLike | ArrayLike)[];

const isArrayLike = (value: unknown): value is ArrayLike => StringType(value) === 'Array';

export type ObjectLike = {
  [k: string]: Primitive | ArrayLike | ObjectLike;
};

const isObjectLike = (value: unknown): value is ObjectLike => StringType(value) === 'Object';

/*
 * Return what has changed in right from left.
 */
export const difference = <X extends ObjectLike, Y extends ObjectLike, Z extends ObjectLike>(
  left: X,
  right: Y
): Z => {
  const result = Object.keys(right).reduce((memo, key) => {
    const leftVal = left[key];
    const rightVal = right[key];

    if (StringType(leftVal) !== StringType(rightVal)) (memo as ObjectLike)[key] = rightVal;

    if (isPrimitive(rightVal) && isPrimitive(leftVal)) {
      if (rightVal !== leftVal) (memo as ObjectLike)[key] = rightVal;
    }

    if (isArrayLike(rightVal) && isArrayLike(leftVal)) {
      const same = isEqual(leftVal, rightVal);
      if (!same) (memo as ObjectLike)[key] = rightVal;
    }

    if (isObjectLike(rightVal) && isObjectLike(leftVal)) {
      const o2 = difference(leftVal, rightVal);
      const o = difference(rightVal, leftVal);
      if (Object.keys(o).length > 0 || Object.keys(o2).length > 0) {
        (memo as ObjectLike)[key] = rightVal;
      }
    }

    return memo;
  }, {} as Z);

  return result;
};
