// @ts-nocheck
import _ from 'lodash';
import { decorator as reusePromise } from 'reuse-promise';
import * as errors from '../errors';
import { arrayWrap } from '../utils';
import BaseService from './BaseService';

export default class MetaDataService extends BaseService {
  /**
   * Updates entire metadata of an entity
   * @param  {string|Object}       id         Entity or ID
   * @param  {Object}              entireData Entire metadata of entity
   * @return {Promise.<void, Error>} A promise with no data
   */
  async fullUpdate(id: string | Object, entireData: Object, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    if (
      this.host.config.maxMetadataKeyCount > 0 &&
      Object.keys(entireData).length > this.host.config.maxMetadataKeyCount
    ) {
      throw new errors.ValidationError(
        'entireData',
        'invalid',
        `Total number of metadata keys is ${Object.keys(entireData)} and should not exceed ${
          this.host.config.maxMetadataKeyCount
        }`
      );
    }

    for (const [key, value] of Object.entries(entireData)) {
      if (typeof value !== 'string') {
        throw new errors.ValidationError(
          'value',
          'invalid',
          `Value for metadata key ${key} must be a string`
        );
      }
    }

    this.host.requireUser();
    id = this._resolveModelId(id);

    try {
      await this.host.api.metadata.update(id, entireData, organizationId);
      this.__injectMetadata(id, organizationId, entireData);
      return entireData;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        throw new errors.NotFoundError('metadata', id);
      } else {
        return Promise.reject(ex);
      }
    }
  }

  /**
   * Updates entity's metadata partially by fetching it before setting
   * @param  {string|Object} id      Entity or ID
   * @param  {Object} partialData    Object with some keys and values
   * @return {Promise.<void, Error>} A promise with no data
   */
  async update(id: string | Object, partialData: Object, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const currentData = await this.find(id, organizationId, { bypassCache: true });
    const newData = Object.assign({}, currentData, partialData);
    return this.fullUpdate(id, newData, organizationId);
  }

  /**
   * Finds entity's metadata
   * @param  {string|Object} id      Entity or ID
   * @return {Promise.<Object,Error>}      A promise that resolves with the metadata
   */
  @reusePromise()
  async find(id: string | Object, organizationId: string, options = {}) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: false });

    this.host.requireUser();
    id = this._resolveModelId(id);
    let data;

    if (!options.bypassCache) {
      data = this.get(id, organizationId);
      if (data) return data;
    }

    try {
      data = await this.host.api.metadata.find(id, organizationId);
      this.__injectMetadata(id, organizationId, data);

      return data;
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        if (!options.ignoreNotFound) {
          throw new errors.NotFoundError('metadata', id);
        }
      } else {
        return Promise.reject(ex);
      }
    }
  }

  /**
   * Bulk get metadata
   * @param  {Array<string|Object>} ids - array of ids
   * @return {Promise.<Object,Error>} - a promise with object which its keys are the ids and values are the metadata objects
   */
  @reusePromise()
  async findMulti(ids: Array<string | Object>, organizationId: string, options = {}) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    this.host.requireUser();
    ids = _.uniq(arrayWrap(ids).map(this._resolveModelId));

    // server allows combined users (0) + metadata (20) = 20 maximum items per request
    const idGroupChunks = _.chunk(ids, 20);

    const promises = idGroupChunks.map((idsInGroup) =>
      this.__findMultiPaged(idsInGroup, organizationId, options)
    );

    const allResults = await Promise.all(promises);

    const result = _.assign({}, ...allResults);

    return result;
  }

  async __findMultiPaged(ids: string[], organizationId: string, options = {}) {
    const { metadata: allMetadata } = await this.host.api.entities.findMulti({
      users: [],
      metadata: ids,
      organizationId,
    });
    const results = {};

    for (const data of allMetadata) {
      for (const [id, metadata] of Object.entries(data)) {
        if (!metadata) continue;
        const entity = this.__injectMetadata(id, organizationId, metadata);
        results[id] = entity;
      }
    }

    return results;
  }

  get(entityId: string, organizationId: string) {
    const key = `metadata:${entityId}:${organizationId}`;
    const metadata = this.host.models.Metadata.get(key);
    return metadata || null;
  }

  __injectMetadata(entityId: string, organizationId: string, data: Object) {
    const id = `metadata:${entityId}:${organizationId}`;

    return this.host.models.Metadata.inject({
      id,
      data,
      entityId,
      organizationId,
    });
  }

  __parseMessageMetadata = (entry) => {
    let { mimetype, payload } = entry;
    const mimetypeIdx = mimetype.indexOf(';');
    if (mimetypeIdx > -1) {
      mimetype = mimetype.slice(0, mimetypeIdx);
    }

    if (mimetype === 'application/json' && typeof payload === 'string') {
      let payloadObject;

      try {
        payloadObject = JSON.parse(payload);
      } catch (e) {
        console.warn('Invalid metadata payload', entry);
        return null;
      }

      return {
        ...entry,
        payload: payloadObject,
      };
    } else {
      return entry;
    }
  };
}
