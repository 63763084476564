// @ts-nocheck
const noop = function () {};

export default class XHRWrapper {
  constructor(xhr) {
    this.withCredentials = false;
    this.responseType = '';
    this.readyState = 0;
    this.status = 0;
    this.statusText = '';
    this.responseText = '';
    this.onprogress = noop;
    this.onreadystatechange = noop;
    this._contentType = '';
    this._xhr = xhr;
    this._sendTimeout = 0;
    this._abort = noop;
  }

  open(method, url) {
    this._abort(true);

    const that = this;
    const xhr = this._xhr;
    let state = 1;
    let timeout = 0;

    this._abort = function (silent) {
      if (that._sendTimeout !== 0) {
        clearTimeout(that._sendTimeout);
        that._sendTimeout = 0;
      }
      if (state === 1 || state === 2 || state === 3) {
        state = 4;
        xhr.onload = noop;
        xhr.onerror = noop;
        xhr.onabort = noop;
        xhr.onprogress = noop;
        xhr.onreadystatechange = noop;
        // IE 8 - 9: XDomainRequest#abort() does not fire any event
        // Opera < 10: XMLHttpRequest#abort() does not fire any event
        xhr.abort();
        if (timeout !== 0) {
          clearTimeout(timeout);
          timeout = 0;
        }
        if (!silent) {
          that.readyState = 4;
          that.onreadystatechange();
        }
      }
      state = 0;
    };

    const onStart = function () {
      if (state === 1) {
        //state = 2
        let statusText = '';
        let contentType;
        let status = 0;

        if (!('contentType' in xhr)) {
          try {
            status = xhr.status;
            statusText = xhr.statusText;
            contentType = xhr.getResponseHeader('Content-Type');
          } catch (error) {
            // IE < 10 throws exception for `xhr.status` when xhr.readyState === 2 || xhr.readyState === 3
            // Opera < 11 throws exception for `xhr.status` when xhr.readyState === 2
            // https://bugs.webkit.org/show_bug.cgi?id=29121
            status = 0;
            statusText = '';
            contentType = undefined;
            // Firefox < 14, Chrome ?, Safari ?
            // https://bugs.webkit.org/show_bug.cgi?id=29658
            // https://bugs.webkit.org/show_bug.cgi?id=77854
          }
        } else {
          status = 200;
          statusText = 'OK';
          contentType = xhr.contentType;
        }

        if (status !== 0) {
          state = 2;
          that.readyState = 2;
          that.status = status;
          that.statusText = statusText;
          that._contentType = contentType;
          that.onreadystatechange();
        }
      }
    };

    const onProgress = function () {
      onStart();

      if (state === 2 || state === 3) {
        state = 3;
        let responseText = '';
        try {
          responseText = xhr.responseText;
        } catch (error) {
          // IE 8 - 9 with XMLHttpRequest
        }
        that.readyState = 3;
        that.responseText = responseText;
        that.onprogress();
      }
    };

    const onFinish = function () {
      // Firefox 52 fires 'readystatechange' (xhr.readyState === 4) without final 'readystatechange' (xhr.readyState === 3)
      // IE 8 fires 'onload' without 'onprogress'
      onProgress();
      if (state === 1 || state === 2 || state === 3) {
        state = 4;
        if (timeout !== 0) {
          clearTimeout(timeout);
          timeout = 0;
        }
        that.readyState = 4;
        that.onreadystatechange();
      }
    };

    const onReadyStateChange = function () {
      if (xhr) {
        // Opera 12
        if (xhr.readyState === 4) {
          onFinish();
        } else if (xhr.readyState === 3) {
          onProgress();
        } else if (xhr.readyState === 2) {
          onStart();
        }
      }
    };

    const onTimeout = function () {
      timeout = setTimeout(function () {
        onTimeout();
      }, 500);
      if (xhr.readyState === 3) {
        onProgress();
      }
    };

    // XDomainRequest#abort removes onprogress, onerror, onload
    xhr.onload = onFinish;
    xhr.onerror = onFinish;
    // improper fix to match Firefox behaviour, but it is better than just ignore abort
    // see https://bugzilla.mozilla.org/show_bug.cgi?id=768596
    // https://bugzilla.mozilla.org/show_bug.cgi?id=880200
    // https://code.google.com/p/chromium/issues/detail?id=153570
    // IE 8 fires 'onload' without 'onprogress
    xhr.onabort = onFinish;

    // https://bugzilla.mozilla.org/show_bug.cgi?id=736723
    if (!('sendAsBinary' in XMLHttpRequest.prototype) && !('mozAnon' in XMLHttpRequest.prototype)) {
      xhr.onprogress = onProgress;
    }

    // IE 8 - 9 (XMLHTTPRequest)
    // Opera < 12
    // Firefox < 3.5
    // Firefox 3.5 - 3.6 - ? < 9.0
    // onprogress is not fired sometimes or delayed
    // see also #64
    xhr.onreadystatechange = onReadyStateChange;

    if ('contentType' in xhr) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + 'padding=true';
    }
    xhr.open(method, url, true);

    if ('readyState' in xhr) {
      // workaround for Opera 12 issue with 'progress' events
      // #91
      timeout = setTimeout(function () {
        onTimeout();
      }, 0);
    }
  }

  abort() {
    this._abort(false);
  }

  getResponseHeader(name) {
    return this._contentType;
  }

  setRequestHeader(name, value) {
    const xhr = this._xhr;
    if ('setRequestHeader' in xhr) {
      xhr.setRequestHeader(name, value);
    }
  }

  getAllResponseHeaders() {
    return this._xhr.getAllResponseHeaders ? this._xhr.getAllResponseHeaders() : '';
  }

  send() {
    // loading indicator in Safari < ? (6), Chrome < 14, Firefox
    if (
      !('ontimeout' in XMLHttpRequest.prototype) &&
      document &&
      document.readyState &&
      document.readyState !== 'complete'
    ) {
      const that = this;
      that._sendTimeout = setTimeout(function () {
        that._sendTimeout = 0;
        that.send();
      }, 4);
      return;
    }

    const xhr = this._xhr;
    // withCredentials should be set after 'open' for Safari and Chrome (< 19 ?)
    xhr.withCredentials = this.withCredentials;
    xhr.responseType = this.responseType;
    try {
      // xhr.send() throws 'Not enough arguments' in Firefox 3.0
      xhr.send(undefined);
    } catch (error1) {
      // Safari 5.1.7, Opera 12
      throw error1;
    }
  }
}
