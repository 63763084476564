// @ts-nocheck
import _ from 'lodash';
import { decorator as reusePromise } from 'reuse-promise';
import { Camelizer, formatSavedEvent, jsonCloneDeep } from '../utils';
import * as errors from '../errors';
import { TagOptions } from '../types/Tags';
import BaseService from './BaseService';

export default class TagsService extends BaseService {
  async createTag(options: TagOptions) {
    const { color, name, organizationId } = options;
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!color) throw new errors.ValidationError('color', 'required');
    if (!name) throw new errors.ValidationError('name', 'required');

    const res = await this.host.api.tags.createTag({ color, name, organizationId });
    return res.token;
  }

  async updateTag(tagId: string, options: TagOptions) {
    const { organizationId } = options;
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!tagId) throw new errors.ValidationError('tagId', 'required');

    const res = await this.host.api.tags.updateTag(tagId, options);
    return res;
  }

  async deleteTags(tagId: string | string[], organizationId: string) {
    const ids = Array.isArray(tagId) ? tagId : [tagId];
    for (const id of ids) {
      await this.host.api.tags.destroy(organizationId, id);
    }
    return;
  }

  async fetchTotals(organizationId: string) {
    this.host.requireUser();
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    return await this.host.api.tags.fetchTotals(organizationId, this.host.currentUserId);
  }

  async findAll(options = {}) {
    options = _.defaults(options, { ignoreNotFound: true, bypassCache: false });
    let organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) {
      organizations = await this.host.organizations.findAll();
    }
    const results = await Promise.all(
      organizations.map((organization) => this.findAllForOrganization(organization.id, options))
    );

    return _.flatten(results);
  }

  async findColors(organizationId: string) {
    const colors = await this.host.api.tags.findColors(organizationId);
    return Camelizer.camelizeObject(colors);
  }

  @reusePromise()
  async saveTag(tagId: string, organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    await this.host.api.tags.saveTag(tagId, this.host.currentUserId, organizationId);
  }

  @reusePromise()
  async removeSavedTag(tagId: string, organizationId: string) {
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    await this.host.api.tags.removeSavedTag(tagId, this.host.currentUserId, organizationId);
  }

  @reusePromise()
  async findTag(tagId: string, organizationId: string, options: Object = {}) {
    options = _.defaults(options, { ignoreNotFound: false });
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    const result = await this.host.api.tags.findTag(organizationId, tagId);

    if (!result) {
      if (options.ignoreNotFound) return;
      throw new errors.NotFoundError('tag', tagId);
    }

    const id = `${organizationId}:${tagId}`;
    const tag = this.host.models.Tag.inject({ ...result, id, organizationId });

    return tag;
  }

  @reusePromise()
  async findSavedTags(organizationId: string, options: Object = {}) {
    options = _.defaults(options, { ignoreNotFound: false });
    this.host.requireUser();
    organizationId = this._resolveModelId(organizationId);

    const results = await this.host.api.tags.findSavedTags(this.host.currentUserId, organizationId);

    if (!results) {
      if (options.ignoreNotFound) return;
      throw new errors.NotFoundError('saved tags', organizationId);
    }

    const tags = results.map((entry) => {
      const id = `${organizationId}:${entry['token']}`;
      return this.host.models.Tag.inject({ ...entry, id, organizationId });
    });

    this.host.models.Organization.touch(organizationId);

    return tags;
  }

  @reusePromise()
  async findAllForOrganization(organizationId: string | Object, options = {}) {
    options = _.defaults(options, { ignoreNotFound: true, bypassCache: false });
    organizationId = this._resolveModelId(organizationId);
    const organization = this._resolveEntity(organizationId);

    if (!organization.rolesEnabled || !organization.canAdminRoles) return [];

    const data = await this.host.api.tags.findAll(organizationId);

    if (!data) {
      if (options.ignoreNotFound) return [];
      throw new errors.NotFoundError('tags', organizationId);
    }

    const result = data.map((entry) => {
      const id = `${organizationId}:${entry['tag_id']}`;
      return this.host.models.Tag.inject({ ...entry, id, organizationId });
    });

    this.host.models.Organization.touch(organizationId);

    return result;
  }

  reactToSavedEvent(data) {
    this.emit('saved', formatSavedEvent(data));
  }

  reactToUpdateEventCR({ data }) {
    data = jsonCloneDeep(data);
    const { entity, organization_id: organizationId } = data;
    const { color, display_name: name } = entity;
    const tagId = entity.id || entity.token;

    const { color_id: colorId, color_value: colorValue } = color;

    const tag = this.host.models.Tag.inject({
      color: colorId,
      colorValue,
      id: `${organizationId}:${tagId}`,
      name,
      organizationId,
      tagId,
    });

    return tag;
  }

  reactToUpdateEvent({ data }) {
    if (this.config.condensedReplays && data.entity) {
      this.reactToUpdateEventCR({ data });
      return;
    }

    data = jsonCloneDeep(data);
    const { color, display_name: name, organization_key: organizationId, token: tagId } = data;

    const { color_id: colorId, color_value: colorValue } = color;

    const tag = this.host.models.Tag.inject({
      color: colorId,
      colorValue,
      id: `${organizationId}:${tagId}`,
      name,
      organizationId,
      tagId,
    });

    return tag;
  }

  getAll() {
    return this.host.models.Tag.getAll();
  }
  getById(id: string) {
    return this.host.models.Tag.get(id);
  }
}
