import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { ReactComponent as EscalateSvg } from '../images/icon-escalation-outlined.svg';
import Modal from './Modal';

const classes = BEM.with('AlwaysEscalateModal');
const IMG_HEADER = {
  image: EscalateSvg,
  fill: '#fff',
  imgClassHeader: classes('img'),
};

class AlwaysEscalateModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  render() {
    const { closeModal, isOpen } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        hasCloseButton={false}
        header={'Escalation Enabled'}
        headerClass={classes('header')}
        imgHeader={IMG_HEADER}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium-small'}
      >
        <div className={classes('body-text')}>
          {"This message is automatically escalated according to your organization's" +
            ' administration settings.'}
        </div>
        <div onClick={closeModal} className={classes('button')}>
          GOT IT
        </div>
      </Modal>
    );
  }
}

export default mobxInjectSelect({
  modalStore: ['openModal'],
})(AlwaysEscalateModal);
