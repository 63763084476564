import EventEmitter from 'events';
import { NotFoundError, PermissionDeniedError, WrongCredentialsError } from '../errors';

const NOT_FOUND_STATUS_CODES = [403, 404];

export const ROUTES = {
  CONVERSATION_FIND_ALL: 'conversations.findAll',
  DISTRIBUTION_LIST_FIND: 'distributionLists.find',
  ENTITIES_FIND_ALL: 'entities.findAll',
  ESCALATION: 'escalation',
  EVENTS_ACK: 'events.ack',
  EVENTS_CLOSE_ALL_CONNECTIONS: 'events.closeAllConnections',
  GROUP_ADD_MEMBERS: 'group.addMembers',
  GROUP_CREATE: 'group.create',
  GROUP_DESTROY: 'group.destroy',
  GROUP_FIND: 'group.find',
  GROUP_FIND_MEMBER_IDS: 'group.findMemberIds',
  GROUP_UPDATE: 'group.update',
  MESSAGE_FIND: 'messages.find',
  MESSAGE_FIND_RECIPIENT_STATUS: 'messages.findRecipientStatus',
  MESSAGE_FIND_RECIPIENT_STATUS_MULTI: 'messages.findRecipientStatusMulti',
  MESSAGE_FORWARD: 'message.forward',
  MESSAGE_SEND: 'message.send',
  MESSAGE_UPDATE_RECIPIENT_STATUS_MULTI: 'messages.updateRecipientStatusMulti',
  METADATA_FIND: 'metadata.find',
  MUTE_FIND_ALL: 'mute.findAll',
  ORGANIZATION_FIND: 'organization.find',
  ORGANIZATION_FIND_ALL_OF_USER: 'organization.findAllOfUser',
  PRESENCE_FIND_MULTI: 'presence.findMulti',
  ROLES_CREATE_P2P_GROUP: 'roles.createP2PGroup',
  SEARCH_QUERY: 'search.query',
  TEAMS_CREATE: 'teams.create',
  TEAMS_FIND: 'teams.find',
  TEAMS_LEAVE: 'teams.leave',
  TEAMS_UPDATE: 'teams.update',
  USER_FIND: 'user.find',
  USER_FIND_ME: 'user.findMe',
};

export const VERSIONS = {
  VERSION_TWO: 'v2',
  VERSION_THREE: 'v3',
  VERSION_FIVE: 'v5',
  VERSION_SIX: 'v6',
  VERSION_EIGHT: 'v8',
} as const;

type VersionRoute = typeof VERSIONS[keyof typeof VERSIONS];
export type VersionNumber = keyof typeof VERSIONS;

export type HttpClientMethod = { [k in keyof HttpClient]: k }[keyof HttpClient];

type HttpClientRequest = {
  headers?: Record<string, unknown>;
  data?: unknown;
  files?: unknown;
  resFormat?: string;
  query?: unknown;
  urlParams?: unknown;
  withCredentials?: boolean;
};

export type ResponseType = 'json' | 'text';
export type ResponseWithStatus<Res> = { status: number; data: Res };

type HttpClientFunc = <Res = Record<string, unknown>, ResType extends ResponseType = 'json'>(
  url: string,
  req: HttpClientRequest
) => Promise<ResType extends 'json' ? ResponseWithStatus<Res> : Res>;

type HttpClient = {
  del: HttpClientFunc;
  get: HttpClientFunc;
  put: HttpClientFunc;
  post: HttpClientFunc;
  patch: HttpClientFunc;
  getAuth: () => { key: string; secret: string };
};

type Host = {
  api: {
    admin: {
      initiateAdminSession: (...args: unknown[]) => Promise<{
        session_id: string;
        cn_server: string;
        resource: string;
        xmpp_password: string;
        lastLogin: number;
      }>;
    };
  };
  config: { condensedReplays: unknown };
  httpClient: HttpClient;
  currentUserId: string;
  cnUrl: string;
  tcUrl: string;
};

export default class BaseAPI {
  config: { condensedReplays: unknown };
  host: Host;
  httpClient: HttpClient;

  constructor(host: Host, options: unknown) {
    Object.assign(this, options);
    this.config = host.config;
    this.host = host;
    this.httpClient = host.httpClient;
  }

  getVersion(_route: string, version?: VersionNumber): { version: VersionRoute } {
    const { VERSION_TWO, VERSION_FIVE } = VERSIONS;
    if (version) return { version: VERSIONS[version] };
    if (this.config.condensedReplays) {
      return { version: VERSION_FIVE };
    }
    return { version: VERSION_TWO };
  }
}

Object.assign(BaseAPI.prototype, (EventEmitter as unknown as typeof Object).prototype);

export function recoverFromNotFound({
  fallbackValue = null,
  httpStatusCodes = NOT_FOUND_STATUS_CODES,
}: { fallbackValue?: unknown; httpStatusCodes?: number[] } = {}) {
  return function decorator(_target: unknown, _name: unknown, descriptor: PropertyDescriptor) {
    const fn = descriptor.value;
    descriptor.value = async function (...args: unknown[]) {
      try {
        return await fn.call(this, ...args);
      } catch (ex) {
        if (ex.response && httpStatusCodes.includes(ex.response.status)) {
          return fallbackValue;
        } else if (ex.code === WrongCredentialsError.CODE && httpStatusCodes.includes(401)) {
          return fallbackValue;
        } else if (ex.code === PermissionDeniedError.CODE && httpStatusCodes.includes(403)) {
          return fallbackValue;
        } else if (ex.code === NotFoundError.CODE && httpStatusCodes.includes(404)) {
          return fallbackValue;
        } else {
          throw ex;
        }
      }
    };
    return descriptor;
  };
}
