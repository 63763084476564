// @ts-ignore
// @ts-nocheck
// If you modify this, please also update ./check-polyfills.js
// @ts-expect-error Cannot redeclare block-scoped variable 'getGlobals'. (tsserver 2451)
const { getGlobals } = require('./globals');
const globals = getGlobals();

require('core-js/features/array/find');
require('core-js/features/array/from');
require('core-js/features/array/includes');
require('core-js/features/object/assign');
require('core-js/features/object/entries');
require('core-js/features/object/values');

if (process.env.NODE_TARGET === 'web') {
  if (typeof Promise === 'undefined' || !('finally' in Promise.prototype)) {
    require('promise/lib/rejection-tracking').enable();
    globals.Promise = require('promise/lib/es6-extensions.js');
  }
}

if (process.env.NODE_TARGET === 'web') {
  require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');
  require('./fetch-browser-polyfill');
} else {
  if (typeof fetch === 'undefined') {
    const nodeFetch = require('@tigerconnect/node-fetch');
    global.fetch = nodeFetch.default;
    global.Headers = nodeFetch.Headers;
    global.Request = nodeFetch.Request;
    global.Response = nodeFetch.Response;

    require('abortcontroller-polyfill/dist/polyfill-patch-fetch');
  }
}

// performance.measure does not return a PerformanceMeasure before Chrome 78
if (process.env.NODE_TARGET === 'web') {
  let check: PerformanceMeasure | undefined = undefined;
  try {
    check = performance.measure('compat-check', { start: 0, duration: 0 });
  } catch (e) {
    // performance.measure measureOptions paramater is not supportedd in Chrome < 78
    // and will throw an error, but it may be possible that another version does not
    // throw, but also does not return PerformanceMeasure, so we assign the polyfill
    // in the finally instead of catch.
  } finally {
    if (check === undefined) {
      const __measure__ = performance.measure.bind(performance);
      performance.measure = (
        measureName: string,
        startOrMeasureOptions?: string | PerformanceMeasureOptions | undefined,
        endMark?: string | undefined
      ): PerformanceMeasure => {
        // Call the original measure method to add it to performance timeline
        __measure__(measureName, startOrMeasureOptions, endMark);

        // Retrieve the entry
        const entries = performance.getEntriesByName(measureName) as PerformanceMeasure[];
        return entries[entries.length - 1];
      };
    }
  }
}
