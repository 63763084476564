// @ts-nocheck
import Camelizer from '../utils/Camelizer';
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class TagsAPI extends BaseAPI {
  @recoverFromNotFound()
  async findAll(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/role/tags', { headers });
    return res.data;
  }

  async createOrUpdateTag(color: string, name: string, organizationId: string, token) {
    const data = {
      color,
      name,
      organizationId,
    };
    let result;

    if (token) {
      result = await this.updateTag(token, data);
    } else {
      result = await this.createTag(data);
    }

    return result;
  }

  async createTag({
    color,
    name,
    organizationId,
  }: {
    color: string;
    name: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const res = await this.httpClient.post('/v2/tags', {
      data: {
        color,
        name,
      },
      headers,
    });

    res.token = res.headers['tt-x-tag-id'];
    return res;
  }

  async updateTag(
    token,
    {
      color,
      name,
      organizationId,
    }: {
      color: string;
      name: string;
      organizationId: string;
    }
  ) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const res = await this.httpClient.put('/v2/tags/:token', {
      urlParams: { token },
      data: {
        color,
        name,
      },
      headers,
    });

    res.token = token;
    return res;
  }

  async destroy(organizationId: string, token: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.del('/v2/tags/:token', {
      urlParams: { token },
      headers,
    });
    return res.data;
  }

  async fetchTotals(organizationId: string, userId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/user/:userId/tagging_totals', {
      urlParams: { userId },
      headers,
    });
    return Camelizer.camelizeObject(res.data);
  }

  async findColors(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/tag/color_list/:organizationId', {
      urlParams: { organizationId },
      headers,
    });
    return res.data;
  }

  async findTag(organizationId: string, tagId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/tags/:tagId', {
      urlParams: { tagId },
      headers,
    });
    return res.data;
  }

  @recoverFromNotFound()
  async findSavedTags(userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/user/:userId/saved_tags', {
      urlParams: { userId },
      headers,
    });

    return Camelizer.camelizeObject(res.data);
  }

  async saveTag(tagId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.post('/v2/user/:userId/saved_tags', {
      urlParams: { userId },
      data: {
        tag_id: tagId,
      },
      headers,
    });
  }

  async removeSavedTag(tagId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del('/v2/user/:userId/saved_tags/:tagId', {
      urlParams: { userId, tagId },
      headers,
    });
  }
}
