import * as errors from '../errors';
import { MessageMetadataNamespaces } from '../models/enums';
import { jsonCloneDeep, Camelizer } from '../utils';
import { Call } from '../types/Calls';
import {
  ClearCompletedVisits,
  CreateRoom,
  FindAllOptions,
  FindAllResponse,
  FindAllVisitsRequest,
  GetVisitRequest,
  InformProvider,
  MarkCallAsCompleted,
  NewVisitorSSE,
  RejoinVisit,
  RejoinVisitWithAuthForm,
  RoomUpdateSSE,
  UpdateCallSSE,
  UpdateRoom,
  RoomStaffUpdateSSE,
  UpdateStatusSSE,
  UpdateVisit,
  VisitAssignmentSSE,
  Visitor,
} from '../types/VirtualWaitingRoom';
import BaseService from './BaseService';

export default class VirtualWaitingRoomService extends BaseService {
  async findAll(
    organizationId: string,
    { showAll, renderUnreadMessageCount }: FindAllOptions = {
      showAll: false,
      renderUnreadMessageCount: false,
    }
  ) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    const data = await this.host.api.virtualWaitingRoom.findAll(organizationId, {
      showAll,
      renderUnreadMessageCount,
    });

    return data.content.map((result: FindAllResponse) =>
      this.host.models.VirtualWaitingRoom.inject(result)
    );
  }

  async find(id: string, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!id) throw new errors.ValidationError('room_id', 'required');

    const data = await this.host.api.virtualWaitingRoom.find(id, organizationId);

    return this.host.models.VirtualWaitingRoom.inject(data);
  }

  async create(organizationId: string, { staff, name }: CreateRoom) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!staff) throw new errors.ValidationError('staff', 'required');
    if (!name) throw new errors.ValidationError('name', 'required');

    const data = await this.host.api.virtualWaitingRoom.create(organizationId, {
      staff,
      name,
    });

    return this.host.models.VirtualWaitingRoom.inject(data);
  }

  async update(id: string, organizationId: string, options: UpdateRoom) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!id) throw new errors.ValidationError('room_id', 'required');

    const data = await this.host.api.virtualWaitingRoom.update(id, organizationId, options);

    return this.host.models.VirtualWaitingRoom.inject({
      ...options,
      id,
      version: data.version,
    });
  }

  async delete(id: string, organizationId: string) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!id) throw new errors.ValidationError('room_id', 'required');

    const room = this.host.models.VirtualWaitingRoom.get(id);

    if (room) {
      this.host.models.VirtualWaitingRoom.eject(room);
    }

    await this.host.api.virtualWaitingRoom.delete(id, organizationId);
  }

  async getVisitInfo(joinId: string) {
    if (!joinId) {
      throw new errors.ValidationError('joinId', 'required');
    }
    return await this.host.api.virtualWaitingRoom.getVisitInfo(joinId);
  }

  async createVisit({
    firstName,
    joinId,
    lastName,
    mobilePhone,
    organizationId,
    visitReasonKey,
  }: {
    firstName: string;
    joinId: string;
    lastName: string;
    mobilePhone: string;
    organizationId: string;
    visitReasonKey?: string;
  }) {
    const visit = await this.host.api.virtualWaitingRoom.createVisit({
      firstName,
      joinId,
      lastName,
      mobilePhone,
      organizationId,
      visitReasonKey,
    });

    return visit;
  }

  async visitorLogin({
    groupId,
    accountKey,
    accountSecret,
    organizationId,
  }: {
    groupId: string;
    accountKey: string;
    accountSecret: string;
    organizationId: string;
  }) {
    this.host.emit('signingIn');

    this.host.configure({
      events: { ...this.host.config.events, singleConversation: groupId },
    });

    this.host._setAuth({
      key: accountKey,
      secret: accountSecret,
    });
    this.host.setDefaultOrganization(organizationId);

    const currentUser = await this.host.users.findMe({
      ignoreNotFound: true,
      isVwrFeature: true,
    });
    this.host._setCurrentUser(currentUser);

    this.host.emit('signedIn', currentUser, this.host.getAuth());

    this.host.messages._fetchAttachmentTokens(20, { isVwrFeature: true });
  }

  async rejoinVisit({ rejoinToken, sessionTokens }: RejoinVisit) {
    const visit = await this.host.api.virtualWaitingRoom.rejoinVisit({
      rejoinToken,
      sessionTokens,
    });

    return visit;
  }

  async rejoinWithAuthForm({ rejoinToken, challengeAnswers }: RejoinVisitWithAuthForm) {
    const visit = await this.host.api.virtualWaitingRoom.rejoinWithAuthForm({
      rejoinToken,
      challengeAnswers,
    });

    return visit;
  }

  async clearAllCompletedVisits({ roomId, organizationId }: ClearCompletedVisits) {
    await this.host.api.virtualWaitingRoom.cleanupVisits({
      type: 'delete_completed_visits',
      roomId,
      organizationId,
    });
  }

  reactToJoinableCallSSE({ data, type }: { data: Object; type: string }) {
    const parsedData = jsonCloneDeep(data);
    parsedData.vwrCallStatus = type.split(':')[1];
    this.host.models.VirtualWaitingRoom.inject(parsedData);
  }

  async reactToUpdateVisitSSE({
    data,
  }: {
    data: {
      vwr: { visit_id: string; data: string; organization_id: string; waiting_room_id: string };
    };
  }) {
    const addNewVisitor = async (vwrData: NewVisitorSSE, organizationId: string) => {
      let conversationId;

      if (this.config.condensedReplays) {
        conversationId = vwrData.conversation_id;
        await this.host.conversations.find(conversationId);
      } else {
        const group = await this.host.groups.find(vwrData.group_id);
        if (!group) return;
        const highestSortNumber = this.host.messages.__getNextSortNumber(organizationId);
        this.host.conversations.ensureConversation('group', group.id, organizationId, {
          highestSortNumber,
          network: 'PROVIDER',
          featureService: 'vwr',
        });
        conversationId = this.host.conversations.getConversationKey(
          'group',
          group.id,
          organizationId
        );
      }

      const updatedVisitor = (await this.getVisit(organizationId, {
        roomId: vwrData.visitor.waiting_room_id,
        visitId: vwrData.visitor.visit_id,
      })) as Visitor;

      const newVisitor = {
        groupConversationId: conversationId,
        conversationId,
        groupId: vwrData.group_id,
        id: updatedVisitor.id,
        visitorAccountToken: updatedVisitor.visitorAccountToken,
        visitorFirstName: updatedVisitor.visitorFirstName,
        visitorFullName: updatedVisitor.visitorFullName,
        visitorJoinedAt: updatedVisitor.visitorJoinedAt,
        visitorLastName: updatedVisitor.visitorLastName,
        visitorMobilePhone: updatedVisitor.visitorMobilePhone,
        reason: updatedVisitor.reason,
        reasonKey: updatedVisitor.reasonKey,
        status: updatedVisitor.status,
      };

      const payload = this.host.models.VirtualWaitingRoomVisit.inject({
        ...newVisitor,
        roomId: vwrData.visitor.waiting_room_id,
        organizationId,
        id: updatedVisitor.id,
      });

      // @ts-ignore
      this.emit('vwr:visitor:add', payload);
    };

    const updateVisitStatus = (
      vwrData: UpdateStatusSSE,
      visitId: string,
      organizationId: string
    ) => {
      const visit = this.host.models.VirtualWaitingRoomVisit.get(visitId);
      // @ts-ignore
      this.emit('vwr:status:update', {
        visitId,
        status: vwrData.status,
        organizationId,
        previousStatus: vwrData.previous_status,
        roomId: vwrData.waiting_room_id,
        version: vwrData.visit_version,
      });
      this.host.models.VirtualWaitingRoomVisit.inject(
        Object.assign({}, visit, {
          status: vwrData.status,
          id: visitId,
          version: vwrData.visit_version,
        })
      );
    };

    const updateVisitAssignment = (vwrData: VisitAssignmentSSE, organizationId: string) => {
      const visitId = vwrData.visit_id;
      const visit = this.host.models.VirtualWaitingRoomVisit.get(visitId);
      const assignedStaffAccountId = vwrData.assignee_token;
      // @ts-ignore
      this.emit('vwr:assignment:update', {
        visitId,
        assignedStaffAccountId,
        roomId: vwrData.waiting_room_id,
        organizationId,
        version: vwrData.visit_version,
      });
      this.host.models.VirtualWaitingRoomVisit.inject(
        Object.assign({}, visit, {
          assignedStaffAccountId,
          id: visitId,
          version: vwrData.visit_version,
        })
      );
    };

    const updateRoom = (
      { status: statusToSet, room_id: roomId, version }: RoomUpdateSSE,
      organizationId: string
    ) => {
      const room = this.host.models.VirtualWaitingRoom.get(roomId);
      const status = ['OPEN', 'CLOSED'].includes(statusToSet) ? statusToSet : 'CLOSED';

      // @ts-ignore
      this.emit('vwr:room:update', {
        roomId,
        status,
        organizationId,
        version,
      });
      this.host.models.VirtualWaitingRoom.inject(
        Object.assign({}, room, {
          status,
          version,
        })
      );
    };

    const updateStaff = async (
      { staff, room_id: roomId, version }: RoomStaffUpdateSSE,
      organizationId: string
    ) => {
      const room =
        this.host.models.VirtualWaitingRoom.get(roomId) ||
        (await this.find(roomId, organizationId));

      const camelizedStaff = Camelizer.camelizeObject(staff);
      // @ts-ignore
      this.emit('vwr:staff:update', {
        staff: camelizedStaff,
        roomId,
        organizationId,
        version,
      });
      this.host.models.VirtualWaitingRoom.inject(
        Object.assign({}, room, {
          staff: camelizedStaff,
          version,
        })
      );
    };

    const updateCallStatus = (vwrData: UpdateCallSSE, organizationId: string) => {
      const { visit_id: visitId, call_id: callId, room_id: roomId } = vwrData;
      const activeCall =
        vwrData.type === 'CALL_PROVIDER_NOTIFIED' && callId ? { id: callId } : null;
      const visit = this.host.models.VirtualWaitingRoomVisit.get(visitId);
      if (
        ['CALL_LINK_EXPIRED', 'CALL_STAFF_CANCELLED', 'CALL_PROVIDER_ENDED'].includes(vwrData.type)
      ) {
        const callToEject = this.host.models.Call.getAll().find(
          (c: Call) => c.callId === (callId || visit?.activeCall?.id)
        );
        callToEject && this.host.models.Call.eject(callToEject);
      }
      const updatedVisit = { ...visit, activeCall, visitId };
      this.host.models.VirtualWaitingRoomVisit.inject(updatedVisit);
      // @ts-ignore
      this.emit('vwr:call:update', { ...updatedVisit, roomId, organizationId });
    };

    try {
      const vwrData = JSON.parse(data.vwr.data);
      switch (vwrData.type) {
        case 'NEW_VISITOR':
          return await addNewVisitor(vwrData, data.vwr.organization_id);
        case 'VISIT_ASSIGNMENT':
          return updateVisitAssignment(vwrData, data.vwr.organization_id);
        case 'VISIT_STATUS_UPDATE':
          const visitId = data.vwr.visit_id;
          return updateVisitStatus(vwrData, visitId, data.vwr.organization_id);
        case 'CALL_STAFF_CANCELLED':
        case 'CALL_PROVIDER_ENDED':
        case 'CALL_LINK_EXPIRED':
        case 'CALL_PROVIDER_NOTIFIED':
          return updateCallStatus(vwrData, data.vwr.organization_id);
        case 'ROOM_STAFF_UPDATE':
          return updateStaff(vwrData, data.vwr.organization_id);
        case 'ROOM_STATUS_UPDATE':
          return updateRoom(vwrData, data.vwr.organization_id);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getVisit(
    organizationId: string,
    { roomId, visitId }: GetVisitRequest & { roomId: string }
  ) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roomId) throw new errors.ValidationError('roomId', 'required');
    if (!visitId) throw new errors.ValidationError('visitId', 'required');
    const data = await this.host.api.virtualWaitingRoom.getVisit(organizationId, { visitId });
    this.host.models.VirtualWaitingRoomVisit.inject({ roomId, organizationId, ...data });
    return data;
  }

  async findAllVisits({ roomId, organizationId, ...restReq }: FindAllVisitsRequest) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roomId) throw new errors.ValidationError('roomId', 'required');

    const data = await this.host.api.virtualWaitingRoom.findAllVisits({
      roomId,
      organizationId,
      ...restReq,
    });

    data.content.forEach((result: Visitor) =>
      this.host.models.VirtualWaitingRoomVisit.inject({ roomId, organizationId, ...result })
    );

    return data;
  }

  async informProvider(organizationId: string, data: InformProvider) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!data.accountToken) throw new errors.ValidationError('accountToken', 'required');
    if (!data.roomId) throw new errors.ValidationError('roomId', 'required');
    if (!data.visitId) throw new errors.ValidationError('visitId', 'required');
    if (!data.visitorName) throw new errors.ValidationError('visitorName', 'required');
    if (!data.vwrConversationId) throw new errors.ValidationError('vwrConversationId', 'required');

    const payload = await this.host.api.virtualWaitingRoom.informProvider(organizationId, data);
    const body = `Start visit with patient - ${data.visitorName}`;
    const options = {
      organizationId,
      subType: 'vwr_call',
      metadata: {
        mimetype: 'application/json',
        namespace: MessageMetadataNamespaces.VWR_CALL,
        payload,
      },
    };

    await this.host.messages.sendToConversation(data.vwrConversationId, body, options);
    await this.host.messages.sendToUser(data.accountToken, body, options);

    return Camelizer.camelizeObject(payload);
  }

  async updateVisit(organizationId: string, data: UpdateVisit) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!data.roomId) throw new errors.ValidationError('roomId', 'required');
    if (!data.visitId) throw new errors.ValidationError('visitId', 'required');

    const result = await this.host.api.virtualWaitingRoom.updateVisit(organizationId, data);

    return this.host.models.VirtualWaitingRoomVisit.inject({
      ...result,
      id: data.visitId,
      organizationId,
    });
  }

  async markCallAsCompleted(organizationId: string, data: MarkCallAsCompleted) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!data.callId) throw new errors.ValidationError('callId', 'required');
    if (!data.reason) throw new errors.ValidationError('reason', 'required');
    if (!data.roomId) throw new errors.ValidationError('roomId', 'required');
    if (!data.target) throw new errors.ValidationError('target', 'required');
    if (!data.visitId) throw new errors.ValidationError('visitId', 'required');

    await this.host.api.virtualWaitingRoom.markCallAsCompleted(organizationId, data);
  }

  async getStats(organizationId: string) {
    return await this.host.api.virtualWaitingRoom.getStats(organizationId);
  }
}
