// @ts-nocheck
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { ESCALATION } = ROUTES;

export default class EscalationsAPI extends BaseAPI {
  @recoverFromNotFound()
  async escalateMessage(messageId: string, organizationId: string) {
    const { version = VERSION_TWO } = super.getVersion(ESCALATION);
    const res = await this.httpClient.post('/:version/message/:messageId/escalation', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        version,
        messageId,
      },
    });

    return res.data;
  }

  @recoverFromNotFound()
  async acknowledge(
    escalationId: string,
    {
      messageId,
      organizationId,
      userTokens,
    }: {
      messageId: string;
      organizationId: string;
      userTokens: Array<string>;
    }
  ) {
    const { version = VERSION_TWO } = super.getVersion(ESCALATION);
    const res = await this.httpClient.post(
      '/:version/message/:messageId/escalation/:escalationId/confirm',
      {
        headers: { 'TT-X-Organization-Key': organizationId },
        urlParams: {
          version,
          escalationId,
          messageId,
        },
        data: {
          confirm_as: userTokens,
        },
      }
    );

    return res.data;
  }

  @recoverFromNotFound()
  async cancel(messageId: string, organizationId: string) {
    const { version = VERSION_TWO } = super.getVersion(ESCALATION);
    await this.httpClient.del('/:version/message/:messageId/escalation', {
      headers: { 'TT-X-Organization-Key': organizationId },
      urlParams: {
        version,
        messageId,
      },
    });
  }

  @recoverFromNotFound()
  async skip(
    escalationId: string,
    {
      messageId,
      organizationId,
      userTokens,
    }: {
      messageId: string;
      organizationId: string;
      userTokens: Array<string>;
    }
  ) {
    const { version = VERSION_TWO } = super.getVersion(ESCALATION);
    const res = await this.httpClient.post(
      '/:version/message/:messageId/escalation/:escalationId/skip',
      {
        headers: { 'TT-X-Organization-Key': organizationId },
        urlParams: {
          version,
          escalationId,
          messageId,
        },
        data: {
          skip_as: userTokens,
        },
      }
    );

    return res.data;
  }
}
