import React, { UIEvent, useCallback, useEffect, useRef } from 'react';
import sanitizeHtml from 'sanitize-html';
import BEM from '../bem';
import { useAppSelector } from 'redux-stores';

type TextAreaStyledProps = {
  body: string;
  bodyEdited: boolean;
  isSecureText: boolean;
  placeholder?: string;
  readOnly?: boolean;
  renderStylizedText: (textAreaValue: string) => string;
  setBodyEdited: (bodyEdited: boolean) => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
};

const sanitizeHtmlConfig = { allowedAttributes: { span: ['class'] } };

const classes = BEM.with('TextAreaStyled');

const TextAreaStyled: React.FC<TextAreaStyledProps> = ({
  body,
  bodyEdited,
  isSecureText,
  placeholder,
  readOnly,
  renderStylizedText,
  setBodyEdited,
  textAreaRef,
}) => {
  const styledRef = useRef<HTMLDivElement>(null);
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);

  const handleChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!styledRef.current) return;

      styledRef.current.innerHTML = sanitizeHtml(
        renderStylizedText(target.value),
        sanitizeHtmlConfig
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderStylizedText, bodyEdited]
  );

  useEffect(() => {
    if (!(styledRef.current && textAreaRef.current)) return;

    styledRef.current.innerHTML = sanitizeHtml(
      renderStylizedText(textAreaRef.current.value),
      sanitizeHtmlConfig
    );
  }, [renderStylizedText, textAreaRef]);

  function handleScroll(e: UIEvent<HTMLTextAreaElement>) {
    if (styledRef.current) {
      styledRef.current.scrollTop = e.currentTarget.scrollTop;
      styledRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }
  }

  return (
    <div className={classes()}>
      <textarea
        defaultValue={body}
        className={classes('textArea', { input: true, focusVisible: accessibilityMode })}
        onInput={handleChange}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={textAreaRef}
        onScroll={handleScroll}
        onChange={() => !isSecureText && !bodyEdited && setBodyEdited(true)}
      />
      <div ref={styledRef} className={classes('textArea', { dummy: true })}></div>
    </div>
  );
};

export default TextAreaStyled;
