import { useCallback } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
    localTrackToggling: { toggleLocalTrack },
    getLocalVideoTrack,
  } = useVideoContext();
  const videoTrack = localTracks.find((track) => track.name === 'camera');

  const toggleVideoEnabled = useCallback(() => {
    toggleLocalTrack('video');
    if (videoTrack) {
      if (videoTrack.isEnabled) {
        videoTrack.disable();
      } else {
        videoTrack.enable();
      }
    } else {
      try {
        getLocalVideoTrack();
      } catch (err) {
        console.error(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack, localParticipant, getLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled];
}
