import React, { useEffect, useRef, useState, useCallback } from 'react';
import { format, parseISO } from 'date-fns';

import { throttle } from 'lodash';
import { Scrollbars } from 'tt-react-custom-scrollbars';
import PFCallOrigins from '../../../models/enums/PfCallOrigins';
import { Modal } from '../WebComponents';
import { ReactComponent as PhoneIcon } from '../../../widgets/messenger/images/care-team-call-icon.svg';
import { ReactComponent as MessageIcon } from '../../../widgets/messenger/images/care-team-message-icon.svg';
import { EntityAvatar } from '..';
import { buildPatientLocation } from './../../utils';
import { mobxInjectSelect, attachmentType, FROZEN_EMPTY_ARRAY } from 'common/utils';
import BEM from 'common/bem';
import { ReactComponent as PatientSvg } from 'common/images/patient-icon.svg';
import DotsIndicator from 'common/components/DotsIndicator';
import { actions, thunk, useAppDispatch, useAppSelector } from 'redux-stores';
import { Conversation, Message, Role, User } from 'types';
import { MDYYYY_DATE_FORMAT } from 'common/constants';
import { Timestamp, MessageStatus } from 'common/components';
import RoleStatus from 'widgets/messenger/components/Sidebar/RoleStatus';

const classes = BEM.with('PatientReferenceDetailsModal');

const { setModal, setSelectedPatientReference, setPatientCareTeam } = actions;
const { fetchPatientContextThunk, fetchPatientCareTeamThunk } = thunk;
const THROTTLE_TIMEOUT = 50;

type PatientReferenceDetailsModalProps = {
  isOpen: boolean;
};

type CareTeamMember = {
  uuid: string;
  avatar: string | null;
  firstName: string;
  lastName: string;
  title: string;
  rolName: string[];
  organization: string;
  accountToken: string | null;
};

type MobxProps = {
  composeNewMessage: (user?: User) => void;
  conversations: Array<Conversation>;
  currentOrganizationId: string;
  currentRoles: Array<Role>;
  findUser: (id: string, organizationId: string) => User;
  isPatientContextAllowed: boolean;
  selectConversation: (conv: Conversation) => void;
  switchApp: (appName: string) => void;
  videoCallSetUp: (...args: unknown[]) => Promise<unknown>;
};

function PatientReferenceDetailsModal({
  composeNewMessage,
  conversations,
  currentOrganizationId,
  currentRoles,
  findUser,
  isOpen,
  isPatientContextAllowed,
  selectConversation,
  switchApp,
  videoCallSetUp,
}: PatientReferenceDetailsModalProps & MobxProps) {
  const dispatch = useAppDispatch();
  const [patientRefConversations, setPatientRefConversations] = useState<Array<Conversation>>([]);
  const [activeTab, setActiveTab] = useState<string>('yourConversations');
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const conversationsTabSelected = activeTab === 'yourConversations';
  const careTeamTabSelected = activeTab === 'careTeam';
  const {
    isCareTeamAllowed,
    patientCareTeamModal,
    patientCareTeamSearchLoading,
    patientReferences,
    selectedPatientReferenceDetail: patientDetail,
  } = useAppSelector(
    ({
      patientContext: {
        isCareTeamAllowed,
        patientCareTeamModal,
        patientCareTeamSearchLoading,
        patientReferences,
        selectedPatientReferenceDetail,
      },
    }) => {
      return {
        isCareTeamAllowed,
        patientCareTeamModal,
        patientCareTeamSearchLoading,
        patientReferences,
        selectedPatientReferenceDetail,
      };
    }
  );

  const throttledLoadMore = useRef(
    throttle(() => setShouldLoadMore(true), THROTTLE_TIMEOUT, {
      leading: false,
    })
  );

  const patientCareTeamInformation = useCallback(async () => {
    setShouldLoadMore(false);
    if (patientDetail?.uuid && !patientCareTeamSearchLoading) {
      dispatch(
        fetchPatientCareTeamThunk({
          patientContextId: patientDetail.uuid,
          isRequestedFromCompose: false,
        })
      );
    }
  }, [patientDetail?.uuid, dispatch, patientCareTeamSearchLoading]);

  useEffect(() => {
    if (isCareTeamAllowed && isOpen) patientCareTeamInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCareTeamAllowed, isOpen]);

  useEffect(() => {
    if (shouldLoadMore) {
      patientCareTeamInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = e.currentTarget;
    if (patientCareTeamModal.continuation && clientHeight / (scrollHeight - scrollTop) > 0.8) {
      throttledLoadMore.current();
    }
  };

  useEffect(
    function filterConversationsByPatientRefMRN() {
      if (isOpen && patientDetail) {
        const result = conversations.filter(
          ({ counterParty }) => counterParty.patientContextId === patientDetail.uuid
        );
        setPatientRefConversations(result);
      }
    },
    [conversations, patientDetail, isOpen]
  );

  useEffect(
    function getPatientContext() {
      if (!!patientDetail?.uuid && isOpen) {
        dispatch(fetchPatientContextThunk(patientDetail.uuid));
      }
    },
    [dispatch, patientDetail?.uuid, isOpen]
  );

  useEffect(() => {
    if (patientRefConversations.length > 0) {
      setActiveTab('yourConversations');
    } else if (patientRefConversations.length < 1 && isCareTeamAllowed) {
      setActiveTab('careTeam');
    }
  }, [isOpen, patientRefConversations, isCareTeamAllowed]);

  const initiateCall = async (userId: string) => {
    const user = await findUser(userId, currentOrganizationId);
    videoCallSetUp(user, PFCallOrigins.PATIENT_DETAILS);
  };

  const handleCreateNewConvWithCareTeamMember = async (userId: string) => {
    const user = await findUser(userId, currentOrganizationId);
    closeModal();
    switchApp('Messages');
    composeNewMessage(user);
    dispatch(setSelectedPatientReference(patientDetail));
  };

  const toggleTab = (activeTab: string) => {
    setActiveTab(activeTab);
  };

  const { firstName, lastName, status, dob, gender, mrn } = patientDetail || {};

  const patientReference = patientDetail?.uuid ? patientReferences[patientDetail.uuid] : undefined;
  const location = buildPatientLocation(patientReference?.patientLocation);

  return (
    <Modal headerText={'Patient Details'} isOpen={isOpen} hasCloseButton onClose={closeModal}>
      <div className={classes('')}>
        <div className={classes('patient-image-header')}>
          <PatientSvg />
        </div>
        <div className={classes('patient-ref-name')}>
          {firstName} {lastName}
        </div>
        <div className={classes('patient-ref-details')}>
          {status === 'discharged' && (
            <>
              <span className={classes('discharged-text')}>DISCHARGED</span> •{' '}
            </>
          )}
          {`${dob && format(parseISO(dob), MDYYYY_DATE_FORMAT)} • ${gender} • ${mrn}`}
        </div>
        {status !== 'discharged' && !!location && (
          <div className={classes('patient-ref-location')}>Location: {location}</div>
        )}
        <div className={classes('patient-ref-create-conv-button')}>
          {status !== 'discharged' && isPatientContextAllowed && (
            <button onClick={handleCreateNewConv}>Create a Patient Reference Conversation</button>
          )}
        </div>
        <div className={classes('patient-ref-headers-container')}>
          {patientRefConversations.length > 0 && (
            <div
              className={classes('patient-ref-header', {
                selected: conversationsTabSelected,
              })}
              onClick={() => toggleTab('yourConversations')}
            >
              Your Conversations
            </div>
          )}
          {isCareTeamAllowed && (
            <div
              className={classes('patient-ref-header', {
                selected: careTeamTabSelected,
              })}
              onClick={() => toggleTab('careTeam')}
            >
              Care Team
            </div>
          )}
        </div>
        {activeTab === 'yourConversations' && patientRefConversations.length > 0 && (
          <div className={classes('patient-ref-conversations')}>
            {patientRefConversations.map((conv) => {
              return (
                <div
                  className={classes('conversation-card-container')}
                  key={conv.id}
                  onClick={() => {
                    handleSelectConv(conv);
                  }}
                >
                  <div className={classes('avatar')}>
                    <EntityAvatar
                      entity={conv.counterParty}
                      entityType={conv.counterParty.$entityType}
                      showPresenceIndicator={false}
                      size={45}
                      isMuted={conv.isMuted}
                    />
                  </div>
                  <div className={classes('details')}>
                    <div className={classes('top-row')}>
                      <div className={classes('name')}>{conv.counterParty.name}</div>
                      <div className={classes('last-message')}>
                        {conv.lastMessage && (
                          <Timestamp
                            value={conv.lastMessage.createdAt}
                            className={classes('last-message-timestamp')}
                          />
                        )}
                      </div>
                    </div>
                    <div className={classes('mid-row')}>
                      <div className={classes('last-message')}>
                        {conv.lastMessage && renderLastMessageBody(conv.lastMessage)}
                      </div>
                    </div>
                    <div className={classes('bot-row')}>
                      <div className={classes('last-message-status')}>
                        {conv?.lastMessage?.isOutgoing && (
                          <MessageStatus isClickable={false} message={conv.lastMessage} />
                        )}
                      </div>
                      <div className={classes('sender-role')}>{renderSenderRole(conv)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {isCareTeamAllowed && careTeamTabSelected && (
          <div className={classes('patient-ref-conversations')}>
            <Scrollbars autoHeight autoHide onScroll={onScroll} autoHideTimeout={750}>
              {!patientCareTeamSearchLoading && patientCareTeamModal.hits.length === 0 && (
                <div className={classes('empty-care-team')}>
                  There are no care team members currently assigned to {firstName} {lastName}
                </div>
              )}
              {patientCareTeamModal &&
                patientCareTeamModal.hits.map((hit: CareTeamMember) => {
                  return (
                    <div className={classes('conversation-card-container')} key={hit.uuid}>
                      <div className={classes('avatar-care-team')}>
                        {hit.avatar ? (
                          <img
                            src={hit.avatar}
                            alt={hit.firstName}
                            className={classes('avatar-image')}
                          />
                        ) : (
                          <div className={classes('avatar-image-initials')}>
                            {hit.firstName[0].toUpperCase()}
                            {hit.lastName[0].toUpperCase()}
                          </div>
                        )}
                      </div>
                      <div className={classes('details', { careTeamTabSelected })}>
                        <div className={classes('top-row')}>
                          <div className={classes('name')}>
                            {hit.firstName} {hit.lastName}
                          </div>
                        </div>
                        {(hit.rolName?.length > 0 || hit.title) && (
                          <div className={classes('mid-row')}>
                            <div className={classes('last-message')}>
                              {hit.rolName?.length > 0 ? hit.rolName.join(',') : hit.title}
                            </div>
                          </div>
                        )}
                      </div>
                      {hit.accountToken && (
                        <div>
                          <div className={classes('icon-right')}>
                            <PhoneIcon
                              className={classes('phone-icon')}
                              onClick={() => {
                                if (hit.accountToken) {
                                  initiateCall(hit.accountToken);
                                }
                              }}
                            />
                            <MessageIcon
                              className={classes('message-icon')}
                              onClick={() => {
                                if (hit.accountToken) {
                                  handleCreateNewConvWithCareTeamMember(hit.accountToken);
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              {patientCareTeamSearchLoading && (
                <div className={classes('search-results-page-loader')}>
                  <DotsIndicator size={13} />
                </div>
              )}
            </Scrollbars>
          </div>
        )}
      </div>
    </Modal>
  );

  function renderLastMessageBody(lastMessage: Message) {
    const { attachments = FROZEN_EMPTY_ARRAY, sender, body } = lastMessage || {};
    return sender?.firstName && body
      ? `${sender.firstName}: ${body}`
      : attachments && attachments.length > 0
      ? `Attachment: ${attachmentType(attachments[0].contentType)}`
      : body;
  }

  function renderSenderRole(conv: Conversation) {
    const { $entityType, memberIds } = conv?.counterParty || {};
    if ($entityType !== 'group' || currentRoles.length === 0) return null;

    const applicableRoles = currentRoles.filter(
      (role) => role.botUserId && memberIds?.includes(role.botUserId)
    );
    if (applicableRoles.length === 0) return null;

    return <RoleStatus role={applicableRoles[0]} />;
  }

  function handleSelectConv(conv: Conversation) {
    closeModal();
    switchApp('Messages');
    selectConversation(conv);
  }

  function handleCreateNewConv(e: React.SyntheticEvent) {
    e.preventDefault();
    closeModal();
    switchApp('Messages');
    composeNewMessage();
    dispatch(setSelectedPatientReference(patientDetail));
  }

  function closeModal() {
    dispatch(setModal());
    setActiveTab('yourConversations');
    dispatch(setPatientCareTeam({ hits: [], continuation: null }));
  }
}

export default mobxInjectSelect<PatientReferenceDetailsModalProps, MobxProps>({
  callStore: ['videoCallSetUp'],
  conversationStore: ['conversations', 'selectConversation'],
  composeMessageStore: ['composeNewMessage'],
  messengerStore: ['currentOrganizationId', 'switchApp', 'isPatientContextAllowed'],
  roleStore: ['currentRoles'],
  userStore: ['findUser'],
})(PatientReferenceDetailsModal);
