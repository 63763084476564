// @ts-nocheck
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { DISTRIBUTION_LIST_FIND } = ROUTES;

export default class DistributionListsAPI extends BaseAPI {
  async batch({
    ids,
    operation,
    organizationId,
  }: {
    ids: string[];
    operation: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.patch('/v5/dist_list', {
      data: {
        ids,
        operation,
      },
      headers,
    });
  }

  async create({
    adminOnly,
    isAdSync,
    members,
    name,
    network,
    organizationId,
    securityGroup,
  }: {
    adminOnly: boolean;
    isAdSync: boolean;
    members: string[];
    name: string;
    network: string;
    organizationId: string;
    securityGroup?: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const { data } = await this.httpClient.post('/v5/dist_list', {
      data: {
        ...(securityGroup === undefined ? null : { ad_security_group: securityGroup }),
        admin_only: adminOnly,
        is_ad_sync: isAdSync,
        members,
        name,
        network,
      },
      headers,
    });

    return data;
  }

  @recoverFromNotFound()
  async delete(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.del('/v5/dist_list/:id', {
      headers,
      urlParams: {
        id,
      },
    });
  }

  @recoverFromNotFound()
  async find(id: string, { organizationId } = {}) {
    const { version = VERSION_TWO } = super.getVersion(DISTRIBUTION_LIST_FIND);
    const headers = {};

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
    }

    const { data } = await this.httpClient.get('/:version/dist_list/:id', {
      headers,
      urlParams: {
        id,
        version,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async findMemberIds(id: string, organizationId: string, { continuation } = {}) {
    const { version = VERSION_TWO } = super.getVersion(DISTRIBUTION_LIST_FIND);
    const headers = { 'TT-X-Organization-Key': organizationId };
    let endpoint = '/:version/dist_list/:id/members';

    if (continuation) {
      endpoint += '/?continuation=:continuation';
    }

    const { data } = await this.httpClient.get(endpoint, {
      headers,
      urlParams: {
        continuation,
        id,
        version,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async membersBatch({
    id,
    members,
    operation,
    organizationId,
  }: {
    id: string;
    members: string[];
    operation: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.patch('/v5/dist_list/:id/members', {
      data: {
        members,
        operation,
      },
      headers,
      urlParams: {
        id,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async sharedMembersBatch({
    id,
    members,
    operation,
    organizationId,
  }: {
    id: string;
    members: string[];
    operation: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.patch('/v5/dist_list/:id/shared', {
      data: {
        shared: members,
        operation,
      },
      headers,
      urlParams: {
        id,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async update({
    adSecurityGroup,
    adminOnly,
    id,
    name,
    organizationId,
  }: {
    adSecurityGroup?: string;
    adminOnly?: boolean | null | undefined;
    id: string;
    name?: string | null | undefined;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.put('/v5/dist_list/:id', {
      data: {
        admin_only: adminOnly,
        name,
        ad_security_group: adSecurityGroup,
      },
      headers,
      urlParams: {
        id,
      },
    });

    return data;
  }
}
