// @ts-nocheck
import _ from 'lodash';
import _uniq from 'lodash-bound/uniq';
import throttle from 'lodash.throttle';
import moment from 'moment';
import { decorator as reusePromise } from 'reuse-promise';
import uuid from 'uuid';
import NetworkStatus from '../network/NetworkStatus';
import * as errors from '../errors';
import {
  FeatureService,
  GroupType,
  MessageMetadataNamespaces,
  MessagePriority,
  MessageRecipientStatus,
  MessageSenderStatus,
  MessageSubType,
  MessageType,
} from '../models/enums';
import { MESSAGE_STATUS_ORDER } from '../models/enums/MessageRecipientStatus';
import {
  arrayWrap,
  arrayWrapBound,
  isConfigurableProperty,
  jsonCloneDeep,
  Validator,
} from '../utils';
import { downloadFile, loadLocalFilePathWithFileReader } from '../utils/file';
import { getMimeType } from '../network/HttpClient/mimeTypes';
import BaseService from './BaseService';

const DELETE_ON_READ_MS = 60 * 1000;
const MESSAGE_NO_COPY_FIELDS = ['escalationExecutionId', 'id', 'serverId'];
const MESSAGE_RECIPIENT_ALLOWED_MODEL_TYPES = ['distributionList', 'group', 'user'];

const isAutoForwardMaxError = (error) => {
  return error?.response?.body?.error?.message?.includes('auto_forward_exceeded_max_group_size');
};

const AutoForwardMaxError =
  'Message cannot be sent because recipient has too many auto-forward recipients (100+).';

type UserIdsType = string[];
type ReactionMessages = {
  [message_id: string]: {
    [emoji_unicode: string]: UserIdsType;
  };
};
type ReactionsServerReturnType = {
  messages: ReactionMessages;
};

export default class MessagesService extends BaseService {
  DELETE_ON_READ_MS = DELETE_ON_READ_MS;

  mounted() {
    this._throttled_flushFindRecipientStatusesQueue = this.config.flushFindRecipientStatusInterval
      ? throttle(
          this.__flushFindRecipientStatusesQueue,
          this.config.flushFindRecipientStatusInterval,
          { leading: false }
        )
      : this.__flushFindRecipientStatusesQueue;
    this._throttled_flushSetMessageStatusesQueue = this.config.flushSetMessageStatusInterval
      ? throttle(this.__flushSetMessageStatusesQueue, this.config.flushSetMessageStatusInterval, {
          leading: false,
        })
      : this.__flushSetMessageStatusesQueue;

    this._attachmentTokens = [];
    this._pendingFindRecipientStatuses = {};
    this._pendingSetMessagesStatusConnections = 0;
    this._queuedFindRecipientStatuses = [];
    this._queuedSetMessageStatuses = {
      deliveredIds: [],
      readIds: [],
    };
    this._conversationsThatHaveBeenPopulatedWithReactions = {};
    this._currentConversationMessageIds = [];

    this.host.on('tick', this._onTick);
    this.host.models.MessageStatusPerRecipient.on('afterInject', this._onChangeMessageStatus);
    this.host.models.MessageStatusPerRecipient.on('afterEject', this._onChangeMessageStatus);
    this.host.on(
      'networkStatus:change',
      this._resetConversationsThatHaveBeenPopulatedWithReactions
    );
  }

  dispose() {
    this._attachmentTokens = null;
    this._pendingFindRecipientStatuses = null;
    this._pendingSetMessagesStatusConnections = 0;
    this._queuedFindRecipientStatuses = null;
    this._queuedSetMessageStatuses = null;
    this._conversationsThatHaveBeenPopulatedWithReactions = null;
    this._downloadAttachmentBlobAndUrl && reusePromise.clear(this._downloadAttachmentBlobAndUrl);
    this.downloadAttachmentUrl && reusePromise.clear(this.downloadAttachmentUrl);
    this.downloadAttachment && reusePromise.clear(this.downloadAttachment);
    this._throttled_flushFindRecipientStatusesQueue.cancel &&
      this._throttled_flushFindRecipientStatusesQueue.cancel();
    this._throttled_flushSetMessageStatusesQueue.cancel &&
      this._throttled_flushSetMessageStatusesQueue.cancel();
    this.host.removeListener('tick', this._onTick);
    this.host.models.MessageStatusPerRecipient.removeListener(
      'afterInject',
      this._onChangeMessageStatus
    );
    this.host.models.MessageStatusPerRecipient.removeListener(
      'afterEject',
      this._onChangeMessageStatus
    );
    this.host.removeListener(
      'networkStatus:change',
      this._resetConversationsThatHaveBeenPopulatedWithReactions
    );
  }

  ///////////////////
  /// send
  ///////////////////

  /**
   * Send a message to a user/group/conversation
   * @param  {string|string[]|User|User[]|Group|DistributionList|Conversation} entityId:  An ID or an entity of a user, group or a conversation or a phone number/email of a user
   * @param  {string} body: body of the message
   * @param  {?Object} options: options
   * @return {Promise.<Message,Error>} A promise to a Message
   */
  async send(
    entityId: string | string[] | Object | Object[],
    body: string,
    options: Object | null | undefined = {}
  ) {
    if (this.config.condensedReplays) {
      throw new Error(
        'the send() method has been deprecated in favor of sendToUser(), sendToGroup(), etc.'
      );
    }

    if (Array.isArray(entityId)) {
      await this.__resolveSender(options);
      return this.sendToGroupOfUsers(entityId, body, options);
    }

    let { counterParty, organizationId } = await this.__resolveCounterPartyEntityAndOrganizationId(
      entityId,
      options.senderOrganizationId || options.organizationId
    );
    Validator.notEmpty('counterPartyId', counterParty, 'counterPartyId not found');

    if (organizationId) {
      options = {
        ..._.omit(options, 'organizationId', 'senderOrganizationId', 'recipientOrganizationId'),
        organizationId,
      };
    } else {
      organizationId = options.organizationId;
    }

    const sender = await this.__resolveSender(options);

    const isUser = (item) => item && item.$entityType === 'user';
    const isRole = (item) => isUser(item) && item.isRoleBot;
    if (isRole(counterParty) || (isUser(counterParty) && isRole(sender))) {
      return this.sendToRole(entityId, body, options);
    }

    const { groupType, memberIds } = counterParty;

    if (
      groupType === GroupType.FORUM &&
      !(memberIds && memberIds.includes(this.host.currentUserId))
    ) {
      await this.host.forums.join(counterParty);
    }

    return this.__sendToCounterParty(counterParty, body, options);
  }

  async __resolveSender(options) {
    const senderId = this.__resolveSenderId(options);
    const organizationId = options.senderOrganizationId || options.organizationId;

    if (senderId === this.host.currentUserId) {
      return this.host.currentUser;
    }

    if (!organizationId) {
      Validator.raise(
        'senderOrganizationId',
        'missing',
        'When senderId is provided, senderOrganizationId or organizationId must also be provided'
      );
    }

    const sender = await this.host.users.find(senderId, { organizationId });

    if (!sender) {
      Validator.raise('senderId', 'invalid', 'The provided senderId was not found');
    }

    if (!sender.isRoleBot) {
      Validator.raise('senderId', 'invalid', 'senderId must be either the current user or a role');
    }

    await this.host.roles.find(senderId, organizationId);

    return sender;
  }

  __resolveSenderId(options = {}) {
    let { senderId } = options;
    if (senderId) {
      senderId = this._resolveModelId(senderId);
      senderId = this.host.roles.__resolveRoleId(senderId);
    } else {
      senderId = this.host.currentUserId;
    }
    options.senderId = senderId;

    return senderId;
  }

  async __resolveCounterPartyEntityAndOrganizationId(
    entityId: string | Object,
    providedOrganizationId: string | null | undefined = null
  ) {
    if (typeof entityId === 'string') entityId = this.host.roles.__resolveRoleId(entityId);
    let entity = this._resolveEntity(entityId, [
      ...MESSAGE_RECIPIENT_ALLOWED_MODEL_TYPES,
      'conversation',
    ]);
    let organizationId = providedOrganizationId;

    // couldn't find user/group/distributionList/conversation with that ID locally
    if (!entity) {
      // if not found locally and ID looks like a conversation hash, there's a good
      // chance it's indeed a conversation. try to look it up by fetching roster
      if (this.host.conversations.looksLikeConversationHandle(entityId)) {
        const conversation = await this.host.conversations.find(entityId);
        if (conversation) {
          entity = conversation.counterParty;
          organizationId = conversation.organizationId;
        }
      }
    }

    // couldn't also find a conversation
    if (!entity) {
      entity = await this.__resolveRemoteCounterPartyEntity(entityId, organizationId);
    }

    if (entity) {
      // can infer organization ID from entity (e.g. group or conversation)
      if (entity.organizationId) {
        if (entity.$entityType !== 'user') {
          organizationId = entity.organizationId;
        }
      }

      // if entity is a conversation, the counter party is actually the conversation.entity
      if (entity.$entityType === 'conversation') {
        entity = entity.counterParty;
      }
    }

    return { counterParty: entity, organizationId };
  }

  async __resolveRemoteCounterPartyEntity(
    entityId: string,
    organizationId: string | null | undefined
  ) {
    let entity = null;
    entityId = this.host.roles.__resolveRoleId(entityId);

    const resolvers = _.compact([
      async () => this.host.users.findOrCreate(entityId, { organizationId }),
      async () => this.host.groups.find(entityId),
      async () => this.host.distributionLists.find(entityId),
    ]);

    for (const resolver of resolvers) {
      try {
        entity = await resolver();
        if (entity) break;
      } catch (ex) {
        if (ex.code !== errors.NotFoundError.CODE) throw ex;
      }
    }

    return entity;
  }

  // this method optimistically inserts a message to a conversation
  // it needs both counterPartyType and counterPartyId in order to generate a conversation id
  async __sendToCounterParty(
    counterParty: Object, // user|group|distributionList
    body: string,
    {
      organizationId,
      senderOrganizationId = organizationId,
      recipientOrganizationId = senderOrganizationId,
      ...options
    } /*: {
  organizationId?: ?string,
  senderOrganizationId?: ?string,
  recipientOrganizationId?: ?string,
  options?: ?Object
  }*/
  ) {
    const { $entityType: counterPartyType, serverId: counterPartyId } = counterParty;
    this.host.requireUser();

    // TODO test
    Validator.oneOf('counterPartyType', counterPartyType, ['user', 'group', 'distributionList']);
    Validator.notEmpty('counterPartyId', counterPartyId);

    senderOrganizationId = this.__ensureOrganization(senderOrganizationId);
    recipientOrganizationId = recipientOrganizationId || senderOrganizationId;
    options = this.__normalizeSendOptions(options, { counterParty, senderOrganizationId });

    let message = await this.__createNewMessage(counterParty, body, {
      organizationId,
      senderOrganizationId,
      recipientOrganizationId,
      ...options,
    });
    this.host.events.deferEventQueue();
    this.host.models.Message.inject(message);
    this.host.emit('message:sending', message);

    const { attachmentFiles, deleteOnRead, escalate, metadata, priority, subType, ttl } = options;

    try {
      const attrs = await this.host.api.messages.send({
        attachment: _.head(attachmentFiles),
        body,
        counterPartyId,
        deleteOnRead,
        escalate,
        localId: message ? message.id : uuid.v4(),
        metadata,
        priority: MessagePriority.toServer(priority),
        recipientOrganizationId,
        senderOrganizationId,
        subType,
        ttl,
      });
      message = await this.__retrieveSentMessage(attrs, { instance: message });
      this.host.emit('message:sent', message);
    } catch (err) {
      this.logger.error('error while sending message', err);
      if (isAutoForwardMaxError(err)) {
        message.senderError = AutoForwardMaxError;
      }
      message.senderStatus = MessageSenderStatus.FAILED;
      this.host.models.Message.inject(message);
    }

    this.host.events.resumeEventQueue();

    return message;
  }

  async __createNewMessage(
    counterParty: Object, // user|group|distributionList
    body: string,
    {
      conversationId,
      isForwarded = false,
      organizationId,
      senderOrganizationId = organizationId,
      recipientOrganizationId = senderOrganizationId,
      ...options
    } /*: {
  organizationId: ?string,
  senderOrganizationId: ?string,
  recipientOrganizationId: ?string,
  options: ?Object
  }*/
  ) {
    const {
      attachmentFiles,
      deleteOnRead,
      escalate,
      metadata,
      originalMetadata,
      priority,
      senderId,
      ttl,
      subType,
    } = options;
    const { $entityType: counterPartyType, serverId: counterPartyId } = counterParty;
    this.host.requireUser();

    // TODO test
    Validator.oneOf('counterPartyType', counterPartyType, ['user', 'group', 'distributionList']);
    Validator.notEmpty('counterPartyId', counterPartyId);

    let conversation;
    if (conversationId) {
      conversation = this.host.conversations.getById(conversationId);
    }

    if (!conversation) {
      conversation = this.host.conversations.ensureConversation(
        counterPartyType,
        counterPartyId,
        senderOrganizationId
      );
    }

    const inTimeline = conversation.isLive;

    const sortNumber = this.__getNextSortNumber(senderOrganizationId, { conversation });
    const currentSenderRoleId =
      senderId && senderId !== this.host.currentUserId ? senderId : undefined;
    const originalBody = body;
    if (counterPartyType === 'distributionList') {
      body = `[${counterParty.displayName}]: ${body}`;
    }

    const message = this.host.models.Message.createInstance({
      $synced: false,
      body,
      conversationId: conversation.id,
      counterPartyId,
      counterPartyType,
      createdAt: new Date(),
      currentSenderRoleId,
      deleteOnRead,
      id: uuid.v4(), // local id
      inTimeline,
      isForwarded,
      isOutgoing: true,
      messageType: MessageType.USER_SENT,
      metadata,
      originalMetadata,
      priority,
      recipientOrganizationId,
      senderId: this.host.currentUserId,
      senderOrganizationId,
      senderStatus: MessageSenderStatus.SENDING,
      shouldEscalate: escalate,
      sortNumber,
      ttl,
      subType,
    });

    message._originalBody = originalBody;
    if (attachmentFiles)
      message.attachments = await Promise.all(
        attachmentFiles.map(this.__generateAttachmentDescriptor)
      );

    if (counterPartyType === 'group') {
      message.groupId = counterPartyId;
    } else if (counterPartyType === 'user') {
      message.recipientId = counterPartyId;
    } else if (counterPartyType === 'distributionList') {
      message.distributionListId = counterPartyId;
    }

    if (!this.config.condensedReplays) {
      message.markedRecipientStatus = MessageRecipientStatus.READ;
    }

    return message;
  }

  __retrieveSentMessage = async (attrs, { instance: message }) => {
    const id = attrs['message_id'];
    if (!id) throw new Error('Missing message_id from /message response');

    if (!attrs['sort_number']) {
      let newMessage = await this.find(id, { instance: message, replace: true });
      if (!newMessage) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        newMessage = await this.find(id, { instance: message, replace: true });
      }
      if (newMessage) message = newMessage;
    } else {
      if (attrs['is_forwarded'] === undefined) attrs['is_forwarded'] = false;
      const status = attrs['status'] || 'New';
      this.__setDefaultAttributes(attrs, { instance: message });
      message = this.host.models.Message.replaceExisting(attrs, { instance: message });
      message.$synced = undefined;

      const { counterPartyType, createdAt, group, recipientId } = message;
      const { groupType, p2pRecipient } = group || {};
      let accountId;

      if (groupType === GroupType.ROLE_P2P && p2pRecipient) {
        accountId = p2pRecipient.$entityType === 'role' ? p2pRecipient.botUserId : p2pRecipient.id;
      } else if (counterPartyType === 'user') {
        accountId = recipientId;
      }

      if (accountId) {
        this.__injectMessageStatus(message, { accountId, createdAt, status });
      } else if (groupType === GroupType.GROUP && message.escalationExecution) {
        await this.findRecipientStatus(message, { queue: false });
      } else if (counterPartyType === 'group') {
        this.findRecipientStatus(message, { newMessage: true });
      }
    }

    return message;
  };

  /**
   * Helper and validation functions
   */
  __normalizeSendOptions(
    {
      attachmentFile,
      attachmentFiles,
      deleteOnRead,
      escalate,
      membersMentionInConversation,
      metadata,
      originalMetadata,
      priority,
      senderId,
      ttl,
      subType,
    }: {
      attachmentFiles?: Array<string | Object> | null | undefined;
      deleteOnRead?: boolean | null | undefined;
      escalate?: boolean | null | undefined;
      membersMentionInConversation?:
        | Array<{
            entity_type: string;
            entity_id: string;
            start: number;
            end: number;
          }>
        | null
        | undefined;
      metadata?: (Array<Object> | null | undefined) | (Object | null | undefined);
      originalMetadata?: (Array<Object> | null | undefined) | (Object | null | undefined);
      priority?: string | null | undefined;
      senderId?: string | null | undefined;
      ttl?: number | null | undefined;
      subType?: string | null | undefined;
    },
    { counterParty, senderOrganizationId }
  ) {
    metadata = this.__normalizeMetadata({
      membersMentionInConversation,
      metadata,
      senderId,
    });
    attachmentFiles = arrayWrap(attachmentFiles || attachmentFile);

    if (!_.isEmpty(attachmentFiles) && attachmentFiles.length > 1) {
      Validator.raise(
        'attachmentFiles',
        'invalid',
        'currently only a single attachment is supported'
      );
    }

    const organization = this.host.models.Organization.get(senderOrganizationId);
    if (typeof ttl === 'undefined') {
      ttl = organization ? organization.messageTimeToLive : -1;
    }

    if (!deleteOnRead && organization) {
      deleteOnRead = organization.deleteOnRead;
    }

    const { groupType, p2pRecipient } = counterParty;
    if (groupType === GroupType.ROLE_P2P && p2pRecipient && p2pRecipient.escalationPolicy) {
      const { alwaysEscalate } = p2pRecipient.escalationPolicy;
      if (alwaysEscalate) {
        escalate = true;
        priority = MessagePriority.NORMAL;
      }
    }

    return {
      attachmentFiles,
      deleteOnRead,
      escalate,
      metadata,
      originalMetadata,
      priority,
      senderId,
      ttl,
      subType,
    };
  }

  __ensureOrganization(organizationId: string | null | undefined) {
    if (organizationId) return organizationId;

    // try using client.config.defaultOrganizationId
    if (this.host.config.defaultOrganizationId) return this.host.config.defaultOrganizationId;

    Validator.raise(
      'organizationId',
      'required',
      'Message is sent in the context of an organization. Provide `organizationId` or set a default one with client.setDefaultOrganization(...)'
    );
  }

  __normalizeMetadata({ membersMentionInConversation, metadata, senderId }) {
    if (!metadata) metadata = [];
    metadata = arrayWrap(metadata);

    if (senderId !== this.host.currentUserId) {
      const role = this.host.roles.getById(senderId);
      const { tag } = role;

      metadata.push({
        namespace: MessageMetadataNamespaces.ROLE_SENDER,
        payload: {
          token: senderId,
          display_name: role.displayName,
          tag_id: tag ? tag.tagId : '',
          tag_color: tag ? tag.color.replace('#', '0x') : '',
          tag_name: tag ? tag.name : '',
        },
      });
    }

    if (membersMentionInConversation) {
      metadata.push({
        namespace: MessageMetadataNamespaces.CONVERSATION_MENTIONS,
        payload: membersMentionInConversation.map((entity) => ({
          entity_type: entity.entity_type,
          entity_id: entity.entity_id,
          start: entity.start,
          end: entity.end,
        })),
      });
    }

    if (metadata.length > 0) {
      return metadata.map((datum) => ({
        mimetype: 'application/json',
        ...datum,
      }));
    } else {
      return undefined;
    }
  }

  __getNextSortNumber(organizationId, { conversation } = {}) {
    const organization = this.host.models.Organization.get(organizationId);
    if (organization && !conversation) {
      conversation = organization.conversations[0];
    }

    const highestSortNumber = (organization && organization.highestSortNumber) || 0;
    const lastBangMessageSortNumber = (conversation && conversation.lastBangMessageSortNumber) || 0;
    const sortNumber = Math.max(highestSortNumber, lastBangMessageSortNumber) + 1;

    return sortNumber;
  }

  __generateAttachmentDescriptor = async (attachment: string | Object) => {
    const id = `local:${uuid.v4()}`;

    if (process.env.NODE_TARGET === 'web') {
      // web - attachment must be a File or Blob
      if (!(attachment instanceof File || attachment instanceof Blob))
        Validator.raise('attachment', 'invalid', 'attachment (File or Blob) is invalid');
      if (!attachment.name) attachment.name = 'attachment';
      const attachmentDescriptor = {
        id,
        localFile: attachment,
        name: attachment.name,
        size: attachment.size,
        contentType: attachment.type,
        isLoading: true,
        isLocal: true,
      };

      if (attachment.preview) {
        attachmentDescriptor.localPath = attachment.preview;
        attachmentDescriptor.localPathPromise = () =>
          Promise.resolve(attachmentDescriptor.localPath);
        attachmentDescriptor.isLoading = false;
      } else {
        const localPathLoaderPromise = loadLocalFilePathWithFileReader(attachment);
        attachmentDescriptor.localPathPromise = localPathLoaderPromise;

        localPathLoaderPromise.then((result) => {
          attachmentDescriptor.localPath = result;
          attachmentDescriptor.isLoading = false;
        });
      }

      return attachmentDescriptor;
    } else if (process.env.NODE_TARGET === 'node') {
      // node - attachment must be either a string - path to a local file, or Buffer
      if (!(typeof attachment === 'string' || attachment instanceof Buffer))
        Validator.raise('attachment', 'invalid', 'attachment (string or Buffer) is invalid');

      let size, contentType, name;

      const fs = require('fs');
      const path = require('path');
      const mime = require('mime');
      const fileType = require('file-type');

      if (typeof attachment === 'string') {
        // local path
        size = fs.statSync(attachment).size;
        contentType = mime.lookup(attachment);
        name = path.basename(attachment);
      } else {
        // buffer
        const fileTypeResult = await fileType.fromBuffer(attachment);
        if (fileTypeResult) {
          const { ext, mime } = fileTypeResult;
          name = `attachment.${ext}`;
          contentType = mime;
        }
        size = attachment.byteLength;
      }

      const attachmentDescriptor = {
        id,
        localPath: typeof attachment === 'string' ? attachment : null,
        localFile: attachment instanceof Buffer ? attachment : null,
        name,
        size,
        contentType,
        isLocal: true,
      };

      return attachmentDescriptor;
    }
  };

  previewMessage(messageId: string | Object, options: Object | null | undefined = {}) {
    const message = this._resolveMessage(messageId);
    let messageFields = {};
    for (const [field, value] of Object.entries(message)) {
      if (isConfigurableProperty(message, field) && !MESSAGE_NO_COPY_FIELDS.includes(field)) {
        messageFields[field] = value;
      }
    }

    messageFields = {
      ...messageFields,
      id: uuid.v4(),
      conversationId: null,
      ...options,
    };

    this.__setForwardedMessageProperties({
      origMessage: message,
      organizationId: message.__organizationId,
      message: messageFields,
    });

    const newMessage = this.host.models.Message.inject(messageFields);

    for (const userStatus of message.statusesPerRecipient) {
      const accountId = userStatus.userId || userStatus.distributionListId;
      const uniqueId = `${newMessage.id}:${accountId}`;
      this.host.models.MessageStatusPerRecipient.inject({
        ...userStatus,
        id: uniqueId,
        messageId: newMessage.id,
      });
    }

    return newMessage;
  }

  endPreviewMessage(messageId: string | Object) {
    const message = this._resolveMessage(messageId);
    if (!message) return;

    for (const userStatus of message.statusesPerRecipient) {
      this.host.models.MessageStatusPerRecipient.eject(userStatus);
    }

    this.host.models.Message.eject(message);
  }

  async sendToUser(userId: string | Object, body: string, options: Object | null | undefined = {}) {
    if (!this.host.config.condensedReplays) {
      return this.send(userId, body, options);
    }

    const {
      organizationId,
      senderId = this.host.currentUserId,
      groupName,
      groupMetadata,
    } = options;

    if (!organizationId) throw new Error('organizationId is required');

    userId = this._resolveModelIdWithTypes(userId, 'user');
    let user = this._resolveEntity(userId, 'user');
    if (!(user && user.profileByOrganizationId && user.profileByOrganizationId[organizationId])) {
      user = await this.host.users.findOrCreate(userId, { organizationId });
    }

    const { isRole: senderIsRole, roleBotId: senderRoleBotId } = await this.__resolveRoleBotId(
      senderId,
      organizationId
    );
    if (senderIsRole) {
      const group = await this.__prepareRoleP2PGroup(
        organizationId,
        userId,
        senderRoleBotId,
        groupName,
        { groupMetadata }
      );

      return this.sendToGroup(group, body, { ...options, organizationId, senderId });
    }

    const profile = user && user.profileByOrganizationId[organizationId];
    const conversationId = profile && profile.conversationId;
    if (!conversationId) {
      throw new Error('conversationId not found for p2p message');
    }

    const conversation = this.host.conversations.getById(conversationId);
    if (!conversation) {
      this.host.models.Conversation.inject({
        counterPartyId: user.id,
        counterPartyType: user.$entityType,
        id: conversationId,
        organizationId,
      });
    }

    return this.sendToConversation(conversationId, body, options);
  }

  async sendToGroup(
    groupId: string | Object,
    body: string,
    options: Object | null | undefined = {}
  ) {
    if (!this.config.condensedReplays) {
      return this.send(groupId, body, options);
    }

    groupId = this._resolveModelId(groupId);
    let group = this._resolveEntity(groupId, 'group');
    if (!group) {
      group = await this.host.groups.find(groupId);
    }

    if (!(group && group.conversationId)) {
      throw new Error('group conversationId not found');
    }

    const conversation = this.host.conversations.getById(group.conversationId);
    if (!conversation) {
      this.host.models.Conversation.inject({
        counterPartyId: group.id,
        counterPartyType: group.$entityType,
        id: group.conversationId,
        organizationId: group.organizationId,
      });
    }

    return this.sendToConversation(group.conversationId, body, options);
  }

  async sendToRole(roleId: string | Object, body: string, options: Object | null | undefined = {}) {
    const {
      organizationId,
      senderId = this.host.currentUserId,
      groupMetadata,
      groupName,
    } = options;

    if (this.host.config.condensedReplays) {
      if (!organizationId) throw new Error('organizationId is required');

      const { isRole, roleBotId } = await this.__resolveRoleBotId(roleId, organizationId);

      if (!isRole) {
        throw new Error('recipient must be a role');
      }

      roleId = roleBotId;
    }

    const group = await this.__prepareRoleP2PGroup(organizationId, roleId, senderId, groupName, {
      groupMetadata,
    });

    return this.sendToGroup(group, body, { ...options, organizationId, senderId });
  }

  async sendToPatient(
    recipientId: string | Object,
    body: string,
    options: Object | null | undefined = {}
  ) {
    const {
      groupMetadata,
      organizationId,
      senderId = this.host.currentUserId,
      ...restOptions
    } = options;

    options = { ...restOptions, organizationId, senderId };

    if (this.host.config.condensedReplays) {
      if (!organizationId) throw new Error('organizationId is required');
    }

    let entityId, metadata;

    if (recipientId && recipientId.length === 1) {
      entityId = recipientId[0];
    } else {
      entityId = recipientId;
    }

    const entity = this._resolveEntity(entityId, 'user');

    if (!entity) throw new Error('user could not be resolved');

    if (entity.isPatientContact) {
      metadata = this.host.patients._createPatientContactGroupMetadata(entity);
    } else {
      metadata = this.host.patients._createPatientGroupMetadata(entity);
    }

    const group = await this.host.groups.createPatientGroup({
      organizationId,
      recipientId,
      senderId,
      metadata,
    });

    this.host.metadata.__injectMetadata(group.id, organizationId, metadata);

    return this.sendToGroup(group, body, { ...options, organizationId, senderId });
  }

  async __resolveRoleBotId(roleBotId, organizationId) {
    roleBotId = this.host.roles.__resolveRoleId(roleBotId);
    let isRole;
    try {
      const existingRoleId = this._resolveModelIdWithTypes(roleBotId, 'role');
      isRole = !!this._resolveEntity(existingRoleId, 'role');
    } catch (err) {
      isRole = false;
    }

    if (!isRole) {
      let user = this._resolveEntity(roleBotId, 'user');
      if (!(user && user.profileByOrganizationId && user.profileByOrganizationId[organizationId])) {
        user = await this.host.users.find(roleBotId, { organizationId });
      }

      if (user && user.isRoleBot) {
        isRole = true;
      }
    }

    return { isRole, roleBotId };
  }

  async injectToConversation(
    conversationId: string,
    body: string,
    options: Record<string, unknown> | null | undefined = {}
  ) {
    conversationId = this._resolveModelIdWithTypes(conversationId, 'conversation');
    const conversation = this.host.conversations.getById(conversationId);
    if (!conversation) {
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    }

    const sender = await this.__resolveSender(options);
    const { counterPartyId, counterPartyType, organizationId } = conversation;

    const counterParty = this._resolveEntity(counterPartyId, counterPartyType);

    const senderOrganizationId = organizationId;
    const recipientOrganizationId = senderOrganizationId;

    options = this.__normalizeSendOptions(options, {
      counterParty,
      senderOrganizationId,
      senderId: sender.id,
    });

    const message = await this.__createNewMessage(counterParty, body, {
      conversationId,
      organizationId,
      senderOrganizationId,
      recipientOrganizationId,
      priority: MessagePriority.NORMAL,
      ...options,
    });

    message.senderStatus = MessageSenderStatus.SENT;
    message.isInjected = true;

    this.host.models.Message.inject(message);

    return message;
  }

  async sendToConversation(
    conversationId: string | Object,
    body: string,
    options: Object | null | undefined = {}
  ) {
    if (!this.host.config.condensedReplays) {
      return this.send(conversationId, body, options);
    }

    conversationId = this._resolveModelIdWithTypes(conversationId, 'conversation');
    const conversation = this.host.conversations.getById(conversationId);
    if (!conversation) {
      throw new errors.NotFoundError(this.host.models.Conversation.name, conversationId);
    }

    const sender = await this.__resolveSender(options);
    const { counterPartyId, counterPartyType, organizationId } = conversation;

    const counterParty = this._resolveEntity(counterPartyId, counterPartyType);
    this.host.requireUser();

    const { groupType, memberIds } = counterParty;

    if (
      groupType === GroupType.FORUM &&
      !(memberIds && memberIds.includes(this.host.currentUserId))
    ) {
      await this.host.forums.join(counterParty);
    }

    const senderOrganizationId = organizationId;
    const recipientOrganizationId = senderOrganizationId;
    options = this.__normalizeSendOptions(options, {
      counterParty,
      senderOrganizationId,
      senderId: sender.id,
    });

    let message = await this.__createNewMessage(counterParty, body, {
      conversationId,
      organizationId,
      senderOrganizationId,
      recipientOrganizationId,
      ...options,
    });
    this.host.events.deferEventQueue();
    this.host.models.Message.inject(message);
    this.host.emit('message:sending', message);

    const { attachmentFiles, deleteOnRead, escalate, metadata, priority, ttl, subType } = options;

    try {
      const attrs = await this.host.api.messages.send({
        attachment: _.head(attachmentFiles),
        body,
        conversationId: conversation.id,
        deleteOnRead,
        escalate,
        localId: message ? message.id : uuid.v4(),
        metadata,
        priority: MessagePriority.toServer(priority),
        senderOrganizationId,
        ttl,
        subType,
      });

      let sentMessage;
      if (attrs.last_message) {
        sentMessage = attrs.last_message;
      } else {
        sentMessage = attrs;
      }
      message = await this.__retrieveSentMessage(sentMessage, { instance: message });

      if (message.unreadMessageCount) {
        conversation.unreadCount = message.unreadMessageCount;
      }

      if (message.unreadPriorityMessageCount) {
        conversation.unreadPriorityCount = message.unreadPriorityMessageCount;
      }

      conversation.lastMessage = message;
      conversation.highestSortNumber = message.sortNumber
        ? message.sortNumber
        : this.__getNextSortNumber(senderOrganizationId, { conversation });
      conversation.isLive = true;
      conversation.timeline.push(message);
      this.host.models.Conversation.inject(conversation);
      message.inTimeline = true;
      this.host.models.Message.inject(message);
      this.host.emit('message:sent', message);
    } catch (err) {
      this.logger.error('error while sending message', err);
      if (isAutoForwardMaxError(err)) {
        message.senderError = AutoForwardMaxError;
      }
      message.senderStatus = MessageSenderStatus.FAILED;
      this.host.models.Message.inject(message);
    }

    this.host.events.resumeEventQueue();

    return message;
  }

  async sendToDistributionList(
    distributionListId: string | Object,
    body: string,
    options: Object | null | undefined = {}
  ) {
    if (!this.config.condensedReplays) {
      return this.send(distributionListId, body, options);
    }

    distributionListId = this._resolveModelIdWithTypes(distributionListId, 'distributionList');
    let distList = this._resolveEntity(distributionListId, 'distributionList');
    if (!distList) {
      distList = await this.host.distributionLists.find(distributionListId);
    }

    if (!(distList && distList.conversationId)) {
      throw new Error('distributionList conversationId not found');
    }

    let conversation = this.host.conversations.getById(distList.conversationId);
    if (!conversation) {
      conversation = this.host.models.Conversation.inject({
        counterPartyId: distList.id,
        counterPartyType: distList.$entityType,
        id: distList.conversationId,
        organizationId: distList.organizationId,
      });
    }

    return this.sendToConversation(conversation.id, body, options);
  }

  async sendToGroupOfUsers(
    userIds: string[] | Object[],
    body: string,
    {
      avatarFile,
      groupMetadata,
      groupName,
      organizationId,
      senderId,
      patientContextId,
      ...restOptions
    } = {}
  ) {
    const group = await this.__prepareGroupForUsers(organizationId, userIds, senderId, groupName, {
      groupMetadata,
      patientContextId,
      avatarFile,
    });

    if (this.config.condensedReplays) {
      const { conversationId } = group;
      await this.host.conversations.find(conversationId);
      return this.sendToConversation(conversationId, body, {
        ...restOptions,
        organizationId,
        senderId,
      });
    } else {
      return this.sendToGroup(group, body, { ...restOptions, organizationId, senderId });
    }
  }

  @reusePromise()
  __prepareGroupForUsers(
    organizationId,
    memberIds,
    senderId,
    name,
    { groupMetadata, patientContextId, avatarFile } = {}
  ) {
    if (memberIds.length === 0) Validator.raise('memberIds', 'required');

    return this.host.groups.create({
      avatarFile,
      memberIds,
      metadata: groupMetadata,
      name,
      organizationId,
      senderId,
      patientContextId,
    });
  }

  @reusePromise()
  async __prepareRoleP2PGroup(organizationId, recipientId, senderId, name, { groupMetadata } = {}) {
    const localGroup = this.host.conversations.findLocalRoleP2PConversation(recipientId, senderId);
    if (localGroup) {
      return localGroup.counterParty;
    }

    const group = await this.host.roles.createP2PGroup({
      metadata: groupMetadata,
      name,
      organizationId,
      recipientId,
      senderId,
    });

    return group;
  }

  // TODO send a message and immediately recall should queue recall after message is sent

  /**
   * Lets current user recall their own message
   * @param  {string|Object} id - The message ID. If message is still pending to send, just removes it locally
   * @return {Promise} a promise
   */
  async recall(id: string | Object | string[] | Object[]) {
    this.host.requireUser();
    const ids = arrayWrap(id).filter(Boolean);
    const promises = ids.map(this.__recallSingle.bind(this));

    await Promise.all(promises);
  }

  async __recallSingle(id: string | Object) {
    const message = this._resolveMessage(id);

    if (message) {
      if (message.senderId !== this.host.currentUserId) {
        throw new errors.PermissionDeniedError(message, message.serverId, 'recall');
      }
      // message never sent to server so we don't have serverId
      // TODO send a message and immediately recall should queue recall after message is sent
      if (message.$synced === false || !message.serverId) {
        this.host.models.Message.eject(message);
      } else {
        await this.host.api.messages.destroy(message.serverId);
        this.host.models.Message.eject(message);
      }
    } else {
      await this.host.api.messages.destroy(id);
    }
  }

  async retrySend(id: string | Object) {
    this.host.requireUser();

    let message = this._resolveMessage(id);
    if (!message) throw new errors.NotFoundError(this.host.models.Message.name, id);
    const { isForwarded, senderStatus } = message;

    // can retry only FAILED messages
    if (senderStatus !== MessageSenderStatus.FAILED) {
      Validator.raise(
        'message',
        'invalid',
        'Message to retry must be in a FAILED senderStatus and sent from current user'
      );
    }

    this.host.events.deferEventQueue();
    message.senderStatus = MessageSenderStatus.RETRYING;
    this.host.models.Message.inject(message);
    this.host.emit('message:sending', message);

    if (isForwarded) {
      message = await this.__retryApiForward(message);
    } else {
      message = await this.__retryApiSend(message);
    }

    if (this.config.condensedReplays) {
      const conversation = this.host.conversations.getById(message.conversationId);

      if (message.unreadMessageCount) {
        conversation.unreadCount = message.unreadMessageCount;
      }

      if (message.unreadPriorityMessageCount) {
        conversation.unreadPriorityCount = message.unreadPriorityMessageCount;
      }

      conversation.lastMessage = message;
      conversation.highestSortNumber = message.sortNumber;
      this.host.models.Conversation.inject(conversation);
    }

    this.host.events.resumeEventQueue();

    return message;
  }

  __retryApiSend = async (message) => {
    const {
      _originalBody: body,
      attachments,
      conversationId,
      counterPartyId,
      deleteOnRead,
      id: messageId,
      metadata,
      priority,
      recipientOrganizationId,
      senderOrganizationId,
      shouldEscalate: escalate,
      ttl,
    } = message;

    try {
      let attrs = await this.host.api.messages.send({
        attachment: _.get(attachments, '0.localFile'),
        body,
        conversationId,
        counterPartyId,
        deleteOnRead,
        escalate,
        localId: messageId,
        metadata,
        priority: MessagePriority.toServer(priority),
        recipientOrganizationId,
        senderOrganizationId,
        ttl,
      });

      if (this.host.config.condensedReplays && attrs.last_message) {
        attrs = attrs.last_message;
      }
      message = await this.__retrieveSentMessage(attrs, { instance: message });
      this.host.emit('message:sent', message);
    } catch (err) {
      this.logger.warn('error while sending message', err);
      if (isAutoForwardMaxError(err)) {
        message.senderError = AutoForwardMaxError;
      }
      message.senderStatus = MessageSenderStatus.FAILED;
      this.host.models.Message.inject(message);
    }

    return message;
  };

  __retryApiForward = async (message) => {
    const {
      conversationId,
      counterPartyId,
      id: messageId,
      metadata,
      originalMessageId,
      priority,
      recipientOrganizationId,
      senderOrganizationId,
    } = message;

    try {
      const res = await this.host.api.messages.forward(originalMessageId, counterPartyId, {
        conversationId,
        localId: messageId,
        metadata,
        priority: MessagePriority.toServer(priority),
        recipientOrganizationId,
        senderOrganizationId,
      });
      const id = res['message_id'];
      message = await this.find(id, { instance: message, replace: true });
      this.host.emit('message:sent', message);
    } catch (err) {
      this.logger.error('error while forwarding message', err);
      message.senderStatus = MessageSenderStatus.FAILED;
      this.host.models.Message.inject(message);
    }

    return message;
  };

  async resend(id: string | Object, { priority, retrieveMessage = false, senderId } = {}) {
    const message = this._resolveMessage(id);
    if (priority === undefined) priority = message.priority;
    if (senderId === undefined) senderId = message.senderId;

    let {
      attachments,
      body,
      conversationId,
      counterParty,
      counterPartyId,
      deleteOnRead,
      isForwarded,
      metadata,
      originalMessageId,
      recipientOrganizationId: organizationId,
      ttl,
    } = message;
    if (isForwarded) {
      if (this.config.condensedReplays) {
        return this.forwardToConversation(originalMessageId, conversationId, {
          organizationId,
          priority,
        });
      }

      return this.forward(originalMessageId, counterPartyId, {
        organizationId,
        priority,
        retrieveMessage,
        senderId,
      });
    } else {
      const attachmentFiles = [];
      metadata = metadata.filter(
        ({ namespace }) => namespace !== MessageMetadataNamespaces.ROLE_SENDER
      );

      if (attachments) {
        for (const attachment of attachments) {
          const file = (await this._downloadAttachmentBlobAndUrl(id, attachment.id)).blob;
          file.name = attachment.name;
          attachmentFiles.push(file);
        }
      }

      if (this.config.condensedReplays) {
        return this.sendToConversation(conversationId, body, {
          attachmentFiles,
          deleteOnRead,
          organizationId,
          priority,
          metadata,
          senderId,
          ttl,
        });
      }

      return this.__sendToCounterParty(counterParty, body, {
        attachmentFiles,
        deleteOnRead,
        organizationId,
        priority,
        senderId,
        metadata,
        ttl,
      });
    }
  }

  async forwardToConversation(messageId: string | Object, conversationId, args = {}) {
    if (!this.config.condensedReplays) {
      return this.forward(messageId, conversationId, args);
    }

    let { metadata = [], priority, senderId = this.host.currentUserId } = args;

    const conversation = this.host.conversations.getById(conversationId);
    if (!conversation) {
      throw new Error('conversation not found');
    }

    this.host.requireUser();
    const origMessage = this._resolveMessage(messageId);
    if (!origMessage) {
      throw new errors.NotFoundError(this.host.models.Message.name, messageId);
    }

    const { attachments, body, serverId } = origMessage;

    if (!serverId) throw new Error('original message was not sent');
    senderId = this.__resolveSenderId({ senderId });

    if (priority === undefined) priority = origMessage.priority;

    const { counterPartyId, counterPartyType, organizationId } = conversation;
    const counterParty = this._resolveEntity(counterPartyId, counterPartyType);
    const senderOrganizationId = organizationId;
    const originalMetadata = _.get(origMessage, 'originalMetadata');
    let options = { metadata, priority, senderId, originalMetadata };
    options = this.__normalizeSendOptions(options, { counterParty, senderOrganizationId });
    metadata = options.metadata;
    const message = await this.__createNewMessage(counterParty, body, {
      conversationId,
      organizationId,
      isForwarded: true,
      ...options,
    });
    message.attachments = attachments;

    this.__setForwardedMessageProperties({ origMessage, organizationId, message });

    this.host.events.deferEventQueue();
    this.host.models.Message.inject(message);
    this.host.emit('message:sending', message);

    try {
      const attrs = await this.host.api.messages.forward(serverId, null, {
        conversationId,
        localId: message.id,
        metadata,
        priority: MessagePriority.toServer(priority),
      });

      const newMessage = await this.__retrieveSentMessage(attrs.message, { instance: message });
      if (newMessage.unreadMessageCount) {
        conversation.unreadCount = newMessage.unreadMessageCount;
      }

      if (newMessage.unreadPriorityMessageCount) {
        conversation.unreadPriorityCount = newMessage.unreadPriorityMessageCount;
      }

      this.host.models.Conversation.inject(conversation);
      this.host.emit('message:sent', newMessage);
    } catch (err) {
      this.logger.error('error while forwarding message', err);
      message.senderStatus = MessageSenderStatus.FAILED;
      this.host.models.Message.inject(message);
    }

    this.host.events.resumeEventQueue();

    return message;
  }

  async forwardToUser(messageId: string | Object, entityId, ...args) {
    if (!this.config.condensedReplays) {
      return this.forward(messageId, entityId, ...args);
    }

    const [options = {}] = args;

    const {
      organizationId,
      senderId = this.host.currentUserId,
      groupName,
      groupMetadata,
    } = options;

    if (!organizationId) throw new Error('organizationId is required');

    entityId = this._resolveModelIdWithTypes(entityId, 'user');
    let user = this._resolveEntity(entityId, 'user');
    if (!(user && user.profileByOrganizationId && user.profileByOrganizationId[organizationId])) {
      user = await this.host.users.find(entityId, { organizationId });
    }

    const { isRole: senderIsRole, roleBotId: senderRoleBotId } = await this.__resolveRoleBotId(
      senderId,
      organizationId
    );
    if (senderIsRole) {
      const group = await this.__prepareRoleP2PGroup(
        organizationId,
        entityId,
        senderRoleBotId,
        groupName,
        { groupMetadata }
      );

      return this.forwardToGroup(messageId, group, { ...options, organizationId, senderId });
    }

    const profile = user && user.profileByOrganizationId[organizationId];
    const conversationId = profile && profile.conversationId;
    if (!conversationId) {
      throw new Error('conversationId not found for p2p message');
    }

    const conversation = this.host.conversations.getById(conversationId);
    if (!conversation) {
      this.host.models.Conversation.inject({
        counterPartyId: user.id,
        counterPartyType: user.$entityType,
        id: conversationId,
        organizationId,
      });
    }

    return this.forwardToConversation(messageId, conversationId, ...args);
  }

  async forwardToGroup(messageId: string | Object, groupId, ...args) {
    if (!this.config.condensedReplays) {
      return this.forward(messageId, groupId, ...args);
    }

    groupId = this._resolveModelId(groupId);
    let group = this._resolveEntity(groupId, 'group');
    if (!group) {
      group = await this.host.groups.find(groupId);
    }

    if (!(group && group.conversationId)) {
      throw new Error(`group ${groupId} not found`);
    }

    const conversation = this.host.conversations.getById(group.conversationId);
    if (!conversation) {
      this.host.models.Conversation.inject({
        counterPartyId: group.id,
        counterPartyType: group.$entityType,
        id: group.conversationId,
        organizationId: group.organizationId,
      });
    }

    return this.forwardToConversation(messageId, group.conversationId, ...args);
  }

  async forwardToDistributionList(
    messageId: string | Object,
    distributionListId: string | Object,
    options = {}
  ) {
    if (!this.config.condensedReplays) {
      return this.forward(messageId, distributionListId, options);
    }

    distributionListId = this._resolveModelId(distributionListId);
    let distList = this._resolveEntity(distributionListId, 'distributionList');

    if (!distList) {
      distList = await this.host.distributionLists.find(distributionListId);
    }

    if (!(distList && distList.conversationId)) {
      throw new Error(`distributionList ${distributionListId} not found`);
    }

    const conversation = this.host.conversations.getById(distList.conversationId);
    if (!conversation) {
      this.host.models.Conversation.inject({
        counterPartyId: distList.id,
        counterPartyType: distList.$entityType,
        id: distList.conversationId,
        organizationId: distList.organizationId,
      });
    }

    return this.forwardToConversation(messageId, distList.conversationId, options);
  }

  async forwardToGroupOfUsers(
    messageId: string,
    userIds: string[] | Object[],
    { groupMetadata, groupName, organizationId, senderId, ...options } = {}
  ) {
    const group = await this.__prepareGroupForUsers(
      organizationId,
      userIds,
      senderId,
      groupName,
      false,
      { groupMetadata }
    );

    if (this.config.condensedReplays) {
      const { conversationId } = group;

      await this.host.conversations.find(conversationId);
      return this.forwardToConversation(messageId, conversationId, {
        ...options,
        organizationId,
        senderId,
      });
    }

    return this.forwardToGroup(messageId, group, { ...options, organizationId, senderId });
  }

  async forwardToRole(messageId, roleId, options = {}) {
    const {
      organizationId,
      senderId = this.host.currentUserId,
      groupMetadata,
      groupName,
    } = options;

    if (this.host.config.condensedReplays) {
      if (!organizationId) throw new Error('organizationId is required');

      const { isRole, roleBotId } = await this.__resolveRoleBotId(roleId, organizationId);

      if (!isRole) {
        throw new Error('recipient must be a role');
      }

      roleId = roleBotId;
    }

    const group = await this.__prepareRoleP2PGroup(organizationId, roleId, senderId, groupName, {
      groupMetadata,
    });

    return this.forwardToGroup(messageId, group.id, { ...options, organizationId, senderId });
  }

  /**
   * Lets current user forward a message to another recipient
   * @param  {string|Object} id - ID of the message to forward
   * @param  {string|Object} recipientId - A user or a group ID that the message should be forwarded to
   * @return {Promise} a promise
   */
  async forward(
    id: string | Object,
    entityId: string | Object | string[] | Object[],
    /*: {
  groupMetadata?: ?Object,
  groupName?: ?string,
  organizationId?: ?string,
  preferExistingGroup?: ?boolean,
  priority?: ?number,
  recipientOrganizationId?: ?string,
  retrieveMessage?: ?boolean,
  senderOrganizationId?: ?string,
  }*/
    {
      organizationId,
      senderOrganizationId = organizationId,
      recipientOrganizationId = senderOrganizationId,
      groupMetadata,
      groupName,
      metadata = [],
      preferExistingGroup = false,
      priority,
      retrieveMessage = false,
      senderId = this.host.currentUserId,
    } = {}
  ) {
    if (this.config.condensedReplays) {
      throw new Error(
        'the forward() method has been deprecated in favor of forwardToUser(), forwardToGroup(), etc.'
      );
    }

    this.host.requireUser();
    const origMessage = this._resolveMessage(id);

    if (!origMessage) throw new errors.NotFoundError(this.host.models.Message.name, id);

    const { attachments, body, serverId } = origMessage;

    if (!serverId) throw new Error('original message was not sent');
    senderId = this.__resolveSenderId({ senderId });

    // in case of an array of users
    if (Array.isArray(entityId)) {
      const userIds = entityId.map((u) => this._resolveModelIdWithTypes(u, ['user', 'role']));
      const group = await this.__prepareGroupForUsers(
        organizationId,
        userIds,
        senderId,
        groupName,
        false,
        { groupMetadata }
      );
      entityId = group.id;
    }

    if (priority === undefined) priority = origMessage.priority;

    let { counterParty, organizationId: inferredOrganizationId } =
      await this.__resolveCounterPartyEntityAndOrganizationId(entityId, senderOrganizationId);

    if (inferredOrganizationId) {
      senderOrganizationId = recipientOrganizationId = inferredOrganizationId;
    }
    recipientOrganizationId = recipientOrganizationId || senderOrganizationId;

    if (counterParty) entityId = counterParty.serverId;

    const sender = await this.__resolveSender({ organizationId, senderId, senderOrganizationId });
    const isUser = (item) => item && item.$entityType === 'user';
    const isRole = (item) => isUser(item) && item.isRoleBot;
    const originalMetadata = _.get(origMessage, 'originalMetadata');

    if (isRole(counterParty) || (isUser(counterParty) && isRole(sender))) {
      const group = await this.__prepareRoleP2PGroup(
        organizationId,
        counterParty.id,
        senderId,
        groupName,
        { groupMetadata }
      );
      counterParty = group;
      entityId = group.id;
    }

    Validator.notEmpty('entityId', entityId, 'entityId not found');

    let options = { metadata, priority, senderId, originalMetadata };
    options = this.__normalizeSendOptions(options, { counterParty, senderOrganizationId });
    metadata = options.metadata;
    // We don't set priority here, because forwarding a priority message to an auto-escalate role
    // will cause a server error if we send priority = 0, even though it will be 0 by the time
    // the message is actually sent; can be changed when TS-5376 is complete.

    let message = await this.__createNewMessage(counterParty, body, {
      organizationId,
      senderOrganizationId,
      recipientOrganizationId,
      isForwarded: true,
      ...options,
    });
    message.attachments = attachments;

    this.__setForwardedMessageProperties({ origMessage, organizationId, message });

    this.host.events.deferEventQueue();
    this.host.models.Message.inject(message);
    this.host.emit('message:sending', message);

    try {
      const res = await this.host.api.messages.forward(serverId, entityId, {
        localId: message.id,
        metadata,
        priority: MessagePriority.toServer(priority),
        recipientOrganizationId,
        senderOrganizationId,
      });
      const id = res['message_id'];

      if (retrieveMessage) {
        message = await this.find(id, { instance: message, replace: true });
      } else {
        message.senderStatus = MessageSenderStatus.SENT;
        message.serverId = id;
        message.$synced = undefined;
        this.host.models.Message.inject(message);
      }
      this.host.emit('message:sent', message);
    } catch (err) {
      this.logger.error('error while forwarding message', err);
      message.senderStatus = MessageSenderStatus.FAILED;
      this.host.models.Message.inject(message);
    }

    this.host.events.resumeEventQueue();

    return message;
  }

  __setForwardedMessageProperties({ origMessage, organizationId, message }) {
    const {
      createdAt,
      currentSenderRole,
      originalForwardedMessageCreatedAt,
      originalMessageId,
      originalSender,
      originalSenderRole,
      sender,
      senderRole,
      serverId,
      subType,
    } = origMessage;

    if (!message.originalForwardedMessageCreatedAt) {
      message.originalForwardedMessageCreatedAt = originalForwardedMessageCreatedAt || createdAt;
    }

    if (!message.originalMessageId) {
      message.originalMessageId = originalMessageId || serverId;
    }

    if (message.originalSender) {
      message.originalSender = originalSender;
    } else if (sender) {
      message.originalSender = {
        displayName: sender.displayName,
        id: sender.id,
        organizationId,
      };
    }

    if (!message.originalSenderRole) {
      if (subType === MessageSubType.ROLE_AWAY_NOTIFICATION && currentSenderRole) {
        message.originalSenderRole = {
          $entityType: 'role',
          botUserId: currentSenderRole.botUserId,
          displayName: currentSenderRole.displayName,
          id: currentSenderRole.id,
          organizationId,
        };
      } else if (originalSenderRole) {
        message.originalSenderRole = { ...originalSenderRole };
      } else if (senderRole) {
        message.originalSenderRole = { ...senderRole };
      }
    }

    if (!message.subType) {
      message.subType = subType;
    }

    return message;
  }

  processAlertMetadata(messageServerId, payload) {
    const message = this.host.models.Message.get(messageServerId);
    this._handleAlertPayload(message, payload);
    message.shouldEnsureRecipientStatus = true;
    this.host.models.Message.inject(message);
  }

  reactToMessageMetadataEvent({ data } = {}) {
    try {
      data = jsonCloneDeep(data);

      if (
        data &&
        data['data'].length &&
        data['data'].length &&
        (data['data'][0].namespace === MessageMetadataNamespaces.VWR_CALL ||
          data['data'][0].namespace === MessageMetadataNamespaces.ALERT)
      ) {
        const messageServerId = data['message_id'];

        const message = this.host.models.Message.get(messageServerId);
        if (!message) return;
        const payload = JSON.parse(data['data'][0].payload);
        if (data['data'][0].namespace === MessageMetadataNamespaces.VWR_CALL) {
          message.vwrCallInvite = payload;
          if (payload.status === 'complete' || payload.status === 'expire') {
            this.host.calls._handleEndedCall({
              payload: {
                reason: 'vwrended',
              },
              roomName: payload.twilio_room_name,
            });
          }
        } else if (data['data'][0].namespace === MessageMetadataNamespaces.ALERT) {
          this.processAlertMetadata(messageServerId, payload);
          this.host.emit('alertMetadataSSEReceived', { messageId: messageServerId, data });
          return;
        }
        return this.host.models.Message.inject(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  __injectMessageReaction(message, { accountId, reaction, reactionTimestamp } = {}) {
    if (!message || !accountId || !reaction || !reactionTimestamp) return;

    this.host.models.User.ensureEntity(accountId, { onlyPlaceholder: true });

    const attrs = {
      id: `${message.id}:${accountId}`,
      messageId: message.id,
      reaction,
      reactionTimestamp,
      userId: accountId,
    };
    const messageStatus = this.host.models.MessageStatusPerRecipient.inject(attrs);

    return messageStatus;
  }

  reactToMessageStatusEvent({ data }) {
    if (this.config.condensedReplays) {
      this.reactToMessageStatusEventCR({ data });
      return;
    }
    data = jsonCloneDeep(data);
    const messageServerId = data['client_id'];
    const isRecalled = data['is_recalled'];

    const message = this.host.models.Message.get(messageServerId);
    if (!message) return;

    if (isRecalled) {
      this.host.models.Message.eject(message);
      return;
    }

    this.__injectMessageStatus(message, data);
    if (
      MESSAGE_STATUS_ORDER[message.markedRecipientStatus] <
      MESSAGE_STATUS_ORDER[MessageRecipientStatus.DELIVERED]
    ) {
      this.markAsReceived(message);
    }
  }

  reactToMessageStatusEventCR({ data }) {
    data = jsonCloneDeep(data);
    const messageServerId = data['client_id'];
    const isRecalled = data['is_recalled'];
    const isUnread = data['is_unread'];

    const message = this.host.models.Message.get(messageServerId);
    if (!message) return;

    if (!data.conversation_id) {
      data.conversation_id = message.conversationId;
    }

    const conversation = this.host.conversations.getById(data.conversation_id);
    if (!conversation) return;

    if (isRecalled) {
      this.host.models.Message.eject(message);
    } else {
      message.shouldEnsureRecipientStatus = true;
      if (isUnread !== undefined) message.isUnread = isUnread;
      this.host.models.Message.inject(message);

      if (
        conversation._markingAsReadSortNumber &&
        message.sortNumber >= conversation._markingAsReadSortNumber
      ) {
        this.host.conversations.__clearOptimisticStatus([conversation]);
      }
    }

    this.host.conversations.__injectConversation(data);
  }

  __injectMessageStatus(message, data) {
    if (!message) return;

    const accountId = data['account_id'] || data['accountId'];
    const attrs = {
      createdAt: data['timestamp'] || data['createdAt'],
      id: `${message.id}:${accountId}`,
      messageId: message.id,
      status: MessageRecipientStatus.resolve(data['status']),
    };

    if (message.counterPartyType === 'distributionList') {
      attrs.distributionListId = accountId;
    } else {
      attrs.userId = accountId;
      this.host.models.User.ensureEntity(accountId, { onlyPlaceholder: true });
    }

    const messageStatus = this.host.models.MessageStatusPerRecipient.inject(attrs);

    return messageStatus;
  }

  _resolveMessage = (id: string | Object) => {
    if (this.host.models.Message.is(id)) return id;
    if (typeof id === 'string') return this.host.models.Message.get(id);
    return null;
  };

  _looksLikeDistributionListMessage = ({ body }) => {
    if (!body) return false;
    const groups = body.match(/^\[(.+?)\]: /);

    return groups ? groups[1] : false;
  };

  async _findCR(
    serverId: string,
    { includeCounterParty = true, includeStatuses = true, instance, replace = false } = {}
  ) {
    this.host.requireUser();

    const attrs = await this.host.api.messages.find(serverId);
    if (!attrs) return null;

    const { message: rawMessage } = attrs;
    if (!rawMessage) return null;
    rawMessage.conversationId = attrs['conversation_id'];

    if (instance && replace) {
      this.__setDefaultAttributes(rawMessage, { instance });
    }

    if (includeCounterParty) {
      const counterPartyId = rawMessage['recipient_token'];
      const organizationId = rawMessage['sender_organization'];
      const counterParty = await this.__lookupCounterParty(counterPartyId, { organizationId });

      if (counterParty) {
        rawMessage.counterParty = counterParty;
        if (!rawMessage.conversationHandle) {
          rawMessage.conversationHandle = this.host.conversations.getConversationKey(
            counterParty.$entityType,
            counterParty.serverId,
            organizationId
          );
        }
      } else {
        return null;
      }
    }

    const message = replace
      ? this.host.models.Message.replaceExisting(rawMessage, { instance })
      : this.host.models.Message.inject(rawMessage);
    message.$synced = undefined;

    if (includeStatuses) {
      await this.findRecipientStatus(message, { queue: false });
    }

    return message;
  }

  @reusePromise({
    serializeArguments: (args) => args[0],
  })

  /**
   * Finds a message
   * @param  {string} serverId - server ID of the message
   * @return {Promise.<Message,Error>} a promise with a message
   */
  async find(
    serverId: string,
    { includeCounterParty = true, includeStatuses = true, instance, replace = false } = {}
  ) {
    if (this.config.condensedReplays) {
      const message = await this._findCR(serverId, {
        includeCounterParty,
        includeStatuses,
        instance,
        replace,
      });
      return message;
    }

    this.host.requireUser();

    const attrs = await this.host.api.messages.find(serverId);
    if (!attrs) return null;

    if (instance && replace) {
      this.__setDefaultAttributes(attrs, { instance });
    }

    if (includeCounterParty) {
      const counterPartyId = attrs['recipient_token'];
      const organizationId = attrs['sender_organization'];
      const counterParty = await this.__lookupCounterParty(counterPartyId, { organizationId });

      if (counterParty) {
        attrs.counterParty = counterParty;
        if (!this.config.condensedReplays && !attrs.conversationId) {
          attrs.conversationId = this.host.conversations.getConversationKey(
            counterParty.$entityType,
            counterParty.serverId,
            organizationId
          );
        }
      } else {
        return null;
      }
    }

    const message = replace
      ? this.host.models.Message.replaceExisting(attrs, { instance })
      : this.host.models.Message.inject(attrs);
    message.$synced = undefined;

    if (includeStatuses) {
      await this.findRecipientStatus(message, { queue: false });
    }

    return message;
  }

  __setDefaultAttributes = (attrs, { instance }) => {
    const defaultAttributes = {
      is_forwarded: instance.isForwarded,
      attachments: instance.attachments,
      conversationId: instance.conversationId,
      counterParty: instance.counterParty,
      senderStatus: MessageSenderStatus.SENT,
      shouldEscalate: false,
    };

    if (instance.isForwarded) {
      defaultAttributes.originalForwardedMessageCreatedAt =
        instance.originalForwardedMessageCreatedAt;
      defaultAttributes.originalMessageId = instance.originalMessageId;
      if (instance.originalSender) {
        defaultAttributes.originalSender = instance.originalSender;
      }
    }

    _.defaults(attrs, defaultAttributes);

    return attrs;
  };

  /**
   * Returns a message attachment by ID
   * @param  {string|Message} messageId: The message ID
   * @param  {string} attachmentId: The attachment ID
   * @return {Promise.<MessageAttachment,Error>} a promise with the attachment object]
   */
  async findAttachment(messageId: string | Object, attachmentId: string) {
    let message = this._resolveMessage(messageId);
    let alreadyFetched = false;

    // if the existing attachment doesn't have a 'name' property, it means it needs to be downloaded from the server.
    if (!message) {
      alreadyFetched = true;
      message = await this.find(messageId, { includeStatuses: false, includeCounterParty: false });
    }

    if (!message) return null;
    let attachment = this.__getMessageAttachment(message, attachmentId);
    if (attachment && !attachment.isLocal && !alreadyFetched && !('name' in attachment)) {
      message = await this.find(messageId, { includeStatuses: false, includeCounterParty: false });
      attachment = this.__getMessageAttachment(message, attachmentId);
      if (!attachment.name) {
        // this will prevent any further querying for name
        attachment.name = null;
      }
    }

    return attachment;
  }

  getById(id: string) {
    return this.host.models.Message.get(id);
  }
  getMultiById(ids: Array<string>) {
    return this.host.models.Message.getMulti(ids);
  }

  async __lookupCounterParty(id: string, { organizationId } = {}) {
    const counterPartyTypesAndFinders = [
      { model: this.host.models.User, finder: () => this.host.users.find(id, { organizationId }) },
      { model: this.host.models.Group, finder: () => this.host.groups.find(id) },
      {
        model: this.host.models.DistributionList,
        finder: () => this.host.distributionLists.find(id),
      },
    ];

    // try to find locally first
    for (const { model } of counterPartyTypesAndFinders) {
      const instance = model.get(id);
      if (instance) return instance;
    }

    // if not found, try to find remotely
    for (const { finder } of counterPartyTypesAndFinders) {
      try {
        const instance = await finder();
        if (instance) return instance;
      } catch (e) {
        if (e.code !== errors.NotFoundError.CODE) throw e;
      }
    }

    return null;
  }

  _isMessageOutgoing(message) {
    const { counterPartyType, currentSenderRole, senderId } = message;
    if (!senderId) return false;

    const { currentUser, currentUserId } = this.host;

    return (
      senderId === currentUserId || // Did I send this message?
      currentUser.roles?.includes(currentSenderRole) || // Am I in the role that sent this message?
      (counterPartyType === 'group' &&
        currentUser.roles?.some((role) => role.botUserId === senderId)) // Was this message sent by an empty role that I'm now in (happens for automated role messages NOT sent by owner)
    );
  }

  _setRecipientStatus(message) {
    let {
      __organizationId,
      _markingAsStatus,
      conversation,
      counterParty,
      counterPartyType,
      createdAt,
      currentSenderRole,
      deleteOnRead,
      expiresAt,
      isOutgoing,
      messageType,
      senderId,
      statusesPerRecipient,
      ttl,
    } = message;

    if (this.host.roles._previouslyInRoles[currentSenderRole?.botUserId]) {
      this.host.roles._addRoleMessageToReload(message, currentSenderRole.id);
      return;
    }

    const { currentUser, currentUserId } = this.host;
    const { groupType, p2pRecipient } = counterParty || {};
    const applicableLeastStatuses = [];
    const applicableStatuses = [];
    const myUserIds = [currentUserId];
    let markedRecipientStatus, recipientStatus;

    for (const role of currentUser.roles) {
      if (role.organizationId === __organizationId) {
        myUserIds.push(role.botUserId);
      }
    }

    const excludeStatusOfSender = senderId && myUserIds.includes(senderId);
    const myRecipientIds = [];
    const defaultMarkedRecipientStatus = MessageRecipientStatus.READ;
    let defaultRecipientStatus = MessageRecipientStatus.NEW;
    const selfConversation = groupType === GroupType.ROLE_P2P && p2pRecipient === currentUser;

    for (const statusPerRecipient of statusesPerRecipient) {
      const { userId } = statusPerRecipient;
      const forMe = myUserIds.includes(userId);
      if (forMe) myRecipientIds.push(userId);
    }

    const excludeStatusOfMyUser = myRecipientIds.length > 1;

    for (const statusPerRecipient of statusesPerRecipient) {
      const { distributionListId, userId, status } = statusPerRecipient;
      const forMe = myUserIds.includes(userId);
      const validIncoming = !isOutgoing && forMe;
      const validOutgoing = isOutgoing && (distributionListId || !forMe);

      if (
        !selfConversation &&
        (validIncoming || validOutgoing) &&
        !(excludeStatusOfMyUser && userId === currentUserId)
      ) {
        applicableStatuses.push(status);
      }

      if (selfConversation || (excludeStatusOfSender && myUserIds.includes(senderId))) {
        if (MESSAGE_STATUS_ORDER[defaultRecipientStatus] < MESSAGE_STATUS_ORDER[status]) {
          defaultRecipientStatus = status;
        }
        if (
          MESSAGE_STATUS_ORDER[defaultRecipientStatus] <
          MESSAGE_STATUS_ORDER[MessageRecipientStatus.READ]
        ) {
          defaultRecipientStatus = MessageRecipientStatus.READ;
        }
      }

      if (forMe && !selfConversation && !(excludeStatusOfSender && senderId === userId)) {
        applicableLeastStatuses.push(status);
      }
    }

    if (applicableStatuses.length > 0) {
      recipientStatus = applicableStatuses[0];
      for (let idx = 1; idx < applicableStatuses.length; idx++) {
        const thisStatus = applicableStatuses[idx];
        if (myRecipientIds.length === 0) {
          if (MESSAGE_STATUS_ORDER[recipientStatus] < MESSAGE_STATUS_ORDER[thisStatus]) {
            recipientStatus = thisStatus;
          }
        } else {
          if (MESSAGE_STATUS_ORDER[recipientStatus] > MESSAGE_STATUS_ORDER[thisStatus]) {
            recipientStatus = thisStatus;
          }
        }
      }
    } else {
      recipientStatus = defaultRecipientStatus;
    }

    markedRecipientStatus = defaultMarkedRecipientStatus;

    if (applicableLeastStatuses.length > 0) {
      for (const thisStatus of applicableLeastStatuses) {
        if (MESSAGE_STATUS_ORDER[markedRecipientStatus] > MESSAGE_STATUS_ORDER[thisStatus]) {
          markedRecipientStatus = thisStatus;
        }
      }
    }

    if (this.config.condensedReplays && conversation) {
      if (
        conversation._markingAsRead &&
        conversation._markingAsReadSortNumber >= message.sortNumber
      ) {
        recipientStatus = MessageRecipientStatus.READ;
      }
    }

    if (_markingAsStatus) {
      let causedChange = false;
      if (MESSAGE_STATUS_ORDER[recipientStatus] < MESSAGE_STATUS_ORDER[_markingAsStatus]) {
        recipientStatus = _markingAsStatus;
        causedChange = true;
      }

      if (
        !this.config.condensedReplays &&
        isOutgoing &&
        MESSAGE_STATUS_ORDER[markedRecipientStatus] < MESSAGE_STATUS_ORDER[_markingAsStatus]
      ) {
        markedRecipientStatus = _markingAsStatus;
        causedChange = true;
      }

      if (!causedChange && !this.config.condensedReplays) message._markingAsStatus = undefined;
    }

    if (
      deleteOnRead &&
      counterPartyType === 'user' &&
      recipientStatus === MessageRecipientStatus.READ
    ) {
      const newExpiresAt = Date.now() + DELETE_ON_READ_MS;
      if (!expiresAt || newExpiresAt < expiresAt) {
        expiresAt = newExpiresAt;
      }
    } else if (!expiresAt && ttl !== undefined) {
      expiresAt = createdAt.getTime() + ttl * 60 * 1000;
    } else if (!expiresAt && createdAt) {
      const currentCreatedAt = new Date(createdAt);
      currentCreatedAt.setHours(createdAt.getHours() + this.host.config.expireBangsCleanUpTTL);
      expiresAt = currentCreatedAt.getTime();
    }

    message.expiresAt = expiresAt;

    if (!this.config.condensedReplays) {
      message.isUnread =
        !message.isEphemeral &&
        messageType === MessageType.USER_SENT &&
        !isOutgoing &&
        message.subType !== MessageSubType.CHATBOT_MESSAGE &&
        this.compareRecipientStatus(recipientStatus, MessageRecipientStatus.READ) === -1;
      message.markedRecipientStatus = markedRecipientStatus;

      if (this.config.allowRTU && message.isHistoricalAlert) {
        message.isUnread = false;
      }
    } else {
      message.isUnread = !message.isEphemeral && message.isUnread;
    }
    message.ttl = ttl;

    return message;
  }

  compareRecipientStatus(status1, status2) {
    if (MessageRecipientStatus.isValid(status1)) status1 = MESSAGE_STATUS_ORDER[status1];
    if (MessageRecipientStatus.isValid(status2)) status2 = MESSAGE_STATUS_ORDER[status2];

    if (status1 > status2) return 1;
    if (status1 === status2) return 0;
    if (status1 < status2) return -1;
  }

  /**
   * Download attachment methods
   */
  __getMessageAttachment(messageId: string | Object, attachmentId: string | number) {
    const message = this._resolveMessage(messageId);
    if (!message) return null;

    attachmentId = attachmentId.toString();
    if (message.attachments) {
      const attachment = _.find(message.attachments, { id: attachmentId });
      return attachment;
    }

    return null;
  }

  __getAttachmentPath(messageId: string, attachmentId: string | number) {
    const { version } = this.host.api.messages.getVersion();
    return `/${version}/message/${messageId}/attachment/${attachmentId}`;
  }

  ///////////////// web methods ///////////////

  // @webTargetOnly
  @reusePromise({ memoize: true })
  downloadAttachmentUrl(messageId: string | Object, attachmentId: string | number) {
    return this._downloadAttachmentBlobAndUrl(messageId, attachmentId).then((file) => file.url);
  }

  // @webTargetOnly
  @reusePromise({ memoize: true })
  downloadAttachmentBlobAndUrl(messageId: string | Object, attachmentId: string | number) {
    return this._downloadAttachmentBlobAndUrl(messageId, attachmentId);
  }

  // @webTargetOnly
  downloadAttachmentUrlWithToken(messageId: string | Object, attachmentId: string | number) {
    return this._downloadAttachmentBlobAndUrlWithToken(messageId, attachmentId).then(
      (file) => file.url
    );
  }

  // @webTargetOnly
  @reusePromise()
  async downloadAttachment(
    messageId: string | Object,
    attachmentId: string | number,
    { fileName } = {}
  ) {
    if (process.env.NODE_TARGET === 'web') {
      const message = this._resolveMessage(messageId);
      let blob;
      const attachment = this.__getMessageAttachment(messageId, attachmentId);
      if (attachment && attachment.isLocal) blob = attachment.localFile;
      else blob = (await this._downloadAttachmentBlobAndUrl(message.serverId, attachmentId)).blob;

      fileName = fileName || attachment.name;

      if (!fileName) {
        fileName = 'Attachment';

        if (!message.sender.$placeholder) fileName += ` from ${message.sender.displayName}`;
        fileName += ` at ${moment(message.createdAt).format('YYYY-MM-DD h-mma')}`;

        const mimeType = await getMimeType({ mime: attachment.contentType });
        if (mimeType) fileName += mimeType.ext;
      }

      await downloadFile({ attachmentContentType: attachment.contentType, blob, fileName });
    } else {
      errors.TargetNotSupportedError.raise();
    }
  }

  // @webTargetOnly
  @reusePromise({ memoize: true })

  /**
   * Retrieves blob and URL of an attachment
   * If attachment is local, gets the blob/url from it
   * Otherwise, downloads from server
   * @param  {string|Object} messageId - Message ID
   * @param  {string|number} attachmentId - Attachment ID
   * @return {Object} an object with { blob, url } properties
   */
  async _downloadAttachmentBlobAndUrl(messageId: string | Object, attachmentId: string | number) {
    if (process.env.NODE_TARGET === 'web') {
      attachmentId = attachmentId.toString();
      const message = this._resolveMessage(messageId);
      const attachment = await this.findAttachment(messageId, attachmentId);
      if (!attachment)
        throw new errors.NotFoundError('MessageAttachment', message.serverId + ':' + attachmentId);

      if (attachment.isLocal && attachment.localFile instanceof Blob) {
        await attachment.localPathPromise;
        return { blob: attachment.localFile, url: URL.createObjectURL(attachment.localFile) };
      } else {
        const attachmentUrl = this.__getAttachmentPath(message.serverId, attachmentId);
        return this.httpClient.downloadFileBlobAndUrl(attachmentUrl);
      }
    } else {
      errors.TargetNotSupportedError.raise();
    }
  }

  async _downloadAttachmentBlobAndUrlWithToken(
    messageId: string | Object,
    attachmentId: string | number
  ) {
    if (process.env.NODE_TARGET === 'web') {
      attachmentId = attachmentId.toString();
      const message = this._resolveMessage(messageId);
      const attachment = await this.findAttachment(messageId, attachmentId);
      if (!attachment)
        throw new errors.NotFoundError('MessageAttachment', message.serverId + ':' + attachmentId);

      if (attachment.isLocal && attachment.localFile instanceof Blob) {
        const url = await attachment.localPathPromise;
        return { blob: attachment.localFile, url };
      } else {
        const attachmentUrl = await this.getAttachmentUrlWithToken(message.serverId, attachmentId);
        return this.httpClient.downloadFileBlobAndUrl(attachmentUrl);
      }
    } else {
      errors.TargetNotSupportedError.raise();
    }
  }

  ///////////////// node methods ///////////////

  // @nodeTargetOnly
  async downloadAttachmentToFile(
    messageId: string | Object,
    attachmentId: string | number,
    dest: string
  ) {
    if (process.env.NODE_TARGET === 'node') {
      attachmentId = attachmentId.toString();
      const message = this._resolveMessage(messageId);
      const attachment = await this.findAttachment(messageId, attachmentId);
      if (!attachment) {
        throw new errors.NotFoundError('MessageAttachment', message.serverId + ':' + attachmentId);
      }

      // when dest ends with / or \ it's just a pointer to a folder. add the file name if exists
      if (/(\/|\\)$/.test(dest)) {
        if (attachment.name) {
          dest += attachment.name;
        } else {
          throw new Error('missing file name in downloadAttachmentToFile(..., dest)');
        }
      }

      const attachmentPath = this.__getAttachmentPath(message.serverId, attachmentId);
      return this.httpClient.downloadToFile(attachmentPath, dest);
    } else {
      errors.TargetNotSupportedError.raise();
    }
  }

  getMessageCount() {
    return this.host.models.Message.getAll().length;
  }

  getTotalUnreadCount() {
    const product = this.host.models.Product.getAll()[0];
    return product ? product.unreadCount : 0;
  }

  getTotalUnreadPriorityCount() {
    const product = this.host.models.Product.getAll()[0];
    return product ? product.unreadPriorityCount : 0;
  }

  ///////////////////
  /// message status
  ///////////////////

  _shouldQueueMessageStatuses() {
    return (
      this._pendingSetMessagesStatusConnections >= this.config.flushSetMessageStatusMaxConnections
    );
  }

  async markAsRead(
    ids: string | Object | Array<string | Object>,
    {
      localOnly = false,
      queue = this.host.currentlyServingOfflineMessages || this._shouldQueueMessageStatuses(),
    } = {}
  ) {
    ids = arrayWrapBound.call(ids).map(this._resolveModelId);

    const uniqueIds = _uniq.call(ids);

    if (uniqueIds.length === 0) return;

    if (!this.config.condensedReplays && localOnly) {
      const readMessages = this.host.models.Message.getMulti(uniqueIds);
      for (const message of readMessages) {
        if (message) {
          message._markingAsStatus = MessageRecipientStatus.READ;
          this.host.models.Message.inject(message);
        }
      }
    } else if (queue) {
      return this.__queueSetMessageStatuses({ readIds: uniqueIds });
    } else {
      return this.__setMessagesStatus({ readIds: uniqueIds });
    }
  }

  async markAsDelivered(ids: string | Object | Array<string | Object>, options = {}) {
    options = _.defaults(options, {
      queue: this.host.currentlyServingOfflineMessages || this._shouldQueueMessageStatuses(),
    });

    ids = arrayWrapBound.call(ids).map(this._resolveModelId);
    const uniqueIds = _uniq.call(ids);

    if (uniqueIds.length === 0) return;

    if (options.queue) {
      return this.__queueSetMessageStatuses({ deliveredIds: uniqueIds });
    } else {
      return this.__setMessagesStatus({ deliveredIds: uniqueIds });
    }
  }

  async markAsReceived(...args) {
    return this.markAsDelivered(...args);
  }

  async __queueSetMessageStatuses({
    deliveredIds,
    readIds,
  }: {
    deliveredIds?: Array<string> | null | undefined;
    readIds?: Array<string> | null | undefined;
  }) {
    const statuses = this._queuedSetMessageStatuses;
    if (deliveredIds) statuses.deliveredIds = _.union(statuses.deliveredIds, deliveredIds);
    if (readIds) statuses.readIds = _.union(statuses.readIds, readIds);

    const totalCount = statuses.deliveredIds.length + statuses.readIds.length;

    if (totalCount >= this.config.flushSetMessageStatusMaxCount) {
      this.logger.log(`reached ${this.config.flushSetMessageStatusMaxCount} -- flushing`);
      return this.__flushSetMessageStatusesQueue();
    } else {
      this._throttled_flushSetMessageStatusesQueue();
    }
  }

  __flushSetMessageStatusesQueue = () => {
    if (this._shouldQueueMessageStatuses()) {
      this._throttled_flushSetMessageStatusesQueue();
      return;
    }

    const totalCount =
      this._queuedSetMessageStatuses.deliveredIds.length +
      this._queuedSetMessageStatuses.readIds.length;

    if (totalCount > 0) {
      const toSet = {
        deliveredIds: this._queuedSetMessageStatuses.deliveredIds.splice(
          0,
          Math.floor(this.config.flushSetMessageStatusMaxCount / 2)
        ),
        readIds: this._queuedSetMessageStatuses.readIds.splice(
          0,
          Math.floor(this.config.flushSetMessageStatusMaxCount / 2)
        ),
      };

      this.__setMessagesStatus(toSet);
    }
  };

  async __setMessagesStatus({ deliveredIds = [], readIds = [] }) {
    this.host.requireUser();

    deliveredIds = deliveredIds.map(this._resolveModelId);
    readIds = readIds.map(this._resolveModelId);

    let uniqueDeliveredIds = _uniq.call(deliveredIds);
    let uniqueReadIds = _uniq.call(readIds);

    if (this.config.condensedReplays) {
      uniqueDeliveredIds = _.difference(uniqueDeliveredIds, uniqueReadIds);
    } else {
      const alreadyDeliveredMessages = this.host.models.Message.getMulti(uniqueDeliveredIds).filter(
        (msg) =>
          msg &&
          MESSAGE_STATUS_ORDER[msg.markedRecipientStatus] >=
            MESSAGE_STATUS_ORDER[MessageRecipientStatus.DELIVERED]
      );
      const alreadyDeliveredIds = alreadyDeliveredMessages.map(({ id }) => id);
      const alreadyReadMessages = this.host.models.Message.getMulti(uniqueReadIds).filter(
        (msg) =>
          msg &&
          MESSAGE_STATUS_ORDER[msg.markedRecipientStatus] >=
            MESSAGE_STATUS_ORDER[MessageRecipientStatus.READ]
      );
      const alreadyReadIds = alreadyReadMessages.map(({ id }) => id);
      uniqueReadIds = _.difference(uniqueReadIds, alreadyReadIds);
      uniqueDeliveredIds = _.difference(uniqueDeliveredIds, uniqueReadIds, alreadyDeliveredIds);
    }

    const changes = {};

    if (uniqueDeliveredIds.length > 0) {
      changes['delivered'] = uniqueDeliveredIds;
    }

    if (uniqueReadIds.length > 0) {
      changes['read'] = uniqueReadIds;

      if (this.config.enableMessageStatusSync) {
        const sampleRate = this.config.messageStatusSyncSampleRate || 0;
        const shouldSample = Math.random() >= 1 - sampleRate;
        if (!shouldSample) return;

        const data = await this.getUnreadMessages(this.host.currentUserId);
        const remoteIds = data.message_token_list.map((message) => message.message_id);
        const correctStatus = uniqueReadIds.every((id) => remoteIds.includes(id));

        this.emit('messageStatusSync', correctStatus);
      }

      const readMessages = this.host.models.Message.getMulti(uniqueReadIds);
      for (const message of readMessages) {
        if (message) {
          message._markingAsStatus = MessageRecipientStatus.READ;
          this.host.models.Message.inject(message);
        }
      }
    }

    if (Object.keys(changes).length === 0) {
      return false;
    }

    this._pendingSetMessagesStatusConnections++;

    try {
      const conversations = await this.host.api.messages.updateRecipientStatusMulti(changes);
      if (this.config.condensedReplays) {
        for (const [conversationId, properties] of Object.entries(conversations)) {
          const {
            unread_message_count: unreadMessageCount,
            unread_priority_message_count: unreadPriorityMessageCount,
          } = properties;

          const conversation = this.host.models.Conversation.get(conversationId);
          if (conversation) {
            conversation.unreadMessageCount = unreadMessageCount;
            conversation.unreadPriorityMessageCount = unreadPriorityMessageCount;

            this.host.models.Conversation.inject(conversation);
          }
        }
      }

      this._pendingSetMessagesStatusConnections--;
    } catch (err) {
      const readMessages = this.host.models.Message.getMulti(uniqueReadIds);
      for (const message of readMessages) {
        if (message && message._markingAsStatus) {
          message._markingAsStatus = undefined;
          this.host.models.Message.inject(message);
        }
      }

      this._pendingSetMessagesStatusConnections--;

      return Promise.reject(err);
    }

    return true;
  }

  setMessageTTLInMinutes(id: string | Object, ttl: number) {
    const message = this._resolveMessage(id);
    if (!message) return;

    const expiresAt = moment().add(ttl, 'minute').toDate().getTime();
    this.host.models.Message.inject({ id: message.id, ttl, expiresAt });
  }

  _onTick = () => {
    this._deleteExpiredMessages();
    this._deleteExpiredMutes();
    if (this.config.condensedReplays) {
      this._clearOptimisticMarkAsReadState();
    }
  };

  _clearOptimisticMarkAsReadState(since = Date.now()) {
    if (since.getTime) since = since.getTime();

    for (const organization of this.host.organizations.getAll()) {
      const conversations = organization.__markingAsReadConversations;
      let done = false;

      while (!done && conversations.length > 0) {
        const head = conversations[0];
        const conversation = this.host.conversations.getById(head.id);
        if (!conversation) return;

        if (conversation._markingAsReadExpiration < since) {
          conversation._markingAsRead = false;
          conversation._markingAsReadSortNumber = null;
          conversation._markingAsReadExpiration = null;

          this.host.models.Conversation.inject(conversation);

          this.host.conversations.find(conversation.id, { ensureEntities: false });
        } else {
          done = true;
        }
      }
    }
  }

  _deleteExpiredMessages = (since = Date.now()) => {
    if (since.getTime) since = since.getTime();

    for (const organization of this.host.organizations.getAll()) {
      const messages = organization.__expirableMessages;
      let done = false;

      while (!done && messages.length > 0) {
        const head = messages[0];

        if (head.expiresAt < since) {
          this.host.models.Message.eject(head);
        } else {
          done = true;
        }
      }
    }
  };

  _deleteExpiredMutes = (since = Date.now()) => {
    if (since.getTime) since = since.getTime();

    for (const organization of this.host.organizations.getAll()) {
      const mutes = organization.__expirableMutes;
      let done = false;

      while (!done && mutes.length > 0) {
        const head = mutes[0];
        if (head.expiresAt < since) {
          this.host.models.Mute.eject(head);
          const conversation = this.host.models.Conversation.get(head.conversationId);
          if (!conversation) continue;

          conversation.muted = null;
          this.host.models.Conversation.inject(conversation);
        } else {
          done = true;
        }
      }
    }
  };

  async ensureRecipientStatus(
    messageId: string | Object,
    { includeUsers = true, queue = true } = {}
  ) {
    this.host.requireUser();
    messageId = this._resolveModelId(messageId);
    const message = this._resolveMessage(messageId);
    if (!message) return null;

    const { counterPartyType, senderStatus, shouldEnsureRecipientStatus, statusesPerRecipient } =
      message;
    if (senderStatus === MessageSenderStatus.SENDING) return null;
    if (counterPartyType === 'distributionList') return null;
    if (!shouldEnsureRecipientStatus && statusesPerRecipient.length > 0) {
      let shouldFetch = false;

      if (includeUsers) {
        for (const { user } of statusesPerRecipient) {
          if (!user) shouldFetch = true;
        }
      }

      if (!shouldFetch) {
        return statusesPerRecipient;
      }
    }

    let status;
    if (queue) {
      const statuses = await this.__queueFindRecipientStatuses([{ id: messageId, includeUsers }]);
      status = statuses[0];
    } else {
      status = await this.__findRecipientStatus(messageId, { includeUsers });
    }

    return status;
  }

  @reusePromise({
    serializeArguments: (args) => (typeof args[0] === 'object' ? args[0].id : args[0]),
  })
  // id can be local id or server id
  async findRecipientStatus(
    id: string | Object,
    { includeUsers = false, queue = true, newMessage = false } = {}
  ) {
    this.host.requireUser();
    const message = this._resolveMessage(id);
    if (!message) return null;
    if (message.senderStatus === MessageSenderStatus.SENDING) return null;
    if (message.counterPartyType === 'distributionList') return null;
    if (message.isGroupReplayStatusesFetched === true) return null;
    id = this._resolveModelId(id);

    if (!message.isGroupReplay && newMessage) {
      const statuses = [];
      const roleMap = this.host.currentUser.roles.reduce((acc, { botUserId }) => {
        if (botUserId) {
          acc[botUserId] = true;
        }
        return acc;
      }, {});
      for (const memberId of message.group.memberIds) {
        if (memberId === this.host.currentUserId || roleMap[memberId]) continue;

        statuses.push(
          this.__injectMessageStatus(message, {
            accountId: memberId,
            createdAt: message.createdAt,
            status: MessageRecipientStatus.NEW,
          })
        );
      }
      return statuses[0];
    }

    let status;
    if (queue) {
      const statuses = await this.__queueFindRecipientStatuses([{ id, includeUsers }]);
      status = statuses[0];
    } else {
      status = await this.__findRecipientStatus(id, { includeUsers });
    }

    if (message.isGroupReplay) {
      message.isGroupReplayStatusesFetched = true;
    }

    return status;
  }

  async ensureRecipientStatusMulti(
    ids: Array<string | Object>,
    { includeUsers = true, queue = true } = {}
  ) {
    ids = arrayWrapBound.call(ids).map(this._resolveModelId);

    const uniqueIds = _uniq.call(ids);

    const requests = [];
    const responseById = {};
    for (const id of uniqueIds) {
      const request = { id, includeUsers };
      const message = this._resolveMessage(id);
      let shouldFetch = true;

      if (message) {
        const { shouldEnsureRecipientStatus, statusesPerRecipient } = message;

        if (!shouldEnsureRecipientStatus && statusesPerRecipient.length > 0) {
          shouldFetch = false;

          if (includeUsers) {
            for (const { user } of statusesPerRecipient) {
              if (!user) shouldFetch = true;
            }
          }
        }
      }

      if (shouldFetch) {
        requests.push(request);
      } else {
        responseById[request.id] = message.statusesPerRecipient;
      }
    }

    if (requests.length === 0) return;

    let results;
    if (queue) {
      results = await this.__queueFindRecipientStatuses(requests);
    } else {
      results = await this.__findRecipientStatusMulti(requests);
    }

    if (results) {
      for (let idx = 0; idx < requests.length; idx++) {
        if (results.length > idx) {
          const request = requests[idx];
          const result = results[idx];
          responseById[request.id] = result;
        }
      }
    }

    const toReturn = [];
    for (const id of uniqueIds) {
      toReturn.push(responseById[id]);
    }

    return toReturn;
  }

  @reusePromise({
    serializeArguments: (args) =>
      args[0].map((arg) => (typeof arg === 'object' ? arg.id : arg)).join(':'),
  })
  async findRecipientStatusMulti(
    ids: Array<string | Object>,
    { includeUsers = false, queue = false } = {}
  ) {
    ids = arrayWrapBound.call(ids).map(this._resolveModelId);
    const uniqueIds = _uniq.call(ids);

    const requests = [];
    for (const id of uniqueIds) {
      const request = { id, includeUsers };
      requests.push(request);
    }

    if (requests.length === 0) return;

    if (queue) {
      return this.__queueFindRecipientStatuses(requests);
    } else {
      return this.__findRecipientStatusMulti(requests);
    }
  }

  async __queueFindRecipientStatuses(statusRequests: Array<string | Object>) {
    const promises = [];

    for (const { id, includeUsers } of statusRequests) {
      let resolve, reject;
      let shouldAdd = true;

      promises.push(
        new Promise((inResolve, inReject) => {
          resolve = inResolve;
          reject = inReject;
        })
      );

      for (const queuedRequest of this._queuedFindRecipientStatuses) {
        if (id === queuedRequest.id) {
          queuedRequest.includeUsers = queuedRequest.includeUsers || includeUsers;
          queuedRequest.onCompletes.push(resolve);
          queuedRequest.onErrors.push(reject);
          shouldAdd = false;
          break;
        }
      }

      if (shouldAdd) {
        this._queuedFindRecipientStatuses.push({
          id,
          includeUsers,
          onCompletes: [resolve],
          onErrors: [reject],
        });
      }
    }

    if (this._queuedFindRecipientStatuses.length >= this.config.flushFindRecipientStatusMaxCount) {
      this.logger.log(`reached ${this.config.flushFindRecipientStatusMaxCount} -- flushing`);
      this.__flushFindRecipientStatusesQueue();
    } else if (this._queuedFindRecipientStatuses.length > 0) {
      this._throttled_flushFindRecipientStatusesQueue();
    }

    const allStatuses = await Promise.all(promises);
    return allStatuses;
  }

  async __flushFindRecipientStatusesQueue() {
    if (this._queuedFindRecipientStatuses.length === 0) return;

    const toFind = this._queuedFindRecipientStatuses;
    this._queuedFindRecipientStatuses = [];

    let err, results;

    try {
      results = await this.__findRecipientStatusMulti(toFind);
    } catch (inErr) {
      err = inErr;
    } finally {
      for (let idx = 0; idx < toFind.length; idx++) {
        let result;
        if (results && results.length > idx) {
          result = results[idx];
        }

        const { onCompletes, onErrors } = toFind[idx];
        if (err) {
          for (const onError of onErrors) {
            setTimeout(() => onError(err), 0);
          }
        } else {
          for (const onComplete of onCompletes) {
            setTimeout(() => onComplete(result), 0);
          }
        }
      }
    }
  }

  async __findRecipientStatus(id, { includeUsers }) {
    this.host.requireUser();
    const message = this._resolveMessage(id);
    let shouldInject = false;

    if (this._pendingFindRecipientStatuses[message.id]) return null;

    let data;
    try {
      const cancelPromise = new Promise((resolve, reject) => {
        this._pendingFindRecipientStatuses[message.id] = () => resolve(null);
      });
      data = await Promise.race([
        cancelPromise,
        this.host.api.messages.findRecipientStatus(message.serverId),
      ]);
    } finally {
      delete this._pendingFindRecipientStatuses[message.id];
    }

    if (!data) return null;

    if (data['is_recalled']) {
      this.host.models.Message.eject(message);
      return null;
    }

    for (const statusAttrs of data['statuses']) {
      this.__injectMessageStatus(message, statusAttrs);
    }

    if (includeUsers) {
      const { recipientOrganizationId: organizationId, statusesPerRecipient } = message;
      const userIds = [];

      for (const { userId } of statusesPerRecipient) {
        if (userId) userIds.push(userId);
      }

      await this.host.users.ensureUsers(userIds, organizationId);

      shouldInject = true;
    }

    if (message.shouldEnsureRecipientStatus) {
      message.shouldEnsureRecipientStatus = false;
      shouldInject = true;
    }

    if (shouldInject) {
      this.host.models.Message.inject(message);
    }

    const isNotDelivered =
      !this.config.condensedReplays &&
      MESSAGE_STATUS_ORDER[message.markedRecipientStatus] <
        MESSAGE_STATUS_ORDER[MessageRecipientStatus.DELIVERED];
    if (isNotDelivered) {
      this.markAsReceived(message);
    }

    return message.statusesPerRecipient;
  }

  async __findRecipientStatusMulti(requests: Array<Object>) {
    this.host.requireUser();
    const statusesToLookup = {};
    const inRequests = requests;
    requests = [];

    for (const { id, includeUsers } of inRequests) {
      const message = this._resolveMessage(id);
      const { counterParty, serverId } = message || {};
      const request = { id, includeUsers, message };
      requests.push(request);

      if (!serverId) continue;
      if (this._pendingFindRecipientStatuses[serverId]) continue;
      if (counterParty && counterParty.$entityType === 'distributionList') {
        continue;
      }

      request.id = serverId;
      statusesToLookup[serverId] = true;
    }

    // server allows maximum of 100 items per request, but using only 20 reduces server load
    const idGroupChunks = _.chunk(Object.keys(statusesToLookup), 20);

    const changedMessages = {};
    const recalledMessages = {};
    for (const idsInGroup of idGroupChunks) {
      let results;
      try {
        const cancelledMessages = {};
        for (const messageId of idsInGroup) {
          this._pendingFindRecipientStatuses[messageId] = () => {
            cancelledMessages[messageId] = true;
          };
        }

        const rawResults = await this.host.api.messages.findRecipientStatusMulti(idsInGroup);

        results = [];
        for (const result of rawResults) {
          const { client_id: messageId } = result;
          if (!cancelledMessages[messageId]) {
            results.push(result);
          }
        }
      } finally {
        for (const messageId of idsInGroup) {
          delete this._pendingFindRecipientStatuses[messageId];
        }
      }

      if (!results) return [];

      for (const result of results) {
        const id = result['client_id'];
        const statuses = result['statuses'];
        const message = this.host.models.Message.get(id);

        if (result['is_recalled']) {
          this.host.models.Message.eject(message);
          recalledMessages[id] = true;
          continue;
        }

        if (statuses && statuses.length > 0) {
          for (const statusAttrs of statuses) {
            this.__injectMessageStatus(message, statusAttrs);
          }
          changedMessages[id] = message;
        }
      }
    }

    for (const { includeUsers, message } of requests) {
      let shouldInject = false;
      if (!message || recalledMessages[message.serverId]) continue;

      const {
        recipientOrganizationId: organizationId,
        shouldEnsureRecipientStatus,
        statusesPerRecipient,
      } = message;

      if (includeUsers) {
        const userIds = [];
        for (const { userId } of statusesPerRecipient) {
          if (userId) userIds.push(userId);
        }

        await this.host.users.ensureUsers(userIds, organizationId);

        shouldInject = true;
      }

      if (shouldEnsureRecipientStatus) {
        message.shouldEnsureRecipientStatus = false;
        shouldInject = true;
      }

      if (shouldInject) {
        this.host.models.Message.inject(message);
      }
    }

    if (!this.config.condensedReplays) {
      for (const message of Object.values(changedMessages)) {
        if (
          message &&
          MESSAGE_STATUS_ORDER[message.markedRecipientStatus] <
            MESSAGE_STATUS_ORDER[MessageRecipientStatus.DELIVERED]
        ) {
          this.markAsReceived(message);
        }
      }
    }

    const toReturn = [];
    for (const { id } of requests) {
      const changedMessage = changedMessages[id];
      toReturn.push(changedMessage ? changedMessage.statusesPerRecipient : null);
    }

    return toReturn;
  }

  _onChangeMessageStatus = (resource, messageStatus) => {
    const { messageId } = messageStatus;
    const message = this.host.models.Message.get(messageId);
    if (!message) return;

    this.host.models.Message.inject(message);
  };

  _fetchAttachmentTokens = (count = 20) => {
    if (!this._fetchingAttachmentTokens) {
      this._fetchingAttachmentTokens = new Promise(async (resolve) => {
        try {
          const { attachment_tokens: attachmentTokens } =
            await this.host.api.messages.createAttachmentTokens(count);
          this._attachmentTokens.push(...attachmentTokens);
        } catch (error) {
          this.logger.error('error while requesting attachment tokens', error);
        }

        this._fetchingAttachmentTokens = null;
        resolve();
      });
    }

    return this._fetchingAttachmentTokens;
  };

  _useAttachmentToken = async (count = 20, retries = 0) => {
    if (retries > 3) throw new Error('Attachment tokens can not be fetched at this time.');

    if (this._attachmentTokens.length < count) await this._fetchAttachmentTokens(count);

    const token = this._attachmentTokens.shift();
    if (!token) return await this._useAttachmentToken(count, ++retries);

    return token;
  };

  getAttachmentUrlWithToken = async (
    messageId: string,
    attachmentId: string,
    includeBaseUrl = false
  ): string => {
    const message = this._resolveMessage(messageId);
    const attachment = await this.findAttachment(message.serverId, attachmentId);

    const token = await this._useAttachmentToken();

    return `${includeBaseUrl ? this.host.config.baseUrl : ''}${this.__getAttachmentPath(
      message.serverId,
      attachment.id
    )}?token=${token}`;
  };

  openAttachmentWithToken = async (messageId: string, attachmentId: string | number) => {
    const attachmentIdStr = attachmentId.toString();
    const message = this._resolveMessage(messageId);
    const attachment = await this.findAttachment(messageId, attachmentIdStr);
    if (!attachment)
      throw new errors.NotFoundError('MessageAttachment', message.serverId + ':' + attachmentIdStr);

    const url = await this.getAttachmentUrlWithToken(message.serverId, attachmentIdStr);
    window.open(this.host.config.baseUrl + url, '_blank');
  };

  _handleAlertPayload(entity, payload) {
    if (!entity || !payload) return;

    entity.alertAttrs = payload;

    if (!entity.alertDetails) {
      entity.alertDetails = {};
    }

    if (payload.alert_recipients) {
      const recipientIds = payload.alert_recipients;

      for (const recipientId of recipientIds) {
        const messageStatusPerRecipientId = `${entity.id}:${recipientId}`;
        if (!this.host.models.MessageStatusPerRecipient.get(messageStatusPerRecipientId)) {
          this.host.models.User.ensureEntity(recipientId, { onlyPlaceholder: true });

          this.host.models.MessageStatusPerRecipient.injectPlaceholder({
            id: messageStatusPerRecipientId,
            messageId: entity.id,
            userId: recipientId,
          });
        }
      }

      entity.alertRecipientIds = recipientIds;
      this.host.users._setMembersOnEntity({
        entity,
        fromField: 'alertRecipientIds',
        placeholder: true,
        toField: 'alertRecipients',
      });
    }

    if (payload.components) {
      const alertCardItems = [];

      for (const item of payload.components) {
        if (item.type === 'completed_item') {
          entity.isHistoricalAlert = true;
        }

        alertCardItems.push(item);
      }

      entity.alertCard = alertCardItems;
    }

    if (payload.alert_group_id) {
      const alertConversationGroupId = payload.alert_group_id;
      entity.alertDetails.conversationGroupId = alertConversationGroupId;
      entity.alertConversationGroupId = alertConversationGroupId;
    }

    if (payload.notification) {
      entity.alertNotification = payload.notification;
    }

    if (payload.primary_action) {
      entity.alertDetails.primaryAction = payload.primary_action;
    }

    if (payload.reactions) {
      for (const {
        account_token: accountId,
        reaction,
        timestamp: reactionTimestamp,
      } of payload.reactions) {
        this.__injectMessageReaction(entity, {
          accountId,
          reaction,
          reactionTimestamp,
        });
      }
    }

    if (payload.statuses) {
      for (const status of payload.statuses) {
        this.__injectMessageStatus(entity, status);
      }
    }
  }

  _setCurrentRecipientAlertAction(entity, payload) {
    if (payload?.reactions && !entity?.currentRecipientAlertAction) {
      const roleIds = this.host.currentUser.roles.reduce((map, { botUserId }) => {
        map[botUserId] = true;
        return map;
      }, {});
      for (const { account_token: accountId } of payload.reactions) {
        if (accountId === this.host.currentUserId || roleIds[accountId]) {
          const uniqueId = `${entity.id}:${accountId}`;
          const messageReaction = this.host.models.MessageStatusPerRecipient.get(uniqueId);
          entity.currentRecipientAlertAction = messageReaction;
          return;
        }
      }
    }
  }

  _setAlertIncludesCurrentUserOrRole = (message) => {
    if (!message.alertDetails) {
      message.alertDetails = {};
    }

    if (message.subType === MessageSubType.ROLE_ALERTS) {
      message.alertDetails.includesCurrentUserOrRole = true;
      return;
    }

    if (message.subType === MessageSubType.ALERTS) {
      message.alertDetails.includesCurrentUserOrRole = false;

      if (message.featureService !== FeatureService.GROUP_ALERTS) {
        message.alertDetails.includesCurrentUserOrRole = true;
        return;
      }

      if (message?.alertRecipientIds) {
        for (const id of message.alertRecipientIds) {
          if (id === this.host.currentUserId) {
            message.alertDetails.includesCurrentUserOrRole = true;
            return;
          } else {
            for (const { botUserId: roleId } of this.host.currentUser.roles) {
              if (roleId === id) {
                message.alertDetails.includesCurrentUserOrRole = true;
                return;
              }
            }
          }
        }
      }
    }
  };

  reactToReactionUpdate({ data } = {}) {
    const { reaction } = data;
    if (!reaction) return;
    const {
      emoji_unicode: emojiUnicode,
      message_id: messageId,
      reaction_type: reactionType,
      user_id: userId,
    }: {
      emoji_unicode?: string;
      message_id?: string;
      reaction_type?: 'ADDED' | 'REMOVED';
      user_id?: string;
    } = reaction;
    if (!emojiUnicode || !messageId || !reactionType || !userId) return;

    const message = this.host.models.Message.get(messageId);
    if (!message) return;

    if (reactionType === 'ADDED') {
      if (!message.reactions) {
        message.reactions = [{ emojiUnicode, userIds: [userId] }];
      } else {
        let reactionEntry = message.reactions.find((r) => r.emojiUnicode === emojiUnicode);
        if (!reactionEntry) {
          message.reactions.push({ emojiUnicode, userIds: [] });
          reactionEntry = message.reactions[message.reactions.length - 1];
        }
        if (
          userId === this.host.currentUserId &&
          reactionEntry.userIds.includes(this.host.currentUserId)
        ) {
          return;
        }
        reactionEntry.userIds.push(userId);
      }
    } else if (reactionType === 'REMOVED') {
      if (!message.reactions) return;

      const reactionEntry = message.reactions.find((r) => r.emojiUnicode === emojiUnicode);
      if (!reactionEntry) return;

      reactionEntry.userIds = reactionEntry.userIds.filter((uId) => uId !== userId);

      message.reactions = message.reactions.filter((r) => r.userIds.length > 0);
    }

    this.host.models.Message.inject(message);
  }

  addReaction = async ({
    messageId,
    reaction,
    userId,
  }: {
    messageId: string;
    reaction: string;
    userId: string;
  }) => {
    const message = this.host.models.Message.get(messageId);
    let reactionEntry = message?.reactions?.find((r) => r?.emojiUnicode === reaction);

    if (!message || reactionEntry?.userIds.includes(userId)) return;

    const previousReactions = [...message.reactions];

    if (!message.reactions) {
      message.reactions = [{ reaction, userIds: [userId] }];
    } else {
      if (!reactionEntry) {
        message.reactions.push({ emojiUnicode: reaction, userIds: [] });
        reactionEntry = message.reactions[message.reactions.length - 1];
      }

      reactionEntry.userIds.push(userId);
    }

    this.host.models.Message.inject(message);

    try {
      const { counterPartyId, counterPartyType } = message;

      await this.host.api.messages.addReaction({
        messageId: message.serverId,
        messageExpiresAt: message.expiresAt,
        reaction,
        orgId: message.recipientOrganizationId,
        counterPartyId,
        counterPartyType: this.mapCounterPartyType(counterPartyType),
      });
    } catch (e) {
      message.reactions = previousReactions;
      this.host.models.Message.inject(message);
      console.error(`Unable to addReaction: ${e}`);
    }

    return message;
  };

  mapCounterPartyType = (counterPartyType: 'user' | 'group') => {
    return counterPartyType === 'user' ? 'p2p' : counterPartyType;
  };

  getReactions = async (messageIds: string[]) => {
    try {
      const message = this.host.models.Message.get(messageIds[0]);
      const { counterPartyId, counterPartyType, recipientOrganizationId } = message;
      if (this._currentConversationMessageIds.length === 0) {
        this._currentConversationMessageIds = messageIds;
      } else {
        this._currentConversationMessageIds = messageIds.filter(
          (messageId) => !this._currentConversationMessageIds.includes(messageId)
        );
      }

      if (this._currentConversationMessageIds.length === 0) return;

      const { messages: rawMessages }: ReactionsServerReturnType =
        await this.host.api.messages.getReactions({
          messageIds: this._currentConversationMessageIds,
          counterPartyId,
          counterPartyType: this.mapCounterPartyType(counterPartyType),
          orgId: recipientOrganizationId,
          sortReactionsByCountAndCreatedAt: true,
        });
      if (!rawMessages) return;

      const messages = [];
      for (const messageKey in rawMessages) {
        if (!rawMessages.hasOwnProperty(messageKey)) continue;

        const rawMessage = rawMessages[messageKey];

        const message = this.host.models.Message.get(messageKey);
        if (!message) continue;

        message.reactions = Object.entries(rawMessage).map(([emojiUnicode, data]) => ({
          emojiUnicode,
          userIds: data[0]['userId'],
        }));
        messages.push(this.host.models.Message.inject(message));
      }
      return messages;
    } catch (e) {
      console.error(`Unable to getReactions: ${e}`);
    }
  };

  removeReaction = async ({
    messageId,
    userId,
    reaction,
  }: {
    messageId: string;
    userId: string;
    reaction: string;
  }) => {
    const message = this.host.models.Message.get(messageId);
    if (!message) return;

    const previousReactions = [...message.reactions];

    message.reactions = message.reactions
      .map(({ emojiUnicode, userIds, ...rest }) => {
        const newUsersForReactionOmittingCurrentUser = userIds.filter((u) => u !== userId);
        if (emojiUnicode === reaction) {
          return { ...rest, emojiUnicode, userIds: newUsersForReactionOmittingCurrentUser };
        }

        return { emojiUnicode, userIds, ...rest };
      })
      .filter(({ userIds }) => userIds.length > 0);

    this.host.models.Message.inject(message);

    try {
      const { counterPartyId, counterPartyType } = message;
      await this.host.api.messages.removeReaction({
        counterPartyId,
        counterPartyType: this.mapCounterPartyType(counterPartyType),
        messageId: message.serverId,
        reaction,
        orgId: message.recipientOrganizationId,
      });
    } catch (e) {
      message.reactions = previousReactions;
      this.host.models.Message.inject(message);
      console.error(`Unable to removeReaction: ${e}`);
    }

    return message;
  };

  _resetConversationsThatHaveBeenPopulatedWithReactions = ({ networkStatus }) => {
    if (networkStatus === NetworkStatus.ONLINE) {
      this._conversationsThatHaveBeenPopulatedWithReactions = {};
    }
  };

  async getUnreadMessages(token: string) {
    const data = await this.host.api.messages.getUnreadMessages(token);
    return data;
  }
}
