import { Camelizer } from '../utils';
import { Unit, Units, OrgResponse, Resource, UnassignedRoomsResponse } from '../types/MultiOrg';
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

const MULTI_ORG_BASE_URL = '/v2/directory-service/topologies/';

type Locations = OrgResponse['locations'];

export default class MultiOrgAPI extends BaseAPI {
  buildURL = (...paths: string[]) => {
    const baseURL = this.host.tcUrl + MULTI_ORG_BASE_URL;
    const url = baseURL + [...paths].join('/');
    return url;
  };

  @recoverFromNotFound()
  async createResource({
    resource,
    orgId,
    data,
  }: {
    resource: Resource;
    orgId: string;
    data: Record<string, unknown>;
  }) {
    const url = this.buildURL(resource);
    const res = await this.httpClient.post(url, {
      headers: { 'TT-X-Organization-Key': orgId },
      data,
    });

    return Camelizer.camelizeObject(res.data);
  }

  async createFacility({
    orgId,
    facilityName,
    tenantId,
  }: {
    orgId: string;
    facilityName: string;
    tenantId: string;
  }) {
    return this.createResource({
      resource: 'facilities',
      orgId,
      data: { facilityName, tenantId },
    });
  }

  async createArea({
    orgId,
    areaName,
    parentFacilityId,
  }: {
    orgId: string;
    areaName: string;
    parentFacilityId: string;
  }) {
    return this.createResource({
      resource: 'areas',
      orgId,
      data: { areaName, parentFacilityId },
    });
  }

  async createRoom({
    orgId,
    roomName,
    parentAreaId,
  }: {
    orgId: string;
    roomName: string;
    parentAreaId: string;
  }) {
    return this.createResource({
      resource: 'rooms',
      orgId,
      data: { roomName, parentAreaId },
    });
  }

  async createSection({
    orgId,
    sectionName,
    parentRoomId,
  }: {
    orgId: string;
    sectionName: string;
    parentRoomId: string;
  }) {
    return this.createResource({
      resource: 'sections',
      orgId,
      data: { sectionName, parentRoomId },
    });
  }

  @recoverFromNotFound()
  async updateResource({
    id,
    resource,
    orgId,
    data,
  }: {
    id: string;
    resource: Resource;
    orgId: string;
    data: Record<string, unknown>;
  }) {
    const url = this.buildURL(resource, id);
    const res = await this.httpClient.put(url, {
      headers: { 'TT-X-Organization-Key': orgId },
      data,
    });
    return Camelizer.camelizeObject(res.data);
  }

  async updateFacility({
    id,
    facilityName,
    orgId,
  }: {
    id: string;
    facilityName: string;
    orgId: string;
  }) {
    return this.updateResource({
      id,
      resource: 'facilities',
      orgId,
      data: { facilityName },
    });
  }

  async updateArea({ id, areaName, orgId }: { id: string; areaName: string; orgId: string }) {
    return this.updateResource({
      id,
      resource: 'areas',
      orgId,
      data: { areaName },
    });
  }

  async updateRoom({ id, roomName, orgId }: { id: string; roomName: string; orgId: string }) {
    return this.updateResource({
      id,
      resource: 'rooms',
      orgId,
      data: { roomName },
    });
  }

  async updateSection({
    id,
    sectionName,
    orgId,
  }: {
    id: string;
    sectionName: string;
    orgId: string;
  }) {
    return this.updateResource({
      id,
      resource: 'sections',
      orgId,
      data: { sectionName },
    });
  }

  @recoverFromNotFound()
  async deleteResource({ id, orgId, resource }: { id: string; orgId: string; resource: Resource }) {
    const url = this.buildURL(resource, id);
    const res = await this.httpClient.del(url, {
      headers: { 'TT-X-Organization-Key': orgId },
    });
    return Camelizer.camelizeObject(res.data);
  }

  async deleteFacility({ id, orgId }: { id: string; orgId: string }) {
    return this.deleteResource({
      id,
      resource: 'facilities',
      orgId,
    });
  }

  async deleteArea({ id, orgId }: { id: string; orgId: string }) {
    return this.deleteResource({
      id,
      resource: 'areas',
      orgId,
    });
  }

  async deleteRoom({ id, orgId }: { id: string; orgId: string }) {
    return this.deleteResource({
      id,
      resource: 'rooms',
      orgId,
    });
  }

  async deleteSection({ id, orgId }: { id: string; orgId: string }) {
    return this.deleteResource({
      id,
      resource: 'sections',
      orgId,
    });
  }

  async getOrganizationLocations(
    orgId: string,
    offset = 0,
    limit = 100,
    results: Locations = []
  ): Promise<Locations> {
    const headers = { 'TT-X-Organization-Key': orgId };
    const params = new URLSearchParams({ offset: `${offset}`, limit: `${limit}` });
    const url = this.buildURL('tenants', orgId) + '?' + params;
    const { data } = await this.httpClient.get<OrgResponse>(url, {
      headers,
    });
    results.push(...data.locations);
    if (data.locations.length < limit) return results;
    return this.getOrganizationLocations(orgId, offset + limit, limit, results);
  }

  async getUnassignedRooms(orgId: string, facilityId: string) {
    const headers = { 'TT-X-Organization-Key': orgId };
    const url = this.buildURL('facilities', facilityId, 'rooms');
    const { data } = await this.httpClient.get<UnassignedRoomsResponse>(url, { headers });
    return data;
  }

  async createUnit({
    facilityId,
    orgId,
    unitName,
    roomIds,
  }: {
    facilityId: string;
    orgId: string;
    unitName: string;
    roomIds: string[];
  }) {
    const url = this.buildURL('units');
    const data = [{ facilityId, unitName, roomIds }];
    const res = await this.httpClient.post<unknown>(url, {
      data: data,
      headers: {
        'TT-X-Organization-Key': orgId,
      },
    });

    return res.data;
  }

  async updateUnit({
    orgId,
    unitId,
    unitName,
    roomIds,
  }: {
    orgId: string;
    unitId: string;
    unitName: string;
    roomIds: string[];
  }) {
    const data = { unitName, roomIds };
    const url = this.buildURL('units', unitId);
    const res = await this.httpClient.put<unknown>(url, {
      data,
      headers: {
        'TT-X-Organization-Key': orgId,
      },
    });

    return res.data;
  }

  async deleteUnit({ orgId, unitId }: { orgId: string; unitId: string }) {
    const url = this.buildURL('units', unitId);
    const res = await this.httpClient.del(url, {
      headers: {
        'TT-X-Organization-Key': orgId,
      },
    });

    return res.data;
  }

  async findUnits({ orgId }: { orgId: string }) {
    const url = this.buildURL('tenants', orgId, 'units');
    const res = await this.httpClient.get<Units>(url, {
      headers: {
        'TT-X-Organization-Key': orgId,
      },
    });

    return res.data;
  }

  async getUnit({ orgId, unitId }: { orgId: string; unitId: string }) {
    const url = this.buildURL('units', unitId);
    const { data } = await this.httpClient.get<Unit>(url, {
      headers: { 'TT-X-Organization-Key': orgId },
    });
    return data;
  }
}
