import * as searchResults from './searchResults';
import adjustQueryParams from './adjustQueryParams';
import allowedSendersReasons from './allowedSendersReasons';
import appointmentFormats from './appointmentFormats';
import attachmentType from './attachmentType';
import calculateDownloadSpeed from './calculateDownloadSpeed';
import copyToClipboard from './copyToClipboard';
import detectOnEsc from './detectOnEsc';
import detectOnSoftClick from './detectOnSoftClick';
import downloadFile from './downloadFile';
import escalationStatusLine from './escalationStatusLine';
import flattenKeys from './flattenKeys';
import formatPhoneWithDash from './formatPhoneWithDash';
import generateValidTimes from './generateValidTimes';
import generateValidTimezones from './generateValidTimezones';
import buildPatientLocation from './buildPatientLocation';
import getRecipientEntity from './getRecipientEntity';
import getTimeDiffInSecondsRounded from './getTimeDiffInSecondsRounded';
import highlightText from './highlightText';
import isInput from './isInput';
import isSingleEmoji from './isSingleEmoji';
import isToday from './isToday';
import isTokenTigerPage from './isTokenTigerPage';
import jsonCloneDeep from './jsonCloneDeep';
import mobxInjectSelect from './mobxInjectSelect';
import mobxReact from './mobxReact';
import overloadEvent from './overloadEvent';
import passwordValidation from './passwordValidation';
import roundWithPrecision from './roundWithPrecision';
import scheduledMessageFormats from './scheduledMessageFormats';
import scheduleTimezones from './scheduleTimezones';
import shouldRunCommandBot from './shouldRunCommandBot';
import searchPlaceholder from './searchPlaceholder';
import timeLeft from './timeLeft';
import validator from './validator';

export const FROZEN_EMPTY_ARRAY = Object.freeze([]);
export const FROZEN_EMPTY_OBJECT = Object.freeze({});

export {
  adjustQueryParams,
  allowedSendersReasons,
  appointmentFormats,
  attachmentType,
  calculateDownloadSpeed,
  copyToClipboard,
  detectOnEsc,
  detectOnSoftClick,
  downloadFile,
  escalationStatusLine,
  flattenKeys,
  formatPhoneWithDash,
  generateValidTimes,
  generateValidTimezones,
  buildPatientLocation,
  getRecipientEntity,
  getTimeDiffInSecondsRounded,
  highlightText,
  isInput,
  isSingleEmoji,
  isToday,
  isTokenTigerPage,
  jsonCloneDeep,
  mobxInjectSelect,
  mobxReact,
  overloadEvent,
  passwordValidation,
  roundWithPrecision,
  scheduledMessageFormats,
  scheduleTimezones,
  searchPlaceholder,
  searchResults,
  shouldRunCommandBot,
  timeLeft,
  validator,
};

type isEmail = { isEmail: (s?: string) => boolean };
type isPhone = { isPhone: (s?: string) => boolean };
export let attachments = {} as unknown;
export let date = {} as {
  formatDob: (date?: string) => string;
  secondsToDurationFormat: (num: number) => string;
};
export let email = {} as isEmail;
export let phone = {} as isPhone;

export function installSdkUtils(client: { [key: string]: unknown }) {
  attachments = client.attachments;
  date = client.date as {
    formatDob: (date?: string) => string;
    secondsToDurationFormat: (num: number) => string;
  };
  email = client.email as isEmail;
  phone = client.phone as isPhone;
}
