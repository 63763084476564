import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import BEM from '../../../../common/bem';
import { EntityAvatar, PatientNetworkDisplayName } from '../../../../common/components';
import { mobxInjectSelect, date } from '../../../../common/utils';
import { CallLog, User } from '../../../../types';

const classes = BEM.with('CallLogViewDetails');
const OPT_OUT_MESSAGE = 'Opted out via SMS';
const AVATAR_SIZE = 16;

function memberLineItem(user: User) {
  const { botRole, id, isRoleBot } = user;
  const path = user.isPatient ? 'patient' : 'patientContact';
  const userOptedOut = get(user, `${path}.smsOptedOut`, false);
  return (
    <div className={classes('member-item')} key={id}>
      <div className={classes('icon')}>
        <EntityAvatar
          entity={isRoleBot && botRole ? botRole : user}
          indicatorSize={'TINY'}
          showPresenceIndicator={true}
          size={AVATAR_SIZE}
        />
      </div>
      <div className={classes('member-name', { userOptedOut })}>
        <PatientNetworkDisplayName users={[user]} />
      </div>
      {userOptedOut && <div className={classes('opt-out')}> {OPT_OUT_MESSAGE} </div>}
    </div>
  );
}

type CallLogViewDetailsProps = {
  patientAndContacts: User[];
  providers: User[];
};

type MobxProps = {
  currentLog: CallLog;
};

function CallLogViewDetails({
  currentLog,
  patientAndContacts,
  providers,
}: CallLogViewDetailsProps & MobxProps) {
  const {
    call: { createdOn, duration },
    isQuickCall,
  } = currentLog;
  const direction = currentLog.isOutgoing ? 'Outgoing' : 'Incoming';
  const type = currentLog.call.isVideo ? 'Video' : 'Mobile';
  return (
    <div className={classes('')}>
      <div className={classes('title')}>Call Details</div>
      <div className={classes('details')}>
        <div>
          <span>Call Type:</span>
          {`${direction} ${type}`}
        </div>
        <div>
          <span>Date:</span>
          {moment(createdOn).format('MMM D')}
        </div>
        <div>
          <span>Time:</span>
          {moment(createdOn).format('h:mmA')}
        </div>
        <div>
          <span>Duration:</span>
          {date.secondsToDurationFormat(duration)}
        </div>
      </div>
      {!isQuickCall && (
        <div>
          <div className={classes('title-sm')}>{'Patient & Contacts'}</div>
          <div className={classes('member-list')}>{patientAndContacts.map(memberLineItem)}</div>
          <div className={classes('title-sm')}>Providers</div>
          <div className={classes('member-list')}>{providers.map(memberLineItem)}</div>
        </div>
      )}
    </div>
  );
}

export default mobxInjectSelect<CallLogViewDetailsProps, MobxProps>({
  callStore: ['currentLog'],
})(CallLogViewDetails);
