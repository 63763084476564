import React, { Component } from 'react';
import BEM from '../../bem';
import Modal from '../Modal';
import mobxInjectSelect from '../../utils/mobxInjectSelect';
import { ReactComponent as CloseButtonSvg } from '../../images/close-button.svg';

const classes = BEM.with('MessageAttachmentModal');

class MessageAttachmentModal extends Component {
  render() {
    const { currentMessageAttachment } = this.props;

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        hasCloseButton={false}
        headerClass={classes('header')}
        isOpen={!!currentMessageAttachment}
        onRequestClose={this._onRequestClose}
        overlayClassName={'image'}
        size={'image'}
      >
        {currentMessageAttachment && this._renderAttachment()}
      </Modal>
    );
  }

  _onRequestClose = () => {
    const { closeMessageAttachmentModal, closeModal } = this.props;
    closeMessageAttachmentModal();
    closeModal();
  };

  _download = async (e) => {
    const { currentMessageAttachment, downloadAttachment } = this.props;
    e.preventDefault();
    e.stopPropagation();
    downloadAttachment(currentMessageAttachment.messageId, currentMessageAttachment.attachmentId);
  };

  _renderPDF = () => {
    const { currentMessageAttachment } = this.props;
    return (
      <iframe
        name={currentMessageAttachment.name}
        className={classes('pdf')}
        src={`${currentMessageAttachment.url}#view=FitBH`}
        title={`pdfAttachmentIframe-${currentMessageAttachment.name}`}
        allowFullScreen
      />
    );
  };

  _renderVideo = () => {
    const { currentMessageAttachment } = this.props;
    return (
      <video
        controls
        alt={currentMessageAttachment.name}
        className={classes('video')}
        data-test-id={currentMessageAttachment.name}
        preload="metadata"
        src={currentMessageAttachment.url}
      >
        {"Sorry, your browser doesn't support embedded videos."}
      </video>
    );
  };

  _renderImage = () => {
    const { currentMessageAttachment } = this.props;
    return (
      <img
        alt={currentMessageAttachment.name}
        className={classes('image')}
        onLoad={this._handleImageLoad}
        src={currentMessageAttachment.url}
      />
    );
  };

  _renderAttachment = () => {
    const { currentMessageAttachment, isInlineVideo, isInlineImage, isInlinePDF } = this.props;

    return (
      <div className={classes('container')}>
        <div className={classes('download-header')}>
          <div className={classes('download-action')} onClick={this._download}>
            Download
          </div>
          <CloseButtonSvg onClick={this._onRequestClose} className={classes('close-button')} />
        </div>
        <div className={classes('image-wrapper-column')}>
          <div className={classes('image-wrapper-row')}>
            <div className={classes('image-wrapper')}>
              {isInlineVideo(currentMessageAttachment) && this._renderVideo()}
              {isInlineImage(currentMessageAttachment) && this._renderImage()}
              {isInlinePDF(currentMessageAttachment) && this._renderPDF()}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default mobxInjectSelect({
  messageAttachmentStore: [
    'closeMessageAttachmentModal',
    'currentMessageAttachment',
    'downloadAttachment',
    'isInlineImage',
    'isInlineVideo',
    'isInlinePDF',
    'openMessageAttachmentModal',
  ],
})(MessageAttachmentModal);
