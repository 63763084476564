// @ts-nocheck
export default function (store, { host }) {
  const VirtualWaitingRoomVisit = store.defineModel('VirtualWaitingRoomVisit', {
    parseAttrs(attrs) {
      return attrs;
    },

    relations: {
      belongsTo: {
        conversation: { type: 'conversation', foreignKey: 'conversationId' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        virtualWaitingRoom: { type: 'VirtualWaitingRoom', foreignKey: 'roomId' },
      },
    },
  });

  return VirtualWaitingRoomVisit;
}
