// @ts-nocheck
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { MUTE_FIND_ALL } = ROUTES;

export default class MuteAPI extends BaseAPI {
  async findAll() {
    const { version = VERSION_TWO } = super.getVersion(MUTE_FIND_ALL);
    const res = await this.httpClient.get('/:version/mute', {
      urlParams: { version },
    });
    return res.data;
  }

  async unmuteAll() {
    await this.httpClient.del('/v2/mute');
  }

  @recoverFromNotFound()
  async muteUser(
    userId: string,
    durationInMinutes: number,
    organizationId: string,
    /*: {
  recipientOrganizationId: ?string
  }*/
    { recipientOrganizationId = organizationId } = {}
  ) {
    await this.httpClient.post('/v2/mute/p2p', {
      data: {
        sender_token: userId,
        sender_organization: organizationId,
        receiver_organization: recipientOrganizationId,
        duration: durationInMinutes.toString(),
      },
    });
  }

  @recoverFromNotFound()
  async unmuteUser(
    userId: string,
    organizationId: string,
    /*: {
  recipientOrganizationId: ?string
  }*/
    { recipientOrganizationId = organizationId } = {}
  ) {
    await this.httpClient.del('/v2/mute/p2p', {
      headers: {
        'TT-X-Sender-Token': userId,
        'TT-X-Sender-Organization': organizationId,
        'TT-X-Receiver-Organization': recipientOrganizationId,
      },
    });
  }

  @recoverFromNotFound()
  async muteGroup(groupId: string, durationInMinutes: number, organizationId: string) {
    await this.httpClient.post('/v2/mute/group/:groupId', {
      urlParams: { groupId },
      data: {
        duration: durationInMinutes.toString(),
        receiver_organization: organizationId,
      },
    });
  }

  @recoverFromNotFound()
  async unmuteGroup(groupId: string, organizationId: string) {
    await this.httpClient.del('/v2/mute/group/:groupId', {
      urlParams: { groupId },
      headers: {
        'TT-X-Receiver-Organization': organizationId,
      },
    });
  }

  @recoverFromNotFound()
  async muteOrganization(organizationId: string, durationInMinutes: number) {
    await this.httpClient.post('/v2/mute/organization/:organizationId', {
      urlParams: { organizationId },
      data: {
        duration: durationInMinutes.toString(),
        receiver_organization: organizationId,
      },
    });
  }

  @recoverFromNotFound()
  async unmuteOrganization(organizationId: string) {
    await this.httpClient.del('/v2/mute/organization/:organizationId', {
      urlParams: { organizationId },
      headers: {
        'TT-X-Receiver-Organization': organizationId,
      },
    });
  }
}
