import { Camelizer } from '../utils';
import BaseAPI from './BaseAPI';

export default class PatientContextAPI extends BaseAPI {
  async searchAllPatients({
    orgId,
    query,
    from,
    pageSize,
  }: {
    orgId: string;
    query: string;
    from: number;
    pageSize: number;
  }) {
    const res = await this.httpClient.post(
      `${this.host.tcUrl}/v1/patient-search-service/api/search/patient`,
      {
        headers: { 'TT-X-Organization-Key': orgId },
        withCredentials: false,
        data: {
          query,
          from,
          pageSize,
        },
      }
    );
    return Camelizer.camelizeObject(res.data);
  }

  async searchMyPatients({
    orgId,
    pageSize,
    continuationValue,
  }: {
    orgId: string;
    pageSize: number;
    continuationValue?: string;
  }) {
    const res = await this.httpClient.get(
      `${this.host.tcUrl}/v1/assignment-service/api/care_providers/${this.host.currentUserId}/patients`,
      {
        headers: { 'TT-X-Organization-Key': orgId },
        withCredentials: false,
        query: {
          continuation: continuationValue,
          care_provider_id_type: 'account_token',
          size: pageSize,
        },
      }
    );

    return Camelizer.camelizeObject(res.data);
  }

  async getPatient({ patientContextId, orgId }: { patientContextId: string; orgId: string }) {
    const res = await this.httpClient.get(
      `${this.host.tcUrl}/v1/patient-service/api/patients/${patientContextId}`,
      {
        withCredentials: false,
        headers: { 'TT-X-Organization-Key': orgId },
      }
    );

    return Camelizer.camelizeObject(res.data);
  }

  async getPatientCareTeamMembers({
    continuationValue = '',
    orgId,
    pageSize = 10,
    patientContextId,
    tcAccountOnly,
  }: {
    continuationValue?: string;
    orgId: string;
    pageSize?: number;
    patientContextId: string;
    tcAccountOnly: number;
  }) {
    const url = `${this.host.tcUrl}/v1/assignment-service/api/patients/${patientContextId}/care_providers?tc_account_only=${tcAccountOnly}&page_size=${pageSize}`;
    const res = await this.httpClient.get(
      continuationValue ? url + `&continuation=${continuationValue}` : url,
      {
        headers: { 'TT-X-Organization-Key': orgId },
      }
    );

    return Camelizer.camelizeObject(res.data);
  }
}
