// @ts-nocheck
const SEC_IN_MINUTES = 60;
const SEC_IN_HOURS = 60 * SEC_IN_MINUTES;
const SEC_IN_DAYS = 24 * SEC_IN_HOURS;
const SEC_IN_WEEKS = 7 * SEC_IN_DAYS;
const OFFSET_UNITS = {
  minutes: SEC_IN_MINUTES,
  hours: SEC_IN_HOURS,
  days: SEC_IN_DAYS,
  weeks: SEC_IN_WEEKS,
};

export default function (store, { host }) {
  const WorkflowEvent = store.defineModel('workflowEvent', {
    replaceKeys: {
      eventId: 'id',
    },

    transientAttrs: ['$deleted'],

    defaultValues: {},

    parseAttrs(attrs) {
      const unitMultiplier = OFFSET_UNITS[attrs.appointmentTimeOffsetUnit];
      attrs.appointmentTimeOffsetSeconds = attrs.appointmentTimeOffset * unitMultiplier;

      return attrs;
    },
  });

  return WorkflowEvent;
}
