// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import _ from 'lodash';
import shallowequal from 'shallowequal';
import * as errors from '../errors';
import Camelizer from '../utils/Camelizer';
import FeatureService from '../models/enums/FeatureService';
import BaseService from './BaseService';

export default class OrganizationsService extends BaseService {
  mounted() {
    this._deferConversationChanges = false;
    this._lastMyRoles = [];
    this.host.models.Conversation.on('afterInject', this._onChangeConversation);
    this.host.models.Conversation.on('afterEject', this._onChangeConversation);
    this.host.models.User.on('afterInject', this._onChangeUser);
  }

  dispose() {
    this._deferConversationChanges = false;
    this._lastMyRoles = [];
    this.host.models.Conversation.removeListener('afterInject', this._onChangeConversation);
    this.host.models.Conversation.removeListener('afterEject', this._onChangeConversation);
    this.host.models.User.removeListener('afterInject', this._onChangeUser);
    reusePromise.clear(this._findAll);
  }

  async create(
    params = {
      allowPublicGroups: undefined,
      autologoutTime: undefined,
      deleteOnRead: undefined,
      groupLifespan: undefined,
      lifecycleCallback: undefined,
      name: undefined,
      pinLock: undefined,
      settingsRestricted: undefined,
      ttl: undefined,
      welcomeEmailSettings: undefined,
    }
  ) {
    this.host.requireUser();

    // TODO validation

    return this.httpClient
      .post('/v2/organization', {
        data: params,
      })
      .then((res) => {
        const id = res.getHeader('TT-X-Organization-Key');

        const organization = this.host.models.Organization.inject({
          id,
          name: params.name,
        });

        return organization;
      });
  }

  async update(
    organization = {
      allowPublicGroups: undefined,
      autologoutTime: undefined,
      autoSendWelcomeEmail: undefined,
      deleteOnRead: undefined,
      groupLifespan: undefined,
      id: undefined,
      lifecycleCallback: undefined,
      name: undefined,
      pinLock: undefined,
      settingsRestricted: undefined,
      ttl: undefined,
      welcomeEmailLogo: undefined,
      welcomeEmailSettings: undefined,
    }
  ) {
    this.host.requireUser();

    // TODO validation

    const params = Camelizer.underscoreObject({
      allowPublicGroups: organization.allowPublicGroups,
      autologoutTime: organization.autologoutTime,
      autoSendWelcomeEmail: organization.autoSendWelcomeEmail,
      dor: organization.deleteOnRead,
      groupLifespan: organization.groupLifespan,
      lifecycleCallback: organization.lifecycleCallback,
      name: organization.name,
      pinLock: organization.pinLock,
      settingsRestricted: organization.settingsRestricted,
      ttl: organization.ttl,
      welcomeEmailLogo: organization.welcomeEmailLogo,
      welcomeEmailSettings: organization.welcomeEmailSettings,
    });

    return this.httpClient
      .post('/v2/organization/:id', {
        urlParams: { id: organization.id },
        data: params,
      })
      .then((res) => {
        const updatedOrganization = this.host.models.Organization.inject({
          id: organization.id,
          ...params,
        });
        return updatedOrganization;
      });
  }

  // TODO @requireUser
  @reusePromise()
  async findAll({ filter = { onlyApproved: false }, findRoles = true } = {}) {
    this.host.requireUser();

    let list = await this._findAll();

    if (findRoles) await this.host.userRoles.findAll();

    if (filter.onlyApproved) list = _.filter(list, { pending: false });
    list = _.sortBy(list, (organization) => organization.name.toLowerCase());

    list.forEach((organization) => this.__reloadConversations(organization));

    return list;
  }

  @reusePromise()
  async refreshAll() {
    reusePromise.clear(this._findAll);
    const list = await this._findAll();
    await this.host.userRoles.refreshAll();
    return list;
  }

  @reusePromise({ memoize: true })
  async _findAll() {
    const items = await this.host.api.organizations.findAllOfUser(this.host.currentUserId);
    const orgs = [],
      orgIds = [];
    for (const item of items) {
      const org = this.host.models.Organization.inject(item);
      this.host.models.Organization.removePlaceholder({ entity: org, attrs: item });
      orgs.push(org);
      orgIds.push(org.id);
    }
    this.host.models.User.inject({ id: this.host.currentUserId, organizationIds: orgIds });

    return orgs;
  }

  @reusePromise()

  /**
   * Find an organization
   * @param  {string} id - organization ID
   * @param  {Boolean} options.bypassCache - Force fetch from server
   * @param  {Boolean} options.ignoreNotFound - Ignore if not found - promise will return null
   * @return {Promise.<Organization,Error>} - a promise with the organization, or error
   */
  async find(id: string, options = {}) {
    options = _.defaults(options, { bypassCache: false, ignoreNotFound: false });

    this.host.requireUser();

    // local first
    let organization;

    if (!options.bypassCache) {
      organization = this.host.models.Organization.get(id);
      if (organization && !organization.$placeholder) return organization;
    }

    const data = await this.host.api.organizations.find(this.host.currentUserId, id);
    await this.host.userRoles.findAll({ organizationId: id });

    if (data) {
      organization = this.host.models.Organization.inject(data);
      this.__reloadConversations(organization);
    } else if (!options.ignoreNotFound) {
      throw new errors.NotFoundError(this.host.models.Organization.name, id);
    }

    return organization;
  }

  _getAllowedSenders(organization) {
    const { id } = organization;
    const allowedSenders = [];
    const currentUser = this.host.currentUser;

    if (currentUser) {
      const currentRoles = currentUser.roles;

      for (const role of currentRoles) {
        if (role.organizationId === id) allowedSenders.push(role);
      }

      allowedSenders.push(currentUser);
    }

    return allowedSenders;
  }

  async getPendingEulas() {
    const userOrgs = await this._findAll();
    const urlOrganizations = userOrgs
      .filter((org) => org.eulaEnabled && !org.isEulaAccepted)
      .reduce((map, org) => {
        const { id, eulaUrl: url } = org;

        if (!map[url]) {
          map[url] = [id];
        } else {
          map[url].push(id);
        }

        return map;
      }, {});

    return Object.entries(urlOrganizations).map(([url, organizationIds]) => ({
      url,
      organizationIds,
    }));
  }

  async findEulaContent(eulaUrl) {
    eulaUrl = eulaUrl.replace('http://', 'https://');
    const content = await this.httpClient.get(eulaUrl, {
      withCredentials: false,
      withHeaders: false,
      resFormat: 'text',
    });
    return content;
  }

  getSettingValue(id: string, key: string) {
    id = this._resolveModelId(id);
    const organization = this.getById(id);
    if (!organization) return null;
    return organization.getSettingValue(key);
  }

  getAll() {
    return this.host.models.Organization.getAll();
  }
  getById(id: string) {
    return this.host.models.Organization.get(id);
  }
  getMultiById(ids: Array<string>) {
    return this.host.models.Organization.getMulti(ids);
  }

  _onChangeConversation = (resource, conversation) => {
    const { organization } = conversation;
    if (!organization) return;
    if (this.host.currentlyServingOfflineMessages || this._deferConversationChanges) return;

    this.__reloadConversations(organization);
  };

  __reloadConversations(organization) {
    if (!organization) return;

    const { conversations } = organization;
    let highestSortNumber = 0;
    let unreadAlertsCount = 0;
    let unreadCountPatient = 0;
    let unreadCountProvider = 0;
    let unreadCountVirtualWaitingRoom = 0;
    let unreadPriorityCountPatient = 0;
    let unreadPriorityCountProvider = 0;

    for (const conversation of conversations) {
      const {
        highestSortNumber: convHighestSortNumber,
        unreadCount: convUnreadCount,
        unreadPriorityCount: convUnreadPriorityCount,
        lastIncomingMessageSortNumber,
        shouldDisplay,
        metadata,
      } = conversation;

      const isAlertConversation =
        conversation?.metadata?.feature_service === FeatureService.ALERTS ||
        conversation?.metadata?.feature_service === FeatureService.GROUP_ALERTS ||
        conversation?.metadata?.feature_service === FeatureService.ROLE_ALERTS;
      if (!shouldDisplay && !isAlertConversation) continue;

      highestSortNumber = Math.max(highestSortNumber, convHighestSortNumber);

      if (metadata && metadata['feature_service'] === FeatureService.PATIENT_MESSAGING) {
        unreadCountPatient += convUnreadCount;
        unreadPriorityCountPatient += convUnreadPriorityCount;
      } else if (metadata && metadata['feature_service'] === FeatureService.VIRTUAL_WAITING_ROOM) {
        unreadCountVirtualWaitingRoom += convUnreadCount;
      } else if (
        metadata &&
        (metadata['feature_service'] === FeatureService.ALERTS ||
          metadata['feature_service'] === FeatureService.ROLE_ALERTS ||
          (metadata['feature_service'] === FeatureService.GROUP_ALERTS &&
            metadata?.meta_type !== 'alert_conversation'))
      ) {
        if (metadata['feature_service'] === FeatureService.GROUP_ALERTS) {
          if (!conversation?.messages?.[0]?.isHistoricalAlert) {
            unreadAlertsCount += convUnreadCount;
          }
        } else {
          unreadAlertsCount += convUnreadCount;
        }
      } else {
        unreadCountProvider += convUnreadCount;
        unreadPriorityCountProvider += convUnreadPriorityCount;
      }

      if (
        !this.config.condensedReplays &&
        lastIncomingMessageSortNumber &&
        (!organization.lastIncomingMessageSortNumber ||
          organization.lastIncomingMessageSortNumber < lastIncomingMessageSortNumber)
      ) {
        organization.lastIncomingMessageSortNumber = lastIncomingMessageSortNumber;
      }
    }

    organization.highestSortNumber = highestSortNumber;
    organization.unreadAlertsCount = unreadAlertsCount;
    organization.unreadCount = unreadCountProvider + unreadCountPatient;
    organization.unreadPriorityCount = unreadPriorityCountProvider + unreadPriorityCountPatient;
    organization.networks.provider.unreadCount = unreadCountProvider;
    organization.networks.provider.unreadPriorityCount = unreadPriorityCountProvider;
    organization.networks.patient.unreadCount = unreadCountPatient;
    organization.networks.patient.unreadPriorityCount = unreadPriorityCountPatient;
    organization.virtualWaitingRoomUnreadCount = unreadCountVirtualWaitingRoom;

    this.host.models.Organization.inject(organization);

    organization.emit('change');
  }

  _onChangeUser = (resource, user) => {
    const { id: userId } = user;
    const allOrganizations = this.host.models.Organization.getAll();

    for (const organization of allOrganizations) {
      if (
        organization.autoForwardReceiverId === userId &&
        organization.autoForwardReceiver !== user
      ) {
        this.host.models.Organization.inject(organization);
      }
    }

    if (user !== this.host.currentUser) return;
    if (this.host.currentlyServingOfflineMessages || this._deferConversationChanges) return;

    const { roles: myRoles } = this.host.currentUser;
    if (shallowequal(myRoles, this._lastMyRoles)) return;
    this._lastMyRoles = [...myRoles];

    for (const organization of allOrganizations) {
      if (!shallowequal(organization.allowedSenders, this._getAllowedSenders(organization))) {
        this.host.models.Organization.inject(organization);
      }
    }
  };

  getPreferences = async (organizationId) => {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    return await this.host.api.organizations.getPreferences(organizationId);
  };

  editPreferences = async ({ quickReplies = [], organizationId }) => {
    if (!quickReplies) throw new errors.ValidationError('quickReplies', 'required');
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');

    return await this.host.api.organizations.editPreferences({ organizationId, quickReplies });
  };

  reactToOrganizationPreferencesEvent(event) {
    this.emit('preferences:change', Camelizer.camelizeObject(event));
  }

  // GET /v1/organization/[ORGANIZATION-KEY]/auth
  // POST /v1/organization/[ORGANIZATION-KEY]/auth
}
