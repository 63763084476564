import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import TCClient from '../../../../client';
import ToolTip from '../../../../widgets/messenger/components/ToolTip';
import { ReactComponent as IconTrashSvg } from '../../../images/icon-trash.svg';
import BEM from '../../../bem';
import DotsIndicator from '../../DotsIndicator';
import Modal from '../../Modal';
import { VWR } from '../../../../types';
import SourceIdVwr from '../../../../models/enums/SourceIdVwr';

const classes = BEM.with('VWRVisitReasonsModal');
const CHARACTER_LIMIT = 50;
const sourceId = SourceIdVwr.ADMIN;

type Reason = { key: string; value: string };

type VisitReasonsProps = {
  currentOrganizationId: string;
  isOpen: boolean;
  onClose: (room: VWR) => void;
  openModal: (modalType: string) => void;
  room: VWR;
};

export const VWRVisitReasonsModal: React.FC<VisitReasonsProps> = ({
  currentOrganizationId,
  isOpen,
  onClose,
  openModal,
  room,
}) => {
  const [items, setItems] = useState<Reason[]>([]);
  const [activeInput, setActiveInput] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [dragTargetId, setDragTargetId] = useState('');
  // Keep for when dragging is re-enabled
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [draggingItemId, setDraggingItemId] = useState('');

  const reasonsLimit = 10;
  const canAdd = items.length < reasonsLimit;

  useEffect(() => {
    const reasons = room.visitReasons
      ?.sort((a, b) => parseInt(a.reasonKey) - parseInt(b.reasonKey))
      ?.map((r) => ({ key: r.reasonKey, value: r.reasonValue }));
    if (reasons) {
      setItems(reasons);
    }
  }, [room, isOpen]);

  useEffect(() => {
    if (activeInput) {
      const el = document.getElementById('item-input');
      if (el) {
        el.focus();
      }
    }
  }, [activeInput]);

  const addItem = () => {
    let key = '1';
    if (items.length) {
      key = (parseInt(items[items.length - 1].key) + 1).toString();
    }
    setItems((items) => [...items, { key, value: '' }]);
    setActiveInput(key);
  };

  const deleteItem = (key: string) => setItems((items) => items.filter((item) => item.key !== key));

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setItems((items) =>
      items.map((item) => {
        return item.key === activeInput ? { ...item, value: event.target.value } : item;
      })
    );
  };

  const onBlur = () => {
    setActiveInput('');
    setItems((items) => items.filter((item) => item.value !== ''));
  };

  const onSave = async () => {
    const save = async () => {
      const sortedMappedReasons = items.map((v, i) => ({
        reasonKey: `${i + 1}`,
        reasonValue: v.value,
      }));
      setIsSaving(true);
      const updatedRoom = await TCClient.virtualWaitingRoom.update(room.id, currentOrganizationId, {
        version: room.version,
        visitReasons: sortedMappedReasons,
        sourceId,
      });
      setIsSaving(false);
      onClose(updatedRoom);
    };
    if (!isSaving) {
      try {
        await save();
      } catch (error) {
        console.log(error);
        setIsSaving(false);
        onClose(room);
        if (openModal) openModal('failure');
      }
    }
  };

  // Keep for when dragging is re-enabled
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const targetIndex = items.findIndex((item) => {
      return item.key === dragTargetId;
    });

    const filteredItems = items.filter((item) => item.key !== draggingItemId);
    const draggedItem = items.find((item) => item.key === draggingItemId);

    const reorderedItems = [
      ...filteredItems.slice(0, targetIndex),
      ...(draggedItem ? [draggedItem] : []),
      ...filteredItems.slice(targetIndex),
    ];

    setItems(reorderedItems);
    setDragTargetId('');
  };

  return (
    <Modal
      hasCloseButton={true}
      bodyClass={classes('body')}
      className={classes()}
      closeClass={classes('close-button')}
      header={'Patient Reasons for Visit'}
      headerClass={classes('header')}
      onRequestClose={() => onClose(room)}
      isOpen={isOpen}
      size={'large'}
    >
      <div className={classes('modal')}>
        <div className={classes('inner-body')}>
          <div className={classes('title')}>
            {`${items.length}/${reasonsLimit} Patient Visit Reasons`}
          </div>

          {items.length === 0 && (
            <div className={classes('no-reasons-message')}>
              <span>The patient will not be asked to select a reason for visit when entering.</span>
            </div>
          )}

          <div>
            {items.map((item, key) => (
              <div
                key={key}
                className={classes('message', { dragTarget: dragTargetId === item.key })}
                id={item.key}
              >
                <div
                  className={classes('text', { blank: !item.value })}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveInput(item.key);
                  }}
                >
                  {item.key === activeInput ? (
                    <input
                      id="item-input"
                      maxLength={CHARACTER_LIMIT}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={item.value}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setActiveInput('');
                        }
                      }}
                    />
                  ) : (
                    item.value
                  )}
                </div>
                <div className={classes('action')}>
                  <ToolTip text="Delete" textAlign="center" className={classes('delete-tooltip')}>
                    <div
                      className={classes('delete-icon-holder')}
                      onClick={() => deleteItem(item.key)}
                    >
                      <IconTrashSvg viewBox={'0.5 0 12 13'} className={classes('delete-icon')} />
                    </div>
                  </ToolTip>
                </div>
              </div>
            ))}
          </div>

          {canAdd && (
            <div
              onClick={addItem}
              className={classNames(classes('message'), classes('add-message'))}
            >
              New Visit Reason
            </div>
          )}
        </div>

        <div className={classes('footer')}>
          <button type="button" onClick={() => onClose(room)} className={classes('cancel-btn')}>
            CANCEL
          </button>
          <button
            type="button"
            className={classes('save-btn', { isSaving })}
            disabled={isSaving}
            onClick={onSave}
          >
            {isSaving ? <DotsIndicator color={'#3080df'} size={13} /> : 'SAVE'}
          </button>
        </div>
      </div>
    </Modal>
  );
};
