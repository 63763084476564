import moment from 'moment';

const CookieOptions = {
  FOREVER: {
    expires: moment().add(10, 'years').toDate(),
    path: '/',
  },
  SEVEN_DAYS: {
    expires: moment().add(7, 'days').toDate(),
    path: '/',
  },
};

export default CookieOptions;
