import Dots from 'react-activity/lib/Dots';
import styled from 'styled-components';

type DotsWrapperProps = {
  className?: string;
  color?: string;
  dotSpacing?: number;
  marginRight?: number;
  marginTop?: number;
  size?: number;
  speed?: number;
};

const DotsWrapper = (props: DotsWrapperProps) => {
  const { ...restProps } = props;
  return <Dots {...restProps} />;
};

const DotsIndicator = styled(DotsWrapper)`
  .rai-circle {
    ${({ dotSpacing = 4 }) =>
      dotSpacing &&
      `
      margin-right: ${dotSpacing}px;
    `}

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default DotsIndicator;
