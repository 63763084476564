// @ts-nocheck
function toLowerCase(name) {
  return name.replace(/[A-Z]/g, function (c) {
    return String.fromCharCode(c.charCodeAt(0) + 0x20);
  });
}

export default class HeadersPolyfill {
  constructor(all) {
    // Get headers: implemented according to mozilla's example code:
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders#Example
    const map = Object.create(null);
    const array = all.split('\r\n');

    for (let i = 0; i < array.length; i += 1) {
      const line = array[i];
      const parts = line.split(': ');
      const name = parts.shift();
      const value = parts.join(': ');
      map[toLowerCase(name)] = value;
    }
    this._map = map;
  }

  get(name) {
    return this._map[toLowerCase(name)];
  }
}
