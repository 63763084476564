import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Images } from '../../../models/enums';
import BEM from '../../bem';
import PatientDetailCard from '../../../widgets/messenger/components/PatientDetailCard';
import propTypes from '../../propTypes';
import ToolTip from '../../../widgets/messenger/components/ToolTip';
import { mobxInjectSelect } from '../../utils';
import { getGroupTag } from '../../utils/getGroupTag';
import VideoCallButton from '../../../common/components/Call/VideoCallButton';
import { TeamAvatar, GroupAvatar, PatientDetails } from '../';
import CallButton from '../Call/CallButton';
import { ReactComponent as PencilSvg } from '../../images/pencil.svg';
import PfCallOrigins from './../../../models/enums/PfCallOrigins';

const classes = BEM.with('GroupForm');
const PROVIDER_NETWORK_MEMBER_LIMIT = 10;

class GroupForm extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation.isRequired,
    group: propTypes.group.isRequired,
    isGroupVoiceCallEnabled: PropTypes.bool.isRequired,
    isPFGroupVideoCallEnabled: PropTypes.bool.isRequired,
    isPFVideoCallEnabled: PropTypes.bool.isRequired,
    memberCount: PropTypes.number.isRequired,
    openModal: PropTypes.func.isRequired,
    stopCurrentAction: PropTypes.func.isRequired,
    updateGroup: PropTypes.func.isRequired,
  };

  _currGroupName = null;
  _prevGroupName = null;

  state = {
    avatarPreviewUrl: this.props.group.avatarUrl,
    draftGroup: {
      avatarFile: null,
      name: this.props.group.displayName,
    },
    isDirty: false,
  };

  _handleKeyDown = (event) => {
    event.stopPropagation();
    const { key } = event;
    if (key === 'Escape') {
      this._onEsc(event);
    }
  };

  render() {
    const {
      currentConversation,
      group,
      isGroupVideoCallEnabled,
      isGroupVoiceCallEnabled,
      isPFGroupVideoCallEnabled,
      isPFVideoCallEnabled,
      memberCount: groupEditorStoreMemberCount,
    } = this.props;
    const { avatarPreviewUrl, draftGroup, isDirty } = this.state;
    const { isMuted, counterParty } = currentConversation;
    const { groupType, patientDetails = {} } = group;
    const tag = getGroupTag(group);
    const { smsOptedOut = false } = patientDetails || {};
    const memberCount = counterParty?.memberCount || groupEditorStoreMemberCount;

    // After invoking submitName(), the group will still have the previous name
    //  until the model gets updated
    // In the meantime, display the draft name (which is the one we submitted)
    const nameChangePending = this._prevGroupName === group.displayName;

    // If the above is not true, update the previous group name in case another instance
    //  changes the group name to a different version and then back to the original version
    if (!nameChangePending) {
      this._prevGroupName = group.displayName;
    }

    this._currGroupName = isDirty || nameChangePending ? draftGroup.name : group.displayName;

    const isPatientCare = groupType === 'PATIENT_CARE';
    const isPatientMessaging = groupType === 'PATIENT_MESSAGING';
    const isIntraTeam = groupType === 'INTRA_TEAM';

    let groupAvatarFragment;
    let groupCallActions;
    let groupNameFragment;
    let patientDetailsFragment;
    let patientDetailsBox;
    let patientCallActions;
    let memberCountFragment;
    let tagFragment;

    if (isPatientCare) {
      groupAvatarFragment = (
        <div className={classes('avatar-container-patient-care')}>
          <div className={classes('avatar-mute-container')}>
            <GroupAvatar
              ariaLabel="Info Pane Avatar Image"
              avatarUrl={avatarPreviewUrl}
              group={group}
              size="fit"
              isMuted={isMuted}
            />
          </div>
        </div>
      );

      groupNameFragment = (
        <div aria-label="Info Pane Name" className={classes('patient-name')}>
          {this._currGroupName}
        </div>
      );
      patientDetailsFragment = (
        <PatientDetails patient={patientDetails} className={classes('patient-details')} />
      );
    } else if (isPatientMessaging) {
      groupAvatarFragment = (
        <div className={classes('avatar-container', { isPatientMessaging })}>
          <GroupAvatar
            ariaLabel="Info Pane Avatar Image"
            avatarUrl={avatarPreviewUrl}
            group={group}
            size="fit"
          />
        </div>
      );

      patientDetailsBox = (
        <div className={classes('patient-detail-card-container')}>
          <PatientDetailCard handleMrnOverflow isCenterName conversation={currentConversation} />
        </div>
      );
      if (
        !smsOptedOut &&
        ((isPFVideoCallEnabled && memberCount === 2) ||
          (isPFVideoCallEnabled && isPFGroupVideoCallEnabled)) &&
        currentConversation
      ) {
        patientCallActions = (
          <div className={classes('patient-actions-container')}>
            <ToolTip
              text="Group call max 4 participants"
              disable={memberCount <= 4}
              isPatientFacing={true}
            >
              <VideoCallButton
                origin={PfCallOrigins.CONVERSATION_DETAILS}
                isWhite={true}
                disabled={memberCount > 4}
                entity={{
                  recipientId: group.patientDetails.isPatientContact
                    ? group.metadata.patient_contact_id
                    : group.patientDetails.id,
                  recipientName: group.displayName,
                  members: group.members,
                }}
              />
            </ToolTip>
          </div>
        );
      }
    } else if (isIntraTeam) {
      groupAvatarFragment = (
        <div className={classes('avatar-container-team', { isPatientMessaging })}>
          <TeamAvatar
            ariaLabel="Info Pane Avatar Image"
            avatarUrl={avatarPreviewUrl}
            displayName={group.displayName}
            group={group}
            size="fit"
            fontSize={24}
          />
        </div>
      );
      groupNameFragment = (
        <div className={classes('name-container-team')}>
          <p>{this._currGroupName}</p>
        </div>
      );
    } else {
      groupAvatarFragment = (
        <div className={classes('avatar-container')} onClick={this._changeAvatar}>
          <Dropzone
            className={classes('avatar-upload-container')}
            disableClick
            ref={this._setDropzone}
            accept="image/*"
            multiple={false}
            onDropAccepted={this._onAvatarSelected}
          >
            <div className={classes('overlay')} />
            <div className={classes('edit-avatar-text')}>Edit</div>
            <div className={classes('edit-avatar-icon-container')}>
              <PencilSvg className={classes('edit-avatar-icon')} />
            </div>
            <GroupAvatar
              ariaLabel="Info Pane Avatar Image"
              avatarUrl={avatarPreviewUrl}
              group={group}
              size="fit"
              isMuted={isMuted}
            />
          </Dropzone>
        </div>
      );

      if (
        (isGroupVideoCallEnabled || isGroupVoiceCallEnabled) &&
        memberCount <= PROVIDER_NETWORK_MEMBER_LIMIT &&
        groupType !== 'FORUM'
      ) {
        groupCallActions = (
          <div className={classes('group-actions-container')}>
            <CallButton entity={group} origin={PfCallOrigins.CONVERSATION_DETAILS} />
          </div>
        );
      }

      groupNameFragment = (
        <div className={classes('name-container')}>
          <input
            aria-label="Info Pane Name"
            className={classes('name-input')}
            maxLength={100}
            onChange={(e) => this._updateDraftField('name', e.target.value)}
            onKeyDown={this._handleKeyDown}
            placeholder="Group name"
            type="text"
            value={this._currGroupName}
          />
        </div>
      );

      memberCountFragment = <div className={classes('group-members')}>{memberCount} members</div>;
    }

    if (tag) {
      tagFragment = (
        <div className={classes('tag-details')}>
          <span className={classes('tag-indicator')} style={{ backgroundColor: tag.color }} />
          <span className={classes('tag-name')}>{tag.name}</span>
        </div>
      );
    }

    return (
      <div className={classes()}>
        <form onSubmit={this.submitName} onBlur={this.submitName} className={classes('form')}>
          <div className={classes('name-and-avatar', { isPatientCare })}>
            {groupAvatarFragment}
            {patientDetailsBox}
            {groupNameFragment}
            {tagFragment}
            {patientDetailsFragment}
            {memberCountFragment}
          </div>
        </form>
        {patientCallActions}
        {groupCallActions}
      </div>
    );
  }

  submitName = (e) => {
    e.preventDefault();

    const { group, updateGroup } = this.props;
    const { draftGroup } = this.state;

    // If the draft name is different than the one being displayed, it is because
    //  another instance changed the name and a SSE came in and updated the model
    // This scenario should only happen if, after the above, the user saves the
    //  name without making any changes (select and submit / deselect)
    // If this happens, set the previous name and draft name to the updated name
    let groupName = draftGroup.name;
    if (draftGroup.name !== this._currGroupName) {
      groupName = this._currGroupName;
    }

    this._prevGroupName = group.displayName;
    updateGroup(group.id, { name: groupName });
    this._updateDraftField('name', groupName, false);
  };

  submitAvatar = (avatarFile) => {
    const { group, updateGroup } = this.props;

    updateGroup(group.id, { avatarFile });
    this._updateDraftField('avatarFile', avatarFile);
  };

  _onEsc = (e) => {
    e.preventDefault();
    const { group, stopCurrentAction } = this.props;
    const { draftGroup } = this.state;
    const { name } = group;

    if (name === draftGroup.name) {
      stopCurrentAction();
    } else {
      this.setState({
        draftGroup: { name },
      });
    }
  };

  _updateDraftField(field, value, isDirty = true) {
    this.setState({
      draftGroup: { ...this.state.draftGroup, [field]: value },
      isDirty,
    });
  }

  _setDropzone = (ref) => {
    this.dropzone = ref;
  };

  _changeAvatar = () => {
    this.dropzone.open();
  };

  _onAvatarSelected = ([file]) => {
    const { openModal } = this.props;
    if (!file) return;

    if (file.size > Images.MAX_AVATAR_FILE_SIZE_BYTES) {
      openModal('rejectedFileSize', {
        maxFileSize: `${Images.MAX_AVATAR_FILE_SIZE_MB}MB`,
      });
    } else {
      this.setState({ avatarPreviewUrl: file.preview });
      this._updateDraftField('avatarFile', file);
      this.submitAvatar(file);
    }
  };
}

export default mobxInjectSelect({
  callStore: [
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isPFGroupVideoCallEnabled',
    'isPFVideoCallEnabled',
  ],
  conversationStore: ['currentConversation'],
  groupEditorStore: ['memberCount'],
  groupStore: ['updateGroup'],
  messengerStore: ['stopCurrentAction'],
  modalStore: ['openModal'],
})(GroupForm);
