import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy, omit } from 'lodash';
import { ReduxState, actions } from '../../../../redux-stores';
import { Color } from '../../../../types';
import BEM from '../../../bem';
import Dropdown from '../../Dropdown';

const { saveFilter } = actions;
const classes = BEM.with('CollaborationTags');

type TagColorFilterProps = {
  buttonRef: React.RefObject<HTMLElement>;
  toggleHandler: () => void;
};

function TagColorFilter({ buttonRef, toggleHandler }: TagColorFilterProps) {
  const dispatch = useDispatch();
  const colors = useSelector((state: ReduxState) => state.tags.colors);
  const filteredColors = useSelector((state: ReduxState) => state.tags.filteredColors);

  const [selectedColors, setSelectedColors] = useState(groupBy(filteredColors, 'colorId'));

  const selectColor = useCallback(
    (color: Color) => {
      if (selectedColors[color.colorId]) {
        setSelectedColors(omit(selectedColors, [color.colorId]));
      } else {
        setSelectedColors({ ...selectedColors, [color.colorId]: [color] });
      }
    },
    [selectedColors]
  );

  const saveColorFilter = () => {
    const payload: Color[] = [];
    Object.values(selectedColors)
      .flat(1)
      .map((item: Color) => {
        if (item) {
          payload.push(item);
        }
        return item;
      });

    dispatch(saveFilter(payload));
    toggleHandler();
  };

  return (
    <div className={classes('dropdown-container')}>
      <Dropdown
        triggerHandler={toggleHandler}
        triggerRef={buttonRef}
        ariaLabel={'Tag Color Filter'}
      >
        <div>
          <div className={classes('dropdown-title')}>Filter tags by color</div>
          {colors.map((color: Color) => (
            <div key={color.colorId} className={classes('tag-colors-container')}>
              <div
                className={classes('tag-colors-option', {
                  selected: !!selectedColors[color.colorId],
                })}
                style={{ backgroundColor: color.colorValue.replace('0x', '#') }}
                key={color.colorId}
                onClick={() => selectColor(color)}
                data-test-id={color.colorName}
              />
            </div>
          ))}
          <div className={classes('filter-actions')}>
            <button
              className={classes('filter-button')}
              onClick={saveColorFilter}
              data-test-id="open color filter"
            >
              Filter
            </button>
          </div>
        </div>
      </Dropdown>
    </div>
  );
  // }
}

export default TagColorFilter;
