import React from 'react';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertNurseHeader');

const DATE_TIME_FORMAT = 'MM/dd/yy p';

export type AlertNurseHeaderProps = {
  component: AlertComponent;
  createdAt: string;
};

const AlertNurseHeader = ({ component, createdAt }: AlertNurseHeaderProps) => {
  const { value: text } = component;
  let { bg_color: backgroundColor } = component;

  if (backgroundColor) {
    backgroundColor = backgroundColor.replace('0x', '#');
  }

  return (
    <div className={classes()}>
      <div className={classes('alert-severity')} style={{ backgroundColor }}>
        {text}
      </div>
      <div className={classes('alert-time')}>{format(parseISO(createdAt), DATE_TIME_FORMAT)}</div>
    </div>
  );
};

export default observer(AlertNurseHeader);
