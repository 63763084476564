export default class ArrayOfFailedPhoneNumberError extends Error {
  static CODE = 'failed-phone-numbers-errors';

  text: string;
  code: string;
  status: string;
  arrayOfFailedPhoneNumbers: string[];

  constructor(text: string, status: string, arrayOfFailedPhoneNumbers: string[], message?: string) {
    if (!message) {
      message = `${text} '${status}' failed-phone-numbers-errors`;
    }
    super(message);
    this.code = ArrayOfFailedPhoneNumberError.CODE;
    this.text = text;
    this.status = status;
    this.arrayOfFailedPhoneNumbers = arrayOfFailedPhoneNumbers;
  }
}
