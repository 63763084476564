// @ts-nocheck
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { ROLES_CREATE_P2P_GROUP } = ROUTES;

export default class RolesAPI extends BaseAPI {
  /**
   * POST /group - create a group
   * @param  {string} options.name - Group name
   * @param  {Boolean} options.replayHistory - Replay History
   * @param  {Array<string>} options.memberIds - Member IDs
   * @param  {string} options.createdBy - User ID or Role ID who is creating the group
   * @param  {string} options.organizationId - Organization ID
   * @return {Promise.<Object,Error>} - A promise with an object representing a group
   */
  async createP2PGroup({
    createdByUserId,
    name,
    replayHistory = false,
    memberIds = [],
    organizationId,
  }) {
    const { version = VERSION_TWO } = super.getVersion(ROLES_CREATE_P2P_GROUP);
    const headers = {
      'TT-X-Organization-Key': organizationId,
    };
    const data = {
      created_by: createdByUserId,
      members: memberIds,
      name: name,
      replay_history: replayHistory,
    };

    const res = await this.httpClient.post('/:version/role_group', {
      urlParams: { version },
      headers,
      data,
    });

    const { conversation_id: conversationId, metadata } = res.data;
    const { token } = res.data;

    const group = {
      conversationId,
      memberIds,
      metadata,
      name,
      organizationId,
      token,
    };

    return group;
  }

  @recoverFromNotFound()
  async find(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/role/:id', {
      urlParams: { id },
      headers,
    });

    return res.data;
  }

  async createRole({
    description,
    doesRoleTransitionExcludePrivateGroups,
    escalationPolicy,
    isRoleTransitionEnabled,
    name,
    noOwnersMessage,
    openAssignment,
    organizationId,
    ownerRequired,
    owners,
    replayHistory,
    tagColor,
    tagName,
    tagToken,
  }: {
    description?: string | null | undefined;
    doesRoleTransitionExcludePrivateGroups?: boolean | null | undefined;
    escalationPolicy?: Object | null | undefined;
    isRoleTransitionEnabled?: boolean | null | undefined;
    name: string;
    noOwnersMessage?: string;
    openAssignment: boolean;
    organizationId: string;
    ownerRequired?: boolean | null | undefined;
    owners?: Array<string> | null | undefined;
    replayHistory: string;
    tagColor?: string | null | undefined;
    tagName?: string | null | undefined;
    tagToken?: string | null | undefined;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const data = {
      display_name: name,
      open_assignment: openAssignment ? '1' : '0',
      replay_history: replayHistory,
    };

    if (description !== undefined) data['description'] = description;
    if (doesRoleTransitionExcludePrivateGroups !== undefined)
      data['exclude_private_group'] = doesRoleTransitionExcludePrivateGroups ? '1' : '0';
    if (escalationPolicy !== undefined) data['escalation_policy'] = escalationPolicy;
    if (isRoleTransitionEnabled !== undefined)
      data['role_transition'] = isRoleTransitionEnabled ? '1' : '0';
    if (noOwnersMessage !== undefined) data['no_owners_message'] = noOwnersMessage;
    if (ownerRequired !== undefined) data['owner_required'] = ownerRequired ? '1' : '0';
    if (owners !== undefined) data['owners'] = owners;
    if (tagColor !== undefined) data['tag_color'] = tagColor;
    if (tagName !== undefined) data['tag_name'] = tagName;
    if (tagToken !== undefined) data['tag_id'] = tagToken;

    const res = await this.httpClient.post('/v2/role', {
      headers,
      data,
    });
    res.token = res.headers['tt-x-role-token'];

    return res;
  }

  async updateRole(
    token: string,
    {
      description,
      doesRoleTransitionExcludePrivateGroups,
      escalationPolicy,
      isRoleTransitionEnabled,
      name,
      noOwnersMessage,
      openAssignment,
      organizationId,
      ownerRequired,
      owners,
      replayHistory,
      tagColor,
      tagName,
      tagToken,
    }: {
      description?: string | null | undefined;
      doesRoleTransitionExcludePrivateGroups?: boolean | null | undefined;
      escalationPolicy?: Object | null | undefined;
      isRoleTransitionEnabled?: boolean | null | undefined;
      name?: string | null | undefined;
      noOwnersMessage?: string;
      openAssignment?: boolean | null | undefined;
      organizationId: string;
      ownerRequired?: boolean | null | undefined;
      owners?: Array<string> | null | undefined;
      replayHistory?: string | null | undefined;
      tagColor?: string | null | undefined;
      tagName?: string | null | undefined;
      tagToken?: string | null | undefined;
    }
  ) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const data = {};

    if (description !== undefined) data['description'] = description;
    if ('boolean' === typeof isRoleTransitionEnabled) {
      data['role_transition'] = isRoleTransitionEnabled ? '1' : '0';
    }

    if (
      doesRoleTransitionExcludePrivateGroups !== undefined &&
      doesRoleTransitionExcludePrivateGroups !== null
    ) {
      data['exclude_private_group'] = doesRoleTransitionExcludePrivateGroups ? '1' : '0';
    }

    if (name !== undefined) data['display_name'] = name;
    if (noOwnersMessage !== undefined) data['no_owners_message'] = noOwnersMessage;
    if (escalationPolicy !== undefined) data['escalation_policy'] = escalationPolicy;
    if (openAssignment !== undefined) data['open_assignment'] = openAssignment ? '1' : '0';
    if (ownerRequired !== undefined) data['owner_required'] = ownerRequired ? '1' : '0';
    if (owners !== undefined) data['owners'] = owners;
    if (replayHistory !== undefined) data['replay_history'] = replayHistory;
    if (tagColor !== undefined) data['tag_color'] = tagColor;
    if (tagToken !== undefined) data['tag_id'] = tagToken;
    if (tagName !== undefined) data['tag_name'] = tagName;

    const res = await this.httpClient.put('/v2/role/:token', {
      urlParams: { token },
      headers,
      data,
    });
    res.token = token;

    return res;
  }

  async deleteRole(organizationId: string, token: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del('/v2/role/:token', {
      urlParams: { token },
      headers,
    });
  }

  @recoverFromNotFound()
  async findSavedRoles(userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v2/user/:userId/saved_roles', {
      urlParams: { userId },
      headers,
    });

    return res.data;
  }

  async saveRole(roleId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.post('/v2/user/:userId/saved_roles', {
      urlParams: { userId },
      data: {
        role_id: roleId,
      },
      headers,
    });
  }

  async removeSavedRole(roleId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del('/v2/user/:userId/saved_roles/:roleId', {
      urlParams: { userId, roleId },
      headers,
    });
  }

  async optIn(roleId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.post('/v2/role/:roleId/members/:userId', {
      urlParams: { roleId, userId },
      headers,
    });
  }

  async optOut(roleId: string, userId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del('/v2/role/:roleId/members/:userId', {
      urlParams: { roleId, userId },
      headers,
    });
  }

  async saveAwayResponse(roleId: string, organizationId: string, status: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.post('/v2/role/:roleId/away', {
      urlParams: { roleId },
      headers,
      data: {
        status,
      },
    });
  }

  async removeAwayResponse(roleId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    return await this.httpClient.del('/v2/role/:roleId/away', {
      urlParams: { roleId },
      headers,
    });
  }
}
