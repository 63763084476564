// @ts-nocheck
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { ENTITIES_FIND_ALL } = ROUTES;

export default class EntitiesAPI extends BaseAPI {
  @recoverFromNotFound()
  async findMulti({
    users,
    metadata,
    organizationId,
  }: {
    users: Array<string>;
    metadata: Array<string>;
    organizationId: string;
  }) {
    const { version = VERSION_TWO } = super.getVersion(ENTITIES_FIND_ALL);
    const headers = { 'TT-X-Organization-Key': organizationId };
    const query = { render_owner_object: 'true' };
    const res = await this.httpClient.post('/:version/entities_bulk', {
      urlParams: { version },
      data: {
        users: users,
        metadata: metadata,
      },
      headers,
      query,
    });

    return res.data;
  }
}
