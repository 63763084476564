// @ts-nocheck
import FeatureService from '../models/enums/FeatureService';
import GroupType from './enums/GroupType';
import Networks from './enums/Networks';

export default function (store, { host }) {
  const Conversation = store.defineModel('conversation', {
    instanceEvents: true,

    transientAttrs: [
      '_counterPartyAddedOnServer',
      '_origIndex',
      'highestSortNumber',
      'unreadCount',
      'unreadIds',
      'unreadPriorityCount',
    ],

    defaultValues: {
      _markingAsRead: false,
      _markingAsReadSortNumber: null,
      _markingAsReadExpiration: null,
      featureService: FeatureService.DEFAULT,
      firstExpiringUnreadMessage: null,
      firstUnreadMessage: null,
      higherContinuation: null,
      highestSortNumber: 0,
      inProgressEscalationCount: 0,
      isLive: false,
      lastBangMessageSortNumber: 0,
      lastIncomingMessageSortNumber: 0,
      lastMessage: null,
      lowerContinuation: null,
      markableAsReadMessages: null,
      messages: null,
      muted: null,
      network: Networks.PROVIDER,
      shouldDisplay: false,
      timeline: null,
      unreadCount: 0,
      unreadIds: [],
      unreadMentionMessages: 0,
      unreadPriorityCount: 0,
    },

    skipCamelizationForKeys: ['metadata', 'muted'],

    parseAttrs(attrs) {
      if ('muted' in attrs && attrs.muted) {
        const muteSettings = attrs.muted;
        if (Object.keys(muteSettings).length > 0) {
          let { mute_duration: durationInMinutes, mute_start: startedAt } = muteSettings;

          if (startedAt) {
            startedAt = +startedAt * 1000;
          }
          const muted = host.mute._addEntry(attrs.id, {
            durationInMinutes,
            organizationId: attrs.organizationId,
            startedAt,
          });

          attrs.muted = muted;
        } else {
          attrs.muted = null;
        }
      }

      return attrs;
    },

    afterAssignment(entity) {
      let {
        counterParty,
        counterPartyId,
        counterPartyType,
        id,
        isDeletable,
        isMuteable,
        metadata,
        muted,
        shouldDisplay,
      } = entity;

      if (!store.host.config.condensedReplays) {
        muted = host.mute._getByConversationHandle(id);
      } else if (!muted) {
        muted = host.mute.getById(id);
      }

      entity.muted = muted;
      entity.isMuted = !!entity.muted;

      if (counterParty) {
        counterPartyId = counterParty.id;
        counterPartyType = counterParty.$entityType;
      } else {
        counterParty =
          counterPartyType && counterPartyId
            ? store.store.get(counterPartyType, counterPartyId)
            : null;
      }

      entity.counterParty = counterParty;
      entity.counterPartyId = counterPartyId;
      entity.counterPartyType = counterPartyType;

      if (counterPartyType !== 'group') {
        if (!counterParty || counterParty.$placeholder || counterParty.isAlertsUser) {
          shouldDisplay = false;
        }
      }

      entity.shouldDisplay = shouldDisplay;

      host.conversations._setAllowedSenders(entity);

      isDeletable = true;
      isMuteable = counterPartyType !== 'distributionList';
      if (counterParty && counterPartyType === 'group') {
        const { groupType, p2pSender } = counterParty;
        if (groupType === GroupType.ROLE_P2P) {
          const onDuty = p2pSender && p2pSender.$entityType === 'role';
          isDeletable = entity.allowedSenders.length > 0 && !onDuty;
          isMuteable = isMuteable && entity.allowedSenders.length > 0;
        } else if (groupType === GroupType.ESCALATION) {
          isDeletable = false;
          isMuteable = true;
        } else if (groupType === GroupType.PATIENT_MESSAGING) {
          isDeletable = entity.counterParty.memberCount > 2;
        }
      } else if (counterParty && counterPartyType === 'team') {
        isDeletable = false;
        isMuteable = false;
      }

      entity.isDeletable = isDeletable;
      entity.isMuteable = isMuteable;
      if (!entity.markableAsReadMessages) entity.markableAsReadMessages = [];
      if (!entity.messages) entity.messages = [];
      if (!entity.timeline) entity.timeline = [];
      if (!entity.higherContinuation) {
        entity.higherContinuation = {
          continuation: store.host.config.condensedReplays
            ? { higher_continuation: null }
            : 'HIGHER',
          itemsEstimate: null,
        };
      }
      if (!entity.lowerContinuation) {
        entity.lowerContinuation = {
          continuation: store.host.config.condensedReplays ? { lower_continuation: null } : 'LOWER',
          itemsEstimate: null,
        };
      }
      if (metadata && metadata['feature_service'] === 'patient_messaging') {
        entity.network = Networks.PATIENT;
        entity.featureService = FeatureService.PATIENT_MESSAGING;

        if (counterPartyType === 'group') {
          entity.patientId = `patient:${metadata['patient_id']}`;
          if (metadata['patient_contact_id']) {
            entity.patientContactId = `patientContact:${metadata['patient_contact_id']}`;
          }
        }
      }

      if (metadata && metadata['feature_service'] === 'vwr') {
        entity.network = Networks.PATIENT;
        entity.featureService = FeatureService.VIRTUAL_WAITING_ROOM;
      }

      if (metadata && metadata['feature_service'] === 'alerts') {
        entity.shouldDisplay = false;
        entity.featureService = FeatureService.ALERTS;
      }

      if (metadata && metadata['feature_service'] === 'role_alerts') {
        entity.shouldDisplay = false;
        entity.featureService = FeatureService.ROLE_ALERTS;
      }

      if (metadata && metadata['feature_service'] === 'group_alerts') {
        entity.shouldDisplay = metadata?.meta_type !== 'workflow_group';
        entity.featureService = FeatureService.GROUP_ALERTS;
      }
    },

    relations: {
      belongsTo: {
        organization: { type: 'organization', foreignKey: 'organizationId' },
      },
      hasMany: {
        content: {
          type: 'message',
          foreignKey: 'conversationId',
          sortBy: [
            ['sortNumber', 'asc'],
            ['id', 'asc'],
          ],
        },
      },
    },
  });

  return Conversation;
}
