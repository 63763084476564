import EventEmitter from 'events';
import { action, observable, runInAction } from 'mobx';
import QRCode from 'qrcode';
import queue from 'emitter-queue';

const QR_IMAGE_TIMEOUT = 28000;

export default class UserStore {
  @observable hideDnd = false;
  @observable isMobileQRLoginOpen = false;
  @observable loadingAutoForward = false;
  @observable loadingUserDisplayName = false;
  @observable loadingUserDndText = false;
  @observable loadingUserStatus = false;
  @observable qrImage = null;
  @observable showDndUndoButton = false;
  @observable.shallow autoForwardOrganizations = [];
  events = queue(new EventEmitter());
  qrTimer = null;
  undoNotificationTimeout = null;
  isFetching = new Map();

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  @action('UserStore.isUserDndAfEnabledForCurrentOrg')
  isUserDndAfEnabledForCurrentOrg = (user) => {
    const { messengerStore } = this.stores;
    const isAutoForwardEnabled = messengerStore?.isExtendedAutoForwardOptionsEnabled
      ? user?.profileByOrganizationId &&
        user?.profileByOrganizationId[messengerStore?.currentOrganizationId]?.dndAutoForwardEntities
          ?.length > 0
      : user?.profileByOrganizationId &&
        user?.profileByOrganizationId[messengerStore?.currentOrganizationId]?.autoForwardReceiverIds
          ?.length > 0;
    return user && messengerStore?.currentOrganizationId && isAutoForwardEnabled;
  };

  @action('UserStore.findUser') findUser = async (userId, organizationId, ignoreNotFound) => {
    const fetchKey = `${userId}-${organizationId}`;

    if (this.isFetching.has(fetchKey)) {
      return this.isFetching.get(fetchKey);
    }

    const promise = (async () => {
      try {
        const user = await this.client.users.find(userId, { ignoreNotFound, organizationId });
        const syncedUser = this.entityStore.syncOne(user);
        return syncedUser;
      } catch (e) {
        console.error(e);
      } finally {
        this.isFetching.delete(fetchKey);
      }
    })();

    this.isFetching.set(fetchKey, promise);
    return promise;
  };

  @action('UserStore.getUserById') getUserById = (userId: string) => {
    return this.entityStore.user.getById(userId);
  };

  @action('UserStore.findMyProfilesForAllOrganizations')
  findMyProfilesForAllOrganizations = async () => {
    await this.client.users.findMyProfilesForAllOrganizations();
  };

  @action('UserStore.update') update = async ({
    avatarFile,
    displayName,
    dnd,
    dndText,
    dndExpireAt,
    firstName,
    incomingCallNotification,
    incomingCallSound,
    lastName,
    loadingComponent = false,
    removeAvatar,
    showSuccess = true,
    status,
  }) => {
    const { modalStore } = this.stores;
    let user;
    try {
      if (loadingComponent === 'loadingUserDisplayName') this.loadingUserDisplayName = true;
      if (loadingComponent === 'loadingUserDndText') this.loadingUserDndText = true;
      if (loadingComponent === 'loadingUserStatus') this.loadingUserStatus = true;
      if (dnd) this.setHideDnd(false);
      user = await this.client.users.update({
        avatarFile,
        displayName,
        dnd,
        dndText,
        dndExpireAt,
        firstName,
        incomingCallNotification,
        incomingCallSound,
        lastName,
        removeAvatar,
        status,
      });
      if (showSuccess) modalStore.openModal('success');
    } catch (err) {
      modalStore.openModal('failure');
    }
    runInAction(() => {
      if (loadingComponent === 'loadingUserDisplayName') this.loadingUserDisplayName = false;
      if (loadingComponent === 'loadingUserDndText') this.loadingUserDndText = false;
      if (loadingComponent === 'loadingUserStatus') this.loadingUserStatus = false;
    });
    return user;
  };

  @action('UserStore.updatePassword') updatePassword = async (currentPassword, newPassword) => {
    const { modalStore } = this.stores;

    try {
      await this.client.users.updatePassword(currentPassword, newPassword);
      modalStore.openModal('success');
      return true;
    } catch (err) {
      if (err.code === 'wrong-credentials') {
        modalStore.openModal('wrongPasswordModal');
      } else {
        modalStore.openModal('failure');
      }
      return false;
    }
  };

  @action('UserStore.setAwayMessage') setAwayMessage = async (organizationId, { dnd, dndText }) => {
    const { modalStore } = this.stores;

    try {
      await this.client.users.setAwayMessage(organizationId, { dnd, dndText });
      modalStore.openModal('success');
    } catch (err) {
      console.error(err);
      modalStore.openModal('failure');
    }
  };

  @action('UserStore.setAutoForward') setAutoForward = async (
    organization,
    receiverId,
    entitiesIds
  ) => {
    const { modalStore } = this.stores;
    const isExtendedAutoForwardOptionsEnabled = organization.dndAutoForwardEntities;

    this.loadingAutoForward = true;
    try {
      await this.client.users.setAutoForward(organization.id, receiverId, entitiesIds);
      await this.loadAutoForwardOrganizations();

      if (isExtendedAutoForwardOptionsEnabled) {
        this._sendAutoForwardPendoEvent(entitiesIds);
      }
      runInAction(() => {
        modalStore.openModal('success');
      });
    } catch (err) {
      modalStore.openModal('failure');
    }
    runInAction(() => {
      this.loadingAutoForward = false;
    });
  };

  _sendAutoForwardPendoEvent = (entitiesIds) => {
    const { trackerStore, sessionStore } = this.stores;

    trackerStore.logPendoAnalytics({
      tracker: {
        name: 'Admin + Settings | Settings - Unavailable Auto-Forward Enable',
        props: {
          AutoForwardIndividualCount: entitiesIds.account.length,
          AutoForwardRoleCount: entitiesIds.role.length,
          AutoForwardTeamCount: entitiesIds.team.length,
          AutoForwardGroup:
            entitiesIds.team.length > 0 ||
            entitiesIds.account.length + entitiesIds.role.length + entitiesIds.team.length > 1,
          AutoForwardSelf: entitiesIds.account.includes(sessionStore.currentUserId),
        },
      },
    });
  };

  @action('UserStore.removeAutoForward') removeAutoForward = async (organization) => {
    const { modalStore, trackerStore } = this.stores;
    const isExtendedAutoForwardOptionsEnabled = organization.dndAutoForwardEntities;
    this.loadingAutoForward = true;

    try {
      await this.client.users.removeAutoForward(organization.id);
      await this.loadAutoForwardOrganizations();
      if (isExtendedAutoForwardOptionsEnabled) {
        trackerStore.logPendoAnalytics({
          tracker: {
            name: 'Admin + Settings | Settings - Unavailable Auto-Forward Disable',
          },
        });
      }
      runInAction(() => {
        modalStore.openModal('success');
      });
    } catch (err) {
      modalStore.openModal('failure');
    }
    runInAction(() => {
      this.loadingAutoForward = false;
    });
  };

  @action('UserStore.getAutoForwardEntitiesIdsByOrg') getAutoForwardEntitiesIdsByOrg = (org) => {
    const { entityStore } = this.stores;
    const autoForwardEntitiesIdsArr = [];
    if (!!org?.autoForwardEntitiesIds) {
      const autoForwardEntitiesIdsKeys = Object.keys(org?.autoForwardEntitiesIds);
      autoForwardEntitiesIdsKeys.forEach((key) => {
        org?.autoForwardEntitiesIds[key].forEach((id) => {
          autoForwardEntitiesIdsArr.push(entityStore.getEntityData(key, id));
        });
      });
    }
    return autoForwardEntitiesIdsArr;
  };

  @action('UserStore.getAutoForwardEntityDisplayNameByOrg') getAutoForwardEntityDisplayNameByOrg = (
    org
  ) => {
    const autoForwardEntitiesId = this.getAutoForwardEntitiesIdsByOrg(org);
    return this.getAutoForwardEntityDisplayName(autoForwardEntitiesId);
  };

  @action('UserStore.getAutoForwardEntityDisplayName') getAutoForwardEntityDisplayName = (
    entities
  ) => {
    if (!entities?.length) return;
    return entities?.length > 1 ? 'Multiple recipients' : entities[0]?.displayName;
  };

  @action('UserStore.loadAutoForwardOrganizations')
  loadAutoForwardOrganizations = async () => {
    const { organizationStore } = this.stores;
    const organizations = await organizationStore.findAll();
    runInAction(() => {
      this.autoForwardOrganizations = organizations.filter((org) => !!org.dndAutoForward);
    });
  };

  @action('UserStore.setDndUndoButton') setDndUndoButton = (value) => {
    this.showDndUndoButton = value;
  };

  @action('UserStore.setHideDnd') setHideDnd = (value) => {
    this.hideDnd = value;
  };

  @action('UserStore.undoRemoveDnd') undoRemoveDnd = async () => {
    this.setDndUndoButton(false);
    this.setHideDnd(false);
    runInAction(() => {
      clearTimeout(this.undoNotificationTimeout);
    });
  };

  @action('UserStore.turnOffDndFromRoster') turnOffDndFromRoster = async (organization) => {
    this.setDndUndoButton(true);
    this.setHideDnd(true);
    runInAction(() => {
      this.undoNotificationTimeout = setTimeout(
        async () => {
          await this.update({ dnd: false, showSuccess: false }, false);
          this.setDndUndoButton(false);

          runInAction(async () => {
            await this.removeAutoForward(organization);
          });
        },
        3000,
        organization.id
      );
    });
  };

  @action('UserStore.setEulaAccepted') setEulaAccepted = async (organizationId, accept) => {
    await this.client.users.setEulaAccepted(organizationId, accept);

    this.events.emit('setEulaAccepted', { accept, organizationId });
  };

  @action('UserStore.createQRCode') createQRCode = async () => {
    const qrCode = await this.client.users.createQRCode();
    return qrCode;
  };

  @action('UserStore.openMobileQRLogin') openMobileQRLogin = () => {
    this.isMobileQRLoginOpen = true;
  };

  @action('UserStore.closeMobileQRLogin') closeMobileQRLogin = () => {
    if (this.qrTimer) clearTimeout(this.qrTimer);
    this.isMobileQRLoginOpen = false;
    this.qrImage = null;
  };

  @action('UserStore.generateQRImage') generateQRImage = async () => {
    if (this.qrTimer) clearTimeout(this.qrTimer);

    const qrCode = await this.createQRCode();

    const qrObject = JSON.stringify({
      endpoint: this.client.config.baseUrl,
      qr_code: qrCode,
    });

    const qrImage = await new Promise((resolve, reject) =>
      // the qrcode library doesn't correctly detect promises on IE 11, so we have to
      // pass it a callback argument instead
      QRCode.toDataURL(qrObject, (err, url) => (err ? reject(err) : resolve(url)))
    );

    runInAction(() => {
      this.qrImage = qrImage;
    });

    this.qrTimer = setTimeout(() => {
      runInAction(() => {
        this.qrImage = null;
      });
    }, QR_IMAGE_TIMEOUT);
  };

  @action('UserStore.ensureUsers') ensureUsers = async (userIds, organizationId) => {
    if (!organizationId) {
      organizationId = this.stores.messengerStore.currentOrganizationId;
    }

    const sdkUsers = await this.client.users.ensureUsers(userIds, organizationId);
    return this.entityStore.sync(sdkUsers);
  };
}
