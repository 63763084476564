import { ServerValueEnum } from '../../utils/enum';

const EscalationStatus = new ServerValueEnum({
  ACKNOWLEDGED: { serverValue: 'confirmed' },
  CANCELLED: { serverValue: 'cancelled' },
  IN_PROGRESS: { serverValue: 'in_progress' },
  NO_RESPONSE: { serverValue: 'no_response' },
} as const);

export default EscalationStatus;
