// @ts-nocheck
import EventEmitter from 'events';
import { PermissionDeniedError, WrongCredentialsError } from '../errors';
import EventSource from './EventSourcePolyfill';
import NetworkStatus from './NetworkStatus';

class EventSourceClient extends EventEmitter {
  constructor({ config, httpClient, logger }) {
    super();

    this.config = config;
    this.httpClient = httpClient;
    this.logger = logger;
  }

  createEventSource(
    path: string,
    {
      auth = this.httpClient.getAuth(),
      features,
      onOpen,
      onMessage,
      onError,
    }: {
      features?: string | null | undefined;
      onOpen?: Function | null | undefined;
      onMessage?: Function | null | undefined;
      onError?: Function | null | undefined;
    }
  ) {
    const url = /https?:/.test(path) ? path : this.config.baseUrl + path;

    const headers = this.httpClient.getAuthHeaders(auth, features);
    if (this.config.ttRoute === 'TT-Route-Xmpp') headers['TT-Route-Xmpp'] = 'true';
    if (features) headers['TT-X-Features'] = features;

    const eventSource = new EventSource(url, { headers });

    let hasSignaledError = false;
    let hasSignaledMessage = false;
    let hasSignaledOpen = false;

    const openHandler = () => {
      if (hasSignaledOpen) return;
      hasSignaledOpen = true;
      eventSource.removeEventListener('open', openHandler);

      if (onOpen) onOpen();
    };

    const errorHandler = (event) => {
      if (hasSignaledError) return;
      hasSignaledError = true;
      eventSource.removeEventListener('error', errorHandler);

      const status = event ? event.status : undefined;
      let err;

      if (status === 401) {
        err = new WrongCredentialsError();
        this.emit('auth:invalid');
      } else if (status === 403) {
        const userId = '403';
        const method = 'get';
        err = new PermissionDeniedError(path, userId, method);
        this.emit('auth:invalid');
      } else if (event && event.message && event.stack) {
        err = new Error(event.message);
        err.stack = event.stack;
      } else if (hasSignaledMessage) {
        err = new Error(`EventSource connection closed${status ? ': status ' + status : ''}`);
      } else {
        err = new Error(
          `Could not establish EventSource connection${status ? ': status ' + status : ''}`
        );
      }

      if (status) err.status = status;

      if (this.httpClient.networkStatus !== NetworkStatus.OFFLINE) {
        this.httpClient._setNetworkStatus(NetworkStatus.UNREACHABLE);
      }

      if (onError) onError(err);
    };

    const messageHandler = (event) => {
      const isFirstMessage = !hasSignaledMessage;

      if (!hasSignaledMessage) {
        hasSignaledMessage = true;
        this.httpClient._setNetworkStatus(NetworkStatus.ONLINE);
      }

      if (onMessage) onMessage(event, { isFirstMessage });
    };

    eventSource.addEventListener('open', openHandler);
    eventSource.addEventListener('error', errorHandler);
    eventSource.addEventListener('message', messageHandler);
    eventSource.type = 'sse';

    return eventSource;
  }
}

export default EventSourceClient;
