import { observable, action, runInAction } from 'mobx';
import uuid from 'uuid';

export const IFRAME_STYLE = ['border:0', 'height:100%', 'padding:0', 'width:100%'].join(';');

export default class AdminConsoleStore {
  @observable isIframeLoading = false;

  types = {
    Settings: 'admin',
    Analytics: 'reporting',
    Roles: 'roles',
  };

  constructor({ client, params, stores }) {
    this.client = client;
    this.params = params;
    this.stores = stores;
  }

  connectToCN = async ({ forceLogin = false } = {}) => {
    this.stores.sessionStore.resetAutoLogout();

    // TODO: Admin Console: add console logout time instead of 1000*600
    if (!forceLogin && this.lastLogin + 1000 * 600 > new Date().getTime()) {
      console.log('AdminConsoleStore: skipping connect, already logged in');
      return true;
    }

    const auth = this.client.getAuth();

    this.apiKey = auth.key;
    this.apiSecret = auth.secret;

    const script = document.createElement('script');
    const callbackName = 'jsonp_callback_' + uuid().replace(/-/g, '');

    const jsonpPromise = new Promise((resolve) => {
      window[callbackName] = (data) => {
        delete window[callbackName];

        document.body.removeChild(script);

        this.cnServer = data.cn_server;
        this.sessionId = data.session_id;
        this.drift = data.drift;
        this.accountToken = data.account_token;
        this.lastLogin = new Date().getTime();

        resolve();
      };
    });

    const payload = {
      api_key: auth.key,
      api_secret: auth.secret,
      ts: new Date().getTime(),
    };

    let cnBox = 'admin_cn';
    if (this.client.config.apiEnv === 'ihis') {
      cnBox = 'cn';
    }

    script.src = `${this.client.cnUrl}/${cnBox}/connect?req=${JSON.stringify(
      payload
    )}&callback=${callbackName}`;

    document.body.appendChild(script);

    await jsonpPromise;
  };

  _handleMessageFromIframe = async ({ data: event }) => {
    const { args = [] } = event;
    const { trackerStore } = this.stores;
    if (event && event.type === 'invalid_session') {
      await this.connectToCN({ forceLogin: true });
      this.iframe.contentWindow.postMessage({ type: 'force_reload' }, '*');
    }

    if (event.type === 'TRACKER_EVENT') {
      const [event, data] = args;
      if (typeof trackerStore[event] === 'function') {
        trackerStore[event](data);
      }
    }
  };

  _onLoad = () => {
    if (this.iframe) {
      const {
        allowAar,
        allowAdSync,
        allowDelPhoneNumber,
        allowDisableLink,
        allowExternalBrowser,
        allowMfa,
        allowOTP,
        allowPbxIntegration,
        allowPBXRoles,
        disableTigerTouchSmsTextFeature,
        pfGroupVoiceCall,
        pfVoiceCall,
      } = this.params;
      const { featureFlags = {} } = this.stores.featureStore;
      this.iframe.contentWindow.postMessage(
        {
          clear_cookies: true,
          cn_server: this.cnServer,
          session_id: this.sessionId,
          drift: this.drift,
          features: {
            allowAar: featureFlags.aar || allowAar,
            allowAdSync: featureFlags['allow-ad-sync'] || allowAdSync,
            allowDisableLink: featureFlags['DISABLE_LINK'] || allowDisableLink,
            allowExternalBrowser: featureFlags.EXTERNAL_BROWSER || allowExternalBrowser,
            allowOTP: featureFlags['OTP_LOGIN'] || allowOTP,
            allowPbxIntegration,
            allowPBXRoles: featureFlags['PBX_ROLES'] || allowPBXRoles,
            allowMfa:
              this.client.config.apiEnv !== 'ihis' && featureFlags.MFA
                ? featureFlags.MFA
                : allowMfa,
            allowDelPhoneNumber: featureFlags['allow-del-phone'] || allowDelPhoneNumber,
            disableTigerTouchSmsTextFeature:
              featureFlags['disable-tt-sms-text'] || disableTigerTouchSmsTextFeature,
            pfVoiceCall,
            pfGroupVoiceCall,
            ...featureFlags,
          },
          account_token: this.accountToken,
          api_key: this.apiKey,
          api_secret: this.apiSecret,
          organizationId: this.organizationId,
        },
        '*'
      );
      this.iframe.setAttribute('aria-label', 'iframe loaded');
    }

    this.stores.sessionStore.resetAutoLogout();
  };

  createIframe = (adminType) => {
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('style', IFRAME_STYLE);
    this.iframe.setAttribute(
      'src',
      `${this.client.adminUrl}/${adminType}?apiEnv=${this.client.config.apiEnv}`
    );
    this.iframe.setAttribute('id', uuid());
    this.iframe.setAttribute('aria-label', 'iframe loading');

    this.iframe.onload = this._onLoad;
  };

  appendIframe = (element) => {
    this.element = element;
    this.element.appendChild(this.iframe);
  };

  @action('AdminConsoleStore.updateIframe') updateIframe = (type, organizationId) => {
    const adminType = this.types[type];
    if (!adminType) {
      throw new Error('Please enter a valid type.');
    }

    this.organizationId = organizationId;

    if (this.iframe) {
      this.iframe.setAttribute(
        'src',
        `${this.client.adminUrl}/${adminType}?apiEnv=${this.client.config.apiEnv}`
      );
      this.iframe.setAttribute('aria-label', 'iframe loading');
    }
    this.stores.sessionStore.resetAutoLogout();
  };

  @action('AdminConsoleStore.destroyIframe') destroyIframe = () => {
    const parentNode = this.iframe && this.iframe.parentNode;
    if (parentNode) {
      parentNode.removeChild(this.iframe);
    }
    this.iframe = null;

    window.removeEventListener('message', this._handleMessageFromIframe, false);
  };

  @action('AdminConsoleStore.getIframe') getIframe = async (element, type, organizationId) => {
    if (!element) {
      throw new Error('Please provide a node to append the iframe to.');
    }

    const adminType = this.types[type];
    if (!adminType) {
      throw new Error('Please enter a valid type.');
    }

    this.isIframeLoading = true;
    this.stores.sessionStore.resetAutoLogout();
    window.addEventListener('message', this._handleMessageFromIframe, false);

    try {
      await this.connectToCN();

      this.organizationId = organizationId;

      this.createIframe(adminType);
      this.appendIframe(element);

      runInAction(() => {
        this.isIframeLoading = false;
      });

      return this;
    } catch (err) {
      console.error(err);

      runInAction(() => {
        this.isIframeLoading = false;
      });

      return Promise.reject(err);
    }
  };

  @action('AdminConsoleStore.changeIframeOrg') changeIframeOrg = (organizationId) => {
    if (this.iframe) {
      this.iframe.contentWindow.postMessage({ organizationId }, '*');
    }
    this.stores.sessionStore.resetAutoLogout();
  };
}
