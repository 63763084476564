// @ts-nocheck
import EventEmitter from 'events';
import btoa from 'btoa';
import _ from 'lodash';
import { decorator as reusePromise } from 'reuse-promise';
import request from 'superagent';
import superAgentPrefix from 'superagent-prefix';
import * as errors from '../../errors';
import NetworkStatus from '../NetworkStatus';
import MultiPart from './MultiPart';

export const HTTP_METHODS = ['get', 'post', 'put', 'patch', 'del'] as const;

const UNRECOVERABLE_ERROR_CODES = [
  errors.PermissionDeniedError.CODE,
  errors.WrongCredentialsError.CODE,
];

class HttpClient {
  constructor({ config, logger, sendReport }) {
    this.sendReport = sendReport;
    this.config = config;
    this.logger = logger;
    this._prefixBaseUrl = superAgentPrefix(this.config.baseUrl);
    this._watchNetworkConnection();
  }

  mounted() {
    this._prefixBaseUrl = superAgentPrefix(this.config.baseUrl);
  }

  _contentType(format) {
    switch (format) {
      case 'json':
        return 'application/json';
      case 'event-stream':
        return 'text/event-stream';
      case 'form':
        return 'application/x-www-form-urlencoded; charset=UTF-8';
      case 'text':
      default:
        return 'text/plain';
    }
  }

  getAuth(auth) {
    return this._auth;
  }

  setAuth(auth) {
    this._auth = auth;
  }

  removeAuth() {
    this._auth = null;
  }

  get isSignedIn() {
    return !!this._auth;
  }

  getAuthForKeyAndSecret(key: string, secret: string) {
    if (!key || !secret) return null;
    return btoa(key + ':' + secret);
  }

  getAuthHeaders(auth = this._auth) {
    if (!auth) return {};
    const headers = {};

    if (auth.key && auth.secret) {
      headers['Authorization'] = `Basic ${this.getAuthForKeyAndSecret(auth.key, auth.secret)}`;
    } else if (auth.bearerToken && auth.udid) {
      headers['Authorization'] = `Bearer ${auth.bearerToken}`;
      headers['X-Device-ID'] = auth.udid;
    } else {
      return {};
    }

    return headers;
  }

  isUnauthorizedError(err) {
    if (err?.eventSourceType === 'websocket' && (err.code === 401 || err.code === 403)) return true;
    return err && UNRECOVERABLE_ERROR_CODES.includes(err.code);
  }

  /**
   * downloadFileBlob - downloads a file authenticated, as a Blob
   *
   * @param  {string} path relative path to baseUrl of a file
   * @param  {Object} additionalHeaders is an object with header as the key and header value as the value
   * @return {Blob}      Blob of the file
   */
  downloadFileBlob(path: string, { headers = {} } = {}) {
    if (process.env.NODE_TARGET === 'web') {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const url = this.config.baseUrl + path;
        xhr.open('GET', url, true);
        for (const [header, value] of Object.entries(this.getAuthHeaders())) {
          xhr.setRequestHeader(header, value);
        }

        for (const [header, value] of Object.entries(headers)) {
          xhr.setRequestHeader(header, value);
        }

        xhr.responseType = 'blob';
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status < 200 || xhr.status > 299) {
              reject(new Error(`Failed downloading ${url}. Status = ${xhr.status}`));
            } else {
              resolve(xhr.response);
            }
          }
        };
        xhr.send(null);
      });
    } else {
      errors.TargetNotSupportedError.raise();
    }
  }

  downloadFileBlobAndUrl =
    process.env.NODE_TARGET === 'web'
      ? async (path: string) => {
          const blob = await this.downloadFileBlob(path);

          return {
            blob,
            get url() {
              return this._url || (this._url = URL.createObjectURL(blob));
            },
          };
        }
      : () => errors.TargetNotSupportedError.raise();

  downloadToFile(path, dest) {
    if (process.env.NODE_TARGET === 'node') {
      const fs = require('fs');
      return new Promise((resolve, reject) => {
        const url = this.config.baseUrl + path;
        require('request')(
          {
            url,
            headers: this.getAuthHeaders(),
          },
          (err, res) => {
            if (err || (res && res.statusCode < 200) || res.statusCode > 299) {
              reject(
                new Error(
                  `Failed downloading ${url}. Error: ${err.message} | Status: ${
                    res ? res.statusCode : null
                  }`
                )
              );
            } else {
              resolve();
            }
          }
        ).pipe(fs.createWriteStream(dest));
      });
    } else {
      errors.TargetNotSupportedError.raise();
    }
  }

  _watchNetworkConnection() {
    if (process.env.NODE_TARGET === 'web') {
      this.networkStatus = navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
      window.addEventListener('online', () => {
        if (this.config.eventsAutoReconnect) {
          // if it was offline before, we're still not sure it's reachable.
          // change to 'CONNECTING' and let next http request determine an ONLINE status
          this._setNetworkStatus(NetworkStatus.CONNECTING);
        } else {
          this._setNetworkStatus(NetworkStatus.ONLINE);
        }
      });
      window.addEventListener('offline', () => {
        this._setNetworkStatus(NetworkStatus.OFFLINE);
      });
    } else {
      this.networkStatus = NetworkStatus.ONLINE;
    }
  }

  _setNetworkStatus(newStatus) {
    const previousStatus = this.networkStatus;
    if (newStatus === previousStatus) return;

    this.networkStatus = newStatus;
    this.emit('networkStatus:change', { networkStatus: newStatus, previousStatus });
  }
}

for (const method of HTTP_METHODS) {
  // eslint-disable-next-line no-loop-func
  HttpClient.prototype[method] = async function (path, options = {}) {
    options = _.defaults(options || {}, {
      reqFormat: 'json',
      resFormat: 'json',
      withCredentials: true,
      withHeaders: true,
    });

    if (options.urlParams) {
      path = path.replace(/:([\w-]+)/g, ($0, $1) => {
        const replacement = options.urlParams[$1];
        if (replacement !== undefined) {
          return encodeURIComponent(replacement);
        } else {
          return ':' + $1;
        }
      });
    }

    this.logger && this.logger.info('start', method.toUpperCase(), path, options);

    const headers = {
      ...options.headers,
      ...(this.config.ttRoute === 'TT-Route-Xmpp' ? { 'TT-Route-Xmpp': 'true' } : null),
    };

    headers['Accept'] = headers['Accept'] || this._contentType(options.resFormat);

    if (options.requestId) {
      headers['Request-ID'] = options.requestId;
    }

    if (!('Authorization' in headers)) {
      Object.assign(headers, this.getAuthHeaders(options.auth));
    }

    let req = request[method](path)
      .use(this._prefixBaseUrl)
      .query(options.query || {});
    if (options.withCredentials) {
      req = req.withCredentials();
    }

    if (options.retry !== false) {
      req = req.retry(options.retryCount || 3);
    }
    if (options.timeout !== false) {
      req = req.timeout(options.timeout === true ? 10 * 1000 : options.timeout);
    }

    let multiPart;
    const fileEntries = options.files && Object.entries(options.files);
    if (fileEntries && fileEntries.length > 0) {
      multiPart = new MultiPart();
      headers['Content-Type'] = multiPart.getContentType();
    } else {
      headers['Content-Type'] = headers['Content-Type'] || this._contentType(options.reqFormat);
    }

    if (options.withHeaders) {
      req = req.set(headers);
    }

    if (multiPart) {
      const promises = [];

      if (options.data) {
        for (const [fieldName, fieldValue] of Object.entries(options.data)) {
          promises.push(multiPart.appendField(fieldName, fieldValue));
        }
      }

      for (const [fieldName, file] of fileEntries) {
        promises.push(multiPart.appendFile(fieldName, file));
      }

      await Promise.all(promises);
      const payload = await multiPart.getPayload(multiPart);
      req = req.send(payload);
    } else {
      req = req.send(options.data);
    }

    return new Promise((resolve, reject) => {
      req.end((err, res) => {
        const { headers } = res || {};
        if (err) {
          this.logger && this.logger.info('error', method.toUpperCase(), path, err);
          if (this.config.reportErrors) {
            const response = {
              method: method.toUpperCase(),
              path,
              request,
              status: err.status,
            };
            if (err.response) {
              Object.assign(response, {
                body: err.response.body,
                headers: err.response.headers,
                ...(options.headers
                  ? { organizationId: options.headers['TT-X-Organization-Key'] }
                  : null),
                statusCode: err.response.statusCode,
                statusText: err.response.statusText,
                text: err.response.text,
                url: err.response.req?.url,
              });
            }
            this.sendReport({
              payload: {
                response,
              },
              level: 'warn',
              message: 'HttpClient error',
              organizationId: response?.organizationId,
            });
          }

          if (err.status) {
            // these special status values must also be handled in BaseAPI.recoverFromNotFound
            if (err.status === 401) {
              reject(new errors.WrongCredentialsError(headers));
            } else if (err.status === 403) {
              const { userId = '403' } = options.urlParams || {};
              reject(
                new errors.PermissionDeniedError(
                  path,
                  userId,
                  method,
                  _.get(res, 'body.error.message'),
                  res.body
                )
              );
            } else if (err.status === 404) {
              const { userId = '404' } = options.urlParams || {};
              reject(
                new errors.NotFoundError(path, userId, method, _.get(res, 'body.error.message'))
              );
            } else {
              reject({
                ...err,
                text: res.body?.error?.message,
                status: res.status,
                statusText: res.statusText,
              });
            }
          } else {
            reject(new errors.ConnectionUnavailableError());
          }

          if (__DEV__) {
            if (/Nock: No match/.test(err.message)) {
              throw err;
            }
          }

          return;
        }

        switch (options.resFormat) {
          case 'json':
            {
              let resBody = res.body || {};

              if (typeof resBody === 'object' && 'reply' in resBody) {
                resBody = resBody.reply;
              }

              const data = {
                data: resBody,
                headers,
                getHeader: (key) => headers[key.toLowerCase()],
                status: res.status,
              };

              this.logger && this.logger.info('success', method.toUpperCase(), path, data);

              resolve(data);
            }
            break;
          default:
            if (this.logger) {
              this.logger.info(
                'success',
                method.toUpperCase(),
                path,
                res.text ? res.text.substr(0, 80) + '...' : '[null]'
              );
            }

            resolve(res.text);
            break;
        }
      });
    });
  };
}

// rewrite 'get' to support reusePromise
function addGetToHttpProto() {
  const get = HttpClient.prototype.get;
  const reusePromiseGet = reusePromise(get);

  HttpClient.prototype.get = function (path, options = {}) {
    if (options.reusePromise) {
      return reusePromiseGet.call(this, path, options);
    } else {
      return get.call(this, path, options);
    }
  };
}
addGetToHttpProto();

Object.assign(HttpClient.prototype, EventEmitter.prototype);

export default HttpClient;
