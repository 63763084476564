import React, { useEffect, useRef, useState } from 'react';
import ToolTip from 'rc-tooltip';
import BEM from '../../../bem';

import { useAMContext } from '../../../../contexts/AutomatedMessages';
import { mobxInjectSelect } from '../../../utils';

const classes = BEM.with('AutomatedMessages');

type WorkflowActivationProps = {
  workflowDisabled: boolean;
  workflowId?: string;
  workflowIsActive?: boolean;
};

type MobxProps = {
  openModal: (modalType: string) => void;
};

function WorkflowActivation({
  openModal,
  workflowDisabled,
  workflowId,
  workflowIsActive,
}: WorkflowActivationProps & MobxProps) {
  const { updateWorkflow, loadWorkflows } = useAMContext();
  const [optProgress, setOptProgress] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const timeoutRef = useRef<number>();
  const activateButtonRef = useRef<HTMLDivElement>(null);
  let workflowStatusBody;

  useEffect(() => {
    const currentRef = activateButtonRef.current;
    const focusInHandler = () => {
      setIsTooltipVisible(true);
    };
    const focusOutHandler = () => {
      setIsTooltipVisible(false);
    };
    currentRef?.addEventListener('focusin', focusInHandler);
    currentRef?.addEventListener('focusout', focusOutHandler);

    return () => {
      currentRef?.removeEventListener('focusin', focusInHandler);
      currentRef?.removeEventListener('focusout', focusOutHandler);
    };
  }, []);

  const userHoldingMouseDown = async (currentProgress: number) => {
    const newVal = currentProgress + 1;
    setOptProgress(newVal);
    timeoutRef.current = setTimeout(() => {
      userHoldingMouseDown(newVal);
    }, 60);
    if (newVal >= 20) {
      clearTimeout(timeoutRef.current);
      try {
        const toggleStatus = !workflowIsActive;
        if (workflowId && workflowIsActive !== undefined) {
          await updateWorkflow({ id: workflowId, active: toggleStatus });
        }
        loadWorkflows();
      } catch (err) {
        openModal('failure');
      }
    }
  };

  const handleOptMouseDown = () => {
    userHoldingMouseDown(0);
  };

  const resetOptProgress = () => {
    clearTimeout(timeoutRef.current);
    setOptProgress(0);
  };

  if (workflowIsActive) {
    workflowStatusBody =
      'This workflow is active and will automatically generate messages. Workflows must be inactive to make any changes.';
  } else {
    workflowStatusBody =
      'This workflow is inactive and will not generate any messages. Workflows and rules are editable while inactive.';
  }

  return (
    <div>
      <div className={classes('workflow-status')}>
        <div className={classes('workflow-status-title')}>STATUS</div>
        <progress value={optProgress} max="20" className={classes('progress-bar')} />
        <div
          className={classes('workflow-status-message-button', { showProgress: optProgress > 0 })}
        >
          <div className={classes('workflow-status-message')}> {workflowStatusBody} </div>
          <div
            className={classes('workflow-status-button', {
              hover: isTooltipVisible,
            })}
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
          >
            <ToolTip
              placement="bottom"
              overlay={<span>{`Hold to ${workflowIsActive ? 'Deactivate' : 'Activate'}`}</span>}
              overlayInnerStyle={{
                padding: '6px',
                width: '115px',
                minHeight: '10px',
                textAlign: 'center',
              }}
              visible={isTooltipVisible}
            >
              <div
                className={classes('activate-button', {
                  isDisabled: workflowDisabled,
                })}
                onMouseDown={handleOptMouseDown}
                onMouseUp={resetOptProgress}
                role="button"
                aria-label={`${workflowIsActive ? 'Deactivate' : 'Activate'} Workflow`}
                tabIndex={0}
                ref={activateButtonRef}
                onKeyDown={(e) => {
                  if ((e.key === 'Enter' || e.key === ' ') && optProgress === 0) {
                    handleOptMouseDown();
                  }
                }}
                onKeyUp={resetOptProgress}
              >
                {workflowIsActive ? 'Deactivate' : 'Activate'}
              </div>
            </ToolTip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default mobxInjectSelect<WorkflowActivationProps, MobxProps>({
  modalStore: ['openModal'],
})(WorkflowActivation);
