import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import Modal from './Modal';

const classes = BEM.with('RemovePatientBroadcastListMemberModal');

const RemovePatientBroadcastListMember = ({
  closeModal,
  isOpen,
  openModal,
  options,
  removeBroadcastListMembers,
}) => {
  const [isInProgress, setIsInProgress] = useState(false);
  const { id, members, network = 'patient', resetCurrentMembers } = options;

  const _removeSelected = async () => {
    setIsInProgress(true);
    try {
      await removeBroadcastListMembers({ id, members });

      // ES data is not updated immediately
      setTimeout(() => {
        resetCurrentMembers();
        setIsInProgress(false);
        closeModal();
      }, 1000);
    } catch (err) {
      console.error(err);
      setIsInProgress(false);
      openModal('patientAdminFailure', {
        reopenModal: 'removePatientBroadcastListMember',
        optionsReopenModal: { id, members, resetCurrentMembers },
        body: 'Unable to update broadcast list. Please try again.',
      });
    }
  };

  return (
    <Modal
      bodyClass={classes('body')}
      closeClass={classes('close-button')}
      footerClass={classes('footer')}
      header={'Confirm Remove'}
      headerClass={classes('header')}
      isOpen={isOpen}
      size={'medium'}
      footerPrimaryActions={
        <button
          className={classes('remove-btn')}
          disabled={isInProgress}
          onClick={_removeSelected}
          type="button"
        >
          {isInProgress ? 'REMOVING' : `REMOVE ${members.length > 1 ? `(${members.length})` : ''}`}
        </button>
      }
      footerSecondaryActions={
        <button type="button" onClick={closeModal} className={classes('cancel-btn')}>
          CANCEL
        </button>
      }
      className={classes()}
      onRequestClose={closeModal}
    >
      <div className={classes('info')}>
        {`Are you sure you want to remove ${
          network === 'provider' ? 'user(s)/role(s)' : 'patient(s)/contact(s)'
        } from this list?`}
      </div>
    </Modal>
  );
};

RemovePatientBroadcastListMember.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  removeBroadcastListMembers: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  modalStore: ['openModal'],
  patientAdminStore: ['removeBroadcastListMembers'],
})(RemovePatientBroadcastListMember);
