import React from 'react';
import styled from 'styled-components';
import Timestamp from './Timestamp';

const BangTextContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 11px 0 !important;
  color: #979797;
  font-size: 12px !important;

  &:focus-visible {
    outline: 3px solid var(--secondary-500);
  }
`;

// false positive
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type BubbleContainerProps = {
  isBubble: boolean;
};
const BubbleContainer = styled.span<BubbleContainerProps>`
  padding: ${(props) => props.isBubble && '4px 32px 2px !important'};
  border-radius: ${(props) => props.isBubble && '10px'};
  background-color: ${(props) => props.isBubble && '#f4f4f4'};
`;

// false positive
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TextContainerProps = {
  colorType: 'normal' | 'alert' | '';
};
const TextContainer = styled.span<TextContainerProps>`
  color: ${(props) =>
    props.colorType === 'normal' ? '#61798d' : props.colorType === 'alert' && '#e41f1f'} !important;
  font-weight: ${(props) => props.colorType !== '' && 500} !important;
`;

type VirtualWaitingRoomBangTextProps = {
  text: string;
  createdAt?: Date;
  bodyStyle?: 'normal' | 'alert' | '';
};

const VirtualWaitingRoomBangText: React.FC<VirtualWaitingRoomBangTextProps> = ({
  text,
  createdAt,
  bodyStyle = '',
}) => (
  <BangTextContainer className="VWR-bang-text">
    <BubbleContainer isBubble={!!bodyStyle}>
      <TextContainer colorType={bodyStyle}>{text}</TextContainer>
      {createdAt && (
        <span>
          {' at '}
          <Timestamp value={createdAt} />
        </span>
      )}
    </BubbleContainer>
  </BangTextContainer>
);

export default VirtualWaitingRoomBangText;
