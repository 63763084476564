// @ts-nocheck
import { CONTACTS_ORG_ID } from './Organization';

export default function (store, options) {
  const UserRoles = store.defineModel('userRoles', {
    defaultValues: {
      roles: [],
    },

    afterAssignment(entity) {
      const isContacts = entity.organizationId === CONTACTS_ORG_ID;
      const roles = entity.roles;

      entity.isAdmin = roles.includes('admin');
      entity.isHelpDesk = roles.includes('help_desk');
      entity.isPatientAdmin = roles.includes('patient_messaging_admin');
      entity.isRoleAdmin = roles.includes('feature_role_admin');
      entity.isTeamAdmin = roles.includes('team_admin');
      entity.isProviderBroadcastListAdmin = roles.includes('provider_broadcast_list_admin');
      entity.isVirtualWaitingRoomAdmin = roles.includes('virtual_waiting_room_admin');
      entity.patientDataAuditAdmin = roles.includes('patient_data_audit_admin');
      entity.isScimAdmin = roles.includes('scim_integration_admin');
      entity.patientContextReporting = roles.includes('patient_context_reporting');
      entity.reportingEnabled = roles.includes('reporting') && !isContacts;
      entity.reportingEnabledOnAllOrgs = roles.includes('reporting_view_orgs') && !isContacts;
      entity.tigerTouchPlusReportingEnabled = roles.includes('tigertouchplus_reporting');
      entity.patientDataReportAnalyticsEnabled = roles.includes('patient_data_report_analytics');
    },

    methods: {
      hasRole(role) {
        return this.roles.includes(role);
      },
    },

    relations: {
      belongsTo: {
        organization: { type: 'organization', foreignKey: 'organizationId' },
        user: { type: 'user', foreignKey: 'userId' },
      },
    },
  });

  return UserRoles;
}
