// @ts-nocheck
export default function (store, { host }) {
  const Workflow = store.defineModel('workflow', {
    replaceKeys: {
      active: 'isActive',
      numOfEvents: 'eventsCount',
      workflowId: 'id',
    },

    transientAttrs: ['$deleted'],

    defaultValues: {},

    parseAttrs(attrs) {
      return attrs;
    },
  });

  return Workflow;
}
