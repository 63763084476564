// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class MessageTemplatesAPI extends BaseAPI {
  async batch({
    ids,
    operation,
    organizationId,
  }: {
    ids: string[];
    operation: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.patch('/v5/message_template', {
      data: {
        ids,
        operation,
      },
      headers,
    });
  }

  async create({
    attachment,
    body,
    isSmsCompatible,
    network,
    organizationId,
    repository,
    title,
  }: {
    attachment?: (Object | null | undefined) | (string | null | undefined);
    body: string;
    isSmsCompatible: boolean;
    network: string;
    organizationId: string;
    repository: string;
    title: string;
  }) {
    const files = {};
    const headers = { 'TT-X-Organization-Key': organizationId };

    if (attachment) files['attachment'] = attachment;

    const { data } = await this.httpClient.post('/v5/message_template', {
      files,
      data: {
        body,
        is_sms_compatible: isSmsCompatible,
        network,
        repository,
        template_label: title,
      },
      headers,
      timeout: !attachment,
    });

    return data;
  }

  @recoverFromNotFound()
  async delete(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.del('/v5/message_template/:id', {
      headers,
      urlParams: {
        id,
      },
    });
  }

  @recoverFromNotFound()
  async downloadTemplateFile({ templateId, fileName, organizationId }) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const path = `/v5/message_template/${templateId}/attachment/${fileName}`;
    return this.httpClient.downloadFileBlob(path, { headers });
  }

  @recoverFromNotFound()
  async find(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.get('/v5/message_template/:id', {
      headers,
      urlParams: {
        id,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async update({
    attachment,
    body,
    id,
    isSmsCompatible,
    organizationId,
    repository,
    title,
  }: {
    attachment?:
      | (null | null | undefined)
      | (Object | null | undefined)
      | (string | null | undefined);
    body: string;
    id: string;
    isSmsCompatible: boolean;
    organizationId: string;
    repository: string;
    title: string;
  }) {
    const files = {};
    const headers = { 'TT-X-Organization-Key': organizationId };

    if (attachment) files['attachment'] = attachment;

    const { data } = await this.httpClient.put('/v5/message_template/:id', {
      files,
      data: {
        ...(files['attachment'] ? null : { attachment, is_sms_compatible: isSmsCompatible }),
        body,
        repository,
        template_label: title,
      },
      headers,
      timeout: !attachment,
      urlParams: {
        id,
      },
    });

    return data;
  }
}
