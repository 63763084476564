// @ts-nocheck
import uuid from 'uuid';
import { isEmail } from '../utils/email';
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_FIVE, VERSION_SIX, VERSION_THREE, VERSION_TWO } = VERSIONS;
const { MESSAGE_FIND, MESSAGE_FORWARD, MESSAGE_SEND, MESSAGE_UPDATE_RECIPIENT_STATUS_MULTI } =
  ROUTES;

export default class MessagesAPI extends BaseAPI {
  async send({
    attachment,
    body,
    conversationId,
    counterPartyId,
    deleteOnRead,
    escalate,
    localId,
    metadata,
    priority,
    recipientOrganizationId,
    senderOrganizationId,
    ttl,
    subType,
  }: {
    attachment?: (Object | null | undefined) | (string | null | undefined);
    body: string;
    conversationId?: string | null | undefined;
    counterPartyId?: string | null | undefined;
    deleteOnRead?: boolean | null | undefined;
    escalate?: boolean | null | undefined;
    localId: string;
    metadata?: Object | null | undefined;
    priority?: number | null | undefined;
    recipientOrganizationId?: string | null | undefined;
    senderOrganizationId: string;
    ttl?: number | null | undefined;
    subType?: string | null | undefined;
  }) {
    const { version = VERSION_TWO } = super.getVersion(MESSAGE_SEND);

    const params = {
      body: body,
    };
    const query = {};
    const files = {};
    let pfmaVersion;

    if (version === VERSION_TWO) {
      if (isEmail(counterPartyId)) {
        counterPartyId = counterPartyId.toLowerCase();
      }
      params.recipient = counterPartyId;
      query.response_format = 'message';

      if (!recipientOrganizationId) recipientOrganizationId = senderOrganizationId;
      if (senderOrganizationId) params['sender_organization'] = senderOrganizationId;
      if (recipientOrganizationId) params['recipient_organization'] = recipientOrganizationId;
    } else if (version === VERSION_FIVE) {
      params.conversation_id = conversationId;
    }

    if (metadata) {
      params['data'] = metadata.map((item) => ({
        ...item,
        payload: JSON.stringify(item.payload),
      }));
    }
    if (attachment) files['attachment'] = attachment;
    if (deleteOnRead) params['dor'] = deleteOnRead;
    if (escalate) params['escalate'] = escalate;
    if (typeof priority !== 'undefined') params['priority'] = priority;
    if (ttl) params['ttl'] = ttl;
    if (subType && subType === 'vwr_call') params['sub_type'] = subType;
    if (this.host.config.product === 'pfma') {
      if (version === VERSION_TWO) pfmaVersion = VERSION_THREE;
      if (version === VERSION_FIVE) pfmaVersion = VERSION_SIX;
    }

    try {
      const res = await this.httpClient.post('/:version/message', {
        urlParams: { version: pfmaVersion || version },
        data: params,
        files,
        headers: {
          ...(subType && subType === 'vwr_call' ? { 'tt-x-features': 'vwr' } : {}),
        },
        query,
        requestId: localId,
        timeout: !attachment,
      });

      /*
      Below is a fix for a race condition where the outgoing message won't render on the sender's side.
      This happens because we render that message based on the last_message data received from the response above,
      and in some cases where two users send a message at the exact same time, the last_message could actually be
      the incoming message
      */
      const outgoingMessageId = res.headers['tt-x-message-id'];
      const lastMessageIsNotSentMessage =
        this.config.condensedReplays &&
        res.data.last_message &&
        outgoingMessageId !== res.data.last_message.message_id;
      if (lastMessageIsNotSentMessage) {
        const resData = await this.host.api.messages.find(outgoingMessageId);
        if (resData) {
          res.data.last_message = resData.message;
        }
      }
      return res.data;
    } catch (err) {
      if (err.status !== 304 && err.status !== 400) return Promise.reject(err);
      const id = err.response.headers['TT-X-Message-Id'.toLowerCase()];
      if (!id) return Promise.reject(err);
      return { message_id: id };
    }
  }

  async destroy(id: string) {
    try {
      await this.httpClient.del('/v2/message/:id', { urlParams: { id } });
      return true;
    } catch (e) {
      return false;
    }
  }

  async forward(
    id: string,
    counterPartyId?: string | null | undefined,
    {
      conversationId,
      localId,
      metadata,
      priority,
      recipientOrganizationId,
      senderOrganizationId,
    }: {
      conversationId?: string | null | undefined;
      localId: string;
      metadata?: Object | null | undefined;
      priority?: number | null | undefined;
      recipientOrganizationId?: string | null | undefined;
      senderOrganizationId?: string | null | undefined;
    }
  ) {
    const { version = VERSION_TWO } = super.getVersion(MESSAGE_FORWARD);
    const params = {};

    if (version === VERSION_TWO) {
      params.recipient = counterPartyId;

      if (!recipientOrganizationId) recipientOrganizationId = senderOrganizationId;
      if (senderOrganizationId) params['sender_organization'] = senderOrganizationId;
      if (recipientOrganizationId) params['recipient_organization'] = recipientOrganizationId;
    } else if (version === VERSION_FIVE) {
      params.conversation_id = conversationId;
    }

    if (metadata) {
      params['data'] = metadata.map((item) => ({
        ...item,
        payload: JSON.stringify(item.payload),
      }));
    }
    if (typeof priority !== 'undefined') params['priority'] = priority;

    const res = await this.httpClient.post('/:version/message/:id/forward', {
      urlParams: { id, version },
      data: params,
      requestId: localId,
    });

    if (version === VERSION_TWO) {
      const message = {
        message_id: res.getHeader('TT-X-Message-Id'),
      };

      return message;
    } else {
      return res.data;
    }
  }

  @recoverFromNotFound()
  async find(id: string) {
    const { version = VERSION_TWO } = super.getVersion(MESSAGE_FIND);

    const res = await this.httpClient.get('/:version/message/:id', {
      urlParams: {
        id,
        version,
      },
    });

    return res.data;
  }

  @recoverFromNotFound()
  async findRecipientStatus(id: string) {
    // v5 is not implemented by server yet
    //
    // const { version=VERSION_TWO } = super.getVersion(MESSAGE_FIND_RECIPIENT_STATUS)

    const res = await this.httpClient.get('/:version/message/:id/status', {
      urlParams: { id, version: VERSION_TWO },
    });

    return res.data;
  }

  async findRecipientStatusMulti(ids: string[]) {
    // v5 is not implemented by server yet
    //
    // const { version=VERSION_TWO } = super.getVersion(MESSAGE_FIND_RECIPIENT_STATUS_MULTI)

    const res = await this.httpClient.post('/:version/get_bulk_message_status', {
      urlParams: { version: VERSION_TWO },
      data: { messages: ids },
    });

    return res.data['bulk_message_statuses'];
  }

  spliceIntoChunks(arr, chunkSize) {
    const res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  }

  async updateRecipientStatusMulti(changes) {
    if (this.host.events.eventSourceIsWebSocket) {
      if (!changes) return;

      const deliveredLength = changes.delivered ? changes.delivered.length : 0;
      const readLength = changes.read ? changes.read.length : 0;
      const changesBatch = this.config.maxRecipientStatusMultiBatch;

      if (deliveredLength + readLength > changesBatch) {
        const deliveredLines =
          changes.delivered && this.spliceIntoChunks(changes.delivered, changesBatch);
        const readLines = changes.read && this.spliceIntoChunks(changes.read, changesBatch);

        if (deliveredLines) {
          deliveredLines.forEach((deliveredLine) => {
            this.host.events.send('v1_message_status', {
              commandId: uuid(),
              notifyCompletion: true,
              status: {
                Delivered: deliveredLine,
              },
            });
          });
        }

        if (readLines) {
          readLines.forEach((readLine) => {
            this.host.events.send('v1_message_status', {
              commandId: uuid(),
              notifyCompletion: true,
              status: {
                Read: readLine,
              },
            });
          });
        }
      } else {
        this.host.events.send('v1_message_status', {
          commandId: uuid(),
          notifyCompletion: true,
          status: {
            Read: readLength ? changes.read : [],
            Delivered: deliveredLength ? changes.delivered : [],
          },
        });
      }

      return;
    }

    const { version = VERSION_TWO } = super.getVersion(MESSAGE_UPDATE_RECIPIENT_STATUS_MULTI);

    const res = await this.httpClient.post('/:version/message/status', {
      urlParams: { version },
      data: changes,
    });

    return res.data;
  }

  async createAttachmentTokens(count? = 20) {
    const res = await this.httpClient.post('/v5/attachment_tokens_bulk', {
      data: {
        count,
        expire_after_sec: 43200,
        expire_after_used_sec: 5,
      },
    });

    return res.data;
  }

  async getReactions({
    messageIds,
    counterPartyId,
    counterPartyType,
    orgId,
    sortReactionsByCountAndCreatedAt,
  }: {
    counterPartyId: string;
    counterPartyType: string;
    messageIds: string[];
    orgId: string;
    sortReactionsByCountAndCreatedAt: boolean;
  }) {
    const res = await this.httpClient.post(`${this.host.webInfraUrl}/v1/reactions/messages`, {
      data: {
        apiEnv: this.config.apiEnv,
        counterPartyId,
        counterPartyType,
        messageIds,
        sortReactionsByCountAndCreatedAt,
      },
      headers: { 'TT-X-Organization-Key': orgId },
      withCredentials: false,
    });

    return res.data;
  }

  async addReaction({
    counterPartyId,
    counterPartyType,
    messageExpiresAt,
    messageId,
    orgId,
    reaction,
  }: {
    counterPartyId: string;
    counterPartyType: string;
    messageExpiresAt: string;
    messageId: string;
    orgId: string;
    reaction: string;
  }) {
    const res = await this.httpClient.post(`${this.host.webInfraUrl}/v1/reactions/`, {
      data: {
        apiEnv: this.config.apiEnv,
        counterPartyId,
        counterPartyType,
        expiresAt: messageExpiresAt,
        messageId,
        reaction,
      },
      headers: {
        'TT-X-Organization-Key': orgId,
      },
      withCredentials: false,
    });

    return res.data;
  }

  async removeReaction({
    counterPartyId,
    counterPartyType,
    messageId,
    orgId,
    reaction,
  }: {
    counterPartyId: string;
    counterPartyType: string;
    messageId: string;
    orgId: string;
    reaction: string;
  }) {
    const res = await this.httpClient.del(`${this.host.webInfraUrl}/v1/reactions/`, {
      data: {
        counterPartyId,
        counterPartyType,
        messageId,
        reaction,
        apiEnv: this.config.apiEnv,
      },
      headers: {
        'TT-X-Organization-Key': orgId,
      },
      withCredentials: false,
    });

    return res.data;
  }

  async getUnreadMessages(token: string): Promise<{
    count: number;
    last_message_time: string;
    message_token_list: { message_id: string }[];
  }> {
    const res = await this.httpClient.get('/v2/message_sync/unread', {
      query: { token },
    });

    return res.data;
  }
}
