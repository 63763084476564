import pluralize from 'pluralize';

export default function timeLeft(expiresAt) {
  const sec = expiresAt ? (expiresAt - Date.now()) / 1000 : 0;

  if (sec <= 0) {
    return '';
  } else if (sec <= 59) {
    return `${pluralize('second', Math.ceil(sec), true)} left`;
  } else if (sec > 59 && sec < 3540) {
    return `${pluralize('minute', Math.ceil(sec / 60), true)} left`;
  } else if (sec >= 3540 && sec < 86399) {
    return `${pluralize('hour', Math.ceil(sec / 3600), true)} left`;
  } else {
    return `${pluralize('day', Math.ceil(sec / 86400), true)} left`;
  }
}
