import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class WorkflowsAPI extends BaseAPI {
  async create({
    active = false,
    label,
    organizationId,
  }: {
    active: boolean;
    label: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.post('/v5/appointment/reminder_workflow', {
      data: {
        active,
        label,
      },
      headers,
    });

    return data;
  }

  async createEvent({
    deliveryMethod,
    offset,
    offsetUnit,
    organizationId,
    sender,
    templateId,
    workflowId,
  }: {
    deliveryMethod: string;
    offset: number;
    offsetUnit: string;
    organizationId: string;
    sender: string;
    templateId: string;
    workflowId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.post(
      '/v5/appointment/reminder_workflow/:workflowId/event',
      {
        data: {
          appointment_time_offset: offset,
          appointment_time_offset_unit: offsetUnit,
          delivery_method: deliveryMethod,
          sender,
          template_id: templateId,
        },
        headers,
        urlParams: {
          workflowId,
        },
      }
    );

    return data;
  }

  @recoverFromNotFound()
  async delete(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.del('/v5/appointment/reminder_workflow/:id', {
      headers,
      urlParams: {
        id,
      },
    });
  }

  @recoverFromNotFound()
  async deleteEvent(id: string, workflowId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.del('/v5/appointment/reminder_workflow/:workflowId/event/:id', {
      headers,
      urlParams: {
        id,
        workflowId,
      },
    });
  }

  @recoverFromNotFound()
  async findAll(organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v5/appointment/reminder_workflows_per_org', {
      headers,
    });
    return res.data;
  }

  @recoverFromNotFound()
  async findAllEvents(workflowId: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v5/appointment/reminder_workflow/:workflowId/events', {
      headers,
      urlParams: {
        workflowId,
      },
    });
    return res.data;
  }

  @recoverFromNotFound()
  async update({
    active,
    id,
    label,
    organizationId,
  }: {
    active?: boolean;
    id: string;
    label?: string;
    organizationId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.put('/v5/appointment/reminder_workflow/:id', {
      data: {
        active,
        label,
      },
      headers,
      urlParams: {
        id,
      },
    });

    return data;
  }

  @recoverFromNotFound()
  async updateEvent({
    deliveryMethod,
    id,
    offset,
    offsetUnit,
    organizationId,
    sender,
    templateId,
    workflowId,
  }: {
    deliveryMethod: string;
    id: string;
    offset: number;
    offsetUnit: string;
    organizationId: string;
    sender: string;
    templateId: string;
    workflowId: string;
  }) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.put(
      '/v5/appointment/reminder_workflow/:workflowId/event/:id',
      {
        data: {
          appointment_time_offset: offset,
          appointment_time_offset_unit: offsetUnit,
          delivery_method: deliveryMethod,
          sender,
          template_id: templateId,
        },
        headers,
        urlParams: {
          id,
          workflowId,
        },
      }
    );

    return data;
  }
}
