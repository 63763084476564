import React from 'react';

import Fab from '../../Fab/Fab';
import { ReactComponent as MicOffIcon } from '../../../images/mic-off.svg';
import { ReactComponent as MicOnIcon } from '../../../images/mic-on.svg';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

export default function ToggleAudioButton(props) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  return (
    <Fab
      dataTestId={isAudioEnabled ? 'mute-button' : 'unmute-button'}
      onClick={toggleAudioEnabled}
      isDisabled={props.disabled}
      isEnabled={isAudioEnabled}
      title={isAudioEnabled ? 'Mute your microphone' : 'Unmute your microphone'}
      backgroundColorOff="rgba(0, 0, 0, 0.5)"
      backgroundColorOn="rgba(0, 0, 0, 0.7)"
      hoverColor="rgba(0, 0, 0, 0.5)"
    >
      {isAudioEnabled ? <MicOnIcon aria-hidden /> : <MicOffIcon aria-hidden />}
    </Fab>
  );
}
