import React, { useState } from 'react';
import { capitalize } from 'lodash';
import ToolTip from 'rc-tooltip';
import BEM from '../../../bem';

import { useAMContext } from '../../../../contexts/AutomatedMessages';
import { ReactComponent as AddNewEventSvg } from '../../../images/add-event.svg';
import type { WorkflowEvent } from '../../../../types';
import WorkflowEventModal from './WorkflowEventModal';
import AccessibleList from 'common/components/AccessibleList';
import { useAppSelector } from 'redux-stores';

const classes = BEM.with('AutomatedMessages');

const renderOffset = ({ appointmentTimeOffset, appointmentTimeOffsetUnit }: WorkflowEvent) => {
  if (appointmentTimeOffset && appointmentTimeOffsetUnit) {
    const absoluteOffset = Math.abs(appointmentTimeOffset);
    const offsetOccurrence = appointmentTimeOffset < 0 ? 'Before' : 'After';
    let offsetUnit = appointmentTimeOffsetUnit;
    if (absoluteOffset === 1) {
      offsetUnit = offsetUnit.slice(0, -1);
    }
    return `${absoluteOffset} ${capitalize(offsetUnit)} ${offsetOccurrence}`;
  }
};

type WorkflowEventsListProps = {
  isWorkflowActive?: boolean;
  workflowDisabled: boolean;
  workflowEvents: WorkflowEvent[];
};

export default function WorkflowEventsList({
  isWorkflowActive,
  workflowDisabled,
  workflowEvents,
}: WorkflowEventsListProps) {
  const { selectWorkflowEvent } = useAMContext();
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));
  const maxWorkflowEventsReached = workflowEvents?.length >= 10;
  const workflowEventsPresent = workflowEvents?.length > 0;
  const addEventsDisabled = isWorkflowActive || maxWorkflowEventsReached || workflowDisabled;
  let addEventFragment;

  const addNewRule = () => {
    if (addEventsDisabled) return true;
    setIsEventModalOpen(true);
    selectWorkflowEvent({
      appointmentTimeOffset: 1,
      appointmentTimeOffsetSeconds: 0,
      appointmentTimeOffsetUnit: 'days',
      deliveryMethod: 'link',
    });
  };

  const addEventButtonFragment = (
    <div className={classes('workflow-event-add')}>
      <button
        className={classes('workflow-new-event')}
        disabled={addEventsDisabled}
        onClick={addNewRule}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            addNewRule();
          }
        }}
      >
        <AddNewEventSvg className={classes('add-event-button')} aria-hidden />
        <div className={classes('add-new-event')}>Add New Rule</div>
      </button>
    </div>
  );

  if (addEventsDisabled) {
    addEventFragment = (
      <>
        <ToolTip
          placement="bottom"
          overlay={
            <span>{isWorkflowActive ? 'Deactivate to create new rules' : '10 Rules Max'}</span>
          }
          trigger={['hover']}
          overlayInnerStyle={{
            padding: '6px',
            width: 'auto',
            minHeight: '10px',
          }}
        >
          {addEventButtonFragment}
        </ToolTip>
      </>
    );
  } else {
    addEventFragment = addEventButtonFragment;
  }

  return (
    <div>
      <div className={classes('workflow-event')}>
        <div className={classes('workflow-event-title')}>RULES</div>
        <AccessibleList
          className={classes('events-accessible-list')}
          focusableClasses={['.tc-AutomatedMessages__workflow-event-information']}
          accessibilityMode={accessibilityMode}
        >
          <div className={classes('events-list')}>
            {workflowEventsPresent &&
              workflowEvents
                .sort(
                  (a: WorkflowEvent, b: WorkflowEvent) =>
                    a.appointmentTimeOffsetSeconds - b.appointmentTimeOffsetSeconds
                )
                .map((event) => (
                  <div
                    onClick={() => {
                      selectWorkflowEvent(event);
                      setIsEventModalOpen(true);
                    }}
                    className={classes('workflow-event-information')}
                    key={event.id}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        selectWorkflowEvent(event);
                        setIsEventModalOpen(true);
                      }
                    }}
                  >
                    <div className={classes('workflow-event-offset')}>{renderOffset(event)}</div>
                    <div className={classes('workflow-event-name')} title={event.templateLabel}>
                      {event.templateLabel}
                    </div>
                  </div>
                ))}
          </div>
          {addEventFragment}
        </AccessibleList>
      </div>
      <WorkflowEventModal
        isOpen={isEventModalOpen}
        onClose={() => {
          setIsEventModalOpen(false);
          selectWorkflowEvent(null);
        }}
      />
    </div>
  );
}
