export type FeatureService =
  | 'alerts'
  | 'group_alerts'
  | 'patient_messaging'
  | 'role_alerts'
  | 'vwr';

/**
 * @deprecated Prefer the FeatureService union, as this object will be deprecated in the future.
 */
export const FeatureServices: { [key: string]: FeatureService } = {
  ALERTS: 'alerts',
  GROUP_ALERTS: 'group_alerts',
  PATIENT_MESSAGING: 'patient_messaging',
  ROLE_ALERTS: 'role_alerts',
  VIRTUAL_WAITING_ROOM: 'vwr',
} as const;
