import { omit } from 'lodash';
import Camelizer from '../utils/Camelizer';
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class AppointmentsAPI extends BaseAPI {
  async create(appointmentData: {
    appointmentDate: string;
    appointmentTime: string;
    appointmentTimezone: string;
    location: string;
    organizationId: string;
    patientId: string;
    provider: string;
    workflowId: string;
  }) {
    const { organizationId } = appointmentData;
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.post('/v5/appointment', {
      data: Camelizer.underscoreObject(omit({ ...appointmentData, source: 'manual' }, ['id'])),
      headers,
    });

    return data;
  }

  @recoverFromNotFound()
  async batchDelete(ids: string[], organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.patch('/v5/appointment', {
      data: {
        ids,
        operation: 'destroy',
      },
      headers,
    });
  }

  async delete(id: string, organizationId: string) {
    const headers = { 'TT-X-Organization-Key': organizationId };

    await this.httpClient.del('/v5/appointment/:id', {
      headers,
      urlParams: {
        id,
      },
    });
  }

  @recoverFromNotFound()
  async findAll({ patientId, organizationId }: { patientId: string; organizationId: string }) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const res = await this.httpClient.get('/v5/appointment/appointments_per_patient/:patientId', {
      headers,
      urlParams: {
        patientId,
      },
    });
    return res.data;
  }

  @recoverFromNotFound()
  async update(appointmentData: {
    appointmentDate?: string;
    appointmentTime?: string;
    appointmentTimezone?: string;
    id: string;
    location?: string;
    organizationId: string;
    provider?: string;
    workflowId: string;
  }) {
    const { id, organizationId } = appointmentData;
    const headers = { 'TT-X-Organization-Key': organizationId };

    const { data } = await this.httpClient.put('/v5/appointment/:id', {
      data: Camelizer.underscoreObject(
        omit({ ...appointmentData, source: 'manual' }, ['id', 'organizationId'])
      ),
      headers,
      urlParams: {
        id,
      },
    });

    return data;
  }
}
