// @ts-nocheck
import Camelizer from '../utils/Camelizer';
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { ORGANIZATION_FIND_ALL_OF_USER, ORGANIZATION_FIND } = ROUTES;

export default class OrganizationsAPI extends BaseAPI {
  @recoverFromNotFound()
  async find(userId: string, id: string) {
    const { version = VERSION_TWO } = super.getVersion(ORGANIZATION_FIND);
    const res = await this.httpClient.get('/:version/user/:userId/organization/:id', {
      urlParams: { version, userId, id },
    });
    return res.data;
  }

  @recoverFromNotFound()
  async findAllOfUser(userId: string) {
    const { version = VERSION_TWO } = super.getVersion(ORGANIZATION_FIND_ALL_OF_USER);
    const res = await this.httpClient.get('/:version/user/:userId/organization', {
      urlParams: { version, userId },
    });
    return res.data;
  }

  @recoverFromNotFound()
  async getPreferences(organizationId) {
    const res = await this.httpClient.get('/v2/organization/:organizationId/preferences', {
      urlParams: { organizationId },
    });
    return res.data;
  }

  @recoverFromNotFound()
  async editPreferences({ organizationId, quickReplies }) {
    const res = await this.httpClient.post('/v2/organization/:organizationId/preferences', {
      headers: { 'TT-X-Organization-Key': organizationId },
      data: Camelizer.underscoreObject({
        network: {
          patient: {
            quickReplies,
          },
        },
      }),
      urlParams: { organizationId },
    });
    return res.data;
  }
}
