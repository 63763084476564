export default function updateParamsWithIdpState(params: Record<string, string>) {
  if (!params || !params.state) return;

  let stateParams: Record<string, string> = {};

  try {
    stateParams = JSON.parse(params.state);
  } catch (e) {
    console.error('Failed to parse state query params for', params.state);
  }

  for (const [key, value] of Object.entries(stateParams)) {
    if (!(key in params)) {
      params[key] = value;
    }
  }
}
