import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { UserAvatar } from '../../../../common/components';
import { mobxInjectSelect } from '../../../../common/utils';
import { PatientInfo } from '../ConversationPane';

const classes = BEM.with('PatientSearchBar');

class PatientSearchBar extends Component {
  static propTypes = {
    hidePatientFilteredConversations: PropTypes.func.isRequired,
    patientFilteredConversations: PropTypes.object.isRequired,
  };

  render() {
    const { patientFilteredConversations, patientFilteredEntity } = this.props;
    const { displayName, isPatientContact } = patientFilteredEntity;

    let relationName = '';
    if (isPatientContact) {
      relationName = patientFilteredEntity.patientContact.relation;
    }

    let detailsFragment;
    if (isPatientContact) {
      detailsFragment = (
        <div className={classes('contact-information')}>
          <div className={classes('contact-relation')}>{relationName}</div>
          <div className={classes('contact-name')}>{displayName}</div>
        </div>
      );
    } else {
      detailsFragment = (
        <div className={classes('patient-container')}>
          <div className={classes('avatar-container')}>
            <UserAvatar user={patientFilteredEntity} size="fit" />
          </div>
          <div className={classes('patient-information')}>
            <div className={classes('patient-name')}>{displayName}</div>
            <PatientInfo conversation={patientFilteredConversations[0]} isInsideCard={true} />
          </div>
        </div>
      );
    }

    return (
      <div className={classes()}>
        <button
          className={classes('search-back-button')}
          onClick={this._onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <div className={classes('selection-arrow')} />
        </button>
        {detailsFragment}
      </div>
    );
  }

  _onClick = () => {
    const { hidePatientFilteredConversations } = this.props;
    hidePatientFilteredConversations();
  };
}

export default mobxInjectSelect({
  conversationStore: [
    'hidePatientFilteredConversations',
    'patientFilteredConversations',
    'patientFilteredEntity',
  ],
})(PatientSearchBar);
