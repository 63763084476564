import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { FROZEN_EMPTY_ARRAY, attachmentType, mobxInjectSelect } from '../../../../common/utils';
import propTypes from '../../../../common/propTypes';
import {
  EntityName,
  MessageStatus,
  Timestamp,
  TypingStatusIndicator,
  UnreadBadgeCount,
} from '../../../../common/components';
import PatientInfo from '../ConversationPane/PatientInfo';
import RoleStatus from './RoleStatus';

const classes = BEM.with('PatientConversationItemDetails');
const DEFAULT_PATIENT_TILE_HEIGHT = 156;
const DEFAULT_PATIENT_BROADCAST_TILE_HEIGHT = 86;
const PADDING_PX = 11 + 6;
const BORDER_PX = 1;
const TILE_SPACE_PX = PADDING_PX + BORDER_PX;
const ROW_PX = 19;

class PatientConversationItemDetails extends Component {
  static propTypes = {
    conversation: propTypes.conversation.isRequired,
    currentRoles: propTypes.roleArray.isRequired,
    getTypersForCounterParty: PropTypes.func.isRequired,
    isPinned: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isTileHovered: PropTypes.bool.isRequired,
    isInsideCard: PropTypes.bool,
  };

  static getHeight(props) {
    const { middleRows } = PatientConversationItemDetails.getTile(props);
    const defaultHeight =
      props.conversation.counterPartyType === 'distributionList'
        ? DEFAULT_PATIENT_BROADCAST_TILE_HEIGHT
        : DEFAULT_PATIENT_TILE_HEIGHT;
    return Math.max(defaultHeight, TILE_SPACE_PX + middleRows.length * ROW_PX);
  }

  static getSenderRole({ counterParty, currentRoles }) {
    const { $entityType, groupType, memberIds, p2pSender } = counterParty;
    if ($entityType !== 'group' || currentRoles.length === 0) return null;
    if (groupType === 'ROLE_P2P')
      return p2pSender && p2pSender.$entityType === 'role' ? p2pSender : null;

    const applicableRoles = currentRoles.filter(
      (role) => memberIds && memberIds.includes(role.botUserId)
    );
    if (applicableRoles.length === 0) return null;

    return applicableRoles[0];
  }

  static getTile(props) {
    const { conversation, currentRoles, getTypersForCounterParty, isSelected } = props;
    const { counterParty, counterPartyType, lastMessage, unreadCount } = conversation;
    const { patientDetails = {}, p2pRecipient, p2pRecipientType, memberCount } = counterParty;
    const { smsOptedOut } = patientDetails;
    const typers = getTypersForCounterParty(counterParty);
    const showTypers = typers && typers.length > 0;
    const senderRole = PatientConversationItemDetails.getSenderRole({
      counterParty,
      currentRoles,
    });
    const recipient = p2pRecipient || counterParty;
    const tag = p2pRecipient && p2pRecipientType === 'role' && p2pRecipient.tag;
    const showTagName = tag && !tag.isDefault;
    const showLastMessageStatus = lastMessage && lastMessage.isOutgoing;

    const middleRows = [];

    middleRows.push({
      className: classes('header'),
      content: (
        <span
          aria-label="Roster Tile Name"
          title={recipient.displayName}
          className={classes('counter-party-name', { smsOptedOut })}
        >
          <div className={classes('content-width')}>
            <EntityName entity={recipient} />
          </div>
        </span>
      ),
    });

    if (showTagName) {
      middleRows.push({
        className: classes('tag', { isSelected }),
        content: <span className={classes('tag-name')}>{tag.name}</span>,
      });
    }

    if (smsOptedOut) {
      middleRows.push({
        className: classes('sms-opted-out-text'),
        content: (
          <div key="sms-opted-out-text" className={classes('sms-opted-out-text')}>
            Opted out via SMS
          </div>
        ),
      });
    } else {
      if (showTypers) {
        middleRows.push({
          className: classes('typers'),
          content: (
            <TypingStatusIndicator
              counterParty={counterParty}
              maxTypers={1}
              size={8}
              typers={typers}
              color={'#828282'}
            />
          ),
        });
      } else {
        const {
          attachments = FROZEN_EMPTY_ARRAY,
          body,
          patientCareCard = FROZEN_EMPTY_ARRAY,
          sender,
        } = lastMessage || {};
        const senderFirstName = sender && sender.firstName ? sender.firstName : '';
        const showLastMessageSender = memberCount > 2;
        const lastMessageBody =
          showLastMessageSender && senderFirstName ? `${senderFirstName}: ${body}` : body;
        const lastMessageText =
          attachments && attachments.length > 0 && !body
            ? `Attachment: ${attachmentType(attachments[0].contentType)}`
            : patientCareCard.length > 0
            ? `Alert: ${patientCareCard[0].value}`
            : lastMessageBody;

        middleRows.push({
          className: classes('last-message-body'),
          content: (
            <div key="last-message-body" className={classes('last-message-body')}>
              {lastMessageText}
            </div>
          ),
        });
      }
    }

    let groupDetails = '';
    let lastMessageStatus = '';

    if (memberCount > 2) {
      groupDetails = <span className={classes('member-details')}>{`${memberCount} Members`}</span>;
    }

    if (showLastMessageStatus) {
      lastMessageStatus = (
        <span className={classes('last-message-status')}>
          <MessageStatus isClickable={false} message={lastMessage} />
        </span>
      );
    }

    middleRows.push({
      className: classes('group-details'),
      content: (
        <div>
          {groupDetails} {groupDetails && lastMessageStatus ? <span>&#8901;</span> : ''}{' '}
          {lastMessageStatus}
        </div>
      ),
    });

    if (counterPartyType !== 'distributionList') {
      middleRows.push({ className: classes('filler'), content: null });
    }

    const rightRows = [
      lastMessage && (
        <Timestamp value={lastMessage.createdAt} className={classes('last-message-timestamp')} />
      ),
      unreadCount > 0 && (
        <UnreadBadgeCount className={classes('unread-badge')} count={unreadCount} />
      ),
    ];

    while (rightRows.length < middleRows.length - 1) {
      rightRows.push(null);
    }

    if (senderRole) {
      rightRows.push(<RoleStatus className={classes('role-status')} role={senderRole} />);
    } else {
      rightRows.push(null);
    }

    return { middleRows, rightRows };
  }

  render() {
    const {
      conversation,
      currentRoles,
      getTypersForCounterParty,
      isPinned,
      isSelected,
      isTileHovered,
      isInsideCard,
    } = this.props;
    const { middleRows, rightRows } = PatientConversationItemDetails.getTile({
      conversation,
      currentRoles,
      getTypersForCounterParty,
      isPinned,
      isSelected,
    });

    const { patientDetails } = conversation.counterParty;
    const renderedRows = [];
    const rowCount = middleRows.length;
    const seen = {};

    for (let idx = 0; idx < rowCount; idx++) {
      const { className, content } = middleRows[idx];
      if (seen[className] === undefined) {
        seen[className] = 0;
      } else {
        seen[className]++;
      }
      const key = `${className}-${seen[className]}`;

      renderedRows.push(
        <div key={key} className={classes('row')}>
          <div className={className}>{content}</div>
          {rightRows[idx]}
        </div>
      );
    }

    return (
      <div className={classes()}>
        {renderedRows}
        {patientDetails && (
          <div
            className={classes('patient-info', {
              selected: isSelected,
              hovered: !isSelected && isTileHovered,
            })}
          >
            <PatientInfo conversation={conversation} isInsideCard={isInsideCard} />
          </div>
        )}
      </div>
    );
  }
}

const WrappedComponent = mobxInjectSelect({
  roleStore: ['currentRoles'],
  typingStatusStore: ['getTypersForCounterParty'],
})(PatientConversationItemDetails);

WrappedComponent.getHeight = PatientConversationItemDetails.getHeight;

export default WrappedComponent;
