import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StatusMessage from './StatusMessage';

export default class TimedStatusMessage extends Component {
  static propTypes = {
    hideAfter: PropTypes.number,
  };

  state = {
    hidden: false,
  };

  componentDidMount() {
    const { hideAfter } = this.props;

    if (hideAfter) {
      this._hideTimeout = setTimeout(() => this.setState({ hidden: true }), hideAfter);
    }
  }

  componentWillUnmount() {
    clearTimeout(this._hideTimeout);
  }

  render() {
    const { hidden } = this.state;

    return hidden ? null : <StatusMessage {...this.props} />;
  }
}
