export default function getRecipientEntity({ entity, entityType }) {
  if (entity) entityType = entity.$entityType;
  if (!entityType) return undefined;

  if (entityType === 'group' && entity && entity.groupType === 'ROLE_P2P' && entity.p2pRecipient) {
    entity = entity.p2pRecipient;
    entityType = entity.$entityType;
  }

  return { entity, entityType };
}
