import React, { useCallback, useEffect, useState } from 'react';

import { mobxInjectSelect } from '../../utils';
import Tooltip from '../../../widgets/messenger/components/ToolTip';

import styles from './FormattingButtons.module.css';

type FormattingButtonsProps = {
  getSelection: () => { selectionStart?: number; selectionEnd?: number };
  setCursorPosition: (pos: number) => void;
  textArea: React.RefObject<HTMLTextAreaElement | HTMLDivElement>;
};

type MobxProps = {
  body: string;
  setBody: (s: string) => void;
};

const FormattingButtons = ({
  body,
  getSelection,
  setBody,
  setCursorPosition,
  textArea,
}: FormattingButtonsProps & MobxProps) => {
  const formatText = useCallback(
    (formatter: '*' | '**' | '```') => {
      let { selectionStart, selectionEnd } = getSelection();
      if (!selectionStart) selectionStart = 0;
      if (!selectionEnd) selectionEnd = 0;

      let newBody = '';
      const start = body.slice(selectionStart - formatter.length, selectionStart);
      const end = body.slice(selectionEnd, selectionEnd + formatter.length);
      const selected = body.slice(selectionStart, selectionEnd);

      if (selectionStart === selectionEnd) {
        if (start === formatter && end === formatter) {
          newBody = [
            body.slice(0, selectionStart - formatter.length),
            selected,
            body.slice(selectionEnd + formatter.length),
          ].join('');
          setCursorPosition(selectionStart);
        } else {
          newBody = [
            body.slice(0, selectionStart),
            formatter,
            formatter,
            body.slice(selectionEnd, body.length),
          ].join('');
          setCursorPosition(selectionStart + formatter.length);
        }
      }

      if (selectionEnd > selectionStart) {
        if (start === formatter && end === formatter) {
          newBody = [
            body.slice(0, selectionStart - formatter.length),
            selected,
            body.slice(selectionEnd + formatter.length),
          ].join('');
          setCursorPosition(selectionEnd);
        } else {
          newBody = [
            body.slice(0, selectionStart),
            formatter,
            selected,
            formatter,
            body.slice(selectionEnd, body.length),
          ].join('');
          setCursorPosition(selectionEnd + formatter.length);
        }
      }

      setBody(newBody);
      textArea.current?.focus();
    },
    [body, getSelection, setBody, setCursorPosition, textArea]
  );

  const formatItalic = useCallback(() => formatText('*'), [formatText]);
  const formatBold = useCallback(() => formatText('**'), [formatText]);
  const formatCode = useCallback(() => formatText('```'), [formatText]);

  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.metaKey) {
        if (e.key === 'b') {
          e.preventDefault();
          formatBold();
        } else if (e.key === 'i') {
          e.preventDefault();
          formatItalic();
        } else if (e.key === 'e') {
          e.preventDefault();
          formatCode();
        }
      }
    };
    window.addEventListener('keydown', keydown);
    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, [formatBold, formatCode, formatItalic, formatText]);

  const [showButtons, setShowButtons] = useState(false);

  return (
    <div className={`${styles.container} ${showButtons ? styles.expandedContainer : ''}`}>
      <Tooltip location="top" text="Show rich text formatting menu">
        <button
          onClick={() => setShowButtons(!showButtons)}
          className={`${styles.formattingIcon} ${showButtons ? styles.expandedFormattingIcon : ''}`}
        >
          Aa
        </button>
      </Tooltip>
      {showButtons && (
        <div className={styles.buttons}>
          <div>
            <Tooltip location="top" text="Add bold text (cmd + b)">
              <button onClick={formatBold} className={`${styles.icon} ${styles.bold}`}>
                B
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip location="top" text="Add italic text (cmd + i)">
              <button onClick={formatItalic} className={`${styles.icon} ${styles.italic}`}>
                I
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip location="top" text="Insert code (cmd + e)">
              <button onClick={formatCode} className={`${styles.icon} ${styles.code}`}>
                <span className={styles.codeLeft}>{'<'}</span>
                <span className={styles.codeRight}>{'>'}</span>
              </button>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export default mobxInjectSelect<FormattingButtonsProps, MobxProps>({
  composeMessageStore: ['body', 'setBody'],
})(FormattingButtons);
