import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import TCClient from '../../../../client';
import { DotsIndicator } from '../../../../common/components';
import Modal from '../../../../common/components/Modal';
import Dropdown from '../../../../common/components/Dropdown';
import { ReactComponent as CloseIcon } from '../../../../common/images/modal-cancel.svg';

import { Room as _Room, Unit as _Unit } from '../../../types';

import styles from './styles.module.css';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';

type Unit = { unitId: _Unit['unitId']; unitName: _Unit['unitDisplayName'] };
type Room = Omit<_Room, 'parentAreaId'>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  room: Room;
  unit: Unit;
};

export const ChangeUnitModal = ({ isOpen, onClose, onSave, room, unit }: Props) => {
  const selectedOrganizationId = useAppSelector((state) => state.admin.selectedOrganizationId);
  const dispatch = useAppDispatch();
  const [selectedUnit, setSelectedUnit] = useState<{ unitId: string; unitName: string }>(unit);
  const [units, setUnits] = useState<{ unitId: string; unitName: string }[]>([]);

  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);

  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [buttonHasFocus, setButtonHasFocus] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleMouseEnter = () => setButtonHasFocus(true);
  const handleMouseLeave = () => setButtonHasFocus(false);

  const formHasChanged = unit.unitId !== selectedUnit.unitId;

  useEffect(() => {
    let ref: HTMLButtonElement | null = null;

    buttonRef.current?.addEventListener('mouseenter', handleMouseEnter);
    buttonRef.current?.addEventListener('mouseleave', handleMouseLeave);

    ref = buttonRef.current;

    return () => {
      if (ref) {
        ref.removeEventListener('mouseenter', handleMouseEnter);
        ref.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  });

  useEffect(() => {
    const getUnits = async () => {
      const summary = await TCClient.multiOrg.getOrganizationLocations(selectedOrganizationId);
      const units: { unitId: string; unitDisplayName: string }[] = [];
      summary.forEach((facility) => {
        if (facility.units) {
          units.push(...facility.units);
        }
      });
      const _units = units
        .map((unit) => {
          const { unitId, unitDisplayName: unitName } = unit;
          return { unitId, unitName };
        })
        .sort((left, right) => left.unitName.localeCompare(right.unitName));
      setUnits(_units);
    };
    getUnits();
  }, [selectedOrganizationId]);

  const close = () => {
    setSelectedUnit(unit);
    setError('');
    onClose();
  };

  return (
    <Modal
      bodyClass={styles.body}
      header={`Change the Unit for ${room.parentAreaName}: ${room.roomName}`}
      isOpen={isOpen}
      size={'medium'}
      onRequestClose={close}
    >
      <>
        <div className={styles.errorContainer}>
          {error.length > 0 && <div className={styles.error}>{error}</div>}
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>Unit</span>
          <div onClick={() => setUnitDropdownOpen(true)} className={styles.selectorContainer}>
            {selectedUnit ? (
              <div className={styles.selectorItem}>
                <span className={styles.selectorItemLabel}>{selectedUnit.unitName}</span>
                <button
                  className={styles.clearButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedUnit({ unitId: '', unitName: '' });
                  }}
                >
                  <CloseIcon className={styles.iconSecondary} />
                </button>
              </div>
            ) : (
              <div className={styles.selectorItem}>-No unit selected-</div>
            )}
          </div>
          {unitDropdownOpen && (
            <div className={styles.dropdown}>
              <Dropdown
                ariaLabel={'unit-selector'}
                triggerHandler={() => setUnitDropdownOpen(false)}
              >
                <div className={styles.listContainer}>
                  <div
                    key="noUnitSelected"
                    className={styles.listOption}
                    onClick={() => {
                      setSelectedUnit({ unitId: '', unitName: '' });
                      setUnitDropdownOpen(false);
                    }}
                    data-test-id="clear unit"
                  >
                    -No unit selected-
                  </div>
                  {units.map((u) => (
                    <div
                      className={classNames(
                        styles.listOption,
                        u.unitId === unit.unitId ? styles.selectedListOption : ''
                      )}
                      key={u.unitId}
                      data-test-id={`select unit ${u.unitName}`}
                      onClick={() => {
                        setSelectedUnit(u);
                        setUnitDropdownOpen(false);
                      }}
                    >
                      {u.unitName}
                    </div>
                  ))}
                </div>
              </Dropdown>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.footerLeft}></div>
          <div className={styles.footerRight}>
            <button
              aria-label="Cancel"
              className={styles.cancelButton}
              onClick={close}
              type="button"
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              disabled={!formHasChanged}
              aria-label="Save"
              className={styles.saveButton}
              onClick={async () => {
                try {
                  setIsSaving(true);

                  // Remove room from old unit
                  const { rooms: roomsPreviousUnit } = await TCClient.multiOrg.getUnit({
                    orgId: selectedOrganizationId,
                    unitId: unit.unitId,
                  });
                  const roomIdsPreviousUnit = roomsPreviousUnit
                    .filter((r) => r.roomId !== room.roomId)
                    .map((r) => r.roomId);
                  await TCClient.multiOrg.updateUnit({
                    orgId: selectedOrganizationId,
                    roomIds: roomIdsPreviousUnit,
                    unitName: unit.unitName,
                    unitId: unit.unitId,
                  });

                  // Add room to new unit
                  if (selectedUnit.unitId !== '') {
                    const { rooms: roomsNewUnit } = await TCClient.multiOrg.getUnit({
                      orgId: selectedOrganizationId,
                      unitId: selectedUnit.unitId,
                    });
                    const roomIdsNewUnit = roomsNewUnit.map((r) => r.roomId).concat(room.roomId);
                    await TCClient.multiOrg.updateUnit({
                      orgId: selectedOrganizationId,
                      roomIds: roomIdsNewUnit,
                      unitName: selectedUnit.unitName,
                      unitId: selectedUnit.unitId,
                    });
                  }

                  // Let the parent component refresh its state
                  onSave();
                  dispatch(actions.setIsUnitsDataDirty({ isUnitsDataDirty: true }));
                  setIsSaving(false);
                } catch (e) {
                  if (
                    e.text &&
                    typeof e.text === 'string' &&
                    e.text.includes('Invalid input for name.')
                  ) {
                    setError(`This Room name is already in use`);
                  } else {
                    setError('Oops! Something went wrong, please try again');
                  }
                  setIsSaving(false);
                }
              }}
              type="button"
            >
              {isSaving && (
                <DotsIndicator color={buttonHasFocus ? '#fff' : '#4580d8'} size={10} speed={0.5} />
              )}
              {!isSaving && 'Save'}
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
