import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { buildMessageRecipientList } from './utils/buildMessageRecipientList';
import { MessageItem } from './MessageItem';
import { GroupMessageRecipient, Modal, Scrollbars } from './';

const classes = BEM.with('GroupMessageStatusModal');

class GroupMessageStatusModal extends Component {
  static propTypes = {
    currentGroupMessage: propTypes.message,
    currentRoles: propTypes.roleArray.isRequired,
    currentUserId: PropTypes.string.isRequired,
    isRolesTransitionFeatureFlagEnabled: PropTypes.bool.isRequired,
    findRecipientStatus: PropTypes.func.isRequired,
    hideGroupMessageStatusModal: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { currentGroupMessage, findRecipientStatus } = this.props;

    if (currentGroupMessage) {
      // Server bug: If roles are in a group, sometimes we don't receive Read status for them
      // when the on-duty user reads a message. So we have to hard-refresh the statuses every
      // time the modal is shown, even though we'd prefer to call `ensure` instead.
      findRecipientStatus(currentGroupMessage.id, { includeUsers: true });
    }
  }

  render() {
    const { currentGroupMessage: message, isRolesTransitionFeatureFlagEnabled } = this.props;
    let direction;

    if (message) {
      direction = message.isOutgoing ? 'OUTGOING' : 'INCOMING';
    }

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes({ direction })}
        header="Message Status"
        isOpen={!!message}
        onRequestClose={this._requestClose}
        size={'variable'}
      >
        {message && (
          <div className={classes('message-container')}>
            <div className={classes('message')}>
              <MessageItem isModal={true} message={message} />
            </div>
          </div>
        )}
        <div className={classes('recipients-outer')}>
          <Scrollbars autoHide={false}>
            {isRolesTransitionFeatureFlagEnabled ? (
              <div className={classes('recipients-inner')}>
                {buildMessageRecipientList(this.props).map((status, idx) => (
                  <GroupMessageRecipient key={status.userId} isFirst={idx === 0} status={status} />
                ))}
              </div>
            ) : (
              <div className={classes('recipients-inner')}>{this._renderRecipients()}</div>
            )}
          </Scrollbars>
        </div>
      </Modal>
    );
  }

  _requestClose = () => {
    const { closeModal, hideGroupMessageStatusModal } = this.props;
    closeModal();
    hideGroupMessageStatusModal();
  };

  _renderRecipients = () => {
    const { currentGroupMessage: message, currentRoles, currentUserId } = this.props;
    const myRoleBotIds = currentRoles.map(({ botUserId }) => botUserId);
    if (!message) return;

    const { group, statusesPerRecipient } = message;
    if (!statusesPerRecipient || !group || !group.members) return;
    const { members: groupMembers } = group;

    const userIdsAlsoInARole = [];
    for (const { botRole, isRoleBot } of groupMembers) {
      if (!(isRoleBot && botRole)) continue;

      const { memberIds } = botRole;
      if (!memberIds) continue;

      for (const memberId of memberIds) {
        userIdsAlsoInARole.push(memberId);
      }
    }

    const messageRecipients = [];
    let isFirst = true;

    for (const status of statusesPerRecipient) {
      const { userId, user } = status;
      if (userId === currentUserId || myRoleBotIds.includes(userId) || !user) continue;
      if (!user.shouldDisplay) continue;
      if (userIdsAlsoInARole.includes(userId) || !groupMembers.includes(user)) continue;

      messageRecipients.push(
        <GroupMessageRecipient key={userId} isFirst={isFirst} status={status} user={user} />
      );

      isFirst = false;
    }

    return messageRecipients;
  };
}

export default mobxInjectSelect({
  messageStore: ['currentGroupMessage', 'findRecipientStatus', 'hideGroupMessageStatusModal'],
  roleStore: ['currentRoles'],
  sessionStore: ['currentUserId'],
  messengerStore: ['isRolesTransitionFeatureFlagEnabled'],
})(GroupMessageStatusModal);
