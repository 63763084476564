// @ts-nocheck
// If there are multiple file extensions for a MIME type, order them by most-common first,
// least-common last; similarly for multiple MIME types that have the same file extension.
const supportedMimeEntries = [
  { ext: 'csv', mime: 'text/csv' },
  { ext: 'csv', mime: 'text/plain' },
  { ext: 'doc', mime: 'application/msword' },
  { ext: 'docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { ext: 'gif', mime: 'image/gif' },
  { ext: 'gz', mime: 'application/gzip' },
  { ext: 'gz', mime: 'application/x-gzip' },
  { ext: 'gz', mime: 'application/x-compressed' },
  { ext: 'jpg', mime: 'image/jpeg' },
  { ext: 'jpeg', mime: 'image/jpeg' },
  { ext: 'm4a', mime: 'audio/m4a' },
  { ext: 'm4a', mime: 'audio/x-m4a' },
  { ext: 'm4a', mime: 'audio/mp4' },
  { ext: 'm4v', mime: 'video/x-m4v' },
  { ext: 'mov', mime: 'video/quicktime' },
  { ext: 'mp3', mime: 'audio/mpeg' },
  { ext: 'mp3', mime: 'audio/mp3' },
  { ext: 'mp3', mime: 'audio/mpeg3' },
  { ext: 'mp3', mime: 'audio/x-mpeg' },
  { ext: 'mp3', mime: 'audio/x-mpeg-3' },
  { ext: 'mp3', mime: 'video/mpeg' },
  { ext: 'mp3', mime: 'video/x-mpeg' },
  { ext: 'mp4', mime: 'video/mp4' },
  { ext: 'pdf', mime: 'application/pdf' },
  { ext: 'png', mime: 'image/png' },
  { ext: 'ppsx', mime: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
  { ext: 'ppt', mime: 'application/vnd.ms-powerpoint' },
  { ext: 'ppt', mime: 'application/ms-powerpoint' },
  { ext: 'ppt', mime: 'application/mspowerpoint' },
  { ext: 'ppt', mime: 'application/powerpoint' },
  { ext: 'ppt', mime: 'application/x-mspowerpoint' },
  {
    ext: 'pptx',
    mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  { ext: 'svg', mime: 'image/svg+xml' },
  { ext: 'txt', mime: 'text/plain' },
  { ext: 'wav', mime: 'audio/wav' },
  { ext: 'wav', mime: 'audio/wave' },
  { ext: 'wav', mime: 'audio/x-pn-wav' },
  { ext: 'wav', mime: 'audio/x-wav' },
  { ext: 'xls', mime: 'application/vnd.ms-excel' },
  { ext: 'xls', mime: 'application/excel' },
  { ext: 'xls', mime: 'application/x-excel' },
  { ext: 'xls', mime: 'application/x-msexcel' },
  { ext: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { ext: 'zip', mime: 'application/zip' },
];

const supportedMimeEntriesByContentType = {};
const supportedMimeEntriesByExtension = {};

for (const entry of supportedMimeEntries) {
  const { ext, mime } = entry;
  if (!supportedMimeEntriesByContentType[mime]) {
    supportedMimeEntriesByContentType[mime] = [];
  }
  supportedMimeEntriesByContentType[mime].push(entry);

  if (!supportedMimeEntriesByExtension[ext]) {
    supportedMimeEntriesByExtension[ext] = [];
  }
  supportedMimeEntriesByExtension[ext].push(entry);
}

export const allContentTypes = Object.keys(supportedMimeEntriesByContentType);
export const allExtensions = Object.keys(supportedMimeEntriesByExtension);

export function getMimeEntryByContentType(contentType) {
  return supportedMimeEntriesByContentType[contentType];
}

export function getMimeEntryByExtension(ext) {
  if (ext && ext[0] === '.') ext = ext.slice(1);
  return supportedMimeEntriesByExtension[ext];
}
