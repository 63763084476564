// @ts-nocheck
import BaseAPI, { ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { METADATA_FIND } = ROUTES;

export default class MetadataAPI extends BaseAPI {
  /**
   * POST /metadata/:id
   * @param  {string}  id
   * @param  {Object}  data
   * @param  {string}  organizationId
   * @return {Promise.<void, Error>} A promise with no data
   */
  async update(id: string, data: Object, organizationId: string) {
    await this.httpClient.post('/v2/metadata/:id', {
      urlParams: { id },
      data: data,
      headers: { 'TT-X-Organization-Key': organizationId },
    });
  }

  /**
   * GET /metadata/:id
   * @param  {string}  id
   * @param  {Object}  data
   * @param  {string}  organizationId
   * @return {Promise.<void, Error>} A promise with the metadata
   */
  async find(id: string, organizationId: string) {
    const { version = VERSION_TWO } = super.getVersion(METADATA_FIND);

    const res = await this.httpClient.get('/:version/metadata/:id', {
      urlParams: { id, version },
      headers: { 'TT-X-Organization-Key': organizationId },
    });

    return res.data;
  }
}
