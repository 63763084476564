import { CloseReason } from '../../models/enums/CloseReason';
import CharCodes from '../EventSourcePolyfill/CharCodes';
import NetworkStatus from '../NetworkStatus';
import WebSocketRequest, {
  OnErrorClient,
  OnErroWebSocket,
  OnMessage,
  OnOpen,
} from './WebSocketRequest';

export type PartialHttpClient = {
  getAuth: () => void;
  setAuth: (auth: { key: string; secret: string }) => void;
  config: { webSocketUrl: string };
  getAuthForKeyAndSecret: (key: string, secret: string) => unknown;
  networkStatus: string;
  sendReport: (data: unknown) => void;
  _setNetworkStatus: (status: unknown) => void;
};

type OnErrorParams = Parameters<OnErroWebSocket>[0];

type ClientError = Error & {
  code?: unknown;
  eventSourceType?: 'websocket' | 'sse';
  hasSentReport?: boolean;
  hasSignaledError?: boolean;
  reason?: string;
};

export const webSocketErrorReportPayload = (webSocketError: OnErrorParams) => ({
  level: 'info',
  message: 'webSocketError',
  payload: { webSocketError },
});

export const E1_START_CONNECTION = 'WS_E1_START_CONNECTION';
export const E2_CONNECTION_OPENED = 'WS_E2_CONNECTION_OPENED';
export const E3_AUTH_COMMAND = 'WS_E3_AUTH_COMMAND';
export const E4_AUTH_RESPONSE = 'WS_E4_AUTH_RESPONSE';

const WS_TIMING_EVENT_MARKS = [
  E1_START_CONNECTION,
  E2_CONNECTION_OPENED,
  E3_AUTH_COMMAND,
  E4_AUTH_RESPONSE,
];

const E2_E1_DELTA = `${E2_CONNECTION_OPENED}-${E1_START_CONNECTION}`;
const E3_E2_DELTA = `${E3_AUTH_COMMAND}-${E2_CONNECTION_OPENED}`;
const E4_E3_DELTA = `${E4_AUTH_RESPONSE}-${E1_START_CONNECTION}`;
const E4_E1_DELTA = `${E4_AUTH_RESPONSE}-${E1_START_CONNECTION}`;

const WS_TIMING_EVENT_MEASURES = [E2_E1_DELTA, E3_E2_DELTA, E4_E3_DELTA, E4_E1_DELTA];

const ACCEPTED_EVENTS = new Set(['data', 'v1_message_status']);

export type TimingEvents = {
  e1_startConnection: number;
  e2_connectionOpened: number;
  e3_authCommand: number;
  e4_authResponse: number;
};

type TimingsReport = {
  events: {
    e1_startConnection: { startTime: number };
    e2_connectionOpened: { startTime: number; deltaMS: number };
    e3_authCommand: { startTime: number; deltaMS: number };
    e4_authResponse: { startTime: number; deltaMS: number };
  };
  overall: { deltaMS: number };
};

const webSocketTimingsReport = (): TimingsReport | undefined => {
  const getPerformanceEntry = (name: string): PerformanceEntry | undefined =>
    performance.getEntriesByName(name, 'mark')?.[0];

  const entries = WS_TIMING_EVENT_MARKS.map((name) => getPerformanceEntry(name)).filter(
    (entry: PerformanceEntry | undefined): entry is PerformanceEntry => !!entry
  );

  const haveAllEntries = entries.length === WS_TIMING_EVENT_MARKS.length;

  if (haveAllEntries) {
    const [e1, e2, e3, e4] = entries;

    const report: TimingsReport = {
      events: {
        e1_startConnection: { startTime: e1.startTime },
        e2_connectionOpened: {
          startTime: e2.startTime,
          deltaMS: performance.measure(E2_E1_DELTA, e1.name, e2.name).duration,
        },
        e3_authCommand: {
          startTime: e3.startTime,
          deltaMS: performance.measure(E3_E2_DELTA, e2.name, e3.name).duration,
        },
        e4_authResponse: {
          startTime: e4.startTime,
          deltaMS: performance.measure(E4_E3_DELTA, e3.name, e4.name).duration,
        },
      },
      overall: {
        deltaMS: performance.measure(E4_E1_DELTA, e1.name, e4.name).duration,
      },
    };

    WS_TIMING_EVENT_MARKS.forEach((mark) => performance.clearMarks(mark));
    WS_TIMING_EVENT_MEASURES.forEach((measure) => performance.clearMeasures(measure));

    return report;
  }
};

export type InfoParams =
  | {
      type: 'AuthCommand' | 'AuthResponse';
    }
  | {
      type: 'Timings';
      timings: TimingsReport;
    };

export const webSocketInfoReportPayload = (webSocketInfo: InfoParams) => ({
  level: 'info',
  message: 'webSocketInfo',
  payload: { webSocketInfo },
});

class WebSocketClient {
  httpClient: PartialHttpClient;
  _connections: [WebSocket, number][] = [];
  connected: Promise<undefined | ClientError>;
  _resolveConnected?: (value?: Error) => void;
  hasAuthMessage: boolean; // = false
  hasConnected: boolean;
  hasError: boolean;
  hasFirstMessage: boolean;
  _auth: { key: string; secret: string };
  _onError?: OnErrorClient;
  _onMessage?: OnMessage;
  _onOpen?: OnOpen;
  _request?: WebSocketRequest;
  _features: string[];
  type = 'websocket';

  constructor({
    auth,
    connections,
    httpClient,
    onError,
    onMessage,
    onOpen,
    path,
    features,
  }: {
    auth: { key: string; secret: string };
    connections: [WebSocket, number][];
    httpClient: PartialHttpClient;
    path: string;
    onOpen?: OnOpen;
    onMessage?: OnMessage;
    onError?: OnErrorClient;
    features: string[];
  }) {
    this.httpClient = httpClient;
    this.connected = new Promise((resolve) => {
      this._resolveConnected = resolve;
    });
    this.hasAuthMessage = false;
    this.hasConnected = false;
    this.hasError = false;
    this.hasFirstMessage = false;

    this._auth = auth || this.httpClient.getAuth();
    this._onError = onError;
    this._onMessage = onMessage;
    this._onOpen = onOpen;
    this._features = features;

    this._connections = connections;

    const url = /wss?:/.test(path)
      ? path
      : this.httpClient.config.webSocketUrl.replace(/^https?:/, 'wss:') + path;

    const request = new WebSocketRequest({
      onError: (err: OnErrorParams) => this.onError(err),
      onMessage: (event: { data: string }, flags: unknown) => this.onMessage(event, flags),
      onOpen: () => this.onOpen(),
      url,
    });
    this._request = request;
    if (request) this._connections.push([request.socket, Date.now()]);
  }
  onOpen() {
    this._request?.send(`v1_auth: ${JSON.stringify(this.getAuthInfo())}`);
    this.sendInfoReport({ type: 'AuthCommand' });
    performance.mark(E3_AUTH_COMMAND);
  }

  getAuthInfo() {
    const { key, secret } = this._auth;

    return {
      scheme: 'Basic',
      credentials: this.httpClient.getAuthForKeyAndSecret(key, secret),
      features: this._features,
    };
  }

  onMessage(event: { data: string }, _flags: unknown) {
    const { data } = event;
    let command = '';
    let payload = '';
    let res;

    for (let pos = 0; pos < data.length; pos++) {
      if (data.charCodeAt(pos) !== CharCodes[':']) continue;

      command = data.substring(0, pos);
      pos++;

      while (data.charCodeAt(pos) === CharCodes[' '] && pos < data.length) {
        pos++;
      }
      payload = data.substring(pos);
      break;
    }

    if (!this.hasAuthMessage) {
      this.hasAuthMessage = true;

      if (!command) {
        return this.errorAndClose({ type: 'CommandMissing', detail: data });
      } else if (command !== 'v1_auth') {
        return this.errorAndClose({ type: 'InvalidAuthCommand', detail: command });
      }

      try {
        res = JSON.parse(payload);
      } catch (error) {
        return this.errorAndClose({ type: 'InvalidPayload', detail: payload });
      }

      if (res.code === 200) {
        this.hasConnected = true;
        this._resolveConnected && this._resolveConnected();
        this.httpClient._setNetworkStatus(NetworkStatus.ONLINE);

        this.sendInfoReport({ type: 'AuthResponse' });

        performance.mark(E4_AUTH_RESPONSE);
        const timings = webSocketTimingsReport();
        if (timings) {
          this.sendInfoReport({
            type: 'Timings',
            timings,
          });
        }

        if (this._onOpen) this._onOpen();
        return;
      } else {
        const { code, message: detail, reason } = res;
        return this.errorAndClose({ code, detail, reason, type: 'AuthFailure' });
      }
    }

    if (ACCEPTED_EVENTS.has(command)) {
      const isFirstMessage = !this.hasFirstMessage;
      this.hasFirstMessage = true;
      if (this._onMessage) this._onMessage({ data: payload }, { command, isFirstMessage });
    } else {
      this.sendErrorReport({ type: 'UnknownEvent', detail: command });
    }
  }

  sendErrorReport(webSocketError: OnErrorParams) {
    const report = webSocketErrorReportPayload(webSocketError);
    this.httpClient.sendReport(report);
  }

  sendInfoReport(webSocketInfo: InfoParams) {
    const report = webSocketInfoReportPayload(webSocketInfo);
    this.httpClient.sendReport(report);
  }

  errorAndClose(webSocketError: OnErrorParams) {
    this.onError(webSocketError);
    this.close();
  }

  onError({ code, hasSignaledError, detail, reason, type }: OnErrorParams) {
    if (this.hasError) return;
    this.hasError = true;

    if (this.httpClient.networkStatus !== NetworkStatus.OFFLINE) {
      this.httpClient._setNetworkStatus(NetworkStatus.UNREACHABLE);
    }

    this.sendErrorReport({ code, detail, reason, type });

    let errorMessage = `WebSocketError, Type: ${type}`;
    if (detail) errorMessage += `, Detail: ${detail}`;
    if (reason) errorMessage += `, Reason: ${reason}`;
    if (code) errorMessage += `, Code: ${code}`;
    const err: ClientError = new Error(errorMessage);
    if (reason) err.reason = reason;
    if (code) err.code = code;
    err.eventSourceType = 'websocket';
    err.hasSignaledError = hasSignaledError;
    err.hasSentReport = true;

    if (this._onError) this._onError(err);
    if (!this.hasConnected) this._resolveConnected && this._resolveConnected(err);
  }

  close(code?: number, reason?: CloseReason) {
    return this._request?.close(code, reason);
  }

  send(message: string) {
    return this._request?.send(message);
  }
}

export default WebSocketClient;
