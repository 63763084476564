// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class QRCodeAPI extends BaseAPI {
  @recoverFromNotFound()
  async create() {
    const headers = this.httpClient.getAuthHeaders();

    const res = await this.httpClient.get('/v2/qr_login_code', {
      headers,
    });

    return res.data.qr_key;
  }
}
