import BEM from '../../../common/bem';
import MessageAttachment from './MessageAttachment';
import { Message } from 'types';

const classes = BEM.with('MessageAttachmentList');

type MessageAttachmentProps = {
  message: Message;
  multiSelectable: unknown;
  printMode?: boolean;
};

const MessageAttachmentList = ({ message, multiSelectable, printMode }: MessageAttachmentProps) => {
  const { attachments } = message;
  if (!attachments || attachments.length === 0) return null;

  // TODO load attachments only if in view

  return (
    <div className={classes()}>
      {attachments.map((attachment) => (
        <div key={attachment.id} className={classes('attachment-list-item')}>
          <MessageAttachment
            attachment={attachment}
            message={message}
            multiSelectable={multiSelectable}
            printMode={printMode}
          />
        </div>
      ))}
    </div>
  );
};

export default MessageAttachmentList;
