import EventEmitter from 'events';
import { action, extendObservable, runInAction } from 'mobx';
import _ from 'lodash';
import Cookie from 'react-cookie';
import queue from 'emitter-queue';
import { CookieOptions } from '../models/enums';
import { settingsValueToObject } from '../common/utils/daConfigConvert';

const ValueTypes = {
  BOOLEAN: 'boolean',
  OBJECT: 'object',
};
const DESKTOP_SETTINGS_KEY = 'desktopSettings';

export default class LocalStore {
  events = queue(new EventEmitter());

  constructor({ client, entityStore, params, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.params = params;
    this.stores = stores;

    this.settings = [
      {
        global: true,
        setting: 'autoLoginUserId',
        storageKey: 'auto_login_user_id',
        valueType: ValueTypes.OBJECT,
      },
      {
        defaultValue: false,
        setting: 'coarseTimezone',
        storageKey: 'coarse_timezone',
        valueType: ValueTypes.OBJECT,
      },
      {
        global: true,
        setting: 'country',
        storageKey: 'country',
        valueType: ValueTypes.OBJECT,
      },
      {
        global: true,
        defaultValue: false,
        setting: 'clearDom',
        storageKey: 'clear_dom',
        valueType: ValueTypes.OBJECT,
      },
      {
        defaultValue: false,
        setting: 'clearNewMessagesOnConvClick',
        storageKey: 'clear_new_messages_on_conv_click',
        valueType: ValueTypes.OBJECT,
      },
      {
        defaultValue: {},
        setting: 'deletedMessages',
        storageKey: 'deleted_messages',
        valueType: ValueTypes.OBJECT,
      },
      {
        setting: 'escalationModalShown',
        storageKey: 'escalation_modal',
        valueType: ValueTypes.BOOLEAN,
      },
      {
        defaultValue: false,
        setting: 'expertMode',
        storageKey: 'expert_mode',
        valueType: ValueTypes.OBJECT,
      },
      {
        defaultValue: true,
        setting: 'markAsReadOnRosterClick',
        storageKey: 'mark_as_read_on_roster_click',
        valueType: ValueTypes.OBJECT,
      },
      {
        global: true,
        setting: 'notificationKey',
        storageKey: 'notification_key',
        valueType: ValueTypes.OBJECT,
      },
      {
        global: true,
        setting: 'notificationSecret',
        storageKey: 'notification_secret',
        valueType: ValueTypes.OBJECT,
      },
      {
        global: true,
        setting: 'pendingLoginUserId',
        storageKey: 'pending_login_user_id',
        valueType: ValueTypes.OBJECT,
      },
      {
        global: true,
        setting: 'pendingLoginUsername',
        storageKey: 'pending_login_username',
        valueType: ValueTypes.OBJECT,
      },
      {
        defaultValue: [],
        saveFilter: this._filterPinnedConversations,
        setting: 'pinnedConversationIds',
        storageKey: 'pinned_conversation_ids',
        valueType: ValueTypes.OBJECT,
      },
      {
        setting: 'priorityModalShown',
        storageKey: 'priority_messaging_tooltip',
        valueType: ValueTypes.BOOLEAN,
      },
      {
        global: true,
        setting: 'username',
        storageKey: 'username',
        valueType: ValueTypes.OBJECT,
      },
      {
        setting: 'richTextFormat',
        storageKey: 'rich_text_format',
        valueType: ValueTypes.BOOLEAN,
      },
    ];

    this.globalSettings = this.settings.filter((setting) => setting.global);
    this.userSpecificSettings = this.settings.filter((setting) => !setting.global);

    for (const entry of this.settings) {
      const { global, saveFilter, setting, storageKey, valueType } = entry;
      let { defaultValue, setterName, removerName } = entry;

      if (valueType === ValueTypes.BOOLEAN) {
        entry.defaultValue = false;
        defaultValue = false;
      } else if (valueType === ValueTypes.OBJECT) {
        if (defaultValue === undefined) {
          entry.defaultValue = null;
          defaultValue = null;
        }
      } else {
        throw new Error(`Unsupported value type: ${valueType}`);
      }

      const partialFunctionName = setting[0].toUpperCase() + setting.slice(1);
      if (!setterName) {
        setterName = `set${partialFunctionName}`;
        entry.setterName = setterName;
      }

      if (!removerName) {
        removerName = `remove${partialFunctionName}`;
        entry.removerName = removerName;
      }

      extendObservable(this, { [setting]: defaultValue });

      this[removerName] = () => {
        if (global) {
          this.removeStorageValue(storageKey);
        } else {
          const { sessionStore } = this.stores;
          const { currentUserId } = sessionStore;
          if (!currentUserId) return;

          const settingsFromLocalStorage = this.getStorageValue(storageKey);
          if (settingsFromLocalStorage) {
            const { ...updatedSettings } = settingsFromLocalStorage;
            if (_.isEmpty(updatedSettings)) {
              this.removeStorageValue(storageKey);
            } else {
              this.setStorageValue(storageKey, updatedSettings);
            }
          }
        }
        runInAction(() => {
          this[setting] = defaultValue || null;
        });
      };

      // eslint-disable-next-line no-loop-func
      this[setterName] = (newValue) => {
        if (global) {
          this.setStorageValue(storageKey, newValue);
        } else {
          const { sessionStore } = this.stores;
          const { currentUserId } = sessionStore;
          if (!currentUserId) return;

          if (saveFilter) newValue = saveFilter(newValue);

          let userSettings = this.getStorageValue(storageKey);

          if (valueType === ValueTypes.BOOLEAN) {
            if (!userSettings) userSettings = [];
            const idx = userSettings.indexOf(currentUserId);

            if (idx === -1 && newValue) {
              userSettings.push(currentUserId);
            } else if (idx > -1 && !newValue) {
              userSettings.splice(idx, 1);
            }
          } else if (valueType === ValueTypes.OBJECT) {
            if (!userSettings) userSettings = {};
            userSettings[currentUserId] = newValue;
          }

          this.setStorageValue(storageKey, userSettings);
        }

        runInAction(() => {
          this[setting] = newValue;
        });

        return newValue;
      };
    }
  }

  mounted() {
    const { sessionStore } = this.stores;
    sessionStore.events.on('signedIn', this.loadSettings);
  }

  getStorageValue(storageKey) {
    let value;

    try {
      const string = localStorage.getItem(storageKey);
      value = JSON.parse(string);
    } catch (err) {
      // do nothing
    }

    return value;
  }

  removeStorageValue(storageKey) {
    localStorage.removeItem(storageKey);
    this?.events.emit('removeItem');
  }

  setStorageValue(storageKey, value) {
    localStorage.setItem(storageKey, JSON.stringify(value));
    this?.events.emit('setItem');
  }

  @action('LocalStore.loadGlobalSettings') loadGlobalSettings = () => {
    for (const entry of this.globalSettings) {
      if (entry.global) {
        this._loadGlobalSetting(entry);
      }
    }
  };

  @action('LocalStore.loadSettings') loadSettings = (user) => {
    const { messageStore } = this.stores;
    const { id: currentUserId } = user;

    for (const entry of this.userSpecificSettings) {
      this._loadSetting(currentUserId, entry);
    }

    messageStore.expireLocallyDeletedMessages();
    this.events.emit('settingsLoaded', user);
  };

  saveDesktopAppSettings = (currentUserId, desktopSettings) => {
    if (!currentUserId) return;

    const loadedFromLocalStorage = this.getStorageValue(DESKTOP_SETTINGS_KEY);
    const updatedDesktopSettings = {
      ...loadedFromLocalStorage,
      [currentUserId]: desktopSettings,
    };

    this.setStorageValue(DESKTOP_SETTINGS_KEY, updatedDesktopSettings);
  };

  _migrate_loadDesktopAppSettings = (currentUserId) => {
    if (currentUserId) {
      const settingsFromStorage = this.getStorageValue(DESKTOP_SETTINGS_KEY);
      const currentUserSettings = settingsFromStorage && settingsFromStorage[currentUserId];

      if (currentUserSettings) return currentUserSettings;
    }

    const valueFromCookie = Cookie.load(currentUserId) || null;
    const desktopSettings = settingsValueToObject(valueFromCookie);

    if (valueFromCookie) {
      this.saveDesktopAppSettings(currentUserId, desktopSettings);

      try {
        Cookie.remove(currentUserId, CookieOptions.FOREVER);
      } catch (err) {
        // do nothing
      }
    }

    return desktopSettings;
  };

  _loadGlobalSetting = (entry) => {
    const { defaultValue, global, setting, storageKey, valueType } = entry;

    if (!global) return;
    const userSettings = this.getStorageValue(storageKey);

    if (valueType === ValueTypes.BOOLEAN) {
      this[setting] = userSettings;
    } else if (valueType === ValueTypes.OBJECT) {
      this[setting] = userSettings || defaultValue;
    }
  };

  _loadSetting = (currentUserId, entry) => {
    const { defaultValue, global, setting, storageKey, valueType } = entry;

    if (global) {
      this._loadGlobalSetting(entry);
    } else {
      if (!currentUserId) {
        this[setting] = defaultValue;
      } else {
        const userSettings = this.getStorageValue(storageKey);

        if (valueType === ValueTypes.BOOLEAN) {
          this[setting] = !!(userSettings && userSettings.includes(currentUserId));
        } else if (valueType === ValueTypes.OBJECT) {
          this[setting] =
            userSettings && _.has(userSettings, currentUserId)
              ? userSettings[currentUserId]
              : defaultValue;
        }
      }
    }
  };

  _filterPinnedConversations = (pinnedConversationIds) => {
    return pinnedConversationIds.filter((conversationId) =>
      this.entityStore.conversation.getById(conversationId)
    );
  };
}
