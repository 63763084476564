import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { styled } from '@material-ui/core/styles';
import clsx from 'clsx';
import PfCallOrigins from '../../models/enums/PfCallOrigins';

import Fab from '../components/Fab/Fab';
import mobxInjectSelect from '../../common/utils/mobxInjectSelect';
import Controls from '../components/Controls/Controls';
import LocalVideoPreview from '../components/LocalVideoPreview/LocalVideoPreview';
import Room from '../components/Room/Room';
import useIsUserActive from '../components/Controls/useIsUserActive/useIsUserActive';
import useParticipants from '../hooks/useParticipants/useParticipants';
import useRoomState from '../hooks/useRoomState/useRoomState';
import useVideoContext from '../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../state';

import { ReactComponent as MaximizeWindow } from '../images/maximize-button.svg';
import { ReactComponent as MinimizeWindow } from '../images/minimize-button.svg';
import { ReactComponent as ToggleIcon } from '../images/toggle.svg';
import { useAppSelector } from 'redux-stores';

const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  right: '10px',
  top: '10px',
  zIndex: '2',
  opacity: 0,
  transform: 'translate(0px, -35%)',
  transition: 'opacity 1.2s, transform 1.2s, visibility 0s 1.2s',
  '& .MuiTooltip-tooltip': {
    minWidth: '80px',
  },
  '&.showControls, &:hover': {
    opacity: 1,
    visibility: 'visible',
    transform: 'translate(0px, 0px)',
  },
}));

const Main = styled('main')({
  height: '100%',
  position: 'relative',
  width: '100%',
});

const Container = styled('div')(() => ({
  backgroundColor: '#e7e7e7',
  border: '3px solid white',
  borderRadius: '6px',
  bottom: '3px',
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.36)',
  height: '290px',
  marginLeft: '74px',
  position: 'absolute',
  width: '342px',
  pointerEvents: 'auto',
  '&.isEnlargedWindow': {
    height: '80%',
    marginLeft: '0px',
    width: '75%',
    bottom: 'auto',
    tabIndex: 0,
  },
}));

const OuterDiv = styled('div')(() => ({
  position: 'fixed',
  display: 'flex',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  zIndex: '2',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'left',
  backgroundColor: 'rgba(255,255,255,0)',
  pointerEvents: 'none',
  '&.isEnlargedWindow': {
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
    pointerEvents: 'auto',
  },
  '&.isDarkModeOn.isEnlargedWindow': {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
}));

const FloatingText = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  borderRadius: '10px',
  color: '#fff',
  textAlign: 'center',
  backgroundColor: '#35333c',
  left: '50%',
  bottom: '70px',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  padding: '3px 14px',
  fontSize: '14px',
  lineHeight: '24px',
  display: 'table',
}));

const LeftToggleContainer = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  color: '#fff',
  textAlign: 'center',
  top: '50%',
  left: '5px',
  transform: 'translate(-50%, -50%)',
  opacity: 0,

  '& .invert': {
    transform: 'rotateY(180deg)',
  },

  '&.showControls, &:hover': {
    opacity: 1,
    visibility: 'visible',
    transform: 'translate(0px, 0px)',
  },
}));

const RightToggleContainer = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  color: '#fff',
  textAlign: 'center',
  top: '50%',
  right: '5px',
  transform: 'translate(-50%, -50%)',
  opacity: 0,

  '&.showControls, &:hover': {
    opacity: 1,
    visibility: 'visible',
    transform: 'translate(0px, 0px)',
  },
}));

function ActiveCall({
  calleePermissionsCheck,
  currentCall,
  isDarkModeOn,
  isGroupVideoCallEnabled,
  isGroupVoiceCallEnabled,
  isPFGroupVideoCallEnabled,
  origin,
}) {
  const roomState = useRoomState();
  const { connect, room } = useVideoContext();
  const { indexOfParticipants, isEnlargedWindow, setIndexOfParticipants, setIsEnlargedWindow } =
    useAppState();
  const participants = useParticipants();
  const [isRoomEmpty, setIsRoomEmpty] = useState(true);
  const [isCalling, setIsCalling] = useState(false);
  const activeContainerRef = useRef(null);
  const [isExpandButtonHovered, setIsExpandButtonHovered] = useState(false);
  const [calleePermissionsEnabled, setCalleePermissionsEnabled] = useState(false);
  const { memberIds, payload, type } = currentCall;
  const { disabledParticipants } = payload;
  const canAddMember =
    payload && payload.networkType === 'patient'
      ? isPFGroupVideoCallEnabled
      : payload.isVideo
      ? isGroupVideoCallEnabled && calleePermissionsEnabled
      : isGroupVoiceCallEnabled && calleePermissionsEnabled;
  const isUserActive = useIsUserActive(activeContainerRef);
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);
  const showControls = isUserActive || roomState === 'disconnected' || accessibilityMode;
  const showLeftToggle = indexOfParticipants !== 0;
  const showRightToggle =
    room &&
    room.participants &&
    room.participants.size >= 4 &&
    room.participants.size / 4 >= indexOfParticipants + 1;

  const toggleWindowSize = () => {
    setIsExpandButtonHovered(false);
    setIsEnlargedWindow((prev) => !prev);
  };

  useEffect(() => {
    setIsCalling(true);
    connect();
    setIsCalling(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(disabledParticipants) && payload.networkType === 'provider') {
      const enabledCapabilities = payload.isVideo ? ['group_video_call'] : ['group_audio_call'];
      calleePermissionsCheck(memberIds, enabledCapabilities).then((results) => {
        setCalleePermissionsEnabled(!!results.length);
      });
    } else {
      setCalleePermissionsEnabled(Object.keys(disabledParticipants).length + 1 < memberIds.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledParticipants]);

  useEffect(() => {
    if (!isCalling && room && room.participants && room.participants.size > 0) {
      setIsRoomEmpty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, participants]);

  useEffect(() => {
    if (isEnlargedWindow) {
      activeContainerRef.current.focus();
    }
  }, [isEnlargedWindow]);

  function handleKeyDown(event) {
    if (accessibilityMode && isEnlargedWindow && event.key === 'Tab') {
      const focusableElements = activeContainerRef.current.querySelectorAll('button');
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (!event.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      } else if (event.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
      }
    }
  }

  function handleMouseEnter() {
    setIsExpandButtonHovered(true);
  }

  function moreMembers(index) {
    setIndexOfParticipants(
      index === 'increase' ? indexOfParticipants + 1 : indexOfParticipants - 1
    );
  }

  return (
    <OuterDiv
      className={clsx({ isEnlargedWindow, isDarkModeOn })}
      onKeyDown={handleKeyDown}
      tabIndex={isEnlargedWindow ? 0 : -1}
    >
      <Container
        ref={activeContainerRef}
        className={clsx({ isEnlargedWindow })}
        tabIndex={isEnlargedWindow ? 0 : -1}
      >
        <Main>
          {isCalling && <LocalVideoPreview />}
          {!isCalling && roomState !== 'disconnected' && <Room />}
          <ButtonContainer className={clsx({ showControls })}>
            <div onMouseEnter={handleMouseEnter}>
              <Fab
                dataTestId={isEnlargedWindow ? 'minimize-view-button' : 'expand-view-button'}
                onClick={toggleWindowSize}
                placement="bottom"
                title={
                  isExpandButtonHovered ? (isEnlargedWindow ? 'Minimize View' : 'Expand View') : ''
                }
                backgroundColorOff="rgba(0, 0, 0, 0.7)"
                marginButton={'0 5px'}
              >
                {!isEnlargedWindow ? (
                  <MaximizeWindow aria-hidden />
                ) : (
                  <MinimizeWindow aria-hidden />
                )}
              </Fab>
            </div>
          </ButtonContainer>
          {showLeftToggle && (
            <LeftToggleContainer className={clsx({ showControls })}>
              <Fab
                dataTestId={'more-members-button'}
                onClick={() => moreMembers('decrease')}
                placement="bottom"
                title={'Toggle Members'}
                backgroundColorOff="#4C4C4C"
                hoverColor="#3080df"
                marginButton={'0 5px'}
              >
                <ToggleIcon className={'invert'} aria-hidden />
              </Fab>
            </LeftToggleContainer>
          )}

          {showRightToggle && (
            <RightToggleContainer className={clsx({ showControls })}>
              <Fab
                dataTestId={'more-members-button'}
                onClick={() => moreMembers('increase')}
                placement="bottom"
                title={'Toggle Members'}
                backgroundColorOff="#4C4C4C"
                hoverColor="#3080df"
                marginButton={'0 5px'}
              >
                <ToggleIcon aria-hidden />
              </Fab>
            </RightToggleContainer>
          )}
          {isRoomEmpty && <FloatingText>Waiting for participant to join</FloatingText>}
          <Controls canAddMember={canAddMember} isUserActive={isUserActive} />
        </Main>
        {isRoomEmpty &&
          currentCall.type !== 'incoming_call' &&
          payload.networkType !== 'patient' &&
          type !== 'vwr' &&
          origin !== PfCallOrigins.VWR_CONVERSATION && (
            <audio src={'media/call-waiting.wav'} autoPlay loop />
          )}
      </Container>
    </OuterDiv>
  );
}

class ActiveCallContainer extends React.Component {
  render() {
    return <ActiveCall {...this.props} />;
  }
}

export default mobxInjectSelect({
  callStore: [
    'calleePermissionsCheck',
    'currentCall',
    'isGroupVideoCallEnabled',
    'isGroupVoiceCallEnabled',
    'isPFGroupVideoCallEnabled',
    'origin',
  ],
  messengerStore: ['isDarkModeOn'],
})(ActiveCallContainer);
