import { email } from '.';

class UserError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UserError';
  }
}

class FormatError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FormatError';
  }
}

function isRequired(label: string, value: string) {
  if (!value) {
    throw new UserError(`${label} is required`);
  }
}

function isPhone(value: string) {
  if (!isValidPhone(value)) {
    throw new FormatError(`${value} is not a valid phone number`);
  }
}

function isEmail(value: string) {
  if (!email.isEmail(value)) {
    throw new FormatError(`${value} is an invalid email address`);
  }
}

function isValidPhone(phone: string) {
  return phone && /^[0-9]{10}$/.test(phone);
}

export default function validator(label: string, value: string) {
  const formats = {
    isRequired: () => {
      isRequired(label, value);
      return formats;
    },
    isPhone: () => {
      isPhone(value);
      return formats;
    },
    isEmail: () => {
      isEmail(value);
      return formats;
    },
  };

  return formats;
}
