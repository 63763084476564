import React from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';

import { Message } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertActionChoice');

const TIME_FORMAT = 'h:mmaaa';

export type AlertActionChoiceProps = {
  message: Message;
  isExpanded?: boolean;
};

const AlertActionChoice = ({ message, isExpanded = false }: AlertActionChoiceProps) => {
  if (!message.currentRecipientAlertAction) return null;

  const { reaction, reactionTimestamp } = message.currentRecipientAlertAction;
  if (!reaction || !reactionTimestamp) return null;

  return (
    <div
      className={classes('', { shouldClip: !isExpanded })}
      data-test-id={'reactionTimestamp'}
      aria-label="reaction-timestamp"
    >
      You chose "{reaction}" at {format(reactionTimestamp, TIME_FORMAT)}
    </div>
  );
};

export default observer(AlertActionChoice);
