import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { Scrollbars } from '../';
import DotsIndicator from '../DotsIndicator';

const classes = BEM.with('EulaModal');

class EulaContent extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
  };

  state = {
    content: null,
    isLoading: true,
  };

  componentDidMount() {
    this.setEulaContent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setEulaContent();
    }
  }

  render() {
    const { content, isLoading } = this.state;

    const eulaContent = (
      <div
        className={classes('eula-content')}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );

    return (
      <div className={classes('content-container')}>
        {isLoading ? (
          <div className={classes('dots-container')}>
            <DotsIndicator size={13} />
          </div>
        ) : (
          <Scrollbars autoHide={false}>{eulaContent}</Scrollbars>
        )}
      </div>
    );
  }

  setEulaContent = async () => {
    this.setState({ isLoading: true });

    const { findEulaContent, src } = this.props;
    const content = await findEulaContent(src);
    this.setState({ content, isLoading: false });
  };
}

export default mobxInjectSelect({
  organizationStore: ['findEulaContent'],
})(EulaContent);
