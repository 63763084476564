export default function calculateDownloadSpeed({
  downloadSize,
  startTime,
}: {
  downloadSize: number;
  startTime: number;
}): { speedBps: number; speedKbps: number; speedMbps: number } {
  const duration = (performance.now() - startTime) / 1000;
  const bitsLoaded = downloadSize * 8;
  const speedBps = bitsLoaded / duration;
  const speedKbps = speedBps / 1000;
  const speedMbps = speedKbps / 1000;
  return {
    speedBps,
    speedKbps,
    speedMbps,
  };
}
