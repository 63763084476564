export const handlePaste = (
  addAttachments: (files: File[]) => void,
  event: ClipboardEvent,
  window: Window,
  insertAtCursor?: (text: string) => void
) => {
  const { items = [] } = event.clipboardData || window.clipboardData || { items: [] };
  const files: File[] = [];
  let atLeastOneFilePasteFailed = false;
  let hasRTF = false,
    hasPlainText = false,
    hasHTML = false;

  for (const item of items as DataTransferItem[]) {
    if (item.type === 'text/rtf') {
      hasRTF = true;
    }

    if (item.type === 'text/plain') {
      hasPlainText = true;
    }

    if (item.type === 'text/html') {
      hasHTML = true;
    }

    if (item.kind === 'file') {
      const file = item.getAsFile();

      if (file) {
        files.push(file);
      } else {
        atLeastOneFilePasteFailed = true;
      }
    }
  }

  const isNotAttachment = hasPlainText && hasHTML && insertAtCursor;

  if (hasRTF && files.length > 0 && !isNotAttachment) {
    return;
  }

  if (files.length > 0 && !isNotAttachment) {
    event.preventDefault();
    addAttachments(files);
  }

  if (event.clipboardData && insertAtCursor) {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    try {
      document.execCommand('insertHTML', false, text);
    } catch (e) {
      console.error('unable to call document.execCommand with exception: ', e);
      insertAtCursor(text);
    }
  }

  if (atLeastOneFilePasteFailed) {
    event.preventDefault();
  }
};
