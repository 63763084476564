// @ts-nocheck
import throttle from 'lodash.throttle';
import uuid from 'uuid';
import VisibilityType from '../models/enums/VisibilityType';
import { CLOSE_REASON } from '../models/enums/CloseReason';
import Event from '../models/Event';
import configuration from '../configuration';
import * as errors from '../errors';
import NetworkStatus from '../network/NetworkStatus';
import WebSocketClient, {
  webSocketErrorReportPayload,
} from '../network/WebSocketClient/WebSocketClient';
import { WebSocketError } from '../network/WebSocketClient/WebSocketRequest';
import { arrayWrap, roundWithPrecision } from '../utils';
import BaseService from './BaseService';

const DO_NOT_ACK = ['message:is_typing', 'presence', 'remote_wipe', 'sse:heartbeat'];

const DO_NOT_LOG = ['message:is_typing', 'presence', 'sse:heartbeat'];

const STATIC_EVENT_IDS = ['00000000-0000-0000-0000-000000000001'];

const IMMEDIATE_EVENTS = ['remote_wipe'];

const COMMANDS_TO_RETRY = new Set(['v1_message_status']);
const DEFAULT_MAX_RETRY_COUNT = 3;
const DEFAULT_RETRY_WAIT_TIME = 10 * 1000;
const PERMANENT_FAILURE_CODES = [401, 403];

const DEFAULT_WEBSOCKETS_CLOSE_CODE = 1000;
const MAX_QUEUE_PROCESS_TIME = 75;

const EVENT_FEATURES_BY_CONFIG_KEY = {
  autoDeliver: 'auto-deliver',
  canConfirm: 'can-confirm',
  closeAfterMessages: 'close-after-messages',
  heartbeat: 'heartbeat_5',
  hidden: 'hidden',
  includeDistributionListEvents: 'dl_sender_event',
  isTyping: 'is-typing',
  keepAlive: 'keep_alive_15_1',
  msgDeliveryDelay: 'msg_delivery_delay',
  multiOrg: 'multi-org',
  noOfflineMessages: 'no-offline-messages',
  renderMetadata: 'render_metadata',
  singleConversation: 'single_conversation',
  vwr: 'vwr',
  emojiReactions: 'emoji_reactions',
};

const PROFILE_BY_ENDPOINT_TYPE = {
  events: {
    allowManualRequestBatch: true,
    basicAuthParam: 'auth_basic',
    requiresBasicAuth: false,
    routerName: 'eventRouter',
    url: '/events',
  },

  simpleNotifications: {
    allowManualRequestBatch: false,
    basicAuthParam: 'auth',
    features: {
      heartbeat: true,
    },
    requiresBasicAuth: true,
    routerName: 'notificationRouter',
    url: '/events/simple_notifications',
  },
};

const SSE_CONNECTION_START = 'SSE_CONNECTION_START';
const SSE_CONNECTION_END = 'SSE_CONNECTION_END';
export const SSE_CONNECTION_DURATION = 'SSE_CONNECTION_DURATION';

const EVENTS_CONNECT_INITIATED = 'events.connect::initiated';
const EVENTS_CONNECT_FIRST_MESSAGE = 'events.connect::firstMessage';
const EVENTS_CONNECT_TIME_UNTIL_FIRST_MESSAGE = 'events.connect time until firstMessage';

export const EVENTS_QUEUE_ERROR = {
  PARSE: 'events:queue:error:parse',
  UNKNOWN: 'events:queue:error:unknown',
};

export const WS_CONNECTIONS_CLOSE_COUNT = 'events.ws.connections.closeCount';
export const WS_CONNECTIONS_OVER_LIMIT = 'events.ws.connections.overLimit';
export const POLL_WS_CONNECTIONS_MS = 1000 * 10;
export const POLL_WS_CONNECTION_LIMIT = 5;

export default class EventsService extends BaseService {
  constructor(host, options) {
    super(host, options);

    if (!this.endpointType) this.endpointType = 'events';

    for (const [key, val] of Object.entries(PROFILE_BY_ENDPOINT_TYPE[this.endpointType])) {
      if (key !== 'url') this[key] = val;
    }
  }

  mounted() {
    this._throttled_flushQueuedAcks = throttle(
      this._flushQueuedAcks,
      this.config.flushAckInterval,
      { leading: false }
    );

    this.__allEvents = [];
    this._connectionRetryCount = 0;
    this._webSocketConnections = [];
    this._pollWebSocketConnectionsTimer = null;
    this._eventQueue = new Map();
    this._eventQueueDeferred = false;
    this._eventQueueIsManual = false;
    this._eventQueueTimeout = null;
    this._heartbeatInterval = this.config.eventsHeartbeatIntervalVisible;
    this._heartbeatTimeout = null;
    this._lastHeartbeatTime = null;
    this._openTimeout = null;
    this._pendingAckConnections = 0;
    this._processedEventIds = {};
    this._queuedAcks = [];
    this._reconnectWhenQueuesAreEmpty = false;
    this._reconnectWhenQueuesAreEmpty = false;
    this._retryHistoryTimeout = null;
    this._retryCommandList = new Map();
    this._sendQueue = [];
    this._staticEventCounter = {};
    this._visibilityState = VisibilityType.VISIBLE;
    this.isConnected = false;
    this.isOpen = false;

    if (process.env.NODE_TARGET === 'web') {
      const Visibility = require('visibilityjs');
      this.visibilityListenerId = Visibility.change(this._onVisibilityChange);
    }
  }

  dispose() {
    this._throttled_flushQueuedAcks.cancel && this._throttled_flushQueuedAcks.cancel();
    this._sendQueue = [];

    this.disconnect({ source: 'EventsService cleanup' });

    if (process.env.NODE_TARGET === 'web' && this.visibilityListenerId !== null) {
      const Visibility = require('visibilityjs');
      Visibility.unbind(this.visibilityListenerId);
      this.visibilityListenerId = null;
    }
  }

  get eventSourceIsWebSocket() {
    return this.eventSource && this.eventSource.type === 'websocket';
  }

  get eventSourceIsSSE() {
    return this.eventSource && this.eventSource.type === 'sse';
  }

  get _autoAck() {
    return this.endpointType === 'events' && this.config.autoAckSuccessfullyHandledEvents;
  }

  get hasConnection() {
    return !!this.eventSource;
  }

  get isSignedIn() {
    return !!this._auth;
  }

  get router() {
    return this.host[this.routerName];
  }

  setAuth(auth) {
    this._auth = { basicAuthParam: this.basicAuthParam, ...auth };
  }

  removeAuth() {
    this._auth = null;
  }

  getAuth() {
    return this._auth;
  }

  connect() {
    if (!this.isSignedIn) {
      this._cancelSessionTimers({ source: 'Connection attempt while not signed in' });
      throw new errors.NotSignedInError();
    }

    if (this.requiresBasicAuth && (!this._auth.key || !this._auth.secret)) {
      this._cancelSessionTimers({ source: 'Connection attempt without valid authorization' });
      throw new errors.RequiresBasicAuthError();
    }

    if (this.eventSource) {
      throw new Error('Already connected to EventSource');
    }

    const featuresArray = [];
    const websocketFeaturesObject = {};

    for (const [key, val] of Object.entries(this.features || this.config.events)) {
      if (!val && val !== 0) continue;

      const resolvedKey = EVENT_FEATURES_BY_CONFIG_KEY[key];
      const toPush = val === true ? resolvedKey : `${resolvedKey}:${val}`;
      if (this.config.useWebSockets && resolvedKey === 'keepAlive') continue;
      websocketFeaturesObject[resolvedKey] = val;
      featuresArray.push(toPush);
    }

    const features = featuresArray.join(',');

    this.logger.info(
      `connecting ${this.endpointType}. networkStatus=${this.httpClient.networkStatus} features=${features}`
    );

    const isFreshConnect = !this._watchingNetworkConnection;
    if (this.httpClient.networkStatus !== NetworkStatus.OFFLINE && isFreshConnect) {
      this.httpClient._setNetworkStatus(NetworkStatus.CONNECTING);
    }

    this._watchNetworkConnection();

    if (this.httpClient.networkStatus === NetworkStatus.OFFLINE) {
      throw new Error('Cannot connect while network is offline, but will autoconnect when online');
    }

    this._isClosed = false;
    this._isHeartbeatTimeout = false;
    this._reconnectWhenQueuesAreEmpty = false;
    this._requestedClose = false;
    let eventSource;
    let hasConnected = false;
    let openTimedOut = false;

    this._openTimeout = setTimeout(() => {
      this._openTimeout = null;
      openTimedOut = true;
      if (this.eventSourceIsWebSocket) {
        eventSource.close(DEFAULT_WEBSOCKETS_CLOSE_CODE, 'open timed out');
      } else {
        eventSource.close();
      }
    }, this.config.eventsOpenTimeout);

    performance.mark(EVENTS_CONNECT_INITIATED);

    const params = {
      auth: this._auth,
      features,
      onOpen: () => {
        this.logger.info('open');
        this._cancelSessionTimers({ source: 'Event source opened' });
        this._setHeartbeatTimeout();
        this._restartRetryCommandListTimers();
        hasConnected = true;
        this.isOpen = true;
        this.emit('opened');
        this._processSendQueue();

        if (this.eventSourceIsSSE) {
          performance.mark(SSE_CONNECTION_END);
          const sseConnectionDuration: PerformanceEntry | undefined = performance.measure(
            SSE_CONNECTION_DURATION,
            SSE_CONNECTION_START,
            SSE_CONNECTION_END
          );
          if (sseConnectionDuration) {
            this.emit(SSE_CONNECTION_DURATION, {
              sseConnectionDurationMS: sseConnectionDuration.duration,
            });
          }
        }
      },
      onMessage: (event, { command, isFirstMessage }) => {
        this._setHeartbeatTimeout();

        if (isFirstMessage) {
          this._setRetryHistoryTimeout();
          this.isConnected = true;
          this.emit('connected');

          performance.mark(EVENTS_CONNECT_FIRST_MESSAGE);
          performance.measure(
            EVENTS_CONNECT_TIME_UNTIL_FIRST_MESSAGE,
            EVENTS_CONNECT_INITIATED,
            EVENTS_CONNECT_FIRST_MESSAGE
          );

          if (this.host.config.reportPerformance) {
            const firstSseMessagePerformanceEntry = performance.getEntriesByName(
              EVENTS_CONNECT_TIME_UNTIL_FIRST_MESSAGE
            )[0];
            let firstSseMessageTime = -1;

            if (firstSseMessagePerformanceEntry?.duration !== undefined) {
              firstSseMessageTime = roundWithPrecision(firstSseMessagePerformanceEntry.duration, 3);
            }

            this.host.report.send({
              level: 'info',
              message: EVENTS_CONNECT_TIME_UNTIL_FIRST_MESSAGE,
              payload: {
                firstSseMessage: firstSseMessageTime,
                useWebSockets: this.eventSourceIsWebSocket,
              },
              openTimedOut,
            });
          }
          performance.clearMarks(EVENTS_CONNECT_INITIATED);
          performance.clearMarks(EVENTS_CONNECT_FIRST_MESSAGE);
          performance.clearMeasures(EVENTS_CONNECT_TIME_UNTIL_FIRST_MESSAGE);
        }

        this._queueEvent(event, command);
      },
      onError: (err) => {
        const isUnauthorized = err ? this.httpClient.isUnauthorizedError(err) : false;
        const isHeartbeatTimeout = this._isHeartbeatTimeout;
        const networkStatus = this.httpClient.networkStatus;
        const requestedClose = this._requestedClose;
        const hasSentReport = err?.hasSentReport;

        if (isHeartbeatTimeout) {
          err = new Error(
            `No activity within ${this._heartbeatInterval} milliseconds; reconnecting`
          );
        } else if (openTimedOut) {
          err = new Error(
            `Open timed out after ${this.config.eventsOpenTimeout} milliseconds; reconnecting`
          );
        }

        this.logger.info(
          'error',
          err,
          err.message || '',
          err.code || '',
          err.status !== undefined ? err.status : ''
        );

        this.__allEvents.push({
          clientDisconnect: {
            error: {
              code: err.code,
              message: err.message,
              status: err.status,
            },
            hasConnected,
            isHeartbeatTimeout,
            isUnauthorized,
            networkStatus,
            openTimedOut,
            requestedClose,
            timestamp: new Date(),
          },
        });

        if (!requestedClose) {
          if (
            isUnauthorized &&
            (this.eventSourceIsSSE || this.config.useWebSocketsDisableSSEFallback)
          ) {
            this.disconnect({
              isRecoverable: false,
              source: 'Unauthorized during eventSource error handling',
            });
          } else if (networkStatus !== NetworkStatus.OFFLINE) {
            if (this.eventSourceIsWebSocket && !hasConnected && this._connectionRetryCount === 0) {
              this._webSocketConnectionFailure({ type: 'AuthFailure', hasSentReport });
            } else if (this.eventSourceIsWebSocket) {
              this._webSocketConnectionFailure({
                type: 'ConnectionClosed',
                hasSentReport,
              });
            }

            if (!hasConnected && this._connectionRetryCount === 0) {
              this._connectionRetryCount = 1;
            }

            this._disconnectAndRetry();
          }
        }
      },
    };

    if (this.config.useWebSockets) {
      try {
        this.eventSource = eventSource = new WebSocketClient({
          ...params,
          connections: this._webSocketConnections,
          features: websocketFeaturesObject,
          httpClient: this.httpClient,
          path: this.config.condensedReplays ? '/v5/events/ws' : '/v2/events/ws',
        });
        this._pollWebSocketConnections();
      } catch (err) {
        if (this._openTimeout) {
          clearTimeout(this._openTimeout);
          this._openTimeout = null;
        }

        this._webSocketConnectionFailure({
          type: 'CouldNotInstantiateClient',
          hasSentReport: false,
          err,
        });

        if (!hasConnected && this._connectionRetryCount === 0) {
          this._connectionRetryCount = 1;
        }

        this._disconnectAndRetry();
      }
    }

    if (!this.config.useWebSockets) {
      this.eventSource = eventSource = this.host.eventSourceClient.createEventSource(
        this.url,
        params
      );
      performance.mark(SSE_CONNECTION_START);
      this.eventSource.start();
    }
  }

  _pollWebSocketConnections = () => {
    if (this._pollWebSocketConnectionsTimer) return;

    this._pollWebSocketConnectionsTimer = setTimeout(() => {
      const connections = this._webSocketConnections;
      const latest = connections[connections.length - 1];
      let previous = connections.slice(0, connections.length - 1);

      previous = previous.filter(([socket, _createdAt]) => socket.readyState !== WebSocket.CLOSED);

      if (previous.length > 0) {
        this.emit(WS_CONNECTIONS_CLOSE_COUNT, previous.length);
        previous.forEach(([socket]) => socket.close());
      }

      if (previous.length > POLL_WS_CONNECTION_LIMIT) {
        this.emit(WS_CONNECTIONS_OVER_LIMIT, previous.length);
        previous = previous.slice(previous.length - POLL_WS_CONNECTION_LIMIT);
      }

      this._webSocketConnections = previous.concat([latest]);

      this._pollWebSocketConnectionsTimer = null;
      this._pollWebSocketConnections();
    }, POLL_WS_CONNECTIONS_MS);
  };

  _webSocketConnectionFailure = ({
    type,
    hasSentReport,
    err,
  }: {
    type: WebSocketError;
    hasSentReport?: boolean;
    err?: unknown;
  }) => {
    if (!this.config.useWebSocketsDisableSSEFallback) this.host.configure({ useWebSockets: false });
    const report = webSocketErrorReportPayload({ type, detail: (err && err.stack) || undefined });
    if (!hasSentReport) this.host.report.send(report);
  };

  _scheduleConnectRetry() {
    this._cancelSessionTimers({ source: 'Scheduling connection retry' });
    this.logger.info('scheduleConnectRetry', this._connectionRetryCount);

    const waitTime = Math.min(
      this.config.eventsAutoReconnectDelay * this._connectionRetryCount,
      this.config.eventsAutoReconnectMaxDelay
    );

    this._scheduleConnectRetryTimeout = setTimeout(() => this.connect(), waitTime);
    this._connectionRetryCount++;
  }

  _watchNetworkConnection() {
    if (this._watchingNetworkConnection) return;
    this._watchingNetworkConnection = true;
    this.host.on('networkStatus:change', this.onNetworkStatusChanged);
  }

  _unwatchNetworkConnection() {
    if (!this._watchingNetworkConnection) return;
    this.host.removeListener('networkStatus:change', this.onNetworkStatusChanged);
    this._watchingNetworkConnection = false;
  }

  onNetworkStatusChanged = ({ networkStatus, previousStatus }) => {
    this.__allEvents.push({
      networkStatusChange: {
        previousStatus,
        networkStatus,
        timestamp: new Date(),
      },
    });

    if (networkStatus === NetworkStatus.CONNECTING) {
      const reconnected = this._reconnectIfHeartbeatIntervalPassed();
      if (reconnected && !!this.eventSource) return;

      // pause a couple seconds, since network may not be fully back up yet
      this._connectionRetryCount = 1;
      this._disconnectAndRetry();
    } else if (networkStatus === NetworkStatus.OFFLINE) {
      this._connectionRetryCount = 0;
      this.disconnect({ unwatchNetwork: false, source: 'Network went offline' });
    }
  };

  _cancelSessionTimers({ source } = {}) {
    if (this._heartbeatTimeout) {
      clearTimeout(this._heartbeatTimeout);
      this._heartbeatTimeout = null;
    }

    if (this._openTimeout) {
      clearTimeout(this._openTimeout);
      this._openTimeout = null;
    }

    if (this._scheduleConnectRetryTimeout) {
      this.__allEvents.push({
        cancelSessionTimer: {
          source,
          timestamp: new Date(),
        },
      });

      clearTimeout(this._scheduleConnectRetryTimeout);
      this._scheduleConnectRetryTimeout = null;
    }

    if (this._eventQueueTimeout !== null) {
      clearTimeout(this._eventQueueTimeout);
      this._eventQueueTimeout = null;
    }

    this._clearRetryCommandListTimers();
  }

  _clearRetryCommandListTimers() {
    if (this._retryCommandList.size > 0) {
      this._retryCommandList.forEach((command) => {
        clearTimeout(command.retryTimer);
      });
    }
  }

  _restartRetryCommandListTimers() {
    if (this._retryCommandList.size > 0) {
      this._retryCommandList.forEach((command) => {
        command.retryTimer = setTimeout(() => {
          command.fn();
        }, command.retryWaitTime);
      });
    }
  }

  _setHeartbeatTimeout(duration) {
    if (this._heartbeatTimeout) {
      clearTimeout(this._heartbeatTimeout);
    }

    if (duration !== undefined) {
      this._heartbeatInterval = duration;
    }

    this._heartbeatTimeout = setTimeout(() => {
      this._heartbeatTimeout = null;
      this._isHeartbeatTimeout = true;
      if (this._connectionRetryCount === 0) this._connectionRetryCount = 1;
      this._disconnectAndRetry({ source: CLOSE_REASON.heartbeatTimeout });
    }, this._heartbeatInterval);
  }

  _cancelRetryHistoryTimeout() {
    if (this._retryHistoryTimeout) {
      clearTimeout(this._retryHistoryTimeout);
      this._retryHistoryTimeout = null;
    }
  }

  _setRetryHistoryTimeout() {
    if (this._retryHistoryTimeout) {
      clearTimeout(this._retryHistoryTimeout);
    }

    this._retryHistoryTimeout = setTimeout(() => {
      this._retryHistoryTimeout = null;
      this._connectionRetryCount = 0;
    }, this.config.eventsClearRetryHistoryInterval);
  }

  reconnect() {
    this._connectionRetryCount = 0;
    this.disconnect({ unwatchNetwork: false, source: CLOSE_REASON.reconnect });
    this.connect();
  }

  _disconnectAndRetry({ source = 'Disconnect and retry' } = {}) {
    this.__allEvents.push({
      eventsDisconnectAndRetry: {
        connectionRetryCount: this._connectionRetryCount,
        eventQueueLength: this._eventQueue.size,
        pendingAckConnections: this._pendingAckConnections,
        queuedAcksLength: this._queuedAcks.length,
        timestamp: new Date(),
        useWebSockets: this.eventSourceIsWebSocket,
      },
    });

    this.disconnect({
      clearQueues: false,
      unwatchNetwork: false,
      source,
    });

    if (this.config.eventsAutoReconnect) {
      this._reconnectWhenQueuesAreEmpty = true;
    }

    if (
      this.config.eventsAutoReconnect &&
      this._eventQueue.size === 0 &&
      this._pendingAckConnections === 0 &&
      this._queuedAcks.length === 0
    ) {
      this._scheduleConnectRetry();
    } else if (this.config.eventsAutoReconnect) {
      this._scheduleConnectRetryTimeout = setTimeout(
        () => this.connect(),
        this.config.eventDrainTimeout
      );
    }

    if (this._eventQueue.size > 0) {
      this._scheduleEventQueue({ immediate: true });
    }

    if (this._pendingAckConnections > 0 || this._queuedAcks.length > 0) {
      this._flushQueuedAcks();
    }
  }

  disconnect({ clearQueues = true, isRecoverable = true, unwatchNetwork = true, source } = {}) {
    this.__allEvents.push({
      eventsDisconnect: {
        clearQueues,
        source,
        unwatchNetwork,
      },
    });

    this._cancelSessionTimers({ source: 'Disconnect' });
    this._cancelRetryHistoryTimeout();
    if (unwatchNetwork) this._unwatchNetworkConnection();

    if (this.eventSource) {
      const eventSource = this.eventSource;

      this.eventSource = null;
      this._requestedClose = true;

      if (!this._isClosed) {
        this._isClosed = true;
        if (this.config.useWebSockets) {
          const closeReason = source || CLOSE_REASON.default;
          eventSource.close(DEFAULT_WEBSOCKETS_CLOSE_CODE, closeReason);
        } else {
          eventSource.close();
        }
      }

      this.emit('disconnected', { isRecoverable });
    }

    this.isConnected = false;
    this.isOpen = false;

    if (clearQueues) {
      this._eventQueue = new Map();
      this._queuedAcks = [];
    }
  }

  // not used yet, waiting for server support for simple_notifications in TS-5649
  _closeAllConnections = async () => {
    if (this.eventSource) throw new Error('Existing connections must be closed first');

    if (this.endpointType === 'simpleNotifications') {
      const { key, secret } = this._auth;
      if (!key || !secret) {
        throw new errors.RequiresBasicAuthError();
      }

      await this.host.api.notifications.closeAllConnections(key, secret);
    } else {
      await this.host.api.events.closeAllConnections();
    }
  };

  _getQueueId = (eventName, eventId) => {
    let count = '';

    if (STATIC_EVENT_IDS.includes(eventId)) {
      if (!this._staticEventCounter[eventId]) this._staticEventCounter[eventId] = 0;
      count = `:${++this._staticEventCounter[eventId]}`;
    }

    return `${eventName}:${eventId}${count}`;
  };

  _queueEvent({ data }, wsCommand) {
    if (!data) return;
    let parsedData, eventName, eventId;
    const isImmediate = IMMEDIATE_EVENTS.some((event) => data.includes(event));

    try {
      parsedData = JSON.parse(data);

      if (wsCommand && wsCommand !== 'data') {
        eventName = `${wsCommand}:ws_response`;
        eventId = parsedData.commandId;
      } else {
        const xmlns = Object.keys(parsedData.event)[0];
        eventName = xmlns.replace('tigertext:iq:', '');
        eventId = parsedData.event_id;
      }

      if (eventName === 'roles:processing') {
        this.emit('immediateRolesProcessing', parsedEvent.event['tigertext:iq:roles:processing']);
      }
    } catch (e) {
      const eventSourceType = this.eventSource?.type;
      const event = { eventSourceType, data, error: e?.message ?? 'invalid event data' };
      this.emit(EVENTS_QUEUE_ERROR.PARSE, event);
      this.logger.error(`error parsing ${eventSourceType} payload: <${data}>`);
      if (isImmediate) {
        this.host.signOut();
      } else {
        this._disconnectAndRetry();
      }
      return;
    }

    if (isImmediate) {
      if (IMMEDIATE_EVENTS.includes(eventName)) {
        this.disconnect({ source: `Immediate event ${eventName}` });
        this._processImmediateEvent(eventId, eventName, parsedData);

        return;
      }
    }

    const queueId = this._getQueueId(eventName, eventId);
    this._eventQueue.set(queueId, parsedData);

    this._scheduleEventQueue();
  }

  _processImmediateEvent = async (eventId, eventName, data) => {
    this._eventQueue = new Map([[this._getQueueId(eventName, eventId), data]]);
    this._eventQueueDeferred = false;

    if (this._autoAck) {
      await this.ack([eventId]);
    }

    this.processEventQueue();
  };

  _scheduleEventQueue = ({ immediate = false } = {}) => {
    if (this.allowManualRequestBatch && this.config.manuallyRequestEventQueueBatch) {
      this._eventQueueIsManual = true;
    }

    if (immediate) {
      clearTimeout(this._eventQueueTimeout);
      this._eventQueueTimeout = null;
    }

    if (this._eventQueueTimeout === null) {
      const interval = this.config.eventCollectionInterval;
      if (immediate || interval === null) {
        if (this._eventQueueIsManual) {
          this._emitBatch({ immediate });
        } else {
          this.processEventQueue();
        }
      } else {
        const handler = this._eventQueueIsManual ? this._emitBatch : this.processEventQueue;
        this._eventQueueTimeout = setTimeout(handler, interval);
      }
    }
  };

  _emitBatch = ({ immediate = false } = {}) => {
    this._eventQueueTimeout = null;
    this.emit('batch:available', { immediate });
  };

  deferEventQueue = () => {
    this._eventQueueDeferred = true;

    if (this._eventQueueTimeout) {
      clearTimeout(this._eventQueueTimeout);
      this._eventQueueTimeout = null;
    }
  };

  resumeEventQueue = () => {
    this._eventQueueDeferred = false;

    if (this._eventQueueTimeout) {
      clearTimeout(this._eventQueueTimeout);
      this._eventQueueTimeout = null;
    }

    if (this._eventQueue.size > 0) {
      this._scheduleEventQueue();
    }
  };

  processEventQueue = (maxQueueTime = MAX_QUEUE_PROCESS_TIME) => {
    if (!this._eventQueueIsManual) this._eventQueueTimeout = null;

    if (this._eventQueueDeferred || this._eventQueue.size === 0) {
      return;
    }

    let parsedEvent;
    const startTime = Date.now();
    try {
      for (const [key, event] of this._eventQueue) {
        parsedEvent = event;
        this._eventQueue.delete(key);
        this._handleEvent(parsedEvent);
        if (Date.now() - startTime >= maxQueueTime) break;
      }

      if (this._eventQueue.size > 0) {
        this._scheduleEventQueue();
      } else if (
        this._reconnectWhenQueuesAreEmpty &&
        this._queuedAcks.length === 0 &&
        this._pendingAckConnections === 0
      ) {
        this._scheduleConnectRetry();
      }
    } catch (e) {
      const eventSourceType = this.eventSource?.type;
      const eventToEmit = {
        eventSourceType,
        error: e?.message ?? 'invalid event data',
      };

      this.logger.error(`error while processing ${eventSourceType} payload`, e, e.stack, {
        data: parsedEvent,
      });
      this.emit(EVENTS_QUEUE_ERROR.UNKNOWN, eventToEmit);
      this.disconnect({ isRecoverable: false, source: 'Error processing SSE payload' });
    }
  };

  _handleEvent(data) {
    const eventId = data['event_id'];

    if (!this._autoAck) {
      if (this._processedEventIds[eventId] && !STATIC_EVENT_IDS.includes(eventId)) {
        return;
      }
    }

    if (data.commandId) {
      this._handleCommandResponse({ data });
      return;
    }

    const xmlns = Object.keys(data.event)[0];
    const eventName = xmlns.replace('tigertext:iq:', '');
    const eventData = data.event[xmlns];

    if (this.config.eventsDebugging || !DO_NOT_LOG.includes(eventName)) this.__allEvents.push(data);

    if (eventName === 'sse:heartbeat') {
      this._lastHeartbeatTime = Date.now();
    }

    this.logger.debug('incoming', eventName, eventData);
    const event = new Event(eventId, eventName, eventData);

    try {
      this.router.emit(event);
      this.emit('event', event);

      if (this._autoAck) {
        if (!DO_NOT_ACK.includes(eventName)) {
          this.__queueAck(eventId);
        }
      } else {
        this._processedEventIds[eventId] = true;
      }
    } catch (e) {
      this.logger.error(`error in event ${eventName}`, e, e.stack, { data: eventData });
      this.disconnect({ isRecoverable: false, source: `Error in event ${eventName}` });
    }
  }

  _handleCommandResponse({ data }) {
    if (data.code === 200 || PERMANENT_FAILURE_CODES.includes(data.code)) {
      clearTimeout(this._retryCommandList.get(data.commandId)?.retryTimer);
      this._retryCommandList.delete(data.commandId);
    } else {
      this._iterateRetryCommand(data.commandId);
    }
  }

  __queueAck(eventIds) {
    eventIds = arrayWrap(eventIds);
    this._queuedAcks.push(...eventIds);
    this._throttled_flushQueuedAcks();
  }

  _flushQueuedAcks = async () => {
    if (this._queuedAcks.length === 0) return;

    if (this._pendingAckConnections >= this.config.flushAckMaxConnections) {
      this._throttled_flushQueuedAcks();
      return;
    }

    const queuedAcksLimit = this.eventSourceIsWebSocket
      ? this.config.flushAckMaxEventsWS
      : this.config.flushAckMaxEventsSSE;

    const payload = this._queuedAcks.splice(0, queuedAcksLimit);
    const ackPromise = this.ack(payload);

    if (this._queuedAcks.length > 0) {
      this._throttled_flushQueuedAcks();
    } else {
      try {
        await ackPromise;
      } catch (e) {
        // do nothing
      }

      if (
        this._reconnectWhenQueuesAreEmpty &&
        this._eventQueue.size === 0 &&
        this._pendingAckConnections === 0
      ) {
        this._scheduleConnectRetry();
      }
    }
  };

  _onVisibilityChange = (e, state) => {
    state = state.toUpperCase();
    const previousState = this._visibilityState;
    this._visibilityState = state;

    if (this.isConnected) {
      this.__allEvents.push({
        visibilityChange: {
          previousState,
          state,
          timestamp: new Date(),
        },
      });

      if (state === VisibilityType.HIDDEN) {
        this.host.configure({ eventCollectionInterval: null });
        this._setHeartbeatTimeout(this.config.eventsHeartbeatIntervalInvisible);
      } else if (state === VisibilityType.VISIBLE) {
        this.host.configure({ eventCollectionInterval: configuration.eventCollectionInterval });
        this._setHeartbeatTimeout(this.config.eventsHeartbeatIntervalVisible);
      }
    }

    if (
      previousState === VisibilityType.HIDDEN &&
      state === VisibilityType.VISIBLE &&
      this.httpClient.networkStatus === NetworkStatus.CONNECTING
    ) {
      this._reconnectIfHeartbeatIntervalPassed();
    }

    this.emit('visibilityChange', e, state);
  };

  _reconnectIfHeartbeatIntervalPassed = () => {
    if (this._lastHeartbeatTime === null) return false;

    const timeSinceLastHeartBeat = Date.now() - this._lastHeartbeatTime;
    if (timeSinceLastHeartBeat >= this._heartbeatInterval) {
      this._lastHeartbeatTime = null;
      this.reconnect();

      return true;
    }

    return false;
  };

  async ack(eventIds) {
    if (this.eventSourceIsWebSocket) {
      this.send('v1_ack', { commandId: uuid(), events: eventIds });
      return;
    }

    this.host.requireUser();
    let isUnauthorized = false;

    eventIds = arrayWrap(eventIds);

    this.logger.log('ack', eventIds);
    this._pendingAckConnections++;

    try {
      await this.host.api.events.ack(eventIds);
    } catch (err) {
      isUnauthorized = this.httpClient.isUnauthorizedError(err);
      this.logger.warn('some events not acked', eventIds);
    }

    this._pendingAckConnections--;

    if (isUnauthorized) {
      this.logger.warn('no longer authorized, terminating session');
      this.disconnect({ isRecoverable: false, source: 'Unauthorized during ack' });
    }
  }

  send(command, payload, options) {
    if (this.eventSourceIsSSE) throw new Error('send not supported');

    if (typeof payload !== 'string') {
      payload = JSON.stringify(payload);
    }
    this._sendQueue.push({ command, payload, options });
    this._processSendQueue();
  }

  _addToRetryCommandList(
    command,
    payload,
    { retryWaitTime = DEFAULT_RETRY_WAIT_TIME, maxRetryCount = DEFAULT_MAX_RETRY_COUNT } = {}
  ) {
    if (!payload.commandId) return;

    this._retryCommandList.set(payload.commandId, {
      fn: () => this.send(command, payload),
      retryTimer: setTimeout(() => {
        this.send(command, payload);
      }, retryWaitTime),
      retryCount: 0,
      retryWaitTime,
      maxRetryCount,
    });
  }

  _iterateRetryCommand(commandId) {
    const existingCommand = this._retryCommandList.get(commandId);
    if (!existingCommand) return;
    clearTimeout(existingCommand.retryTimer);

    if (existingCommand.retryCount === existingCommand.maxRetryCount - 1) {
      this._retryCommandList.delete(commandId);

      return;
    }

    this._retryCommandList.set(commandId, {
      ...existingCommand,
      retryTimer: setTimeout(() => {
        existingCommand.fn();
      }, existingCommand.retryWaitTime),
      retryCount: existingCommand.retryCount + 1,
    });
  }

  _processSendQueue() {
    if (!this.eventSource?.hasConnected) return;
    while (this._sendQueue.length > 0) {
      const { command, payload, options } = this._sendQueue.shift();

      try {
        this.eventSource.send(`${command}: ${payload}`);
        if (COMMANDS_TO_RETRY.has(command)) {
          const parsedPayload = JSON.parse(payload);
          if (this._retryCommandList.has(parsedPayload.commandId)) {
            this._iterateRetryCommand(parsedPayload.commandId);
          } else {
            this._addToRetryCommandList(command, parsedPayload, options);
          }
        }
      } catch (e) {}
    }
  }

  get url() {
    const url = PROFILE_BY_ENDPOINT_TYPE[this.endpointType].url;
    let version;

    if (this.endpointType === 'events') {
      version = this.config.condensedReplays ? 'v5' : 'v2';
    } else {
      version = 'v2';
    }

    return `/${version}${url}`;
  }
}
