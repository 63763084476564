import { B } from 'b_';

const prefixedB = B({ modSeparator: '--', modValueSeparator: '--' });

// usage:
//   in the root of the app, define the prefix
//   import { setPrefix } from '../path/to/bem'
//   setPrefix('tc')
//
//   in a component:
//     import BEM from '../path/to/bem'
//     const classes = BEM.with('UserProfile')
//
//     <div className={classes()}> // tc-UserProfile
//
//     <div className={classes({ cool: 'stuff' })}> // tc-UserProfile tc-UserProfile--cool--stuff
//       <div className={classes('avatar')}> // tc-UserProfile__avatar

const oldWith = prefixedB.with;
const blockFormatterWith = oldWith.bind(prefixedB);
prefixedB.with = prefixedB.lock = (name: string) => blockFormatterWith(`${_prefix}-${name}`);

let _prefix: string;

export function setPrefix(prefix: string) {
  _prefix = prefix;
}

export default prefixedB;
