import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';

import mobxInjectSelect from '../common/utils/mobxInjectSelect';
import AppStateProvider from './state';
import { VideoProvider } from './components/VideoProvider';
import theme from './theme';
import ActiveCall from './views/ActiveCall';
import IncomingCall from './views/IncomingCall';

function VideoCallContainer({
  currentCall,
  endVoipCall,
  getUserInfo,
  localTracksObject,
  sendDataMessage,
  shouldAnswerWithCamOn,
}) {
  if (!currentCall) return null;
  const { callStatus, room } = currentCall;
  const canUseVideo = currentCall.payload.isVideo;
  if (callStatus === 'incoming' && !room) {
    return <IncomingCall />;
  } else if (room) {
    return (
      <AppStateProvider
        {...{
          currentCall,
          endVoipCall,
          getUserInfo,
          sendDataMessage,
        }}
      >
        <MuiThemeProvider theme={theme}>
          <VideoProvider
            localTracksObject={localTracksObject}
            canUseVideo={canUseVideo}
            onError={() => {}}
            shouldAnswerWithCamOn={shouldAnswerWithCamOn && canUseVideo}
          >
            <ActiveCall />
          </VideoProvider>
        </MuiThemeProvider>
      </AppStateProvider>
    );
  } else {
    return null;
  }
}

export default mobxInjectSelect({
  callStore: [
    'currentCall',
    'endVoipCall',
    'getUserInfo',
    'localTracksObject',
    'sendDataMessage',
    'shouldAnswerWithCamOn',
  ],
})(VideoCallContainer);
