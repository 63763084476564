import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { Modal, Scrollbars, Timestamp } from './';

const classes = BEM.with('MessageInfoModal');

class MessageInfoModal extends Component {
  static propTypes = {
    currentConversation: propTypes.conversation,
    currentMessageInfo: propTypes.message,
    ensureRecipientStatus: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { currentMessageInfo, ensureRecipientStatus } = this.props;

    if (currentMessageInfo) {
      ensureRecipientStatus(currentMessageInfo.id, { includeUsers: true });
    }
  }

  render() {
    const { currentConversation, currentMessageInfo: message, isOpen } = this.props;
    if (!currentConversation) return null;

    const { counterParty, counterPartyType } = currentConversation;
    let recipients;

    if (message && counterPartyType === 'group') {
      if (counterParty.groupType === 'ROLE_P2P') {
        const { p2pSender, p2pRecipient } = counterParty;

        let recipientName;
        if (message.isOutgoing) {
          if (p2pRecipient.$entityType === 'user') {
            recipientName = p2pRecipient.displayName;
          } else if (p2pRecipient.$entityType === 'role') {
            let membersName;
            if (p2pRecipient && p2pRecipient.members && p2pRecipient.members.length > 0) {
              membersName = p2pRecipient.members[0].displayName;
            } else {
              membersName = 'No one is on duty';
            }
            recipientName = `${p2pRecipient.displayName} (${membersName})`;
          }
        } else {
          if (p2pSender.$entityType === 'user') {
            recipientName = p2pSender.displayName;
          } else if (p2pSender.$entityType === 'role') {
            recipientName = `${p2pSender.displayName} (${p2pSender.members[0].displayName})`;
          }
        }

        recipients = [recipientName];
      } else {
        if (counterParty.members) {
          recipients = counterParty.members
            .filter((recipient) => recipient.displayName !== '')
            .map(
              (recipient) =>
                recipient.displayName + (recipient.username ? ` (${recipient.username})` : '')
            );
        }
      }
    }

    return (
      <Modal
        bodyClass={classes('body')}
        isOpen={isOpen}
        header="Message Details"
        size={'medium-large'}
        className={classes()}
        onRequestClose={this._closeModal}
        footerPrimaryActions={
          <button type="button" className={classes('done-btn')} onClick={this._closeModal}>
            DONE
          </button>
        }
      >
        {message && (
          <div className={classes('content-outer')}>
            <Scrollbars autoHide={false} scrollOverflowAmount={10} shouldScrollOverflow={true}>
              {this._renderBody({ message, recipients })}
            </Scrollbars>
          </div>
        )}
      </Modal>
    );
  }

  _closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  _renderBody = ({ message, recipients }) => {
    const { originalSender, originalSenderRole, sender, senderRole, statusesPerRecipient } =
      message;
    let originalSenderName = originalSender ? originalSender.displayName : '';
    let recipientStatus;
    let senderName = sender ? sender.displayName : '';

    if (originalSenderRole) {
      originalSenderName = `${originalSenderRole.displayName} (${originalSenderName})`;
    }

    if (statusesPerRecipient?.length === 0) {
      recipientStatus = 'NA';
    } else {
      recipientStatus = statusesPerRecipient[0].status;
    }

    if (senderRole) {
      senderName = `${senderRole.displayName} (${senderName})`;
    }

    return (
      <div className={classes('content-inner')}>
        <div className={classes('line')}>
          <span className={classes('title')}>Message ID: </span>
          <span className={classes('value')}>{message.serverId || `${message.id} (local)`}</span>
        </div>

        {senderName && (
          <div className={classes('line')}>
            <span className={classes('title')}>Sender Name: </span>
            <span className={classes('value')}>{senderName}</span>
          </div>
        )}

        {message.sender && message.sender.username && (
          <div className={classes('line')}>
            <span className={classes('title')}>Sender User Name: </span>
            <span className={classes('value')}>{message.sender.username}</span>
          </div>
        )}

        {originalSenderName && (
          <div className={classes('line')}>
            <span className={classes('title')}>Original Sender Name: </span>
            <span className={classes('value')}>{originalSenderName}</span>
          </div>
        )}

        {message.counterPartyType === 'group' && (
          <div className={classes('line')}>
            <span className={classes('title')}>
              Recipient {pluralize('Name', recipients?.length)}:{' '}
            </span>
            <span className={classes('value')}>{recipients?.join(', ')}</span>
          </div>
        )}

        {message.counterPartyType === 'user' && (
          <div className={classes('line')}>
            <span className={classes('title')}>Recipient: </span>
            <span className={classes('value')}>{message.recipient.displayName}</span>
          </div>
        )}

        {message.counterPartyType === 'distributionList' && message.counterParty && (
          <div className={classes('line')}>
            <span className={classes('title')}>Recipient: </span>
            <span className={classes('value')}>{message.counterParty.displayName}</span>
          </div>
        )}

        <div className={classes('line')}>
          <span className={classes('title')}>Created: </span>
          <span className={classes('value')}>
            <Timestamp value={message.createdAt} format="MMM DD, YYYY HH:mm:ss" />
          </span>
        </div>

        {message.counterPartyType === 'user' && (
          <div className={classes('line')}>
            <span className={classes('title')}>Status: </span>
            <span className={classes('value')}>
              {this._lowerCaseAllWordsExceptFirstLetters(recipientStatus)}
            </span>
          </div>
        )}

        <div className={classes('line')}>
          <span className={classes('title')}>TTL: </span>
          <span className={classes('value')}>{message.ttl}</span>
        </div>
      </div>
    );
  };

  _lowerCaseAllWordsExceptFirstLetters = (string) => {
    return string.replace(/\w\S*/g, function (word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  };
}

export default mobxInjectSelect({
  conversationStore: ['currentConversation'],
  messageStore: ['currentMessageInfo', 'ensureRecipientStatus'],
})(MessageInfoModal);
