// @ts-nocheck
const SECONDS_IN_A_DAY = 86400;

export function formatDob(dob) {
  if (!dob) return dob;

  const [dateOnly] = dob.split('T');

  if (dateOnly.includes('-')) {
    const [year, day, month] = dateOnly.split('-');
    return [day, month, year].join('/');
  } else if (dateOnly.includes('/')) {
    const [day, month, year] = dateOnly.split('/');
    return [day, month, year].join('/');
  } else {
    return dob;
  }
}

export function secondsToDurationFormat(seconds) {
  if (!seconds || !parseInt(seconds)) {
    return '';
  }

  const durationInSeconds = parseInt(seconds);
  const days = Math.floor(durationInSeconds / SECONDS_IN_A_DAY);
  const secondsMod = durationInSeconds % SECONDS_IN_A_DAY;
  const dateObj = new Date(0);
  dateObj.setSeconds(secondsMod);
  const [hours, mins, secs] = dateObj
    .toISOString()
    .substr(11, 8)
    .split(':')
    .map((x) => parseInt(x));

  let timeStr = secs + ' sec';
  if (mins || hours || days) timeStr = `${mins} min ${timeStr}`;
  if (hours || days) timeStr = `${hours + days * 24} hr ${timeStr}`;

  return timeStr;
}
