import React from 'react';
import Dropzone from 'react-dropzone';

import BEM from '../../../../common/bem';
import NewConversation from '../../../../common/components/NewConversation';
import { attachments, mobxInjectSelect } from '../../../../common/utils';
import DotsIndicator from '../../../../common/components/DotsIndicator';
import type { Entity, Conversation } from '../../../../types';
import {
  ConversationHeader,
  CurrentConversation,
  DndBanner,
  DragModal,
  ExistingConvMessageForm,
  NewConvMessageForm,
  NewMessageHeader,
  NewMessageSearchResult,
  NullDropzone,
} from './';
import { useAppSelector } from 'redux-stores';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('ConversationPane');

type MobxProps = {
  addAttachments: unknown;
  counterPartyProfile?: { displayName: string; id: string };
  currentConversation: Conversation;
  currentOrganizationId: string;
  getAutoForwardEntityDisplayName: (entities: string[]) => string;
  hasCurrentConversation: boolean;
  isAvailabilityFeatureFlagEnabled: boolean;
  isComposeTo: boolean;
  isComposing: boolean;
  isExtendedAutoForwardOptionsEnabled: boolean;
  isInfoPaneOpen: boolean;
  isProviderNetwork: boolean;
  isRecipientPickerOpen: boolean;
  isSwitchingApp: boolean;
  rejectAttachments: unknown;
  selectedFilter: unknown;
  selectedRecipients: Entity[];
  hideMessageMultiSelect: () => void;
};

type ConversationPaneProps = {
  hideHeader: boolean;
  hideFooter: boolean;
};

function ConversationPane({
  addAttachments,
  counterPartyProfile,
  currentConversation,
  currentOrganizationId,
  getAutoForwardEntityDisplayName,
  hasCurrentConversation,
  hideFooter = false,
  hideHeader = false,
  isAvailabilityFeatureFlagEnabled,
  isComposeTo,
  isComposing,
  isExtendedAutoForwardOptionsEnabled,
  isInfoPaneOpen,
  isProviderNetwork,
  isRecipientPickerOpen,
  isSwitchingApp,
  rejectAttachments,
  selectedFilter,
  selectedRecipients,
  hideMessageMultiSelect,
}: ConversationPaneProps & MobxProps) {
  const { accessibilityMode } = useAppSelector(({ ui }) => ({
    accessibilityMode: ui.accessibilityMode,
  }));

  if (isSwitchingApp) {
    return (
      <div className={classes()}>
        <div className={classes('loading')}>
          <DotsIndicator />
        </div>
      </div>
    );
  }

  let Header: React.ComponentType;
  let Content: React.ComponentType;
  let Footer: React.ComponentType;
  if (isComposeTo) {
    Header = NewMessageHeader;
    Content = NewConversation;
    Footer = NewConvMessageForm;
  } else if (isComposing) {
    Header = NewMessageSearchResult;
    Content = NewConversation;
    Footer = NewConvMessageForm;
  } else if (hasCurrentConversation) {
    Header = ConversationHeader;
    Content = CurrentConversation;
    Footer = ExistingConvMessageForm;
  } else {
    return (
      <NullDropzone
        className={classes()}
        innerClassName={classes('placeholder-container')}
        disabled={accessibilityMode}
      >
        {selectedFilter !== 'Teams' && (
          <p className={classes('placeholder-text')}>No conversation selected</p>
        )}
      </NullDropzone>
    );
  }

  return (
    <main
      role="main"
      aria-labelledby="conversation-heading"
      className={classes()}
      onKeyDown={(e) => {
        if (accessibilityMode && e.key === KEYMAP.ESCAPE) {
          hideMessageMultiSelect();
        }
      }}
    >
      <h2 id="conversation-heading" className="screen-reader-only">
        Conversation with {currentConversation?.counterParty?.name}
      </h2>
      <Dropzone
        className={classes('contents')}
        accept={(attachments as { allContentTypes: string[] }).allContentTypes.join(',')}
        disableClick
        disablePreview
        multiple={true}
        onDropAccepted={addAttachments}
        onDropRejected={rejectAttachments}
        disabled={accessibilityMode}
      >
        {({ isDragActive = false, isDragReject = false } = {}) => (
          <div className={classes('layout')}>
            <DragModal {...{ isDragActive, isDragReject }} />
            {!hideHeader && (
              <div
                className={classes('header', {
                  isInfoPaneOpen,
                })}
                data-test-id="conversation-header"
              >
                <Header />
                {isAvailabilityFeatureFlagEnabled && isProviderNetwork && (
                  <DndBanner
                    counterParty={currentConversation?.counterParty}
                    counterPartyProfile={counterPartyProfile}
                    currentOrganizationId={currentOrganizationId}
                    getAutoForwardEntityDisplayName={getAutoForwardEntityDisplayName}
                    hasCurrentConversation={hasCurrentConversation}
                    isComposing={isComposing}
                    isExtendedAutoForwardOptionsEnabled={isExtendedAutoForwardOptionsEnabled}
                    isRecipientPickerOpen={isRecipientPickerOpen}
                    selectedRecipients={selectedRecipients}
                  />
                )}
              </div>
            )}
            <Content />
            {!hideFooter && <Footer />}
          </div>
        )}
      </Dropzone>
    </main>
  );
}

export default mobxInjectSelect<ConversationPaneProps, MobxProps>({
  composeMessageStore: [
    'addAttachments',
    'isComposing',
    'isComposeTo',
    'isRecipientPickerOpen',
    'rejectAttachments',
    'selectedRecipients',
  ],
  conversationStore: ['hasCurrentConversation', 'currentConversation', 'counterPartyProfile'],
  rosterStore: ['selectedFilter'],
  networkStore: ['isProviderNetwork'],
  messengerStore: [
    'isAvailabilityFeatureFlagEnabled',
    'isExtendedAutoForwardOptionsEnabled',
    'isInfoPaneOpen',
    'isSwitchingApp',
    'currentOrganizationId',
    'hideMessageMultiSelect',
  ],
  userStore: ['getAutoForwardEntityDisplayName'],
})(ConversationPane);
