// @ts-nocheck
export default function (store, { host }) {
  const MessageTemplate = store.defineModel('messageTemplate', {
    replaceKeys: {
      createdBy: 'createdById',
      templateLabel: 'title',
      updatedBy: 'updatedById',
    },

    transientAttrs: ['$deleted'],

    parseAttrs(attrs) {
      attrs.hasAttachment = false;
      if (attrs.attachmentName && attrs.attachmentSize && attrs.attachmentType) {
        attrs.hasAttachment = true;
      }

      return attrs;
    },

    relations: {
      belongsTo: {
        createdBy: { type: 'user', foreignKey: 'createdById' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        updatedBy: { type: 'user', foreignKey: 'updatedById' },
      },
    },
  });

  return MessageTemplate;
}
