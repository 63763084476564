import { isObject, merge, reduce } from 'lodash';

export default function flattenKeys(
  obj: Record<string, unknown> | unknown,
  path: string[] = []
): Record<string, unknown> {
  return !isObject(obj)
    ? { [path.join('.')]: obj }
    : reduce(obj, (cum, next, key) => merge(cum, flattenKeys(next, [...path, key])), {});
}
