import { Bounce } from '../../models/enums';
const SOUND_OPTIONS_DEFAULT = 0;

export const settingsValueToObject = (value) => {
  if (!value) {
    return {
      bounceDockIcon: Bounce.UNREAD,
      customSound: SOUND_OPTIONS_DEFAULT,
      showMessageBody: false,
      playSounds: true,
      showNotifications: true,
      toastTimeout: 0,
    };
  }

  return {
    bounceDockIcon: value.length >= 5 ? value[4] : Bounce.UNREAD, // desktopAttention()
    customSound: value.length >= 6 ? value[5].charCodeAt() - 65 : SOUND_OPTIONS_DEFAULT,
    showMessageBody: value.length >= 4 ? value[3] === '1' : false, // activeContents()
    playSounds: value[1] === '1', // activeTones()
    showNotifications: value[0] === '1', // activeNotes()
    toastTimeout: value[2].charCodeAt() - 65, // noteTimeout((
  };
};

export const settingsObjectToValue = (settingsObject) => {
  if (!settingsObject) settingsObject = {};

  return [
    settingsObject.showNotifications ? '1' : '0',
    settingsObject.playSounds ? '1' : '0',
    String.fromCharCode(settingsObject.toastTimeout + 65),
    settingsObject.showMessageBody ? '1' : '0',
    settingsObject.bounceDockIcon,
    String.fromCharCode(settingsObject.customSound + 65),
  ].join('');
};
