import { sdkApi } from '../api';
import { SearchTypes } from 'models/enums';
import TCClient from 'client';
import { SearchArgType, SearchResults } from 'types/api';
import { PatientEntity } from 'types';

export type PatientResult = {
  entity: PatientEntity;
  entityId: string;
};

const patientsApi = sdkApi.injectEndpoints({
  endpoints: (build) => ({
    getPatients: build.query<SearchResults<PatientResult>, SearchArgType>({
      queryFn: async ({ searchValue, orgId, continuation }, _queryApi) => {
        const res = await TCClient.search.query<PatientResult>({
          version: 'LEGACY',
          query: { 'displayName,name,patient_mrn': searchValue },
          organizationId: orgId,
          types: [SearchTypes.PATIENT],
          sort: ['displayName'],
          continuation: continuation || '',
        });

        return { data: res };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { searchValue, orgId } = queryArgs;
        return { searchValue, orgId };
      },
      merge: (currentCache, newItems) => {
        if (newItems.metadata.firstHit === 1) {
          currentCache.results = newItems.results;
          currentCache.metadata = newItems.metadata;
        } else {
          currentCache.results.push(...newItems.results);
          currentCache.metadata = newItems.metadata;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetPatientsQuery } = patientsApi;
