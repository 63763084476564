import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BEM from '../bem';
import { useInfiniteSearchResults } from '../hooks';
import propTypes from '../propTypes';
import { highlightText, mobxInjectSelect } from '../utils';

import { ReactComponent as BroadcastSvg } from '../images/broadcast.svg';
import { ReactComponent as PatientIcon } from '../images/patient-blue.svg';
import { DotsIndicator, SimpleMenu } from './';

const SEARCH_RESULT = 'SEARCH_RESULT';
const SELECTED_ITEM = 'SELECTED_ITEM';

const classes = BEM.with('PatientBroadcastListSearchResultDetails');

const PatientBroadcastListSearchResultDetails = ({
  context = SEARCH_RESULT,
  entity,
  loadBroadcastListMembers,
  searchTerms = [],
}) => {
  const { error, isLoading, results, resetSearch, scrollContainerRef, updateOptions } =
    useInfiniteSearchResults(loadBroadcastListMembers, { id: entity.id });
  const [canViewList, setCanViewList] = useState(true);

  const reloadSearch = (options, refreshSearch = true) => {
    resetSearch();
    updateOptions(options, refreshSearch);
  };

  const handleOpen = () => {
    if (results.length === 0) reloadSearch();
  };

  useEffect(() => {
    reloadSearch(
      {
        id: entity.id,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id]);

  useEffect(() => {
    setCanViewList(!('raw' in error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.raw]);

  const buttonFragment = <span className={classes('view-list-btn')}>View List</span>;

  const menuFragment = (
    <div className={classes('member-list')} ref={scrollContainerRef}>
      <div className={classes('members-header')}>
        <span className={classes('members-name')}>Members</span>
      </div>
      <div className={classes('members-container')}>
        {results.length > 0 &&
          results.map((member) => (
            <div key={member.id} className={classes()}>
              <div className={classes('member-container')}>
                <div>
                  <PatientIcon />
                </div>
                <div className={classes('member-details-list')}>
                  <div className={classes('member-display-name')}>
                    <span className={classes('member-name')}>
                      <span title={member.displayName}>{member.displayName}</span>
                    </span>
                    <span className={classes('member-relation')}>
                      {member.isPatientContact && (
                        <span title={member.patientContact.relation}>
                          {' '}
                          ({member.patientContact.relation}){' '}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className={classes('member-information')}>
                    {member.isPatientContact && (
                      <div className={classes('patient-name')}>
                        <span
                          className={classes('patient-member-name')}
                          title={member.patient.user.displayName}
                        >
                          {' '}
                          {member.patient.user.displayName}{' '}
                        </span>
                      </div>
                    )}
                    <div className={classes('member-details')}>
                      <div className={classes('member-mrn')}>
                        MRN <span title={member.patient.mrn}>{member.patient.mrn}</span>
                      </div>
                      <div>
                        | DOB <span title={member.patient.dob}>{member.patient.dob}</span>
                      </div>
                      <div> &nbsp;| {member.patient.gender}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {!isLoading && results.length === 0 && (
          <div className={classes('no-results-container')}>
            No members found in the broadcast list.
          </div>
        )}
        {isLoading && (
          <div className={classes('loading')}>
            <DotsIndicator color={'#969696'} size={13} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={classes('', { context })}>
      <div className={classes('wrapper', { context })}>
        <BroadcastSvg className={classes('broadcast-avatar')} />
        <div className={classes('info-container')}>
          <div className={classes('name')}>
            {highlightText(entity.displayName, searchTerms, classes)}
          </div>
          <div className={classes('broadcast-list-details')}>
            <div className={classes('member-count')}>{entity.memberCount} People</div>
            <div className={classes('show-member-list')}>
              {canViewList && context === SELECTED_ITEM && (
                <SimpleMenu button={buttonFragment} menu={menuFragment} handleOpen={handleOpen} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PatientBroadcastListSearchResultDetails.propTypes = {
  context: PropTypes.oneOf([SEARCH_RESULT, SELECTED_ITEM]),
  entity: propTypes.distributionList.isRequired,
  loadBroadcastListMembers: PropTypes.func.isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string),
};

export default mobxInjectSelect({
  patientAdminStore: ['loadBroadcastListMembers'],
})(PatientBroadcastListSearchResultDetails);
