import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ScheduleOption } from '../../../models/enums';
import { DeliveryMethods } from '../../../models/enums/DeliveryMethods';
import { mobxInjectSelect, scheduledMessageFormats } from '../../utils';
import BEM from '../../bem';
import propTypes from '../../propTypes';
import { ReactComponent as MessageStatus } from '../../images/scheduled-message-status.svg';
import { ReactComponent as PreferencesSvg } from '../../images/scheduled-message-preferences.svg';
import { ReactComponent as ScheduleRecipientSvg } from '../../images/schedule-recipient-icon.svg';
import { ReactComponent as ScheduleMessageHeaderSvg } from '../../images/schedule-message-header-icon.svg';
import { ReactComponent as ScheduleMessageSvg } from '../../images/schedule-message-content-icon.svg';
import { ReactComponent as RepeatingSvg } from '../../images/repeating-message.svg';
import { ReactComponent as ScheduleSenderSvg } from '../../images/schedule-message-provider-sender.svg';
import { ReactComponent as ScheduleSvg } from '../../images/schedule-icon.svg';
import { DeliveryMethodIcon } from '../PatientSettings/DeliveryMethodIcon';
import { Status } from '../PatientSettings/ScheduledMessages/ScheduleMessage';
import { DeliveryMethod } from '../ScheduleMessageModal/DeliveryMethodScheduledMessageMenu';
import {
  AttachmentIcon,
  Modal,
  PatientBroadcastListSearchResultDetails,
  PatientSearchResultHeader,
  Scrollbars,
} from '../';

const { LINK, SMS } = DeliveryMethods;
const { DATE_FORMAT_WITH_DAY, DATE_FORMAT_WITH_DAY_AND_TIME } = scheduledMessageFormats;

const classes = BEM.with('ScheduleMessageReviewModal');
const successModalClasses = BEM.with('ScheduleMessageSuccessModal');

const ScheduleMessageReviewModal = ({
  allowPatientDeliveryMethod,
  closeModal,
  createScheduledMessage,
  editScheduledMessage,
  findScheduledMessageInHistory,
  isOpen,
  openModal,
  options,
  resetScheduledMessageState,
  scheduledMessage,
  setIsBackButtonPressed,
}) => {
  const [scheduledMessageHistoryDeliveryMethod, setScheduledMessageHistoryDeliveryMethod] =
    useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    appointmentId,
    attachments,
    body,
    deliveryMethod,
    noReply,
    recipients,
    recipient,
    recipientType,
    repeatEnabled,
    repeatSchedule,
    scheduleOption,
    selectedEndDate,
    selectedTemplate,
    selectedTime,
    scheduledTime,
    sender,
    status,
    timezone,
  } = scheduledMessage;
  const {
    category,
    id,
    isEditScheduleMessage,
    isTemplateChanged,
    scheduledMessageView,
    setIsTemplateChanged,
  } = options;
  const isPatientBroadcastList =
    (recipients.length > 0 && recipients[0].$entityType === 'distributionList') ||
    (scheduledMessageView && recipientType === 'distributionList');
  const [isUnChecked, setIsUnChecked] = useState(true);
  const isSecureMessage =
    deliveryMethod === LINK ||
    (scheduledMessageHistoryDeliveryMethod === LINK && category === 'history');
  let footerAgreementFragment,
    footerPrimaryFragment,
    footerSecondaryFragment,
    noReplyFragment,
    scheduleFragment,
    senderFragment,
    statusFragment,
    headingFragment;

  useMemo(async () => {
    if (category === 'history') {
      const { id } = options;
      const { deliveryMethod } = await findScheduledMessageInHistory(id, category);
      setScheduledMessageHistoryDeliveryMethod(deliveryMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const _generateSuccessContent = () => {
    const { scheduleOption } = scheduledMessage;

    return (
      <div className={successModalClasses()}>
        <div className={successModalClasses('success-header')}>Success!</div>
        <div>Message {scheduleOption === ScheduleOption.SEND_NOW ? 'Sent' : 'Scheduled'}</div>
      </div>
    );
  };

  const _confirmEditedMessageChanges = async () => {
    const { id } = scheduledMessage;
    try {
      await editScheduledMessage(id, isTemplateChanged);
      openModal('createSuccessModal', {
        content: _generateSuccessContent(),
      });
      resetScheduledMessageState();
    } catch (err) {
      console.error(err);
      openModal('patientAdminFailure', {
        reopenModal: '',
        isEditScheduledMessage: true,
        messageId: id,
        body: 'Unable to update message. Please try again.',
      });
    }
  };

  const _editMessage = () => {
    openModal('editScheduleMessage', scheduledMessage);
    setIsBackButtonPressed(true);
  };

  const _closeModal = async () => {
    const { reopenModal = 'scheduleMessage', onClose } = options;

    closeModal();
    if (setIsTemplateChanged) setIsTemplateChanged(false);

    if (reopenModal) {
      openModal(reopenModal, { onClose });
    }
  };

  const _createScheduledMessage = async () => {
    const { onClose, reopenModal = 'scheduleMessage' } = options;
    setIsLoading(true);

    try {
      await createScheduledMessage();
      setIsLoading(false);
      openModal('createSuccessModal', {
        content: _generateSuccessContent(),
        onClose,
      });
      resetScheduledMessageState();
    } catch (err) {
      console.error(err);
      openModal('patientAdminFailure', {
        reopenModal,
        body: 'Unable to schedule message. Please try again.',
        onClose,
      });
    }
  };

  if (scheduleOption === ScheduleOption.SEND_NOW) {
    scheduleFragment = <div className={classes('send-now')}>Send Now</div>;
  } else {
    let scheduledTimeFragment;
    if (selectedTime) {
      scheduledTimeFragment = <div>{selectedTime?.format(DATE_FORMAT_WITH_DAY_AND_TIME)}</div>;
    } else {
      scheduledTimeFragment = (
        <div>
          <span className={classes('schedule-header')}>Schedule </span>
          {scheduledTime?.format(DATE_FORMAT_WITH_DAY_AND_TIME)}
        </div>
      );
    }
    scheduleFragment = (
      <React.Fragment>
        <div className={classes('start-and-time')}>
          <div className={classes('start-schedule')}>{scheduledTimeFragment}</div>
          <div className={classes('timezone-abbr', { scheduledMessageView })}>
            {(selectedTime || scheduledTime)?.tz(timezone)?.zoneAbbr()}
          </div>
        </div>
        {repeatEnabled && (
          <div className={classes('repeating-section')}>
            <div className={classes('repeat-schedule')}>
              <div className={classes('repeat-schedule-svg')}>
                <RepeatingSvg />
              </div>
              <div className={classes('repeat-schedule-details')}>
                {repeatSchedule} Until {selectedEndDate?.format(DATE_FORMAT_WITH_DAY)}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
  if (scheduledMessageView) {
    headingFragment = (
      <div className={classes('header-content')}>
        <ScheduleMessageHeaderSvg />
        <p>Scheduled Message</p>
      </div>
    );
    statusFragment = (
      <div className={classes('section')}>
        <MessageStatus />
        <div className={classes('section-container')}>
          <div className={classes('schedule-section')}>
            <div className={classes('send-now')}>
              <Status isAutomated={!!appointmentId} status={status} />
            </div>
          </div>
        </div>
      </div>
    );
    senderFragment = (
      <div className={classes('section')}>
        <ScheduleSenderSvg />
        <div className={classes('section-container')}>
          <div className={classes('schedule-section')}>
            <div className={classes('send-now')}>
              <span className={classes('send-now-from')}>From</span>{' '}
              <span className={classes('send-now-sender')}>{sender?.displayName || sender}</span>
            </div>
          </div>
        </div>
      </div>
    );
    noReplyFragment = (
      <span className={classes('no-reply-container', { scheduledMessageView })}>
        {noReply ? 'ON' : 'OFF'}
      </span>
    );
    if (isEditScheduleMessage) {
      footerPrimaryFragment = (
        <button
          type="button"
          className={classes('primary-btn')}
          onClick={_confirmEditedMessageChanges}
        >
          CONFIRM
        </button>
      );
      footerSecondaryFragment = (
        <button
          className={classes('secondary-btn')}
          data-test-id={'back-button'}
          type="button"
          onClick={_editMessage}
        >
          BACK
        </button>
      );
    } else {
      footerPrimaryFragment = (
        <button type="button" className={classes('primary-btn')} onClick={_closeModal}>
          CLOSE
        </button>
      );
    }
  } else {
    headingFragment = (
      <div className={classes('header-content')}>
        <ScheduleMessageHeaderSvg />
        <p>Review</p>
      </div>
    );
    footerPrimaryFragment = (
      <button
        type="button"
        className={classes('primary-btn')}
        data-test-id={'confirm-button'}
        disabled={isLoading || (deliveryMethod === SMS && isUnChecked)}
        onClick={_createScheduledMessage}
      >
        {isLoading ? 'SUBMITTING' : 'CONFIRM'}
      </button>
    );
    footerSecondaryFragment = (
      <button
        className={classes('secondary-btn')}
        data-test-id={'back-button'}
        type="button"
        onClick={_closeModal}
      >
        BACK
      </button>
    );
    noReplyFragment = (
      <span className={classes('no-reply-container', { scheduledMessageView })}>
        {noReply ? 'On' : 'Off'}
      </span>
    );
  }

  if (!id && deliveryMethod === SMS) {
    footerAgreementFragment = (
      <div className={classes('message-unsecured-note')}>
        <input
          type="checkbox"
          className={classes('message-unsecured-note-checkbox')}
          id="messageUnsecuredNoteCheckbox"
          onClick={() => setIsUnChecked(!isUnChecked)}
        />
        <label
          className={classes('message-unsecured-note-message')}
          htmlFor="messageUnsecuredNoteCheckbox"
        >
          SMS messages are unencrypted and unsecured. I confirm that the content above does not
          contain any PHI.
        </label>
      </div>
    );
  }

  return (
    <Modal
      bodyClass={classes('body')}
      className={classes()}
      hasCloseButton={false}
      header={headingFragment}
      headerClass={classes('header')}
      isOpen={isOpen}
      onRequestClose={_closeModal}
      size="medium-large"
      footerClass={classes('footer', { isSmsMessage: !isSecureMessage })}
      footerAgreementClass={classes('sms-consent')}
      footerAgreementCheck={footerAgreementFragment}
      footerPrimaryActions={footerPrimaryFragment}
      footerSecondaryActions={footerSecondaryFragment}
    >
      <div className={classes('info-container')}>
        <Scrollbars shouldScrollOverflow={true}>
          {statusFragment}
          <div className={classes('section')}>
            <DeliveryMethodIcon circleColor="#ECF1F3" isSecure={isSecureMessage} size={31} />
            <div className={classes('section-container', { isDeliverySection: true })}>
              <DeliveryMethod
                copy={`${
                  isSecureMessage ? 'Secure Browser Link: ' : 'Unsecure SMS Text: Non-'
                }HIPAA Compliant`}
                deliveryMethod={deliveryMethod}
                noReply={noReply}
                shouldShowArrows={false}
                shouldShowIcon={false}
              />
            </div>
          </div>
          {senderFragment}
          <div className={classes('section')}>
            <ScheduleRecipientSvg />
            <div className={classes('section-container')}>
              {recipients &&
                recipients.map((recipient) => {
                  let recipientFragment;
                  if (recipient.$entityType === 'distributionList') {
                    recipientFragment = (
                      <PatientBroadcastListSearchResultDetails
                        context="SELECTED_ITEM"
                        entity={recipient}
                      />
                    );
                  } else if (recipient.$entityType === 'user') {
                    recipientFragment = (
                      <PatientSearchResultHeader entity={recipient} isSingleRecipient={true} />
                    );
                  }
                  return (
                    <div className={classes('recipient-container')} key={recipient.id}>
                      {recipientFragment}
                    </div>
                  );
                })}
              {scheduledMessageView && recipientType === 'user' && (
                <div
                  className={classes('recipient-container', {
                    scheduledMessageView,
                  })}
                  key={recipient.id}
                >
                  <PatientSearchResultHeader entity={recipient} isSingleRecipient={true} />
                </div>
              )}
              {scheduledMessageView && recipientType === 'distributionList' && (
                <div
                  className={classes('recipient-container', {
                    scheduledMessageView,
                  })}
                  key={recipient.id}
                >
                  <PatientBroadcastListSearchResultDetails
                    context="SELECTED_ITEM"
                    entity={recipient}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={classes('section')}>
            <ScheduleSvg />
            <div className={classes('section-container')}>
              <div
                className={classes('schedule-section', { scheduledMessageView }, { recipientType })}
              >
                {scheduleFragment}
              </div>
            </div>
          </div>
          <div className={classes('section')}>
            <ScheduleMessageSvg />
            <div className={classes('section-container')}>
              <div className={classes('message-section')}>
                {scheduledMessageView && <span className={classes('message-header')}>Message</span>}
                <div className={classes('template-name')}>Template: {selectedTemplate.title}</div>
                <div className={classes('message-body')}>{body}</div>
                {attachments.length > 0 &&
                  attachments.map(({ name, type }) => (
                    <div className={classes('attachment-container')} key={name}>
                      <AttachmentIcon className={classes('file-icon')} fileType={type} />
                      {name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {isPatientBroadcastList && !allowPatientDeliveryMethod && (
            <div className={classes('section')}>
              <PreferencesSvg />
              <div className={classes('section-container')}>
                <div>
                  <div>
                    {scheduledMessageView && (
                      <span
                        className={classes('preferences-header', {
                          scheduledMessageView,
                        })}
                      >
                        Preferences
                      </span>
                    )}
                  </div>
                  <div>
                    <span
                      className={classes('no-reply-header', {
                        scheduledMessageView,
                      })}
                    >
                      No-Reply:{' '}
                    </span>
                    {noReplyFragment}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Scrollbars>
      </div>
    </Modal>
  );
};

ScheduleMessageReviewModal.propTypes = {
  allowPatientDeliveryMethod: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  createScheduledMessage: PropTypes.func.isRequired,
  editScheduledMessage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  options: PropTypes.shape({
    feed: PropTypes.string,
    id: PropTypes.string,
    isEditScheduleMessage: PropTypes.bool,
    isTemplateChanged: PropTypes.bool,
    reopenModal: PropTypes.string,
    scheduledMessageView: PropTypes.bool,
    setIsTemplateChanged: PropTypes.func,
  }),
  resetScheduledMessageState: PropTypes.func.isRequired,
  setIsBackButtonPressed: PropTypes.func,
  scheduledMessage: propTypes.scheduledMessageState.isRequired,
  findScheduledMessageInHistory: PropTypes.func,
};

export default mobxInjectSelect({
  modalStore: ['openModal'],
  patientAdminStore: ['allowPatientDeliveryMethod'],
  scheduleMessageStore: [
    'createScheduledMessage',
    'editScheduledMessage',
    'resetScheduledMessageState',
    'scheduledMessage',
    'setIsBackButtonPressed',
    'findScheduledMessageInHistory',
  ],
})(ScheduleMessageReviewModal);
