// @ts-nocheck
import BaseAPI, { recoverFromNotFound, ROUTES, VERSIONS } from './BaseAPI';

const { VERSION_TWO } = VERSIONS;
const { GROUP_ADD_MEMBERS, GROUP_CREATE, GROUP_DESTROY, GROUP_FIND, GROUP_UPDATE } = ROUTES;

export default class GroupsAPI extends BaseAPI {
  /**
   * POST /group - create a group
   * @param  {string} options.name - Group name
   * @param  {File} options.avatarFile - Avatar
   * @param  {Boolean} options.replayHistory - Replay History
   * @param  {Array<string>} options.memberIds - Member IDs
   * @param  {string} options.organizationId - Organization ID
   * @return {Promise.<Object,Error>} - A promise with an object representing a group
   */
  async create({
    avatarFile,
    description,
    excludeCreator,
    isPublic = false,
    memberIds = [],
    metadata,
    name,
    organizationId,
    renderEntity = true,
    replayHistory = false,
    patientContextId,
  }) {
    const { version = VERSION_TWO } = super.getVersion(GROUP_CREATE);
    const headers = {};
    const data = {
      is_public: isPublic,
      members: memberIds.join(','),
      metadata: metadata,
      name: name,
      replay_history: replayHistory,
    };

    if (patientContextId) {
      data.patient_context_id = patientContextId;
    }

    const files = {};
    const query = { exclude_creator: excludeCreator, render_entity: renderEntity };

    if (avatarFile) files['avatar'] = avatarFile;
    if (description) data['description'] = description;

    if (organizationId) {
      headers['TT-X-Organization-Key'] = organizationId;
    }

    const res = await this.httpClient.post('/:version/group', {
      urlParams: { version },
      query,
      headers,
      data,
      files,
    });

    return res.data;
  }

  /**
   * POST /group/:id - update a group
   * @param  {string} options.name - Group name
   * @param  {File} options.avatarFile - Avatar
   * @param  {Boolean} options.replayHistory - Replay History
   * @param  {Array<string>} options.memberIds - Member IDs
   * @param  {Boolean} options.preserve - Preserve Group Conversation
   * @return {Promise.<Object,Error>} - A promise with an object representing a group
   */
  async update(
    id,
    {
      name = undefined,
      avatarFile = undefined,
      // TODO
      replayHistory = undefined,
      memberIds,
      preserve = undefined,
    }
  ) {
    // TODO validation
    const { version = VERSION_TWO } = super.getVersion(GROUP_UPDATE);
    const data = {};
    const files = {};

    if (typeof name !== 'undefined') data['name'] = name;
    if (typeof memberIds !== 'undefined') data['members'] = memberIds.join(',');
    if (typeof replayHistory !== 'undefined') data['replay_history'] = replayHistory;
    if (avatarFile) files['avatar'] = avatarFile;
    if (typeof preserve !== 'undefined') data['preserve'] = preserve;

    const res = await this.httpClient.post('/:version/group/:id', {
      urlParams: { id, version },
      data,
      files,
    });

    const avatarUrl = res.getHeader('TT-X-Group-Avatar');

    return {
      id,
      avatarUrl,
    };
  }

  async find(id: string) {
    const { version = VERSION_TWO } = super.getVersion(GROUP_FIND);

    const res = await this.httpClient.get('/:version/group/:id', {
      urlParams: { id, version },
      query: { render_metadata: 'true' },
    });

    return res.data;
  }

  @recoverFromNotFound()
  async findMemberIds(id: string, { continuation } = {}) {
    // This is currently broken, will be addressed by:
    // https://tigertext.atlassian.net/browse/TS-6555
    //
    // const { version=VERSION_TWO } = super.getVersion(GROUP_FIND_ALL_MEMBER_IDS)
    const endpoint = '/:version/group/:id/members';

    const res = await this.httpClient.get(endpoint, {
      urlParams: {
        id,
        version: VERSION_TWO,
      },
      query: {
        continuation,
        return_user_objects: true,
      },
    });

    return res.data;
  }

  @recoverFromNotFound()
  async destroy(id: string) {
    const { version = VERSION_TWO } = super.getVersion(GROUP_DESTROY);

    await this.httpClient.del('/:version/group/:id', { urlParams: { id, version } });

    return true;
  }

  async addMembers(id: string, memberIds: string[]) {
    const { version = VERSION_TWO } = super.getVersion(GROUP_ADD_MEMBERS);
    const res = await this.httpClient.post('/:version/group/:id/members', {
      urlParams: { id, version },
      data: {
        members: memberIds,
      },
    });

    return res.data;
  }

  async removeMembers(id: string, memberIds: string[]) {
    await this.httpClient.post('/v2/group/:id/members/remove', {
      urlParams: { id },
      data: {
        members: memberIds,
      },
    });
  }
}
