// @ts-nocheck
import Event from './Event';

export default class MessageEvent extends Event {
  constructor(type, options) {
    super(type);
    this.data = options.data;
    this.lastEventId = options.lastEventId;
  }
}
