// @ts-nocheck
import {
  NativeTextDecoder,
  TextDecoderPolyfill,
  supportsStreamOption,
} from './TextDecoderPolyfill';

const IOS_SAFARI_REGEX = /\/([0-9]|1[0-2])[.\d]* Mobile\/[0-9A-F]* Safari/;

const userAgent =
  process.env.NODE_TARGET === 'web' && global.navigator && global.navigator.userAgent;
const isIE11 = !!userAgent && userAgent.includes('Trident/');
const isOldIosSafari = !!userAgent && IOS_SAFARI_REGEX.test(userAgent);

export const isFetchSupported = !!(
  !isIE11 &&
  !isOldIosSafari &&
  global.fetch &&
  global.Headers &&
  global.Response &&
  global.AbortController
);

const TextDecoder = supportsStreamOption() ? NativeTextDecoder : TextDecoderPolyfill;
const nativeFetch = global.fetch;

class HeadersWrapper {
  constructor(headers) {
    this._headers = headers;
  }

  get(name) {
    return this._headers.get(name);
  }
}

export class FetchRequest {
  constructor({ headers, onProgress, onStart, url, withCredentials }) {
    this.controller = new AbortController();
    this.headers = new Headers(headers);
    this.isAborted = false;
    this.onProgress = onProgress;
    this.onStart = onStart;
    this.response = null;
    this.textDecoder = new TextDecoder();
    this.url = url;
    this.withCredentials = withCredentials;
    this._reader = null;
    this._stream = null;
  }

  async fetch() {
    if (this.isAborted) return;

    const response = (this.response = await nativeFetch(this.url, {
      headers: this.headers,
      credentials: this.withCredentials ? 'include' : 'same-origin',
      signal: this.controller.signal,
      cache: 'no-store',
    }));

    if (response.body.on) {
      this._emitStart();
      await this._streamBody();
    } else if ('getReader' in response.body) {
      this._emitStart();
      await this._readBody();
    } else {
      throw new Error("Cannot read from response.body, lacks 'getReader' or 'on' property");
    }
  }

  _emitStart() {
    const { headers, status, statusText } = this.response;

    this.onStart({
      contentType: headers.get('Content-Type'),
      headers: new HeadersWrapper(headers),
      status,
      statusText,
    });
  }

  async _streamBody() {
    await new Promise((resolve, reject) => {
      let isClosed = false;

      const closeHandler = () => {
        if (isClosed) return;
        isClosed = true;
        resolve();
      };

      this._stream = this.response.body
        .on('data', (buf) => {
          const chunk = buf.toString('utf8');
          this.onProgress(chunk);
        })
        .on('close', closeHandler)
        .on('end', closeHandler)
        .on('error', (err) => reject(err));
    });
  }

  async _readBody() {
    this._reader = this.response.body.getReader();
    let isDone = false;

    while (!isDone && !this.isAborted) {
      const result = await this._reader.read();

      if (result.done) {
        // Note: bytes in textDecoder are ignored
        isDone = true;
      } else {
        const chunk = this.textDecoder.decode(result.value, { stream: true });
        this.onProgress(chunk);
      }
    }
  }

  async abort() {
    if (this.isAborted) return;

    this.isAborted = true;

    if (this._reader) {
      try {
        await this._reader.cancel();
      } catch (err) {
        // ignore "network error" that can be thrown when trying to abort; aborting is a
        // best-effort operation
      }

      this._reader = null;
    }

    if (this._stream) {
      this._stream.destroy();
      this._stream = null;
    }

    this.controller.abort();
  }
}
