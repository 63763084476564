const shouldRunCommandBot = ({
  isGiphyBotEnabled,
  isCommandBotEnabled,
  isCommandEditorOpen,
  bodyText,
  setState,
  toggleCommandEditor,
}: {
  bodyText: string;
  isCommandBotEnabled: boolean;
  isCommandEditorOpen: boolean;
  isGiphyBotEnabled: boolean;
  toggleCommandEditor: () => void;
  setState: (newState: { commandValue?: string | boolean; commandType: string | boolean }) => void;
}) => {
  return (
    shouldRunGiphyBot({
      bodyText,
      setState,
      isGiphyBotEnabled,
      isCommandEditorOpen,
      toggleCommandEditor,
    }) || shouldRunTCBot({ bodyText, setState, isCommandBotEnabled })
  );
};

export const shouldRunGiphyBot = ({
  bodyText,
  setState,
  isCommandEditorOpen,
  isGiphyBotEnabled,
  toggleCommandEditor,
}: {
  bodyText: string;
  isCommandEditorOpen: boolean;
  isGiphyBotEnabled: boolean;
  toggleCommandEditor: () => void;
  setState: (newState: { commandValue?: string | boolean; commandType: string | boolean }) => void;
}) => {
  if (!isGiphyBotEnabled || !bodyText) return false;

  const body = bodyText?.trim();
  if (!body) return false;

  const giphyPrompt = '/giphy ';
  const isGiphyCommand = body.startsWith(giphyPrompt);
  if (!isGiphyCommand) {
    if (isCommandEditorOpen) {
      toggleCommandEditor();
    }
    return false;
  }

  const [, ...commandValue] = body.split(' ');
  if (!commandValue) {
    // room for improvement

    // lets move this logic to on keystroke and not on submit
    // show some sort of hint for user and return true instead of false
    return false;
  }

  setState({
    commandValue: commandValue.join(' '),
    commandType: 'giphy',
  });

  return true;
};

export const shouldRunTCBot = ({
  bodyText,
  setState,
  isCommandBotEnabled,
}: {
  bodyText: string;
  isCommandBotEnabled: boolean;
  setState: (newState: { commandValue: string | boolean; commandType: string | boolean }) => void;
}) => {
  if (!isCommandBotEnabled || !bodyText) return false;

  const body = bodyText?.trim();
  if (!body) return false;

  const [prompt, commandType, commandValue] = body.split(' ');
  if (prompt !== '.tc') return false;

  const allowedCommands = ['deploy', 'version', 'envdash'];
  if (!commandType || allowedCommands.indexOf(commandType) === -1) return false;

  setState({
    commandType,
    commandValue,
  });

  return true;
};

export default shouldRunCommandBot;
