// @ts-nocheck
export function isPhone(phoneNumber) {
  return phoneNumber && /^\+?([0-9]{7,15})$/.test(phoneNumber.replace(/[-.● ()]/g, ''));
}

// Format to 1 xxx xxx xxxx OR xxx xxx xxxx to display to user
export function formatPhone(phoneNumber) {
  if (typeof phoneNumber !== 'string') return;

  let formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

  if (/^\+?1/.test(phoneNumber)) {
    return formattedPhoneNumber.replace(/^(1)(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4');
  }

  if (formattedPhoneNumber[0] === '1') {
    formattedPhoneNumber = formattedPhoneNumber.slice(1);
  }

  return formattedPhoneNumber.replace(/^(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
}

// Format xxx-xxx-xxxx to display to user
export function formatPhoneWithDash(phoneNumber) {
  if (typeof phoneNumber !== 'string') return;
  let formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

  if (formattedPhoneNumber[0] === '1') {
    formattedPhoneNumber = formattedPhoneNumber.slice(1);
  }
  return formattedPhoneNumber.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

// Normalize to xxxxxxxxxx to send to server, let server handle parsing out the
// country code
export function normalizePhone(phoneNumber) {
  return phoneNumber.replace(/\D/g, '');
}

// Normalize to +1xxxxxxxxxx to send to server
export function normalizePhoneUS(phoneNumber) {
  let normalizedPhoneNumber = phoneNumber.replace(/\D/g, '');

  if (normalizedPhoneNumber[0] !== '1') {
    normalizedPhoneNumber = `1${normalizedPhoneNumber}`;
  }

  return `+${normalizedPhoneNumber}`;
}

// if string has leading '+' then do nothing
// if string has no '+' and no leading '1' then add a '+1' --> default to us code
// if string has no '+' and has a leading '1' then add '+' --> default to us code
export function formatCountryCodePhoneNumber(numberToFormat: string) {
  if (!numberToFormat || numberToFormat[0] === '+') return numberToFormat;
  if (numberToFormat[0] === '1') return `+${numberToFormat}`;
  else return `+1${numberToFormat}`;
}
