import moment from 'moment-timezone';

const validTimezones = [
  {
    name: 'Hawaii',
    timezone: 'US/Hawaii',
  },
  {
    name: 'Alaska',
    timezone: 'US/Alaska',
  },
  {
    name: 'Pacific',
    timezone: 'US/Pacific',
  },
  {
    name: 'Arizona',
    timezone: 'US/Arizona',
  },
  {
    name: 'Mountain',
    timezone: 'US/Mountain',
  },
  {
    name: 'Central',
    timezone: 'US/Central',
  },
  {
    name: 'Eastern',
    timezone: 'US/Eastern',
  },
];

const generateValidTimezones = (date) => {
  const dateCopy = moment(date || {});
  return validTimezones.map(({ name, timezone }) => ({
    abbr: dateCopy.tz(timezone).zoneAbbr(),
    name,
    timezone,
  }));
};

export default generateValidTimezones;
