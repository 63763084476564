import React, { forwardRef, useImperativeHandle } from 'react';
import { actions, useAppDispatch, useAppSelector } from 'redux-stores';
const { setMessageBodyInputFocus } = actions;
export interface ReduxEscapeHatchRef {
  accessibilityMode: boolean;
  focusMessageBodyInput: () => void;
}
/*
This component is meant as a temporary bridge to gain access to Redux dispatch from within class-based React components.

1. We should not rely on this component if we have the time to convert a class component to support hooks.

2. We should not increase the utility of this component such as adding more props, or using it as a means to also read Redux data.

3. Adding dispatch calls to this component should be considered a last resort.
*/

export default forwardRef((props, ref) => {
  const accessibilityMode = useAppSelector((state) => state.ui.accessibilityMode);
  const dispatch = useAppDispatch();

  function focusMessageBodyInput() {
    dispatch(setMessageBodyInputFocus(true));
  }

  useImperativeHandle(ref, () => ({
    accessibilityMode,
    focusMessageBodyInput,
  }));
  return <></>;
});
