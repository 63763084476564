import React, { useState } from 'react';

import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { mobxInjectSelect } from '../../../../common/utils';
import { AlertComponent } from '../../../../../src/types/';
import { actions } from '../../../../redux-stores';
import BEM from '../../../../common/bem';
import DotsIndicator from '../../../../common/components/DotsIndicator';

const { setModal } = actions;

const classes = BEM.with('AlertActionButton');
const CALLBACK_CONFLICT_ERROR = 'duplicate_callback_with_different_reaction';

type MobxProps = {
  _stopSound: () => void;
  isGroupAlertsAllowed: boolean;
  logGroupAlertAction: (messageId: string, buttonComponent: AlertComponent) => void;
  markAsRead: (messageId: string) => Promise<unknown>;
  openModal: (modalName: string, options?: Record<string, string>) => void;
  processAlertCallbackResponse: (data: Record<string, string>, throwOnError: boolean) => void;
  sendRequest: (
    method: 'post' | 'get' | 'put' | 'delete' | 'patch' | 'del',
    url: string,
    { data, headers }: { data: Record<string, unknown>; headers: Record<string, string> }
  ) => Promise<{ data: Record<string, string> }>;
};

export type AlertActionButtonProps = {
  component: AlertComponent;
  isDisabled: boolean;
  messageId: string;
  onActionClick: () => void;
  onActionFinish: (enableButtons?: boolean) => void;
};

const AlertActionButton = ({
  _stopSound,
  component,
  isDisabled,
  isGroupAlertsAllowed,
  logGroupAlertAction,
  markAsRead,
  messageId,
  onActionClick,
  onActionFinish,
  openModal,
  processAlertCallbackResponse,
  sendRequest,
}: AlertActionButtonProps & MobxProps) => {
  const dispatch = useDispatch();

  const {
    action_url: actionUrl,
    headers: requestHeaders,
    params: requestParams,
    read_message: shouldReadMessageAfterAction,
    value: text,
  } = component;
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  let { border_color: borderColor, bg_color: backgroundColor, value_color: color } = component;

  if (backgroundColor) backgroundColor = backgroundColor.replace('0x', '#');
  if (borderColor) borderColor = borderColor.replace('0x', '#');
  if (color) color = color.replace('0x', '#');

  const onClick = async () => {
    setIsRequestInProgress(true);
    _stopSound();
    onActionClick();

    try {
      requestHeaders['Content-Type'] = requestHeaders['content-type'];
      delete requestHeaders['content-type'];
      requestHeaders['Accept'] = '*/*';
      requestParams['internal_message_id'] = messageId;

      const { data: callbackResponse } = await sendRequest('post', actionUrl, {
        data: requestParams,
        headers: requestHeaders,
      });

      if (Object.keys(callbackResponse).length) {
        processAlertCallbackResponse(callbackResponse, true);
      }

      if (shouldReadMessageAfterAction) {
        await markAsRead(messageId);
      }
      onActionFinish(false);
    } catch (err) {
      if (err.id === '403' && get(err, 'body.forbidden') === CALLBACK_CONFLICT_ERROR) {
        dispatch(
          setModal({
            name: 'error',
            data: {
              customHeaderText: 'Already Acknowledged',
              customBodyText: "You've already acknowledged this alert.",
            },
          })
        );
        processAlertCallbackResponse(err.body, false);
        onActionFinish(false);
      } else {
        openModal('failure');
        onActionFinish(true);
      }
    } finally {
      setIsRequestInProgress(false);
      await logGroupAlertAction(messageId, component);
    }
  };

  let shouldFill = false;
  if (isGroupAlertsAllowed) {
    borderColor = undefined;
    color = undefined;

    if (backgroundColor) {
      shouldFill = true;
      backgroundColor = undefined;
    }
  }

  return (
    <button
      className={classes('', {
        isGroupAlertsAllowed,
        shouldFill,
      })}
      disabled={isDisabled}
      onClick={onClick}
      data-test-id={`alertActionButton${text}`}
      aria-label={`alert-action-button-${text}`}
      style={{
        backgroundColor,
        borderColor,
        color,
      }}
    >
      {isRequestInProgress && !isGroupAlertsAllowed ? <DotsIndicator size={13} /> : text}
    </button>
  );
};

export default mobxInjectSelect<AlertActionButtonProps, MobxProps>({
  alertStore: ['processAlertCallbackResponse'],
  desktopAppStore: ['_stopSound'],
  messageStore: ['markAsRead'],
  messengerStore: ['isGroupAlertsAllowed', 'sendRequest'],
  modalStore: ['openModal'],
  trackerStore: ['logGroupAlertAction'],
})(AlertActionButton);
