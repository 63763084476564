// @ts-nocheck
import _ from 'lodash';
import { decorator as reusePromise } from 'reuse-promise';
import { SearchType } from '../models/enums';
import * as errors from '../errors';
import BaseService from './BaseService';

const DIST_LIST_MEMBER_ID_QUERY = 'dist_list_token';

const MEMBERS_SEARCH_TERMS = ['department', 'display_name', 'name', 'title'].join(',');

const SEARCH_TERMS = ['name'].join(',');

export default class DistributionListsService extends BaseService {
  mounted() {
    this._fetched = false;
  }

  dispose() {
    this._fetched = false;
  }

  async addMembers({ id, members, organizationId }) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!Array.isArray(members)) {
      throw new errors.ValidationError('members', 'required', 'members must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.distributionLists.membersBatch({
      id,
      members,
      operation: 'add',
      organizationId,
    });
  }

  async batchDelete({ ids, organizationId }) {
    if (!Array.isArray(ids)) {
      throw new errors.ValidationError('ids', 'required', 'ids must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.distributionLists.batch({
      ids,
      operation: 'destroy',
      organizationId,
    });

    const distributionLists = ids.map((id) =>
      this.host.models.DistributionList.inject({
        $deleted: true,
        id,
      })
    );

    return distributionLists;
  }

  async create({
    adminOnly,
    isAdSync,
    members,
    name,
    network = 'patient',
    organizationId,
    securityGroup,
  }) {
    this._validate({ adminOnly, members, name, organizationId, shouldCheckId: false });

    const data = await this.host.api.distributionLists.create({
      adminOnly,
      isAdSync,
      members,
      name,
      network,
      organizationId,
      securityGroup,
    });

    const distributionList = this.host.models.DistributionList.inject({
      conversationId: data.conversation_id,
      organizationId: data.organization_id,
      ...data.entity,
    });

    return distributionList;
  }

  async delete(id, organizationId) {
    this._validate({ id, organizationId, shouldCheckInvalid: false });

    await this.host.api.distributionLists.delete(id, organizationId);

    const distributionList = this.host.models.DistributionList.inject({
      $deleted: true,
      id,
    });

    return distributionList;
  }

  @reusePromise()

  /**
   * Find a distributionList
   * @param  {string} id - distributionList ID
   * @param  {Boolean} options.bypassCache - Force fetch from server
   * @param  {Boolean} options.ignoreNotFound - Ignore if not found - promise will return null
   * @return {Promise.<DistributionList,Error>} - a promise with the distributionList, or error
   */
  async find(id: string, options = {}) {
    options = _.defaults(options, {
      ignoreNotFound: false,
      bypassCache: false,
      includeMembers: false,
      includeMetadata: false,
    });
    this.host.requireUser();

    // local first
    let entity = null;

    if (!options.bypassCache) {
      entity = this.host.models.DistributionList.get(id);
      if (entity) return entity;
    }

    const data = await this.host.api.distributionLists.find(id, {
      organizationId: options.organizationId,
    });

    if (data) {
      if (this.config.condensedReplays || data.entity) {
        entity = this.host.models.DistributionList.inject({
          conversationId: data.conversation_id,
          organizationId: data.organization_id,
          ...data.entity,
        });
      } else {
        entity = this.host.models.DistributionList.inject(data);
      }
      delete entity.$placeholder;
    } else if (!options.ignoreNotFound) {
      throw new errors.NotFoundError(this.host.models.DistributionList.name, id);
    }

    return entity;
  }

  @reusePromise()
  async findAll() {
    if (this._fetched) return this.getAll();

    let organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) {
      organizations = await this.host.organizations.findAll();
    }

    const { results } = await this.host.search.query({
      version: 'SEARCH_PARITY',
      types: ['distributionList'],
      organizationIds: organizations.map((o) => o.id),
      query: { displayName: '' },
      followContinuations: true,
    });
    const all = results.map((searchResult) => searchResult.entity);

    this._fetched = true;

    return all;
  }

  getAll() {
    return this.host.models.DistributionList.getAll();
  }
  getById(id: string) {
    return this.host.models.DistributionList.get(id);
  }
  getMultiById(ids: Array<string>) {
    return this.host.models.DistributionList.getMulti(ids);
  }

  async removeMembers({ id, members, organizationId }) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!Array.isArray(members)) {
      throw new errors.ValidationError('members', 'required', 'members must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.distributionLists.membersBatch({
      id,
      members,
      operation: 'remove',
      organizationId,
    });
  }

  @reusePromise()
  async search({
    continuation,
    network = 'patient',
    organizationId,
    query = '',
    sortBy = 'name',
    sortOrder = 'asc',
  }) {
    const response = await this.host.search.query({
      version: 'SEARCH_PARITY',
      continuation,
      network,
      organizationId,
      query: {
        [SEARCH_TERMS]: query,
      },
      returnFields: [],
      sort: { [sortBy]: sortOrder },
      types:
        network === 'provider'
          ? [SearchType.DISTRIBUTION_LIST]
          : [SearchType.PATIENT_DISTRIBUTION_LIST],
    });

    const results = this._processSearchResults(response.results);

    return {
      metadata: response.metadata,
      results,
    };
  }

  async findMembers(
    id,
    {
      continuation,
      network = 'patient',
      organizationId,
      query = '',
      sortBy = ['display_name'],
      sortOrder = ['asc'],
    } = {}
  ) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }

    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    if (sortBy.length !== sortOrder.length) {
      throw new errors.ValidationError(
        'sortBy',
        'invalid',
        'sortBy and sortOrder must be the same length'
      );
    }

    const sort = {};
    for (let idx = 0; idx < sortBy.length; idx++) {
      sort[sortBy[idx]] = sortOrder[idx];
    }

    const response = await this.host.search.query({
      version: 'SEARCH_PARITY',
      continuation,
      network,
      organizationId,
      query: {
        [MEMBERS_SEARCH_TERMS]: query,
        [DIST_LIST_MEMBER_ID_QUERY]: id,
      },
      returnFields: [],
      sort,
      types: [SearchType.DISTRIBUTION_LIST_MEMBER],
    });

    const results = this._processSearchResults(response.results);

    return {
      metadata: response.metadata,
      results,
    };
  }

  async findMemberIds(id, organizationId, { continuation } = {}) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }

    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    const { members = [], metadata } = await this.host.api.distributionLists.findMemberIds(
      id,
      organizationId,
      { continuation }
    );
    const memberIds = members.map(({ token }) => token);

    return {
      metadata,
      results: memberIds,
    };
  }

  async update({ adSecurityGroup, adminOnly, id, name, organizationId }) {
    this._validate({ adSecurityGroup, adminOnly, name, id, organizationId });

    const data = await this.host.api.distributionLists.update({
      adSecurityGroup,
      adminOnly,
      id,
      name,
      organizationId,
    });

    const distributionList = this.host.models.DistributionList.inject({
      conversationId: data.conversation_id,
      organizationId: data.organization_id,
      ...data.entity,
    });
    return distributionList;
  }

  async findSharedMembers(
    id,
    {
      continuation,
      network = 'provider',
      organizationId,
      query = '',
      sortBy = ['name'],
      sortOrder = ['asc'],
    } = {}
  ) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }

    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    if (sortBy.length !== sortOrder.length) {
      throw new errors.ValidationError(
        'sortBy',
        'invalid',
        'sortBy and sortOrder must be the same length'
      );
    }

    const sort = {};
    for (let idx = 0; idx < sortBy.length; idx++) {
      sort[sortBy[idx]] = sortOrder[idx];
    }

    const response = await this.host.search.query({
      version: 'SEARCH_PARITY',
      continuation,
      network,
      organizationId,
      query: {
        name: query,
        [DIST_LIST_MEMBER_ID_QUERY]: id,
      },
      returnFields: [],
      sort,
      types: [SearchType.DISTRIBUTION_LIST_SHARED],
    });

    const results = this._processSearchResults(response.results);

    return {
      metadata: response.metadata,
      results,
    };
  }

  async addSharedMembers({ id, members, organizationId }) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!Array.isArray(members)) {
      throw new errors.ValidationError('members', 'required', 'members must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.distributionLists.sharedMembersBatch({
      id,
      members,
      operation: 'add',
      organizationId,
    });
  }

  async removeSharedMembers({ id, members, organizationId }) {
    if (!id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!Array.isArray(members)) {
      throw new errors.ValidationError('members', 'required', 'members must be an array');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }

    await this.host.api.distributionLists.sharedMembersBatch({
      id,
      members,
      operation: 'remove',
      organizationId,
    });
  }

  _processSearchResults(results) {
    return results.map(({ entity }) => entity).filter(({ $deleted }) => !$deleted);
  }

  _validate({
    adSecurityGroup,
    adminOnly,
    id,
    members,
    name,
    organizationId,
    shouldCheckId = true,
    shouldCheckInvalid = true,
  }) {
    if (shouldCheckId && !id) {
      throw new errors.ValidationError('id', 'required');
    }
    if (!organizationId) {
      throw new errors.ValidationError('organizationId', 'required');
    }
    if (shouldCheckInvalid) {
      if (adminOnly !== undefined && typeof adminOnly !== 'boolean') {
        throw new errors.ValidationError(
          'adminOnly',
          'invalid',
          'adminOnly must be a boolean value'
        );
      }
      if (name && (name.length === 0 || name.length > 60)) {
        throw new errors.ValidationError(
          'name',
          'invalid',
          'name must be less than 60 alphanumeric characters'
        );
      }
      if (adSecurityGroup && (adSecurityGroup.length === 0 || adSecurityGroup.length > 64)) {
        throw new errors.ValidationError(
          'security group name',
          'invalid',
          'name must be less than 64 alphanumeric characters'
        );
      }
    }
  }
}
