import React from 'react';

import { mobxInjectSelect } from '../../../utils';

import GiphySelector from './GiphySelector';

type CommandEditorProps = {
  type: string;
  value: string;
  close: () => void;
  sendMessage: (msg: string, options: {}) => void;
};
type MobxProps = {
  resetMessage: () => void;
};

function CommandEditor({
  type,
  value,
  close,
  sendMessage,
  resetMessage,
}: CommandEditorProps & MobxProps) {
  const closeAndClear = () => {
    close();
    resetMessage();
  };

  switch (type) {
    case 'giphy':
      if (!value) return null;
      return (
        <GiphySelector
          query={value}
          send={(gifUrl: string) => sendMessage(gifUrl, {})}
          close={closeAndClear}
        />
      );
    default:
      return null;
  }
}

export default mobxInjectSelect<CommandEditorProps, MobxProps>({
  composeMessageStore: ['resetMessage'],
})(CommandEditor);
