// @ts-nocheck
import addEnsureEntity from './extensions/ensureEntity';

export default function (store, { host }) {
  const Team = store.defineModel('team', {
    replaceKeys: {
      avatar: 'avatarUrl',
      members: '__members',
      numMembers: '__memberCount',
      name: 'displayName',
      tags: '__tags',
    },

    transientAttrs: ['__memberCount', '__members', '__tags'],

    defaultValues: {
      canMembersLeave: false,
      canRequestToJoin: false,
      hasCurrentUserOrRole: false,
      hasCurrentUserPending: false,
      hasCurrentUserBeenDeclined: false,
      memberIds: [],
      tagId: null,
    },

    skipCamelizationForKeys: ['members'],

    parseAttrs(attrs) {
      const { __members: rawMembers, __tags: rawTags } = attrs;

      if (rawMembers) {
        const memberIds = [];

        for (const rawMember of rawMembers) {
          if (typeof rawMember === 'object') {
            if (rawMember.token) {
              memberIds.push(rawMember.token);
              const user = host.models.User.inject(rawMember);
              host.models.User.removePlaceholder({ entity: user, attrs: rawMember });
            } else if (rawMember.id) {
              memberIds.push(rawMember.id);
            }
          } else if (typeof rawMember === 'string') {
            memberIds.push(rawMember);
          }
        }

        attrs.memberIds = memberIds;
        delete attrs.__members;
      }

      if (rawTags) {
        const tag = rawTags[0];
        const tagId = tag ? `${attrs.organizationId}:${tag.id}` : undefined;
        tag && host.models.Tag.inject({ ...tag, id: tagId });
        attrs.tagId = tagId;
      }
      delete attrs.__tags;

      if (attrs.groupToken || attrs.groupId) {
        attrs.groupId = attrs.groupToken || attrs.groupId;
      }

      return attrs;
    },

    afterAssignment(entity) {
      const { memberIds } = entity;
      host.groups._setMembership(entity);
      host.users._setMembersOnEntity({ entity, placeholder: true });
      host.teams._setHasUserPending(entity);
      entity.memberCount = (memberIds && memberIds.length) || entity.__memberCount || 0;
    },

    relations: {
      belongsTo: {
        group: { type: 'group', foreignKey: 'groupId' },
        tag: { type: 'tag', foreignKey: 'tagId' },
      },
    },
  });

  addEnsureEntity(Team, {
    finder: (...args) => host.teams.find(...args),
    placeholderEntityDelayBeforeRefreshing() {
      return host.config.placeholderEntityDelayBeforeRefreshing;
    },
    placeholderEntityAllowLoading() {
      return host.config.placeholderEntityAllowLoading;
    },

    defaultPlaceholderAttrs: { displayName: 'Loading...' },
    defaultNotFoundAttrs: { displayName: '<Team Not Found>' },
  });

  return Team;
}
