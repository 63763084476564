// @ts-nocheck
import EscalationStatus from './enums/EscalationStatus';

export default function (store, { host }) {
  const EscalationExecution = store.defineModel('escalationExecution', {
    replaceKeys: {
      additionalTargets: 'additionalTargets',
      destructionDuration: 'minutesToExpire',
      destructionTime: 'expiresAt',
      displayName: 'displayId',
      escalationExecutionId: 'id',
      escalationGroupToken: 'groupId',
      escalationPath: 'path',
      escalationStatus: 'status',
      level: 'currentStep',
      organizationToken: 'organizationId',
      targetDuration: 'targetMinutesToAcknowledge',
      targetToken: 'targetId',
      triggeredMessageToken: 'carbonCopyMessageId',
      triggeringConversationId: 'originalGroupConversationId',
      triggeringMessageToken: 'originalMessageId',
    },

    defaultValues: {
      currentStep: 0,
      status: EscalationStatus.IN_PROGRESS,
    },

    parseAttrs(attrs) {
      if ('actionTime' in attrs && typeof attrs.actionTime === 'string') {
        attrs.actionTime = new Date(attrs.actionTime);
      }

      if (
        'carbonCopyMessageId' in attrs &&
        typeof attrs.carbonCopyMessageId === 'string' &&
        attrs.carbonCopyMessageId === 'undefined'
      ) {
        attrs.carbonCopyMessageId = null;
      }

      if ('displayId' in attrs) {
        if (attrs.displayId.startsWith('#')) {
          attrs.displayId = attrs.displayId.slice(1);
        }

        attrs.displayId = attrs.displayId.toUpperCase();
      }

      if ('expiresAt' in attrs && typeof attrs.expiresAt === 'string') {
        attrs.expiresAt = new Date(attrs.expiresAt).getTime();
      }

      if (
        'groupId' in attrs &&
        typeof attrs.groupId === 'string' &&
        attrs.groupId === 'undefined'
      ) {
        attrs.groupId = null;
      }

      if ('path' in attrs) {
        const path = [];
        let invalidTarget = false;

        for (const step of attrs.path) {
          const { targets: targetIds, duration: minutesToAcknowledge } = step;
          const targets = [];
          for (const target of targetIds) {
            if (typeof target === 'string') {
              targets.push(host.injectPlaceholderModel({ id: target }, 'user'));
            } else if (target && target.token) {
              const { displayName, token: id } = target;
              targets.push(host.injectPlaceholderModel({ id, displayName }, 'user'));
            } else {
              invalidTarget = true;
            }
          }
          path.push({ minutesToAcknowledge, targets });
        }

        if (invalidTarget) {
          delete attrs.path;
        } else {
          attrs.path = path;
        }
      }

      if ('status' in attrs) {
        if (attrs.status === 'init') attrs.status = 'in_progress';
        if (attrs.status === 'destruct') attrs.status = 'cancelled';
        attrs.status = EscalationStatus.resolve(attrs.status);
      }

      if ('additionalTargets' in attrs) {
        attrs.additionalTargets = attrs.additionalTargets.map((additionalTarget) => {
          const { level, target } = additionalTarget;

          // Normalize v2/v5 ids to a single 'id' property
          if (target.token) {
            target.id = target.token;
            delete target.token;
          }

          return { level, target };
        });
      }

      return attrs;
    },

    afterAssignment(entity) {},

    relations: {
      belongsTo: {
        carbonCopyMessage: { type: 'message', foreignKey: 'carbonCopyMessageId' },
        group: { type: 'group', foreignKey: 'groupId' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        originalConversation: { type: 'conversation', foreignKey: 'originalGroupConversationId' },
        originalMessage: { type: 'message', foreignKey: 'originalMessageId' },
        target: { type: 'user', foreignKey: 'targetId' },
        escalationGroupConversation: {
          type: 'conversation',
          foreignKey: 'escalationGroupConversationId',
        },
      },
    },
  });

  return EscalationExecution;
}
