// @ts-nocheck
import BaseService from './BaseService';

export default class NotificationRouter extends BaseService {
  constructor(host, options) {
    super(host, options);

    for (const [eventName, fn] of Object.entries(this.EVENTS)) {
      if (eventName.includes('|')) {
        for (const alias of eventName.split('|')) {
          this.EVENTS[alias] = fn;
        }

        delete this.EVENTS[eventName];
      }
    }
  }

  emit(event) {
    if (this.EVENTS[event.type]) {
      this.EVENTS[event.type].call(this, event);
    } else {
      this.logger.info(`unhandled event: ${event.type}`, event.data);
    }
  }

  EVENTS = {
    'message|group_message'(msg) {
      this.host.notifications.reactToMessageEvent(msg);
    },

    'message:status|group:message:status'(msg) {
      this.host.notifications.reactToMessageStatusEvent(msg);
    },

    remote_wipe(event) {
      this.host.notifications.disconnect({ source: 'Notifications remote_wipe' });
      this.host.notifications.signOut({ fromServer: true });
    },

    'sse:heartbeat': () => null,
  };
}
