import React from 'react';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react';

import { AlertComponent } from '../../../../../src/types/';
import BEM from '../../../../common/bem';

const classes = BEM.with('AlertCompletedItem');

const DATE_TIME_FORMAT = 'MM/dd/yy, p';

export type AlertCompletedItemProps = {
  component: AlertComponent;
  isExpanded?: boolean;
  isV2?: boolean;
};

const AlertCompletedItem = ({
  component,
  isExpanded = false,
  isV2 = false,
}: AlertCompletedItemProps) => {
  const {
    role_display_name: roleDisplayName,
    sender_display_name: senderDisplayName,
    timestamp: completedTime = Date.now().toString(),
    value: actionName,
  } = component;
  let { bg_color: backgroundColor, value_color: color } = component;

  if (backgroundColor) backgroundColor = backgroundColor.replace('0x', '#');
  if (color) color = color.replace('0x', '#');

  let completedByName = senderDisplayName;
  if (roleDisplayName) {
    completedByName = `${roleDisplayName} (${senderDisplayName})`;
  }

  return (
    <div
      className={classes('', { isExpanded, isV2, shouldClip: isV2 && !isExpanded })}
      style={{ backgroundColor, color }}
    >
      {actionName} at {format(parseISO(completedTime), DATE_TIME_FORMAT)} by {completedByName}
    </div>
  );
};

export default observer(AlertCompletedItem);
