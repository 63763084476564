import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import BEM from '../bem';
import propTypes from '../propTypes';
import { attachmentType, escalationStatusLine, mobxInjectSelect } from '../utils';
import { messageRecipientStatusToReadableLabel } from '../utils/readableLabels';
import { Card } from './WebComponents';
import { GroupAvatar, Scrollbars } from './';

const classes = BEM.with('EscalationPolicyInfoPane');

class EscalationPolicyInfoPane extends Component {
  static propTypes = {
    compareRecipientStatus: PropTypes.func.isRequired,
    ensureEscalationExecution: PropTypes.func.isRequired,
    escalationExecution: propTypes.escalationExecution,
  };

  componentDidMount() {
    const { ensureEscalationExecution, escalationExecution } = this.props;
    if (escalationExecution) ensureEscalationExecution(escalationExecution);
  }

  render() {
    const { compareRecipientStatus, escalationExecution } = this.props;
    if (!escalationExecution) return null;

    const {
      carbonCopyMessage,
      currentStep,
      displayId,
      group,
      originalMessage,
      path,
      status,
      target,
      targetId,
      targetMinutesToAcknowledge,
    } = escalationExecution;
    if (!carbonCopyMessage) return null;

    const {
      attachments,
      body: messageBody,
      createdAt: messageCreatedAt,
      currentSenderRole,
      sender,
      statusesPerRecipient,
    } = carbonCopyMessage;

    const senderName = currentSenderRole
      ? `${currentSenderRole.displayName} (${sender.displayName})`
      : sender.displayName;

    let messagePreview = '';
    if (attachments.length > 0 && !messageBody) {
      messagePreview = `Attachment: ${attachmentType(attachments[0].contentType)}`;
    } else {
      messagePreview = messageBody;
    }

    const statusLine = (
      <div
        className={classNames(
          classes('section'),
          classes('escalation-status'),
          classes({ status })
        )}
      >
        {escalationStatusLine(escalationExecution)}
      </div>
    );

    const originalStatusPerRecipient =
      originalMessage &&
      originalMessage.statusesPerRecipient.find(({ userId }) => targetId === userId);
    const {
      createdAt: originalStatusCreatedAt = new Date(),
      status: originalMessageStatus = 'NEW',
    } = originalStatusPerRecipient || {};
    const {
      createdAt: carbonCopyStatusCreatedAt = new Date(),
      status: carbonCopyMessageStatus = 'NEW',
    } = statusesPerRecipient.find(({ userId }) => targetId === userId) || {};

    let originalCreatedAt, originalStatus;
    const statusComparison = compareRecipientStatus(originalMessageStatus, carbonCopyMessageStatus);
    if (statusComparison === 1) {
      originalCreatedAt = originalStatusCreatedAt;
      originalStatus = originalMessageStatus;
    } else {
      if (statusComparison === 0 && originalStatusPerRecipient) {
        originalCreatedAt =
          originalStatusCreatedAt < carbonCopyStatusCreatedAt
            ? originalStatusCreatedAt
            : carbonCopyStatusCreatedAt;
      } else {
        originalCreatedAt = carbonCopyStatusCreatedAt;
      }
      originalStatus = carbonCopyMessageStatus;
    }

    const targetTag =
      target.isRoleBot && target.botRole && target.botRole.tag
        ? target.botRole.tag.displayName
        : null;

    let step = 0;
    const backups = [];
    for (const stage of path) {
      const { minutesToAcknowledge, targets } = stage;
      step++;

      for (const target of targets) {
        const currTargetTag =
          target.isRoleBot && target.botRole && target.botRole.tag
            ? target.botRole.tag.displayName
            : null;
        const { status = 'NEW', createdAt = new Date() } =
          statusesPerRecipient.find(({ userId }) => target.id === userId) || {};
        const title = `BACKUP ${step}`;

        const backup = (
          <div key={target.id} className={classes('target')}>
            <div className={classes('target-info')}>
              <div className={classes('recipient-type')}>{title}</div>
              <div className={classes('name')}>{target.displayName}</div>
              {currTargetTag && <div className={classes('tag')}>{currTargetTag}</div>}
              <div className={classes('duration')}>
                Time to Acknowledge: {minutesToAcknowledge} {pluralize('Min', minutesToAcknowledge)}
              </div>
            </div>
            {step <= currentStep && (
              <div className={classes('target-status')}>
                <div className={classNames(classes('status'), classes({ status }))}>
                  {messageRecipientStatusToReadableLabel(status)}
                </div>
                <div>{moment(createdAt).format('h:mm A')}</div>
              </div>
            )}
          </div>
        );

        backups.push(backup);
      }
    }

    return (
      <div className={classes()}>
        <Scrollbars shouldScrollOverflow={true}>
          <div className={classes('name-and-avatar')}>
            <div className={classes('avatar-container')}>
              <GroupAvatar ariaLabel="Info Pane Avatar Image" group={group} size="fit" />
            </div>
            <div aria-label="Info Pane Name" className={classes('name-container')}>
              Escalation {displayId}
            </div>
          </div>
          {statusLine}
          <Card header="ESCALATION DETAILS">
            <div className={classNames(classes('section'))}>
              <div className={classes('sender')}>{senderName}</div>
              <div className={classes('message-body')}>{messagePreview}</div>
              <div className={classes('message-time')}>
                Sent {moment(messageCreatedAt).format('h:mm A')}
              </div>
            </div>
          </Card>
          <Card header="ESCALATION PATH">
            <div className={classNames(classes('section'))}>
              <div key={targetId} className={classes('target')}>
                <div className={classes('target-info')}>
                  <div className={classes('recipient-type')}>ORIGINAL</div>
                  <div className={classes('name')}>{target.displayName}</div>
                  {targetTag && <div className={classes('tag')}>{targetTag}</div>}
                  <div className={classes('duration')}>
                    Time to Acknowledge: {targetMinutesToAcknowledge}
                    {pluralize('Min', targetMinutesToAcknowledge)}
                  </div>
                </div>
                <div className={classNames(classes('target-status'))}>
                  <div
                    className={classNames(classes('status'), classes({ status: originalStatus }))}
                  >
                    {messageRecipientStatusToReadableLabel(originalStatus)}
                  </div>
                  <div>{moment(originalCreatedAt).format('h:mm A')}</div>
                </div>
              </div>
              {backups}
            </div>
          </Card>
        </Scrollbars>
      </div>
    );
  }
}

export default mobxInjectSelect({
  escalationStore: ['ensureEscalationExecution'],
  messageStore: ['compareRecipientStatus'],
})(EscalationPolicyInfoPane);
