import React, { useEffect } from 'react';
import styles from './Toast.module.css';

export type TableColumn = {
  field: string;
  headerName: string;
};

export const ToastTypes = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  WARNING: 'WARNING',
} as const;

export interface ToastProps {
  autoHideDuration?: number;
  message: string;
  open: boolean;
  onClose: () => void;
  type: keyof typeof ToastTypes;
}

const AUTO_HIDE_TIME = 3000;

export const Toast: React.FC<ToastProps> = ({
  // optional, alter the default hide duration
  autoHideDuration = AUTO_HIDE_TIME,
  // the text message to show
  message = '',
  // the type of toast to show, see ToastTypes for options
  type,
  // do something after toast closes
  onClose,
  // state of the toast
  open = false,
}) => {
  useEffect(() => {
    if (open) {
      setTimeout(onClose, autoHideDuration);
    }
  }, [autoHideDuration, onClose, open]);

  if (!open) {
    return null;
  }

  return (
    <div className={styles.toastContainer}>
      <div className={styles.toastHolder}>
        <div className={`${styles.toast} ${styles[type]} ${open ? styles.open : ''}`}>
          {message}
        </div>
      </div>
    </div>
  );
};
