// @ts-nocheck
import _ from 'lodash';
import {
  FeatureService,
  MessageMetadataNamespaces,
  MessagePriority,
  MessageRecipientStatus,
  MessageSubType,
  MessageType,
  PatientCareCardActionType,
  PatientCareCardType,
  PatientCareCardUIType,
} from './enums';

export default function (store, { host }) {
  const Message = store.defineModel('message', {
    replaceKeys: {
      clientId: 'id',
      createdTime: 'createdAt',
      data: 'metadata',
      dndAutoForwardedFrom: 'autoForwardedFromId',
      dndAutoForwardedTo: 'autoForwardedToId',
      dor: 'deleteOnRead',
      escalationExecId: 'escalationExecutionId',
      groupAvatar: 'groupAvatarUrl',
      groupToken: 'groupId',
      messageId: 'id',
      originalData: 'originalMetadata',
      originalMsgId: 'originalMessageId',
      originalSenderName: 'originalSenderDisplayName',
      originalTimestamp: 'originalForwardedMessageCreatedAt',
      recipient: 'recipientId',
      recipientOrganization: 'recipientOrganizationId',
      recipientOrgToken: 'recipientOrganizationId',
      recipientToken: 'recipientId',
      sender: 'senderId',
      senderAvatar: 'senderAvatarUrl',
      senderOrganization: 'senderOrganizationId',
      senderOrgToken: 'senderOrganizationId',
      senderToken: 'senderId',
    },

    transientAttrs: [
      '__organizationId',
      '$synced',
      'escalationExecutionChange',
      'groupMembersChange',
      'inTimeline',
    ],

    defaultValues: {
      inTimeline: false,
      isHistoricalAlert: false,
      isInjected: false,
      isUnread: false,
      messageType: MessageType.USER_SENT,
      reactions: [],
      senderStatus: MessageRecipientStatus.NEW,
      senderError: undefined,
      shouldEnsureRecipientStatus: false,
      sortNumber: 0,
      isGroupReplayStatusesFetched: false,
    },

    skipCamelizationForKeys: ['escalation', 'metadata', 'originalMetadata'],

    parseAttrs(attrs) {
      const { currentUserId, currentUser } = host;
      if (attrs.teamRequest) {
        if (!attrs.senderId) {
          attrs.senderId = attrs.teamRequest.createdById;
        }
        attrs.teamRequestId = attrs.teamRequest.id;
        delete attrs.teamRequest;
      }

      // server returns "attachment_name" field on the message itself and "attachments" as an array
      // since currently only a single attachment is allowed, need to merge the two
      // server may return "attachment_name":"undefined" as a string, so need to filter that
      if ('attachmentName' in attrs) {
        const attachmentName = attrs.attachmentName;
        delete attrs.attachmentName;
        if (
          attachmentName !== 'undefined' &&
          Array.isArray(attrs.attachments) &&
          attrs.attachments.length > 0 &&
          attachmentName
        ) {
          attrs.attachments[0].name = attachmentName;
        }
      }

      if (Array.isArray(attrs.attachments)) {
        attrs.attachments.forEach((attachment, i) => (attachment.id = `${i + 1}`));
      }

      if ('priority' in attrs) {
        attrs.priority = MessagePriority.resolve(attrs.priority);
      }

      if ('createdAt' in attrs && typeof attrs.createdAt === 'string') {
        attrs.createdAt = new Date(attrs.createdAt);
      }

      if ('expireIn' in attrs) {
        attrs.expiresAt = Date.now() + attrs.expireIn * 1000;
        delete attrs.expireIn;
      }

      if ('availabilityDndAutoForwardedTo' in attrs) {
        attrs.isAvailabilityDndAutoForwardedTo = !!attrs.availabilityDndAutoForwardedTo;
      }

      if ('recipientStatus' in attrs) {
        delete attrs.recipientStatus;
      }
      if ('status' in attrs) {
        delete attrs.status;
      }
      if ('xmlns' in attrs) {
        delete attrs.xmlns;
      }

      if ('escalation' in attrs) {
        const execution = host.escalations._updateEscalation(attrs.escalation);
        delete attrs.escalation;
        attrs.escalationExecutionId = execution.id;
      }

      if ('subType' in attrs) {
        attrs.subType = MessageSubType.resolve(attrs.subType);
      }

      // convert metadata JSON string payloads into objects
      if (attrs.metadata) {
        attrs.metadata = _.castArray(attrs.metadata)
          .map(host.metadata.__parseMessageMetadata)
          .filter(Boolean);

        const alertAttrs = attrs.metadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.ALERT
        );

        const mentionAttrs = attrs.metadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.CONVERSATION_MENTIONS
        );

        const senderRoleAttrs = attrs.metadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.ROLE_SENDER
        );

        const roleOwnerAttrs = attrs.metadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.ROLE_OWNER
        );

        const patientCareAttrs = attrs.metadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.PATIENT_CARE
        );

        const vwrCallAttrs = attrs.metadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.VWR_CALL
        );

        if (alertAttrs) {
          host.messages._handleAlertPayload(attrs, alertAttrs.payload);
        }

        if (vwrCallAttrs?.payload?.status === 'invite' && vwrCallAttrs?.payload?.twilio_room_name) {
          attrs.vwrCallInvite = vwrCallAttrs.payload;

          const {
            createdAt,
            id,
            senderOrganizationId,
            vwrCallInvite: {
              is_video,
              participant_tokens,
              twilio_room_name,
              vwr_call_id,
              visitor_account_token,
              visitor_display_name,
              vwr_visitor_access_token,
            },
          } = attrs;

          host.models.Call.inject({
            accessToken: vwr_visitor_access_token,
            callStatus: 'vwr_card',
            id: twilio_room_name,
            entityType: 'call',
            memberIds: participant_tokens,
            membersStatuses: {},
            organizationId: senderOrganizationId,
            payload: {
              callId: vwr_call_id,
              callerId: null,
              date: createdAt,
              groupId: null,
              identity: id,
              isVideo: is_video,
              metadata: {},
              name: visitor_display_name,
              networkType: 'patient',
              orgId: senderOrganizationId,
              participantsTokens: participant_tokens,
              pId: visitor_account_token,
              recipientToken: visitor_account_token,
              roleTokens: {},
              disabled_participants: {},
              type: 'vwr',
              staffId: attrs.senderId,
            },
            roomName: twilio_room_name,
            type: 'vwr',
          });
        }

        if (mentionAttrs && mentionAttrs.payload.length > 0) {
          const ids = [currentUserId, ...currentUser.roles.map((r) => r.botUserId)];

          const isCurrentMemberMentionedInConversation = mentionAttrs?.payload?.some((e) =>
            ids.includes(e.entity_id)
          );

          if (isCurrentMemberMentionedInConversation) attrs.isMentioned = true;
        }

        if (senderRoleAttrs && senderRoleAttrs.payload) {
          const { senderOrganizationId: organizationId } = attrs;
          const metadata = senderRoleAttrs.payload;
          attrs.senderRole = host.roles.__parseRoleFromMetadata({ metadata, organizationId });
          attrs.currentSenderRoleId = attrs.senderRole.id;
        }

        if (roleOwnerAttrs && roleOwnerAttrs.payload) {
          const { senderOrganizationId: organizationId, senderId } = attrs;
          const metadata = roleOwnerAttrs.payload;
          attrs.senderId = metadata.token;

          if (senderId !== metadata.token) {
            attrs.senderRole = {
              $entityType: 'role',
              botUserId: senderId,
              id: `role:${senderId}`,
              organizationId,
            };
            attrs.currentSenderRoleId = `role:${senderId}`;
          }
        }

        if (patientCareAttrs && patientCareAttrs.payload) {
          const patientCareCardItems = [];

          for (const item of patientCareAttrs.payload.components) {
            if ('type' in item) {
              item.type = PatientCareCardUIType.resolve(item.type);
            }
            if ('sub_type' in item) {
              item.sub_type = PatientCareCardType.resolve(item.sub_type);
            }
            if ('action_type' in item) {
              item.action_type = PatientCareCardActionType.resolve(item.action_type);
            }
            patientCareCardItems.push(item);
          }
          attrs.patientCareCard = patientCareCardItems;
        }
      } else if ('metadata' in attrs) {
        delete attrs['metadata'];
      }

      if (attrs.originalMetadata) {
        attrs.originalMetadata = _.castArray(attrs.originalMetadata)
          .map(host.metadata.__parseMessageMetadata)
          .filter(Boolean);

        const senderRoleAttrs = attrs.originalMetadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.ROLE_SENDER
        );

        const roleOwnerAttrs = attrs.originalMetadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.ROLE_OWNER
        );

        const patientAttrs = attrs.originalMetadata.find(
          ({ namespace }) => namespace === MessageMetadataNamespaces.PATIENT_MESSAGING
        );

        if (senderRoleAttrs && senderRoleAttrs.payload) {
          const { senderOrganizationId: organizationId } = attrs;
          const metadata = senderRoleAttrs.payload;
          attrs.originalSenderRole = host.roles.__parseRoleFromMetadata({
            metadata,
            organizationId,
          });
        }

        if (roleOwnerAttrs && roleOwnerAttrs.payload) {
          const {
            originalSender,
            originalSenderDisplayName,
            originalSenderRole,
            senderOrganizationId: organizationId,
          } = attrs;
          const metadata = roleOwnerAttrs.payload;

          if (!originalSenderRole && typeof originalSender === 'string') {
            attrs.originalSenderRole = {
              $entityType: 'role',
              botUserId: originalSender,
              displayName: originalSenderDisplayName,
              id: `role:${originalSender}`,
              organizationId,
            };
          }

          if (!originalSender || (originalSender && typeof originalSender === 'string')) {
            attrs.originalSender = {
              $entityType: 'user',
              displayName: metadata.display_name,
              id: metadata.token,
              organizationId,
            };
          }
        }

        if ('isForwarded' in attrs && attrs.isForwarded && patientAttrs) {
          attrs.isForwardedFromPatientNetwork = true;

          if (patientAttrs.payload) {
            attrs.patientDetails = {
              isPatientContact: patientAttrs.payload.is_patient_contact,
              mrn: patientAttrs.payload.patient_mrn,
              patientId: patientAttrs.payload.patient_id,
              patientName: patientAttrs.payload.patient_name,
            };

            if (patientAttrs.payload.is_patient_contact) {
              attrs.patientDetails.contactId = patientAttrs.payload.patient_contact_id;
              attrs.patientDetails.contactName = patientAttrs.payload.patient_contact_name;
              attrs.patientDetails.relation = patientAttrs.payload.relation_name;
            }

            attrs.originalGroupId = patientAttrs.payload.group_id;
            attrs.originalGroupName = patientAttrs.payload.group_name;
          }
        }
      }

      if ('originalSender' in attrs && typeof attrs.originalSender === 'string') {
        const {
          senderOrganizationId: organizationId,
          originalSender,
          originalSenderDisplayName,
        } = attrs;

        attrs.originalSender = {
          $entityType: 'user',
          displayName: originalSenderDisplayName,
          id: originalSender,
          organizationId,
        };

        if ('patientDetails' in attrs) {
          if (attrs.patientDetails.patientId === attrs.originalSender.id) {
            attrs.originalSender.isPatient = true;
          }

          if (attrs.patientDetails.contactId === attrs.originalSender.id) {
            attrs.originalSender.isPatientContact = true;
            attrs.originalSender.relation = attrs.patientDetails.relation;
          }
        }

        delete attrs.originalSenderDisplayName;
      }

      if (attrs.featureService === FeatureService.ROLE_ALERTS) {
        attrs.subType = MessageSubType.ROLE_ALERTS;
      }

      return attrs;
    },

    afterAssignment(entity) {
      let {
        __organizationId,
        counterParty,
        counterPartyId,
        counterPartyType,
        createdAt,
        isOutgoing,
        recipientOrganizationId,
        senderId,
        senderOrganizationId,
      } = entity;
      const { currentUserId } = host;

      if (counterParty) {
        counterPartyId = counterParty.id;
        counterPartyType = counterParty.$entityType;
        switch (counterPartyType) {
          case 'group':
            entity.groupId = counterPartyId;
            delete entity.recipientId;
            break;
          case 'distributionList':
            entity.distributionListId = counterPartyId;
            delete entity.recipientId;
            break;
          default:
        }
      } else {
        counterParty =
          counterPartyType && counterPartyId
            ? store.store.get(counterPartyType, counterPartyId)
            : null;
      }

      entity.counterParty = counterParty;
      entity.counterPartyId = counterPartyId;
      entity.counterPartyType = counterPartyType;

      if (!createdAt) {
        createdAt = new Date();
      }

      if (senderId) {
        isOutgoing = host.messages._isMessageOutgoing(entity);
      }

      if (isOutgoing && senderOrganizationId) {
        __organizationId = senderOrganizationId;
      } else if (!isOutgoing && recipientOrganizationId) {
        __organizationId = recipientOrganizationId;
      }

      entity.__organizationId = __organizationId;
      entity.canRecall = counterPartyType !== 'distributionList' && senderId === currentUserId;
      entity.createdAt = createdAt;
      entity.isOutgoing = isOutgoing;

      if (
        ['role', 'team'].includes(counterPartyType) &&
        !!entity.autoForwardedFromId &&
        !host.config.extendAutoForwardOptions
      ) {
        entity.isAutoForwarded = null;
      } else {
        entity.isAutoForwarded = !!entity.autoForwardedFromId;
      }

      if (!entity.subType) {
        entity.subType = MessageSubType.NA;
      }

      host.messages._setRecipientStatus(entity);

      if ([MessageSubType.ALERTS, MessageSubType.ROLE_ALERTS].includes(entity.subType)) {
        host.messages._setAlertIncludesCurrentUserOrRole(entity);
      }

      if ([MessageSubType.ALERTS].includes(entity.subType)) {
        host.messages._setCurrentRecipientAlertAction(entity, entity.alertAttrs);
      }

      if (!entity.isOutgoing || !entity.isGroupReplay) {
        delete entity.isGroupReplayStatusesFetched;
      }
    },

    relations: {
      belongsTo: {
        alertConversationGroup: { type: 'group', foreignKey: 'alertConversationGroupId' },
        autoForwardedFrom: { type: 'user', foreignKey: 'autoForwardedFromId' },
        autoForwardedTo: { type: 'user', foreignKey: 'autoForwardedToId' },
        conversation: { type: 'conversation', foreignKey: 'conversationId' },
        currentSenderRole: { type: 'role', foreignKey: 'currentSenderRoleId' },
        distributionList: { type: 'distributionList', foreignKey: 'distributionListId' },
        escalationExecution: { type: 'escalationExecution', foreignKey: 'escalationExecutionId' },
        group: { type: 'group', foreignKey: 'groupId' },
        originalGroup: { type: 'group', foreignKey: 'originalGroupId' },
        recipient: { type: 'user', foreignKey: 'recipientId' },
        recipientOrganization: { type: 'organization', foreignKey: 'recipientOrganizationId' },
        sender: { type: 'user', foreignKey: 'senderId' },
        senderOrganization: { type: 'organization', foreignKey: 'senderOrganizationId' },
        teamRequest: { type: 'teamRequest', foreignKey: 'teamRequestId' },
      },
      hasMany: {
        statusesPerRecipient: { type: 'messageStatusPerRecipient', foreignKey: 'messageId' },
      },
    },
  });

  return Message;
}
