import difference from 'lodash/difference';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import partition from 'lodash/partition';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import {
  LocalDataTrack,
  LocalTrack,
  LocalTrackPublication,
  LocalDataTrackPublication,
} from 'twilio-video';
import VideoCallMemberStatus, {
  VideoCallMemberStatusType,
} from '../models/enums/VideoCallMemberStatus';
import EndCallState, { EndCallStateType } from '../models/enums/EndCallState';
// @ts-ignore
import * as errors from '../errors';
// @ts-ignore
import { jsonCloneDeep } from '../utils';
// @ts-ignore
import Camelizer from '../utils/Camelizer';
import {
  Call,
  CallSSEData,
  CallStateSSEData,
  DeclineOptions,
  EndOptions,
  InviteOptions,
  JoinOptions,
  StartType,
  StartWithGroupType,
  TwilioParticipant,
} from '../types/Calls';
import { User, UserRole } from '../types/User';
import BaseService from './BaseService';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 540, width: 960 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 24000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
  audio: false,
  video: false,
};
const activeMemberStatuses: VideoCallMemberStatusType[] = [
  VideoCallMemberStatus.CONNECTED,
  VideoCallMemberStatus.RINGING,
];

export default class CallsService extends BaseService {
  _dataTrackPublished: { promise?: Promise<null>; resolve?: Function; reject?: Function };
  _ringTimeouts: number[];
  _incomingCallTimeout: number | null;
  _providerRingLimit: number;

  constructor(host: { [k: string]: string | object | Function }, options: { [k: string]: string }) {
    super(host, options);

    this._dataTrackPublished = {};
    this._ringTimeouts = [];
    this._incomingCallTimeout = null;
    this._providerRingLimit = 60 * 1000;
  }

  async start({
    dataTrack,
    isVideo = true,
    metadata = {},
    networkType,
    organizationId,
    participantIds,
    providedContext,
    twilioOptions,
    userId,
  }: StartType) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!participantIds) throw new errors.ValidationError('participantIds', 'required');
    if (!participantIds.length)
      throw new errors.ValidationError('participantIds array', 'required');
    if (!networkType) throw new errors.ValidationError('networkType', 'required');
    if (!get(this.host, 'config.twilioVideo'))
      throw new errors.ValidationError('Twilio config', 'required');

    const { api, users, groups } = this.host;
    const userInfo = this._getUserInfo(userId, organizationId);

    const recipientIds = participantIds.filter((id) => id !== userInfo.id);
    const recipients = await Promise.all(
      recipientIds.map(async (id) => users.find(id, { organizationId }))
    );
    const [patients, providers] = partition(
      recipients,
      (user) => user.isPatient || user.isPatientContact
    );
    const patientData = patients.reduce(
      (memo, r) => ({ ...memo, [r.isPatient ? 'p_id' : 'pc_id']: r.id }),
      {}
    );
    const recipient = patients[0] || providers[0];
    const callId = `${userInfo.id}+${recipient.id}`;

    let context;
    let groupId;
    const matchingGroups = await groups.findAllWithSpecificMembers(
      [...recipientIds, userInfo.id],
      organizationId
    );
    if (matchingGroups.length === 1) {
      context = { id: matchingGroups[0].id, type: 'group' };
      groupId = context.id;
    } else if (!matchingGroups.length && recipientIds.length === 1) {
      context = { id: recipientIds[0], type: 'account' };
    }

    const payload = {
      ...patientData,
      callId,
      callerId: userInfo.id,
      date: new Date().toISOString(),
      groupId,
      identity: userInfo.id,
      isVideo,
      metadata,
      name: userInfo.displayName,
      networkType,
      orgId: organizationId,
      participantsTokens: recipientIds,
      recipientToken: recipient.id,
      roleTokens: this._getRoleTokens(recipients, organizationId),
    };

    const {
      accessToken,
      roomName,
      disabledParticipants = {},
    } = await api.calls.start({
      organization: organizationId,
      recipients: recipientIds,
      payload: Camelizer.underscoreObject(payload),
      context:
        !isEmpty(providedContext) && providedContext?.id && providedContext?.type
          ? providedContext
          : context
          ? context
          : {},
    });

    this.host.models.Call.inject({
      memberIds: [...recipientIds, userInfo.id],
      id: roomName,
      payload,
      roomName,
    });

    let roomProps;
    try {
      roomProps = await this._connectTwilioRoom(accessToken, roomName, dataTrack, twilioOptions);
    } catch (e: unknown) {
      const callExists = this._resolveEntity(roomName, 'call');
      if (callExists) {
        this._clearCallState(callExists);
        throw new Error('Unable to Connect to Twilio');
      }
    }

    if (!this._confirmCallStateDuringStart(roomName, roomProps)) return;

    const callObject = this.host.models.Call.inject({
      ...roomProps,
      accessToken,
      id: roomName,
      payload: { ...payload, disabled_participants: disabledParticipants },
      roomName,
    });

    if (Object.keys(disabledParticipants).length) {
      await api.calls.state(roomName, organizationId, {
        callId,
        payload: {
          callState: 'disabled_participants',
          disabledParticipants,
        },
      });
    }

    const membersStatuses = await this._initMemberStatuses(
      callObject,
      roomProps?.room.participants || []
    );

    if (!this._confirmCallStateDuringStart(roomName, roomProps)) return;

    return this.host.models.Call.inject({
      ...callObject,
      membersStatuses,
    });
  }

  _confirmCallStateDuringStart(roomName: string, roomProps?: Record<string, unknown>) {
    const call = this._resolveEntity(roomName, 'call');
    if (!roomProps) {
      call && this._clearCallState(call);
      return false;
    }
    return !!call;
  }

  async startWithGroup({
    dataTrack,
    groupId,
    isVideo = true,
    metadata = {},
    twilioOptions,
    userId,
  }: StartWithGroupType) {
    if (!groupId) throw new errors.ValidationError('groupId', 'required');
    const group = await this.host.groups.find(groupId);
    if (!group || !group.hasCurrentUserOrRole) throw new errors.NotFoundError('group', groupId);
    const { groupType, memberIds: participantIds, organizationId } = group;

    return this.start({
      dataTrack,
      isVideo,
      metadata,
      networkType: groupType === 'PATIENT_MESSAGING' ? 'patient' : 'provider',
      organizationId,
      participantIds,
      twilioOptions,
      userId,
    });
  }

  async decline(call: string | Object, declineOptions?: DeclineOptions) {
    const { metadata, userId } = declineOptions || {};
    return await this.end(call, { metadata, reason: 'declined', userId });
  }

  async end(call: string | Object, endOptions?: EndOptions) {
    const { metadata, reason = 'ended', userId } = endOptions || {};
    const identifier = this._resolveModelId(call) as string;
    const newCall: Call = this._resolveEntity(identifier, 'call');
    if (!newCall) return;
    const { callId, organizationId, id: roomName } = newCall;
    const userInfo = this._getUserInfo(userId, organizationId);
    const isVwr = newCall.type === 'vwr';

    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!reason) throw new errors.ValidationError('reason', 'required');
    if (!roomName) throw new errors.ValidationError('roomName', 'required');
    if (!callId) throw new errors.ValidationError('callId', 'required');

    if (newCall.room && get(newCall, 'room.localParticipant.state') === 'connected')
      newCall.room.disconnect();

    if (reason === 'unanswered' || newCall.room) {
      this._clearTimers();
      this.emit('closed');
    }

    if (isVwr) {
      this.host.models.Call.inject({ ...newCall, room: undefined });
    } else {
      this.host.models.Call.eject(newCall);
    }

    return await this.host.api.calls.end(roomName, organizationId, {
      callId,
      payload: { callId, metadata, reason, userToken: userInfo.id },
      reason,
      type: 'call_ended',
      roomName,
      isVwrFeatureEnabled: isVwr,
    });
  }

  async join(call: string | Object, joinOptions?: JoinOptions) {
    const { dataTrack, metadata, twilioOptions } = joinOptions || {};

    const identifier = this._resolveModelId(call) as string;
    const newCall: Call = this._resolveEntity(identifier, 'call');
    const {
      callId,
      organizationId,
      payload: { recipientToken },
      id: roomName,
    } = newCall;
    const isVwr = newCall.type === 'vwr';

    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!roomName) throw new errors.ValidationError('roomName', 'required');
    if (!get(this.host, 'config.twilioVideo'))
      throw new errors.ValidationError('Twilio config', 'required');

    if (this._incomingCallTimeout) clearTimeout(this._incomingCallTimeout);

    const joinResponse = await this.host.api.calls.join(roomName, organizationId, {
      isVwrFeatureEnabled: isVwr,
    });
    const { accessToken } = Camelizer.camelizeObject(joinResponse);

    await this.host.api.calls.answer(roomName, organizationId, {
      payload: {
        recipientToken,
        roomName,
        userToken: this.host.currentUserId,
      },
      callId,
      isVwrFeatureEnabled: isVwr,
    });

    const roomProps = await this._connectTwilioRoom(
      accessToken,
      roomName,
      dataTrack,
      twilioOptions
    );
    const membersStatuses = await this._initMemberStatuses(newCall, roomProps.room.participants);

    if (metadata) await this.updateMetadata(newCall, metadata);

    return this.host.models.Call.inject({
      ...newCall,
      ...roomProps,
      accessToken,
      membersStatuses,
    });
  }

  async inviteUser(call: string | Object, invitedMemberId: string, inviteOptions?: InviteOptions) {
    const { metadata, userId, pfData } = inviteOptions || {};
    const identifier = this._resolveModelId(call) as string;
    const newCall: Call = this._resolveEntity(identifier, 'call');

    if (!invitedMemberId) throw new errors.ValidationError('invitedMemberId', 'required');

    const { callId, membersStatuses, organizationId, payload: callPayload, id: roomName } = newCall;
    const { users } = this.host;
    const userInfo = this._getUserInfo(userId, organizationId);
    const newMemberStatuses = membersStatuses;

    await this._ringAndTrackMember(newCall, invitedMemberId);
    newMemberStatuses[invitedMemberId] = VideoCallMemberStatus.RINGING;

    const participantsTokens = Object.entries(membersStatuses)
      .filter(([id, status]) => {
        const memberStatus = status as VideoCallMemberStatusType;
        return activeMemberStatuses.includes(memberStatus);
      })
      .map(([id]) => id);

    const connectedParticipants = Object.entries(membersStatuses)
      .filter(([id, status]) => status === VideoCallMemberStatus.CONNECTED)
      .map(([id]) => id);

    const participants = await Promise.all(
      participantsTokens.map(async (id) => users.find(id, { organizationId }))
    );

    const payload = {
      ...omit(callPayload, 'groupId'),
      ...pfData,
      callerId: userInfo.id,
      connectedParticipants,
      date: new Date().toISOString(),
      identity: userInfo.id,
      metadata,
      name: `${get(newCall, 'payload.name')} (Group Call)`,
      participantsTokens,
      roleTokens: this._getRoleTokens(participants, organizationId),
    };

    await this.host.api.calls.invite(roomName, organizationId, {
      callId,
      payload,
      recipients: [invitedMemberId],
    });

    await this.host.api.calls.state(roomName, organizationId, {
      callId,
      payload: {
        callState: 'participants_updated',
        inviteInitiator: userInfo.id,
        newParticipants: [invitedMemberId],
        roleTokens: {},
      },
    });

    return this.host.models.Call.inject({
      ...newCall,
      membersStatuses: newMemberStatuses,
    });
  }

  async invitePatient(
    call: string | Object,
    invitedMemberId: string,
    inviteOptions?: InviteOptions
  ) {
    const { metadata, userId } = inviteOptions || {};
    return await this.inviteUser(call, invitedMemberId, {
      metadata,
      userId,
      pfData: { p_id: invitedMemberId },
    });
  }

  async invitePatientContact(
    call: string | Object,
    invitedMemberId: string,
    inviteOptions?: InviteOptions
  ) {
    const { metadata, userId } = inviteOptions || {};
    return await this.inviteUser(call, invitedMemberId, {
      metadata,
      userId,
      pfData: { pc_id: invitedMemberId },
    });
  }

  async sendDataMessage(call: string | Object, message: string | object) {
    if (!call) throw new errors.ValidationError('call', 'required');
    if (!message) throw new errors.ValidationError('message', 'required');

    const identifier = this._resolveModelId(call) as string;
    const newCall: Call = this._resolveEntity(identifier, 'call');
    if (!newCall) throw new errors.NotFoundError('call', identifier);
    const { room } = newCall;
    if (!room) throw new Error('No Twilio Room found');

    const [dataTrackPublication] = Array.from(
      room.localParticipant.dataTracks.values()
    ) as LocalDataTrackPublication[];

    if (!dataTrackPublication)
      throw new Error('No Twilio LocalDataTrackPublication found. Please provide a dataTrack');

    let data: string | undefined = undefined;
    try {
      data = JSON.stringify(message);
    } catch (err) {
      console.error(err);
    }

    if (data) {
      this._dataTrackPublished.promise!.then(() => dataTrackPublication.track.send(data!));
    }
  }

  async updateMetadata(call: string | Object, metadata: object) {
    const identifier = this._resolveModelId(call) as string;
    const newCall: Call = this._resolveEntity(identifier, 'call');

    await this.host.api.calls.state(newCall.id, newCall.organizationId, {
      callId: newCall.callId,
      payload: {
        callState: 'metadata_update',
        metadata,
      },
    });
  }

  async getPatientLink(call: Object, memberId: string) {
    const identifier = this._resolveModelId(call) as string;
    const newCall: Call = this._resolveEntity(identifier, 'call');
    if (!newCall) return;

    const { organizationId, id: roomName } = newCall;

    return await this.host.api.calls.getPatientLink(roomName, memberId, organizationId);
  }

  async findCallLogEntries({
    network,
    organizationId,
    page,
  }: {
    network: string;
    organizationId: string;
    page?: string;
  }) {
    if (!organizationId) throw new errors.ValidationError('organizationId', 'required');
    if (!network) throw new errors.ValidationError('network', 'required');
    const logResponse = await this.host.api.calls.log({ network, organizationId, page });
    const { metadata, results } = Camelizer.camelizeObject(logResponse);

    return {
      metadata,
      // @ts-ignore need to document call log api response
      results: results.map((log) => this.host.models.CallLogEntry.inject(log)),
    };
  }

  async reactToVoipSSE({ data, type }: { data: { roomName: string }; type: string }) {
    const disabledParticipants = get(data, 'payload.disabled_participants', {});
    data = Camelizer.camelizeObject(jsonCloneDeep(data));
    set(data, 'payload.disabledParticipants', disabledParticipants);

    const callStatus = type.split(':')[1];
    const statusFunctions: { [k: string]: Function } = {
      answered: this._handleAnsweredCall,
      ended: this._handleEndedCall,
      incoming: this._handleIncomingCall,
      state: this._handleCallStateChange,
    };

    if (statusFunctions[callStatus]) {
      const callModel = await statusFunctions[callStatus].bind(this)(data);
      callModel && this.emit(callStatus, callModel);
    }
  }

  _handleAnsweredCall(eventData: CallSSEData) {
    const { roomName: callId } = eventData;
    const call = this._resolveEntity(callId, 'call');
    if (!call) return;
    this._clearCallState(call);
    return true;
  }

  _handleEndedCall(eventData: CallSSEData) {
    const {
      payload: { reason, userToken },
      roomName: callId,
    } = eventData;
    const call = this._resolveEntity(callId, 'call');
    if (!call) return;

    if (call.type === 'vwr' && call.room === undefined) {
      return;
    }

    const { membersStatuses, room } = call;
    const eventReason = eventData.reason || reason || '';
    const newMemberStatuses = membersStatuses;

    if (eventReason === 'declined' && room)
      newMemberStatuses[userToken] = VideoCallMemberStatus.DECLINED;

    const endCallState: EndCallStateType = this._getEndCallState(
      call,
      eventData,
      eventReason,
      newMemberStatuses
    );

    if (endCallState === EndCallState.RECIPIENT_BUSY) this.emit('calleeBusy', call);
    if (endCallState !== EndCallState.CALL_CONTINUES) return this._clearCallState(call);

    return this.host.models.Call.inject({
      ...call,
      membersStatuses: newMemberStatuses,
    });
  }

  async _checkIfBusy() {
    if (this.config.allowVoipCheck) {
      const validCalls = await Promise.all(
        this.host.models.Call.getAll()
          .filter((c: Call) => c.room)
          .map(async (c: Call) => {
            try {
              await this.host.api.calls.join(c.roomName, c.organizationId);
              return true;
            } catch (e: unknown) {
              this.host.models.Call.eject(c);
              return false;
            }
          })
      );
      return validCalls.filter(Boolean).length > 0;
    } else {
      return this.host.models.Call.getAll().filter((c: Call) => c.room).length;
    }
  }

  async _handleIncomingCall(eventData: CallSSEData) {
    const callModel = this.host.models.Call.inject(
      Object.assign(eventData, { callStatus: 'incoming' })
    );

    const isBusy = await this._checkIfBusy();

    if (isBusy) {
      window.setTimeout(() => this.decline(callModel), 1000);
    } else {
      this._incomingCallTimeout = window.setTimeout(() => {
        this.end(callModel, { reason: 'unanswered' });
      }, this._providerRingLimit);

      return callModel;
    }
  }

  _handleCallStateChange(eventData: CallStateSSEData) {
    const { roomName: callId } = eventData;
    const call = this._resolveEntity(callId, 'call');
    if (!call) return;

    const newPayload = call.payload;
    let newMemberStatuses = call.membersStatuses;
    const {
      payload: { callState, disabledParticipants, metadata },
    } = eventData;

    if (callState === 'metadata_update') {
      newPayload.metadata = {
        ...newPayload.metadata,
        ...metadata,
      };
    } else if (callState === 'disabled_participants' && disabledParticipants) {
      newPayload.disabledParticipants = disabledParticipants;
      const unavailableMembers = Object.entries(disabledParticipants).reduce(
        (accumulator: { [k: string]: string }, [id]) => {
          accumulator[id] = VideoCallMemberStatus.UNAVAILABLE;
          return accumulator;
        },
        {}
      );
      newMemberStatuses = { ...newMemberStatuses, ...unavailableMembers };
    } else if (callState === 'participants_updated') {
      get(eventData, 'payload.newParticipants', []).forEach(async (newParticipantToken: string) => {
        if (newParticipantToken === this.host.currentUserId) {
          newMemberStatuses[this.host.currentUserId] = VideoCallMemberStatus.CONNECTED;
        } else if (
          newParticipantToken &&
          call.membersStatuses[newParticipantToken] !== VideoCallMemberStatus.UNAVAILABLE
        ) {
          newMemberStatuses[newParticipantToken] = VideoCallMemberStatus.RINGING;
          await this._ringAndTrackMember(call, newParticipantToken);
        }
      });
    }

    return this.host.models.Call.inject({
      ...call,
      payload: newPayload,
      membersStatuses: newMemberStatuses,
    });
  }

  _getEndCallState(
    call: Call,
    eventData: CallSSEData,
    eventReason: string,
    membersStatuses: { [k: string]: string }
  ) {
    const {
      payload: { userToken },
    } = eventData;
    const {
      memberIds,
      payload: { callerId, recipientToken },
      room,
    } = call;
    const { currentUserId } = this.host;

    let output = EndCallState.CALL_CONTINUES;

    if (!room && callerId === userToken) output = EndCallState.ENDED_BY_CALLER;
    else if (userToken === currentUserId) output = EndCallState.ENDED_BY_USER;
    else if (eventReason === 'declined' && memberIds.length === 2 && userToken === recipientToken) {
      output = EndCallState.RECIPIENT_BUSY;
    } else if (call.type === 'vwr' && eventReason === 'vwrended') {
      output = EndCallState.VWR_CALL_CANCELLED;
    } else if (
      ['declined', 'ended', 'unanswered'].includes(eventReason) &&
      room &&
      call.type !== 'vwr'
    ) {
      const activeParticipants = Object.entries(membersStatuses).filter(([id, status]) => {
        const memberStatus = status as VideoCallMemberStatusType;
        return (
          activeMemberStatuses.includes(memberStatus) && ![userToken, currentUserId].includes(id)
        );
      });
      if (activeParticipants.length < 1) output = EndCallState.TOO_FEW_PARTICIPANTS;
    }

    return output;
  }

  _getUserInfo(userOrRoleId: string | undefined, organizationId: string) {
    const { currentUser } = this.host;

    if (!userOrRoleId || userOrRoleId === currentUser.id) return currentUser;

    const role = get(currentUser, 'roles', []).find(
      (role: UserRole) => role.botUserId === userOrRoleId && role.organizationId === organizationId
    );

    if (!role) throw new errors.NotFoundError('user', userOrRoleId);

    return { id: role.botUserId, displayName: role.displayName };
  }

  async _initMemberStatuses(call: Call, participantsMap: Map<string, TwilioParticipant>) {
    const connectedParticipantIds: string[] = Array.from(participantsMap.values()).map(
      (p) => p.identity
    );
    const { currentUserId, users } = this.host;
    const currentMemberIds: string[] = get(call, 'memberIds', []);
    const activeParticipants = uniq(
      currentMemberIds.concat(connectedParticipantIds).concat(currentUserId)
    );
    // TODO: Does this logic need to change with Family/Contacts
    const patientAndContactIds = ['pId', 'pcId']
      .map((id) => get(call, `payload.${id}`))
      .filter((x) => x);
    const missedPatientAndContactIds = difference(patientAndContactIds, activeParticipants);

    // Initialize status of all members
    const newStatuses = uniq(activeParticipants.concat(missedPatientAndContactIds)).reduce(
      (obj, id) => {
        const memberId: string = id as string;
        if (call.membersStatuses[memberId]) return obj;

        let callStatusKey = 'RINGING';
        if (connectedParticipantIds.includes(memberId) || memberId === currentUserId) {
          callStatusKey = 'CONNECTED';
        } else if (get(call, `payload.disabledParticipants[${memberId}]`, false)) {
          callStatusKey = 'UNAVAILABLE';
        } else if (missedPatientAndContactIds.includes(memberId)) {
          callStatusKey = 'NOTCONNECTED';
        }
        return { ...obj, [memberId]: VideoCallMemberStatus[callStatusKey] };
      },
      {}
    );

    const membersStatuses: { [k: string]: string } = {
      ...call.membersStatuses,
      ...newStatuses,
    };

    // Set status of RINGING members to MISSED after time limit from call start
    const millisecondsSinceCallStarted = Math.max(
      new Date().valueOf() - new Date(call.payload.date).valueOf(),
      0
    );
    const patientRingTimeLimit = await this._getRingTimeLimit(
      call.organizationId,
      get(call, 'payload.networkType', 'patient')
    );

    currentMemberIds.forEach(async (memberId) => {
      if (membersStatuses[memberId] !== VideoCallMemberStatus.RINGING) return;
      const member = await users.find(memberId, { organizationId: call.organizationId });
      const isPatientOrContact = member.isPatient || member.isPatientContact;
      if (isPatientOrContact && patientRingTimeLimit === -1) return;
      const timeLimit = !isPatientOrContact ? this._providerRingLimit : patientRingTimeLimit;
      if (millisecondsSinceCallStarted >= timeLimit) return;

      this._ringTimeouts.push(
        window.setTimeout(() => {
          const identifier: string = this._resolveModelId(call) as string;
          const updatedCall = this._resolveEntity(identifier, 'call');
          if (!updatedCall) return;
          if (updatedCall?.membersStatuses[memberId] === VideoCallMemberStatus.RINGING) {
            this._emitMemberStatusUpdate(updatedCall, memberId, VideoCallMemberStatus.MISSED);
          }
        }, timeLimit - millisecondsSinceCallStarted)
      );
    });

    return membersStatuses;
  }

  _emitMemberStatusUpdate(call: Call, memberId: string, status: string) {
    const identifier: string = this._resolveModelId(call) as string;
    call = this._resolveEntity(identifier, 'call');
    if (!call) return;

    const { membersStatuses = {} } = call;
    const newMemberStatuses = {
      ...membersStatuses,
      [memberId]: status,
    };

    const callModel = this.host.models.Call.inject({
      ...call,
      membersStatuses: newMemberStatuses,
    });

    this.emit('memberUpdate', callModel);

    const activeParticipants = Object.entries(newMemberStatuses).filter(([id, status]) => {
      const memberStatus = status as VideoCallMemberStatusType;
      return activeMemberStatuses.includes(memberStatus) && this.host.currentUserId !== id;
    });

    if (activeParticipants.length < 1 && call.type !== 'vwr') this._clearCallState(callModel);
  }

  _clearCallState(call: Call) {
    const { room } = call;
    if (room) {
      room.off(
        'participantConnected',
        this._twilioParticipantStatusChange.bind(this, call.id, 'connected')
      );
      room.off(
        'participantDisconnected',
        this._twilioParticipantStatusChange.bind(this, call.id, 'disconnected')
      );
      this.end(call, { reason: 'ended' });
    } else {
      this.host.models.Call.eject(call);
      this._clearTimers();
      this.emit('closed');
    }
  }

  _clearTimers() {
    this._ringTimeouts.forEach(clearTimeout);
    if (this._incomingCallTimeout) clearTimeout(this._incomingCallTimeout);
  }

  async _connectTwilioRoom(
    accessToken: string,
    callId: string,
    dataTrack?: LocalDataTrack,
    twilioOptions?: { [k: string]: string }
  ) {
    const tracks = [];

    if (dataTrack) tracks.push(dataTrack);
    const room = await this.host.config.twilioVideo.connect(accessToken, {
      ...connectionOptions,
      ...twilioOptions,
      tracks,
    });
    room.on(
      'participantConnected',
      this._twilioParticipantStatusChange.bind(this, callId, 'connected')
    );
    room.on(
      'participantDisconnected',
      this._twilioParticipantStatusChange.bind(this, callId, 'disconnected')
    );

    if (dataTrack) {
      this._dataTrackPublished = {};
      this._dataTrackPublished.promise = new Promise((resolve, reject) => {
        this._dataTrackPublished.resolve = resolve;
        this._dataTrackPublished.reject = reject;
      });

      room.localParticipant.on('trackPublished', (publication: LocalTrackPublication) => {
        if (publication.track === dataTrack) {
          this._dataTrackPublished.resolve!();
        }
      });

      room.localParticipant.on('trackPublicationFailed', (error: Error, track: LocalTrack) => {
        if (track === dataTrack) {
          this._dataTrackPublished.reject!(error);
        }
      });
    }

    return { room };
  }

  _twilioParticipantStatusChange(callId: string, status: string, { identity }: TwilioParticipant) {
    const identifier: string = this._resolveModelId(callId) as string;
    const call = this._resolveEntity(identifier, 'call');
    const memberStatus =
      status === 'connected' ? VideoCallMemberStatus.CONNECTED : VideoCallMemberStatus.LEFTCALL;
    this._emitMemberStatusUpdate(call, identity, memberStatus);
  }

  async _ringAndTrackMember(call: Call, memberId: string) {
    const member = await this.host.users.find(memberId, { organizationId: call.organizationId });
    const isPatientOrContact = member.isPatient || member.isPatientContact;
    const patientRingTimeLimit: number = await this._getRingTimeLimit(
      call.organizationId,
      get(call, 'payload.networkType', 'patient')
    );

    if (isPatientOrContact && patientRingTimeLimit === -1) return;

    const timeLimit = !isPatientOrContact ? this._providerRingLimit : patientRingTimeLimit;
    this._ringTimeouts.push(
      window.setTimeout(() => {
        const identifier: string = this._resolveModelId(call) as string;
        const updatedCall = this._resolveEntity(identifier, 'call');
        if (updatedCall.membersStatuses[memberId] === VideoCallMemberStatus.RINGING) {
          this._emitMemberStatusUpdate(call, memberId, VideoCallMemberStatus.MISSED);
        }
      }, timeLimit)
    );
  }

  _getRoleTokens(users: User[], organizationId: string) {
    const getUserRoles = (user: User) => {
      user.roles
        .filter((role) => role.organizationId === organizationId)
        .map((role) => role.botUserId);
    };

    return users
      .filter((user) => user.roles && user.roles.length)
      .reduce(
        (memo, user) => ({
          ...memo,
          [user.id]: getUserRoles(user),
        }),
        {}
      );
  }

  async _getRingTimeLimit(organizationId: string, networkType: string) {
    if (networkType !== 'patient') return this._providerRingLimit;
    const { pfVideoCallLinkTtl = 60 } = await this.host.organizations.find(organizationId);
    return pfVideoCallLinkTtl * 1000;
  }

  async getConnectedParticipants(accessToken: string, organizationId: string) {
    return this.host.api.calls.getConnectedParticipants(accessToken, organizationId);
  }
}
