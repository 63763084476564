import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import { partition, get } from 'lodash';
import { SearchTypes, VideoCallMemberStatus } from '../../models/enums/';
import BEM from '../bem';
import { ReactComponent as IconVideoSvg } from '../images/icon-video.svg';
import mobxInjectSelect from '../utils/mobxInjectSelect';
import { EntityAvatar, Modal, RecipientSearchPicker, VideoCallMember } from './';

const PATIENT_NETWORK_MEMBER_LIMIT = 4;
const PROVIDER_NETWORK_MEMBER_LIMIT = 10;
const classes = BEM.with('AddMemberToVideoCall');
const buttonType = {
  patient: 'patient',
  provider: 'provider',
};

function AddMemberToVideoCall({ closeModal, currentCall, currentUserId, findUser, inviteMember }) {
  const [activeButton, setActiveButton] = useState('');
  const [patientOrContactList, setPatientOrContactList] = useState([]);
  const [patientDropDownOptions, setPatientDropDownOptions] = useState([]);
  const [patientInRoom, setPatientInRoom] = useState([]);
  const [providerList, setProviderList] = useState([]);

  const buildPatientDropDown = (patient) => {
    //If the user is not a member of the group they can't get patient details
    if (!patient) return [];
    return [
      ...patient.contacts.map((c) => ({
        id: c.userId,
        user: c.user,
        display: `${c.user.displayName} (${c.relation})`,
      })),
      {
        id: patient.userId,
        user: patient.user,
        display: `${patient.user.displayName} (Patient)`,
      },
    ];
  };

  const addMember = (members) => {
    if (!members.length) {
      return;
    }
    members.forEach((member) => {
      inviteMember(member);
    });
    setActiveButton('');
  };

  const toggleMemberSelect = (type) => {
    setActiveButton(activeButton === type ? '' : type);
  };

  const videoSvg = (isActive) => {
    return <IconVideoSvg fill={isActive ? '#fff' : '#4A657B'} height={15} width={15} />;
  };

  const hidePatientList = () => {
    if (activeButton === buttonType.patient) {
      toggleMemberSelect(buttonType.patient);
    }
  };

  useEffect(() => {
    async function getMembers() {
      const memberIds = Object.keys(get(currentCall, 'membersStatuses', {}));
      const members = await Promise.all(
        memberIds.map(async (id) => findUser(id, get(currentCall, 'payload.orgId')))
      );
      const [patientOrContacts, providers] = partition(
        members,
        (member) => member.isPatient || member.isPatientContact || member.isVisitor
      );
      const currentProvider = providers.find((provider) => provider.id === currentUserId);
      if (!currentProvider) return;
      const otherProviders = providers.filter((provider) => provider.id !== currentUserId);
      const sortedProviders = [currentProvider, ...otherProviders];
      setPatientDropDownOptions(buildPatientDropDown(get(patientOrContacts, '[0].patient')));
      setPatientInRoom(patientOrContacts.find((p) => p.isPatient));
      setPatientOrContactList(patientOrContacts);
      setProviderList(sortedProviders);
    }
    if (currentCall) getMembers();
    else closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCall, findUser]);

  if (!currentCall) return null;

  const { networkType } = currentCall.payload;
  const activeStatuses = [VideoCallMemberStatus.CONNECTED, VideoCallMemberStatus.RINGING];
  const activeMembers = Object.values(get(currentCall, 'membersStatuses', {})).filter((s) =>
    activeStatuses.includes(s)
  ).length;
  const canInvite =
    activeMembers <
    (networkType === 'patient' ? PATIENT_NETWORK_MEMBER_LIMIT : PROVIDER_NETWORK_MEMBER_LIMIT);
  const filteredPatientDropDownOptions = patientDropDownOptions.filter(
    (option) =>
      !patientOrContactList.map((patientOrContact) => patientOrContact.id).includes(option.id)
  );
  const isPatientInviteEnabled = filteredPatientDropDownOptions.length > 0;
  const isVwrCall = currentCall.type && currentCall.type === 'vwr';
  const enabledCapabilities =
    networkType === 'patient'
      ? ['pf_group_video_call']
      : currentCall.payload?.isVideo
      ? ['group_video_call']
      : ['group_audio_call'];

  return (
    <div className={classes('body')}>
      {networkType === 'patient' && (
        <div className={classes('patient-contacts')}>
          <OutsideClickHandler onOutsideClick={hidePatientList}>
            <div className={classes('title-container')} onClick={hidePatientList}>
              <div className={classes('title-text')}>
                {isVwrCall ? 'Patient' : 'Patient & Contacts'}
              </div>
              {!isVwrCall && (
                <button
                  className={classes('invite-button', {
                    active: activeButton === buttonType.patient,
                  })}
                  disabled={!canInvite || !isPatientInviteEnabled}
                  onClick={() => toggleMemberSelect(buttonType.patient)}
                >
                  {canInvite && videoSvg(activeButton === buttonType.patient)}
                  <div>
                    {canInvite
                      ? `Invite ${!patientInRoom ? 'patient &' : ''} contacts`
                      : `${PATIENT_NETWORK_MEMBER_LIMIT} member limit`}
                  </div>
                </button>
              )}
            </div>
            {activeButton === buttonType.patient && (
              <div className={classes('patient-picker-container')}>
                {filteredPatientDropDownOptions
                  .filter(
                    (option) =>
                      !patientOrContactList
                        .map((patientOrContact) => patientOrContact.id)
                        .includes(option.id)
                  )
                  .map((option) => (
                    <div
                      key={option.id}
                      className={classes('patient-picker-option')}
                      onClick={() => addMember([option.user])}
                    >
                      <EntityAvatar
                        entity={option.user}
                        indicatorSize={'TINY'}
                        showPresenceIndicator={true}
                        size={16}
                      />
                      <div className={classes('patient-picker-option-title')}>
                        {' '}
                        {option.display}{' '}
                      </div>
                      {!isVwrCall && (
                        <div className={classes('patient-picker-option-invite')}> Invite </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </OutsideClickHandler>
          <div className={classes('provider-list-container')}>
            {patientOrContactList &&
              patientOrContactList.map((member) => (
                <VideoCallMember key={member.id} member={member} canInvite={canInvite} />
              ))}
          </div>
        </div>
      )}
      <div className={classes('providers')}>
        <div className={classes('title-container')}>
          {networkType === 'patient' ? (
            <div className={classes('title-text')}>{'Providers'}</div>
          ) : (
            <div
              className={classes('title-text-members')}
            >{`${activeMembers}/10 Members Connected`}</div>
          )}
          <button
            className={classes('invite-button', {
              active: activeButton === buttonType.provider,
            })}
            disabled={!canInvite}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => toggleMemberSelect(buttonType.provider)}
          >
            {canInvite && videoSvg(activeButton === buttonType.provider)}
            <div>
              {canInvite
                ? 'Invite more members'
                : `${
                    networkType === 'patient'
                      ? PATIENT_NETWORK_MEMBER_LIMIT
                      : PROVIDER_NETWORK_MEMBER_LIMIT
                  } member limit`}
            </div>
          </button>
        </div>
        {activeButton === buttonType.provider && (
          <div className={classes('provider-picker-container')}>
            <RecipientSearchPicker
              ariaLabelSelect={'Choose providers'}
              autoFocus={true}
              className={classes('Edit-providers')}
              enabledCapabilities={enabledCapabilities}
              excludeIds={providerList.map((p) => p.id)}
              excludeRoles={true}
              excludeTeams={true}
              multi
              shouldShowSelectedValues={false}
              onBlur={() => toggleMemberSelect(buttonType.provider)}
              onChange={addMember}
              organization={{ id: get(currentCall, 'payload.orgId') }}
              placeholder="Choose providers"
              searchTypes={[SearchTypes.USER]}
            />
          </div>
        )}
        <div className={classes('provider-list-container')}>
          {providerList &&
            providerList.map((member) => (
              <VideoCallMember key={member.id} member={member} canInvite={canInvite} />
            ))}
        </div>
      </div>
    </div>
  );
}

AddMemberToVideoCall.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentCall: PropTypes.object,
  currentUserId: PropTypes.string.isRequired,
  findUser: PropTypes.func.isRequired,
  inviteMember: PropTypes.func.isRequired,
};

class AddMemberToVideoCallContainer extends React.Component {
  render() {
    const { isOpen, closeModal, currentCall } = this.props;
    const headerText = currentCall && !currentCall.payload.isVideo ? 'Voice' : 'Video';

    return (
      <Modal
        bodyClass={classes('body')}
        className={classes()}
        closeClass={classes('close-button')}
        header={`${headerText} Members`}
        headerClass={classes('header')}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size={'medium-small'}
      >
        <AddMemberToVideoCall {...this.props} />
      </Modal>
    );
  }
}

export default mobxInjectSelect({
  callStore: ['currentCall', 'inviteMember'],
  sessionStore: ['currentUserId'],
  userStore: ['findUser'],
})(AddMemberToVideoCallContainer);
