// @ts-nocheck
import { RequestBody as MultiPartBody, RequestField as MultiPartField } from 'maltypart';
import { doubleEncodeUtf8 } from './encoding';
import readFile from './readFile';

const UTF8_CONTENT_TYPES = ['application/json', 'text/plain'];

class MultiPart {
  constructor() {
    this.body = new MultiPartBody();
    this.fileIdx = 0;

    this.body.setType('multipart');
  }

  async appendField(fieldName, fieldValue = '') {
    if (Array.isArray(fieldValue)) {
      let idx = 0;
      for (const arrayElement of fieldValue) {
        if (fieldName === 'data' && typeof arrayElement === 'object') {
          const { mimetype = 'application/json', namespace, payload = '' } = arrayElement;
          if (!namespace) throw new Error(`Missing namespace on data[${idx}] object`);

          const filename = null;
          const headers = { 'TT-X-Namespace': namespace };

          this._appendParsedField(fieldName, {
            fieldValue: String(payload),
            mimetype,
            filename,
            headers,
          });
        } else {
          this.appendField(fieldName, arrayElement);
        }
        idx++;
      }
    } else {
      if (fieldValue && fieldValue.nodeType && fieldValue.nodeName && fieldValue.getAttribute) {
        fieldValue = fieldValue.value;
      }
      if (typeof fieldValue === 'object') {
        this._appendParsedField(fieldName, {
          fieldValue: JSON.stringify(fieldValue),
          mimetype: 'application/json',
        });
      } else {
        this._appendParsedField(fieldName, {
          fieldValue: String(fieldValue),
          mimetype: 'text/plain',
        });
      }
    }
  }

  _appendParsedField(name, { fieldValue, filename, headers, mimetype }) {
    const { fields } = this.body;
    if (filename) {
      filename = doubleEncodeUtf8(filename);
    } else {
      fieldValue = doubleEncodeUtf8(fieldValue);
    }

    if (UTF8_CONTENT_TYPES.includes(mimetype)) {
      mimetype = `${mimetype}; charset="utf-8"`;
    }

    const newField = new MultiPartField(fieldValue, mimetype, filename, headers);

    if (fields.hasOwnProperty(name)) {
      if (!Array.isArray(fields[name])) {
        fields[name] = [fields[name]];
      }
      fields[name].push(newField);
    } else {
      fields[name] = newField;
    }
  }

  async appendFile(fieldName, file) {
    let fieldContent = '';

    if (file) {
      fieldContent = await readFile(file, { idx: this.fileIdx++ });
    }

    this._appendParsedField(fieldName, fieldContent);
  }

  getContentType() {
    return this.body.getContentType();
  }

  async getPayload() {
    if (process.env.NODE_TARGET === 'node') {
      return this.body.toString();
    } else if (process.env.NODE_TARGET === 'web') {
      const data = this.body.getData();
      return new Blob([data]);
    }
  }
}

export default MultiPart;
