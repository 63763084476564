// @ts-nocheck
import _ from 'lodash';
import * as errors from '../../errors';
import isConfigurableProperty from '../../utils/isConfigurableProperty';
import configuration from '../../configuration';

let _scheduledEntityRefreshTimeouts = {};
const _entityRefreshSubscriptions = {};
const _scheduledEntityRefreshKey = (instance) => `${instance.$entityType}:${instance.id}`;
const {
  CONFIG_DEFAULT: { loadEntityMaxConnections },
} = configuration;

// for cases where an entity arrives, points another entity by ID which still
// hasn't arrived to store.
// ensure the other entity is stored with $placeholder attributes until fetched.
// e.g. message.sender / group.members

function ensureEntity(model, id, placeholderAttrs, options = {}) {
  let entity = model.get(id);

  if (entity) {
    if (entity.$placeholder) {
      // we have a placeholder but requested for a non-placeholder
      if (!entity.$$loading && !entity.$notFound && !options.onlyPlaceholder) {
        _scheduleEntityLoad(model, entity, options);
      }
    } else {
      options.onExists && options.onExists(entity);
      options.onFinish && options.onFinish(entity);
    }
  } else {
    placeholderAttrs = { [model.idAttribute]: id, ...placeholderAttrs };

    options.onMissing && options.onMissing();

    // inject the placeholder attributes and mark this entity as $placeholder
    entity = model.createInstance(placeholderAttrs);
    Object.defineProperty(entity, '$placeholder', { value: true, configurable: true });
    model.inject(entity);

    // in case all we need is a placeholder, don't bother going to the server
    if (options.onlyPlaceholder) return entity;

    _scheduleEntityLoad(model, entity, options);
  }
}

const _loadEntityQueue = [];
let _processingEntityQueue = false;

export function resetLoadEntityQueue() {
  _loadEntityQueue.length = 0;
  _processingEntityQueue = false;
}

async function _processLoadEntityQueue() {
  if (!_loadEntityQueue.length || _processingEntityQueue) return;

  _processingEntityQueue = true;

  const entitiesToLoad = _loadEntityQueue.splice(0, loadEntityMaxConnections);
  const promises = entitiesToLoad.map(async ({ model, entity, options }) => {
    let notFoundOnRemote;

    try {
      await options.finder(entity.id);
    } catch (err) {
      if (
        err.code === errors.NotFoundError.CODE ||
        err.code === errors.PermissionDeniedError.CODE
      ) {
        notFoundOnRemote = true;
      } else if (__DEV__) {
        throw err;
      }
    }

    delete entity.$$loading;

    const key = _scheduledEntityRefreshKey(entity);

    if (notFoundOnRemote) {
      for (const [key, value] of Object.entries(options.notFoundAttributes)) {
        if (isConfigurableProperty(entity, key)) {
          entity[key] = value;
        }
      }
      Object.defineProperty(entity, '$notFound', { value: true, configurable: true });
      model.inject(entity);
      options.onNotFound && options.onNotFound();
      options.onLoad && options.onLoad(null);
      options.onFinish && options.onFinish(null);
      _entityRefreshSubscriptions[key]?.map((fn) => fn(null));
    } else {
      delete entity.$placeholder;
      delete entity.$notFound;
      model.inject(entity);
      options.onSuccess && options.onSuccess(entity);
      options.onLoad && options.onLoad(entity);
      options.onFinish && options.onFinish(entity);
      _entityRefreshSubscriptions[key]?.map((fn) => fn(entity));
    }
  });

  try {
    await Promise.all(promises);
  } catch (err) {
    console.error(err);
  }

  _processingEntityQueue = false;

  if (_loadEntityQueue.length > 0) {
    _processLoadEntityQueue();
  }
}

function _addReloadSubscriber(entity, options = {}) {
  const key = _scheduledEntityRefreshKey(entity);
  const { onFinish, onLoad, onSuccess } = options;
  if (!_entityRefreshSubscriptions[key]) _entityRefreshSubscriptions[key] = [];

  [onFinish, onLoad, onSuccess]
    .filter((x) => x)
    .forEach((fn) => {
      _entityRefreshSubscriptions[key].push(fn);
    });
}

async function _loadEntity(model, entity, options) {
  if (!options.placeholderEntityAllowLoading) return;

  Object.defineProperty(entity, '$$loading', { value: true, configurable: true });

  _loadEntityQueue.push({ model, entity, options });
  _processLoadEntityQueue();
}

function _scheduleEntityLoad(model, entity, options) {
  const timeoutKey = _scheduledEntityRefreshKey(entity);

  // already scheduled to refresh? bail
  if (_scheduledEntityRefreshTimeouts[timeoutKey]) {
    if (options.subcribeToReload) _addReloadSubscriber(entity, options);
    return;
  }

  if (options.placeholderEntityDelayBeforeRefreshing) {
    Object.defineProperty(entity, '$$loadingScheduled', { value: true, configurable: true });

    // wait a bit, *maybe* the entity will be added due to an incoming event
    // e.g. users can be injected from 'friends' event

    _scheduledEntityRefreshTimeouts[timeoutKey] = setTimeout(
      () => _loadEntityIfNeeded(model, entity, options),
      options.placeholderEntityDelayBeforeRefreshing
    );
  } else {
    return _loadEntity(model, entity, options).catch(console.error);
  }
}

async function _loadEntityIfNeeded(model, entity, options) {
  try {
    delete entity.$$loadingScheduled;
    delete _scheduledEntityRefreshTimeouts[_scheduledEntityRefreshKey(entity)];

    // means that entity was already updated elsewhere
    if (!entity.$placeholder) {
      return;
    }

    // fire a find method that will eventually update the entity
    return _loadEntity(model, entity, options);
  } catch (err) {
    console.error(err);
  }
}

export default function addEnsureEntity(
  model,
  {
    finder,
    defaultPlaceholderAttrs,
    defaultNotFoundAttrs,
    placeholderEntityDelayBeforeRefreshing,
    placeholderEntityAllowLoading,
    shouldEnsure, // custom shouldEnsure per model
  }: {
    finder?: Function | null | undefined;
    defaultPlaceholderAttrs?: Object | null | undefined;
    defaultNotFoundAttrs?: Object | null | undefined;
    placeholderEntityDelayBeforeRefreshing?:
      | (Function | null | undefined)
      | (number | null | undefined);
    placeholderEntityAllowLoading?: (Function | null | undefined) | (boolean | null | undefined);
    shouldEnsure?: Function | null | undefined;
  }
) {
  // add static method to the model class
  model.ensureEntity = function (id, options = {}) {
    if (typeof placeholderEntityDelayBeforeRefreshing === 'function') {
      placeholderEntityDelayBeforeRefreshing = placeholderEntityDelayBeforeRefreshing();
    }
    if (typeof placeholderEntityAllowLoading === 'function') {
      placeholderEntityAllowLoading = placeholderEntityAllowLoading();
    }

    return ensureEntity(
      model,
      id,
      { ...defaultPlaceholderAttrs, ...options.attrs },
      {
        finder,
        notFoundAttributes: defaultNotFoundAttrs,
        placeholderEntityDelayBeforeRefreshing,
        placeholderEntityAllowLoading,
        ...options,
      }
    );
  };

  model.waitForEnsuredEntity = function (id, options = {}) {
    return new Promise((resolve, reject) => {
      model.ensureEntity(id, {
        ...options,
        onFinish: resolve,
      });
    });
  };

  // if entity doesn't exist, or it's currently a placeholder that isn't loading and a full entity
  // requested, ensure should run. If entity is already loading ($$loading), we can subscribe to its
  // completion with subcribeToReload and onLoad in options.
  model.shouldEnsure = (entity, requestedOnlyPlaceholder, options = {}) => {
    if (typeof entity === 'string') entity = model.get(entity);
    if (!entity) return true;
    if (shouldEnsure && shouldEnsure(entity, requestedOnlyPlaceholder, options)) return true;
    if (entity.$placeholder && !entity.$$loading && !requestedOnlyPlaceholder) return true;

    if (entity.$$loading && options.subcribeToReload && options.onLoad) {
      _addReloadSubscriber(entity, options);
    }

    return false;
  };
}

export function dispose() {
  _.values(_scheduledEntityRefreshTimeouts).map((timeout) => clearTimeout(timeout));
  _scheduledEntityRefreshTimeouts = {};
}
