// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

export default class UserRolesAPI extends BaseAPI {
  @recoverFromNotFound()
  async findAll(userId: string, organizationId: string) {
    const res = await this.httpClient.get('/v2/user/:userId/organization/:organizationId/roles', {
      urlParams: { userId, organizationId },
    });
    return res.data.roles;
  }
}
