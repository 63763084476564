import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
import BEM from '../bem';
import propTypes from '../propTypes';

const classes = BEM.with('MessageGroupEscalationChangeLog');

class MessageGroupEscalationChangeLog extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
    precededByDateSeparator: PropTypes.bool.isRequired,
  };

  render() {
    const {
      message: { body, escalationExecutionChange },
      precededByDateSeparator,
    } = this.props;
    const { actionTime } = escalationExecutionChange;

    return (
      <div className={classes({ precededByDateSeparator })} onClick={this._onClick}>
        <span>{body}</span>
        <span> at {moment(actionTime).format('h:mm A')} </span>
      </div>
    );
  }

  _onClick = (e) => {
    // Prevent ConversationMessages.onClick from firing; otherwise it prevents text from being selected
    e.stopPropagation();
  };
}

export default observer(MessageGroupEscalationChangeLog);
