// @ts-nocheck
import get from 'lodash/get';
import { isPhone } from '../utils/phone';

export default function (store, { host }) {
  const CallLogEntry = store.defineModel('callLogEntry', {
    parseAttrs(attrs) {
      attrs.isContact = get(attrs, 'target.entity.metadata.isPatientContact');
      attrs.isGroup = get(attrs, 'target.type') === 'tigertext:entity:group';
      attrs.memberCount = get(attrs, 'target.entity.numMembers');
      attrs.isMissed = attrs.type === 'missed_voip_call';
      attrs.isOptedOut = get(attrs, 'target.entity.metadata.smsOptedOut', false);
      attrs.isOutgoing = host.currentUserId === get(attrs, 'caller.entity.token');
      attrs.isQuickCall =
        Boolean(isPhone(get(attrs, 'target.entity.displayName'))) &&
        get(attrs, 'target.entity.metadata.patientMrn') === '00000';
      return attrs;
    },
  });

  return CallLogEntry;
}
