import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button } from '@tigerconnect/web-component-library';
import BEM from '../bem';

import { ReactComponent as CloseButtonSvg } from '../images/close-icon.svg';
import { AppType } from 'models/enums/AppTypes';
import { KEYMAP } from 'common/constants';

const classes = BEM.with('CollapsingSearchBar');

type CollapsingSearchBarProps = {
  currentAppSelected?: AppType;
  disabled?: boolean;
  handleQueryChange: (value: string) => void;
  isAlwaysOpen?: boolean;
  onOpen?: () => void;
  query: string;
  shouldClose?: boolean;
  shouldShowSearchIcon?: boolean;
  theme?: string;
};

function CollapsingSearchBar({
  currentAppSelected = 'PATIENT_SETTINGS',
  disabled = false,
  handleQueryChange,
  isAlwaysOpen = false,
  onOpen,
  query,
  shouldClose = false,
  shouldShowSearchIcon = true,
  theme = 'general',
}: CollapsingSearchBarProps) {
  const queryInputRef = useRef<HTMLInputElement>(null);
  const [showQuery, setShowQuery] = useState(isAlwaysOpen);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      handleQueryChange(value);
    },
    [handleQueryChange]
  );

  useEffect(() => {
    if (isAlwaysOpen) {
      if (queryInputRef.current) {
        queryInputRef.current.value = query;
      }
      return;
    }

    if (
      queryInputRef.current &&
      queryInputRef.current.value?.length > 1 &&
      showQuery &&
      query === ''
    ) {
      setShowQuery(false);
    }

    if (showQuery && queryInputRef.current && query === '') {
      queryInputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlwaysOpen, query, queryInputRef.current, showQuery]);

  useEffect(() => {
    if (!isAlwaysOpen && shouldClose && showQuery) {
      setShowQuery(false);
    }
  }, [shouldClose, setShowQuery, showQuery, isAlwaysOpen]);

  return (
    <div
      className={classes({ theme })}
      onKeyDown={(e) => {
        if (e.key === KEYMAP.ESCAPE && showQuery) {
          handleQueryChange('');
          if (!isAlwaysOpen) setShowQuery(false);
        }
      }}
    >
      <div
        className={classes('wrapper', {
          currentAppSelected,
          disabled,
          hideFocus: isAlwaysOpen && query === '',
          active: showQuery,
        })}
      >
        {shouldShowSearchIcon && (
          <span
            className={classes('search-icon-container')}
            onClick={() => {
              if (!disabled) {
                setShowQuery(true);
                if (onOpen) onOpen();
              }
            }}
            data-test-id={'search-icon'}
          >
            <Button
              shape="circle"
              color={currentAppSelected === 'PATIENT_SETTINGS' ? 'patient' : 'secondary'}
              icon={'search'}
              disabled={showQuery}
            />
          </span>
        )}
        {showQuery && (
          <>
            <input
              type="text"
              onChange={onChange}
              className={classes('input-container', { searchIconHidden: !shouldShowSearchIcon })}
              placeholder="Search"
              ref={queryInputRef}
              data-test-id={'search-input'}
            />
            {(!isAlwaysOpen || !!query.length) && (
              <button
                onClick={() => {
                  handleQueryChange('');
                  if (!isAlwaysOpen) setShowQuery(false);
                }}
                className={classes('close-button-container')}
                data-test-id={'search-close-button'}
              >
                <CloseButtonSvg className={classes('close-icon-white')} />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CollapsingSearchBar;
