import React, { createContext } from 'react';

import { useAppState } from '../../state';
import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export const VideoContext = createContext();

export function VideoProvider({
  canUseVideo,
  children,
  localTracksObject,
  onDisconnect = () => {},
  onError = () => {},
  shouldAnswerWithCamOn,
}) {
  const onErrorCallback = (error) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  const { audioTrack, videoTrack } = localTracksObject;
  const { room, sendDataMessage } = useAppState();
  const { getLocalAudioTrack, getLocalVideoTrack, localTracks, localTrackToggling } =
    useLocalTracks({
      createdAudioTrack: audioTrack,
      createdVideoTrack: videoTrack,
      shouldAnswerWithCamOn,
    });
  const { isConnecting, connect } = useRoom(localTracks, localTrackToggling, onErrorCallback, room);

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        canUseVideo,
        connect,
        getLocalAudioTrack,
        getLocalVideoTrack,
        isConnecting,
        localTracks,
        localTrackToggling,
        onDisconnect,
        onError: onErrorCallback,
        room,
        sendDataMessage,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}
