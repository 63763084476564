export default function attachmentType(type) {
  if (type.startsWith('audio/')) {
    return 'Audio';
  }
  if (type.startsWith('application/')) {
    return 'File';
  }
  if (type.startsWith('image/')) {
    return 'Image';
  }
  if (type.startsWith('video/')) {
    return 'Video';
  }
  return 'File';
}
