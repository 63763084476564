// @ts-nocheck
import { decorator as reusePromise } from 'reuse-promise';
import shallowequal from 'shallowequal';
import ProcessingActionTypes from '../models/enums/ProcessingActionTypes';
import BaseService from './BaseService';

export default class ProductsService extends BaseService {
  mounted() {
    this.host.models.Organization.on('afterInject', this._onChangeOrganization);
    this.host.models.Organization.on('afterEject', this._onChangeOrganization);
  }

  dispose() {
    this.host.models.Organization.removeListener('afterInject', this._onChangeOrganization);
    this.host.models.Organization.removeListener('afterEject', this._onChangeOrganization);
  }

  @reusePromise()
  async findAll() {
    await this.host.organizations.findAll();
    return this.getAll();
  }

  @reusePromise()
  async refreshAll() {
    await this.host.organizations.refreshAll();
    return this.getAll();
  }

  getAll() {
    return this.host.models.Product.getAll();
  }
  getById(id: string) {
    return this.host.models.Product.get(id);
  }

  _addProcessingEvent = (event) => {
    const product = this.host.models.Product.getAll()[0];
    if (!product) return;

    const { actionType, entity } = event;

    if (actionType === ProcessingActionTypes.ROLE_OPT_IN) {
      product.rolesOptingIn.push(entity.id);
    }

    product.activeProcessingEvents.push(event);
    this.host.models.Product.inject(product);
    this.emit('processing:start', event);
  };

  _removeProcessingEvent = (event) => {
    const product = this.host.models.Product.getAll()[0];
    if (!product) return;

    const { activeProcessingEvents, rolesOptingIn } = product;
    const { actionType, entity } = event;

    for (let idx = activeProcessingEvents.length - 1; idx >= 0; idx--) {
      const activeProcessingEvent = activeProcessingEvents[idx];
      if (
        actionType === activeProcessingEvent.actionType &&
        entity.id === activeProcessingEvent.entity.id
      ) {
        activeProcessingEvents.splice(idx, 1);

        if (actionType === ProcessingActionTypes.ROLE_OPT_IN && rolesOptingIn.includes(entity.id)) {
          rolesOptingIn.splice(rolesOptingIn.indexOf(entity.id), 1);
        }

        if (actionType !== ProcessingActionTypes.MESSAGE_REPLAY) {
          break;
        }
      }
    }
    this.host.models.Product.inject(product);
    this.emit('processing:stop', event);
  };

  _onChangeOrganization = (resource, organization) => {
    const product = this.host.models.Product.getAll()[0];
    if (!product) return;

    const organizations = this.host.models.Organization.getAll();
    if (organizations.length === 0) return;

    const newValues = { organizations };
    let conversationCount = 0;
    let unreadAlertsCount = 0;
    let unreadCount = 0;
    let unreadPriorityCount = 0;
    let unreadVWRCount = 0;

    for (const organization of organizations) {
      if (organization.$placeholder) continue;

      conversationCount += organization.conversations.length;
      unreadAlertsCount += organization.unreadAlertsCount;
      unreadCount += organization.unreadCount;
      unreadPriorityCount += organization.unreadPriorityCount;
      unreadVWRCount += organization.virtualWaitingRoomUnreadCount;
    }

    newValues.conversationCount = conversationCount;
    newValues.unreadAlertsCount = unreadAlertsCount;
    newValues.unreadCount = unreadCount;
    newValues.unreadPriorityCount = unreadPriorityCount;
    newValues.unreadVWRCount = unreadVWRCount;

    let changed = false;
    for (const [key, newValue] of Object.entries(newValues)) {
      if (!shallowequal(product[key], newValue)) {
        product[key] = newValue;
        changed = true;
      }
    }

    if (changed) this.host.models.Product.inject(product);
  };
}
