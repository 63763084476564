import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { useScrollListener } from './';

const DEFAULT_SCROLL_RATIO = 0.8;
const END_OF_CONTINUATION = 'end_of_continuation';
const THROTTLE_TIMEOUT = 50;

export const useInfiniteSearchResults = (
  searchFn,
  defaultOptions = {},
  scrollRatio = DEFAULT_SCROLL_RATIO
) => {
  const [continuation, setContinuation] = useState('');
  const [error, setError] = useState({});
  const [options, setOptions] = useState(defaultOptions);
  const [totalHits, setTotalHits] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [results, setResults] = useState([]);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const scrollContainerRef = useRef(null);
  const throttledLoadMore = useRef(
    throttle(() => setShouldLoadMore(true), THROTTLE_TIMEOUT, {
      leading: false,
    })
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSearchResults = useCallback(async () => {
    setShouldLoadMore(false);
    if (continuation === END_OF_CONTINUATION || isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      const response = await searchFn({
        ...options,
        continuation,
      });

      setError({});
      setContinuation(response.metadata.continuation || END_OF_CONTINUATION);
      setResults([...results, ...response.results]);
      setTotalHits(response.metadata.totalHits);
    } catch (error) {
      setContinuation(END_OF_CONTINUATION);
      setError({
        continuation,
        options,
        raw: error,
      });
    } finally {
      setIsLoadingMore(false);
    }
  });

  const handleScroll = useCallback(
    ({ target }) => {
      const { clientHeight, scrollHeight, scrollTop } = target;
      if (clientHeight / (scrollHeight - scrollTop) > scrollRatio) {
        throttledLoadMore.current();
      }
    },
    [scrollRatio]
  );

  const resetSearch = useCallback(() => {
    setContinuation('');
    setResults([]);
  }, []);

  const updateOptions = (newOptions, refreshSearch = true) => {
    setOptions({ ...options, ...newOptions });
    if (refreshSearch) {
      throttledLoadMore.current();
    }
  };

  useEffect(() => {
    if (shouldLoadMore) {
      fetchSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  useScrollListener(scrollContainerRef.current, handleScroll);

  return {
    canLoadMore: continuation !== END_OF_CONTINUATION,
    continuation,
    error,
    isLoading:
      shouldLoadMore ||
      isLoadingMore ||
      (continuation !== END_OF_CONTINUATION && results.length === 0),
    results,
    resetSearch,
    scrollContainerRef,
    totalHits,
    updateOptions,
  };
};

export default useInfiniteSearchResults;
