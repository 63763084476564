import GraphemeBreaker from '@ov/grapheme-breaker';
import isEmoji from 'isemoji';

function isSingleEmoji(string, { ignoreSpaces = true } = {}) {
  if (typeof string !== 'string') return false;
  if (ignoreSpaces) string = string.trim();

  const breakIndex = GraphemeBreaker.nextBreak(string);
  if (breakIndex !== string.length) return false;

  const firstCodePoint = string.slice(0, breakIndex);
  return isEmoji(firstCodePoint);
}

export default isSingleEmoji;
