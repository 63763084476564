import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BEM from '../bem';
import propTypes from '../propTypes';
import mobxInjectSelect from '../utils/mobxInjectSelect';

const classes = BEM.with('MessageGroupMembershipChangeLog');

class MessageGroupMembershipChangeLog extends Component {
  static propTypes = {
    message: propTypes.message.isRequired,
    precededByDateSeparator: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { findUser, message } = this.props;

    if (message.$placeholder) {
      findUser(message.senderId, message.counterParty.organizationId);
    }
  }

  render() {
    const {
      isMultiSelect,
      message: { $placeholder: placeholder, body, groupMembersChange },
      precededByDateSeparator,
    } = this.props;
    const { action, actionTime } = groupMembersChange;
    let contentFragment;

    if (!placeholder) {
      contentFragment = (
        <>
          {body}
          {(action === 'OPT_IN' || action === 'OPT_OUT') && (
            <span> at {moment(actionTime).format('h:mm A')} </span>
          )}
        </>
      );
    }

    return (
      <div
        className={classes({ action, isMultiSelect, precededByDateSeparator })}
        onClick={this._onClick}
        title={moment(actionTime).format('LLLL')}
      >
        {contentFragment}
      </div>
    );
  }

  _onClick = (e) => {
    // Prevent ConversationMessages.onClick from firing; otherwise it prevents text from being selected
    e.stopPropagation();
  };
}

export default mobxInjectSelect({
  userStore: ['findUser'],
})(MessageGroupMembershipChangeLog);
