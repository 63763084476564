import Camelizer from '../../utils/Camelizer';
import { ResponseServerOrganizationSettings, ProductRole } from '../../services/Admin/utils/types';
import BaseAdminAPI from './BaseAdminAPI';
import { recoverFromNotFound } from '../BaseAPI';

const roleSchedulerPartnerConfig = 'excel';
const roleSchedulerSecretConfig = 'webWasHere';

export default class OrganizationsAPI extends BaseAdminAPI {
  async createCustomDirectories({
    directories,
    organizationId,
  }: {
    directories: string[];
    organizationId: string;
  }): Promise<unknown> {
    const endpoint = `v2/organization/${organizationId}/security_groups`;

    const res = await this.cnDevApi({
      data: {
        security_groups: directories,
      },
      endpoint,
      method: 'post',
      organizationId,
    });

    return res.data;
  }

  async deleteCustomDirectories({
    directories,
    organizationId,
  }: {
    directories: string[];
    organizationId: string;
  }): Promise<unknown> {
    const endpoint = `v2/organization/${organizationId}/security_groups`;

    const res = await this.cnDevApi({
      data: {
        security_groups: directories,
      },
      endpoint,
      method: 'del',
      organizationId,
    });

    return res.data;
  }

  async deleteOrganization({ organizationId }: { organizationId: string }): Promise<unknown> {
    const endpoint = `v2/organization/${organizationId}`;

    const res = await this.cnDevApi({
      endpoint,
      method: 'del',
      organizationId,
    });

    return res.data;
  }

  async findIntegration({ organizationId }: { organizationId: string }) {
    const res = await this.cnDevApi({
      method: 'post',
      endpoint: 'v1/integrations/scheduler/configs/info/org',
      organizationId,
      data: {
        org: organizationId,
      },
    });

    return res.data;
  }

  async createIntegration({ organizationId }: { organizationId: string }) {
    return await this.cnDevApi({
      method: 'post',
      endpoint: 'v1/integrations/scheduler/configs/set',
      organizationId,
      data: {
        org: organizationId,
        partner: roleSchedulerPartnerConfig,
        secret: roleSchedulerSecretConfig,
      },
    });
  }

  async createOrganization({
    hasAutoSendWelcomeEmail,
    billing,
    join,
    name,
    orgType,
  }: {
    hasAutoSendWelcomeEmail: boolean;
    billing: string;
    join: boolean;
    name: string;
    orgType: string;
  }): Promise<{ organizationId: string }> {
    const route = `organization_devapi`;
    const data = {
      auto_send_welcome_email: hasAutoSendWelcomeEmail,
      billing,
      join,
      name,
      org_type: orgType,
      ts: new Date().getTime(),
    };
    const res = (await this.cnRoute({
      data,
      route,
      method: 'post',
    })) as { data: Record<string, unknown> & { organization_id: string } };
    return Camelizer.camelizeObject(res.data);
  }

  async saveWelcomeEmailSettings({
    data,
    organizationId,
  }: {
    data: Record<string, unknown>;
    organizationId: string;
  }) {
    const res = await this.cnDevApi({
      method: 'post',
      endpoint: `v2/organization/${organizationId}`,
      organizationId,
      data,
    });

    return res;
  }

  async getEmailDomains(organizationId: string) {
    const endpoint = `v2/organization/${organizationId}/email_domain`;
    const res = await this.cnDevApi<Record<string, unknown>>({
      endpoint,
      method: 'get',
      organizationId,
    });
    return Camelizer.camelizeObject(res.data);
  }

  async addEmailDomain({
    domainNames,
    organizationId,
  }: {
    domainNames: string[];
    organizationId: string;
  }): Promise<unknown> {
    const endpoint = `v2/organization/${organizationId}/email_domain`;
    const data = {
      email_domains: domainNames,
    };
    const res = await this.cnDevApi({ data, endpoint, method: 'post', organizationId });
    return res.data;
  }

  async deleteEmailDomain({
    domainNames,
    organizationId,
  }: {
    domainNames: string[];
    organizationId: string;
  }): Promise<unknown> {
    const endpoint = `v2/organization/${organizationId}/email_domain`;
    const data = {
      email_domains: domainNames,
    };
    const res = await this.cnDevApi({ data, endpoint, method: 'del', organizationId });
    return res.data;
  }

  async getWelcomeEmailSettings({ organizationId }: { organizationId: string }): Promise<{
    autoSendWelcomeEmail: unknown;
    welcomeEmailLogo: string;
    welcomeEmailSettings: {
      from: string;
      optionalHeader: string;
      optionalMessage: string;
      optionalName: string;
      subject: string;
    };
  }> {
    const endpoint = `v2/organization/${organizationId}`;
    const res = await this.cnDevApi<{
      auto_send_welcome_email: unknown;
      welcome_email_logo: unknown;
      welcome_email_settings: Record<string, string>;
    }>({
      endpoint,
      method: 'get',
      organizationId,
    });
    const { auto_send_welcome_email, welcome_email_logo, welcome_email_settings } = res.data;

    return Camelizer.camelizeObject({
      auto_send_welcome_email,
      welcome_email_logo,
      welcome_email_settings: {
        ...(welcome_email_settings || {}),
        optional_message: (welcome_email_settings?.optional_message || '').replace(
          /<(first_name|last_name|org_name|user_name|email_address)>/g,
          '&lt;$1&gt;'
        ),
      },
    });
  }

  async getWelcomeEmailUnsentCount({ organizationId }: { organizationId: string }) {
    const endpoint = `v2/welcome_email/unsent_count`;
    const res = await this.cnDevApi<string>({ endpoint, method: 'get', organizationId });
    return JSON.parse(res.data) as { count: number };
  }

  async sendWelcomeEmail({
    organizationId,
    userIds,
    sendUnsent = false,
  }: {
    organizationId: string;
    userIds?: string[];
    sendUnsent?: boolean;
  }): Promise<{ status: number }> {
    let data = {};
    if (sendUnsent) {
      data = { all_not_sent: true };
    } else {
      data = { entries: userIds };
    }
    const endpoint = `v2/send_welcome_email`;
    const res = await this.cnDevApi({
      data,
      endpoint,
      method: 'post',
      organizationId,
    });
    return res;
  }

  async getAvailableTwilioNumbers({
    contains,
    countryCode,
  }: {
    contains: string;
    countryCode?: string;
  }) {
    const route = `twilio_available_numbers`;
    const res = await this.cnRoute<unknown[]>({
      route,
      data: { contains, countryCode },
      method: 'get',
    });
    return res.data;
  }

  async verifyCustomNumber({ organizationId }: { organizationId: string }): Promise<unknown> {
    const res = await this.cnDevApi({
      method: 'post',
      organizationId,
      endpoint: `v2/caller_id/verify`,
    });

    return res;
  }

  async getCustomNumberVerificationCode({
    organizationId,
    customNumber,
    status,
  }: {
    organizationId: string;
    customNumber: string;
    status: string;
  }): Promise<{ validationCode: string }> {
    const endpoint = `v2/caller_id/call`;
    const res = await this.cnDevApi<Record<string, unknown>>({
      endpoint,
      method: 'post',
      data: {
        click_to_call_custom_number: '+1' + customNumber.replace(/\D/g, ''),
        click_to_call_status: status,
      },
      organizationId,
    });
    return Camelizer.camelizeObject(res.data);
  }

  async fetchCustomDirectories({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<{ security_groups: string[] }> {
    const endpoint = `v2/organization/${organizationId}/security_groups`;

    const res = await this.cnDevApi<{ security_groups: string[] }>({
      endpoint,
      method: 'get',
      organizationId,
    });

    return res.data;
  }

  async fetchSettings({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<ResponseServerOrganizationSettings> {
    const endpoint = `v2/organization/${organizationId}`;

    const res = await this.cnDevApi<ResponseServerOrganizationSettings>({
      endpoint,
      method: 'get',
      organizationId,
    });

    return res.data;
  }

  async fetchSettingsOld({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<ResponseServerOrganizationSettings> {
    const route = `organization`;

    const res = await this.cnRoute<{ organization: ResponseServerOrganizationSettings }>({
      data: {
        organization_id: organizationId,
      },
      method: 'get',
      route,
    });

    return res.data.organization;
  }

  async saveSettings({
    organizationId,
    data,
    isOrgTypeChange = false,
  }: {
    organizationId: string;
    data: Record<string, unknown>;
    isOrgTypeChange?: boolean;
  }): Promise<unknown> {
    if (isOrgTypeChange) {
      const postData = {
        organization_id: organizationId,
        settings: data,
        ts: new Date().getTime(),
      };
      await this.cnRoute({
        route: `organization_set`,
        data: postData,
        method: 'post',
        requestFormat: 'form',
      });
    } else {
      const endpoint = `v2/organization/${organizationId}`;
      await this.cnDevApi({
        data,
        endpoint,
        method: 'post',
        organizationId,
      });
    }

    return;
  }

  async saveAuthSettings({ data }: { data: Record<string, unknown> }): Promise<unknown> {
    const res = await this.cnRoute({
      route: 'organization_auth',
      data,
      method: 'post',
    });

    return res.data;
  }

  async usersInRoles({ organizationId }: { organizationId: string }) {
    const endpoint = `v2/organization/${organizationId}/accounts_roles`;

    const res = await this.cnDevApi<Record<string, unknown>>({
      endpoint,
      method: 'get',
      organizationId,
    });

    return Camelizer.camelizeObject(res.data);
  }

  async getOrganizationSecurityGroups({ orgId }: { orgId: string }) {
    const res = await this.cnRoute({
      route: 'organization_security_groups_devapi',
      data: { organization_id: orgId },
      method: 'get',
    });
    return res.data;
  }

  async getAllPermissionRoles({ orgId }: { orgId: string }) {
    const res = await this.cnDevApi({
      endpoint: `/v2/organization/${orgId}/roles`,
      method: 'get',
      organizationId: orgId,
    });
    return res.data;
  }

  async getEntityCustomDirectories({
    entityId,
    entityType = 'distribution_list',
    orgId,
  }: {
    entityId: string;
    entityType?: string;
    orgId: string;
  }) {
    type SecurityGroups = {
      security_groups: {
        security_group: { name: string } | { name: string }[] | null;
      };
    };
    const res = await this.cnRoute<SecurityGroups>({
      data: {
        token: entityId,
        organization_id: orgId,
        type: 'get',
      },
      method: 'get',
      route: `${entityType}_security_groups`,
    });

    return res.data;
  }

  @recoverFromNotFound({ fallbackValue: { data: [] } })
  async getOrgCustomDirectories({ orgId }: { orgId: string }) {
    const res = await this.cnRoute<{ security_groups: string[] }>({
      data: {
        organization_id: orgId,
      },
      method: 'get',
      route: 'organization_security_groups_devapi',
    });

    return res.data;
  }

  async updateEntityCustomDirectories({
    entityId,
    entitySecurityGroups,
    entityType = 'distribution_list',
    orgId,
  }: {
    entityId: string;
    entitySecurityGroups?: string[];
    entityType?: string;
    orgId: string;
  }) {
    const res = await this.cnRoute({
      data: {
        token: entityId,
        organization_id: orgId,
        type: 'set',
        groups: entitySecurityGroups,
      },
      method: 'get',
      route: `${entityType}_security_groups`,
    });

    return res.data;
  }

  async getAllProductRoles({ orgId }: { orgId: string }) {
    const res = await this.cnDevApi<{ roles: ProductRole[] }>({
      method: 'get',
      endpoint: 'v2/roles?type=product',
      organizationId: orgId,
    });
    return res.data.roles;
  }

  async createOrUpdateForum({
    action,
    name,
    members,
    organizationId,
    token,
  }: {
    action: string;
    name: string;
    token?: string;
    organizationId: string;
    members: Array<{ token: string; action: string }>;
  }) {
    const res = await this.cnRoute<{ entity: unknown }>({
      method: 'get',
      route: 'group',
      data: {
        action,
        is_public: 'true',
        name,
        ttl: 43200,
        avatar: null,
        members,
        organization_id: organizationId,
        ...(token && { token }),
      },
    });
    return res.data.entity;
  }

  async deleteForum({ forumId, organizationId }: { forumId: string; organizationId: string }) {
    const res = await this.cnDevApi({
      method: 'del',
      endpoint: `v1/group/${forumId}`,
      organizationId,
    });
    return res.status;
  }

  async fetchAvailableTwilioNumbers({ contains }: { contains: string }) {
    const res = await this.cnRoute({
      method: 'get',
      route: `twilio_available_numbers`,
      data: { contains },
    });

    return res.data;
  }

  async fetchCustomNumberVerificationCode({
    orgId,
    customNumber,
    status,
  }: {
    orgId: string;
    customNumber: string;
    status: string;
  }) {
    const res = await this.cnDevApi({
      method: 'post',
      endpoint: `v2/caller_id/call`,
      data: {
        click_to_call_custom_number: '+1' + customNumber.replace(/\D/g, ''),
        click_to_call_status: status,
      },
      organizationId: orgId,
    });

    return res.data;
  }
}
