import { action, computed, observable, runInAction } from 'mobx';
import Video, { LocalDataTrack } from 'twilio-video';
import { get, isEmpty } from 'lodash';

import { IS_CHROME, IS_CHROMIUM_EDGE, IS_FIREFOX, IS_SAFARI } from '../common/utils/browsers';
import PfCallOrigins from '../models/enums/PfCallOrigins';
import { MessageSubTypes } from '../models/enums/MessageSubTypes';
import SearchTypes from '../models/enums/SearchTypes';

const isMac = navigator.platform === 'MacIntel';

export default class CallStore {
  @observable logContinuation = '';
  @observable.shallow currentCall = null;
  @observable.shallow currentLog = null;
  @observable isSettingUpTracks = false;
  @observable isSettingUpCall = false;
  @observable isVideoInactive = false;
  @observable localTracksObject = {};
  @observable.shallow logsByNetwork = {};
  @observable shouldAnswerWithCamOn = true;
  @observable roomName = null;
  @observable room = null;
  @observable incomingCallActionsEnabled = true;

  constructor({ client, entityStore, params, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.params = params;
    this.stores = stores;
    this.isBrowserNotSupported = isMac
      ? !IS_CHROME && !IS_FIREFOX && !IS_CHROMIUM_EDGE && !IS_SAFARI
      : !IS_CHROME && !IS_FIREFOX && !IS_CHROMIUM_EDGE;
  }

  mounted() {
    this.client.on('call:answered', this.clearCallState);
    this.client.on('call:closed', this.clearCallState);

    this.client.on('call:calleeBusy', () => {
      this.stores.modalStore.openModal('calleeBusy');
    });

    this.client.on('call:ended', this.setCurrentCall);
    this.client.on('call:incoming', this.setCurrentCall);
    this.client.on('call:memberUpdate', this.setCurrentCall);
    this.client.on('call:state', this.setCurrentCall);

    this.client.models.Message.on('afterInject', (_, message) => {
      if (
        message.messageType === 'CALL_CHANGE' ||
        message.subType === MessageSubTypes.SMS_OPT_OUT_NOTIFICATION ||
        message.subType === MessageSubTypes.SMS_OPT_IN_NOTIFICATION ||
        this.logContinuation
      ) {
        this.setLogContinuation('');
      }
    });
  }

  @computed get isCallingNotificationsAllowed() {
    return this.stores.featureStore.featureFlags?.['calling-notifications'];
  }

  @computed get isGroupVideoCallEnabled() {
    return get(this.stores, 'messengerStore.currentOrganization.isGroupVideoCallEnabled', false);
  }

  @computed get isGroupVoiceCallEnabled() {
    return get(this.stores, 'messengerStore.currentOrganization.isGroupVoiceCallEnabled', false);
  }

  @computed get isVideoCallEnabled() {
    return get(this.stores, 'messengerStore.currentOrganization.isVideoCallEnabled', false);
  }

  @computed get isVoiceCallEnabled() {
    return get(this.stores, 'messengerStore.currentOrganization.isVoiceCallEnabled', false);
  }

  @computed get isPFVideoCallEnabled() {
    return get(this.stores, 'messengerStore.currentOrganization.isPFVideoCallEnabled', false);
  }

  @computed get isPFVoiceCallEnabled() {
    //return get(this.stores, 'messengerStore.currentOrganization.isPFVoiceCallEnabled', false)
    return false;
  }

  @computed get isPFGroupVideoCallEnabled() {
    return get(this.stores, 'messengerStore.currentOrganization.isPFGroupVideoCallEnabled', false);
  }

  @computed get isPFGroupVoiceCallEnabled() {
    //return get(this.stores, 'messengerStore.currentOrganization.isPFGroupVoiceCallEnabled', false)
    return false;
  }

  @action('CallStore.isCallingAvailable') isCallingAvailable = () => {
    return (
      this.isGroupVideoCallEnabled ||
      this.isGroupVoiceCallEnabled ||
      this.isVideoCallEnabled ||
      this.isVoiceCallEnabled ||
      this.isPFVideoCallEnabled ||
      this.isPFVoiceCallEnabled ||
      this.isPFGroupVideoCallEnabled ||
      this.isPFGroupVoiceCallEnabled
    );
  };

  @action('CallStore.calleePermissionsCheck') calleePermissionsCheck = async (
    memberIds,
    enabledCapabilities
  ) => {
    const { messengerStore } = this.stores;
    const { currentOrganizationId, isPresenceFeatureFlagEnabled } = messengerStore;
    const { results } = await this.client.search.query({
      version: 'SEARCH_PARITY',
      enabledCapabilities,
      organizationId: currentOrganizationId,
      query: { id: memberIds?.join(',') },
      sort: isPresenceFeatureFlagEnabled ? ['presenceStatus', 'displayName'] : ['displayName'],
      types: [SearchTypes.INDIVIDUAL],
    });

    return results;
  };

  @action('CallStore.checkAudioAndVideoDevices')
  checkAudioAndVideoDevices = async () => {
    const devicesAvailable = await navigator.mediaDevices.enumerateDevices();
    const audioDevice =
      devicesAvailable.filter((device) => device.kind === 'audioinput').length > 0;
    const videoDevice =
      devicesAvailable.filter((device) => device.kind === 'videoinput').length > 0;
    return { audioDevice, videoDevice };
  };

  @action('CallStore.getUserInfo') getUserInfo = async (userId) => {
    const {
      userStore: { findUser },
      messengerStore: { currentOrganizationId },
    } = this.stores;
    const callOrgId = get(this.currentCall, 'payload.orgId', currentOrganizationId);

    return await findUser(userId, callOrgId);
  };

  @action('CallStore.videoCallSetUp') videoCallSetUp = async (entity, origin) => {
    const { networkStore } = this.stores;
    const { isProviderNetwork } = networkStore;
    if (this.isBrowserNotSupported) {
      return this.stores.modalStore.openModal('callUnsupported');
    } else if (entity.dnd) {
      return this.stores.modalStore.openModal('calleeDndEnabled');
    }
    const context = {
      id: entity.id,
      type: entity.$entityType === 'user' ? 'account' : entity.$entityType,
    };

    if (isEmpty(entity.context) || !entity.context.id || !entity.context.type) {
      if (context.id && context.type) entity.context = context;
    }

    if (!this.isSettingUpTracks) {
      this.isSettingUpTracks = true;
    } else {
      return;
    }

    const { audioTrack, videoTrack } = await this.setUpTracks();
    this.isSettingUpTracks = false;

    this.setIsVideoInactive(!videoTrack);
    this.origin = origin;

    if (!audioTrack || !videoTrack) {
      const devices = [];
      if (!videoTrack) devices.push('Camera');
      if (!audioTrack) devices.push('Mic');
    }

    if (!audioTrack) {
      const { audioDevice, videoDevice } = await this.checkAudioAndVideoDevices();
      this.stores.modalStore.openModal('enableBrowserPermissions', {
        audioDevice,
        isVideo: videoTrack || false,
        videoDevice,
      });
    } else if (
      !videoTrack &&
      (!isProviderNetwork || [PfCallOrigins.VWR_CONVERSATION].includes(origin))
    ) {
      const { videoDevice } = await this.checkAudioAndVideoDevices();
      this.stores.modalStore.openModal('noCamera', { entity, isProviderNetwork, videoDevice });
    } else if (origin === PfCallOrigins.BANG) {
      this.startCall(entity);
    } else if (origin === PfCallOrigins.VWR_CONVERSATION) {
      this.stores.modalStore.openModal('visitorCall', { entity });
    } else if (isProviderNetwork) {
      this.stores.modalStore.openModal('callAction', {
        audioTrack,
        entity,
        videoTrack,
      });
    } else {
      this.stores.modalStore.openModal('confirmCall', { entity });
    }
  };

  @action('CallStore.setUpTracks') setUpTracks = async () => {
    let audioTrack, videoTrack;
    try {
      audioTrack = await Video.createLocalAudioTrack();
      videoTrack = await Video.createLocalVideoTrack({ name: 'camera' });
    } catch (err) {
      console.error(err);
    }
    runInAction(() => {
      this.localTracksObject = { audioTrack, videoTrack };
    });
    return { audioTrack, videoTrack };
  };

  @action('CallStore.stopTracks') stopTracks = async (localTracks) => {
    for (const track of Object.values(localTracks)) {
      if (track && ['audio', 'video'].includes(track.kind)) {
        track.stop();
      }
    }
  };

  @action('CallStore.setIncomingCallActionsEnabled')
  setIncomingCallActionsEnabled = (areEnabled) => {
    this.incomingCallActionsEnabled = areEnabled;
  };

  @action('CallStore.tryToJoinVoipCall')
  tryToJoinVoipCall = async (shouldUseCamera, { roomName = null } = {}) => {
    if (this.isBrowserNotSupported) {
      return this.stores.modalStore.openModal('callUnsupported');
    }
    const { audioTrack, videoTrack } = await this.setUpTracks();

    if (!shouldUseCamera && videoTrack) videoTrack.disable();
    this.setIsVideoInactive(!videoTrack);

    if (!audioTrack) {
      const { audioDevice, videoDevice } = await this.checkAudioAndVideoDevices();
      this.stores.modalStore.openModal('enableBrowserPermissions', {
        audioDevice,
        isVideo: videoTrack || false,
        videoDevice,
      });
    } else if (!videoTrack) {
      const { videoDevice } = await this.checkAudioAndVideoDevices();
      this.stores.modalStore.openModal('noCamera', {
        entity: {},
        isIncomingCall: true,
        videoDevice,
        roomName,
      });
    } else {
      return this.joinVoipCall({ shouldAnswerWithCamOn: shouldUseCamera, roomName });
    }
  };

  @action('CallStore.closeModal') closeModal = () => {
    if (this.localTracksObject) {
      const { audioTrack, videoTrack } = this.localTracksObject;
      this.stopTracks({ audioTrack, videoTrack });
      this.localTracksObject = {};
    }
    this.stores.modalStore.closeModal();
  };

  @action('CallStore.inviteMember') inviteMember = async (member) => {
    const pfData = {};
    let inviteFn = 'inviteUser';

    if (member.isPatient) {
      pfData.p_id = member.id;
      inviteFn = 'invitePatient';
    } else if (member.isPatientContact) {
      pfData.pc_id = member.id;
      inviteFn = 'invitePatientContact';
    }

    const callResponse = await this.client.calls[inviteFn](this.currentCall, member.id, {
      pfData,
    });

    runInAction(() => {
      this.currentCall = callResponse;
    });
  };

  @action('CallStore.startCall') startCall = async ({
    recipientId,
    members = [],
    isVideo = true,
    context,
  }) => {
    const {
      conversationStore: { currentConversation: { featureService } = {} },
      messengerStore: { currentOrganizationId },
      networkStore: { isProviderNetwork },
    } = this.stores;

    runInAction(() => {
      this.isSettingUpCall = true;
    });

    if (!isVideo) {
      const { audioTrack, videoTrack } = this.localTracksObject;
      if (videoTrack) {
        this.stopTracks(videoTrack);
        runInAction(() => {
          this.localTracksObject = { audioTrack };
        });
      }
    }

    const dataTrack = new LocalDataTrack();
    const recipients = members.length ? members.map(({ id }) => id) : [recipientId];
    let callResponse;

    try {
      callResponse = await this.client.calls.start({
        dataTrack,
        isVideo,
        organizationId: currentOrganizationId,
        participantIds: recipients,
        providedContext: context,
        metadata: {},
        networkType: isProviderNetwork && featureService !== 'vwr' ? 'provider' : 'patient',
      });
    } catch (err) {
      console.error(err);
      const { response = {} } = err;
      this.stores.modalStore.closeModal();
      if (
        get(response, 'body.status') === 'fail' &&
        response?.body?.error?.message?.hasOwnProperty('disabled_participants') &&
        Object.keys(response.body.error.message.disabled_participants).length > 0
      ) {
        this.stores.modalStore.openModal('calleeCapabilities');
      } else {
        this.stores.modalStore.openModal('failure');
      }
      this.isSettingUpCall = false;
    }

    if (callResponse) {
      runInAction(() => {
        this.currentCall = callResponse;
        this.stores.modalStore.closeModal();
        this.isSettingUpCall = false;
      });
    }
  };

  @action('CallStore.joinVoipCall')
  joinVoipCall = async ({ roomName, shouldAnswerWithCamOn }) => {
    const { modalStore } = this.stores;
    let callResponse;
    try {
      const dataTrack = new LocalDataTrack();
      callResponse = await this.client.calls.join(roomName || this.currentCall, {
        dataTrack,
      });
    } catch (err) {
      console.error(err);
      this.clearCallState();
      modalStore.openModal('joinVoipCallErrorModal', {});
    } finally {
      if (callResponse) {
        runInAction(() => {
          this.currentCall = callResponse;
          this.roomName = this.currentCall.roomName;
          this.shouldAnswerWithCamOn = shouldAnswerWithCamOn;
        });
      }
    }
  };

  @action('CallStore.clearCallState') clearCallState = () => {
    this.isSettingUpCall = false;
    this.currentCall = null;
    this.localTracksObject && this.stopTracks(this.localTracksObject);
    this.localTracksObject = {};
  };

  @action('CallStore.endVoipCall') endVoipCall = async (reason = 'ended') => {
    if (!this.currentCall) return;
    try {
      await this.client.calls.end(this.currentCall, { reason });
    } finally {
      this.clearCallState();
    }
  };

  @action('CallStore.declineVoipCall') declineVoipCall = async () => {
    this.endVoipCall('declined');
  };

  @action('CallStore.setIsVideoInactive')
  setIsVideoInactive = (inactivateVideoDevice) => {
    this.isVideoInactive = inactivateVideoDevice;
  };

  @action('CallStore.fetchLog')
  fetchLog = async ({ continuation, network, organizationId } = {}) => {
    const { metadata, results } = await this.client.calls.findCallLogEntries({
      network,
      organizationId,
      page: continuation,
    });

    return {
      metadata: { continuation: metadata.page },
      results,
    };
  };

  @action('CallStore.sendDataMessage') sendDataMessage = async (message) => {
    await this.client.calls.sendDataMessage(this.currentCall, message);
  };

  @action('CallStore.setCurrentCall') setCurrentCall = (call) => {
    this.stores.desktopAppStore._stopSound();
    this.currentCall = call;
  };

  @action('CallStore.setCurrentLog') setCurrentLog = (log) => {
    this.currentLog = log;
  };

  @action('CallStore.setLogContinuation') setLogContinuation = (continuation) => {
    this.logContinuation = continuation;
  };

  @action('CallStore.getPatientLink') getPatientLink = async (member) => {
    try {
      const patientLink = await this.client.calls.getPatientLink(this.currentCall, member.id);
      if (patientLink?.access_url) {
        return patientLink.access_url;
      } else {
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };
}
