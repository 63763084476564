import Enum from '../../utils/enum';

const GroupType = new Enum([
  'ACTIVATED_TEAM',
  'ESCALATION',
  'FORUM',
  'GROUP',
  'INTRA_TEAM',
  'PATIENT_CARE',
  'PATIENT_MESSAGING',
  'ROLE_ASSIGNMENT',
  'ROLE_P2P',
] as const);

export default GroupType;
