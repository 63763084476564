export const MessageTemplateRepositories = {
  ALL: 'admin',
  AUTOMATED: 'automated',
  GENERAL: 'general',
  PERSONAL: 'personal',
  DYNAMIC: 'aar_variables',
} as const;

export const MessageTemplateRepositoryLabels = {
  admin: 'Admin',
  automated: 'Automated',
  general: 'General',
  personal: 'Personal',
  aar_variables: 'Dynamic',
};

export type MessageTemplateRepository =
  | typeof MessageTemplateRepositories.GENERAL
  | typeof MessageTemplateRepositories.PERSONAL
  | typeof MessageTemplateRepositories.DYNAMIC;
