import React, { useState } from 'react';
import { observer } from 'mobx-react';

import BEM from '../../../../common/bem';
import { AlertComponent, Conversation, Message, User } from '../../../../../src/types/';
import { mobxInjectSelect } from '../../../../common/utils';
import AlertActionButton from './AlertActionButton';
import AlertActionChoice from './AlertActionChoice';
import AlertBoldTextView from './AlertBoldTextView';
import AlertCompletedItem from './AlertCompletedItem';
import AlertEscalateHeader from './AlertEscalateHeader';
import AlertHeader from './AlertHeader';
import AlertRoleInfo from './AlertRoleInfo';
import AlertTextView from './AlertTextView';
import AlertViewConversation from './AlertViewConversation';

const classes = BEM.with('AlertCardV2');

const DISABLED_ACTION_TYPES = ['call'];

const TOP_SECTION_COMPONENT_TYPES = ['escalate_header', 'nurse_header'];
const LEFT_SECTION_COMPONENT_TYPES = ['bold_text_view', 'role_info', 'text_view'];
const RIGHT_SECTION_COMPONENT_TYPES = ['completed_item'];

type ModalOptionsProps = {
  message: Message;
};

const ALERT_CARD_COMPONENTS = {
  bold_text_view: AlertBoldTextView,
  button_view: null,
  completed_item: AlertCompletedItem,
  escalate_header: AlertEscalateHeader,
  nurse_header: null,
  role_info: AlertRoleInfo,
  text_view: null,
  two_button_view: null,
} as const;

type MobxProps = {
  currentUser: User;
  openModal: (modalName: string, options?: ModalOptionsProps) => void;
  showAlertConversation: (conversation?: Conversation) => void;
  isGroupAlertsVCAllowed: boolean;
};

export type AlertCardV2Props = {
  isExpanded?: boolean;
  message: Message;
  numLinesToClipContentAt?: number;
  showConversationLink?: boolean;
  showButtons?: boolean;
};

const WrappedAlertCardV2 = ({
  currentUser,
  isExpanded = false,
  isGroupAlertsVCAllowed,
  message,
  numLinesToClipContentAt = 0,
  openModal,
  setShouldDisableAllButtons,
  shouldDisableAllButtons,
  showAlertConversation,
  showButtons,
  showConversationLink,
}: AlertCardV2Props &
  MobxProps & {
    setShouldDisableAllButtons: (shouldDisableAllButtons: boolean) => void;
    shouldDisableAllButtons: boolean;
  }) => {
  const { alertCard, createdAt } = message;
  if (!alertCard) return null;

  const onActionClick = () => {
    setShouldDisableAllButtons(true);
  };
  const onActionFinish = (enableButtons = true) => {
    enableButtons && setShouldDisableAllButtons(false);
  };
  const openAlertDetailsModal = () => openModal('alertDetails', { message });

  const topSectionComponents = [];
  const leftSectionComponents = [];
  const rightSectionComponents = [];
  const rawButtonComponents = [];

  for (let idx = 0; idx < alertCard.length; idx++) {
    const component = alertCard[idx];

    if (['button_view', 'two_button_view'].includes(component.type)) {
      if (component.type === 'button_view') {
        rawButtonComponents.push(component);
      } else if (component.type === 'two_button_view') {
        const { data } = component;
        if (!data) continue;

        rawButtonComponents.push(...data);
      }

      continue;
    }

    const alertComponent = renderComponent(component, {
      createdAt: createdAt.toISOString(),
      isExpanded,
      message,
      numLinesToClipContentAt,
      openAlertDetailsModal,
    });
    if (isExpanded) {
      topSectionComponents.push(alertComponent);
    } else {
      if (TOP_SECTION_COMPONENT_TYPES.includes(component.type)) {
        topSectionComponents.push(alertComponent);
      } else if (LEFT_SECTION_COMPONENT_TYPES.includes(component.type)) {
        leftSectionComponents.push(alertComponent);
      } else if (RIGHT_SECTION_COMPONENT_TYPES.includes(component.type)) {
        rightSectionComponents.push(alertComponent);
      }
    }
  }

  const currentUserRolesInGroup = message?.alertRecipients?.filter((alertRecipient) => {
    return currentUser.roles?.find(({ botUserId }) => botUserId === alertRecipient.id);
  });
  if (currentUserRolesInGroup?.length > 0) {
    const roleInfoFragment = (
      <AlertRoleInfo
        component={
          {
            value_color: '#8798a4',
            value: `Sent to ${currentUserRolesInGroup
              .map((role) => role.displayName)
              .join(', ')} (You)`,
          } as AlertComponent
        }
        key="role-info"
      />
    );

    if (isExpanded) {
      topSectionComponents.push(roleInfoFragment);
    } else {
      leftSectionComponents.push(roleInfoFragment);
    }
  }

  if (!isExpanded) {
    leftSectionComponents.push(
      <button
        className={classes('expand-btn')}
        key="expand-btn"
        data-test-id={'viewMoreButton'}
        aria-label="view-more-button"
        onClick={openAlertDetailsModal}
      >
        + View More
      </button>
    );
  }

  const shouldShowButtons =
    !message.isHistoricalAlert &&
    !message.currentRecipientAlertAction &&
    !!message.alertDetails?.includesCurrentUserOrRole;
  if (shouldShowButtons && rawButtonComponents.length > 0) {
    const buttonProps = {
      messageId: message.id,
      onActionClick,
      onActionFinish,
    };

    const buttonComponents = (
      <div className={classes('alert-buttons')} key="alert-buttons">
        {rawButtonComponents.map((rawButtonComponent, index) => {
          const key = rawButtonComponent.type + rawButtonComponent.value + index;

          return (
            <AlertActionButton
              component={rawButtonComponent}
              isDisabled={
                shouldDisableAllButtons ||
                DISABLED_ACTION_TYPES.includes(rawButtonComponent.action_type)
              }
              key={key}
              {...buttonProps}
            />
          );
        })}
      </div>
    );

    if (isExpanded) {
      topSectionComponents.push(buttonComponents);
    } else {
      rightSectionComponents.push(buttonComponents);
    }
  }

  if (message.currentRecipientAlertAction) {
    if (isExpanded) {
      topSectionComponents.push(
        <AlertActionChoice isExpanded={isExpanded} message={message} key={'topSectionComponents'} />
      );
    } else {
      rightSectionComponents.push(
        <AlertActionChoice
          isExpanded={isExpanded}
          message={message}
          key={'rightSectionComponents'}
        />
      );
    }
  }

  const shouldShowConversationLink =
    isGroupAlertsVCAllowed &&
    showConversationLink &&
    message.featureService === 'group_alerts' &&
    !!message.alertConversationGroup?.conversation;

  return (
    <div className={classes()}>
      <div className={classes('content-container')}>
        <div className={classes('top-section')}>{topSectionComponents}</div>
        <div className={classes('horizontal-section-container')}>
          <div
            className={classes('left-section', { fullWidth: rightSectionComponents.length === 0 })}
          >
            {leftSectionComponents}
          </div>
          {rightSectionComponents.length > 0 && (
            <div className={classes('right-section')}>{rightSectionComponents}</div>
          )}
        </div>
      </div>
      {shouldShowConversationLink && (
        <AlertViewConversation
          onClick={() => {
            showAlertConversation(message.alertConversationGroup?.conversation);
          }}
        />
      )}
    </div>
  );
};
const ObservedAlertCardV2 = observer(WrappedAlertCardV2);

const AlertCardV2 = ({
  currentUser,
  isExpanded = false,
  isGroupAlertsVCAllowed,
  message,
  numLinesToClipContentAt = 0,
  openModal,
  showConversationLink,
  showAlertConversation,
  showButtons,
}: AlertCardV2Props & MobxProps) => {
  const [shouldDisableAllButtons, setShouldDisableAllButtons] = useState(false);

  return (
    <ObservedAlertCardV2
      currentUser={currentUser}
      isExpanded={isExpanded}
      isGroupAlertsVCAllowed={isGroupAlertsVCAllowed}
      message={message}
      numLinesToClipContentAt={numLinesToClipContentAt}
      openModal={openModal}
      setShouldDisableAllButtons={setShouldDisableAllButtons}
      shouldDisableAllButtons={shouldDisableAllButtons}
      showAlertConversation={showAlertConversation}
      showButtons={showButtons}
      showConversationLink={showConversationLink}
    />
  );
};

const renderComponent = (
  component: AlertComponent,
  {
    createdAt,
    isExpanded,
    message,
    numLinesToClipContentAt,
    openAlertDetailsModal,
  }: {
    createdAt: string;
    isExpanded: boolean;
    message: Message;
    numLinesToClipContentAt: number;
    openAlertDetailsModal: () => void;
  }
) => {
  const key = component.type + component.value;

  if (component.type === 'nurse_header') {
    return (
      <AlertHeader
        component={component}
        createdAt={createdAt}
        key={key}
        message={message}
        onStatusClick={isExpanded ? undefined : openAlertDetailsModal}
      />
    );
  } else if (component.type === 'text_view') {
    return (
      <AlertTextView
        component={component}
        key={key}
        numLinesToClipContentAt={numLinesToClipContentAt}
      />
    );
  }

  const AlertCardComponent = ALERT_CARD_COMPONENTS[component.type];

  if (AlertCardComponent) {
    // TODO: Also remove all isV2 instances (including CSS modifiers) when removing group alerts feature flag
    return <AlertCardComponent key={key} component={component} isExpanded={isExpanded} isV2 />;
  }
};

export default mobxInjectSelect<AlertCardV2Props, MobxProps>({
  messengerStore: ['isGroupAlertsVCAllowed', 'showAlertConversation'],
  modalStore: ['openModal'],
  sessionStore: ['currentUser'],
})(AlertCardV2);
