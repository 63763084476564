import BaseAPI from './BaseAPI';

export default class ReportAPI extends BaseAPI {
  async send(reports: Record<string, unknown>[]) {
    const url = `${this.host.cnUrl}/admin_cn/report`;

    const res = await this.httpClient.post(url, {
      data: reports,
    });

    return res.data;
  }
}
