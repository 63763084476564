// @ts-nocheck
import _ from 'lodash';

const Camelizer = {
  camelizeKey: _.memoize(_.camelCase),
  underscoreKey: _.memoize(_.snakeCase),

  camelizeObject<T = any>(object) {
    return deepMapKeys(object, Camelizer.camelizeKey) as T;
  },

  underscoreObject<T = any>(object) {
    return deepMapKeys(object, Camelizer.underscoreKey) as T;
  },
};

function deepMapKeys(obj, keyMapper) {
  if (_.isArray(obj)) {
    return obj.map((v) => deepMapKeys(v, keyMapper));
  } else if (_.isObject(obj)) {
    return _.transform(
      obj,
      (a, v, k) => {
        a[keyMapper(k)] = deepMapKeys(v, keyMapper);
      },
      {}
    );
  } else {
    return obj;
  }
}

export default Camelizer;
