import { observer } from 'mobx-react';
import React from 'react';
import { ReactComponent as DragToTileSvg } from '../../images/drag-to-tile.svg';
import BEM from 'common/bem';

const classes = BEM.with('AttachmentDragModal');

function AttachmentDragModal({ isDragActive }) {
  // Bug: react-dropzone sometimes gets a wrong value for isDragReject after dragging through multiple tiles
  // const { isDragActive, isDragReject } = this.props
  // const dragState = isDragActive ? (isDragReject ? 'drag-rejected' : 'drag-accepted') : null
  const dragState = isDragActive ? 'drag-accepted' : null;

  return (
    dragState && (
      <div className={classes()}>
        <div className={classes('overlay', { [dragState]: true })}>
          {dragState === 'drag-accepted' && <DragToTileSvg className={classes('image')} />}
        </div>
      </div>
    )
  );
}

export default observer(AttachmentDragModal);
