import {
  forwardRef,
  KeyboardEventHandler,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

type AccessibleListProps = {
  children: JSX.Element | (JSX.Element | false | undefined)[];
  className?: string | undefined;
  direction?: 'horizontal' | 'vertical';
  focusableClasses: string[] | string;
  focusStart?: 'first' | 'last' | HTMLElement;
  loop?: boolean;
  accessibilityMode?: boolean;
  focusableChildrenClasses?: string[] | string;
  setStartElementOnChange?: boolean;
  focusCenteredOnScroll?: boolean;
  role?: string;
  ariaLabel?: string;
};

const AccessibleList = (
  {
    className,
    children,
    direction = 'vertical',
    focusableClasses,
    focusStart = 'first',
    loop = false,
    focusableChildrenClasses = [],
    setStartElementOnChange = false,
    accessibilityMode = false,
    focusCenteredOnScroll = false,
    ariaLabel = undefined,
    role,
  }: AccessibleListProps,
  ref: Ref<HTMLUListElement>
) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const listItems = useRef<HTMLElement[]>([]);
  const [startElementSet, setStartElementSet] = useState(false);
  const [prevActiveIndex, setPrevActiveIndex] = useState(0);
  const forwardKey = direction === 'horizontal' ? 'ArrowRight' : 'ArrowDown';
  const backKey = direction === 'horizontal' ? 'ArrowLeft' : 'ArrowUp';
  const cssQuery = Array.isArray(focusableClasses) ? focusableClasses.join(',') : focusableClasses;
  const cssChildrenQuery = Array.isArray(focusableChildrenClasses)
    ? focusableChildrenClasses.join(',')
    : focusableChildrenClasses;

  useImperativeHandle<HTMLUListElement | null, HTMLUListElement | null>(
    ref,
    () => containerRef.current
  );

  const setTabIndex = useCallback(
    (
      element: HTMLElement | Element,
      tabIndexValue: 0 | -1,
      scope: 'all' | 'parent' | 'children'
    ) => {
      if (scope !== 'children') (element as HTMLElement).tabIndex = tabIndexValue;
      if (cssChildrenQuery && scope !== 'parent') {
        element.querySelectorAll<HTMLElement>(cssChildrenQuery).forEach((element: HTMLElement) => {
          element.tabIndex = tabIndexValue;
        });
      }
    },
    [cssChildrenQuery]
  );

  const elementHasFocusableClass = useCallback(
    (element: HTMLElement, isChildClasses = false) => {
      let classes = !isChildClasses ? focusableClasses : focusableChildrenClasses;
      classes = Array.isArray(classes) ? classes : [classes];
      classes = classes.filter((x) => x).map((x) => x.replace('.', ''));
      return Array.from(element.classList).find((c) => classes.includes(c));
    },
    [focusableChildrenClasses, focusableClasses]
  );

  const setStartElement = useCallback(() => {
    if (startElementSet || !accessibilityMode) return;
    let startingElement: HTMLElement | null | undefined = null;
    if (focusStart === 'first') {
      startingElement = listItems.current[0];
    } else if (focusStart === 'last') {
      const listLength = listItems.current.length;
      if (listLength) startingElement = listItems.current[listLength - 1];
    } else {
      startingElement = focusStart;
    }
    if (startingElement) {
      setTabIndex(startingElement, 0, 'parent');
      setTabIndex(startingElement, -1, 'children');
      listItems.current
        .filter((item) => item !== startingElement)
        .forEach((item) => {
          setTabIndex(item, -1, 'all');
        });
      setStartElementSet(true);
    }
  }, [accessibilityMode, focusStart, startElementSet, setTabIndex]);

  useEffect(() => {
    const container = containerRef.current;

    const closestParent = (e: HTMLElement) => {
      let parent: HTMLElement | null = e;
      while (parent && !elementHasFocusableClass(parent)) parent = parent.parentElement;
      return parent;
    };

    const handleBlur = (e: FocusEvent) => {
      if (!e.relatedTarget || !e.target) return;
      const newFocusedParent = closestParent(e.relatedTarget as HTMLElement);
      const focusHasLeftList = !newFocusedParent;
      const preserveLastFocus = focusHasLeftList && !setStartElementOnChange;
      const blurredParent = closestParent(e.target as HTMLElement);

      if (newFocusedParent && blurredParent && blurredParent.isSameNode(newFocusedParent)) return;
      const newFocusIsInContainer = container?.contains(e.relatedTarget as HTMLElement);
      if (elementHasFocusableClass(e.relatedTarget as HTMLElement, true) && newFocusIsInContainer)
        return;
      if (blurredParent) {
        setTabIndex(blurredParent, -1, preserveLastFocus ? 'children' : 'all');
        preserveLastFocus && setTabIndex(blurredParent, 0, 'parent');
      }
      if (newFocusedParent) setTabIndex(newFocusedParent, 0, 'parent');
      if (setStartElementOnChange && focusHasLeftList) {
        setStartElementSet(false);
        setStartElement();
      }
    };

    const handleFocus = (e: FocusEvent) => {
      const element = e.target as HTMLElement;
      if (elementHasFocusableClass(element)) {
        setTabIndex(element, 0, 'all');
      }
    };

    container?.addEventListener('blur', handleBlur, true);
    container?.addEventListener('focus', handleFocus, true);

    return () => {
      container?.removeEventListener('blur', handleBlur, true);
      container?.removeEventListener('focus', handleFocus, true);
    };
  }, [elementHasFocusableClass, setStartElement, setStartElementOnChange, setTabIndex]);

  const onKeyDown: KeyboardEventHandler<HTMLUListElement> = (event) => {
    const isHtmlInputEvent = event.target instanceof HTMLInputElement;
    const isActionableKey = event.key === 'Enter' || event.key === ' ';
    const isNavigationKey = event.key === backKey || event.key === forwardKey;
    if ((event.key !== 'Tab' && !isHtmlInputEvent && !isActionableKey) || isNavigationKey)
      event.preventDefault();
    if ([backKey, forwardKey].includes(event.key)) {
      let activeElement = document.activeElement;
      if (!activeElement) return;
      let activeIndex = listItems.current.findIndex((item) => item === document.activeElement);
      if (activeIndex === -1) {
        activeIndex = prevActiveIndex;
        activeElement = listItems.current[prevActiveIndex];
      }
      const listLength = listItems.current.length;
      const atBottom = event.key === forwardKey && activeIndex === listLength - 1;
      const atTop = event.key === backKey && activeIndex === 0;
      if (!listLength) return;
      if ((atBottom || atTop) && !loop) return;

      let nextElement: HTMLElement;

      if (atBottom) {
        nextElement = listItems.current[0];
        setPrevActiveIndex(0);
      } else if (atTop) {
        nextElement = listItems.current[listLength - 1];
        setPrevActiveIndex(listLength - 1);
      } else {
        nextElement = listItems.current[activeIndex + (event.key === forwardKey ? 1 : -1)];
        setPrevActiveIndex(activeIndex + (event.key === forwardKey ? 1 : -1));
      }

      if (nextElement) {
        nextElement.tabIndex = 0;
        nextElement.focus();
        focusCenteredOnScroll && nextElement.scrollIntoView({ block: 'center' });
        if ((activeElement as HTMLElement).tabIndex === 0) {
          setTabIndex(activeElement, -1, 'all');
        }
      }
    }
  };

  const refreshListItems = useCallback(() => {
    listItems.current = Array.from(
      containerRef.current?.querySelectorAll<HTMLElement>(cssQuery) || []
    );
  }, [cssQuery]);

  const onMutation = useCallback(() => {
    refreshListItems();
    const focusableItem = listItems.current.find((x) => x.tabIndex === 0);
    if (!focusableItem) setStartElementSet(false);
    setStartElement();
  }, [refreshListItems, setStartElement]);

  useEffect(() => {
    setStartElement();
  }, [focusStart, containerRef, cssQuery, setStartElement]);

  useEffect(() => {
    if (!containerRef.current || !accessibilityMode) return;
    if (listItems.current.length === 0) {
      onMutation();
    }
    const observer = new MutationObserver(onMutation);
    observer.observe(containerRef.current, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [accessibilityMode, onMutation]);

  refreshListItems();

  return (
    <ul
      ref={containerRef}
      className={className}
      onKeyDown={accessibilityMode ? onKeyDown : undefined}
      role={role}
      aria-label={ariaLabel}
    >
      {children}
    </ul>
  );
};

export default forwardRef(AccessibleList);
