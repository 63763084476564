import BaseAPI, { ResponseType, ResponseWithStatus } from '../BaseAPI';
import WrongCredentialsError from '../../errors/WrongCredentialsError';

type Method = 'del' | 'get' | 'post' | 'put';

const CN_SESSION_ID_TIMEOUT = 1000 * 60 * 10;

export const cnSession: {
  sessionId?: string;
  cnBox?: string;
  resource?: string;
  xmppPassword?: string;
  lastLogin?: number;
} = {};

export default class BaseAdminAPI extends BaseAPI {
  get cnBox() {
    return cnSession.cnBox;
  }

  async initiateAdminSession(options: { forceRefresh?: boolean } = { forceRefresh: false }) {
    if (
      !options.forceRefresh &&
      cnSession.lastLogin &&
      cnSession.lastLogin + CN_SESSION_ID_TIMEOUT > Date.now()
    ) {
      return;
    }

    const { key: apiKey, secret: apiSecret } = this.httpClient.getAuth();

    const {
      data: { session_id, cn_server, resource, xmpp_password },
    } = await this.httpClient.post<{
      session_id: string;
      cn_server: string;
      resource: string;
      xmpp_password: string;
    }>(`${this.host.cnUrl}/admin_cn/login`, {
      data: {
        api_key: apiKey,
        api_secret: apiSecret,
      },
    });

    cnSession.sessionId = session_id;
    cnSession.cnBox = cn_server;
    cnSession.resource = resource;
    cnSession.xmppPassword = xmpp_password;
    cnSession.lastLogin = Date.now();
  }

  xmppAuthorizationHeader({ organizationId }: { organizationId: string }) {
    if (!organizationId) {
      throw new Error('Please provide an organizationId');
    }

    return `XMPP ${btoa(
      `${organizationId}:${cnSession.resource}:${this.host.currentUserId}:${cnSession.xmppPassword}`
    )}`;
  }

  refreshSession: Promise<void> | null = null;

  async retryWithSessionRefresh<Res, ResType>(
    requestFn: () => Promise<ResType extends 'json' ? ResponseWithStatus<Res> : Res>
  ): Promise<ResType extends 'json' ? ResponseWithStatus<Res> : Res> {
    try {
      return await requestFn();
    } catch (e) {
      if (e && e.code && e.code === WrongCredentialsError.CODE) {
        if (this.refreshSession === null) {
          this.refreshSession = this.initiateAdminSession({ forceRefresh: true });
        }
        await this.refreshSession;
        this.refreshSession = null;
        return requestFn();
      } else {
        throw e;
      }
    }
  }

  async cnDevApi<Res, ResType extends ResponseType = 'json'>({
    data,
    endpoint,
    method = 'get',
    organizationId,
    query,
  }: {
    data?: unknown;
    endpoint: string;
    method?: Method;
    organizationId: string;
    query?: Record<string, unknown>;
  }) {
    return this.retryWithSessionRefresh<Res, ResType>(async () => {
      await this.initiateAdminSession();

      return this.httpClient[method]<Res, ResType>(
        `${this.host.cnUrl}/admin_cn${cnSession.cnBox}/devapi/${endpoint}`,
        {
          data,
          headers: { 'x-tt-session-id': cnSession.sessionId },
          query: {
            ...query,
            session_org: organizationId,
            _: new Date().getTime(),
          },
        }
      );
    });
  }

  async cnRoute<Res, ResType extends ResponseType = 'json'>({
    data,
    method = 'get',
    route,
    files,
    responseFormat,
    requestFormat,
  }: {
    data?: Record<string, unknown>;
    method?: 'get' | 'post' | 'put' | 'del';
    route: string;
    files?: Record<string, unknown>;
    responseFormat?: ResType;
    requestFormat?: 'json' | 'form' | 'event-stream' | 'text';
  }) {
    return this.retryWithSessionRefresh<Res, ResType>(async () => {
      await this.initiateAdminSession();

      return this.httpClient[method]<Res, ResType>(
        `${this.host.cnUrl}/admin_cn${cnSession.cnBox}/${route}`,
        {
          data: requestFormat === 'form' ? { req: JSON.stringify(data) } : data,
          headers: { 'x-tt-session-id': cnSession.sessionId },
          query: {
            req: this._generateReq(data),
            _: new Date().getTime(),
          },
          ...(responseFormat && { resFormat: responseFormat }),
          ...(requestFormat && { reqFormat: requestFormat }),
          ...(files && { files }),
        }
      );
    });
  }

  _generateReq(data?: Record<string, unknown>) {
    return JSON.stringify({
      ...data,
      ts: new Date().getTime(),
    });
  }
}
