// @ts-nocheck
import BaseAPI from './BaseAPI';

export default class ConversationsAPI extends BaseAPI {
  async find(id: string) {
    const res = await this.httpClient.get('/v5/conversation/:id', {
      urlParams: { id },
      headers: {
        'tt-x-features': 'vwr',
      },
    });

    return res.data;
  }

  async updateConversationStatus(
    id: string,
    status: string,
    { highestSortNumber, lowestSortNumber } = {}
  ) {
    const data = { status };

    if (highestSortNumber) {
      data.highest_sort_number = highestSortNumber;
    }

    if (lowestSortNumber) {
      data.lowest_sort_number = lowestSortNumber;
    }

    const res = await this.httpClient.post('/v5/conversation/:id/status', {
      urlParams: { id },
      data,
    });

    return res.data;
  }

  async fetchTimeline(
    id: string,
    anchorPoint: string,
    maxItems: number,
    {
      continuation,
      markAsDelivered = true,
    }: {
      continuation: Object | null | undefined;
      markAsDelivered: boolean | null | undefined;
    }
  ) {
    const res = await this.httpClient.post('/v5/conversation/:id/timeline', {
      urlParams: { id },
      data: {
        anchor_point: anchorPoint,
        continuation,
        mark_as_delivered: markAsDelivered,
        max_items: maxItems,
      },
      headers: {
        'tt-x-features': 'vwr',
      },
    });
    return res.data;
  }
}
