const map = new WeakMap();

export default function detectOnSoftClick(fn, { stopPropagation } = {}) {
  let clicks = 0;

  return (e) => {
    if (stopPropagation) e.stopPropagation();

    clicks++;
    let timer;

    const { currentTarget } = e;

    if (clicks === 1) {
      e.persist();
      timer = setTimeout(() => {
        clicks = 0;
        fn(e);
        map.delete(currentTarget);
      }, 200);
      map.set(currentTarget, timer);
    } else {
      timer = map.get(currentTarget);
      if (timer) {
        clearTimeout(timer);
        map.delete(currentTarget);
      }
      clicks = 0;
    }
  };
}
