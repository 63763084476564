// @ts-nocheck
import addEnsureEntity from './extensions/ensureEntity';
import { cloudFrontUrlTransform } from './extensions/cloudFrontTransform';
import { FeatureService, GroupType, MessageSubType } from './enums';

export default function (store, { host }) {
  const Group = store.defineModel('group', {
    replaceKeys: {
      avatar: 'avatarUrl',
      createdTime: 'createdAt',
      displayName: 'name',
      members: 'memberIds',
      numMembers: '__memberCount',
      organizationKey: 'organizationId',
      proxied_members: 'proxiedMembers',
    },

    transientAttrs: [
      // store memberCount in __memberCount, and turn it into a property (computed) that returns memberIds.length
      // if available, or this number. ROOM groups don't have memberIds array
      '__memberCount',
    ],

    defaultValues: {
      hasCurrentUser: false,
      isPublic: false,
      memberIds: null,
      p2pRecipient: null,
      p2pSender: null,
    },

    skipCamelizationForKeys: ['escalation', 'metadata', 'proxied_members'],

    parseAttrs(attrs) {
      if ('createdAt' in attrs) attrs.createdAt = new Date(attrs.createdAt);
      if ('createdBy' in attrs) delete attrs['createdBy'];

      if (attrs.avatarUrl) {
        attrs.avatarUrl = cloudFrontUrlTransform(attrs.avatarUrl, host.config);
      }

      if ('escalation' in attrs) {
        const execution = host.escalations._updateEscalation(attrs.escalation, {
          inGroupInject: true,
        });
        delete attrs.escalation;
        attrs.escalationExecutionId = execution.id;
      }

      if (attrs.memberIds) {
        const memberIds = [];
        for (const member of attrs.memberIds) {
          if (typeof member === 'string') {
            memberIds.push(member);
          } else if (member.id) {
            memberIds.push(member.id);
          }
        }
        attrs.memberIds = memberIds;
      }

      if (
        attrs.metadata &&
        attrs.metadata['feature_service'] === FeatureService.WORKFLOW_NOTIFICATION
      ) {
        if (attrs?.memberIds) {
          attrs.memberIds = attrs.memberIds.filter((id) => !host.users.getById(id)?.isWorkflowBot);
        }
        attrs.memberCount = (attrs.memberIds && attrs.memberIds.length) || 0;
        attrs.members = attrs?.members?.filter((id) => !host.users.getById(id)?.isWorkflowBot);
      }

      if (attrs.metadata && attrs.metadata['feature_service'] === FeatureService.GROUP_ALERTS) {
        if (!attrs.alertDetails) {
          attrs.alertDetails = {};
        }

        if (attrs?.memberIds) {
          attrs.memberIds = attrs.memberIds.filter((id) => !host.users.getById(id)?.isAlertsUser);
        }
        attrs.memberCount = (attrs.memberIds && attrs.memberIds.length) || 0;
        attrs.members = attrs?.members?.filter((id) => !host.users.getById(id)?.isAlertsUser);

        const messageId = attrs.metadata['alert_id'];
        if (messageId) {
          attrs.alertDetails.messageId = messageId;
          attrs.alertMessageId = messageId;
        }
        attrs.alertDetails.metaType = attrs.metadata['meta_type'];
        attrs.alertDetails.workflowId = attrs.metadata['workflow_id'];

        if (attrs.metadata.alert_payload) {
          let alertPayload;
          try {
            alertPayload = JSON.parse(attrs.metadata.alert_payload);
          } catch (e) {
            console.warn('Invalid metadata payload', entry);
          }

          if (messageId) {
            let message = host.models.Message.get(messageId);
            if (!message) {
              const { id: groupId, organizationId } = attrs;
              const messageAttrs = {
                __organizationId: organizationId,
                counterPartyType: 'group',
                featureService: FeatureService.GROUP_ALERTS,
                id: messageId,
                recipientOrganizationId: organizationId,
                senderOrganizationId: organizationId,
                subType: MessageSubType.ALERTS,
              };

              if (attrs.alertDetails.metaType === 'workflow_group') {
                messageAttrs.counterPartyId = groupId;
                messageAttrs.groupId = groupId;
              }

              message = host.models.Message.injectPlaceholder(messageAttrs);
            }

            host.messages._handleAlertPayload(message, alertPayload);

            message.shouldEnsureRecipientStatus = true;
            host.models.Message.touch(messageId);
          }
        }
      }

      if (attrs.metadata && attrs.metadata['feature_service'] === 'patient_care') {
        attrs.patientDetails = attrs.metadata['patient'];
      }

      if (attrs.metadata && attrs.metadata['feature_service'] === 'patient_messaging') {
        attrs.patientDetails = {
          dob: attrs.metadata['patient_dob'],
          gender: attrs.metadata['patient_gender'],
          id: attrs.metadata['patient_id'],
          isPatientContact: attrs.metadata['is_patient_contact'],
          mrn: attrs.metadata['patient_mrn'],
          name: attrs.metadata['patient_name'],
          phoneNumber: attrs.metadata['phone_number'],
          smsOptedOut: attrs.metadata['sms_opted_out'],
        };

        if (attrs.patientDetails.isPatientContact && attrs.metadata['relation_name']) {
          attrs.patientDetails.relationName = attrs.metadata['relation_name'];
        }
      }

      if (attrs.metadata && attrs.patientContextId) {
        attrs.patientContextDetails = {
          dob: attrs.metadata['patient_context_dob'],
          gender: attrs.metadata['patient_context_gender'],
          mrn: attrs.metadata['patient_context_mrn'],
          firstName: attrs.metadata['patient_context_first_name'],
          lastName: attrs.metadata['patient_context_last_name'],
        };
      }

      if (attrs?.metadata?.team?.token || attrs?.metadata?.team?.id) {
        const isIntraTeam = attrs.metadata.meta_type === 'intra_team';
        const rawTeam = attrs.metadata.team;
        const rawTag = attrs.metadata.tags?.[0];
        const tagId = rawTag ? `${attrs.organizationId}:${rawTag.id}` : undefined;
        const teamId = rawTeam?.id || attrs.metadata.team_token;
        let tag;

        const team = rawTeam && { ...rawTeam, groupId: attrs.id, tags: rawTag ? [rawTag] : [] };
        if (isIntraTeam) {
          team && host.models.Team.inject(team);
          tag = rawTag && host.models.Tag.inject({ ...rawTag, id: tagId });
        } else {
          tag = rawTag && host.models.Tag.inject({ ...rawTag, id: `${attrs.id}:${rawTag.id}` });
        }
        attrs.tagId = tag?.id;
        attrs.teamId = team?.id || teamId;
      } else if (attrs?.metadata?.team_token) {
        attrs.teamId = attrs?.metadata?.team_token;
      }

      if ('metadata' in attrs) delete attrs['metadata'];

      return attrs;
    },

    afterAssignment(entity) {
      const { id, memberIds, organizationId } = entity;
      let conversation;
      if (!host.config.condensedReplays) {
        conversation = organizationId ? host.conversations.get('group', id, organizationId) : null;
      } else if (entity.conversationId) {
        conversation = host.conversations.getById(entity.conversationId);
      }
      let metadata = organizationId ? host.metadata.get(id, organizationId) : null;
      if (metadata) metadata = metadata.data;

      const groupType = host.groups.__extractGroupTypeFromAttrs(entity);
      const displayName =
        metadata && groupType === GroupType.ROLE_P2P ? metadata.target_display_name : entity.name;

      entity.conversation = conversation;
      entity.displayName = displayName;
      entity.groupType = groupType;
      if (conversation?.featureService === FeatureService.GROUP_ALERTS) {
        if (entity?.memberIds) {
          entity.memberIds = entity.memberIds.filter((id) => !host.users.getById(id)?.isAlertsUser);
        }
        entity.memberCount = (entity.memberIds && entity.memberIds.length) || 0;
        entity.members = entity?.members?.filter((id) => !host.users.getById(id)?.isAlertsUser);
      } else {
        entity.memberCount = entity.__memberCount || (memberIds && memberIds.length) || 0;
      }
      entity.metadata = metadata;

      if (groupType !== 'FORUM') {
        delete entity.hasCurrentUser;
      }

      if (groupType !== 'ROLE_P2P') {
        entity.p2pRecipient = null;
        entity.p2pSender = null;
      }

      host.users._setMembersOnEntity({ entity, placeholder: true });
      host.groups._setMembership(entity);
    },

    beforeCreateInstance(attrs) {
      ensureMembersByIds(attrs.id, attrs.memberIds, attrs.organizationId);
    },

    relations: {
      belongsTo: {
        alertMessage: { type: 'message', foreignKey: 'alertMessageId' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        escalationExecution: { type: 'escalationExecution', foreignKey: 'escalationExecutionId' },
        tag: { type: 'tag', foreignKey: 'tagId' },
        team: { type: 'team', foreignKey: 'teamId' },
      },
    },
  });

  // use onlyPlaceholder because groups may be big and we don't want to pre-load all the users
  // let the app decide when to load the users on demand

  function ensureMembersByIds(id, memberIds, organizationId) {
    if (!memberIds || memberIds.length === 0) return;
    host.groups.ensureMembersWithIds(id, memberIds, {
      onlyPlaceholder: true,
      attrs: { organizationId },
    });
  }

  addEnsureEntity(Group, {
    finder: (...args) => host.groups.find(...args),
    placeholderEntityDelayBeforeRefreshing() {
      return host.config.placeholderEntityDelayBeforeRefreshing;
    },
    placeholderEntityAllowLoading() {
      return host.config.placeholderEntityAllowLoading;
    },

    defaultPlaceholderAttrs: { displayName: 'Loading group...' },
    defaultNotFoundAttrs: { displayName: '<Group Not Found>' },

    shouldEnsure(entity, requestedOnlyPlaceholder, { groupMembersOnlyPlaceholder }) {
      return (
        (entity.memberCount > 0 && entity.members && entity.members.length === 0) ||
        !entity.members ||
        !entity.metadata ||
        entity.groupType === GroupType.ROLE_P2P ||
        entity.members.some((m) => host.models.User.shouldEnsure(m, groupMembersOnlyPlaceholder))
      );
    },
  });

  return Group;
}
