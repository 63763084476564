import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';
import BaseService from '../BaseService';
import { difference } from './utils';
import {
  requiredFieldRelationships,
  formatServerOrganizationSettings,
  formatServerOrganizationSettingsWithBoolean,
  parseServerOrganizationSettings,
} from './utils/organizationSettingsHelpers';
import { OrganizationSettings, ResponseServerOrganizationSettings } from './utils/types';

export default class OrganizationsService extends BaseService {
  async createCustomDirectories({
    directories,
    organizationId,
  }: {
    directories: string[];
    organizationId: string;
  }): Promise<unknown> {
    return this.host.api.adminOrganizations.createCustomDirectories({
      directories,
      organizationId,
    });
  }

  async findAndCreateIntegration({ organizationId }: { organizationId: string }): Promise<unknown> {
    const listOfIntegrationIds: unknown[] = [];
    const findResults = await this.host.api.adminOrganizations.findIntegration({
      organizationId,
    });

    if (Array.isArray(findResults)) {
      findResults.forEach((integration) => {
        if (integration.id && integration.partner === 'excel') {
          listOfIntegrationIds.push(integration);
        }
      });
    }

    if (!Array.isArray(findResults) || listOfIntegrationIds.length === 0) {
      return await this.host.api.adminOrganizations.createIntegration({
        organizationId,
      });
    }
  }

  async deleteCustomDirectories({
    directories,
    organizationId,
  }: {
    directories: string[];
    organizationId: string;
  }): Promise<unknown> {
    return this.host.api.adminOrganizations.deleteCustomDirectories({
      directories,
      organizationId,
    });
  }

  async deleteOrganization({ organizationId }: { organizationId: string }): Promise<unknown> {
    return this.host.api.adminOrganizations.deleteOrganization({
      organizationId,
    });
  }

  async createOrganization({
    hasAutoSendWelcomeEmail,
    billing,
    join,
    name,
    orgType,
  }: {
    hasAutoSendWelcomeEmail: boolean;
    billing: string;
    join: boolean;
    name: string;
    orgType: string;
  }): Promise<{ organizationId: string }> {
    return await this.host.api.adminOrganizations.createOrganization({
      hasAutoSendWelcomeEmail,
      billing,
      join,
      name,
      orgType,
    });
  }

  async addEmailDomain({
    orgId,
    domainNames,
  }: {
    orgId: string;
    domainNames: Array<string>;
  }): Promise<unknown> {
    return this.host.api.adminOrganizations.addEmailDomain({ organizationId: orgId, domainNames });
  }

  async getWelcomeEmailSettings({ organizationId }: { organizationId: string }): Promise<{
    autoSendWelcomeEmail: unknown;
    welcomeEmailLogo: string;
    welcomeEmailSettings: {
      from: string;
      optionalHeader: string;
      optionalMessage: string;
      optionalName: string;
      subject: string;
    };
  }> {
    return this.host.api.adminOrganizations.getWelcomeEmailSettings({ organizationId });
  }

  async fetchCustomDirectories({ organizationId }: { organizationId: string }) {
    const { security_groups: directories } =
      await this.host.api.adminOrganizations.fetchCustomDirectories({
        organizationId,
      });

    return directories;
  }

  async saveWelcomeEmailSettings({
    organizationId,
    from,
    subject,
    header: optional_header,
    message: optional_message,
    name: optional_name,
    logo,
    sendTrigger,
  }: {
    organizationId: string;
    from: string;
    subject: string;
    header: string;
    message: string;
    name: string;
    logo: string;
    sendTrigger: 'Manual' | 'Automatic';
  }) {
    const settings = {
      from,
      subject,
      optional_header,
      optional_message: optional_message.replace(
        /&lt;(first_name|last_name|org_name|user_name|email_address)&gt;/g,
        '<$1>'
      ),
      optional_name,
    };
    const data = {
      auto_send_welcome_email: sendTrigger === 'Automatic',
      welcome_email_settings: JSON.stringify(settings),
      ...(logo ? { welcome_email_logo: logo.replace(/.+?base64,/, '') } : null),
    };
    return this.host.api.adminOrganizations.saveWelcomeEmailSettings({ organizationId, data });
  }

  async getWelcomeEmailUnsentCount({ organizationId }: { organizationId: string }) {
    return this.host.api.adminOrganizations.getWelcomeEmailUnsentCount({ organizationId });
  }

  async sendWelcomeEmail({
    organizationId,
    userIds,
    sendUnsent = false,
  }: {
    organizationId: string;
    userIds?: string[];
    sendUnsent?: boolean;
  }): Promise<{ status: number }> {
    return this.host.api.adminOrganizations.sendWelcomeEmail({
      organizationId,
      userIds,
      sendUnsent,
    });
  }

  async getAvailableTwilioNumbers({
    contains,
    countryCode,
  }: {
    contains: string;
    countryCode?: string;
  }): Promise<unknown[]> {
    return this.host.api.adminOrganizations.getAvailableTwilioNumbers({
      contains,
      countryCode,
    });
  }

  async verifyCustomNumber({ organizationId }: { organizationId: string }): Promise<unknown> {
    return this.host.api.adminOrganizations.verifyCustomNumber({
      organizationId,
    });
  }

  async getCustomNumberVerificationCode({
    organizationId,
    status,
    customNumber,
  }: {
    organizationId: string;
    status: string;
    customNumber: string;
  }): Promise<{ validationCode: string }> {
    return this.host.api.adminOrganizations.getCustomNumberVerificationCode({
      organizationId,
      status,
      customNumber,
    });
  }

  async fetchSettings({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<OrganizationSettings> {
    const newSettingsReq = this.host.api.adminOrganizations.fetchSettings({ organizationId });
    const oldSettingsReq = this.host.api.adminOrganizations.fetchSettingsOld({ organizationId });
    const [newSettings, oldSettings] = await Promise.all([newSettingsReq, oldSettingsReq]);
    oldSettings.feature_hide_caller_number = Number(newSettings.hide_caller_number);
    oldSettings.feature_transaction = newSettings.feature_transactions;
    const orgSettings = parseServerOrganizationSettings({
      ...oldSettings,
      ...newSettings,
    });

    return orgSettings;
  }

  async fetchSettingsRaw({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<ResponseServerOrganizationSettings> {
    const newSettingsReq = this.host.api.adminOrganizations.fetchSettings({ organizationId });
    const oldSettingsReq = this.host.api.adminOrganizations.fetchSettingsOld({ organizationId });
    const [newSettings, oldSettings] = await Promise.all([newSettingsReq, oldSettingsReq]);
    oldSettings.feature_hide_caller_number = Number(newSettings.hide_caller_number);
    oldSettings.feature_transaction = newSettings.feature_transactions;
    const orgSettings = {
      ...oldSettings,
      ...newSettings,
    };

    return orgSettings;
  }

  prepareServerSubset(
    oldSettings: OrganizationSettings,
    incomingSettings: OrganizationSettings
  ): Partial<OrganizationSettings> {
    const settingsToSave = difference(oldSettings, incomingSettings);

    Object.entries(requiredFieldRelationships).forEach(([setting, requiredSettings]) => {
      if (isUndefined(settingsToSave[setting])) return;
      Object.assign(settingsToSave, pick(incomingSettings, requiredSettings));
    });

    return settingsToSave;
  }

  async saveSettings({
    organizationId,
    settings,
    logPendoAnalytics,
  }: {
    organizationId: string;
    settings: OrganizationSettings;
    logPendoAnalytics?: (p: { tracker: { name: string } }) => void;
  }): Promise<unknown> {
    let serverSettings;
    const oldSettings = await this.fetchSettings({ organizationId });
    const diffSettings = this.prepareServerSubset(oldSettings, settings);
    const isOrgTypeChange = diffSettings.hasOwnProperty('orgType');

    if (!this.config.allowAlertLifespan) {
      delete diffSettings.alertLifespan;
    }

    if (Object.keys(diffSettings).length === 0) return;
    if (isOrgTypeChange) {
      serverSettings = formatServerOrganizationSettingsWithBoolean(
        settings as OrganizationSettings
      );
    } else {
      serverSettings = formatServerOrganizationSettings(diffSettings as OrganizationSettings);
    }

    try {
      await this.host.api.adminOrganizations.saveSettings({
        organizationId,
        data: serverSettings,
        isOrgTypeChange,
      });

      if (diffSettings.isRolesScheduleUploadEnabled) {
        await this.findAndCreateIntegration({
          organizationId,
        });
      }
      if (diffSettings.hasOwnProperty('isEmojiReactionsEnabled') && logPendoAnalytics) {
        const isEmojiReactionsEnabled = diffSettings.isEmojiReactionsEnabled;
        if (isEmojiReactionsEnabled) {
          logPendoAnalytics({
            tracker: {
              name: `Admin + Settings | Org Settings | Emoji Reaction Enable`,
            },
          });
        } else {
          logPendoAnalytics({
            tracker: {
              name: `Admin + Settings | Org Settings | Emoji Reaction Disable`,
            },
          });
        }
      }
      if (diffSettings.hasOwnProperty('isMentionsEnabled') && logPendoAnalytics) {
        const isMentionsEnabled = diffSettings.isMentionsEnabled;
        if (isMentionsEnabled) {
          logPendoAnalytics({
            tracker: {
              name: `Admin + Settings | Org Settings | Mentions Enable`,
            },
          });
        } else {
          logPendoAnalytics({
            tracker: {
              name: `Admin + Settings | Org Settings | Mentions Disable`,
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async saveAuthSettings({ data }: { data: Record<string, unknown> }) {
    const res = await this.host.api.adminOrganizations.saveAuthSettings({ data });
    return res;
  }

  async getOrganizationSecurityGroups({ orgId }: { orgId: string }) {
    const res = (await this.host.api.adminOrganizations.getOrganizationSecurityGroups({
      orgId,
    })) as { security_groups: string[] };
    return res;
  }

  async getAllPermissionRoles({ orgId }: { orgId: string }) {
    return this.host.api.adminOrganizations.getAllPermissionRoles({ orgId });
  }

  async getEntityCustomDirectories({ orgId, entityId }: { orgId: string; entityId: string }) {
    const data = await this.host.api.adminOrganizations.getEntityCustomDirectories({
      orgId,
      entityId,
    });
    return data.security_groups?.security_group;
  }

  async getOrgCustomDirectories({ orgId }: { orgId: string }) {
    const data = await this.host.api.adminOrganizations.getOrgCustomDirectories({ orgId });
    return data.security_groups;
  }

  async updateEntityCustomDirectories({
    orgId,
    entityId,
    entitySecurityGroups,
  }: {
    orgId: string;
    entityId: string;
    entitySecurityGroups?: string[];
  }) {
    return this.host.api.adminOrganizations.updateEntityCustomDirectories({
      orgId,
      entityId,
      entitySecurityGroups,
    });
  }

  async getAllProductRoles({ orgId }: { orgId: string }) {
    return this.host.api.adminOrganizations.getAllProductRoles({ orgId });
  }

  async createForum({
    memberIds = [],
    name,
    organizationId,
  }: {
    memberIds: Array<string>;
    name: string;
    organizationId: string;
  }) {
    return this.host.api.adminOrganizations.createOrUpdateForum({
      action: 'add',
      members: memberIds.map((id) => ({ token: id, action: 'add' })),
      name,
      organizationId,
      token: '', // TODO
    });
  }

  async updateForum({
    memberIds = [],
    name,
    organizationId,
    forumId,
  }: {
    memberIds: Array<{ token: string; action: string }>;
    name: string;
    forumId: string;
    organizationId: string;
  }) {
    return this.host.api.adminOrganizations.createOrUpdateForum({
      action: 'edit',
      members: memberIds,
      name,
      token: forumId,
      organizationId,
    });
  }

  async deleteForum({ forumId, organizationId }: { forumId: string; organizationId: string }) {
    return this.host.api.adminOrganizations.deleteForum({
      forumId,
      organizationId,
    });
  }
}
