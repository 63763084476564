import { formatDuration } from 'date-fns';

const generateExpirationTime = (expireAt: string) => {
  const oneMinute = 60 * 1000;
  const sixtyMinutes = 60 * oneMinute;
  const twentyFourHours = sixtyMinutes * 24;
  const ttl = new Date(expireAt).getTime() - Date.now();
  const convertTTLToDays = Math.floor(ttl / twentyFourHours);
  const convertTTLToHours = Math.floor(ttl / sixtyMinutes) % 24;
  const convertTTLToMinutes = Math.floor(ttl / oneMinute) % 60;
  const expireInDays = formatDuration({ days: convertTTLToDays }, { format: ['days'] });
  const expireInHours = formatDuration({ hours: convertTTLToHours }, { format: ['hours'] });
  const expireInMinutes = formatDuration({ minutes: convertTTLToMinutes }, { format: ['minutes'] });

  const currentExpirationTime =
    ttl < oneMinute
      ? '< 1 minute'
      : ttl >= oneMinute && ttl < sixtyMinutes
      ? expireInMinutes
      : ttl >= sixtyMinutes && ttl < twentyFourHours
      ? expireInHours
      : expireInDays;

  return currentExpirationTime;
};

export default generateExpirationTime;
