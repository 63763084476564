import { Persona, PersonaCreateFields, PersonaUpdateFields } from '../types/Persona';
import BaseAPI from './BaseAPI';

export default class PersonaAPI extends BaseAPI {
  async findPersonas(): Promise<Persona[]> {
    // const endpoint = `${this.host.tcUrl}/v1/user-service/Personas`;
    // const res = await this.httpClient.get(endpoint);
    return [
      {
        id: '6e129dc6-cfc9-4136-befb-c108b3b48a21',
        titles: ['nurse'],
        permissions: {
          admin: true,
          feature_role_admin: true,
          help_desk: true,
          patient_context_reporting: false,
          patient_data_audit_admin: true,
          patient_data_report_analytics: true,
          patient_messaging_admin: true,
          provider_broadcast_list_admin: true,
          reporting: true,
          team_admin: false,
          tigertouchplus_reporting: true,
          virtual_waiting_room_admin: true,
        },
        organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
        name: 'Persona1',
        description: 'This is a test Persona',
        parentPersonaId: '6e129dc6-cfc9-4136-befb-c108b3b48a232',
      },
      {
        id: '6e129dc6-cfc9-4136-befb-c108b3b48a232',
        titles: ['secretary'],
        permissions: {
          admin: false,
          feature_role_admin: false,
          help_desk: false,
          patient_context_reporting: false,
          patient_data_audit_admin: false,
          patient_data_report_analytics: false,
          patient_messaging_admin: true,
          provider_broadcast_list_admin: true,
          reporting: true,
          team_admin: false,
          tigertouchplus_reporting: true,
          virtual_waiting_room_admin: true,
        },
        organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
        name: 'Persona2',
        description: 'This is a test 2 Persona',
        parentPersonaId: '6e129dc6-cfc9-4136-befb-c108b3b48a21',
      },
    ];
  }

  async findTitles() {
    // const endpoint = `${this.host.tcUrl}/v1/user-service/Titles/GetOrgTitles`;
    // const res = await this.httpClient.get(endpoint);
    return {
      titles: ['nurse', 'physician', 'secretary'],
    };
  }

  async createPersona(payload: PersonaCreateFields): Promise<Persona> {
    // const endpoint = `${this.host.tcUrl}/user-service/Personas`;
    // const res = await this.httpClient.post(endpoint);
    return {
      id: 'randomId',
      organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
      ...payload,
    };
  }

  async updatePersona(personaId: string, payload: PersonaUpdateFields) {
    // const endpoint = `${this.host.tcUrl}/user-service/Personas/{personaId}`;
    // const res = await this.httpClient.patch(endpoint);
    return {
      id: personaId,
      titles: payload.titles,
      permissions: payload.permissions,
      organizationId: '12329dc6-cfc9-4136-befb-c108b3b48123',
      name: payload.name,
      description: 'This is a test Persona',
      parentPersonaId: payload.parentPersonaId,
    };
  }
}
