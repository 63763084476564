import React, { useEffect, useState } from 'react';

import { NetworkType } from '../../../../types';
import ProviderBroadcastListView from '../../BroadcastListSettings/ProviderBroadcastListView';
import { mobxInjectSelect } from '../../../utils';
import { PatientBroadcastForm, BroadcastListsView } from '.';

const ACTIONS = {
  CREATE: 'create',
  EDIT: 'edit',
  SEARCH: 'search',
  VIEW: 'view',
};

type BroadcastListsProps = {
  isAdmin: boolean;
  network?: NetworkType;
  providerNetworkCustomDirectories?: string[];
  refreshProviderNetworkCustomDirectories?: () => void;
  setCurrentAdminPageRefresher: (fn: () => void) => void;
  setAppResetter?: (fn: () => void) => void;
};

type MobxProps = {
  logPendoAnalytics: (p: { parentPathName: string; pathName: string }) => void;
};

const BroadcastLists: React.FC<BroadcastListsProps & MobxProps> = ({
  isAdmin,
  network,
  setAppResetter = () => {},
  setCurrentAdminPageRefresher,
  providerNetworkCustomDirectories = [],
  refreshProviderNetworkCustomDirectories,
  logPendoAnalytics,
}) => {
  const [action, setAction] = useState(ACTIONS.SEARCH);
  const [id, setId] = useState<string>('');
  const [isADSync, setIsADSync] = useState(false);

  useEffect(() => {
    setAppResetter(searchBroadcastLists);
    return () => setAppResetter(() => {});
  }, [setAppResetter]);

  const createBroadcastList = () => {
    let parentPathName = 'Broadcast Lists';
    let pathName = 'Create Provider Broadcast List';
    setIsADSync(false);
    setId('');
    setAction(ACTIONS.CREATE);

    if (network !== 'provider') {
      parentPathName = 'Patient Settings';
      pathName = 'Create Patient Broadcast List';
    }

    logPendoAnalytics({
      parentPathName,
      pathName,
    });
  };

  const editBroadcastList = (id: string) => {
    setId(id);
    setAction(ACTIONS.EDIT);
  };

  const searchBroadcastLists = () => {
    setId('');
    setAction(ACTIONS.SEARCH);
  };

  const setMessageTemplateAdminPageRefresher = (fn: () => void) => {
    setCurrentAdminPageRefresher(() => {
      searchBroadcastLists();
      fn();
    });
  };

  switch (action) {
    case ACTIONS.CREATE:
    case ACTIONS.EDIT:
      if (network === 'provider') {
        return (
          <ProviderBroadcastListView
            action={action}
            id={id}
            isAdmin={isAdmin}
            isADSync={isADSync}
            isPregen={id.includes('@')}
            setIsADSync={setIsADSync}
            viewLists={searchBroadcastLists}
            customDirectories={providerNetworkCustomDirectories}
          />
        );
      }
      return (
        <PatientBroadcastForm
          id={id}
          isAdmin={isAdmin}
          searchBroadcastLists={searchBroadcastLists}
        />
      );
    case ACTIONS.SEARCH:
      return (
        <BroadcastListsView
          createBroadcastList={createBroadcastList}
          isADSync={isADSync}
          setIsADSync={setIsADSync}
          editBroadcastList={editBroadcastList}
          isAdmin={isAdmin}
          setMessageTemplateAdminPageRefresher={setMessageTemplateAdminPageRefresher}
          refreshProviderNetworkCustomDirectories={refreshProviderNetworkCustomDirectories}
        />
      );
    case ACTIONS.VIEW:
      return (
        <div>
          Not yet implemented {action} {id}
        </div>
      );
    default:
      return <></>;
  }
};

export default mobxInjectSelect<BroadcastListsProps, MobxProps>({
  trackerStore: ['logPendoAnalytics'],
})(BroadcastLists);
