import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ToolTip from '@material-ui/core/Tooltip';

function setSize(size) {
  switch (size) {
    case 'small':
      return 30;
    case 'medium':
      return 40;
    case 'large':
      return 50;
    default:
      return 30;
  }
}

const Button = styled.button`
  height: ${({ size }) => `${setSize(size)}px`} !important;
  width: ${({ size }) => `${setSize(size)}px`} !important;
  border-radius: 50% !important;
  background-color: ${({ backgroundColor }) => backgroundColor} !important;
  margin: ${({ marginButton }) => marginButton} !important;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')} !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  &:hover {
    ${({ disabled }) => !disabled && 'box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);'}
    ${({ hoverColor }) => hoverColor && `background-color: ${hoverColor} !important;`}
  }
`;

const ToolTipContainer = styled.div`
  div.MuiTooltip-popper > div.MuiTooltip-tooltip {
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#35333c')};
  }
`;

export default function Fab({
  children,
  dataTestId,
  onClick,
  isInactive,
  isEnabled,
  hoverColor,
  isDisabled,
  title,
  backgroundColorInactive,
  placement,
  marginButton,
  backgroundColorOn,
  backgroundColorOff,
  tooltipBackGroundColor,
  size,
}) {
  const backgroundColor = isInactive
    ? backgroundColorInactive
    : isEnabled
    ? backgroundColorOn
    : backgroundColorOff;
  const hover = isInactive ? backgroundColorInactive : hoverColor;

  const popperProps = {
    disablePortal: true,
  };
  if (isInactive) {
    // Immediately close open tooltips when inactive
    popperProps.open = false;
  }

  return (
    <ToolTipContainer backgroundColor={tooltipBackGroundColor}>
      <ToolTip
        title={title}
        disableHoverListener={isInactive}
        placement={placement}
        PopperProps={popperProps}
      >
        <Button
          onClick={onClick}
          data-test-id={dataTestId}
          disabled={isDisabled}
          backgroundColor={backgroundColor}
          hoverColor={hover}
          marginButton={marginButton}
          size={size}
        >
          {children}
        </Button>
      </ToolTip>
    </ToolTipContainer>
  );
}

Fab.propTypes = {
  backgroundColorOff: PropTypes.string,
  backgroundColorOn: PropTypes.string,
  hoverColor: PropTypes.string,
  isDisabled: PropTypes.bool,
  isEnabled: PropTypes.bool,
  isInactive: PropTypes.bool,
  onClick: PropTypes.func,
  marginButton: PropTypes.string,
  title: PropTypes.string.isRequired,
  tooltipBackGroundColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Fab.defaultProps = {
  backgroundColorInactive: '#B7B7B7',
  backgroundColorOff: '#479EB4',
  backgroundColorOn: '#4C4C4C',
  hoverColor: '#313131',
  marginButton: '0 10px',
  placement: 'top',
  size: 'medium',
};
