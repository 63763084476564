// @ts-nocheck
import Camelizer from '../utils/Camelizer';

export default function (store, { host }) {
  const Call = store.defineModel('call', {
    replaceKeys: {},
    defaultValues: {},
    skipCamelizationForKeys: ['membersStatuses', 'payload', 'room'],
    transientAttrs: ['room'],

    parseAttrs(attrs) {
      if (!attrs.id) {
        attrs.id = attrs.roomName;
      }

      attrs.membersStatuses = attrs.membersStatuses || {};

      if (attrs.payload) {
        let disabledParticipants = {};
        if (attrs.payload.disabled_participants) {
          disabledParticipants = attrs.payload.disabled_participants;
          delete attrs.payload.disabled_participants;
        }
        if (attrs.payload.disabledParticipants) {
          disabledParticipants = attrs.payload.disabledParticipants;
        }
        attrs.payload = Camelizer.camelizeObject(attrs.payload);
        attrs.payload.disabledParticipants = disabledParticipants;
      }

      if (attrs.payload) {
        if (attrs.payload.orgId) attrs.organizationId = attrs.payload.orgId;
        if (attrs.payload.callId) attrs.callId = attrs.payload.callId;
        if (attrs.payload.groupId) attrs.groupId = attrs.payload.groupId;
        if (attrs.payload.participantsTokens && attrs.payload.callerId) {
          attrs.memberIds = [...attrs.payload.participantsTokens, attrs.payload.callerId];
        }
      }

      delete attrs.serverId;

      return attrs;
    },
  });

  return Call;
}
