// @ts-nocheck
export default function (store, options) {
  const Metadata = store.defineModel('metadata', {
    parseAttrs(attrs, origAttrs) {
      attrs.data = origAttrs['data'];
      return attrs;
    },

    relations: {
      belongsTo: {
        organization: { type: 'organization', foreignKey: 'organizationId' },
      },
    },
  });

  return Metadata;
}
