// @ts-nocheck
import _ from 'lodash';
import * as modelFactories from '../models/storeModels';
import { Camelizer, replaceKeys } from '../utils';
import Store from './Store';

export default class TCStore {
  constructor(host, { logger, ...options } = {}) {
    this.host = host;
    this.options = options;
    this.logger = logger;

    this.store = new Store({
      ...options,
      host,
      logger,
    });

    this.store.host = host;
  }

  defineModels() {
    const models = {};

    const passedOptions = {
      ...this.options,
      host: this.host,
      logger: this.logger,
    };

    _.each(modelFactories, (modelFactory, name) => {
      models[name] = modelFactory(this, passedOptions);
    });

    this.models = models;
  }

  defineModel(name, options = {}) {
    _.defaults(options, {
      replaceKeys: {},
      skipCamelizationForKeys: null,
    });
    _.defaults(options.replaceKeys, { token: 'id' });

    const model = this.store.defineModel(name, {
      ...options,

      afterInject(entity) {
        let now = Date.now();
        if (entity.$lastModified === now) now += 0.00001;
        entity.$lastModified = now;

        options.afterInject && options.afterInject(entity);
      },

      parseAttrs(attrs) {
        let newAttrs;

        if (!_.isEmpty(options.skipCamelizationForKeys)) {
          const attrsToCamelize = _.omit(attrs, options.skipCamelizationForKeys);
          const skippedAttrs = _.pick(attrs, options.skipCamelizationForKeys);
          newAttrs = {
            ...Camelizer.camelizeObject(attrsToCamelize),
            ...skippedAttrs,
          };
        } else {
          newAttrs = Camelizer.camelizeObject(attrs);
        }

        replaceKeys(newAttrs, options.replaceKeys);

        if (options.parseAttrs) newAttrs = options.parseAttrs(newAttrs, attrs);

        return newAttrs;
      },
    });

    return model;
  }

  clear() {
    this.store.clear();
  }
}
