import { ServerValueEnum } from '../../utils/enum';

const ProcessingActionTypes = new ServerValueEnum({
  CONVERSATION_MESSAGE_STATUS: { serverValue: 'conversation_message_status' },
  GROUP_REPLAY: { serverValue: 'group_replay' },
  MESSAGE_REPLAY: { serverValue: 'message_replay' },
  OFFLINE: { serverValue: 'offline' },
  ORGANIZATION_MESSAGE_STATUS: { serverValue: 'organization_message_status' },
  ORGANIZATION_REPLAY: { serverValue: 'organization_replay' },
  ROLE_OPT_IN: { serverValue: 'optin' },
  ROLE_OPT_OUT: { serverValue: 'optout' },
} as const);

export default ProcessingActionTypes;
