import React from 'react';
import { observer } from 'mobx-react';
import BEM from '../../../../common/bem';

const classes = BEM.with('RosterFilterBarButton');

type RosterFilterBarButtonProps = {
  children: React.ReactElement;
  dataTestId: string;
  isSelected?: boolean;
  onClick: () => void;
  showUnreadCount?: boolean;
  useSelectedLine?: boolean;
};

type MobxProps = {};

function RosterFilterBarButton({
  children,
  dataTestId,
  isSelected = false,
  onClick,
  showUnreadCount = false,
  useSelectedLine = false,
}: RosterFilterBarButtonProps & MobxProps) {
  return (
    <div
      className={classes('', {
        isSelected,
        showUnreadCount,
        useSelectedLine,
      })}
      data-test-id={dataTestId}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default observer(RosterFilterBarButton);
