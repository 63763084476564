import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep, partial } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ObservableArray } from 'mobx/lib/internal';
import { ReduxState, actions, thunk } from '../../../redux-stores';
import { Organization } from '../../../types';
import BEM from '../../bem';
import { mobxInjectSelect } from '../../utils';
import { CollaborationCategories } from '../../../models/enums';
import { CollaborationCategory } from '../../../models/enums/CollaborationCategories';
import Entities from './Entities/Entities';
import Tags from './Tags/Tags';
import OrgSelectorModal from './Modals/OrgSelectorModal';

const {
  clearUserSelections,
  selectEntity,
  selectTagCategory,
  setAdminStatus,
  setCurrentOrganization,
  setFeatureEnablementStatus,
  setMyRoles,
  setMyTeams,
  setIsAdminOrgSelectorOpen,
  setSelectedOrganization,
  updateSavedEntityIds,
} = actions;
const {
  createEventListeners,
  destroyEventListeners,
  fetchTotals,
  handleFriendsEvent,
  handleRoleChangeEvent,
  handleSaveTagEvent,
  loadColors,
} = thunk;
const { MYROLES, MYTEAMS } = CollaborationCategories;

const classes = BEM.with('Collaboration');

type MobxProps = {
  closeModal: () => void;
  activeTeamIds: ObservableArray<string>;
  currentOrganizationId: string;
  currentRolesIds: string[];
  getAdminRolesInOrganization: (orgId: string) => { [k: string]: boolean };
  getOrganizationById: (orgId: string) => Organization;
  loadActiveTeamIds: (orgId: string) => void;
  isAdminOrgSelectorOpen: boolean;
  collabSelection?: CollaborationCategory;
  collabSelectionId?: string;
  savedRoleIds: ObservableArray<string>;
  savedTeamIds: ObservableArray<string>;
  organizations: Organization[];
  unsetCollabSelection: () => void;
};

function Collaboration({
  currentOrganizationId,
  currentRolesIds,
  activeTeamIds,
  collabSelection,
  collabSelectionId,
  getAdminRolesInOrganization,
  getOrganizationById,
  loadActiveTeamIds,
  isAdminOrgSelectorOpen,
  savedRoleIds,
  savedTeamIds,
  organizations,
  unsetCollabSelection,
}: MobxProps) {
  const dispatch = useDispatch();
  const savedTeamsInitialized = useRef(false);
  const savedRolesInitialized = useRef(false);
  const activeTab = useSelector((state: ReduxState) => state.entities.activeTab);
  const isRolesEnabled = useSelector((state: ReduxState) => state.collab.isRolesEnabled);
  const isTeamsEnabled = useSelector((state: ReduxState) => state.collab.isTeamsEnabled);
  const selectedOrgId = useSelector((state: ReduxState) => state.collab.selectedOrgId);
  const selectedCategory = useSelector((state: ReduxState) => state.tags.selectedCategory);
  const currentOrganization = useSelector((state: ReduxState) => state.collab.currentOrganization);
  const hasFetchedTotals = useSelector((state: ReduxState) => state.entities.hasFetchedTotals);
  const [mobxOrg, setMobxOrg] = useState<Organization>();

  const setEnablementAndPermissions = useCallback(
    (orgId: string) => {
      const { isRolesAdmin, isTeamAdmin } = getAdminRolesInOrganization(orgId);
      const {
        canAdminRoles = false,
        rolesEnabled = false,
        showTeams = false,
      } = getOrganizationById(orgId) || {};
      const isRolesEnabled = rolesEnabled && canAdminRoles;
      dispatch(
        setAdminStatus({
          isRolesAdmin: isRolesEnabled && isRolesAdmin,
          isTeamAdmin: showTeams && isTeamAdmin,
        })
      );
      dispatch(
        setFeatureEnablementStatus({
          rolesEnabled: isRolesEnabled,
          showTeams,
        })
      );
      return {
        rolesEnabled: isRolesEnabled,
        showTeams,
      };
    },
    [dispatch, getAdminRolesInOrganization, getOrganizationById]
  );

  const savedEntityIdsUpdated = useCallback(
    async ({ type, ids }: { type: string; ids: string[] }) => {
      if (
        (type === 'role' && selectedCategory === MYROLES) ||
        (type === 'team' && selectedCategory === MYTEAMS)
      ) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        dispatch(updateSavedEntityIds(ids));
      }
    },
    [dispatch, selectedCategory]
  );

  useEffect(() => {
    hasFetchedTotals && unsetCollabSelection();
  }, [hasFetchedTotals, unsetCollabSelection]);

  useEffect(() => {
    if (collabSelection) {
      dispatch(selectTagCategory(collabSelection));
      if (collabSelection === MYTEAMS && collabSelectionId) {
        dispatch(selectEntity({ $entityType: 'team', id: collabSelectionId }));
      } else {
        dispatch(selectEntity(undefined));
      }
      hasFetchedTotals && unsetCollabSelection();
    }
  }, [
    activeTab,
    collabSelection,
    collabSelectionId,
    dispatch,
    hasFetchedTotals,
    unsetCollabSelection,
  ]);

  useEffect(() => {
    if (!mobxOrg) return;
    const { id, name } = mobxOrg;
    if (currentOrganization?.id === mobxOrg?.id) return;
    dispatch(setSelectedOrganization({ id, name }));
    dispatch(clearUserSelections(collabSelection));
    setEnablementAndPermissions(id);
    dispatch(setCurrentOrganization(cloneDeep(mobxOrg)));
  }, [collabSelection, dispatch, mobxOrg, currentOrganization, setEnablementAndPermissions]);

  useEffect(() => {
    const newMobxOrg = organizations.find((o) => o.id === currentOrganizationId);
    newMobxOrg && setMobxOrg({ ...newMobxOrg });
  }, [currentOrganizationId, organizations]);

  useEffect(() => {
    if (!selectedOrgId) return;
    const { showTeams } = setEnablementAndPermissions(selectedOrgId);
    loadColors(dispatch, selectedOrgId);
    fetchTotals(dispatch, selectedOrgId);
    showTeams && loadActiveTeamIds(selectedOrgId);
  }, [selectedOrgId, dispatch, setEnablementAndPermissions, loadActiveTeamIds]);

  useEffect(() => {
    const ids = cloneDeep(savedRoleIds.toJSON());
    dispatch(setMyRoles({ savedRoleIds: ids }));
    savedRolesInitialized.current && savedEntityIdsUpdated({ type: 'role', ids });
    savedRolesInitialized.current = true;
  }, [dispatch, savedEntityIdsUpdated, savedRoleIds]);

  useEffect(() => {
    const ids = cloneDeep(savedTeamIds.toJSON());
    dispatch(setMyTeams({ savedTeamIds: ids }));
    savedTeamsInitialized.current && savedEntityIdsUpdated({ type: 'team', ids });
    savedTeamsInitialized.current = true;
  }, [dispatch, savedEntityIdsUpdated, savedTeamIds]);

  useEffect(() => {
    dispatch(setMyTeams({ activeTeamIds: cloneDeep(activeTeamIds.toJSON()) }));
  }, [dispatch, activeTeamIds]);

  useEffect(() => {
    dispatch(setMyRoles({ activeRoleIds: currentRolesIds }));
  }, [dispatch, currentRolesIds]);

  useEffect(() => {
    dispatch(setIsAdminOrgSelectorOpen(isAdminOrgSelectorOpen));
  }, [dispatch, isAdminOrgSelectorOpen]);

  useEffect(() => {
    if (!selectedOrgId) return;
    const fns = {
      friends: partial(handleFriendsEvent, dispatch, selectedOrgId),
      'role:change': partial(handleRoleChangeEvent, dispatch, selectedOrgId),
      'tag:saved': partial(
        handleSaveTagEvent,
        dispatch,
        selectedOrgId,
        isRolesEnabled,
        isTeamsEnabled
      ),
    };
    createEventListeners(fns);
    return () => destroyEventListeners(fns);
  }, [dispatch, isRolesEnabled, isTeamsEnabled, selectedOrgId]);

  return (
    <div className={classes()}>
      <Tags />
      <Entities />
      <OrgSelectorModal />
    </div>
  );
}

export default mobxInjectSelect<{}, MobxProps>({
  messengerStore: [
    'currentOrganizationId',
    'getAdminRolesInOrganization',
    'isAdminOrgSelectorOpen',
    'organizations',
    'collabSelection',
    'collabSelectionId',
    'unsetCollabSelection',
  ],
  organizationStore: ['getOrganizationById'],
  roleStore: ['currentRolesIds', 'savedRoleIds'],
  teamStore: ['activeTeamIds', 'loadActiveTeamIds', 'savedTeamIds'],
})(Collaboration);
