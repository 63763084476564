// @ts-nocheck
import MessageRecipientStatus, { MESSAGE_STATUS_ORDER } from './enums/MessageRecipientStatus';

export default function (store) {
  const MessageStatusPerRecipient = store.defineModel('messageStatusPerRecipient', {
    replaceKeys: {
      accountId: 'userId',
      timestamp: 'createdAt',
    },

    defaultValues: {
      reaction: '',
      status: MessageRecipientStatus.NA,
    },

    parseAttrs(attrs) {
      if (attrs.status) attrs.status = MessageRecipientStatus.resolve(attrs.status);

      if (attrs.createdAt && typeof attrs.createdAt === 'string') {
        attrs.createdAt = new Date(attrs.createdAt);
      }

      if (attrs.reactionTimestamp && typeof attrs.reactionTimestamp === 'string') {
        attrs.reactionTimestamp = new Date(attrs.reactionTimestamp);
      }

      return attrs;
    },

    beforeInject(attrs, existingEntity) {
      if (existingEntity) {
        const currentStatus = existingEntity.status;
        const incomingStatus = attrs.status;

        if (MESSAGE_STATUS_ORDER[incomingStatus] < MESSAGE_STATUS_ORDER[currentStatus]) {
          delete attrs.createdAt;
          delete attrs.status;
        }
      }
    },

    relations: {
      belongsTo: {
        distributionList: { type: 'distributionList', foreignKey: 'distributionListId' },
        message: { type: 'message', foreignKey: 'messageId' },
        user: { type: 'user', foreignKey: 'userId' },
      },
    },
  });

  return MessageStatusPerRecipient;
}
