// @ts-nocheck
export default function (store, { host }) {
  const GroupMembersChange = store.defineModel('groupMembersChange', {
    parseAttrs(attrs) {
      if ('actionTime' in attrs && typeof attrs.actionTime === 'string') {
        attrs.actionTime = new Date(attrs.actionTime);
      }

      return attrs;
    },
  });

  return GroupMembersChange;
}
