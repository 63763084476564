// @ts-nocheck
import { Camelizer } from '../utils';

export default function (store, { host }) {
  const ScheduledMessage = store.defineModel('scheduledMessage', {
    replaceKeys: {
      createdBy: 'createdById',
      recipient: 'recipientId',
      recipientType: '_recipientType',
      senderToken: 'senderId',
      scheduleStatus: 'status',
      updatedBy: 'updatedById',
    },

    transientAttrs: ['$deleted'],

    parseAttrs(attrs) {
      attrs.hasAttachment = false;
      if (attrs.attachmentName && attrs.attachmentSize && attrs.attachmentType) {
        attrs.hasAttachment = true;
      }

      if (attrs._recipientType) {
        attrs.recipientType =
          host.modelsByEntityType[Camelizer.camelizeKey(attrs._recipientType)].name;
      }

      return attrs;
    },

    afterAssignment(entity) {
      let { recipient, recipientId, recipientType } = entity;

      if (recipient) {
        recipientId = recipient.id;
        recipientType = recipient.$entityType;
      } else {
        recipient =
          recipientType && recipientId ? store.store.get(recipientType, recipientId) : null;
      }

      entity.recipient = recipient;
      entity.recipientId = recipientId;
      entity.recipientType = recipientType;
    },

    relations: {
      belongsTo: {
        createdBy: { type: 'user', foreignKey: 'createdById' },
        organization: { type: 'organization', foreignKey: 'organizationId' },
        sender: { type: 'user', foreignKey: 'senderId' },
        template: { type: 'messageTemplate', foreignKey: 'templateId' },
        updatedBy: { type: 'user', foreignKey: 'updatedById' },
      },
    },
  });

  return ScheduledMessage;
}
