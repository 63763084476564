import React from 'react';
import { observer } from 'mobx-react';
import { Picker } from 'emoji-mart';
import type { BaseEmoji } from 'emoji-mart';
import BEM from '../../bem';
import { Dropdown } from '../WebComponents';

const classes = BEM.with('EmojiPickerPortal');

type EmojiPickerPortalProps = {
  isOpen: boolean;
  onEmojiSelected: (emoji: BaseEmoji) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const EmojiPickerPortal = ({ isOpen, onEmojiSelected, setIsOpen }: EmojiPickerPortalProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={classes()}
      style={{ position: 'absolute', bottom: 70, right: 12 }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      {isOpen && (
        <div tabIndex={0} onKeyDown={handleKeyDown}>
          <Dropdown triggerHandler={() => setIsOpen(!isOpen)}>
            <Picker
              autoFocus
              emoji="point_up"
              native
              onClick={onEmojiSelected}
              set="twitter"
              showPreview={false}
              title="Pick your emoji…"
            />
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default observer(EmojiPickerPortal);
