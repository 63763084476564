// @ts-nocheck
import BaseAPI from './BaseAPI';

export default class NotificationsAPI extends BaseAPI {
  async closeAllConnections(key, secret) {
    const headers = this.httpClient.getAuthHeaders({ key, secret });
    await this.httpClient.del('/v2/events/simple_notifications', { headers });
  }

  async signIn() {
    const res = await this.host.httpClient.post('/v2/events/simple_notifications/key');
    const key = res.getHeader('TT-X-Api-Key');
    const secret = res.getHeader('TT-X-Api-Secret');
    return { key, secret };
  }

  async signOut(key, secret) {
    const headers = this.httpClient.getAuthHeaders({ key, secret });
    return this.httpClient.post('/v2/logout', { headers });
  }
}
