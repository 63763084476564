import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BEM from '../../../../common/bem';
import { EntityAvatar, PatientSearchResultHeader } from '../../../../common/components';
import { getRecipientEntity, mobxInjectSelect } from '../../../../common/utils';
import { RoleConversationHeader } from './';

const classes = BEM.with('NewMessageSearchResult');

class NewMessageSearchResult extends Component {
  static propTypes = {
    composeEntity: PropTypes.shape({
      displayName: PropTypes.string,
    }).isRequired,
    isTeamActivation: PropTypes.bool,
  };

  render() {
    const { composeEntity, isTeamActivation } = this.props;
    const { groupType, isPatient, isPatientContact } = composeEntity;

    let header;
    if (isPatient || isPatientContact) {
      header = (
        <div className={classes()}>
          <PatientSearchResultHeader avatarSize={42} entity={composeEntity} />
        </div>
      );
    } else if (groupType === 'ROLE_P2P') {
      const { conversation } = composeEntity;
      const { counterParty, counterPartyType } = conversation;
      const componentOptions = {};
      const { entity, entityType } = getRecipientEntity({
        entity: counterParty,
        entityType: counterPartyType,
      });
      if (entityType) {
        componentOptions[entityType] = entity;
        componentOptions.user =
          entity.members && entity.members.length === 1 ? entity.members[0] : entity.botUser;
        header = <RoleConversationHeader {...componentOptions} />;
      } else {
        header = null;
      }
    } else if (composeEntity.$entityType === 'role') {
      const { botUser, members } = composeEntity;
      header = (
        <RoleConversationHeader
          user={members && members.length === 1 ? members[0] : botUser}
          role={composeEntity}
        />
      );
    } else {
      header = (
        <div className={classes()}>
          <EntityAvatar entity={composeEntity} size="fit" showPresenceIndicator={true} />
          <span className={classes('name')}>
            {composeEntity.displayName}
            {isTeamActivation ? ' ####' : ''}
          </span>
        </div>
      );
    }

    return header;
  }
}

export default mobxInjectSelect({
  composeMessageStore: ['composeEntity', 'isTeamActivation'],
})(NewMessageSearchResult);
