// @ts-nocheck
import BaseAPI, { recoverFromNotFound } from './BaseAPI';

const TRANSACTION_RETURN_FIELDS = [
  'messageId',
  'sender.name',
  'sender.title',
  'sender.department',
  'sender.mrn',
  'senderRoleDisplayName',
  'recipient.name',
  'recipient.title',
  'recipient.department',
  'recipient.mrn',
  'recipientRoleDisplayName',
  'group.name',
  'distributionList.name',
  'sentTimestamp',
  'attachment.hasAttachment',
  'recalledTimestamp',
  'forwardedMessageId',
  'expireTimestamp',
  'dndAutoForwardedFrom',
];

const DELIVERED_TIMESTAMP_SCRIPT = {
  script:
    "(doc['deliveredTimestamp'].empty ? " +
    "'' : _source.deliveredTimestamp < _source.sentTimestamp ? " +
    '_source.sentTimestamp : _source.deliveredTimestamp)',
};

const READ_TIMESTAMP_SCRIPT = {
  script:
    "(doc['readTimestamp'].empty ? " +
    "'' :_source.readTimestamp < _source.sentTimestamp ? " +
    '_source.sentTimestamp : _source.readTimestamp)',
};

const MULTI_MATCH_FIELDS = [
  'sender.title',
  'recipient.title',
  'sender.department',
  'sender.mrn',
  'recipient.mrn',
  'senderRoleDisplayName',
  'recipient.department',
  'recipientRoleDisplayName',
  'sender.username',
  'recipient.username',
  'sender.verifiedEmails',
  'recipient.verifiedEmails',
  'sender.unverifiedEmails',
  'recipient.unverifiedEmails',
  'sender.verifiedPhones',
  'recipient.verifiedPhones',
  'sender.unverifiedPhones',
  'recipient.unverifiedPhones',
  'group.groupId',
  'group.name',
  'distributionList.distributionListId',
  'distributionList.name',
  'forwardedMessageId',
  'messageId',
];

export default class ReportingAPI extends BaseAPI {
  @recoverFromNotFound()
  async fetchTransactions(
    organizationId: string,
    {
      page,
      size,
      startDate,
      endDate,
      query,
    }: {
      page: number;
      size: number;
      startDate: string;
      endDate: string;
      query: string;
    }
  ) {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const from = page * size - size;
    const orgId = organizationId;
    const queryString = query;
    const queryStringFormatted = queryString && query.split(' ').join('*');
    const queryBoolMust = [
      {
        range: {
          sentTimestamp: {
            from: startDate,
            to: endDate,
          },
        },
      },
      {
        match: {
          'sender.organization.orgId': orgId,
        },
      },
      {
        constant_score: {
          filter: {
            missing: {
              field: 'message.hideMessage',
            },
          },
        },
      },
      {
        bool: {
          should: [
            {
              query_string: {
                query: '*' + queryStringFormatted + '*',
                fields: ['sender.name', 'recipient.name'],
              },
            },
            {
              multi_match: {
                query: queryString,
                fields: MULTI_MATCH_FIELDS,
              },
            },
          ],
        },
      },
    ];

    const transactions = await this.httpClient.post('/v2/report/messages', {
      headers,
      data: {
        from: from,
        size: size,
        fields: TRANSACTION_RETURN_FIELDS,
        script_fields: {
          deliveredTimestamp: DELIVERED_TIMESTAMP_SCRIPT,
          readTimestamp: READ_TIMESTAMP_SCRIPT,
        },
        sort: {
          sentTimestamp: {
            order: 'desc',
          },
        },
        query: {
          bool: {
            must: queryBoolMust,
          },
        },
      },
    });

    return transactions;
  }

  generateLookerEmbed = async (organizationId: string, dashboard_type: string) => {
    const headers = { 'TT-X-Organization-Key': organizationId };
    const { data } = await this.httpClient.post('/v2/looker', {
      headers,
      data: {
        dashboard_type,
      },
    });

    return data && data.looker_url;
  };
}
