import { action, computed, observable, runInAction } from 'mobx';
import _ from 'lodash';
import uuid from 'uuid';
import { saveAs } from 'file-saver';
import { LOGIN_ERROR_TO_MESSAGE } from '../models/enums/LoginErrors';
import DATitles from '../models/enums/DATitles';
import { settingsObjectToValue } from '../common/utils/daConfigConvert';
import { Bounce, DesktopClients, NotificationContext, SignInStep } from '../models/enums';
import TigerConnectLogo from '../common/images/tigerconnect-logo.png';

const DEFAULT_ORGANIZATION = {
  priorityMessagingSounds: false,
};

const ALERT_SOUNDS = [
  '1_beep_loud.m4a',
  '1_beep_mild.m4a',
  '1_beep_soft.m4a',
  '1_beep.m4a',
  '10_beep.m4a',
  '3_beep_loud.m4a',
  '3_beep_mild.m4a',
  '3_beep_soft.m4a',
  '3_beep.m4a',
  '5_beep_loud.m4a',
  '5_beep_mild.m4a',
  '5_beep_soft.m4a',
  '5_beep.m4a',
  'alarm.m4a',
  'clinical_alert_critical_loud.m4a',
  'clinical_alert_critical_mild.m4a',
  'clinical_alert_critical_soft.m4a',
  'clinical_alert_critical_tone.m4a',
  'clinical_alert_high_loud.m4a',
  'clinical_alert_high_mild.m4a',
  'clinical_alert_high_soft.m4a',
  'clinical_alert_high_tone.m4a',
  'clinical_alert_low_tone.m4a',
  'clinical_alert_medium_tone.m4a',
  'code_alert_1.m4a',
  'low_alert_1.m4a',
  'medium_alert_1.m4a',
  'medium_high_1.m4a',
  'medium_high_2.m4a',
  'medium_high_3.m4a',
  'medium_high_4.m4a',
  'medium_high_5.m4a',
  'siren.m4a',
];

const SOUND_OPTIONS = [
  'purr.mp3', // Default
  'beep.mp3',
  'loud.mp3',
  'pager.mp3',
  'ping.mp3',
];
const SOUND_OPTIONS_DEFAULT = 0;

const isIframe = window.location !== window.parent.location;

const clientType =
  !isIframe && window.parent && window.parent.isElectron
    ? DesktopClients.ELECTRON
    : window.notificationHandler
    ? DesktopClients.DOTNET
    : DesktopClients.NONE;

const desktopAppVersion =
  clientType === DesktopClients.ELECTRON
    ? window.parent.DAVersion
    : clientType === DesktopClients.DOTNET
    ? window.notificationHandler.dAVersion
    : '';

const isDesktopApp = clientType !== DesktopClients.NONE;

const flushData = () => {
  if (window.parent.storage && window.parent.storage.flushData) {
    window.parent.storage.flushData();
  }
};

export default class DesktopAppStore {
  clientType = clientType;
  customSoundOptions = SOUND_OPTIONS;
  desktopAppVersion = desktopAppVersion;
  isAutoUpdateEnabled = null;
  isDaDisconnect = true;
  isDesktopApp = isDesktopApp;
  isPrioritySoundPlaying = false;
  loginSuccess = null;
  logoutSuccess = null;
  noise = null;
  setDATitle = null;

  @observable bounceDockIcon = isDesktopApp ? Bounce.UNREAD : Bounce.NEVER;
  @observable customSound = SOUND_OPTIONS_DEFAULT;
  @observable playSounds = isDesktopApp;
  @observable showMessageBody = false;
  @observable showNotifications = isDesktopApp;
  @observable toastTimeout = 0;
  @observable.shallow openNotifications = [];
  @observable vwrNotification = null;

  // NOTE: The following settings are controlled by feature flags exposed by both Mac and Windows DAs
  disableAndroidAppLink = false;
  exposesWindowState = false;
  supportsAudioInput = false;
  supportsCustomSounds = false;
  supportsIdp = false;
  supportsInlinePdf = false;
  supportsVideoInput = false;

  constructor({ client, entityStore, stores }) {
    this.client = client;
    this.entityStore = entityStore;
    this.stores = stores;
  }

  mounted() {
    const { localStore, messengerStore, sessionStore } = this.stores;
    const { MessageRecipientStatus } = this.client.enums;
    if (!this.isDesktopApp) return;

    this._playSound = _.throttle(this._playSound, 1000);

    window.parent.setGeneralStatus = messengerStore.setGeneralStatus;
    window.parent.saveFileFromUrl = saveAs;
    window.parent.tigerconnect = {
      checkLogin: sessionStore.checkLogin,
      signIn: sessionStore.signIn,
    };
    this.disconnectBadgeCount(false);

    const {
      disableAndroidAppLink = false,
      exposesWindowState = this.clientType === DesktopClients.ELECTRON,
      supportsAudioInput = false,
      supportsCustomSounds = false,
      supportsIdp = false,
      supportsInlinePdf = false,
      supportsVideoInput = false,
      wtf = false,
    } = window.parent?.desktopFeatures || window.desktopFeatures || {};

    this.disableAndroidAppLink = disableAndroidAppLink;
    this.exposesWindowState = exposesWindowState;
    this.supportsAudioInput = supportsAudioInput;
    this.supportsCustomSounds = supportsCustomSounds;
    this.supportsIdp = supportsIdp;
    this.supportsInlinePdf = supportsInlinePdf;
    this.supportsVideoInput = supportsVideoInput;

    if (this.clientType === DesktopClients.DOTNET) {
      window.goToNotifierMsg = this._openNotification;
      window.tcSignOut = sessionStore.signOut;

      this.loginSuccess = window.notificationHandler.loginSuccess;
      this.logoutSuccess = window.notificationHandler.logoutSuccess;
      this.setDATitle = window.notificationHandler.setDATitle;

      this._sendNotification = _.debounce(this._sendNotification, 250);
    } else if (this.clientType === DesktopClients.ELECTRON) {
      if (window.parent.setDATitle) {
        this.setDATitle = window.parent.setDATitle;
      }
      this._sendNotification = _.debounce(this._sendNotification, 1000);
    }

    if (typeof desktopAppVersion === 'function') {
      (async () => {
        const result = await desktopAppVersion();
        runInAction(() => {
          this.desktopAppVersion = result;
        });
      })();
    }

    localStore.events.on('setItem', flushData);
    localStore.events.on('removeItem', flushData);

    localStore.events.on(
      'settingsLoaded',
      action(async (user) => {
        localStore.setAutoLoginUserId(user.id);

        await this.disconnectNotifications({
          source: 'DesktopAppStore on session signedIn',
        });
        await this.signOutNotifications();
        this.client.notifications.events.removeAuth();

        this.loadSettings();
        this.setBadgeCount({ unreadCount: 0 });

        this._notifyDALoginSuccess();

        this.client.on('messages:offline:start', () => {
          this.client.removeListener('friends', this._handleGroupNotification);
        });

        this.client.on('messages:offline:stop', () => {
          this.client.on('friends', this._handleGroupNotification);
        });

        this.client.once('messages:offline:stop', () => {
          this.client.on('message', this._handleMessageNotification(NotificationContext.LOGGED_IN));

          this._notifyDAMessagesDownloaded();
        });

        await this.signInNotifications();
      })
    );

    sessionStore.events.on('errorCaught', this._notifyDAError);
    sessionStore.events.on('externalLogin', this._notifyDAExternalLogin);
    sessionStore.events.on('externalRedirect', this._notifyDAExternalRedirect);
    sessionStore.events.on('sessionExpired', this._notifyDASessionExpired);
    sessionStore.events.on('signedOut', this._notifyDALogoutSuccess);
    sessionStore.events.on('signInError', this._notifyDALoginError);

    sessionStore.events.on(
      'setSignInStep',
      action(async (signInStep) => {
        this._notifyDASignInStep(signInStep);
        if (signInStep !== SignInStep.USERNAME) return;

        await this.disconnectNotifications({
          source: `DesktopAppStore on session setSignInStep=${signInStep}`,
        });
        await this.signOut();
      })
    );

    this.client.on(
      'notifications:signedIn',
      action((auth) => {
        const { key, secret } = auth;
        if (key && secret) {
          localStore.setNotificationKey(key);
          localStore.setNotificationSecret(secret);
        }
      })
    );

    this.client.on(
      'notifications:signedOut',
      action(() => {
        localStore.removeNotificationKey();
        localStore.removeNotificationSecret();
      })
    );

    this.client.on(
      'notifications:connected',
      action(() => {
        this.setBadgeCount({ unreadCount: 0 });
        this.recallAllNotifications();
      })
    );

    this.client.on('notifications:disconnected', this.disconnectBadgeCount);
    this.client.on('notification:unread:change', (unreadCount) =>
      this.setBadgeCount({ unreadCount })
    );
    this.client.on(
      'notification:message',
      this._handleMessageNotification(NotificationContext.SIMPLE_NOTIFICATION)
    );

    this.client.on('notification:vwr:visitor', this.showVWRNotification);

    this.entityStore.events.on(
      'afterInject',
      action((message) => {
        if (message.$entityType !== 'message') return;

        const { id: messageId, isOutgoing, isUnread } = message;
        if (isOutgoing || isUnread) return;
        if (!this.openNotifications.some(({ entityId }) => entityId === messageId)) {
          return;
        }
        this.recallNotification(messageId);
      })
    );

    this.entityStore.events.on(
      'afterInject',
      action((messageStatus) => {
        if (messageStatus.$entityType !== 'messageStatusPerRecipient') return;
        const { messageId, status, userId } = messageStatus;
        if (userId !== sessionStore.currentUserId) return;
        if (status !== MessageRecipientStatus.READ) return;
        if (!this.openNotifications.some(({ entityId }) => entityId === messageId)) {
          return;
        }
        this.recallNotification(messageId);
      })
    );

    this.entityStore.events.on(
      'afterInject',
      action((org) => {
        if (org.$entityType !== 'organization') return;

        const allOrganizations = this.client.organizations.getAll();
        const nextAutoUpdateEnabled = !allOrganizations.find((org) => !org.desktopAutoUpdate);
        if (nextAutoUpdateEnabled === this.isAutoUpdateEnabled) return;
        this.isAutoUpdateEnabled = nextAutoUpdateEnabled;

        if (this.clientType === DesktopClients.ELECTRON) {
          if (window.autoUpdate && typeof window.autoUpdate.set === 'function') {
            window.autoUpdate.set(this.isAutoUpdateEnabled);
          }
        } else if (this.clientType === DesktopClients.DOTNET) {
          window.notificationHandler.setAutoUpdateAppFeature(this.isAutoUpdateEnabled);
        }
      })
    );
  }

  @computed get canAuthenticate() {
    const { localStore } = this.stores;
    return !!(localStore.notificationKey && localStore.notificationSecret);
  }

  @action('DesktopAppStore.signOut') signOut = async () => {
    const { localStore } = this.stores;
    this.recallAllNotifications();

    await this.signOutNotifications();

    localStore.removeAutoLoginUserId();
    localStore.removeNotificationKey();
    localStore.removeNotificationSecret();
  };

  @action('DesktopAppStore.signInPreviousUser')
  signInPreviousUser = async () => {
    const { localStore, sessionStore } = this.stores;
    const { checkLogin, signInUsername } = sessionStore;
    if (!signInUsername) return;

    const isValidUser = await checkLogin(signInUsername, { fromReload: true });
    if (isValidUser && this.canAuthenticate) {
      await this.authenticateNotifications();
      await this.connectNotifications();

      if (localStore.autoLoginUserId) {
        this.loadSettings(localStore.autoLoginUserId);
      }
    }
  };

  @action('DesktopAppStore.authenticateNotifications')
  authenticateNotifications = async () => {
    const { localStore } = this.stores;
    return this.client.notifications.authenticate(
      localStore.notificationKey,
      localStore.notificationSecret
    );
  };

  @action('DesktopAppStore.connectNotifications')
  connectNotifications = async () => {
    if (this.client.notifications.hasConnection) return;

    try {
      await this.client.notifications.connect();
    } catch (err) {
      console.error(err);
      await this.signOutNotifications();
      this.client.notifications.events.removeAuth();
    }
  };

  @action('DesktopAppStore.disconnectNotifications')
  disconnectNotifications = async ({ source }) => {
    return this.client.notifications.disconnect({ source });
  };

  @action('DesktopAppStore.signInNotifications')
  signInNotifications = async () => {
    return this.client.notifications.signIn();
  };

  @action('DesktopAppStore.signOutNotifications')
  signOutNotifications = async () => {
    const { localStore } = this.stores;
    try {
      await this.client.notifications.signOut();
    } catch (err) {
      console.error(err);
      if (localStore.notificationKey) {
        this.client.notifications.emit('signedOut', {
          fromServer: false,
          signedOut: false,
        });
      }
    }
  };

  @action('DesktopAppStore.setIsDaDisconnect') setIsDaDisconnect = (val) => {
    this.isDaDisconnect = val;

    if (this.isDaDisconnect) {
      this.disconnectBadgeCount();
      const notificationEntry = {
        body: 'Please log in again',
        entityId: uuid(),
        entityType: 'message',
        isPriority: false,
        title: 'Your session has expired',
      };

      if (window.parent && window.parent.DAOS === 'win32') {
        notificationEntry.icon = TigerConnectLogo;
      }

      this.openNotifications.push(notificationEntry);
      this._sendNotification(notificationEntry);
    }
  };

  @action('DesktopAppStore.disconnectBadgeCount') disconnectBadgeCount = (
    recallAllNotifications = true
  ) => {
    if (this.clientType === DesktopClients.ELECTRON) {
      window.parent.setUnreadCount('X');
    } else if (this.clientType === DesktopClients.DOTNET) {
      window.notificationHandler.setUnreadCount(null);
      window.notificationHandler.logout();
    }
    if (recallAllNotifications) this.recallAllNotifications();
  };

  @action('DesktopAppStore.setBadgeCount') setBadgeCount = ({
    unreadCount,
    unreadPriorityCount,
  }) => {
    const { sessionStore } = this.stores;

    if (this.isDaDisconnect) {
      this.disconnectBadgeCount();
      return;
    }

    if (!unreadCount) unreadCount = null;
    if (this.clientType === DesktopClients.ELECTRON) {
      window.parent.setUnreadCount(unreadCount);
    } else if (this.clientType === DesktopClients.DOTNET) {
      window.notificationHandler.setUnreadCount(unreadCount);
    }
  };

  @action('DesktopAppStore.loadSettings')
  loadSettings = (userId = this.stores.sessionStore.currentUserId) => {
    const { localStore } = this.stores;
    const desktopSettings = localStore._migrate_loadDesktopAppSettings(userId);

    this.bounceDockIcon = desktopSettings.bounceDockIcon;
    this.customSound = desktopSettings.customSound;
    this.showMessageBody = desktopSettings.showMessageBody;
    this.playSounds = desktopSettings.playSounds;
    this.showNotifications = desktopSettings.showNotifications;
    this.toastTimeout = desktopSettings.toastTimeout;
  };

  @action('DesktopAppStore.saveSettings') saveSettings = () => {
    const { localStore, modalStore, sessionStore } = this.stores;

    const desktopSettings = {
      showNotifications: this.showNotifications,
      playSounds: this.playSounds,
      toastTimeout: this.toastTimeout,
      showMessageBody: this.showMessageBody,
      bounceDockIcon: this.bounceDockIcon,
      customSound: this.customSound,
    };

    localStore.saveDesktopAppSettings(sessionStore.currentUserId, desktopSettings);
    modalStore.openModal('success');
  };

  @action('DesktopAppStore.setShowNotifications') setShowNotifications = (value) => {
    this.showNotifications = value;
    this.saveSettings();
  };

  @action('DesktopAppStore.setBounceDockIcon') setBounceDockIcon = (value) => {
    this.bounceDockIcon = value;
    this.saveSettings();
  };

  @action('DesktopAppStore.setCustomSound') setCustomSound = (value) => {
    this.customSound = value;
    this.saveSettings();
  };

  @action('DesktopAppStore.setShowMessageBody') setShowMessageBody = (value) => {
    this.showMessageBody = value;
    this.saveSettings();
  };

  @action('DesktopAppStore.setPlaySounds') setPlaySounds = (value) => {
    this.playSounds = value;
    this.saveSettings();
  };

  @action('DesktopAppStore.setToastTimeout') setToastTimeout = (value) => {
    this.toastTimeout = value;
    this.saveSettings();
  };

  @action('DesktopAppStore.showSimpleNotification')
  showSimpleNotification = async (notification) => {
    const {
      groupAvatarUrl,
      groupDisplayName,
      senderAvatarUrl,
      senderDisplayName,
      serverId: messageId,
    } = notification;

    if (!isDesktopApp) {
      return;
    }

    const displayName = groupDisplayName || senderDisplayName;
    const title = `${displayName || 'Someone'} sent you a new message`;
    const icon = groupAvatarUrl || senderAvatarUrl;
    const isRoleTransitionedGroup = icon?.includes('roles_transition');
    const notificationEntry = {
      body: senderDisplayName,
      entityId: messageId,
      entityType: 'message',
      icon: isRoleTransitionedGroup ? '' : icon,
      isPriority: false,
      title,
    };

    this.openNotifications.push(notificationEntry);
    this._sendNotification(notificationEntry);
  };

  @action('DesktopAppStore.showClinicalAlertsNotification')
  showClinicalAlertsNotification = async (notification) => {
    const {
      $entityType,
      alertNotification,
      conversationId,
      isUnread,
      recipientOrganizationId,
      serverId: messageId,
    } = notification;
    const { sound, text, title } = alertNotification;
    let alertSound = sound?.toLowerCase();
    if (!this.isDesktopApp || !isUnread) {
      return;
    }

    if (!ALERT_SOUNDS.includes(`${alertSound}.m4a`)) {
      alertSound = '3_beep';
    }

    if ($entityType === 'message') {
      const notificationEntry = {
        body: text,
        conversationId,
        entityId: messageId,
        entityType: 'clinical_alerts',
        organizationId: recipientOrganizationId,
        sound: alertSound,
        title,
      };

      this.openNotifications.push(notificationEntry);
      this._sendNotification(notificationEntry);
    }
  };

  @action('DesktopAppStore.showVWRNotification')
  showVWRNotification = async (notification) => {
    const { sessionStore } = this.stores;
    const {
      $entityType,
      conversationId,
      id: visitId,
      roomId,
      organizationId,
      visitorAccountToken,
    } = notification;

    if (!isDesktopApp) {
      return;
    }

    if ($entityType === 'message') {
      const { counterParty = {} } = notification;
      const { metadata = {}, organizationId } = counterParty;
      const { visit_id, visitor_token, vwr_room_id, vwr_room_name } = metadata;

      const room = await this.client.virtualWaitingRoom.find(vwr_room_id, organizationId);
      const currentStaffMember =
        room.staff.find(({ accountToken }) => accountToken === sessionStore.currentUserId) || {};
      const shouldUserReceiveNotification = currentStaffMember.notificationStatus === 'ON';

      if (!shouldUserReceiveNotification) {
        return;
      }
      const title = `You have a new message in ${vwr_room_name}`;

      const notificationEntry = {
        body: '',
        conversationId,
        entityId: visit_id,
        entityType: 'vwr_visitor',
        isPriority: false,
        organizationId,
        roomId: vwr_room_id,
        title,
        visitId: visit_id,
        visitorAccountToken: visitor_token,
      };

      this.openNotifications.push(notificationEntry);
      this._sendNotification(notificationEntry);
      return;
    } else {
      const room = await this.client.virtualWaitingRoom.find(roomId, organizationId);

      const currentStaffMember =
        room.staff.find(({ accountToken }) => accountToken === sessionStore.currentUserId) || {};
      const shouldUserReceiveNotification = currentStaffMember.notificationStatus === 'ON';

      if (!shouldUserReceiveNotification) {
        return;
      }

      const title = `You have a new visitor in ${room.name}`;
      const notificationEntry = {
        body: '',
        conversationId,
        entityId: visitId,
        entityType: 'vwr_visitor',
        isPriority: false,
        organizationId,
        roomId,
        title,
        visitId,
        visitorAccountToken,
      };

      this.openNotifications.push(notificationEntry);
      this._sendNotification(notificationEntry);
    }
  };

  @action('DesktopAppStore.showNotification') showNotification = async (notification) => {
    const { messageStore, messengerStore, roleStore, sessionStore } = this.stores;
    let {
      escalationExecution,
      isUnread,
      organizationId,
      recipientOrganizationId,
      sender,
      senderRole,
      serverId: messageId,
    } = notification;

    const myUserIds = [sessionStore.currentUserId, ...roleStore.currentRoleBotUserIds];
    const senderId = senderRole ? senderRole.botUserId : sender.id;

    let mentionedBody;
    let isMainWindowActive = false;
    if (this.exposesWindowState) {
      isMainWindowActive = document.hasFocus() && !document.hidden;
    }

    const shouldNotShow =
      !this.isDesktopApp ||
      (!!this.client.currentUser && this.client.currentUser.dnd) ||
      (this.exposesWindowState && isMainWindowActive) ||
      (!!escalationExecution && !!escalationExecution.groupId) || // Do not show notifications for carbon copy messages
      !isUnread ||
      myUserIds.includes(senderId);

    if (shouldNotShow) {
      return;
    }

    const message = sessionStore.currentUserId ? await messageStore.findMessage(messageId) : null;

    if (messengerStore.isMentionsEnabled && message.isMentioned) {
      mentionedBody = `${message.sender.displayName} mentioned you`;
    }

    const conversation = message ? message.conversation : null;
    if (conversation && (conversation.isMuted || !conversation.shouldDisplay) && !mentionedBody) {
      return;
    }

    if (recipientOrganizationId) organizationId = recipientOrganizationId;
    const organization =
      (sessionStore.currentUserId &&
        organizationId &&
        this.entityStore.getById('organization', organizationId)) ||
      DEFAULT_ORGANIZATION;

    const isPriority =
      !!message &&
      message.priority === 'HIGH' &&
      organization &&
      organization.desktopPriorityMessagingSounds;

    const { body, icon, title } = this._getNotificationDetails(notification, message);

    const notificationEntry = {
      body: mentionedBody ? mentionedBody : body,
      conversationId: message && message.conversationId,
      entityId: messageId,
      entityType: 'message',
      icon,
      isPriority,
      title,
    };

    this.openNotifications.push(notificationEntry);
    this._sendNotification(notificationEntry);
  };

  _getNotificationDetails = (notification, message) => {
    const { group, sender, senderRole } = notification;

    let body = '';
    let displayName;
    let icon;
    let title;

    if (group) {
      const { groupType } = group;
      body = message && message.sender ? message.sender.displayName : '';

      if (group.groupType !== 'ACTIVATED_TEAM') icon = group.avatarUrl;

      if (groupType === 'PATIENT_CARE' && message) {
        const { patientCareCard } = message;
        const patientName = group.displayName;
        body = '';

        if (patientCareCard && patientCareCard.length > 0) {
          const alertType = patientCareCard[0].value;
          title = `Patient: ${patientName} has an alert: ${alertType}`;
        } else {
          title = `Patient: ${patientName} has a new message`;
        }
      } else if (groupType === 'ROLE_P2P') {
        displayName = senderRole ? senderRole.displayName : sender.displayName;
      } else {
        displayName = group.displayName;
      }
    } else if (sender) {
      displayName = sender.displayName;
      body = displayName || '';
      icon = sender.avatarUrl;
    }

    if (!title) {
      title = `${displayName} sent you a new message`;
    }

    if (!icon && window.parent && window.parent.DAOS === 'win32') {
      icon = TigerConnectLogo;
    }

    return { body, icon, title };
  };

  _openNotification = async (entityId, entityType, notification) => {
    const {
      alertStore,
      conversationStore,
      groupStore,
      messageStore,
      messengerStore,
      networkStore,
      rosterStore,
      sessionStore,
      trackerStore,
    } = this.stores;

    const { logPendoAnalytics } = trackerStore;

    let notificationType, oldDaNotification, priority;
    if (!entityType) {
      const notificationEntry = this.openNotifications.find(
        (notificationEntry) => notificationEntry.entityId === entityId
      );
      if (!notificationEntry) return;

      entityType = notificationEntry.entityType;
      oldDaNotification = notificationEntry;
    }

    this.openNotifications = this.openNotifications.filter(
      (notificationEntry) => notificationEntry.entityId !== entityId
    );

    if (sessionStore.currentUserId && messengerStore.isMessengerDisplayed) {
      let entity = null;

      if (entityType === 'group') {
        entity = await groupStore.findGroup(entityId);
        if (entity.conversation.counterParty.groupType === 'PATIENT_CARE')
          notificationType = 'Patient Care';
      } else if (entityType === 'message') {
        entity = await messageStore.findMessage(entityId);
        priority = entity.priority;
        if (messengerStore.isMentionsEnabled && entity.isMentioned) {
          notificationType = 'Mention';
        } else {
          notificationType = 'Message';
        }
      } else if (entityType === 'vwr_visitor') {
        const { organizationId } = notification;
        const { changeOrganizationFromUI, currentOrganizationId } = messengerStore;

        if (organizationId !== currentOrganizationId) {
          if (sessionStore.currentUserId && organizationId) {
            const organization = this.entityStore.getById('organization', organizationId);
            if (organization) await changeOrganizationFromUI(organization);
          }
        }
        runInAction(() => {
          this.vwrNotification = notification || oldDaNotification;
          const vwrApp = 'Virtual Waiting';
          notificationType = 'VWR';
          if (messengerStore.currentAppSelected !== vwrApp) {
            messengerStore.switchApp(vwrApp);
          }
        });
      } else if (entityType === 'clinical_alerts') {
        const { changeOrganizationFromUI, currentOrganizationId } = messengerStore;
        const { entityId: messageId, organizationId } = notification;
        const messengerApp = 'Messages';

        if (this.noise) {
          this.noise.pause();
        }

        notificationType = 'Clinical Alerts';

        if (messengerStore.currentAppSelected !== messengerApp) {
          messengerStore.switchApp(messengerApp);
        }

        if (organizationId !== currentOrganizationId) {
          if (sessionStore.currentUserId && organizationId) {
            const organization = this.entityStore.getById('organization', organizationId);
            if (organization) await changeOrganizationFromUI(organization);
          }
        }

        if (!networkStore.isProviderNetwork) {
          networkStore.switchNetwork();
        }

        rosterStore.setFilter('Inbox');
        rosterStore.setFilterBar('Alerts');
        alertStore.scrollToAlertMessage(messageId);

        return;
      }

      if (entity) {
        rosterStore.setFilterBarByEntity(entity);
        conversationStore.selectConversationWithEntity({
          [entityType]: entity,
          markAsRead: true,
        });
      }
    }

    logPendoAnalytics({
      tracker: {
        name: 'Inbox | Messenger - Notification Click',
        props: {
          NotificationType: notificationType,
          ...(priority && { Priority: priority === 'HIGH' ? 'Yes' : 'No' }),
        },
      },
    });

    if (this.clientType === DesktopClients.DOTNET) {
      window.focus();
    } else {
      // eslint-disable-next-line new-cap
      if (window.parent.ShowMainWindow) window.parent.ShowMainWindow();
    }
  };

  @action('DesktopAppStore.clearVwrNotification') clearVwrNotification = () => {
    this.vwrNotification = null;
  };

  _sendNotification = (notificationEntry) => {
    let audioFile;
    const { body, entityId, entityType, icon, isPriority, sound, title } = notificationEntry;
    const isRoleTransitionedGroup = icon?.includes('roles_transition');

    if (this.clientType === DesktopClients.ELECTRON) {
      if (this.showNotifications) {
        const notification = new Notification(title, {
          icon: isRoleTransitionedGroup ? '' : icon,
          body,
          silent: true,
        });
        notificationEntry.notification = notification;

        notification.onclick = async () => {
          this._openNotification(entityId, entityType, notificationEntry);
        };
      }

      if (this.playSounds) {
        const priorityFile = '/media/priority.mp3';
        if (entityType === 'clinical_alerts') {
          audioFile = `/media/clinicalAlerts/${sound}.m4a`;
        } else {
          audioFile = `/media/${this.customSoundOptions[this.customSound]}`;
        }
        let playSound = audioFile;

        if (isPriority) {
          if (this.noise && !this.noise.paused && this.noise.src.includes(priorityFile)) {
            playSound = null;
          } else {
            if (this.noise) {
              this.noise.pause();
            }
            playSound = priorityFile;
          }
        } else if (this.noise && !this.noise.paused) {
          playSound = null;
        }

        if (playSound) {
          this._playSound(`.${playSound}`);
        }
      }

      if (this.bounceDockIcon !== Bounce.NEVER) {
        window.parent.attention(null);
      }
    } else if (this.clientType === DesktopClients.DOTNET) {
      // Windows DA reads notification and sound settings out of the cookie and ignores bounce
      const { localStore, sessionStore } = this.stores;
      const userId = sessionStore.currentUserId || localStore.autoLoginUserId;
      const desktopSettings = localStore._migrate_loadDesktopAppSettings(userId);
      const settingsValue = settingsObjectToValue(desktopSettings);

      try {
        window.notificationHandler.notify(
          title,
          isRoleTransitionedGroup ? '' : icon,
          body,
          entityId,
          settingsValue,
          true,
          isPriority
        );
      } catch (err) {
        window.notificationHandler.notify(title, icon, body, entityId, settingsValue, true);
      }
    }
  };

  _playSound = (audioFile) => {
    try {
      this.noise = new Audio(audioFile);
      this.noise?.play();
    } catch (e) {
      console.error(e);
    }
  };

  _stopSound = () => {
    this.noise?.pause();
  };

  _handleMessageNotification = (context) => {
    return async (notification) => {
      if (context === NotificationContext.SIMPLE_NOTIFICATION) {
        if (this.client.currentUser && this.client.notifications.isConnected) {
          const { networkStatusStore } = this.stores;

          networkStatusStore.ignoreNetworkStatusChange = true;
          await this.disconnectNotifications({
            source: 'DesktopAppStore received simple notification when logged in',
          });
          networkStatusStore.ignoreNetworkStatusChange = false;

          return;
        }

        this.showSimpleNotification(notification);
      } else if (notification.$entityType === 'message' && notification.featureService === 'vwr') {
        this.showVWRNotification(notification);
      } else if (context === NotificationContext.LOGGED_IN) {
        if (notification.alertNotification) {
          this.showClinicalAlertsNotification(notification);
        } else {
          this.showNotification(notification);
        }
      }
    };
  };

  _handleGroupNotification = (data) => {
    const { roleStore, sessionStore } = this.stores;

    const { action, avatar: icon, escalation, token: groupId } = data;

    if (!escalation) return;

    if (action === 'add') {
      if (escalation['escalation_status'] !== 'in_progress') return;

      const body = escalation['action_user_name'];
      let title = '';

      if (
        roleStore.currentRoleBotUserIds.includes(escalation['target_token']) ||
        escalation['action_user_id'] === sessionStore.currentUserId
      ) {
        title = `Escalation Group ${escalation['display_name']} Created`;
      } else {
        title = `Escalation ${escalation['display_name']} requires your attention`;
      }

      const notificationEntry = {
        body,
        entityId: groupId,
        entityType: 'group',
        icon,
        isPriority: false,
        title,
      };
      this.openNotifications.push(notificationEntry);
      this._sendNotification(notificationEntry);
    } else if (action === 'del') {
      this.recallNotification(groupId);
    }
  };

  _notifyDASignInStep = (signInStep) => {
    if (this.setDATitle) {
      const screenTitle = signInStep === SignInStep.USERNAME ? DATitles.LOGIN : DATitles.PASSWORD;
      this.setDATitle(screenTitle);
    }
  };

  _notifyDAError = () => {
    if (this.setDATitle) {
      this.setDATitle(DATitles.ERROR);
    }
  };

  _notifyDAExternalLogin = () => {
    if (this.setDATitle) {
      this.setDATitle(DATitles.EXTERNAL_LOGIN);
    }
  };

  _notifyDAExternalRedirect = () => {
    if (this.setDATitle) {
      this.setDATitle(DATitles.EXTERNAL_REDIRECT);
    }
  };

  _notifyDALoginError = (error) => {
    const errMsg = LOGIN_ERROR_TO_MESSAGE[error] || error;

    if (this.setDATitle) {
      this.setDATitle(`TigerConnect - Error - ${errMsg}`);
    }
  };

  _notifyDALoginSuccess = () => {
    if (this.setDATitle) {
      this.setDATitle(DATitles.LOGGING_IN);
    }

    if (this.loginSuccess) {
      this.loginSuccess();
    }
  };

  _notifyDASessionExpired = async ({ fromServer }) => {
    if (this.setDATitle) {
      this.setDATitle(DATitles.SESSION_EXPIRED);
    }

    if (fromServer) {
      this.setBadgeCount({ unreadCount: 0 });
    }
  };

  _notifyDALogoutSuccess = async ({ fromServer }) => {
    if (this.logoutSuccess) {
      this.logoutSuccess();
    }

    if (this.stores.sessionStore.isSessionExpired && !fromServer) {
      await this.connectNotifications();
    }
    this.setIsDaDisconnect(true);
  };

  _notifyDAMessagesDownloaded = () => {
    if (this.setDATitle) {
      this.setDATitle(DATitles.DEFAULT);
    }
  };

  @action('DesktopAppStore.recallAllNotifications')
  recallAllNotifications = () => {
    if (this.clientType === DesktopClients.ELECTRON) {
      this.openNotifications.forEach(({ notification }) => notification && notification.close());
    } else if (this.clientType === DesktopClients.DOTNET) {
      window.notificationHandler.closeNotification();
    }
    this.openNotifications = [];
  };

  @action('DesktopAppStore.recallAllNotificationsForConversation')
  recallAllNotificationsForConversation = async (conversationId) => {
    let openNotificationRecalled = false;

    this.openNotifications = this.openNotifications.filter((notificationEntry) => {
      if (notificationEntry.conversationId === conversationId) {
        const { notification } = notificationEntry;

        if (notification) {
          notification.close();
        }
        openNotificationRecalled = true;

        return false;
      }

      return true;
    });

    if (this.clientType === DesktopClients.DOTNET && openNotificationRecalled) {
      window.notificationHandler.closeNotification();

      let isMainWindowActive = false;
      if (this.exposesWindowState) {
        isMainWindowActive = await window.notificationHandler.isMainWindowActive();
      }

      if (!isMainWindowActive && this.openNotifications.length > 0 && this.showNotifications) {
        this._sendNotification(_.last(this.openNotifications));
      }
    }
  };

  @action('DesktopAppStore.recallNotification') recallNotification = async (entityId) => {
    if (this.clientType === DesktopClients.ELECTRON) {
      this.openNotifications.forEach((notificationEntry) => {
        const { notification } = notificationEntry;

        if (notificationEntry.entityId === entityId && notification) notification.close();
      });
      this.openNotifications = this.openNotifications.filter(
        (notificationEntry) => notificationEntry.entityId !== entityId
      );
    } else if (this.clientType === DesktopClients.DOTNET) {
      let openNotificationRecalled = false;
      this.openNotifications = this.openNotifications.filter((notificationEntry) => {
        if (notificationEntry.entityId === entityId) {
          openNotificationRecalled = true;
          return false;
        }

        return true;
      });

      if (openNotificationRecalled) {
        window.notificationHandler.closeNotification();

        let isMainWindowActive = false;
        if (this.exposesWindowState) {
          isMainWindowActive = await window.notificationHandler.isMainWindowActive();
        }

        if (!isMainWindowActive && this.openNotifications.length > 0 && this.showNotifications) {
          this._sendNotification(_.last(this.openNotifications));
        }
      }
    }
  };
}
