import React, { MouseEvent, useState } from 'react';
import type { BaseEmoji } from 'emoji-mart';
import { mobxInjectSelect } from '../../utils';
import BEM from '../../bem';
import { ReactComponent as EmojiSvg } from '../../images/emoji.svg';
import { ReactComponent as EmojiHoverSvg } from '../../images/emoji-hover.svg';
import { EmojiPickerPortal } from './';

const classes = BEM.with('EmojiPickerButton');

type EmojiPickerButtonProps = {
  onPick: (message: string) => void;
};

const EmojiPickerButton = ({ onPick }: EmojiPickerButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onEmojiSelected = (emoji: BaseEmoji) => {
    onPick(emoji.native);
  };

  const toggleIsOpen = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes()}>
      <button
        aria-label="Emoji Picker Button"
        className={classes('emoji-button-container')}
        onClick={toggleIsOpen}
      >
        <EmojiSvg className={classes('image')} fill="#DADADA" />
        <EmojiHoverSvg className={classes('image-hover')} fill="#DADADA" />
      </button>
      <EmojiPickerPortal isOpen={isOpen} onEmojiSelected={onEmojiSelected} setIsOpen={setIsOpen} />
    </div>
  );
};

// TODO: Change to observer once mobx-react is upgraded to ^6.0.0 for hooks support
export default mobxInjectSelect<EmojiPickerButtonProps, {}>({})(EmojiPickerButton);
