// @ts-nocheck
export default class Event {
  constructor(id, type, data) {
    this.id = id;
    this.type = type;
    this.data = data;
  }

  static is(o) {
    return o instanceof this;
  }
}
