export default class ValidationError extends Error {
  static CODE = 'validation';

  code: string;
  reason: string;

  constructor(name: string, reason = 'invalid', message = `${name} is ${reason}`) {
    super(message);
    this.code = ValidationError.CODE;
    this.name = name;
    this.reason = reason;
  }
}
