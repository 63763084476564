import { extendObservable } from 'mobx';
import { FROZEN_EMPTY_ARRAY, jsonCloneDeep } from '../common/utils';

// mobx model is a "clone" of models from the SDK, and kept in sync with SDK data
// they contain primitive properties and relationship getters
// their objective is to make react components re-render on change, without explicit listeners
export default function createMobxModel({ fields, name, objectFields, relations }) {
  const objectFieldEntries = objectFields ? Object.entries(objectFields) : [];

  // prepare default values for idProp fields
  const oneRelationEntries = relations && relations.one ? Object.entries(relations.one) : [];
  for (const [relName, relConfig] of oneRelationEntries) {
    if (!relConfig.idProp) relConfig.idProp = relName + 'Id';
    if (Array.isArray(relConfig.type) && !relConfig.typeProp) relConfig.typeProp = relName + 'Type';
  }

  const manyRelationEntries = relations && relations.many ? Object.entries(relations.many) : [];
  for (const [relName, relConfig] of manyRelationEntries) {
    const singular = relName.endsWith('s') ? relName.slice(0, -1) : relName;
    if (!relConfig.idsProp) relConfig.idsProp = singular + 'Ids';
    if (Array.isArray(relConfig.type) && !relConfig.typesProp)
      relConfig.typesProp = singular + 'Types';
  }

  return class Model {
    static entityType = name;
    static entityStore;
    id;
    $lastModified;
    $entityType;

    get entityStore() {
      return this.constructor.entityStore;
    }

    constructor(id) {
      this.id = id;
      const props = {};

      for (const field of fields) {
        props[field] = null;
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this;

      for (const [relName, { idProp, type, typeProp }] of oneRelationEntries) {
        if (Array.isArray(type)) {
          props[idProp] = null;
          props[typeProp] = null;
          Object.defineProperty(props, relName, {
            get: function () {
              const entityType = this[typeProp];
              const entityId = this[idProp];
              if (!entityType || !entityId) return null;
              return _this.constructor.entityStore.getById(entityType, entityId);
            },
          });
        } else {
          props[idProp] = null;
          Object.defineProperty(props, relName, {
            get: function () {
              return _this.constructor.entityStore.getById(type, this[idProp]);
            },
          });
        }
      }

      for (const [relName, { idsProp, type, typesProp }] of manyRelationEntries) {
        if (Array.isArray(type)) {
          props[idsProp] = null;
          props[typesProp] = null;

          Object.defineProperty(props, relName, {
            get: function () {
              return this[idsProp]
                .map((id, idx) => _this.constructor.entityStore.getById(this[typesProp][idx], id))
                .filter(Boolean);
            },
          });
        } else {
          props[idsProp] = [];
          Object.defineProperty(props, relName, {
            get: function () {
              return this[idsProp]
                .map((id) => _this.constructor.entityStore.getById(type, id))
                .filter(Boolean);
            },
          });
        }
      }

      extendObservable(this, props);
    }

    // takes an entity and copies over the values, which will trigger a mobx reaction
    // and possible re-renders where the values are being used
    updateFromEntity(entity, deepSync = false) {
      this.$lastModified = entity.$lastModified;
      this.$entityType = entity.$entityType;
      for (const field of fields) {
        this[field] = entity[field];
      }

      for (const [field, { clone = jsonCloneDeep }] of objectFieldEntries) {
        const value = clone(entity[field], {
          entity,
          entityStore: this.constructor.entityStore,
        });
        extendObservable(this, { [field]: value });
      }

      for (const [relName, { idProp, type, typeProp }] of oneRelationEntries) {
        const entityFromOne = entity[relName];
        if (entityFromOne) {
          const entityList = this.constructor.entityStore[entityFromOne.$entityType];
          if (
            (entity.propertyIsEnumerable(relName) || deepSync) &&
            (this[idProp] !== entityFromOne.id || !entityList?.getById(entityFromOne.id))
          ) {
            entityList?._sync(entityFromOne, deepSync);
          }
          this[idProp] = entityFromOne.id;
          if (Array.isArray(type)) this[typeProp] = entityFromOne.$entityType;
        } else {
          this[idProp] = null;
          if (Array.isArray(type)) this[typeProp] = null;
        }
      }

      for (const [relName, { idsProp, type, typesProp }] of manyRelationEntries) {
        const entitiesFromMany = entity[relName];
        const previousIds = this[idsProp];
        if (Array.isArray(entitiesFromMany)) {
          if (entity.propertyIsEnumerable(relName) || deepSync) {
            for (const entityValue of entitiesFromMany) {
              if (!entityValue) continue;
              const entityList = this.constructor.entityStore[entityValue.$entityType];
              if (
                !entityList?.getById(entityValue.id) ||
                !(previousIds && previousIds.includes(entityValue.id))
              ) {
                entityList?._sync(entityValue, deepSync);
              }
            }
          }
          this[idsProp] = entitiesFromMany.map(({ id }) => id);
          if (Array.isArray(type))
            this[typesProp] = entitiesFromMany.map(({ $entityType }) => $entityType);
        } else {
          this[idsProp] = FROZEN_EMPTY_ARRAY;
          if (Array.isArray(type)) this[typesProp] = FROZEN_EMPTY_ARRAY;
        }
      }
    }
  };
}
