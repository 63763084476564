import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import BEM from '../../../bem';
import { AppTypes } from '../../../../models/enums';
import { useInfiniteSearchResults } from '../../../hooks';
import { ReactComponent as DeleteButtonSvg } from '../../../images/delete_button.svg';
import { mobxInjectSelect } from '../../../utils';
import { CollapsingSearchBar, Dropdown, DotsIndicator } from '../..';
import { ReactComponent as DropDownSvg } from '../../../images/pa-dropdown.svg';
import RefreshButton from '../../RefreshButton';
import { getProviderBroadcastLists } from '../../../../contexts/BroadcastListSettings';
import { PatientBroadcastTable } from '.';

const DEBOUNCE_TIMEOUT = 500;

const classes = BEM.with('BroadcastListsView');

const BroadcastListsView = ({
  allowProviderBroadcastListAdSync,
  createBroadcastList,
  currentAppSelected,
  currentOrganizationId,
  editBroadcastList,
  isAdmin,
  loadBroadcastLists,
  openModal,
  setIsADSync,
  setMessageTemplateAdminPageRefresher,
  refreshProviderNetworkCustomDirectories,
}) => {
  const loadListFn =
    currentAppSelected === AppTypes.BROADCAST_LISTS
      ? getProviderBroadcastLists
      : loadBroadcastLists;
  const { isLoading, results, resetSearch, scrollContainerRef, totalHits, updateOptions } =
    useInfiniteSearchResults(loadListFn, { organizationId: currentOrganizationId });

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState([]);
  const [sortBy, setSortBy] = useState('updated_on');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const setQueryDebounced = useRef(debounce(setQuery, DEBOUNCE_TIMEOUT));
  const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);
  const createButtonRef = useRef(null);

  const handleQueryChange = (value) => {
    if (value !== query) {
      resetSearch();
      setQueryDebounced.current(value);
    }
  };

  const reloadSearch = (options) => {
    if (refreshProviderNetworkCustomDirectories) refreshProviderNetworkCustomDirectories();
    setSelected([]);
    resetSearch();
    updateOptions(options);
  };

  const handleBulkDelete = () => {
    openModal('deleteSelectedBroadcastListItems', {
      selected,
      onClose: reloadSearch,
    });
  };

  setMessageTemplateAdminPageRefresher(reloadSearch);

  const toggleSelected = (token) => {
    if (selected.includes(token)) {
      setSelected(selected.filter((id) => id !== token));
    } else {
      setSelected([...selected, token]);
    }
  };

  const toggleSort = (newSortBy) => {
    setSortOrder(sortBy !== newSortBy ? 'asc' : sortOrder === 'asc' ? 'desc' : 'asc');
    setSortBy(newSortBy);
  };

  useEffect(() => {
    reloadSearch({
      organizationId: currentOrganizationId,
      query,
      sortBy,
      sortOrder,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganizationId, query, sortBy, sortOrder]);

  function toggleDropdown() {
    setIsCreateDropdownOpen(!isCreateDropdownOpen);
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <div className={classes()}>
      <div className={classes('header')}>
        <h3 className={classes('title')} id="patient-settings-heading">
          {currentAppSelected === AppTypes.BROADCAST_LISTS ? 'Provider' : 'Patient'} Broadcast Lists
          ({totalHits})
          <RefreshButton currentAppSelected={currentAppSelected} refreshList={reloadSearch} />
        </h3>
      </div>
      <div className={classes('actions')}>
        <div className={classes('actions-left')}>
          <button
            className={classes('create', { currentAppSelected, allowProviderBroadcastListAdSync })}
            onClick={
              currentAppSelected === AppTypes.BROADCAST_LISTS && allowProviderBroadcastListAdSync
                ? toggleDropdown
                : createBroadcastList
            }
            data-test-id={'create-list-button'}
            disabled={!isAdmin}
            ref={createButtonRef}
          >
            Create List{' '}
            {currentAppSelected === AppTypes.BROADCAST_LISTS &&
              allowProviderBroadcastListAdSync && (
                <DropDownSvg
                  className={classes('create-list-arrow', { isExpanded: isDropdownOpen })}
                />
              )}
          </button>
          <div className={classes('filters')}>
            <CollapsingSearchBar
              handleQueryChange={handleQueryChange}
              query={query}
              currentAppSelected={currentAppSelected}
            />
          </div>
        </div>
        {selected.length > 0 && (
          <div className={classes('actions-right')}>
            <button onClick={handleBulkDelete} className={classes('actions-right-button')}>
              <DeleteButtonSvg className={classes('delete-icon')} />
              <span className={classes('delete-icon-text')}>Delete </span>
            </button>
          </div>
        )}
      </div>
      <PatientBroadcastTable
        currentAppSelected={currentAppSelected}
        editBroadcastList={editBroadcastList}
        isAdmin={isAdmin}
        isLoadingMore={isLoading}
        queryValue={query}
        results={results}
        scrollContainerRef={scrollContainerRef}
        selected={selected}
        sortBy={sortBy}
        sortOrder={sortOrder}
        toggleSelected={toggleSelected}
        toggleSort={toggleSort}
      />
      <div className={classes('loading-more', { isLoadingMore: isLoading })}>
        <DotsIndicator color={'#969696'} size={18} />
      </div>
      {allowProviderBroadcastListAdSync && isCreateDropdownOpen && (
        <div className={classes('create-list-dropdown')}>
          <Dropdown
            ariaLabel={'create-list-dropdown'}
            triggerHandler={toggleDropdown}
            triggerRef={createButtonRef}
          >
            <div className={classes('create-list-dropdown-item')} onClick={createBroadcastList}>
              Create List
            </div>
            <div
              className={classes('create-list-dropdown-item')}
              onClick={() => {
                createBroadcastList();
                setIsADSync(true);
              }}
            >
              Create Directory Sync
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

BroadcastListsView.propTypes = {
  createBroadcastList: PropTypes.func.isRequired,
  currentAppSelected: PropTypes.string.isRequired,
  currentOrganizationId: PropTypes.string.isRequired,
  editBroadcastList: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loadBroadcastLists: PropTypes.func.isRequired,
  setIsADSync: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default mobxInjectSelect({
  messengerStore: [
    'allowProviderBroadcastListAdSync',
    'currentAppSelected',
    'currentOrganizationId',
  ],
  modalStore: ['openModal'],
  patientAdminStore: ['loadBroadcastLists'],
})(BroadcastListsView);
